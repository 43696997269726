import { AuthMethod } from "src/constants/AuthMethod"
import { Result } from "src/utils/Result"
import { signIn } from "src/aws/customAPI"
import { ApiError, execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type LoginParams = {
  authMethod: AuthMethod
  value: string
}

type LoginSuccessResponse = {
  session: string
}

export const login = (_params: LoginParams): Promise<Result<LoginSuccessResponse, ApiError>> => {
  return execApi(
    () => signIn({
      input: {
        email: _params.authMethod === AuthMethod.Email ? _params.value : null,
        phone_number: _params.authMethod === AuthMethod.PhoneNumber ? _params.value : null,
      },
      appInfo
    }),
    res => {
      // レスポンスの型上Sessionがoptionalとなっているが、成功ケースのみこちらに入るため、Sessionがundefinedになることはない
      const session = res.signIn.data?.Session || ''
      return { session }
    },
    {
      identifiers: ["login", "signIn"],
      defaultErrorMessage: "ログインに失敗しました。"
    }
  )
}
