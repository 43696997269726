import { atom, selector } from "recoil"
import { draftSelector } from "src/recoil/selectors/contact/draftSelector"
import { templateSelector } from "src/recoil/selectors/contact/templateSelector"
import { EventDateCandidate } from "src/types/contact/EventDateCandidate"
import { existingContactDetailSelector } from "src/recoil/selectors/contact/create/existingContactDetailSelector"
import { reservedSelector } from "src/recoil/selectors/contact/reservedSelector"

const initialState = undefined

const initialStateSelector = selector({
  key: "atoms/contact/create/eventDateCandidatesState_initialState",
  get: ({ get }) => {
    const contact = get(existingContactDetailSelector)
    const contactValue =
      contact?.contactType === "ScheduleAdjustment" ? contact.schedules.map((s) => s.eventDateCandidate) : undefined
    const reserved = get(reservedSelector)
    const reservedValue =
      reserved?.contactType === "ScheduleAdjustment" ? reserved.schedules.map((s) => s.eventDateCandidate) : undefined
    const template = get(templateSelector)
    const draft = get(draftSelector)
    return contactValue ?? reservedValue ?? (template ?? draft)?.eventDateCandidates ?? initialState
  },
})

export const eventDateCandidatesState = atom<EventDateCandidate[] | undefined>({
  key: "atoms/contact/create/eventDateCandidatesState",
  default: initialStateSelector,
})
