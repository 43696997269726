import { selectorFamily } from "recoil"
import { getInformationDetail, InformationDetail } from "src/queries/settings/getInformationDetail"
import { informationRequestIdStateFamily } from "src/recoil/atoms/settings/informationRequestIdStateFamily"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"

export const informationSelectorFamily = selectorFamily<InformationDetail | undefined, string>({
  key: "selectors/settings/informationSelectorFamily",
  get:
    (id) =>
    async ({ get }) => {
      get(informationRequestIdStateFamily(id))
      const tokens = get(tokensState)
      if (tokens == null) {
        return
      }
      const information = await getInformationDetail({ accessToken: tokens.accessToken, id: id })
      return information
    },
})
