export const ACTIVE_MATCHING_OFFER_LIMIT = 10
export const MAX_BYTE_CONTENT_MAIL = 800
export const MARRY_QUESTION_ID = "1"
export const KID_QUESTION_ID = "2"
export const FROM_SIGN_UP = "fromSignUp"
export const HEADER_HEIGHT = 64
export const ERROR_017_MESSAGE = "エラーコード 017\n保存上限数（10件）に達しているため、保存できません。"
export const LIMIT_TIME_ERROR = "error400"
export const TIME_DIFF_5MIN = 300000
export const MIGRATE_PLAN_ID = "1"
export const errorMessageNotInThread = "このスレッドにアクセスする権限がありません。"
