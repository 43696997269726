import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { Result } from "src/utils/Result"

import { ContactFilterValue } from "src/types/contact/ContactFilter"
import { listSentAndReservedMail, MemberRole } from "src/aws/customAPI"
import { removeUndefined } from "src/utils/removeUndefined"
import { MailType } from "src/aws/API"
import { ContactType } from "src/constants/ContactType"
import { MemberType } from "src/types/organization/OrganizationMember"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

export type GetOutboxContactsParams = {
  accessToken: string
  myMemberId: string
  myMemberRole: MemberType
  organizationId: string
  searchText?: string
  filters?: ContactFilterValue[]
  count?: number
  nextToken?: string
}

export type GetOutboxContactsSuccess = {
  contacts: ContactOverviewModel[]
  nextToken?: string
}

type GetOutboxContactsFailure = {
  message: string
}

export const getOutboxContacts = async (params: GetOutboxContactsParams): Promise<Result<GetOutboxContactsSuccess, GetOutboxContactsFailure>> => {
  return execApi(
    () => listSentAndReservedMail(params.accessToken, {
      teamMemberId: params.myMemberId,
      teamMemberRole: memberTypeMapping[params.myMemberRole],
      filter: getFilter(params),
      appInfo,
      count: params.count,
      nextToken: params.nextToken
    }),
    res => {
      return {
        contacts: res.searchTeamMemberMails.items.map(m => ({
          id: m.mail.id,
          contactType: mailTypeMapping[m.mail.mailType],
          receivedMailId: m.id,
          isRead: m.readFlg,
          title: m.mail.title,
          senderId: m.mail.sender.id,
          senderName: m.mail.sender.nickname,
          isSenderDeleted: m.mail.sender.deleteFlg || false,
          isFavorite: m.favoriteFlgSent,
          isAttachmentExists: (m.mail.attachmentFileNames || []).length > 0,
          sentDate: m.mail.sentAt ? new Date(m.mail.sentAt) : undefined,
          isUpdate: m.mail.updatedAt > m.mail.createdAt,
          updatedDate: m.mail.updatedAt ? new Date(m.mail.updatedAt) : undefined,
          deadlineDate: m.mail.responseDueDatetime ? new Date(m.mail.responseDueDatetime) : undefined,
          reservedDate: m.mail.sendScheduledAt ? new Date(m.mail.sendScheduledAt) : undefined,
          teamMemberContactId: m.id,
        })),
        nextToken: res.searchTeamMemberMails.nextToken
      }
    }
  )
}

const getFilter = (param: { searchText?: string, filters?: ContactFilterValue[] }) => {
  const mailTypes: MailType[] = param.filters?.filter(f => contactTypeFilterValues.has(f)).map(toMailType) || []

  return removeUndefined({
    keyword : param.searchText,
    unanswered : param.filters?.includes("unanswered") || undefined,
    favorite: param.filters?.includes("favorite") || undefined,
    hasThread: param.filters?.includes("withReply") || undefined,
    hasAttachment: param.filters?.includes("withAttachment") || undefined,
    mailTypes: mailTypes.length > 0 ? mailTypes : undefined
  })
}

const mailTypeMapping: {[key in MailType]: ContactType} = {
  [MailType.normal]: ContactType.Normal,
  [MailType.attendance]: ContactType.Attendance,
  [MailType.questionnaire]: ContactType.Survey,
  [MailType.schedule]: ContactType.ScheduleAdjustment,
}

const memberTypeMapping: { [k in MemberType]: MemberRole } = {
  [MemberType.Representative]: "manager",
  [MemberType.General]: "general",
  [MemberType.GeneralWithContactAuthorization]: "mailSender",
}

const contactTypeFilterValues = new Set<ContactFilterValue>([
  ContactFilterValue.NormalContact,
  ContactFilterValue.AttendanceContact,
  ContactFilterValue.SurveyContact,
  ContactFilterValue.ScheduleAdjustmentContact
])

const contactTypeFilterValueMapping: Partial<{[key in ContactFilterValue]: MailType}> = {
  [ContactFilterValue.NormalContact]: MailType.normal,
  [ContactFilterValue.AttendanceContact]: MailType.attendance,
  [ContactFilterValue.SurveyContact]: MailType.questionnaire,
  [ContactFilterValue.ScheduleAdjustmentContact]: MailType.schedule,
}

const toMailType = (filterValue: ContactFilterValue): MailType => {
  return contactTypeFilterValueMapping[filterValue] || MailType.normal
}
