// https://rnfirebase.io/crashlytics/usage
export class CrashlyticsWeb {
  log(message) {
    // eslint-disable-next-line no-undef
    console.log(message)
  }

  crash() {
    // eslint-disable-next-line no-undef
    console.error("crashed")
  }

  recordError(err, jsErrorName) {
    // eslint-disable-next-line no-undef
    if (jsErrorName) console.error(jsErrorName)
    // eslint-disable-next-line no-undef
    console.error(err)
  }

  setUser(uid) {
    // eslint-disable-next-line no-undef
    console.log(uid)
  }
}

// インスタンスをexport
export const crashlyticsWrap = new CrashlyticsWeb()
