import { updateComment, GqlError } from "src/aws/customAPI";
import { CustomAlert } from "src/utils/CustomAlert";

type Params = {
  accessToken: string
  mailId: string
  memberId: string
  comment: string
}

export const updateCommentApi = async ({accessToken, mailId, memberId, comment} :Params): Promise<{id: string} | any> => {
  if (!accessToken || !mailId || !memberId) return
  return updateComment(accessToken, {
      input: {
        mailId,
        memberId,
        comment: comment ?? "",
      }
  }).then(res => {
    if (res.data?.updateComment.id) {
      return {id: res.data?.updateComment.id}
    }
  }).catch((error: GqlError) => {
    CustomAlert.alert("エラー", error.errors?.[0]?.errorInfo?.userMessage, "閉じる")
    return error
  })
}