import { getTeamMemberPreset } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
  organizationId: string
  email: string
}

export const existOrganizationMemberPreset = async ({accessToken, organizationId, email}: Params): Promise<boolean> => {
  const result = await execApi(
    () => getTeamMemberPreset(accessToken, {
      teamId: organizationId,
      email: email,
      appInfo
    }),
    res => res.getTeamMemberPreset != null,
    {
      identifiers: ["existOrganizationMemberPreset", "getTeamMemberPreset"]
    }
  )
  return result.isOk ? result.content : false
}
