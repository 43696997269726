import type { FC } from "react"
import { Svg, Path } from "react-native-svg"
import { DrawerIconProps } from "src/types/DrawerIconProps"

export const InboxIcon: FC<DrawerIconProps> = ({ color }) => {
  return (
    <Svg width="21.407" height="14.717" viewBox="0 0 21.407 14.717">
      <Path
        data-name="パス 1280"
        d="M54.493 4.986h-15.7l-2.854 6.69V19.7h21.409v-8.024zm-3.835 5.352a4.014 4.014 0 1 1-8.027 0h-4.665l1.714-4.014h13.93l1.713 4.014z"
        transform="translate(-35.941 -4.986)"
        fill={color}
      />
    </Svg>
  )
}

InboxIcon.displayName = "InboxIcon"
