import { oldRa9Ra9GetTeams } from "./oldRa9Ra9Api";
import { CustomAlert } from "../../utils/CustomAlert";

export type TeamList = {
  team_id: number;
  name: string;
  member_id: number;
  admin: number;
  with_ads?: number;
};

type Params = {
  accessToken: string
  userId: number
}

export const getMigrationTeams = async ({ accessToken, userId }: Params): Promise<TeamList[]> => {
  return oldRa9Ra9GetTeams(accessToken, userId).then(async (res) => {
    if (res.status === 200) {
      const { myteam_list } = await res.json();
      return myteam_list;
    } else {
      await CustomAlert.alert("完了", "予期せぬエラーが発生しました");
      return;
    }
  });
}

