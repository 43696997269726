import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilCallback, useRecoilState } from "recoil"
import { resourceUrlAtomFamily } from "src/recoil/atoms/resource/resourceUrlAtomFamily"
import { getResourceGetUrl } from "src/apis/resource/getResourceGetUrl"
import { deleteResource } from "src/apis/resource/deleteResource"
import { getResourcePutUrl } from "src/apis/resource/getResourcePutUrl"
import { Result } from "src/utils/Result"
import { LocalDocument, LocalImage, LocalResource } from "src/types/resource/LocalResource"
import { ResourceUnit } from "src/types/resource/ResourceUnit"
import { allMemberImageUrlsRequestIdState } from "src/recoil/atoms/resource/allMemberImageUrlsRequestIdState"
import { useFileManager } from "src/recoil/hooks/resource/fileManager"
import * as ImageManipulator from "expo-image-manipulator"

export const imageSize = {
  account: {
    normal: { width: 100, height: 100 },
    thumbnail: { width: 50, height: 50 },
    maxSizeInMB: 10,
  },
  team: {
    normal: { width: 720, height: 250 },
    thumbnail: { width: 100, height: 100 },
    maxSizeInMB: 10,
  },
  // TeamMember画像はAccount画像と同じため省略
  attachment: {
    thumbnail: { width: 100, height: 100 },
    maxSizeInMB: 10,
    maxCount: 5,
  },
}

type Param = ResourceUnit & {
  init?: boolean
  onLocalImageChange?: (localImage: LocalImage, resetLocalResource: () => void) => boolean
  defaultLocalImage?: LocalImage
  onLocalDocumentChange?: (localDocument: LocalDocument, resetLocalResource: () => void) => boolean
  defaultLocalDocument?: LocalDocument
  onUploaderChange?: (resourceUnit: ResourceUnit, uploader: () => Promise<Result<undefined, { message: string }>>) => void
}

export const useResource = ({
  onLocalImageChange,
  defaultLocalImage,
  onLocalDocumentChange,
  defaultLocalDocument,
  onUploaderChange,
  ...resourceUnit
}: Param) => {
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const { value: resourceUrl } = useAsyncSelector(resourceUrlAtomFamily(resourceUnit))

  const [_localImage, __setLocalImage] = useState<LocalImage | undefined>(defaultLocalImage)
  const [_localDocument, __setLocalDocument] = useState<LocalDocument | undefined>(defaultLocalDocument)
  const [deleteResourceFlg, _setDeleteResourceFlg] = useState(false)
  const maxSizeInMB = useMemo(
    () =>
      resourceUnit.type === "accountImage"
        ? imageSize.account.maxSizeInMB
        : resourceUnit.type === "teamImage"
        ? imageSize.team.maxSizeInMB
        : resourceUnit.type === "attachmentFile"
        ? imageSize.attachment.maxSizeInMB
        : undefined,
    [resourceUnit.type]
  )
  const altFileName = useMemo(
    () => (resourceUnit.type === "attachmentFile" || resourceUnit.type === "attachmentThumbnail" ? resourceUnit.fileName : ""),
    [resourceUnit]
  )
  const _setLocalImage = useCallback(
    (localImage: LocalImage | undefined) => {
      __setLocalDocument(undefined)
      __setLocalImage(localImage)
      _setDeleteResourceFlg(localImage == null)
    },
    [__setLocalImage, __setLocalDocument, _setDeleteResourceFlg]
  )

  const _setLocalDocument = useCallback(
    (localDocument: LocalDocument | undefined) => {
      __setLocalImage(undefined)
      __setLocalDocument(localDocument)
      _setDeleteResourceFlg(localDocument == null)
    },
    [__setLocalImage, __setLocalDocument, _setDeleteResourceFlg]
  )

  const localResource = useMemo<LocalResource | undefined>(() => {
    return _localDocument || _localImage
  }, [_localDocument, _localImage])

  const _resetLocalResource = useCallback(() => {
    __setLocalImage(undefined)
    __setLocalDocument(undefined)
  }, [__setLocalImage, __setLocalDocument])

  const _onLocalImageChange = useMemo(
    () => (onLocalImageChange ? (localImage: LocalImage) => onLocalImageChange?.(localImage, _resetLocalResource) : undefined),
    [onLocalImageChange, _resetLocalResource]
  )

  const _onLocalDocumentChange = useMemo(
    () =>
      onLocalDocumentChange
        ? (localDocument: LocalDocument) => onLocalDocumentChange?.(localDocument, _resetLocalResource)
        : undefined,
    [onLocalDocumentChange, _resetLocalResource]
  )

  const refreshResourceUrl = useRecoilCallback(
    ({ set }) =>
      async () => {
        if (accessToken == null || resourceUnit.type === "none") return

        const newUrl = await getResourceGetUrl({ accessToken, ...resourceUnit })
        set(resourceUrlAtomFamily(resourceUnit), newUrl)
        if (resourceUnit.type === "teamImage") {
          const resourceUnitOtherSize: ResourceUnit = {
            type: "teamImage",
            id: resourceUnit.id,
            size: resourceUnit.size === "normal" ? "thumbnail" : "normal",
          }
          const newUrlOtherSize = await getResourceGetUrl({ accessToken, ...resourceUnitOtherSize })
          set(resourceUrlAtomFamily(resourceUnitOtherSize), newUrlOtherSize)
        }
        return newUrl
      },
    [accessToken, resourceUnit]
  )

  const { pickImage, pickDocument, upload } = useFileManager({
    setLocalImage: _setLocalImage,
    onLocalImageChange: _onLocalImageChange,
    setLocalDocument: _setLocalDocument,
    onLocalDocumentChange: _onLocalDocumentChange,
  })

  const pickLocalImage = useCallback(
    async (param?: { maxSizeInMB?: number; allowsEditing?: boolean; messageErrorMaxSizeFile?: string }) => {
      const maxSizeInMB_ = param?.maxSizeInMB || maxSizeInMB
      if (maxSizeInMB_)
        await pickImage({
          maxSizeInMB: maxSizeInMB_,
          altFileName,
          allowsEditing: param?.allowsEditing,
          messageErrorMaxSizeFile: param?.messageErrorMaxSizeFile,
        })
    },
    [pickImage, altFileName, maxSizeInMB]
  )

  const pickLocalDocument = useCallback(
    async (param?: { maxSizeInMB?: number; messageErrorMaxSizeFile?: string }) => {
      const maxSizeInMB_ = param?.maxSizeInMB || maxSizeInMB
      if (maxSizeInMB_)
        await pickDocument({ maxSizeInMB: maxSizeInMB_, altFileName, messageErrorMaxSizeFile: param?.messageErrorMaxSizeFile })
    },
    [pickDocument, maxSizeInMB, altFileName]
  )

  const prepareDeleteResource = useCallback(async () => {
    _setLocalImage(undefined)
    _setLocalDocument(undefined)
  }, [_setLocalImage, _setLocalDocument])

  const _getResizedImageUri = useCallback(
    async (newSize: { width: number; height: number }): Promise<string | undefined> => {
      if (_localImage == null) return
      if (_localImage.width <= newSize.width || _localImage.height <= newSize.height) return _localImage.uri

      const ratio =
        _localImage.width * newSize.height > _localImage.height * newSize.width
          ? newSize.height / _localImage.height
          : newSize.width / _localImage.width
      return await ImageManipulator.manipulateAsync(
        _localImage.uri,
        [{ resize: { width: _localImage.width * ratio, height: _localImage.height * ratio } }],
        { compress: 0.7, format: ImageManipulator.SaveFormat.JPEG }
      ).then((res) => res.uri)
    },
    [_localImage]
  )

  const [, setAllMemberImageUrlsRequestId] = useRecoilState(allMemberImageUrlsRequestIdState)
  const [, setNeedAllMemberImageUrlRequest] = useState(false)
  const refreshAllMemberImageUrls = useCallback(() => {
    setAllMemberImageUrlsRequestId((prev) => prev + 1)
    setNeedAllMemberImageUrlRequest(true)
  }, [setAllMemberImageUrlsRequestId])

  const _uploadResource = useCallback(
    async (localUri: string | undefined, putUrl: string | undefined): Promise<Result<undefined, { message: string }>> => {
      if (localUri == null || putUrl == null) return Result.Ok(undefined)
      return upload({ localUri, putUrl })
    },
    [upload]
  )

  const uploadResource = useCallback(async (): Promise<Result<undefined, { message: string }>> => {
    try {
      if (accessToken == null) {
        return Result.Ok(undefined)
      } else if (deleteResourceFlg) {
        if (resourceUnit.type === "accountImage" || resourceUnit.type === "teamImage") {
          return deleteResource({ accessToken, ...resourceUnit })
            .then(async () => {
              await refreshResourceUrl()
              refreshAllMemberImageUrls()
              return Result.Ok(undefined)
            })
            .catch(() => Result.Error({ message: "リソースの削除に失敗しました。" }))
        } else {
          return Result.Ok(undefined)
        }
      } else if (_localImage) {
        if (resourceUnit.type === "accountImage" || resourceUnit.type === "teamImage") {
          const extension = _localImage.extension
          const urls = await getResourcePutUrl({ accessToken, extension, ...resourceUnit })
          const size = resourceUnit.type === "accountImage" ? imageSize.account : imageSize.team
          const [result1, result2] = await Promise.all([
            _getResizedImageUri(size.normal).then((uri) => _uploadResource(uri, urls?.url)),
            _getResizedImageUri(size.thumbnail).then((uri) => _uploadResource(uri, urls?.thumbnailUrl)),
          ])

          if (result1.isOk && result2.isOk) {
            await refreshResourceUrl()
            refreshAllMemberImageUrls()
            return Result.Ok(undefined)
          } else {
            return Result.Error({ message: "ファイルのアップロードに失敗しました。" })
          }
        } else if (resourceUnit.type === "attachmentFile") {
          const urls = await getResourcePutUrl({ accessToken, ...resourceUnit })
          return _uploadResource(_localImage.uri, urls?.url)
        } else if (resourceUnit.type === "attachmentThumbnail") {
          const urls = await getResourcePutUrl({ accessToken, ...resourceUnit })
          return _getResizedImageUri(imageSize.attachment.thumbnail).then((uri) => _uploadResource(uri, urls?.url))
        } else {
          // 上記以外のリソースはupload不可 (ユーザーによる利用時には発生しない)
          return Result.Error({ message: "予期せぬエラーが発生しました。" })
        }
      } else if (_localDocument) {
        if (resourceUnit.type === "attachmentFile") {
          const urls = await getResourcePutUrl({ accessToken, ...resourceUnit })
          return _uploadResource(_localDocument.uri, urls?.url)
        } else {
          // 上記以外のリソースはupload不可 (ユーザーによる利用時には発生しない)
          return Result.Error({ message: "予期せぬエラーが発生しました。" })
        }
      } else {
        return Result.Ok(undefined)
      }
    } catch {
      return Result.Error({ message: "リソースの更新に失敗しました。" })
    }
  }, [
    accessToken,
    _getResizedImageUri,
    _localImage,
    _localDocument,
    _uploadResource,
    resourceUnit,
    refreshResourceUrl,
    deleteResourceFlg,
    refreshAllMemberImageUrls,
  ])

  useEffect(() => {
    onUploaderChange?.(resourceUnit, uploadResource)
  }, [onUploaderChange, resourceUnit, uploadResource])

  return {
    resourceUrl,
    localResource,
    refreshResourceUrl,
    prepareDeleteResource,
    deleteResourceFlg,
    uploadResource,
    pickLocalImage,
    pickLocalDocument,
    refreshAllMemberImageUrls,
  }
}
