import { selectorFamily } from "recoil"
import { MatchingOfferDraftModel } from "src/types/matching/matchingOfferModel"
import { requestDataSelector } from "../auth/requestDataSelector"
import { getMatchingOfferDraft } from "src/aws/customAPI"
import { matchingOfferDraftRequestIdState } from "src/recoil/atoms/matching/matchingOfferDraftRequestIdState"

export const matchingOfferDraftSelectorFamily = selectorFamily<MatchingOfferDraftModel | undefined, string>({
  key: "selectors/matting/matchingOfferDraftSelectorFamily",
  get:
    (draftId) =>
    async ({ get }) => {
      get(matchingOfferDraftRequestIdState)
      const requestData = get(requestDataSelector)
      if (requestData == null) return
      const { accessToken } = requestData
      const draftResult = await getMatchingOfferDraft(accessToken, { id: draftId })
      if (!draftResult.data) {
        return
      }
      const d = draftResult.data.getMatchingOfferDraft
      return {
        id: d.id,
        title: d.title,
        body: d.body,
        date: d.date,
        startTime: d.startTime,
        endTime: d.endTime,
        prefecture: d.prefecture,
        city: d.city,
        place: d.place,
        placeURL: d.placeURL,
        expense: d.expense,
        expensePurpose: d.expensePurpose,
        ageFrom: d.ageFrom,
        ageTo: d.ageTo,
        offerCount: d.offerCount,
        publishEndDatetime: d.publishEndDate,
        createdAt: d.createdAt,
        updatedAt: d.updatedAt,
        tempMatchingOfferId: draftId,
      } as MatchingOfferDraftModel
    },
})
