import { useCallback } from "react"
import { useResetRecoilState } from "recoil"
import { useRefresher } from "src/hooks/useRefresher"
import { affiliationStateFamily } from "src/recoil/atoms/organization/myProfile/affiliationStateFamily"
import { myProfileRequestIdState } from "src/recoil/atoms/organization/myProfile/myProfileRequestIdState"
import { phoneNumberStateFamily } from "src/recoil/atoms/organization/myProfile/phoneNumberStateFamily"
import { schoolGradeStateFamily } from "src/recoil/atoms/organization/myProfile/schoolGradeStateFamily"
import { thumbnailStateFamily } from "src/recoil/atoms/organization/myProfile/thumbnailStateFamily"

export const useResetMyProfileFormData = (organizationId: string) => {
  const resetMyProfile = useRefresher(myProfileRequestIdState)
  const resetThumbnail = useResetRecoilState(thumbnailStateFamily(organizationId))
  const resetPhoneNumber = useResetRecoilState(phoneNumberStateFamily(organizationId))
  const resetAffiliation = useResetRecoilState(affiliationStateFamily(organizationId))
  const resetSchoolGrade = useResetRecoilState(schoolGradeStateFamily(organizationId))

  return useCallback(() => {
    resetMyProfile()
    resetThumbnail()
    resetPhoneNumber()
    resetAffiliation()
    resetSchoolGrade()
  }, [resetMyProfile, resetThumbnail, resetPhoneNumber, resetAffiliation, resetSchoolGrade])
}
