import { Result } from "src/utils/Result"
import { getOrCreateTempMailId } from "src/aws/customAPI"
import { crashlyticsWrap } from "src/tags/crashlytics"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
  organizationId: string
}

type Success = string

type Failure = {
  message: string
}

export const getTempMailId = ({ accessToken, organizationId }: Params): Promise<Result<Success, Failure>> => {
  return execApi(
    () => getOrCreateTempMailId(accessToken, {
      input: {
        teamId: organizationId,
      },
      appInfo
    }),
    res => res.getOrCreateTempMailId.id,
  )
}
