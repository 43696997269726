import { selectorFamily } from "recoil"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { ContactFilterValue } from "src/types/contact/ContactFilter"
import { migrationContactsSelectorFamily } from "src/recoil/selectors/contact/migrationContactsSelectorFamily"
import { migrationContactsConcatRequestIdState } from "src/recoil/atoms/contact/migrationContactsConcatRequestIdState"

export const migrationContactsConcatSelectorFamily = selectorFamily<
  { contacts: ContactOverviewModel[]; nextToken?: string } | undefined,
  { searchText?: string; filters?: ContactFilterValue[]; nextTokens: string[] }
>({
  key: "selectors/contact/migrationContactsConcatSelectorFamily",
  get:
    ({ nextTokens, ...params }) =>
    async ({ get }) => {
      get(migrationContactsConcatRequestIdState)
      const result = [
        get(migrationContactsSelectorFamily(params)),
        ...nextTokens.map((nextToken) => get(migrationContactsSelectorFamily({ ...params, nextToken }))),
      ]
      return {
        contacts: result.reduce((p, c) => (c != null ? [...p, ...c.contacts] : p), [] as ContactOverviewModel[]),
        nextToken: result.length > 0 ? result[result.length - 1]?.nextToken : undefined,
      }
    },
})
