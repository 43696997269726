import { getAdminNotice } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

export type InformationDetail = {
  id: string
  title: string
  body: string
}

type Params = {
  accessToken: string
  id: string
}

export const getInformationDetail = async ({ accessToken, id }: Params): Promise<InformationDetail | undefined> => {
  const result = await execApi(
    () =>
      getAdminNotice(accessToken, {
        id: id,
        appInfo,
      }),
    (res) => {
      const notice = res.getAdminNotice
      return notice
        ? {
            id: notice.id,
            title: notice.title,
            body: notice.content,
          }
        : undefined
    }
  )

  return result.isOk ? result.content : undefined
}
