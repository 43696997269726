import { useCallback, useEffect, useMemo, useState } from "react"
import { getMatchingOffers } from "src/apis/matching/getMatchingOffer"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import {
  createOrEditMatchingOfferState,
  createOrEditParams,
} from "src/recoil/atoms/matching/createOrEdit/createOrEditMatchingOfferState"
import { myOffersState } from "src/recoil/atoms/matching/myOfferState"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { MatchingOffersModel } from "src/types/matching/matchingOfferModel"
import { CustomAlert } from "src/utils/CustomAlert"
import { ACTIVE_MATCHING_OFFER_LIMIT } from "src/utils/const"
import { isEndPublishDatetime } from "../resource/fileManager/common"

export const useMyMatchingOffers = (isCreate?: boolean) => {
  const [myOffers, setMyOffers] = useAsyncState<MatchingOffersModel[]>(myOffersState)
  const [myCreateData, setMyCreateData] = useAsyncState<createOrEditParams>(createOrEditMatchingOfferState)
  const { value: selectedMyTeam } = useAsyncSelector(selectedMyOrganizationSelector)
  const teamId = useMemo(() => selectedMyTeam?.id, [selectedMyTeam])
  const [searchText, setSearchText] = useState<string>("")
  const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(selectedMyTeam?.id))
  const teamMemberId = useMemo(() => myProfile?.memberId, [myProfile])
  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])

  const [nextToken, setNextToken] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [total, setTotal] = useState<number>(0)
  const [tokenMyMatching, setTokenMyMatching] = useState<string>()
  const { value: organizationDetail } = useAsyncSelector(organizationDetailSelectorFamily(selectedMyTeam?.id))
  const getData = useCallback(
    async (keyword?: string, nextToken?: string) => {
      if (teamId == null || accessToken == null || teamMemberId == null) return
      setLoading(true)
      await getMatchingOffers({
        accessToken,
        teamId,
        teamMemberId,
        type: "myOffers",
        filter: { keyword },
        nextToken,
      }).then((res) => {
        setLoading(false)
        if (!res.isOk) {
          CustomAlert.alert("エラー", res.error.message)
          return
        }
        if (nextToken) {
          const filterExistOffer = res.content.data.filter((offer) => {
            if (!myOffers) {
              return false
            }
            return !myOffers.map((of) => of.id).includes(offer.id)
          })
          setMyOffers([...(myOffers ?? []), ...filterExistOffer])
        } else {
          setMyOffers(res.content.data)
        }
        setTokenMyMatching(res.content.nextToken)
        setTotal(res.content.total)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamId, accessToken, teamMemberId, myOffers]
  )
  const refreshList = async (searchText?: string) => {
    if (isCreate) return
    setNextToken(undefined)
    await setTimeout(() => {
      getData(searchText)
    }, 1500)
  }

  const loadMore = async () => {
    await setNextToken(tokenMyMatching)
  }

  const hasMore = useMemo(() => (myOffers?.length ?? 0) < total, [myOffers, total])

  const checkCreateNewOffer = async (callback: () => void) => {
    if (
      myOffers &&
      myOffers.filter((offer) => {
        if (isEndPublishDatetime(offer.publishEndDate ?? "")) {
          return !isEndPublishDatetime(offer.publishEndDate ?? "")
        } else {
          return !offer.deleteFlg
        }
      }).length >= ACTIVE_MATCHING_OFFER_LIMIT
    ) {
      await CustomAlert.alert(
        "エラーコード037",
        `募集掲載上限数（${ACTIVE_MATCHING_OFFER_LIMIT}件）に達しているため、作成できません。`
      )
    } else {
      callback()
    }
  }

  useEffect(() => {
    if (nextToken) {
      getData(searchText, nextToken)
    } else {
      getData(searchText)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken, searchText])

  const resetValueCreateOffer = () => {
    setMyCreateData({
      title: "",
      body: "",
      place: "",
      placeURL: "",
      expense: "",
      expensePurpose: "",
      activity: undefined,
      offerCount: undefined,
      date: undefined,
      publishEndDatetime: undefined,
      startTime: undefined,
      endTime: undefined,
      prefecture: undefined,
      city: undefined,
      ageFrom: undefined,
      ageTo: undefined,
    })
  }

  return {
    myOffers,
    isLoading,
    refreshList,
    searchText,
    loadMore,
    hasMore,
    setSearchText,
    checkCreateNewOffer,
    resetValueCreateOffer,
  }
}
