import { dayOfWeek } from "src/constants/dayOfWeek"
import dayjs from "dayjs"

export type EventDateCandidate =
  | {
      type: "AllDay"
      startDate: Date
    }
  | {
      type: "AllDayAfter"
      startDate: Date
      startTime: string
    }
  | {
      type: "TimeRange"
      startDate: Date
      startTime: string
      endTime: string
    }
  | {
      type: "DateRange"
      startDate: Date
      endDate: Date
    }
  | {
      type: "DateTimeRange"
      startDate: Date
      startTime: string
      endDate: Date
      endTime: string
    }

export type PartialCandidate = {
  startDate?: Date
  startTime?: string
  endDate?: Date
  endTime?: string
}

export const getEventDateCandidate = ({
  startDate,
  startTime,
  endDate,
  endTime,
}: PartialCandidate): EventDateCandidate | undefined => {
  if (!startDate) return undefined

  if (!startTime && !endDate && !endTime) {
    return {
      type: "AllDay",
      startDate: startDate,
    }
  } else if (startTime && !endDate && !endTime) {
    return {
      type: "AllDayAfter",
      startDate: startDate,
      startTime: startTime,
    }
  } else if (
    startTime &&
    (!endDate || dayjs(startDate).format("YYYY/MM/DD") === dayjs(endDate).format("YYYY/MM/DD")) &&
    endTime
  ) {
    return {
      type: "TimeRange",
      startDate: startDate,
      startTime: startTime,
      endTime: endTime,
    }
  } else if (!startTime && endDate && !endTime) {
    return {
      type: "DateRange",
      startDate: startDate,
      endDate: endDate,
    }
  } else if (startTime && endDate && endTime) {
    return {
      type: "DateTimeRange",
      startDate: startDate,
      startTime: startTime,
      endDate: endDate,
      endTime: endTime,
    }
  } else {
    return undefined
  }
}

export const asPartial = (c: EventDateCandidate): { startDate: Date } & Omit<PartialCandidate, "id"> => {
  return {
    startDate: c.startDate,
    startTime: c.type === "AllDayAfter" || c.type === "TimeRange" || c.type === "DateTimeRange" ? c.startTime : undefined,
    endDate: c.type === "DateRange" || c.type === "DateTimeRange" ? c.endDate : undefined,
    endTime: c.type === "TimeRange" || c.type === "DateTimeRange" ? c.endTime : undefined,
  }
}

const _formatDate = (d: Date) => `${d.getMonth() + 1}/${d.getDate()} (${dayOfWeek[d.getDay()]})`
export const formatCandidate = (c: EventDateCandidate) => {
  if (c.type === "AllDay") {
    return _formatDate(c.startDate)
  } else if (c.type === "AllDayAfter") {
    return `${_formatDate(c.startDate)} ${c.startTime} ～`
  } else if (c.type === "TimeRange") {
    return `${_formatDate(c.startDate)} ${c.startTime} ～ ${c.endTime}`
  } else if (c.type === "DateRange") {
    if (dayjs(c.startDate).isSame(dayjs(c.endDate))) {
      return _formatDate(c.startDate)
    } else {
      return `${_formatDate(c.startDate)} ～ ${_formatDate(c.endDate)}`
    }
  } else {
    return `${_formatDate(c.startDate)} ${c.startTime} ～ ${_formatDate(c.endDate)} ${c.endTime}`
  }
}
