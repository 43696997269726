import { selectorFamily } from "recoil"
import { TeamMemberContacts } from "src/types/contact/TeamMemberContact"
import { teamMemberContactsRequestIdState } from "src/recoil/atoms/contact/teamMemberContactsRequestIdState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { getTeamMemberContacts } from "src/apis/contact/getTeamMemberContacts"
import { CustomAlert } from "src/utils/CustomAlert"
import { Platform } from "react-native"
import { CustomAlert as AppAlert } from "src/utils/CustomAlert/index.native"
import { ContactType } from "src/constants/ContactType"
export const teamMemberContactsSelectorFamily = selectorFamily<
  TeamMemberContacts | undefined,
  { contactId: string | undefined; type: "Inbox" | "Outbox" | "Reserved" | "Migration" }
>({
  key: "selectors/contact/teamMemberContactsSelectorFamily",
  get:
    ({ contactId, type }) =>
    async ({ get }) => {
      if (!(type === "Inbox" || type === "Outbox" || type === "Reserved")) return undefined
      get(teamMemberContactsRequestIdState)
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null || contactId == null) {
        return
      }
      const result = await getTeamMemberContacts({ accessToken, contactId, type })
      if (!result.isOk) {
        if (Platform.OS === "web") {
          await CustomAlert.alert("エラー", result.error.message)
        } else {
          await AppAlert.alert("エラー", result.error.message)
        }

        return { contactType: ContactType.Normal, items: [] }
      }
      return result.content
    },
})
