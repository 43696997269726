import { selector } from "recoil"
import { isReceiversAllLeadersState } from "src/recoil/atoms/contact/create/isReceiversAllLeadersState"
import { isReceiversAllMembersState } from "src/recoil/atoms/contact/create/isReceiversAllMembersState"
import { selectedGroupIdsState } from "src/recoil/atoms/contact/create/selectedGroupIdsState"
import { selectedMemberIdsState } from "src/recoil/atoms/contact/create/selectedMemberIdsState"
import type { Receivers } from "src/types/contact/Receivers"

export const receiversSelector = selector<Receivers | undefined>({
  key: "selectors/contact/create/receiversSelector",
  get: ({ get }) => {
    const isAllMembers = get(isReceiversAllMembersState)
    const isAllLeaders = get(isReceiversAllLeadersState)
    const memberIds = get(selectedMemberIdsState)
    const groupIds = get(selectedGroupIdsState)

    if (isAllMembers == null && isAllLeaders == null && memberIds == null && groupIds == null) {
      return {}
    }

    return {
      isAllMembers,
      isAllLeaders,
      memberIds,
      groupIds,
    }
  },
})
