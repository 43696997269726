import { SchoolGrade } from "src/constants/SchoolGradeOptions"
import { MemberType } from "src/types/organization/OrganizationMember"
import { Result } from "src/utils/Result"
import { GqlError, MemberRole, transferApplicantAuthority, updateTeamMember } from "src/aws/customAPI"
import { removeUndefined } from "src/utils/removeUndefined"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type UpdateMemberDetailParams = {
  accessToken: string
  accountId: string
  organizationId: string
  memberId: string
  nickname?: string
  kanaNickname?: string
  isShowEmail?: boolean
  memberType?: MemberType
  newMemberId?: number
  phoneNumber?: string
  isShowPhoneNumber?: boolean
  affiliation?: string
  isShowAffiliation?: boolean
  schoolGrade?: SchoolGrade
}

type UpdateMemberDetailSuccess = unknown

type UpdateMemberDetailFailure = {
  message: string
}

type ParamsTransfer = {
  teamId: string
  newApplicantId: string
  accessToken: string
}

export const updateMemberDetail = async (params: UpdateMemberDetailParams): Promise<Result<UpdateMemberDetailSuccess, UpdateMemberDetailFailure>> => {
  const input = {
    id: params.memberId,
    ...removeUndefined({
      nickname: params.nickname,
      nicknameKana: params.kanaNickname,
      role: params.memberType ? convertMemberRole(params.memberType) : undefined,
      showEmailFlg: params.isShowEmail,
      memberSeq: params.newMemberId,
      phoneNumber: params.phoneNumber,
      showPhoneNumberFlg: params.isShowPhoneNumber,
      belongs: params.affiliation,
      showBelongsFlg: params.isShowAffiliation,
      grade: params.schoolGrade,
    })
  }
  return execApi(
    () => updateTeamMember(params.accessToken, {
      input: input,
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["updateMemberDetail", "updateTeamMember"]
    }
  )
}

export const convertMemberRole = (type: MemberType): MemberRole => {
  return ({
    [MemberType.General]: 'general',
    [MemberType.Representative]: 'manager',
    [MemberType.GeneralWithContactAuthorization]: 'mailSender',
  } as {[type in MemberType]: MemberRole})[type]
}

export const transferApplicantAuthorityApi = async ({ accessToken, teamId, newApplicantId }: ParamsTransfer): Promise<{ id: string } | any> => {
  return transferApplicantAuthority(accessToken, {
    input: {
      teamId,
      newApplicantId,
    }
  }).then((res) => {
    if (res.data?.applicantAuthorityTransfer.id) return { id: res.data.applicantAuthorityTransfer.id }
    else return ""
  }).catch((err: GqlError) => err)
}
