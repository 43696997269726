const tintColorLight = "#E59333"
const tintColorDark = "#fff"

/**
 * @deprecated - Use `Colors` instead.
 * TODO: remove default export.
 */
export default {
  light: {
    text: "#000",
    background: "#fff",
    tint: tintColorLight,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorLight,
  },
  dark: {
    text: "#fff",
    background: "#000",
    tint: tintColorDark,
    tabIconDefault: "#ccc",
    tabIconSelected: tintColorDark,
  },
}

export const Colors = {
  white: "#faf8f5",
  white2: "#e3e3e3",
  white3: "#ffffff",
  white4: "#d8d8d8",
  white5: "#f0f0f0",
  white6: "#d3d3d3",
  white7: "#f8f8f8",
  white8: "#FBFAF6",
  white9: "#FFF1E3",
  beige: "#faf8f5",
  marigolod: "#ffbf00",
  orange1: "#9F866B1A",
  orange: "#f0830e",
  bgOrange: "#f0830e1f",
  veryLightPink2: "#ffe2e2",
  red: "#f00e0e",
  blush: "#f4ab83",
  pale: "#ffe5c9",
  pale2: "#ffead5",
  turtleGreen: "#89b64d",
  leaf: "#7bb938",
  duckEggBlue: "#e8f5fa",
  brightSkyBlue: "#00c4ff",
  lightGrey: "#dddbd7",
  silver: "#dbdcdd",
  cement: "#9a9996",
  gray: "#707070",
  warmGrey: "#707070",
  greyshBrown: "#3c3c3c",
  coolGrey: "#a4adb2",
  black: "#292724",
  black2: "#000",
  lightTan: "#f9d5c1",
  reddishGrey: "#9f866b1a",
  warmGrey2: "#8e8e8e",
  warmGrey3: "#888",
  important: "#E11520",
  sidebarBg: "#f2eae4",
  redBold: "#E11520",
  activeStep: "#F0830E",
  grey: "#B5ACA0",
  unActiveStep: "#EFEAE3",
  borderColor: "#E3E3E3",
  borderColor2: "#CCC8C4",
  textColor: "#141414",
  lightGrayishOrange: "#F1EFEB",
  orangeLight: "#EE8327",
  backgroundColor: "#FAF8F5",
}
