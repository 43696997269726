import React, { memo, useCallback, useMemo } from "react"
import { TeamList } from "src/apis/migration/getMigrationTeams"
import { View, StyleSheet, Text, ScrollView, useWindowDimensions, Platform } from "react-native"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { Screens } from "src/constants/Screens"
import { RootTabs } from "src/constants/RootTabs"
import { RootStackScreenProps } from "src/types.d"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { listAllMigratedTeamsRequestIdState } from "src/recoil/atoms/organization/listAllMigratedTeamsRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"
import { useSetRecoilState } from "recoil"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"

export const MigrationTeamComplete = memo<RootStackScreenProps<"MigrationTeamComplete">>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const widthScreen = useWindowDimensions().width
  const { teamName, memberCount, teamId } = useMemo(() => route.params, [route.params])
  const refreshAllMigratedTeams = useRefresher(listAllMigratedTeamsRequestIdState)
  const setOrganizationId = useSetRecoilState(selectedMyOrganizationState)

  const openListMigrateOrganization = useCallback(async () => {
    refreshAllMigratedTeams()
    navigation.navigate(Screens.MigrateOrganizations, { joinFlg: true })
  }, [navigation, refreshAllMigratedTeams])

  return (
    <View style={[isLargeScreen ? styles.container : {}, { flex: 1 }]}>
      <ScrollView style={styles.main}>
        <View style={isLargeScreen ? styles.oneColumn : { width: "100%", paddingVertical: 32 }}>
          <View style={isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : { paddingHorizontal: 24 }}>
            <Text style={[styles.title, { marginBottom: 0 }]}>
              {`らくらく連絡網＋に「${teamName}」の団体情報が移行されました。\n${memberCount}人のメンバーに招待メールを送信しました。`}
            </Text>
            <View style={styles.content}>
              <Text>
                {`メンバーは招待メールのURLより参加手続きを行うことで、この移行団体に所属できます。\n招待メールを送信したメンバーの一覧は「メンバー登録メール送信履歴」で確認できます。`}
              </Text>
            </View>
          </View>
          <View
            style={[
              styles.actions,
              styles.separator,
              isLargeScreen ? { display: "flex", alignItems: "center", paddingHorizontal: (widthScreen - 600) / 2 } : {},
            ]}
          >
            <TextButton
              title="メール送信履歴を確認する"
              onPress={() => {
                navigation.navigate(Screens.SendingHistory, { id: teamId })
              }}
              buttonType={ButtonType.Primary}
              style={isLargeScreen ? styles.buttonsPC : {}}
            />
            <TextButton
              title="他の団体を移行する"
              onPress={() => navigation.navigate(Screens.MigrationTeamSelect)}
              buttonType={ButtonType.Secondary}
              style={[styles.actionTail, isLargeScreen ? styles.buttonsPC : {}]}
            />
          </View>

          {Platform.OS === "web" && (
            <>
              <View
                style={[
                  { paddingHorizontal: 24, paddingTop: 32 },
                  isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : {},
                ]}
              >
                <Text style={[styles.title, { marginBottom: 0 }]}>{"続けて「団体有料版」の申込をしましょう。"}</Text>
                <View style={styles.content}>
                  <Text>
                    {`らくらく連絡網にて有料版をご利用の場合、らくらく連絡網＋の団体有料版の申込時のプラン選択にて「らくらく連絡網からの引継ぎプラン」を選択いただくことで、らくらく連絡網でご利用いただいた有料版の残り期間を引き継いで、らくらく連絡網＋の団体有料版をご利用いただくことが可能となります。`}
                  </Text>
                </View>
              </View>
              <View
                style={[
                  styles.actions,
                  styles.separator,
                  isLargeScreen ? { display: "flex", alignItems: "center", paddingHorizontal: (widthScreen - 600) / 2 } : {},
                ]}
              >
                <TextButton
                  title="団体有料版を申込む"
                  onPress={() => {
                    setOrganizationId(teamId)
                    navigation.navigate(Screens.registerApplicantInformation, {
                      organizationId: teamId,
                      mode: "init",
                      isMigrate: true,
                    })
                  }}
                  buttonType={ButtonType.Primary}
                  style={isLargeScreen ? styles.buttonsPC : {}}
                />
              </View>
            </>
          )}

          <View
            style={[
              { paddingHorizontal: 24, paddingTop: 32 },
              isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : {},
            ]}
          >
            <Text style={[styles.title, { marginBottom: 0 }]}>
              {"移行団体への参加状況を確認して、未参加メンバーに参加リクエストを送りましょう。"}
            </Text>
            <View style={styles.content}>
              <Text>
                {`メンバーの参加状況は「移行団体管理」で確認できます。まだ参加していないメンバーには、移行団体への参加を促すリクエストメールを送信できます。`}
              </Text>
            </View>
          </View>
          <View
            style={[
              styles.actions,
              styles.separator,
              isLargeScreen ? { display: "flex", alignItems: "center", paddingHorizontal: (widthScreen - 600) / 2 } : {},
            ]}
          >
            <TextButton
              title="メンバーの進捗を確認する"
              onPress={openListMigrateOrganization}
              buttonType={ButtonType.Primary}
              style={isLargeScreen ? styles.buttonsPC : {}}
            />
          </View>
          <View
            style={[
              styles.actionContainerAcpect,
              isLargeScreen ? { alignItems: "center", paddingHorizontal: (widthScreen - 600) / 2 } : {},
            ]}
          >
            <TextButton
              buttonType={ButtonType.Blank}
              title={"終了する"}
              onPress={() =>
                navigation.navigate(Screens.Root, {
                  screen: RootTabs.OrganizationManagerTab,
                  params: {
                    screen: OrganizationManagerScreens.OrganizationManager,
                  },
                })
              }
              style={[isLargeScreen ? styles.buttonsPC : {}, styles.BtnOrganizationManager]}
            />
          </View>
        </View>
      </ScrollView>
    </View>
  )
})

type Props = {
  team: TeamList
  selectedTeamId: number | undefined
  select: (team: TeamList) => void
}

export const migrationTeamCompleteOptions: ScreenOptions = ({ navigation }) => ({
  ...commonHeaderOptions,
  headerShown: true,
  title: "団体情報の移行完了",
  headerLeft: () => null,
})

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.beige,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  main: {
    flex: 1,
    backgroundColor: "#faf8f5",
  },
  oneColumn: {
    paddingTop: 20,
  },
  buttonsPC: {
    width: "100%",
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  centerLayout: {
    display: "flex",
    alignItems: "center",
    paddingVertical: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    width: "100%",
    textAlign: "left",
    marginBottom: 20,
  },
  titleModalLarge: {
    fontSize: 18,
    fontWeight: "600",
    width: "100%",
    textAlign: "center",
  },
  titleModal: {
    fontSize: 18,
    fontWeight: "600",
    textAlign: "center",
  },
  titleModalScroll: {
    fontSize: 18,
    fontWeight: "600",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    fontSize: 14,
    fontWeight: "400",
    width: "100%",
    textAlign: "left",
    paddingTop: 16,
  },
  contentNote: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "left",
    paddingTop: 20,
  },
  contentLink: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "left",
  },
  card: {
    borderRadius: 12,
    padding: 15,
    fontWeight: "bold",
    backgroundColor: "#FFFFFF",
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginVertical: 5,
    fontFamily: "Hiragino Kaku Gothic Pro",
  },
  linkText: {
    color: Colors.orange,
    textDecorationLine: "underline",
  },
  backgroundModal: {
    position: "absolute",
    zIndex: 100,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainModal: {
    backgroundColor: Colors.white3,
    width: "90%",
    maxWidth: 382,
    padding: 24,
    borderRadius: 24,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  secondLayer: { backgroundColor: "#f0f0f0", opacity: 0.4, width: "100%", height: "100%" },
  alertTop: {
    backgroundColor: Colors.white3,
    width: 270,
    padding: 16,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  alertBottom: {
    display: "flex",
    justifyContent: "center",
    borderTopWidth: 0.2,
    borderTopColor: "#f0f0f0",
    textAlign: "center",
    backgroundColor: Colors.white3,
    width: 270,
    height: 44,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    alignItems: "center",
  },
  contentAlert: {
    fontSize: 14,
    fontWeight: "400",
    width: "100%",
    textAlign: "left",
    paddingHorizontal: 2,
  },
  textModal: {
    color: Colors.greyshBrown,
    fontSize: 14,
    lineHeight: 20,
    marginVertical: 20,
  },
  modalWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.37)",
    padding: 22,
    paddingVertical: 60,
  },
  textInput: {
    fontSize: 16,
    textAlignVertical: "top",
    paddingVertical: 13,
    paddingHorizontal: 16,
    backgroundColor: Colors.white3,
    borderWidth: 2,
    borderColor: Colors.lightGrey,
    borderRadius: 10,
    minHeight: 320,
  },
  mainModalScroll: {
    backgroundColor: Colors.white3,
    borderRadius: 8,
    paddingVertical: 24,
    paddingHorizontal: 20,
  },
  actionContainer: {
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  textButton: { color: "#F0830E", fontSize: 18, fontWeight: "600", lineHeight: 25.2, textAlign: "center" },
  buttonCustom: { margin: 5, width: 120, borderRadius: 8, backgroundColor: "rgba(159, 134, 107, 0.1)", padding: 10 },
  textInModal: {
    fontSize: 14,
    fontWeight: "400",
  },
  actions: {
    paddingVertical: 32,
    paddingHorizontal: 24,
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  actionTail: {
    marginTop: 20,
  },
  actionContainerAcpect: {
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
  BtnOrganizationManager: {
    backgroundColor: "#9F866B1A",
    borderRadius: 8,
  },
})
