import { useMemo } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { citiesSelectorFamily } from "src/recoil/selectors/account/citiesSelectorFamily"
import { jobsSelector } from "src/recoil/selectors/account/jobsSelector"
import { prefecturesSelector } from "src/recoil/selectors/account/prefecturesSelector"
import { schoolDepartmentsSelectorFamily } from "src/recoil/selectors/account/schoolDepartmentsSelectorFamily"
import { schoolsSelectorFamily } from "src/recoil/selectors/account/schoolsSelectorFamily"
import { accountQuestionMasterState } from "src/recoil/atoms/account/accountQuestionMasterState"
import { useResource } from "src/recoil/hooks/resource/useResource"

export const useAccountInfoData = () => {
  const { value: account } = useAsyncSelector(accountSelector)
  const {
    resourceUrl: imageUrl,
    refreshResourceUrl: refreshImageUrl,
    refreshAllMemberImageUrls,
  } = useResource({ type: "accountImage", size: "normal" })
  const { value: jobs } = useAsyncSelector(jobsSelector)
  const jobName = useMemo(() => jobs?.find(({ id }) => id === account?.jobId)?.label, [account, jobs])
  const { value: schools } = useAsyncSelector(schoolsSelectorFamily(account?.jobId))
  const schoolName = useMemo(() => schools?.find(({ id }) => id === account?.schoolId)?.label, [account, schools])
  const { value: schoolDepartments } = useAsyncSelector(schoolDepartmentsSelectorFamily(account?.schoolId))
  const schoolDepartmentName = useMemo(
    () => schoolDepartments?.find(({ id }) => id === account?.schoolDepartmentId)?.label,
    [account, schoolDepartments]
  )
  const { value: prefectures } = useAsyncSelector(prefecturesSelector)
  const prefectureName = useMemo(
    () => prefectures?.find(({ id }) => id === account?.prefectureId)?.label,
    [account, prefectures]
  )
  const { value: cities } = useAsyncSelector(citiesSelectorFamily(account?.prefectureId))
  const cityName = useMemo(() => cities?.find(({ id }) => id === account?.cityId)?.label, [account, cities])

  const { value: accountQuestions } = useAsyncSelector(accountQuestionMasterState)

  return {
    account,
    imageUrl,
    refreshImageUrl,
    jobName,
    schoolName,
    schoolDepartmentName,
    prefectureName,
    cityName,
    accountQuestions,
    refreshAllMemberImageUrls,
  }
}
