import { memo, useCallback, useMemo, useState } from "react"
import { RootStackScreenProps } from "src/types.d"
import { Modal, Platform, ScrollView, StyleSheet, Text, View } from "react-native"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { Screens } from "src/constants/Screens"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { Button } from "src/components/parts/buttons/Button"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { useReportMatchingProfileData } from "src/recoil/hooks/organization/useReportMatchingProfileData"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"

const placeholderText = "通報内容および理由をできるだけ具体的に記載してください。"

export type ReportMatchingProfileParams = {
  id: string
  teamName: string
}

type Props = RootStackScreenProps<typeof Screens.ReportMatchingProfile>

export const ReportMatchingProfile = memo<Props>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const id = useMemo(() => route.params.id, [route.params.id])
  const teamName = useMemo(() => route.params.teamName, [route.params.teamName])
  const insets = useSafeAreaInsets()
  const [invitationMethodModalOpen, setInvitationMethodModalOpen] = useState(false)
  const { value: selectedMyTeam } = useAsyncSelector(selectedMyOrganizationSelector)

  const onCloseReportModal = useCallback(() => {
    navigation.goBack()
  }, [navigation])
  const { inputComment, setComment, onSubmit, isLoadingSubmit } = useReportMatchingProfileData(id, onCloseReportModal)

  return (
    <CustomKeyboardAvoidingView>
      <ScrollView contentContainerStyle={isLargeScreen ? {} : { minHeight: 670 }}>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <View style={isLargeScreen ? { width: 600 } : { width: "100%" }}>
            <View style={[styles.contentsContainer, { borderTopWidth: 0 }]}>
              <Text style={styles.sectionTitle}>この団体を通報しますか？</Text>
              <Text style={[styles.description, { paddingBottom: 0 }]}>
                通報した内容はらくらく連絡網＋の運営チームに報告されます。
              </Text>
            </View>
            <View style={[styles.contentsContainer]}>
              <ItemLabel label="自分の団体" />
              <Text style={[styles.description, { marginLeft: 25 }]}>{selectedMyTeam?.title}</Text>
              <ItemLabel label="相手の団体" />
              <Text style={[styles.description, { marginLeft: 25 }]}>{teamName}</Text>
              <ItemLabel
                label="通報内容および理由"
                RightComponent={<InputLengthCounter text={inputComment} maxLength={500} unit={"字以内"} />}
              />
              <View style={{ paddingTop: 16, paddingBottom: 25 }}>
                <MultilineTextInput
                  style={[styles.textInput, { height: 240 }, Platform.OS !== "ios" && { fontSize: 16, paddingVertical: 13 }]}
                  placeholder={placeholderText}
                  value={inputComment}
                  onChangeText={setComment}
                  maxLength={500}
                />
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <View
        style={[styles.actionContainer, { paddingBottom: insets.bottom + 10 }, isLargeScreen ? { alignItems: "center" } : {}]}
      >
        <TextButton
          style={isLargeScreen ? { width: 300 } : {}}
          title={"通報"}
          buttonType={ButtonType.Primary}
          onPress={onSubmit}
          disabled={isLoadingSubmit || inputComment.length === 0}
          isLoading={isLoadingSubmit}
        />
      </View>
      <Modal animationType="fade" visible={invitationMethodModalOpen} transparent>
        <View style={styles.modalWrapper}>
          <View style={[styles.mainModal, { maxWidth: 600 }]}>
            <View style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
              <Button onPress={onCloseReportModal} style={{ paddingVertical: 10, paddingHorizontal: 10 }}>
                <RemoveIcon fill={Colors.orange}></RemoveIcon>
              </Button>
              <Text style={styles.titleModal}>招待方法について</Text>
            </View>
            <Text style={styles.textModal}>{"この内容で通報しますか？"}</Text>
          </View>
        </View>
      </Modal>
    </CustomKeyboardAvoidingView>
  )
})

export const options: ScreenOptions = {
  ...commonSingleModalOptions,
  title: "通報する",
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white3,
  },
  head: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 8,
    backgroundColor: Colors.white3,
  },
  orgName: {
    marginLeft: 8,
  },
  contentsContainer: {
    padding: 24,
    borderTopWidth: 1,
    borderTopColor: Colors.lightGrey,
  },
  description: {
    fontSize: 14,
    lineHeight: 21,
    color: Colors.black,
    marginVertical: 8,
    marginLeft: 0,
    paddingVertical: 12,
  },
  contentWrapper: {
    borderRadius: 8,
    backgroundColor: "#efeae3",
  },
  content: {
    fontSize: 16,
    lineHeight: 25,
    color: Colors.black2,
    padding: 12,
  },
  centering: {
    textAlign: "left",
  },
  buttonContainer: {
    marginTop: 16,
    alignItems: "center",
  },
  buttonLabel: {
    marginLeft: 14,
    color: Colors.orange,
  },
  actions: {
    paddingVertical: 16,
    paddingHorizontal: 32,
    paddingBottom: 25,
  },
  secondaryButton: {
    marginTop: 16,
  },
  linkText: {
    color: Colors.black,
    textDecorationLine: "underline",
  },
  actionContainer: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  textInput: {
    textAlignVertical: "top",
    paddingLeft: 16,
    backgroundColor: Colors.white3,
    borderWidth: 2,
    borderColor: Colors.lightGrey,
    borderRadius: 10,
    minHeight: 280,
  },

  modalWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.37)",
    padding: 22,
    paddingVertical: 60,
  },
  mainModal: {
    backgroundColor: Colors.white3,
    borderRadius: 8,
    paddingVertical: 24,
    paddingHorizontal: 20,
  },
  titleModal: {
    fontSize: 18,
    fontWeight: "600",
    width: "100%",
    textAlign: "center",
    paddingRight: 50,
  },
  textModal: {
    color: Colors.greyshBrown,
    fontSize: 14,
    lineHeight: 20,
    marginVertical: 20,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginLeft: 0,
    marginRight: 0,
  },
})
