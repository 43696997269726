import { atom, selector } from "recoil"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"

export const prefectureIdState = atom({
  key: "atoms/account/prefectureIdState",
  default: selector({
    key: "atoms/account/prefectureIdState_default",
    get: ({ get }) => {
      const account = get(accountSelector)
      return account?.prefectureId
    },
  }),
})
