import { analyticsWrap } from "src/tags/analytics/index"
import Constants from "expo-constants"

const send = (id: string, teamId?: string) => {
  if (Constants.expoConfig?.extra?.env === "dev") {
    console.log({ id, teamId })
  }
  return analyticsWrap.sendAnalyticsEventAsync("custom_action", "clickButton", id, teamId)
}

export const Analytics = {
  goToInvitation: (teamId: string) => send("メンバー招待ボタン(SP)", teamId),
  shareInvitation: (teamId: string) => send("共有ボタン", teamId),
  copyInvitationCode: (teamId: string) => send("コピーボタン", teamId),
  sendJoinRequest: () => send("団体に参加ボタン"),
  deleteTeam: () => send("解約ボタン"),
  quitTeam: () => send("団体からの脱退ボタン"),
  deleteAccount: () => send("退会ボタン"),
  goToCreateMail: (teamId: string) => send("連絡作成ボタン", teamId),
  sendMail: (teamId: string) => send("連絡を送信ボタン", teamId),
  goToMailDetailFromInbox: (teamId: string) => send("連絡カード(受信トレイ)", teamId),
  goToMailDetailFromOutbox: (teamId: string) => send("連絡カード(送信ボックス)", teamId),
  replyToMailSender: (teamId: string) => send("返信ボタン(連絡受信者)", teamId),
  goToThreadByMailRecipient: (teamId: string) => send("返信カード(連絡受信者)", teamId),
  goToThreadByMailSender: (teamId: string) => send("返信カード(連絡送信者)", teamId),
  answerToMail: (teamId: string) => send("回答するボタン", teamId),
  sendThreadMessage: (teamId: string) => send("送信ボタン", teamId),
  goToHome: () => send("ホームボタン"),
  goToMail: () => send("連絡網ボタン"),
  goToTeam: () => send("団体管理ボタン"),
  goToSetting: () => send("設定ボタン"),
}
export const sendAnalyticsEventAsync = (eventName: string, type: string, id: string, teamId?: string): void => {
  analyticsWrap?.sendAnalyticsEventAsync(eventName, type, id, teamId)
}
