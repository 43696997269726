import { memo, useCallback, useMemo } from "react"
import { StyleSheet, Text, View } from "react-native"
import { ExpiryBadge } from "src/components/parts/authorization/ExpiryBadge"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptionsOnNestedScreen } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useEditOrRegisterPhoneNumberData } from "src/recoil/hooks/screens/useEditOrRegisterPhoneNumberData"
import { AuthorizationPhoneNumberCodeParams as AuthPhoneNumberCodeProps } from "src/screens/settings/AccountInfo/AuthorizationPhoneNumberCode"
import { RootStackScreenProps } from "src/types.d"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type EditOrRegisterPhoneNumberParams = {
  currentPhoneNumber?: string
}

type Props = RootStackScreenProps<typeof Screens.EditOrRegisterPhoneNumber>

export const EditOrRegisterPhoneNumber = memo<Props>(({ navigation, route }) => {
  const gotoEnterAuthCode = useCallback(
    (params: AuthPhoneNumberCodeProps) => navigation.navigate(Screens.AuthorizationPhoneNumberCode, params),
    [navigation]
  )

  const currentPhoneNumber = useMemo(() => route.params?.currentPhoneNumber, [route.params?.currentPhoneNumber])
  const isLargeScreen = useCheckPCScreen()

  const { newPhoneNumber, setNewPhoneNumber, isDisabled, sendAuthCode, isSendingAuthCode } = useEditOrRegisterPhoneNumberData({
    currentPhoneNumber,
    gotoEnterAuthCode,
  })

  return (
    <CustomKeyboardAvoidingView style={styles.container}>
      <CustomScrollView scrollEnabled={false} style={styles.scrollArea}>
        {currentPhoneNumber != null ? (
          <View style={isLargeScreen ? styles.oneColumn : {}}>
            <View style={isLargeScreen ? { width: 380 } : {}}>
              <ItemLabel label="現在の携帯電話番号" />
              <Text style={styles.phoneNumber}>{currentPhoneNumber}</Text>
            </View>
          </View>
        ) : null}
        <View style={isLargeScreen ? styles.oneColumn : {}}>
          <View style={isLargeScreen ? { width: 380 } : {}}>
            <ItemLabel
              label="携帯電話番号を入力（ハイフンなし）"
              RightComponent={<InputLengthCounter text={newPhoneNumber} maxLength={11} unit={"桁"} />}
            />
          </View>
          <CustomTextInput
            style={[styles.textInput, isLargeScreen ? styles.inputPC : {}]}
            value={newPhoneNumber}
            onChangeText={(text) => {
              const numericValue = text.replace(/[^0-9]/g, "")
              setNewPhoneNumber(numericValue)
            }}
            keyboardType="number-pad"
            textContentType="telephoneNumber"
            placeholder="入力してください"
            maxLength={11}
          />

          <View style={styles.buttonWrapper}>
            <TextButton
              style={isLargeScreen ? styles.buttonsPC : {}}
              buttonType={ButtonType.Primary}
              onPress={sendAuthCode}
              title="認証コードを送信"
              isLoading={isSendingAuthCode}
              disabled={isDisabled}
            />
          </View>
          <View style={styles.badgeContainer}>
            <ExpiryBadge />
          </View>
        </View>
      </CustomScrollView>
    </CustomKeyboardAvoidingView>
  )
})

export const options: ScreenOptions = ({ route }) => {
  const currentPhoneNumber = (route.params as EditOrRegisterPhoneNumberParams)?.currentPhoneNumber
  const title = `携帯電話番号(認証用)${currentPhoneNumber ? "変更" : "登録"}`
  return {
    ...commonHeaderOptionsOnNestedScreen,
    title,
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
  },
  scrollArea: {
    padding: 16,
  },
  phoneNumber: {
    fontSize: 16,
    marginTop: 4,
    marginBottom: 16,
  },
  input: {
    borderWidth: 0.5,
    borderRadius: 8,
    height: 48,
    padding: 8,
    marginTop: 8,
  },
  buttonWrapper: {
    marginTop: 32,
  },
  textInput: {
    marginTop: 8,
  },
  badgeContainer: {
    marginTop: 32,
    flexDirection: "row",
    justifyContent: "center",
  },
  oneColumn: {
    display: "flex",
    alignItems: "center",
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  inputPC: {
    width: 380,
  },
})
