
import { GqlError, reportMatchingTeam as _reportMatchingTeam } from "src/aws/customAPI"
import { Result } from "src/utils/Result";

type ReportMatchingTeamParams = {
  accessToken: string
  reporterTeamId: string;
  otherTeamId: string;
  content?: string;
}

type ReportMatchingTeamSuccess = unknown

type ReportMatchingTeamFailure = {
  message: string
}

export const reportMatchingTeam = async ({accessToken, reporterTeamId, otherTeamId, content}: ReportMatchingTeamParams): Promise<Result<ReportMatchingTeamSuccess, ReportMatchingTeamFailure>> => {
  return await _reportMatchingTeam(accessToken, {
    input: {
      reporterTeamId,
      teamId: otherTeamId,
      content,
    }
  }).then(res => {
    if (res.data?.reportMatchingTeam.id) {
      return Result.Ok(undefined)
    } else {
      return Result.Error<ReportMatchingTeamFailure>({message: "団体の通報に失敗しました"})
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "団体の通報に失敗しました"
    return Result.Error<ReportMatchingTeamFailure>({
      message: message
    })
  })
}