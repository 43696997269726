import type { ValueOf } from "src/types.d"
import dayjs from "dayjs"

export const Day = Object.freeze({
  Monday: "Monday",
  Tuesday: "Tuesday",
  Wednesday: "Wednesday",
  Thursday: "Thursday",
  Friday: "Friday",
  Saturday: "Saturday",
  Sunday: "Sunday",
} as const)

export const DayLabel = {
  [Day.Monday]: "月",
  [Day.Tuesday]: "火",
  [Day.Wednesday]: "水",
  [Day.Thursday]: "木",
  [Day.Friday]: "金",
  [Day.Saturday]: "土",
  [Day.Sunday]: "日",
}

export type Day = ValueOf<typeof Day>

export const days = Object.values(Day)

export const formatDate = {
  normal: "YYYY-MM-DD",
  japanNormal: "YYYY年MM月DD日",
  UntilYYYYMMDD: "YYYY年MM月DD日まで",
  PlanStartTime: "YYYY年MM月DD日開始",
}

export const convertDate = (date?: string | null, isStart?: boolean) => {
  if (!date) {
    return ""
  }
  if (isStart) {
    return dayjs(date).startOf("month").format("YYYY年MM月DD日")
  }
  return dayjs(date).format("YYYY年MM月DD日")
}
