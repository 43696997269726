import { Platform } from "react-native"
import { selector } from "recoil"
import { getNotifications, Notification } from "src/queries/home/getNotifications"
import { notificationsRequestIdState } from "src/recoil/atoms/home/notificationsRequestIdState"
import { requestDataSelector } from "src/recoil/selectors/auth/requestDataSelector"
import { CustomAlert } from "src/utils/CustomAlert"
import { TeamNoticeType } from "src/aws/customAPI"

export const notificationsSelector = selector<Notification[] | undefined>({
  key: "selectors/home/notificationsSelector",
  get: async ({ get }) => {
    get(notificationsRequestIdState)
    const requestData = get(requestDataSelector)
    if (requestData == null) return

    const result = await getNotifications(requestData)
    if (!result.isOk) {
      await CustomAlert.alert("エラー", result.error.message)
      return
    }
    if (Platform.OS === "web") {
      return result.content
    } else {
      return result.content.filter(
        (notification) =>
          notification.type != TeamNoticeType.paymentDeadline &&
          notification.type != TeamNoticeType.startExtendOrderTime &&
          notification.type != TeamNoticeType.middleExtendOrderTime
      )
    }
  },
})
