import { memo, useCallback, useMemo } from "react"
import { Platform, ScrollView, StyleSheet, Text, View } from "react-native"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import * as Linking from "expo-linking"
import Constants from "expo-constants"
import { CustomAlert } from "src/utils/CustomAlert"
import { useRecoilValue } from "recoil"
import { appVersionsState } from "src/recoil/atoms/updateApp/appVersionsState"
import { Colors } from "src/constants/Colors"
import { SafeAreaView } from "react-native"
import { appStoreIosRedirectLinkApp, chPlayAndroiRedirectLinkApp } from "src/constants/links/appStore"

export type UpdateAppInfoParams = undefined

export const UpdateAppInfo = memo(() => {
  const appVersions = useRecoilValue(appVersionsState)
  const description = useMemo(() => appVersions?.map((v) => `${v.version}\n${v.content}`).join("\n\n"), [appVersions])
  const textButtonTitle = useMemo(() => (Platform.OS === "ios" ? "App Storeへ" : "Google Playへ"), [])
  const openUpdatePage = useCallback(async () => {
    const url =
      Platform.OS === "ios"
        ? appStoreIosRedirectLinkApp
        : chPlayAndroiRedirectLinkApp(Constants.expoConfig?.extra?.androidPackage || "")
    if (await Linking.canOpenURL(url)) {
      await Linking.openURL(url)
    } else {
      CustomAlert.alert("エラー", "StoreLinkが不正です")
    }
  }, [])
  const { bottom, top } = useSafeAreaInsets()

  if (appVersions == null) {
    return null
  }

  return (
    <CustomKeyboardAvoidingView>
      <SafeAreaView style={styles.backgroundHeader}>
        <View style={[styles.header, Platform.OS === "android" ? { paddingTop: top } : null]}>
          <Text style={styles.headerTitle}>お知らせ</Text>
        </View>
      </SafeAreaView>

      <View style={styles.container}>
        <Text style={styles.title}>アプリを最新版にアップデートしてください。</Text>
        <Text style={styles.subTitle}>更新情報</Text>
        <Text style={styles.description}>{description}</Text>
        <View style={[styles.textButtonContainer, { paddingBottom: bottom }]}>
          <TextButton buttonType={ButtonType.Primary} title={textButtonTitle} onPress={openUpdatePage} />
        </View>
      </View>
    </CustomKeyboardAvoidingView>
  )
})

const styles = StyleSheet.create({
  header: {
    paddingTop: 10,
    paddingBottom: 15,
    backgroundColor: Colors.orange,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    color: Colors.white,
    fontSize: 18,
    fontWeight: "500",
  },
  container: {
    paddingVertical: 28,
    paddingHorizontal: 30,
  },
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 18,
    color: "#414141",
  },
  subTitle: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 18,
    color: "#414141",
    marginTop: 28,
    marginBottom: 16,
  },
  description: {
    fontSize: 15,
    lineHeight: 18,
    color: "#414141",
  },
  textButtonContainer: {
    width: "100%",
    padding: 16,
    paddingBottom: 32,
  },
  backgroundHeader: {
    backgroundColor: Colors.orange,
  },
})
