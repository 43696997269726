import { memo, useMemo } from "react"
import { Image, Linking, Pressable, StyleSheet, Text, View, useWindowDimensions } from "react-native"
import { Colors } from "src/constants/Colors"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { MatchingProfileModel } from "src/types/matching/matchingProfileModel"
import { BanIcon } from "../icons/BanIcon"
import { defaultTeam } from "src/constants/defaultTeam"
import { matchingIcon } from "src/constants/matchingIcon"

type Props = {
  matchingProfile: MatchingProfileModel | undefined
  teamId: string | undefined
}

export const MatchingProfile = memo<Props>(({ matchingProfile, teamId }) => {
  const { width } = useWindowDimensions()
  const imageHeight = useMemo(() => width * 0.54, [width])

  const { resourceUrl: teamImageUrl, refreshResourceUrl: refreshTeamImageUrl } = useResource({
    type: "matchingTeamImage",
    teamId: teamId ?? "",
    size: "normal",
  })

  const { resourceUrl: teamBgImageUrl, refreshResourceUrl: refreshTeamBgImageUrl } = useResource({
    type: "matchingTeamBgImage",
    teamId: teamId ?? "",
    size: "normal",
  })

  return (
    <View style={styles.container}>
      <Image
        style={[styles.teamImage, { height: imageHeight }]}
        source={teamBgImageUrl ? { uri: teamBgImageUrl } : defaultTeam}
        onError={refreshTeamBgImageUrl}
      />
      {matchingProfile?.isBlocking && (
        <View style={[styles.blockView, { width: "100%" }]}>
          <View style={[{ marginLeft: 20 }]}>
            <BanIcon fill={Colors.white3} />
          </View>
          <Text style={styles.blockLabel}>ブロック中</Text>
        </View>
      )}
      <View style={[styles.row, { padding: 24, width: "100%" }]}>
        <View style={styles.header}>
          <Image
            style={styles.teamImageIcon}
            source={teamImageUrl ? { uri: teamImageUrl } : matchingIcon}
            onError={refreshTeamImageUrl}
          />
          <View style={styles.row}>
            <Text>{matchingProfile?.prefecture?.label}</Text>
            <Text style={styles.name}>{matchingProfile?.name}</Text>
          </View>
        </View>
        <View>
          <View style={styles.row}>
            <Text>
              {matchingProfile?.category?.label} {matchingProfile?.activity?.label}
            </Text>
            <Text>
              年齢層：{matchingProfile?.ageFrom}～{matchingProfile?.ageTo}歳
            </Text>
          </View>
        </View>
        <View style={[styles.row, styles.mt16]}>
          {matchingProfile?.introduction.split("\n").map((value, index) => (
            <Text key={index}>{value}</Text>
          ))}
        </View>
        {matchingProfile?.teamUrl && (
          <Pressable onPress={() => Linking.openURL(matchingProfile.teamUrl ?? "")}>
            <Text style={{ color: Colors.orange, marginLeft: 5, textDecorationLine: "underline" }}>
              {matchingProfile.teamUrl}
            </Text>
          </Pressable>
        )}
      </View>
    </View>
  )
})

export const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white3,
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    borderBottomColor: Colors.white2,
    borderBottomWidth: 1,
  },
  teamImage: {
    maxHeight: 300,
    width: "100%",
  },
  teamImageIcon: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  row: {
    flexDirection: "column",
    padding: 8,
  },
  header: {
    flexDirection: "row",
    padding: 8,
  },
  mt16: {
    marginTop: 16,
  },
  blockLabel: {
    color: Colors.white3,
    alignItems: "center",
    justifyContent: "center",
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: 5,
    textAlign: "left",
  },
  blockView: {
    flexDirection: "row",
    backgroundColor: Colors.important,
    alignItems: "center",
    justifyContent: "flex-start",
    height: 30,
  },
  name: {
    fontSize: 18,
    fontWeight: "bold",
  },
  registerImageButtonText: {
    color: Colors.orange,
    marginLeft: 8,
    fontSize: 13,
  },
})
