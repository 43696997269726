import { createTeamMemberFromPreset } from "src/aws/customAPI"
import { Result } from "src/utils/Result"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
  organizationId: string
  email: string
}

type Success = unknown

type Failure = {
  message: string
}


export const createOrganizationMemberFromPreset = ({accessToken, organizationId, email}: Params): Promise<Result<Success, Failure>> => {
  return execApi(
    () => createTeamMemberFromPreset(accessToken, {
      input: {
        teamId: organizationId,
        email: email
      },
      appInfo
    }),
    res => res.createTeamMemberFromPreset.id,
    {
      identifiers: ["createOrganizationMemberFromPreset", "createTeamMemberFromPreset"],
    }
  )
}
