import { Gender } from "src/constants/Gender"
import { Result } from "src/utils/Result"
import { initAccount as initAccountAPI, updateAccount as updateAccountAPI } from "src/aws/customAPI"
import { Gender as APIGender, SignupStatus } from "src/aws/API"
import { AccountModel } from "src/types/user/AccountModel"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

export type InitAccountRequestParams = {
  accessToken: string
  signupStatus: SignupStatus
  termsVersion?: string
} & Omit<AccountModel, "id" | "email" | "phoneNumber">

type InitAccountSuccess = unknown

type InitAccountFailure = {
  message: string
}

export const initOrUpdateAccount = async (params: InitAccountRequestParams): Promise<Result<InitAccountSuccess, InitAccountFailure>> => {
  const variables = {
    input: {
      lastName: params.lastName,
      firstName: params.firstName,
      lastNameKana: params.kanaLastName,
      firstNameKana: params.kanaFirstName,
      gender: convertGender(params.gender),
      birthday: params.birthDate != null ? params.birthDate.toISOString().split("T")[0] : null,
      // どこかで"undefined"という文字列が入ってしまうことがあるため、!== "undefined"の条件を追加
      accountJobId: params.jobId != null && params.jobId !== "undefined" ? params.jobId : null,
      accountSchoolId: params.schoolId != null ? params.schoolId : null,
      accountSchoolDepartmentId: params.schoolId != null && params.schoolDepartmentId != null ? params.schoolDepartmentId : null,
      schoolGraduationYear: params.schoolId != null && params.graduationYear != null ? params.graduationYear : null,
      accountPrefectureId: params.prefectureId != null ? params.prefectureId : null,
      accountCityId: params.prefectureId != null && params.cityId != null ? params.cityId : null,
      questionAnswers: params.questionAnswers ? Object.entries(params.questionAnswers).map(([k, v]) => ({
        questionId: k,
        answerId: v,
      })) : [],
    },
    appInfo
  }

  if (params.signupStatus === SignupStatus.inProgress) {
    if (params.termsVersion == null) return Result.Error({ message: "アカウントの初期化に失敗しました。" })
    return execApi(
      () => initAccountAPI(params.accessToken, {
        input: {
          ...variables.input,
          agreedTermsVersion: params.termsVersion,
        },
      }),
      _ => undefined,
      {
        identifiers: ["initOrUpdateAccount", "initAccountAPI"],
        defaultErrorMessage: "アカウントの初期化に失敗しました。",
      },
    )
  } else {
    return execApi(
      () => updateAccountAPI(params.accessToken, variables),
      _ => undefined,
      {
        identifiers: ["initOrUpdateAccount", "updateAccountAPI"],
        defaultErrorMessage: "アカウントの更新に失敗しました。",
      },
    )
  }
}

const convertGender = (gender?: Gender | null): APIGender => {
  return {
    [Gender.Male]: APIGender.male,
    [Gender.Female]: APIGender.female,
    [Gender.NoAnswer]: APIGender.unspecified,
  }[gender || Gender.NoAnswer]
}