import { atom } from "recoil"
export type FilterParams = {
  activity:
    | {
        id: string
        label: string
      }
    | undefined
  dateFrom: any
  dateTo: any
  prefecture:
    | {
        id: number
        label: string
      }
    | undefined
  city:
    | {
        id: string
        label: string
      }
    | undefined
  ageFrom:
    | {
        id: string
        label: string
      }
    | undefined
  ageTo:
    | {
        id: string
        label: string
      }
    | undefined
  keyword: string | undefined
}
export const defaultFilterParams = {
  activity: undefined,
  dateFrom: undefined,
  dateTo: undefined,
  prefecture: undefined,
  city: undefined,
  ageFrom: undefined,
  ageTo: undefined,
  keyword: undefined,
}
export const filterParamsMatchingState = atom<FilterParams>({
  key: "atoms/matching/search/filterParamsMatchingState",
  default: defaultFilterParams,
})
