import { memo, useCallback, useEffect, useMemo } from "react"
import { ScrollView, StyleSheet, Text, View } from "react-native"
import { ButtonType } from "src/components/parts/buttons/TextButton"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { CloseModalHeaderLeftButton } from "src/components/parts/headers/CloseModalHeaderLeftButton"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { RadioButton } from "src/components/parts/RadioButton"
import { SingleButtonFooter } from "src/components/parts/SingleButtonFooter"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { schoolGradeOptions } from "src/constants/SchoolGradeOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useEditOrganizationMemberDetailData } from "src/recoil/hooks/screens/useEditOrganizationMemberDetailData"
import { RootStackScreenProps } from "src/types.d"
import { MemberType } from "src/types/organization/OrganizationMember"
import { MemberTypeLabel } from "src/constants/MemberTypeLabel"
import { TeamCustomQuestion } from "src/aws/API"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { ValidationErrorMessage } from "src/components/parts/ValidationErrorMessage"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type EditOrganizationMemberDetailParams = {
  organizationId: string
  memberId: string
}

type Props = RootStackScreenProps<"EditOrganizationMemberDetail">

export const EditOrganizationMemberDetail = memo<Props>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const { organizationId, memberId } = useMemo(() => route.params, [route.params])
  const { allowEditMember, allowRoleSetting } = useCheckAllowAccountPermission({ organizationId })
  const gotoMemberDetail = useCallback(
    (imageUrl?: string) =>
      navigation.navigate(Screens.OrganizationMemberDetail, { organizationId, memberId, imageUrl, allowEditMember }),
    [navigation, organizationId, memberId, allowEditMember]
  )

  const {
    imageUrl,
    refreshImageUrl,
    organizationMemberDetail,
    nickname,
    setNickname,
    kanaNickname,
    setKanaNickname,
    kanaNicknameErrorMessage,
    memberType,
    setMemberType,
    editableMemberId,
    setEditableMemberId,
    editableMemberIdErrorMessage,
    phoneNumber,
    setPhoneNumber,
    affiliation,
    setAffiliation,
    schoolGrade,
    setSchoolGrade,
    customQuestions,
    execUpdateMemberDetail,
    isUpdatingMemberDetail,
    isDisabled,
  } = useEditOrganizationMemberDetailData({ organizationId, memberId, gotoMemberDetail })

  useEffect(() => {
    refreshImageUrl()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberId])

  return (
    <CustomKeyboardAvoidingView style={styles.container}>
      <ScrollView style={styles.main}>
        <View style={isLargeScreen ? { alignItems: "center" } : {}}>
          <View style={[isLargeScreen ? { width: 600 } : {}]}>
            <View style={styles.inner}>
              <View style={styles.topContainer}>
                <CircleThumbnail source={imageUrl ? { uri: imageUrl } : defaultThumbnail} onError={refreshImageUrl} />
              </View>
              <View style={styles.itemLabelWrapper}>
                <ItemLabel
                  label="ニックネーム"
                  required
                  RightComponent={<InputLengthCounter text={nickname} maxLength={128} unit={"字以内"} />}
                />
              </View>
              <CustomTextInput value={nickname} onChangeText={setNickname} maxLength={128} />
              <View style={styles.itemLabelWrapper}>
                <ItemLabel
                  label="フリガナ"
                  required
                  RightComponent={<InputLengthCounter text={kanaNickname} maxLength={128} unit={"字以内"} />}
                />
              </View>
              <CustomTextInput
                value={kanaNickname}
                onChangeText={setKanaNickname}
                maxLength={128}
                isError={kanaNicknameErrorMessage !== ""}
              />
              {kanaNicknameErrorMessage !== "" && (
                <ValidationErrorMessage style={styles.validationErrorMessage} message={kanaNicknameErrorMessage} />
              )}
              {allowRoleSetting && (
                <View style={styles.itemLabelWrapper}>
                  <ItemLabel label="メンバーの種類" required />
                </View>
              )}
              {allowRoleSetting && (
                <View style={styles.radioButtonWrapper}>
                  {[MemberType.Representative, MemberType.GeneralWithContactAuthorization, MemberType.General].map((type) => (
                    <View style={styles.radioButtonContainer} key={type}>
                      <RadioButton selected={memberType} value={type} label={MemberTypeLabel[type]} onPress={setMemberType} />
                    </View>
                  ))}
                </View>
              )}
              <View style={styles.itemLabelWrapper}>
                <ItemLabel
                  label="メンバー番号（数字のみ）"
                  required
                  RightComponent={<InputLengthCounter text={editableMemberId} maxLength={10} unit={"字以内"} />}
                />
              </View>
              <CustomTextInput
                value={editableMemberId}
                onChangeText={setEditableMemberId}
                maxLength={10}
                keyboardType="number-pad"
                isError={editableMemberIdErrorMessage !== ""}
              />
              {editableMemberIdErrorMessage !== "" && (
                <ValidationErrorMessage style={styles.validationErrorMessage} message={editableMemberIdErrorMessage} />
              )}
              <View style={[styles.itemLabelWrapper, styles.itemLabelWrapperEmail]}>
                <ItemLabel label="メールアドレス" />
                <Text style={styles.noteText}>（本人のみ変更可）</Text>
              </View>
              <View style={styles.notEditContainer}>
                <Text style={styles.notEditText}>{organizationMemberDetail?.email}</Text>
              </View>
              {customQuestions.includes(TeamCustomQuestion.phoneNumber) ? (
                <>
                  <View style={styles.itemLabelWrapper}>
                    <ItemLabel
                      label="連絡先電話番号"
                      RightComponent={<InputLengthCounter text={phoneNumber} maxLength={11} unit={"桁"} />}
                    />
                  </View>
                  <View style={styles.phoneNumberNoteTextContainer}>
                    <Text style={styles.phoneNumberNoteText}>ログイン認証用の携帯電話番号とは異なります。</Text>
                  </View>
                  <CustomTextInput value={phoneNumber} onChangeText={setPhoneNumber} maxLength={11} keyboardType="number-pad" />
                </>
              ) : null}
              {customQuestions.includes(TeamCustomQuestion.belongs) ? (
                <>
                  <View style={styles.itemLabelWrapper}>
                    <ItemLabel
                      label="所属（学校・会社等）"
                      RightComponent={<InputLengthCounter text={affiliation} maxLength={255} unit={"字以内"} />}
                    />
                  </View>
                  <CustomTextInput value={affiliation} onChangeText={setAffiliation} maxLength={255} />
                </>
              ) : null}
              {customQuestions.includes(TeamCustomQuestion.grade) ? (
                <>
                  <View style={styles.itemLabelWrapper}>
                    <ItemLabel label="学年" />
                  </View>
                  <CustomSelect value={schoolGrade} options={schoolGradeOptions} onChange={setSchoolGrade} />
                </>
              ) : null}
            </View>
          </View>
        </View>
      </ScrollView>
      <SingleButtonFooter
        buttonType={ButtonType.Primary}
        title="保存"
        onPress={execUpdateMemberDetail}
        isLoading={isUpdatingMemberDetail}
        disabled={isDisabled}
      />
    </CustomKeyboardAvoidingView>
  )
})

export const options: ScreenOptions = {
  ...commonSingleModalOptions,
  title: "メンバー情報編集",
  headerLeft: CloseModalHeaderLeftButton,
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  main: {
    flex: 1,
  },
  inner: {
    padding: 15,
    flex: 1,
  },
  topContainer: {
    paddingTop: 43,
    paddingBottom: 26,
    justifyContent: "center",
    alignItems: "center",
  },
  profileImage: {
    width: 122,
    height: 122,
    borderRadius: 61,
  },
  itemLabelWrapper: {
    marginTop: 20,
    marginBottom: 9,
  },
  itemLabelWrapperEmail: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  radioButtonWrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexWrap: "wrap",
    paddingLeft: 8,
  },
  radioButtonContainer: {
    marginLeft: 15,
    marginBottom: 8,
  },
  notEditContainer: {
    paddingLeft: 23,
    paddingTop: 6,
  },
  notEditText: {
    fontSize: 16,
  },
  noteText: {
    fontSize: 14,
  },
  inputLengthWithNoteCounter: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  phoneNumberNoteTextContainer: {
    marginBottom: 9,
  },
  phoneNumberNoteText: {
    fontSize: 16,
  },
  validationErrorMessage: {
    marginTop: 4,
  },
})
