import { Result } from "src/utils/Result"
import { deleteAccount as deleteAccountApi } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type DeleteAccountParams = {
  accessToken: string
  reason?: string
}

type DeleteAccountSuccess = unknown

type DeleteAccountFailure = {
  message: string
}

export const deleteAccount = async (params: DeleteAccountParams): Promise<Result<DeleteAccountSuccess, DeleteAccountFailure>> => {
  const { accessToken, reason } = params
  return execApi(
    () => deleteAccountApi(accessToken, { input: { reason }, appInfo}),
    _ => undefined,
    {
      identifiers: ["deleteAccount", "deleteAccountApi"],
      defaultErrorMessage: "会員の退会に失敗しました。",
    },
  )
}
