import { useCallback, useEffect, useMemo, useState } from "react"
import { useRequestData } from "../useRequestData"
import { useFetcher } from "src/hooks/useFetcher"
import { CustomAlert } from "src/utils/CustomAlert"
import { useNavigation } from "@react-navigation/native"
import { Screens } from "src/constants/Screens"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { RootTabs } from "src/constants/RootTabs"
import { ModeCompleted, TypeCompleted } from "src/screens/organization/CompletedApplyPaid"
import { resendEmailPaidVersionOrder } from "src/apis/organization/resendMailApplicationPaidTeam"
import { ResendEmailOrderType } from "src/aws/API"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import {
  SHOW_CONTENT_CREATE,
  SHOW_CONTENT_EXTEND,
  SHOW_CONTENT_CANCEL,
  SHOW_CONTENT_UPDATE_PLAN,
  SHOW_CONTENT_INQUIRY,
} from "src/constants/paidVersion"
import { extendDataRequestState } from "src/recoil/atoms/organization/extenDataRequestState"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { inquiryTeamPaidState } from "src/recoil/atoms/organization/inquiryTeamPaidState"
import { applicantIdState } from "src/recoil/selectors/paidTeam/applicantIdState"
import { useRefresher } from "src/hooks/useRefresher"
import { teamPermissionRequestIdState } from "src/recoil/atoms/organization/teamPermissionRequestIdState"
import { organizationDetailRequestIdState } from "src/recoil/atoms/organization/organizationDetailRequestIdState"
import { getOrderDetailSelectorFamily } from "src/recoil/selectors/paidTeam/getOrderDetailSelectorFamily"
import { getOrderDetailIdRequestState } from "src/recoil/selectors/paidTeam/getOrderDetailIdRequestState"

export const useCompletedApplicationPaidTeam = (mode: ModeCompleted, orderId: string, type: TypeCompleted, teamId?: string) => {
  const requestDataResult = useRequestData()
  const refreshPermission = useRefresher(teamPermissionRequestIdState)
  const setExtendDataRequest = useSetRecoilState(extendDataRequestState)
  const navigation = useNavigation()
  const setInquiryState = useSetRecoilState(inquiryTeamPaidState)
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const accountId = useRecoilValue(applicantIdState)
  const refreshOrganizationDetail = useRefresher(organizationDetailRequestIdState)
  const { value: orderDetailValues, isLoading } = useAsyncSelector(getOrderDetailSelectorFamily({ teamId: teamId ?? "" }))
  const [goDetail, setGoDetail] = useState(false)
  const refreshOrder = useRefresher(getOrderDetailIdRequestState)

  const goToInquiryPaidTeam = useCallback(() => {
    if (teamId == null) {
      return
    }
    refreshOrganizationDetail()
    navigation.navigate(Screens.InquiryPaidTeam, {
      organizationId: teamId,
    })
  }, [navigation, teamId, refreshOrganizationDetail])

  useEffect(() => {
    const delayGoDetail = setTimeout(() => {
      setGoDetail(true)
    }, 1500)
    return () => {
      clearTimeout(delayGoDetail)
    }
  }, [])

  const checkTypeSendMail = (type: TypeCompleted) => {
    switch (type) {
      case ResendEmailOrderType.cancel:
        return ResendEmailOrderType.cancel
      case ResendEmailOrderType.extend:
        return ResendEmailOrderType.extend
      default:
        return ResendEmailOrderType.create
    }
  }

  const { fetch: sendMailToMember, isFetching: isLoadingSendMail } = useFetcher(
    useCallback(async () => {
      if (!requestDataResult.isOk) return
      const { accessToken } = requestDataResult.content
      if (accessToken == null || orderId == null) return
      const result = await resendEmailPaidVersionOrder({
        accessToken: accessToken,
        orderId: orderId,
        type: checkTypeSendMail(type),
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.CompletedApplyPaid,
          params: {
            mode: "SUCCESS",
            orderId: orderId,
            type: type,
          },
        },
      })
    }, [requestDataResult, orderId, navigation, type])
  )

  const backToBeforeScreen = useCallback(() => {
    switch (type) {
      case "create":
        return navigation.navigate(Screens.confirmPlan, {})
      case "extend":
        return navigation.navigate(Screens.ExtendPaidTeam, {
          organizationId: teamId ?? "",
          accountId: accountId,
        })
      case "cancel":
        return navigation.navigate(Screens.cancelOrder, {
          teamMembers: selectedOrganization?.myMemberNumber ?? 0,
          accountId: accountId ?? "",
          mode: "FAIL_DB",
        })
      case "updatePlan":
        return navigation.reset({
          index: 0,
          routes: [
            {
              name: Screens.confirmUpdatePlan,
              params: {
                orderId: orderId,
              },
            },
          ],
        })
      case "inquiry":
        goToInquiryPaidTeam()
        return
      default:
        return
    }
  }, [type, navigation, teamId, accountId, selectedOrganization?.myMemberNumber, orderId, goToInquiryPaidTeam])

  const goToDetailScreen = useCallback(() => {
    if (type === "extend") {
      setExtendDataRequest({
        applicantRemark: "",
        invoiceAddressee: "",
        invoiceAddresseeKana: "",
        isSubmit: false,
        orderId: "",
        planId: "",
      })
      refreshOrder()
    }
    if (type === "cancel") {
      refreshPermission()
      return navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.OrganizationDetail,
          params: { organizationId: teamId ?? "" },
        },
      })
    }
    if (
      orderDetailValues !== undefined &&
      typeof orderDetailValues !== "string" &&
      orderDetailValues.usedOrder !== undefined &&
      goDetail
    ) {
      return navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.OrderDetail,
          params: {
            teamId: teamId ?? "",
            accountId: accountId ?? "",
            mode: type === "updatePlan" ? "updated" : undefined,
          },
        },
      })
    }
  }, [navigation, setExtendDataRequest, accountId, type, teamId, refreshPermission, orderDetailValues, goDetail, refreshOrder])

  const renderContent = useMemo(() => {
    switch (type) {
      case "extend":
        return SHOW_CONTENT_EXTEND
      case "create":
        return SHOW_CONTENT_CREATE
      case "updatePlan":
        return SHOW_CONTENT_UPDATE_PLAN
      case "inquiry":
        return SHOW_CONTENT_INQUIRY
      default:
        return SHOW_CONTENT_CANCEL
    }
  }, [type])

  const actionButtonMain = useCallback(() => {
    switch (mode) {
      case "SUCCESS":
        goToDetailScreen()
        break
      case "FAIL_DB":
        backToBeforeScreen()
        break
      case "FAIL_MAIL":
        sendMailToMember()
        break
      default:
        break
    }
  }, [mode, sendMailToMember, backToBeforeScreen, goToDetailScreen])
  return {
    isLoadingSendMail,
    actionButtonMain,
    goToInquiryPaidTeam,
    renderContent,
    setInquiryState,
    isLoading,
    orderDetailValues,
    goDetail,
  }
}
