import { useMemo } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"

export const useAuthorizationData = () => {
  const { value: account, isLoading: isAccountLoading } = useAsyncSelector(accountSelector)
  const accountId = useMemo(() => account?.id, [account])
  const [tokens, isTokensLoading] = useAsyncState(tokensState)
  const isLoading = useMemo(() => isAccountLoading || isTokensLoading, [isAccountLoading, isTokensLoading])

  return {
    ...tokens,
    accountId,
    isLoading,
  }
}
