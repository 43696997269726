import { atom } from "recoil"

export type ApplicantInformationData = {
  appCategory: string | undefined
  companyName: string | undefined
  companyNameKana: string | undefined
  representativeName: string | undefined
  companyRepresentativeNameKana: string | undefined
  phoneNumber: string | undefined
  zip: string | undefined
  address: string | undefined
  prefecture: string | undefined
  buildingName?: string | undefined
  idTeam: string | undefined
  email: string | undefined
  idAdmin: string | undefined
  teamName: string | undefined
  teamMembers: number | undefined
  adminName: string | undefined
}

export const applicantInformationDataState = atom<ApplicantInformationData>({
  key: "atoms/applicantInformationDataState",
  default: {
    appCategory: "法人",
    companyName: undefined,
    companyNameKana: undefined,
    representativeName: undefined,
    companyRepresentativeNameKana: undefined,
    phoneNumber: undefined,
    zip: undefined,
    address: undefined,
    prefecture: undefined,
    buildingName: undefined,
    idTeam: undefined,
    email: undefined,
    idAdmin: undefined,
    teamName: undefined,
    teamMembers: undefined,
    adminName: undefined,
  },
})
