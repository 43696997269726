import { memo, useMemo } from "react"
import { Image, StyleSheet, Text, View, useWindowDimensions } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ValueOf } from "src/types.d"
import { SearchIcon } from "../icons/SearchIcon"

type Props = {
  onPress: () => void
  showBadge?: boolean
}

export const SearchButton = memo<Props>(({ onPress }) => {
  return (
    <View style={[commonStyles.viewWrapper]}>
      <Button style={[commonStyles.container]} onPress={onPress}>
        <SearchIcon fillColor={Colors.orange} height={12} width={12} />
        <Text style={[commonStyles.title]}>絞り込み検索</Text>
      </Button>
    </View>
  )
})
export const SearchButtonLarge = memo<Props>(({ onPress }) => {
  const isPCScreen = useCheckPCScreen()
  return (
    <View style={[commonStyles.viewWrapperLarge]}>
      <Button style={commonStyles.btnStyle} onPress={onPress}>
        <SearchIcon fillColor={Colors.white3} height={24} width={24} />
        <Text style={[commonStyles.titlePC]}>絞り込み検索</Text>
      </Button>
    </View>
  )
})
const commonStyles = StyleSheet.create({
  viewWrapper: {
    paddingTop: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    width: `100%`,
  },
  viewWrapperLarge: {
    width: "100%",
    paddingTop: 16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.orange1,
    paddingVertical: 14,
    paddingHorizontal: 20,
    borderRadius: 8,
    width: "100%",
  },
  title: {
    marginLeft: 8,
    fontSize: 13,
    color: Colors.orange,
    fontWeight: "bold",
  },
  titlePC: {
    marginLeft: 8,
    fontSize: 18,
    color: Colors.white3,
    fontWeight: "bold",
  },
  badgeIcon: {
    marginTop: -8,
    marginLeft: 4,
    width: 10,
    height: 10,
  },
  btnStyle: {
    padding: 8,
    backgroundColor: Colors.orange,
    borderRadius: 10,
    display: "flex",
    flexDirection: "row",
  },
})
