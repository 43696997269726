import { Result } from "src/utils/Result"
import { reportTeam } from "src/aws/customAPI"
import { RequestData } from "src/types/RequestData"
import { execApi } from "src/apis/execApi"

type Params = {
  requestData: RequestData
  organizationId: string
  reportText: string
}

type Success = unknown

type Failure = {
  message: string
}

export const postOrganizationReport = async ({requestData, organizationId, reportText, ...appInfo}: Params): Promise<Result<Success, Failure>> => {
  return execApi(
    () => reportTeam(requestData.accessToken, {
      input: {
        teamId: organizationId,
        content: reportText,
        ...appInfo
      },
    }),
    _ => undefined,
    {
      identifiers: ["postOrganizationReport", "reportTeam"],
      defaultErrorMessage: "通報の送信に失敗しました。"
    }
  )
}
