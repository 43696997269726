import { memo } from "react"
import { Path, Svg, Rect } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
  width?: number
  height?: number
}

export const GoToIcon = memo<Props>(({ fill = Colors.white3, height = 36, width = 36 }) => (
  <Svg width={width} height={height} viewBox="0 0 36 37" fill="none">
    <Path d="M12.8775 25.2379L15 27.3604L24 18.3604L15 9.36035L12.8775 11.4829L19.755 18.3604L12.8775 25.2379Z" fill={fill} />
  </Svg>
))
