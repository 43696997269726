import { selector } from "recoil"
import { informationsSelector } from "src/recoil/selectors/settings/informationsSelector"
import { informationsRequestIdState } from "src/recoil/atoms/settings/informationsRequestIdState"

export const hasServiceInformationSelector = selector({
  key: "selectors/bottomTab/hasServiceInformationSelector",
  get: ({ get }) => {
    get(informationsRequestIdState)
    const informations = get(informationsSelector)
    return informations?.some((info) => !info.readFlg)
  },
})
