import { Result } from "src/utils/Result"
import { getLatestTerms as getLatestTermsApi } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import { LatestTermsModel } from "src/types/maintenanceUpdate/Terms"

type Param = {
  accessToken: string
}

type Failure = {
  message: string
}

export const getLatestTerms = async ({ accessToken }: Param): Promise<Result<LatestTermsModel, Failure>> => {
  return execApi(
    () => getLatestTermsApi(accessToken, { appInfo }),
    res => res.getLatestTerms,
    {
      identifiers: ["getLatestTerms", "getLatestTermsApi"],
      defaultErrorMessage: "規約の取得に失敗しました。",
    },
  )
}
