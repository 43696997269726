import { useCallback, useMemo, useState } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { memberInvitationSelectorFamily } from "src/recoil/selectors/organization/memberInvitationSelectorFamily"
import { CustomAlert } from "src/utils/CustomAlert"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { useFetcher } from "src/hooks/useFetcher"
import { useRequestData } from "../useRequestData"
import { sendInvitationEmail } from "src/apis/team/invitation/sendInvitationEmail"
import { validateEmail } from "src/utils/validateEmail"

export const useEmailInvitationData = (
  id: string,
  onOpenResultModal: (successCount: number, errorEmails: string[] | undefined) => void
) => {
  const [inputEmails, setInputEmails] = useState("")
  const { value: memberInvitationData, isLoading: isMemberInvitationDataLoading } = useAsyncSelector(
    memberInvitationSelectorFamily(id)
  )
  const { resourceUrl: thumbnailImageUrl } = useResource({ type: "teamImage", id, size: "thumbnail" })
  const organizationName = useMemo(() => memberInvitationData?.organizationName, [memberInvitationData])

  const emails = inputEmails.split(/\n/)
  const validateEmails = useMemo(() => emails.filter((email) => validateEmail(email) && email), [emails])
  const filterEmails = useMemo(
    () => validateEmails.filter((email, index, self) => self.indexOf(email) === index),
    [validateEmails]
  )

  const requestDataResult = useRequestData()
  const { fetch: onSubmit, isFetching: isLoadingSubmit } = useFetcher(
    useCallback(async () => {
      if (!requestDataResult.isOk) return
      const { accessToken } = requestDataResult.content
      if (accessToken == null || emails == null || id == null) return
      const result = await sendInvitationEmail({
        accessToken,
        teamId: id,
        emails: filterEmails,
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      setInputEmails("")
      let temp = filterEmails
      const difference = emails.filter((email) => {
        if (temp.includes(email)) {
          temp = temp.filter((item) => item !== email)
          return false
        }
        return true
      })
      const successCount = emails.length - difference.length
      onOpenResultModal(successCount, difference)
    }, [emails, filterEmails, id, requestDataResult, onOpenResultModal])
  )

  return {
    thumbnailImageUrl,
    organizationName,
    inputEmails,
    setInputEmails,
    onSubmit,
    isLoadingSubmit,
    isMemberInvitationDataLoading,
  }
}
