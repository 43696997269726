import { useCallback, useMemo } from "react"
import { ScrollView, StyleSheet, Text, View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"
import { SearchInput } from "./SearchInput"

type Props<T> = {
  data: { value: T; label: string }[]
  filters: T[]
  searchText?: string
  onFiltersChange: (next: T[]) => void
  onSearchTextChange?: (text: string) => void
  isApplicantAuthorityTransfer?: boolean
}

export const QueryBuilder = <T,>({
  data,
  filters,
  searchText,
  onSearchTextChange,
  onFiltersChange,
  isApplicantAuthorityTransfer = false,
}: Props<T>) => {
  const isNoFiltersSelected = useMemo(() => filters.length === 0, [filters])
  const selectFilter = useCallback(
    (value: T) => {
      const isSelected = filters.includes(value)
      const next = [...filters]
      if (isSelected) {
        onFiltersChange(next.filter((item) => item !== value))
      } else {
        next.push(value)
        onFiltersChange(next)
      }
    },
    [filters, onFiltersChange]
  )
  const selectOrUnselectAll = useCallback(() => {
    if (!isNoFiltersSelected) {
      onFiltersChange([])
      return
    }
  }, [isNoFiltersSelected, onFiltersChange])

  return (
    <View>
      <SearchInput value={searchText} onChangeText={onSearchTextChange} style={{ borderTopWidth: 0 }} />
      {!isApplicantAuthorityTransfer && (
        <ScrollView contentContainerStyle={styles.container} horizontal={true} showsHorizontalScrollIndicator={false}>
          <Button
            style={[commonButtonStyles.commonStyle, isNoFiltersSelected && styles.filterButtonSelected]}
            onPress={selectOrUnselectAll}
          >
            <Text style={[styles.buttonText, isNoFiltersSelected ? styles.buttonTextActive : styles.buttonTextInactive]}>
              すべて
            </Text>
          </Button>
          {data.map((item) => (
            <Button
              style={[commonButtonStyles.commonStyle, filters.includes(item.value) && styles.filterButtonSelected]}
              key={String(item.value)}
              onPress={() => selectFilter(item.value)}
            >
              <Text
                style={[styles.buttonText, filters.includes(item.value) ? styles.buttonTextActive : styles.buttonTextInactive]}
              >
                {item.label}
              </Text>
            </Button>
          ))}
        </ScrollView>
      )}
    </View>
  )
}

const commonButtonStyles = StyleSheet.create({
  commonStyle: {
    marginLeft: 8,
    height: 38,
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    borderWidth: 1,
    borderColor: "#ddd",
    backgroundColor: Colors.white3,
  },
})

const styles = StyleSheet.create({
  container: {
    paddingVertical: 8,
    paddingRight: 8,
  },
  buttonText: {
    fontSize: 13,
  },
  buttonTextInactive: {
    color: Colors.coolGrey,
  },
  buttonTextActive: {
    color: Colors.greyshBrown,
  },
  filterButtonSelected: {
    borderColor: Colors.orange,
    backgroundColor: Colors.pale,
  },
  searchButtonContainer: {
    backgroundColor: Colors.orange,
    borderColor: Colors.orange,
  },
})
