import { ValueOf } from "src/types.d"

export const AuthMethod = Object.freeze({
  Email: "Email",
  PhoneNumber: "PhoneNumber",
} as const)

export type AuthMethod = ValueOf<typeof AuthMethod>

export const AuthMethodLabel = {
  [AuthMethod.Email]: "メールアドレス",
  [AuthMethod.PhoneNumber]: "携帯電話番号",
}

export const AuthMethodMediaLabel = {
  [AuthMethod.Email]: "メール",
  [AuthMethod.PhoneNumber]: "SMS",
}
