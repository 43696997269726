import { atom, selector } from "recoil"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"

export const graduationYearState = atom({
  key: "atoms/account/graduationYearState",
  default: selector({
    key: "atoms/account/graduationYearState_default",
    get: ({ get }) => {
      const account = get(accountSelector)
      return account?.graduationYear
    },
  }),
})
