import * as React from "react"
import Svg, { Circle, Ellipse } from "react-native-svg"

export const SeeMoreIcon = React.memo(() => (
  <Svg width={21} height={21} viewBox="0 0 21 21" fill="none">
    <Circle cx={10.5} cy={10.5} r={10} fill="#D9D9D9" fillOpacity={0.5} stroke="#9F9999" />
    <Ellipse cx={5.68} cy={10.68} rx={1.68} ry={1.68} fill="#9F9999" />
    <Ellipse cx={10.72} cy={10.68} rx={1.68} ry={1.68} fill="#9F9999" />
    <Ellipse cx={15.76} cy={10.68} rx={1.68} ry={1.68} fill="#9F9999" />
  </Svg>
))
