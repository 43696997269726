import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FlatList, ListRenderItemInfo, StyleSheet, Text, View, useWindowDimensions } from "react-native"
import { ButtonType } from "src/components/parts/buttons/TextButton"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { MemberListItem } from "src/components/parts/organizationTab/MemberListItem"
import { QueryBuilder } from "src/components/parts/QueryBuilder"
import { SingleButtonFooter } from "src/components/parts/SingleButtonFooter"
import { Colors } from "src/constants/Colors"
import { CreateOrEditGroupScreens } from "src/constants/CreateOrEditGroupScreens"
import { CreateOrEditGroupStackScreenProps, ValueOf } from "src/types.d"
import { OrganizationMemberOverview, addMemberSelectorOverview } from "src/types/organization/OrganizationMember"
import { CustomAlert } from "src/utils/CustomAlert"
import { useGroupEditorData } from "src/recoil/hooks/organization/group/useGroupEditorData"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { useRefresher } from "src/hooks/useRefresher"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { teamMemberRequestState } from "src/recoil/atoms/team/teamMemberRequestState"
import { useRecoilState } from "recoil"
import { teamMembersState } from "src/recoil/atoms/team/teamMembersState"
import { addMemberSelectorFamily } from "src/recoil/selectors/organization/addMemberSelectorFamily"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"

export type AddMemberInGroupParams = {
  organizationId: string
  groupId?: string
}

const QueryValue = Object.freeze({
  Ungrouped: "Ungrouped",
} as const)
type QueryValue = ValueOf<typeof QueryValue>
const queryData: { value: QueryValue; label: string }[] = [{ value: QueryValue.Ungrouped, label: "グループ未所属" }]

type Props = CreateOrEditGroupStackScreenProps<typeof CreateOrEditGroupScreens.AddMemberInGroup>

export const AddMemberInGroup = memo<Props>(
  ({
    navigation,
    route: {
      params: { organizationId, groupId },
    },
  }) => {
    const widthScreen = useWindowDimensions().width
    const heightScreen = useWindowDimensions().height
    const isLargeScreen = useCheckPCScreen()
    const refresher = useRefresher(teamMemberRequestState)
    const [page, setPage] = useState(1)
    const [debouncedSearchText, setDebouncedSearchText] = useState("")
    const [organizationMembers, setOrganizationMembers] = useRecoilState(teamMembersState)
    const [selectedMembers, setSelectedMember] = useState<addMemberSelectorOverview[]>([])
    const [searchText, setSearchText] = useState("")
    const { mergedMembers, addMembers } = useGroupEditorData(groupId)
    const [filters, setFilters] = useState<QueryValue[]>([])
    const [checks, setChecks] = useState<{ [key: string]: boolean }>({})
    const checksForTheFirstTime = useRef<{ [key: string]: boolean }>({})
    const checkedCount = useMemo(() => Object.values(checks).filter((check) => check).length, [checks])
    const isSubmitPressed = useRef(false)

    const pageSize = useMemo(() => (heightScreen - 350 > 610 ? 20 : 10), [heightScreen]) // Heigh of each row = 61px => 10 row = 610

    const { value: teamMembersValue, isLoading } = useAsyncSelector(
      addMemberSelectorFamily({
        organizationId: organizationId,
        getImage: true,
        groupId: groupId ?? "",
        name: debouncedSearchText,
        limit: pageSize,
        page: page,
        excludedIds: mergedMembers.map((m) => m.id),
        mode: filters[0],
      })
    )

    const totalMember = useMemo(() => teamMembersValue?.total || 0, [teamMembersValue])
    const maxPages = useMemo(() => Math.ceil(totalMember / pageSize), [totalMember, pageSize])

    const loadMoreMembers = useCallback(() => {
      if (page < maxPages) {
        setPage((prevPage) => prevPage + 1)
      }
    }, [page, maxPages, setPage])

    const onSubmit = useCallback(() => {
      addMembers(selectedMembers.filter((member) => checks[member.id]))
      isSubmitPressed.current = true
      navigation.goBack()
    }, [addMembers, selectedMembers, navigation, checks])

    const ListHeader = useMemo(
      () => (
        <View style={styles.headerContainer}>
          <View style={styles.headerDescriptionContainer}>
            <Text style={styles.headerDescription}>追加するメンバーを選択してください</Text>
          </View>
          <View style={styles.headerCount}>
            <ItemLabel label={`メンバー ${totalMember}人`} />
          </View>
          <QueryBuilder
            data={queryData}
            searchText={searchText}
            onSearchTextChange={setSearchText}
            filters={filters}
            onFiltersChange={setFilters}
          />
        </View>
      ),
      [searchText, setSearchText, filters, setFilters, totalMember]
    )

    const toggleCheck = useCallback(
      (id: string) => {
        setChecks((prev) => {
          prev[id] = !prev[id]
          return { ...prev }
        })
        const findMember = organizationMembers.find((m) => m.id === id)
        if (findMember && !selectedMembers.find((m) => m.id === id)) {
          setSelectedMember((members) => [...members, findMember])
        }
        checksForTheFirstTime.current[id] = !checksForTheFirstTime.current[id]
      },
      [organizationMembers, selectedMembers]
    )

    const ListItem = useCallback(
      (info: ListRenderItemInfo<OrganizationMemberOverview>) => (
        <MemberListItem
          id={info.item.id}
          name={`${info.item.nickname} (${info.item.memberNumber})`}
          imageUrl={info.item.imageUrl}
          memberType={info.item.memberType}
          isLeader={info.item.isLeader}
          isSelectedForCheckbox={checks[info.item.id]}
          toggleSelectedForCheckbox={() => toggleCheck(info.item.id)}
          customSelect={true}
          isCustomImg
        />
      ),
      [checks, toggleCheck]
    )

    const ListFooter = useMemo(
      () =>
        !teamMembersValue?.total && !isLoading ? (
          <View style={styles.footerContainer}>
            <Text style={[styles.noRecordText]}>一致するメンバーはいません</Text>
          </View>
        ) : null,
      [teamMembersValue, isLoading]
    )
    useEffect(
      () =>
        navigation.addListener("beforeRemove", async (e) => {
          if (isSubmitPressed.current) {
            return
          }
          const toShowAlert = Object.values(checksForTheFirstTime.current).find((value) => value)
          if (!toShowAlert) {
            return
          }
          e.preventDefault()
          const isConfirmed = await CustomAlert.confirm(
            "確認",
            "保存ボタンを押さずに閉じると、\nデータが保存されません。このページを閉じてよろしいですか？"
          )
          isConfirmed && navigation.dispatch(e.data.action)
        }),
      [navigation]
    )
    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedSearchText(searchText)
      }, 500)
      return () => {
        clearTimeout(handler)
      }
    }, [searchText])
    useEffect(() => {
      setOrganizationMembers([])
      setPage(1)
      if (!debouncedSearchText) {
        refresher()
      }
    }, [debouncedSearchText, refresher, setOrganizationMembers, filters])

    useEffect(() => {
      if (teamMembersValue && teamMembersValue?.data) {
        setOrganizationMembers((prevMembers = []) => {
          const newMembers = teamMembersValue.data?.filter(
            (member) => !prevMembers.some((prevMember) => prevMember.id === member.id)
          )
          return [...prevMembers, ...(newMembers ?? [])]
        })
      }
    }, [teamMembersValue?.data, teamMembersValue, setOrganizationMembers])
    useEffect(() => {
      setOrganizationMembers([])
      setPage(1)
      refresher()
    }, [setOrganizationMembers, organizationId, groupId, refresher])
    return (
      <CustomKeyboardAvoidingView style={styles.container}>
        <FlatList
          data={organizationMembers}
          renderItem={ListItem}
          ListHeaderComponent={ListHeader}
          keyExtractor={(data) => `${data.id}`}
          refreshControl={<CustomRefreshControl refreshing={isLoading} onRefresh={refresher} />}
          contentContainerStyle={[isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : {}]}
          onEndReached={!isLoading ? loadMoreMembers : undefined}
          ListFooterComponent={
            isLoading ? (
              <View style={styles.loading}>
                <LoadingIndicator />
              </View>
            ) : (
              ListFooter
            )
          }
        />
        <SingleButtonFooter
          buttonType={ButtonType.Primary}
          title={checkedCount === 0 ? "メンバーが選択されていません" : `${checkedCount}人のメンバーを選択`}
          disabled={checkedCount === 0}
          onPress={onSubmit}
        />
      </CustomKeyboardAvoidingView>
    )
  }
)

const styles = StyleSheet.create({
  footerContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flex: 1,
  },
  headerCount: {
    padding: 16,
    borderTopColor: Colors.white2,
    borderTopWidth: 1,
  },
  headerContainer: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  headerDescriptionContainer: {
    paddingVertical: 18,
    justifyContent: "center",
    alignItems: "center",
  },
  headerDescription: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.warmGrey,
  },
  separator: {
    height: 1,
    backgroundColor: Colors.white2,
  },
  noRecordText: {
    marginTop: 8,
    marginLeft: 16,
    color: Colors.warmGrey,
    fontSize: 16,
  },
})
