import { ValueOf } from "src/types.d"

export const MatchingDrawers = Object.freeze({
  OtherMatchingOffer: "other_matching_offers",
  MyMatchingOffer: "my_matching_offers",
  FavoriteMatchingOffer: "favorite_matching_offers",
  MatchingOfferDetail: "matching_offer_detail",
  Applications: "applications",
  MyMatchingProfile: "my_matching_profile",
  MyMatchingDraftOffer: "my_matching_draft_offers",
  MyMatchingCreateOffer: "matching_create_offer",
} as const)

export type MatchingDrawers = ValueOf<typeof MatchingDrawers>
