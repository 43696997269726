import { StyleSheet, Text, View } from "react-native"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"

type Props = {
  teamName: string
  adminName: string
  email: string
  appCategory: string
  companyName?: string
  companyNameKana?: string
  representativeName?: string
  companyRepresentativeNameKana?: string
  phoneNumber: string | number
  zip: string | number
  prefecture: string
  address: string
  buildingName?: string
  goToEditApplicant: () => void
}

const ApplicantBlock = ({
  teamName,
  adminName,
  email,
  appCategory,
  companyName,
  companyNameKana,
  representativeName,
  companyRepresentativeNameKana,
  phoneNumber,
  zip,
  prefecture,
  address,
  buildingName,
  goToEditApplicant,
}: Props) => {
  return (
    <View style={[styles.container]}>
      <Text style={[styles.title]}>申込者情報</Text>
      <View style={[styles.subContainer]}>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="団体名" />
            <Text style={[styles.textField]}>{teamName}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="団体代表者氏名（会員名）" />
            <Text style={[styles.textField]}>{adminName}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="メールアドレス" />
            <Text style={[styles.textField]}>{email}</Text>
          </View>
        </View>
        {appCategory === "法人" && (
          <>
            <View style={[styles.row]}>
              <View style={styles.itemLabelWrapper}>
                <ItemLabel label="企業名" />
                <Text style={[styles.textField]}>{companyName}</Text>
              </View>
            </View>
            <View style={[styles.row]}>
              <View style={styles.itemLabelWrapper}>
                <ItemLabel label="企業名（フリガナ）" />
                <Text style={[styles.textField]}>{companyNameKana}</Text>
              </View>
            </View>
            <View style={[styles.row]}>
              <View style={styles.itemLabelWrapper}>
                <ItemLabel label="法人代表者名" />
                <Text style={[styles.textField]}>{representativeName}</Text>
              </View>
            </View>
            <View style={[styles.row]}>
              <View style={styles.itemLabelWrapper}>
                <ItemLabel label="法人代表者名（フリガナ）" />
                <Text style={[styles.textField]}>{companyRepresentativeNameKana}</Text>
              </View>
            </View>
          </>
        )}
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="電話番号" />
            <Text style={[styles.textField]}>{phoneNumber}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="郵便番号" />
            <Text style={[styles.textField]}>{zip}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="都道府県" />
            <Text style={[styles.textField]}>{prefecture}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="住所" />
            <Text style={[styles.textField]}>{address}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="ビル・建物名" />
            <Text style={[styles.textField]}>{buildingName}</Text>
          </View>
        </View>
        <Button style={[styles.editButton]} onPress={goToEditApplicant}>
          <Text style={[styles.textBtn]}>申込者情報の修正</Text>
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    width: "100%",
    paddingVertical: 24,
    paddingHorizontal: 16,
    maxWidth: 600,
    marginHorizontal: "auto",
  },
  subContainer: {
    alignItems: "center",
    width: "100%",
    paddingVertical: 16,
    maxWidth: 600,
    marginHorizontal: "auto",
  },
  row: {
    marginBottom: 16,
    width: "100%",
    borderBottomColor: Colors.borderColor,
    borderBottomWidth: 1,
  },
  itemLabelWrapper: {
    marginBottom: 4,
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 20,
    color: Colors.black2,
  },
  title: {
    width: "100%",
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 25.2,
    marginBottom: 16,
  },
  textField: {
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 22.4,
    marginVertical: 14,
    textAlign: "left",
    width: "100%",
  },
  editButton: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    width: "100%",
    borderRadius: 8,
    backgroundColor: "rgba(159, 134, 107, 0.10)",
  },
  textBtn: {
    fontSize: 13,
    fontWeight: "600",
    lineHeight: 18.2,
    textAlign: "center",
  },
})

export default ApplicantBlock
