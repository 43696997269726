import { memo, useCallback, useEffect, useMemo } from "react"
import { ScrollView, StyleSheet, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { schoolGradeOptions } from "src/constants/SchoolGradeOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useEditMyProfileData } from "src/recoil/hooks/screens/useEditMyProfileData"
import { RootStackScreenProps } from "src/types.d"
import { Checkbox } from "src/components/parts/Checkbox"
import { MemberTypeLabel } from "src/constants/MemberTypeLabel"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { ValidationErrorMessage } from "src/components/parts/ValidationErrorMessage"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { isEmptyString } from "src/utils/validate"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type EditMyProfileParams = {
  organizationId: string
  myTeamMemberId: string
}

type Props = RootStackScreenProps<typeof Screens.EditMyProfile>

export const EditMyProfile = memo<Props>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const { organizationId, myTeamMemberId } = useMemo(() => route.params, [route.params])
  const gotoOrganizationDetail = useCallback(
    () =>
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.OrganizationDetail,
          params: { organizationId },
        },
      }),
    [navigation, organizationId]
  )
  const {
    requirementForMemberProfile,
    memberType,
    email,
    isShowEmail,
    nickname,
    kanaNickname,
    kanaNicknameErrorMessage,
    phoneNumber,
    isShowPhoneNumber,
    affiliation,
    isShowAffiliation,
    schoolGrade,
    organizationNumber,
    memberNumber,
    update,
    isUpdating,
    setIsShowEmail,
    setNickname,
    setKanaNickname,
    setPhoneNumber,
    setIsShowPhoneNumber,
    setAffiliation,
    setIsShowAffiliation,
    setSchoolGrade,
    isLoadingSomething,
    imageUrl,
    refreshImageUrl,
    askPhoneNumber,
    askAffiliation,
    askSchoolGrade,
    areRequiredValuesFilled,
    resetState,
  } = useEditMyProfileData({ organizationId, myTeamMemberId, gotoOrganizationDetail })
  const insets = useSafeAreaInsets()

  useEffect(() => {
    return () => {
      resetState()
    }
  }, [resetState])

  return (
    <CustomKeyboardAvoidingView>
      <ScrollView>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <View style={isLargeScreen ? { width: 600 } : { width: "100%" }}>
            {requirementForMemberProfile && (
              <View style={styles.section}>
                <Text style={styles.demand}>{requirementForMemberProfile}</Text>
              </View>
            )}
            <View style={styles.thumbnailContainer}>
              <CircleThumbnail source={imageUrl ? { uri: imageUrl } : defaultThumbnail} size={80} onError={refreshImageUrl} />
            </View>
            <View style={styles.section}>
              <View style={styles.requiredItemNote}>
                <Text style={styles.requiredItemNoteText}>
                  <Text style={styles.requiredItemNoteAsterisk}>※</Text>は必須項目
                </Text>
              </View>
              <ItemLabel
                label="ニックネーム"
                required
                RightComponent={<InputLengthCounter text={nickname} maxLength={128} unit={"字以内"} />}
              />
              <CustomTextInput
                style={styles.input}
                value={nickname}
                onChangeText={setNickname}
                editable={!isUpdating}
                maxLength={128}
              />
              <ItemLabel
                label="フリガナ"
                required
                RightComponent={<InputLengthCounter text={kanaNickname} maxLength={128} unit={"字以内"} />}
              />
              <CustomTextInput
                style={kanaNicknameErrorMessage === "" ? styles.input : styles.inputWithError}
                value={kanaNickname}
                onChangeText={setKanaNickname}
                editable={!isUpdating}
                maxLength={128}
                isError={kanaNicknameErrorMessage !== ""}
              />
              {kanaNicknameErrorMessage !== "" && (
                <ValidationErrorMessage style={styles.validationErrorMessage} message={kanaNicknameErrorMessage} />
              )}
              <ItemLabel label="メンバーの種類" />
              <Text style={styles.text}>{memberType ? MemberTypeLabel[memberType] : ""}</Text>
              <ItemLabel label="メンバー番号" />
              <Text style={styles.text}>{memberNumber}</Text>
              <ItemLabel label="団体番号" />
              <Text style={styles.text}>{organizationNumber}</Text>
              <ItemLabel label="メールアドレス" />
              <Text style={styles.text}>{email}</Text>
              <View style={styles.checkbox}>
                <Checkbox isChecked={isShowEmail} onPress={() => setIsShowEmail(!isShowEmail)} />
                <Text style={styles.checkboxLabel}>一般メンバーにメールアドレスを公開する</Text>
              </View>
              {askPhoneNumber ? (
                <>
                  <ItemLabel
                    label="連絡先電話番号"
                    RightComponent={<InputLengthCounter text={phoneNumber} maxLength={11} unit={"桁"} />}
                  />
                  <CustomTextInput
                    style={styles.input}
                    value={phoneNumber}
                    onChangeText={setPhoneNumber}
                    editable={!isUpdating}
                    keyboardType="number-pad"
                    maxLength={11}
                  />
                  <View style={styles.checkbox}>
                    <Checkbox isChecked={isShowPhoneNumber} onPress={() => setIsShowPhoneNumber(!isShowPhoneNumber)} />
                    <Text style={styles.checkboxLabel}>一般メンバーに連絡先電話番号を公開する</Text>
                  </View>
                </>
              ) : null}
              {askAffiliation ? (
                <>
                  <ItemLabel
                    label="所属（学校・会社等）"
                    RightComponent={<InputLengthCounter text={affiliation} maxLength={255} unit={"字以内"} />}
                  />
                  <CustomTextInput
                    style={styles.input}
                    value={affiliation}
                    onChangeText={setAffiliation}
                    editable={!isUpdating}
                    maxLength={255}
                  />
                  <View style={styles.checkbox}>
                    <Checkbox isChecked={isShowAffiliation} onPress={() => setIsShowAffiliation(!isShowAffiliation)} />
                    <Text style={styles.checkboxLabel}>一般メンバーに所属を公開する</Text>
                  </View>
                </>
              ) : null}
              {askSchoolGrade ? (
                <View style={styles.bottomMargin}>
                  <ItemLabel label="学年" />
                  <View style={styles.input}>
                    <CustomSelect
                      options={schoolGradeOptions}
                      value={schoolGrade}
                      onChange={setSchoolGrade}
                      disabled={isUpdating}
                    />
                  </View>
                </View>
              ) : null}
            </View>
          </View>
        </View>
      </ScrollView>
      <View
        style={[
          styles.bottomAction,
          { paddingTop: 12, paddingBottom: insets.bottom + 12 },
          isLargeScreen ? { alignItems: "center" } : {},
        ]}
      >
        <TextButton
          style={isLargeScreen ? { width: 300 } : {}}
          buttonType={ButtonType.Primary}
          title="保存"
          onPress={update}
          isLoading={isUpdating}
          disabled={
            isEmptyString(nickname) ||
            isEmptyString(kanaNickname) ||
            isLoadingSomething ||
            !areRequiredValuesFilled ||
            !!kanaNicknameErrorMessage
          }
        />
      </View>
    </CustomKeyboardAvoidingView>
  )
})

export const editMyProfileOptions: ScreenOptions = {
  ...commonSingleModalOptions,
  title: "団体内個人設定の変更",
}

const styles = StyleSheet.create({
  bottomAction: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
    paddingHorizontal: 24,
  },
  requiredItemNote: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  requiredItemNoteText: {
    fontWeight: "400",
    fontSize: 14,
  },
  requiredItemNoteAsterisk: {
    color: Colors.red,
  },
  section: {
    padding: 16,
  },
  demand: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    textAlign: "center",
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.warmGrey,
  },
  thumbnailContainer: {
    alignItems: "center",
    paddingVertical: 20,
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  editButtonContainer: {
    paddingTop: 20,
  },
  editLabel: {
    marginLeft: 8,
    color: Colors.orange,
  },
  forms: {
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  text: {
    fontSize: 16,
    paddingTop: 16,
    paddingLeft: 8,
    paddingRight: 16,
    paddingBottom: 24,
    color: Colors.greyshBrown,
  },
  checkbox: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 16,
  },
  checkboxLabel: {
    marginLeft: 8,
  },
  input: {
    marginTop: 8,
    marginBottom: 16,
  },
  inputWithError: {
    marginTop: 8,
    marginBottom: 4,
  },
  validationErrorMessage: {
    marginBottom: 16,
  },
  organizationInfoContainer: {
    paddingVertical: 6,
    marginTop: 16,
    paddingLeft: 8,
  },
  row: {
    flexDirection: "row",
    paddingVertical: 6,
  },
  head: {
    width: 100,
  },
  content: {
    flex: 1,
  },
  headText: {
    fontSize: 13,
    color: Colors.warmGrey3,
  },
  contentText: {
    fontSize: 16,
    color: Colors.greyshBrown,
  },
  bottomMargin: {
    marginBottom: 50,
  },
})
