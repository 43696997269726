import { useCallback, useMemo } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { useFetcher } from "src/hooks/useFetcher"
import { affiliationStateFamily } from "src/recoil/atoms/organization/myProfile/affiliationStateFamily"
import { phoneNumberStateFamily } from "src/recoil/atoms/organization/myProfile/phoneNumberStateFamily"
import { schoolGradeStateFamily } from "src/recoil/atoms/organization/myProfile/schoolGradeStateFamily"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { CustomAlert } from "src/utils/CustomAlert"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { updateMemberDetail } from "src/apis/organization/updateMemberDetail"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { nicknameStateFamily } from "src/recoil/atoms/organization/myProfile/nicknameStateFamily"
import { kanaNicknameStateFamily } from "src/recoil/atoms/organization/myProfile/kanaNicknameStateFamily"
import { isShowEmailStateFamily } from "src/recoil/atoms/organization/myProfile/isShowEmailStateFamily"
import { isShowPhoneNumberStateFamily } from "src/recoil/atoms/organization/myProfile/isShowPhoneNumberStateFamily"
import { isShowAffiliationStateFamily } from "src/recoil/atoms/organization/myProfile/isShowAffiliationStateFamily"
import { TeamCustomQuestion } from "src/aws/API"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { useIncrementRecoilState } from "src/recoil/hooks/useIncrementRecoilState"
import { organizationDetailRequestIdState } from "src/recoil/atoms/organization/organizationDetailRequestIdState"
import { validateKanas } from "src/utils/validate"
import { useResetRecoilState } from "recoil"

type Params = {
  organizationId: string
  myTeamMemberId: string
  gotoOrganizationDetail: () => void
}

export const useEditMyProfileData = ({ organizationId, myTeamMemberId, gotoOrganizationDetail }: Params) => {
  const { value: organization, isLoading: isLoadingOrganization } = useAsyncSelector(
    organizationDetailSelectorFamily(organizationId)
  )
  const organizationNumber = useMemo(() => organization?.organizationNumber || "", [organization])
  const requirementForMemberProfile = useMemo(() => organization?.requirementForMemberProfile || "", [organization])
  const refreshOrganizationDetail = useIncrementRecoilState(organizationDetailRequestIdState)

  const { value: account, isLoading: isLoadingAccount } = useAsyncSelector(accountSelector)
  const email = useMemo(() => account?.email, [account])
  const [nickname, setNickname, isNicknameLoading] = useAsyncState(nicknameStateFamily(organizationId))
  const [kanaNickname, setKanaNickname, isKanaNicknameLoading] = useAsyncState(kanaNicknameStateFamily(organizationId))
  const kanaNicknameErrorMessage = useMemo(
    () => (validateKanas(kanaNickname || "") ? "" : "カナで入力してください。"),
    [kanaNickname]
  )
  const [isShowEmail, setIsShowEmail, isShowEmailLoading] = useAsyncState(isShowEmailStateFamily(organizationId))

  const resetNickname = useResetRecoilState(nicknameStateFamily(organizationId))
  const resetKanaNickname = useResetRecoilState(kanaNicknameStateFamily(organizationId))
  const resetShowEmail = useResetRecoilState(isShowEmailStateFamily(organizationId))

  const customQuestions = useMemo(() => organization?.customQuestions || [], [organization])
  const [phoneNumber, setPhoneNumber, isPhoneNumberLoading] = useAsyncState(phoneNumberStateFamily(organizationId))
  const [isShowPhoneNumber, setIsShowPhoneNumber, isShowPhoneNumberLoading] = useAsyncState(
    isShowPhoneNumberStateFamily(organizationId)
  )
  const askPhoneNumber = useMemo(() => customQuestions.includes(TeamCustomQuestion.phoneNumber), [customQuestions])
  const [affiliation, setAffiliation, isAffiliationLoading] = useAsyncState(affiliationStateFamily(organizationId))
  const [isShowAffiliation, setIsShowAffiliation, isShowAffiliationLoading] = useAsyncState(
    isShowAffiliationStateFamily(organizationId)
  )
  const askAffiliation = useMemo(() => customQuestions.includes(TeamCustomQuestion.belongs), [customQuestions])
  const [schoolGrade, setSchoolGrade, isSchoolGradeLoading] = useAsyncState(schoolGradeStateFamily(organizationId))
  const askSchoolGrade = useMemo(() => customQuestions.includes(TeamCustomQuestion.grade), [customQuestions])
  const { value: myProfile, isLoading: isMyProfileLoading } = useAsyncSelector(myProfileSelectorFamily(organizationId))
  const areRequiredValuesFilled = useMemo(() => !!(nickname && kanaNickname), [nickname, kanaNickname])

  const { resourceUrl: imageUrl, refreshResourceUrl: refreshImageUrl } = useResource(
    myProfile
      ? {
          type: "teamMemberImage",
          id: myProfile?.memberId,
          size: "normal",
        }
      : {
          type: "none",
        }
  )

  const memberType = useMemo(() => myProfile?.memberType, [myProfile])

  const memberNumber = useMemo(() => myProfile?.memberNumber, [myProfile])
  const requestDataResult = useRequestData()
  const { fetch: update, isFetching: isUpdating } = useFetcher(
    useCallback(async () => {
      if (!requestDataResult.isOk || !areRequiredValuesFilled || kanaNicknameErrorMessage) {
        return
      }
      const { accessToken, accountId } = requestDataResult.content
      const result = await updateMemberDetail({
        accessToken,
        accountId,
        organizationId,
        memberId: myTeamMemberId,
        nickname,
        kanaNickname,
        isShowEmail,
        newMemberId: memberNumber,
        phoneNumber,
        isShowPhoneNumber,
        affiliation,
        isShowAffiliation,
        schoolGrade,
      })

      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      await CustomAlert.alert("完了", "保存しました。")
      refreshOrganizationDetail()
      gotoOrganizationDetail()
    }, [
      requestDataResult,
      organizationId,
      myTeamMemberId,
      nickname,
      kanaNickname,
      isShowEmail,
      memberNumber,
      phoneNumber,
      isShowPhoneNumber,
      affiliation,
      isShowAffiliation,
      schoolGrade,
      gotoOrganizationDetail,
      refreshOrganizationDetail,
      areRequiredValuesFilled,
      kanaNicknameErrorMessage,
    ])
  )

  const isLoadingSomething = useMemo(
    () =>
      isNicknameLoading ||
      isKanaNicknameLoading ||
      isShowEmailLoading ||
      isMyProfileLoading ||
      isPhoneNumberLoading ||
      isShowPhoneNumberLoading ||
      isAffiliationLoading ||
      isShowAffiliationLoading ||
      isSchoolGradeLoading ||
      isLoadingOrganization ||
      isLoadingAccount,
    [
      isNicknameLoading,
      isKanaNicknameLoading,
      isShowEmailLoading,
      isMyProfileLoading,
      isPhoneNumberLoading,
      isShowPhoneNumberLoading,
      isAffiliationLoading,
      isShowAffiliationLoading,
      isSchoolGradeLoading,
      isLoadingOrganization,
      isLoadingAccount,
    ]
  )

  const resetState = useCallback(() => {
    resetNickname()
    resetKanaNickname()
    resetShowEmail()
  }, [resetNickname, resetKanaNickname, resetShowEmail])

  return {
    requirementForMemberProfile,
    memberType,
    email,
    isShowEmail,
    nickname,
    kanaNickname,
    kanaNicknameErrorMessage,
    phoneNumber,
    isShowPhoneNumber,
    affiliation,
    isShowAffiliation,
    schoolGrade,
    organizationNumber,
    memberNumber,
    update,
    isUpdating,
    setIsShowEmail,
    setNickname,
    setKanaNickname,
    setPhoneNumber,
    setIsShowPhoneNumber,
    setAffiliation,
    setIsShowAffiliation,
    setSchoolGrade,
    isLoadingSomething,
    imageUrl,
    refreshImageUrl,
    askPhoneNumber,
    askAffiliation,
    askSchoolGrade,
    areRequiredValuesFilled,
    resetState,
  }
}
