import { Result } from "src/utils/Result"
import { deleteDraft } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type DeleteContactDraftParams = {
  accessToken: string
  draftId: string
}

type DeleteContactDraftSuccess = unknown

type DeleteContactDraftFailure = {
  message: string
}

export const deleteContactDraft = async (params: DeleteContactDraftParams): Promise<Result<DeleteContactDraftSuccess, DeleteContactDraftFailure>> => {
  const {accessToken, draftId} = params

  return execApi(
    () => deleteDraft(accessToken, {
      input: {
        id: draftId
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["deleteContactDraft", "deleteDraft"],
      defaultErrorMessage: "下書きの削除に失敗しました"
    }
  )
}
