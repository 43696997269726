import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FlatList, ListRenderItemInfo, Platform, StyleSheet, Text, View, useWindowDimensions } from "react-native"
import { BorderedItemSeparator } from "src/components/parts/BorderedItemSeparator"
import { ButtonType } from "src/components/parts/buttons/TextButton"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { MemberListItem } from "src/components/parts/organizationTab/MemberListItem"
import { CustomQueryBuilder } from "src/components/parts/CustomQueryBuilder"
import { SingleButtonFooter } from "src/components/parts/SingleButtonFooter"
import { Colors } from "src/constants/Colors"
import { EditMigrateOrganizationStackScreenProps, ValueOf } from "src/types.d"
import { OrganizationMemberOverview } from "src/types/organization/OrganizationMember"
import { useGroupEditorData } from "src/recoil/hooks/organization/group/useGroupEditorData"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { useRefresher } from "src/hooks/useRefresher"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { EditMigrateOrganizationScreens } from "src/constants/EditMigrateOrganizationScreens"
import { listNotJoinMembersSelectorFamily } from "src/recoil/selectors/organization/listNotJoinMembersSelectorFamily"
import {
  listNotJoinMembersRequestIdState,
  sendNotJoinMembersState,
} from "src/recoil/atoms/organization/listNotJoinMembersRequestIdState"
import { useSetRecoilState } from "recoil"
import { organizationMembersSelectorFamily } from "src/recoil/selectors/organization/organizationMembersSelectorFamily"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"

const QueryValue = Object.freeze({
  Unjoin: "Unjoin",
  Joined: "Joined",
} as const)
type QueryValue = ValueOf<typeof QueryValue>
const queryData: { value: QueryValue; label: string }[] = [
  { value: QueryValue.Unjoin, label: "未参加" },
  { value: QueryValue.Joined, label: "参加済" },
]

export const SendMemberInMigrate = memo<
  EditMigrateOrganizationStackScreenProps<typeof EditMigrateOrganizationScreens.SendMemberInMigrate>
>(({ navigation, route }) => {
  const screenDimensions = useWindowDimensions()
  const isLargeScreen = useCheckPCScreen()
  const setSendNotJoinMembersState = useSetRecoilState(sendNotJoinMembersState)
  const refresher = useRefresher(listNotJoinMembersRequestIdState)
  const { value: membersNotInTeam, isLoading } = useAsyncSelector(
    listNotJoinMembersSelectorFamily({ organizationId: route.params.organizationId })
  )
  const { value: organizationMembers, isLoading: isJoinedMemsLoading } = useAsyncSelector(
    organizationMembersSelectorFamily({ organizationId: route.params.organizationId, getImage: true, joinFlg: true })
  )
  const sortMembers = useMemo(() => {
    return organizationMembers ? [...organizationMembers].sort((a, b) => (a.memberSeq ?? 0) - (b.memberSeq ?? 0)) : []
  }, [organizationMembers])

  const allMembers = useMemo(() => {
    return [...(sortMembers ?? []), ...(membersNotInTeam ?? [])]
  }, [membersNotInTeam, sortMembers])
  const [filters, setFilters] = useState<QueryValue[]>([QueryValue.Unjoin])
  const [searchText, setSearchText] = useState("")
  const [isFirstLoad, setFirstLoad] = useState(false)
  const [checks, setChecks] = useState<{ [key: string]: boolean }>({})
  const checkedCount = useMemo(() => Object.values(checks).filter((check) => check).length, [checks])
  const searchTextPredicate = useCallback(
    (member: OrganizationMemberOverview) => {
      return !searchText || member.nickname.includes(searchText)
    },
    [searchText]
  )
  const filteredMembers = useMemo(() => {
    let list = allMembers
    if (filters.includes(QueryValue.Unjoin)) {
      list = list.filter((m) => !m.joinFlg)
    } else if (filters.includes(QueryValue.Joined)) {
      list = list.filter((m) => m.joinFlg)
    }
    return list.filter((m) => searchTextPredicate(m))
  }, [allMembers, filters, searchTextPredicate])

  const isSubmitPressed = useRef(false)

  const onSubmit = useCallback(() => {
    const list = allMembers
      .filter((m) => checks[m.id])
      .map((m) => ({
        ...m,
      }))

    setSendNotJoinMembersState(list)
    isSubmitPressed.current = true

    navigation.navigate("MailInviteMemberToMigrateTeam", {
      organizationId: route.params.organizationId,
      nameOrg: route.params.title,
    })
  }, [allMembers, setSendNotJoinMembersState, navigation, route, checks])

  const overallActionLabel = useMemo(
    () => (checkedCount === (membersNotInTeam ?? []).length ? "すべて解除" : "すべて選択"),
    [checkedCount, membersNotInTeam]
  )

  const toggleCheckAll = useCallback(() => {
    const isCheckAll = checkedCount === (membersNotInTeam ?? []).length
    setChecks((prev) => {
      const newChecks: { [key: string]: boolean } = {}
      ;(membersNotInTeam ?? []).forEach((member) => {
        newChecks[member.id] = !isCheckAll
      })
      return { ...prev, ...newChecks }
    })
  }, [checkedCount, membersNotInTeam])

  const ListHeader = useMemo(
    () => (
      <View style={styles.headerContainer}>
        <View style={styles.popUpNoticeContainer}>
          <Text style={styles.popUpNoticeText}>
            {filters.includes(QueryValue.Unjoin)
              ? "下記のメンバーは団体に参加できていません。メンバーに参加リクエストを送りましょう。"
              : null}
            {filters.includes(QueryValue.Joined) ? "下記のメンバーは参加済みです。" : null}
            {filters.length === 0 ? "この団体の全所属メンバーです。" : null}
          </Text>
        </View>
        <View style={styles.listHeader}>
          <CustomQueryBuilder
            data={queryData}
            searchText={searchText}
            onSearchTextChange={setSearchText}
            filters={filters}
            onFiltersChange={setFilters}
            overallActionLabel={overallActionLabel}
            toggleCheckAll={toggleCheckAll}
          />
        </View>
        <View style={styles.numberMember}>
          <ItemLabel label={`メンバー ${filteredMembers.length ?? "0000"}人`} />
        </View>
      </View>
    ),
    [filters, searchText, overallActionLabel, toggleCheckAll, filteredMembers]
  )

  const toggleCheck = useCallback((id: string) => {
    setChecks((prev) => {
      prev[id] = !prev[id]
      return { ...prev }
    })
  }, [])

  const ListItem = useCallback(
    (info: ListRenderItemInfo<OrganizationMemberOverview>) => (
      <MemberListItem
        id={info.item.id}
        name={info.item.nickname}
        imageUrl={info.item.imageUrl}
        memberType={info.item.memberType}
        isLeader={info.item.isLeader}
        isSelectedForCheckbox={filters.includes(QueryValue.Unjoin) ? checks[info.item.id] : undefined}
        toggleSelectedForCheckbox={filters.includes(QueryValue.Unjoin) ? () => toggleCheck(info.item.id) : undefined}
        isJoined={filters.length === 0 ? info.item.joinFlg : undefined}
        customSelect={true}
      />
    ),
    [checks, filters, toggleCheck]
  )

  useEffect(() => {
    if (route.params.title) {
      navigation.setOptions({
        headerTitle: route.params.title,
      })
    }
    if (!isLoading && !isJoinedMemsLoading && !isFirstLoad) {
      setFirstLoad(true)
    }
  }, [navigation, route, isJoinedMemsLoading, isLoading, isFirstLoad])

  if (!filteredMembers) {
    return null
  }
  return (
    <CustomKeyboardAvoidingView style={styles.container}>
      <FlatList
        data={filteredMembers}
        renderItem={ListItem}
        ListHeaderComponent={ListHeader}
        keyExtractor={(data) => `${data.id}`}
        extraData={allMembers}
        refreshControl={
          isFirstLoad ? <CustomRefreshControl refreshing={isLoading && isJoinedMemsLoading} onRefresh={refresher} /> : undefined
        }
        contentContainerStyle={[isLargeScreen ? { paddingHorizontal: (screenDimensions.width - 600) / 2 } : {}]}
        ListEmptyComponent={
          isLoading || isJoinedMemsLoading ? (
            <View style={[styles.rowCenter, { height: screenDimensions.height - 350 }]}>
              <LoadingIndicator />
            </View>
          ) : null
        }
      />
      {filters.includes(QueryValue.Unjoin) ? (
        <SingleButtonFooter
          buttonType={ButtonType.Primary}
          title="参加リクエストを送信"
          disabled={checkedCount === 0}
          onPress={onSubmit}
        />
      ) : null}
    </CustomKeyboardAvoidingView>
  )
})

SendMemberInMigrate.displayName = "SendMemberInMigrate"

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  headerContainer: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  headerDescriptionContainer: {
    paddingVertical: 18,
    justifyContent: "center",
    alignItems: "center",
  },
  headerDescription: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.warmGrey,
  },
  separator: {
    height: 1,
    backgroundColor: Colors.white2,
  },
  listHeader: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  numberMember: {
    paddingVertical: 16,
    paddingHorizontal: 20,
  },
  popUpNoticeContainer: {
    flexDirection: "row",
    marginTop: 24,
    paddingHorizontal: 15,
    justifyContent: "center",
  },
  popUpNoticeText: {
    color: Colors.black,
    marginLeft: 8,
    paddingBottom: 20,
    textAlign: "center",
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
})
