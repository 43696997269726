import { Props } from "./Props"
import Select, { SingleValue } from "react-select"
import { useCallback, useMemo } from "react"
import { Colors } from "src/constants/Colors"

const height = 50

export const CustomSelect = <T extends string | number | boolean>(props: Props<T>) => {
  const { options, placeholder = "選択してください", disabled, isLoading, isError } = props
  const defaultValue = useMemo(() => ({ value: undefined, label: placeholder }), [placeholder])
  const selected = useMemo(
    () => options.find((opt) => opt.value === props.value) ?? defaultValue,
    [options, props.value, defaultValue]
  )
  const update = useCallback(
    (next: SingleValue<{ value?: T; label: string }>) => {
      if (props.required) {
        if (next?.value == null) {
          return
        }
        props.onChange(next.value)
      } else {
        props.onChange(next?.value)
      }
    },
    [props]
  )
  const opts = useMemo(() => {
    const head = props.required ? [] : [defaultValue]
    return [...head, ...options]
  }, [props, options, defaultValue])

  return (
    <Select<{ value?: T; label: string }>
      options={opts}
      value={selected}
      onChange={update}
      isSearchable={false}
      isLoading={isLoading}
      styles={{
        control: (provided: any) => {
          return {
            ...provided,
            borderColor: isError ? Colors.red : Colors.lightGrey,
            borderWidth: 2,
            borderRadius: 8,
            height,
            minHeight: height,
            boxShadow: "none",
          }
        },
        menu: (provided: any) => ({
          ...provided,
          zIndex: 10000,
        }),
        indicatorsContainer: (provided: any) => {
          return { ...provided, padding: "0 16px" }
        },
        indicatorSeparator: () => {
          return { display: "none" }
        },
        valueContainer: (provided) => {
          return { ...provided }
        },
      }}
      menuPortalTarget={document.body}
      menuPosition="fixed"
      menuShouldBlockScroll
      isDisabled={disabled}
      components={{
        DropdownIndicator: () => (
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7.4" viewBox="0 0 12 7.4">
            <path data-name="↳Color" d="M10.59 0 6 4.574 1.41 0 0 1.408 6 7.4l6-5.992z" fill="#f0830e" />
          </svg>
        ),
      }}
      placeholder={placeholder}
    />
  )
}

CustomSelect.displayName = "CustomSelect"
