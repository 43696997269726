import { deleteResource as deleteResourceApi, ResourceDeleteParams } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
} & ResourceDeleteParams

type Response = {
  id: string
}

export const deleteResource = async ({accessToken, ...rest}: Params): Promise<Response | undefined> => {
  const result = await execApi(
    () => deleteResourceApi(accessToken, { ...rest, appInfo }),
    res => ({id: res.id}),
    {
      identifiers: ["deleteResource", "deleteResourceApi"],
    }
  )

  return result.isOk ? result.content : undefined
}
