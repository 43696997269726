import { ComponentProps, memo } from "react"
import { StyleSheet, Text, TextInput, View } from "react-native"
import { Colors } from "src/constants/Colors"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

type Props = {
  label: string
  phoneNumber: string
  onPhoneNumberChange: ComponentProps<typeof TextInput>["onChangeText"]
  isDisabled?: boolean
  isSending?: boolean
  sendAuthCode: () => void
  gotoLoginEmail: () => void
}

export const EnterPhoneNumber = memo<Props>(
  ({ label, phoneNumber, onPhoneNumberChange, isDisabled, isSending, sendAuthCode, gotoLoginEmail }) => {
    const isLargeScreen = useCheckPCScreen()

    return (
      <View style={styles.container}>
        <View style={isLargeScreen ? styles.containerPC : { width: "100%" }}>
          <View style={[styles.form, styles.separator]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel
                size={16}
                label={label}
                RightComponent={<InputLengthCounter text={phoneNumber} maxLength={11} unit={"桁"} />}
              />
            </View>
            <View style={styles.inputContainer}>
              <CustomTextInput
                value={phoneNumber}
                onChangeText={onPhoneNumberChange}
                placeholder="入力してください"
                placeholderTextColor={Colors.white6}
                editable={!isSending}
                onSubmitEditing={sendAuthCode}
                keyboardType="number-pad"
                maxLength={11}
              />
            </View>
          </View>
          <View style={[isLargeScreen ? { display: "flex", alignItems: "center" } : {}]}>
            <View style={[styles.actions, isLargeScreen ? styles.buttonsPC : {}]}>
              <TextButton
                buttonType={ButtonType.Primary}
                title="認証コードを送信"
                onPress={sendAuthCode}
                disabled={isDisabled}
                isLoading={isSending}
              />
            </View>
          </View>
          <View style={[styles.badgeContainer, styles.separator]}>
            <View style={styles.badge}>
              <Text style={styles.badgeText}>※認証コードの有効期限は3分です</Text>
            </View>
          </View>
          <View style={styles.separator}>
            <Text onPress={gotoLoginEmail} style={styles.alternative}>
              メールアドレスでログインする方はこちら
            </Text>
          </View>
        </View>
      </View>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.beige,
    alignItems: "center",
    width: "100%",
  },
  containerPC: {
    display: "flex",
    alignItems: "center",
    width: 600,
    maxWidth: 1020,
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  inputContainer: {
    paddingTop: 10,
  },
  form: {
    paddingBottom: 24,
    paddingHorizontal: 17,
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  actions: {
    padding: 24,
  },
  badgeContainer: {
    flexDirection: "row",
    justifyContent: "center",
    paddingBottom: 48,
  },
  badge: {
    height: 22,
    borderRadius: 11,
    borderWidth: 1,
    borderColor: Colors.turtleGreen,
    alignSelf: "flex-start",
    justifyContent: "center",
    paddingHorizontal: 12,
    fontSize: 12,
  },
  badgeText: {
    color: Colors.turtleGreen,
  },
  caveats: {
    padding: 24,
  },
  itemLabelWrapper: {
    marginTop: 20,
  },
  link: {
    color: Colors.orange,
  },
  alternative: {
    alignSelf: "center",
    marginVertical: 16,
    color: Colors.orange,
    fontWeight: "bold",
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
})
