import { StyleSheet, Text, View } from "react-native"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import dayjs from "dayjs"
type Props = {
  companyName: string
  maxMembers: string
  limitTime: string
  createdTime: string
  price: string
  invoiceAddress: string
  invoiceKanaAddress: string
  startTimeConvert: string
  remark: string
  planName: string
  orderNumber: string | number
}

const PlanUpdateConfirmBlock = ({
  orderNumber,
  maxMembers,
  createdTime,
  limitTime,
  remark,
  startTimeConvert,
  price,
  invoiceAddress,
  planName,
}: Props) => {
  const convertTimeString = (inputTime: string) => {
    return dayjs(inputTime).format("YYYY年MM月DD日")
  }

  const formatEndDate = (month: string, starDate: string): string => {
    const baseDate: dayjs.Dayjs = dayjs(starDate)
    const newDate: dayjs.Dayjs = baseDate.add(parseInt(month) - 1, "month")
    const endOfMonth: dayjs.Dayjs = newDate.endOf("month")
    const result: string = endOfMonth.format("YYYY年MM月DD日まで")
    return result
  }

  return (
    <View style={[styles.container]}>
      <Text style={[styles.title]}>団体有料版申込情報</Text>
      <View style={[styles.subContainer]}>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="受注番号" />
            <Text style={[styles.textField]}>{orderNumber}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="プラン" />
            <Text style={[styles.textField]}>{planName}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="人数上限" />
            <Text style={[styles.textField]}>{maxMembers + "名"}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="利用期間" />
            <Text style={[styles.textField]}>{`${limitTime + "カ月"} (${
              limitTime && startTimeConvert ? formatEndDate(limitTime, startTimeConvert) : ""
            })`}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="申込日" />
            <Text style={[styles.textField]}>{convertTimeString(createdTime)}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="利用開始日" />
            <Text style={[styles.textField]}>{convertTimeString(startTimeConvert)}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="料金（税込）" />
            <Text style={[styles.textField]}>{price + "円"}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="請求書の宛名[振込名義]" />
            <Text style={[styles.textField]}>{invoiceAddress}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="入金状況" />
            <Text style={[styles.textRed]}>入金前</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="備考欄" />
            <Text style={[styles.textField]}>{remark}</Text>
          </View>
        </View>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    width: "100%",
    paddingVertical: 24,
    paddingHorizontal: 16,
    maxWidth: 600,
    marginHorizontal: "auto",
  },
  subContainer: {
    alignItems: "center",
    width: "100%",
    paddingVertical: 16,
    maxWidth: 600,
    marginHorizontal: "auto",
  },
  textRed: {
    paddingVertical: 14,
    fontSize: 16,
    fontWeight: "600",
    lineHeight: 22.4,
    color: Colors.red,
  },
  row: {
    marginBottom: 16,
    width: "100%",
    borderBottomColor: Colors.borderColor,
    borderBottomWidth: 1,
  },
  itemLabelWrapper: {
    marginBottom: 4,
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 20,
    color: Colors.black2,
  },
  title: {
    width: "100%",
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 25.2,
    marginBottom: 16,
  },
  textField: {
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 22.4,
    marginVertical: 14,
    textAlign: "left",
    width: "100%",
  },
  editButton: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    width: "100%",
    borderRadius: 8,
    backgroundColor: "rgba(159, 134, 107, 0.10)",
  },
  textBtn: {
    fontSize: 13,
    fontWeight: "600",
    lineHeight: 18.2,
    textAlign: "center",
  },
})

export default PlanUpdateConfirmBlock
