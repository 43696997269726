import { ValueOf } from "src/types.d"

export const SchoolGrade = Object.freeze({
  FirstYearElementarySchool: 1,
  SecondYearElementarySchool: 2,
  ThirdYearElementarySchool: 3,
  FourthYearElementarySchool: 4,
  SixthYearElementarySchool: 5,
  FifthYearElementarySchool: 6,
  FirstYearJuniorHighSchool: 7,
  SecondYearJuniorHighSchool: 8,
  ThirdYearJuniorHighSchool: 9,
  FirstYearHighSchool: 10,
  SecondYearHighSchool: 11,
  ThirdYearHighSchool: 12,
} as const)

export type SchoolGrade = ValueOf<typeof SchoolGrade>

export const SchoolGradeLabels = {
  [SchoolGrade.FirstYearElementarySchool]: "小1",
  [SchoolGrade.SecondYearElementarySchool]: "小2",
  [SchoolGrade.ThirdYearElementarySchool]: "小3",
  [SchoolGrade.FourthYearElementarySchool]: "小4",
  [SchoolGrade.SixthYearElementarySchool]: "小5",
  [SchoolGrade.FifthYearElementarySchool]: "小6",
  [SchoolGrade.FirstYearJuniorHighSchool]: "中1",
  [SchoolGrade.SecondYearJuniorHighSchool]: "中2",
  [SchoolGrade.ThirdYearJuniorHighSchool]: "中3",
  [SchoolGrade.FirstYearHighSchool]: "高1",
  [SchoolGrade.SecondYearHighSchool]: "高2",
  [SchoolGrade.ThirdYearHighSchool]: "高3",
}

export const schoolGradeOptions = Object.values(SchoolGrade).map((value) => ({
  value,
  label: SchoolGradeLabels[value],
}))
