import { format } from "date-fns"
import { ja } from "date-fns/locale"
import dayjs from "dayjs"
import "dayjs/locale/ja"

dayjs.locale("ja")

export const UseFormatDatetime = (dateTime: Date) => {
  const beforeThisYearFlg = dayjs(dateTime).isBefore(dayjs(new Date()), "year")
  const beforeTodayFlg = dayjs(dateTime).isBefore(dayjs(new Date()), "day")
  if (beforeThisYearFlg) return dayjs(dateTime).format("YYYY/MM/DD(dd)HH:mm")
  if (beforeTodayFlg) return dayjs(dateTime).format("MM/DD(dd)HH:mm")
  const now = new Date()
  if (
    dateTime.getDate() === now.getDate() &&
    dateTime.getMonth() === now.getMonth() &&
    dateTime.getFullYear() === now.getFullYear()
  )
    return `今日 ${dayjs(dateTime).format("HH:mm")}`
}

export const UseReservedDateTime = (dateTime: Date) => {
  const afterThisYearFlg = dayjs(dateTime).isAfter(dayjs(new Date()), "year")
  const now = new Date()
  if (afterThisYearFlg) {
    return dayjs(dateTime).format("YYYY/MM/DD(dd)HH:mm")
  } else if (
    dateTime.getDate() === now.getDate() &&
    dateTime.getMonth() === now.getMonth() &&
    dateTime.getFullYear() === now.getFullYear()
  ) {
    return `今日 ${dayjs(dateTime).format("HH:mm")}`
  } else {
    return dayjs(dateTime).format("MM/DD(dd)HH:mm")
  }
}

export const UseAnsweredDateTime = (dateTime: Date) => {
  return dayjs(dateTime).format("YYYY/MM/DD HH:mm")
}

// export const UseDate = (date: Date) => {
//   return format(date, "MM/dd(E)", { locale: ja })
// }

export const UseSearchedDate = (date: Date) => {
  return format(date, "yyyy/MM/dd", { locale: ja })
}

export const ShowDate = (date?: Date | null, start?: any, end?: any, noOption?: boolean) => {
  let option = ""
  if (!start && !end && noOption !== true) {
    option = date ? " 終日" : "未定"
  }
  if (!date) return "" + option
  const weekday = ["日", "月", "火", "水", "木", "金", "土"]
  const nowDay = dayjs()
  const formattedDate = dayjs(date)

  if (formattedDate.isSame(nowDay, "day")) {
    return `今日` + option
  } else if (formattedDate.year() === nowDay.year()) {
    return `${formattedDate.format("MM/DD")} (${weekday[formattedDate.day()]})` + option
  } else {
    return `${formattedDate.format("YYYY/MM/DD")} (${weekday[formattedDate.day()]})` + option
  }
}

export const getToday = () => {
  const datetime = new Date()
  const today = format(new Date(datetime.toString()), "yyyy-MM-dd", { locale: ja })
  return { today }
}

export const dateComparison = (datetime: Date | undefined, inputDate: Date | undefined) => {
  if (datetime == null || inputDate == null) return
  const todayYear = datetime.getFullYear()
  const todayMonth = datetime.getMonth() + 1
  const todayDay = datetime.getDate()

  const inputYear = inputDate.getFullYear()
  const inputMonth = inputDate.getMonth() + 1
  const inputDay = inputDate.getDate()

  if (todayYear == inputYear) {
    if (todayMonth == inputMonth) {
      return todayDay > inputDay
    } else {
      return todayMonth > inputMonth
    }
  } else {
    return todayYear > inputYear
  }
}

export const sameDayComparison = (datetime: Date | undefined, inputDate: Date | undefined) => {
  if (datetime == null || inputDate == null) return
  const todayYear = datetime.getFullYear()
  const todayMonth = datetime.getMonth() + 1
  const todayDay = datetime.getDate()

  const inputYear = inputDate.getFullYear()
  const inputMonth = inputDate.getMonth() + 1
  const inputDay = inputDate.getDate()

  return todayYear == inputYear && todayMonth == inputMonth && todayDay === inputDay
}

export const UseIOSString = (datetime: Date) => {
  const pad = (str: string) => {
    return ("0" + str).slice(-2)
  }
  const year = datetime.getFullYear().toString()
  const month = pad((datetime.getMonth() + 1).toString())
  const day = pad(datetime.getDate().toString())
  const hour = pad(datetime.getHours().toString())
  const min = pad(datetime.getMinutes().toString())
  const sec = pad(datetime.getSeconds().toString())
  const tz = -datetime.getTimezoneOffset()
  const sign = tz >= 0 ? "+" : "-"
  const tzHour = pad((tz / 60).toString())
  const tzMin = pad((tz % 60).toString())
  return `${year}-${month}-${day}T${hour}:${min}:${sec}${sign}${tzHour}:${tzMin}`
}

export const convertDate = (date: string) => {
  if (!date) return ""
  const formattedDate = dayjs(date)
  return `${formattedDate.format("YYYY/MM/DD")} ${formattedDate.format(" HH:mm")}`
}
