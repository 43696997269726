import { memo } from "react"
import { QueryBuilder } from "src/components/parts/QueryBuilder"
import { ContactFilterType, ContactFilterValue } from "src/types/contact/ContactFilter"
import { ContactTypeLabel } from "src/constants/ContactType"

const filterItems: { [key in ContactFilterType]: { value: ContactFilterValue; label: string }[] } = {
  [ContactFilterType.Inbox]: [
    { value: ContactFilterValue.Unread, label: "未読" },
    { value: ContactFilterValue.Unanswered, label: "未回答" },
    { value: ContactFilterValue.WithReply, label: "返信あり" },
    { value: ContactFilterValue.Favorite, label: "お気に入り" },
    { value: ContactFilterValue.WithAttachment, label: "添付ファイルあり" },
    { value: ContactFilterValue.NormalContact, label: ContactTypeLabel["Normal"] },
    { value: ContactFilterValue.AttendanceContact, label: ContactTypeLabel["Attendance"] },
    { value: ContactFilterValue.SurveyContact, label: ContactTypeLabel["Survey"] },
    { value: ContactFilterValue.ScheduleAdjustmentContact, label: ContactTypeLabel["ScheduleAdjustment"] },
  ],
  [ContactFilterType.Outbox]: [
    { value: ContactFilterValue.Favorite, label: "お気に入り" },
    { value: ContactFilterValue.WithAttachment, label: "添付ファイルあり" },
    { value: ContactFilterValue.WithReply, label: "返信あり" },
    { value: ContactFilterValue.NormalContact, label: ContactTypeLabel["Normal"] },
    { value: ContactFilterValue.AttendanceContact, label: ContactTypeLabel["Attendance"] },
    { value: ContactFilterValue.SurveyContact, label: ContactTypeLabel["Survey"] },
    { value: ContactFilterValue.ScheduleAdjustmentContact, label: ContactTypeLabel["ScheduleAdjustment"] },
  ],
  [ContactFilterType.Template]: [{ value: ContactFilterValue.Favorite, label: "お気に入り" }],
  [ContactFilterType.Migration]: [{ value: ContactFilterValue.Favorite, label: "お気に入り" }],
}

type Props = {
  filterType: ContactFilterType
  filters: ContactFilterValue[]
  onFiltersChange: (next: ContactFilterValue[]) => void
  searchText: string
  onSearchTextChange: (text: string) => void
}

export const ContactQueryBuilder = memo<Props>(({ filterType, filters, searchText, onFiltersChange, onSearchTextChange }) => (
  <QueryBuilder
    data={filterItems[filterType]}
    filters={filters}
    searchText={searchText}
    onFiltersChange={onFiltersChange}
    onSearchTextChange={onSearchTextChange}
  />
))
