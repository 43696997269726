import { selector } from "recoil"
import { canDeleteAccount, CanDeleteAccountResponse } from "src/aws/customAPI"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"

export const canDeleteAccountSelector = selector<CanDeleteAccountResponse | undefined>({
  key: "selectors/account/canDeleteAccountSelector",
  get: async ({ get }) => {
    const tokens = get(tokensState)
    if (tokens == null) return undefined
    const response = await canDeleteAccount(tokens.accessToken).then((res) => res.data)
    if (response == null) return
    return response
  },
})
