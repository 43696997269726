import { useCallback, useEffect, useRef, useState } from "react"

export function useFetcher<T>(query: () => Promise<T>): {
  fetch: () => Promise<T>
  isFetching: boolean
  data?: T
  error: unknown
}

export function useFetcher<T, U>(
  query: (params: U) => Promise<T>
): { fetch: (params: U) => Promise<T>; isFetching: boolean; data?: T; error: unknown }

export function useFetcher<T, U>(query: (params?: U) => Promise<T>) {
  const existingRef = useRef(true)
  const [isFetching, setIsFetching] = useState(false)
  const [data, setData] = useState<T>()
  const [error, setError] = useState<unknown>()
  const fetch = useCallback(
    async (params?: U) => {
      let dataBuffer: T | undefined
      let errorBuffer: unknown | undefined
      try {
        setIsFetching(true)
        const result = await query(params)
        dataBuffer = result
      } catch (error) {
        errorBuffer = error
      } finally {
        if (existingRef.current === true) {
          setData(dataBuffer)
          setError(errorBuffer)
          setIsFetching(false)
        }
      }
    },
    [query]
  )

  useEffect(() => {
    return () => {
      existingRef.current = false
    }
  }, [])

  return {
    fetch,
    isFetching,
    data,
    error,
  }
}
