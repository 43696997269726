import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { useMemo } from "react"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { contactDetailSelectorFamily } from "src/recoil/selectors/contact/contactDetailSelectorFamily"
import { MemberType } from "src/types/organization/OrganizationMember"

type Params = {
  organizationId?: string
  contactId?: string
  type?: "Inbox" | "Outbox" | "Reserved" | "Migration"
}

export const useAuthorization = ({ organizationId, contactId, type }: Params) => {
  const { value: organization, isLoading: _isLoadingOrganization } = useAsyncSelector(
    organizationDetailSelectorFamily(organizationId)
  )
  const { value: myProfile, isLoading: _isLoadingMyProfile } = useAsyncSelector(myProfileSelectorFamily(organizationId))
  const { value: contactDetail, isLoading: _isLoadingContactDetail } = useAsyncSelector(
    contactDetailSelectorFamily({
      // Inbox, Outbox以外のtypeが明示された場合はcontact取得できないためundefinedとする
      contactId: type == null || type === "Inbox" || type === "Outbox" ? contactId : undefined,
      type: type || "Inbox",
    })
  )
  const memberType = useMemo(() => myProfile?.memberType, [myProfile])
  const isMemberPublic = useMemo(() => organization?.isMembersPublic, [organization])
  // 団体管理者でない場合は、groupは自身がリーダーのもののみに絞られる
  const groups = useMemo(() => organization?.groups, [organization])
  const isGroupLeader = useMemo(() => (groups != null ? groups.length > 0 : undefined), [groups])

  const role: MemberType | "GroupLeader" | "GroupLeaderWithContactAuth" | undefined = useMemo(() => {
    if (isGroupLeader && (myProfile?.memberType === "General" || myProfile?.memberType === "GeneralWithContactAuthorization")) {
      return myProfile.memberType === "General" ? "GroupLeader" : "GroupLeaderWithContactAuth"
    } else {
      return myProfile?.memberType
    }
  }, [myProfile?.memberType, isGroupLeader])
  const isSender = useMemo(
    () => myProfile?.memberId === contactDetail?.sender.memberId,
    [myProfile?.memberId, contactDetail?.sender.memberId]
  )

  // =========== 以下、 団体管理系の権限 ===========

  const allowEditOrganizationDetail = useMemo(() => memberType === "Representative", [memberType])

  const allowViewMembers = useMemo(
    () => memberType === "Representative" || memberType === "GeneralWithContactAuthorization" || isMemberPublic,
    [memberType, isMemberPublic]
  )

  const allowViewPendingInvitations = useMemo(() => memberType === "Representative", [memberType])

  const allowCreateOrEditGroup = useMemo(() => memberType === "Representative", [memberType])

  const allowDeleteTeam = useMemo(() => memberType === "Representative", [memberType])

  const allowInviteMember = useMemo(() => memberType === "Representative", [memberType])

  const allowEditOrDeleteMember = useMemo(() => memberType === "Representative", [memberType])

  // ========================================

  // =========== 以下、 連絡系の権限 ===========

  const allowCreateContact = useMemo(
    () =>
      memberType != null && groups != null
        ? memberType === "Representative" || memberType === "GeneralWithContactAuthorization" || groups.length > 0
        : undefined,
    [memberType, groups]
  )

  const allowGoToOutbox = useMemo(() => allowCreateContact, [allowCreateContact])

  const allowGoToDraft = useMemo(() => allowCreateContact, [allowCreateContact])

  const allowGoToTemplate = useMemo(() => allowCreateContact, [allowCreateContact])

  const allowGoToMigrationBox = useMemo(() => memberType === "Representative", [memberType])

  const allowViewMemberAnswers = useMemo(() => isSender, [isSender])

  const allowSendReminder = useMemo(() => isSender, [isSender])

  const allowSendToAllMembers = useMemo(() => memberType !== "General", [memberType])

  const allowSendToAdmins = useMemo(() => memberType !== "General", [memberType])

  const allowTeamMatching = useMemo(() => memberType === "Representative", [memberType])

  /**
   * 宛先のテキストのタップ可否
   * trueの場合は、タップすると宛先のメンバー一覧が表示される。(一覧に表示されるメンバーは、API側で権限制御)
   * falseの場合は、タップしても何もおこらない
   */
  const allowViewReceiverList = useMemo(() => {
    if (organization == null) return false
    return organization.isMembersPublic || role !== "General"
  }, [organization, role])

  /**
   * 読了結果ボタンのタップ可否
   * trueの場合は、読了または未読をタップすると読了/未読のメンバー一覧が表示される。(一覧に表示されるメンバーは、API側で権限制御)
   * falseの場合は、タップしても何もおこらない(件数自体は表示される)
   */
  const allowViewReadUnreadMemberList = useMemo(() => allowViewReceiverList, [allowViewReceiverList])

  /**
   * 回答状況ボタンのタップ可否
   * trueの場合は、回答済または未回答をタップすると回答済/未回答のメンバー一覧が表示される。(一覧に表示されるメンバーは、API側で権限制御)
   * falseの場合は、回答状況を非表示
   */
  const allowViewAnsweredUnansweredMemberList = useMemo(() => allowViewReceiverList, [allowViewReceiverList])

  /**
   * 「回答結果を見る」ボタンの表示可否
   * trueの場合は、「回答結果を見る」ボタンが表示され、タップで「回答結果」画面に遷移可能
   */
  const allowViewAnswerSummary = useMemo(() => {
    if (
      !(
        contactDetail?.contactType === "Attendance" ||
        contactDetail?.contactType === "Survey" ||
        contactDetail?.contactType === "ScheduleAdjustment"
      )
    )
      return false

    if (
      contactDetail.isPublishAnswersToMembers ||
      myProfile?.memberId === contactDetail.sender.memberId ||
      role === "Representative"
    )
      return true
    return false
  }, [contactDetail, role, myProfile])

  /**
   * 「回答結果」画面で件数をタップした際のメンバー一覧の表示可否
   * 可の場合は、メンバー一覧にはAPIからのレスポンスがundefinedでないメンバーが表示される
   * 不可の場合は、「回答結果」画面で件数は表示されるがタップ不可
   */
  const allowViewAnswerMemberList = useMemo(() => {
    if (
      !(
        contactDetail?.contactType === "Attendance" ||
        contactDetail?.contactType === "Survey" ||
        contactDetail?.contactType === "ScheduleAdjustment"
      )
    )
      return false
    if (organization == null || role == null) return false

    if (organization.isMembersPublic) {
      if (!contactDetail.isPublishAnswersToMembers) {
        if (role === "GeneralWithContactAuthorization") {
          if (type === "Inbox") {
            return false
          }
        } else if (role === "General") {
          return false
        }
      }
    } else {
      if (contactDetail.isPublishAnswersToMembers) {
        if (role === "General") {
          return false
        }
      } else {
        if (role === "GeneralWithContactAuthorization") {
          if (type === "Inbox") {
            return false
          }
        } else if (role === "General") {
          return false
        }
      }
    }

    return true
  }, [organization, contactDetail, role, type])

  /**
   * 「回答結果」画面上で、APIから返却されるundefinedなメンバーも件数に含めるかどうか
   * ※ メンバー一覧には、undefinedなメンバーは表示されない
   *
   * 例:
   *   権限: グループリーダー
   *   回答者: 10人、内自身がリーダーのグループメンバー: 3人
   * の時、trueの場合は、10が表示され、falseの場合は3が表示される
   */
  const allowCountUndefinedMemberAnswers = useMemo(() => {
    if (organization == null || contactDetail == null) return false
    if (
      !(
        contactDetail?.contactType === "Attendance" ||
        contactDetail?.contactType === "Survey" ||
        contactDetail?.contactType === "ScheduleAdjustment"
      )
    )
      return false

    return (
      !organization.isMembersPublic && contactDetail.isPublishAnswersToMembers && (role === "General" || role === "GroupLeader")
    )
  }, [organization, contactDetail, role])

  const isLoading = useMemo(
    () => _isLoadingOrganization || _isLoadingMyProfile || _isLoadingContactDetail,
    [_isLoadingOrganization, _isLoadingMyProfile, _isLoadingContactDetail]
  )

  return {
    allowEditOrganizationDetail,
    allowViewMembers,
    allowViewPendingInvitations,
    allowCreateOrEditGroup,
    allowDeleteTeam,
    allowInviteMember,
    allowEditOrDeleteMember,
    allowCreateContact,
    allowGoToOutbox,
    allowGoToDraft,
    allowGoToTemplate,
    allowGoToMigrationBox,
    allowViewMemberAnswers,
    allowSendReminder,
    allowSendToAllMembers,
    allowSendToAdmins,
    allowTeamMatching,
    allowViewReceiverList,
    allowViewReadUnreadMemberList,
    allowViewAnswerSummary,
    allowViewAnswerMemberList,
    allowViewAnsweredUnansweredMemberList,
    allowCountUndefinedMemberAnswers,
    isLoading,
  }
}
