import { memo, useEffect, useMemo, useRef } from "react"
import { Props } from "./Props"
import lottie from "lottie-web"
import json from "./3dotsloading.json"

export const LoadingIndicator = memo<Props>(({ size = 60 }) => {
  const boxHeight = useMemo(() => size / 3, [size])
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (ref.current == null) {
      return
    }

    lottie.loadAnimation({
      container: ref.current, // the dom element that will contain the animation
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: json,
    })
  }, [])

  return (
    <div
      style={{
        display: "flex",
        width: size,
        height: boxHeight,
        overflow: "hidden",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div ref={ref} />
    </div>
  )
})

LoadingIndicator.displayName = "LoadingIndicator"
