import { Result } from "src/utils/Result"
import dayjs from "dayjs"
import { listAdminNotice } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

export type InformationItem = {
  id: string
  title: string
  updatedAt: string
  isRead?: boolean
  readFlg: boolean
}

const convertToText = (d: Date) => dayjs(d).format("MM月DD日（dd）HH:mm")

type GetInformationsRequestParams = {
  accessToken: string
  accountId: string
  readIds: Set<string>
}

type GetInformationsSuccess = InformationItem[] | undefined

type GetInformationsFailure = {
  message: string
}

export const getInformations = async (
  params: GetInformationsRequestParams
): Promise<Result<GetInformationsSuccess, GetInformationsFailure>> => {
  return execApi(
    () => listAdminNotice(params.accessToken, { appInfo }),
    (res) => {
      return (
        res.listAdminNotice.items.map((item) => ({
          id: item.id,
          title: item.title,
          updatedAt: convertToText(new Date(item.publishDatetime)),
          readFlg: item.readFlg ?? false,
          isRead: params.readIds.has(item.id),
        })) || undefined
      )
    },
    {
      identifiers: ["getInformations", "listAdminNotice"],
    }
  )
}
