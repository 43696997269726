import React from "react"
import Svg, { Path } from "react-native-svg"

const ArrowDown = () => (
  <Svg width={20} height={20} viewBox="0 0 20 20" fill="none">
    <Path fill="#F0830E" d="M13.825 6.916L10 10.728 6.175 6.916 5 8.089l5 4.994 5-4.994-1.175-1.173Z" />
  </Svg>
)

export default ArrowDown
