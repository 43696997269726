import Constants from "expo-constants"

const url = (type: string, tempToken: string, organizationId?: string) => {
  let domain = "dev.ra9plus.jp"
  if (Constants.expoConfig?.extra?.env == "prd") {
    domain = "ra9plus.jp"
  }
  if (Constants.expoConfig?.extra?.env == "stg") {
    domain = "stg.ra9plus.jp"
  }
  const orgQuery = organizationId ? `&organizationId=${organizationId}` : ""
  return `https://${domain}/migration/migration_login/?type=${type}&tt=${tempToken}${orgQuery}`
}

export const migration = {
  organization: (tempToken: string) => url("team", tempToken),
  contact: (tempToken: string, organizationId: string) => url("contact", tempToken, organizationId),
  description: `https://www.ra9plus.jp/guide/category/migration/`,
}
