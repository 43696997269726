import { selector } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { draftContactsRequestIdState } from "src/recoil/atoms/contact/draftContactsRequestIdState"
import { getDraftContacts } from "src/apis/contact/getDraftContacts"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { CustomAlert } from "src/utils/CustomAlert"

export const draftContactsSelector = selector({
  key: "selectors/contact/draftContactsSelector",
  get: async ({ get }) => {
    get(draftContactsRequestIdState)
    const selectedMyOrganization = get(selectedMyOrganizationSelector)
    if (selectedMyOrganization == null) {
      return
    }
    const myProfile = get(myProfileSelectorFamily(selectedMyOrganization.id))
    if (myProfile == null) {
      return
    }
    const myMemberId = myProfile.memberId
    const tokens = get(tokensState)
    if (tokens == null) {
      return
    }
    const { accessToken } = tokens
    const result = await getDraftContacts({ accessToken, myMemberId })
    if (!result.isOk) {
      CustomAlert.alert("エラー", result.error.message)
      return
    }
    return result.content
  },
})
