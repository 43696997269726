import { useMemo } from "react"
import { memo } from "react"
import { Svg, Path, G, Circle } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  color?: string
  filled?: boolean
}

export const OrganizationIcon = memo<Props>(({ filled, color }) => {
  const defaultColor = useMemo(() => color ?? Colors.white, [color])
  const fill = useMemo(() => (filled ? defaultColor : "none"), [filled, defaultColor])
  const stroke = useMemo(() => (filled ? "none" : defaultColor), [filled, defaultColor])

  return (
    <Svg width="32.173" height="17.701" viewBox="0 0 32.173 17.701">
      <G data-name="グループ 1915">
        <G
          data-name="楕円形 24"
          transform="translate(12.954 1)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        >
          <Circle cx="3.133" cy="3.133" r="3.133" stroke="none" />
          <Circle cx="3.133" cy="3.133" r="3.633" fill="none" />
        </G>
        <Path
          data-name="パス 56"
          d="M1 18.2v-2.33a5.386 5.386 0 0 1 5.37-5.37h2.686a5.386 5.386 0 0 1 5.37 5.37v2.33"
          transform="translate(8.374 -.997)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        />
        <G
          data-name="楕円形 24"
          transform="translate(25.166 6.493)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        >
          <Circle cx="2.07" cy="2.07" r="2.07" stroke="none" />
          <Circle cx="2.07" cy="2.07" r="2.57" fill="none" />
        </G>
        <G
          data-name="楕円形 376"
          transform="translate(2.867 6.493)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        >
          <Circle cx="2.07" cy="2.07" r="2.07" stroke="none" />
          <Circle cx="2.07" cy="2.07" r="2.57" fill="none" />
        </G>
        <Path
          data-name="パス 56"
          d="M1 15.587v-1.538A3.56 3.56 0 0 1 4.549 10.5h1.775a3.56 3.56 0 0 1 3.549 3.549v1.538"
          transform="translate(21.8 1.613)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        />
        <Path
          data-name="パス 881"
          d="M1 15.587v-1.538A3.56 3.56 0 0 1 4.549 10.5h1.775a3.56 3.56 0 0 1 3.549 3.549v1.538"
          transform="translate(-.5 1.613)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        />
      </G>
    </Svg>
  )
})
