import { memo } from "react"
import { StyleSheet, Text, View } from "react-native"

type Props = {
  items: JSX.Element[]
}

export const UnorderedList = memo<Props>(({ items }) => {
  return (
    <>
      {items.map((item, index) => (
        <View key={index} style={[styles.caveatsItem, index !== 0 && styles.caveatsItemTail]}>
          <Text>・</Text>
          <View style={styles.rest}>{item}</View>
        </View>
      ))}
    </>
  )
})

const styles = StyleSheet.create({
  caveatsItem: {
    flexDirection: "row",
  },
  rest: {
    flex: 1,
  },
  caveatsItemTail: {
    marginTop: 8,
  },
})
