import React, { memo, useMemo } from "react"
import { TeamList } from "src/apis/migration/getMigrationTeams"
import { View, StyleSheet, Text, ScrollView } from "react-native"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { Screens } from "src/constants/Screens"
import { RootStackScreenProps } from "src/types.d"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"

export const MigrationAsMemberFinish = memo<RootStackScreenProps<"MigrationAsMemberFinish">>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const teamName = useMemo(() => route.params?.teamName?.toString(), [route.params?.teamName])
  const renderViewModal = (content: string) => {
    return (
      <View style={{ flexDirection: "row", padding: 4 }}>
        <Text style={[{ paddingRight: 3 }, styles.contentTitle]}>・</Text>
        <Text style={styles.contentTitle}>{content}</Text>
      </View>
    )
  }
  return (
    <CustomKeyboardAvoidingView>
      <ScrollView style={{ height: 300 }}>
        <View style={isLargeScreen ? { alignItems: "center" } : {}}>
          <View style={isLargeScreen ? { width: 600, paddingTop: 16 } : {}}>
            <View style={{ paddingTop: 32, paddingBottom: 12 }}>
              <Text style={[styles.titleContent]}>{`「${teamName}」の代表者に移行リクエストメールを送信しました。`}</Text>
              <View style={{ paddingHorizontal: 24 }}>
                {renderViewModal(
                  "引き続き他の団体に移行リクエストする場合は「他の団体を移行する」ボタンより、手続きを行なってください。"
                )}
                {renderViewModal("移行リクエストを終了する場合は「団体管理に戻る」ボタンより、手続きを終了してください。")}
              </View>
            </View>
          </View>
        </View>
        <View style={isLargeScreen ? { alignItems: "center" } : {}}>
          <View style={[styles.actionContainer, isLargeScreen ? { width: 600, alignItems: "center" } : {}]}>
            <TextButton
              buttonType={ButtonType.Primary}
              title={"他の団体に移行リクエストする"}
              onPress={() => navigation.navigate(Screens.MigrationTeamSelect)}
              style={isLargeScreen ? styles.buttonsPC : {}}
            />
          </View>
        </View>
        <View style={isLargeScreen ? { alignItems: "center" } : {}}>
          <View style={[styles.actionContainerAcpect, isLargeScreen ? { width: 600, alignItems: "center" } : {}]}>
            <TextButton
              buttonType={ButtonType.Blank}
              title={"終了する"}
              onPress={() =>
                navigation.navigate(Screens.Root, {
                  screen: RootTabs.OrganizationManagerTab,
                  params: {
                    screen: OrganizationManagerScreens.OrganizationManager,
                  },
                })
              }
              style={[isLargeScreen ? styles.buttonsPC : {}, styles.BtnOrganizationManager]}
            />
          </View>
        </View>
      </ScrollView>
    </CustomKeyboardAvoidingView>
  )
})
export const migrationAsMemberFinishOptions: ScreenOptions = () => ({
  ...commonHeaderOptions,
  headerShown: true,
  title: "移行リクエスト送信完了",
  headerLeft: () => null,
})
const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.beige,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  contentTitle: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "left",
  },
  titleContent: {
    fontSize: 16,
    fontWeight: "700",
    width: "100%",
    textAlign: "left",
    paddingHorizontal: 24,
  },
  actionContainer: {
    borderBottomColor: Colors.white2,
    borderBottomWidth: 1,
    paddingHorizontal: 24,
    paddingVertical: 12,
    paddingBottom: 32,
  },
  actionContainerAcpect: {
    paddingHorizontal: 24,
    paddingVertical: 24,
  },
  BtnOrganizationManager: {
    backgroundColor: "#9F866B1A",
    borderRadius: 8,
  },
})
