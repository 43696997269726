import { useCallback, useEffect, useMemo, useState } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useFetcher } from "src/hooks/useFetcher"
import { Notification } from "src/queries/home/getNotifications"
import { homeOrganizationsRequestIdState } from "src/recoil/atoms/home/homeOrganizationsRequestIdState"
import { notificationsRequestIdState } from "src/recoil/atoms/home/notificationsRequestIdState"
import { HomeOrganization, homeOrganizationsSelector } from "src/recoil/selectors/home/HomeOrganizationsSelector"
import { notificationsSelector } from "src/recoil/selectors/home/notificationsSelector"
import { ValueOf } from "src/types.d"
import { useIncrementRecoilState } from "src/recoil/hooks/useIncrementRecoilState"
import { useAsyncState } from "src/hooks/useAsyncState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { useContactDetailDataRefresher } from "src/recoil/hooks/refresher/useContactDetailDataRefresher"
import { updateFavoriteContactInbox } from "src/apis/contact/updateFavoriteContactInbox"
import { CustomAlert } from "src/utils/CustomAlert"
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { useBottomTabBadgeData } from "src/recoil/hooks/bottomTab/useBottomTabBadgeData"
import { pendingInvitationsRequestIdState } from "src/recoil/atoms/organization/pendingInvitationsRequestIdState"
import { deleteNotifications } from "src/queries/home/deleteNotifications"
import { inboxContactFavoriteFlgsCacheState } from "src/recoil/atoms/contact/contactFavoriteFlgsCacheState"
import { myFullOrganizationsRequestIdState } from "src/recoil/atoms/organization/myOrganizationsRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"

export const HomeListItemType = Object.freeze({
  Notification: "Notification",
  Organization: "Organization",
} as const)

export type HomeListItemType = ValueOf<typeof HomeListItemType>

export type HomeListItem =
  | {
      itemType: typeof HomeListItemType.Notification
      organization?: HomeOrganization
      notifications: Notification[]
    }
  | {
      itemType: typeof HomeListItemType.Organization
      organization: HomeOrganization
      notifications?: Notification[]
    }

export const useHomeTabData = () => {
  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])

  const [favoriteFlgsCache, setFavoriteFlgsCache] = useRecoilState(inboxContactFavoriteFlgsCacheState)
  const resetFavoriteFlgsCache = useResetRecoilState(inboxContactFavoriteFlgsCacheState)
  const _refreshMyOrganizations = useRefresher(myFullOrganizationsRequestIdState)
  const refreshNotifications = useIncrementRecoilState(notificationsRequestIdState)
  const refreshHomeOrganizations = useIncrementRecoilState(homeOrganizationsRequestIdState)
  const refreshPendingInvitations = useIncrementRecoilState(pendingInvitationsRequestIdState)
  const setOrganizationId = useSetRecoilState(selectedMyOrganizationState)
  const { value: notifications, isLoading: isNotificationsLoading } = useAsyncSelector(notificationsSelector)
  const { value: organizations, isLoading: isOrganizationContactsListLoading } = useAsyncSelector(homeOrganizationsSelector)

  const [isNotificationAccordionOpen, setIsNotificationAccordionOpen] = useState(false)
  const toggleNotificationAccordion = useCallback(
    () => setIsNotificationAccordionOpen((prev) => !prev),
    [setIsNotificationAccordionOpen]
  )
  const enableNotificationAccordion = useMemo(() => (notifications?.length || 0) > 3, [notifications])
  const toggleNotificationAccordionText = useMemo(
    () => (isNotificationAccordionOpen ? "閉じる" : "すべて表示"),
    [isNotificationAccordionOpen]
  )
  const showDeleteAllNotifications = useMemo(
    () => (notifications?.length || 0) > 0 && (!enableNotificationAccordion || isNotificationAccordionOpen),
    [notifications, enableNotificationAccordion, isNotificationAccordionOpen]
  )
  const deleteAllNotifications = useCallback(async () => {
    if (accessToken == null) return
    const isConfirmed = await CustomAlert.confirm("確認", "削除しますか?")
    if (!isConfirmed) {
      return
    }
    await deleteNotifications({ accessToken })
    await CustomAlert.alert("完了", "通知を削除しました。")
    refreshNotifications()
  }, [accessToken, refreshNotifications])

  const { fetch: updateFavorite } = useFetcher(
    useCallback(
      async ({ isFavorite, teamMemberContactId }: { isFavorite: boolean; teamMemberContactId: string }) => {
        if (accessToken == null) {
          return
        }
        const result = await updateFavoriteContactInbox({ accessToken, teamMemberContactId, isFavorite })
        if (!result.isOk) {
          CustomAlert.alert("エラー", result.error.message)
          return undefined
        }
        const contactId = teamMemberContactId.split(".")[1] ?? teamMemberContactId
        setFavoriteFlgsCache((prev) => ({ ...prev, [contactId]: isFavorite }))
      },
      [accessToken, setFavoriteFlgsCache]
    )
  )

  const listItems = useMemo(() => {
    const result: HomeListItem[] = []
    if (notifications != null) {
      result.push({
        itemType: HomeListItemType.Notification,
        notifications: enableNotificationAccordion && !isNotificationAccordionOpen ? notifications.slice(0, 3) : notifications,
      })
    }
    if (organizations != null) {
      organizations.forEach((organization) =>
        result.push({
          itemType: HomeListItemType.Organization,
          organization: organization,
        })
      )
    }
    return result
  }, [notifications, organizations, enableNotificationAccordion, isNotificationAccordionOpen])

  const isShowHomeAd = useMemo(() => {
    if (organizations != null) {
      return organizations.some((item) => item.isShowAd === true)
    }
    return true
  }, [organizations])

  // ホームのリフレッシュ時にbottomTabのアイコンもリフレッシュ。notificationはrefreshNotificationsでリフレッシュされる
  const { refreshUnreadContactCount, refreshHasPreTeamMember, refreshHasServiceInformation } = useBottomTabBadgeData()
  const refresh = useCallback(() => {
    _refreshMyOrganizations()
    refreshNotifications()
    refreshHomeOrganizations()
    resetFavoriteFlgsCache()
    refreshUnreadContactCount()
    refreshHasPreTeamMember()
    refreshHasServiceInformation()
  }, [
    _refreshMyOrganizations,
    refreshNotifications,
    refreshHomeOrganizations,
    resetFavoriteFlgsCache,
    refreshUnreadContactCount,
    refreshHasPreTeamMember,
    refreshHasServiceInformation,
  ])

  const isRefreshing = useMemo(
    () => isNotificationsLoading || isOrganizationContactsListLoading,
    [isNotificationsLoading, isOrganizationContactsListLoading]
  )

  const refreshContactDetailData = useContactDetailDataRefresher()

  return {
    listItems,
    enableNotificationAccordion,
    toggleNotificationAccordion,
    toggleNotificationAccordionText,
    showDeleteAllNotifications,
    deleteAllNotifications,
    updateFavorite,
    refresh,
    isRefreshing,
    refreshContactDetailData,
    setOrganizationId,
    refreshNotifications,
    refreshPendingInvitations,
    favoriteFlgsCache,
    isShowHomeAd,
  }
}
