import { ValueOf } from "src/types.d"

export const CONTACT_GROUP = ["Inbox", "Outbox", "Draft", "Template", "TrashBox", "Migration"]

export const RootDrawer = Object.freeze({
  HomeTab: "HomeTab",
  ContactNetworkDrawer: "ContactNetworkDrawer",
  MatchingDrawer: "matching",
  OrganizationManagerTab: "OrganizationManagerTab",
  SettingsTab: "SettingsTab",
  InboxScreen: "Inbox",
  OutboxScreen: "Outbox",
  TrashBoxScreen: "TrashBox",
  TemplateScreen: "Template",
  DraftScreen: "Draft",
  MigrationScreen: "Migration",
  OtherMatchingOffer: "OtherMatchingOffer",
} as const)

export const MATCHING_GROUP = [RootDrawer.MatchingDrawer]

export type RootDrawer = ValueOf<typeof RootDrawer>
