import { FC } from "react"
import Svg, { Path } from "react-native-svg"

type Props = {
  size?: number
}

export const CheckWithCircleIcon: FC<Props> = ({ size = 30 }) => (
  <Svg width={size} height={size} viewBox="0 0 30 30" fill="none">
    <Path
      d="M14.9987 0.832031C7.1787 0.832031 0.832031 7.1787 0.832031 14.9987C0.832031 22.8187 7.1787 29.1654 14.9987 29.1654C22.8187 29.1654 29.1654 22.8187 29.1654 14.9987C29.1654 7.1787 22.8187 0.832031 14.9987 0.832031ZM14.9987 26.332C8.7512 26.332 3.66536 21.2462 3.66536 14.9987C3.66536 8.7512 8.7512 3.66536 14.9987 3.66536C21.2462 3.66536 26.332 8.7512 26.332 14.9987C26.332 21.2462 21.2462 26.332 14.9987 26.332ZM21.5012 8.73703L12.1654 18.0729L8.4962 14.4179L6.4987 16.4154L12.1654 22.082L23.4987 10.7487L21.5012 8.73703Z"
      fill="#EE8327"
    />
  </Svg>
)
