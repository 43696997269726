import { selectorFamily } from "recoil"
import { listCityMaster } from "src/aws/customAPI"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"

type City = {
  id: string
  label: string
}

export const citiesSelectorFamily = selectorFamily<City[] | undefined, string | undefined>({
  key: "selectors/account/citiesSelectorFamily",
  get:
    (prefectureId) =>
    async ({ get }) => {
      const tokens = get(tokensState)
      if (tokens == null || !prefectureId) {
        return
      }

      const cityMaster = await listCityMaster(tokens.accessToken, {
        prefectureId: prefectureId,
      })

      const cities: City[] | undefined = cityMaster.data?.getPrefectureMaster.cities.items
        .filter((c) => c.displayFlg === 1)
        .map((c) => ({
          id: c.id,
          label: c.label,
        }))

      return cities
    },
})
