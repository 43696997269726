import { atom, selector } from "recoil"
import { getLatestTerms } from "src/apis/account/getLatestTerms"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { LatestTermsModel } from "src/types/maintenanceUpdate/Terms"

export const latestTermsState = atom<LatestTermsModel | undefined>({
  key: "atoms/account/latestTermsState",
  default: selector({
    key: "atoms/account/latestTermsState_default",
    get: async ({ get }) => {
      const tokens = get(tokensState)
      if (tokens == null) return

      const terms = await getLatestTerms({ accessToken: tokens.accessToken })

      if (!terms.isOk) {
        console.error(terms.error.message)
        return
      }

      return terms.content
    },
  }),
})
