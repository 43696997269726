import { MemberType, OrganizationMemberDetail } from "src/types/organization/OrganizationMember"
import { getTeamMember } from "src/aws/customAPI"
import { SchoolGrade } from "src/constants/SchoolGradeOptions"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
  memberId: string
}

export const getOrganizationMemberDetail = async ({
  accessToken,
  memberId,
}: Params): Promise<OrganizationMemberDetail | undefined> => {
  const result = await execApi(
    () =>
      getTeamMember(accessToken, {
        id: memberId,
        appInfo,
      }),
    (res) => {
      const m = res.getTeamMember
      return {
        id: m.id,
        nickname: m.nickname,
        nicknameKana: m.nicknameKana,
        memberNumber: m.memberSeq,
        imageUrl: "http://www.ra9.jp/wp-content/themes/ra9/images/web_guide/guide_ren.png",
        isAdministrator: m.role === "manager",
        memberType: memberTypeMapping[m.role],
        editableMemberId: m.memberSeq.toString(),
        email: m.account.email || "",
        isShowEmail: m.showEmailFlg || false,
        phoneNumber: m.phoneNumber || "",
        isShowPhoneNumber: m.showPhoneNumberFlg || false,
        affiliation: m.belongs || "",
        isShowAffiliation: m.showBelongsFlg || false,
        schoolGrade: m.grade as SchoolGrade,
      }
    },
    {
      identifiers: ["getOrganizationMemberDetail", "getTeamMember"],
    }
  )

  return result.isOk ? result.content : undefined
}

const memberTypeMapping = {
  general: MemberType.General,
  mailSender: MemberType.GeneralWithContactAuthorization,
  manager: MemberType.Representative,
}
