import { selectorFamily } from "recoil"
import { getDraft } from "src/apis/contact/getDraft"
import { draftRequestIdState } from "src/recoil/atoms/contact/draftRequestIdState"
import { DraftModel } from "src/types/contact/DraftModel"
import { requestDataSelector } from "../auth/requestDataSelector"

export const draftSelectorFamily = selectorFamily<DraftModel | undefined, string>({
  key: "selectors/contact/draftSelectorFamily",
  get:
    (draftId) =>
    async ({ get }) => {
      get(draftRequestIdState)
      const requestData = get(requestDataSelector)
      if (requestData == null) {
        return
      }
      const { accessToken } = requestData
      const draftResult = await getDraft({ accessToken, draftId })
      if (!draftResult.isOk) {
        return
      }
      return draftResult.content
    },
})
