import { memo, useMemo } from "react"
import Svg, { Path } from "react-native-svg"

type Props = {
  width?: number
}

export const DocumentIcon = memo<Props>((props) => {
  const width = props.width || 20
  const height = useMemo(() => (width * 35.099) / 27, [width])
  return (
    <Svg width={width} height={height} viewBox="0 0 27 35.099">
      <Path
        d="M53 30.105a1.629 1.629 0 0 0-.505-1.18l-9.562-9.674a1.683 1.683 0 0 0-1.182-.45H31.062A5.062 5.062 0 0 0 26 23.863v24.973a5.062 5.062 0 0 0 5.062 5.064h16.875A5.062 5.062 0 0 0 53 48.836zm-9.786-5.792 4.05 4.107h-4.05zm4.724 26.211H31.063a1.631 1.631 0 0 1-1.687-1.687V23.863a1.631 1.631 0 0 1 1.687-1.687h8.776v6.243a3.373 3.373 0 0 0 3.375 3.375h6.411v17.043a1.631 1.631 0 0 1-1.687 1.687z"
        transform="translate(-26 -18.8)"
        fill="#f0830e"
      />
    </Svg>
  )
})

DocumentIcon.displayName = "DocumentIcon"
