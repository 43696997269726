import { ComponentProps, memo, ReactNode } from "react"
import { StyleSheet } from "react-native"
import { Button } from "./Button"

type Props = {
  onPress: ComponentProps<typeof Button>["onPress"]
  children: ReactNode
  disabled?: boolean
  width?: number
}

export const SmallButton = memo<Props>(({ children, onPress, disabled, width }) => {
  return (
    <Button
      onPress={onPress}
      style={[styles.button, width === undefined ? {} : { width: width }, disabled && styles.disabled]}
      disabled={disabled}
    >
      {children}
    </Button>
  )
})

const styles = StyleSheet.create({
  button: {
    width: 128,
    height: 40,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 8,
    backgroundColor: "#efeae3",
  },
  disabled: {
    opacity: 0.5,
  },
})
