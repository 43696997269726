import { useEffect, useMemo } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { useRecoilValue } from "recoil"
import { routeNameState } from "src/recoil/atoms/routeNameState"
import { Platform } from "react-native"

type Params = {
  queryParamOrganizationId?: string
  screenName: string
  redirect: (organizationId: string) => void
}

export const useContactNetworkDrawerNavEffect = ({ queryParamOrganizationId, screenName, redirect }: Params) => {
  const { value: defaultOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const defaultOrganizationId = useMemo(() => defaultOrganization?.id, [defaultOrganization])
  const [selectedOrganizationId, setSelectedOrganizationId] = useAsyncState(selectedMyOrganizationState)
  const routeName = useRecoilValue(routeNameState)

  useEffect(() => {
    if (Platform.OS === "web" && routeName === screenName) {
      if (selectedOrganizationId == null) {
        setSelectedOrganizationId(queryParamOrganizationId || defaultOrganizationId)
      } else if (queryParamOrganizationId !== selectedOrganizationId) {
        redirect(selectedOrganizationId)
      }
    }
  }, [
    queryParamOrganizationId,
    selectedOrganizationId,
    setSelectedOrganizationId,
    defaultOrganizationId,
    screenName,
    routeName,
    redirect,
  ])
}
