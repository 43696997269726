import { useCallback, useMemo, useState } from "react"
import { useRecoilValue } from "recoil"
import { AuthMethod } from "src/constants/AuthMethod"
import { sessionState } from "src/recoil/atoms/authorization/sessionState"
import { CustomAlert } from "src/utils/CustomAlert"
import { getCodeSentDescription } from "src/utils/getCodeSentDescription"
import { useEmailLoginData } from "src/recoil/hooks/authorization/useAuthEmailData"
import { useValidateCode } from "src/recoil/hooks/authorization/useValidateCode"
import { useLoginPhoneNumberData } from "src/recoil/hooks/screens/useLoginPhoneNumberData"

type Params = {
  email?: string
  phoneNumber?: string
}

export const useLoginCodeData = ({ email, phoneNumber }: Params) => {
  const authMethod = useMemo(() => {
    if (email != null) {
      return AuthMethod.Email
    }
    if (phoneNumber != null) {
      return AuthMethod.PhoneNumber
    }
    throw new Error("Email or phoneNumber must be specified to params on LoginCode.")
  }, [email, phoneNumber])
  const session = useRecoilValue(sessionState)
  const [code, setCode] = useState<string>("")
  const validateCodeParams = useMemo(() => {
    if (code === "" || session == null) {
      return
    }
    const common = { code, session }
    if (phoneNumber != null) {
      return { ...common, authMethod: AuthMethod.PhoneNumber, value: phoneNumber }
    }
    if (email != null) {
      return { ...common, authMethod: AuthMethod.Email, value: email }
    }
  }, [code, session, email, phoneNumber])
  const { login, isSendingCode } = useValidateCode(validateCodeParams)

  const showMessage = useCallback(() => CustomAlert.alert("完了", "認証コードを再送しました。"), [])
  const { isSending: isSendingEmail, sendAuthCode: sendAuthCodeEmail } = useEmailLoginData(showMessage)
  const { isSendingAuthCode: isSendingPhoneNumber, sendAuthCode: sendAuthCodePhoneNumber } = useLoginPhoneNumberData({
    gotoEnterAuthCode: showMessage,
    phoneNumber,
  })
  const isSending = useMemo(() => isSendingEmail || isSendingPhoneNumber, [isSendingEmail, isSendingPhoneNumber])
  const sendAuthCode = useMemo(
    () => (authMethod === AuthMethod.Email ? sendAuthCodeEmail : sendAuthCodePhoneNumber),
    [authMethod, sendAuthCodeEmail, sendAuthCodePhoneNumber]
  )

  const description = useMemo(
    () => getCodeSentDescription(email ?? phoneNumber ?? "", authMethod),
    [email, phoneNumber, authMethod]
  )
  const isResendDisabled = useMemo(() => isSending || isSendingCode, [isSending, isSendingCode])

  return {
    authMethod,
    description,
    code,
    setCode,
    isResendDisabled,
    isSending,
    isSendingCode,
    login,
    sendAuthCode,
  }
}
