import { selectorFamily } from "recoil"
import { groupsRequestIdState } from "src/recoil/atoms/organization/group/groupsRequestIdState"
import { getGroups } from "src/queries/organization/getGroups"
import { GroupOverview } from "src/types/organization/group/GroupOverview"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"

export const groupsSelectorFamily = selectorFamily<GroupOverview[] | undefined, string | undefined>({
  key: "selectors/organization/group/groupsSelectorFamily",
  get:
    (organizationId) =>
    async ({ get }) => {
      get(groupsRequestIdState)
      const tokens = get(tokensState)
      if (tokens == null || organizationId == null) {
        return
      }

      return await getGroups({ accessToken: tokens.accessToken, organizationId })
    },
})
