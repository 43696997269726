import { atom, selector } from "recoil"
import { draftSelector } from "src/recoil/selectors/contact/draftSelector"
import { templateSelector } from "src/recoil/selectors/contact/templateSelector"
import { existingContactDetailSelector } from "src/recoil/selectors/contact/create/existingContactDetailSelector"
import { migrationSelector } from "src/recoil/selectors/contact/migrationSelector"
import { reservedSelector } from "src/recoil/selectors/contact/reservedSelector"

const initialState = ""

const initialStateSelector = selector({
  key: "atoms/contact/create/titleState_initialState",
  get: ({ get }) => {
    const contact = get(existingContactDetailSelector)
    const reserved = get(reservedSelector)
    const template = get(templateSelector)
    const draft = get(draftSelector)
    const migration = get(migrationSelector)
    return (contact ?? reserved ?? template ?? draft ?? migration)?.title ?? initialState
  },
})

export const titleState = atom({
  key: "atoms/contact/create/titleState",
  default: initialStateSelector,
})
