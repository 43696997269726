import { memo, useEffect, useState } from "react"
import { Linking, Platform, StyleSheet, Text, useWindowDimensions, View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { Colors } from "src/constants/Colors"
import { RootStackScreenProps } from "src/types.d"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import {
  ApplicantInformationData,
  applicantInformationDataState,
} from "src/recoil/atoms/applicantInformation/applicantInformationDataState"
import { PlanData, planDataState } from "src/recoil/atoms/planData/planDataState"
import { Screens } from "src/constants/Screens"
import { Checkbox } from "src/components/parts/Checkbox"
import { createPaidVersionOrder } from "src/apis/paidTeam/createPaidVersionOrder"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import ApplicantBlock from "./components/ApplicantBlock"
import PlanBlock from "./components/PlanBlock"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { PC_BREAKPOINT } from "src/constants/Screen"
import { useFetcher } from "src/hooks/useFetcher"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { getAsync } from "src/storage/secureStorage/getAsync"
import { deleteAsync } from "src/storage/secureStorage/deleteAsync"
import { teamPermissionRequestIdState } from "src/recoil/atoms/organization/teamPermissionRequestIdState"
import { isEmpty } from "lodash"
import { refreshConfirmScreenState } from "src/recoil/atoms/paidTeam/refreshConfirmScreenState"
import { setAsync } from "src/storage/secureStorage/setAsync"
import { applicantIdState } from "src/recoil/selectors/paidTeam/applicantIdState"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { planMigrateFlag } from "src/recoil/atoms/planData/planMigrateFlag"

const confirmPlan = memo<RootStackScreenProps<"confirmPlan">>(({ route, navigation }) => {
  const isLargeScreen = useCheckPCScreen()
  const { extend, initUpdatePlan } = route.params
  const [applicantInformationData, setApplicantInformationData] = useState<ApplicantInformationData | undefined>(undefined)
  const [planData, setPlanData] = useState<PlanData | undefined>(undefined)
  const requestDataResult = useRequestData()
  const accessToken = requestDataResult.isOk ? requestDataResult?.content?.accessToken : ""
  const setRefreshTrigger = useSetRecoilState(teamPermissionRequestIdState)
  const [checkVersion, setCheckVersion] = useState(false)
  const [checkPersonal, setCheckPersonal] = useState(false)
  const setApplicantStore = useSetRecoilState(applicantInformationDataState)
  const setPlanStore = useSetRecoilState(planDataState)
  const refreshData = useRecoilValue(refreshConfirmScreenState)
  const setApplicantId = useSetRecoilState(applicantIdState)
  const heightScreen = useWindowDimensions().height
  const setMigratePaidFunc = useSetRecoilState(planMigrateFlag)

  const insets = useSafeAreaInsets()

  const goBack = () => {
    if (planData) setPlanStore(planData)
    navigation.navigate(Screens.registerPlan, {
      organizationId: applicantInformationData?.idTeam ?? "",
      mode: "back",
      extend,
      oriUpdatePlan: initUpdatePlan,
    })
  }

  useEffect(() => {
    navigation.setOptions({
      headerLeft: (props) => (
        <Button onPress={() => goBack()}>
          <BackIcon
            fill={Number(props.screenLayout?.width) >= PC_BREAKPOINT && Platform.OS === "web" ? Colors.orange : Colors.white3}
            {...props}
          ></BackIcon>
        </Button>
      ),
    })
  })

  useEffect(() => {
    const fetchData = async () => {
      const value = await getAsync("applicantInformationData")
      const planValue = await getAsync("planData")
      if (value !== undefined && planValue !== undefined) {
        setApplicantInformationData(JSON.parse(value))
        setPlanData(JSON.parse(planValue))
      }
    }
    fetchData()
  }, [refreshData])

  const goToEditApplicant = () => {
    setCheckVersion(false)
    setCheckPersonal(false)
    if (applicantInformationData) setApplicantStore(applicantInformationData)
    navigation.navigate(Screens.registerApplicantInformation, {
      organizationId: applicantInformationData?.idTeam ?? "",
      mode: "update",
    })
  }

  const goToEditPlan = () => {
    setCheckVersion(false)
    setCheckPersonal(false)
    if (planData) setPlanStore(planData)
    navigation.navigate(Screens.registerPlan, {
      organizationId: applicantInformationData?.idTeam ?? "",
      mode: "update",
      extend,
      oriUpdatePlan: initUpdatePlan,
    })
  }

  const { fetch: createOrder, isFetching: isLoading } = useFetcher(async () => {
    if (planData != undefined && applicantInformationData != undefined && accessToken) {
      const result = await createPaidVersionOrder({ accessToken, planData, applicantInformationData })
      const mode = result?.id ? "SUCCESS" : isEmpty(result?.errors[0]?.errorInfo?.detail) ? "FAIL_DB" : "FAIL_MAIL"

      if (mode === "SUCCESS" || mode == "FAIL_MAIL") {
        setRefreshTrigger((oldValue) => oldValue + 1)
        setAsync("applicantInformationData", JSON.stringify({ ...applicantInformationData, buildingName: "" }))
        deleteAsync("planData")
        setApplicantId(applicantInformationData?.idAdmin ?? "")
      }
      setMigratePaidFunc(false)
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.CompletedApplyPaid,
          params: {
            mode: mode,
            orderId: mode === "FAIL_DB" ? "" : result,
            type: "create",
            teamId: applicantInformationData?.idTeam,
          },
        },
      })
    }
  })

  return (
    <View style={[{ height: heightScreen - 64, paddingBottom: insets.bottom }]}>
      <CustomScrollView noPaddingBottom={true}>
        <ApplicantBlock
          teamName={applicantInformationData?.teamName ?? ""}
          adminName={applicantInformationData?.adminName ?? ""}
          email={applicantInformationData?.email ?? ""}
          appCategory={applicantInformationData?.appCategory ?? ""}
          companyName={applicantInformationData?.companyName}
          companyNameKana={applicantInformationData?.companyNameKana}
          companyRepresentativeNameKana={applicantInformationData?.companyRepresentativeNameKana}
          representativeName={applicantInformationData?.representativeName}
          phoneNumber={applicantInformationData?.phoneNumber ?? 0}
          zip={applicantInformationData?.zip ?? 0}
          prefecture={applicantInformationData?.prefecture ?? ""}
          address={applicantInformationData?.address ?? ""}
          buildingName={applicantInformationData?.buildingName}
          goToEditApplicant={goToEditApplicant}
        />
        <View style={styles.line}></View>
        <PlanBlock
          companyName={applicantInformationData?.teamName ?? ""}
          teamMembers={applicantInformationData?.teamMembers ?? 0}
          maxMembers={planData?.maxMembers ?? ""}
          limitTime={planData?.limitTime ?? ""}
          price={planData?.price ?? ""}
          invoiceAddress={planData?.invoiceAddress ?? ""}
          invoiceKanaAddress={planData?.invoiceKanaAddress ?? ""}
          startTimeConvert={planData?.startTimeConvert ?? ""}
          remark={planData?.remark ?? ""}
          goToEditPlan={goToEditPlan}
          planId={planData?.planId ?? ""}
        />
        <View style={[styles.line]}></View>
        <View style={[styles.containerCheck]}>
          <View style={[styles.wrap, isLargeScreen ? { paddingHorizontal: 16 } : {}]}>
            <Text style={[styles.titleCheck]}>
              団体有料版のお申込には、団体有料版利用規約と個人情報の取り扱いをご確認のうえ、同意してください。
            </Text>
            <View style={[styles.checkBox, { marginBottom: 16 }]}>
              <Checkbox
                isChecked={checkVersion}
                onPress={() => {
                  setCheckVersion(!checkVersion)
                }}
              />
              <Button
                style={[styles.textLink]}
                onPress={() => {
                  Linking.openURL("https://www.ra9plus.jp/rule_paid/")
                }}
              >
                <Text style={[styles.textLink]}>団体有料版利用規約</Text>
              </Button>
              <Text style={[styles.textCheckBox]}>に同意する</Text>
            </View>
            <View style={[styles.checkBox]}>
              <Checkbox
                isChecked={checkPersonal}
                onPress={() => {
                  setCheckPersonal(!checkPersonal)
                }}
              />
              <Button
                style={[styles.textLink]}
                onPress={() => {
                  Linking.openURL("https://www.ra9plus.jp/privacypolicy/")
                }}
              >
                <Text style={[styles.textLink]}>個人情報の取り扱い</Text>
              </Button>
              <Text style={[styles.textCheckBox]}>に同意する</Text>
            </View>
          </View>
        </View>
      </CustomScrollView>
      <View style={[styles.buttonContainer, isLargeScreen ? {} : { paddingVertical: 24 }]}>
        <View
          style={[
            isLargeScreen
              ? {
                  maxWidth: 600,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  paddingHorizontal: 24,
                }
              : { width: "100%" },
          ]}
        >
          <TextButton
            buttonType={ButtonType.Secondary}
            title="戻る"
            onPress={() => {
              navigation.navigate(Screens.Root, {
                screen: RootTabs.OrganizationManagerTab,
                params: {
                  screen: OrganizationManagerScreens.OrganizationDetail,
                  params: { organizationId: applicantInformationData?.idTeam ?? "" },
                },
              })
            }}
            style={[isLargeScreen ? { width: "49%" } : null]}
          />
          <TextButton
            buttonType={ButtonType.Primary}
            title="申込"
            isLoading={isLoading}
            onPress={() => {
              createOrder()
            }}
            disabled={!checkVersion || !checkPersonal}
            style={[isLargeScreen ? { width: "49%" } : { marginTop: 16 }]}
          />
        </View>
      </View>
    </View>
  )
})

export const confirmPlanOptions: ScreenOptions = () => ({
  ...commonHeaderOptions,
  headerShown: true,
  title: "団体有料版申込情報確認",
})

const styles = StyleSheet.create({
  line: {
    height: 1,
    width: "100%",
    backgroundColor: Colors.borderColor,
  },
  row: {
    marginBottom: 16,
    width: "100%",
    borderBottomColor: Colors.borderColor,
    borderBottomWidth: 1,
  },
  buttonContainer: {
    paddingVertical: 12,
    width: "100%",
    alignItems: "center",
    paddingHorizontal: 24,
    borderTopWidth: 1,
    borderColor: Colors.borderColor,
  },
  containerCheck: {
    width: "100%",
    padding: 24,
    backgroundColor: "#FAF8F5",
    alignItems: "center",
  },
  titleCheck: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 20,
    marginBottom: 16,
  },
  wrap: {
    width: "100%",
    maxWidth: 600,
  },
  checkBox: {
    flexDirection: "row",
    alignItems: "center",
  },
  textCheckBox: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 19.6,
  },
  textLink: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 19.6,
    marginLeft: 8,
    color: Colors.activeStep,
  },
})

export default confirmPlan
