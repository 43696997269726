import * as React from "react"
import { Dimensions } from "react-native"
import Svg, { Path } from "react-native-svg"

function Step(props: any) {
  const { width: screenWidth } = Dimensions.get("window")
  const scale = screenWidth < 250 ? 0.5 : screenWidth < 380 ? 0.8 : 1
  const svgWidth = 114 * scale
  const svgHeight = 38 * scale
  return (
    <Svg width={svgWidth} height={svgHeight} viewBox="0 0 114 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill={props.active ? "#F0830E" : "#EFEAE3"} d="M 98 38 L 0 38 V 0 L 98 0 L 114 19 Z" />
      <Path
        d={`M25.428 23.864l-1.536 1.664a30.757 30.757 0 00-4.912-4.752c-1.808 2.016-3.648 3.424-6.096 4.624-.448-.832-.64-1.088-1.296-1.776 1.232-.48 2-.912 3.2-1.76 2.416-1.696 4.336-4.016 5.376-6.512l.176-.416c-.256.016-.256.016-5.296.096-.768.016-1.2.048-1.744.128l-.064-2.048c.448.08.752.096 1.408.096h.384l5.632-.08c.896-.016 1.024-.032 1.424-.176l1.056 1.088c-.208.24-.336.464-.512.864-.736 1.712-1.52 3.104-2.496 4.416 1.008.672 1.872 1.344 2.96 2.32.4.352.4.352 2.336 2.224zm13.776-5.392H35.78c-.08 1.84-.432 3.168-1.12 4.304-.848 1.408-2.016 2.288-4.176 3.152-.4-.816-.608-1.088-1.296-1.76 1.92-.592 3.12-1.44 3.792-2.672.416-.736.656-1.776.704-3.024H29.78c-1.088 0-1.456.016-2.032.08v-1.904c.608.096.96.112 2.064.112h9.36c1.088 0 1.456-.016 2.064-.112v1.904c-.576-.064-.944-.08-2.032-.08zm-9.84-3.904v-1.952c.592.096.912.112 2.064.112h6.144c1.152 0 1.472-.016 2.064-.112v1.952c-.528-.064-.912-.08-2.032-.08h-6.208c-1.12 0-1.504.016-2.032.08zm15.696 1.728l1.696-.496c.672 1.232.992 2 1.568 3.792l-1.808.576c-.416-1.616-.736-2.464-1.456-3.872zm3.424-.672l1.68-.48c.848 1.584 1.152 2.288 1.552 3.68l-1.776.56c-.304-1.216-.784-2.448-1.456-3.76zm5.504-.16l1.952.448c-.096.24-.128.32-.224.816-.464 2.176-1.184 3.936-2.208 5.328-1.184 1.616-2.512 2.656-4.88 3.856-.416-.704-.608-.944-1.28-1.584 1.12-.432 1.776-.784 2.72-1.472 1.872-1.36 2.944-2.992 3.632-5.552.208-.784.288-1.344.288-1.84zm8.304-2.096h7.344c.288 0 .528 0 .88-.032.032-.976.8-1.728 1.76-1.728.976 0 1.76.784 1.76 1.76s-.784 1.776-1.744 1.776c-.032 0-.064 0-.176-.016-.56 2.16-1.232 3.776-2.16 5.168-.976 1.472-2.368 2.816-3.984 3.872-.912.608-1.664.992-3.04 1.584-.448-.864-.656-1.168-1.408-1.904 1.408-.448 2.32-.864 3.504-1.632 2.496-1.616 4.064-3.872 4.848-6.976H62.26c-1.072 0-1.44.016-2.016.096v-2.08c.624.096.96.112 2.048.112zm9.984-.912a.922.922 0 00-.912.912c0 .496.416.912.912.912a.922.922 0 00.912-.912c0-.512-.4-.912-.912-.912zm${props.stepContent}`}
        fill={props.active ? "#fff" : "#B5ACA0"}
      />
    </Svg>
  )
}

export default Step
