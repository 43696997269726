import { FC, useMemo } from "react"
import Svg, { G, Path } from "react-native-svg"

type Props = {
  size?: number
}

export const CreateOrganizationIcon: FC<Props> = ({ size = 59.85 }) => {
  const height = useMemo(() => size * 0.5489, [size])
  return (
    <Svg width={size} height={height} viewBox="0 0 59.85 32.854">
      <G data-name="グループ 3205">
        <G data-name="グループ 3204">
          <G data-name="グループ 1915">
            <Path
              data-name="楕円形 24"
              d="M4.933-1.25A6.183 6.183 0 1 1-1.25 4.933 6.19 6.19 0 0 1 4.933-1.25zm0 9.867A3.683 3.683 0 1 0 1.25 4.933a3.688 3.688 0 0 0 3.683 3.684z"
              transform="translate(20.862 1.25)"
              stroke="#fff"
              fill="#fff"
            />
            <Path
              data-name="パス 56"
              d="M22.143 23.872a1.25 1.25 0 0 1-1.25-1.25v-3.665a7.232 7.232 0 0 0-7.207-7.207H9.457a7.232 7.232 0 0 0-7.207 7.207v3.665a1.25 1.25 0 1 1-2.5 0v-3.665A9.732 9.732 0 0 1 9.457 9.25h4.229a9.732 9.732 0 0 1 9.707 9.707v3.665a1.25 1.25 0 0 1-1.25 1.25z"
              transform="translate(14.224 5.126)"
              stroke="#fff"
              fill="#fff"
            />
            <Path
              data-name="楕円形 24"
              d="M3.261-1.25A4.511 4.511 0 1 1-1.25 3.261 4.516 4.516 0 0 1 3.261-1.25zm0 6.521a2.011 2.011 0 1 0-2.011-2.01 2.013 2.013 0 0 0 2.011 2.01z"
              transform="translate(40.093 9.35)"
              stroke="#fff"
              fill="#fff"
            />
            <Path
              data-name="楕円形 376"
              d="M3.261-1.25A4.511 4.511 0 1 1-1.25 3.261 4.516 4.516 0 0 1 3.261-1.25zm0 6.521a2.011 2.011 0 1 0-2.011-2.01 2.013 2.013 0 0 0 2.011 2.01z"
              transform="translate(4.976 9.35)"
              stroke="#fff"
              fill="#fff"
            />
            <Path
              data-name="パス 56"
              d="M1 19.762a1.25 1.25 0 0 1-1.25-1.25v-2.423A6.847 6.847 0 0 1 6.589 9.25h2.795a7.079 7.079 0 0 1 4.307 1.536 1.25 1.25 0 0 1-1.491 2.009 4.557 4.557 0 0 0-2.816-1.045H6.589a4.344 4.344 0 0 0-4.339 4.339v2.422A1.25 1.25 0 0 1 1 19.762z"
              transform="translate(35.367 9.237)"
              stroke="#fff"
              fill="#fff"
            />
            <Path
              data-name="パス 881"
              d="M14.974 19.762a1.25 1.25 0 0 1-1.25-1.25v-2.423a4.344 4.344 0 0 0-4.34-4.339H6.589a4.344 4.344 0 0 0-4.339 4.339v2.422a1.25 1.25 0 1 1-2.5 0v-2.422A6.847 6.847 0 0 1 6.589 9.25h2.795a6.847 6.847 0 0 1 6.839 6.839v2.422a1.25 1.25 0 0 1-1.249 1.251z"
              transform="translate(.25 9.237)"
              stroke="#fff"
              fill="#fff"
            />
          </G>
          <Path
            d="M28.606 23a.83.83 0 0 0-.83.83v3.945H23.83a.83.83 0 0 0 0 1.661h3.945v3.945a.83.83 0 1 0 1.661 0v-3.945h3.945a.83.83 0 1 0 0-1.661h-3.945V23.83a.83.83 0 0 0-.83-.83z"
            transform="translate(25.139 -1.858)"
            stroke="#fff"
            fill="#fff"
            data-name="グループ 3203"
          />
        </G>
      </G>
    </Svg>
  )
}

CreateOrganizationIcon.displayName = "CreateOrganizationIcon"
