import { markTeamNoticeAsRead, getAccountPermission } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
  id: string
}

export const markNotificationAsRead = async ({ accessToken, id }: Params): Promise<undefined> => {
  await execApi(
    () => markTeamNoticeAsRead(accessToken, { id, appInfo }),
    (_) => undefined,
    {
      identifiers: ["markNotificationAsRead", "markTeamNoticeAsRead"],
    }
  )

  return undefined
}

export const getCanEditApplicantInfor = async ({ accessToken, id }: Params): Promise<boolean> => {
  const accountPermission = await getAccountPermission(accessToken, {
    input: { teamId: id },
    appInfo: appInfo,
  }).then((res) => res.data?.getPermissionAccount)

  return (accountPermission?.order.canTransferApplicant ?? false) && !(accountPermission?.order.canCreateOrder ?? false)
}
