import { memo, useCallback, useEffect } from "react"
import { GroupInOrganizationEditor } from "src/components/parts/organizationTab/GroupInOrganizationEditor"
import { useFetcher } from "src/hooks/useFetcher"
import { postGroupInOrganization } from "src/queries/organization/postGroupInOrganization"
import { useGroupEditorData } from "src/recoil/hooks/organization/group/useGroupEditorData"
import { CreateOrEditGroupStackScreenProps } from "src/types.d"
import { CreateOrEditGroupScreens } from "src/constants/CreateOrEditGroupScreens"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { CustomAlert } from "src/utils/CustomAlert"

export type AddGroupMemberParams = {
  organizationId: string
}

type Props = CreateOrEditGroupStackScreenProps<typeof CreateOrEditGroupScreens.AddGroupInOrganization>

export const AddGroupInOrganization = memo<Props>(
  ({
    navigation,
    route: {
      params: { organizationId },
    },
  }) => {
    const { value: tokens } = useAsyncSelector(tokensState)
    const {
      groupName,
      setGroupName,
      searchText,
      debouncedSearchText,
      setSearchText,
      toggleGroupLeader,
      deleteMember,
      recoverMember,
      mergedMembers,
      resetState,
      refresh,
      totalMember,
      isMembersGroupLoading,
      loadMoreMembers,
      setDefaultGroupMembers,
      filteredMembers,
    } = useGroupEditorData()

    const postGroup = useCallback(async () => {
      if (tokens == null) return
      const result = await postGroupInOrganization({
        accessToken: tokens.accessToken,
        organizationId,
        groupName,
        members: mergedMembers.filter((member) => !member.isDeleteFlg),
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      CustomAlert.alert("完了", "グループを作成しました。")
      refresh()
      navigation.goBack()
    }, [tokens, organizationId, mergedMembers, groupName, refresh, navigation])
    const { fetch, isFetching } = useFetcher(postGroup)

    const submit = useCallback(async () => {
      await fetch()
    }, [fetch])

    useEffect(() => {
      return resetState
    }, [resetState])

    return (
      <GroupInOrganizationEditor
        organizationId={organizationId}
        name={groupName}
        members={filteredMembers}
        searchDebouce={debouncedSearchText}
        searchText={searchText}
        onSearchTextChange={setSearchText}
        isMemberSubmitting={isFetching}
        onNameChange={setGroupName}
        recoverMember={recoverMember}
        deleteMember={deleteMember}
        toggleLeader={toggleGroupLeader}
        onSubmit={submit}
        totalMember={totalMember}
        loadMoreMembers={loadMoreMembers}
        isMembersGroupLoading={isMembersGroupLoading}
        setDefaultGroupMembers={setDefaultGroupMembers}
      />
    )
  }
)
