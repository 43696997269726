import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react"
import { updateMemberDetail } from "src/apis/organization/updateMemberDetail"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useFetcher } from "src/hooks/useFetcher"
import { organizationMemberDetailSelectorFamily } from "src/recoil/selectors/organization/organizationMemberDetailSelectorFamily"
import { MemberType } from "src/types/organization/OrganizationMember"
import { CustomAlert } from "src/utils/CustomAlert"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { useRefresher } from "src/hooks/useRefresher"
import { organizationMemberDetailRequestIdState } from "src/recoil/atoms/organization/organizationMemberDetailRequestIdState"
import { groupDetailRequestIdState } from "src/recoil/atoms/organization/group/groupDetailRequestIdState"
import { organizationMembersRequestIdState } from "src/recoil/atoms/organization/organizationMembersRequestIdState"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { isEmptyString, validateKanas, validateNumbers } from "src/utils/validate"

type Params = {
  organizationId: string
  memberId: string
  gotoMemberDetail: (imageUrl?: string) => void
}

const useCustomState = <T>(value: T): [T, Dispatch<SetStateAction<T | undefined>>] => {
  const [state, setState] = useState<T>()
  const newState = useMemo(() => state ?? value, [state, value])
  return [newState, setState]
}

export const useEditOrganizationMemberDetailData = ({ organizationId, memberId, gotoMemberDetail }: Params) => {
  const { value: organizationMemberDetail } = useAsyncSelector(
    organizationMemberDetailSelectorFamily({ organizationId, memberId })
  )
  const { value: organizationDetail } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))
  const customQuestions = useMemo(() => organizationDetail?.customQuestions || [], [organizationDetail])

  const requestDataResult = useRequestData()
  const { resourceUrl: imageUrl, refreshResourceUrl: refreshImageUrl } = useResource({
    type: "teamMemberImage",
    id: organizationMemberDetail?.id || "",
    size: "normal",
  })
  const [nickname, setNickname] = useCustomState(organizationMemberDetail?.nickname)
  const [kanaNickname, setKanaNickname] = useCustomState(organizationMemberDetail?.nicknameKana)
  const kanaNicknameErrorMessage = useMemo(
    () => (validateKanas(kanaNickname || "") ? "" : "カナで入力してください。"),
    [kanaNickname]
  )
  const [memberType, setMemberType] = useCustomState<MemberType | undefined>(organizationMemberDetail?.memberType)
  const [editableMemberId, setEditableMemberId] = useCustomState(organizationMemberDetail?.editableMemberId)
  const editableMemberIdErrorMessage = useMemo(
    () => (validateNumbers(editableMemberId || "") ? "" : "半角数字で入力してください。"),
    [editableMemberId]
  )
  const [phoneNumber, setPhoneNumber] = useCustomState(organizationMemberDetail?.phoneNumber)
  const [affiliation, setAffiliation] = useCustomState(organizationMemberDetail?.affiliation)
  const [schoolGrade, setSchoolGrade] = useCustomState(organizationMemberDetail?.schoolGrade)

  const areRequiredFieldsFilled = useMemo(
    () => !!(!isEmptyString(nickname) && !isEmptyString(kanaNickname) && memberType && editableMemberId),
    [nickname, kanaNickname, memberType, editableMemberId]
  )
  const isDisabled = useMemo(
    () => !areRequiredFieldsFilled || kanaNicknameErrorMessage !== "" || editableMemberIdErrorMessage !== "",
    [areRequiredFieldsFilled, kanaNicknameErrorMessage, editableMemberIdErrorMessage]
  )

  const refreshMember = useRefresher(organizationMemberDetailRequestIdState)
  const refreshGroupMembers = useRefresher(groupDetailRequestIdState)
  const refreshOrganizationMembers = useRefresher(organizationMembersRequestIdState)
  const refresh = useCallback(() => {
    refreshMember()
    refreshGroupMembers()
    refreshOrganizationMembers()
  }, [refreshMember, refreshGroupMembers, refreshOrganizationMembers])

  const { fetch: execUpdateMemberDetail, isFetching: isUpdatingMemberDetail } = useFetcher(
    useCallback(async () => {
      if (!requestDataResult.isOk) {
        return
      }
      if (!areRequiredFieldsFilled) {
        await CustomAlert.alert("エラー", "必須項目が入力されていません。")
        return
      }
      const newMemberId = Number(editableMemberId)
      const { accessToken, accountId } = requestDataResult.content
      const result = await updateMemberDetail({
        accessToken,
        accountId,
        organizationId,
        memberId,
        nickname,
        kanaNickname,
        memberType,
        newMemberId,
        phoneNumber,
        affiliation,
        schoolGrade,
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      refresh()
      await CustomAlert.alert("完了", "保存しました。")
      gotoMemberDetail(imageUrl)
    }, [
      requestDataResult,
      organizationId,
      memberId,
      nickname,
      kanaNickname,
      memberType,
      editableMemberId,
      phoneNumber,
      affiliation,
      schoolGrade,
      gotoMemberDetail,
      areRequiredFieldsFilled,
      refresh,
      imageUrl,
    ])
  )

  return {
    imageUrl,
    refreshImageUrl,
    organizationMemberDetail,
    nickname,
    setNickname,
    kanaNickname,
    setKanaNickname,
    kanaNicknameErrorMessage,
    memberType,
    setMemberType,
    editableMemberId,
    setEditableMemberId,
    editableMemberIdErrorMessage,
    phoneNumber,
    setPhoneNumber,
    affiliation,
    setAffiliation,
    schoolGrade,
    setSchoolGrade,
    customQuestions,
    execUpdateMemberDetail,
    isUpdatingMemberDetail,
    isDisabled,
  }
}
