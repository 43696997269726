import { ValueOf } from "src/types.d"

export const CreateOrEditGroupScreens = Object.freeze({
  AddGroupInOrganization: "AddGroupInOrganization",
  EditGroupMembers: "EditGroupMembers",
  AddMemberInGroup: "AddMemberInGroup",
  TransferOfApplicantAuthority: "ApplicantAuthorityTransfer",
  ConfirmationTransferApplicantAuthority: "ConfirmationTransferApplicantAuthority",
} as const)

export type CreateOrEditGroupScreens = ValueOf<typeof CreateOrEditGroupScreens>
