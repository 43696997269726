import { atom } from "recoil"
import { getSession, setSession } from "src/storage/session"

export const sessionState = atom<string | undefined>({
  key: "atoms/authorization/sessionState",
  default: getSession(),
  effects: [
    ({ onSet }) => {
      onSet((newValue) => setSession(newValue))
    },
  ],
})
