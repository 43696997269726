import { atomFamily, selectorFamily } from "recoil"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"

export const affiliationStateFamily = atomFamily<string | undefined, string>({
  key: "atoms/organization/myProfile/affiliationStateFamily",
  default: selectorFamily<string | undefined, string>({
    key: "atoms/organization/myProfile/affiliationStateFamily_default",
    get:
      (organizationId) =>
      ({ get }) => {
        const myProfile = get(myProfileSelectorFamily(organizationId))
        return myProfile?.affiliation
      },
  }),
})
