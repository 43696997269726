import { selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { scheduleAnswerCsvDataRequestIdState } from "src/recoil/atoms/csv/scheduleAnswerCsvDataRequestIdState"
import { ScheduleAnswerModel } from "src/types/csv/answerCsvData"
import { selectedMyOrganizationSelector } from "../organization/selectedMyOrganizationSelector"
import { myProfileSelectorFamily } from "../organization/myProfile/myProfileSelectorFamily"
import { getScheduleAnswerCsvData } from "src/apis/csv/getScheduleAnswerCsvData"

export const scheduleAnswerCsvDataSelectorFamily = selectorFamily<
  ScheduleAnswerModel | undefined,
  { mailId: string | undefined }
>({
  key: "selectors/csvData/scheduleAnswerCsvDataSelectorFamily",
  get:
    ({ mailId }) =>
    async ({ get }) => {
      get(scheduleAnswerCsvDataRequestIdState)
      if (mailId == undefined) return
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) {
        return
      }
      const selectedMyTeam = get(selectedMyOrganizationSelector)
      if (selectedMyTeam == null) return
      const teamId = selectedMyTeam.id
      const myProfile = get(myProfileSelectorFamily(teamId))
      const memberId = myProfile?.memberId
      if (memberId == null) return
      const result = await getScheduleAnswerCsvData({ accessToken, teamId, mailId, memberId })
      if (!result.isOk) {
        return
      }
      return result.content
    },
})
