import { ScheduleAnswerType } from "src/constants/ScheduleAnswerType"
import { useTeamMemberContacts } from "src/recoil/hooks/screens/contact/useTeamMemberContacts"
import { useMemo } from "react"
import { notNull } from "src/utils/typeguards"

export const useContactScheduleAnswerDetailData = (
  contactId: string,
  scheduleId: string,
  scheduleAnswerType: ScheduleAnswerType
) => {
  const { scheduleAnswers, isLoading, refreshTeamMemberContacts: refresher } = useTeamMemberContacts(contactId)
  const members = useMemo(
    () => scheduleAnswers[Number(scheduleId)][scheduleAnswerType].members.filter(notNull),
    [scheduleAnswers, scheduleId, scheduleAnswerType]
  )

  return {
    members,
    isLoading,
    refresher,
  }
}
