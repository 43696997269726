import { FC } from "react"
import Svg, { Path } from "react-native-svg"

export const CancelWithCircleIcon: FC = () => (
  <Svg width="30" height="30" viewBox="0 0 30 30" fill="none">
    <Path
      d="M14.9987 0.832031C7.19144 0.832031 0.832031 7.19144 0.832031 14.9987C0.832031 22.806 7.19144 29.1654 14.9987 29.1654C22.806 29.1654 29.1654 22.806 29.1654 14.9987C29.1654 7.19144 22.806 0.832031 14.9987 0.832031ZM14.9987 3.66536C21.2747 3.66536 26.332 8.72269 26.332 14.9987C26.332 21.2747 21.2747 26.332 14.9987 26.332C8.72269 26.332 3.66536 21.2747 3.66536 14.9987C3.66536 8.72269 8.72269 3.66536 14.9987 3.66536ZM10.3337 8.3304L8.3304 10.3337L12.9954 14.9987L8.3304 19.6637L10.3337 21.667L14.9987 17.002L19.6637 21.667L21.667 19.6637L17.002 14.9987L21.667 10.3337L19.6637 8.3304L14.9987 12.9954L10.3337 8.3304Z"
      fill="#B4B4B4"
    />
  </Svg>
)
