import { atomFamily, selectorFamily } from "recoil"
import { getResourceGetUrl } from "src/apis/resource/getResourceGetUrl"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
// import { myTeamMemberImageUrlsSelector } from "src/recoil/selectors/team/memberImageUrlsSelector"
import { ResourceUnit } from "src/types/resource/ResourceUnit"

export const resourceUrlAtomFamily = atomFamily<string | undefined, ResourceUnit>({
  key: "atoms/image/resourceUrlAtomFamily",
  default: selectorFamily({
    key: "atoms/image/resourceUrlAtomFamily_default",
    get:
      (param) =>
      ({ get }) => {
        const tokens = get(tokensState)
        // const teamMemberImageUrls = get(myTeamMemberImageUrlsSelector)
        if (tokens == null || param.type === "none" || param.init) return undefined

        // if (param.type === "teamMemberImage") {
        //   return teamMemberImageUrls ? teamMemberImageUrls[param.id] : ""
        // }
        return getResourceGetUrl({ accessToken: tokens.accessToken, ...param })
      },
  }),
})
