import { Result } from "src/utils/Result"
import { rejectPreTeamMember } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type DeclineInvitationsRequestParams = {
  accessToken: string
  organizationId: string
  pendingMemberIds: string[]
  isProcessAll: boolean
}

type DeclineInvitationsSuccess = undefined

type DeclineInvitationsFailure = {
  message: string
}

export const declineInvitations = async ({ accessToken, organizationId, pendingMemberIds, isProcessAll }: DeclineInvitationsRequestParams): Promise<Result<DeclineInvitationsSuccess, DeclineInvitationsFailure>> => {
  return execApi(
    () => rejectPreTeamMember(accessToken, {
      input: {
        teamId: organizationId,
        preMemberIds: isProcessAll ? [] : pendingMemberIds,
        isProcessAll: isProcessAll
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["declineInvitations", "rejectPreTeamMember"]
    }
  )
}
