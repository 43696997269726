import { atom } from "recoil"
import { TeamMemberOverview } from "src/apis/organization/getTeamMembers"
import { OrganizationMemberOverview } from "src/types/organization/OrganizationMember"

export const teamMemberState = atom<TeamMemberOverview[]>({
  key: "atoms/organization/teamMemberState",
  default: undefined,
})

export const oneMember = atom<OrganizationMemberOverview>({
  key: "atoms/organization/oneMember",
  default: undefined,
})
