import type { HeaderBackButtonProps } from "@react-navigation/elements"
import { useNavigation } from "@react-navigation/native"
import { useCallback } from "react"
import { StyleSheet } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { Colors } from "src/constants/Colors"
import { Screens } from "src/constants/Screens"

const styles = StyleSheet.create({
  container: {
    width: 45,
    height: 45,
    justifyContent: "center",
    alignItems: "center",
  },
})

export const CloseModalHeaderLeftButton = ({ onPress, canGoBack }: HeaderBackButtonProps) => {
  const navigation = useNavigation()

  const goBack = useCallback(() => {
    canGoBack ? onPress?.() : navigation.reset({ index: 0, routes: [{ name: Screens.Root }] })
  }, [canGoBack, onPress, navigation])

  return (
    <Button onPress={goBack} style={styles.container}>
      <RemoveIcon fill={Colors.orange} />
    </Button>
  )
}

export const CloseModalHeaderLeftDisableButton = () => {
  return (
    <Button disabled={true} style={styles.container}>
      <RemoveIcon fill={Colors.orange} />
    </Button>
  )
}

CloseModalHeaderLeftButton.displayName = "CloseModalHeaderLeftButton"
