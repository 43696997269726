import { useCallback, useMemo } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { memberInvitationSelectorFamily } from "src/recoil/selectors/organization/memberInvitationSelectorFamily"
import { CustomAlert } from "src/utils/CustomAlert"
import { Share } from "react-native"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { getOrganizationInvitationMessage } from "src/apis/organization/getOrganizationInvitationMessage"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { Analytics } from "src/tags/analytics/Analytics"

export const useEmailInvitationResultData = (id: string) => {
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const { value: emailInvitationResultData, isLoading: isEmailInvitationResultDataLoading } = useAsyncSelector(
    memberInvitationSelectorFamily(id)
  )
  const { resourceUrl: thumbnailImageUrl } = useResource({ type: "teamImage", id, size: "thumbnail" })
  const organizationId = useMemo(() => emailInvitationResultData?.organizationId, [emailInvitationResultData])
  const organizationName = useMemo(() => emailInvitationResultData?.organizationName, [emailInvitationResultData])
  const invitationCode = useMemo(() => emailInvitationResultData?.invitationCode, [emailInvitationResultData])
  const openShareSheet = useCallback(async () => {
    if (accessToken == null || organizationId == null) {
      return
    }
    await Analytics.shareInvitation(organizationId)

    const message = await getOrganizationInvitationMessage({ accessToken, organizationId })
    if (!message.isOk) {
      await CustomAlert.alert("エラー", message.error.message)
      return
    }

    try {
      await Share.share(message.content)
    } catch (_error) {
      await CustomAlert.alert("エラー", "シェアに失敗しました")
    }
  }, [accessToken, organizationId])

  return {
    thumbnailImageUrl,
    organizationName,
    invitationCode,
    isEmailInvitationResultDataLoading,
    openShareSheet,
  }
}
