import { atom } from "recoil"

export const pendingInvitationsRequestIdState = atom({
  key: "atoms/organization/pendingInvitationsRequestIdState",
  default: 0,
})

export const pendingInvitationsConcatRequestIdState = atom({
  key: "atoms/organization/pendingInvitationsConcatRequestIdState",
  default: 0,
})
