import { useCallback, useEffect, useMemo, useState } from "react"
import { acceptInvitations as acceptInvitations } from "src/apis/pandingInvitations/acceptInvitations"
import { declineInvitations } from "src/apis/pandingInvitations/declineInvitations"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { useFetcher } from "src/hooks/useFetcher"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import {
  pendingInvitationsConcatRequestIdState,
  pendingInvitationsRequestIdState,
} from "src/recoil/atoms/organization/pendingInvitationsRequestIdState"
import { pendingInvitationsConcatSelectorFamily } from "src/recoil/selectors/organization/pendingInvitationsSelectorFamily"
import { CustomAlert } from "src/utils/CustomAlert"
import { useIncrementRecoilState } from "src/recoil/hooks/useIncrementRecoilState"
import { organizationDetailRequestIdState } from "src/recoil/atoms/organization/organizationDetailRequestIdState"
import { organizationMembersRequestIdState } from "src/recoil/atoms/organization/organizationMembersRequestIdState"
import { useBottomTabBadgeData } from "src/recoil/hooks/bottomTab/useBottomTabBadgeData"
import { useRefresher } from "src/hooks/useRefresher"

type Params = {
  organizationId: string
  joinCount?: number
  closeModal: () => void
}
export const usePendingInvitationsData = ({ organizationId, joinCount, closeModal }: Params) => {
  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const [selectedMemberIDs, setSelectedMemberIDs] = useState<string[]>([])
  const toggleSelectedMember = useCallback((id: string) => {
    setSelectedMemberIDs((prev) => {
      const next = [...prev]
      if (prev.includes(id)) {
        return next.filter((item) => item !== id)
      } else {
        next.push(id)
        return next
      }
    })
  }, [])

  const [hasFetchedPendingInvitations, setHasFetchedPendingInvitations] = useState(true)
  const [nextTokens, setNextTokens] = useState<string[]>([])
  useEffect(() => {
    setNextTokens([])
  }, [organizationId, setNextTokens])
  const params = useMemo(
    () => ({
      organizationId: organizationId,
    }),
    [organizationId]
  )
  const { value: pendingInvitations, isLoading: isPendingInvitationsLoading } = useAsyncSelector(
    pendingInvitationsConcatSelectorFamily({
      ...params,
      nextTokens,
      isGetAll: false,
    })
  )
  const pendingMembers = useMemo(() => pendingInvitations?.pendingMembers, [pendingInvitations])
  const _refreshPendingInvitationsConcat = useRefresher(pendingInvitationsConcatRequestIdState)
  const refreshAllPendingInvitations = useIncrementRecoilState(pendingInvitationsRequestIdState)
  const refreshOrganizationDetail = useIncrementRecoilState(organizationDetailRequestIdState)
  const refreshOrganizationMembers = useIncrementRecoilState(organizationMembersRequestIdState)

  const selectAll = useCallback(() => {
    if (pendingMembers == null) {
      return
    }
    setSelectedMemberIDs(pendingMembers.map((m) => m.id))
  }, [pendingMembers])
  const unselectAll = useCallback(() => {
    setSelectedMemberIDs([])
  }, [])
  const [prevIsSelectAll, setPrevIsSelectAll] = useState(true)
  const isSelectAll = useMemo(() => {
    let initialized = false
    if (selectedMemberIDs.length !== pendingMembers?.length) {
      initialized = true
    }
    if (!isPendingInvitationsLoading && pendingInvitations && !hasFetchedPendingInvitations) {
      setHasFetchedPendingInvitations(true)
      if (initialized && !prevIsSelectAll) {
        selectAll()
      }
    }

    setPrevIsSelectAll(initialized)
    return initialized
  }, [
    selectedMemberIDs.length,
    pendingMembers?.length,
    isPendingInvitationsLoading,
    pendingInvitations,
    hasFetchedPendingInvitations,
    prevIsSelectAll,
    selectAll,
  ])

  const refreshPendingInvitations = useCallback(() => {
    _refreshPendingInvitationsConcat()
    setNextTokens([])
    unselectAll()
  }, [_refreshPendingInvitationsConcat, unselectAll])
  const refreshOrganizationDetailPage = useCallback(() => {
    refreshAllPendingInvitations()
    refreshOrganizationDetail()
    refreshOrganizationMembers()
  }, [refreshAllPendingInvitations, refreshOrganizationDetail, refreshOrganizationMembers])

  const applyOverallAction = useCallback(() => {
    isSelectAll ? selectAll() : unselectAll()
  }, [isSelectAll, selectAll, unselectAll])
  const overallActionLabel = useMemo(() => (isSelectAll ? "すべて選択" : "すべて解除"), [isSelectAll])
  const { refreshHasPreTeamMember } = useBottomTabBadgeData()
  const { fetch: accept, isFetching: isAccepting } = useFetcher(
    useCallback(async () => {
      if (accessToken == null) {
        return
      }
      let pendingMemberCount = selectedMemberIDs.length
      if (!isSelectAll && joinCount) {
        if (joinCount > pendingMemberCount) {
          pendingMemberCount = joinCount
        }
      }

      const isConfirmed = await CustomAlert.confirm("確認", `${pendingMemberCount}人を承認します。`)
      if (!isConfirmed) {
        return
      }
      const result = await acceptInvitations({
        accessToken,
        organizationId,
        pendingMemberIds: selectedMemberIDs,
        isProcessAll: !isSelectAll,
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
      } else {
        await CustomAlert.alert("完了", "参加申請を承認しました。")
        refreshOrganizationDetailPage()
        refreshHasPreTeamMember()
        closeModal()
      }
    }, [
      accessToken,
      selectedMemberIDs,
      isSelectAll,
      joinCount,
      organizationId,
      refreshOrganizationDetailPage,
      refreshHasPreTeamMember,
      closeModal,
    ])
  )
  const { fetch: decline, isFetching: isDeclining } = useFetcher(
    useCallback(async () => {
      if (accessToken == null) {
        return
      }
      let pendingMemberCount = selectedMemberIDs.length
      if (!isSelectAll && joinCount) {
        if (joinCount > pendingMemberCount) {
          pendingMemberCount = joinCount
        }
      }

      const isConfirmed = await CustomAlert.confirm(
        "確認",
        `${pendingMemberCount}人を拒否します。\n拒否した参加申請は削除され、元に戻すことはできません。\n拒否されたことは申請者に通知されます。 \n本当に拒否しますか？`
      )
      if (!isConfirmed) {
        return
      }
      const result = await declineInvitations({
        accessToken,
        organizationId,
        pendingMemberIds: selectedMemberIDs,
        isProcessAll: !isSelectAll,
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
      } else {
        await CustomAlert.alert("完了", "参加申請を拒否しました。")
        refreshOrganizationDetailPage()
        refreshHasPreTeamMember()
        closeModal()
      }
    }, [
      accessToken,
      selectedMemberIDs,
      isSelectAll,
      joinCount,
      organizationId,
      refreshOrganizationDetailPage,
      refreshHasPreTeamMember,
      closeModal,
    ])
  )
  const isBlockingAction = useMemo(
    () => isPendingInvitationsLoading || isAccepting || isDeclining,
    [isPendingInvitationsLoading, isAccepting, isDeclining]
  )

  return {
    isPendingInvitationsLoading,
    pendingInvitations,
    pendingMembers,
    selectedMemberIDs,
    overallActionLabel,
    isAccepting,
    isDeclining,
    isBlockingAction,
    toggleSelectedMember,
    applyOverallAction,
    accept,
    decline,
    refreshPendingInvitations,
    setNextTokens,
    setHasFetchedPendingInvitations,
  }
}
