import type { ValueOf } from "src/types.d"

export const CreateNewContactErrors = Object.freeze({
  ContactTypeIsEmpty: "ContactTypeIsEmpty",
  NotEdited: "NotEdited",
  ReceiversAreEmpty: "ReceiversAreEmpty",
  TitleIsEmpty: "TitleIsEmpty",
  BodyIsEmpty: "BodyIsEmpty",
  SurveyIsEmpty: "SurveyIsEmpty",
  CandidatesAreEmpty: "CandidatesAreEmpty",
  InvalidState: "InvalidState",
  InvalidReservationDate: "InvalidReservationDate",
  InvalidDeadlineDate: "InvalidDeadlineDate",
  InvalidReminderDate: "InvalidReminderDate",
} as const)

export type CreateNewContactErrors = ValueOf<typeof CreateNewContactErrors>
