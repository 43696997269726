import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { informationsRequestIdState } from "src/recoil/atoms/settings/informationsRequestIdState"
import { informationsSelector } from "src/recoil/selectors/settings/informationsSelector"
import { getAsync } from "src/storage/secureStorage/getAsync"
import { deleteAsync } from "src/storage/secureStorage/deleteAsync"
import { markAsReadAdminNotice } from "src/queries/settings/markAsReadAdminNotice"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { useAsyncState } from "src/hooks/useAsyncState"
import { useEffect, useMemo } from "react"
import { readSystemInformationsState } from "src/recoil/atoms/bottomTab/readSystemInformationsState"
const STORE_KEY = "readSystemInformationsState"
export const useInformationData = () => {
  const insets = useSafeAreaInsets()
  const [readSystemInformations, setReadSystemInformations] = useAsyncState(readSystemInformationsState)
  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const { value: informations, isLoading } = useAsyncSelector(informationsSelector)
  const refreshInformations = useRefresher(informationsRequestIdState)
  useEffect(() => {
    const fetchNoticeIds = async () => {
      const noticeIdsStorage: string | undefined = await getAsync(STORE_KEY)
      if (accessToken && noticeIdsStorage) {
        const noticeIdsArray: string[] = JSON.parse(noticeIdsStorage)
        let idsCount = 0
        for (const noticeId of noticeIdsArray) {
          try {
            await markAsReadAdminNotice({ accessToken, adminNoticeId: noticeId })
            setReadSystemInformations((currentSet) => {
              const newSet = new Set(currentSet)
              newSet.add(noticeId)
              return newSet
            })
            idsCount++
          } catch (error) {
            console.error(error)
          }
        }
        if (idsCount === noticeIdsArray.length) {
          await deleteAsync(STORE_KEY)
        }
      }
    }
    fetchNoticeIds()
  }, [accessToken, setReadSystemInformations])
  return {
    insets,
    informations,
    isLoading,
    refreshInformations,
  }
}
