import { useMemo, useRef } from "react"
import { RecoilValue, useRecoilValueLoadable } from "recoil"

export const useAsyncSelector = <T>(recoilValue: RecoilValue<T>) => {
  const loadable = useRecoilValueLoadable(recoilValue)
  const cacheRef = useRef<T>()
  const value = useMemo(() => {
    if (loadable.state === "hasValue") {
      cacheRef.current = loadable.contents
    }
    if (loadable.state === "hasError") {
      cacheRef.current = undefined
    }
    return cacheRef.current
  }, [loadable])
  const error = useMemo(() => {
    return loadable.errorMaybe()
  }, [loadable])
  const isLoading = useMemo(() => loadable.state === "loading", [loadable])

  return {
    value,
    isLoading,
    error,
  }
}
