import { FC } from "react"
import Svg, { G, Path } from "react-native-svg"

type Props = {
  size?: number
}

export const OrganizationMigrateIcon: FC<Props> = ({ size = 129.125 }) => (
  <Svg width={size} height={size * 0.2019} viewBox="0 0 129.125 26.076">
    <G data-name="グループ 3355">
      <Path
        data-name="合体 8"
        d="M5660.866 10582.136v-1.67a3 3 0 0 0-3-2.992h-1.927a3 3 0 0 0-2.993 2.992v1.67a.863.863 0 1 1-1.726 0v-2.526a4.988 4.988 0 0 0-4.972-4.972h-2.917a4.988 4.988 0 0 0-4.972 4.972v2.526a.863.863 0 1 1-1.726 0v-1.67a3 3 0 0 0-2.993-2.992h-1.929a3 3 0 0 0-2.993 2.992v1.67a.862.862 0 1 1-1.724 0v-1.67a4.722 4.722 0 0 1 4.717-4.717h1.929a4.7 4.7 0 0 1 3.43 1.48 6.74 6.74 0 0 1 6.261-4.315h2.917a6.74 6.74 0 0 1 6.261 4.315 4.706 4.706 0 0 1 3.43-1.48h1.927a4.723 4.723 0 0 1 4.719 4.717v1.67a.862.862 0 1 1-1.724 0zm-7.069-10.437a3.111 3.111 0 1 1 3.111 3.11 3.115 3.115 0 0 1-3.108-3.109zm1.724 0a1.387 1.387 0 1 0 1.388-1.389 1.389 1.389 0 0 0-1.388 1.39zm-25.95 0a3.112 3.112 0 1 1 3.112 3.11 3.116 3.116 0 0 1-3.113-3.109zm1.726 0a1.387 1.387 0 1 0 1.387-1.389 1.388 1.388 0 0 0-1.384 1.39zm9.233-4.434a4.266 4.266 0 1 1 4.265 4.264 4.269 4.269 0 0 1-4.266-4.264zm1.726 0a2.541 2.541 0 1 0 2.539-2.541 2.543 2.543 0 0 0-2.54 2.541z"
        transform="translate(-5627.001 -10556.923)"
        opacity={0.7}
        fill="#fff"
      />
      <Path
        d="m13.139 13.439-4.43-4.43a1.085 1.085 0 1 0-1.534 1.533l2.619 2.615h-8.71a1.085 1.085 0 0 0 0 2.169H9.77l-2.595 2.6a1.085 1.085 0 0 0 1.534 1.533l4.43-4.43a1.076 1.076 0 0 0 .317-.767.228.228 0 0 0 0-.027.228.228 0 0 0 0-.027 1.085 1.085 0 0 0-.317-.767z"
        transform="translate(50.566 1.864)"
        fill="#fff"
      />
      <G data-name="グループ 3349">
        <Path
          data-name="合体 9"
          d="M5667.634 10585.5v-1.963a3.559 3.559 0 0 0-3.593-3.517h-2.313a3.557 3.557 0 0 0-3.59 3.517v1.963a1.036 1.036 0 0 1-2.071 0v-2.97a5.744 5.744 0 0 0-1.753-4.127 5.986 5.986 0 0 0-4.211-1.717h-3.5a5.983 5.983 0 0 0-4.211 1.717 5.743 5.743 0 0 0-1.754 4.127v2.97a1.036 1.036 0 0 1-2.071 0v-1.963a3.557 3.557 0 0 0-3.59-3.517h-2.314a3.558 3.558 0 0 0-3.591 3.517v1.963a1.034 1.034 0 0 1-2.068 0v-1.963a5.61 5.61 0 0 1 5.66-5.545h2.314a5.7 5.7 0 0 1 4.115 1.741 7.886 7.886 0 0 1 1.836-2.763 8.06 8.06 0 0 1 5.675-2.311h3.5a8.058 8.058 0 0 1 5.674 2.311 7.879 7.879 0 0 1 1.836 2.763 5.7 5.7 0 0 1 4.115-1.741h2.313a5.611 5.611 0 0 1 5.662 5.545v1.963a1.035 1.035 0 0 1-2.069 0zm-8.482-12.271a3.733 3.733 0 1 1 3.734 3.656 3.7 3.7 0 0 1-3.734-3.66zm2.068 0a1.665 1.665 0 1 0 1.666-1.632 1.651 1.651 0 0 0-1.665 1.628zm-31.137 0a3.734 3.734 0 1 1 3.733 3.656 3.7 3.7 0 0 1-3.732-3.66zm2.07 0a1.665 1.665 0 1 0 1.664-1.632 1.649 1.649 0 0 0-1.663 1.628zm11.079-5.212a5.12 5.12 0 1 1 5.118 5.013 5.072 5.072 0 0 1-5.117-5.017zm2.07 0a3.049 3.049 0 1 0 3.047-2.986 3.022 3.022 0 0 0-3.049 2.982z"
          transform="translate(-5545.114 -10560.434)"
          fill="#fff"
        />
        <Path
          d="M27.268 23a.522.522 0 0 0-.522.522V26h-1.224a.522.522 0 0 0 0 1.045h1.223v2.485a.522.522 0 1 0 1.045 0v-2.481h1.223a.522.522 0 0 0 0-1.045h-1.222v-2.482a.522.522 0 0 0-.523-.522z"
          transform="translate(99.589 -20.923)"
          fill="#fff"
        />
        <Path
          data-name="np_plus_2048206_000000"
          d="M26.65 23a.38.38 0 0 0-.38.38v1.805h-.89a.38.38 0 0 0 0 .76h.89v1.805a.38.38 0 1 0 .76 0v-1.805h.89a.38.38 0 1 0 0-.76h-.89V23.38a.38.38 0 0 0-.38-.38z"
          transform="translate(94.825 -23)"
          fill="#fff"
        />
        <Path
          data-name="np_plus_2048206_000000"
          d="M26.65 23a.38.38 0 0 0-.38.38v1.805h-.89a.38.38 0 0 0 0 .76h.89v1.805a.38.38 0 1 0 .76 0v-1.805h.89a.38.38 0 1 0 0-.76h-.89V23.38a.38.38 0 0 0-.38-.38z"
          transform="translate(49.825 -13.87)"
          fill="#fff"
        />
        <Path
          data-name="np_plus_2048206_000000"
          d="M26.989 23a.458.458 0 0 0-.458.458v2.176h-1.073a.458.458 0 0 0 0 .916h1.073v2.176a.458.458 0 0 0 .916 0V26.55h1.073a.458.458 0 0 0 0-.916h-1.073v-2.176a.458.458 0 0 0-.458-.458z"
          transform="translate(53.825 -18.923)"
          fill="#fff"
        />
      </G>
    </G>
  </Svg>
)

OrganizationMigrateIcon.displayName = "OrganizationImportIcon"
