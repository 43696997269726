export const SHOW_CONTENT_CREATE = {
  SUCCESS: {
    title: "団体有料版申込完了",
    header: "団体有料版の申込受付を\n完了しました。",
    content: "※お申込の内容に変更がある場合は、入金を行う前までに団体詳細画面より申請を行ってください。",
    button: "団体有料版詳細を開く",
  },
  FAIL_DB: {
    title: "団体有料版申込エラー",
    header: "団体有料版の申込受付に\n失敗しました。",
    content: "通信環境をご確認いただき「戻る」ボタンを押して申込画面より再度申込を行ってください。",
    button: "戻る",
  },
  FAIL_MAIL: {
    title: "団体有料版申込エラー",
    header: "団体有料版の\n申込受付完了メールの送信に\n失敗しました。",
    content: "通信環境をご確認いただき、以下のボタンよりメールの再送信をお試しください。",
    button: "メールを再送信する",
  },
}

export const SHOW_CONTENT_EXTEND = {
  SUCCESS: {
    title: "継続申込完了",
    header: "継続の申込が\n完了しました。",
    content: "",
    button: "団体有料版詳細へ戻る",
  },
  FAIL_DB: {
    title: "継続申込エラー",
    header: "継続申込の受付に\n失敗しました。",
    content: "通信環境をご確認いただき「戻る」ボタンを押して団体申込プラン更新画面より再度申込を行ってください。",
    button: "戻る",
  },
  FAIL_MAIL: {
    title: "団体有料版申込エラー",
    header: "継続申込受付メールの送信に\n失敗しました。",
    content: "通信環境をご確認いただき、以下のボタンよりメールの再送信をお試しください。",
    button: "メールを再送信する",
  },
}

export const SHOW_CONTENT_CANCEL = {
  SUCCESS: {
    title: "有料版申込キャンセル完了",
    header: "有料版の申込を\nキャンセルしました。",
    content: "",
    button: "団体管理へ戻る",
  },
  FAIL_DB: {
    title: "有料版申込キャンセルエラー",
    header: "キャンセル申込の受付に\n失敗しました。",
    content: "通信環境をご確認いただき「戻る」ボタンを押して団体有料版申込キャンセル画面より再度申込を行ってください。",
    button: "戻る",
  },
  FAIL_MAIL: {
    title: "有料版申込キャンセルエラー",
    header: "申込キャンセル完了メールの\n送信に失敗しました。",
    content: "通信環境をご確認いただき、以下のボタンよりメールの再送信をお試しください。",
    button: "メールを再送信する",
  },
}
export const SHOW_CONTENT_UPDATE_PLAN = {
  SUCCESS: {
    title: "プラン変更申込完了",
    header: "プラン変更の申込が\n完了しました。",
    content:
      "変更前の申込をキャンセルし、有料版の新規申込を行いました。\n※ご登録いただいているメールアドレス宛に、らくらく連絡網＋より申込キャンセルと新規申込の２通のメールが届きます。",
    button: "団体有料版詳細へ戻る",
  },
  FAIL_DB: {
    title: "プラン変更申込エラー",
    header: "プラン変更申込の受付に\n失敗しました。",
    content: "通信環境をご確認いただき「戻る」ボタンを押して団体申込プラン変更画面より再度申込を行ってください。",
    button: "戻る",
  },
  FAIL_MAIL: {
    title: "プラン変更申込エラー",
    header: "プラン変更申込のメール送信に\n失敗しました。",
    content: "通信環境をご確認いただき、以下のボタンよりメールの再送信をお試しください。",
    button: "メールを再送信する",
  },
}
export const SHOW_CONTENT_INQUIRY = {
  SUCCESS: {
    title: "有料版お問い合わせ完了",
    header: "お問い合わせの内容を\n送信しました。",
    content: "",
    button: "団体有料版詳細へ戻る",
  },
  FAIL_DB: {
    title: "有料版お問い合わせエラー",
    header: "お問い合わせ内容の送信に\n失敗しました。",
    content: "通信環境をご確認いただき「戻る」ボタンを押してお問い合わせ画面より再度申込を行ってください。",
    button: "戻る",
  },
  FAIL_MAIL: {
    title: "",
    header: "",
    content: "",
    button: "",
  },
}
