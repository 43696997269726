import { selectorFamily } from "recoil"
import { jobsSelector } from "./jobsSelector"
import { listSchoolMaster } from "src/aws/customAPI"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"

export type School = {
  id: string
  label: string
  labelKana: string
}

export const schoolsSelectorFamily = selectorFamily<School[] | undefined, string | undefined>({
  key: "selectors/account/schoolsSelectorFamily",
  get:
    (jobId?: string) =>
    async ({ get }) => {
      const tokens = get(tokensState)
      if (tokens == null) {
        return
      }

      const jobs = get(jobsSelector)
      const job = jobs.find((job) => job.id === jobId)
      if (job == null || !job.isSchool) {
        return undefined
      }

      const schoolMaster = await listSchoolMaster(tokens.accessToken, {
        input: {
          type: getSchoolType(job.label),
        },
      })
      return schoolMaster.data.listSchoolMaster.items
    },
})

const getSchoolType = (jobLabel: string) => {
  return jobLabel.includes("大学生")
    ? "10"
    : jobLabel.includes("大学院生")
    ? "20"
    : jobLabel.includes("短大生")
    ? "30"
    : jobLabel.includes("専門学校生")
    ? "40"
    : ""
}
