import { memo, useCallback, useMemo } from "react"
import { ScrollView, StyleSheet, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { RootTabs } from "src/constants/RootTabs"
import { schoolGradeOptions } from "src/constants/SchoolGradeOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useRegisterProfileData } from "src/recoil/hooks/screens/useRegisterProfileData"
import { RootStackScreenProps } from "src/types.d"
import { Checkbox } from "src/components/parts/Checkbox"
import { TeamCustomQuestion } from "src/aws/API"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { ValidationErrorMessage } from "src/components/parts/ValidationErrorMessage"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type RegisterProfileParams = {
  organizationId: string
  organizationName: string
  requirementForMemberProfile?: string | null
  customQuestions?: TeamCustomQuestion[] | null
}

type Props = RootStackScreenProps<typeof Screens.RegisterProfile>

export const RegisterProfile = memo<Props>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const gotoOrganizationTab = useCallback(
    () =>
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: { screen: OrganizationManagerScreens.OrganizationManager },
      }),
    [navigation]
  )
  const { organizationId, organizationName, requirementForMemberProfile, customQuestions } = useMemo(
    () => route.params,
    [route.params]
  )
  const {
    account,
    nickname,
    setNickname,
    kanaNickname,
    setKanaNickname,
    kanaNicknameErrorMessage,
    join,
    isProcessing,
    email,
    isShowEmail,
    setIsShowEmail,
    phoneNumber,
    setPhoneNumber,
    isShowPhoneNumber,
    setIsShowPhoneNumber,
    affiliation,
    setAffiliation,
    isShowAffiliation,
    setIsShowAffiliation,
    gradeId,
    setGradeId,
    askPhoneNumber,
    askAffiliation,
    askGrade,
    isDisabled,
  } = useRegisterProfileData(
    organizationId,
    organizationName,
    requirementForMemberProfile || null,
    customQuestions || [],
    gotoOrganizationTab
  )
  const insets = useSafeAreaInsets()

  return (
    <CustomKeyboardAvoidingView>
      <ScrollView style={styles.scrollArea}>
        <View style={styles.head}>
          <Text style={styles.orgName}>{organizationName}</Text>
        </View>
        {requirementForMemberProfile && (
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <View style={styles.section}>
              <Text style={[styles.demand, isLargeScreen ? { width: 600 } : {}]}>{requirementForMemberProfile}</Text>
            </View>
            <View
              style={[
                { borderBottomWidth: 1, borderBottomColor: Colors.white2 },
                isLargeScreen ? { width: 600 } : { width: "100%" },
              ]}
            ></View>
          </View>
        )}
        <View style={styles.section}>
          <View style={isLargeScreen ? { width: 600 } : { width: "100%" }}>
            <View style={styles.requiredItemNote}>
              <Text style={styles.requiredItemNoteText}>
                <Text style={styles.requiredItemNoteAsterisk}>※</Text>は必須項目
              </Text>
            </View>
            <ItemLabel
              label="ニックネーム"
              required
              RightComponent={<InputLengthCounter text={nickname} maxLength={128} unit={"字以内"} />}
            />
            <CustomTextInput
              style={styles.input}
              value={nickname}
              onChangeText={setNickname}
              editable={!isProcessing}
              maxLength={128}
            />
            <ItemLabel
              label="フリガナ"
              required
              RightComponent={<InputLengthCounter text={kanaNickname} maxLength={128} unit={"字以内"} />}
            />
            <CustomTextInput
              style={kanaNicknameErrorMessage === "" ? styles.input : styles.inputWithError}
              value={kanaNickname}
              onChangeText={setKanaNickname}
              editable={!isProcessing}
              maxLength={128}
              isError={kanaNicknameErrorMessage !== ""}
            />
            {kanaNicknameErrorMessage !== "" && (
              <ValidationErrorMessage style={styles.validationErrorMessage} message={kanaNicknameErrorMessage} />
            )}
            <ItemLabel label="メールアドレス" />
            <Text style={styles.value}>{email}</Text>
            <View style={styles.checkbox}>
              <Checkbox isChecked={isShowEmail} onPress={() => setIsShowEmail(!isShowEmail)} />
              <Text style={styles.checkboxLabel}>一般メンバーにメールアドレスを公開する</Text>
            </View>
            {askPhoneNumber ? (
              <>
                <ItemLabel
                  label="連絡先電話番号"
                  RightComponent={<InputLengthCounter text={phoneNumber} maxLength={11} unit={"桁"} />}
                />
                <CustomTextInput
                  style={styles.input}
                  value={phoneNumber}
                  onChangeText={setPhoneNumber}
                  editable={!isProcessing}
                  keyboardType="number-pad"
                  maxLength={11}
                />
                <View style={styles.checkbox}>
                  <Checkbox isChecked={isShowPhoneNumber} onPress={() => setIsShowPhoneNumber(!isShowPhoneNumber)} />
                  <Text style={styles.checkboxLabel}>一般メンバーに連絡先電話番号を公開する</Text>
                </View>
              </>
            ) : null}
            {askAffiliation ? (
              <>
                <ItemLabel
                  label="所属（学校・会社等）"
                  RightComponent={<InputLengthCounter text={affiliation} maxLength={255} unit={"字以内"} />}
                />
                <CustomTextInput
                  style={styles.input}
                  value={affiliation}
                  onChangeText={setAffiliation}
                  editable={!isProcessing}
                  maxLength={255}
                />
                <View style={styles.checkbox}>
                  <Checkbox isChecked={isShowAffiliation} onPress={() => setIsShowAffiliation(!isShowAffiliation)} />
                  <Text style={styles.checkboxLabel}>一般メンバーに所属を公開する</Text>
                </View>
              </>
            ) : null}
            {askGrade ? (
              <View style={styles.bottomMargin}>
                <ItemLabel label="学年" />
                <View style={styles.input}>
                  <CustomSelect options={schoolGradeOptions} value={gradeId} onChange={setGradeId} disabled={isProcessing} />
                </View>
              </View>
            ) : null}
          </View>
        </View>
      </ScrollView>
      <View style={[styles.actions, { paddingBottom: insets.bottom + 16 }, isLargeScreen ? { alignItems: "center" } : {}]}>
        <TextButton
          style={isLargeScreen ? { width: 300 } : {}}
          title="団体に参加"
          buttonType={ButtonType.Primary}
          onPress={join}
          isLoading={isProcessing}
          disabled={isDisabled}
        />
      </View>
    </CustomKeyboardAvoidingView>
  )
})

export const registerProfileOptions: ScreenOptions = {
  ...commonHeaderOptions,
  title: "団体内個人設定の登録",
}

RegisterProfile.displayName = "RegisterPersonalSettingsInOrganization"

const styles = StyleSheet.create({
  scrollArea: {
    flex: 1,
  },
  requiredItemNote: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  requiredItemNoteText: {
    fontWeight: "400",
    fontSize: 14,
  },
  requiredItemNoteAsterisk: {
    color: Colors.red,
  },
  actions: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
    padding: 16,
  },
  head: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 8,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  orgName: {
    marginLeft: 16,
  },
  section: {
    padding: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  demand: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.warmGrey,
  },
  input: {
    marginTop: 8,
    marginBottom: 16,
  },
  inputWithError: {
    marginTop: 8,
    marginBottom: 4,
  },
  validationErrorMessage: {
    marginBottom: 16,
  },
  value: {
    marginVertical: 16,
    marginLeft: 8,
  },
  checkbox: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginBottom: 16,
  },
  checkboxLabel: {
    marginLeft: 8,
  },
  bottomMargin: {
    marginBottom: 50,
  },
})
