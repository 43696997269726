import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { ScrollView, StyleSheet, Text, View } from "react-native"
import { DrawerStatus } from "src/components/parts/contactNetworkTab/DrawerStatus"
import { Colors } from "src/constants/Colors"
import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { MainLayout } from "src/layouts/MainLayout"
import { OrganizationManagerStackScreenProps } from "src/types.d"
import { MachingProfileIcon } from "src/components/parts/icons/MatchingProfileIcon"
import { MatchingProfile } from "src/components/parts/matching/MatchingProfile"
import { Button } from "src/components/parts/buttons/Button"
import { EditIcon } from "src/components/parts/icons/EditIcon"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ButtonType, IconButton } from "src/components/parts/buttons/IconButton"
import { BanIcon } from "src/components/parts/icons/BanIcon"
import { useMatchingMyProfileData } from "src/recoil/hooks/matching/useMatchingMyProfileData"
import { Screens } from "src/constants/Screens"
import { AgreeMatchingTermModal } from "./AgreeMatchingTermModal"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { ListIcon } from "src/components/parts/icons/ListIcon"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { acceptMatchingTermState } from "src/recoil/atoms/matching/acceptMatchingTermState"
import { useRecoilState } from "recoil"

export type MyMatchingProfileParams = {
  id?: string
  offerId?: string
  applicationId?: string
  myOrganizationId?: string
  to?: string
  isMyMatching?: boolean
  isFavorite?: boolean
  matchingTeamId?: string
  isApplications?: boolean
}

type Props = OrganizationManagerStackScreenProps<typeof MatchingDrawers.MyMatchingProfile>
export const MyMatchingProfile = memo<Props>(({ navigation, route }) => {
  const isPCScreen = useCheckPCScreen()
  const { id, offerId, applicationId, myOrganizationId, isMyMatching, to, isFavorite, isApplications, matchingTeamId } =
    useMemo(() => route.params ?? "", [route.params])
  const isMyProfile = id === undefined
  const [agreeTerm, setAgreeTerm] = useRecoilState(acceptMatchingTermState)
  const { selectedMyTeam, matchingMyProfile, execBlockMatchingTeam, execDeleteBlockingMatchingTeam } =
    useMatchingMyProfileData(id)
  const onOpenReportModal = useCallback(() => {
    navigation.navigate(Screens.ReportMatchingProfile, { id: id ?? "", teamName: matchingMyProfile?.name ?? "" })
  }, [id, navigation, matchingMyProfile])

  const onOpenCreateOrEditModal = useCallback(() => {
    navigation.navigate(Screens.CreateOrEditMatchingProfile, {
      matchingProfile: matchingMyProfile,
      id: matchingMyProfile?.id ?? selectedMyTeam?.id ?? "",
      selectedMyTeam: selectedMyTeam,
    })
  }, [selectedMyTeam, matchingMyProfile, navigation])

  const goBackLastAction = useCallback(() => {
    if (applicationId) {
      return navigation.navigate(Screens.MatchingThread, {
        applicationId,
        offerId,
        myOrganizationId,
        isMyMatching,
        to,
        isFavorite,
        isApplications,
        matchingTeamId,
      })
    } else if (offerId) {
      return navigation.navigate(MatchingDrawers.MatchingOfferDetail, { id: offerId })
    }
  }, [navigation, applicationId, offerId, myOrganizationId, isMyMatching, to, isFavorite, isApplications, matchingTeamId])

  useEffect(() => {
    if (!isMyProfile) {
      navigation.setOptions({
        headerLeft: () => (
          <>
            {
              <Button onPress={goBackLastAction}>
                <BackIcon fill={Colors.white3} />
              </Button>
            }
          </>
        ),
        headerRight: () => null,
        title: "団体マッチング 団体プロフィール",
      })
    } else {
      navigation.setOptions({
        headerLeft: () => (
          <>
            {
              <Button
                onPress={() => {
                  navigation.goBack()
                }}
              >
                <BackIcon fill={Colors.white3} />
              </Button>
            }
          </>
        ),
        headerRight: () => (
          <>
            {
              <View style={{ width: 20, height: 20, marginRight: 8 }}>
                <Button onPress={(navigation as any).openDrawer}>
                  <ListIcon size={18} fill={Colors.white3} />
                </Button>
              </View>
            }
          </>
        ),
        title: "マッチング管理",
      })
    }
  }, [isMyProfile, navigation, goBackLastAction])

  return (
    <MainLayout>
      <AgreeMatchingTermModal />
      {isPCScreen && !isMyProfile && (
        <View style={[styles.headerPC, { justifyContent: "space-between" }]}>
          <Button onPress={goBackLastAction}>
            <BackIcon fill={Colors.orange}></BackIcon>
          </Button>
          <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
            <Text style={[{ fontSize: 18, fontWeight: "500", textAlign: "center" }]}>団体マッチング 団体プロフィール</Text>
          </View>
          <View style={{ width: 36 }}></View>
        </View>
      )}
      <ScrollView style={styles.main}>
        {isMyProfile ? (
          <View style={{ borderBottomWidth: 1, borderBottomColor: Colors.lightGrey }}>
            <DrawerStatus Icon={<MachingProfileIcon />} label={"マッチング用プロフィール"} />
          </View>
        ) : null}
        {!matchingMyProfile ? (
          isMyProfile ? (
            <View style={[styles.contentWrapper, isPCScreen && styles.paddingContent]}>
              <View style={styles.content}>
                <Text>マッチング用プロフィールが設定されていません。</Text>
                <Text>プロフィールの登録を行ってください。</Text>
              </View>
              <Button style={styles.button} onPress={onOpenCreateOrEditModal}>
                <EditIcon />
                <Text style={styles.registerImageButtonText}>プロフィールを登録</Text>
              </Button>
            </View>
          ) : null
        ) : (
          <View style={styles.contentWrapper}>
            <MatchingProfile matchingProfile={matchingMyProfile} teamId={matchingMyProfile?.id} />
            {isMyProfile ? (
              <Button style={styles.button} onPress={onOpenCreateOrEditModal}>
                <EditIcon />
                <Text style={styles.registerImageButtonText}>プロフィールを編集</Text>
              </Button>
            ) : (
              <View style={[styles.parallelButtonContainer, isPCScreen ? { width: 380 } : { width: "90%" }]}>
                <IconButton
                  buttonType={matchingMyProfile?.isBlocking ? ButtonType.Primary : ButtonType.Secondary}
                  IconComponent={matchingMyProfile?.isBlocking ? <></> : <BanIcon />}
                  title={matchingMyProfile?.isBlocking ? "ブロック解除" : "ブロック"}
                  onPress={matchingMyProfile?.isBlocking ? execDeleteBlockingMatchingTeam : execBlockMatchingTeam}
                />
                <IconButton buttonType={ButtonType.Secondary} IconComponent={<></>} title="通報" onPress={onOpenReportModal} />
              </View>
            )}
          </View>
        )}
      </ScrollView>
    </MainLayout>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  containerLarge: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingLeft: 10,
    paddingRight: 10,
  },
  paddingContent: {
    paddingHorizontal: 0,
    paddingTop: 0,
  },
  contentWrapper: {
    flexDirection: "column",
    alignItems: "center",
    flex: 1,
    paddingBottom: 24,
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    height: 44,
    maxWidth: 300,
    paddingHorizontal: 24,
    paddingVertical: 12,
    backgroundColor: Colors.reddishGrey,
    marginTop: 32,
    borderRadius: 8,
  },
  buttonLabel: {
    color: Colors.orange,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "600",
  },
  content: {
    backgroundColor: Colors.white3,
    padding: 20,
    textAlign: "center",
    width: "100%",
    borderTopColor: Colors.lightGrey,
    borderTopWidth: 0,
    borderBottomColor: Colors.lightGrey,
    borderBottomWidth: 1,
  },
  parallelButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 16,
  },
  registerImageButtonText: {
    color: Colors.orange,
    fontSize: 14,
    fontWeight: "bold",
    paddingLeft: 8,
  },
  headerPC: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: Colors.white3,
    paddingVertical: 8,
    borderColor: Colors.white2,
    borderBottomWidth: 1,
  },
  main: {
    flex: 1,
    backgroundColor: "#faf8f5",
  },
})

const optionStyle = StyleSheet.create({
  headerRight: {
    marginRight: 2,
  },
})
