import dayjs from "dayjs"
import relativeTime from "dayjs/plugin/relativeTime"
import updateLocale from "dayjs/plugin/updateLocale"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FlatList, Image, ListRenderItemInfo, Platform, StyleSheet, Text, View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { ContactItem } from "src/components/parts/ContactItem"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { RootTabs } from "src/constants/RootTabs"
import { Screens } from "src/constants/Screens"
import { HomeListItem, HomeListItemType, useHomeTabData } from "src/recoil/hooks/bottomTab/useHomeTabData"
import { useRestoreLinking } from "src/recoil/hooks/useRestoreLinking"
import { RootTabScreenProps } from "src/types.d"
import { HomeOrganization } from "src/recoil/selectors/home/HomeOrganizationsSelector"
import { Notification } from "src/queries/home/getNotifications"
import { useIsFocused, useNavigation } from "@react-navigation/native"
import { BadgeIcon } from "src/components/parts/icons/BadgeIcon"
import { getCanEditApplicantInfor, markNotificationAsRead } from "src/queries/home/markNotificationAsRead"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { wait } from "src/utils/wait"
import { ButtonType, IconButton } from "src/components/parts/buttons/IconButton"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { defaultContactFilterState } from "src/recoil/atoms/contact/defaultContactFilterState"
import { ContactNetworkDrawers } from "src/constants/ContactNetworkDrawers"
import { BannerAd } from "src/tags/ads/BannerAd"
import { Buckets, TeamNoticeType } from "src/aws/customAPI"
import { requestDataSelector } from "src/recoil/selectors/auth/requestDataSelector"
import { getUnreadThreadCount } from "src/apis/contact/getUnreadThreadCount"
import { adLoadState, isLoadDoneAd } from "src/recoil/atoms/ads/adState"
import { defaultTeam } from "src/constants/defaultTeam"
import { MainLayout } from "src/layouts/MainLayout"
import { HeaderCommon } from "src/components/parts/HeaderCommon"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { useRecoilState } from "recoil"

dayjs.locale("ja")
dayjs.extend(relativeTime)
dayjs.extend(updateLocale)
dayjs.updateLocale("ja", {
  relativeTime: {
    past: "今日",
    s: "今日",
    m: "今日",
    mm: "今日",
    h: "今日",
    hh: "今日",
    d: "1日前",
    dd: "%d日前",
    M: "1ヶ月前",
    MM: "%dヶ月前",
    y: "1年前",
    yy: "%d年前",
  },
})

export const HomeTab = memo<RootTabScreenProps<typeof RootTabs.HomeTab>>(({ navigation }) => {
  const {
    listItems,
    enableNotificationAccordion,
    toggleNotificationAccordion,
    toggleNotificationAccordionText,
    showDeleteAllNotifications,
    deleteAllNotifications,
    updateFavorite,
    refresh,
    isRefreshing,
    refreshContactDetailData,
    setOrganizationId,
    refreshNotifications,
    refreshPendingInvitations,
    favoriteFlgsCache,
    isShowHomeAd,
  } = useHomeTabData()

  useRestoreLinking()
  const [adLoadStateList] = useRecoilState(adLoadState)
  const [firstLoad, setFirstLoad] = useState(true)
  const [listItem, setListItems] = useState<HomeListItem[]>([])
  useEffect(() => {
    setListItems(listItems)
  }, [listItems])
  useEffect(() => {
    if (!isRefreshing) {
      setFirstLoad(false)
    }
  }, [isRefreshing])
  const isFocused = useIsFocused()
  const goToContactDetail = useCallback(
    (organizationId: string, contactId: string) => {
      setOrganizationId(organizationId)
      refreshContactDetailData()
      navigation.navigate(Screens.ContactDetail, { contactId })
    },
    [navigation, setOrganizationId, refreshContactDetailData]
  )

  const goToContactNetworkTab = useCallback(
    (organizationId: string) => {
      setOrganizationId(organizationId)
      navigation.navigate(Screens.Root, { screen: RootTabs.ContactNetworkDrawer })
    },
    [navigation, setOrganizationId]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function getOrganizationImageUrl(organizationId: string) {
    const team = listItem.find((el) => el.itemType === HomeListItemType.Organization && el.organization.id === organizationId)
    return team?.organization?.uriImage
  }

  const ListItem = useCallback(
    (props: ListRenderItemInfo<HomeListItem>) => {
      switch (props.item.itemType) {
        case HomeListItemType.Notification: {
          const { notifications } = props.item
          return (
            <View>
              {Platform.OS !== "web" && isShowHomeAd ? (
                <View style={!isLoadDoneAd("home_top", adLoadStateList) && { height: 0 }}>{BannerAd.HomeTop}</View>
              ) : null}
              <View style={listItems.length === 1 && notifications.length === 0 ? styles.contentContainer : styles.itemWrapper}>
                {listItems.length === 1 && notifications.length === 0 ? (
                  !firstLoad && (
                    <View style={{ alignItems: "center" }}>
                      <Text>所属している団体がありません。</Text>
                      <Text>団体管理から新しく団体を作成するか、</Text>
                      <Text>既存の団体に参加してください。</Text>
                    </View>
                  )
                ) : (
                  <View style={styles.itemContainer}>
                    <View style={styles.itemInner}>
                      <View style={styles.itemTitleContainer}>
                        <Text style={styles.itemTitle}>団体からのお知らせ</Text>
                        {enableNotificationAccordion ? (
                          <Text style={styles.toggleAccordionText} onPress={toggleNotificationAccordion}>
                            {toggleNotificationAccordionText}
                          </Text>
                        ) : null}
                      </View>
                      <View
                        style={[styles.notificationInner, notifications.length > 0 ? styles.notificationInnerBorderTop : null]}
                      >
                        {notifications.length === 0 ? <Text>通知はありません</Text> : null}
                        {notifications.map((item) => (
                          <NotificationItem
                            notification={item}
                            setOrganizationId={setOrganizationId}
                            uriImage={getOrganizationImageUrl(item.organizationId) || undefined}
                            key={item.id}
                            refreshNotifications={refreshNotifications}
                            refreshPendingInvitations={refreshPendingInvitations}
                          />
                        ))}
                      </View>
                      {showDeleteAllNotifications ? (
                        <View style={styles.singleButtonContainer}>
                          <IconButton
                            buttonType={ButtonType.Primary}
                            IconComponent={<RemoveIcon fill={Colors.orange} size={10} />}
                            title="一括削除"
                            onPress={deleteAllNotifications}
                          />
                        </View>
                      ) : null}
                    </View>
                  </View>
                )}
              </View>
              {Platform.OS !== "web" && isShowHomeAd ? (
                <View style={!isLoadDoneAd("home_bottom", adLoadStateList, 1) && { height: 0 }}>
                  {props.index === listItems.length - 1 ? BannerAd.HomeBottom_1 : null}
                </View>
              ) : null}
            </View>
          )
        }
        case HomeListItemType.Organization: {
          const { organization: organization } = props.item
          return (
            <>
              <HomeOrganizationItem
                organization={organization}
                uriImage={organization.uriImage || undefined}
                updateFavorite={updateFavorite}
                favoriteFlgsCache={favoriteFlgsCache}
                goToContactDetail={goToContactDetail}
                goToContactNetworkTab={() => goToContactNetworkTab(organization.id)}
                key={organization.id}
              />
              {Platform.OS !== "web" && isShowHomeAd ? (
                <View style={!isLoadDoneAd("home_bottom", adLoadStateList, 2) && { height: 0 }}>
                  {props.index === listItems.length - 1 ? BannerAd.HomeBottom_2 : null}
                </View>
              ) : null}
            </>
          )
        }
      }
    },
    [
      adLoadStateList,
      listItems.length,
      enableNotificationAccordion,
      toggleNotificationAccordion,
      toggleNotificationAccordionText,
      showDeleteAllNotifications,
      deleteAllNotifications,
      setOrganizationId,
      getOrganizationImageUrl,
      refreshNotifications,
      refreshPendingInvitations,
      updateFavorite,
      favoriteFlgsCache,
      goToContactDetail,
      goToContactNetworkTab,
      firstLoad,
      isShowHomeAd,
    ]
  )

  return (
    <MainLayout>
      <HeaderCommon title="ホーム" needBackIcon={false} />
      <FlatList
        data={listItems}
        renderItem={ListItem}
        style={styles.container}
        contentContainerStyle={styles.contentContainer}
        refreshControl={<CustomRefreshControl refreshing={isRefreshing && isFocused} onRefresh={refresh} />}
      />
    </MainLayout>
  )
})

type NotificationItemProps = {
  notification: Notification
  setOrganizationId: (organizationId: string) => void
  uriImage?: string
  refreshNotifications: () => void
  refreshPendingInvitations: () => void
}
const NotificationItem = memo<NotificationItemProps>(({ notification, setOrganizationId, uriImage, refreshNotifications }) => {
  const navigation = useNavigation()
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const setSelectedOrganization = useSetRecoilState(selectedMyOrganizationState)
  const [isMarkNotificationAsReadDone, setIsMarkNotificationAsReadDone] = useState(false)
  const setDefaultContactFilter = useSetRecoilState(defaultContactFilterState)
  const regex = /[?&]([^=#]+)=([^&#]*)/g
  const params: Record<string, string> = {}
  let match
  while ((match = regex.exec(notification.url))) {
    params[match[1]] = match[2]
  }
  const applicationId = params["applicationId"] ?? ""
  const organizationId = params["organizationId"] ?? ""
  const teamApplication = applicationId.split(".")[0]
  useEffect(() => {
    if (accessToken && !notification.isRead && !isMarkNotificationAsReadDone) {
      setIsMarkNotificationAsReadDone(true)
    }
  }, [accessToken, notification.id, notification.isRead, isMarkNotificationAsReadDone, refreshNotifications])

  const getParamUrl = (url: string, param: string) => {
    const include = url.includes(param)
    if (!include) return ""
    const params = url.split(/([&,?,=])/)
    const index = params.indexOf(param)
    if (params.length < index + 3) return ""
    const value = params[index + 2]
    return value
  }

  const goToNext = useCallback(() => {
    switch (notification.type) {
      case "joinRequested":
        if (accessToken && !notification.isRead) {
          markNotificationAsRead({ accessToken, id: notification.id }).then(() => {
            wait(1000).then(() => refreshNotifications())
          })
        }
        navigation.navigate(Screens.PendingInvitations, { id: notification.organizationId })
        return
      case "joinRequestApproved":
        if (accessToken && !notification.isRead) {
          markNotificationAsRead({ accessToken, id: notification.id }).then(() => {
            wait(1000).then(() => refreshNotifications())
          })
        }
        setSelectedOrganization(notification.organizationId)
        navigation.navigate(Screens.Root, {
          screen: RootTabs.OrganizationManagerTab,
          params: {
            screen: OrganizationManagerScreens.OrganizationDetail,
            params: { organizationId: notification.organizationId },
          },
        })
        return
      case "remindToAnswer":
        if (accessToken && !notification.isRead) {
          markNotificationAsRead({ accessToken, id: notification.id }).then(() => {
            wait(1000).then(() => refreshNotifications())
          })
        }
        setOrganizationId(notification.organizationId)
        setDefaultContactFilter(["unanswered"])
        navigation.navigate(Screens.Root, {
          screen: RootTabs.ContactNetworkDrawer,
          params: {
            screen: ContactNetworkDrawers.InboxScreen,
            params: { organizationId: notification.organizationId },
          },
        })
        return
      case "matchingMessage":
        if (accessToken && !notification.isRead) {
          markNotificationAsRead({ accessToken, id: notification.id }).then(() => {
            wait(1000).then(() => refreshNotifications())
          })
        }

        navigation.navigate(Screens.MatchingThread, {
          applicationId,
          offerId: undefined,
          myOrganizationId: organizationId,
          to: teamApplication === notification.organizationId ? "application" : "myMatching",
        })
        return
      case "hasMailSendFailedMember":
        return
      case "mailSendFailed":
        return
      case TeamNoticeType.startExtendOrderTime:
      case TeamNoticeType.middleExtendOrderTime:
      case TeamNoticeType.paymentDeadline:
        if (accessToken && !notification.isRead) {
          markNotificationAsRead({ accessToken, id: notification.id }).then(() => {
            wait(1000).then(() => refreshNotifications())
          })
        }
        setSelectedOrganization(notification.organizationId)
        if (notification.paidFunctionEnabled === false) {
          navigation.navigate(Screens.Root, {
            screen: RootTabs.OrganizationManagerTab,
            params: {
              screen: OrganizationManagerScreens.OrganizationDetail,
              params: { organizationId: notification.organizationId },
            },
          })
        } else {
          navigation.navigate(Screens.Root, {
            screen: RootTabs.OrganizationManagerTab,
            params: {
              screen: OrganizationManagerScreens.OrderDetail,
              params: {
                teamId: notification.organizationId,
                accountId: "",
                mode: "home",
              },
            },
          })
        }
        return
      case TeamNoticeType.transferApplicant: {
        setSelectedOrganization(notification.organizationId)
        if (accessToken && notification.organizationId) {
          getCanEditApplicantInfor({ accessToken, id: notification.organizationId }).then((canEditApplicantInfor) => {
            setSelectedOrganization(notification.organizationId)
            if (canEditApplicantInfor) {
              navigation.navigate(Screens.registerApplicantInformation, {
                organizationId: notification.organizationId,
                mode: "update",
                transferStatusId: getParamUrl(notification.url, "transferStatusId") ?? "",
                orderId: getParamUrl(notification.url, "orderId"),
              })
            } else {
              navigation.navigate(Screens.Root, {
                screen: RootTabs.OrganizationManagerTab,
                params: {
                  screen: OrganizationManagerScreens.OrganizationDetail,
                  params: { organizationId: notification.organizationId },
                },
              })
            }
          })
        }
        return
      }
      default:
        refreshNotifications()
    }
  }, [
    notification.type,
    notification.isRead,
    notification.organizationId,
    notification.id,
    notification.url,
    accessToken,
    navigation,
    setOrganizationId,
    setDefaultContactFilter,
    applicationId,
    organizationId,
    teamApplication,
    refreshNotifications,
    setSelectedOrganization,
    notification.paidFunctionEnabled,
  ])
  const checkDate = (date: Date) => {
    if (!date) return ""
    const weekday = ["日", "月", "火", "水", "木", "金", "土"]
    const nowDay = new Date()
    if (
      date.getFullYear() === nowDay.getFullYear() &&
      date.getMonth() === nowDay.getMonth() &&
      date.getDate() === nowDay.getDate()
    ) {
      return "今日"
    } else if (date.getFullYear() === nowDay.getFullYear()) {
      return `${dayjs(date).format("MM/DD")}(${weekday[date.getDay()]})`
    } else {
      return `${dayjs(date).format("YYYY/MM/DD")}(${weekday[date.getDay()]})`
    }
  }

  return (
    <Button style={styles.notificationItemContainer} key={notification.id} onPress={goToNext}>
      <Image style={styles.notificationItemImage} source={uriImage ? { uri: uriImage } : defaultTeam} resizeMode={"cover"} />
      <View style={styles.notificationItemInner}>
        <View style={styles.notificationItemTitleContainer}>
          {!notification.isRead ? (
            <View style={styles.notificationItemTitleIcon}>
              <BadgeIcon size={"small"} />
            </View>
          ) : null}
          <Text
            style={[
              styles.notificationItemTitle,
              notification.type == TeamNoticeType.transferApplicant ||
              notification.type == TeamNoticeType.paymentDeadline ||
              notification.type == TeamNoticeType.startExtendOrderTime ||
              notification.type == TeamNoticeType.middleExtendOrderTime
                ? { color: Colors.red }
                : {},
            ]}
          >
            {notification.title}
          </Text>
        </View>
        <Text style={styles.notificationItemText}>{notification.organizationName}</Text>
        <Text style={styles.notificationItemText}>
          {checkDate(notification.updatedAt)} {dayjs(notification.updatedAt).format("HH:mm")}
        </Text>
      </View>
    </Button>
  )
})

type HomeOrganizationItemProps = {
  organization: HomeOrganization
  uriImage?: string
  updateFavorite: (param: { isFavorite: boolean; teamMemberContactId: string }) => void
  favoriteFlgsCache: { [contactId: string]: boolean }
  goToContactDetail: (organizationId: string, contactId: string) => void
  goToContactNetworkTab: () => void
}
const HomeOrganizationItem = memo<HomeOrganizationItemProps>(
  ({ organization, uriImage, updateFavorite, favoriteFlgsCache, goToContactDetail, goToContactNetworkTab }) => {
    const [unreadThreadCountBuckets, setUnreadThreadCountBuckets] = useState<Buckets[]>([])
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const request = Platform.OS !== "web" ? useRecoilValue(requestDataSelector) : undefined
    useEffect(() => {
      if (Platform.OS === "web" || request == null) {
        return
      }
      const contactIds = organization.contacts.map((el) => el.id)
      getUnreadThreadCount({ ...request, contactIds }).then((data) => setUnreadThreadCountBuckets(data.buckets))
    }, [request, organization])
    function calculateUnreadThreadCount(contactId: string, unreadThreadCountBuckets: Buckets[]) {
      const unreadThread = unreadThreadCountBuckets?.find((el) => el.key === contactId)
      return unreadThread?.doc_count ?? 0
    }

    return (
      <View style={styles.itemWrapper} key={organization.id}>
        <View style={styles.itemContainer}>
          <Image style={styles.contactTopImage} source={uriImage ? { uri: uriImage } : defaultTeam} />
          <View style={styles.itemInner}>
            <View style={styles.itemTitleContainer}>
              <Text style={styles.itemTitle}>{organization.name}</Text>
            </View>
            <Text style={styles.contactSubTitle}>最新の受信連絡</Text>
            {organization.contacts.map((contact) => (
              <View style={styles.contactItemWrapper} key={contact.id}>
                <ContactItem
                  contactId={contact.id}
                  viewType="Home"
                  title={contact.title}
                  contactType={contact.contactType}
                  sentDate={contact.sentDate}
                  timeLimitDate={contact.deadlineDate}
                  senderId={contact.senderId}
                  senderName={contact.senderName}
                  senderImageUrl={contact.senderImageUrl}
                  isSenderDeleted={contact.isSenderDeleted}
                  isFavorite={contact.isFavorite}
                  isRead={contact.isRead}
                  hasAttachment={contact.isAttachmentExists}
                  onIsFavoriteChange={() =>
                    updateFavorite({
                      teamMemberContactId: contact.teamMemberContactId,
                      isFavorite:
                        favoriteFlgsCache[contact.id] !== undefined ? !favoriteFlgsCache[contact.id] : !contact.isFavorite,
                    })
                  }
                  favoriteFlgsCache={favoriteFlgsCache}
                  onContactPress={() => goToContactDetail(organization.id, contact.id)}
                  unreadThreadCount={
                    Platform.OS === "web" ? undefined : calculateUnreadThreadCount(contact.id, unreadThreadCountBuckets)
                  }
                  imageUrl={contact.senderImageUrl}
                />
              </View>
            ))}
            <View style={styles.itemBottomButtonWrapper}>
              <Button onPress={goToContactNetworkTab}>
                <Text style={styles.itemBottomButtonTitle}>すべての連絡を見る</Text>
              </Button>
            </View>
          </View>
        </View>
      </View>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingHorizontal: 16,
  },
  contentContainer: {
    paddingBottom: 16,
  },
  itemWrapper: {
    marginTop: 16,
    borderRadius: 10,
    shadowColor: Colors.black,
    shadowOpacity: 0.16,
    shadowRadius: 16,
    elevation: 2,
  },
  itemContainer: {
    position: "relative",
    backgroundColor: Colors.white3,
    borderRadius: 10,
    overflow: "hidden",
  },
  itemInner: {
    padding: 18,
    backgroundColor: Colors.white3,
  },
  itemTitleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  itemTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
  itemTitleButton: {
    fontSize: 14,
    fontWeight: "bold",
    color: Colors.orange,
  },
  itemBottomButtonWrapper: {
    justifyContent: "center",
    alignItems: "center",
    marginTop: 18,
  },
  itemBottomButtonTitle: {
    fontSize: 14,
    fontWeight: "bold",
    color: Colors.orange,
    textAlign: "center",
  },

  notificationInner: {
    marginTop: 10,
  },
  notificationInnerBorderTop: {
    borderTopWidth: 1,
    borderTopColor: Colors.silver,
  },
  notificationItemContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: Colors.silver,
    paddingVertical: 4,
  },
  notificationItemImage: {
    width: 64,
    height: 64,
    borderRadius: 4,
  },
  notificationItemInner: {
    marginHorizontal: 14,
    justifyContent: "space-between",
    alignItems: "flex-start",
    flex: 1,
  },
  notificationItemTitleContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  notificationItemTitleIcon: {
    marginRight: 5,
  },
  notificationItemTitle: {
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: 17,
    color: Colors.greyshBrown,
  },
  notificationItemText: {
    fontSize: 11,
    lineHeight: 17,
    color: Colors.cement,
  },
  singleButtonContainer: {
    alignItems: "center",
    marginTop: 16,
  },
  contactTopImage: {
    width: "100%",
    height: 152,
  },
  contactSubTitle: {
    fontSize: 12,
    color: Colors.cement,
    marginTop: 8,
  },
  contactItemWrapper: {
    marginTop: 10,
  },
  toggleAccordionText: {
    color: Colors.orange,
  },
})
