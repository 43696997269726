import { selectorFamily } from "recoil"
import { multiMigrationStatusState } from "../../atoms/migration/multiMigrationStatusState"
import { getMultiMigrationStatus, MigrationTeamsModel } from "../../../apis/migration/getMultiMigrationStatus"
import { CustomAlert } from "../../../utils/CustomAlert"
import { tokensState } from "../../atoms/authorization/tokensState"

export const multiMigrationStatusSelectorFamily = selectorFamily<
  MigrationTeamsModel[] | undefined,
  { teamListIds: Array<number> | undefined }
>({
  key: "selectors/migration/multiMigrationStatusSelectorFamily",
  get:
    ({ teamListIds }) =>
    async ({ get }) => {
      get(multiMigrationStatusState)
      const accessToken_ = get(tokensState)?.accessToken
      if (accessToken_ == null || teamListIds == null) return
      const result = await getMultiMigrationStatus({
        accessToken: accessToken_,
        oldRa9Ra9TeamIds: teamListIds,
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      return result.content
    },
})
