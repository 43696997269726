import { Result } from "src/utils/Result"
import { deleteGroup as deleteGroupApi } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type DeleteGroupParams = {
  accessToken: string
  groupId: string
}

type DeleteGroupSuccess = unknown

type DeleteGroupFailure = {
  message: string
}

export const deleteGroup = async (params: DeleteGroupParams): Promise<Result<DeleteGroupSuccess, DeleteGroupFailure>> => {
  const {accessToken, groupId} = params

  return execApi(
    () => deleteGroupApi(accessToken, {
      input: {
        id: groupId
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["deleteGroup", "deleteGroupApi"],
      defaultErrorMessage: "グループの削除に失敗しました。"
    }
  )
}
