import { memo } from "react"
import { Platform, useWindowDimensions } from "react-native"
import { RootTabs } from "src/constants/RootTabs"
import { Analytics } from "src/tags/analytics/Analytics"

import { BottomTabNavigator } from "./BottomTabNavigator"
import { RootDrawerNavigator } from "./RootDrawerNavigator"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export const RootNavigator = memo(() => {
  const isPCScreen = useCheckPCScreen()

  const sendAnalyticsEvent = async (screenName: string) => {
    if (screenName === RootTabs.HomeTab) {
      await Analytics.goToHome()
    } else if (screenName === RootTabs.ContactNetworkDrawer) {
      await Analytics.goToMail()
    } else if (screenName === RootTabs.OrganizationManagerTab) {
      await Analytics.goToTeam()
    } else if (screenName === RootTabs.SettingsTab) {
      await Analytics.goToSetting()
    }
  }

  if (isPCScreen) {
    return <RootDrawerNavigator sendAnalyticsEvent={sendAnalyticsEvent} />
  }

  return <BottomTabNavigator sendAnalyticsEvent={sendAnalyticsEvent} />
})

RootNavigator.displayName = "RootNavigator"
