import { selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { TeamMemberModel } from "src/types/csv/teamMemberCsvData"
import { getTeamMemberCsvData } from "src/apis/csv/getTeamMemberCsvData"
import { teamMembersCsvDataRequestIdState } from "src/recoil/atoms/csv/teamMembersCsvDataRequestIdState"

export const teamMembersCsvDataSelectorFamily = selectorFamily<
  TeamMemberModel[] | undefined,
  { teamId: string; groupId?: string }
>({
  key: "selectors/csvData/teamMembersCsvDataSelectorFamily",
  get:
    ({ teamId, groupId }) =>
    async ({ get }) => {
      get(teamMembersCsvDataRequestIdState)
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) {
        return
      }
      const result = await getTeamMemberCsvData({ accessToken, teamId, groupId })
      if (!result.isOk) {
        return
      }
      return result.content.sort((a, b) => {
        const memberSeqA = Number(a.memberSeq)
        const memberSeqB = Number(b.memberSeq)
        return memberSeqA - memberSeqB
      })
    },
})
