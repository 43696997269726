import { useCallback } from "react"
import { useRequestData } from "../useRequestData"
import { useFetcher } from "src/hooks/useFetcher"
import { sendMigrationInvitationEmail } from "src/apis/team/invitation/resendInvitationEmail"
import { CustomAlert } from "src/utils/CustomAlert"
import { useRecoilValue } from "recoil"
import { sendNotJoinMembersState } from "src/recoil/atoms/organization/listNotJoinMembersRequestIdState"
import { useNavigation } from "@react-navigation/native"
import { Screens } from "src/constants/Screens"
import { listAllMigratedTeamsRequestIdState } from "src/recoil/atoms/organization/listAllMigratedTeamsRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"

export const useInviteMailTeamMigrate = (teamId: string) => {
  const requestDataResult = useRequestData()
  const navigation = useNavigation()
  const mailInfo = useRecoilValue(sendNotJoinMembersState)
  const refreshAllMigratedTeams = useRefresher(listAllMigratedTeamsRequestIdState)
  const { value: organization } = useAsyncSelector(organizationDetailSelectorFamily(teamId))

  const { fetch: sendMailToMember, isFetching: isLoadingSendMailToMember } = useFetcher(
    useCallback(async () => {
      if (!requestDataResult.isOk) return
      const { accessToken } = requestDataResult.content
      const emails = mailInfo?.map((el) => el.email ?? el.id) || []

      if (accessToken == null || teamId == null || mailInfo == null) return
      const result = await sendMigrationInvitationEmail({
        accessToken,
        teamId: teamId,
        emails: emails,
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      CustomAlert.alert("完了", "送信しました。").then(() => {
        refreshAllMigratedTeams()
        navigation.navigate(Screens.MigrateOrganizations, { joinFlg: true })
      })
    }, [requestDataResult, teamId, mailInfo, navigation, refreshAllMigratedTeams])
  )

  return {
    sendMailToMember,
    isLoadingSendMailToMember,
    mailInfo,
    organization,
  }
}
