import { Result } from "src/utils/Result"
import { createInquiry, createPaidTeamInquiry } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import { getUserAgent } from 'react-native-device-info';

type PostInquiryRequestParams = {
  accessToken: string
  content?: string
  userDevice?: string
  organizationId?: string
  agreedToPrivacyPolicy: boolean
}

type PostInquirySuccess = {
  id: string
}

type PostInquiryFailure = {
  message: string
}

export const postInquiryPaidTeam = async ({ accessToken, ...params }: PostInquiryRequestParams): Promise<Result<PostInquirySuccess, PostInquiryFailure>> => {
  const userAgent = await getUserAgent()
  return execApi(
    () => createPaidTeamInquiry(accessToken, {
      input: {
        content: params.content,
        deviceInfo: params.userDevice,
        teamId: params.organizationId,
        agreedToPrivacyPolicy: params.agreedToPrivacyPolicy,
        userAgent,
      },
      appInfo
    }),
    res => ({
      id: res.createPaidTeamInquiry.id
    }),
    {
      identifiers: ["postInquiryPaidTeam", "createPaidTeamInquiry"]
    }
  )
}
