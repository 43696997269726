import { memo } from "react"
import { Modal, StyleSheet, View, Text } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { Colors } from "src/constants/Colors"
import WebView from "react-native-webview"

export type Props = {
  fileUri: string
  visible: boolean
  onRequestClose: () => void
  FooterComponent: () => JSX.Element
}

export const CustomFileView = memo<Props>(({ visible, onRequestClose, fileUri, FooterComponent }) => {
  return (
    <Modal visible={visible} onRequestClose={onRequestClose}>
      <View style={styles.headerContainer}>
        <Button style={{ paddingTop: 40 }} onPress={onRequestClose}>
          <RemoveIcon size={20} fill={Colors.white3} />
        </Button>
      </View>
      <View style={styles.webViewContainer}>
        <WebView source={{ uri: fileUri }} style={styles.webView} />
      </View>
      <View style={styles.footerContainer}>
        <FooterComponent />
      </View>
    </Modal>
  )
})

const styles = StyleSheet.create({
  webViewContainer: {
    flex: 1,
    marginTop: 70,
    marginBottom: 100,
  },
  webView: {
    flex: 1,
  },
  headerContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    width: "100%",
    height: 70,
    alignItems: "flex-end",
    paddingHorizontal: 26,
    backgroundColor: Colors.black2,
    zIndex: 1,
  },
  footerContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
    height: 70,
    backgroundColor: Colors.black2,
    zIndex: 1,
  },
})
