import { memo } from "react"
import Svg, { Path } from "react-native-svg"

export const RemoveRowIcon = memo(() => (
  <Svg width="16.422" height="16.422" viewBox="0 0 16.422 16.422">
    <Path
      d="M13.211 5a8.211 8.211 0 1 0 8.211 8.211A8.2 8.2 0 0 0 13.211 5zm5.018 8.667H8.193a.456.456 0 1 1 0-.912h10.036a.456.456 0 1 1 0 .912z"
      transform="translate(-5 -5)"
      fill="red"
    />
  </Svg>
))
