import { memo, useEffect } from "react"
import { Platform, StyleSheet, Text, useWindowDimensions, View } from "react-native"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { Colors } from "src/constants/Colors"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { RootStackScreenProps } from "src/types.d"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { PlanEditor } from "src/components/parts/PlanEditor"
import { usePlanData } from "src/recoil/hooks/screens/usePlanData"
import { Button } from "src/components/parts/buttons/Button"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { PC_BREAKPOINT } from "src/constants/Screen"
import { Screens } from "src/constants/Screens"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { ScrollView } from "react-native-gesture-handler"
import { useSafeAreaInsets } from "react-native-safe-area-context"

const registerPlan = memo<RootStackScreenProps<"registerPlan">>(({ route, navigation }) => {
  const isLargeScreen = useCheckPCScreen()
  const heightScreen = useWindowDimensions().height
  const insets = useSafeAreaInsets()
  const { organizationId, mode, orderId, extend, oriUpdatePlan } = route.params

  const {
    organizationDetail,
    errorMessage,
    isDisabled,
    planError,
    plansOption,
    startTimeOptions,
    startTimeError,
    invoiceAddressError,
    invoiceKanaAddressError,
    gotoConfirmPlan,
    planData,
    setPlanData,
    setLocalStorageRealTime,
    orderData,
    changePlan,
    initUpdatePlan,
  } = usePlanData({
    organizationId,
    mode: mode,
    extend,
    orderId,
    oriUpdatePlan,
  })

  const goBack = () => {
    if (mode === "update" && !orderId) {
      return navigation.navigate(Screens.confirmPlan, { extend, initUpdatePlan })
    } else if ((mode === "update" && orderId) || (mode === "back" && orderId)) {
      return navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.OrderDetail,
          params: {
            teamId: organizationId,
            teamMembers: organizationDetail?.memberNumber ?? 0,
            accountId: organizationDetail?.profileInOrganization.id ?? "",
          },
        },
      })
    } else {
      return navigation.navigate(Screens.registerApplicantInformation, {
        organizationId: organizationId,
        mode: "back",
      })
    }
  }

  useEffect(() => {
    navigation.setOptions({
      headerLeft: (props) => (
        <Button
          onPress={() => {
            goBack()
          }}
        >
          <BackIcon
            fill={Number(props.screenLayout?.width) >= PC_BREAKPOINT && Platform.OS === "web" ? Colors.orange : Colors.white3}
            {...props}
          ></BackIcon>
        </Button>
      ),
      title: (mode === "update" || mode === "back") && orderId ? "プラン変更" : "団体有料版申込情報入力",
    })
  })

  return (
    <CustomKeyboardAvoidingView
      style={[{ height: heightScreen - 64 }, Platform.OS === "web" ? {} : { paddingBottom: insets.bottom }]}
      noContainer={true}
      useAware={true}
    >
      <ScrollView style={{ height: 500 }}>
        {!orderId && mode !== "update" && (
          <>
            <View style={[styles.header]}>
              <View style={[isLargeScreen ? { width: "100%", maxWidth: 600, paddingHorizontal: 16 } : { width: "100%" }]}>
                <Text style={[styles.headerTitle]}>続いて、プランの選択と利用開始希望月を選択してください。</Text>
              </View>
            </View>
          </>
        )}

        <View style={[styles.container]}>
          <View style={[styles.row]}>
            <Text style={styles.requiredItemNoteText}>
              <Text style={styles.requiredItemNoteAsterisk}>※</Text>は必須項目
            </Text>
          </View>
          {orderId ? (
            <>
              <View style={[styles.row]}>
                <View style={styles.itemLabelWrapper}>
                  <ItemLabel label="受注番号" />
                </View>
                <Text style={[styles.textField]}>{orderData.orderNumber}</Text>
              </View>
            </>
          ) : (
            <>
              <View style={[styles.row]}>
                <View style={styles.itemLabelWrapper}>
                  <ItemLabel label="団体名" />
                </View>
                <Text style={[styles.textField]}>{organizationDetail?.organizationName}</Text>
              </View>
            </>
          )}
          <View style={[styles.row]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="メンバー数" />
            </View>
            <Text style={[styles.textField]}>{organizationDetail?.memberNumber}名</Text>
          </View>

          <PlanEditor
            errorMessage={errorMessage}
            planError={planError}
            plansOption={plansOption}
            startTimeOptions={startTimeOptions}
            startTimeError={startTimeError}
            invoiceAddressError={invoiceAddressError}
            invoiceKanaAddressError={invoiceKanaAddressError}
            mode={mode}
            planData={planData}
            setPlanData={setPlanData}
            orderId={orderId}
            setLocalStorageRealTime={setLocalStorageRealTime}
            changePlan={changePlan}
            extend={extend}
          />
        </View>
      </ScrollView>
      <View
        style={[
          styles.buttonContainer,
          isLargeScreen ? {} : { paddingVertical: 24 },
          Platform.OS === "ios" ? { paddingBottom: insets.top } : null,
        ]}
      >
        <View
          style={[
            isLargeScreen
              ? { maxWidth: 600, flexDirection: "row", justifyContent: "space-between", width: "100%", paddingHorizontal: 24 }
              : { width: "100%" },
          ]}
        >
          <TextButton
            buttonType={ButtonType.Secondary}
            title="戻る"
            onPress={() => {
              goBack()
            }}
            style={[isLargeScreen ? { width: "49%" } : null]}
          />
          <TextButton
            buttonType={ButtonType.Primary}
            title={"確認"}
            onPress={() => {
              gotoConfirmPlan()
              if ((mode === "update" && orderId) || (mode === "back" && orderId)) {
                navigation.navigate(Screens.confirmUpdatePlan, { orderId: orderId, extend, initUpdatePlan })
              } else {
                navigation.navigate(Screens.confirmPlan, { extend, initUpdatePlan })
              }
            }}
            disabled={isDisabled}
            style={[isLargeScreen ? { width: "49%" } : { marginTop: 16 }]}
          />
        </View>
      </View>
    </CustomKeyboardAvoidingView>
  )
})

export const registerPlanOptions: ScreenOptions = () => ({
  ...commonHeaderOptions,
  headerShown: true,
  title: "団体有料版申込情報入力",
})

const styles = StyleSheet.create({
  header: {
    paddingHorizontal: 16,
    paddingVertical: 14,
    width: "100%",
    borderBottomColor: Colors.borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
  },
  container: {
    alignItems: "center",
    width: "100%",
    paddingVertical: 24,
    paddingHorizontal: 16,
    maxWidth: 600,
    marginHorizontal: "auto",
  },
  row: {
    marginBottom: 16,
    width: "100%",
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 20,
    color: Colors.black2,
  },
  requiredItemNoteText: {
    fontWeight: "400",
    fontSize: 14,
  },
  requiredItemNoteAsterisk: {
    color: Colors.red,
  },
  itemLabelWrapper: {
    marginBottom: 4,
  },
  textField: {
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 22.4,
    marginVertical: 14,
    textAlign: "left",
    width: "100%",
  },
  text: {
    width: "100%",
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "300",
  },
  buttonContainer: {
    paddingHorizontal: 24,
    width: "100%",
    borderTopColor: Colors.borderColor,
    borderTopWidth: 1,
    alignItems: "center",
    paddingVertical: 12,
  },
})

export default registerPlan
