import { createAccountAdminNotice } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import { Result } from "src/utils/Result"

type Params = {
  accessToken: string
  adminNoticeId: string
}
type PostAdminNoticeSuccess = unknown

type PostAdminNoticeFailure = {
  message: string
}
export const markAsReadAdminNotice = async ({
  accessToken,
  adminNoticeId,
}: Params): Promise<Result<PostAdminNoticeSuccess, PostAdminNoticeFailure>> => {
  return execApi(
    () =>
      createAccountAdminNotice(accessToken, {
        input: {
          adminNoticeId: adminNoticeId,
        },
        appInfo,
      }),
    (_) => undefined,
    {
      identifiers: ["markAsReadAdminNotice", "createAccountAdminNotice"],
    }
  )
}
