import { selector } from "recoil"
import { getMyTeams, MyTeam } from "src/queries/team/getMyTeams"
import { myTeamsRequestIdState } from "src/recoil/atoms/team/myTeamsRequestIdState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"

export const myTeamsSelector = selector<MyTeam[]>({
  key: "selectors/team/myTeamsSelector",
  get: async ({ get }) => {
    get(myTeamsRequestIdState)
    const tokens = get(tokensState)
    const accountId = get(accountIdState)
    if (tokens == null || accountId == null) return []

    return await getMyTeams({
      accessToken: tokens.accessToken,
      accountId,
    })
  },
})
