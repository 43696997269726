import { memo } from "react"
import { Svg, Path } from "react-native-svg"

export const LogoutIcon = memo(() => (
  <Svg width="20.138" height="19.415" viewBox="0 0 20.138 19.415">
    <Path
      d="M6.95 35.818h2.157V35.1a.724.724 0 0 1 1.23-.511l2.876 2.876a.718.718 0 0 1 0 1.021l-2.876 2.876a.718.718 0 0 1-.511.209.6.6 0 0 1-.273-.058.719.719 0 0 1-.446-.662v-.719H6.95a.718.718 0 0 1-.719-.719v-2.876a.718.718 0 0 1 .719-.719z"
      transform="translate(6.712 -28.252)"
      fill={"#f2caa0"}
    />
    <Path
      d="m57.155 10.148 5.752-2.3a1.438 1.438 0 0 1 1.97 1.337v16.538a1.433 1.433 0 0 1-1.438 1.438 1.383 1.383 0 0 1-.532-.1l-5.752-2.3a1.438 1.438 0 0 1-.906-1.309V11.486a1.438 1.438 0 0 1 .906-1.337zm4.127 8.758a.719.719 0 1 0 1.438 0V16.03a.719.719 0 1 0-1.438 0z"
      transform="translate(-56.249 -7.746)"
      fill={"#f2caa0"}
    />
    <Path
      d="M41.344 32.163h2.876a1.438 1.438 0 0 0 1.438-1.438V17.063a1.438 1.438 0 0 0-1.438-1.438h-2.876a.718.718 0 0 0-.719.719v15.1a.718.718 0 0 0 .719.719zm2.876-15.1v13.662h-2.157V17.063z"
      transform="translate(-33.435 -13.812)"
      fill={"#f2caa0"}
    />
  </Svg>
))
