import { atom, selector } from "recoil"
import { existingContactDetailSelector } from "src/recoil/selectors/contact/create/existingContactDetailSelector"
import { reservedSelector } from "src/recoil/selectors/contact/reservedSelector"

const initialState: string[] = []

const initialStateSelector = selector({
  key: "atoms/contact/create/attachmentFileNamesState_initialState",
  get: ({ get }) => {
    const contact = get(existingContactDetailSelector)
    const reserved = get(reservedSelector)
    return contact?.attachmentFileNames ?? reserved?.attachmentFileNames ?? initialState
  },
})

export const attachmentFileNamesState = atom({
  key: "atoms/contact/create/attachmentFileNamesState",
  default: initialStateSelector,
})
