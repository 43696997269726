import { contactDetailRequestIdState } from "src/recoil/atoms/contact/contactDetailRequestIdState"
import { contactReadStatusRequestIdState } from "src/recoil/atoms/contact/contactReadStatusRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"
import { useCallback } from "react"

export const useContactDetailDataRefresher = () => {
  const refreshContactDetail = useRefresher(contactDetailRequestIdState)
  const refreshContactReadStatus = useRefresher(contactReadStatusRequestIdState)
  const refreshContactDetailData = useCallback(() => {
    refreshContactDetail()
    refreshContactReadStatus()
  }, [refreshContactDetail, refreshContactReadStatus])

  return refreshContactDetailData
}
