import { atom, selector } from "recoil"
import { draftSelector } from "src/recoil/selectors/contact/draftSelector"
import { templateSelector } from "src/recoil/selectors/contact/templateSelector"
import { existingContactDetailSelector } from "src/recoil/selectors/contact/create/existingContactDetailSelector"
import { reservedSelector } from "src/recoil/selectors/contact/reservedSelector"

const initialState = undefined

const initialStateSelector = selector({
  key: "atoms/contact/create/selectedGroupIdsState_initialState",
  get: ({ get }) => {
    const contact = get(existingContactDetailSelector)
    const contactValue = contact?.receivers.groupIds
    const reserved = get(reservedSelector)
    const reservedValue = reserved?.receivers.groupIds
    const template = get(templateSelector)
    const draft = get(draftSelector)
    return contactValue ?? reservedValue ?? (template ?? draft)?.groupIds ?? initialState
  },
})

export const selectedGroupIdsState = atom<string[] | undefined>({
  key: "atoms/contact/create/selectedGroupIdsState",
  default: initialStateSelector,
})
