import { getTeamMemberImageGetUrl, GqlError, multiTeamMemberImageGetUrl } from "src/aws/customAPI"
import { Result } from "src/utils/Result"

type Input = {
  accessToken: string
  memberId: string
  thumbnail?: boolean
}
type MultiInput = {
  accessToken: string
  teamMemberImages: {
    teamMemberId: string
    thumbnail?: boolean
  }[]
}
type SuccessResponse = {
  url: string
  isDefault: boolean
}
type SuccessResponseOfMulti = {
  teamMemberId: string
  url: string
  isDefault: boolean
}
type FailureResponse = {
  message: string
}

export const getMemberImageUrl = async ({accessToken, memberId, thumbnail}: Input): Promise<Result<SuccessResponse, FailureResponse>> => {
  return getTeamMemberImageGetUrl(accessToken, {
    input: {
      teamMemberId: memberId,
      thumbnail: !!thumbnail
    }
  })
    .then(res => res.data?.getTeamMemberImageGetUrl)
    .then(res => {
      if (res) {
        return Result.Ok<SuccessResponse>({
          url: res.url,
          isDefault: res.isDefault
        })
      } else {
        return Result.Error<FailureResponse>({
          message: "画像の取得に失敗しました。"
        })
      }
    })
    .catch((e: GqlError) => {
      const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || '予期せぬエラーが発生しました'
      return Result.Error<FailureResponse>({
        message: message
      })
    })
}
export const multiTeamMemberImageUrls = async ({accessToken, teamMemberImages}: MultiInput): Promise<Result<SuccessResponseOfMulti[], FailureResponse>> => {
  if (teamMemberImages.length === 0) {
    Result.Ok<SuccessResponseOfMulti[]>([])
  }

  return multiTeamMemberImageGetUrl(accessToken, {
    input: {
      teamMemberImages
    }
  })
    .then(res => res.data?.multiTeamMemberImageGetUrl)
    .then(res => {
      if (res) {
        const data = res.teamMemberImages.map(el => ({ teamMemberId: el.teamMemberId, url: el.image?.url ?? "", isDefault: el.image?.isDefault ?? false} as SuccessResponseOfMulti));
        return Result.Ok<SuccessResponseOfMulti[]>(data);
      } else {
        return Result.Error<FailureResponse>({
          message: "画像の取得に失敗しました。"
        })
      }
    })
    .catch((e: GqlError) => {
      const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || '予期せぬエラーが発生しました'
      return Result.Error<FailureResponse>({
        message: message
      })
    })
}