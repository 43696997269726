import { getOldAccountInfo } from "./oldRa9Ra9Api";
import { CustomAlert } from "../../utils/CustomAlert";

export type accountInfo = {
  user_info: UserInfo
  user_license: userLicense
}

export type UserInfo = {
  name: string
  kana: string
  mail: string
  birth: string
  image_url: string
  sex: number
  prefecture: string
  city: string
  occupation: number
  marr: number
  kids: number
  nickname: string
  university: string
  department: string
  graduate_date: number
  should_update_props: number
}

type userLicense = {
  drivers_license: number
  drivers_mag: number
}

type Params = {
  accessToken: string
  userId: number
}

export const oldAccountInfo = async ({accessToken, userId}: Params): Promise<UserInfo> => {
  return getOldAccountInfo(accessToken, userId).then(async (res) => {
    if (res.status === 200) {
      const { user_info } = await res.json();
      return user_info
    } else {
      await CustomAlert.alert("完了","予期せぬエラーが発生しました");
      return;
    }
  });
}

