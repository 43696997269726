
import { EmailStatus } from "src/aws/API"
import { GqlError, searchInvitationEmailStatus } from "src/aws/customAPI"
import { Result } from "src/utils/Result"


type Params = {
  accessToken: string
  teamId: string
  limit?: number
  nextToken?: string
}

type Success = {
  emails: InvitationEmailStatus[]
  nextToken?: string | null | undefined
  total?:number | undefined 
}

type Failure = {
  message: string
}

export type InvitationEmailStatus = {
  id: string;
  email: string;
  status: EmailStatus;
  createdAt: string;
  updatedAt: string;
};

export const getListInvitationEmailStatus = async ({
                                                     accessToken,
                                                     teamId,
                                                     limit,
                                                     nextToken
                                                   }: Params): Promise<Result<Success, Failure>> => {
  return searchInvitationEmailStatus(accessToken, {
    filter:{
      teamInvitationEmailStatusesId: {
        eq:teamId
      }
    },
    limit: limit,
    nextToken: nextToken
  }).then(res => {
    if (res.data) {
      return Result.Ok<Success>({
          emails: res.data.searchInvitationEmailStatuses.items.map(e => ({
          id: e.id,
          email: e.email,
          status: e.status,
          createdAt: e.createdAt,
          updatedAt: e.updatedAt
        })),
        nextToken: res.data.searchInvitationEmailStatuses.nextToken,
        total:res.data.searchInvitationEmailStatuses.total
      });
    } else {
      return Result.Error<Failure>({ message: "招待メール送信状況一覧の取得に失敗しました。" });
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "招待メール送信状況一覧の取得に失敗しました";
    return Result.Error<Failure>({
      message: message
    });
  });
};