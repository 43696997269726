import { useEffect, useMemo } from "react"
import { markContactReplyThreadMessageAsRead } from "src/apis/contact/markContactReplyThreadMessageAsRead"
import { markMatchingMessageAsRead } from "src/aws/customAPI"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"

type Param = {
  replyThreadMessageId: string
  isRead: boolean
  isMatching?: boolean
  itemType?: string
  organizationId?: string
}

export const useMarkThreadMessageAsRead = ({ replyThreadMessageId, isRead, isMatching, organizationId, itemType }: Param) => {
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])

  useEffect(() => {
    if (itemType === "RemovedReply") {
      if (accessToken) {
        if (isMatching && organizationId) {
          markMatchingMessageAsRead(accessToken, {
            input: {
              matchingMessageId: replyThreadMessageId,
              teamId: organizationId,
            },
          })
        }
        if (replyThreadMessageId && !isMatching) {
          markContactReplyThreadMessageAsRead({ accessToken, replyThreadMessageId })
        }
      }
    } else {
      if (accessToken && !isRead) {
        if (isMatching && organizationId) {
          markMatchingMessageAsRead(accessToken, {
            input: {
              matchingMessageId: replyThreadMessageId,
              teamId: organizationId,
            },
          })
        } else {
          markContactReplyThreadMessageAsRead({ accessToken, replyThreadMessageId })
        }
      }
    }
  }, [accessToken, isRead, replyThreadMessageId, isMatching, organizationId, itemType])
}
