import { selector } from "recoil"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { myFullOrganizationsSelector } from "./myOrganizationsSelector"

export const selectedMyOrganizationSelector = selector({
  key: "selectors/organization/selectedMyOrganizationSelector",
  get: ({ get }) => {
    const selected = get(selectedMyOrganizationState)
    const organizations = get(myFullOrganizationsSelector)
    if (organizations.length === 0) {
      return
    }
    const matched = organizations.find((organization) => organization.id === selected)
    if (matched == null) {
      return organizations[0]
    }
    return matched
  },
})
