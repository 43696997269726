import { atom } from "recoil"

export const organizationRefreshPermissionState = atom<boolean>({
  key: "atoms/organization/organizationRefreshPermissionState",
  default: false,
})

export const organizationRefreshState = atom<boolean>({
  key: "atoms/organization/organizationRefreshState",
  default: false,
})
