import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { StyleSheet, View, Text, Platform, Modal } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { Colors } from "src/constants/Colors"
import { CustomDateTimePicker } from "src/components/parts/CustomDateTimePicker"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { TextButton, ButtonType } from "src/components/parts/buttons/TextButton"
import { useCheckPCScreen, useCheckPCSmallScreen } from "src/hooks/useCheckPCScreen"
import { ageOptions, purposeOptions } from "src/constants/matchingSearchDate"
import { Button } from "src/components/parts/buttons/Button"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { RootStackScreenProps, ValueOf } from "src/types.d"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { useCreateOrEditMatchingOfferData } from "src/recoil/hooks/matching/useCreateOrEditMatchingOffer"
import { Checkbox } from "src/components/parts/Checkbox"
import { ValidationErrorMessage } from "src/components/parts/ValidationErrorMessage"
import { useResource } from "src/recoil/hooks/resource/useResource"
import {
  NextActionAfterModalClosed,
  getMB,
  messageErrorMaxTotalFile,
} from "src/components/parts/contactNetworkTab/ContactDetailEditor"
import { CustomAlert } from "src/utils/CustomAlert"
import { LocalDocument, LocalImage } from "src/types/resource/LocalResource"
import { ResourceUnit } from "src/types/resource/ResourceUnit"
import { Result } from "src/utils/Result"
import { getExtension, isExpired } from "src/recoil/hooks/resource/fileManager/common"
import { SafeAreaView } from "react-native"
import { ConfirmCreateOffer } from "src/components/parts/matching/ConfirmCreateOffer"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { useMyMatchingOffers } from "src/recoil/hooks/matching/useMyMatchingOffers"
import { wait } from "src/utils/wait"
import { Screens } from "src/constants/Screens"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { RootTabs } from "src/constants/RootTabs"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { MatchingAttachmentViewer } from "src/components/parts/matching/AttachmentViewer"
import { matchingOfferDraftRequestIdState } from "src/recoil/atoms/matching/matchingOfferDraftRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"
import { AttachmentItem } from "src/components/parts/matching/AttachmentItem"

export type OfferCreateParams = {
  id?: string
  draftId?: string
}

export type NextActionAfterModalClosedPickfile = ValueOf<typeof NextActionAfterModalClosed>

const dp = 16
const ddp = dp * 2
export const imageSize = {
  account: {
    normal: { width: 100, height: 100 },
    thumbnail: { width: 50, height: 50 },
    maxSizeInMB: 10,
  },
  team: {
    normal: { width: 720, height: 250 },
    thumbnail: { width: 100, height: 100 },
    maxSizeInMB: 10,
  },
  // TeamMember画像はAccount画像と同じため省略
  attachment: {
    thumbnail: { width: 100, height: 100 },
    maxSizeInMB: 10,
    maxCount: 5,
  },
}
const maxFileCount = imageSize.attachment.maxCount
const maxEachFileSizeInMB = imageSize.attachment.maxSizeInMB
const maxTotalFileSizeInMB = maxEachFileSizeInMB * maxFileCount

type Props = RootStackScreenProps<typeof Screens.MatchingOfferCreate>
export const OfferCreateModal = memo<Props>(({ navigation, route }) => {
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = selectedOrganization?.id
  const { value: organization } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))
  const id = useMemo(() => route.params?.id, [route.params])
  const draftId = useMemo(() => route.params?.draftId, [route.params])
  const refeshDraffDetail = useRefresher(matchingOfferDraftRequestIdState)

  const isLargeScreen = useCheckPCScreen()
  const isSmallScreen = useCheckPCSmallScreen()
  const {
    ageToErrorMessage,
    timeErrorMessage,
    publishEndDatetimeErrorMessage,
    offerCountErrorMessage,
    expenseErrorMessage,
    onChangeSelect,
    onChangeText,
    onChangeDate,
    onTimeChange,
    onChangePublishEndDatetime,
    myCreateData,
    submit,
    onSaveDraft,
    setAttachmentThumbnailUploaders,
    undecided,
    onClickUndecided,
    allDay,
    onClickAllDay,
    cityOptions,
    prefectureOptions,
    setLocalAttachments,
    setLocalImageURLList,
    placeUrlErrorMessage,
    costUndecided,
    onClickCostUndecided,
    attachmentsCount,
    attachmentsSize,
    localAttachments,
    onCloseCreateOfferModal,
    isDisabled,
    createdAt,
    isSaveDraftDisabled,
    openConfirmModal,
    setOpenConfirmModal,
    resetAllUserInput,
    onUpdate,
    onChangeCity,
    setLoading,
    isLoading,
    isSaving,
    isSavingAsDraft,
    isUpdating,
    onChangePrefecture,
  } = useCreateOrEditMatchingOfferData({ onClose, offerId: id, draftId })

  const emptyUploader = (): Promise<Result<undefined, { message: string }>> => new Promise((r) => r(Result.Ok(undefined)))
  const { refreshList } = useMyMatchingOffers(true)

  const { bottom } = useSafeAreaInsets()

  async function onClose(type?: string) {
    setLoading(true)
    resetAllUserInput()
    if (type === "draff") {
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: RootTabs.MatchingDrawer,
          params: {
            screen: MatchingDrawers.MyMatchingDraftOffer,
          },
        },
      })
    } else if (type === "create" || type === "update") {
      await refreshList()
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: RootTabs.MatchingDrawer,
          params: {
            screen: MatchingDrawers.MyMatchingOffer,
          },
        },
      })
    } else {
      navigation.goBack()
    }
    if (draftId) {
      refeshDraffDetail()
    }
    setLoading(false)
  }

  useEffect(() => {
    if (!isLargeScreen) {
      navigation.setOptions({
        headerLeft: () => (
          <Button
            style={{ marginLeft: 15 }}
            onPress={() => (openConfirmModal ? setOpenConfirmModal(false) : onCloseCreateOfferModal())}
          >
            <RemoveIcon size={17} fill={Colors.orange} />
          </Button>
        ),
        title: id ? "募集の編集" : "新規募集作成",
      })
    } else {
      navigation.setOptions({
        title: id ? "募集の編集" : "新規募集作成",
      })
    }
  }, [navigation, isLargeScreen, onCloseCreateOfferModal, openConfirmModal, setOpenConfirmModal, id])

  const [purpose, setPurpose] = useState<any>(purposeOptions[0].value)
  const onChangePurpose = () => {
    setPurpose(purposeOptions[0].value)
  }
  const onRemove = useCallback(
    (index: number) => {
      setLocalAttachments((prev) => {
        let newValue = [...prev]
        newValue.splice(index, 1)
        newValue = newValue.map((el, i) => {
          el.index = i
          return el
        })
        return newValue
      })
      setLocalImageURLList((prev) => {
        const newValue = [...prev]
        newValue.splice(index, 1)
        return newValue
      })
      setAttachmentThumbnailUploaders((prev) => {
        const newValue = [...prev]
        newValue.splice(index, 1)
        return newValue
      })
    },
    [setLocalAttachments, setLocalImageURLList, setAttachmentThumbnailUploaders]
  )
  const filteredAgeOptions = (isFrom: boolean) => {
    if (isFrom) {
      if (myCreateData?.ageTo?.label && Number(myCreateData?.ageTo?.label) > 0) {
        return ageOptions.filter((ageOpt) => Number(ageOpt.label) <= Number(myCreateData.ageTo?.label))
      }
    } else {
      if (myCreateData?.ageFrom?.label && Number(myCreateData?.ageFrom?.label) > 0) {
        return ageOptions.filter((ageOpt) => Number(ageOpt.label) >= Number(myCreateData?.ageFrom?.label))
      }
    }
    return ageOptions
  }
  const onUploaderChange = useCallback(
    (resourceUnit: ResourceUnit, uploader: () => Promise<Result<undefined, { message: string }>>) => {
      if (resourceUnit.type === "attachmentFile") {
        const index = resourceUnit.index
        setLocalImageURLList((prevState) => {
          const newUploaders = [...prevState]
          newUploaders.splice(index, 1, uploader)
          return newUploaders
        })
      } else if (resourceUnit.type === "attachmentThumbnail") {
        const index = resourceUnit.index
        setAttachmentThumbnailUploaders((prev) => {
          const newUploaders = [...prev]
          newUploaders.splice(index, 1, uploader)
          return newUploaders
        })
      }
    },
    [setAttachmentThumbnailUploaders, setLocalImageURLList]
  )
  const validateAttachment = useCallback(
    (attachment: AttachmentItemPropsMatching): boolean => {
      if (maxTotalFileSizeInMB <= attachmentsSize + getMB(attachment.sizeInB, true)) {
        CustomAlert.alert("エラー", `ファイルサイズが${maxTotalFileSizeInMB}MBを超えています。`)
        return false
      } else {
        return true
      }
    },
    [attachmentsSize]
  )
  const onLocalDocumentChange = useCallback(
    (localDocument: LocalDocument, resetLocalResource: () => void) => {
      const newProps: AttachmentItemPropsMatching = {
        type: "document",
        teamId: organizationId ?? "",
        index: attachmentsCount,
        fileName: localDocument.fileName,
        sizeInB: localDocument.sizeInB,
        onRemove,
        localDocument: localDocument,
        onUploaderChange,
      }
      if (!validateAttachment(newProps)) return false
      setLocalAttachments((prev) => [...prev, newProps])
      resetLocalResource()
      return true
    },
    [organizationId, attachmentsCount, setLocalAttachments, validateAttachment, onRemove, onUploaderChange]
  )

  const onLocalImageChange = useCallback(
    (localImage: LocalImage, resetLocalResource: () => void) => {
      const extension = getExtension(localImage.uri)
      const newProps: AttachmentItemPropsMatching = {
        type: "image",
        teamId: organizationId ?? "",
        index: attachmentsCount,
        fileName: localImage.fileName || `デバイスの画像.${extension}`,
        sizeInB: localImage.sizeInB,
        onRemove,
        localImage: localImage,
        onUploaderChange,
      }
      if (!validateAttachment(newProps)) return false
      setLocalAttachments((prev) => [...prev, newProps])
      setLocalImageURLList((prev) => [...prev, emptyUploader])
      setAttachmentThumbnailUploaders((prev) => [...prev, emptyUploader])
      resetLocalResource()
      return true
    },
    [
      organizationId,
      attachmentsCount,
      validateAttachment,
      onRemove,
      onUploaderChange,
      setLocalAttachments,
      setLocalImageURLList,
      setAttachmentThumbnailUploaders,
    ]
  )

  const { pickLocalImage, pickLocalDocument } = useResource({
    type: "none",
    onLocalImageChange,
    onLocalDocumentChange,
  })

  const [isOpenAttachmentsModal, setIsOpenAttachmentsModal] = useState(false)

  useEffect(() => {
    const handleNextAction = async () => {
      const maxFileSizeUpload = Math.floor((maxTotalFileSizeInMB - attachmentsSize) * 100) / 100
      await wait(30)
      switch (nextActionRef.current) {
        case undefined:
          return
        case NextActionAfterModalClosed.OpenImagePicker: {
          nextActionRef.current = undefined
          await pickLocalImage({ maxSizeInMB: maxFileSizeUpload, messageErrorMaxSizeFile: messageErrorMaxTotalFile })
          return
        }
        case NextActionAfterModalClosed.OpenDocumentPicker: {
          nextActionRef.current = undefined
          await pickLocalDocument({ maxSizeInMB: maxFileSizeUpload, messageErrorMaxSizeFile: messageErrorMaxTotalFile })
          return
        }
      }
    }
    if (!isOpenAttachmentsModal && nextActionRef.current != null) {
      handleNextAction()
    }
  }, [attachmentsCount, attachmentsSize, isOpenAttachmentsModal, pickLocalImage, pickLocalDocument])

  const nextActionRef = useRef<NextActionAfterModalClosedPickfile>()

  const closeAttachmentsModal = useCallback(async (nextAction?: NextActionAfterModalClosedPickfile) => {
    nextActionRef.current = nextAction
    setIsOpenAttachmentsModal(false)
  }, [])
  const openImagePicker = useCallback(
    () => closeAttachmentsModal(NextActionAfterModalClosed.OpenImagePicker),
    [closeAttachmentsModal]
  )
  const openDocumentPicker = useCallback(
    () => closeAttachmentsModal(NextActionAfterModalClosed.OpenDocumentPicker),
    [closeAttachmentsModal]
  )
  const closeAttachmentsModalOnly = useCallback(() => closeAttachmentsModal(), [closeAttachmentsModal])

  const openAttachmentsModal = useCallback(async () => {
    if (attachmentsCount >= maxFileCount) {
      CustomAlert.alert("エラー", `添付ファイルの上限は${maxFileCount}までです。`)
      return
    }
    if (Platform.OS === "web") {
      const maxFileSizeUpload = Math.floor((maxTotalFileSizeInMB - attachmentsSize) * 100) / 100
      await pickLocalDocument({ maxSizeInMB: maxFileSizeUpload, messageErrorMaxSizeFile: messageErrorMaxTotalFile })
    } else {
      setIsOpenAttachmentsModal(true)
    }
  }, [attachmentsCount, pickLocalDocument, attachmentsSize])

  const submitCreate = useCallback(async () => {
    if (id) {
      await onUpdate()
    } else {
      await submit()
    }
  }, [onUpdate, submit, id])
  return (
    <>
      {isLoading ? (
        <View style={styles.loading}>
          <LoadingIndicator />
        </View>
      ) : (
        <>
          {openConfirmModal ? (
            <ConfirmCreateOffer
              setOpenModal={setOpenConfirmModal}
              value={myCreateData}
              organization={organization}
              localAttachments={localAttachments}
              submit={submitCreate}
              offerId={id}
              isLoading={isLoading || isSaving || isUpdating}
            />
          ) : (
            <CustomKeyboardAvoidingView>
              <SafeAreaView>
                {isLargeScreen && (
                  <View style={[styles.header]}>
                    <Button onPress={() => onCloseCreateOfferModal()}>
                      <RemoveIcon size={17} fill={Colors.orange} />
                    </Button>
                    <Text style={[styles.textHeaderPC]}>{id ? "募集の編集" : "新規募集作成"}</Text>
                  </View>
                )}
              </SafeAreaView>

              <CustomScrollView style={isLargeScreen && { marginHorizontal: 14 }}>
                <View style={isLargeScreen && { display: "flex", flexDirection: "row", justifyContent: "center" }}>
                  <View
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      width: isLargeScreen ? "60%" : "100%",
                      maxWidth: 1020,
                      paddingHorizontal: isLargeScreen ? 14 : 0,
                    }}
                  >
                    <View style={{ width: "100%" }}>
                      <View style={styles.container}>
                        <View style={[styles.contactType, { display: "flex", flexDirection: "row", alignItems: "center" }]}>
                          <Text style={styles.required}>{"※ "}</Text>
                          <Text>{"は必須項目"}</Text>
                        </View>
                        <View style={styles.contactType}>
                          <ItemLabel label="募集目的" />
                          <View style={styles.contactTypePicker}>
                            <CustomSelect value={purpose} options={purposeOptions} onChange={onChangePurpose} />
                          </View>
                        </View>
                        <View style={styles.contactType}>
                          <ItemLabel label="競技カテゴリ" />
                          <View style={styles.contactTypePicker}>
                            <Text style={{ paddingVertical: 2 }}>{organization?.category.label}</Text>
                            <Text style={{ paddingVertical: 2 }}>{organization?.subCategory.label}</Text>
                          </View>
                        </View>
                        <View style={styles.contactType}>
                          <ItemLabel
                            label="募集タイトル"
                            required
                            RightComponent={<InputLengthCounter text={myCreateData?.title} maxLength={50} unit={"字以内"} />}
                          />
                          <CustomTextInput
                            style={{ marginTop: 12 }}
                            value={myCreateData?.title}
                            onChangeText={(ev) => onChangeText(ev, "title" as never)}
                            maxLength={50}
                          />
                        </View>
                        <View style={styles.contactType}>
                          <ItemLabel
                            label="募集内容"
                            required
                            RightComponent={<InputLengthCounter text={myCreateData?.body} maxLength={1000} unit={"字以内"} />}
                          />
                          <MultilineTextInput
                            value={myCreateData?.body ?? ""}
                            onChangeText={(ev) => onChangeText(ev, "body" as never)}
                            numberOfLines={6}
                            maxLength={1000}
                            style={{ marginTop: 12, height: 240 }}
                          />
                        </View>
                        <View style={[styles.contactType, { width: "100%" }]}>
                          <ItemLabel label="開催日" required />
                          <View
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              paddingTop: 16,
                              width: "100%",
                              flex: 1,
                            }}
                          >
                            <CustomDateTimePicker
                              value={myCreateData?.date}
                              onChange={(ev) => onChangeDate(ev as Date)}
                              min={myCreateData?.publishEndDatetime || new Date()}
                              viewType="Date"
                              isDisabled={undecided}
                            />
                            {isLargeScreen && !allDay && (
                              <View style={[styles.viewTime, { marginTop: 0, marginLeft: 50 }]}>
                                <View style={[styles.centerRow]}>
                                  <Text style={styles.textTime}>{"開始"}</Text>
                                  <CustomDateTimePicker
                                    value={myCreateData?.startTime}
                                    onChange={(ev) => onTimeChange(ev as Date, "startTime" as never)}
                                    max={myCreateData?.endTime}
                                    viewType="Time"
                                    isDisabled={undecided}
                                  />
                                </View>
                                <View style={[styles.centerRow, { marginLeft: 16 }]}>
                                  <Text style={styles.textTime}>{"終了"}</Text>
                                  <CustomDateTimePicker
                                    isDisabled={undecided}
                                    value={myCreateData?.endTime}
                                    onChange={(ev) => onTimeChange(ev as Date, "endTime" as never)}
                                    min={myCreateData?.startTime}
                                    viewType="Time"
                                  />
                                </View>
                              </View>
                            )}
                            {isLargeScreen && (
                              <View style={[styles.centerRow, { marginLeft: 50 }]}>
                                <View style={[styles.centerRow, { marginRight: 16 }]}>
                                  <Checkbox isChecked={allDay} onPress={onClickAllDay} />
                                  <Text style={styles.textCheckBox}>{"終日"}</Text>
                                </View>
                                <View style={styles.centerRow}>
                                  <Checkbox isChecked={undecided} onPress={onClickUndecided} />
                                  <Text style={styles.textCheckBox}>{"未定"}</Text>
                                </View>
                              </View>
                            )}
                          </View>
                          {!isLargeScreen && (
                            <View>
                              {!allDay && (
                                <View style={styles.viewTime}>
                                  <View style={[styles.centerRow]}>
                                    <Text style={styles.textTime}>{"開始"}</Text>
                                    <CustomDateTimePicker
                                      value={myCreateData?.startTime}
                                      onChange={(ev) => onTimeChange(ev as Date, "startTime" as never)}
                                      max={myCreateData?.endTime}
                                      viewType="Time"
                                      isDisabled={undecided}
                                    />
                                  </View>
                                  <View style={[styles.centerRow, { marginLeft: 16 }]}>
                                    <Text style={styles.textTime}>{"終了"}</Text>
                                    <CustomDateTimePicker
                                      isDisabled={undecided}
                                      value={myCreateData?.endTime}
                                      onChange={(ev) => onTimeChange(ev as Date, "endTime" as never)}
                                      min={myCreateData?.startTime}
                                      viewType="Time"
                                    />
                                  </View>
                                </View>
                              )}
                              <View style={[styles.centerRow, { marginTop: 24 }]}>
                                <View style={[styles.centerRow, { marginRight: 16 }]}>
                                  <Checkbox isChecked={allDay} onPress={onClickAllDay} />
                                  <Text style={styles.textCheckBox}>{"終日"}</Text>
                                </View>
                                <View style={styles.centerRow}>
                                  <Checkbox isChecked={undecided} onPress={onClickUndecided} />
                                  <Text style={styles.textCheckBox}>{"未定"}</Text>
                                </View>
                              </View>
                            </View>
                          )}
                          {timeErrorMessage ? (
                            <ValidationErrorMessage style={{ marginTop: 10 }} message={timeErrorMessage} />
                          ) : undefined}
                        </View>
                        <View style={styles.contactType}>
                          <ItemLabel label="開催エリア" required noteRequired="都道府県のみ必須 " />
                          <View
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              paddingTop: 16,
                            }}
                          >
                            <View style={[styles.customButtonSelect]}>
                              <CustomSelect
                                options={prefectureOptions || []}
                                value={myCreateData?.prefecture?.id.toString()}
                                onChange={onChangePrefecture}
                                placeholder={"都道府県を選択してください"}
                              />
                            </View>
                            <View>
                              <CustomSelect
                                options={cityOptions || []}
                                disabled={!cityOptions || !cityOptions.length}
                                value={myCreateData?.city?.id}
                                onChange={onChangeCity}
                                placeholder={"市区町村を選択してください"}
                              />
                            </View>
                          </View>
                        </View>
                        <View style={styles.contactType}>
                          <ItemLabel
                            label="会場"
                            RightComponent={<InputLengthCounter text={myCreateData?.place} maxLength={100} unit={"字以内"} />}
                          />
                          <CustomTextInput
                            style={{ marginTop: 12 }}
                            value={myCreateData?.place}
                            onChangeText={(ev) => onChangeText(ev, "place" as never)}
                            maxLength={100}
                          />
                        </View>
                        <View style={styles.contactType}>
                          <ItemLabel
                            label="会場URL"
                            RightComponent={
                              <InputLengthCounter text={myCreateData?.placeURL} maxLength={100} unit={"字以内"} />
                            }
                          />
                          <CustomTextInput
                            style={{ marginTop: 12 }}
                            value={myCreateData?.placeURL}
                            onChangeText={(ev) => onChangeText(ev, "placeURL" as never)}
                            maxLength={100}
                          />
                          {placeUrlErrorMessage ? (
                            <ValidationErrorMessage style={{ marginTop: 10 }} message={placeUrlErrorMessage} />
                          ) : undefined}
                        </View>
                        <View style={[styles.contactType, { width: "100%" }]}>
                          <ItemLabel label="費用" required />
                          <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <View style={[styles.centerRow, { marginTop: 12 }]}>
                              <CustomTextInput
                                value={myCreateData?.expense?.toString()}
                                onChangeText={(text) => {
                                  const numericValue = text.replace(/[^0-9]/g, "")
                                  onChangeText(numericValue, "expense" as never)
                                }}
                                keyboardType="number-pad"
                                style={[{ width: 200 }]}
                                maxLength={9}
                                editable={!costUndecided}
                              />
                              <Text style={[styles.text, { marginLeft: 5 }]}>{"円"}</Text>
                            </View>

                            <View style={[styles.centerRow, styles.CheckboxFeeLarge]}>
                              <Checkbox isChecked={costUndecided} onPress={onClickCostUndecided} />
                              <Text style={styles.textCheckBox}>{"未定"}</Text>
                            </View>
                          </View>
                          {expenseErrorMessage ? (
                            <ValidationErrorMessage style={{ marginTop: 10 }} message={expenseErrorMessage} />
                          ) : undefined}
                        </View>
                        <View style={styles.contactType}>
                          <ItemLabel
                            label="費用の利用目的"
                            RightComponent={
                              <InputLengthCounter text={myCreateData?.expensePurpose} maxLength={100} unit={"字以内"} />
                            }
                          />
                          <CustomTextInput
                            style={{ marginTop: 12 }}
                            value={myCreateData?.expensePurpose}
                            onChangeText={(ev) => onChangeText(ev, "expensePurpose" as never)}
                            maxLength={100}
                          />
                        </View>
                        <View style={styles.contactType}>
                          <ItemLabel label="年齢層" />
                          <View
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              paddingTop: 16,
                            }}
                          >
                            <View style={[isLargeScreen ? styles.CustomSelectLarge : styles.CustomSelect]}>
                              <CustomSelect
                                options={filteredAgeOptions(true)}
                                onChange={(ev) => onChangeSelect("ageFrom", ev)}
                                value={myCreateData?.ageFrom?.label}
                                placeholder=" "
                              />
                            </View>
                            <Text style={[styles.text]}> {"～"} </Text>
                            <View style={[isLargeScreen ? styles.CustomSelectLarge : styles.CustomSelect]}>
                              <CustomSelect
                                options={filteredAgeOptions(false)}
                                onChange={(ev) => onChangeSelect("ageTo", ev)}
                                value={myCreateData?.ageTo?.label}
                                placeholder=" "
                              />
                            </View>
                            <Text style={[styles.text, { marginLeft: 5 }]}> {"歳"}</Text>
                          </View>
                          {ageToErrorMessage ? (
                            <ValidationErrorMessage style={{ marginTop: 10 }} message={ageToErrorMessage} />
                          ) : undefined}
                        </View>
                        <View style={[styles.contactType, { width: "100%" }]}>
                          <ItemLabel label="募集団体数" />
                          <View style={[{ marginTop: 12 }, styles.centerRow]}>
                            <CustomTextInput
                              value={myCreateData?.offerCount}
                              onChangeText={(text) => {
                                const numericValue = text.replace(/[^0-9]/g, "")
                                onChangeText(numericValue, "offerCount" as never)
                              }}
                              keyboardType="number-pad"
                              style={[{ width: 200 }]}
                              maxLength={3}
                            />
                            <Text style={[styles.text, { marginLeft: 5 }]}>{"団体"}</Text>
                          </View>
                          {offerCountErrorMessage ? (
                            <ValidationErrorMessage style={{ marginTop: 10 }} message={offerCountErrorMessage} />
                          ) : undefined}
                        </View>
                        <View style={[styles.contactType, { width: "100%" }]}>
                          <ItemLabel label="募集期限" required />
                          <View
                            style={{
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "row",
                              paddingTop: 16,
                              width: "100%",
                            }}
                          >
                            <CustomDateTimePicker
                              value={myCreateData?.publishEndDatetime}
                              onChange={(ev) => onChangePublishEndDatetime(ev as Date)}
                              min={new Date()}
                              viewType="Date"
                              isCheckMinDate={true}
                            />
                          </View>
                          {publishEndDatetimeErrorMessage ? (
                            <ValidationErrorMessage style={{ marginTop: 10 }} message={publishEndDatetimeErrorMessage} />
                          ) : undefined}
                        </View>
                        {id && (
                          <View style={styles.contactType}>
                            <ItemLabel label="ファイル添付" />
                            <View>
                              <MatchingAttachmentViewer
                                offerId={id}
                                attachmentFileNames={myCreateData?.attachmentFileNames || []}
                                organizationId={organizationId || ""}
                              />
                            </View>
                          </View>
                        )}
                        {!id && (
                          <>
                            {isLargeScreen ? (
                              <View
                                style={[isSmallScreen ? { display: "flex", flexDirection: "column" } : styles.attachmentsLarge]}
                              >
                                <View
                                  style={[
                                    isSmallScreen
                                      ? styles.attachments
                                      : { display: "flex", flexDirection: "column", width: 150 },
                                  ]}
                                >
                                  <ItemLabel label="ファイル添付" />
                                  <View>
                                    <Text
                                      style={isSmallScreen ? styles.attachmentsInfoLabel : styles.attachmentsInfoLabelLarge}
                                    >
                                      {attachmentsCount}/{maxFileCount} {attachmentsSize}MB/{maxTotalFileSizeInMB}MB
                                    </Text>
                                  </View>
                                </View>
                                <View style={[isSmallScreen ? styles.attachmentsSelection : styles.attachmentsSelectionLarge]}>
                                  <View style={[styles.attachmentItems]}>
                                    {localAttachments.map((props, index) => (
                                      <AttachmentItem {...props} key={index} />
                                    ))}
                                  </View>
                                  <View
                                    style={[
                                      isSmallScreen
                                        ? styles.selectAttachmentButtonContainer
                                        : styles.selectAttachmentButtonContainerLarger,
                                    ]}
                                  >
                                    <TextButton
                                      buttonType={ButtonType.Unprioritized}
                                      title="ファイルを選択する"
                                      onPress={openAttachmentsModal}
                                    />
                                  </View>
                                </View>
                              </View>
                            ) : (
                              <>
                                <View style={styles.attachments}>
                                  <ItemLabel label="ファイル添付" />
                                  <View>
                                    <Text style={styles.attachmentsInfoLabelLarge}>
                                      {attachmentsCount}/{maxFileCount} {attachmentsSize}MB/{maxTotalFileSizeInMB}MB
                                    </Text>
                                  </View>
                                </View>
                                <View style={styles.attachmentsSelection}>
                                  <View style={styles.attachmentItems}>
                                    {localAttachments.map((props, index) => (
                                      <AttachmentItem {...props} key={index} />
                                    ))}
                                  </View>
                                  <View style={styles.selectAttachmentButtonContainer}>
                                    <TextButton
                                      buttonType={ButtonType.Unprioritized}
                                      title="ファイルを選択する"
                                      onPress={openAttachmentsModal}
                                      style={{ width: "100%" }}
                                    />
                                  </View>
                                </View>
                              </>
                            )}
                          </>
                        )}
                      </View>
                    </View>
                  </View>
                  {!isLargeScreen && (
                    <View style={[{ paddingTop: 16, borderColor: Colors.white2, borderTopWidth: 1, marginTop: 16 }]}>
                      <View style={[styles.actions]}>
                        <TextButton
                          buttonType={ButtonType.Secondary}
                          disabled={isSaveDraftDisabled}
                          title={"下書きとして保存"}
                          onPress={onSaveDraft}
                          style={{ width: "100%" }}
                        />
                      </View>
                      <View style={[styles.actions, { paddingBottom: bottom + 16 }]}>
                        <TextButton
                          disabled={isDisabled}
                          buttonType={ButtonType.Primary}
                          title={"確認"}
                          onPress={() => setOpenConfirmModal(true)}
                          style={{ width: "100%" }}
                        />
                      </View>
                    </View>
                  )}
                </View>
              </CustomScrollView>
              {isLargeScreen && (
                <View style={[{ paddingTop: 16, borderColor: Colors.white2, borderTopWidth: 1, marginTop: 16 }]}>
                  <View style={[styles.actions, { marginBottom: 24 }]}>
                    <TextButton
                      buttonType={ButtonType.Secondary}
                      disabled={isSaveDraftDisabled}
                      title={"下書きとして保存"}
                      onPress={onSaveDraft}
                      isLoading={isSavingAsDraft}
                      style={{ width: 300, marginRight: 15 }}
                    />
                    <TextButton
                      disabled={isDisabled}
                      buttonType={ButtonType.Primary}
                      title={"確認"}
                      onPress={() => setOpenConfirmModal(true)}
                      style={{ width: 300 }}
                    />
                  </View>
                </View>
              )}
            </CustomKeyboardAvoidingView>
          )}
          <Modal visible={isOpenAttachmentsModal} transparent={true} onRequestClose={closeAttachmentsModalOnly}>
            <View style={styles.attachmentsModalContainer}>
              <View style={styles.attachmentsModalInner}>
                <Button onPress={closeAttachmentsModalOnly} style={styles.closeModalButton}>
                  <RemoveIcon />
                </Button>
                <View style={styles.modalActions}>
                  <View style={styles.modalActionButtonContainer}>
                    <TextButton
                      buttonType={ButtonType.Unprioritized}
                      title="写真ライブラリから追加"
                      onPress={openImagePicker}
                    />
                  </View>
                  <View style={styles.modalActionButtonContainer}>
                    <TextButton buttonType={ButtonType.Unprioritized} title="ファイルから追加" onPress={openDocumentPicker} />
                  </View>
                </View>
              </View>
            </View>
          </Modal>
        </>
      )}
    </>
  )
})
export type AttachmentItemPropsMatching = {
  teamId: string
  offerId?: string
  index: number
  fileName: string
  sizeInB: number
  onRemove: (index: number) => void
  onUploaderChange: (resourceUnit: ResourceUnit, uploader: () => Promise<Result<undefined, { message: string }>>) => void
} & (
  | {
      type: "image"
      localImage: LocalImage
    }
  | {
      type: "document"
      localDocument: LocalDocument
    }
)
const styles = StyleSheet.create({
  text: {
    fontWeight: "600",
    fontSize: 18,
    lineHeight: 25,
  },
  customButtonSelect: {
    paddingBottom: 20,
  },
  CustomSelect: {
    width: "42%",
  },
  CustomSelectLarge: {
    width: 103,
  },

  textHeaderPC: {
    fontSize: 18,

    fontWeight: "500",
    lineHeight: 25,
    textAlign: "center",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: 64,
    width: "100%",
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: Colors.white2,
    backgroundColor: Colors.white3,
  },
  headerLeft: {
    width: 45,
    height: 45,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    paddingTop: 16,
  },
  templatePicker: {
    paddingTop: 16,
  },
  contactType: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  contactTypePicker: {
    paddingTop: 16,
  },
  label: {
    fontSize: 16,
  },
  actions: {
    paddingHorizontal: 16,
    paddingTop: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  required: {
    color: Colors.red,
  },
  centerRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },

  textTime: {
    marginRight: 5,
  },
  textCheckBox: {
    marginLeft: 10,
  },

  attachments: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: ddp,
    paddingHorizontal: dp,
  },
  attachmentsLarge: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingTop: ddp,
    paddingHorizontal: dp,
  },
  selectAttachmentButtonContainer: {
    paddingHorizontal: dp,
  },
  selectAttachmentButtonContainerLarger: {
    width: 200,
    marginBottom: 4,
  },
  attachmentsInfoLabel: {
    fontSize: 14,
  },
  attachmentsInfoLabelLarge: {
    fontSize: 14,
    textAlign: "center",
  },
  attachmentsSelection: {
    paddingTop: dp,
  },
  attachmentsModalContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
  attachmentsModalInner: {
    width: 300,
    borderRadius: 16,
    backgroundColor: "#fff",
    padding: 16,
  },
  attachmentItems: {
    paddingBottom: 4,
    paddingLeft: dp,
    width: "100%",
  },
  attachmentsSelectionLarge: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "flex-end",
    flex: 1,
    paddingLeft: 190,
  },
  closeModalButton: {
    width: 16,
    height: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  modalActions: {
    paddingTop: 12,
  },
  modalActionButtonContainer: {
    paddingVertical: 4,
  },
  viewTime: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: 24,
  },
  CheckboxFeeLarge: {
    marginLeft: 16,
    marginTop: 12,
  },
  loading: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
})
