import dayjs from "dayjs"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { extendPaidVersionOrderApi } from "src/apis/organization/extendPaidVersionOrder"
import { PlanType } from "src/aws/API"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useFetcher } from "src/hooks/useFetcher"
import { listPlanSelector } from "src/recoil/selectors/paidTeam/listPlanSelector"
import { getOrderDetailState } from "src/recoil/selectors/paidTeam/orderDetailState"
import { orderPlanState } from "src/recoil/selectors/paidTeam/orderPlanState"
import { PlanModel } from "src/types/paidTeam/plan"
import { validateKanas, validateJapanese } from "src/utils/validate"
import { useAuthorizationData } from "../authorization/useAuthorizationData"
import { ModeCompleted } from "src/screens/organization/CompletedApplyPaid"
import { extendDataRequestState } from "src/recoil/atoms/organization/extenDataRequestState"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { ScrollView } from "react-native"
import { formatDate } from "src/constants/Day"
import { useRefresher } from "src/hooks/useRefresher"
import { listPlanRequestIdState } from "src/recoil/atoms/paidTeam/listPlanRequestIdState"
import { MIGRATE_PLAN_ID } from "src/utils/const"
import { getOrderDetailIdRequestState } from "src/recoil/selectors/paidTeam/getOrderDetailIdRequestState"

export const useExtendPaidTeam = (organizationId: string, gotoCompleted: (orderId: string, mode: ModeCompleted) => void) => {
  const { value: plans, isLoading: isLoadingPlans } = useAsyncSelector(listPlanSelector)
  const { value: organizationDetail, isLoading: isLoadingDetailOrg } = useAsyncSelector(
    organizationDetailSelectorFamily(organizationId)
  )
  const orderPlan = useRecoilValue(orderPlanState)
  const orderDetail = useRecoilValue(getOrderDetailState)
  const [extendDataStore, setExtendDataRequest] = useRecoilState(extendDataRequestState)
  const [invoiceAddress, setInvoiceAddress] = useState<string>()
  const [invoiceAddressKana, setInvoiceAddressKana] = useState<string>()
  const [confirmMode, setConfirmMode] = useState(false)
  const [policyStatus, setPolicyStatus] = useState(false)
  const [rulePaidStatus, setRulePaidStatus] = useState(false)
  const [isChangePlan, setIsChangePlan] = useState(false)
  const [remask, setRemask] = useState("")
  const [plan, setPlan] = useState<PlanModel>()
  const planStore = useRecoilValue(orderPlanState)
  const { accessToken } = useAuthorizationData()
  const scrollViewRef = useRef<ScrollView>(null)
  const refreshListPlan = useRefresher(listPlanRequestIdState)
  const refreshOrderDetail = useRefresher(getOrderDetailIdRequestState)
  const compareById = (a: any, b: any) => {
    const idA = parseInt(a.value)
    const idB = parseInt(b.value)
    return idA - idB
  }
  const currentOrderPlan = useMemo(
    () => ({
      id: orderPlan.id ?? "",
      planCode: orderPlan.planCode ?? "",
      name: orderPlan.name ?? "",
      type: orderPlan.type ?? "",
      memberLimit: orderPlan.memberLimit,
      teamLimit: orderPlan.teamLimit,
      period: orderPlan.period ?? 0,
      amount: orderPlan.amount ?? 0,
      taxRate: 10,
      planType: orderPlan.planType ?? PlanType.normal,
    }),
    [orderPlan]
  )

  const optionPlan = useMemo(() => {
    if (!plans || !organizationDetail) {
      return []
    }
    const filterPlan = plans.filter((e) => e.id !== currentOrderPlan.id)
    const arr = [...filterPlan, currentOrderPlan]
      .filter((plan) => (plan?.memberLimit ?? 0) >= (organizationDetail.memberNumber ?? 0) && plan.planType !== PlanType.end)
      .map((plan) => ({
        value: plan.id,
        label: `${plan.memberLimit}名${plan.name.substring(plan.name.indexOf("("))}`,
      }))
      .sort(compareById)
    return arr
  }, [plans, organizationDetail, currentOrderPlan])

  const onChangePlan = useCallback(
    (id: string, isChange: boolean) => {
      const withCurrentOrderPlan = (plans ?? []).filter((e) => e.id !== currentOrderPlan.id)
      const filteredPlans = [...withCurrentOrderPlan, currentOrderPlan].filter((plan) =>
        optionPlan.some((option) => option.value === plan.id)
      )
      const isPlan: PlanModel = filteredPlans.find((el) => el.id == id && el.planType !== PlanType.end) ?? {
        id: "",
        amount: 0,
        name: "",
        period: 0,
        planCode: "",
        planType: PlanType.normal,
        taxRate: 0,
        type: "",
      }
      setPlan(isPlan)
      setIsChangePlan(isChange && !!isPlan)
    },
    [plans, optionPlan, currentOrderPlan]
  )

  const requiredMessage = "必須項目です。"

  const planErrorMessage = useMemo(() => {
    if (plan?.id == "") return requiredMessage
  }, [plan])

  const invoiceAddressErrorMessage = useMemo(() => {
    if (invoiceAddress == "") return requiredMessage
    else if (invoiceAddress && !validateJapanese(invoiceAddress))
      return "請求書の宛名[振込名義]に漢字、カナ、平仮名以外の文字が入力されています。ご確認ください。"
    return ""
  }, [invoiceAddress])

  const invoiceAddressKanaErrorMessage = useMemo(() => {
    if (invoiceAddressKana == "") return requiredMessage
    return validateKanas(invoiceAddressKana ?? "") ? "" : "カナで入力してください。"
  }, [invoiceAddressKana])

  const { fetch: changeModeConfirmOrSubmit, isFetching: isLoadingSubmit } = useFetcher(
    useCallback(async () => {
      if (!confirmMode) {
        scrollViewRef.current?.scrollTo({
          y: 0,
        })
        return setConfirmMode(true)
      }

      if (!accessToken || !invoiceAddressKana || !invoiceAddress || !plan?.id || !orderDetail.id) return

      const result = await extendPaidVersionOrderApi({
        planId: plan.id,
        orderId: orderDetail.id,
        invoiceAddressee: invoiceAddress,
        invoiceAddresseeKana: invoiceAddressKana,
        applicantRemark: remask,
        accessToken: accessToken,
      })
      setExtendDataRequest({
        planId: plan.id,
        orderId: orderDetail.id,
        invoiceAddressee: invoiceAddress,
        invoiceAddresseeKana: invoiceAddressKana,
        applicantRemark: remask,
        isSubmit: true,
      })
      if (result.isOk) {
        return gotoCompleted(result.content.orderId, "SUCCESS")
      }
      return gotoCompleted(result.error.orderId, result.error.orderId ? "FAIL_MAIL" : "FAIL_DB")
    }, [
      confirmMode,
      plan,
      invoiceAddress,
      invoiceAddressKana,
      remask,
      accessToken,
      orderDetail,
      gotoCompleted,
      setExtendDataRequest,
    ])
  )

  const formatCreatedDate = useMemo(() => dayjs(orderDetail?.createdAt ?? "").format(formatDate.normal), [orderDetail])

  const disabledSubmit = useMemo(() => {
    if (confirmMode) return !rulePaidStatus || !policyStatus
    return (
      !!invoiceAddressKanaErrorMessage ||
      !invoiceAddressKana ||
      !invoiceAddress ||
      !plan?.id ||
      !!invoiceAddressErrorMessage ||
      !!planErrorMessage
    )
  }, [
    invoiceAddressKanaErrorMessage,
    invoiceAddressKana,
    invoiceAddress,
    plan,
    rulePaidStatus,
    policyStatus,
    confirmMode,
    planErrorMessage,
    invoiceAddressErrorMessage,
  ])

  const convertDate = (date: string, isEnd?: boolean) => {
    const pattern = /^\d{4}-\d{2}-\d{2}$/
    if (!pattern.test(date)) return ""
    const result = isEnd
      ? dayjs(new Date(date)).add(1, "month").startOf("month").format(formatDate.japanNormal)
      : dayjs(date).format(formatDate.japanNormal)
    return result
  }

  const showEndDateExtend = useCallback(
    (date: string) => {
      const pattern = /^\d{4}-\d{2}-\d{2}$/
      if (!pattern.test(date) || !isChangePlan) return ""
      const period = plan?.period ? Number(plan.period) : 1
      const endDate = dayjs(new Date(date)).add(period, "month").endOf("month").format(formatDate.UntilYYYYMMDD)
      return `(${endDate})`
    },
    [plan, isChangePlan]
  )
  useEffect(() => {
    refreshListPlan()
    refreshOrderDetail()
  }, [refreshListPlan, refreshOrderDetail])
  useEffect(() => {
    if (extendDataStore.isSubmit) {
      onChangePlan(extendDataStore.planId, true)
      setInvoiceAddress(extendDataStore.invoiceAddressee)
      setInvoiceAddressKana(extendDataStore.invoiceAddresseeKana)
      setRemask(extendDataStore.applicantRemark)
      setConfirmMode(true)
    }
    if (orderDetail && !extendDataStore.isSubmit) {
      setInvoiceAddress(orderDetail.invoiceAddressee ?? "")
      setInvoiceAddressKana(orderDetail.invoiceAddresseeKana ?? "")
      onChangePlan(planStore.id, true)
    }
  }, [extendDataStore, onChangePlan, orderDetail, planStore])
  const isLoadingView = useMemo(() => isLoadingDetailOrg || isLoadingPlans, [isLoadingDetailOrg, isLoadingPlans])
  const isMigratePlan = useMemo(() => plan?.id === MIGRATE_PLAN_ID, [plan])
  return {
    optionPlan,
    confirmMode,
    invoiceAddress,
    invoiceAddressKana,
    policyStatus,
    rulePaidStatus,
    remask,
    setRemask,
    scrollViewRef,
    setPolicyStatus,
    setRulePaidStatus,
    setConfirmMode,
    setInvoiceAddress,
    setInvoiceAddressKana,
    changeModeConfirmOrSubmit,
    invoiceAddressKanaErrorMessage,
    invoiceAddressErrorMessage,
    planErrorMessage,
    plan,
    onChangePlan,
    disabledSubmit,
    organizationDetail,
    orderDetail,
    formatCreatedDate,
    isChangePlan,
    convertDate,
    showEndDateExtend,
    planStore,
    isLoadingSubmit,
    setExtendDataRequest,
    isLoadingView,
    isMigratePlan,
  }
}
