import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { hasOrganizationNoticeSelector } from "src/recoil/selectors/bottomTab/hasOrganizationNoticeSelector"
import { unreadContactCountSelector } from "src/recoil/selectors/bottomTab/unreadContactCountSelector"
import { hasPreTeamMemberSelector } from "src/recoil/selectors/bottomTab/hasPreTeamMemberSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { unreadContactCountRequestIdState } from "src/recoil/atoms/bottomTab/unreadContactCountRequestIdState"
import { hasPreTeamMemberRequestIdState } from "src/recoil/atoms/bottomTab/hasPreTeamMemberRequestIdState"
import { hasServiceInformationSelector } from "src/recoil/selectors/bottomTab/hasServiceInformationSelector"
import { informationsRequestIdState } from "src/recoil/atoms/settings/informationsRequestIdState"
import { useMemo } from "react"
import { notificationsRequestIdState } from "src/recoil/atoms/home/notificationsRequestIdState"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { hasUnreadThreadMessageSelectorFamily } from "src/recoil/selectors/contact/hasUnreadThreadMessageSelectorFamily"
import { hasOrganizationMatchingNoticeSelector } from "src/recoil/selectors/matching/hasOrganizationMatchingNoticeSelector"
import { notificationsSelector } from "src/recoil/selectors/home/notificationsSelector"

export const useBottomTabBadgeData = () => {
  const { value: hasOrganizationNotice } = useAsyncSelector(hasOrganizationNoticeSelector)
  const refreshHasOrganizationNotice = useRefresher(notificationsRequestIdState)

  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const { value: hasUnreadThreadMessage } = useAsyncSelector(hasUnreadThreadMessageSelectorFamily({ organizationId }))

  const { value: _unreadContactCount } = useAsyncSelector(unreadContactCountSelector)
  const unreadContactCount = useMemo(
    () => (_unreadContactCount != null ? Object.values(_unreadContactCount).reduce((l, r) => l + r, 0) : undefined),
    [_unreadContactCount]
  )
  const refreshUnreadContactCount = useRefresher(unreadContactCountRequestIdState)

  const { value: hasPreTeamMemberByTeam } = useAsyncSelector(hasPreTeamMemberSelector)
  const refreshHasPreTeamMember = useRefresher(hasPreTeamMemberRequestIdState)
  const hasPreTeamMember = useMemo(
    () => Object.values(hasPreTeamMemberByTeam || {}).some((bool) => bool),
    [hasPreTeamMemberByTeam]
  )

  const { value: hasServiceInformation } = useAsyncSelector(hasServiceInformationSelector)
  const refreshHasServiceInformation = useRefresher(informationsRequestIdState)

  const { value: hasOrganizationMatchingNotice } = useAsyncSelector(hasOrganizationMatchingNoticeSelector)
  const { value: notifications } = useAsyncSelector(notificationsSelector)

  return {
    hasOrganizationNotice,
    refreshHasOrganizationNotice,
    unreadContactCount,
    refreshUnreadContactCount,
    hasPreTeamMember,
    refreshHasPreTeamMember,
    notifications,
    hasServiceInformation,
    refreshHasServiceInformation,
    hasUnreadThreadMessage,
    hasOrganizationMatchingNotice,
  }
}
