import { FC } from "react"
import Svg, { Circle, G, Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

export const ClockWithCircleIcon: FC = () => (
  <Svg width="15.5" height="15.5" viewBox="0 0 15.5 15.5">
    <G data-name="グループ 2716" transform="translate(-1092.25 -939.25)">
      <Circle
        data-name="楕円形 13"
        cx="7"
        cy="7"
        r="7"
        transform="translate(1093 940)"
        fill="none"
        stroke={Colors.greyshBrown}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
      />
      <Path
        data-name="パス 49"
        d="M12 6v4.384l2.922 1.461"
        transform="translate(1088.168 936.458)"
        fill="none"
        stroke={Colors.greyshBrown}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5px"
      />
    </G>
  </Svg>
)

ClockWithCircleIcon.displayName = "ClockWithCircleIcon"
