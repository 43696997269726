import { atomFamily, selectorFamily } from "recoil"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"

export const kanaNicknameStateFamily = atomFamily<string | undefined, string>({
  key: "atoms/organization/myProfile/kanaNicknameStateFamily",
  default: selectorFamily<string | undefined, string>({
    key: "atoms/organization/myProfile/kanaNicknameStateFamily_default",
    get:
      (organizationId) =>
      ({ get }) => {
        const myProfile = get(myProfileSelectorFamily(organizationId))
        return myProfile?.kanaNickname
      },
  }),
})
