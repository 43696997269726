import { useFocusEffect, useNavigation } from "@react-navigation/native"
import { useCallback } from "react"
import { useSetRecoilState } from "recoil"
import { Screens } from "src/constants/Screens"
import { linkingRestorerState } from "../atoms/linkingRestorerState"
import { useRequestData } from "./useRequestData"

export const useSaveLinking = (restorer: () => void) => {
  const navigation = useNavigation()
  const setLinkingRestorer = useSetRecoilState(linkingRestorerState)
  const requestDataResult = useRequestData()

  useFocusEffect(
    useCallback(() => {
      if (!requestDataResult.isOk && !requestDataResult.error.isLoading) {
        setLinkingRestorer({ restorer })
        navigation.reset({ index: 0, routes: [{ name: Screens.AuthorizationTop }] })
      }
    }, [requestDataResult, restorer, setLinkingRestorer, navigation])
  )
}
