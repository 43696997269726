import { Result } from "../../utils/Result";
import { GqlError, multiGetMigrationStatus } from "../../aws/customAPI";

type Params = {
  accessToken: string;
  oldRa9Ra9TeamIds: Array< number >;
}

export type GetMultiMigrationStatusSuccess = MigrationTeamsModel[]

export type MigrationTeamsModel = {
  oldRa9Ra9TeamId: number;
  teamId?: string | null | undefined;
  isMigrated?: boolean | null | undefined;
}

type GetMultiMigrationStatusFailure = {
  message: string;
}

export const getMultiMigrationStatus = async ({ accessToken, oldRa9Ra9TeamIds}: Params): Promise<Result<GetMultiMigrationStatusSuccess, GetMultiMigrationStatusFailure>> => {
  return multiGetMigrationStatus(accessToken, {
    input: {
      oldRa9Ra9TeamIds,
    }
  }).then(res => {
    if (res.data) {
      return Result.Ok<GetMultiMigrationStatusSuccess>(res.data.multiGetMigrationStatus.items.map(item => ({
        oldRa9Ra9TeamId: item.oldRa9Ra9TeamId,
        teamId: item.teamId,
        isMigrated: item.isMigrated,
      })))
    } else {
      return Result.Error<GetMultiMigrationStatusFailure>({ message: "移行状況の取得に失敗しました。" });
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "移行状況の取得に失敗しました。";
    return Result.Error<GetMultiMigrationStatusFailure>({
      message: message
    });
  });
};