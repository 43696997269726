import { PropsWithChildren, useState } from "react"
import { Platform, Text, TouchableOpacity, View, Linking, StyleSheet } from "react-native"
import Hyperlink from "react-native-hyperlink"
import { Colors } from "src/constants/Colors"
import ArrowDown from "src/screens/contact/contactDetail/assets/ArrowDown"
import ArrowUp from "src/screens/contact/contactDetail/assets/ArrowUp"

type OwnProps = {
  // style: StyleProp<TextStyle>
}

const maxLines = 4

const CollapseText = ({ children }: PropsWithChildren<OwnProps>) => {
  const [shouldShowButton, setShouldShowButton] = useState(false)
  const [show, setShow] = useState(Platform.OS !== "web")
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [maxHeight, setMaxHeight] = useState(0)

  const toggleShow = () => setShow((prevShow) => !prevShow)

  const handleFirstRender = (exceedsMaxLines: boolean) => {
    setShouldShowButton(exceedsMaxLines)
    setShow(!exceedsMaxLines)
    setIsFirstRender(false)
  }

  const handleTextLayoutMobile = (e: any) => {
    if (Platform.OS === "web" || !isFirstRender) return
    const { lines } = e.nativeEvent
    handleFirstRender(lines.length > maxLines)
  }

  const handleLayoutWebSupport = (e: any) => {
    if (Platform.OS !== "web") return
    const { height } = e.nativeEvent.layout
    setMaxHeight(height)
    setShow(true)
  }
  const handleLayoutWeb = (e: any) => {
    if (Platform.OS !== "web" || !isFirstRender) return
    const { height } = e.nativeEvent.layout
    handleFirstRender(height > maxHeight)
  }

  const renderCollapsedSupport = () => (
    <Text style={styles.collapsedSupportText} numberOfLines={maxLines} onLayout={handleLayoutWebSupport}>
      {"\n".repeat(maxLines)}
    </Text>
  )

  const renderToggleButton = () => (
    <TouchableOpacity onPress={toggleShow}>
      <View style={styles.toggleButtonContainer}>
        {show ? <ArrowUp /> : <ArrowDown />}
        <Text style={styles.toggleButtonText}>{show ? "閉じる" : "すべて表示"}</Text>
      </View>
    </TouchableOpacity>
  )

  const renderText = () => (
    <Hyperlink
      linkStyle={styles.hyperlinkText}
      onPress={async (url) => await Linking.openURL(url)}
      style={styles.hyperlinkContainer}
    >
      <View style={styles.textContainer}>
        <Text
          style={[styles.mainText, Platform.OS !== "web" ? styles.mainTextMobile : !show && { maxHeight }]}
          numberOfLines={show || Platform.OS === "web" ? 0 : maxLines}
          onTextLayout={handleTextLayoutMobile}
          onLayout={handleLayoutWeb}
        >
          {children}
        </Text>
      </View>
    </Hyperlink>
  )

  return (
    <View style={styles.container}>
      <View style={styles.contentContainer}>
        {Platform.OS === "web" && maxHeight === 0 ? renderCollapsedSupport() : renderText()}
        {shouldShowButton && !show && (
          <>
            {["0.5", "0.8"].map((opacity, index) => (
              <View key={index} style={[styles.fadeOverlay, { bottom: index === 0 ? 20 : 0, opacity: parseFloat(opacity) }]} />
            ))}
          </>
        )}
      </View>
      {shouldShowButton && <View style={styles.buttonContainer}>{renderToggleButton()}</View>}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
  },
  contentContainer: {
    flexDirection: "row",
    position: "relative",
    marginTop: 16,
    width: "100%",
  },
  textContainer: {
    width: "100%",
    overflow: "hidden",
  },
  mainText: {
    fontSize: 14,
    lineHeight: 19.6,
    color: Colors.greyshBrown,
    width: "100%",
  },
  mainTextMobile: {
    marginRight: 16,
  },
  hyperlinkContainer: {
    width: "100%",
    flexShrink: 1,
  },
  hyperlinkText: {
    lineHeight: 19.6,
    color: "#2980b9",
    fontSize: 14,
    width: "100%",
  },
  collapsedSupportText: {
    fontSize: 14,
    lineHeight: 19.6,
    width: "10%",
  },
  toggleButtonContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  toggleButtonText: {
    fontWeight: "300",
    fontSize: 14,
    lineHeight: 19.6,
    marginLeft: 4,
    color: Colors.orange,
  },
  buttonContainer: {
    width: "100%",
    marginTop: 8,
    flexDirection: "row",
    justifyContent: "center",
  },
  fadeOverlay: {
    width: "100%",
    height: 20,
    backgroundColor: Colors.backgroundColor,
    position: "absolute",
    left: 0,
  },
})

export default CollapseText
