import { searchTeamMember } from './../../aws/customAPI';
import { Result } from "src/utils/Result"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import { TeamMemberType, OrderBy, TeamMemberSortBy } from 'src/aws/API';

export type SearchTeamMembersParams = {
  accessToken: string
  groupId: string
  name?: string | null
  page: number | 0
  limit?: number
  excludedIds?: Array<string | null> | null
  excludedAllGroup?: boolean
  teamId: string
  isRoleSort?: boolean | null
}

type GetTeamMembersSuccess = {
  data: TeamMemberType[]
  total: number
  page: number
  limit: number
}

type GetTeamMembersFailure = {
  message: string
}

export const getTeamMembersByName = async (params: SearchTeamMembersParams): Promise<Result<GetTeamMembersSuccess, GetTeamMembersFailure>> => {
  if (params.excludedAllGroup) {
    return await execApi(() => searchTeamMember(params.accessToken, {
      input: {
        teamId: params.teamId,
        name: params.name ?? "",
        excludedIds: params.excludedIds ?? [],
        excludedAllGroup: params.excludedAllGroup,
        limit: params.limit ?? 10,
        page: params.page,
        sortBy: params.isRoleSort ? TeamMemberSortBy.role : TeamMemberSortBy.memberSeq,
        orderBy: params.isRoleSort ? OrderBy.DESC : OrderBy.ASC,
      }, appInfo
    }), res => {
      return (
        {
          data: res.searchTeamMember.items,
          limit: res.searchTeamMember.limit,
          total: res.searchTeamMember.total,
          page: res.searchTeamMember.page
        }
      )
    })
  } else {
    return await execApi(() => searchTeamMember(params.accessToken, {
      input: {
        teamId: params.teamId,
        name: params.name ?? "",
        excludedIds: params.excludedIds ?? [],
        excludedGroupId: params.groupId ?? undefined,
        limit: params.limit ?? 10,
        page: params.page,
        sortBy: params.isRoleSort ? TeamMemberSortBy.role : TeamMemberSortBy.memberSeq,
        orderBy: params.isRoleSort ? OrderBy.DESC : OrderBy.ASC,
      }, appInfo
    }), res => {
      return (
        {
          data: res.searchTeamMember.items,
          limit: res.searchTeamMember.limit,
          total: res.searchTeamMember.total,
          page: res.searchTeamMember.page
        }
      )
    })
  }

}