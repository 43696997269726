import { selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { questionnaireAnswerCsvDataRequestIdState } from "src/recoil/atoms/csv/questionnaireAnswerCsvDataRequestIdState"
import { QuestionnaireAnswerModel } from "src/types/csv/answerCsvData"
import { selectedMyOrganizationSelector } from "../organization/selectedMyOrganizationSelector"
import { myProfileSelectorFamily } from "../organization/myProfile/myProfileSelectorFamily"
import { getQuestionnaireAnswerCsvData } from "src/apis/csv/getQuestionnaireAnswerCsvData"

export const questionnaireAnswerCsvDataSelectorFamily = selectorFamily<
  QuestionnaireAnswerModel | undefined,
  { mailId: string | undefined }
>({
  key: "selectors/csvData/questionnaireAnswerCsvDataSelectorFamily",
  get:
    ({ mailId }) =>
    async ({ get }) => {
      get(questionnaireAnswerCsvDataRequestIdState)
      if (mailId == undefined) return
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) {
        return
      }
      const selectedMyTeam = get(selectedMyOrganizationSelector)
      if (selectedMyTeam == null) return
      const teamId = selectedMyTeam.id
      const myProfile = get(myProfileSelectorFamily(teamId))
      const memberId = myProfile?.memberId
      if (memberId == null) return
      const result = await getQuestionnaireAnswerCsvData({ accessToken, teamId, mailId, memberId })
      if (!result.isOk) {
        return
      }
      return result.content
    },
})
