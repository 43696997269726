import { Tokens } from "src/recoil/atoms/authorization/tokensState"
import { deleteAsync } from "./secureStorage/deleteAsync"
import { getAsync } from "./secureStorage/getAsync"
import { setAsync } from "./secureStorage/setAsync"

const keyPrefix = "tokens"

const TokenType = Object.freeze({
  AccessToken: "access_token",
  ExpiresIn: "expires_in",
  IdToken: "id_token",
  RefreshToken: "refresh_token",
  TokenType: "token_type",
} as const)

type TokenType = typeof TokenType[keyof typeof TokenType]

const getKey = (tokenType: TokenType) => `${keyPrefix}.${tokenType}`

const accessTokenKey = getKey(TokenType.AccessToken)
const expiresInKey = getKey(TokenType.ExpiresIn)
const idTokenKey = getKey(TokenType.IdToken)
const refreshTokenKey = getKey(TokenType.RefreshToken)
const tokenTypeKey = getKey(TokenType.TokenType)

export const getTokens = async () => {
  const [accessToken, expiresIn, idToken, refreshToken, tokenType] = await Promise.all([
    getAsync(accessTokenKey),
    getAsync(expiresInKey),
    getAsync(idTokenKey),
    getAsync(refreshTokenKey),
    getAsync(tokenTypeKey),
  ])
  if (accessToken == null || expiresIn == null || idToken == null || refreshToken == null || tokenType == null) {
    return
  }
  return {
    accessToken,
    expiresIn,
    idToken,
    refreshToken,
    tokenType,
  }
}

export const setTokens = async (tokens?: Tokens) => {
  if (tokens == null) {
    return Promise.all([
      deleteAsync(accessTokenKey),
      deleteAsync(expiresInKey),
      deleteAsync(idTokenKey),
      deleteAsync(refreshTokenKey),
      deleteAsync(tokenTypeKey),
    ])
  } else {
    return Promise.all([
      setAsync(accessTokenKey, tokens.accessToken),
      setAsync(expiresInKey, tokens.expiresIn),
      setAsync(idTokenKey, tokens.idToken),
      setAsync(refreshTokenKey, tokens.refreshToken),
      setAsync(tokenTypeKey, tokens.tokenType),
    ])
  }
}
