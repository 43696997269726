import { getMailQuery, getUnreadThreadMessageCountByIds, listUnreadMail} from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { haveOldRa9TeamJoinSelector } from "src/recoil/selectors/team/haveOldRa9TeamJoinSelector"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"

type Params = {
  accessToken: string
  accountId: string
  organizationId: string
  teamMemberId:string
}

export type UnreadResponse = {
  hasUnread: boolean
  type?: "inbox" | "outbox"
}

export const hasUnreadThreadMessage = async ({accessToken, accountId, organizationId ,teamMemberId}: Params): Promise<UnreadResponse> => {
  const result = await execApi(
    () => getUnreadThreadMessageCountByIds(accessToken, {
      accountId,
      teamId: organizationId,
      aggrUnit: "account"
    }),
    res => res.searchThreadMessages.aggregateItems[0].result,
  )
  if (!result.isOk || result.content.buckets.length === 0) {
    return {
      hasUnread: false
    }
  }
  if (!result.isOk || result.content.buckets.length === 0) {
    return {
      hasUnread: false
    }
  }
  const mailIds = new Set<string>()
  result.content.buckets.every(doc => mailIds.add(doc.key))
  const mailIDs = result.content.buckets.map(bucket => ({
  mailID: {
    eq: bucket.key
  }
}));
  const result2 = await execApi(
    () => listUnreadMail(accessToken, {
      teamMemberId,
      mailIDs,
    }),
    res => res.searchTeamMemberMails
  )
  const mailCreator = new Set()
  for await (const mailId of mailIds) {
    const accountIdResult = await execApi(() => getMailQuery(accessToken, { mailId }), res => res)
    if (accountIdResult.isOk) {
      mailCreator.add(accountIdResult.content)
    }
  }
  if(result2.isOk && result2.content.total=== 0){
    return {
      hasUnread: false
    }
  }
  if (mailCreator.has(accountId)) {
    return {
      hasUnread: true,
      type: "outbox",
    }
  }
  
  return {
    hasUnread: true
  }
}
