import { createGroup, GqlError } from "src/aws/customAPI"
import { Result } from "src/utils/Result"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Member = {
  id: string
  isLeader: boolean
}

type Params = {
  accessToken: string
  organizationId: string
  groupName: string
  members: Member[]
}

type SuccessResponse = undefined

type FailureResponse = {
  message: string
}

export const postGroupInOrganization = async (params: Params): Promise<Result<SuccessResponse, FailureResponse>> => {
  const { accessToken, organizationId, groupName, members } = params
  return execApi(
    () =>
      createGroup(accessToken, {
        input: {
          teamId: organizationId,
          name: groupName,
          members: members.map((m) => ({
            id: m.id,
            leaderFlg: m.isLeader,
          })),
        },
        appInfo,
      }),
    (_) => undefined,
    {
      identifiers: ["postGroupInOrganization", "createGroup"],
    }
  )
}
