import { ComponentProps, memo } from "react"
import { RefreshControl } from "react-native"
import { Colors } from "src/constants/Colors"

type Props = Omit<ComponentProps<typeof RefreshControl>, "tintColor">

/**
 * CustomRefreshControl is `projects` because colors will be controlled by theme with recoil.
 */
export const CustomRefreshControl = memo<Props>((props) => {
  return <RefreshControl {...props} tintColor={Colors.coolGrey} />
})
