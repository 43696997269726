import { StyleSheet, Text, View } from "react-native"
import { memo } from "react"
import { BadgeIcon } from "src/components/parts/icons/BadgeIcon"

type Props = {
  title: string
  showBadge: boolean
}
export const DrawerLabel = memo<Props>(({ title, showBadge }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>{title}</Text>
      {showBadge ? <BadgeIcon size={"small"} /> : null}
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    fontWeight: "bold",
  },
})
