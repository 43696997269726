import { atom } from "recoil"
import { getTokens, setTokens } from "src/storage/tokens"

export type Tokens = {
  accessToken: string
  expiresIn: string
  idToken: string
  refreshToken: string
  tokenType: string
}

export const tokensState = atom<Tokens | undefined>({
  key: "atoms/session/tokensState",
  default: getTokens(),
  effects: [
    ({ onSet }) => {
      onSet((newValue) => setTokens(newValue))
    },
  ],
})
