import { StyleSheet, View, Image, Text, Linking } from "react-native"
import { memo, useEffect, useMemo } from "react"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"
import { ShowDate } from "src/utils/useDateTime"
import { ScrollView } from "react-native-gesture-handler"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { OclockIcon } from "src/components/parts/icons/OclockIcon"
import { LocationPinIcon } from "src/components/parts/icons/LocationPinIcon"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { createOrEditParams } from "src/recoil/atoms/matching/createOrEdit/createOrEditMatchingOfferState"
import { AttachmentItemPropsMatching } from "src/screens/matching/OfferCreate"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { Organization } from "src/queries/organization/getOrganizationDetail"
import { SafeAreaView } from "react-native"
import { ButtonType, TextButton } from "../buttons/TextButton"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { matchingIcon } from "src/constants/matchingIcon"
import { AttachmentItem } from "./AttachmentItem"
import { routeNameState } from "src/recoil/atoms/routeNameState"
import { useRecoilValue } from "recoil"
import { Screens } from "src/constants/Screens"

type Props = {
  value?: createOrEditParams
  offerId?: string
  organization?: Organization
  setOpenModal: (value: boolean) => void
  localAttachments?: AttachmentItemPropsMatching[]
  submit: () => void
  isLoading?: boolean
}

export const ConfirmCreateOffer = memo<Props>(
  ({ value, offerId, organization, localAttachments, setOpenModal, submit, isLoading }) => {
    const isLargeScreen = useCheckPCScreen()
    const routeName = useRecoilValue(routeNameState)

    const formatDateTime = useMemo(
      () => ShowDate(value?.date ? new Date(value?.date) : null, value?.startTime, value?.endTime) + " ",
      [value]
    )
    const formatPublishEndDatetime = useMemo(
      () => value?.publishEndDatetime && ShowDate(new Date(value?.publishEndDatetime), true),
      [value?.publishEndDatetime]
    )

    const backToCreate = () => {
      setOpenModal(false)
    }

    const { bottom } = useSafeAreaInsets()

    const convertTimeToHours = (time?: Date) => {
      if (!time) {
        return ""
      }
      const formatNumber = (num: number) => (num < 10 ? `0${num}` : num)
      return `${formatNumber(time?.getHours())}:${formatNumber(time?.getMinutes())}`
    }

    const { resourceUrl: teamImageUrl, refreshResourceUrl: refreshTeamImageUrl } = useResource({
      type: "matchingTeamImage",
      teamId: organization?.id || "",
      size: "thumbnail",
    })

    useEffect(() => {
      if (routeName === Screens.MatchingOfferCreate) {
        refreshTeamImageUrl()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [routeName])

    return (
      <CustomKeyboardAvoidingView>
        <SafeAreaView style={{ backgroundColor: Colors.white3 }}>
          {isLargeScreen && (
            <View style={[styles.header]}>
              <Button onPress={() => backToCreate()}>
                <RemoveIcon size={17} fill={Colors.orange} />
              </Button>
              <Text style={[styles.textHeaderPC]}>{offerId ? "募集の編集" : "新規募集作成"}</Text>
            </View>
          )}
          <View style={[styles.header]}>
            <Text style={[isLargeScreen ? styles.textHeaderPC : styles.textHeader, { width: "100%" }]}>
              {"募集内容を確認してください。"}
            </Text>
          </View>
        </SafeAreaView>
        {value && (
          <ScrollView>
            <View style={isLargeScreen && { display: "flex", flexDirection: "row", justifyContent: "center" }}>
              <View
                style={[
                  styles.container,
                  isLargeScreen ? { width: "60%", maxWidth: 1020, paddingHorizontal: 30 } : { marginHorizontal: 0 },
                ]}
              >
                <View style={[styles.contactTeam, { marginBottom: 24 }]}>
                  <Image
                    style={[styles.imageTeam]}
                    source={teamImageUrl ? { uri: teamImageUrl } : matchingIcon}
                    onError={refreshTeamImageUrl}
                  />
                  <View>
                    <Text style={styles.organizationName}>{organization?.organizationName}</Text>
                  </View>
                </View>
                <View style={{ marginBottom: 24 }}>
                  <Text style={styles.litmitTime}>
                    {"募集期限："}
                    {formatPublishEndDatetime}まで
                  </Text>
                  <Text style={styles.titleMatching}>{value?.title}</Text>
                </View>

                <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginBottom: 24 }}>
                  <Text style={[styles.bgLabel]}>{organization?.subCategory?.label}</Text>
                  {value?.ageFrom?.id && value?.ageTo?.id ? (
                    <Text style={styles.bgLabel}>
                      {value?.ageFrom.label}～{value?.ageTo.label}
                      {"歳"}
                    </Text>
                  ) : undefined}
                  <Text style={[styles.bgLabel]}>{"合同練習・試合"}</Text>
                </View>
                <View style={{ marginBottom: 24 }}>
                  <View style={{ display: "flex", flexDirection: "row", marginBottom: 12 }}>
                    <View style={{ marginRight: 10 }}>
                      <OclockIcon />
                    </View>
                    <Text style={{ fontSize: 12, fontWeight: "300", lineHeight: 17 }}>
                      {"開催日時："}
                      {formatDateTime}
                    </Text>

                    {(value?.startTime || value?.endTime) && (
                      <Text style={{ fontSize: 12, fontWeight: "300", lineHeight: 17 }}>
                        {convertTimeToHours(value?.startTime)}～{convertTimeToHours(value?.endTime)}
                      </Text>
                    )}
                  </View>
                  {(value?.prefecture || value?.city) && (
                    <View style={{ display: "flex", flexDirection: "row", marginBottom: 12 }}>
                      <View style={{ marginRight: 10 }}>
                        <LocationPinIcon />
                      </View>
                      <Text style={{ fontSize: 12, fontWeight: "300", lineHeight: 17 }}>
                        {value?.prefecture?.label} {value?.city?.label}
                      </Text>
                    </View>
                  )}
                  <Text style={styles.offer}>
                    {"会場："}
                    {value?.place}
                  </Text>
                  <View style={styles.url}>
                    <Text style={styles.offer}>{"会場URL："}</Text>
                    <Text
                      numberOfLines={1}
                      ellipsizeMode="tail"
                      style={[styles.offerUrl, !isLargeScreen && { width: 250 }]}
                      onPress={() => Linking.openURL(value?.placeURL || "")}
                    >
                      {value?.placeURL}
                    </Text>
                  </View>
                  <Text style={styles.offer}>
                    {"費用："}
                    {value?.expense}
                  </Text>
                  <Text style={styles.offer}>
                    {"費用の利用目的："}
                    {value?.expensePurpose}
                  </Text>
                  <Text style={[styles.offer, { marginBottom: 0 }]}>
                    {"募集団体数："}
                    {value?.offerCount}
                  </Text>
                </View>
                <View style={styles.description}>
                  <Text>{value?.body}</Text>
                </View>
                {localAttachments?.length !== 0 && (
                  <View style={styles.attachmentItems}>
                    {localAttachments?.map((props, index) => (
                      <AttachmentItem {...props} key={index} />
                    ))}
                  </View>
                )}
              </View>
            </View>
            {isLargeScreen ? (
              <View style={[{ paddingTop: 16, borderColor: Colors.white2, borderTopWidth: 1, marginTop: 16 }]}>
                <View style={[styles.actions, { marginBottom: 24 }]}>
                  <TextButton
                    buttonType={ButtonType.Secondary}
                    title={"キャンセル"}
                    onPress={backToCreate}
                    style={{ width: 300, marginRight: 15 }}
                  />

                  <TextButton
                    buttonType={ButtonType.Primary}
                    isLoading={isLoading}
                    title={offerId ? "保存" : "募集を作成"}
                    onPress={submit}
                    style={{ width: 300 }}
                  />
                </View>
              </View>
            ) : (
              <View style={[{ paddingTop: 16, borderColor: Colors.white2, borderTopWidth: 1, marginTop: 16 }]}>
                <View style={[styles.actions]}>
                  <TextButton
                    buttonType={ButtonType.Primary}
                    title={offerId ? "保存" : "募集を作成"}
                    onPress={submit}
                    style={{ width: "100%" }}
                  />
                </View>
                <View style={[styles.actions, { paddingBottom: bottom + 16 }]}>
                  <TextButton
                    buttonType={ButtonType.Secondary}
                    title={"キャンセル"}
                    onPress={backToCreate}
                    style={{ width: "100%" }}
                  />
                </View>
              </View>
            )}
          </ScrollView>
        )}
      </CustomKeyboardAvoidingView>
    )
  }
)

const styles = StyleSheet.create({
  attachmentItems: {
    borderTopWidth: 1,
    paddingBottom: 4,
    paddingLeft: 16,
    borderTopColor: Colors.white2,
    paddingTop: 24,
  },
  container: {
    paddingTop: 24,
    paddingRight: 16,
    paddingLeft: 16,
  },
  imageTeam: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },

  contactTeam: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  litmitTime: {
    fontSize: 13,
    font: "Noto Sans",
    color: Colors.leaf,
    marginBottom: 12,
  },
  titleMatching: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: "600",
  },
  bgLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 13,
    minWidth: 42,
    marginRight: 10,
    height: 26,
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: Colors.coolGrey,
    color: Colors.coolGrey,
    backgroundColor: Colors.white3,
    outline: "none",
    borderRadius: 6,
    flexWrap: "wrap",
    paddingLeft: 10,
    paddingRight: 10,
  },
  offer: {
    fontSize: 14,
    fontStyle: "normal",
    lineHeight: 20,
    marginBottom: 12,
  },
  url: {
    display: "flex",
    flexDirection: "row",
  },
  offerUrl: {
    fontSize: 14,
    fontStyle: "normal",
    lineHeight: 20,
    marginBottom: 12,
    color: Colors.orange,
  },
  description: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
    borderBottomColor: Colors.white2,
    paddingBottom: 16,
    paddingTop: 16,
  },

  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: 64,
    width: "100%",
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderColor: Colors.white2,
  },
  organizationName: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "600",
    paddingLeft: 16,
  },
  textHeader: {
    fontSize: 18,
    fontWeight: "300",
    lineHeight: 25,
    textAlign: "center",
    width: "87%",
  },
  textHeaderPC: {
    fontSize: 18,
    fontWeight: "500",
    lineHeight: 25,
    textAlign: "center",
    width: "100%",
  },
  actions: {
    paddingHorizontal: 16,
    paddingTop: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
})
