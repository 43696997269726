import { Fragment, memo } from "react"
import { StyleSheet, Text, View, Linking } from "react-native"
import Hyperlink from "react-native-hyperlink"
import { ContactListCommentStackScreenProps } from "src/types.d"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { Colors } from "src/constants/Colors"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { useContactSurveyAnswerOverviewData } from "src/recoil/hooks/screens/useContactSurveyAnswerOverviewData"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { MemberListItem } from "src/components/parts/organizationTab/MemberListItem"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { ContactListCommentScreens } from "src/constants/ContactListCommentScreens"
import { ListCommentTeamMemberMailItem } from "src/aws/customAPI"
import { convertDate } from "src/utils/useDateTime"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import { Screens } from "src/constants/Screens"
import { convertToMemberType } from "src/apis/organization/getListNotJoinMembers"
import { defaultUser } from "src/constants/defaultUser"
import { MemberType } from "src/types/organization/OrganizationMember"

export type ContactSurveyCommentDetailParams = {
  contactId: string
  type: "Inbox" | "Outbox"
  item: ListCommentTeamMemberMailItem
}

export const ContactSurveyCommentDetail = memo<
  ContactListCommentStackScreenProps<typeof ContactListCommentScreens.ContactSurveyCommentDetail>
>(
  ({
    route: {
      params: { contactId, item },
    },
    navigation,
  }) => {
    const { surveyAnswerOverviews, isLoading } = useContactSurveyAnswerOverviewData(contactId)
    const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
    const { allowEditMember } = useCheckAllowAccountPermission({ organizationId: selectedOrganization?.id })
    const isLargeScreen = useCheckPCScreen()

    if (isLoading) {
      return (
        <View style={styles.loadingIndicator}>
          <LoadingIndicator />
        </View>
      )
    }

    if (surveyAnswerOverviews == null) {
      return null
    }
    const questionnaireAnswers = item?.questionnaireAnswers ?? []
    return (
      <CustomKeyboardAvoidingView style={isLargeScreen ? styles.containerLarge : styles.container}>
        <CustomScrollView>
          <View
            style={[
              isLargeScreen ? styles.listContainerLarge : styles.profileContainer,
              styles.separatorContainer,
              { borderRadius: 0 },
              isLargeScreen && { paddingHorizontal: 15 },
            ]}
          >
            <MemberListItem
              name={item.teamMember.deleteFlg ? `${item?.teamMember?.nickname}（脱退）` : item?.teamMember?.nickname}
              imageUrl={item?.imageUrl ?? undefined}
              deleteImage={item.teamMember.deleteFlg && defaultUser}
              tapToName={() => {
                if (item.teamMember.deleteFlg) return
                navigation.navigate(Screens.OrganizationMemberDetail, {
                  organizationId: selectedOrganization?.id ?? "",
                  memberId: item?.teamMember?.id,
                  imageUrl: item?.imageUrl ?? undefined,
                  allowEditMember,
                })
              }}
              isCustomImg
              id={item?.teamMember?.id}
              memberType={item.teamMember.deleteFlg ? MemberType.General : convertToMemberType(item?.teamMember?.role)}
              isLeader={!item.teamMember.deleteFlg && item?.teamMember?.groups.items.find((el) => el.leaderFlg) != null}
              isClearBackground={true}
            />
          </View>
          {item?.comment && (
            <View
              style={[
                { paddingHorizontal: isLargeScreen ? 30 : 15, paddingTop: 16 },
                styles.separatorContainer,
                isLargeScreen && styles.listContainerLarge,
                isLargeScreen && { borderRadius: 0 },
              ]}
            >
              <View style={[{ borderRadius: 0 }]}>
                <ItemLabel
                  label={"回答コメント"}
                  RightComponent={
                    <Text style={styles.timeTitle}>{"送信日時：" + convertDate(item?.commentUpdatedAt ?? "")}</Text>
                  }
                />
                <View style={{ paddingTop: 10 }}>
                  <View>
                    <Hyperlink
                      linkStyle={{ color: "#2980b9", fontSize: 16, width: "100%" }}
                      onPress={async (url) => await Linking.openURL(url)}
                    >
                      <Text style={styles.textComment}>{item?.comment}</Text>
                    </Hyperlink>
                  </View>
                </View>
              </View>
            </View>
          )}
          <View style={{ paddingHorizontal: isLargeScreen ? 0 : 15, paddingTop: 16 }}>
            <View style={isLargeScreen && styles.listContainerLarge}>
              <ItemLabel
                label={"回答"}
                RightComponent={<Text style={styles.timeTitle}>{"回答日時：" + convertDate(item?.answeredAt ?? "")}</Text>}
              />
            </View>
            {surveyAnswerOverviews.map((v, i) => (
              <View style={isLargeScreen ? styles.listContainerLarge : styles.listContainer} key={v.survey.id}>
                <View style={styles.answerLarge}>
                  <View style={styles.questionContainer}>
                    <Text style={styles.questionSubTitle}>質問{i + 1}</Text>
                  </View>
                  <View style={styles.questionContainer}>
                    <Text style={{ paddingBottom: 16 }}>{v.survey.question}</Text>
                  </View>
                  {v.answers.map(
                    (ans, index) =>
                      questionnaireAnswers.length > i &&
                      questionnaireAnswers[i]?.answer.includes(index) && (
                        <Fragment key={index}>
                          <View style={styles.separator} />
                          <View style={styles.rowContainer}>
                            <Text style={styles.rowText}>{v.survey.choices[index].value}</Text>
                          </View>
                        </Fragment>
                      )
                  )}
                </View>
              </View>
            ))}
          </View>
        </CustomScrollView>
      </CustomKeyboardAvoidingView>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    paddingBottom: 20,
  },
  containerLarge: {
    backgroundColor: Colors.white,
    paddingBottom: 20,
  },
  profileContainer: {
    marginTop: 0,
  },
  separatorContainer: {
    marginTop: 0,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  listContainer: {
    borderRadius: 10,
    backgroundColor: Colors.white,
    marginTop: 20,
  },
  listContainerLarge: {
    borderRadius: 10,
    backgroundColor: Colors.white,
    marginTop: 20,
    width: "60%",
    maxWidth: 1020,
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  questionContainer: {
    // paddingVertical: 20,
    paddingHorizontal: 23,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  questionSubTitle: {
    paddingTop: 16,
    paddingBottom: 10,
    fontSize: 13,
    lineHeight: 20,
    color: Colors.cement,
  },
  timeTitle: {
    fontSize: 13,
    lineHeight: 20,
    color: Colors.cement,
  },
  questionTitle: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 20,
    color: Colors.orange,
    maxWidth: "85%",
  },
  rowContainer: {
    paddingVertical: 13,
    paddingHorizontal: 23,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowText: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 35,
    color: Colors.greyshBrown,
    width: "100%",
  },
  resultContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  resultText: {
    fontSize: 27,
    fontWeight: "bold",
    lineHeight: 35,
    color: Colors.leaf,
  },
  resultPercentText: {
    fontSize: 13,
    lineHeight: 20,
    color: Colors.cement,
    marginLeft: 15,
  },
  separator: {
    marginLeft: 23,
    height: 1,
    backgroundColor: Colors.white2,
  },
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  textComment: {
    // paddingLeft: 16,
    paddingBottom: 16,
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "400",
  },
  answerLarge: {
    borderRadius: 10,
    borderWidth: 1,
    borderColor: Colors.white2,
    shadowOpacity: 0.1,
    shadowRadius: 16,
    backgroundColor: Colors.white3,
  },
})
