export type GetOldRa9Ra9AuthStateFromResultParams = {
  access_token?: string | null
  user_id?: string | null
}

export const getOldRa9Ra9AuthStateFromResult = (params?: GetOldRa9Ra9AuthStateFromResultParams | null) => {
  if (params == null) {
    return
  }
  const { access_token, user_id } = params
  if (access_token == null || user_id == null) {
    return
  }
  return {
    oldRa9Ra9AccessToken: access_token,
    oldRa9Ra9UserId: user_id,
  }
}
