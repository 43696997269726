import { atom } from "recoil"

type Props = {
  planId: string
  orderId: string
  invoiceAddressee: string
  invoiceAddresseeKana: string
  applicantRemark: string
  isSubmit: boolean
}

export const extendDataRequestState = atom<Props>({
  key: "atoms/organization/extendDataRequestState",
  default: {
    planId: "",
    orderId: "",
    invoiceAddressee: "",
    invoiceAddresseeKana: "",
    applicantRemark: "",
    isSubmit: false,
  },
})
