import { useCallback, useMemo, useState } from "react"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { organizationDetailRequestIdState } from "src/recoil/atoms/organization/organizationDetailRequestIdState"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { useIncrementRecoilState } from "src/recoil/hooks/useIncrementRecoilState"
import { useResetMyProfileFormData } from "src/recoil/hooks/organization/myProfile/useResetMyProfileFormData"
import { organizationMembersRequestIdState } from "src/recoil/atoms/organization/organizationMembersRequestIdState"
import { pendingInvitationsRequestIdState } from "src/recoil/atoms/organization/pendingInvitationsRequestIdState"
import { groupsRequestIdState } from "src/recoil/atoms/organization/group/groupsRequestIdState"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { useRecoilState, useSetRecoilState } from "recoil"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { useFetcher } from "src/hooks/useFetcher"
import { CustomAlert } from "src/utils/CustomAlert"
import { quitOrganization } from "src/apis/organization/quitOrganization"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { useResetter } from "src/recoil/hooks/screens/contact/create/useResetter"
import { myFullOrganizationsRequestIdState } from "src/recoil/atoms/organization/myOrganizationsRequestIdState"
import { groupsSelectorFamily } from "src/recoil/selectors/organization/group/groupsSelectorFamily"
import { Analytics } from "src/tags/analytics/Analytics"
import { groupMemberListState } from "src/recoil/atoms/organization/group/groupMemberListState"
import { getOrderDetailSelectorFamily } from "src/recoil/selectors/paidTeam/getOrderDetailSelectorFamily"
import { getOrderDetailIdRequestState } from "src/recoil/selectors/paidTeam/getOrderDetailIdRequestState"
import { applicantIdState } from "src/recoil/selectors/paidTeam/applicantIdState"
import { useCheckAllowAccountPermission } from "./useCheckAllowAccountPermission"
import { teamPermissionRequestIdState } from "src/recoil/atoms/organization/teamPermissionRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"

type Params = {
  organizationId: string
  gotoOrganizationTab: () => void
}

export const useOrganizationDetailData = ({ organizationId, gotoOrganizationTab }: Params) => {
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const [listMembers, setListMembers] = useRecoilState(groupMemberListState)
  const setApplicantId = useSetRecoilState(applicantIdState)
  const { allowShowOrder, allowTransferApplicant } = useCheckAllowAccountPermission({ organizationId })

  const { value: organizationDetail, isLoading: isLoadingOrganizationDetail } = useAsyncSelector(
    organizationDetailSelectorFamily(organizationId)
  )
  const { value: groups } = useAsyncSelector(groupsSelectorFamily(organizationId))
  const { value: orderDetailValues } = useAsyncSelector(getOrderDetailSelectorFamily({ teamId: organizationId }))
  const refreshOrder = useIncrementRecoilState(getOrderDetailIdRequestState)
  const groupCount = useMemo(() => groups?.length || 0, [groups])
  const refreshOrganizationDetail = useIncrementRecoilState(organizationDetailRequestIdState)
  const refreshOrganizationMembers = useIncrementRecoilState(organizationMembersRequestIdState)
  const refreshPendingInvitations = useIncrementRecoilState(pendingInvitationsRequestIdState)
  const refreshGroups = useIncrementRecoilState(groupsRequestIdState)
  const setOrganizationId = useSetRecoilState(selectedMyOrganizationState)
  const refreshMyOrganizations = useIncrementRecoilState(myFullOrganizationsRequestIdState)
  const { resourceUrl: organizationImageUrl, refreshResourceUrl: refreshOrganizationImageUrl } = useResource({
    type: "teamImage",
    id: organizationId,
    size: "normal",
  })

  const myProfile = useMemo(() => organizationDetail?.profileInOrganization, [organizationDetail?.profileInOrganization])

  const { resourceUrl: myProfileImageUrl, refreshResourceUrl: refreshMyProfileImageUrl } = useResource(
    myProfile
      ? {
          type: "teamMemberImage",
          id: myProfile.id,
          size: "normal",
        }
      : {
          type: "none",
        }
  )

  const refreshMyProfileFormData = useResetMyProfileFormData(organizationId)
  const refreshPermission = useRefresher(teamPermissionRequestIdState)
  const refresh = useCallback(async () => {
    refreshOrganizationDetail()
    refreshOrganizationMembers()
    refreshPendingInvitations()
    refreshGroups()
    refreshOrder()
    await refreshOrganizationImageUrl()
    refreshMyProfileFormData()
    await refreshMyProfileImageUrl()
    refreshPermission()
  }, [
    refreshOrganizationDetail,
    refreshOrganizationMembers,
    refreshPendingInvitations,
    refreshGroups,
    refreshOrder,
    refreshOrganizationImageUrl,
    refreshMyProfileFormData,
    refreshMyProfileImageUrl,
    refreshPermission,
  ])
  const [showAllGroups, setShowAllGroups] = useState(false)
  const defaultShowGroupsCount = 10
  const insets = useSafeAreaInsets()

  const { fetch: quit, isFetching: isQuitting } = useFetcher(
    useCallback(async () => {
      if (accessToken == null || myProfile == null) {
        return
      }

      if (allowTransferApplicant) {
        await CustomAlert.alert(
          "警告",
          "団体有料版の申込者に設定されています。団体を脱退する場合は「申込者権限を譲渡する」ボタンより、別の代表者に権限を譲渡後、再度脱退手続きを行ってください。"
        )
        return
      }

      const isConfirmed = await CustomAlert.confirm(
        "警告",
        allowTransferApplicant
          ? "団体有料版の申込者に設定されています。団体を脱退する場合は「申込者権限を譲渡する」ボタンより、別の代表者に権限を譲渡後、再度脱退手続きを行ってください。"
          : "脱退すると、団体内の情報や履歴は完全に削除されます。\n本当に脱退しますか？"
      )
      if (!isConfirmed) {
        return
      }
      const result = await quitOrganization({
        accessToken,
        organizationMemberId: myProfile.id,
      })
      if (!result.isOk) {
        await CustomAlert.alert("完了", result.error.message)
        return
      }
      await Analytics.quitTeam()
      await CustomAlert.alert("完了", "脱退しました。")
      refreshMyOrganizations()
      gotoOrganizationTab()
    }, [accessToken, myProfile, allowTransferApplicant, refreshMyOrganizations, gotoOrganizationTab])
  )

  const resetCreateNewContactScreenData = useResetter()
  const refreshMembers = useIncrementRecoilState(organizationMembersRequestIdState)

  return {
    organizationDetail,
    groupCount,
    organizationImageUrl,
    isLoadingOrganizationDetail,
    refresh,
    refreshOrganizationImageUrl,
    refreshMyProfileFormData,
    refreshPendingInvitations,
    defaultShowGroupsCount,
    showAllGroups,
    setShowAllGroups,
    insets,
    myProfileImageUrl,
    refreshMyProfileImageUrl,
    setOrganizationId,
    quit,
    isQuitting,
    resetCreateNewContactScreenData,
    refreshOrganizationDetail,
    refreshMembers,
    setListMembers,
    orderDetailValues,
    setApplicantId,
    refreshOrder,
  }
}
