import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { informationSelectorFamily } from "src/recoil/selectors/settings/informationSelectorFamily"
import { useEffect, useMemo } from "react"
import { readSystemInformationsState } from "src/recoil/atoms/bottomTab/readSystemInformationsState"
import { useAsyncState } from "src/hooks/useAsyncState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { markAsReadAdminNotice } from "src/queries/settings/markAsReadAdminNotice"
type Params = {
  infoId: string
  readFlg: boolean
}

export const useInformationDetailData = ({ infoId, readFlg }: Params) => {
  const { value: information } = useAsyncSelector(informationSelectorFamily(infoId))
  const [readSystemInformations, setReadSystemInformations] = useAsyncState(readSystemInformationsState)
  const insets = useSafeAreaInsets()
  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  useEffect(() => {
    if (accessToken && infoId && !readFlg) {
      markAsReadAdminNotice({ accessToken, adminNoticeId: infoId })
    }
  }, [infoId, accessToken, readFlg])
  useEffect(() => {
    if (infoId) {
      const isInfoIdAlreadyRead = readSystemInformations?.has(infoId)
      if (!isInfoIdAlreadyRead) {
        setReadSystemInformations((prevReadSystemInformations) => {
          const newReadSystemInformations = new Set(prevReadSystemInformations)
          newReadSystemInformations.add(infoId)
          return newReadSystemInformations
        })
      }
    }
  }, [infoId, readSystemInformations, setReadSystemInformations])

  return {
    information,
    insets,
  }
}
