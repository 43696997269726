import { memo, useEffect } from "react"
import { Platform, StyleSheet, Text, useWindowDimensions, View } from "react-native"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { Colors } from "src/constants/Colors"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { RootStackScreenProps } from "src/types.d"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { Screens } from "src/constants/Screens"
import { Button } from "src/components/parts/buttons/Button"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { PC_BREAKPOINT } from "src/constants/Screen"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { ApplicantInformationEditor } from "src/components/parts/ApplicantInformationEditor"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { ApplicantInfoUpdate } from "src/components/parts/ApplicantInformationEditor/ApplicantInfoUpdate"
import { useApplicantInformationData } from "src/recoil/hooks/screens/useApplicantInformationData"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { ScrollView } from "react-native-gesture-handler"
import { getOrderDetailIdRequestState } from "src/recoil/selectors/paidTeam/getOrderDetailIdRequestState"
import { useRefresher } from "src/hooks/useRefresher"
import { MIGRATE_PLAN_ID } from "src/utils/const"
import { setAsync } from "src/storage/secureStorage/setAsync"

const registerApplicantInformation = memo<RootStackScreenProps<"registerApplicantInformation">>(({ route, navigation }) => {
  const isLargeScreen = useCheckPCScreen()
  const heightScreen = useWindowDimensions().height
  const refreshOrder = useRefresher(getOrderDetailIdRequestState)

  const { organizationId, mode, orderId, transferStatusId, isMigrate } = route.params

  const {
    organizationDetail,
    email,
    errorMessage,
    companyNameError,
    companyNameKanaError,
    representativeNameError,
    companyRepresentativeNameKanaError,
    phoneNumberError,
    zipError,
    prefectureId,
    setPrefectureId,
    prefectureOptions,
    addressError,
    isDisabled,
    prefectureError,
    setLocalStorageData,
    isDisabledPrivate,
    getLocationByZipCode,
    setApplicantInformationData,
    applicantInformationData,
    buildingNameError,
    setLocalStorageDataRealTime,
    isLoadingOrganizationDetail,
    planData,
    setPlanData,
  } = useApplicantInformationData({
    organizationId,
    mode: mode,
    orderId,
    transferStatusId,
  })
  const insets = useSafeAreaInsets()
  const goBack = () => {
    if (mode === "update" && !orderId && !transferStatusId) {
      return navigation.navigate(Screens.confirmPlan, {})
    } else if (mode === "update" && orderId && !transferStatusId) {
      setApplicantInformationData({ ...applicantInformationData, buildingName: "" })
      return navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.OrderDetail,
          params: {
            teamId: organizationId,
            teamMembers: organizationDetail?.memberNumber ?? 0,
            accountId: organizationDetail?.profileInOrganization.id ?? "",
          },
        },
      })
    } else if (transferStatusId) {
      return navigation.reset({ index: 0, routes: [{ name: Screens.Root }] })
    } else {
      return navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.OrganizationDetail,
          params: { organizationId: organizationId },
        },
      })
    }
  }

  const showUpdate = () => {
    if (orderId || transferStatusId) return true
  }

  useEffect(() => {
    navigation.setOptions({
      headerLeft: (props) => (
        <Button
          onPress={() => {
            goBack()
          }}
        >
          <BackIcon
            fill={Number(props.screenLayout?.width) >= PC_BREAKPOINT && Platform.OS === "web" ? Colors.orange : Colors.white3}
            {...props}
          ></BackIcon>
        </Button>
      ),
      title: orderId ? "申込者情報編集" : "申込者情報入力",
    })
  })
  return (
    <CustomKeyboardAvoidingView
      style={[{ height: heightScreen - 64 }, Platform.OS === "web" ? {} : { paddingBottom: insets.bottom }]}
      noContainer={true}
      useAware={true}
    >
      <ScrollView style={{ height: 500 }}>
        {mode !== "update" && (
          <View style={[styles.header]}>
            <View style={[isLargeScreen ? { width: "100%", maxWidth: 600, paddingHorizontal: 16 } : { width: "100%" }]}>
              <Text style={[styles.headerTitle]}>最初に、申込者情報の入力を行ってください。</Text>
            </View>
          </View>
        )}

        <View style={[styles.container]}>
          <View style={[styles.row]}>
            <Text style={styles.requiredItemNoteText}>
              <Text style={styles.requiredItemNoteAsterisk}>※</Text>は必須項目
            </Text>
          </View>
          <View style={[styles.row]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="団体名" />
            </View>
            <Text style={[styles.textField]}>{organizationDetail?.organizationName}</Text>
          </View>
          <View style={[styles.row]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="団体代表者氏名（会員名）" />
            </View>
            <Text style={[styles.textField]}>{organizationDetail?.profileInOrganization.name}</Text>
          </View>
          <View style={[styles.row]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="メールアドレス" />
            </View>
            <Text style={[styles.textField]}>{email}</Text>
          </View>
          {!orderId && !transferStatusId && (
            <ApplicantInformationEditor
              errorMessage={errorMessage}
              companyNameError={companyNameError}
              companyNameKanaError={companyNameKanaError}
              representativeNameError={representativeNameError}
              companyRepresentativeNameKanaError={companyRepresentativeNameKanaError}
              phoneNumberError={phoneNumberError}
              zipError={zipError}
              prefectureOptions={prefectureOptions}
              prefectureId={prefectureId}
              setPrefecture={setPrefectureId}
              addressError={addressError}
              prefectureError={prefectureError}
              getLocationByZipCode={getLocationByZipCode}
              setApplicantInformationData={setApplicantInformationData}
              applicantInformationData={applicantInformationData}
              buildingNameError={buildingNameError}
              setLocalStorageDataRealTime={setLocalStorageDataRealTime}
            />
          )}
          {showUpdate() && (
            <ApplicantInfoUpdate
              errorMessage={errorMessage}
              representativeNameError={representativeNameError}
              companyRepresentativeNameKanaError={companyRepresentativeNameKanaError}
              phoneNumberError={phoneNumberError}
              zipError={zipError}
              prefectureOptions={prefectureOptions}
              prefectureId={prefectureId}
              setPrefecture={setPrefectureId}
              addressError={addressError}
              prefectureError={prefectureError}
              getLocationByZipCode={getLocationByZipCode}
              setApplicantInformationData={setApplicantInformationData}
              applicantInformationData={applicantInformationData}
              buildingNameError={buildingNameError}
            />
          )}
        </View>
      </ScrollView>
      <View
        style={[
          styles.buttonContainer,
          isLargeScreen ? {} : { paddingVertical: 24 },
          Platform.OS === "ios" ? { paddingBottom: insets.top } : null,
        ]}
      >
        <View
          style={[
            isLargeScreen
              ? { maxWidth: 600, flexDirection: "row", justifyContent: "space-between", width: "100%", paddingHorizontal: 24 }
              : { width: "100%" },
          ]}
        >
          <TextButton
            buttonType={ButtonType.Secondary}
            title="戻る"
            onPress={() => {
              goBack()
            }}
            style={[isLargeScreen ? { width: "49%" } : null]}
          />
          <TextButton
            buttonType={ButtonType.Primary}
            title={mode === "update" ? "確認" : "次へ"}
            onPress={() => {
              setLocalStorageData()
              if (mode === "init" || mode === "back") {
                if (isMigrate) {
                  setPlanData({
                    ...planData,
                    maxMembers: ``,
                    limitTime: ``,
                    price: ``,
                    planId: MIGRATE_PLAN_ID,
                  })
                  setAsync(
                    "planData",
                    JSON.stringify({
                      ...planData,
                      maxMembers: ``,
                      limitTime: ``,
                      price: ``,
                      planId: MIGRATE_PLAN_ID,
                    })
                  )
                }
                navigation.navigate(Screens.registerPlan, {
                  organizationId: organizationId,
                  mode: "init",
                })
              } else {
                if (!orderId) {
                  navigation.navigate(Screens.confirmPlan, {})
                } else {
                  navigation.navigate(Screens.confirmApplicantInfo, {
                    transferStatusId,
                    orderId,
                    organizationId: organizationId,
                  })
                }
              }
            }}
            disabled={applicantInformationData.appCategory === "法人" ? isDisabled : isDisabledPrivate}
            style={[isLargeScreen ? { width: "49%" } : { marginTop: 16 }]}
            isLoading={isLoadingOrganizationDetail}
          />
        </View>
      </View>
    </CustomKeyboardAvoidingView>
  )
})

export const registerApplicantInformationOptions: ScreenOptions = () => ({
  ...commonHeaderOptions,
  headerShown: true,
})

const styles = StyleSheet.create({
  header: {
    paddingHorizontal: 16,
    paddingVertical: 14,
    width: "100%",
    borderBottomColor: Colors.borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
  },
  container: {
    alignItems: "center",
    width: "100%",
    paddingVertical: 24,
    paddingHorizontal: 16,
    maxWidth: 600,
    marginHorizontal: "auto",
  },
  row: {
    marginBottom: 16,
    width: "100%",
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 20,
    color: Colors.black2,
  },
  requiredItemNoteText: {
    fontWeight: "400",
    fontSize: 14,
  },
  requiredItemNoteAsterisk: {
    color: Colors.red,
  },
  itemLabelWrapper: {
    marginBottom: 4,
  },
  textField: {
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 22.4,
    marginVertical: 14,
    textAlign: "left",
    width: "100%",
  },
  text: {
    width: "100%",
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "300",
  },
  buttonContainer: {
    paddingHorizontal: 24,
    width: "100%",
    borderTopColor: Colors.borderColor,
    borderTopWidth: 1,
    alignItems: "center",
    paddingVertical: 12,
  },
})

export default registerApplicantInformation
