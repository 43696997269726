import { FC, useMemo } from "react"
import Svg, { G, Path } from "react-native-svg"

type Props = {
  size?: number
}

export const CreateContactIcon: FC<Props> = ({ size = 23.897 }) => {
  const height = useMemo(() => size * 0.6854, [size])
  return (
    <Svg width={size} height={height} viewBox="0 0 23.897 16.38">
      <G data-name="グループ 2501">
        <Path
          d="M26.674 23a.544.544 0 0 0-.544.544v2.586h-2.586a.544.544 0 1 0 0 1.089h2.586V29.8a.544.544 0 1 0 1.089 0v-2.581H29.8a.544.544 0 1 0 0-1.089h-2.581v-2.586a.544.544 0 0 0-.545-.544z"
          transform="translate(-6.951 -14.469)"
          stroke="#fff"
          fill="#fff"
        />
        <Path
          data-name="前面オブジェクトで型抜き 5"
          d="M13.615 13.206H2.473A2.475 2.475 0 0 1 0 10.734V2.473a2.477 2.477 0 0 1 .03-.387.825.825 0 0 1 .337-.908A2.459 2.459 0 0 1 2.473 0h15.691a2.475 2.475 0 0 1 2.472 2.473V6.6h-1.648V3.1l-8.411 4.68a.824.824 0 0 1-.8 0L1.648 3.264v7.469a.825.825 0 0 0 .824.824h11.143v1.647zM2.473 1.648a.807.807 0 0 0-.262.043l7.966 4.425 8.039-4.466h-.052z"
          fill="#fff"
        />
      </G>
    </Svg>
  )
}

CreateContactIcon.displayName = "CreateContactIcon"
