import { memo, useCallback } from "react"
import * as WebBrowser from "expo-web-browser"
import { deliveryMailReceiveSetting } from "src/constants/links/deliveryMailReceiveSetting"
import { StyleSheet, Text, View } from "react-native"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Button } from "src/components/parts/buttons/Button"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { Colors } from "src/constants/Colors"
import { RootStackScreenProps } from "src/types.d"
import { Screens } from "src/constants/Screens"
import { useInquiryConfirmData } from "src/recoil/hooks/screens/useInquiryConfirmData"
import { RootTabs } from "src/constants/RootTabs"
import { CustomAlert } from "src/utils/CustomAlert"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type InquiryConfirmParams = undefined

type Props = RootStackScreenProps<typeof Screens.InquiryConfirm>

const completeMessage = `
お問い合わせ内容が送信されました。

・お問い合わせ状況によっては、回答に数日かかる場合がございます。尚、調査が必要となるお問い合わせにつきましては、更にお日にちを頂戴する場合もございますので、予めご了承をお願いいたします。

・自動受付メールが送信されます。届かない場合はカスタマーサポートからの返信が届かない可能性があるのでドメイン「@ra9plus.jp」からのメールを受信許可する設定をお願いいたします。`

export const InquiryConfirm = memo<Props>(({ navigation }) => {
  const { email, isEmailLoading, selectedOrganization, content, userDevice, isMyOrganizationsLoading, sendInquiry, isSending } =
    useInquiryConfirmData()
  const isPCScreen = useCheckPCScreen()

  const openMailSetting = useCallback(async () => {
    await WebBrowser.openBrowserAsync(deliveryMailReceiveSetting)
  }, [])

  const onSubmit = useCallback(async () => {
    await sendInquiry(async () => {
      await CustomAlert.alert("送信完了", completeMessage)
      // navigation.navigate(Screens.Root, { screen: RootTabs.SettingsTab })
      navigation.navigate(Screens.Root, { screen: RootTabs.HomeTab })
    })
  }, [sendInquiry, navigation])

  if ((email != null && isEmailLoading) || isMyOrganizationsLoading) {
    return (
      <View style={styles.loadingIndicator}>
        <LoadingIndicator />
      </View>
    )
  }

  return (
    <CustomKeyboardAvoidingView style={isPCScreen && styles.centerLayout}>
      <CustomScrollView style={[styles.container, isPCScreen && styles.oneColumn]}>
        <View>
          <View style={styles.headDescriptionTextWrapper}>
            <Text style={styles.descriptionText}>以下の内容で送信いたします。よろしければ「送信」を押してください。</Text>
          </View>
          <View style={styles.itemWrapper}>
            <ItemLabel label="お問い合わせ内容" />
            <Text style={styles.descriptionText}>{content}</Text>
          </View>
          <View style={styles.itemWrapper}>
            <ItemLabel label="団体名" />
            <Text style={styles.descriptionText}>{selectedOrganization}</Text>
          </View>
          <View style={styles.itemWrapper}>
            <ItemLabel label="ご利用環境" />
            <Text style={styles.descriptionText}>{userDevice}</Text>
          </View>
        </View>
        <View style={styles.halfBottomContainer}>
          <View style={[styles.textButtonContainer, isPCScreen && styles.centerLayout]}>
            <TextButton
              title="キャンセル"
              buttonType={ButtonType.Secondary}
              onPress={navigation.goBack}
              style={isPCScreen && styles.buttonsPC}
            />
          </View>
          <View style={[styles.textButtonContainer, isPCScreen && styles.centerLayout]}>
            <TextButton
              title="送信"
              buttonType={ButtonType.Primary}
              onPress={onSubmit}
              isLoading={isSending}
              style={isPCScreen && styles.buttonsPC}
            />
          </View>
          <View style={styles.bottomDescriptionContainer}>
            <Text style={styles.descriptionText}>
              ・お問い合わせ状況によっては、回答に数日かかる場合がございます。尚、調査が必要となるお問い合わせにつきましては、更にお日にちを頂戴する場合もございますので、予めご了承をお願いいたします。
              {`\n`}
              ・迷惑メールの設定をされている方へ
              {`\n`}
              らくらく連絡網＋からの返信メールが届くように必ず「@ra9plus.jp」からのメールを受信許可する設定をお願いいたします。
              {`\n`}
              設定方法は
              <Button onPress={openMailSetting}>
                <Text style={styles.textColorOrange}>こちら</Text>
              </Button>
              をご確認ください。
            </Text>
          </View>
        </View>
      </CustomScrollView>
    </CustomKeyboardAvoidingView>
  )
})

export const inquiryConfirmOptions: ScreenOptions = {
  ...commonHeaderOptions,
  title: "お問い合わせ",
}

const styles = StyleSheet.create({
  centerLayout: {
    display: "flex",
    alignItems: "center",
  },
  oneColumn: {
    width: 600,
    maxWidth: 1020,
    paddingLeft: 30,
    paddingRight: 30,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  container: {
    flex: 1,
  },
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  headDescriptionTextWrapper: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  descriptionText: {
    fontSize: 14,
    lineHeight: 24,
    color: Colors.black2,
  },
  itemWrapper: {
    paddingHorizontal: 16,
    paddingVertical: 24,
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  halfBottomContainer: {
    marginTop: 32,
    paddingVertical: 24,
    paddingHorizontal: 16,
    flex: 1,
  },
  textButtonContainer: {
    marginTop: 24,
  },
  bottomDescriptionContainer: {
    marginTop: 32,
  },
  textColorOrange: {
    color: Colors.orange,
  },
})
