import { useTeamMemberContacts } from "src/recoil/hooks/screens/contact/useTeamMemberContacts"
import { useMemo } from "react"
import { notNull } from "src/utils/typeguards"

export const useContactSurveyAnswerDetailData = (contactId: string, surveyId: string, choiceId: string) => {
  const { surveyAnswers, isLoading, refreshTeamMemberContacts: refresher } = useTeamMemberContacts(contactId)
  const surveyAnswer = useMemo(() => surveyAnswers[Number(surveyId)], [surveyAnswers, surveyId])
  const choice = useMemo(() => surveyAnswer.survey.choices[Number(choiceId)].value, [surveyAnswer, choiceId])
  const members = useMemo(() => surveyAnswer.answers[Number(choiceId)].members.filter(notNull), [surveyAnswer, choiceId])

  return {
    choice,
    members,
    isLoading,
    refresher,
  }
}
