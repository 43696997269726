import { memo } from "react"
import { Svg, G, Path } from "react-native-svg"

export const NotificationOptionsIcon = memo(() => (
  <Svg width="15.836" height="18.69" viewBox="0 0 15.836 18.69">
    <G>
      <Path
        d="M1.855 1.814A1.84 1.84 0 0 0 3.709 0H0a1.834 1.834 0 0 0 1.855 1.814z"
        transform="translate(6.064 16.375)"
        fill={"#f2caa0"}
        stroke={"transparent"}
        strokeMiterlimit={10}
      />
      <Path
        d="M12.982 12.247V7.711a5.54 5.54 0 0 0-4.173-5.733v-.617a1.391 1.391 0 0 0-2.782 0v.617a5.524 5.524 0 0 0-4.172 5.733v4.536L0 14.061v.907h14.836v-.907z"
        transform="translate(.5 .5)"
        fill={"#f2caa0"}
        stroke={"transparent"}
        strokeMiterlimit={10}
      />
    </G>
  </Svg>
))
