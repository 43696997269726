import { selectorFamily } from "recoil"
import { getTrashboxContacts } from "src/apis/contact/getTrashboxContacts"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { trashboxContactsRequestIdState } from "src/recoil/atoms/contact/trashboxContactsRequestIdState"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"

export const trashboxContactsSelectorFamily = selectorFamily<
  { contacts: ContactOverviewModel[]; nextToken?: string } | undefined,
  { count?: number; nextToken?: string }
>({
  key: "selectors/contact/trashboxContactsSelectorFamily",
  get:
    ({ count, nextToken }) =>
    async ({ get }) => {
      get(trashboxContactsRequestIdState)
      const tokens = get(tokensState)
      if (tokens == null) {
        return
      }
      const { accessToken } = tokens

      const selectedMyOrganization = get(selectedMyOrganizationSelector)
      if (selectedMyOrganization == null) {
        return
      }
      const myProfile = get(myProfileSelectorFamily(selectedMyOrganization.id))
      if (myProfile == null) {
        return
      }

      const result = await getTrashboxContacts({ accessToken, myMemberId: myProfile.memberId, count, nextToken })
      if (!result.isOk) {
        return
      }

      return result.content
    },
})
