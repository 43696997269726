import { Result } from "src/utils/Result"
import { wait } from "src/utils/wait"
import { deleteTeam, GqlError } from "src/aws/customAPI"
import { crashlyticsWrap } from "src/tags/crashlytics"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type DeleteOrganizationParams = {
  accessToken: string
  organizationId: string
  reasonText: string
}

type DeleteOrganizationSuccess = unknown

type DeleteOrganizationFailure = {
  message: string
}

export const deleteOrganization = async (params: DeleteOrganizationParams): Promise<Result<DeleteOrganizationSuccess, DeleteOrganizationFailure>> => {
  const {accessToken, organizationId, reasonText} = params

  return execApi(
    () => deleteTeam(accessToken, {
      input: {
        id: organizationId,
        reason: reasonText
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["deleteOrganization", "deleteTeam"],
      defaultErrorMessage: "団体の解約に失敗しました。"
    }
  )
}
