import { createStackNavigator } from "@react-navigation/stack"
import { commonModalOptions } from "src/constants/options/commonModalOptions"
import type { CreateNewContactStackParamList } from "src/types.d"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"

export const CreateNewContactStack = createStackNavigator<CreateNewContactStackParamList>()

export const createNewContactStackScreenOptions: ScreenOptions = {
  ...commonModalOptions,
}
