import { Result } from "../../utils/Result";
import { getAccessTokenByTempToken as _getAccessTokenByTempToken } from "src/aws/customAPI";
import { Tokens } from "../../recoil/atoms/authorization/tokensState";

type Params = {
  tempToken: string;
}

export type Success = Tokens;

type Failure = {
  message: string;
}

export const getAccessTokenByTempToken = async ({ tempToken }: Params): Promise<Result<Success, Failure>> => {
  return _getAccessTokenByTempToken({
    input: {
      tempToken: tempToken
    }
  }).then(res => {
    if (res.data?.getAccessTokenByTempToken.accessToken) {
      return Result.Ok<Tokens>({
        accessToken: res.data?.getAccessTokenByTempToken.accessToken,
        expiresIn: "",
        idToken: "",
        refreshToken: "",
        tokenType: "",
      })
    } else {
      return Result.Error<Failure>({ message: "認証情報の取得に失敗しました。" });
    }
  }).catch(() => {
    return Result.Error<Failure>({ message: "認証情報の取得に失敗しました。" });
  });
};