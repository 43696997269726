import { getGroup, MemberRole } from "src/aws/customAPI"
import { MemberType } from "src/types/organization/OrganizationMember"
import { GroupDetail } from "src/types/organization/group/GroupDetail"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
  groupId: string
}

export const getGroupDetail = async ({ accessToken, groupId }: Params): Promise<GroupDetail | undefined> => {
  const result = await execApi(
    () =>
      getGroup(accessToken, {
        id: groupId,
        appInfo,
      }),
    (res) => res.getGroup,
    {
      identifiers: ["getGroupDetail", "getGroup"],
    }
  )
  if (!result.isOk) return

  const group = result.content

  if (!group) return undefined

  const members = group.groupMembers.items.map((m) => ({
    id: m.teamMember.id,
    nickname: m.teamMember.nickname,
    memberNumber: m.teamMember.memberSeq,
    memberType: memberTypeMapping[m.teamMember.role],
    isLeader: m.leaderFlg,
  }))

  return {
    id: group.id,
    groupName: group.name,
    members: members,
  }
}

const memberTypeMapping: { [k in MemberRole]: MemberType } = {
  manager: MemberType.Representative,
  general: MemberType.General,
  mailSender: MemberType.GeneralWithContactAuthorization,
}
