import { selector } from "recoil"
import { listTeamCategoryMaster } from "src/aws/customAPI"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"

export const teamCategorySelector = selector({
  key: "selectors/team/teamCategorySelector",
  get: async ({ get }) => {
    const tokens = get(tokensState)
    if (tokens == null) return undefined

    const res = await listTeamCategoryMaster(tokens.accessToken)
    return res.data?.listTeamCategoryMaster
  },
})
