import { ListTeamRawResponse, getHaveOldRa9TeamNotJoin as _getHaveOldRa9TeamNotJoin } from "src/aws/customAPI"

type Params = {
  accessToken: string
  accountId: string
}

export const getHaveOldRa9TeamNotJoin = async ({ accessToken, accountId }: Params): Promise<any> => {
  const res = await _getHaveOldRa9TeamNotJoin(accessToken, {
    accountId: accountId,
  })
  return res.data
}
