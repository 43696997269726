import { ChangeEvent, ComponentProps, KeyboardEvent } from "react"
import TextareaAutosize from "react-textarea-autosize"
import "./CommentInput.css"
import { CustomTextInput } from "../CustomTextInput"

type OriginalProps = ComponentProps<typeof CustomTextInput>
type Props = Omit<OriginalProps, "multiline" | "onContentSizeChange"> & {
  minHeight?: number
  placeholder?: string
}

const CommentInput = ({ placeholder, value, onChangeText, maxLength, editable }: Props) => {
  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    if (!onChangeText) {
      return
    }
    const val = e.target.value

    if (val.length <= (maxLength ?? 250)) {
      onChangeText(val)
    } else {
      const valSlice = val.slice(0, 250)
      onChangeText(valSlice)
    }
  }

  const handleKeydown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
    const allowedKeys = ["ArrowUp", "ArrowDown", "ArrowLeft", "ArrowRight", "Enter", "Backspace", "Delete"]

    const inputLength = value?.length ?? 0

    if (!allowedKeys.includes(event.key) && inputLength >= 250) {
      event.preventDefault()
    }
  }
  return (
    <TextareaAutosize
      className="textarea-autosize"
      style={{
        fontFamily: "inherit",
        fontWeight: 400,
        fontSize: 16,
        resize: "none",
        overflow: "hidden",
        border: 2,
        borderRadius: 10,
        borderColor: "#D3D3D3",
        paddingRight: 8,
        paddingLeft: 8,
        borderStyle: "solid",
      }}
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      minRows={6}
      disabled={editable}
      onKeyDown={handleKeydown}
    />
  )
}

export default CommentInput
