import { memo, useMemo } from "react"
import { Svg, Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  size?: number
  fill?: string
}

export const ListIcon = memo<Props>(({ fill = Colors.white3, size = 13.749 }) => {
  const height = useMemo(() => size * 0.857, [size])

  return (
    <Svg width={size} height={height} viewBox="0 0 13.749 11.785">
      <Path
        data-name="パス 1354"
        d="M38.982 27.964h7.857a.982.982 0 0 0 0-1.964h-7.857a.982.982 0 0 0 0 1.964z"
        transform="translate(-34.072 -26)"
        fill={fill}
      />
      <Path
        data-name="パス 1355"
        d="M38.982 47.964h7.857a.982.982 0 0 0 0-1.964h-7.857a.982.982 0 0 0 0 1.964z"
        transform="translate(-34.072 -41.09)"
        fill={fill}
      />
      <Path
        data-name="パス 1356"
        d="M38.982 67.964h7.857a.982.982 0 0 0 0-1.964h-7.857a.982.982 0 0 0 0 1.964z"
        transform="translate(-34.072 -56.179)"
        fill={fill}
      />
      <Path
        data-name="パス 1357"
        d="M23.964 26.983a.982.982 0 0 1-1.964 0 .982.982 0 0 1 1.964 0"
        transform="translate(-22 -26.001)"
        fill={fill}
      />
      <Path
        data-name="パス 1358"
        d="M23.964 46.983a.982.982 0 0 1-1.964 0 .982.982 0 0 1 1.964 0"
        transform="translate(-22 -41.09)"
        fill={fill}
      />
      <Path
        data-name="パス 1359"
        d="M23.964 66.983a.982.982 0 0 1-1.964 0 .982.982 0 0 1 1.964 0"
        transform="translate(-22 -56.18)"
        fill={fill}
      />
    </Svg>
  )
})
