import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilValue } from "recoil"
import { EnterAuthorizationCode } from "src/components/parts/authorization/EnterAuthorizationCode"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { emailState } from "src/recoil/atoms/authorization/emailState"
import { sessionState } from "src/recoil/atoms/authorization/sessionState"
import { useEmailSignupData } from "src/recoil/hooks/authorization/useAuthEmailData"
import { useAuthorizeCode } from "src/recoil/hooks/authorization/useAuthorizeCode"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { RootStackScreenProps } from "src/types.d"
import { CustomAlert } from "src/utils/CustomAlert"
import { getCodeSentDescription } from "src/utils/getCodeSentDescription"
import { AuthMethod } from "src/constants/AuthMethod"
import { Platform } from "react-native"
import { HeaderLeft } from "src/components/parts/headers/HeaderLeft"

export const SignupCode = memo<RootStackScreenProps<typeof Screens.SignupCode>>(({ route, navigation }) => {
  const email = useMemo(() => route.params.email, [route.params.email])
  const [code, setCode] = useState("")
  const session = useRecoilValue(sessionState)
  const signupParams = useMemo(() => ({ code, session, email }), [code, session, email])
  const { authorizeCode, isSendingCode } = useAuthorizeCode(signupParams)
  const showAlert = useCallback(() => CustomAlert.alert("完了", "認証コードを再送しました。"), [])
  const { sendAuthCode, isSending } = useEmailSignupData(showAlert)
  const target = useRecoilValue(emailState)
  const description = useMemo(() => getCodeSentDescription(target, AuthMethod.Email), [target])
  const isResendDisabled = useMemo(() => isSending || isSendingCode, [isSending, isSendingCode])

  useEffect(() => {
    if (Platform.OS === "web") {
      navigation.setOptions({
        headerLeft: () => <HeaderLeft onPress={() => navigation.navigate(Screens.SignupEmail)} canGoBack={true} />,
      })
    }
  }, [navigation])

  return (
    <EnterAuthorizationCode
      type="email"
      description={description}
      code={signupParams.code}
      onCodeChange={setCode}
      actionLabel="認証"
      isResendDisabled={isResendDisabled}
      isSending={isSending}
      isSendingCode={isSendingCode}
      next={authorizeCode}
      resendAuthCode={sendAuthCode}
    />
  )
})

SignupCode.displayName = "SignupCode"

export const signupCodeOptions: ScreenOptions = {
  ...commonHeaderOptions,
  title: "認証コード入力",
}
