import { atom, selector } from "recoil"
import { Questions } from "src/constants/Questions"
import { draftSelector } from "src/recoil/selectors/contact/draftSelector"
import { templateSelector } from "src/recoil/selectors/contact/templateSelector"
import { existingContactDetailSelector } from "src/recoil/selectors/contact/create/existingContactDetailSelector"
import { AnswerType } from "src/constants/AnswerType"

const initialState = undefined

const initialStateSelector = selector({
  key: "atoms/contact/create/questionsState_initialState",
  get: ({ get }) => {
    const contact = get(existingContactDetailSelector)
    const contactValue =
      contact?.contactType === "Survey"
        ? contact.surveys.map((s) => ({
            title: s.question,
            answerType: s.isMultipleSelect ? AnswerType.Multiple : AnswerType.Single,
            choices: s.choices.map((c) => c.value),
          }))
        : undefined
    const reserved = get(existingContactDetailSelector)
    const reservedValue =
      reserved?.contactType === "Survey"
        ? reserved.surveys.map((s) => ({
            title: s.question,
            answerType: s.isMultipleSelect ? AnswerType.Multiple : AnswerType.Single,
            choices: s.choices.map((c) => c.value),
          }))
        : undefined
    const template = get(templateSelector)
    const draft = get(draftSelector)
    return contactValue ?? reservedValue ?? (template ?? draft)?.questions ?? initialState
  },
})

export const questionsState = atom<Questions | undefined>({
  key: "atoms/contact/create/questionsState",
  default: initialStateSelector,
})
