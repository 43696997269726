import { memo, useCallback, useEffect } from "react"
import { EnterAuthorizationCode } from "src/components/parts/authorization/EnterAuthorizationCode"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useLoginCodeData } from "src/recoil/hooks/screens/useLoginCodeData"
import { RootStackScreenProps } from "src/types.d"
import { AuthMethod } from "src/constants/AuthMethod"
import { Platform } from "react-native"
import { HeaderLeft } from "src/components/parts/headers/HeaderLeft"

export const LoginCode = memo<RootStackScreenProps<typeof Screens.LoginCode>>(({ route, navigation }) => {
  const { authMethod, description, code, setCode, isResendDisabled, isSending, isSendingCode, login, sendAuthCode } =
    useLoginCodeData(route.params)

  const goBack = useCallback(
    () =>
      authMethod === AuthMethod.Email ? navigation.navigate(Screens.LoginEmail) : navigation.navigate(Screens.LoginPhoneNumber),
    [authMethod, navigation]
  )

  useEffect(() => {
    if (Platform.OS === "web") {
      navigation.setOptions({
        headerLeft: () => <HeaderLeft onPress={goBack} canGoBack={true} />,
      })
    }
  }, [navigation, goBack])

  return (
    <EnterAuthorizationCode
      type={authMethod === AuthMethod.Email ? "email" : "phoneNumber"}
      description={description}
      code={code}
      onCodeChange={setCode}
      actionLabel="ログイン"
      isResendDisabled={isResendDisabled}
      isSending={isSending}
      isSendingCode={isSendingCode}
      next={login}
      resendAuthCode={sendAuthCode}
    />
  )
})

LoginCode.displayName = "LoginCode"

export const loginCodeOptions: ScreenOptions = {
  ...commonHeaderOptions,
  title: "認証コードを入力",
}
