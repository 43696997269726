import { Result } from "src/utils/Result"
import { GraphQLResult } from "@aws-amplify/api-graphql"
import { GqlError } from "src/aws/customAPI"
import { crashlyticsWrap } from "src/tags/crashlytics"
import { Maintenance } from "src/types/maintenanceUpdate/Maintenance"
import { AppVersion } from "src/types/maintenanceUpdate/AppVersion"
import { Terms } from "src/types/maintenanceUpdate/Terms"

export type ApiError = {
  errorCode?: number
  message: string
  userMessage?: string
  maintenance?: Maintenance
  appVersions?: {
    versions: AppVersion[]
  }
  newTerms?: Terms
  session?: string
}

export type ApiResult<T> = Result<T, ApiError>

export const execApi = async <T, U>(
  api: () => Promise<GraphQLResult<T>>,
  converter: (res: T) => U,
  option?: {
    defaultErrorMessage?: string
    noDataErrorMessage?: string
    onNoData?: () => void
    identifiers?: string[]
    onError?: (e: GqlError | any) => ApiResult<U> | undefined
  }
): Promise<ApiResult<U>> => {
  const defaultErrorMessage = option?.defaultErrorMessage || "予期せぬエラーが発生しました。"
  return api().then(res => {
    if (res.data != null) {
      return Result.Ok(converter(res.data))
    } else if (res.errors == null || res.errors.length === 0) {
      return Result.Error<ApiError>( { message: option?.noDataErrorMessage || defaultErrorMessage })
    } else {
      return Result.Error<ApiError>( { message: defaultErrorMessage })
    }
  }).catch((e: GqlError | any) => {
    const onErrorResult = option?.onError?.(e)
    if (onErrorResult != null) return onErrorResult

    const message = (e.errors?.[0]?.errorInfo?.userMessageWithCode) || defaultErrorMessage
    crashlyticsWrap.recordError(e, `${(option?.identifiers || []).join('|')}|${message}`)
    return Result.Error<ApiError>({ errorCode: e.errors && e.errors[0].errorInfo?.code, message, userMessage: (e.errors?.[0]?.errorInfo?.userMessage)})
  })
}