import { selector } from "recoil"
import { PlanModel } from "src/types/paidTeam/plan"
import { getListPlan } from "src/apis/paidTeam/getListPlan"
import { listPlanRequestIdState } from "src/recoil/atoms/paidTeam/listPlanRequestIdState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"

export const listPlanSelector = selector<PlanModel[]>({
  key: "selectors/paidTeam/listPlanSelector",
  get: async ({ get }) => {
    get(listPlanRequestIdState)
    const tokens = get(tokensState)
    const accountId = get(accountIdState)
    if (tokens == null || accountId == null) return []

    return await getListPlan({
      accessToken: tokens.accessToken,
    })
  },
})
