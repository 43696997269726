import { atom, selector } from "recoil"
import { ContactType } from "src/constants/ContactType"
import { draftSelector } from "src/recoil/selectors/contact/draftSelector"
import { templateSelector } from "src/recoil/selectors/contact/templateSelector"
import { existingContactDetailSelector } from "src/recoil/selectors/contact/create/existingContactDetailSelector"
import { reservedSelector } from "src/recoil/selectors/contact/reservedSelector"

const initialState = ContactType.Normal

const initialStateSelector = selector<ContactType>({
  key: "atoms/contact/create/contactTypeState_initialStateSelector",
  get: ({ get }) => {
    const contact = get(existingContactDetailSelector)
    const reserved = get(reservedSelector)
    const template = get(templateSelector)
    const draft = get(draftSelector)
    return (contact ?? reserved ?? template ?? draft)?.contactType ?? initialState
  },
})

export const contactTypeState = atom<ContactType>({
  key: "atoms/contact/create/contactTypeState",
  default: initialStateSelector,
})
