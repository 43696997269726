import { atom } from "recoil"

export type PlanData = {
  planId: string | undefined
  startTime: string | undefined
  limitTime: string | undefined
  maxMembers: string | undefined
  price: string | undefined
  invoiceAddress: string | undefined
  invoiceKanaAddress: string | undefined
  remark?: string | undefined
  startTimeConvert: string | undefined
}

export const planDataState = atom<PlanData>({
  key: "atoms/planDataState",
  default: {
    planId: undefined,
    startTime: undefined,
    limitTime: undefined,
    maxMembers: undefined,
    price: undefined,
    invoiceAddress: undefined,
    invoiceKanaAddress: undefined,
    remark: undefined,
    startTimeConvert: undefined,
  },
})
