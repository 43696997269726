import { FC } from "react"
import Svg, { Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  size?: number
  fill?: string
}

export const ShareIcon: FC<Props> = ({ size = 13.01, fill = Colors.orange }) => (
  <Svg width={size} height={size * 1.2778631822} viewBox="0 0 13.01 16.625">
    <Path
      d="M19.729 4.553v2.588h4.34a1.443 1.443 0 0 1 1.442 1.441v8.682a1.442 1.442 0 0 1-1.442 1.441H13.942a1.443 1.443 0 0 1-1.442-1.44V8.583a1.441 1.441 0 0 1 1.442-1.441h4.34V4.551l-.816.816a.723.723 0 0 1-1.023-1.022L18.5 2.291a.714.714 0 0 1 .5-.209h.005a.709.709 0 0 1 .5.207l2.053 2.054a.723.723 0 0 1-1.022 1.022zm-1.446 4.034h-4.337v8.673h10.12V8.587h-4.337v2.893a.723.723 0 0 1-1.446 0zm-2.169-1.446v1.446H21.9V7.141zm2.168 0h1.446v1.446h-1.445z"
      transform="translate(-12.501 -2.081)"
      fill={fill}
    />
  </Svg>
)

ShareIcon.displayName = "ShareIcon"
