import { memo, useCallback, useMemo, useState } from "react"
import { EnterAuthorizationCode } from "src/components/parts/authorization/EnterAuthorizationCode"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { Screens } from "src/constants/Screens"
import { useEditEmail } from "src/hooks/useEditEmail"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { RootStackScreenProps } from "src/types.d"
import { CustomAlert } from "src/utils/CustomAlert"
import { getCodeSentDescription } from "src/utils/getCodeSentDescription"
import { AuthMethod } from "src/constants/AuthMethod"
import { validateAuthMethodCode } from "src/apis/auth/validateAuthMethodCode"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { accountRequestIdState } from "src/recoil/atoms/account/accountRequestIdState"
import { RootTabs } from "src/constants/RootTabs"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export const UpdateCode = memo<RootStackScreenProps<typeof Screens.UpdateCode>>(({ navigation, route }) => {
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const refreshAccount = useRefresher(accountRequestIdState)
  const email = useMemo(() => route.params.email, [route.params.email])
  const isPCScreen = useCheckPCScreen()
  const { isSending, updateEmail } = useEditEmail(email)
  const resend = useCallback(
    () =>
      updateEmail(() => {
        CustomAlert.alert("完了", "認証コードを再送しました。")
      }),
    [updateEmail]
  )
  const [code, setCode] = useState("")
  const [isSendingCode, setIsSendingCode] = useState(false)
  const isResendDisabled = useMemo(() => isSending || isSendingCode, [isSending, isSendingCode])
  const description = useMemo(() => getCodeSentDescription(email ?? "", AuthMethod.Email), [email])
  const action = useCallback(async () => {
    if (accessToken == null || isSendingCode || code === "") {
      return
    }
    setIsSendingCode(true)
    const result = await validateAuthMethodCode({ accessToken, code, type: "email" })
    if (!result.isOk) {
      await CustomAlert.alert("エラー", result.error.message)
      setIsSendingCode(false)
      return
    }
    refreshAccount()
    await CustomAlert.alert("変更完了", "メールアドレスの変更が完了しました。")
    if (isPCScreen) {
      navigation.navigate(Screens.Root, {
        screen: RootTabs.SettingsTab,
        params: {
          screen: Screens.AccountInfo,
        },
      })
    } else {
      navigation.navigate(Screens.AccountInfo)
    }
  }, [accessToken, code, navigation, setIsSendingCode, isSendingCode, refreshAccount, isPCScreen])

  return (
    <CustomKeyboardAvoidingView>
      <CustomScrollView>
        <EnterAuthorizationCode
          type="email"
          code={code}
          onCodeChange={setCode}
          actionLabel="メールアドレスを変更"
          isResendDisabled={isResendDisabled}
          isSending={isSending}
          isSendingCode={isSendingCode}
          next={action}
          resendAuthCode={resend}
          description={description}
        />
      </CustomScrollView>
    </CustomKeyboardAvoidingView>
  )
})

export const updateCodeOptions: ScreenOptions = {
  ...commonHeaderOptions,
  title: "認証コード入力",
}
