import { Platform } from "react-native"
import { atom } from "recoil"
import { Position } from "src/tags/ads/BannerAd"

export const adState = atom({
  key: "atoms/ads/adState",
  default: [""],
})

export const adLoadState = atom({
  key: "atoms/ads/adLoadState",
  default: [""],
})

export function isShowAd(banner: Position, list: string[], idNumber?: number) {
  if (Platform.OS === "web") {
    return false
  }
  return !list.includes(`${banner}_${idNumber ?? 0}`)
}

export function isLoadDoneAd(banner: Position, list: string[], idNumber?: number) {
  if (Platform.OS === "web") {
    return false
  }
  return list.includes(`${banner}_${idNumber ?? 0}`)
}
