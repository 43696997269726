import { MatchingOffersModel } from "../../types/matching/matchingOfferModel";
import { Result } from "src/utils/Result";
import { GqlError, listMatchingApplication } from "../../aws/customAPI";
import { MatchingPurposeMapping } from "../../types/matching/matchingPurpose";

type GetAllListMatchingApplicationParams = {
  accessToken: string
  teamId: string
  filter?: {
    keyword?: string | undefined
  }
  limit?: number
}

export type GetAllListMatchingApplicationSuccess = MatchingOffersModel[]

type GetAllListMatchingApplicationFailure = {
  message: string
}

export const getAllListMatchingApplication = async ({accessToken, teamId, filter, limit}: GetAllListMatchingApplicationParams): Promise<Result<GetAllListMatchingApplicationSuccess, GetAllListMatchingApplicationFailure>> => {
  return await listMatchingApplication(accessToken, {
    teamId,
    filter,
    limit,
  }).then(res => {
    if (res.data) {
      return Result.Ok<GetAllListMatchingApplicationSuccess>(res.data.searchMatchingApplications.items.map(item => ({
        id: item.offer.id,
        purpose: MatchingPurposeMapping["jointPracticeGame"],
        title: item.offer.title,
        date: item.offer.date,
        startTime: item.offer.startTime,
        endTime: item.offer.endTime,
        prefecture: item.offer.prefecture,
        city: item.offer.city,
        ageFrom: item.offer.ageFrom,
        ageTo: item.offer.ageTo,
        publishEndDate: item.offer.publishEndDate,
        activity: item.offer.activity,
        teamId: item.offer.teamId,
        teamName: item.offer.teamName,
        applicationId: item.id,
        messages: item.messages.items,
        deleteFlg: item.offer.deleteFlg,
      })))
    } else {
      return Result.Error<GetAllListMatchingApplicationFailure>({ message: "応募の取得に失敗しました" });
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "応募の取得に失敗しました";
    return Result.Error<GetAllListMatchingApplicationFailure>({
      message: message
    });
  });
}