import { selectorFamily } from "recoil"
import { getOrganizationDetail, Organization } from "src/queries/organization/getOrganizationDetail"
import { organizationDetailRequestIdState } from "src/recoil/atoms/organization/organizationDetailRequestIdState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { organizationMembersSelectorFamily } from "src/recoil/selectors/organization/organizationMembersSelectorFamily"
import { pendingInvitationsSelectorFamily } from "src/recoil/selectors/organization/pendingInvitationsSelectorFamily"
import { groupsSelectorFamily } from "src/recoil/selectors/organization/group/groupsSelectorFamily"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"

export const organizationDetailSelectorFamily = selectorFamily<Organization | undefined, string | undefined>({
  key: "selectors/organization/organizationDetailSelectorFamily",
  get:
    (organizationId) =>
    async ({ get }) => {
      get(organizationDetailRequestIdState)
      if (!organizationId) return
      const tokens = get(tokensState)
      const accountId = get(accountIdState)
      const organizationMembers = get(organizationMembersSelectorFamily({ organizationId: organizationId, getImage: false }))
      const pendingInvitations = get(pendingInvitationsSelectorFamily({ organizationId, isGetAll: true }))

      const groups = get(groupsSelectorFamily(organizationId))
      if (tokens == null || accountId == null || organizationMembers == null || pendingInvitations == null || groups == null)
        return

      return await getOrganizationDetail({
        accountId,
        accessToken: tokens.accessToken,
        organizationId: organizationId,
        memberNumber: organizationMembers.length,
        pendingInvitationsCount: pendingInvitations.pendingMembers.length,
        groups: groups.map((g) => ({
          id: g.id,
          name: g.groupName,
        })),
      })
    },
})
