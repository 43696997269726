import { Result } from "src/utils/Result"
import { updateNotificationSetting as updateNotificationSettingAPI } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

export type UpdateNotificationSettingsRequestParams = {
  isNewContactPushEnabled?: boolean
  isNewContactEmailEnabled?: boolean
  isReplyPushEnabled?: boolean
  isReplyEmailEnabled?: boolean
  isNewMemberPushEnabled?: boolean
  isNewMemberEmailEnabled?: boolean
}

type UpdateNotificationSettingsRequestParamsWithID = {
  accessToken: string
  accountId: string
  organizationId: string
} & UpdateNotificationSettingsRequestParams

type UpdateNotificationSettingsSuccess = unknown
type UpdateNotificationSettingsFailure = {
  message: string
}

export const updateNotificationSettings = async (params: UpdateNotificationSettingsRequestParamsWithID): Promise<Result<UpdateNotificationSettingsSuccess, UpdateNotificationSettingsFailure>> => {
  const {
    isNewContactPushEnabled,
    isNewContactEmailEnabled,
    isReplyPushEnabled,
    isReplyEmailEnabled,
    isNewMemberPushEnabled,
    isNewMemberEmailEnabled,
    accessToken,
    accountId,
    organizationId
  } = params

  return execApi(
    () => updateNotificationSettingAPI(accessToken, {
      input: {
        accountId: accountId,
        teamId: organizationId,
        mailReceivedNotice: {
          push: !!isNewContactPushEnabled,
          email: !!isNewContactEmailEnabled
        },
        threadMessageReceivedNotice: {
          push: !!isReplyPushEnabled,
          email: !!isReplyEmailEnabled,
        },
        joinRequestNotice: {
          push: !!isNewMemberPushEnabled,
          email: !!isNewMemberEmailEnabled
        }
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["updateNotificationSettings", "updateNotificationSettingAPI"]
    }
  )
}
