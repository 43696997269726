import { FC } from "react"
import Svg, { ClipPath, Defs, G, Path } from "react-native-svg"

export const SportsIcon: FC = () => (
  <Svg data-name="グループ 2353" width="46.433" height="40.791" viewBox="0 0 46.433 40.791">
    <Defs>
      <ClipPath id="59r71rf4ga">
        <Path data-name="長方形 1578" fill="none" d="M0 0h46.433v40.791H0z" />
      </ClipPath>
    </Defs>
    <G data-name="グループ 2352" clipPath="url(#59r71rf4ga)">
      <Path
        data-name="パス 1040"
        d="M64.087 75.456a1.155 1.155 0 0 1-1.627-.143l-7.442-8.879 4.145-3.475 7.437 8.88a1.155 1.155 0 0 1-.143 1.627z"
        transform="translate(-32.641 -37.353)"
        fill="#ec7c81"
      />
      <Path
        data-name="パス 1041"
        d="m55.018 69.477 7.442 8.879a1.155 1.155 0 0 0 1.627.143l1.657-1.389a1.155 1.155 0 0 1-1.626-.143l-7.442-8.879z"
        transform="translate(-32.641 -40.396)"
        fill="#ea616b"
      />
      <Path
        data-name="パス 1042"
        d="M27.488 37.7a20.43 20.43 0 0 1 7.212 3.971 1.153 1.153 0 0 0 1.747-1.445 21.083 21.083 0 0 1-2.6-7.852l2.371-2.25c.011 7.3 5.056 13.355 5.056 13.355l-4.145 3.474s-5.083-6.026-12.266-7.312z"
        transform="translate(-14.749 -17.872)"
        fill="#8abfe8"
      />
      <Path
        data-name="パス 1043"
        d="M24.859 50.7c7.183 1.286 12.266 7.312 12.266 7.312l1.657-1.389a31.624 31.624 0 0 0-4.182-3.977 20.32 20.32 0 0 0-7.114-3.891z"
        transform="translate(-14.748 -28.926)"
        fill="#67abde"
      />
      <Path
        data-name="パス 1044"
        d="M4.588 3.6c-4.2 3.518-4.377 10.215-.4 14.958s10.6 5.736 14.8 2.218 4.377-10.215.4-14.958S8.785.086 4.588 3.6zm1.256 13.573c-3.2-3.822-3.143-9.165.133-11.911s8.548-1.872 11.752 1.95 3.144 9.165-.133 11.912-8.549 1.871-11.752-1.951z"
        transform="translate(-.782 -.889)"
        fill="#ec7c81"
      />
      <Path
        data-name="パス 1045"
        d="M4.588 3.6C.39 7.122.211 13.819 4.186 18.562a11.69 11.69 0 0 0 9.034 4.321 12 12 0 0 1-6.88-4.132 11.9 11.9 0 0 1-2.849-8.271q.016-.31.054-.615a9.223 9.223 0 0 1 3.2-6.071 9.128 9.128 0 0 1 5.764-2.1A9.417 9.417 0 0 0 4.588 3.6z"
        transform="translate(-.782 -.89)"
        fill="#ea616b"
      />
      <Path
        data-name="パス 1046"
        d="M3.452 2.4c-7.278 6.119-2.165 18.82 7.1 20.1 6.615 1.435 11.359 6.979 11.407 7.036l5.149 6.143a.541.541 0 1 0 .828-.695l-4.8-5.729 3.317-2.78 7.1 8.465a.617.617 0 0 1-.076.865L31.1 37.791a.617.617 0 0 1-.865-.076l-.689-.822a.541.541 0 1 0-.828.695l.689.822a1.7 1.7 0 0 0 2.389.21l2.376-1.991a1.7 1.7 0 0 0 .21-2.389l-7.442-8.878c-.052-.062-4.693-5.706-4.94-12.481a12.489 12.489 0 0 0-2.986-8.2A13.356 13.356 0 0 0 18.1 3.7a.541.541 0 1 0-.754.775 12.2 12.2 0 0 1 .841.907c8.708 11.146-4.917 22.567-14.373 12.046-7.463-9.052 1.471-20.675 11.64-14.455a.541.541 0 1 0 .582-.912C11.9-.577 6.842-.442 3.452 2.4zm13.789 18.922a9.391 9.391 0 0 0 2.414-2.015 25.337 25.337 0 0 0 1.565 3.41.613.613 0 0 1-.928.767 24.673 24.673 0 0 0-3.051-2.162zm5.186 7.111a26.36 26.36 0 0 0-3.163-2.852 24.1 24.1 0 0 0-5.252-3.1 9.742 9.742 0 0 0 2.062-.587 23.277 23.277 0 0 1 3.526 2.421 1.694 1.694 0 0 0 2.566-2.123 23.941 23.941 0 0 1-1.733-3.935 9.754 9.754 0 0 0 .955-1.952 24.093 24.093 0 0 0 2.13 5.712 26.408 26.408 0 0 0 2.256 3.613z"
        transform="translate(0 -.102)"
        fill="#545151"
      />
      <Path
        data-name="パス 1047"
        d="M20.288 21.836c8.121-7.4-3.6-21.394-12.315-14.692-6.047 5.181-1.091 15.827 6.659 16.384a7.65 7.65 0 0 0 5.656-1.692m.881-9.532-1.627 1.364-1.5-1.791 1.936-1.622a9.764 9.764 0 0 1 1.193 2.05m-1.89-2.876-1.933 1.62-1.5-1.791 1.611-1.351a9.763 9.763 0 0 1 1.823 1.522M16.446 7.34l-1.3 1.087-1.5-1.791.1-.088a8.5 8.5 0 0 1 2.694.792m-8.178 1 .687.82-2.288 1.921a6.4 6.4 0 0 1 1.607-2.742m2.479 12.7a9.794 9.794 0 0 1-1.81-1.539l1.936-1.622 1.5 1.791zm1 .571 1.32-1.106 1.5 1.792-.152.127a8.543 8.543 0 0 1-2.669-.813m7.414-.272a6.41 6.41 0 0 1-3.052 1.112l-.017-.02 2.34-1.961zm.829-.695-.729-.87 2.34-1.961.017.02a6.409 6.409 0 0 1-1.628 2.811m1.563-7.252a8.544 8.544 0 0 1 .334 2.77l-.152.128-1.5-1.792zm-6.537-3.44 1.5 1.792-2.339 1.957-1.5-1.792zm-3.035 1.132-1.5-1.792 2.34-1.961 1.5 1.792zm5.231 1.487 1.5 1.792-2.34 1.961-1.5-1.792zm2.2 2.62 1.5 1.791-2.34 1.961-1.5-1.791zm-1.671 4.448L15.4 21.6l-1.5-1.791 2.34-1.961zm-6.04-2.45 2.34-1.961 1.5 1.791-2.34 1.961zm-.695-.829-1.5-1.791 2.34-1.961 1.5 1.791zm-2.2-2.62-1.5-1.791L9.65 9.987l1.5 1.791zm-.828.695-1.3 1.088a8.513 8.513 0 0 1-.309-2.791l.1-.088zM9.1 7.645a6.4 6.4 0 0 1 2.978-1.1L9.784 8.463zm-.854 11.029a9.769 9.769 0 0 1-1.181-2.06l1.612-1.351 1.5 1.791z"
        transform="translate(-3.13 -3.19)"
        fill="#545151"
      />
      <Path
        data-name="パス 1048"
        d="M87.77 41.139a6.467 6.467 0 0 1 4.945 1.321 6.488 6.488 0 0 0-1.287-8.845 6.486 6.486 0 0 1-9.308 1.355 6.462 6.462 0 0 0-1.172 4.236c.011.147.028.3.049.444a6.464 6.464 0 0 0 2.41 4.165 6.468 6.468 0 0 1 4.363-2.676z"
        transform="translate(-48.014 -19.943)"
        fillRule="evenodd"
        fill="#fff"
      />
      <Path
        data-name="パス 1050"
        d="M64.308 15.818a6.469 6.469 0 0 1 4.008-2.618l4.99-6.69a3.235 3.235 0 0 0 .607-2.4v-.011a3.24 3.24 0 0 0-5.806-1.467L58.288 15.8l-7.381 12.607 2.6 1.939 9.629-10.292a6.464 6.464 0 0 1 1.172-4.236z"
        transform="translate(-30.202 -.791)"
        fill="#f7bc76"
        fillRule="evenodd"
      />
      <Path
        data-name="パス 1051"
        d="M79.755 30.578a6.469 6.469 0 0 1 4.008-2.614l1.307-1.752a8.141 8.141 0 0 0-7.882 10.092l1.4-1.491a6.464 6.464 0 0 1 1.167-4.235z"
        transform="translate(-45.649 -15.552)"
        fill="#ef8a5a"
        fillRule="evenodd"
      />
      <Path data-name="長方形 1575" transform="rotate(-53.281 43.34 4.047)" fill="#b9b9bd" d="M0 0h10.913v3.243H0z" />
      <Path
        data-name="パス 1052"
        d="M30.832 89.317a1.085 1.085 0 0 0 .22 1.513l2.6 1.939a1.081 1.081 0 1 0 1.293-1.733l-2.6-1.939a1.085 1.085 0 0 0-1.513.22"
        transform="translate(-18.166 -52.733)"
        fill="#f7bc76"
        fillRule="evenodd"
      />
      <Path
        data-name="パス 1053"
        d="M84.215 47.8a6.484 6.484 0 0 1-3.282-4.038 6.482 6.482 0 0 0 2.476 4.975 6.524 6.524 0 0 1 .806-.937m7.463-1.086a6.515 6.515 0 0 1 1.039.668 6.48 6.48 0 0 0 .956-5.474 6.484 6.484 0 0 1-2 4.806"
        transform="translate(-48.016 -24.86)"
        fill="#9cd6e9"
        fillRule="evenodd"
      />
      <Path
        data-name="パス 1054"
        d="M91.967 57.062a6.471 6.471 0 0 0 4.363-2.675 6.568 6.568 0 0 0-1.039-.669 6.49 6.49 0 0 1-7.463 1.082 6.505 6.505 0 0 0-.806.937 6.473 6.473 0 0 0 4.945 1.32"
        transform="translate(-51.629 -31.87)"
        fill="#d8d8d8"
        fillRule="evenodd"
      />
      <Path
        data-name="パス 1055"
        d="M30.832 89.317a1.085 1.085 0 0 0 .22 1.513l1.3.969a1.081 1.081 0 0 1 1.294-1.733l-1.3-.97a1.085 1.085 0 0 0-1.513.22"
        transform="translate(-18.166 -52.733)"
        fill="#ef8a5a"
        fillRule="evenodd"
      />
      <Path data-name="長方形 1576" transform="rotate(-53.281 43.34 4.047)" fill="#7a787e" d="M0 0h10.913v1.622H0z" />
      <Path
        data-name="パス 1056"
        d="m96.532 11.829.867.647-.646.867-.867-.647zm1.293-1.729.867.646-.646.867-.867-.647z"
        transform="translate(-56.887 -5.99)"
        fill="#fff"
      />
      <Path
        data-name="パス 1057"
        d="M60.144.749a3.789 3.789 0 0 0-5.3.771l-9.833 13.187-2.886 4.931.933.546 2.856-4.879 9.8-13.139A2.7 2.7 0 0 1 60.048 5.4l-4.87 6.529a7.041 7.041 0 0 0-5.42 7.134l-9.14 9.77-1.825-1.361 2.627-4.487-.933-.546-2.857 4.877-6.241 8.367a1.621 1.621 0 0 0-1.45 2.848l2.6 1.939a1.621 1.621 0 0 0 2.317-2.2L41.1 29.9l8.855-9.465a7.029 7.029 0 1 0 6.713-8.694l4.25-5.7a3.789 3.789 0 0 0-.774-5.292zm-2.507 23.908a5.919 5.919 0 0 1-4.093-.9 5.93 5.93 0 0 1 3.163-1.93l-.246-1.053a7.007 7.007 0 0 0-3.767 2.314 5.929 5.929 0 0 1-1.061-7.288 7.027 7.027 0 0 0 9.234-1.344 5.929 5.929 0 0 1 1.06 7.287 7.009 7.009 0 0 0-4.27-1.143l.064 1.079a5.93 5.93 0 0 1 3.582.948 5.918 5.918 0 0 1-3.666 2.03zm-18.7 6.328-1.733-1.293.985-1.32 1.733 1.293zm-6.627 5.269.985-1.32 1.734 1.293-.985 1.32zm1.631-2.187.985-1.32 1.734 1.293-.985 1.321zm1.631-2.187.985-1.32 1.733 1.293-.985 1.32zm-5.1 5.028a.542.542 0 0 1 .757-.11l2.6 1.94a.541.541 0 0 1-.646.867l-2.6-1.94a.544.544 0 0 1-.11-.756zm25.452-24.022a5.922 5.922 0 0 1 4.093.9 5.943 5.943 0 0 1-7.759 1.129 5.922 5.922 0 0 1 3.666-2.03"
        transform="translate(-17.376)"
        fill="#545151"
      />
      <Path data-name="長方形 1577" transform="rotate(-59.654 30.59 -9.876)" fill="#545151" d="M0 0h1.081v1.081H0z" />
    </G>
  </Svg>
)

SportsIcon.defaultProps = "SportsIcon"
