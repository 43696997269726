import { useCallback, useMemo } from "react"
import {
  updateNotificationSettings,
  UpdateNotificationSettingsRequestParams,
} from "src/apis/settings/updateNotificationSettings"
import { useAsyncState } from "src/hooks/useAsyncState"
import { useFetcher } from "src/hooks/useFetcher"
import { isNewContactEmailEnabledStateFamily } from "src/recoil/atoms/settings/notifications/isNewContactEmailEnabledStateFamily"
import { isNewContactPushEnabledStateFamily } from "src/recoil/atoms/settings/notifications/isNewContactPushEnabledStateFamily"
import { isNewMemberEmailEnabledStateFamily } from "src/recoil/atoms/settings/notifications/isNewMemberEmailEnabledStateFamily"
import { isNewMemberPushEnabledStateFamily } from "src/recoil/atoms/settings/notifications/isNewMemberPushEnabledStateFamily"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { CustomAlert } from "src/utils/CustomAlert"
import { isReplyPushEnabledStateFamily } from "src/recoil/atoms/settings/notifications/isReplyPushEnabledStateFamily"
import { isReplyEmailEnabledStateFamily } from "src/recoil/atoms/settings/notifications/isReplyEmailEnabledStateFamily"
import { Platform } from "react-native"

export const useOrganizationNotificationSettingData = (organizationId: string) => {
  const [isNewContactPushEnabled, setIsNewContactPushEnabled, isNewContactPushEnabledLoading] = useAsyncState(
    isNewContactPushEnabledStateFamily(organizationId)
  )
  const [isNewContactEmailEnabled, setIsNewContactEmailEnabled, isNewContactEmailEnabledLoading] = useAsyncState(
    isNewContactEmailEnabledStateFamily(organizationId)
  )
  const [isReplyPushEnabled, setIsReplyPushEnabled, isReplyPushEnabledLoading] = useAsyncState(
    isReplyPushEnabledStateFamily(organizationId)
  )
  const [isReplyEmailEnabled, setIsReplyEmailEnabled, isReplyEmailEnabledLoading] = useAsyncState(
    isReplyEmailEnabledStateFamily(organizationId)
  )
  const [isNewMemberPushEnabled, setIsNewMemberPushEnabled, isNewMemberPushEnabledLoading] = useAsyncState(
    isNewMemberPushEnabledStateFamily(organizationId)
  )
  const [isNewMemberEmailEnabled, setIsNewMemberEmailEnabled, isNewMemberEmailEnabledLoading] = useAsyncState(
    isNewMemberEmailEnabledStateFamily(organizationId)
  )
  const settings = useMemo(
    () => ({
      isNewContactPushEnabled,
      isNewContactEmailEnabled,
      isReplyPushEnabled,
      isReplyEmailEnabled,
      isNewMemberPushEnabled,
      isNewMemberEmailEnabled,
    }),
    [
      isNewContactPushEnabled,
      isNewContactEmailEnabled,
      isReplyPushEnabled,
      isReplyEmailEnabled,
      isNewMemberPushEnabled,
      isNewMemberEmailEnabled,
    ]
  )

  const isLoading = useMemo(
    () =>
      isNewContactPushEnabledLoading ||
      isNewContactEmailEnabledLoading ||
      isReplyPushEnabledLoading ||
      isReplyEmailEnabledLoading ||
      isNewMemberPushEnabledLoading ||
      isNewMemberEmailEnabledLoading,
    [
      isNewContactPushEnabledLoading,
      isNewContactEmailEnabledLoading,
      isReplyPushEnabledLoading,
      isReplyEmailEnabledLoading,
      isNewMemberPushEnabledLoading,
      isNewMemberEmailEnabledLoading,
    ]
  )
  const requestDataResult = useRequestData()
  const update = useCallback(
    async (params: UpdateNotificationSettingsRequestParams) => {
      if (!requestDataResult.isOk) {
        return
      }
      const { accessToken, accountId } = requestDataResult.content
      const {
        isNewContactPushEnabled,
        isNewContactEmailEnabled,
        isReplyPushEnabled,
        isReplyEmailEnabled,
        isNewMemberPushEnabled,
        isNewMemberEmailEnabled,
      } = params
      if (isNewContactPushEnabled != null) {
        setIsNewContactPushEnabled(isNewContactPushEnabled)
      }
      if (isNewContactEmailEnabled != null) {
        setIsNewContactEmailEnabled(isNewContactEmailEnabled)
      }
      if (isReplyPushEnabled != null) {
        setIsReplyPushEnabled(isReplyPushEnabled)
      }
      if (isReplyEmailEnabled != null) {
        setIsReplyEmailEnabled(isReplyEmailEnabled)
      }
      if (isNewMemberPushEnabled != null) {
        setIsNewMemberPushEnabled(isNewMemberPushEnabled)
      }
      if (isNewMemberEmailEnabled != null) {
        setIsNewMemberEmailEnabled(isNewMemberEmailEnabled)
      }
      const result = await updateNotificationSettings({
        ...settings, // paramsに含まれない項目は、既存の設定で上書き
        ...params,
        accessToken,
        accountId,
        organizationId,
      })
      if (!result.isOk) {
        if (isNewContactPushEnabled != null) {
          setIsNewContactPushEnabled(!isNewContactPushEnabled)
        }
        if (isNewContactEmailEnabled != null) {
          setIsNewContactEmailEnabled(!isNewContactEmailEnabled)
        }
        if (isReplyPushEnabled != null) {
          setIsReplyPushEnabled(!isReplyPushEnabled)
        }
        if (isReplyEmailEnabled != null) {
          setIsReplyEmailEnabled(!isReplyEmailEnabled)
        }
        if (isNewMemberPushEnabled != null) {
          setIsNewMemberPushEnabled(!isNewMemberPushEnabled)
        }
        if (isNewMemberEmailEnabled != null) {
          setIsNewMemberEmailEnabled(!isNewMemberEmailEnabled)
        }
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
    },
    [
      requestDataResult,
      organizationId,
      settings,
      setIsNewContactPushEnabled,
      setIsNewContactEmailEnabled,
      setIsReplyPushEnabled,
      setIsReplyEmailEnabled,
      setIsNewMemberPushEnabled,
      setIsNewMemberEmailEnabled,
    ]
  )
  const { fetch: updateNewContactPush, isFetching: isUpdatingNewContactPush } = useFetcher(
    useCallback((next: boolean) => update({ isNewContactPushEnabled: next }), [update])
  )
  const { fetch: updateNewContactEmail, isFetching: isUpdatingNewContactEmail } = useFetcher(
    useCallback((next: boolean) => update({ isNewContactEmailEnabled: next }), [update])
  )
  const { fetch: updateReplyPush, isFetching: isUpdatingReplyPush } = useFetcher(
    useCallback((next: boolean) => update({ isReplyPushEnabled: next }), [update])
  )
  const { fetch: updateReplyEmail, isFetching: isUpdatingReplyEmail } = useFetcher(
    useCallback((next: boolean) => update({ isReplyEmailEnabled: next }), [update])
  )
  const { fetch: updateNewMemberPush, isFetching: isUpdatingNewMemberPush } = useFetcher(
    useCallback((next: boolean) => update({ isNewMemberPushEnabled: next }), [update])
  )
  const { fetch: updateNewMemberEmail, isFetching: isUpdatingNewMemberEmail } = useFetcher(
    useCallback((next: boolean) => update({ isNewMemberEmailEnabled: next }), [update])
  )

  const showPushSetting = useMemo(() => Platform.OS !== "web", [])

  return {
    isNewContactPushEnabled,
    isNewContactEmailEnabled,
    isReplyPushEnabled,
    isReplyEmailEnabled,
    isNewMemberPushEnabled,
    isNewMemberEmailEnabled,
    isLoading,
    updateNewContactPush,
    updateNewContactEmail,
    updateReplyPush,
    updateReplyEmail,
    updateNewMemberPush,
    updateNewMemberEmail,
    isUpdatingNewContactPush,
    isUpdatingNewContactEmail,
    isUpdatingReplyPush,
    isUpdatingReplyEmail,
    isUpdatingNewMemberPush,
    isUpdatingNewMemberEmail,
    showPushSetting,
  }
}
