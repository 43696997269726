import React, { memo, useEffect, useMemo, useState } from "react"
import { View, StyleSheet, Text, Platform, Modal, Dimensions } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { RootStackScreenProps } from "src/types.d"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { migrationTeamsSelector } from "src/recoil/selectors/migration/migrationTeamsSelector"
import { useRecoilState, useSetRecoilState } from "recoil"
import { selectTeamStateAdmin, selectTeamStateMember } from "src/recoil/atoms/migration/selectTeamState"
import { TeamList } from "src/apis/migration/getMigrationTeams"
import { Screens } from "src/constants/Screens"
import { useNavigation } from "@react-navigation/native"
import { PC_BREAKPOINT } from "src/constants/Screen"
import { ScrollView } from "react-native-gesture-handler"
import { myFullOrganizationsSelector } from "src/recoil/selectors/organization/myOrganizationsSelector"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { HeaderLeft } from "src/components/parts/headers/HeaderLeft"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { multiMigrationStatusSelectorFamily } from "src/recoil/selectors/migration/multiMigrationStatusSelectorFamily"
import { useRefresher } from "src/hooks/useRefresher"
import { multiMigrationStatusState } from "src/recoil/atoms/migration/multiMigrationStatusState"
import { FROM_SIGN_UP, HEADER_HEIGHT } from "src/utils/const"
import { BreadCrumb } from "./BreadCrumb"
import { Step } from "./BreadCrumb"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
export const useMigrationTeams = () => {
  const { value: allTeamList } = useAsyncSelector(migrationTeamsSelector)
  const refreshTeam = useRefresher(multiMigrationStatusState)

  const [teamId, setTeamId] = useState(0)
  const [adminTeamFilter, setAdminTeamFilter] = useState<TeamList[]>([])
  const [isAdminLoading, setIsAdminLoading] = useState<boolean>(false)
  const [teamNotMigrate, setTeamNotMigrate] = useState<TeamList[]>([])
  const teamListIds = allTeamList?.map((e) => e.team_id)
  const { value: teamsMigrated } = useAsyncSelector(multiMigrationStatusSelectorFamily({ teamListIds }))
  useEffect(() => {
    if (teamsMigrated && allTeamList) {
      const teamNotmigrate = teamsMigrated.filter((e) => !e.isMigrated)
      const filteredTeams = allTeamList.filter((team) =>
        teamNotmigrate.some((notMigrateTeam) => notMigrateTeam.oldRa9Ra9TeamId === team.team_id)
      )
      setTeamNotMigrate(filteredTeams)
    }
  }, [teamsMigrated, allTeamList, setTeamNotMigrate])
  const { value: organizations, isLoading: isMyOrganizationsLoading } = useAsyncSelector(myFullOrganizationsSelector)
  const adminTeams = useMemo(() => teamNotMigrate?.filter((team) => team.admin === 1), [teamNotMigrate])
  const memberTeams = useMemo(() => teamNotMigrate?.filter((team) => team.admin === 0), [teamNotMigrate])
  useEffect(() => {
    refreshTeam()
  }, [refreshTeam])
  useEffect(() => {
    if (!allTeamList) {
      setIsAdminLoading(true)
    } else {
      setIsAdminLoading(false)
    }

    if (organizations && allTeamList && adminTeams) {
      const filteredAdminTeams = adminTeams.filter(
        (adminTeam) => !organizations.some((teamMember) => teamMember.migrationId === adminTeam.team_id)
      )
      setAdminTeamFilter(filteredAdminTeams)
    }
  }, [organizations, allTeamList, adminTeams, setIsAdminLoading])
  const navigation = useNavigation()
  const setSelectTeamAdmin = useSetRecoilState(selectTeamStateAdmin)
  const [selectTeamMember, setSelectTeamMember] = useRecoilState(selectTeamStateMember)
  const [selectedTeamIdMember, setSelectedTeamIdMember] = useState<number>()
  const [selectedTeamIdAdmin, setSelectedTeamIdAdmin] = useState<number>()
  const selectAdmin = (team: TeamList) => {
    if (selectedTeamIdMember) setSelectedTeamIdMember(0)
    setSelectTeamAdmin(team)
    setSelectedTeamIdAdmin(team.team_id)
    setTeamId(team.team_id)
  }
  const selectMember = (team: TeamList) => {
    if (selectedTeamIdAdmin) setSelectedTeamIdAdmin(0)
    setSelectTeamMember(team)
    setSelectedTeamIdMember(team.team_id)
  }
  const onSubmit = () => {
    navigation.navigate(Screens.MigrationTeamConfirmation, { teamId: teamId })
  }
  const onSubmitWithMember = () => {
    navigation.navigate(Screens.MailMigrationAsMember, {
      selectTeamMemberId: selectTeamMember.team_id,
    })
  }

  return {
    allTeamList,
    onSubmit,
    onSubmitWithMember,
    memberTeams,
    selectedTeamIdMember,
    selectedTeamIdAdmin,
    adminTeamFilter,
    isAdminLoading,
    selectAdmin,
    selectMember,
  }
}

export const MigrationTeamSelect = memo<RootStackScreenProps<"MigrationTeamSelect">>(({ navigation, route }) => {
  const {
    onSubmit,
    selectedTeamIdAdmin,
    selectedTeamIdMember,
    selectMember,
    selectAdmin,
    memberTeams,
    isAdminLoading,
    adminTeamFilter,
    onSubmitWithMember,
  } = useMigrationTeams()
  const isLargeScreen = useCheckPCScreen()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const showModal = () => {
    setIsModalVisible(true)
  }
  const hideModal = () => {
    setIsModalVisible(false)
  }

  useEffect(() => {
    const setUpBackFlow = () => {
      if (!route.params) return navigation.navigate(Screens.MigrationLogin, { type: "team" })
      const { from } = route.params
      if (from == FROM_SIGN_UP) {
        return navigation.navigate(Screens.Root, {
          screen: RootTabs.OrganizationManagerTab,
          params: {
            screen: OrganizationManagerScreens.OrganizationManager,
          },
        })
      } else {
        return navigation.navigate(Screens.MigrationLogin, { type: "team" })
      }
    }
    navigation.setOptions({
      headerLeft: () => <HeaderLeft onPress={() => setUpBackFlow()} canGoBack={true} />,
    })
  }, [navigation, route])

  useEffect(() => {
    navigation.setOptions({
      headerLeft: (props) => (
        <Button
          onPress={() =>
            navigation.navigate(Screens.Root, {
              screen: RootTabs.OrganizationManagerTab,
              params: { screen: OrganizationManagerScreens.OrganizationManager },
            })
          }
        >
          <BackIcon
            fill={Number(props.screenLayout?.width) >= PC_BREAKPOINT && Platform.OS === "web" ? Colors.orange : Colors.white3}
            {...props}
          ></BackIcon>
        </Button>
      ),
    })
  })

  const windowHeight = Dimensions.get("window").height - HEADER_HEIGHT
  const windowWidth = Dimensions.get("window").width

  const renderViewModal = (content: string) => {
    return (
      <View style={{ flexDirection: "row", padding: 4 }}>
        <Text style={{ paddingRight: 3 }}>※</Text>
        <Text>{content}</Text>
      </View>
    )
  }
  return (
    <View style={[styles.container]}>
      <ScrollView
        contentContainerStyle={[
          isLargeScreen ? styles.oneColumn : { width: 360 },
          Platform.OS === "web" ? { height: windowHeight, width: windowWidth } : { width: windowWidth },
        ]}
        showsVerticalScrollIndicator={false}
      >
        <View style={[isLargeScreen ? { width: 600, maxWidth: 1020 } : {}]}>
          <BreadCrumb stepProp={Step.two} />
          <View style={[{ paddingTop: 32, paddingHorizontal: 24, paddingBottom: 0 }]}>
            <Text style={[styles.title, { marginBottom: 8, textAlign: "left" }]}>
              らくらく連絡網（旧サービス）で参加している団体の中から目的の団体を選択してください。
            </Text>
            <Text>※ 手続きは1団体ずつ行えます。</Text>
          </View>
          <View style={[styles.vertical, { paddingTop: 32 }]}>
            <ItemLabel label={"移行する団体を選択"} />
            <Text style={{ marginVertical: 8 }}>
              下記の代表者として参加している団体の中から、移行する団体を１つ選択してください。
            </Text>
            <Text style={{ paddingBottom: 19, display: "flex", alignItems: "center" }}>
              {Platform.OS === "ios" || Platform.OS === "android" ? (
                <>
                  <Text>※ </Text>
                  <Text>団体移行の注意点は </Text>
                </>
              ) : (
                renderViewModal("団体移行の注意点は")
              )}
              <Text onPress={showModal} style={[styles.openPopupText]}>
                こちら
              </Text>
              。
            </Text>
            {isAdminLoading ? (
              <View style={styles.loadingContainer}>
                <LoadingIndicator />
              </View>
            ) : adminTeamFilter?.length !== 0 && adminTeamFilter ? (
              <View style={{ marginBottom: 2 }}>
                {adminTeamFilter
                  .filter((team) => !!team)
                  .map((team) => (
                    <TeamCard key={team.team_id} team={team} selectedTeamId={selectedTeamIdAdmin} select={selectAdmin} />
                  ))}
              </View>
            ) : (
              <Text style={{ width: "100%", textAlign: "center", marginBottom: 14 }}>移行できる団体はありません。</Text>
            )}
          </View>
          <View style={[isLargeScreen ? styles.centerLayout : { paddingHorizontal: 30 }, { marginBottom: 32, marginTop: 15 }]}>
            <TextButton
              buttonType={ButtonType.Primary}
              title={"選択"}
              isLoading={false}
              disabled={adminTeamFilter?.length === 0 || selectedTeamIdAdmin == null || selectedTeamIdAdmin == 0}
              onPress={onSubmit}
              style={isLargeScreen ? styles.buttonsPC : {}}
            />
          </View>
        </View>
        <View style={[isLargeScreen ? { width: 600, maxWidth: 1020 } : {}, styles.contentTopMember]}>
          <View style={[styles.vertical, { marginTop: 22 }]}>
            <ItemLabel label={"移行リクエストする団体を選択"} />
            <Text style={{ paddingVertical: 10 }}>
              下記のメンバーとして参加している団体の中から、代表者に移行リクエストする団体を１つ選択してください。
            </Text>
            {isAdminLoading ? (
              <View style={styles.loadingContainer}>
                <LoadingIndicator />
              </View>
            ) : memberTeams?.length !== 0 && memberTeams ? (
              <View style={{ marginTop: 14, marginBottom: 2 }}>
                {memberTeams
                  .filter((team) => !!team)
                  .map((team) => (
                    <TeamCard key={team.team_id} team={team} selectedTeamId={selectedTeamIdMember} select={selectMember} />
                  ))}
              </View>
            ) : (
              <Text style={{ marginVertical: 14, width: "100%", textAlign: "center" }}>移行できる団体はありません。</Text>
            )}
          </View>
          <View style={[isLargeScreen ? styles.centerLayout : { paddingHorizontal: 30 }, { marginBottom: 32, marginTop: 14 }]}>
            <TextButton
              buttonType={ButtonType.Primary}
              title={"選択"}
              isLoading={false}
              disabled={memberTeams?.length === 0 || selectedTeamIdMember == null || selectedTeamIdMember == 0}
              onPress={onSubmitWithMember}
              style={isLargeScreen ? styles.buttonsPC : {}}
            />
          </View>
        </View>
        <Modal transparent={true} visible={isModalVisible} onRequestClose={hideModal}>
          <View style={styles.modalOverlay}>
            <View style={isLargeScreen ? styles.modalLargeScreen : styles.modalSmallScreen}>
              <View style={{ flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
                <Button style={styles.buttonHideModal} onPress={hideModal}>
                  <RemoveIcon fill={Colors.orange} />
                </Button>
                <Text style={[styles.title]}>{"団体移行の注意点"}</Text>
                <View style={[styles.buttonHideModal, { opacity: 0 }]}>
                  <RemoveIcon fill={Colors.orange} />
                </View>
              </View>
              <View style={styles.content}>
                {renderViewModal("既に団体情報の移行が完了している団体は表示されません。")}
                {renderViewModal(
                  "複数代表者がいる団体の場合は、事前に代表者同士でご相談の上、複数の方が移行手続きをしないようご留意ください。"
                )}
                {renderViewModal("有料版を利用中の団体は移行できません。")}
                {renderViewModal("メンバーが3000人（上限）以上所属している団体は移行できません。")}
                {renderViewModal(
                  "「らくらく連絡網＋」で既に所属団体数が100団体（上限）に達しているメンバーが存在している場合、移行メンバーから除外されます。"
                )}
                {renderViewModal(
                  "移行後、同じ団体を再度移行しなおすことを希望される場合は、「らくらく連絡網＋」側で移行済みの該当団体をご解約の上、改めて移行手続きを行なっていただく必要がございます。"
                )}
              </View>
            </View>
          </View>
        </Modal>
      </ScrollView>
    </View>
  )
})

type Props = {
  team: TeamList
  selectedTeamId: number | undefined
  select: (team: TeamList) => void
}

export const TeamCard = memo<Props>(({ team, selectedTeamId, select }) => {
  const isChecked = useMemo(() => selectedTeamId === team.team_id, [selectedTeamId, team.team_id])
  return (
    <Button
      onPress={() => select(team)}
      style={[
        styles.card,
        isChecked ? { borderWidth: 2, borderColor: Colors.orange } : { borderWidth: 2, borderColor: Colors.white },
      ]}
    >
      <Text style={{ fontWeight: "700", fontSize: 16 }}>{team.name}</Text>
    </Button>
  )
})
export const migrationTeamSelectOptions: ScreenOptions = ({ navigation }) => ({
  ...commonHeaderOptions,
  headerShown: true,
  title: "移行する団体を選択",
})
const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.beige,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  oneColumn: {
    paddingLeft: 30,
    paddingRight: 30,
    backgroundColor: Colors.beige,
    alignItems: "center",
  },
  vertical: {
    paddingVertical: 10,
    paddingHorizontal: 24,
  },
  modalOverlay: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  buttonHideModal: {
    width: 5,
    border: "none",
    paddingLeft: 0,
    paddingRight: 16,
  },
  modalLargeScreen: {
    backgroundColor: Colors.white3,
    padding: 40,
    borderRadius: 20,
    width: "50%",
    maxWidth: 600,
  },

  modalSmallScreen: {
    backgroundColor: Colors.white3,
    padding: 24,
    borderRadius: 10,
    width: "80%",
    position: "relative",
  },

  closeButtonSmall: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    position: "absolute",
    top: -8,
    left: 1,
  },
  closeButtonLarge: {
    paddingHorizontal: 10,
    paddingVertical: 10,
    position: "absolute",
    top: 10,
    left: 10,
  },
  closeButtonTextLarge: {
    fontSize: 16,
    color: Colors.orange,
  },
  closeButtonTextSmall: {
    fontSize: 24,
    color: Colors.orange,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  centerLayout: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: 16,
    fontWeight: "600",
    width: "100%",
    textAlign: "center",
    lineHeight: 22.4,
  },
  content: {
    fontSize: 14,
    fontWeight: "400",
    width: "100%",
    textAlign: "left",
    paddingTop: 24,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 30,
  },
  card: {
    borderRadius: 12,
    padding: 15,
    fontWeight: "bold",
    backgroundColor: "#FFFFFF",
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginVertical: 5,
    fontFamily: "Hiragino Kaku Gothic Pro",
  },
  openPopupText: {
    color: Colors.orange,
    textDecorationLine: "underline",
  },
  contentTopMember: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
})
