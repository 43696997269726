import type { FC } from "react"
import { Svg, Path } from "react-native-svg"
import { DrawerIconProps } from "src/types/DrawerIconProps"

export const OutboxIcon: FC<DrawerIconProps> = ({ color }) => {
  return (
    <Svg width="20.475" height="20.481" viewBox="0 0 20.475 20.481">
      <Path
        data-name="パス 1281"
        d="M103.409.63 84.872 11.323a.96.96 0 0 0 .087 1.728l4.251 1.784 9.5-7.141a.24.24 0 0 1 .344.333l-7.644 8.756V20a.959.959 0 0 0 1.7.631l2.539-3.092 4.983 2.09a.963.963 0 0 0 1.26-.517 1.034 1.034 0 0 0 .061-.21l2.879-17.28a.96.96 0 0 0-1.424-1"
        transform="translate(-84.371 -.503)"
        fill={color}
      />
    </Svg>
  )
}

OutboxIcon.displayName = "OutboxIcon"
