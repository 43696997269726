import { Result } from "src/utils/Result"
import { deleteAccountDevice } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import * as Notifications from "expo-notifications"
import * as Device from "expo-device"
import { Platform } from "react-native"

type RemoveAccountDeviceSuccessResponse = {
  message: string
}

type RemoveAccountDeviceFailureResponse = {
  message: string
}

export const removeAccountDevice = async (access_token: string): Promise<Result<RemoveAccountDeviceSuccessResponse, RemoveAccountDeviceFailureResponse>> => {
  if (Device.isDevice && Platform.OS !== 'web') {
    const device_token = await Notifications.getDevicePushTokenAsync();
    if (!device_token) {
      return Promise.resolve(Result.Ok<RemoveAccountDeviceSuccessResponse>({
        message: 'No DeviceToken',
      }))
    }

    return execApi(
      () => deleteAccountDevice(access_token, {
        input: {
          deviceToken: device_token.data,
        },
        appInfo
      }),
      _ => ({ message: "OK" }),
      {
        identifiers: ["removeAccountDevice", "deleteAccountDevice"],
      }
    )
  } else {
    return Result.Error<RemoveAccountDeviceSuccessResponse>({
      message: 'No Device',
    });
  }
}
