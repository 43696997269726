import { execApi } from "src/apis/execApi";
import { listCommentNormalMail, listCommentSpecialMail, TeamMemberIDFilter, ListCommentTeamMemberMailItem, accountImages } from "src/aws/customAPI";
import { ContactType } from "src/constants/ContactType";
import { s3ImageDomain } from "src/utils/s3ImageDomain";

export type Response = {
  total?: number | null;
  items: ListCommentTeamMemberMailItem[];
  nextToken?: string;
};

export type Params = {
  mailId: string;
  teamMemberIds?: TeamMemberIDFilter[];
  count?: number;
  nextToken?: string;
  contactType: string;
  accessToken: string;
};

export const getListComment = async (params: Params): Promise<Response | undefined | string> => {
  if (!params.accessToken || !params.contactType || !params.mailId) return;

  const fetchComments = params.contactType === ContactType.Normal
    ? listCommentNormalMail
    : listCommentSpecialMail;

  const result = await execApi(
    () => fetchComments(params.accessToken, {
      mailId: params.mailId,
      teamMemberIds: params.teamMemberIds,
      count: params.count,
      nextToken: params.nextToken,
    }),
    res => ({
      total: res.searchTeamMemberMails.total,
      items: res.searchTeamMemberMails.items,
      nextToken: res.searchTeamMemberMails.nextToken,
    })
  );

  if (!result.isOk) {
    return result.errorMessage ?? "";
  }

  const accountIds = result.content.items
    .filter(item => !!item.teamMember.accountTeamMembersId)
    .map(item => item.teamMember.accountTeamMembersId as string);

  const accountImagesResult = await accountImages(params.accessToken, {
    input: { accountIds },
  });

  const accountImageMap: Record<string, string> = accountImagesResult.data?.multiAccountImageGetUrl.images?.reduce(
    (map, ele) => ({
      ...map,
      [ele.accountId]: ele.url,
    }),
    {}
  ) ?? {};

  return {
    total: result.content.total,
    items: result.content.items.map(item => ({
      ...item,
      imageUrl: `${s3ImageDomain}${
        accountImageMap[item.teamMember.accountTeamMembersId ?? ""] ?? "default/account/image/profile.png"
      }?${new Date().getTime()}`,
    })),
    nextToken: result.content.nextToken,
  };
};
