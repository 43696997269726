import { useFocusEffect } from "@react-navigation/native"
import { memo, useCallback, useEffect, useMemo } from "react"
import { FlatList, ListRenderItemInfo, StyleSheet, Text, View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { groupsRequestIdState } from "src/recoil/atoms/organization/group/groupsRequestIdState"
import { groupsSelectorFamily } from "src/recoil/selectors/organization/group/groupsSelectorFamily"
import { RootStackScreenProps } from "src/types.d"
import { GroupOverview } from "src/types/organization/group/GroupOverview"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { ButtonType, IconButton } from "src/components/parts/buttons/IconButton"
import { PlusIcon } from "src/components/parts/icons/PlusIcon"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { MaxCount } from "src/constants/MaxCount"
import { CustomAlert } from "src/utils/CustomAlert"
import { ChevronRightIcon } from "src/components/parts/icons/ChevronRightIcon"
import { MainLayout } from "src/layouts/MainLayout"
import { HeaderCommon } from "src/components/parts/HeaderCommon"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { RootTabs } from "src/constants/RootTabs"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import { useAsyncState } from "src/hooks/useAsyncState"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { useRecoilState, useRecoilValue } from "recoil"
import { routeNameState } from "src/recoil/atoms/routeNameState"
import { groupMemberListState } from "src/recoil/atoms/organization/group/groupMemberListState"

export type OrganizationGroupsParams = {
  id: string
}

type Props = RootStackScreenProps<typeof Screens.OrganizationGroups>

export const OrganizationGroups = memo<Props>(
  ({
    navigation,
    route: {
      params: { id },
    },
  }) => {
    const isLargeScreen = useCheckPCScreen()
    const refresher = useRefresher(groupsRequestIdState)
    const { value, isLoading } = useAsyncSelector(groupsSelectorFamily(id))
    const groupCount = useMemo(() => value?.length || 0, [value])
    const [listMembers, setListMembers] = useRecoilState(groupMemberListState)

    const { allowCreateGroup, allowEditGroup, allowEditMember, listGroupAccessAll, listGroupIds } =
      useCheckAllowAccountPermission({
        organizationId: id,
      })
    const listGroup = useMemo(() => {
      const apiListGroup = value ?? []
      if (listGroupAccessAll) {
        return apiListGroup
      }
      const filteredGroups = apiListGroup.filter((group) => listGroupIds.includes(group.id))
      return filteredGroups
    }, [listGroupAccessAll, listGroupIds, value])

    const [selectedOrganizationId, setSelectedOrganizationId] = useAsyncState(selectedMyOrganizationState)
    const routeName = useRecoilValue(routeNameState)

    useEffect(() => {
      if (routeName === Screens.OrganizationGroups && selectedOrganizationId !== id) {
        if (isLargeScreen && selectedOrganizationId) {
          navigation.replace(Screens.Root, {
            screen: RootTabs.OrganizationManagerTab,
            params: {
              screen: Screens.OrganizationGroups,
              params: {
                id: selectedOrganizationId,
              },
            },
          })
        } else {
          setSelectedOrganizationId(id)
        }
      }
    }, [id, selectedOrganizationId, setSelectedOrganizationId, routeName, isLargeScreen, navigation])

    const goToEditGroupSettingsInOrganization = useCallback(
      (groupId: string) => {
        navigation.navigate(Screens.GroupMembers, {
          organizationId: id,
          groupId,
          allowEditGroup,
          allowEditMember,
        })
      },
      [navigation, id, allowEditGroup, allowEditMember]
    )

    const goToAddGroupInOrganization = useCallback(() => {
      if (groupCount >= MaxCount.Group) {
        CustomAlert.alert(
          "エラー",
          `エラーコード010\nグループ数が上限を超えるため、作成できません。\n※1団体で作成できるグループ数は最大${MaxCount.Group}グループです。`
        )
        return
      }
      setListMembers([])
      navigation.navigate(Screens.CreateOrEditGroupStack, { params: { organizationId: id }, screen: "AddGroupInOrganization" })
    }, [navigation, id, groupCount, setListMembers])

    const ListHeaderComponent = useMemo(
      () => (
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <View style={styles.listHeaderContainer}>
            <ItemLabel label="グループ一覧" />
          </View>
          {allowCreateGroup && isLargeScreen ? (
            <View style={{ marginBottom: 10 }}>
              <IconButton
                buttonType={ButtonType.Primary}
                IconComponent={<PlusIcon fillColor={Colors.orange} />}
                title="グループ追加"
                onPress={goToAddGroupInOrganization}
              />
            </View>
          ) : null}
        </View>
      ),
      [allowCreateGroup, goToAddGroupInOrganization, isLargeScreen]
    )

    const ListEmptyComponent = useMemo(
      () => (
        <View style={styles.emptyContainer}>
          <Text style={styles.emptyText}>グループはありません</Text>
        </View>
      ),
      []
    )

    const renderItem = useCallback(
      ({ item }: ListRenderItemInfo<GroupOverview>) => (
        <Button style={styles.rowButtonContainer} key={item.id} onPress={() => goToEditGroupSettingsInOrganization(item.id)}>
          <Text style={styles.rowButtonText}>{item.groupName}</Text>
          <ChevronRightIcon />
        </Button>
      ),
      [goToEditGroupSettingsInOrganization]
    )

    const { bottom } = useSafeAreaInsets()

    useFocusEffect(
      useCallback(() => {
        refresher()
      }, [refresher])
    )

    return (
      <MainLayout>
        <HeaderCommon
          title="グループ一覧"
          back={() =>
            navigation.navigate(Screens.Root, {
              screen: RootTabs.OrganizationManagerTab,
              params: {
                screen: OrganizationManagerScreens.OrganizationDetail,
                params: { organizationId: id },
              },
            })
          }
        />
        <CustomKeyboardAvoidingView style={styles.container}>
          {value == null ? (
            <View style={{ height: "80%", width: "100%", alignItems: "center", justifyContent: "center" }}>
              <LoadingIndicator />
            </View>
          ) : (
            <>
              <FlatList
                data={listGroup}
                renderItem={renderItem}
                ListHeaderComponent={ListHeaderComponent}
                ListEmptyComponent={ListEmptyComponent}
                refreshControl={<CustomRefreshControl refreshing={isLoading} onRefresh={refresher} />}
                contentContainerStyle={[isLargeScreen ? { paddingHorizontal: 30 } : {}]}
              />
              {allowCreateGroup && !isLargeScreen ? (
                <View style={[styles.addGroupButtonContainer, { paddingBottom: bottom }]}>
                  <IconButton
                    buttonType={ButtonType.Primary}
                    IconComponent={<PlusIcon fillColor={Colors.orange} />}
                    title="グループ追加"
                    onPress={goToAddGroupInOrganization}
                  />
                </View>
              ) : null}
            </>
          )}
        </CustomKeyboardAvoidingView>
      </MainLayout>
    )
  }
)

export const options: ScreenOptions = {
  ...commonHeaderOptions,
  title: "グループ一覧",
}

const styles = StyleSheet.create({
  container: {
    padding: 15,
    backgroundColor: Colors.white,
  },
  listHeaderContainer: {
    marginBottom: 16,
  },
  rowButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: Colors.white3,
    borderRadius: 16,
    paddingVertical: 18,
    paddingHorizontal: 25,
    marginTop: 8,
    shadowColor: Colors.lightTan,
    shadowOpacity: 0.38,
    shadowRadius: 8,
    elevation: 3,
  },
  rowButtonText: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "bold",
  },
  emptyContainer: {
    paddingVertical: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  emptyText: {
    fontSize: 16,
    color: Colors.lightGrey,
  },
  addGroupButtonContainer: {
    alignItems: "center",
  },
})
