import { listHasPreTeamMember } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
  accountId: string
}

type Response = {
  [teamId: string]: boolean
}

export const getHasPreTeamMemberByTeam = async ({accessToken, accountId}: Params): Promise<Response> => {
  const result = await execApi(
    () => listHasPreTeamMember(accessToken, { accountId, appInfo }),
    res => Object.fromEntries(res.items.map(item => [item.teamId, item.hasPreTeamMember]) || []),
    {
      identifiers: ["getHasPreTeamMemberByTeam", "listHasPreTeamMember"]
    }
  )

  return result.isOk ? result.content : {}
}
