import { GqlError, blockMatchingTeam as _blockMatchingTeam } from "src/aws/customAPI"
import { Result } from "src/utils/Result"

type BlockMatchingTeamParams = {
  accessToken: string
  myTeamId: string
  blockingTeamId: string
}

type BlockMatchingTeamSuccess = unknown

type BlockMatchingTeamFailure = {
  message: string
}

export const blockMatchingTeam = async ({accessToken, blockingTeamId, myTeamId}: BlockMatchingTeamParams): Promise<Result<BlockMatchingTeamSuccess, BlockMatchingTeamFailure>> => {
  return await _blockMatchingTeam(accessToken, {
    myTeamId,
    blockingTeamId,
  }).then(res => {
    if (res.data?.createBlockingMatchingTeam.id) {
      return Result.Ok(undefined)
    } else {
      return Result.Error<BlockMatchingTeamFailure>({message: "団体のブロックに失敗しました"})
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "団体のブロックに失敗しました"
    return Result.Error<BlockMatchingTeamFailure>({
      message: message
    })
  })
}