import { useCallback, useState } from "react"
import { useSetRecoilState } from "recoil"
import { RegisterAuthCodeInput } from "src/aws/API"
import { Tokens, tokensState } from "src/recoil/atoms/authorization/tokensState"
import { assertAuthCodeError } from "src/utils/assertAuthCodeError"
import { CustomAlert } from "src/utils/CustomAlert"
import { getAuthStateFromResult } from "src/utils/getAuthStateFromResult"
import { registerDevice } from "src/apis/auth/createDevice"
import { sessionState } from "src/recoil/atoms/authorization/sessionState"
import { validateCode } from "src/apis/auth/validateCode"
import * as Linking from "expo-linking"
import { oldRa9Ra9TokenStates } from "src/recoil/atoms/migration/oldRa9Ra9TokenStates"
import { migrateAccountDataState } from "src/recoil/atoms/account/migrateAccountDataState"

type Params = {
  email?: string
  phoneNumber?: string
  code: string
  session?: string
}

export const useAuthorizeCode = ({ email, phoneNumber, code, session }: Params) => {
  const [isSendingCode, setIsSendingCode] = useState(false)
  const setAuthorizationState = useSetRecoilState(tokensState)
  const setOldRa9Ra9AccessTokens = useSetRecoilState(oldRa9Ra9TokenStates)
  const setSession = useSetRecoilState(sessionState)
  const setMigrateAccountData = useSetRecoilState(migrateAccountDataState)
  const authorizeCode = useCallback(async () => {
    const input: RegisterAuthCodeInput | undefined = (() => {
      if (session == null) {
        return
      }
      const common = {
        secret_code: code,
        session_id: session,
      }
      if (email != null) {
        return {
          ...common,
          email,
        }
      }
      if (phoneNumber != null) {
        return {
          ...common,
          phone_number: phoneNumber,
        }
      }
    })()
    if (input == null) {
      return
    }

    let authState: Tokens | undefined
    try {
      setIsSendingCode(true)
      const result = await validateCode({
        authMethod: "Email",
        value: email || "",
        code: code,
        session: session || "",
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        if (result.error.session) {
          setSession(result.error.session)
        } else {
          await Linking.openURL("/auth")
        }
        return
      }
      const authResult = result.content
      const { accessToken, expiresIn, idToken, refreshToken, tokenType } = authResult
      if (accessToken == null || expiresIn == null || idToken == null || refreshToken == null || tokenType == null) {
        return CustomAlert.alert("完了", "予期せぬエラーが発生しました。")
      }
      authState = getAuthStateFromResult({
        AccessToken: accessToken,
        ExpiresIn: expiresIn,
        IdToken: idToken,
        RefreshToken: refreshToken,
        TokenType: tokenType,
      })
      if (authState == null) {
        CustomAlert.alert("エラー", "予期せぬエラーが発生しました。")
      }
      setOldRa9Ra9AccessTokens(undefined)
      setMigrateAccountData(true)
      await registerDevice(accessToken)
      await CustomAlert.alert("認証完了", "メールアドレスが登録されました。")
    } catch (error) {
      if (assertAuthCodeError(error)) {
        error.errors?.forEach((error) => {
          CustomAlert.alert("エラー", error.errorInfo.userMessage)
        })
      }
    } finally {
      setIsSendingCode(false)
    }
    if (authState != null) {
      setAuthorizationState(authState)
    }
  }, [setAuthorizationState, email, phoneNumber, code, session, setSession, setOldRa9Ra9AccessTokens, setMigrateAccountData])

  return {
    authorizeCode,
    isSendingCode,
  }
}
