import { selectorFamily } from "recoil"
import { notificationsSelector } from "src/recoil/selectors/home/notificationsSelector"

export const hasMatchingNoticeSelector = selectorFamily({
  key: "selectors/matching/hasMatchingNoticeSelector",
  get:
    (organizationId: string) =>
    ({ get }) => {
      const notifications = get(notificationsSelector)
      return (
        notifications
          ?.filter((n) => n.organizationId === organizationId && n.type === "matchingMessage")
          .some((n) => !n.isRead) || false
      )
    },
})
