import { FC } from "react"
import Svg, { G, Path } from "react-native-svg"

export const BellIcon: FC = () => (
  <Svg width="9.53" height="11.363" viewBox="0 0 9.53 11.363">
    <G data-name="グループ 2198">
      <Path
        d="M1.191 1.165A1.182 1.182 0 0 0 2.382 0H0a1.178 1.178 0 0 0 1.191 1.165z"
        transform="translate(3.574 10.197)"
        fill="#fff"
      />
      <Path
        data-name="Path"
        d="M8.339 7.866V4.953A3.559 3.559 0 0 0 5.658 1.27v-.4a.894.894 0 0 0-1.787 0v.4a3.548 3.548 0 0 0-2.68 3.683v2.913L0 9.032v.583h9.53v-.583z"
        fill="#fff"
      />
    </G>
  </Svg>
)

BellIcon.displayName = "BellIcon"
