import { getUnreadThreadMessageCountByIds, GetUnreadThreadMessageCountByIdsVariables } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
  accountId: string
  contactIds?: string []
}

export const getUnreadThreadCount = async ({accessToken, accountId, contactIds}: Params): Promise<any> => {
  if (contactIds != null && contactIds.length == 0) { return 0 }
  const variables: GetUnreadThreadMessageCountByIdsVariables = contactIds == null ? {
    aggrUnit: 'account',
    accountId
  } : {
    aggrUnit: 'mail',
    accountId,
    mailIds: contactIds
  }

  const result = await execApi(
    () => getUnreadThreadMessageCountByIds(accessToken, { ...variables, appInfo }),
    res => res.searchThreadMessages.aggregateItems[0].result,
  )

  return result.isOk ? result.content : 0
}
