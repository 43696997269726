import { selectorFamily } from "recoil"
import { memberInvitationRequestIdState } from "src/recoil/atoms/organization/memberInvitationRequestIdState"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { getInvitationInfo } from "src/aws/customAPI"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { appInfo } from "src/constants/AppInfo"

type Data = {
  organizationId: string
  organizationName: string
  invitationCode: string
}

export const memberInvitationSelectorFamily = selectorFamily<Data | undefined, string>({
  key: "selectors/organization/memberInvitationSelectorFamily",
  get:
    (id) =>
    async ({ get }) => {
      get(memberInvitationRequestIdState)
      const organization = get(organizationDetailSelectorFamily(id))
      const tokens = get(tokensState)
      if (organization == null || tokens == null) {
        return
      }

      const invitationInfo = await getInvitationInfo(tokens.accessToken, {
        teamId: organization.id,
        appInfo,
      }).then((res) => res.data?.getInvitationInfo)

      return (
        invitationInfo && {
          organizationId: organization.id,
          organizationName: organization.organizationName,
          invitationCode: invitationInfo.invitationCode,
        }
      )
    },
})
