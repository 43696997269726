import { memo, ReactElement } from "react"
import { StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"

type Props = {
  Icon: ReactElement
  label: string
}

export const DrawerStatus = memo<Props>(({ Icon, label }) => {
  return (
    <View style={styles.header}>
      {Icon}
      <Text style={styles.headerTitle}>{label}</Text>
    </View>
  )
})

const styles = StyleSheet.create({
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.white3,
    height: 50,
  },
  headerTitle: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginLeft: 8,
  },
})
