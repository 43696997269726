import { useRecoilState } from "recoil"
import { useCallback, useMemo, useEffect, useState } from "react"
import { newTermsState } from "src/recoil/atoms/updateTerms/newTermsState"
import { updateAcceptTerms } from "src/apis/updateTerms/updateAcceptTerms"
import { useAsyncState } from "src/hooks/useAsyncState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { CustomAlert } from "src/utils/CustomAlert"
import * as WebBrowser from "expo-web-browser"
import { latestTermsState } from "src/recoil/atoms/account/latestTermsState"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { acceptServiceTermFlagState } from "src/recoil/atoms/account/acceptServiceTermsFlagState"
import { Platform } from "react-native"

export const useTermsData = () => {
  const [newTerms, setNewTerms] = useRecoilState(newTermsState)
  const isTermsModalOpen = useMemo(() => newTerms != undefined, [newTerms])
  const { value: latestTerms } = useAsyncSelector(latestTermsState)
  const { value: account } = useAsyncSelector(accountSelector)
  const [agreeTerm, setAgreeTerm] = useRecoilState(acceptServiceTermFlagState)
  const checkVersion = account?.agreedTermsVersion === latestTerms?.service.version ? true : false
  const serviceVersion = account?.agreedTermsVersion

  useEffect(() => {
    if (latestTerms?.service.version && !checkVersion && !agreeTerm && serviceVersion) {
      setNewTerms(latestTerms?.service)
    }
  }, [latestTerms, checkVersion, agreeTerm, serviceVersion, setNewTerms])

  useEffect(() => {
    if (!latestTerms && Platform.OS !== "web") {
      setNewTerms(undefined)
    }
  }, [latestTerms, setNewTerms])

  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const [isTermsUpdating, setIsTermsUpdating] = useState(false)
  const [isChecked, setIsChecked] = useState(false)
  const toggleCheck = useCallback(() => setIsChecked((prev) => !prev), [setIsChecked])

  const acceptTerms = useCallback(async () => {
    if (accessToken == null || newTerms == null || !isChecked || isTermsUpdating) return
    setIsTermsUpdating(true)
    const result = await updateAcceptTerms({ accessToken, termsVersion: newTerms.version })
    setIsTermsUpdating(false)
    if (!result.isOk) {
      CustomAlert.alert("エラー", result.error.message)
      return
    }
    setAgreeTerm(true)
    setNewTerms(undefined)
  }, [accessToken, newTerms, setNewTerms, setIsTermsUpdating, isChecked, isTermsUpdating, setAgreeTerm])

  const onOpenTerms = useCallback(async () => {
    if (newTerms != null) await WebBrowser.openBrowserAsync(newTerms.url)
  }, [newTerms])

  return {
    isChecked,
    toggleCheck,
    newTerms,
    acceptTerms,
    isTermsUpdating,
    isTermsModalOpen,
    onOpenTerms,
    serviceVersion,
  }
}
