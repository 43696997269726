import { GqlError, extendPaidVersionOrder as _extendPaidVersionOrder } from "src/aws/customAPI"
import { Result } from "src/utils/Result"
import { convertDataEmail } from "src/utils/validate"

type Params = {
  accessToken: string
  orderId: string
  planId: string,
  invoiceAddressee: string,
  invoiceAddresseeKana: string,
  applicantRemark: string,
}

type Success = {
  orderId: string
}

type Failure = {
  orderId: string
  message: string
}

export const extendPaidVersionOrderApi = async ({ accessToken, orderId, planId, invoiceAddressee, invoiceAddresseeKana, applicantRemark }: Params): Promise<Result<Success, Failure>> => {
  return _extendPaidVersionOrder(accessToken, {
    input: {
      orderId,
      planId,
      invoiceAddressee,
      invoiceAddresseeKana,
      applicantRemark: convertDataEmail(applicantRemark).length <= 2000 ? convertDataEmail(applicantRemark ?? "") : convertDataEmail(applicantRemark ?? "").slice(0, 2000)
    }
  }).then(res => {
    if (res.data?.extendPaidVersionOrder.id) {
      return Result.Ok<Success>({
        orderId: res.data?.extendPaidVersionOrder.id
      })
    } else {
      return Result.Error<Failure>({
        orderId: "",
        message: "予期せぬエラーが発生しました"
      })
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "予期せぬエラーが発生しました"
    return Result.Error<Failure>({
      message: message,
      orderId: e.errors ? ((e.errors[0].errorInfo?.detail as any)?.orderId ?? "") : ""
    })
  })
}