import { useEffect, useMemo } from "react"
import { useRequestData } from "./useRequestData"
import { analyticsWrap } from "src/tags/analytics"
import { crashlyticsWrap } from "src/tags/crashlytics"
import { registerDevice } from "src/apis/auth/createDevice"
import { getUpdateDeviceFlag, setUpdateDeviceFlag } from "src/storage/updateDeviceFlag"

export const useAccountIdEffect = () => {
  const requestDataResult = useRequestData()
  const content = useMemo(() => {
    if (!requestDataResult.isOk) {
      return
    }
    return requestDataResult.content
  }, [requestDataResult])

  useEffect(() => {
    ;(async () => {
      if (content?.accountId) {
        if (content?.accessToken.length > 0) {
          getUpdateDeviceFlag().then(async (value) => {
            if (!value) {
              setUpdateDeviceFlag()
              await registerDevice(content?.accessToken)
            }
          })
        }

        try {
          analyticsWrap.setUser(content?.accountId)
          crashlyticsWrap.setUser(content?.accountId)
        } catch (e) {
          crashlyticsWrap.recordError(e, "accountIdSetError")
        }
      }
    })()
  }, [content])
}
