import { Result } from "src/utils/Result";
import { ImageExtension } from "../../aws/API";
import { getMatchingTeamBgImagePutUrl, getMatchingTeamImagePutUrl } from "../../aws/customAPI";
import { ResourceUnit } from "../../types/resource/ResourceUnit";
import { deleteResource } from "../resource/deleteResource";
import { getResourcePutUrl } from "../resource/getResourcePutUrl";
type SuccessResponse = unknown

type FailureResponse = {
  message: string
}
const ERROR_MESSAGE = "画像のアップロードに失敗しました。ネットワークに接続されているかを確認し、再度保存をしてください。";

type UploadTeamImageInput = ResourceUnit & {
  accessToken: string
  fileData: File | undefined
  localImageName: string | undefined
  deleteResourceFlg?: boolean,
}

export const uploadMatchingImageFile = async ({
                                                accessToken,
                                                fileData,
                                                localImageName,
                                                deleteResourceFlg,
                                                ...resourceUnit
                                              }: UploadTeamImageInput): Promise<Result<SuccessResponse, FailureResponse>> => {
  if (deleteResourceFlg && resourceUnit.type === "matchingTeamBgImage" ) {
    return deleteResource({ accessToken, ...resourceUnit })
      .then(async () => {
        return Result.Ok(undefined);
      })
      .catch(() => Result.Error({ message: "リソースの削除に失敗しました。" }));
  }
  if (resourceUnit.type === "matchingTeamBgImage" && localImageName != null) {
    const extension = getExtension(localImageName) as ImageExtension;
    const teamId = resourceUnit.teamId
    const urls = await getMatchingTeamBgImagePutUrl(accessToken, {
      input: { teamId, extension }
    }).then(res => res.data?.getMatchingTeamBgImagePutUrl);
    if (urls != null) {
      await fetch(urls.url, {
        method: "PUT",
        body: fileData
      }).then(_ => Result.Ok<SuccessResponse>(undefined))
        .catch(_ => {
          return Result.Error<FailureResponse>({
            message: ERROR_MESSAGE
          });
        });
    } else {
      return Result.Error<FailureResponse>({
        message: ERROR_MESSAGE
      });
    }
  }
  if (resourceUnit.type === "matchingTeamImage" && localImageName != null) {
    const extension = getExtension(localImageName) as ImageExtension;
    const teamId = resourceUnit.teamId;
    const urls = await getMatchingTeamImagePutUrl(accessToken, {
      input: { teamId, extension }
    }).then(res => res.data?.getMatchingTeamImagePutUrl);
    if (urls != null) {
      await fetch(urls.url, {
        method: "PUT",
        body: fileData
      }).then(_ => Result.Ok<SuccessResponse>(undefined))
        .catch(_ => {
          return Result.Error<FailureResponse>({
            message: ERROR_MESSAGE
          });
        });
      await fetch(urls.thumbnailUrl, {
        method: "PUT",
        body: fileData
      }).then(_ => Result.Ok<SuccessResponse>(undefined))
        .catch(_ => {
          return Result.Error<FailureResponse>({
            message: ERROR_MESSAGE
          });
        });
      return Result.Ok(undefined);
    } else {
      return Result.Error<FailureResponse>({
        message: ERROR_MESSAGE
      });
    }
  }
  return Result.Ok(undefined);
};

type UploadAttachmentFileInput = ResourceUnit & {
  accessToken: string
  fileData: File
}

export const uploadMatchingAttachmentFile = async ({
                                             accessToken,
                                             fileData,
                                             ...resourceUnit
                                           }: UploadAttachmentFileInput): Promise<Result<SuccessResponse, FailureResponse>> => {
  if (resourceUnit.type === "matchingOfferAttachmentFile") {
    const urls = await getResourcePutUrl({ accessToken, ...resourceUnit });
    if (urls != null) {
      return await fetch(urls.url, {
        method: "PUT",
        body: fileData
      }).then(_ => Result.Ok<SuccessResponse>(undefined))
        .catch(_ => {
          return Result.Error<FailureResponse>({
            message: ERROR_MESSAGE
          });
        });
    } else {
      return Result.Error<FailureResponse>({
        message: ERROR_MESSAGE
      });
    }
  }
  return Result.Ok(undefined);
};

type UploadAttachmentThumbnailFileInput = ResourceUnit & {
  accessToken: string
  fileData: File
}

export const uploadMatchingAttachmentThumbnailFile = async ({
                                                      accessToken,
                                                      fileData,
                                                      ...resourceUnit
                                                    }: UploadAttachmentThumbnailFileInput): Promise<Result<SuccessResponse, FailureResponse>> => {
  if (resourceUnit.type === "matchingOfferAttachmentThumbnail") {
    const urls = await getResourcePutUrl({ accessToken, ...resourceUnit });
    if (urls != null) {
      return await fetch(urls.url, {
        method: "PUT",
        body: fileData
      }).then(_ => Result.Ok<SuccessResponse>(undefined))
        .catch(_ => {
          return Result.Error<FailureResponse>({
            message: ERROR_MESSAGE
          });
        });
    } else {
      return Result.Error<FailureResponse>({
        message: ERROR_MESSAGE
      });
    }
  }
  return Result.Ok(undefined);
};

const getExtension = (uri: string): string => {
  const split = uri.split(".");
  return split.length > 0 ? split.slice(-1)[0] : "";
};