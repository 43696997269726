import { useCallback, useMemo } from "react"
import { deleteMember } from "src/apis/organization/deleteMember"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { useFetcher } from "src/hooks/useFetcher"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { organizationMemberDetailRequestIdState } from "src/recoil/atoms/organization/organizationMemberDetailRequestIdState"
import { organizationMembersRequestIdState } from "src/recoil/atoms/organization/organizationMembersRequestIdState"
import { organizationMemberDetailSelectorFamily } from "src/recoil/selectors/organization/organizationMemberDetailSelectorFamily"
import { CustomAlert } from "src/utils/CustomAlert"
import { useIncrementRecoilState } from "src/recoil/hooks/useIncrementRecoilState"
import * as Clipboard from "expo-clipboard"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"

export const useOrganizationMemberDetailData = (
  organizationId: string,
  memberId: string,
  gotoOrganizaitonMembers: () => void
) => {
  const refreshMemberDetail = useIncrementRecoilState(organizationMemberDetailRequestIdState)
  const { value: memberDetail, isLoading: isLoadingMemberDetail } = useAsyncSelector(
    organizationMemberDetailSelectorFamily({ organizationId, memberId })
  )
  const { value: organizationDetail, isLoading: isLoadingOrganizationDetail } = useAsyncSelector(
    organizationDetailSelectorFamily(organizationId)
  )
  const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(organizationDetail?.id))
  const customQuestions = useMemo(() => organizationDetail?.customQuestions || [], [organizationDetail])

  const isLoading = useMemo(() => {
    return isLoadingMemberDetail || isLoadingOrganizationDetail
  }, [isLoadingMemberDetail, isLoadingOrganizationDetail])
  const refreshOrganizationMembers = useIncrementRecoilState(organizationMembersRequestIdState)
  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const copyEmail = useCallback(async () => {
    if (memberDetail == null) return

    const isSuccess = await Clipboard.setStringAsync(memberDetail.email)
    if (isSuccess) {
      await CustomAlert.alert("完了", "メールアドレスをコピーしました")
    }
  }, [memberDetail])

  const { fetch: execDeleteMember, isFetching: isDeletingMember } = useFetcher(
    useCallback(async () => {
      if (accessToken == null) {
        return
      }
      const isConfirmed = await CustomAlert.confirm("確認", "本当に団体から削除しますか？\n元に戻せません。")
      if (!isConfirmed) {
        return
      }

      const result = await deleteMember({
        accessToken,
        organizationMemberId: memberId,
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      await CustomAlert.alert("完了", "団体からの削除が完了しました。")
      refreshOrganizationMembers()
      gotoOrganizaitonMembers()
    }, [accessToken, memberId, gotoOrganizaitonMembers, refreshOrganizationMembers])
  )

  return {
    refreshMemberDetail,
    memberDetail,
    customQuestions,
    execDeleteMember,
    isLoading,
    isDeletingMember,
    copyEmail,
    myProfile,
  }
}
