import { StyleSheet, Text, View } from "react-native"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"
import dayjs from "dayjs"
import { formatDate } from "src/constants/Day"
import { MIGRATE_PLAN_ID } from "src/utils/const"

type Props = {
  companyName: string
  teamMembers: number
  maxMembers: string
  limitTime: string
  price: string
  invoiceAddress: string
  invoiceKanaAddress: string
  startTimeConvert: string
  remark: string
  goToEditPlan: () => void
  planId: string
}

const PlanBlock = ({
  companyName,
  teamMembers,
  maxMembers,
  limitTime,
  startTimeConvert,
  price,
  invoiceAddress,
  invoiceKanaAddress,
  remark,
  goToEditPlan,
  planId,
}: Props) => {
  const convertTimeString = (inputTime: string) => {
    const date = dayjs(inputTime)
    return date.format(formatDate.PlanStartTime)
  }
  return (
    <View style={[styles.container]}>
      <Text style={[styles.title]}>団体有料版申込情報</Text>
      <View style={[styles.subContainer]}>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="団体名" />
            <Text style={[styles.textField]}>{companyName}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="メンバー数" />
            <Text style={[styles.textField]}>{teamMembers + "名"}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="プラン" />
            <Text style={[styles.textField]}>
              {planId === MIGRATE_PLAN_ID ? "らくらく連絡網からの移行プラン" : `${maxMembers + "名"}(${limitTime}カ月)`}
            </Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="人数上限" />
            <Text style={[styles.textField]}>{planId === MIGRATE_PLAN_ID ? "" : maxMembers + "名"}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="利用期間" />
            <Text style={[styles.textField]}>{planId === MIGRATE_PLAN_ID ? "" : limitTime + "カ月"}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="利用開始希望日" />
            <Text style={[styles.textField]}>
              {planId === MIGRATE_PLAN_ID ? "" : convertTimeString(startTimeConvert ?? "")}
            </Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="料金（税込）" />
            <Text style={[styles.textField]}>{planId === MIGRATE_PLAN_ID ? "" : price + "円"}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="入金方法" />
            <Text style={[styles.textField]}>指定口座への振込</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="請求書の宛名[振込名義]" />
            <Text style={[styles.textField]}>{invoiceAddress}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="請求書の宛名[振込名義](フリガナ)" />
            <Text style={[styles.textField]}>{invoiceKanaAddress}</Text>
          </View>
        </View>
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="備考欄" />
            <Text style={[styles.textField]}>{remark}</Text>
          </View>
        </View>
        <Button style={[styles.editButton]} onPress={goToEditPlan}>
          <Text style={[styles.textBtn]}>有料版申込情報の修正</Text>
        </Button>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    width: "100%",
    paddingVertical: 24,
    paddingHorizontal: 16,
    maxWidth: 600,
    marginHorizontal: "auto",
  },
  subContainer: {
    alignItems: "center",
    width: "100%",
    paddingVertical: 16,
    maxWidth: 600,
    marginHorizontal: "auto",
  },
  row: {
    marginBottom: 16,
    width: "100%",
    borderBottomColor: Colors.borderColor,
    borderBottomWidth: 1,
  },
  itemLabelWrapper: {
    marginBottom: 4,
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 20,
    color: Colors.black2,
  },
  title: {
    width: "100%",
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 25.2,
    marginBottom: 16,
  },
  textField: {
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 22.4,
    marginVertical: 14,
    textAlign: "left",
    width: "100%",
  },
  editButton: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    width: "100%",
    borderRadius: 8,
    backgroundColor: "rgba(159, 134, 107, 0.10)",
  },
  textBtn: {
    fontSize: 13,
    fontWeight: "600",
    lineHeight: 18.2,
    textAlign: "center",
  },
})

export default PlanBlock
