import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { StyleSheet, Text, View } from "react-native"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { HeaderCommon } from "src/components/parts/HeaderCommon"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { MainLayout } from "src/layouts/MainLayout"
import { OrganizationManagerStackScreenProps } from "src/types.d"
import { useOrderDetail } from "src/recoil/hooks/screens/useOrderDetail"
import { OrderStatus, OrderPaymentStatus, OderPaymentType } from "src/apis/paidTeam/getOrderDetail"
import { Screens } from "src/constants/Screens"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { RootTabs } from "src/constants/RootTabs"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { convertDate } from "src/constants/Day"
import { useRefresher } from "src/hooks/useRefresher"
import { organizationDetailRequestIdState } from "src/recoil/atoms/organization/organizationDetailRequestIdState"
import { useSetRecoilState } from "recoil"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { MIGRATE_PLAN_ID } from "src/utils/const"
import { OrderPaymentType } from "src/aws/API"
import { ContactSurveyAnswerScreens } from "src/constants/ContactSurveyAnswerScreens"
import { CustomAlert } from "src/utils/CustomAlert"

export type OrderDetailParams = {
  teamId: string
  teamMembers?: number
  accountId?: string
  mode?: string
}

type Props = OrganizationManagerStackScreenProps<typeof OrganizationManagerScreens.OrderDetail>

const OrderDetail = memo<Props>(({ navigation, route }) => {
  const { teamId, teamMembers, accountId, mode } = route.params
  const isLargeScreen = useCheckPCScreen()
  const refreshOrganizationDetail = useRefresher(organizationDetailRequestIdState)
  const setOrganizationId = useSetRecoilState(selectedMyOrganizationState)
  const { value: organizationDetail, isLoading: isLoadingOrganization } = useAsyncSelector(
    organizationDetailSelectorFamily(teamId)
  )
  const [canGoBack, setCanGoBack] = useState(false)

  const adminId = useMemo(
    () => (accountId ? accountId : organizationDetail?.profileInOrganization.id ?? ""),
    [accountId, organizationDetail]
  )

  const goBack = useCallback(() => {
    setOrganizationId(teamId)
    navigation.replace(Screens.Root, {
      screen: RootTabs.OrganizationManagerTab,
      params: {
        screen: OrganizationManagerScreens.OrganizationDetail,
        params: { organizationId: teamId },
      },
    })
  }, [setOrganizationId, navigation, teamId])

  const {
    orderDetail,
    plan,
    isLoading,
    isApplicant,
    formatCreatedDate,
    isShowExtendButton,
    orderPreDetail,
    prePlan,
    isExtended,
  } = useOrderDetail({
    teamId,
    adminId,
    mode,
    goBack,
  })

  const goToInquiryPaidTeam = useCallback(() => {
    if (teamId == null) {
      return
    }
    refreshOrganizationDetail()
    navigation.navigate(Screens.InquiryPaidTeam, {
      organizationId: teamId,
    })
  }, [navigation, teamId, refreshOrganizationDetail])

  useEffect(() => {
    if (!orderDetail.createdAt && !orderPreDetail.createdAt && !isLoading) {
      const delayGoBack = setTimeout(() => {
        setCanGoBack(true)
      }, 500)

      return () => clearTimeout(delayGoBack)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetail.createdAt, orderPreDetail.createdAt, isLoading])

  useEffect(() => {
    if (
      (mode !== "home" && !orderDetail.createdAt && !orderPreDetail.createdAt && !isLoading && canGoBack) ||
      organizationDetail?.paidFunctionEnabled === false
    ) {
      goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canGoBack])

  useEffect(() => {
    navigation.setOptions({
      headerLeft: (props) => (
        <Button
          onPress={() => {
            goBack()
          }}
        >
          <BackIcon fill={isLargeScreen ? Colors.orange : Colors.white3} {...props}></BackIcon>
        </Button>
      ),
    })
  })

  const goToExtendOrder = useCallback(() => {
    if (
      !orderDetail.applicantPhone ||
      !orderDetail.applicantPostcode ||
      !orderDetail.applicantPrefecture ||
      !orderDetail.applicantAddress
    ) {
      CustomAlert.alert("エラー", "申込者情報が一部未登録です。「申込者情報の修正」より登録後、お申込ください。")
    } else {
      navigation.navigate(Screens.ExtendPaidTeam, {
        organizationId: orderDetail?.teamId ?? "",
        accountId: adminId,
      })
    }
  }, [navigation, orderDetail, adminId])

  return (
    <MainLayout>
      {isLargeScreen && <HeaderCommon title={"団体有料版詳細"} needBackIcon={true} back={goBack} noMarginBot={true} />}
      <CustomScrollView noPaddingBottom={true}>
        {!isLoading ? (
          <>
            <View style={[styles.container, isLargeScreen ? { paddingHorizontal: 34 } : {}]}>
              <View style={[styles.subContainer]}>
                <Text style={[styles.title]}>申込者情報</Text>
                <View style={[styles.wrapper]}>
                  {isApplicant && (
                    <View style={[styles.row]}>
                      <ItemLabel label="団体名" />
                      <Text style={[styles.textField]}>{orderDetail?.teamName ?? ""}</Text>
                    </View>
                  )}
                  <View style={[styles.row]}>
                    <ItemLabel label="団体代表者氏名（会員名）" />
                    <Text style={[styles.textField]}>{orderDetail?.applicantName ?? ""}</Text>
                  </View>
                  {isApplicant && (
                    <View style={[styles.row]}>
                      <ItemLabel label="メールアドレス" />
                      <Text style={[styles.textField]}>{orderDetail?.applicantMail}</Text>
                    </View>
                  )}
                  {orderDetail?.orderType !== "private" && (
                    <>
                      <View style={[styles.row]}>
                        <ItemLabel label="企業名" />
                        <Text style={[styles.textField]}>{orderDetail?.companyName ?? ""}</Text>
                      </View>
                      <View style={[styles.row]}>
                        <ItemLabel label="法人代表者名" />
                        <Text style={[styles.textField]}>{orderDetail?.corporateRepresentativeName ?? ""}</Text>
                      </View>
                    </>
                  )}
                  {isApplicant && (
                    <>
                      <View style={[styles.row]}>
                        <ItemLabel label="電話番号" />
                        <Text style={[styles.textField]}>{orderDetail?.applicantPhone ?? ""}</Text>
                      </View>
                      <View style={[styles.row]}>
                        <ItemLabel label="郵便番号" />
                        <Text style={[styles.textField]}>{orderDetail?.applicantPostcode ?? ""}</Text>
                      </View>
                      <View style={[styles.row]}>
                        <ItemLabel label="都道府県" />
                        <Text style={[styles.textField]}>{orderDetail?.applicantPrefecture ?? ""}</Text>
                      </View>
                      <View style={[styles.row]}>
                        <ItemLabel label="住所" />
                        <Text style={[styles.textField]}>{orderDetail?.applicantAddress ?? ""}</Text>
                      </View>
                      <View style={[styles.row]}>
                        <ItemLabel label="ビル・建物名" />
                        <Text style={[styles.textField]}>{orderDetail?.applicantBuildingName ?? ""}</Text>
                      </View>
                      <Button
                        style={[styles.button]}
                        onPress={() =>
                          navigation.navigate(Screens.registerApplicantInformation, {
                            organizationId: orderDetail?.teamId ?? "",
                            mode: "update",
                            orderId: orderDetail?.id,
                          })
                        }
                      >
                        <Text style={[styles.textButton]}>申込者情報の修正</Text>
                      </Button>
                      <View style={{ height: 16 }}></View>
                      <Button
                        style={[styles.button]}
                        onPress={() => {
                          navigation.navigate(Screens.CreateOrEditGroupStack, {
                            params: {
                              organizationId: orderDetail?.teamId ?? "",
                              memberId: organizationDetail?.profileInOrganization.id ?? "",
                              orderId: orderDetail?.id,
                              accountId,
                            },
                            screen: "ApplicantAuthorityTransfer",
                          })
                        }}
                      >
                        <Text style={[styles.textButton]}>申込者権限を譲渡する</Text>
                      </Button>
                    </>
                  )}
                </View>
              </View>
            </View>
            {/* Plan Block */}
            <View style={[styles.line]}></View>
            <View
              style={[
                styles.container,
                isLargeScreen ? { paddingHorizontal: 34 } : {},
                isExtended ? { paddingBottom: 16 } : {},
              ]}
            >
              <View style={[styles.subContainer]}>
                <Text style={[styles.title]}>団体有料版申込情報</Text>
                <View style={[styles.wrapper]}>
                  {isExtended && <Text style={[styles.title]}>現在の契約内容</Text>}
                  <View
                    style={[
                      styles.row,
                      orderDetail?.orderStatus === OrderStatus.New &&
                      orderDetail?.paymentStatus === OrderPaymentStatus.Wait &&
                      isApplicant
                        ? { borderBottomColor: "transparent" }
                        : {},
                    ]}
                  >
                    <ItemLabel label="プラン" />
                    <Text style={[styles.textField]}>
                      {plan?.id === MIGRATE_PLAN_ID
                        ? "らくらく連絡網からの移行プラン"
                        : `${plan?.memberLimit}名(${plan?.period}カ月)`}
                    </Text>
                  </View>
                  {orderDetail?.orderStatus === OrderStatus.New &&
                    orderDetail?.paymentStatus === OrderPaymentStatus.Wait &&
                    isApplicant && (
                      <View style={[styles.wrapperButton]}>
                        <Button
                          style={[styles.button]}
                          onPress={() =>
                            navigation.navigate(Screens.registerPlan, {
                              organizationId: orderDetail?.teamId ?? "",
                              mode: "update",
                              orderId: orderDetail?.id,
                            })
                          }
                        >
                          <Text style={[styles.textLink]}>プランの変更</Text>
                        </Button>
                        <Text style={[styles.longText]}>
                          ※プラン変更は入金前に限り行えます。入金後のプラン変更は行えませんので、ご注意ください。
                        </Text>
                      </View>
                    )}

                  <View style={[styles.row]}>
                    <ItemLabel label="人数上限" />
                    {isLoadingOrganization ? (
                      <LoadingIndicator />
                    ) : (
                      <Text style={[styles.textField]}>
                        {plan?.id === MIGRATE_PLAN_ID
                          ? ""
                          : teamMembers
                          ? teamMembers + "/" + plan?.memberLimit + "名"
                          : organizationDetail?.memberNumber + "/" + plan?.memberLimit + "名"}
                      </Text>
                    )}
                  </View>
                  <View style={[styles.row]}>
                    <ItemLabel label="利用期間" />
                    <Text style={[styles.textField]}>
                      {plan?.id === MIGRATE_PLAN_ID
                        ? orderDetail.endDate && `${convertDate(orderDetail.endDate)}まで`
                        : `${plan?.period}カ月（${convertDate(orderDetail.endDate)}まで)`}
                    </Text>
                  </View>
                  {orderDetail.orderStatus === OrderStatus.New && (
                    <View style={[styles.row]}>
                      <ItemLabel label="申込日" />
                      <Text style={[styles.textField]}>{convertDate(formatCreatedDate)}</Text>
                    </View>
                  )}
                  <View style={[styles.row]}>
                    <ItemLabel label="利用開始日" />
                    <Text style={[styles.textField]}>{convertDate(orderDetail.startDate)}</Text>
                  </View>
                  <View style={[styles.row]}>
                    <ItemLabel label="料金（税込）" />
                    <Text style={[styles.textField]}>
                      {orderDetail?.amount ? orderDetail.amount.toLocaleString("ja-JP") + "円" : ""}
                    </Text>
                  </View>
                  {!isApplicant && (
                    <View style={[styles.row]}>
                      <ItemLabel label="入金方法" />
                      <Text style={[styles.textField]}>指定口座への振込</Text>
                    </View>
                  )}
                  <View style={[styles.row]}>
                    <ItemLabel label="請求書の宛名[振込名義]" />
                    <Text style={[styles.textField]}>{orderDetail?.invoiceAddressee ?? ""}</Text>
                  </View>

                  {/* Show deadline */}
                  {orderDetail?.orderStatus === OrderStatus.New &&
                    orderDetail?.paymentStatus === OrderPaymentStatus.Wait &&
                    isApplicant && (
                      <>
                        <View style={[styles.row]}>
                          <ItemLabel label="入金状況" />
                          <Text style={[styles.textRed]}>入金前</Text>
                          {orderDetail.paymentDeadline && (
                            <View style={[styles.redArea]}>
                              <Text style={[styles.textRedBow, { fontSize: 18 }]}>
                                入金期限：{convertDate(orderDetail.paymentDeadline)}
                              </Text>
                            </View>
                          )}
                        </View>
                        <Button
                          style={[styles.button]}
                          onPress={() => {
                            if (teamMembers || organizationDetail?.memberNumber)
                              navigation.navigate(Screens.cancelOrder, {
                                teamMembers: teamMembers ?? organizationDetail?.memberNumber ?? 0,
                                accountId: adminId,
                              })
                          }}
                        >
                          <Text style={styles.textLink}>申込のキャンセル</Text>
                        </Button>
                        <Text style={styles.longText}>
                          ※キャンセルは入金前に限り行えます。入金後のキャンセルは行えませんので、ご了承ください。
                        </Text>
                      </>
                    )}
                  {/* Show Extend */}
                  {(orderDetail?.orderStatus === OrderStatus.Use || orderDetail?.orderStatus === OrderStatus.Wait) &&
                    (orderDetail?.paymentStatus === OrderPaymentStatus.Paid ||
                      orderDetail?.paymentType === OderPaymentType.PostPaid) &&
                    isApplicant &&
                    !isExtended && (
                      <>
                        <Text style={[styles.textRedBow, { paddingBottom: 20, paddingTop: 8 }]}>
                          契約満了日：{convertDate(orderDetail.endDate)}
                        </Text>
                        <View style={[styles.redArea]}>
                          <Text style={[styles.textRedBow]}>{`更新手続き期間：${isLargeScreen ? "" : "\n"}${convertDate(
                            orderDetail.endDate,
                            true
                          )}〜${convertDate(orderDetail.endDate)}`}</Text>
                          {isShowExtendButton && (
                            <Button style={[styles.button, { marginTop: 16, width: "100%" }]} onPress={() => goToExtendOrder()}>
                              <Text style={styles.textLink}>継続の申込</Text>
                            </Button>
                          )}
                        </View>
                      </>
                    )}
                  {/* remark */}
                  {!isApplicant && (
                    <View style={[styles.row]}>
                      <ItemLabel label="備考欄" />
                      <Text style={[styles.longText, { paddingBottom: 14 }]}>{orderDetail?.applicantRemark ?? ""}</Text>
                    </View>
                  )}
                </View>
              </View>
            </View>

            {/* Extend Block */}
            {isExtended && (
              <>
                <View style={[styles.container, isLargeScreen ? { paddingHorizontal: 34 } : {}, { paddingTop: 0 }]}>
                  <View style={[styles.subContainer]}>
                    <View style={[styles.wrapper]}>
                      <Text style={[styles.title]}>更新後の契約内容</Text>
                      <View
                        style={[
                          styles.row,
                          orderPreDetail?.orderStatus === OrderStatus.New &&
                          orderPreDetail?.paymentStatus === OrderPaymentStatus.Wait
                            ? { borderBottomColor: "transparent" }
                            : {},
                        ]}
                      >
                        <ItemLabel label="プラン" />
                        <Text style={[styles.textField]}>{`${prePlan?.memberLimit}名(${prePlan?.period}カ月)`}</Text>
                      </View>
                      {orderPreDetail?.orderStatus === OrderStatus.New &&
                        orderPreDetail?.paymentStatus === OrderPaymentStatus.Wait &&
                        isApplicant && (
                          <View style={[styles.wrapperButton]}>
                            <Button
                              style={[styles.button]}
                              onPress={() =>
                                navigation.navigate(Screens.registerPlan, {
                                  organizationId: orderPreDetail?.teamId ?? "",
                                  mode: "update",
                                  orderId: orderPreDetail?.id,
                                  extend: true,
                                })
                              }
                            >
                              <Text style={[styles.textLink]}>プランの変更</Text>
                            </Button>
                            <Text style={[styles.longText]}>
                              ※プラン変更は入金前に限り行えます。入金後のプラン変更は行えませんので、ご注意ください。
                            </Text>
                          </View>
                        )}

                      <View style={[styles.row]}>
                        <ItemLabel label="人数上限" />
                        <Text style={[styles.textField]}>
                          {teamMembers
                            ? teamMembers + "/" + plan?.memberLimit + "名"
                            : organizationDetail?.memberNumber + "/" + prePlan?.memberLimit + "名"}
                        </Text>
                      </View>
                      <View style={[styles.row]}>
                        <ItemLabel label="利用期間" />
                        <Text style={[styles.textField]}>
                          {prePlan?.id === MIGRATE_PLAN_ID
                            ? orderPreDetail.endDate && `${convertDate(orderPreDetail.endDate)}まで`
                            : `${prePlan?.period}カ月（${convertDate(orderPreDetail.endDate)}まで)`}
                        </Text>
                      </View>
                      <View style={[styles.row]}>
                        <ItemLabel label="利用開始日" />
                        <Text style={[styles.textField]}>{convertDate(orderPreDetail.startDate)}</Text>
                      </View>
                      <View style={[styles.row]}>
                        <ItemLabel label="料金（税込）" />
                        <Text style={[styles.textField]}>
                          {orderPreDetail?.amount ? orderPreDetail.amount.toLocaleString("ja-JP") + "円" : ""}
                        </Text>
                      </View>
                      {!isApplicant && (
                        <View style={[styles.row]}>
                          <ItemLabel label="入金方法" />
                          <Text style={[styles.textField]}>指定口座への振込</Text>
                        </View>
                      )}
                      <View style={[styles.row]}>
                        <ItemLabel label="請求書の宛名[振込名義]" />
                        <Text style={[styles.textField]}>{orderPreDetail?.invoiceAddressee ?? ""}</Text>
                      </View>

                      {/* Show deadline */}
                      {orderPreDetail?.orderStatus === OrderStatus.New &&
                        orderPreDetail?.paymentStatus === OrderPaymentStatus.Wait &&
                        isApplicant && (
                          <>
                            <View style={[styles.row]}>
                              <ItemLabel label="入金状況" />
                              <Text style={[styles.textRed]}>入金前</Text>
                              <View style={[styles.redArea]}>
                                <Text style={[styles.textRedBow, { fontSize: 18 }]}>
                                  入金期限：{convertDate(orderPreDetail?.paymentDeadline ?? "")}
                                </Text>
                              </View>
                            </View>
                          </>
                        )}
                      {/* Show time extend */}
                      {(orderPreDetail?.orderStatus === OrderStatus.Use || orderPreDetail?.orderStatus === OrderStatus.Wait) &&
                        (orderPreDetail?.paymentStatus === OrderPaymentStatus.Paid ||
                          orderPreDetail?.paymentType === OrderPaymentType.postpaid) &&
                        isApplicant && (
                          <>
                            <Text style={[styles.textRedBow, { paddingBottom: 20, paddingTop: 8 }]}>
                              契約満了日：{convertDate(orderPreDetail.endDate)}
                            </Text>
                            <View style={[styles.redArea]}>
                              <Text style={[styles.textRedBow]}>{`更新手続き期間：${isLargeScreen ? "" : "\n"}${convertDate(
                                orderPreDetail.endDate,
                                true
                              )}〜${convertDate(orderPreDetail.endDate)}`}</Text>
                            </View>
                          </>
                        )}
                      {/* remark */}
                      {!isApplicant && (
                        <View style={[styles.row]}>
                          <ItemLabel label="備考欄" />
                          <Text style={[styles.longText, { paddingBottom: 14 }]}>{orderPreDetail?.applicantRemark ?? ""}</Text>
                        </View>
                      )}
                    </View>
                  </View>
                </View>
              </>
            )}
            <View style={[styles.line]}></View>
            <Button
              onPress={() => goToInquiryPaidTeam()}
              style={[
                { width: "100%", paddingHorizontal: 24, paddingVertical: 16, backgroundColor: "rgba(159, 134, 107, 0.10)" },
              ]}
            >
              <Text style={[styles.textLink, { textDecorationLine: "underline", textAlign: "center" }]}>お問い合わせ</Text>
            </Button>
            <View style={[styles.line]}></View>
          </>
        ) : (
          <View style={{ flex: 1, alignItems: "center", justifyContent: "center", marginTop: "30%" }}>
            <LoadingIndicator />
          </View>
        )}
      </CustomScrollView>
    </MainLayout>
  )
})

export const OrderDetailOptions = {
  ...commonHeaderOptions,
  title: "団体有料版詳細",
  headerShown: true,
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
    paddingHorizontal: 16,
    alignItems: "center",
    backgroundColor: "rgba(159, 134, 107, 0.10)",
  },
  subContainer: {
    width: "100%",
  },
  title: {
    marginBottom: 16,
    fontSize: 18,
    fontWeight: "600",
    lineHeight: 25.2,
  },
  wrapper: {
    borderRadius: 8,
    backgroundColor: Colors.white3,
    paddingVertical: 24,
    paddingHorizontal: 16,
    shadowColor: "rgba(41, 39, 36, 0.06)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 30,
    elevation: 6,
  },
  row: {
    marginBottom: 16,
    width: "100%",
    borderBottomColor: Colors.borderColor,
    borderBottomWidth: 1,
  },
  textField: {
    paddingVertical: 14,
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 22.4,
  },
  button: {
    paddingHorizontal: 24,
    paddingVertical: 12,
    backgroundColor: "rgba(159, 134, 107, 0.10)",
    borderRadius: 8,
    alignItems: "center",
  },
  textLink: {
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 18.2,
    color: Colors.activeStep,
  },
  textButton: {
    fontSize: 13,
    fontWeight: "600",
    lineHeight: 18.2,
  },
  line: {
    width: "100%",
    height: 1,
    backgroundColor: Colors.borderColor,
  },
  wrapperButton: {
    paddingHorizontal: 16,
    paddingBottom: 30,
    width: "100%",
    marginBottom: 16,
    borderBottomColor: Colors.borderColor,
    borderBottomWidth: 1,
  },
  longText: {
    fontSize: 14,
    marginTop: 16,
    fontWeight: "300",
    lineHeight: 20,
  },
  textRed: {
    paddingVertical: 14,
    fontSize: 16,
    fontWeight: "600",
    lineHeight: 22.4,
    color: Colors.red,
  },
  redArea: {
    marginTop: 4,
    padding: 16,
    marginBottom: 14,
    borderRadius: 4,
    borderWidth: 1,
    borderColor: Colors.red,
    alignItems: "center",
  },
  textRedBow: {
    fontSize: 14,
    fontWeight: "600",
    color: Colors.red,
  },
})

export default OrderDetail
