import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { Platform, StyleSheet, Text, View } from "react-native"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { Colors } from "src/constants/Colors"
import { Screens } from "src/constants/Screens"
import { RootStackScreenProps } from "src/types.d"
import { AccountEditor } from "src/components/parts/AccountEditor"
import { useAccountFormData } from "./useAccountFormData"
import { openPrivacyPolicy } from "src/utils/links/openPrivacyPolicy"
import { getQuery } from "src/storage/query"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { oldRa9Ra9TokenStates } from "src/recoil/atoms/migration/oldRa9Ra9TokenStates"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { PopupCustom } from "./PopupCustom"
import { FROM_SIGN_UP } from "src/utils/const"
import { accountRequestIdState } from "src/recoil/atoms/account/accountRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"
import { CustomAlert } from "src/utils/CustomAlert"
import { Button } from "src/components/parts/buttons/Button"
import { ConfirmModal } from "src/components/parts/Modal"
import { useSignout } from "src/recoil/hooks/authorization/useSignout"
import { completedSignUpFlagState } from "src/recoil/atoms/account/completedSignUpFlagState"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
type Props = RootStackScreenProps<typeof Screens.Root>

export const RegisterAccount = memo<Props>(({ navigation }) => {
  const [visible, setVisible] = useState(false)
  const oldTokenRa9 = useRecoilValue(oldRa9Ra9TokenStates)
  const refreshAccount = useRefresher(accountRequestIdState)
  const isLargeScreen = useCheckPCScreen()
  const setCompletedSignUpState = useSetRecoilState(completedSignUpFlagState)
  const { value, isLoading } = useAsyncSelector(accountSelector)

  const handleClose = () => {
    setVisible(false)
  }
  const goToMigrateTeam = () => {
    navigation.reset({
      index: 0,
      routes: [{ name: Screens.MigrationTeamSelect, params: { from: FROM_SIGN_UP } }],
    })
    setVisible(false)
  }
  const goToHomeScreen = async () => {
    refreshAccount()
    setVisible(false)
  }

  useEffect(() => {
    if (Platform.OS === "web" && value?.signupStatus === "completed") {
      navigation.reset({
        index: 0,
        routes: [
          {
            name: Screens.Root,
            params: {
              screen: RootTabs.OrganizationManagerTab,
              params: { screen: OrganizationManagerScreens.OrganizationManager },
            },
          },
        ],
      })
    }
  }, [value?.signupStatus, navigation])

  const params = useMemo<{ mode: "init"; onSuccess: () => Promise<void> }>(
    () => ({
      mode: "init",
      onSuccess: async () => {
        setCompletedSignUpState(true)
        if (oldTokenRa9) {
          await CustomAlert.alert("完了", "アカウントの登録が完了しました。")
          setVisible(true)
        } else if (await getQuery("code")) {
          navigation.reset({
            index: 0,
            routes: [{ name: Screens.JoinOrganization }],
          })
        } else {
          await CustomAlert.alert("完了", "アカウントの登録が完了しました。")
          refreshAccount()
        }
      },
    }),
    [oldTokenRa9, navigation, refreshAccount, setCompletedSignUpState]
  )
  const {
    firstName,
    lastName,
    kanaFirstName,
    kanaLastName,
    birthDate,
    setFirstName,
    setLastName,
    setKanaFirstName,
    setKanaLastName,
    updateBirthDate,
    email,
    update,
    isRegistering,
    isDisabled,
    gender,
    setGender,
    jobId,
    setJobId,
    jobOptions,
    schoolId,
    setSchoolId,
    schoolOptions,
    schoolDepartmentId,
    setSchoolDepartmentId,
    schoolDepartmentOptions,
    graduationYear,
    setGraduationYear,
    graduationYearOptions,
    prefectureOptions,
    prefectureId,
    setPrefectureId,
    cityId,
    setCityId,
    cityOptions,
    questionAnswers,
    setQuestionAnswers,
    accountQuestions,
    latestTerms,
    openTeamsOfService,
    migrateAccountData,
    firstNameErrorMessage,
    kanaLastNameErrorMessage,
    kanaFirstNameErrorMessage,
    birthdayErrorMessage,
    refreshFormData,
  } = useAccountFormData(params)
  const goToMigrateOrRegister = () => {
    navigation.navigate(Screens.MigrateOrRegisterAccount, { email: email })
  }

  const { signout, isProcessingSignout } = useSignout()
  const [signoutConfirmModal, setSignoutConfirmModal] = useState(false)

  const handleSignOut = async () => {
    setSignoutConfirmModal(false)
    await signout()
  }
  const signOutAction = async () => {
    if (Platform.OS === "web") {
      const isConfirmed = await CustomAlert.confirm("確認", "本当にログアウトしますか？")
      if (isConfirmed) {
        await handleSignOut()
      }
    } else {
      setSignoutConfirmModal(true)
    }
  }

  const gotoSelectAccountSchool = useCallback(() => {
    if (jobId == null) {
      return
    }
    navigation.navigate(Screens.SelectAccountSchool, { jobId })
  }, [jobId, navigation])

  useEffect(() => {
    return () => {
      refreshFormData()
    }
  }, [navigation, refreshFormData])

  if (gender == null || email == null || latestTerms == null || isLoading) {
    return (
      <View style={[styles.fullHeight, styles.centering]}>
        <LoadingIndicator />
      </View>
    )
  }

  return (
    <CustomKeyboardAvoidingView>
      <CustomScrollView>
        <ConfirmModal
          visible={signoutConfirmModal}
          closeAction={() => setSignoutConfirmModal(false)}
          content="本当にログアウトしますか？"
          okAction={handleSignOut}
        />
        <View style={[styles.logoutContainer, isLargeScreen ? styles.oneColumn : {}]}>
          <Text style={[{ fontSize: 14, fontWeight: "600" }]}>{email}</Text>
          <Button onPress={signOutAction}>
            <Text style={[styles.logOutBtn]}>ログアウト</Text>
          </Button>
        </View>
        <View style={[styles.warningContainer, isLargeScreen ? styles.oneColumn : {}]}>
          <Text style={styles.warning}>
            <Text style={{ fontWeight: "300" }}>
              ご本人の情報を登録してください。団体内で表示する個人情報は「団体内個人設定」で登録できます。
            </Text>
          </Text>
        </View>
        <View style={[styles.authContainer, isLargeScreen ? styles.oneColumn : {}]}>
          <Text style={[styles.oldRakuTitle]}>らくらく連絡網をご利用中の方</Text>
          <Text style={[styles.oldRakuSubTitle]}>らくらく連絡網（旧サービス）の個人設定情報を引継いで自動入力できます。</Text>
          <Text style={[styles.oldRakuContent]}>
            らくらく連絡網（旧サービス）に登録した個人設定の情報を引継ぐことで、アカウント情報を入力する手間が省けます。
          </Text>
          <TextButton
            title="個人設定を引継いで自動入力"
            buttonType="Secondary"
            onPress={goToMigrateOrRegister}
            style={[isLargeScreen ? styles.buttonsPC : { width: "100%" }, { alignSelf: "center" }]}
          />
        </View>

        {!oldTokenRa9 ? null : (
          <View
            style={[
              styles.warningContainer,
              { borderBottomWidth: 0, borderTopWidth: 0, paddingBottom: 3 },
              isLargeScreen ? styles.oneColumn : {},
            ]}
          >
            <Text style={styles.warning}>
              <Text style={{ fontWeight: "300" }}>
                {"自動入力が完了しました。\n「お名前」の「名」と「お名前（フリガナ）」の「メイ」を入力してください。"}
              </Text>
            </Text>
          </View>
        )}

        <AccountEditor
          firstName={firstName}
          lastName={lastName}
          kanaFirstName={kanaFirstName}
          kanaLastName={kanaLastName}
          birthDate={birthDate}
          setFirstName={setFirstName}
          setLastName={setLastName}
          setKanaFirstName={setKanaFirstName}
          setKanaLastName={setKanaLastName}
          updateBirthDate={updateBirthDate}
          email={email}
          gender={gender}
          setGender={setGender}
          jobId={jobId}
          setJobId={setJobId}
          jobOptions={jobOptions}
          schoolId={schoolId}
          schoolDepartmentId={schoolDepartmentId}
          schoolOptions={schoolOptions}
          setSchoolId={setSchoolId}
          setSchoolDepartmentId={setSchoolDepartmentId}
          schoolDepartmentOptions={schoolDepartmentOptions}
          graduationYear={graduationYear}
          setGraduationYear={setGraduationYear}
          graduationYearOptions={graduationYearOptions}
          prefectureOptions={prefectureOptions}
          prefectureId={prefectureId}
          setPrefecture={setPrefectureId}
          cityId={cityId}
          setCity={setCityId}
          cityOptions={cityOptions}
          questionAnswers={questionAnswers}
          setQuestionAnswers={setQuestionAnswers}
          accountQuestions={accountQuestions}
          gotoSelectAccountSchool={gotoSelectAccountSchool}
          firstNameErrorMessage={firstNameErrorMessage}
          kanaLastNameErrorMessage={kanaLastNameErrorMessage}
          kanaFirstNameErrorMessage={kanaFirstNameErrorMessage}
          birthdayErrorMessage={birthdayErrorMessage}
        />

        <View style={[styles.actions, isLargeScreen ? { display: "flex", alignItems: "center" } : {}]}>
          <Text style={styles.termsAndPolicy}>
            当サービスの
            <Text onPress={openTeamsOfService} style={styles.link}>
              利用規約
            </Text>
            及び
            <Text onPress={openPrivacyPolicy} style={styles.link}>
              個人情報の取り扱い
            </Text>
            をご確認ください。
          </Text>
          <TextButton
            buttonType={ButtonType.Primary}
            title="同意して登録"
            onPress={update}
            isLoading={isRegistering}
            disabled={isDisabled}
            style={isLargeScreen ? styles.buttonsPC : {}}
          />
        </View>
        <PopupCustom
          visible={visible}
          handleClose={handleClose}
          goToMigrateTeam={goToMigrateTeam}
          goToHomeScreen={goToHomeScreen}
        />
      </CustomScrollView>
    </CustomKeyboardAvoidingView>
  )
})

RegisterAccount.displayName = "RegisterAccount"

export const RegisterAccountOptions: ScreenOptions = () => ({
  ...commonHeaderOptions,
  headerShown: true,
  title: "アカウント情報登録",
  headerLeft: () => null,
})

const styles = StyleSheet.create({
  oneColumn: {
    width: "60%",
    maxWidth: 1020,
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  logoutContainer: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    justifyContent: "space-between",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  warningContainer: {
    padding: 16,
    borderBottomWidth: 1,
    borderColor: Colors.lightGrey,
    borderTopWidth: 1,
  },
  authContainer: {
    paddingHorizontal: 16,
    paddingVertical: 32,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
    display: "flex",
    flexDirection: "column",
  },
  warning: {
    color: Colors.red,
    fontWeight: "400",
    fontSize: 14,
    paddingVertical: 8,
  },
  form: {
    paddingHorizontal: 15,
    paddingBottom: 24,
  },
  input: {
    flex: 1,
    marginTop: 8,
  },
  text: {
    marginTop: 16,
    paddingLeft: 8,
    paddingBottom: 24,
  },
  actions: {
    borderTopWidth: 1,
    borderColor: Colors.white6,
    paddingTop: 24,
    paddingBottom: 12,
    paddingHorizontal: 24,
  },
  itemLabelWrapper: {
    marginTop: 20,
    marginBottom: 4,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginRight: 12,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
  },
  pickerContainer: {
    marginTop: 8,
  },
  radioContainer: {
    flexDirection: "row",
    marginTop: 8,
    marginBottom: 8,
    paddingLeft: 16,
  },
  radioItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 20,
  },
  termsAndPolicy: {
    fontSize: 11,
    fontWeight: "bold",
    paddingBottom: 16,
  },
  link: {
    textDecorationLine: "underline",
  },
  logOutBtn: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    fontSize: 14,
    fontWeight: "600",
    borderRadius: 4,
    backgroundColor: "rgba(159,134,107,0.1)",
    minWidth: 98,
    overflow: "hidden",
  },
  oldRakuTitle: {
    paddingHorizontal: 8,
    paddingVertical: 4,
    backgroundColor: Colors.redBold,
    borderRadius: 4,
    color: Colors.white,
    fontSize: 16,
    fontWeight: "300",
    marginBottom: 16,
    overflow: "hidden",
  },
  oldRakuSubTitle: {
    color: Colors.black2,
    fontSize: 18,
    fontWeight: "600",
    lineHeight: 25.2,
    fontFamily: "Hiragino Kaku Gothic Pro",
    marginBottom: 12,
    fontStyle: "normal",
  },
  oldRakuContent: {
    fontFamily: "Hiragino Kaku Gothic Pro",
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 19.6,
    fontStyle: "normal",
    marginBottom: 24,
  },
  centering: {
    justifyContent: "center",
    alignItems: "center",
  },
  fullHeight: {
    flex: 1,
  },
})
