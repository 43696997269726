import { Result } from "src/utils/Result"
import { confirmInvitationCode } from "src/aws/customAPI"
import { TeamCustomQuestion } from "src/aws/API"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type ValidateInvitationCodeParams = {
  accessToken: string
  accountId: string
  code: string
}

type ValidateInvitationCodeSuccess = {
  organizationId: string
  organizationName: string
  requirementForMemberProfile?: string | null
  customQuestions?: TeamCustomQuestion[] | null
}

type ValidateInvitationCodeFailure = {
  message: string
}

export const validateInvitationCode = async ({ accessToken, accountId, code }: ValidateInvitationCodeParams): Promise<Result<ValidateInvitationCodeSuccess, ValidateInvitationCodeFailure>> => {
  const result = await execApi(
    () => confirmInvitationCode(accessToken, {
      accountId: accountId,
      invitationCode: code,
      appInfo
    }),
    res => res,
    {
      identifiers: ["validateInvitationCode", "confirmInvitationCode"]
    }
  )

  if (!result.isOk) {
    return Result.Error(result.error)
  } else if (result.content.confirmInvitationCode.errorMessage) {
    return Result.Error({ message: result.content.confirmInvitationCode.errorMessage })
  } else {
    const team = result.content.confirmInvitationCode.team
    if (team == null) throw Error("システムエラーが発生しました。")

    return Result.Ok({
      organizationId: team.id,
      organizationName: team.name,
      requirementForMemberProfile: team.requirementForMemberProfile,
      customQuestions: team.customQuestions
    })
  }
}