import { memo, useCallback } from "react"
import { StyleSheet, Text, View } from "react-native"
import { ContactAttendanceAnswerStackScreenProps } from "src/types.d"
import { ContactAttendanceAnswerScreens } from "src/constants/ContactAttendanceAnswerScreens"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { Colors } from "src/constants/Colors"
import { Button } from "src/components/parts/buttons/Button"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { AttendanceType } from "src/constants/AttendanceType"
import { useContactAttendanceAnswerOverviewData } from "src/recoil/hooks/screens/useContactAttendanceAnswerOverviewData"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"

export type ContactAttendanceAnswerOverviewParams = {
  contactId: string
  type: "Inbox" | "Outbox"
}

export const ContactAttendanceAnswerOverview = memo<
  ContactAttendanceAnswerStackScreenProps<typeof ContactAttendanceAnswerScreens.ContactAttendanceAnswerOverview>
>(
  ({
    route: {
      params: { contactId, type },
    },
    navigation,
  }) => {
    const { attendanceAnswerOverview, isLoading } = useContactAttendanceAnswerOverviewData(contactId)
    const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)

    const { allowViewAnswerMemberList } = useCheckAllowAccountPermission({
      contactId,
      viewMode: type,
      organizationId: selectedOrganization?.id,
    })

    const isLargeScreen = useCheckPCScreen()
    const goToContactAttendanceAnswerDetail = useCallback(
      (attendanceType: AttendanceType) => {
        if (allowViewAnswerMemberList) {
          navigation.navigate(ContactAttendanceAnswerScreens.ContactAttendanceAnswerDetail, {
            contactId,
            attendanceType,
          })
        }
      },
      [navigation, contactId, allowViewAnswerMemberList]
    )

    if (isLoading) {
      return (
        <View style={styles.loadingIndicator}>
          <LoadingIndicator />
        </View>
      )
    }

    return (
      <CustomKeyboardAvoidingView style={isLargeScreen ? styles.containerLarge : styles.container}>
        <View style={isLargeScreen ? styles.listContainerLarge : styles.listContainer}>
          <Button style={styles.rowContainer} onPress={() => goToContactAttendanceAnswerDetail(AttendanceType.Attend)}>
            <Text style={styles.rowText}>出席</Text>
            <View style={styles.resultContainer}>
              <Text style={styles.resultText}>{attendanceAnswerOverview.Attend.count}</Text>
              <Text style={styles.resultPercentText}>{attendanceAnswerOverview.Attend.percent}%</Text>
            </View>
          </Button>
          <View style={styles.separator} />
          <Button style={styles.rowContainer} onPress={() => goToContactAttendanceAnswerDetail(AttendanceType.NonAttend)}>
            <Text style={styles.rowText}>欠席</Text>
            <View style={styles.resultContainer}>
              <Text style={styles.resultText}>{attendanceAnswerOverview.NonAttend.count}</Text>
              <Text style={styles.resultPercentText}>{attendanceAnswerOverview.NonAttend.percent}%</Text>
            </View>
          </Button>
          <View style={styles.separator} />
          <Button style={styles.rowContainer} onPress={() => goToContactAttendanceAnswerDetail(AttendanceType.Pend)}>
            <Text style={styles.rowText}>保留</Text>
            <View style={styles.resultContainer}>
              <Text style={styles.resultText}>{attendanceAnswerOverview.Pend.count}</Text>
              <Text style={styles.resultPercentText}>{attendanceAnswerOverview.Pend.percent}%</Text>
            </View>
          </Button>
        </View>
      </CustomKeyboardAvoidingView>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    paddingVertical: 20,
    paddingHorizontal: 15,
  },
  containerLarge: {
    backgroundColor: Colors.white3,
    paddingBottom: 20,
    paddingHorizontal: 15,
  },
  listContainer: {
    borderRadius: 10,
    backgroundColor: Colors.white3,
  },
  listContainerLarge: {
    borderRadius: 10,
    backgroundColor: Colors.white3,
    marginTop: 20,
    width: "60%",
    maxWidth: 1020,
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  rowContainer: {
    paddingVertical: 13,
    paddingHorizontal: 23,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowText: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 35,
    color: Colors.greyshBrown,
  },
  resultContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  resultText: {
    fontSize: 27,
    fontWeight: "bold",
    lineHeight: 35,
    color: Colors.leaf,
  },
  resultPercentText: {
    fontSize: 13,
    lineHeight: 20,
    color: Colors.cement,
    marginLeft: 15,
  },
  separator: {
    marginLeft: 23,
    height: 1,
    backgroundColor: Colors.white2,
  },
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
})
