import { memo, useMemo } from "react"
import { Svg, Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
}

export const BanIcon = memo<Props>(({ fill = Colors.black2 }) => {
  return (
    <Svg width="14.889" height="14.889" viewBox="0 0 14.889 14.889">
      <Path
        d={
          "M7.445 14.889a7.443 7.443 0 1 0-5.265-2.18 7.447 7.447 0 0 0 5.265 2.18zm6-7.445a6 6 0 0 1-9.707 4.721l8.425-8.425a5.974 5.974 0 0 1 1.282 3.7zm-6-6a5.975 5.975 0 0 1 3.7 1.282L2.72 11.151a6 6 0 0 1 4.721-9.707z"
        }
        fill={fill}
      />
    </Svg>
  )
})
