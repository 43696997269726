import { memo, useCallback, useMemo } from "react"
import { EnterAuthorizationCode } from "src/components/parts/authorization/EnterAuthorizationCode"
import { AuthMethod } from "src/constants/AuthMethod"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useAuthPhoneNumberCodeData } from "src/recoil/hooks/screens/useAuthPhoneNumberCodeData"
import { RootStackScreenProps } from "src/types.d"
import { getCodeSentDescription } from "src/utils/getCodeSentDescription"

export type AuthorizationPhoneNumberCodeParams = {
  currentPhoneNumber?: string
  newPhoneNumber: string
}

type Props = RootStackScreenProps<typeof Screens.AuthorizationPhoneNumberCode>

export const AuthorizationPhoneNumberCode = memo<Props>(({ navigation, route }) => {
  const phoneNumber = useMemo(() => route.params.newPhoneNumber, [route.params.newPhoneNumber])
  const currentPhoneNumber = useMemo(() => route.params.currentPhoneNumber, [route.params.currentPhoneNumber])
  const gotoAccountInfo = useCallback(() => navigation.navigate(Screens.AccountInfo), [navigation])

  const { code, setCode, isResendDisabled, isSendingCode, isSending, resendAuthCode, authorize, actionLabel } =
    useAuthPhoneNumberCodeData({
      currentPhoneNumber,
      phoneNumber,
      onSuccess: gotoAccountInfo,
    })

  return (
    <EnterAuthorizationCode
      type="phoneNumber"
      code={code}
      onCodeChange={setCode}
      actionLabel={actionLabel}
      isResendDisabled={isResendDisabled}
      isSending={isSending}
      isSendingCode={isSendingCode}
      resendAuthCode={resendAuthCode}
      next={authorize}
      description={getCodeSentDescription(phoneNumber, AuthMethod.PhoneNumber)}
    />
  )
})

export const options: ScreenOptions = {
  ...commonHeaderOptions,
  title: "認証コード入力",
}
