import { useCallback, useEffect, useMemo, useState } from "react"
import { validateInvitationCode } from "src/apis/organization/validateInvitationCode"
import { TeamCustomQuestion } from "src/aws/API"
import { useFetcher } from "src/hooks/useFetcher"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { CustomAlert } from "src/utils/CustomAlert"
import { getInvitationCodeByVolatileId } from "src/apis/organization/getInvitationCodeByVolatileId"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { existOrganizationMemberPreset } from "src/apis/organization/existOrganizationMemberPreset"
import { createOrganizationMemberFromPreset } from "src/apis/organization/createOrganizationMemberFromPreset"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { deleteQuery, getQuery } from "src/storage/query"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { useAsyncState } from "src/hooks/useAsyncState"
import { listAllMigratedTeamsRequestIdState } from "src/recoil/atoms/organization/listAllMigratedTeamsRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"

type Params = {
  gotoRegisterOrganizationProfile: (
    orgId: string,
    orgName: string,
    requirementForMemberProfile?: string | null,
    customQuestions?: TeamCustomQuestion[] | null
  ) => void
  gotoOrganizationDetail: (id: string) => void
  invitationCode?: string
  migrate: {
    isMigrateFlg?: boolean
    orgId?: string
    name?: string
    role?: string
  }
  openListMigrateOrganization?: () => void
}

export const useJoinOrganizationData = ({
  gotoRegisterOrganizationProfile,
  gotoOrganizationDetail,
  invitationCode,
  migrate,
  openListMigrateOrganization,
}: Params) => {
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const { value: account } = useAsyncSelector(accountSelector)

  const requestDataResult = useRequestData()
  const [code, setCode] = useState("")
  const [stopCallApi, setStopCallApi] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (migrate?.isMigrateFlg && invitationCode) {
        setCode(invitationCode)
      } else if (invitationCode) {
        const volatileId = invitationCode ? invitationCode : (await getQuery("code")) ?? ""
        if (!accessToken || stopCallApi || !volatileId) return
        getInvitationCodeByVolatileId({ accessToken, volatileId }).then(async (res) => {
          await deleteQuery("code")
          setStopCallApi(true)
          if (res.isOk) {
            setCode(res.content.invitationCode)
          } else {
            CustomAlert.alert("エラー", res.error.message)
          }
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken, invitationCode, migrate?.isMigrateFlg])
  const [selectedOrganizationId, setSelectedOrganizationId] = useAsyncState(selectedMyOrganizationState)
  const refreshAllMigratedTeams = useRefresher(listAllMigratedTeamsRequestIdState)
  const { fetch: next, isFetching: isProcessing } = useFetcher(
    useCallback(async () => {
      if (!requestDataResult.isOk || account?.email == null) {
        return
      }
      const email = account.email
      const { accessToken, accountId } = requestDataResult.content
      if (migrate?.isMigrateFlg) {
        const existPreset = await existOrganizationMemberPreset({ accessToken, organizationId: migrate?.orgId || "", email })
        if (existPreset) {
          const res = await createOrganizationMemberFromPreset({ accessToken, organizationId: migrate?.orgId || "", email })
          if (res.isOk) {
            await setSelectedOrganizationId(undefined)
            await refreshAllMigratedTeams()
            await CustomAlert.alert("完了", "団体への参加が完了しました。")
            if (openListMigrateOrganization) {
              openListMigrateOrganization()
            }
            return
          } else {
            CustomAlert.alert("エラー", res.error.message ?? "")
          }
        }
        gotoRegisterOrganizationProfile(migrate?.orgId || "", migrate?.name || "")
      } else {
        const result = await validateInvitationCode({ accessToken, accountId, code })
        if (!result.isOk) {
          await CustomAlert.alert("エラー", result.error.message)
          return
        }
        const { organizationId, organizationName, requirementForMemberProfile, customQuestions } = result.content

        // 旧らくらく連絡網からの移行データがある場合はそのデータを用いて団体参加
        const existPreset = await existOrganizationMemberPreset({ accessToken, organizationId, email })
        if (existPreset) {
          const res = await createOrganizationMemberFromPreset({ accessToken, organizationId, email })
          if (res.isOk) {
            await setSelectedOrganizationId(undefined)
            await refreshAllMigratedTeams()
            gotoOrganizationDetail(organizationId)
            return
          }
        }
        gotoRegisterOrganizationProfile(organizationId, organizationName, requirementForMemberProfile, customQuestions)
      }
    }, [
      refreshAllMigratedTeams,
      gotoRegisterOrganizationProfile,
      gotoOrganizationDetail,
      setSelectedOrganizationId,
      code,
      requestDataResult,
      account?.email,
      migrate,
      openListMigrateOrganization,
    ])
  )

  const isDisabled = useMemo(() => requestDataResult == null || code.length !== 8, [requestDataResult, code])

  return {
    code,
    setCode,
    next,
    isDisabled,
    isProcessing,
  }
}
