import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { OrganizationManagerStackScreenProps } from "src/types.d"
import { useCallback, useEffect, useMemo } from "react"
import { FlatList, ListRenderItemInfo, StyleSheet, View, Text, Platform } from "react-native"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { ContactListEmpty } from "src/components/parts/contactNetworkTab/ContactListEmpty"
import { BannerAd } from "src/tags/ads/BannerAd"
import { adLoadState, isLoadDoneAd } from "src/recoil/atoms/ads/adState"
import { useRecoilState, useRecoilValue } from "recoil"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { MainLayout } from "src/layouts/MainLayout"
import { useOtherMatchingOffers } from "src/recoil/hooks/matching/useOtherMatchingOffers"
import { MatchingOffersModel } from "src/types/matching/matchingOfferModel"
import { MatchingListFooter, MatchingListFooterMargin } from "src/components/parts/matching/MatchingListFooter"
import { SearchButton } from "src/components/parts/buttons/SearchButton"
import { useOfferSearchData } from "src/recoil/hooks/matching/useOfferSearchData"
import { Button } from "src/components/parts/buttons/Button"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { SearchIcon } from "src/components/parts/icons/SearchIcon"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { DrawerStatus } from "src/components/parts/contactNetworkTab/DrawerStatus"
import { MyMatchingCard } from "src/components/parts/matching/MyMatchingCard"
import { Screens } from "src/constants/Screens"
import { useMyMatchingOffers } from "src/recoil/hooks/matching/useMyMatchingOffers"
import { ListActionBackInThread } from "src/constants/OfferDetail"
import { routeNameState } from "src/recoil/atoms/routeNameState"
import { RootTabs } from "src/constants/RootTabs"
import { isEndPublishDatetime } from "src/recoil/hooks/resource/fileManager/common"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useResource } from "src/recoil/hooks/resource/useResource"

export const OtherMatchingOffer = ({
  navigation,
  route,
}: OrganizationManagerStackScreenProps<typeof MatchingDrawers.OtherMatchingOffer>) => {
  const isLargeScreen = useCheckPCScreen()
  const routeName = useRecoilValue(routeNameState)
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const { refreshResourceUrl: refreshTeamImageUrl } = useResource({
    type: "matchingTeamImage",
    teamId: organizationId || "",
    size: "thumbnail",
  })
  const {
    otherOffers,
    loadingOffer,
    offerToken,
    refresh,
    setToken,
    setNextTokens,
    onChangeFavoriteFlg,
    favoriteFlgsCache,
    isLoadingDeleteFavFlg,
    isLoadingCreateFavFlg,
    cacheOfferIdFav,
    organization,
  } = useOtherMatchingOffers()

  const [adLoadStateList] = useRecoilState(adLoadState)
  const { checkCreateNewOffer, resetValueCreateOffer } = useMyMatchingOffers()

  const gotoOfferDetail = useCallback(
    (id: string) => {
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: RootTabs.MatchingDrawer,
          params: {
            screen: MatchingDrawers.MatchingOfferDetail,
            params: {
              id,
            },
          },
        },
      })
    },
    [navigation]
  )

  const ListItem = useCallback(
    (listRenderItemInfo: ListRenderItemInfo<MatchingOffersModel>) => {
      const { item, index } = listRenderItemInfo
      const isLoadingFv = (isLoadingCreateFavFlg || isLoadingDeleteFavFlg) && item.id === cacheOfferIdFav
      if (otherOffers === undefined) {
        return null
      }
      return (
        <View style={styles.contentWrapper}>
          <MyMatchingCard
            data={item}
            to={ListActionBackInThread.otherMatching}
            onPress={() => gotoOfferDetail(item.id)}
            showFavBtn={true}
            handleChangeFav={() =>
              onChangeFavoriteFlg(
                item.id,
                favoriteFlgsCache[item.id] !== undefined ? favoriteFlgsCache[item.id] : item.favoriteFlg
              )
            }
            isLoadingFav={isLoadingFv}
            favoriteFlgsCache={favoriteFlgsCache}
          />
          {Platform.OS !== "web" && (!organization?.paidFunctionEnabled || organization?.showAdsFlg) && index === 2 ? (
            <View style={!isLoadDoneAd("inbox_upper", adLoadStateList, 2) && { height: 0 }}>{BannerAd.InboxUpper_2}</View>
          ) : null}
          {Platform.OS !== "web" && (!organization?.paidFunctionEnabled || organization?.showAdsFlg) && index === 5 ? (
            <View style={!isLoadDoneAd("inbox_bottom", adLoadStateList, 2) && { height: 0 }}>{BannerAd.InboxBottom_2}</View>
          ) : null}
        </View>
      )
    },
    [
      adLoadStateList,
      gotoOfferDetail,
      otherOffers,
      onChangeFavoriteFlg,
      favoriteFlgsCache,
      isLoadingCreateFavFlg,
      isLoadingDeleteFavFlg,
      cacheOfferIdFav,
      organization,
    ]
  )
  const { resetSearchData, searchedItem, setSearchFlg } = useOfferSearchData()
  const onCancel = useCallback(async () => {
    resetSearchData()
    setToken(undefined)
    setNextTokens(undefined)
  }, [resetSearchData, setToken, setNextTokens])

  const gotoCreateNewOffer = async () => {
    resetValueCreateOffer()
    checkCreateNewOffer(() => {
      navigation.navigate(Screens.MatchingOfferCreate)
      onCancel()
    })
  }

  const getMoreContacts = useCallback(() => {
    setNextTokens((prev = undefined) => (offerToken != null ? offerToken : prev))
  }, [offerToken, setNextTokens])

  const openModal = () => {
    setSearchFlg(true)
    setNextTokens(undefined)
    setToken(undefined)
    navigation.navigate(Screens.MatchingOfferSearch)
  }

  useEffect(() => {
    if (routeName === MatchingDrawers.OtherMatchingOffer) {
      refresh()
      refreshTeamImageUrl()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeName])

  return (
    <MainLayout>
      {isLargeScreen && <DrawerStatus Icon={<SearchIcon fillColor={Colors.orange} />} label="募集情報" />}
      <View
        style={{
          paddingBottom: 10,
          borderColor: Colors.white2,
          borderTopWidth: 1,
        }}
      >
        {searchedItem.length > 0 ? (
          <View style={[isLargeScreen ? styles.containerSearchLarge : styles.containerSearch, { backgroundColor: "#ECE8E3" }]}>
            <Button onPress={openModal} style={styles.buttonContainer}>
              <SearchIcon height={16} width={16} />
              <Text
                ellipsizeMode="clip"
                numberOfLines={1}
                style={[styles.showTextSearch, isLargeScreen ? { width: "90%" } : { paddingRight: 12, width: "85%" }]}
              >
                {searchedItem}
              </Text>
            </Button>
            <Button style={styles.buttonClearSearch} onPress={onCancel}>
              <RemoveIcon size={16} />
            </Button>
          </View>
        ) : (
          <View>
            <View style={[styles.topView]}>
              <View style={{ width: "100%" }}>
                <SearchButton onPress={openModal} />
              </View>
              <View style={{ paddingTop: 14 }}></View>
            </View>
          </View>
        )}
      </View>

      <View style={[isLargeScreen ? styles.containerLarge : styles.container]}>
        {loadingOffer ? (
          <View style={styles.loading}>
            <LoadingIndicator />
          </View>
        ) : (
          <FlatList
            data={otherOffers?.filter((el) => !isEndPublishDatetime(el?.publishEndDate ?? ""))}
            renderItem={ListItem}
            ListFooterComponent={MatchingListFooterMargin}
            ListEmptyComponent={!loadingOffer ? <ContactListEmpty label="表示できる募集がありません。" /> : undefined}
            refreshControl={<CustomRefreshControl refreshing={loadingOffer} onRefresh={refresh} />}
            onEndReached={getMoreContacts}
            keyExtractor={(item) => item.id}
          />
        )}
        <MatchingListFooter onPress={gotoCreateNewOffer} />
      </View>
    </MainLayout>
  )
}
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  containerLarge: {
    flex: 1,
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  containerSearch: {
    borderRadius: 16,
    backgroundColor: Colors.white3,
    shadowColor: "#F9D5C1",
    shadowOpacity: 0.38,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 15,
    height: 42,
    justifyContent: "space-between",
    flexDirection: "row",
    padding: 16,
    marginHorizontal: 16,
    alignItems: "center",
    marginVertical: 10,
  },
  containerSearchLarge: {
    borderRadius: 16,
    backgroundColor: Colors.white3,
    shadowColor: "#F9D5C1",
    shadowOpacity: 0.38,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 15,
    width: "95%",
    height: 42,
    display: "flex",
    flexDirection: "row",
    position: "relative",
    padding: 16,
    marginHorizontal: 16,
    marginVertical: 10,
    alignItems: "center",
  },
  searched: {
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: 16,
  },
  topView: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 5,
    paddingHorizontal: 16,
    marginBottom: 15,
    width: "100%",
  },
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.white3,
    height: 50,
  },
  headerTitle: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginLeft: 8,
  },
  searchInput: {
    backgroundColor: Colors.white3,
  },
  contentWrapper: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  headerPC: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: Colors.white3,
    padding: 15,
  },

  showTextSearch: { paddingLeft: 16, marginRight: 32 },
  buttonClearSearch: { position: "absolute", right: 15 },
})
