import { Result } from "src/utils/Result"
import { deleteSentMail } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type DeleteContactParams = {
  accessToken: string
  myMemberId: string
  contactId: string
}

type DeleteContactSuccess = unknown

type DeleteContactFailure = {
  message: string
}

export const deleteContactOutbox = async (params: DeleteContactParams): Promise<Result<DeleteContactSuccess, DeleteContactFailure>> => {
  const {accessToken, myMemberId, contactId} = params

  return execApi(
    () => deleteSentMail(accessToken, {
      input: {
        memberId: myMemberId,
        mailId: contactId
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["deleteContactOutbox", "deleteSentMail"],
      defaultErrorMessage: "送信済み連絡の削除に失敗しました"
    }
  )
}
