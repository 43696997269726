import { RootStackScreenProps } from "src/types.d"
import { Screens } from "src/constants/Screens"
import { memo, useMemo } from "react"
import { Dimensions, StyleSheet, Text, View, Platform } from "react-native"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { Colors } from "src/constants/Colors"
import { CheckWithCircleIcon } from "src/components/parts/icons/CheckWithCircleIcon"
import { BannerAd } from "src/tags/ads/BannerAd"
import { adLoadState, isLoadDoneAd } from "src/recoil/atoms/ads/adState"
import { useRecoilState } from "recoil"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"

export type SubmittedContactParams = {
  label: string
  message?: string
}

type Props = RootStackScreenProps<typeof Screens.SubmittedContact>

export const SubmittedContact = memo<Props>(
  ({
    navigation,
    route: {
      params: { label, message },
    },
  }) => {
    const [adLoadStateList] = useRecoilState(adLoadState)
    const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
    const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
    const { value: organization } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))

    return (
      <CustomKeyboardAvoidingView style={styles.container}>
        <View style={styles.squareContainer} />
        <View style={styles.arcContainer}>
          <View style={styles.arc} />
        </View>
        <View style={styles.main}>
          <View style={styles.checkIconContainer}>
            <CheckWithCircleIcon size={42} />
          </View>
          <Text style={styles.title}>{label}</Text>
          {message ? <Text style={styles.message}>{message}</Text> : null}
        </View>

        {Platform.OS !== "web" && (!organization?.paidFunctionEnabled || organization?.showAdsFlg) && (
          <View style={!isLoadDoneAd("send_complete", adLoadStateList) && { height: 0 }}>{BannerAd.SendComplete}</View>
        )}
      </CustomKeyboardAvoidingView>
    )
  }
)

export const submittedContactOptions: ScreenOptions = {
  ...commonSingleModalOptions,
  headerBackgroundContainerStyle: {
    borderBottomWidth: 0,
  },
  headerStyle: {
    backgroundColor: Colors.white,
  },
  title: "",
}

const circleSize = Dimensions.get("window").width * 3
const halfCircleSize = circleSize / 2

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white3,
    flex: 1,
  },
  squareContainer: {
    backgroundColor: Colors.white,
    width: "100%",
    height: 200,
  },
  arcContainer: {
    backgroundColor: Colors.white3,
    width: "100%",
    height: 250,
    overflow: "hidden",
    alignItems: "center",
    flex: 1,
  },
  arc: {
    position: "absolute",
    top: -circleSize + 250,
    backgroundColor: Colors.white,
    width: circleSize,
    height: circleSize,
    borderRadius: halfCircleSize,
  },
  main: {
    flex: 1,
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    alignItems: "center",
  },
  checkIconContainer: {
    marginTop: 100,
  },
  title: {
    marginTop: 24,
    fontSize: 24,
    fontWeight: "bold",
    color: Colors.black2,
  },
  message: {
    marginTop: 24,
    paddingHorizontal: 24,
    fontSize: 14,
    color: Colors.black2,
  },
  closeButtonContainer: {
    marginTop: 60,
    backgroundColor: "#F1EDE7",
    paddingVertical: 12,
    paddingHorizontal: 32,
    borderRadius: 8,
  },
  closeButtonText: {
    fontSize: 14,
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
})
