import { Result } from "src/utils/Result"
import { deleteTemplate } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type DeleteContactTemplateParams = {
  accessToken: string
  templateId: string
}

type DeleteContactTemplateSuccess = unknown

type DeleteContactTemplateFailure = {
  message: string
}

export const deleteContactTemplate = async (params: DeleteContactTemplateParams): Promise<Result<DeleteContactTemplateSuccess, DeleteContactTemplateFailure>> => {
  const {accessToken, templateId} = params
  return execApi(
    () => deleteTemplate(accessToken, {
      input: {
        id: templateId
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["deleteContactTemplate", "deleteTemplate"],
      defaultErrorMessage: "テンプレートの削除に失敗しました"
    }
  )
}
