import { selectorFamily } from "recoil"
import { getTemplate } from "src/apis/contact/getTemplate"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import type { TemplateModel } from "src/types/contact/TemplateModel"

export const templateSelectorFamily = selectorFamily<TemplateModel | undefined, TemplateModel["id"]>({
  key: "selectors/contact/templateSelectorFamily",
  get:
    (templateId) =>
    async ({ get }) => {
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) {
        return
      }
      const template = await getTemplate({ accessToken, templateId })
      if (!template.isOk) {
        return
      }
      return template.content
    },
})
