import { initializeApp } from "firebase/app"
import { getAnalytics, logEvent, setUserId } from "firebase/analytics"
import Constants from "expo-constants"
import * as firebaseConfigDev from "../../../firebase/dev/firebase"
import * as firebaseConfigStg from "../../../firebase/stg/firebase"
import * as firebaseConfigPrd from "../../../firebase/prd/firebase"

// https://rnfirebase.io/analytics/usage
export class AnalyticsWeb {
  isEnv

  webAnalytics

  constructor() {
    this.isEnv = Constants.expoConfig?.extra?.env ?? "dev"
    const config = this.isEnv == "prd" ? firebaseConfigPrd : this.isEnv == "stg" ? firebaseConfigStg : firebaseConfigDev

    const app = initializeApp(config.firebaseConfig)
    this.webAnalytics = getAnalytics(app)
  }

  setUser(uid) {
    setUserId(this.webAnalytics, uid)
  }

  async logScreenView(currentRouteName) {
    logEvent(this.webAnalytics, "screen_view", {
      firebase_screen: currentRouteName,
      firebase_screen_class: currentRouteName,
    })
  }

  async sendAnalyticsEventAsync(eventName, type, id, team_id) {
    await logEvent(this.webAnalytics, eventName, {
      content_type: type,
      content_id: id,
      team_id: team_id,
    })
  }
}

export const analyticsWrap = new AnalyticsWeb()
