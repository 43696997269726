import { memo, useCallback, useState } from "react"
import { StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { RootStackScreenProps } from "src/types.d"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { useInquiryData } from "src/recoil/hooks/screens/useInquiryData"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { Button } from "src/components/parts/buttons/Button"
import { Checkbox } from "src/components/parts/Checkbox"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import * as WebBrowser from "expo-web-browser"
import { deliveryMailReceiveSetting } from "src/constants/links/deliveryMailReceiveSetting"
import { privacypolicyURL } from "src/constants/links/privacypolicy"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type InquiryParams = undefined

type Props = RootStackScreenProps<typeof Screens.Inquiry>

export const Inquiry = memo<Props>(({ navigation }) => {
  const {
    email,
    isEmailLoading,
    myOrganizationsOption,
    isMyOrganizationsLoading,
    selectedOrganization,
    setSelectedOrganization,
    content,
    setContent,
    deviceInfoOptions,
    deviceInfo,
    setDeviceInfo,
  } = useInquiryData()
  const [isAgreed, setIsAgreed] = useState(false)
  const toggleIsAgreed = useCallback(() => setIsAgreed((prev) => !prev), [])
  const openPrivacyPolicy = useCallback(async () => {
    await WebBrowser.openBrowserAsync(privacypolicyURL)
  }, [])
  const openMailSetting = useCallback(async () => {
    await WebBrowser.openBrowserAsync(deliveryMailReceiveSetting)
  }, [])
  const gotoInquiryConfirm = useCallback(() => {
    navigation.navigate(Screens.InquiryConfirm)
  }, [navigation])
  const isLargeScreen = useCheckPCScreen()
  if ((email != null && isEmailLoading) || (myOrganizationsOption != null && isMyOrganizationsLoading)) {
    return (
      <View style={styles.loadingIndicator}>
        <LoadingIndicator />
      </View>
    )
  }

  return (
    <CustomKeyboardAvoidingView style={isLargeScreen ? styles.centerLayout : {}}>
      <CustomScrollView style={[styles.container, isLargeScreen ? styles.oneColumn : {}]}>
        <View style={styles.halfTopContainer}>
          <Text style={styles.descriptionText}>ログインしている下記メールアドレス宛にご返答させていただきます。</Text>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="ログイン中のメールアドレス" />
          </View>
          <CustomTextInput style={styles.customTextInput} value={email} editable={false} />
          <View style={styles.itemLabelWrapper}>
            <ItemLabel
              label="お問い合わせ内容"
              RightComponent={<InputLengthCounter text={content} maxLength={1000} unit={"字以内"} />}
            />
          </View>
          <MultilineTextInput
            style={[styles.multilineTextInput, { height: 240 }]}
            value={content ?? ""}
            onChangeText={setContent}
            placeholder={"お問い合わせ内容を記載ください"}
            maxLength={1000}
          />
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="団体名" />
          </View>
          <View style={styles.customSelectWrapper}>
            <CustomSelect value={selectedOrganization} options={myOrganizationsOption} onChange={setSelectedOrganization} />
          </View>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="ご利用環境" />
          </View>
          <View style={styles.customSelectWrapper}>
            <CustomSelect value={deviceInfo} options={deviceInfoOptions} onChange={setDeviceInfo} />
          </View>
        </View>
        <View style={styles.halfBottomContainer}>
          <Text style={styles.cautionText}>
            ※お問い合わせの際は、「個人情報の取り扱いについて」をご一読いただき、同意した上でフォームを送信ください。
          </Text>
          <Button style={styles.privacyButtonContainer} onPress={openPrivacyPolicy}>
            <Text style={styles.privacyButtonText}>個人情報の取り扱いについて</Text>
          </Button>
          <View style={styles.checkboxContainer}>
            <Button style={styles.checkboxInner} onPress={toggleIsAgreed}>
              <Checkbox isChecked={isAgreed} onPress={toggleIsAgreed} />
              <Text style={styles.checkboxLabel}>上記の項目に同意する</Text>
            </Button>
          </View>
          <View style={[styles.textButtonContainer, isLargeScreen ? styles.centerLayout : {}]}>
            <TextButton
              title="キャンセル"
              buttonType={ButtonType.Secondary}
              onPress={navigation.goBack}
              style={isLargeScreen ? styles.buttonsPC : {}}
            />
          </View>
          <View style={[styles.textButtonContainer, isLargeScreen ? styles.centerLayout : {}]}>
            <TextButton
              title="確認"
              buttonType={ButtonType.Primary}
              onPress={gotoInquiryConfirm}
              disabled={!isAgreed}
              style={isLargeScreen ? styles.buttonsPC : {}}
            />
          </View>
          <View style={styles.bottomDescriptionContainer}>
            <Text style={styles.descriptionText}>
              ・お問い合わせ状況によっては、回答に数日かかる場合がございます。尚、調査が必要となるお問い合わせにつきましては、更にお日にちを頂戴する場合もございますので、予めご了承をお願いいたします。
              {`\n`}
              ・迷惑メールの設定をされている方へ
              {`\n`}
              らくらく連絡網＋からの返信メールが届くように必ず「@ra9plus.jp」からのメールを受信許可する設定をお願いいたします。
              {`\n`}
              設定方法は
              <Button onPress={openMailSetting}>
                <Text style={styles.textColorOrange}>こちら</Text>
              </Button>
              をご確認ください。
            </Text>
          </View>
        </View>
      </CustomScrollView>
    </CustomKeyboardAvoidingView>
  )
})

export const inquiryOptions: ScreenOptions = {
  ...commonHeaderOptions,
  title: "お問い合わせ",
}

const styles = StyleSheet.create({
  centerLayout: {
    display: "flex",
    alignItems: "center",
  },
  container: {
    flex: 1,
  },
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  halfTopContainer: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  descriptionText: {
    fontSize: 14,
    lineHeight: 24,
    color: Colors.black2,
  },
  itemLabelWrapper: {
    marginTop: 24,
  },
  customTextInput: {
    marginTop: 10,
  },
  multilineTextInput: {
    marginTop: 10,
  },
  customSelectWrapper: {
    marginTop: 10,
  },
  halfBottomContainer: {
    marginTop: 32,
    paddingVertical: 24,
    paddingHorizontal: 16,
    flex: 1,
  },
  cautionText: {
    fontSize: 14,
    lineHeight: 24,
    color: Colors.red,
  },
  privacyButtonContainer: {
    marginTop: 24,
    paddingVertical: 10,
    paddingHorizontal: 41,
    backgroundColor: "#F1EDE8",
    borderRadius: 6,
    alignSelf: "center",
  },
  privacyButtonText: {
    fontSize: 14,
    fontWeight: "700",
    lineHeight: 20,
    color: Colors.orange,
  },
  checkboxContainer: {
    marginTop: 24,
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  checkboxInner: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
  },
  checkboxLabel: {
    fontSize: 16,
    fontWeight: "700",
    lineHeight: 24,
    marginLeft: 8,
  },
  textButtonContainer: {
    marginTop: 24,
  },
  bottomDescriptionContainer: {
    marginTop: 32,
  },
  textColorOrange: {
    color: Colors.orange,
  },
  oneColumn: {
    width: 600,
    maxWidth: 1020,
    paddingLeft: 30,
    paddingRight: 30,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
})
