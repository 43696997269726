import { memo, useEffect, useMemo, useState } from "react"
import { Platform, StyleSheet, View } from "react-native"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { RootStackScreenProps } from "src/types.d"
import { ConfirmDialog } from "react-native-simple-dialogs"
import { DynamicLink } from "src/utils/dynamicLink/common/base"
import { IDlApiConfig, IDlAppConfig } from "src/utils/dynamicLink/common/types"
import Constants from "expo-constants"
import { crashlyticsWrap } from "src/tags/crashlytics"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type InvitationDialogParams = {
  code: string
}

type Props = RootStackScreenProps<typeof Screens.InvitationDialog>

export const InvitationDialog = ({ navigation, route }: Props) => {
  const { code } = useMemo(() => route.params, [route.params])
  const [visible, setVisible] = useState(true)
  const isPCScreen = useCheckPCScreen()

  useEffect(() => {
    if (Platform.OS === "ios" || Platform.OS === "android" || isPCScreen) {
      setVisible(false)
      navigation.navigate("JoinOrganization", {
        code: code,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleApp = async () => {
    const apiConfig: IDlApiConfig = {
      apiUrl: "https://firebasedynamiclinks.googleapis.com/v1/shortLinks",
      apiKey: Constants.expoConfig?.extra?.dynamicLinkApiKey,
    }

    const appConfig: IDlAppConfig = {
      dynamicDomain: `https://${Constants.expoConfig?.extra?.dynamicLinkDomain}` ?? "",
      deepLinkDomain: `https://${Constants.expoConfig?.extra?.deepLinkDomain}/dlink` ?? "",
      androidPackageName: Constants.expoConfig?.extra?.androidPackage ?? "",
      iosBundleId: Constants.expoConfig?.extra?.iosBundleIdentifier ?? "",
      iosAppStoreId: Constants.expoConfig?.extra?.iosStoreId ?? "",
    }

    try {
      const dynamicLink = new DynamicLink(apiConfig, appConfig)
      dynamicLink.setLink("/org/join", { code })
      const link = await dynamicLink.getDynamicLink()
      window.location.href = link.shortLink
    } catch (e) {
      crashlyticsWrap.recordError(e, "InvitationDialog")
      navigation.navigate("JoinOrganization", {
        code: code,
      })
      setVisible(false)
    }
  }

  const handleWeb = () => {
    navigation.navigate("JoinOrganization", {
      code: code,
    })
    setVisible(false)
  }

  return (
    <View style={styles.container}>
      {!isPCScreen && (
        <ConfirmDialog
          title="らくらく連絡網+"
          message="スマホアプリで開きますか？"
          visible={visible}
          positiveButton={{ title: "はい", onPress: () => handleApp() }}
          negativeButton={{ title: "いいえ", onPress: () => handleWeb() }}
        />
      )}
    </View>
  )
}

export const options: ScreenOptions = {
  ...commonSingleModalOptions,
  title: "アプリ選択",
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
})
