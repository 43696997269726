import { memo } from "react"
import { Svg, Path, Circle } from "react-native-svg"

export const AccountIcon = memo(() => (
  <Svg width="15.311" height="19.415" viewBox="0 0 15.311 19.415">
    <Circle cx="4.512" cy="4.512" r="4.512" transform="translate(3.143)" fill={"#f2caa0"} />
    <Path
      d="M1 19.278v-2.654A6.142 6.142 0 0 1 7.124 10.5h3.062a6.142 6.142 0 0 1 6.124 6.124v2.654"
      transform="translate(-1 .137)"
      fill={"#f2caa0"}
    />
  </Svg>
))
