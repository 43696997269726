import { FC } from "react"
import Svg, { Path } from "react-native-svg"

export const SendMessageIcon: FC = () => (
  <Svg width="24.089" height="24.088" viewBox="0 0 24.089 24.088">
    <Path
      d="M.724 0a.753.753 0 0 0-.7.946l2.509 9.4 11.075 1.695-11.074 1.696-2.51 9.4a.753.753 0 0 0 1.066.868l22.583-11.288a.754.754 0 0 0 0-1.346L1.09.08A.76.76 0 0 0 .724 0z"
      transform="translate(.002 -.001)"
      fill="#f0830e"
    />
  </Svg>
)

SendMessageIcon.displayName = "SendMessageIcon"
