import { Result } from "src/utils/Result"
import { GqlError, createMatchingApplication as _createMatchingApplication } from "src/aws/customAPI"

type CreateMatchingApplicationParams = {
  accessToken: string
  applicantTeamId: string,
  matchingOfferId: string,
  firstMessage: string,
}

export type CreateMatchingApplicationSuccess = {
  applicationId: string
}

type CreateMatchingApplicationFailure = {
  errorCode?: number
  message: string
}

export const createMatchingApplication = async (params: CreateMatchingApplicationParams): Promise<Result<CreateMatchingApplicationSuccess, CreateMatchingApplicationFailure>> => {
  const {accessToken, applicantTeamId, matchingOfferId, firstMessage} = params
  return await _createMatchingApplication(accessToken, {
    input: {
      applicantTeamId,
      matchingOfferId,
      firstMessage,
    }
  }).then(res => {
    const applicationId = res.data?.createMatchingApplication.id
    if (applicationId) {
      return Result.Ok<CreateMatchingApplicationSuccess>({
        applicationId: applicationId,
      })
    } else {
      return Result.Error<CreateMatchingApplicationFailure>({
        // 到達しない
        message: "応募の登録に失敗しました。",
      })
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || '応募の登録に失敗しました'
    return Result.Error<CreateMatchingApplicationFailure>({
      errorCode: e.errors && e.errors[0].errorInfo?.code,
      message: message
    })
  })
}