import { memo, useCallback } from "react"
import { RootStackScreenProps } from "src/types.d"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { Platform, StyleSheet, Text, View, useWindowDimensions } from "react-native"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { Button } from "src/components/parts/buttons/Button"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CreateOrEditOrganizationScreens } from "src/constants/CreateOrEditOrganizationScreens"
import { useDeleteOrganizationData } from "src/recoil/hooks/screens/useDeleteOrganizationData"
import { RootTabs } from "src/constants/RootTabs"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type DeleteOrganizationParams = {
  id: string
  name: string
}

type Props = RootStackScreenProps<typeof Screens.DeleteOrganization>

export const DeleteOrganization = memo<Props>(
  ({
    navigation,
    route: {
      params: { name, id },
    },
  }) => {
    const isLargeScreen = useCheckPCScreen()
    const gotoOrganizations = useCallback(
      () =>
        navigation.navigate(Screens.Root, {
          screen: RootTabs.OrganizationManagerTab,
          params: { screen: OrganizationManagerScreens.OrganizationManager },
        }),
      [navigation]
    )

    const { organizationDetail, reasonText, setReasonText, sendDeleteOrganization, isDeleteOrganizationSending } =
      useDeleteOrganizationData({
        id,
        gotoOrganizations,
      })

    const goBack = useCallback(() => {
      navigation.goBack()
    }, [navigation])

    const goToEditOrganizationDetail = useCallback(() => {
      if (organizationDetail == null) {
        return
      }
      navigation.navigate(Screens.EditOrganizationStack, {
        screen: CreateOrEditOrganizationScreens.CreateOrEditOrganization,
        params: organizationDetail,
      })
    }, [navigation, organizationDetail])

    const goToOrganizationMembers = useCallback(() => {
      navigation.navigate(Screens.OrganizationMembers, { organizationId: id })
    }, [navigation, id])

    const goToOrganizationMembersPC = useCallback(() => {
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.OrganizationMembers,
          params: { organizationId: id },
        },
      })
    }, [navigation, id])

    const goToOrganizationDetail = useCallback(() => {
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.OrganizationDetail,
          params: { organizationId: id },
        },
      })
    }, [navigation, id])

    return (
      <CustomKeyboardAvoidingView style={styles.container}>
        {organizationDetail != null ? (
          <CustomScrollView>
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <View style={isLargeScreen ? { width: 600 } : { width: "100%" }}>
                <View style={isLargeScreen ? {} : styles.backgroundColorWhite3}>
                  <View style={styles.sectionContainer}>
                    <ItemLabel label="団体解約" />
                    <View style={styles.descriptionContainer}>
                      <Text style={styles.normalText}>この団体を解約します。</Text>
                    </View>
                  </View>
                  <View style={styles.separator} />
                  <View style={styles.onlyTitleSectionContainer}>
                    <Text style={styles.titleRedText}>解約の前にちょっと確認！</Text>
                  </View>
                  <View style={styles.separator} />
                  <View style={styles.sectionContainer}>
                    <Text style={styles.titleText}>団体を再利用しませんか？</Text>
                    <View style={styles.descriptionContainer}>
                      <Text style={styles.normalText}>
                        新たに団体を作って再度メンバー登録するのは面倒。
                        今の団体を解約せず、団体名や所属メンバーの整理を行い、再利用してみては？
                      </Text>
                    </View>
                    <Button onPress={goToEditOrganizationDetail}>
                      <Text style={styles.orangeUnderlineText}>団体名や設定を変更</Text>
                    </Button>
                    <Button onPress={isLargeScreen ? goToOrganizationMembersPC : goToOrganizationMembers}>
                      <Text style={styles.orangeUnderlineText}>所属メンバー一覧</Text>
                    </Button>
                  </View>
                  <View style={styles.separator} />
                  <View style={styles.sectionContainer}>
                    <Text style={styles.titleText}>団体を解散せず、「同窓会」団体として残しておこう！</Text>
                    <View style={styles.descriptionContainer}>
                      <Text style={styles.normalText}>
                        活動や役割が終了した後、「あの時一緒だったメンバーで久しぶりに集まろう」と思った時に、団体が残っていると直ぐにメンバーと連絡が取れます。
                        折角作った団体は残して同窓会団体として残しておきましょう。
                      </Text>
                    </View>
                    <Button onPress={goToOrganizationDetail}>
                      <Text style={styles.orangeUnderlineText}>{name}TOPへ戻る</Text>
                    </Button>
                  </View>
                </View>
                <View style={styles.backgroundColorWhite}>
                  <View style={styles.separator} />
                  <View style={styles.onlyTitleSectionContainer}>
                    <Text style={styles.titleText}>解約の理由</Text>
                  </View>
                  <View style={styles.separator} />
                  <View style={styles.deleteReasonSectionContainer}>
                    <View style={styles.descriptionContainer}>
                      <Text style={styles.normalText}>
                        今後のよりよいサイト作りに役立てたいと思いますので、よろしければ解約の理由を詳しくご記入ください。
                        また、ご意見・ご要望等ございましたら、ご記入お願いいたします。
                      </Text>
                    </View>
                    <View style={styles.inputLengthCountContainer}>
                      <InputLengthCounter text={reasonText} maxLength={500} unit={"字以内"} />
                    </View>
                    <View style={styles.multipleLineTextInputWrapper}>
                      <MultilineTextInput
                        value={reasonText}
                        onChangeText={setReasonText}
                        placeholder="入力してください"
                        maxLength={500}
                        style={{ height: 240 }}
                      />
                    </View>
                  </View>
                  <View style={styles.separator} />
                  <View style={styles.sectionContainer}>
                    <Text style={styles.titleRedText}>解約に関するご注意！</Text>
                    <View style={styles.descriptionContainer}>
                      <Text style={styles.normalText}>
                        解約する場合は、団体そのものを削除する事になり、団体メンバー全員が団体から削除となりますのでご注意下さい。
                        解約しないときは「キャンセル」を押してください。
                      </Text>
                    </View>
                  </View>
                  <View style={styles.separator} />
                  <View style={[styles.footerContainer, isLargeScreen ? { flexDirection: "row" } : {}]}>
                    <View style={[styles.footerButtonWrapper, isLargeScreen ? styles.buttonContainer : {}]}>
                      <TextButton
                        style={isLargeScreen && { width: "100%" }}
                        buttonType={ButtonType.Secondary}
                        title="キャンセル"
                        onPress={goBack}
                      />
                    </View>
                    <View style={[styles.footerButtonWrapper, isLargeScreen ? styles.buttonContainer : {}]}>
                      <TextButton
                        buttonType={ButtonType.Primary}
                        title="解約"
                        style={isLargeScreen && { width: "100%" }}
                        onPress={sendDeleteOrganization}
                        isLoading={isDeleteOrganizationSending}
                        disabled={isDeleteOrganizationSending}
                      />
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </CustomScrollView>
        ) : (
          <View style={styles.loading}>
            <LoadingIndicator />
          </View>
        )}
      </CustomKeyboardAvoidingView>
    )
  }
)

export const deleteOrganizationOptions: ScreenOptions = {
  ...commonHeaderOptions,
  title: "団体解約",
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
  },
  sectionContainer: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  backgroundColorWhite3: {
    backgroundColor: Colors.white3,
  },
  backgroundColorWhite: {
    backgroundColor: Colors.white,
  },
  normalText: {
    fontSize: 14,
    lineHeight: 24,
    color: Colors.black2,
  },
  descriptionContainer: {
    marginTop: 16,
  },
  separator: {
    width: "100%",
    height: 1,
    backgroundColor: Colors.lightGrey,
  },
  titleText: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 24,
    color: Colors.black2,
  },
  titleRedText: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 24,
    color: Colors.red,
  },
  onlyTitleSectionContainer: {
    padding: 16,
  },
  orangeUnderlineText: {
    fontSize: 14,
    lineHeight: 32,
    color: Colors.orange,
    textDecorationLine: "underline",
    textDecorationColor: Colors.orange,
  },
  deleteReasonSectionContainer: {
    padding: 16,
  },
  inputLengthCountContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  multipleLineTextInputWrapper: {
    marginTop: 16,
  },
  footerContainer: {
    paddingBottom: 43,
    paddingHorizontal: 25,
  },
  footerButtonWrapper: {
    marginTop: 24,
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    flexDirection: "row",
  },
  button: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
  },
})
