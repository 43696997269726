import { Result } from "src/utils/Result"
import { updateAuthMethod } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"

type UpdateEmailParams = {
  accessToken: string
  email: string
}

type UpdateEmailSuccessResponse = {}

type UpdateEmailFailureResponse = {
  message: string
}

export const updateEmail = (_params: UpdateEmailParams): Promise<Result<UpdateEmailSuccessResponse, UpdateEmailFailureResponse>> => {
  return execApi(
    () => updateAuthMethod(_params.accessToken,{
      input: {
        email: _params.email
      }
    }),
    _ => ({}),
    {
      identifiers: ["updateEmail", "updateAuthMethod"],
    }
  )
}
