import { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { selectedMemberIdsState } from "src/recoil/atoms/contact/create/selectedMemberIdsState"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { OrganizationMemberOverview } from "src/types/organization/OrganizationMember"
import { isReceiversAllMembersState } from "src/recoil/atoms/contact/create/isReceiversAllMembersState"
import {
  TeamMemberTypeResponse,
  organizationMembersPaginationSelectorFamily,
} from "src/recoil/selectors/organization/organizationMembersPaginationSelectorFamily"
import { useRefresher } from "src/hooks/useRefresher"
import { organizationMembersRequestIdState } from "src/recoil/atoms/organization/organizationMembersRequestIdState"
type Params = {
  goToCreateNewContactScreen: () => void
  isLargeScreen: boolean
}

const limitPageSize = 10

export const useSelectMembersScreenData = ({ goToCreateNewContactScreen }: Params) => {
  const { value: selectedMyOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  if (selectedMyOrganization == null) {
    throw new Error("Select organization first before using useSelectMembersScreenData.")
  }

  const [searchText, setSearchText] = useState("")
  const [showSearchText, setShowSearchText] = useState<string>()
  const [currentPage, setCurrentPage] = useState(1)
  const [members, setMembers] = useState<TeamMemberTypeResponse[]>([])
  const refreshOrgMembers = useRefresher(organizationMembersRequestIdState)

  const searchMemberWithName = useCallback(
    (value?: string) => {
      if (showSearchText !== undefined) {
        setMembers([])
        setSearchText(value ?? "")
        setCurrentPage(1)
      }
    },
    [showSearchText]
  )

  useEffect(() => {
    const handler = setTimeout(() => {
      searchMemberWithName(showSearchText)
    }, 500)
    return () => {
      clearTimeout(handler)
    }
  }, [showSearchText, searchMemberWithName])

  const { value: membersValue, isLoading: isLoadingMoreMembers } = useAsyncSelector(
    organizationMembersPaginationSelectorFamily({
      organizationId: selectedMyOrganization.id,
      getImage: true,
      page: currentPage,
      limit: limitPageSize,
      name: searchText,
      excludedIds: [],
      myGroup: true,
    })
  )

  useEffect(() => {
    return () => {
      refreshOrgMembers()
    }
  }, [refreshOrgMembers])

  const getMoreTeamMembers = useCallback(() => {
    if (members.length < (membersValue?.total ?? 0) && !isLoadingMoreMembers) {
      setCurrentPage((preState) => preState + 1)
    }
  }, [members, membersValue, isLoadingMoreMembers])

  const handleChangeTextSearch = (value: string) => {
    setShowSearchText(value)
  }

  useEffect(() => {
    if (!!membersValue && !!membersValue?.items) {
      setMembers((preState) => [...preState, ...membersValue.items])
    }
  }, [membersValue])

  const [selectedMemberIds, setSelectedMemberIds] = useRecoilState(selectedMemberIdsState)
  const [localSelectedMemberIds, setLocalSelectedMemberIds] = useState(selectedMemberIds)
  const setIsReceiversAllMembers = useSetRecoilState(isReceiversAllMembersState)
  const toggleMember = useCallback((memberId: OrganizationMemberOverview["id"]) => {
    setLocalSelectedMemberIds((prev) => {
      if (prev == null) {
        return [memberId]
      }
      if (prev.includes(memberId)) {
        return prev.filter((id) => id !== memberId)
      }
      return [...prev, memberId]
    })
  }, [])
  const filteredMembers = useMemo(() => {
    if (!members) {
      return
    }

    return members.map((member) => ({
      ...member,
      isChecked: localSelectedMemberIds?.includes(member.id),
    }))
  }, [members, localSelectedMemberIds])
  const selectMembers = useCallback(() => {
    if (localSelectedMemberIds == null) {
      return
    }
    setIsReceiversAllMembers(undefined)
    setSelectedMemberIds(localSelectedMemberIds)
    goToCreateNewContactScreen()
  }, [localSelectedMemberIds, goToCreateNewContactScreen, setSelectedMemberIds, setIsReceiversAllMembers])

  return {
    showSearchText,
    handleChangeTextSearch,
    filteredMembers,
    toggleMember,
    selectMembers,
    getMoreTeamMembers,
    isLoadingMoreMembers,
    limitPageSize,
  }
}
