import { memo, useCallback, useMemo, useState } from "react"
import { StyleSheet, Text, View } from "react-native"
import { ExpiryBadge } from "src/components/parts/authorization/ExpiryBadge"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptionsOnNestedScreen } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { useEditEmail } from "src/hooks/useEditEmail"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { RootStackScreenProps } from "src/types.d"
import { containsUppercase, validateEmail } from "src/utils/validateEmail"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type EditEmailParams = {
  currentEmail: string
}

const caveats = `（注意）入力していただくアドレスは、現在メールを受け取れる状態になっている必要があります。
これからメールアドレスを変える予定の方は、新しいメールアドレスが有効になりましたら登録変更をしてください。`

const warning = `同じアドレスで利用している全ての団体で、登録アドレスが一括変更となります。`

type Props = RootStackScreenProps<typeof Screens.EditEmail>

export const EditEmail = memo<Props>(
  ({
    navigation,
    route: {
      params: { currentEmail },
    },
  }) => {
    const [newEmail, setNewEmail] = useState("")
    const { isSending, updateEmail } = useEditEmail(newEmail)
    const isValid = useMemo(() => validateEmail(newEmail), [newEmail])
    const isContainsUpperCase = useMemo(() => containsUppercase(newEmail), [newEmail])
    const isLargeScreen = useCheckPCScreen()
    const action = useCallback(() => {
      updateEmail((email) => navigation.navigate(Screens.UpdateCode, { email }))
    }, [updateEmail, navigation])
    const isDisabled = useMemo(
      () => isSending || newEmail === "" || newEmail === currentEmail,
      [isSending, newEmail, currentEmail]
    )

    return (
      <CustomKeyboardAvoidingView style={styles.container}>
        <CustomScrollView>
          <View style={isLargeScreen ? styles.oneColumn : {}}>
            <View style={[styles.row, isLargeScreen && styles.inputPC]}>
              <ItemLabel label="現在の登録メールアドレス" />
              <Text style={styles.email}>{currentEmail}</Text>
            </View>
            <View style={[styles.row, isLargeScreen && styles.inputPC]}>
              <Text style={styles.caveats}>{caveats}</Text>
              <View>
                <View style={[styles.itemLabelWrapper]}>
                  <ItemLabel
                    label="新しいメールアドレス"
                    RightComponent={<InputLengthCounter text={newEmail} maxLength={255} unit={"字以内"} />}
                  />
                </View>
                <CustomTextInput
                  style={styles.input}
                  value={newEmail}
                  onChangeText={setNewEmail}
                  keyboardType="email-address"
                  autoCapitalize="none"
                  textContentType="emailAddress"
                  placeholder="入力してください"
                  editable={!isSending}
                  maxLength={255}
                />
                {isContainsUpperCase && (
                  <Text style={{ marginTop: 10, color: Colors.red }}>メールアドレスに大文字が含まれています</Text>
                )}
              </View>
            </View>
            <View style={[styles.buttonWrapper, isLargeScreen && styles.inputPC]}>
              <Text style={styles.warning}>{warning}</Text>
              <TextButton
                style={isLargeScreen ? styles.buttonsPC : {}}
                buttonType={ButtonType.Primary}
                onPress={action}
                title="認証コードを送信"
                disabled={isDisabled || !isValid || isContainsUpperCase}
                isLoading={isSending}
              />
            </View>
            <View style={styles.badgeContainer}>
              <ExpiryBadge />
            </View>
          </View>
        </CustomScrollView>
      </CustomKeyboardAvoidingView>
    )
  }
)

export const options: ScreenOptions = {
  ...commonHeaderOptionsOnNestedScreen,
  title: "メールアドレス変更",
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
  },
  row: {
    borderBottomColor: Colors.lightGrey,
    borderBottomWidth: 1,
    paddingVertical: 24,
    paddingHorizontal: 24,
  },
  email: {
    fontSize: 16,
    paddingTop: 16,
  },
  input: {
    marginTop: 8,
  },
  buttonWrapper: {
    paddingHorizontal: 24,
    marginTop: 32,
    alignItems: "center",
  },
  caveats: {
    fontSize: 14,
    marginBottom: 24,
  },
  warning: {
    color: Colors.red,
    fontWeight: "bold",
    marginBottom: 24,
  },
  itemLabelWrapper: {
    marginTop: 20,
  },
  badgeContainer: {
    marginTop: 24,
    flexDirection: "row",
    justifyContent: "center",
  },
  oneColumn: {
    display: "flex",
    alignItems: "center",
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignItems: "center",
  },
  inputPC: {
    width: 480,
  },
})
