import { useEffect, useMemo, useState } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import {
  validateKanas,
  validateNumbers,
  validateSpecialCharacter,
  validateJapanese,
  validateApplicantAddressSpecialCharacter,
} from "src/utils/validate"
import { useAsyncState } from "src/hooks/useAsyncState"
import { prefecturesSelector } from "src/recoil/selectors/account/prefecturesSelector"
import { prefectureIdState } from "src/recoil/atoms/account/prefectureIdState"
import { useRecoilState, useSetRecoilState } from "recoil"
import {
  ApplicantInformationData,
  applicantInformationDataState,
} from "src/recoil/atoms/applicantInformation/applicantInformationDataState"
import { OrderType } from "src/aws/API"
import { useRefresher } from "src/hooks/useRefresher"
import { organizationDetailRequestIdState } from "src/recoil/atoms/organization/organizationDetailRequestIdState"
import { CustomAlert } from "src/utils/CustomAlert"
import { getAsync } from "src/storage/secureStorage/getAsync"
import { setAsync } from "src/storage/secureStorage/setAsync"
import { getOrderDetailSelectorFamily } from "src/recoil/selectors/paidTeam/getOrderDetailSelectorFamily"
import { defaultValue, getOrderDetailState } from "src/recoil/selectors/paidTeam/orderDetailState"
import { s3PostCodeDomain } from "src/utils/s3PostCodeDomain"
import { refreshConfirmScreenState } from "src/recoil/atoms/paidTeam/refreshConfirmScreenState"
import { planDataState } from "src/recoil/atoms/planData/planDataState"

type Params = {
  mode: "init" | "update" | "back"
  organizationId: string
  orderId: string | undefined
  transferStatusId?: string | undefined
}

export const useApplicantInformationData = ({ organizationId, mode, orderId, transferStatusId }: Params) => {
  const { value: account, isLoading: isLoadingAccount } = useAsyncSelector(accountSelector)
  const setRefreshConfirm = useSetRecoilState(refreshConfirmScreenState)
  const email = useMemo(() => account?.email, [account])
  const { value: organizationDetail, isLoading: isLoadingOrganizationDetail } = useAsyncSelector(
    organizationDetailSelectorFamily(organizationId)
  )
  const refreshOrganization = useRefresher(organizationDetailRequestIdState)
  const [planData, setPlanData] = useRecoilState(planDataState)

  const validateRequiredValue = (input: string | undefined) => {
    if (!input && storedData && !transferStatusId) {
      return errorMessage
    } else if (input === null || input === "") {
      return errorMessage
    } else return ""
  }

  const errorMessage = "必須項目です。"
  const [storedData, setStoredData] = useState<ApplicantInformationData | undefined>()
  const [runEffect, setRunEffect] = useState(false)

  const [applicantInformationData, setApplicantInformationData] = useRecoilState(applicantInformationDataState)
  const { value: orderDetailValues } = useAsyncSelector(getOrderDetailSelectorFamily({ teamId: organizationId }))
  const { value: prefectures } = useAsyncSelector(prefecturesSelector)
  const [orderDetail, setOrderDetail] = useRecoilState(getOrderDetailState)
  const prefectureOptions = useMemo(
    () => prefectures?.map((prefecture) => ({ value: prefecture.id, label: prefecture.label })),
    [prefectures]
  )
  const [prefectureId, setPrefectureId] = useAsyncState(prefectureIdState)
  const companyNameError = useMemo(() => {
    if (validateRequiredValue(applicantInformationData.companyName) !== "") {
      return validateRequiredValue(applicantInformationData.companyName)
    }
    return !validateSpecialCharacter(applicantInformationData?.companyName || "") &&
      applicantInformationData?.companyName != undefined
      ? "企業名に漢字、カナ、平仮名、英数字以外の文字が入力されています。ご確認ください。"
      : ""
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantInformationData?.companyName, storedData, transferStatusId])

  const companyNameKanaError = useMemo(() => {
    if (validateRequiredValue(applicantInformationData.companyNameKana) !== "") {
      return validateRequiredValue(applicantInformationData.companyNameKana)
    }
    return validateKanas(applicantInformationData.companyNameKana || "") ? "" : "カナで入力してください。"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantInformationData.companyNameKana, storedData, transferStatusId])

  const representativeNameError = useMemo(() => {
    if (validateRequiredValue(applicantInformationData.representativeName) !== "") {
      return validateRequiredValue(applicantInformationData.representativeName)
    }
    return !validateJapanese(applicantInformationData.representativeName || "") &&
      applicantInformationData.representativeName != undefined
      ? "法人代表者名に漢字、カナ、平仮名以外の文字が入力されています。ご確認ください。"
      : ""
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantInformationData.representativeName, storedData, transferStatusId])

  const companyRepresentativeNameKanaError = useMemo(() => {
    if (validateRequiredValue(applicantInformationData.companyRepresentativeNameKana) !== "") {
      return validateRequiredValue(applicantInformationData.companyRepresentativeNameKana)
    }
    return validateKanas(applicantInformationData.companyRepresentativeNameKana || "") ? "" : "カナで入力してください。"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantInformationData.companyRepresentativeNameKana, storedData, transferStatusId])

  const phoneNumberError = useMemo(() => {
    if (validateRequiredValue(applicantInformationData.phoneNumber?.toString()) !== "") {
      return validateRequiredValue(applicantInformationData.phoneNumber?.toString())
    }
    return validateNumbers(applicantInformationData.phoneNumber?.toString() || "") ? "" : "半角数字で入力しください。"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantInformationData.phoneNumber, storedData])

  const prefectureError = useMemo(() => {
    if (prefectureId == undefined || (prefectureId === "" && storedData && !transferStatusId)) {
      return true
    } else return false
  }, [prefectureId, storedData, transferStatusId])

  const zipError = useMemo(() => {
    if (validateRequiredValue(applicantInformationData.zip) !== "") {
      return validateRequiredValue(applicantInformationData.zip)
    }
    return validateNumbers(applicantInformationData.zip || "") ? "" : "半角数字で入力しください。"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantInformationData.zip, storedData, transferStatusId])

  const addressError = useMemo(() => {
    if (validateRequiredValue(applicantInformationData.address) !== "") {
      return validateRequiredValue(applicantInformationData.address)
    }
    return !validateApplicantAddressSpecialCharacter(applicantInformationData?.address || "") &&
      applicantInformationData?.address != undefined
      ? "住所に漢字、カナ、平仮名、英数字、ハイフン以外の文字が入力されています。ご確認ください。"
      : ""
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applicantInformationData.address, storedData, transferStatusId])

  const buildingNameError = useMemo(() => {
    if (applicantInformationData.buildingName == "" || applicantInformationData.buildingName == undefined) return ""
    return validateSpecialCharacter(applicantInformationData.buildingName)
      ? ""
      : "ビル・建物名に漢字、カナ、平仮名、英数字以外の文字が入力されています。ご確認ください。"
  }, [applicantInformationData.buildingName])

  const convertPrefectureId = (region: string) => {
    return prefectureOptions?.find((pre) => pre.label === region)?.value
  }

  const setLocalStorageDataRealTime = (data: ApplicantInformationData) => {
    if (mode === "update") return
    setAsync("applicantInformationData", JSON.stringify(data))
  }

  const getLocationByZipCode = (zipCode: string) => {
    if (zipError || zipCode.length < 7) return
    fetch(`${s3PostCodeDomain}${zipCode}.json`)
      .then((res) => res.json())
      .then((data) => {
        setPrefectureId(convertPrefectureId(data?.prefecture_name ?? ""))
        setApplicantInformationData({
          ...applicantInformationData,
          address: `${data?.city_name === "以下に掲載がない場合" ? "" : data.city_name}${
            data?.town_name === "以下に掲載がない場合" ? "" : data.town_name
          }`,
          prefecture: `${data?.prefecture_name ?? ""}`,
        })
        setLocalStorageDataRealTime({
          ...applicantInformationData,
          address: `${data?.city_name ?? ""}${data?.town_name ?? ""}`,
          prefecture: `${data?.prefecture_name ?? ""}`,
        })
      })
      .catch(() => {
        setPrefectureId(undefined)
        setApplicantInformationData({ ...applicantInformationData, address: "" })
        CustomAlert.alert("警告", "入力された郵便番号に該当する住所がありません。")
      })
  }

  const setData = async () => {
    const storedData = await getAsync("applicantInformationData")
    setRunEffect(true)
    if (storedData !== undefined) {
      setStoredData(JSON.parse(storedData))
    } else {
      setStoredData(undefined)
    }
  }

  const isDisabled = useMemo(
    () =>
      companyNameError !== "" ||
      companyNameKanaError !== "" ||
      representativeNameError !== "" ||
      addressError !== "" ||
      companyRepresentativeNameKanaError !== "" ||
      phoneNumberError !== "" ||
      prefectureError ||
      zipError !== "" ||
      applicantInformationData.companyName == undefined ||
      applicantInformationData.companyNameKana == undefined ||
      applicantInformationData.representativeName == undefined ||
      applicantInformationData.address == undefined ||
      applicantInformationData.companyRepresentativeNameKana == undefined ||
      applicantInformationData.phoneNumber == undefined ||
      prefectureId == "" ||
      applicantInformationData.zip == undefined ||
      buildingNameError !== "",
    [
      companyNameKanaError,
      companyRepresentativeNameKanaError,
      phoneNumberError,
      zipError,
      companyNameError,
      representativeNameError,
      addressError,
      prefectureError,
      applicantInformationData?.companyName,
      applicantInformationData.companyNameKana,
      applicantInformationData.representativeName,
      applicantInformationData.address,
      applicantInformationData.companyRepresentativeNameKana,
      applicantInformationData.phoneNumber,
      prefectureId,
      applicantInformationData.zip,
      buildingNameError,
    ]
  )

  const isDisabledPrivate = useMemo(
    () =>
      addressError !== "" ||
      phoneNumberError !== "" ||
      prefectureError ||
      zipError !== "" ||
      applicantInformationData.address == undefined ||
      applicantInformationData.phoneNumber == undefined ||
      prefectureId == "" ||
      applicantInformationData.zip == undefined ||
      buildingNameError !== "",
    [
      phoneNumberError,
      zipError,
      addressError,
      prefectureError,
      applicantInformationData.address,
      prefectureId,
      applicantInformationData.phoneNumber,
      applicantInformationData.zip,
      buildingNameError,
    ]
  )

  useEffect(() => {
    setApplicantInformationData({
      ...applicantInformationData,
      teamName: organizationDetail?.organizationName ?? "",
      idTeam: organizationDetail?.id ?? "",
      adminName: organizationDetail?.profileInOrganization.name ?? "",
      idAdmin: organizationDetail?.profileInOrganization.id ?? "",
      email: email ?? "",
      teamMembers: organizationDetail?.memberNumber ?? 0,
    })
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    email,
    organizationDetail?.memberNumber,
    organizationDetail?.profileInOrganization.id,
    organizationDetail?.profileInOrganization.name,
    organizationDetail?.id,
    organizationDetail?.organizationName,
  ])

  useEffect(() => {
    if (orderDetailValues !== undefined && typeof orderDetailValues !== "string") {
      if (orderDetailValues.preOrder.order !== undefined && orderDetailValues.usedOrder.order === undefined) {
        setOrderDetail(orderDetailValues.preOrder.order)
        const companyName = orderDetailValues.preOrder.order.companyName
        if (!applicantInformationData.companyName) {
          setApplicantInformationData({
            ...applicantInformationData,
            appCategory: companyName ? "法人" : "個人",
            companyName: companyName ?? "",
            email: email,
            adminName: organizationDetail?.profileInOrganization.name,
            teamName: organizationDetail?.organizationName,
            buildingName: orderDetailValues.preOrder.order.applicantBuildingName ?? "",
          })
        }
      } else if (orderDetailValues.preOrder.order === undefined && orderDetailValues.usedOrder.order !== undefined) {
        setOrderDetail(orderDetailValues.usedOrder.order)
        const companyName = orderDetailValues.usedOrder.order.companyName
        if (!applicantInformationData.companyName) {
          setApplicantInformationData({
            ...applicantInformationData,
            appCategory: companyName ? "法人" : "個人",
            companyName: companyName ?? "",
            email: email,
            adminName: organizationDetail?.profileInOrganization.name,
            teamName: organizationDetail?.organizationName,
            buildingName: orderDetailValues.usedOrder.order.applicantBuildingName ?? "",
          })
        }
      } else if (orderDetailValues.preOrder.order !== undefined && orderDetailValues.usedOrder.order !== undefined) {
        setOrderDetail(orderDetailValues.preOrder.order)
        const companyName = orderDetailValues.preOrder.order.companyName
        if (!applicantInformationData.companyName) {
          setApplicantInformationData({
            ...applicantInformationData,
            appCategory: companyName ? "法人" : "個人",
            companyName: companyName ?? "",
            email: email,
            adminName: organizationDetail?.profileInOrganization.name,
            teamName: organizationDetail?.organizationName,
            buildingName: orderDetailValues.preOrder.order.applicantBuildingName ?? "",
          })
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [transferStatusId, orderDetailValues, organizationId])
  const setLocalStorageData = () => {
    if (applicantInformationData.appCategory === "法人" ? isDisabled : isDisabledPrivate) return
    setRefreshConfirm((oldValue) => oldValue + 1)
    if (!orderId && !transferStatusId) {
      setAsync("applicantInformationData", JSON.stringify(applicantInformationData))
    }
  }
  useEffect(() => {
    if (mode === "init") {
      setOrderDetail(defaultValue)
    }
  }, [organizationId, mode, setOrderDetail])

  useEffect(() => {
    setData()
  }, [mode])

  useEffect(() => {
    refreshOrganization()
  }, [organizationId, refreshOrganization])

  useEffect(() => {
    if (runEffect) {
      if (storedData && !orderDetail.createdAt) {
        setApplicantInformationData({
          ...applicantInformationData,
          appCategory: storedData.appCategory,
          companyName: storedData.companyName,
          companyNameKana: storedData.companyNameKana,
          representativeName: storedData.representativeName,
          companyRepresentativeNameKana: storedData.companyRepresentativeNameKana,
          phoneNumber: storedData.phoneNumber,
          zip: storedData.zip,
          address: storedData.address,
          prefecture: storedData.prefecture,
          buildingName: storedData.buildingName,
        })
        if (prefectureOptions)
          setPrefectureId(prefectureOptions.find((prefecture) => prefecture.label === storedData.prefecture)?.value ?? "")
      } else if (mode === "init" && !storedData) {
        setApplicantInformationData({
          ...applicantInformationData,
          appCategory: "法人",
          companyName: undefined,
          companyNameKana: undefined,
          representativeName: undefined,
          companyRepresentativeNameKana: undefined,
          phoneNumber: undefined,
          zip: undefined,
          address: undefined,
          prefecture: undefined,
          buildingName: undefined,
        })
        setPrefectureId("")
      } else if ((mode === "update" && orderId) || transferStatusId) {
        if (orderDetail.createdAt && orderDetail.teamId === organizationId) {
          setApplicantInformationData({
            ...applicantInformationData,
            appCategory: orderDetail.orderType === OrderType.corporation ? "法人" : "個人",
            companyName: orderDetail.companyName || undefined,
            companyNameKana: orderDetail.companyNameKana || undefined,
            representativeName: orderDetail.corporateRepresentativeName || undefined,
            companyRepresentativeNameKana: orderDetail.corporateRepresentativeNameKana || undefined,
            phoneNumber: orderDetail.applicantPhone || undefined,
            zip: orderDetail.applicantPostcode || undefined,
            address: orderDetail.applicantAddress || undefined,
            buildingName: orderDetail.applicantBuildingName || "",
            prefecture: orderDetail.applicantPrefecture || undefined,
          })

          if (prefectureOptions) {
            setPrefectureId(
              prefectureOptions.find((prefecture) => prefecture.label === orderDetail.applicantPrefecture)?.value ?? ""
            )
          }
        }
      }
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderId, orderDetail, mode, prefectureOptions, setPrefectureId, storedData, transferStatusId, runEffect])

  return {
    organizationDetail,
    isLoadingOrganizationDetail,
    isLoadingAccount,
    email,
    errorMessage,
    companyNameError,
    companyNameKanaError,
    representativeNameError,
    companyRepresentativeNameKanaError,
    phoneNumberError,
    zipError,
    prefectureOptions,
    prefectureId,
    setPrefectureId,
    addressError,
    isDisabled,
    prefectureError,
    setLocalStorageData,
    applicantInformationData,
    isDisabledPrivate,
    getLocationByZipCode,
    setApplicantInformationData,
    buildingNameError,
    setLocalStorageDataRealTime,
    setOrderDetail,
    planData,
    setPlanData,
  }
}
