import { Result } from "src/utils/Result"
import { createTemplate } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type PostContactTemplateParams = {
  accessToken: string
  contactId: string
}

type PostContactTemplateSuccess = unknown

type PostContactTemplateFailure = {
  message: string
}

export const postContactTemplate = async (params: PostContactTemplateParams): Promise<Result<PostContactTemplateSuccess, PostContactTemplateFailure>> => {
  const { accessToken, contactId } = params

  return execApi(
    () => createTemplate(accessToken, {
      input: {
        mailId: contactId
      },
      appInfo
    }),
    _ => undefined
  )
}
