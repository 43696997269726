import { ComponentProps, memo } from "react"
import { ImageBackground, StyleSheet } from "react-native"

type Props = {
  size?: number
  source: ComponentProps<typeof ImageBackground>["source"]
  onError?: () => void
}

export const CircleThumbnail = memo<Props>(({ size, source, onError }) => (
  <ImageBackground
    style={[styles.profileImageBackground, size != null && { width: size, height: size }]}
    imageStyle={styles.profileImageStyle}
    source={source}
    onError={onError}
  />
))

const styles = StyleSheet.create({
  profileImageStyle: {
    borderRadius: 50,
  },
  profileImageBackground: {
    width: 100,
    height: 100,
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
})
