import { FC } from "react"
import { Path, Svg } from "react-native-svg"

type Props = {
  size?: number
  fillColor?: string
}

export const PlusIcon: FC<Props> = ({ size = 13, fillColor = "#000" }) => (
  <Svg width={size} height={size} viewBox="0 0 12.786 12.786">
    <Path
      d="M29.393 23a.947.947 0 0 0-.947.947v4.5h-4.5a.947.947 0 0 0 0 1.894h4.5v4.5a.947.947 0 0 0 1.894 0v-4.5h4.5a.947.947 0 0 0 0-1.894h-4.5v-4.5a.947.947 0 0 0-.947-.947z"
      transform="translate(-23 -23)"
      fill={fillColor}
    />
  </Svg>
)

PlusIcon.displayName = "PlusIcon"
