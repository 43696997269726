import { selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { listSchoolDepartmentMaster } from "src/aws/customAPI"

type SchoolDepartment = {
  id: string
  label: string
}

export const schoolDepartmentsSelectorFamily = selectorFamily<SchoolDepartment[] | undefined, string | undefined>({
  key: "selectors/account/schoolDepartmentsSelectorFamily",
  get:
    (schoolId) =>
    async ({ get }) => {
      const tokens = get(tokensState)
      if (tokens == null || schoolId == null) {
        return
      }

      const departmentMaster = await listSchoolDepartmentMaster(tokens.accessToken, {
        schoolId: schoolId,
      })
      const departments: SchoolDepartment[] | undefined = departmentMaster.data?.getSchoolMaster.departments.items
        .filter((d) => d.displayFlg === 1)
        .map((d) => ({
          id: d.id,
          label: d.label,
        }))

      return departments
    },
})
