import { selectorFamily } from "recoil"
import { requestDataSelector } from "src/recoil/selectors/auth/requestDataSelector"
import { getUnreadMatchingMessageCount } from "src/apis/matching/getUnreadMatchingMessageCount"
import { unreadMatchingMessageRequestIdState } from "src/recoil/atoms/matching/unreadMatchingMessageRequestIdState"
import { organizationDetailSelectorFamily } from "../organization/organizationDetailSelectorFamily"
import { myProfileSelectorFamily } from "../organization/myProfile/myProfileSelectorFamily"

export type UnreadMatchingMessageStatus = {
  count: number
  offerMessage?: boolean
  applicationMessage?: boolean
}

export const unreadMatchingMessageCountFamily = selectorFamily<
  UnreadMatchingMessageStatus,
  { applicationId: string | string[] | undefined; myTeamId: string | undefined }
>({
  key: "selectors/matching/unreadMatchingMessageCountFamily",
  get:
    ({ applicationId, myTeamId }) =>
    async ({ get }) => {
      get(unreadMatchingMessageRequestIdState)
      const requestData = get(requestDataSelector)
      if (requestData == null) return { count: 0 }
      const { accessToken } = requestData
      if (accessToken == null || myTeamId == null) return { count: 0 }

      const organizationDetail = get(organizationDetailSelectorFamily(myTeamId))
      const myProfile = get(myProfileSelectorFamily(myTeamId))
      if (organizationDetail?.category.label !== "スポーツ系サークル・チーム" || myProfile?.memberType !== "Representative") {
        return { count: 0 }
      }

      const res = await getUnreadMatchingMessageCount({ accessToken, applicationId, myTeamId })
      return res
    },
})
