import { useCallback, useMemo, useState } from "react"
import { updatePhoneNumber } from "src/apis/auth/updatePhoneNumber"
import { useAsyncState } from "src/hooks/useAsyncState"
import { useFetcher } from "src/hooks/useFetcher"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { AuthorizationPhoneNumberCodeParams } from "src/screens/settings/AccountInfo/AuthorizationPhoneNumberCode"
import { CustomAlert } from "src/utils/CustomAlert"

type Params = {
  currentPhoneNumber?: string
  gotoEnterAuthCode: (params: AuthorizationPhoneNumberCodeParams) => void
}

export const useEditOrRegisterPhoneNumberData = ({ currentPhoneNumber, gotoEnterAuthCode }: Params) => {
  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const [newPhoneNumber, setNewPhoneNumber] = useState("")
  const { fetch: sendAuthCode, isFetching: isSendingAuthCode } = useFetcher(
    useCallback(async () => {
      if (accessToken == null) {
        return
      }
      const result = await updatePhoneNumber({ accessToken, phoneNumber: newPhoneNumber })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      gotoEnterAuthCode({ currentPhoneNumber, newPhoneNumber })
    }, [accessToken, currentPhoneNumber, gotoEnterAuthCode, newPhoneNumber])
  )
  const isDisabled = useMemo(
    () =>
      newPhoneNumber.length !== 11 ||
      !/^[0-9]*$/.test(newPhoneNumber) ||
      isSendingAuthCode ||
      newPhoneNumber === currentPhoneNumber?.replace("+81", "0"),
    [newPhoneNumber, currentPhoneNumber, isSendingAuthCode]
  )

  return {
    newPhoneNumber,
    setNewPhoneNumber,
    isDisabled,
    sendAuthCode,
    isSendingAuthCode,
  }
}
