import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { Result } from "src/utils/Result"
import { requestDataSelector } from "../selectors/auth/requestDataSelector"
import { RequestData } from "src/types/RequestData"

export const useRequestData = (): Result<RequestData, { isLoading: true } | { isLoading: false; message: string }> => {
  const { value: requestData, isLoading } = useAsyncSelector(requestDataSelector)

  if (isLoading) {
    return Result.Error({ isLoading })
  }

  if (requestData == null) {
    return Result.Error({ isLoading, message: "accountIdもしくはaccessTokenがありません" })
  }

  return Result.Ok(requestData)
}
