import { memo } from "react"
import { Dimensions, Image, StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { useRecoilValue } from "recoil"
import { maintenanceState } from "src/recoil/atoms/maintenance/maintenanceState"
import { SimpleMarkDown, SimpleMarkDownStyle } from "src/components/parts/SimpleMarkDown"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type MaintenanceParams = undefined

const fixedContent = `「らくらく連絡網＋」はただいまメンテナンス中です。
皆様にはご迷惑をお掛けいたしますが、メンテナンス終了後に再度アクセスをお願いいたします。`

export const Maintenance = memo(() => {
  const maintenance = useRecoilValue(maintenanceState)
  const { top } = useSafeAreaInsets()
  const isLargeScreen = useCheckPCScreen()

  if (maintenance == null) {
    return null
  }

  return (
    <View style={{ flex: 1 }}>
      <View style={[styles.header, isLargeScreen ? { height: 63 } : { paddingTop: top, paddingBottom: 10 }]}>
        <Text style={styles.headerTitle}>メンテナンスのお知らせ</Text>
      </View>
      <CustomKeyboardAvoidingView>
        <View style={styles.backgroundContainer}>
          <View style={styles.squareContainer}>
            <View style={styles.arcContainer}>
              <View style={styles.arc} />
            </View>
          </View>
        </View>
        <View style={styles.container}>
          <View style={styles.logoContainer}>
            <Image style={styles.icon} source={require("src/assets/images/characterMaintenance.png")} />
          </View>
          <View style={styles.titleContainer}>
            <Text style={styles.title}>メンテナンス中</Text>
          </View>
          <Text style={styles.fixedContent}>{fixedContent}</Text>
          <SimpleMarkDown content={maintenance.content} style={mdStyles} />
        </View>
      </CustomKeyboardAvoidingView>
    </View>
  )
})

const circleSize = Dimensions.get("window").width * 3
const halfCircleSize = circleSize / 2

const styles = StyleSheet.create({
  header: {
    backgroundColor: Colors.orange,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  headerTitle: {
    color: Colors.white,
    fontSize: 18,
  },
  icon: {
    width: 130,
    height: 98,
  },
  container: {
    position: "absolute",
    flex: 1,
    height: "100%",
    width: "100%",
    backgroundColor: "transparent",
  },
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  logoContainer: {
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 72,
  },
  titleContainer: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
    paddingBottom: 16,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 48,
    marginBottom: 16,
    marginHorizontal: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: "700",
    lineHeight: 24,
    color: "#e11520",
  },
  fixedContent: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.greyshBrown,
    paddingHorizontal: 16,
  },
  backgroundContainer: {
    flex: 1,
    backgroundColor: Colors.white3,
    paddingTop: 370,
  },
  squareContainer: {
    position: "relative",
    backgroundColor: Colors.beige,
    flex: 1,
  },
  arcContainer: {
    position: "absolute",
    top: -250,
    left: 0,
    backgroundColor: Colors.white3,
    width: "100%",
    height: 250,
    overflow: "hidden",
    alignItems: "center",
  },
  arc: {
    backgroundColor: Colors.white,
    width: circleSize,
    height: circleSize,
    borderRadius: halfCircleSize,
  },
})

const mdStyles: SimpleMarkDownStyle = StyleSheet.create({
  container: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.greyshBrown,
  },
  link: {
    fontSize: 16,
    lineHeight: 24,
    color: "#e11520",
    textDecorationLine: "underline",
  },
})
