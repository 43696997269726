import { memo, useCallback } from "react"
import { StyleSheet, Text, View, useWindowDimensions } from "react-native"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { Colors } from "src/constants/Colors"
import { RootStackScreenProps } from "src/types.d"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { applicantInformationDataState } from "src/recoil/atoms/applicantInformation/applicantInformationDataState"
import { Screens } from "src/constants/Screens"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { getOrderDetailState } from "src/recoil/selectors/paidTeam/orderDetailState"
import { useRecoilValue } from "recoil"
import { useFetcher } from "src/hooks/useFetcher"
import { changeApplicantInfoApi } from "src/apis/paidTeam/changeApplicantInfo"
import { CustomAlert } from "src/utils/CustomAlert"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { deleteAsync } from "src/storage/secureStorage/deleteAsync"

const confirmApplicantInfo = memo<RootStackScreenProps<"confirmApplicantInfo">>(({ navigation, route }) => {
  const { transferStatusId, organizationId } = route.params
  const isLargeScreen = useCheckPCScreen()
  const requestDataResult = useRequestData()
  const accessToken = requestDataResult.isOk ? requestDataResult?.content?.accessToken : ""
  const orderDetail = useRecoilValue(getOrderDetailState)
  const heightScreen = useWindowDimensions().height
  const insets = useSafeAreaInsets()
  const applicantInformationData = useRecoilValue(applicantInformationDataState)

  const { fetch: updateApplicant, isFetching: isLoadingApi } = useFetcher(
    useCallback(async () => {
      if (!accessToken || !applicantInformationData) {
        return
      }
      try {
        const result = await changeApplicantInfoApi({
          accessToken,
          applicantInformationData,
          teamId: organizationId,
          transferStatusId: transferStatusId,
        })
        if (result.id) {
          if (transferStatusId) {
            deleteAsync("applicantInformationData")
            navigation.replace(Screens.Root, {
              screen: RootTabs.OrganizationManagerTab,
              params: {
                screen: OrganizationManagerScreens.OrganizationDetail,
                params: { organizationId: organizationId },
              },
            })
          } else {
            navigation.navigate(Screens.Root, {
              screen: RootTabs.OrganizationManagerTab,
              params: {
                screen: OrganizationManagerScreens.OrderDetail,
                params: {
                  teamId: organizationId,
                  teamMembers: applicantInformationData.teamMembers,
                  accountId: orderDetail.transferApplicantId ?? orderDetail.applicantId ?? "",
                  mode: "update",
                },
              },
            })
          }
        } else {
          CustomAlert.alert("エラー", "エラーが発生しました")
        }
      } catch (error) {
        console.error("Failed to update plan:", error)
      }
    }, [accessToken, orderDetail, navigation, applicantInformationData, transferStatusId, organizationId])
  )
  return (
    <View style={[{ height: heightScreen - 64, paddingBottom: insets.bottom }]}>
      <CustomScrollView noPaddingBottom={true}>
        <View style={[styles.container]}>
          <View style={[styles.header]}>
            <Text
              style={[
                isLargeScreen
                  ? {
                      fontWeight: "bold",
                      fontSize: 18,
                    }
                  : { fontWeight: "bold", color: Colors.greyshBrown, fontSize: 18 },
              ]}
            >
              申込者情報
            </Text>
          </View>
          <View style={[styles.row, { marginTop: 16 }]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="団体名" />
            </View>
            <Text style={[styles.textField]}>{applicantInformationData?.teamName}</Text>
          </View>
          <View style={[styles.row]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="団体代表者氏名（会員名）" />
            </View>
            <Text style={[styles.textField]}>{applicantInformationData?.adminName}</Text>
          </View>
          <View style={[styles.row]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="メールアドレス" />
            </View>
            <Text style={[styles.textField]}>{applicantInformationData?.email}</Text>
          </View>
          {applicantInformationData?.appCategory !== "個人" && (
            <>
              <View style={[styles.row]}>
                <View style={styles.itemLabelWrapper}>
                  <ItemLabel label="企業名" />
                </View>
                <Text style={[styles.textField]}>{applicantInformationData?.companyName}</Text>
              </View>
              <View style={[styles.row]}>
                <View style={styles.itemLabelWrapper}>
                  <ItemLabel label="法人代表者名" />
                </View>
                <Text style={[styles.textField]}>{applicantInformationData?.representativeName}</Text>
              </View>
            </>
          )}
          <View style={[styles.row]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="電話番号" />
            </View>
            <Text style={[styles.textField]}>{applicantInformationData?.phoneNumber}</Text>
          </View>
          <View style={[styles.row]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="郵便番号" />
            </View>
            <Text style={[styles.textField]}>{applicantInformationData?.zip}</Text>
          </View>
          <View style={[styles.row]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="都道府県" />
            </View>
            <Text style={[styles.textField]}>{applicantInformationData?.prefecture}</Text>
          </View>
          <View style={[styles.row]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="住所" />
            </View>
            <Text style={[styles.textField]}>{applicantInformationData?.address}</Text>
          </View>
          <View style={[styles.row]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="ビル・建物名" />
            </View>
            <Text style={[styles.textField]}>{applicantInformationData?.buildingName}</Text>
          </View>
        </View>
      </CustomScrollView>
      <View style={[styles.buttonContainer, isLargeScreen ? {} : { paddingVertical: 24 }]}>
        <View
          style={[
            isLargeScreen
              ? {
                  maxWidth: 600,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  paddingHorizontal: 24,
                }
              : { width: "100%" },
          ]}
        >
          <TextButton
            buttonType={ButtonType.Secondary}
            title="戻る"
            onPress={() => {
              navigation.goBack()
            }}
            style={[isLargeScreen ? { width: "49%" } : null]}
          />
          <TextButton
            buttonType={ButtonType.Primary}
            title="保存"
            onPress={updateApplicant}
            isLoading={isLoadingApi}
            style={[isLargeScreen ? { width: "49%" } : { marginTop: 16 }]}
          />
        </View>
      </View>
    </View>
  )
})

export const confirmApplicantInfoOptions: ScreenOptions = () => ({
  ...commonHeaderOptions,
  headerShown: true,
  title: "申込者情報確認",
})

const styles = StyleSheet.create({
  header: {
    paddingBottom: 16,
    width: "100%",
    fontSize: 18,
    fontWeight: 600,
    lineHeight: 25.2,
  },
  container: {
    alignItems: "center",
    width: "100%",
    paddingVertical: 24,
    paddingHorizontal: 16,
    maxWidth: 600,
    marginHorizontal: "auto",
  },
  row: {
    marginBottom: 16,
    width: "100%",
    borderBottomColor: Colors.borderColor,
    borderBottomWidth: 1,
  },
  lastRow: {
    width: "100%",
  },
  headerTitle: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 20,
  },
  requiredItemNoteText: {
    fontWeight: "400",
    fontSize: 14,
  },
  requiredItemNoteAsterisk: {
    color: Colors.red,
  },
  itemLabelWrapper: {
    marginBottom: 4,
  },
  textField: {
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 22.4,
    marginVertical: 14,
    textAlign: "left",
    width: "100%",
  },
  text: {
    width: "100%",
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "300",
  },
  buttonContainer: {
    paddingHorizontal: 24,
    width: "100%",
    borderTopColor: Colors.borderColor,
    borderTopWidth: 1,
    alignItems: "center",
    paddingVertical: 12,
  },
})

export default confirmApplicantInfo
