import { selector } from "recoil"
import { notificationsSelector } from "src/recoil/selectors/home/notificationsSelector"

export const hasOrganizationMatchingNoticeSelector = selector({
  key: "selectors/matching/hasOrganizationMatchingNoticeSelector",
  get: ({ get }) => {
    const notifications = get(notificationsSelector)
    return notifications?.filter((n) => n.type === "matchingMessage").some((n) => !n.isRead) || false
  },
})
