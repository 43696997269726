import { memo, useCallback, useEffect } from "react"
import { StyleSheet, View, Text, Linking, ScrollView } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Checkbox } from "src/components/parts/Checkbox"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptionsOnNestedScreen } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { RootStackScreenProps } from "src/types.d"
import { useInquiryPaidTeam } from "src/recoil/hooks/organization/useInquiryPaidTeam"
import { deliveryMailReceiveSettingGuideURL, privacyPolicyURL } from "src/constants/links/guide"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { ModeCompleted } from "./CompletedApplyPaid"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"

type Props = RootStackScreenProps<typeof Screens.InquiryPaidTeam>

export const InquiryPaidTeam = memo<Props>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const { organizationId } = route.params

  const goToCompletedScreen = (mode: ModeCompleted) => {
    navigation.navigate(Screens.Root, {
      screen: RootTabs.OrganizationManagerTab,
      params: {
        screen: OrganizationManagerScreens.CompletedApplyPaid,
        params: {
          mode: mode,
          orderId: orderDetail.id,
          type: "inquiry",
          teamId: organizationId,
        },
      },
    })
  }

  const {
    changeModeOrSendInquiry,
    confirmMode,
    disabledAcceptButton,
    inquiry,
    policyAccept,
    setInquiry,
    setPolicyAccept,
    isUpdatingNewContactPush,
    setConfirmMode,
    organizationDetail,
    orderDetail,
    setInquiryTeamPaidState,
    applicantData,
    accountInfo,
    isLoading,
  } = useInquiryPaidTeam(organizationId, goToCompletedScreen)

  const changeModeExtendOrBackDetail = useCallback(() => {
    if (confirmMode) {
      setPolicyAccept(false)
      setConfirmMode(false)
      return
    }
    if (!organizationDetail) return
    setInquiryTeamPaidState({})
    navigation.navigate(Screens.Root, {
      screen: RootTabs.OrganizationManagerTab,
      params: {
        screen: OrganizationManagerScreens.OrderDetail,
        params: {
          teamId: organizationDetail.id,
          teamMembers: organizationDetail.memberNumber,
          accountId: organizationDetail.profileInOrganization.id,
        },
      },
    })
  }, [confirmMode, navigation, setConfirmMode, organizationDetail, setInquiryTeamPaidState, setPolicyAccept])

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Button onPress={changeModeExtendOrBackDetail}>
          <BackIcon fill={isLargeScreen ? Colors.orange : Colors.white3}></BackIcon>
        </Button>
      ),
    })
  }, [isLargeScreen, navigation, changeModeExtendOrBackDetail])
  return (
    <CustomKeyboardAvoidingView>
      {isLoading ? (
        <View style={[{ alignItems: "center", justifyContent: "center", marginTop: "15%" }]}>
          <LoadingIndicator />
        </View>
      ) : (
        <ScrollView>
          <View style={styles.textSubHeader}>
            <View style={[isLargeScreen ? styles.oneColumn : {}, isLargeScreen ? { paddingHorizontal: 20 } : {}]}>
              <Text>
                {confirmMode
                  ? "以下の内容で送信いたします。よろしければ「送信」を押してください。"
                  : "ログインしている下記のメールアドレス宛にご返答させていただきます。"}
              </Text>
            </View>
          </View>

          <View style={[isLargeScreen ? styles.oneColumn : {}, styles.itemLabel]}>
            <View>
              <ItemLabel label="ログイン中のメールアドレス" />
              <Text style={styles.valueItem}>{accountInfo?.email}</Text>
            </View>
            <View style={styles.topSpace}>
              <ItemLabel label="団体番号" />
              <Text style={styles.valueItem}>{organizationDetail?.organizationNumber}</Text>
            </View>
            <View style={styles.topSpace}>
              <ItemLabel label="団体名" />
              <Text style={styles.valueItem}>
                {orderDetail.createdAt ? orderDetail.teamName : applicantData?.teamName ?? organizationDetail?.organizationName}
              </Text>
            </View>
            <View style={styles.topSpace}>
              <ItemLabel label="会員名" />
              <Text style={styles.valueItem}>{organizationDetail?.profileInOrganization.name ?? ""}</Text>
            </View>
            <View style={styles.topSpace}>
              <ItemLabel
                label="お問い合わせ内容"
                required={!confirmMode}
                RightComponent={
                  confirmMode ? undefined : <InputLengthCounter text={inquiry} maxLength={2000} unit={"字以内"} />
                }
              />
              {confirmMode ? (
                <Text style={styles.valueItem}>{inquiry}</Text>
              ) : (
                <MultilineTextInput
                  value={inquiry}
                  onChangeText={(e) => {
                    setInquiry(e)
                  }}
                  numberOfLines={6}
                  maxLength={2000}
                  style={styles.multilineInput}
                  placeholder={"お問い合わせ内容を入力してください"}
                />
              )}
            </View>
          </View>
          {confirmMode && (
            <View style={styles.policyContainer}>
              <View style={isLargeScreen ? styles.oneColumn : {}}>
                <Text>
                  お問い合わせの際は、「個人情報の取り扱いについて」をご一読いただき、同意した上でフォームを送信ください。
                </Text>
                <View style={styles.checkboxContainer}>
                  <Checkbox isChecked={policyAccept} onPress={() => setPolicyAccept(!policyAccept)} />
                  <View style={styles.labelPolicy}>
                    <Button
                      onPress={async () => {
                        await Linking.openURL(privacyPolicyURL)
                      }}
                    >
                      <Text style={styles.policy}>個人情報の取り扱い</Text>
                    </Button>
                    <Text>に同意する</Text>
                  </View>
                </View>
              </View>
            </View>
          )}

          <View style={[styles.bottomContainer]}>
            <View style={isLargeScreen ? styles.oneColumn : {}}>
              <View style={!isLargeScreen && styles.smallContainerBtn}>
                <TextButton
                  buttonType={ButtonType.Secondary}
                  title={`キャンセル`}
                  onPress={changeModeExtendOrBackDetail}
                  disabled={isUpdatingNewContactPush}
                />
                <TextButton
                  buttonType={ButtonType.Primary}
                  title={confirmMode ? "送信" : "確認"}
                  onPress={changeModeOrSendInquiry}
                  disabled={disabledAcceptButton}
                  style={{ marginVertical: 16 }}
                  isLoading={isUpdatingNewContactPush}
                />
              </View>
              <View style={styles.flexBox}>
                <Text>・</Text>
                <Text style={{ width: isLargeScreen ? "100%" : "97%" }}>
                  お問い合わせ状況によっては、回答に数日かかる場合がございます。尚、調査が必要となるお問い合わせにつきましては、更にお日にちを頂戴する場合もございますので、予めご了承をお願いいたします。
                </Text>
              </View>
              <View style={[styles.flexBox, { marginTop: 8, width: "100%" }]}>
                <Text>・</Text>
                <View style={{ width: isLargeScreen ? "100%" : "97%" }}>
                  <Text>
                    {
                      "迷惑メールの設定をされている方へ\nらくらく連絡網＋からの返信メールが届くように必ず「@ra9plus.jp」からのメールを受信許可する設定をお願いいたします。"
                    }
                  </Text>
                  <View style={styles.flexBox}>
                    <Text>設定方法は </Text>
                    <Button
                      onPress={async () => {
                        await Linking.openURL(deliveryMailReceiveSettingGuideURL)
                      }}
                    >
                      <Text style={styles.terms}>こちら</Text>
                    </Button>
                    <Text>をご確認ください。</Text>
                  </View>
                </View>
              </View>
            </View>
          </View>
        </ScrollView>
      )}
    </CustomKeyboardAvoidingView>
  )
})

export const InquiryPaidTeamOptions: ScreenOptions = {
  ...commonHeaderOptionsOnNestedScreen,
  title: "有料版専用お問い合わせ",
}

const styles = StyleSheet.create({
  oneColumn: {
    maxWidth: 1020,
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  textSubHeader: {
    paddingHorizontal: 16,
    paddingVertical: 24,
    fontSize: 14,
    lineHeight: 20,
    borderColor: Colors.white2,
    textAlign: "center",
    borderBottomWidth: 1,
  },
  itemLabel: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  valueItem: {
    paddingVertical: 14,
  },
  topSpace: {
    marginTop: 16,
  },
  bottomContainer: {
    backgroundColor: Colors.beige,
    borderColor: Colors.white2,
    borderTopWidth: 1,
    paddingTop: 24,
    paddingBottom: 32,
    paddingHorizontal: 16,
  },
  multilineInput: {
    marginTop: 12,
    height: 200,
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  terms: {
    color: Colors.orange,
    textDecorationLine: "underline",
  },
  policy: {
    color: Colors.orange,
  },
  smallContainerBtn: {
    paddingHorizontal: 16,
  },
  largeContainerBtn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 16,
  },
  policyContainer: {
    backgroundColor: Colors.beige,
    borderColor: Colors.white2,
    borderTopWidth: 1,
    padding: 24,
  },
  checkboxContainer: {
    paddingTop: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  labelPolicy: {
    paddingLeft: 5,
    display: "flex",
    flexDirection: "row",
  },
})
