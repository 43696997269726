export const UseIOSString = (datetime: Date) => {
  const pad = (str: string) => {
    return ("0" + str).slice(-2)
  }
  const year = datetime.getFullYear().toString()
  const month = pad((datetime.getMonth() + 1).toString())
  const day = pad(datetime.getDate().toString())
  const hour = pad(datetime.getHours().toString())
  const min = pad(datetime.getMinutes().toString())
  const sec = pad(datetime.getSeconds().toString())
  const tz = -datetime.getTimezoneOffset()
  const sign = tz >= 0 ? "+" : "-"
  const tzHour = pad((tz / 60).toString())
  const tzMin = pad((tz % 60).toString())
  return `${year}-${month}-${day}T${hour}:${min}:${sec}${sign}${tzHour}:${tzMin}`
}

export function isBeforeToday(date: string) {
  if (!date) {
    return false // Assuming that if date is undefined or null, it's not before the current year
  }
  const currentDate = new Date()
  const publishEndDateDate = new Date(date)
  publishEndDateDate.setHours(23)
  publishEndDateDate.setMinutes(59)
  publishEndDateDate.setSeconds(59)
  return publishEndDateDate < currentDate
}

export const convertMonthToYearAndMonth = (months: number) => {
  const years = Math.floor(months / 12)
  const remainingMonths = months % 12
  return {
    years: years,
    months: remainingMonths,
  }
}
