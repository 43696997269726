import { FC, useState } from "react"
import { Text, View, Modal, Pressable, StyleSheet } from "react-native"
import { Colors } from "src/constants/Colors"

interface OwnProps {
  visible: boolean
  title?: string
  content: string
  closeAction: () => void
  okAction?: () => void
}

export const ConfirmModal: FC<OwnProps> = ({ title, content, okAction, visible, closeAction }) => {
  return (
    <Modal animationType="fade" transparent={true} visible={visible} onRequestClose={() => closeAction()}>
      <View style={styles.centeredView}>
        <View style={styles.modalView}>
          <Text style={styles.title}>{title ?? "確認"}</Text>
          <Text style={styles.modalText}>{content}</Text>
          <View style={styles.buttons}>
            <Pressable style={[styles.button, styles.buttonCancel]} onPress={() => closeAction()}>
              <Text style={[styles.textStyle, styles.textButtonCancel]}>キャンセル</Text>
            </Pressable>
            {okAction && (
              <Pressable style={[styles.button, styles.buttonOK]} onPress={() => okAction()}>
                <Text style={[styles.textStyle, styles.textButtonOK]}>OK</Text>
              </Pressable>
            )}
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  modalView: {
    margin: 10,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 15,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  button: {
    borderRadius: 4,
    padding: 10,
    elevation: 2,
    margin: 10,
    minWidth: 80,
  },
  buttonCancel: {
    backgroundColor: "#efeae3",
  },
  buttonOK: {
    backgroundColor: "rgba(159, 134, 107, 0.1)",
  },
  textStyle: {
    fontWeight: "bold",
    textAlign: "center",
  },
  textButtonCancel: {
    color: Colors.greyshBrown,
    fontWeight: "bold",
  },
  textButtonOK: {
    color: Colors.orange,
  },
  modalText: {
    marginBottom: 15,
    padding: 20,
    textAlign: "center",
  },
  title: {
    padding: 10,
    fontSize: 16,
    fontWeight: "500",
    textAlign: "center",
  },
  buttons: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
})
