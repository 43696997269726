import { CandidateDatetimesInput } from "src/aws/API"
import { asPartial, EventDateCandidate } from "src/types/contact/EventDateCandidate"
import dayjs from "dayjs"

export const convertDateTimeCandidate = (candidate: EventDateCandidate): CandidateDatetimesInput => {
  const partialCandidate = asPartial(candidate)
  return {
    startDate: dayjs(partialCandidate.startDate).format("YYYY-MM-DD"),
    startTime: partialCandidate.startTime,
    endDate: partialCandidate.endDate ? dayjs(partialCandidate.endDate).format("YYYY-MM-DD") : undefined,
    endTime: partialCandidate.endTime,
  }
}
