import { listNotificationSetting as listNotificationSettingAPI, GqlError } from "src/aws/customAPI"
import { Result } from "src/utils/Result"

type ListNotificationSettingsRequestParams = {
  accessToken: string
}


export type ListNotificationSettingsSuccess = {
  memberType: string
  teamId: string,
  role: string,
  teamName: string,
  id: string,
  teamProfileImage: string | null | undefined,
  mailReceivedNotice: {
    push: boolean,
    email: boolean
  },
  threadMessageReceivedNotice: {
    push: boolean,
    email: boolean
  },
  joinRequestNotice: {
    push: boolean,
    email: boolean
  },
  teamMatchingNotice: {
    email: boolean
  },
  teamCategory: { id: string, label?: string | null | undefined } | null | undefined
}


type ListNotificationSettingsFailure = {
  message: string
}

export const listNotificationSettings = async ({ accessToken }: ListNotificationSettingsRequestParams): Promise<Result<ListNotificationSettingsSuccess[], ListNotificationSettingsFailure>> => {
  return listNotificationSettingAPI(accessToken)
    .then(res => res.data?.listNotificationSetting)
    .then(res => {
      if (res) {
        const data = res?.items.map(s => ({ 
          memberType: s.role ?? "",
          teamId : s.teamId,
          role:s.role ?? "",
          teamName:s.teamName ?? "",
          id:s.id,
          teamProfileImage:s.teamProfileImage,
          mailReceivedNotice:s.mailReceivedNotice,
          threadMessageReceivedNotice:s.threadMessageReceivedNotice,
          joinRequestNotice:s.joinRequestNotice,
          teamMatchingNotice:s.teamMatchingNotice,
          teamCategory: s.teamCategory ?? { id: "", label: "" }
        }) );
        return Result.Ok<ListNotificationSettingsSuccess[]>(data)
      } else {
        return Result.Error<ListNotificationSettingsFailure>({
          message: "設定の取得に失敗しました。"
        })
      }
    })
    .catch((e: GqlError) => {
      const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || '予期せぬエラーが発生しました'
      return Result.Error<ListNotificationSettingsFailure>({
        message: message
      })
    })
}
