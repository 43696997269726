import { memo, useCallback, useEffect } from "react"
import { EnterEmail } from "src/components/parts/authorization/EnterEmail"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useEmailLoginData } from "src/recoil/hooks/authorization/useAuthEmailData"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { RootStackScreenProps } from "src/types.d"
import { HeaderLeft } from "src/components/parts/headers/HeaderLeft"
import { Platform } from "react-native"

export const LoginEmail = memo<RootStackScreenProps<typeof Screens.LoginEmail>>(({ navigation }) => {
  const gotoLoginCode = useCallback((email: string) => navigation.navigate(Screens.LoginCode, { email }), [navigation])
  const { email, setEmail, isDisabled, isSending, sendAuthCode } = useEmailLoginData(gotoLoginCode)
  const gotoLoginPhoneNumber = useCallback(() => navigation.navigate("LoginPhoneNumber"), [navigation])

  useEffect(() => {
    if (Platform.OS === "web") {
      navigation.setOptions({
        headerLeft: () => <HeaderLeft onPress={() => navigation.navigate(Screens.AuthorizationTop)} canGoBack={true} />,
      })
    }
  }, [navigation])

  return (
    <EnterEmail
      label="登録したメールアドレスを入力"
      email={email}
      onEmailChange={setEmail}
      sendAuthCode={sendAuthCode}
      isDisabled={isDisabled}
      isEditable={!isSending}
      gotoLoginPhoneNumber={gotoLoginPhoneNumber}
    />
  )
})

LoginEmail.displayName = "LoginEmail"

export const loginEmailOptions: ScreenOptions = {
  ...commonHeaderOptions,
  title: "ログイン",
}
