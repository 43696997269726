import { FC } from "react"
import Svg, { G, Path } from "react-native-svg"

/*
 ** さんかくボタン
 */

type Props = {
  fill: string
}

export const PendIcon: FC<Props> = ({ fill }) => (
  <Svg width="16" height="14" viewBox="0 0 16 14">
    <G data-name="多角形 5" fill="none">
      <Path d="m8 0 8 14H0z" stroke="none" />
      <Path d="M8 6.047 5.17 11h5.66L8 6.047M8 0l8 14H0L8 0z" fill={fill} stroke="none" />
    </G>
  </Svg>
)

PendIcon.displayName = "PendIcon"
