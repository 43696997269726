import { updateNameGroupAndGroupMembers } from "src/aws/customAPI"
import { Result } from "src/utils/Result"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

export type Member = {
  id: string
  isLeader: boolean
  isDeleteFlg: boolean
}

type Params = {
  accessToken: string
  groupId: string
  groupName: string
  members: Member[]
}

type SuccessResponse = undefined

type FailureResponse = {
  message: string
}

export const putNameGroupAndGroupMembers = async (params: Params): Promise<Result<SuccessResponse, FailureResponse>> => {
  const { accessToken, groupId, groupName, members } = params
  return execApi(
    () =>
      updateNameGroupAndGroupMembers(accessToken, {
        input: {
          id: groupId,
          name: groupName,
          members: members.map((m) => ({
            id: m.id,
            leaderFlg: m.isLeader,
            deleteFlg: m.isDeleteFlg,
          })),
        },
        appInfo,
      }),
    (_) => undefined,
    {
      identifiers: ["putNameGroupAndGroupMembers", "updateGroup"],
    }
  )
}
