import { useCallback, useMemo, useState } from "react"
import { joinOrganization } from "src/apis/organization/joinOrganization"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useFetcher } from "src/hooks/useFetcher"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { CustomAlert } from "src/utils/CustomAlert"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { TeamCustomQuestion } from "src/aws/API"
import { isEmptyString, validateKanas } from "src/utils/validate"

export const useRegisterProfileData = (
  organizationId: string,
  organizationName: string,
  requirementForMemberProfile: string | null,
  customQuestions: TeamCustomQuestion[],
  onSuccess: () => void
) => {
  const [nickname, setNickname] = useState("")
  const [kanaNickname, setKanaNickname] = useState("")
  const kanaNicknameErrorMessage = useMemo(
    () => (validateKanas(kanaNickname || "") ? "" : "カナで入力してください。"),
    [kanaNickname]
  )
  const { value: account } = useAsyncSelector(accountSelector)
  const requestData = useRequestData()
  const email = useMemo(() => account?.email, [account])
  const [phoneNumber, setPhoneNumber] = useState("")
  const [affiliation, setAffiliation] = useState("")
  const [isShowEmail, setIsShowEmail] = useState(false)
  const [isShowPhoneNumber, setIsShowPhoneNumber] = useState(false)
  const askPhoneNumber = useMemo(() => customQuestions.includes(TeamCustomQuestion.phoneNumber), [customQuestions])
  const [isShowAffiliation, setIsShowAffiliation] = useState(false)
  const askAffiliation = useMemo(() => customQuestions.includes(TeamCustomQuestion.belongs), [customQuestions])
  const [gradeId, setGradeId] = useState<number>()
  const askGrade = useMemo(() => customQuestions.includes(TeamCustomQuestion.grade), [customQuestions])
  const isDisabled = useMemo(
    () => isEmptyString(nickname) || isEmptyString(kanaNickname) || kanaNicknameErrorMessage !== "",
    [nickname, kanaNickname, kanaNicknameErrorMessage]
  )

  const { fetch: join, isFetching: isProcessing } = useFetcher(
    useCallback(async () => {
      if (!requestData.isOk || isDisabled) {
        return
      }
      const { accountId, accessToken } = requestData.content

      const result = await joinOrganization({
        accessToken,
        accountId,
        organizationId,
        nickname,
        kanaNickname,
        isShowEmail,
        phoneNumber,
        isShowPhoneNumber,
        affiliation,
        isShowAffiliation,
        gradeId,
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      await CustomAlert.alert(
        "完了",
        organizationName + "に参加申請を送信しました。\n代表者の承認後、団体ページのご利用が可能となります。"
      )
      onSuccess()
    }, [
      requestData,
      organizationId,
      nickname,
      kanaNickname,
      isShowEmail,
      phoneNumber,
      isShowPhoneNumber,
      affiliation,
      isShowAffiliation,
      gradeId,
      onSuccess,
      organizationName,
      isDisabled,
    ])
  )

  return {
    account,
    nickname,
    setNickname,
    kanaNickname,
    setKanaNickname,
    kanaNicknameErrorMessage,
    join,
    isProcessing,
    email,
    isShowEmail,
    setIsShowEmail,
    phoneNumber,
    setPhoneNumber,
    isShowPhoneNumber,
    setIsShowPhoneNumber,
    affiliation,
    setAffiliation,
    isShowAffiliation,
    setIsShowAffiliation,
    gradeId,
    setGradeId,
    askPhoneNumber,
    askAffiliation,
    askGrade,
    isDisabled,
  }
}
