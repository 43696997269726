/* eslint-disable react-hooks/exhaustive-deps */
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { StyleSheet, Text, View } from "react-native"
import Accordion from "react-native-collapsible/Accordion"
import { Button } from "src/components/parts/buttons/Button"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ChevronDownIcon } from "src/components/parts/icons/ChevronDownIcon"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { AnswerType, AnswerTypeLabel, answerTypeOptions } from "src/constants/AnswerType"
import { Colors } from "src/constants/Colors"
import { Question, Questions } from "src/constants/Questions"
import { PlusIcon } from "src/components/parts/icons/PlusIcon"
import { ButtonType, IconButton } from "src/components/parts/buttons/IconButton"
import { CustomAlert } from "src/utils/CustomAlert"
import { ChevronUpIcon } from "src/components/parts/icons/ChevronUpIcon"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"

type Props = {
  questions: Questions
  onQuestionsChange: (next: Questions) => void
  readOnly?: boolean
}

const maxQuestionsCount = 5
const maxChoicesCount = 10

export const QuestionsEditor = memo<Props>(({ questions, onQuestionsChange, readOnly }) => {
  const [activeSections, setActiveSections] = useState<number[]>([])
  const canAddNewQuestion = useMemo(
    () => questions.every((q) => q.title && q.choices.length > 0 && q.choices.every((c) => c)),
    [questions]
  )

  const canAddNewChoice = useCallback((choices: string[]) => {
    return choices.every((c) => c)
  }, [])

  const addNewQuestion = useCallback(() => {
    if (!canAddNewQuestion) return
    const nextQuestions: Questions = [
      ...questions,
      {
        title: "",
        answerType: AnswerType.Single,
        choices: [""],
      },
    ]
    onQuestionsChange(nextQuestions)
  }, [questions, onQuestionsChange, canAddNewQuestion])

  const Header = useCallback(
    ({ title }: Question, index: number, isActive: boolean) => {
      const removeQuestion = async () => {
        const isConfirmed = await CustomAlert.confirm(
          "本当に削除しますか？",
          `質問内容・回答内容を含め、質問${index + 1}で入力した内容がすべて削除されます。`
        )
        if (!isConfirmed) {
          return
        }

        const nextQuestions = [...questions]
        nextQuestions.splice(index, 1)
        onQuestionsChange(nextQuestions)
        setActiveSections([])
      }

      return (
        <View style={styles.header}>
          <Text>質問{index + 1}</Text>
          {!isActive ? (
            <Text numberOfLines={1} ellipsizeMode="tail" style={styles.questionTitle}>
              {title}
            </Text>
          ) : null}
          {index === 0 && questions.length === 1 ? null : isActive ? (
            !readOnly ? (
              <Button onPress={removeQuestion} style={styles.removeButton}>
                <RemoveIcon />
                <Text style={styles.removeLabel}>削除</Text>
              </Button>
            ) : (
              <ChevronUpIcon />
            )
          ) : (
            <ChevronDownIcon />
          )}
        </View>
      )
    },
    [questions, onQuestionsChange, readOnly]
  )

  const Content = useCallback(
    ({ title, answerType, choices }: Question, index: number) => {
      const onTitleChange = (nextTitle: string) => {
        const nextQuestion = { ...questions[index] }
        nextQuestion.title = nextTitle
        const nextQuestions = [...questions]
        nextQuestions.splice(index, 1, nextQuestion)
        onQuestionsChange(nextQuestions)
      }
      const onAnswerTypeChange = (nextAnswerType: AnswerType) => {
        const nextQuestion = { ...questions[index] }
        nextQuestion.answerType = nextAnswerType
        const nextQuestions = [...questions]
        nextQuestions.splice(index, 1, nextQuestion)
        onQuestionsChange(nextQuestions)
      }
      const addNewChoice = () => {
        const nextChoices = [...choices, ""]
        const nextQuestion = { ...questions[index] }
        nextQuestion.choices = nextChoices
        const nextQuestions = [...questions]
        nextQuestions.splice(index, 1, nextQuestion)
        onQuestionsChange(nextQuestions)
      }

      return (
        <View style={styles.contentContainer}>
          <View style={styles.topLabelContainer}>
            <ItemLabel
              label="質問内容"
              required
              RightComponent={<InputLengthCounter text={title} maxLength={2000} unit={"字以内"} />}
            />
          </View>
          {!readOnly ? (
            <CustomTextInput value={title} onChangeText={onTitleChange} placeholder="質問のタイトルを入力" maxLength={2000} />
          ) : (
            <Text style={styles.readOnlyValue}>{title}</Text>
          )}
          <View style={styles.tailLabelContainer}>
            <ItemLabel label="回答方法" />
          </View>
          {!readOnly ? (
            <CustomSelect options={answerTypeOptions} value={answerType} onChange={onAnswerTypeChange} required />
          ) : (
            <Text style={styles.readOnlyValue}>{AnswerTypeLabel[answerType]}</Text>
          )}
          <View style={styles.tailLabelContainer}>
            <ItemLabel label="回答内容" required RightComponent={<Text style={styles.noteText}>{"100字以内"}</Text>} />
          </View>
          <View>
            {choices.map((choice, choiceIndex) => {
              const updateChoice = (nextChoice: string) => {
                const nextQuestions = [...questions]
                const nextChoices = [...choices]
                nextChoices[choiceIndex] = nextChoice
                const { choices: oldChoices, ...rest } = nextQuestions[index]
                nextQuestions[index] = {
                  choices: nextChoices,
                  ...rest,
                }
                onQuestionsChange(nextQuestions)
              }
              const removeChoice = () => {
                const nextQuestions = [...questions]
                const nextChoices = [...choices]
                nextChoices.splice(choiceIndex, 1)
                nextQuestions[index] = {
                  ...nextQuestions[index],
                  choices: nextChoices,
                }
                onQuestionsChange(nextQuestions)
              }

              return (
                <View style={styles.choice} key={`choices${choiceIndex}`}>
                  {!readOnly ? (
                    <>
                      <CustomTextInput
                        value={choice}
                        onChangeText={updateChoice}
                        style={styles.choiceInput}
                        maxLength={100}
                        editable={!readOnly}
                      />
                      <Button style={styles.removeChoiceButton} onPress={removeChoice}>
                        <RemoveIcon />
                      </Button>
                    </>
                  ) : (
                    <Text style={styles.readOnlyValue}>{choice}</Text>
                  )}
                </View>
              )
            })}
          </View>
          {!readOnly ? (
            <View style={styles.buttonContainer}>
              <IconButton
                IconComponent={<PlusIcon fillColor={Colors.orange} />}
                buttonType={ButtonType.Primary}
                title="回答を追加"
                onPress={addNewChoice}
                disabled={!canAddNewChoice(choices) || choices.length >= maxChoicesCount}
              />
            </View>
          ) : null}
        </View>
      )
    },
    [questions, onQuestionsChange, readOnly, canAddNewChoice]
  )

  useEffect(() => {
    if (questions.length === 0) {
      !readOnly && addNewQuestion()
    }
  }, [questions])

  return (
    <View style={styles.container}>
      <View style={styles.labelContainer}>
        <ItemLabel label="アンケート" required />
      </View>
      <Accordion
        activeSections={[...activeSections, ...questions.map((_, i) => i)]}
        sections={questions}
        renderHeader={Header}
        renderContent={Content}
        onChange={setActiveSections}
        underlayColor="transparent"
        sectionContainerStyle={styles.sectionContainer}
      />
      {!readOnly ? (
        <View style={styles.buttonContainer}>
          <IconButton
            IconComponent={<PlusIcon fillColor={Colors.orange} />}
            buttonType={ButtonType.Primary}
            title="質問を追加"
            onPress={addNewQuestion}
            disabled={!canAddNewQuestion || questions.length >= maxQuestionsCount}
          />
        </View>
      ) : null}
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.beige,
  },
  labelContainer: {
    paddingHorizontal: 16,
    paddingTop: 16,
    paddingBottom: 10,
  },
  sectionContainer: {
    backgroundColor: Colors.white3,
    borderRadius: 10,
    marginHorizontal: 16,
    marginVertical: 6,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
  },
  questionTitle: {
    fontWeight: "bold",
    flex: 1,
    paddingHorizontal: 12,
  },
  buttonContainer: {
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 16,
  },
  contentContainer: {
    padding: 16,
    paddingTop: 0,
  },
  topLabelContainer: {
    paddingBottom: 8,
  },
  tailLabelContainer: {
    paddingTop: 16,
    paddingBottom: 8,
  },
  removeButton: {
    flexDirection: "row",
    alignItems: "center",
  },
  removeLabel: {
    paddingLeft: 8,
  },
  choice: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingVertical: 4,
  },
  removeChoiceButton: {
    paddingLeft: 16,
  },
  choiceInput: {
    flex: 1,
  },
  readOnlyValue: {
    fontSize: 16,
    padding: 16,
  },
  noteText: {
    fontSize: 14,
  },
})
