import { memo, useCallback, useEffect, useMemo, useRef, useState } from "react"
import { FlatList, ListRenderItemInfo, Platform, StyleSheet, Text, View, useWindowDimensions } from "react-native"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { MemberListItem } from "src/components/parts/organizationTab/MemberListItem"
import { QueryBuilder } from "src/components/parts/QueryBuilder"
import { Colors } from "src/constants/Colors"
import { CreateOrEditGroupScreens } from "src/constants/CreateOrEditGroupScreens"
import { CreateOrEditGroupStackScreenProps, ValueOf } from "src/types.d"
import { OrganizationMemberOverview } from "src/types/organization/OrganizationMember"
import { CustomAlert } from "src/utils/CustomAlert"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { teamMemberRequestState } from "src/recoil/atoms/team/teamMemberRequestState"
import { useRecoilState, useSetRecoilState } from "recoil"
import { teamMembersState } from "src/recoil/atoms/team/teamMembersState"
import { addMemberSelectorFamily } from "src/recoil/selectors/organization/addMemberSelectorFamily"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { commonHeaderOptionsOnNestedScreen } from "src/constants/options/commonHeaderOptions"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { Screens } from "src/constants/Screens"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { oneMember } from "src/recoil/atoms/organization/teamMemberState"
import { useSafeAreaInsets } from "react-native-safe-area-context"

export type TransferApplicantAuthorityType = {
  organizationId: string
  memberId: string
  orderId?: string
  accountId?: string
}

const QueryValue = Object.freeze({
  Ungrouped: "Ungrouped",
} as const)
type QueryValue = ValueOf<typeof QueryValue>

type Props = CreateOrEditGroupStackScreenProps<typeof CreateOrEditGroupScreens.TransferOfApplicantAuthority>

export const ApplicantAuthorityTransfer = memo<Props>(
  ({
    navigation,
    route: {
      params: { organizationId, memberId, accountId },
    },
  }) => {
    const widthScreen = useWindowDimensions().width
    const heightScreen = useWindowDimensions().height
    const isLargeScreen = useCheckPCScreen()
    const refresher = useRefresher(teamMemberRequestState)
    const [page, setPage] = useState(1)
    const [debouncedSearchText, setDebouncedSearchText] = useState("")
    const [organizationMembers, setOrganizationMembers] = useRecoilState(teamMembersState)
    const [searchText, setSearchText] = useState("")
    const setApplicant = useSetRecoilState(oneMember)

    useEffect(() => {
      const handler = setTimeout(() => {
        setDebouncedSearchText(searchText)
      }, 500)
      return () => {
        clearTimeout(handler)
      }
    }, [searchText])

    const pageSize = useMemo(() => (heightScreen - 250 > 549 ? 20 : 10), [heightScreen])

    const { value: teamMembersValue, isLoading } = useAsyncSelector(
      addMemberSelectorFamily({
        organizationId: organizationId,
        getImage: true,
        groupId: "",
        name: debouncedSearchText,
        limit: pageSize,
        page: page,
        excludedIds: [],
        isRoleSort: true,
      })
    )

    const totalMember = useMemo(() => teamMembersValue?.total || 0, [teamMembersValue])
    const maxPages = useMemo(() => Math.ceil(totalMember / 10), [totalMember])
    const [checks, setChecks] = useState({ id: "", isAdministrator: false })
    const insets = useSafeAreaInsets()
    useEffect(() => {
      setOrganizationMembers([])
      setPage(1)
      if (!debouncedSearchText) {
        refresher()
      }
    }, [debouncedSearchText, refresher, setOrganizationMembers])

    useEffect(() => {
      if (teamMembersValue && teamMembersValue?.data) {
        const responeList = teamMembersValue.data?.filter((member) => member.id != memberId)
        setOrganizationMembers((prevMembers = []) => {
          const newMembers = responeList.filter((member) => !prevMembers.some((prevMember) => prevMember.id === member.id))
          return [...prevMembers, ...(newMembers ?? [])]
        })

        if (checks.id === "" && responeList.length > 0) {
          setApplicant(responeList[0])
          setChecks(responeList[0])
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [teamMembersValue, setOrganizationMembers])

    const loadMoreMembers = useCallback(() => {
      if (page < maxPages) {
        setPage((prevPage) => prevPage + 1)
      }
    }, [page, maxPages, setPage])

    const [filters, setFilters] = useState<QueryValue[]>([])
    const checksForTheFirstTime = useRef<{ [key: string]: boolean }>({})

    const ListHeader = useMemo(
      () => (
        <View style={[isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : {}]}>
          <QueryBuilder
            data={[]}
            searchText={searchText}
            onSearchTextChange={setSearchText}
            filters={filters}
            onFiltersChange={setFilters}
            isApplicantAuthorityTransfer={true}
          />
        </View>
      ),
      [isLargeScreen, widthScreen, searchText, filters]
    )

    const toggleCheck = useCallback(
      (item: OrganizationMemberOverview) => {
        setApplicant(item)
        setChecks(item)
        checksForTheFirstTime.current[item.id] = !checksForTheFirstTime.current[item.id]
      },
      [setApplicant]
    )

    const ListItem = useCallback(
      (info: ListRenderItemInfo<OrganizationMemberOverview>) => {
        return (
          <MemberListItem
            id={info.item.id}
            name={`${info.item.nickname} (${info.item.memberNumber})`}
            imageUrl={info.item.imageUrl}
            memberType={info.item.memberType}
            isLeader={info.item.isLeader}
            isSelectedForCheckbox={checks.id === info.item.id}
            toggleSelectedForCheckbox={() => toggleCheck(info.item)}
            customSelect={true}
            isCustomImg
            isApplicantAuthorityTransfer={true}
          />
        )
      },
      [checks, toggleCheck]
    )

    const ListFooter = useMemo(
      () =>
        !teamMembersValue?.total && !isLoading ? (
          <View style={[styles.footerList, { borderTopWidth: 0 }]}>
            <Text>一致するメンバーはいません</Text>
          </View>
        ) : null,
      [teamMembersValue, isLoading]
    )
    useEffect(() => {
      setOrganizationMembers([])
      setPage(1)
      refresher()
    }, [setOrganizationMembers, organizationId, refresher])

    const handleCertification = async () => {
      if (checks.id == "") {
        return
      }
      if (!checks.isAdministrator) {
        const isConfirmed = await CustomAlert.confirm(
          "確認",
          "申込者権限の譲渡は「代表者」のみが対象となります。選択したメンバーは申込者権限の譲渡後、権限が自動的に「代表者」に変更されますがよろしいですか？"
        )
        if (!isConfirmed) {
          return
        }
      }

      navigation.navigate(Screens.CreateOrEditGroupStack, {
        params: { organizationId, accountId },
        screen: "ConfirmationTransferApplicantAuthority",
      })
    }

    return (
      <CustomKeyboardAvoidingView
        style={[{ height: heightScreen - 64 }, Platform.OS === "web" ? {} : { paddingBottom: insets.bottom }]}
      >
        <View style={styles.headerDescriptionContainer}>
          <Text style={styles.headerDescription}>下記のメンバー一覧より、申込者権限を譲渡するメンバーを選択してください。</Text>
          <Text style={styles.headerDescription}>
            ※代表者以外のメンバーを選択した場合、自動的に権限が「代表者」に変更されます。
          </Text>
        </View>
        {ListHeader}
        <FlatList
          data={organizationMembers}
          renderItem={ListItem}
          keyExtractor={(data) => `${data.id}`}
          refreshControl={<CustomRefreshControl refreshing={isLoading} onRefresh={refresher} />}
          contentContainerStyle={[isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : {}]}
          onEndReached={!isLoading ? loadMoreMembers : undefined}
          ListFooterComponent={
            isLoading ? (
              <View style={styles.loading}>
                <LoadingIndicator />
              </View>
            ) : (
              ListFooter
            )
          }
        />
        <View
          style={[
            styles.footer,
            isLargeScreen ? { paddingBottom: 12, flexDirection: "row", justifyContent: "center" } : { paddingBottom: 32 },
          ]}
        >
          {isLargeScreen && (
            <TextButton
              style={{ width: 300, marginRight: 15 }}
              buttonType={ButtonType.Secondary}
              title={"戻る"}
              onPress={() => navigation.goBack()}
            />
          )}
          <TextButton
            style={isLargeScreen ? { width: 300 } : { marginBottom: 15 }}
            buttonType={ButtonType.Primary}
            title={"確認"}
            disabled={checks.id === ""}
            onPress={handleCertification}
          />
          {!isLargeScreen && (
            <TextButton buttonType={ButtonType.Secondary} title={"戻る"} onPress={() => navigation.goBack()} />
          )}
        </View>
      </CustomKeyboardAvoidingView>
    )
  }
)

export const applicantAuthorityTransferHeader: ScreenOptions = {
  ...commonHeaderOptionsOnNestedScreen,
  title: "申込者権限の譲渡",
}

const styles = StyleSheet.create({
  footerList: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flex: 1,
  },
  headerDescriptionContainer: {
    paddingVertical: 18,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 18,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  headerDescription: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.warmGrey,
  },
  separator: {
    height: 1,
    backgroundColor: Colors.white2,
  },
  footer: {
    width: "100%",
    paddingHorizontal: 24,
    paddingVertical: 12,
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
})
