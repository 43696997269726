import { atom, selector } from "recoil"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"

export const firstNameState = atom({
  key: "atoms/account/firstNameState",
  default: selector({
    key: "atoms/account/firstNameState_default",
    get: ({ get }) => {
      const account = get(accountSelector)
      return account?.firstName
    },
  }),
})
