import { useEffect } from "react"
import { StyleSheet } from "react-native"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"

import { View } from "src/components/parts/Themed"
import { Screens } from "src/constants/Screens"
import { RootStackScreenProps } from "src/types.d"

export default function NotFoundScreen({ navigation }: RootStackScreenProps<typeof Screens.NotFound>) {
  useEffect(() => {
    navigation.replace(Screens.Root)
  }, [navigation])
  return (
    <View style={styles.container}>
      <LoadingIndicator />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
  },
})
