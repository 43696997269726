import { selectorFamily } from "recoil"
import { getInboxContacts } from "src/apis/contact/getInboxContacts"
import { inboxContactsRequestIdState } from "src/recoil/atoms/contact/inboxContactsRequestIdState"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { ContactFilterValue } from "src/types/contact/ContactFilter"
import { CustomAlert } from "src/utils/CustomAlert"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { multiTeamMemberImageUrls } from "src/apis/team/getMemberImageUrl"

interface TeamMemberImageType {
  isDefault?: boolean
  teamMemberId: string
  url: string
}

export const inboxContactsSelectorFamily = selectorFamily<
  { contacts: ContactOverviewModel[]; nextToken?: string } | undefined,
  { searchText?: string; filters?: ContactFilterValue[]; count?: number; organizationId?: string; nextToken?: string }
>({
  key: "selectors/contact/inboxContactsSelectorFamily",
  get:
    ({ searchText, filters, count, organizationId, nextToken }) =>
    async ({ get }) => {
      get(inboxContactsRequestIdState)
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) {
        return
      }

      const organizationId_ = organizationId || get(selectedMyOrganizationSelector)?.id
      if (organizationId_ == null) {
        return
      }
      const myProfile = get(myProfileSelectorFamily(organizationId_))
      if (myProfile == null) {
        return
      }
      const result = await getInboxContacts({
        accessToken,
        myMemberId: myProfile.memberId,
        searchText,
        organizationId: organizationId_,
        filters,
        count,
        nextToken,
      })
      if (!result.isOk) {
        CustomAlert.alert("エラー", result.error.message)
        return
      }
      const uniqueSenders: any = {}
      const expect: any = []
      result.content.contacts.forEach((item) => {
        if (!uniqueSenders[item.senderId]) {
          uniqueSenders[item.senderId] = true
          expect.push({
            teamMemberId: item.senderId,
            thumbnail: false,
          })
        }
      })
      const dataImages = await multiTeamMemberImageUrls({
        accessToken: accessToken,
        teamMemberImages: expect,
      })
      let teamMemberImage: TeamMemberImageType[] = []
      if (dataImages.isOk) teamMemberImage = dataImages.content

      const accountImageMap: Record<string, string> =
        teamMemberImage?.reduce((map, ele) => {
          const baseUrl = ele.url.split("?")[0]
          return {
            ...map,
            [ele.teamMemberId]: baseUrl,
          }
        }, {}) ?? {}

      const resultAddImageUrl = result.content.contacts.map((item) => {
        return {
          ...item,
          imgUrl: `${accountImageMap[item.senderId ?? ""] ?? "default/account/image/profile.png"}?${new Date().getTime()}`,
        }
      })
      const mixResult = { contacts: resultAddImageUrl, nextToken: result.content.nextToken }

      return mixResult
    },
})
