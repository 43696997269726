import { memo } from "react"
import { Svg, Path } from "react-native-svg"

export const TeamsIcon = memo(() => (
  <Svg width="15.805" height="17.507" viewBox="0 0 15.805 17.507">
    <Path
      d="M22.584 20.226H20.1l3.586-3.5v2.4a1.1 1.1 0 0 1-1.1 1.1zM35.024 17v15.313a1.1 1.1 0 0 1-1.1 1.1H20.318a1.1 1.1 0 0 1-1.1-1.1V21.429h3.366a2.308 2.308 0 0 0 2.3-2.3v-3.224h9.038a1.1 1.1 0 0 1 1.1 1.1zm-3.306 11.75a.6.6 0 0 0-.6-.6h-7.992a.6.6 0 0 0 0 1.2h7.991a.6.6 0 0 0 .602-.595zm0-4.1a.6.6 0 0 0-.6-.6h-7.992a.6.6 0 0 0 0 1.2h7.991a.6.6 0 0 0 .602-.591zm0-4.1a.6.6 0 0 0-.6-.6h-2.464a.6.6 0 0 0 0 1.2h2.463a.6.6 0 0 0 .602-.587z"
      transform="translate(-19.219 -15.905)"
      fill={"#f2caa0"}
    />
  </Svg>
))
