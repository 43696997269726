import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useFetcher } from "src/hooks/useFetcher"
import { useIncrementRecoilState } from "src/recoil/hooks/useIncrementRecoilState"
import { useAsyncState } from "src/hooks/useAsyncState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { updateFavoriteContactInbox } from "src/apis/contact/updateFavoriteContactInbox"
import { CustomAlert } from "src/utils/CustomAlert"
import { contactDetailSelectorFamily } from "src/recoil/selectors/contact/contactDetailSelectorFamily"
import { postAttendanceAnswer } from "src/apis/contact/postAttendanceAnswer"
import { postSurveyAnswer, SurveyAnswer } from "src/apis/contact/postSurveyAnswer"
import { postScheduleAnswer } from "src/apis/contact/postScheduleAnswer"
import { postContactTemplate } from "src/apis/contact/postContactTemplate"
import { AttendanceType } from "src/constants/AttendanceType"
import { contactReplyThreadRequestIdState } from "src/recoil/atoms/contact/contactReplyThreadRequestIdState"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { ScheduleAnswerType } from "src/constants/ScheduleAnswerType"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { useResetter } from "src/recoil/hooks/screens/contact/create/useResetter"
import { deleteContact } from "src/apis/contact/deleteContact"
import { useNavigation } from "@react-navigation/native"
import { contactReplyThreadsSelectorFamily } from "src/recoil/selectors/contact/contactReplyThreadsSelectorFamily"
import { useRefresher } from "src/hooks/useRefresher"
import { contactReplyThreadsRequestIdState } from "src/recoil/atoms/contact/contactReplyThreadsRequestIdState"
import { sendReminder as sendReminderApi } from "src/apis/contact/sendReminder"
import { useTeamMemberContacts } from "src/recoil/hooks/screens/contact/useTeamMemberContacts"
import { Screens } from "src/constants/Screens"
import { CreateNewContactScreens } from "src/constants/CreateNewContactScreens"
import { outboxContactsRequestIdState } from "src/recoil/atoms/contact/outboxContactsRequestIdState"
import { templateContactsRequestIdState } from "src/recoil/atoms/contact/templateContactsRequestIdState"
import { unreadThreadCountRequestIdState } from "src/recoil/atoms/contact/unreadThreadCountRequestIdState"
import { hasUnreadThreadMessageRequestIdState } from "src/recoil/atoms/contact/hasUnreadThreadMessageRequestIdState"
import { wait } from "src/utils/wait"
import { useResetRecoilState, useSetRecoilState } from "recoil"
import {
  inboxContactFavoriteFlgsCacheState,
  migrationContactFavoriteFlgsCacheState,
  outboxContactFavoriteFlgsCacheState,
} from "src/recoil/atoms/contact/contactFavoriteFlgsCacheState"
import { Analytics } from "src/tags/analytics/Analytics"
import { updateFavoriteContactOutbox } from "src/apis/contact/updateFavoriteContactOutbox"
import { updateFavoriteContactMigration } from "src/apis/contact/updateFavoriteContactMigration"
import { ApiResult } from "src/apis/execApi"
import { unreadThreadCountPerContactState } from "src/recoil/atoms/contact/unreadThreadCountPerContactState"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { updateCommentApi } from "src/apis/comment/updateComment"
import { contactDetailRequestIdState } from "src/recoil/atoms/contact/contactDetailRequestIdState"
import { deleteCommentApi } from "src/apis/comment/deleteComment"

type Params = {
  contactId?: string
  viewMode: "Inbox" | "Outbox" | "Reserved" | "Migration"
}

export const useContactDetailData = ({ contactId, viewMode }: Params) => {
  const { value: selectedMyOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const { value, isLoading } = useAsyncSelector(contactDetailSelectorFamily({ contactId, type: viewMode }))
  const [unreadStatePerContact, setUnreadStatePerContact] = useAsyncState(unreadThreadCountPerContactState)
  const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(selectedMyOrganization?.id))
  const [isSenderMenuBottomSheetOpen, setIsSenderMenuBottomSheetOpen] = useState(false)
  const organizationId = useMemo(() => selectedMyOrganization?.id, [selectedMyOrganization])
  const organizationName = useMemo(() => selectedMyOrganization?.title, [selectedMyOrganization])
  const myMemberId = useMemo(() => myProfile?.memberId, [myProfile])
  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const { value: contactReplyThreads } = useAsyncSelector(contactReplyThreadsSelectorFamily({ contactId, viewMode }))
  const { value: organization } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))
  const [comment, setComment] = useState("")
  const [isEditComment, setIsEditComment] = useState(false)

  useEffect(() => {
    if (value?.comment) {
      setComment(value.comment)
    } else {
      setComment("")
    }
  }, [value?.comment, contactId])

  useEffect(() => {
    if (contactReplyThreads && unreadStatePerContact) {
      const totalUnreadCount = contactReplyThreads.reduce((acc, thread) => {
        return acc + thread.unreadMessageCount
      }, 0)

      if (totalUnreadCount !== undefined) {
        const updatedUnreadStatePerContact = [...unreadStatePerContact]

        const existingItemIndex = updatedUnreadStatePerContact.findIndex((item) => item.key === contactId)

        if (existingItemIndex !== -1) {
          updatedUnreadStatePerContact[existingItemIndex] = {
            doc_count: totalUnreadCount,
            key: contactId ?? "",
          }
        } else {
          updatedUnreadStatePerContact.push({
            doc_count: totalUnreadCount,
            key: contactId ?? "",
          })
        }

        setUnreadStatePerContact(updatedUnreadStatePerContact)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId, contactReplyThreads])
  const isSender = useMemo(
    () => value?.sender.memberId != null && myMemberId != null && value.sender.memberId === myMemberId,
    [myMemberId, value]
  )
  useEffect(() => {
    if (contactReplyThreads && unreadStatePerContact) {
      const totalUnreadCount = contactReplyThreads.reduce((acc, thread) => {
        return acc + thread.unreadMessageCount
      }, 0)

      if (totalUnreadCount !== undefined) {
        const updatedUnreadStatePerContact = [...unreadStatePerContact]

        const existingItemIndex = updatedUnreadStatePerContact.findIndex((item) => item.key === contactId)

        if (existingItemIndex !== -1) {
          updatedUnreadStatePerContact[existingItemIndex] = {
            doc_count: totalUnreadCount,
            key: contactId ?? "",
          }
        } else {
          updatedUnreadStatePerContact.push({
            doc_count: totalUnreadCount,
            key: contactId ?? "",
          })
        }

        setUnreadStatePerContact(updatedUnreadStatePerContact)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contactId, contactReplyThreads])
  const { resourceUrl: senderImageUrl, refreshResourceUrl: refreshSenderImage } = useResource(
    value?.sender.memberId
      ? {
          type: "teamMemberImage",
          id: value.sender.memberId,
          size: "thumbnail",
        }
      : { type: "none" }
  )
  const receiverText = useMemo(() => {
    const receivers = value?.receivers
    if (receivers == null) return ""

    const texts: string[] = []

    if (receivers.isAllMembers) {
      texts.push("メンバー全員")
    } else {
      if (receivers.isAllLeaders) {
        texts.push("代表者全員")
      }
      if (receivers.groupIds && receivers.groupIds.length > 0) {
        texts.push(`グループ指定（${receivers.groupIds.length}）`)
      }
      if (receivers.memberIds && receivers.memberIds.length > 0) {
        texts.push(`メンバー指定（${receivers.memberIds.length}）`)
      }
    }
    return `To: ${texts.join("，")}`
  }, [value])

  const setInboxFavoriteFlgsCache = useSetRecoilState(inboxContactFavoriteFlgsCacheState)
  const setOutboxFavoriteFlgsCache = useSetRecoilState(outboxContactFavoriteFlgsCacheState)
  const setMigrationFavoriteFlgsCache = useSetRecoilState(migrationContactFavoriteFlgsCacheState)
  const { fetch: execUpdateContactDetailFavorite, data: isNewFavorite } = useFetcher(
    useCallback(
      async (isFavorite: boolean) => {
        if (accessToken == null || myMemberId == null || contactId == null) {
          return
        }

        let result: ApiResult<any> | undefined = undefined
        if (viewMode === "Inbox") {
          result = await updateFavoriteContactInbox({
            accessToken,
            contactId,
            myMemberId,
            isFavorite,
          })
          if (result.isOk) setInboxFavoriteFlgsCache((prev) => ({ ...prev, [contactId]: isFavorite }))
        } else if (viewMode === "Outbox") {
          result = await updateFavoriteContactOutbox({
            accessToken,
            contactId,
            myMemberId,
            isFavorite,
          })
          if (result.isOk) setOutboxFavoriteFlgsCache((prev) => ({ ...prev, [contactId]: isFavorite }))
        } else if (viewMode === "Migration") {
          result = await updateFavoriteContactMigration({
            accessToken,
            contactId,
            isFavorite,
          })
          if (result.isOk) setMigrationFavoriteFlgsCache((prev) => ({ ...prev, [contactId]: isFavorite }))
        } else {
          return
        }

        if (!result.isOk) {
          await CustomAlert.alert("エラー", result.error.message)
          return
        }
        return isFavorite
      },
      [
        accessToken,
        myMemberId,
        contactId,
        viewMode,
        setInboxFavoriteFlgsCache,
        setOutboxFavoriteFlgsCache,
        setMigrationFavoriteFlgsCache,
      ]
    )
  )
  const isFavorite = useMemo(() => isNewFavorite ?? value?.isFavorite ?? false, [isNewFavorite, value?.isFavorite])

  //Comment
  const refreshContactDetail = useRefresher(contactDetailRequestIdState)

  const { fetch: updateOrCreateCommentAPI } = useFetcher(
    useCallback(async () => {
      if (!accessToken || !value?.contactId || !myMemberId) return
      try {
        const result = await updateCommentApi({
          accessToken,
          mailId: value?.contactId ?? "",
          memberId: myMemberId ?? "",
          comment: comment,
        })
        if (result.id) {
          if (isEditComment) {
            await CustomAlert.alert("完了", "編集内容を保存しました。")
          } else {
            await CustomAlert.alert("完了", "コメントを送信しました。")
          }
          refreshContactDetail()
          setIsEditComment(false)
        }
      } catch (error) {
        console.error("Failed to update comment:", error)
      }
    }, [accessToken, value?.contactId, myMemberId, comment, refreshContactDetail, isEditComment])
  )

  const { fetch: deleteCommentAPI } = useFetcher(
    useCallback(async () => {
      if (!accessToken || !value?.contactId || !myMemberId) return
      const confirmResult = await CustomAlert.confirm("確認", "コメントを削除しますか？")
      if (!confirmResult) return
      try {
        const result = await deleteCommentApi({
          accessToken,
          mailId: value?.contactId ?? "",
          memberId: myMemberId ?? "",
        })
        if (result.id) {
          refreshContactDetail()
          setIsEditComment(false)
          setComment("")
        }
      } catch (error) {
        console.error("Failed to delete comment:", error)
      }
    }, [accessToken, value?.contactId, myMemberId, refreshContactDetail])
  )

  const createComment = async () => {
    if (!value?.comment && !value?.commentSentAt) {
      await updateOrCreateCommentAPI()
    } else {
      setIsEditComment(true)
    }
  }

  const deleteComment = () => {
    if (isEditComment) {
      setComment(value?.comment ?? "")
      setIsEditComment(false)
    } else {
      deleteCommentAPI()
    }
  }

  //End Comment

  const { fetch: execPostAttendance, isFetching: isPostAttendanceExecuting } = useFetcher(
    useCallback(
      async (attendance: AttendanceType) => {
        if (accessToken == null || myMemberId == null || contactId == null) {
          return
        }
        const result = await postAttendanceAnswer({
          accessToken,
          contactId,
          myMemberId,
          attendance,
        })
        if (result.isOk) {
          await CustomAlert.alert("完了", "回答しました。")
        } else {
          if (result.error.errorCode == 400) {
            await CustomAlert.alert("エラー", "回答期限が過ぎているため、回答できません。")
          } else {
            await CustomAlert.alert("エラー", result.error.message)
          }
        }
      },
      [accessToken, contactId, myMemberId]
    )
  )

  const { fetch: execPostSurveyAnswer, isFetching: isPostSurveyAnswerExecuting } = useFetcher(
    useCallback(
      async (answer: SurveyAnswer[]) => {
        if (accessToken == null || myMemberId == null || contactId == null) {
          return
        }
        // questionIdがそのままindexに該当するため並び替え。念のため歯抜けになっていないことを確認
        const postAnswers = [...answer]
        postAnswers.sort((l, r) => (l.questionId < r.questionId ? -1 : 1))
        postAnswers.forEach((ans, index) => {
          if (Number(ans.questionId) !== index) {
            CustomAlert.alert("エラー", "予期せぬエラーが発生しました。")
            return
          }
        })

        const result = await postSurveyAnswer({
          accessToken,
          contactId,
          myMemberId,
          answers: postAnswers.map((ans) => ({ answer: ans.answerIds.map(Number) })),
        })
        if (result.isOk) {
          await CustomAlert.alert("完了", "回答しました。")
        } else {
          if (result.error.errorCode == 400) {
            await CustomAlert.alert("エラー", "回答期限が過ぎているため、回答できません。")
          } else {
            await CustomAlert.alert("エラー", result.error.message)
          }
        }
      },
      [accessToken, contactId, myMemberId]
    )
  )

  const { fetch: execPostScheduleAnswer, isFetching: isPostScheduleAnswerExecuting } = useFetcher(
    useCallback(
      async (answers: ScheduleAnswerType[]) => {
        if (accessToken == null || myMemberId == null || contactId == null) {
          return
        }

        const result = await postScheduleAnswer({
          accessToken,
          contactId,
          myMemberId,
          // この時点でans.answerがundefinedであることはないが、型を合わせるために定義されていないものはすべてPendに変換(実際の値の変換は無し)
          answers,
        })
        if (result.isOk) {
          await CustomAlert.alert("完了", "回答しました。")
        } else {
          if (result.error.errorCode == 400) {
            await CustomAlert.alert("エラー", "回答期限が過ぎているため、回答できません。")
          } else {
            await CustomAlert.alert("エラー", result.error.message)
          }
        }
      },
      [accessToken, contactId, myMemberId]
    )
  )

  const refreshOutboxContacts = useRefresher(outboxContactsRequestIdState)
  const resetFavoriteFlgsCache = useResetRecoilState(outboxContactFavoriteFlgsCacheState)
  const navigation = useNavigation()
  const { fetch: execDeleteContact, isFetching: isDeleteContactExecuting } = useFetcher(
    useCallback(async () => {
      if (accessToken == null || contactId == null) {
        return
      }
      const isConfirmed =
        viewMode === "Reserved"
          ? await CustomAlert.confirm(
              "送信予約を取り消しますか？",
              "※送信予約を取り消すと、連絡が完全に削除されます。\n元には戻せません。"
            )
          : await CustomAlert.confirm("確認", "本当に送信を取り消しますか？\n元には戻せません。")
      if (!isConfirmed) {
        setIsSenderMenuBottomSheetOpen(true)
        return
      }

      const result = await deleteContact({ accessToken, contactId })
      if (result.isOk) {
        await CustomAlert.alert("完了", `送信${viewMode === "Reserved" ? "予約" : ""}を取り消しました。`)
        await wait(2000)
        refreshOutboxContacts()
        resetFavoriteFlgsCache()
        navigation.goBack()
      } else {
        await CustomAlert.alert("エラー", result.error.message)
      }
    }, [accessToken, contactId, navigation, refreshOutboxContacts, resetFavoriteFlgsCache, viewMode])
  )

  const refreshTemplateContacts = useRefresher(templateContactsRequestIdState)
  const { fetch: execPostContactTemplate } = useFetcher(
    useCallback(async () => {
      if (accessToken == null || contactId == null) {
        return
      }
      const result = await postContactTemplate({ accessToken, contactId })
      if (result.isOk) {
        await CustomAlert.alert("完了", "テンプレートとして保存しました。")
        refreshTemplateContacts()
      } else {
        await CustomAlert.alert("エラー", result.error.message)
      }
    }, [accessToken, contactId, refreshTemplateContacts])
  )

  const {
    receivedMembers,
    readMembers,
    unreadMembers,
    answeredMembers,
    unansweredMembers,
    refreshTeamMemberContacts,
    teamMemberContacts,
  } = useTeamMemberContacts(contactId, viewMode)
  const refreshContactAttendanceAnswerOverview = useMemo(() => refreshTeamMemberContacts, [refreshTeamMemberContacts])
  const refreshContactSurveyAnswerOverview = useMemo(() => refreshTeamMemberContacts, [refreshTeamMemberContacts])
  const refreshContactScheduleAnswerOverview = useMemo(() => refreshTeamMemberContacts, [refreshTeamMemberContacts])

  const refreshContactReplyThread = useIncrementRecoilState(contactReplyThreadRequestIdState)

  const resetCreateNewContactScreenData = useResetter()

  const { fetch: sendReminder, isFetching: isSendingReminder } = useFetcher(
    useCallback(async () => {
      if (accessToken == null || contactId == null) return

      const isConfirmed = await CustomAlert.confirm(
        "確認",
        "未回答メンバーに再通知を行います。催促しますか？\n通知をオフにしているメンバーには届きませんのでご注意ください。"
      )
      if (!isConfirmed) return

      const result = await sendReminderApi({ accessToken, contactId })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      await CustomAlert.alert("完了", "未回答メンバーへ催促をしました。")
    }, [accessToken, contactId])
  )

  const _refreshContactReplyThreads = useRefresher(contactReplyThreadsRequestIdState)
  const _refreshUnreadThreadCount = useRefresher(unreadThreadCountRequestIdState)
  const _refreshHasUnreadThreadMessage = useRefresher(hasUnreadThreadMessageRequestIdState)
  const refreshContactReplyThreads = useCallback(() => {
    _refreshContactReplyThreads()
    _refreshUnreadThreadCount()
    _refreshHasUnreadThreadMessage()
  }, [_refreshContactReplyThreads, _refreshUnreadThreadCount, _refreshHasUnreadThreadMessage])

  const gotoCreateNewContactFromMigration = useCallback(async () => {
    if (organizationId != null) await Analytics.goToCreateMail(organizationId)
    resetCreateNewContactScreenData({ mode: "New", migrationId: contactId })
    navigation.navigate(Screens.CreateNewContactStack, { screen: CreateNewContactScreens.CreateNewContact })
  }, [navigation, resetCreateNewContactScreenData, contactId, organizationId])

  return {
    isLoading,
    value,
    organizationId,
    organizationName,
    myMemberId,
    contactReplyThreads,
    refreshContactReplyThreads,
    isSender,
    senderImageUrl,
    refreshSenderImage,
    isFavorite,
    unreadThreadCountPerContactState,
    receiverText,
    execUpdateContactDetailFavorite,
    execPostAttendance,
    isPostAttendanceExecuting,
    execPostSurveyAnswer,
    isPostSurveyAnswerExecuting,
    execPostScheduleAnswer,
    isPostScheduleAnswerExecuting,
    execDeleteContact,
    isDeleteContactExecuting,
    execPostContactTemplate,
    refreshContactAttendanceAnswerOverview,
    refreshContactSurveyAnswerOverview,
    refreshContactScheduleAnswerOverview,
    refreshContactReplyThread,
    unreadStatePerContact,
    resetCreateNewContactScreenData,
    sendReminder,
    isSendingReminder,
    receivedMembers,
    readMembers,
    setIsSenderMenuBottomSheetOpen,
    isSenderMenuBottomSheetOpen,
    unreadMembers,
    answeredMembers,
    unansweredMembers,
    refreshTeamMemberContacts,
    gotoCreateNewContactFromMigration,
    teamMemberContacts,
    organization,
    _refreshContactReplyThreads,
    _refreshHasUnreadThreadMessage,
    comment,
    setComment,
    deleteComment,
    isEditComment,
    setIsEditComment,
    createComment,
    updateOrCreateCommentAPI,
  }
}
