import { selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { attendanceAnswerCsvDataRequestIdState } from "src/recoil/atoms/csv/attendanceAnswerCsvDataRequestIdState"
import { AttendanceAnswerModel } from "src/types/csv/answerCsvData"
import { selectedMyOrganizationSelector } from "../organization/selectedMyOrganizationSelector"
import { getAttendanceAnswerCsvData } from "src/apis/csv/getAttendanceAnswerCsvData"

export const attendanceAnswerCsvDataSelectorFamily = selectorFamily<
  AttendanceAnswerModel[] | undefined,
  { mailId: string | undefined }
>({
  key: "selectors/csvData/attendanceAnswerCsvDataSelectorFamily",
  get:
    ({ mailId }) =>
    async ({ get }) => {
      get(attendanceAnswerCsvDataRequestIdState)
      if (mailId == undefined) return
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) return

      const selectedMyTeam = get(selectedMyOrganizationSelector)
      if (selectedMyTeam == null || mailId == null) return
      const teamId = selectedMyTeam.id
      const result = await getAttendanceAnswerCsvData({ accessToken, teamId, mailId })
      if (!result.isOk) {
        return
      }
      return result.content.sort((a, b) => {
        const memberSeqA = Number(a.memberSeq)
        const memberSeqB = Number(b.memberSeq)
        return memberSeqA - memberSeqB
      })
    },
})
