import { ComponentProps, memo } from "react"
import { EditIcon } from "src/components/parts/icons/EditIcon"
import { Colors } from "src/constants/Colors"
import { StyleSheet, Text } from "react-native"
import { Button } from "src/components/parts/buttons/Button"

export const EditButtonForHeader = memo<ComponentProps<typeof Button>>((props) => {
  return (
    <Button {...props} style={styles.buttonContainer}>
      <EditIcon fill={Colors.white3} />
      <Text style={styles.buttonText}>編集</Text>
    </Button>
  )
})

const styles = StyleSheet.create({
  buttonContainer: {
    borderRadius: 4,
    backgroundColor: "rgb(248, 151, 45)",
    paddingVertical: 5,
    paddingHorizontal: 15,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 13,
    marginRight: 15,
  },
  buttonText: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 26,
    color: Colors.white3,
    marginLeft: 8,
  },
})
