import { selector } from "recoil"
import { migrationTeamsRequestIdState } from "../../atoms/migration/migrationTeamsRequestIdState"
import { oldRa9Ra9TokenStates } from "../../atoms/migration/oldRa9Ra9TokenStates"
import { getMigrationTeams, TeamList } from "../../../apis/migration/getMigrationTeams"

export const migrationTeamsSelector = selector<TeamList[]>({
  key: "selectors/migration/migrationTeamsSelector",
  get: async ({ get }) => {
    get(migrationTeamsRequestIdState)
    const oldRa9Ra9AccessTokens = get(oldRa9Ra9TokenStates)
    const oldRa9Ra9AccessToken = oldRa9Ra9AccessTokens?.oldRa9Ra9AccessToken
    const oldRa9Ra9UserId = Number(oldRa9Ra9AccessTokens?.oldRa9Ra9UserId)

    if (oldRa9Ra9AccessToken == null || oldRa9Ra9UserId == null) return []

    return await getMigrationTeams({
      accessToken: oldRa9Ra9AccessToken,
      userId: oldRa9Ra9UserId,
    })
  },
})
