import { memo, useCallback, useMemo } from "react"
import { FlatList, ListRenderItemInfo, StyleSheet, Text, View } from "react-native"
import { ContactSurveyAnswerStackScreenProps } from "src/types.d"
import { Colors } from "src/constants/Colors"
import { ContactSurveyAnswerScreens } from "src/constants/ContactSurveyAnswerScreens"
import { useContactSurveyAnswerDetailData } from "src/recoil/hooks/screens/useContactSurveyAnswerDetailData"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import dayjs from "dayjs"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type ContactSurveyAnswerDetailParams = {
  contactId: string
  surveyId: string
  choiceId: string
}

export const ContactSurveyAnswerDetail = memo<
  ContactSurveyAnswerStackScreenProps<typeof ContactSurveyAnswerScreens.ContactSurveyAnswerDetail>
>(
  ({
    route: {
      params: { contactId, surveyId, choiceId },
    },
  }) => {
    const { choice, members, isLoading, refresher } = useContactSurveyAnswerDetailData(contactId, surveyId, choiceId)
    const isLargeScreen = useCheckPCScreen()

    const ListHeaderComponent = useMemo(
      () => (
        <View style={styles.titleContainer}>
          <Text style={styles.title}>{choice}</Text>
          <Text style={styles.subTitle}>{members.length}人</Text>
        </View>
      ),
      [choice, members]
    )

    const RenderItem = useCallback(
      (itemInfo: ListRenderItemInfo<{ nickname: string; answeredAt: Date | undefined }>) => (
        <>
          <View style={styles.rowContainer}>
            <Text style={styles.rowText} numberOfLines={1} ellipsizeMode="tail">
              {itemInfo.item.nickname}
            </Text>
            {itemInfo.item.answeredAt ? (
              <View style={styles.datetimeContainer}>
                <Text style={styles.dateText}>{dayjs(itemInfo.item.answeredAt).format("YYYY/MM/DD")}</Text>
                <Text style={styles.timeText}>{dayjs(itemInfo.item.answeredAt).format("HH:mm")}</Text>
              </View>
            ) : null}
          </View>
          <View style={styles.rowSeparator} />
        </>
      ),
      []
    )

    return (
      <CustomKeyboardAvoidingView style={[styles.container, isLargeScreen ? { width: 600, marginHorizontal: "auto" } : null]}>
        <FlatList
          data={members}
          renderItem={RenderItem}
          ListHeaderComponent={ListHeaderComponent}
          refreshControl={<CustomRefreshControl refreshing={isLoading} onRefresh={refresher} />}
        />
      </CustomKeyboardAvoidingView>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white3,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 13,
    paddingHorizontal: 22,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    width: "90%",
  },
  subTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.orange,
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 13,
    paddingHorizontal: 22,
  },
  rowText: {
    fontSize: 16,
    color: Colors.greyshBrown,
    flexShrink: 1,
  },
  datetimeContainer: {
    flexDirection: "row",
    marginLeft: 12,
  },
  dateText: {
    marginRight: 12,
    fontSize: 15,
  },
  timeText: {
    fontSize: 15,
  },
  rowSeparator: {
    width: "100%",
    height: 1,
    backgroundColor: Colors.lightGrey,
  },
})
