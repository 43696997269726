import { memo } from "react"
import { StyleSheet, Text } from "react-native"
import { Colors } from "src/constants/Colors"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { Button } from "src/components/parts/buttons/Button"

type Props = {
  title: string
  onPress: () => void
}

export const RemoveIconButton = memo<Props>(({ title, onPress }) => {
  return (
    <Button style={styles.container} onPress={onPress}>
      <RemoveIcon fill={Colors.greyshBrown} />
      <Text style={styles.title}>{title}</Text>
    </Button>
  )
})

const styles = StyleSheet.create({
  container: {
    padding: 14,
    borderRadius: 8,
    backgroundColor: "#f4f4f4",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: {
    marginLeft: 4,
    fontSize: 13,
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
})
