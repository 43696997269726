import { StyleSheet, Text, View, Linking, Platform } from "react-native"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel, ItemLabelLongText } from "src/components/parts/ItemLabel"
import { Button } from "src/components/parts/buttons/Button"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { ValidationErrorMessage } from "src/components/parts/ValidationErrorMessage"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { PlanData } from "src/recoil/atoms/planData/planDataState"
import { Colors } from "src/constants/Colors"
import { SelectOption } from "src/types/SelectOption"
import dayjs from "dayjs"
import { formatDate } from "src/constants/Day"
import { useMemo } from "react"
import { MIGRATE_PLAN_ID } from "src/utils/const"

type Props = {
  errorMessage: string
  planError: boolean
  plansOption?: SelectOption[]
  startTimeOptions: SelectOption[]
  startTimeError: boolean
  invoiceAddressError: string
  invoiceKanaAddressError: string
  mode: string
  planData: PlanData
  setPlanData: (next: PlanData) => void
  orderId?: string
  setLocalStorageRealTime: (next: PlanData) => void
  changePlan: (next: string | undefined) => void
  extend?: boolean
  // isMigratedPaidTeam: boolean | number | string | undefined
}

export const PlanEditor = ({
  errorMessage,
  planError,
  plansOption,
  startTimeOptions,
  startTimeError,
  mode,
  invoiceAddressError,
  invoiceKanaAddressError,
  planData,
  setPlanData,
  orderId,
  setLocalStorageRealTime,
  changePlan,
  extend,
}: // isMigratedPaidTeam,
Props) => {
  const Link = () => {
    if (mode === "init") {
      return (
        <>
          <Text style={{ fontSize: 14, fontWeight: "300", lineHeight: 19.6 }}>プラン一覧は</Text>
          <Button
            onPress={() => {
              Linking.openURL("https://www.ra9plus.jp/pay_service/#plan")
            }}
          >
            <Text
              style={{ fontSize: 14, fontWeight: "300", lineHeight: 19.6, color: "#F0830E", textDecorationLine: "underline" }}
            >
              こちら
            </Text>
          </Button>
        </>
      )
    } else return null
  }

  const showPlanDetail = (planData: PlanData) => {
    return (
      planData.planId === undefined || planData.planId === "" || !plansOption?.find((plan) => plan.value === planData.planId)
    )
  }

  const isMigratePlan = useMemo(() => planData.planId === MIGRATE_PLAN_ID, [planData.planId])

  return (
    <View style={[styles.container]}>
      <View style={[styles.row]}>
        <View style={styles.itemLabelWrapper}>
          <ItemLabel label="プラン" required RightComponent={<Link />} />
          <View style={[{ marginTop: 8 }]}></View>
          <CustomSelect
            options={plansOption ?? []}
            value={planData.planId}
            onChange={(e) => changePlan(e)}
            isError={planError}
          />
          {planError && <ValidationErrorMessage style={styles.validationErrorMessage} message={errorMessage} />}
        </View>
      </View>

      <View style={[styles.row]}>
        <View style={styles.itemLabelWrapper}>
          <ItemLabel label="人数上限" />
        </View>
        <Text style={[styles.textField]}>
          {showPlanDetail(planData) || planData.maxMembers === undefined
            ? "プランを選択すると自動表示されます。"
            : isMigratePlan
            ? ""
            : planData.maxMembers + `${planData.maxMembers == "" ? "" : "名"}`}
        </Text>
      </View>

      <View style={[styles.row]}>
        <View style={styles.itemLabelWrapper}>
          <ItemLabel label="利用期間" />
        </View>
        <Text style={[styles.textField]}>
          {showPlanDetail(planData) || planData.limitTime === undefined
            ? "プランを選択すると自動表示されます。"
            : isMigratePlan
            ? ""
            : planData.limitTime + `${planData.limitTime == "" ? "" : "カ月"}`}
        </Text>
      </View>

      <View style={[styles.row]}>
        <View style={styles.itemLabelWrapper}>
          <ItemLabel label="料金（税込）" />
        </View>
        <Text style={[styles.textField]}>
          {showPlanDetail(planData)
            ? "プランを選択すると自動表示されます。" || planData.price === undefined
            : isMigratePlan
            ? ""
            : `${planData.price}` + `${planData.price == "" ? "" : "円"}`}
        </Text>
      </View>

      {planData.planId !== "1" && (
        <View style={[styles.row]}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="利用開始希望月" required />
            <Text style={[styles.textField]}>
              当月から開始とした場合も経過した日数に関係なく1ヵ月分の料金が発生しますのでご注意ください。
            </Text>
            <CustomSelect
              options={startTimeOptions}
              value={planData.startTime}
              onChange={(e: any) => {
                setPlanData({
                  ...planData,
                  startTime: e !== "undefined" ? e : undefined,
                  startTimeConvert: `${dayjs().add(parseInt(e), "month").startOf("month").format(formatDate.normal)}`,
                })
                setLocalStorageRealTime({ ...planData, startTime: e })
              }}
              isError={startTimeError}
              disabled={extend}
            />
            {startTimeError && <ValidationErrorMessage style={styles.validationErrorMessage} message={errorMessage} />}
          </View>
        </View>
      )}

      {!orderId && (
        <>
          <View style={[styles.row]}>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="入金方法" />
            </View>
            <Text style={[styles.textField]}>指定口座への振込</Text>
          </View>
        </>
      )}
      {!orderId ? (
        <View style={styles.row}>
          <ItemLabelLongText
            label="請求書の宛名[振込名義]"
            required
            RightComponent={<InputLengthCounter text={planData.invoiceAddress} maxLength={30} unit={"字以内"} />}
          />
          <CustomTextInput
            value={planData.invoiceAddress === undefined ? "" : planData.invoiceAddress}
            onChangeText={(e) => {
              setPlanData({ ...planData, invoiceAddress: e })
              setLocalStorageRealTime({ ...planData, invoiceAddress: e })
            }}
            style={styles.input}
            maxLength={30}
            placeholder="入力してください"
            isError={invoiceAddressError !== ""}
          />
          {invoiceAddressError !== "" && (
            <ValidationErrorMessage style={styles.validationErrorMessage} message={invoiceAddressError} />
          )}
        </View>
      ) : (
        <View style={styles.row}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="請求書の宛名[振込名義]" />
            <Text style={[styles.textField]}>{planData.invoiceAddress}</Text>
          </View>
        </View>
      )}
      {!orderId ? (
        <View style={styles.row}>
          <ItemLabelLongText
            label="請求書の宛名[振込名義]（フリガナ）"
            required
            RightComponent={<InputLengthCounter text={planData.invoiceKanaAddress} maxLength={50} unit={"字以内"} />}
          />
          <CustomTextInput
            value={planData.invoiceKanaAddress === undefined ? "" : planData.invoiceKanaAddress}
            onChangeText={(e) => {
              setPlanData({ ...planData, invoiceKanaAddress: e })
              setLocalStorageRealTime({ ...planData, invoiceKanaAddress: e })
            }}
            style={styles.input}
            maxLength={50}
            placeholder="入力してください"
            isError={invoiceKanaAddressError !== ""}
          />
          {invoiceKanaAddressError !== "" && (
            <ValidationErrorMessage style={styles.validationErrorMessage} message={invoiceKanaAddressError} />
          )}
        </View>
      ) : null}

      <View style={styles.row}>
        <View style={styles.itemLabelWrapper}>
          <ItemLabel
            label="備考欄"
            RightComponent={<InputLengthCounter text={planData.remark} maxLength={2000} unit={"字以内"} />}
          />
        </View>
        <MultilineTextInput
          value={planData.remark}
          onChangeText={(e) => {
            setPlanData({ ...planData, remark: e })
            setLocalStorageRealTime({ ...planData, remark: e })
          }}
          style={[styles.inputMulti, { height: 240 }, Platform.OS !== "ios" && { paddingVertical: 14 }]}
          maxLength={2000}
          numberOfLines={7}
          placeholder="入力してください"
        />
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    maxWidth: 600,
  },
  row: {
    marginBottom: 16,
    width: "100%",
  },
  requiredItemNoteText: {
    fontWeight: "400",
    fontSize: 14,
  },
  requiredItemNoteAsterisk: {
    color: Colors.red,
  },
  itemLabelWrapper: {
    marginBottom: 4,
  },
  textField: {
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 22.4,
    marginVertical: 14,
    textAlign: "left",
    width: "100%",
  },
  radioContainer: {
    flexDirection: "row",
    marginTop: 4,
    paddingVertical: 14,
  },
  radioItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 16,
  },
  text: {
    width: "100%",
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "300",
  },
  validationErrorMessage: {
    marginTop: 5,
  },
  input: {
    flex: 1,
    marginTop: 8,
    paddingHorizontal: 12,
    paddingVertical: 14,
  },
  inputMulti: {
    flex: 1,
    marginTop: 8,
    paddingLeft: 12,
  },
  grayButton: {
    backgroundColor: "rgba(159, 134, 107, 0.1)",
    paddingHorizontal: 24,
    paddingVertical: 12,
    borderRadius: 8,
    alignItems: "center",
    marginTop: 10,
    maxWidth: 191,
  },
  textGrayButton: {
    fontSize: 13,
    lineHeight: 18.2,
    fontWeight: "600",
  },
})
