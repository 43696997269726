import { memo } from "react"
import { StyleSheet, View } from "react-native"
import { BackIcon } from "./icons/BackIcon"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { Colors } from "src/constants/Colors"
import { Button } from "src/components/parts/buttons/Button"

type Props = {
  goBack: () => void
  canGoBack?: boolean
  isCloseIcon?: boolean
}

/**
 * @deprecated Use `HeaderLeft`.
 */
export const HeaderLeftComponent = memo<Props>(({ canGoBack, goBack, isCloseIcon }) => {
  if (!canGoBack) {
    return null
  }

  return (
    <Button onPress={goBack}>
      {isCloseIcon ? (
        <View style={styles.removeIconWrapper}>
          <RemoveIcon fill={Colors.orange} />
        </View>
      ) : (
        <BackIcon fill={Colors.orange} />
      )}
    </Button>
  )
})

HeaderLeftComponent.displayName = "HeaderLeftComponent"

const styles = StyleSheet.create({
  removeIconWrapper: {
    width: 36,
    height: 36,
    justifyContent: "center",
    alignItems: "center",
  },
})
