import { Result } from "src/utils/Result"
import { listThreadByMailRecipient, listThreadByMailSender, ThreadMember } from "src/aws/customAPI"
import { ContactReplyThreadOverview } from "src/recoil/selectors/contact/contactReplyThreadsSelectorFamily"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type GetContactReplyThreadsRequestParams = {
  accessToken: string
  accountId: string
  contactId: string
  myMemberId: string
  viewMode: "Inbox" | "Outbox"
}

export type GetContactReplyThreadsRequestSuccess = ContactReplyThreadOverview[]

type GetContactReplyThreadsRequestFailure = {
  message: string
}

export const getContactReplyThreads = async (
  params: GetContactReplyThreadsRequestParams
): Promise<Result<GetContactReplyThreadsRequestSuccess, GetContactReplyThreadsRequestFailure>> => {
  const { accessToken, accountId, contactId, myMemberId } = params

  return execApi(
    () => (params.viewMode === "Inbox" ? listThreadByMailRecipient : listThreadByMailSender)(accessToken, {
      accountId,
      mailId: contactId,
      myMemberId,
      appInfo
    }),
    res => {
      return res.listThread.items.map(item => ({
        replyThreadId: item.id,
        partner: getPartner(myMemberId, item.mailSender, item.mailRecipient),
        recentMessage: {
          text: item.latestMessage.text,
          updatedAt: new Date(item.latestMessage.updatedAt),
        },
        unreadMessageCount: item.unreadMessageCount,
      }))
    },
    {
      identifiers: ["getContactReplyThreads", params.viewMode === "Inbox" ? "listThreadByMailRecipient": "listThreadByMailSender"]
    }
  )
}

const getPartner = (myMemberId: string, mailSender: ThreadMember, mailRecipient: ThreadMember) => {
  const partner = [mailSender, mailRecipient].find(m => m.id !== myMemberId)
  if (!partner) throw Error("返信メッセージの取得に失敗しました")
  return {
    id: partner.id,
    name: partner.nickname,
    isDeleted: partner.deleteFlg || false,
  }
}
