import { FC } from "react"
import Svg, { ClipPath, Defs, G, Path } from "react-native-svg"

export const WorkIcon: FC = () => (
  <Svg width="40.558" height="40.091" viewBox="0 0 40.558 40.091">
    <Defs>
      <ClipPath id="2ocbjrcj0a">
        <Path data-name="長方形 1572" fill="none" d="M0 0h40.558v40.091H0z" />
      </ClipPath>
    </Defs>
    <G data-name="グループ 2348">
      <G data-name="グループ 2347" clipPath="url(#2ocbjrcj0a)">
        <Path
          data-name="パス 954"
          d="M67 28.954h-3.7a.4.4 0 0 0-.4.4v2.11h4.5v-2.11a.4.4 0 0 0-.4-.4"
          transform="translate(-34.791 -16.015)"
          fill="#e73e58"
        />
        <Path
          data-name="パス 955"
          d="M65.957 34.572H53.881V57.4H66.75V35.366a.793.793 0 0 0-.793-.794"
          transform="translate(-29.801 -19.122)"
          fill="#c3a98f"
        />
        <Path
          data-name="パス 956"
          d="M78.048 34.572h-2.306a.794.794 0 0 1 .794.794V57.4h2.306V35.366a.794.794 0 0 0-.793-.794"
          transform="translate(-41.893 -19.122)"
          fill="#ac8f77"
        />
        <Path
          data-name="パス 957"
          d="M27.392 1.292H9.231a1.154 1.154 0 0 0-1.154 1.154v36.548h20.469V2.446a1.154 1.154 0 0 0-1.154-1.154"
          transform="translate(-4.467 -.715)"
          fill="#ffd38b"
        />
        <Path
          data-name="パス 958"
          d="M48.461 1.292h-2.292a1.154 1.154 0 0 1 1.154 1.155v36.547h2.293V2.447a1.155 1.155 0 0 0-1.154-1.155"
          transform="translate(-25.536 -.715)"
          fill="#ffbb4e"
        />
        <Path
          data-name="パス 959"
          d="M16.238 11.523h-2.7a.493.493 0 0 1-.493-.492V7.383a.493.493 0 0 1 .493-.492h2.7a.492.492 0 0 1 .492.492v3.647a.492.492 0 0 1-.492.492"
          transform="translate(-7.215 -3.812)"
          fill="#b4dee6"
        />
        <Path
          data-name="パス 960"
          d="M30.2 11.523h-2.7a.493.493 0 0 1-.492-.492V7.383a.493.493 0 0 1 .492-.492h2.7a.492.492 0 0 1 .492.492v3.647a.492.492 0 0 1-.492.492"
          transform="translate(-14.936 -3.812)"
          fill="#b4dee6"
        />
        <Path
          data-name="パス 961"
          d="M44.16 11.523h-2.7a.493.493 0 0 1-.492-.492V7.383a.493.493 0 0 1 .492-.492h2.7a.492.492 0 0 1 .492.492v3.647a.492.492 0 0 1-.492.492"
          transform="translate(-22.658 -3.812)"
          fill="#b4dee6"
        />
        <Path
          data-name="パス 962"
          d="M16.238 31.353h-2.7a.493.493 0 0 1-.493-.492v-3.648a.493.493 0 0 1 .493-.492h2.7a.492.492 0 0 1 .492.492v3.647a.492.492 0 0 1-.492.492"
          transform="translate(-7.215 -14.78)"
          fill="#b4dee6"
        />
        <Path
          data-name="パス 963"
          d="M30.2 31.353h-2.7a.493.493 0 0 1-.492-.492v-3.648a.493.493 0 0 1 .492-.492h2.7a.492.492 0 0 1 .492.492v3.647a.492.492 0 0 1-.492.492"
          transform="translate(-14.936 -14.78)"
          fill="#b4dee6"
        />
        <Path
          data-name="パス 964"
          d="M44.16 31.353h-2.7a.493.493 0 0 1-.492-.492v-3.648a.493.493 0 0 1 .492-.492h2.7a.492.492 0 0 1 .492.492v3.647a.492.492 0 0 1-.492.492"
          transform="translate(-22.658 -14.78)"
          fill="#b4dee6"
        />
        <Path
          data-name="パス 965"
          d="M16.238 51.182h-2.7a.493.493 0 0 1-.493-.492v-3.648a.493.493 0 0 1 .493-.492h2.7a.492.492 0 0 1 .492.492v3.648a.492.492 0 0 1-.492.492"
          transform="translate(-7.215 -25.747)"
          fill="#b4dee6"
        />
        <Path
          data-name="パス 966"
          d="M30.2 51.182h-2.7a.493.493 0 0 1-.492-.492v-3.648a.493.493 0 0 1 .492-.492h2.7a.492.492 0 0 1 .492.492v3.648a.492.492 0 0 1-.492.492"
          transform="translate(-14.936 -25.747)"
          fill="#b4dee6"
        />
        <Path
          data-name="パス 967"
          d="M44.16 51.182h-2.7a.493.493 0 0 1-.492-.492v-3.648a.493.493 0 0 1 .492-.492h2.7a.492.492 0 0 1 .492.492v3.648a.492.492 0 0 1-.492.492"
          transform="translate(-22.658 -25.747)"
          fill="#b4dee6"
        />
        <Path data-name="長方形 1571" transform="translate(12.069 29.665)" fill="#91d1dc" d="M0 0h3.687v7.921H0z" />
        <Path
          data-name="パス 968"
          d="M16.238 71.012h-2.7a.493.493 0 0 1-.493-.492v-3.648a.493.493 0 0 1 .493-.492h2.7a.492.492 0 0 1 .492.492v3.648a.493.493 0 0 1-.492.492"
          transform="translate(-7.215 -36.715)"
          fill="#b4dee6"
        />
        <Path
          data-name="パス 969"
          d="M44.16 71.012h-2.7a.493.493 0 0 1-.492-.492v-3.648a.493.493 0 0 1 .492-.492h2.7a.492.492 0 0 1 .492.492v3.648a.493.493 0 0 1-.492.492"
          transform="translate(-22.658 -36.715)"
          fill="#b4dee6"
        />
        <Path
          data-name="パス 970"
          d="M61.4 42.161h-3.085a.3.3 0 0 1-.3-.3v-1.988a.3.3 0 0 1 .3-.3H61.4a.3.3 0 0 1 .3.3v1.988a.3.3 0 0 1-.3.3"
          transform="translate(-32.089 -21.889)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 971"
          d="M73.679 42.161H70.59a.3.3 0 0 1-.3-.3v-1.988a.3.3 0 0 1 .3-.3h3.088a.3.3 0 0 1 .3.3v1.988a.3.3 0 0 1-.3.3"
          transform="translate(-38.878 -21.889)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 972"
          d="M61.4 55.075h-3.085a.3.3 0 0 1-.3-.3v-1.988a.3.3 0 0 1 .3-.3H61.4a.3.3 0 0 1 .3.3v1.988a.3.3 0 0 1-.3.3"
          transform="translate(-32.089 -29.031)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 973"
          d="M73.679 55.075H70.59a.3.3 0 0 1-.3-.3v-1.988a.3.3 0 0 1 .3-.3h3.088a.3.3 0 0 1 .3.3v1.988a.3.3 0 0 1-.3.3"
          transform="translate(-38.878 -29.031)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 974"
          d="M61.4 66.072h-3.085a.3.3 0 0 1-.3-.3v-1.988a.3.3 0 0 1 .3-.3H61.4a.3.3 0 0 1 .3.3v1.988a.3.3 0 0 1-.3.3"
          transform="translate(-32.089 -35.114)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 975"
          d="M73.679 66.072H70.59a.3.3 0 0 1-.3-.3v-1.988a.3.3 0 0 1 .3-.3h3.088a.3.3 0 0 1 .3.3v1.988a.3.3 0 0 1-.3.3"
          transform="translate(-38.878 -35.114)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 976"
          d="M73.679 76.6H70.59a.3.3 0 0 1-.3-.3v-1.992a.3.3 0 0 1 .3-.3h3.088a.3.3 0 0 1 .3.3V76.3a.3.3 0 0 1-.3.3"
          transform="translate(-38.878 -40.934)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 977"
          d="M59.248 79.213v-4.9a.3.3 0 0 1 .3-.3h2.309a.3.3 0 0 1 .3.3v4.9z"
          transform="translate(-32.77 -40.934)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 978"
          d="M45.871 6.891h-1.814v4.632h1.814a.492.492 0 0 0 .492-.492V7.383a.492.492 0 0 0-.492-.492"
          transform="translate(-24.368 -3.812)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 979"
          d="M45.871 26.721h-1.814v4.632h1.814a.492.492 0 0 0 .492-.492v-3.647a.492.492 0 0 0-.492-.493"
          transform="translate(-24.368 -14.78)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 980"
          d="M45.871 46.55h-1.814v4.632h1.814a.492.492 0 0 0 .492-.492v-3.648a.493.493 0 0 0-.492-.492"
          transform="translate(-24.368 -25.747)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 981"
          d="M31.91 46.55H30.1v4.632h1.81a.492.492 0 0 0 .493-.492v-3.648a.493.493 0 0 0-.493-.492"
          transform="translate(-16.647 -25.747)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 982"
          d="M17.948 46.55h-1.814v4.632h1.814a.492.492 0 0 0 .492-.492v-3.648a.493.493 0 0 0-.492-.492"
          transform="translate(-8.924 -25.747)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 983"
          d="M17.948 26.721h-1.814v4.632h1.814a.492.492 0 0 0 .492-.492v-3.647a.492.492 0 0 0-.492-.493"
          transform="translate(-8.924 -14.78)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 984"
          d="M17.948 6.891h-1.814v4.632h1.814a.492.492 0 0 0 .492-.492V7.383a.492.492 0 0 0-.492-.492"
          transform="translate(-8.924 -3.812)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 985"
          d="M31.91 26.721H30.1v4.632h1.81a.492.492 0 0 0 .493-.492v-3.647a.492.492 0 0 0-.493-.493"
          transform="translate(-16.647 -14.78)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 986"
          d="M31.91 6.891H30.1v4.632h1.81a.492.492 0 0 0 .493-.492V7.383a.492.492 0 0 0-.493-.492"
          transform="translate(-16.647 -3.812)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 987"
          d="M17.948 66.379h-1.814v4.632h1.814a.492.492 0 0 0 .492-.492v-3.648a.493.493 0 0 0-.492-.492"
          transform="translate(-8.924 -36.714)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 988"
          d="M45.871 66.379h-1.814v4.632h1.814a.492.492 0 0 0 .492-.492v-3.648a.493.493 0 0 0-.492-.492"
          transform="translate(-24.368 -36.714)"
          fill="#91d1dc"
        />
        <Path
          data-name="パス 989"
          d="M39.732 84.1H2.256a.964.964 0 1 0 0 1.927h37.476a.964.964 0 1 0 0-1.927"
          transform="translate(-.715 -46.518)"
          fill="#7f7f80"
        />
        <Path
          data-name="パス 990"
          d="M85.671 84.1H84.35v1.927h1.321a.964.964 0 1 0 0-1.927"
          transform="translate(-46.654 -46.518)"
          fill="#666"
        />
        <Path
          data-name="パス 991"
          d="M61.566 39.16a.878.878 0 0 0-.877-.877H57.6a.878.878 0 0 0-.876.877v1.988a.877.877 0 0 0 .876.876h3.088a.877.877 0 0 0 .877-.876zm-1.154 1.71h-2.533v-1.433h2.533z"
          transform="translate(-31.374 -21.174)"
          fill="#545151"
        />
        <Path
          data-name="パス 992"
          d="M73.841 39.16a.878.878 0 0 0-.877-.877h-3.087a.878.878 0 0 0-.877.877v1.988a.877.877 0 0 0 .877.876h3.088a.877.877 0 0 0 .877-.876zm-1.154 1.71h-2.533v-1.433h2.533z"
          transform="translate(-38.164 -21.174)"
          fill="#545151"
        />
        <Path
          data-name="パス 993"
          d="M61.566 52.073a.878.878 0 0 0-.877-.877H57.6a.878.878 0 0 0-.876.877v1.988a.878.878 0 0 0 .876.877h3.088a.878.878 0 0 0 .877-.877zm-1.154 1.711h-2.533v-1.433h2.533z"
          transform="translate(-31.374 -28.317)"
          fill="#545151"
        />
        <Path
          data-name="パス 994"
          d="M73.841 52.073a.878.878 0 0 0-.877-.877h-3.087a.878.878 0 0 0-.877.877v1.988a.878.878 0 0 0 .877.877h3.088a.878.878 0 0 0 .877-.877zm-1.154 1.711h-2.533v-1.433h2.533z"
          transform="translate(-38.164 -28.317)"
          fill="#545151"
        />
        <Path
          data-name="パス 995"
          d="M60.689 62.194H57.6a.878.878 0 0 0-.876.877v1.988a.878.878 0 0 0 .876.877h3.088a.878.878 0 0 0 .877-.877v-1.988a.878.878 0 0 0-.877-.877m-.278 2.587h-2.531v-1.433h2.533z"
          transform="translate(-31.374 -34.4)"
          fill="#545151"
        />
        <Path
          data-name="パス 996"
          d="M73.841 63.071a.878.878 0 0 0-.877-.877h-3.087a.878.878 0 0 0-.877.877v1.988a.878.878 0 0 0 .877.877h3.088a.878.878 0 0 0 .877-.877zm-1.154 1.71h-2.533v-1.433h2.533z"
          transform="translate(-38.164 -34.4)"
          fill="#545151"
        />
        <Path
          data-name="パス 997"
          d="M72.964 72.718h-3.087a.878.878 0 0 0-.877.877v1.988a.878.878 0 0 0 .877.876h3.088a.878.878 0 0 0 .877-.876v-1.988a.878.878 0 0 0-.877-.877m-.278 2.587h-2.533v-1.433h2.533z"
          transform="translate(-38.164 -40.22)"
          fill="#545151"
        />
        <Path
          data-name="パス 998"
          d="M12.823 11.386h2.7a1.071 1.071 0 0 0 1.07-1.069V6.67a1.071 1.071 0 0 0-1.07-1.07h-2.7a1.071 1.071 0 0 0-1.07 1.07v3.647a1.071 1.071 0 0 0 1.07 1.069m.084-4.632h2.533v3.478h-2.533z"
          transform="translate(-6.501 -3.098)"
          fill="#545151"
        />
        <Path
          data-name="パス 999"
          d="M26.784 11.386h2.7a1.071 1.071 0 0 0 1.07-1.069V6.67a1.071 1.071 0 0 0-1.07-1.07h-2.7a1.071 1.071 0 0 0-1.07 1.07v3.647a1.071 1.071 0 0 0 1.07 1.069m.084-4.632H29.4v3.478h-2.532z"
          transform="translate(-14.222 -3.098)"
          fill="#545151"
        />
        <Path
          data-name="パス 1000"
          d="M40.745 11.386h2.7a1.071 1.071 0 0 0 1.07-1.069V6.67a1.071 1.071 0 0 0-1.07-1.07h-2.7a1.071 1.071 0 0 0-1.07 1.07v3.647a1.071 1.071 0 0 0 1.07 1.069m.084-4.632h2.533v3.478h-2.533z"
          transform="translate(-21.944 -3.098)"
          fill="#545151"
        />
        <Path
          data-name="パス 1001"
          d="M11.753 30.146a1.071 1.071 0 0 0 1.07 1.07h2.7a1.071 1.071 0 0 0 1.07-1.07V26.5a1.071 1.071 0 0 0-1.07-1.07h-2.7a1.071 1.071 0 0 0-1.07 1.07zm1.154-3.562h2.533v3.478h-2.533z"
          transform="translate(-6.501 -14.065)"
          fill="#545151"
        />
        <Path
          data-name="パス 1002"
          d="M25.714 30.146a1.071 1.071 0 0 0 1.07 1.07h2.7a1.071 1.071 0 0 0 1.07-1.07V26.5a1.071 1.071 0 0 0-1.07-1.07h-2.7a1.071 1.071 0 0 0-1.07 1.07zm1.154-3.562H29.4v3.478h-2.532z"
          transform="translate(-14.222 -14.065)"
          fill="#545151"
        />
        <Path
          data-name="パス 1003"
          d="M39.675 30.146a1.071 1.071 0 0 0 1.07 1.07h2.7a1.071 1.071 0 0 0 1.07-1.07V26.5a1.071 1.071 0 0 0-1.07-1.07h-2.7a1.071 1.071 0 0 0-1.07 1.07zm1.154-3.562h2.533v3.478h-2.533z"
          transform="translate(-21.944 -14.065)"
          fill="#545151"
        />
        <Path
          data-name="パス 1004"
          d="M11.753 49.976a1.071 1.071 0 0 0 1.07 1.069h2.7a1.071 1.071 0 0 0 1.07-1.069v-3.647a1.071 1.071 0 0 0-1.07-1.07h-2.7a1.071 1.071 0 0 0-1.07 1.07zm1.154-3.563h2.533v3.478h-2.533z"
          transform="translate(-6.501 -25.033)"
          fill="#545151"
        />
        <Path
          data-name="パス 1005"
          d="M25.714 49.976a1.071 1.071 0 0 0 1.07 1.069h2.7a1.071 1.071 0 0 0 1.07-1.069v-3.647a1.071 1.071 0 0 0-1.07-1.07h-2.7a1.071 1.071 0 0 0-1.07 1.07zm1.154-3.563H29.4v3.478h-2.532z"
          transform="translate(-14.222 -25.033)"
          fill="#545151"
        />
        <Path
          data-name="パス 1006"
          d="M39.675 49.976a1.071 1.071 0 0 0 1.07 1.069h2.7a1.071 1.071 0 0 0 1.07-1.069v-3.647a1.071 1.071 0 0 0-1.07-1.07h-2.7a1.071 1.071 0 0 0-1.07 1.07zm1.154-3.563h2.533v3.478h-2.533z"
          transform="translate(-21.944 -25.033)"
          fill="#545151"
        />
        <Path
          data-name="パス 1007"
          d="M11.753 69.805a1.071 1.071 0 0 0 1.07 1.07h2.7a1.071 1.071 0 0 0 1.07-1.07v-3.647a1.071 1.071 0 0 0-1.07-1.07h-2.7a1.071 1.071 0 0 0-1.07 1.07zm1.154-3.562h2.533v3.478h-2.533z"
          transform="translate(-6.501 -36)"
          fill="#545151"
        />
        <Path
          data-name="パス 1008"
          d="M39.675 69.805a1.071 1.071 0 0 0 1.07 1.07h2.7a1.071 1.071 0 0 0 1.07-1.07v-3.647a1.071 1.071 0 0 0-1.07-1.07h-2.7a1.071 1.071 0 0 0-1.07 1.07zm1.154-3.562h2.533v3.478h-2.533z"
          transform="translate(-21.944 -36)"
          fill="#545151"
        />
        <Path
          data-name="パス 1009"
          d="M39.017 37.008h-1.491v-8.674a.577.577 0 0 0-1.154 0v8.674h-6.409v-3.634a.878.878 0 0 0-.877-.877h-2.309a.878.878 0 0 0-.876.877v3.634h-1.245V16.027h11.5a.216.216 0 0 1 .216.216v9.763a.577.577 0 0 0 1.154 0v-9.762a1.372 1.372 0 0 0-1.371-1.371h-2.97V13.34a.978.978 0 0 0-.977-.977h-3.7a.978.978 0 0 0-.977.977v1.533h-2.875V1.731A1.733 1.733 0 0 0 22.925 0H4.764a1.733 1.733 0 0 0-1.731 1.731V8.41a.577.577 0 0 0 1.154 0V1.731a.578.578 0 0 1 .577-.577h18.161a.578.578 0 0 1 .577.577v35.277h-7.169v-7.343a.577.577 0 0 0-.577-.577h-3.687a.577.577 0 0 0-.577.577v7.343h-7.3v-26.28a.577.577 0 1 0-1.154 0v26.28H1.541a1.541 1.541 0 1 0 0 3.082h37.476a1.541 1.541 0 0 0 0-3.082M28.688 13.517h3.342v1.356h-3.342zm-1.633 20.135h1.754v3.357h-1.754zm-14.409-3.41h2.533v6.767h-2.533zm26.371 8.694H1.541a.387.387 0 1 1 0-.773h37.476a.387.387 0 0 1 0 .773"
          fill="#545151"
        />
      </G>
    </G>
  </Svg>
)

WorkIcon.displayName = "WorkIcon"
