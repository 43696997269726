import { AuthMethod } from "src/constants/AuthMethod"
import { Result } from "src/utils/Result"
import { GqlError, registerAuthCode } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type ValidateCodeParams = {
  authMethod: AuthMethod
  value: string
  code: string
  session: string
}

type ValidateCodeSuccessResponse = {
  accessToken: string
  expiresIn: string
  idToken: string
  refreshToken: string
  tokenType: string
}

type ValidateCodeFailureResponse = {
  message: string
  session?: string
}

export const validateCode = (_params: ValidateCodeParams): Promise<Result<ValidateCodeSuccessResponse, ValidateCodeFailureResponse>> => {
  return execApi(
    () => registerAuthCode({
      input: {
        email: _params.authMethod === AuthMethod.Email ? _params.value : null,
        phone_number: _params.authMethod === AuthMethod.PhoneNumber ? _params.value : null,
        session_id: _params.session,
        secret_code: _params.code
      },
      appInfo
    }),
    res => {
      const authenticationResult = res.registerAuthCode?.data?.auth?.AuthenticationResult
      if (!authenticationResult) throw Error('システムエラーが発生しました。') // 到達しない
      return {
        accessToken: authenticationResult.AccessToken,
        expiresIn: authenticationResult.ExpiresIn,
        idToken: authenticationResult.IdToken,
        refreshToken: authenticationResult.RefreshToken,
        tokenType: authenticationResult.TokenType,
      }
    },
    {
      identifiers: ["validateCode", "registerAuthCode"],
      onError: (e: GqlError | any) => {
        let message: string = '予期せぬエラーが発生しました';
        let session: string = '';

        // メッセージを取得
        if (e.errors && e.errors[0].errorInfo?.userMessage && e.errors[0].errorInfo?.code) {
          message =　"エラーコード" + e.errors[0].errorInfo?.code + "\n" + "認証エラー\n" + e.errors[0].errorInfo?.userMessage;
        }
        // セッションを取得
        if (e.errors && (e.errors[0].errorInfo?.detail as object ).hasOwnProperty('data')) {
          const detail = e.errors[0].errorInfo?.detail as {data: any }
          session = detail.data.Session || '';
        }

        return Result.Error<ValidateCodeFailureResponse>({
          message: message,
          session: session,
        })
      }
    }
  )
}
