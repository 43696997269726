import { Result } from "src/utils/Result";
import { MatchingOfferDraftModel } from "../../types/matching/matchingOfferModel";
import { updateMatchingOfferDraft as _updateMatchingOfferDraft, GqlError } from "src/aws/customAPI";
import { MatchingOfferPurpose } from "../../aws/API";

type UpdateDraftRequestParams = {
  accessToken: string
  matchingDraftId: string
  model: Partial<MatchingOfferDraftModel>
}

type UpdateDraftSuccess = unknown

type UpdateDraftFailure = {
  message: string
}

export const updateMatchingOfferDraft = async (params: UpdateDraftRequestParams): Promise<Result<UpdateDraftSuccess, UpdateDraftFailure>> => {
  const { accessToken, matchingDraftId, model } = params
  return _updateMatchingOfferDraft(accessToken, {
    input: {
      id: matchingDraftId,
      purpose: MatchingOfferPurpose.jointPracticeGame,
      title: model.title,
      body: model.body,
      date: model.date,
      startTime: model.startTime?.toString().slice(16, 21),
      endTime: model.endTime?.toString().slice(16, 21),
      expense: model.expense,
      expensePurpose: model.expensePurpose,
      ageFrom: model.ageFrom,
      ageTo: model.ageTo,
      offerCount: model.offerCount,
      publishEndDate: model.publishEndDate,
      prefectureId: model.prefectureId,
      cityId: model.cityId,
      place: model.place,
      placeURL: model.placeURL,
    }
  }).then(res => {
    if (res.data) {
      return Result.Ok(undefined)
    } else {
      return Result.Error<UpdateDraftFailure>({ message: '下書きの保存に失敗しました' })
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || '下書きの保存に失敗しました'
    return Result.Error<UpdateDraftFailure>({
      message: message
    })
  })
}