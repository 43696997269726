import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { OrganizationManagerTab } from "src/screens/bottomTabs/OrganizationManagerTab"
import { OrganizationDetail } from "src/screens/organization/OrganizationDetail"
import { OrganizationGroups } from "src/screens/organization/OrganizationGroups"
import { OrganizationMembers } from "src/screens/organization/OrganizationMembers"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { RootDrawer } from "src/constants/RootDrawer"
import { MatchingDrawer } from "../MatchingDrawer"

import { OrganizationManagerStackParamList } from "src/types.d"
import { createStackNavigator } from "@react-navigation/stack"
import { CompletedApplyPaid, CompletedApplyPaidOptions } from "src/screens/organization/CompletedApplyPaid"
import OrderDetail, { OrderDetailOptions } from "src/screens/registerPaidTeam/OrderDetail"

export const OrganizationManagerStack = createStackNavigator<OrganizationManagerStackParamList>()

const OrganizationManagerNavigator = () => {
  const isLargeScreen = useCheckPCScreen()
  return (
    <OrganizationManagerStack.Navigator>
      <OrganizationManagerStack.Screen
        name={OrganizationManagerScreens.OrganizationManager}
        component={OrganizationManagerTab}
        options={{ ...commonHeaderOptions, title: "団体管理", headerShown: !isLargeScreen, headerLeft: () => null }}
      />
      <OrganizationManagerStack.Screen
        name={OrganizationManagerScreens.OrganizationDetail}
        component={OrganizationDetail}
        options={{ headerShown: false, title: "団体詳細" }}
      />
      <OrganizationManagerStack.Screen
        name={OrganizationManagerScreens.OrganizationMembers}
        component={OrganizationMembers}
        options={{ ...commonHeaderOptions, title: "メンバー一覧", headerShown: !isLargeScreen }}
      />
      <OrganizationManagerStack.Screen
        name={OrganizationManagerScreens.OrganizationGroups}
        component={OrganizationGroups}
        options={{ ...commonHeaderOptions, title: "グループ一覧", headerShown: !isLargeScreen }}
      />
      <OrganizationManagerStack.Screen
        name={OrganizationManagerScreens.CompletedApplyPaid}
        component={CompletedApplyPaid}
        options={{ ...CompletedApplyPaidOptions, headerShown: !isLargeScreen }}
      />
      <OrganizationManagerStack.Screen
        name={OrganizationManagerScreens.OrderDetail}
        component={OrderDetail}
        options={{ ...OrderDetailOptions, headerShown: !isLargeScreen }}
      />
      <OrganizationManagerStack.Screen
        name={RootDrawer.MatchingDrawer}
        component={MatchingDrawer}
        options={{ headerShown: false }}
      />
    </OrganizationManagerStack.Navigator>
  )
}

export default OrganizationManagerNavigator
