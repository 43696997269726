import { memo } from "react"
import { StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"

type Props = {
  label: string
}

export const MatchingListEmpty = memo<Props>(({ label }) => (
  <View style={styles.container}>
    <Text style={styles.label}>{label}</Text>
  </View>
))

const styles = StyleSheet.create({
  container: {
    paddingTop: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: 16,
    lineHeight: 22,
    color: Colors.greyshBrown,
  },
})
