import { selectorFamily } from "recoil"
import { listMatchingMessageRequestIdState } from "src/recoil/atoms/matching/listMatchingMessageRequestIdState"
import { tokensState } from "../../atoms/authorization/tokensState"
import { getListMatchingMessage } from "src/apis/matching/matchingMessage"
import { CustomAlert } from "src/utils/CustomAlert"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { ContactReplyThreadItem, ContactReplyThreadType, DateLine, NoMessage } from "src/constants/ContactReplyThreadType"
import dayjs from "dayjs"

export type MatchingReplyThread = {
  hasMessage: boolean
  reversedReplyItems: ContactReplyThreadItem[]
  nextToken?: string | null
}
const listMessage = async (accessToken: string, applicationId: string, teamId: string, nextToken?: string) => {
  const result = await getListMatchingMessage({
    accessToken,
    matchingApplicationId: applicationId,
    teamId,
    nextToken,
  })
  if (!result.isOk) {
    await CustomAlert.alert("エラー", result.error.message)
    return
  }
  const replyItems = result.content.replyItems.reverse().reduce<any[]>(function insertDateLine(prev, curr) {
    const currDate = dayjs(curr.date)
    const getDateLineLabel = () => {
      const todayDate = dayjs()
      if (
        todayDate.year() === currDate.year() &&
        todayDate.month() === currDate.month() &&
        todayDate.date() === currDate.date()
      ) {
        return "今日"
      } else if (
        todayDate.year() === currDate.year() &&
        todayDate.month() === currDate.month() &&
        todayDate.date() - currDate.date() === 1
      ) {
        return "昨日"
      } else if (todayDate.year() !== currDate.year()) {
        return dayjs(currDate).format("YYYY/MM/DD")
      } else {
        return currDate.format("MM/DD")
      }
    }

    if (prev.length === 0) {
      return [
        {
          contactReplyThreadType: ContactReplyThreadType.DateLine,
          label: getDateLineLabel(),
        },
        curr,
      ]
    }
    const prevValue = prev[prev.length - 1]
    if (
      prevValue.contactReplyThreadType === ContactReplyThreadType.DateLine ||
      prevValue.contactReplyThreadType === ContactReplyThreadType.MemberDeleted ||
      prevValue.contactReplyThreadType === ContactReplyThreadType.NoMessage ||
      dayjs(prevValue.date).format("YYYY/MM/DD") === dayjs(currDate).format("YYYY/MM/DD")
    ) {
      return [...prev, curr]
    }
    return [
      ...prev,
      {
        contactReplyThreadType: ContactReplyThreadType.DateLine,
        label: getDateLineLabel(),
      },
      curr,
    ]
  }, [])

  const hasMessage = replyItems.length > 0
  if (replyItems.length === 0) {
    replyItems.push(NoMessage)
  }
  return {
    ...result.content,
    hasMessage,
    reversedReplyItems: replyItems.reverse(),
    nextToken: result.content.nextToken,
  }
}

export const listMatchingMessageSelectorFamily = selectorFamily<
  MatchingReplyThread | undefined,
  { applicationId: string | undefined; myOrganizationId?: string | undefined; nextTokens?: string[] }
>({
  key: "selectors/matting/listMatchingMessageSelectorFamily",
  get:
    ({ applicationId, myOrganizationId, nextTokens }) =>
    async ({ get }) => {
      get(listMatchingMessageRequestIdState)
      if (applicationId == null) return
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) return
      const selectedMyTeam = get(selectedMyOrganizationSelector)
      const teamId = myOrganizationId ? myOrganizationId : selectedMyTeam?.id
      if (teamId == null) return

      const firstMatchingThreads = await listMessage(accessToken, applicationId, teamId)
      if (firstMatchingThreads == null) return
      if (!nextTokens) return firstMatchingThreads
      const data = await Promise.all(
        nextTokens
          .map(async (nextToken) => {
            const r = await listMessage(accessToken, applicationId, teamId, nextToken)
            return r == null ? undefined : r
          })
          .reverse()
      )
      const result = [...data, firstMatchingThreads].reverse()
      const replyItems = result.reduce(
        (p, c) => (c != null ? [...p, ...c.reversedReplyItems] : p),
        [] as ContactReplyThreadItem[]
      )
      const reverseReplyItems = replyItems.reverse()
      const dateLineList: DateLine[] = []
      const filteredReplyItems: ContactReplyThreadItem[] = reverseReplyItems.reduce((acc: ContactReplyThreadItem[], item) => {
        if (item.contactReplyThreadType === ContactReplyThreadType.DateLine) {
          const existingItem = dateLineList.find((accItem) => accItem.label === item.label)
          if (!existingItem) {
            dateLineList.push(item)
            acc.push(item)
          }
        } else {
          acc.push(item)
        }
        return acc
      }, [])
      return {
        ...firstMatchingThreads,
        reversedReplyItems: filteredReplyItems.reverse(),
        nextToken: result.length > 0 ? result[result.length - 1]?.nextToken : undefined,
      }
    },
})
