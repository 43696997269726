import { memo, useCallback } from "react"
import { StyleProp, Text, TextStyle, ViewStyle, View, StyleSheet } from "react-native"
import { notNull } from "src/utils/typeguards"
import * as WebBrowser from "expo-web-browser"
import { Button } from "src/components/parts/buttons/Button"

type Props = {
  content: string
  style?: SimpleMarkDownStyle
  custom?: boolean
}

export type SimpleMarkDownStyle = {
  container?: StyleProp<ViewStyle>
  text?: StyleProp<TextStyle>
  link?: StyleProp<TextStyle>
}

/**
 * 以下のlink形式のみ変換するマークダウンテキスト
 * [テキスト](https://example.com)
 * ただし、リンクは1行につき1つのみ許容
 */
export const SimpleMarkDown = memo<Props>(({ content, style, custom }) => {
  return (
    <View style={style?.container}>
      {parseContent(content, custom).map((elems, i) => (
        <Line elements={elems} textStyle={style?.text} linkStyle={style?.link} key={i} />
      ))}
    </View>
  )
})

type LineProps = {
  elements: Element[]
  textStyle?: StyleProp<TextStyle>
  linkStyle?: StyleProp<TextStyle>
}

const Line = memo<LineProps>(({ elements, textStyle, linkStyle }) => {
  const openLink = useCallback(async (url: string) => {
    WebBrowser.openBrowserAsync(url)
  }, [])

  return (
    <View style={styles.line}>
      {elements.map((e, i) =>
        e.type === "text" ? (
          <Text style={textStyle} key={i}>
            {e.text !== "" ? e.text : "\n"}
          </Text>
        ) : (
          <Button onPress={() => openLink(e.url)} key={i}>
            <Text style={linkStyle}>{e.text}</Text>
          </Button>
        )
      )}
    </View>
  )
})

type Element =
  | {
      type: "text"
      text: string
    }
  | {
      type: "link"
      text: string
      url: string
    }

const parseLine = (line: string): Element[] => {
  const match = line.match(/(.*)\[(.*)]\((https?:\/\/.*)\)(.*)/)
  if (match == null) {
    return [{ type: "text", text: line }]
  } else {
    const [_, text1, linkText, linkUrl, text2] = match
    const result: (Element | undefined)[] = [
      text1 !== "" ? { type: "text", text: text1 } : undefined,
      linkUrl !== "" ? { type: "link", text: linkText || linkUrl, url: linkUrl } : undefined,
      text2 !== "" ? { type: "text", text: text2 } : undefined,
    ]
    return result.filter(notNull)
  }
}

const parseLineWithLink = (line: string): Element[] => {
  const match = line.match(/(.*)\[(.*)]\((https?:\/\/.*)\)/)
  if (match == null) {
    return [{ type: "text", text: line }]
  } else {
    const [_, text1, linkText, linkUrl] = match
    const result: (Element | undefined)[] = [
      text1 !== "" ? { type: "text", text: text1 } : undefined,
      linkUrl !== "" ? { type: "link", text: linkText || linkUrl, url: linkUrl } : undefined,
    ]
    return result.filter(notNull)
  }
}

const parseLineWithoutLink = (line: string): Element[] => {
  const match = line.match(/.*\((https?:\/\/.*)\)(.*)/)
  if (match == null) {
    return [{ type: "text", text: line }]
  } else {
    const [_, linkUrl, text2] = match
    const result: (Element | undefined)[] = [text2 !== "" ? { type: "text", text: text2 } : undefined]
    return result.filter(notNull)
  }
}

const parseContent = (content: string, custom?: boolean): Element[][] => {
  if (custom === true) {
    return content.split("\n").flatMap((line) => {
      const array = parseLineWithLink(line)
      return array.length > 1 ? [[array[0]], [array[1]], parseLineWithoutLink(line)] : [array]
    })
  }
  return content.split("\n").map(parseLine)
}

const styles = StyleSheet.create({
  line: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "center",
  },
})
