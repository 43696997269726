import { memo, useMemo } from "react"
import { StyleSheet, Text } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"
import { ValueOf } from "src/types.d"

export const ButtonType = Object.freeze({
  Primary: "Primary",
  Secondary: "Secondary",
} as const)

export type ButtonType = ValueOf<typeof ButtonType>

type Props = {
  buttonType: ButtonType
  IconComponent: JSX.Element
  title: string
  onPress: () => void
  disabled?: boolean
  style?: any
  styleTitle?: any
}

export const IconButton = memo<Props>(({ buttonType, IconComponent, title, style, styleTitle, onPress, disabled }) => {
  const styles = useMemo(() => {
    switch (buttonType) {
      case ButtonType.Primary: {
        return primaryStyles
      }
      case ButtonType.Secondary: {
        return secondaryStyles
      }
    }
  }, [buttonType])

  return (
    <Button style={[commonStyles.container, style]} onPress={onPress} disabled={disabled}>
      {IconComponent}
      <Text style={[commonStyles.title, styles.title, styleTitle]}>{title}</Text>
    </Button>
  )
})

const commonStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(159, 134, 107, 0.1)",
    paddingVertical: 14,
    paddingHorizontal: 20,
    borderRadius: 8,
    minWidth: 160,
  },
  title: {
    marginLeft: 8,
    fontSize: 13,
    fontWeight: "bold",
  },
})

const primaryStyles = StyleSheet.create({
  title: {
    color: Colors.orange,
  },
})

const secondaryStyles = StyleSheet.create({
  title: {
    color: Colors.black,
  },
})
