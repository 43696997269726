import { Result } from "src/utils/Result"
import { getOrCreateTempTeamId } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
}

type Success = string

type Failure = {
  message: string
}

export const getTempOrganizationId = ({accessToken}: Params): Promise<Result<Success, Failure>> => {
  return execApi(
    () => getOrCreateTempTeamId(accessToken, {appInfo}),
    res => res.getOrCreateTempTeamId.id,
    {
      identifiers: ["getTempOrganizationId", "getOrCreateTempTeamId"]
    }
  )
}
