import { Result } from "src/utils/Result"
import { GqlError, updateThreadMessage } from "src/aws/customAPI"
import { crashlyticsWrap } from "src/tags/crashlytics"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type DeleteContactReplyThreadMessageParams = {
  accessToken: string
  replyThreadMessageId: string
}

type DeleteContactReplyThreadMessageSuccess = unknown

type DeleteContactReplyThreadMessageFailure = {
  message: string
}

export const deleteContactReplyThreadMessage = async (params: DeleteContactReplyThreadMessageParams): Promise<Result<DeleteContactReplyThreadMessageSuccess, DeleteContactReplyThreadMessageFailure>> => {
  const {accessToken, replyThreadMessageId} = params
  return execApi(
    () => updateThreadMessage(accessToken, {
      input: {
        id: replyThreadMessageId,
        text: null,
        deleteFlg: true
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["deleteContactReplyThreadMessage", "updateThreadMessage"],
      defaultErrorMessage: "メッセージの取り消しに失敗しました"
    }
  )
}
