import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { myOrganizationsSelector } from "src/recoil/selectors/organization/myOrganizationsSelector"
import { SelectOption } from "src/types/SelectOption"
import { useMemo } from "react"
import { useRecoilState } from "recoil"
import { inquirySelectedOrganizationState } from "src/recoil/atoms/settings/inquiry/inquirySelectedOrganizationState"
import { inquiryContentState } from "src/recoil/atoms/settings/inquiry/inquiryContentState"
import { inquiryUserDeviceState } from "src/recoil/atoms/settings/inquiry/inquiryUserDeviceState"

const deviceInfoOptions: SelectOption[] = [
  "PC",
  "Web版(iPhone)",
  "Web版(Android）",
  "アプリ版(iPhone)",
  // アプリ版(Android）リリースまでは非表示とする
  "アプリ版(Android）",
].map((v) => ({ value: v, label: v }))

export const useInquiryData = () => {
  const { value: account, isLoading: isEmailLoading } = useAsyncSelector(accountSelector)
  const { value: myOrganizations, isLoading: isMyOrganizationsLoading } = useAsyncSelector(myOrganizationsSelector)
  const [selectedOrganization, setSelectedOrganization] = useRecoilState(inquirySelectedOrganizationState)
  const myOrganizationsOption: SelectOption[] = useMemo(
    () => myOrganizations?.map((v) => ({ value: v.id, label: v.title })) || [],
    [myOrganizations]
  )
  const [content, setContent] = useRecoilState(inquiryContentState)
  const [deviceInfo, setDeviceInfo] = useRecoilState(inquiryUserDeviceState)

  return {
    email: account?.email,
    isEmailLoading,
    myOrganizationsOption,
    isMyOrganizationsLoading,
    selectedOrganization,
    setSelectedOrganization,
    content,
    setContent,
    deviceInfoOptions,
    deviceInfo,
    setDeviceInfo,
  }
}
