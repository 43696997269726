import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FlatList, Linking, ListRenderItemInfo, Modal, Platform, StyleSheet, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { BorderedListItem } from "src/components/parts/BorderedListItem"
import { Button } from "src/components/parts/buttons/Button"
import { MemberListItem } from "src/components/parts/organizationTab/MemberListItem"
import { SearchInput } from "src/components/parts/SearchInput"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useOrganizationMembersData } from "src/recoil/hooks/screens/useOrganizationMembersData"
import { RootStackScreenProps } from "src/types.d"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { BannerAd } from "src/tags/ads/BannerAd"
import { adLoadState, isLoadDoneAd } from "src/recoil/atoms/ads/adState"
import { useRecoilState, useRecoilValue } from "recoil"
import { MainLayout } from "src/layouts/MainLayout"
import { HeaderCommon } from "src/components/parts/HeaderCommon"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { teamMembersCsvDataSelectorFamily } from "src/recoil/selectors/csv/teamMembersCsvDataSelectorFamily"
import Svg, { Path } from "react-native-svg"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { ruleURL } from "src/constants/links/rule"
import { guideURL } from "src/constants/links/guide"
import { CustomAlert } from "src/utils/CustomAlert"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import { HeaderLeft } from "src/components/parts/headers/HeaderLeft"
import { useAsyncState } from "src/hooks/useAsyncState"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { routeNameState } from "src/recoil/atoms/routeNameState"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { TeamMemberOverview } from "src/apis/organization/getTeamMembers"
import { permissionSelector } from "src/recoil/selectors/account/permissionSelector"

export type OrganizationMembersParams = {
  organizationId: string
}

type Props = RootStackScreenProps<typeof Screens.OrganizationMembers>

export const OrganizationMembers = memo<Props>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const { organizationId } = useMemo(() => route.params, [route.params])
  const { searchText, setSearchText, isLoadingMembers, teamMembers, members, totalMember, getMoreMembers, organization } =
    useOrganizationMembersData(organizationId, true)
  const [selectedOrganizationId, setSelectedOrganizationId] = useAsyncState(selectedMyOrganizationState)
  const routeName = useRecoilValue(routeNameState)
  const { value: accountPermission, isLoading: isLoadingAccountPermission } = useAsyncSelector(permissionSelector)

  const allowViewMembers = useMemo(() => {
    return accountPermission?.list.canVisibleListMember ?? false
  }, [accountPermission])

  useEffect(() => {
    if (routeName === Screens.OrganizationMembers && selectedOrganizationId !== organizationId) {
      if (isLargeScreen && selectedOrganizationId) {
        navigation.replace(Screens.Root, {
          screen: RootTabs.OrganizationManagerTab,
          params: {
            screen: Screens.OrganizationMembers,
            params: {
              organizationId: selectedOrganizationId,
            },
          },
        })
      } else {
        setSelectedOrganizationId(organizationId)
      }
    }
  }, [organizationId, selectedOrganizationId, setSelectedOrganizationId, routeName, isLargeScreen, navigation])

  const [isConfirmDownLoad, setIsConfirmDownLoad] = useState(false)
  const { value: membersCsvData } = useAsyncSelector(teamMembersCsvDataSelectorFamily({ teamId: organizationId }))

  const { allowEditMember, allowExportCsv } = useCheckAllowAccountPermission({ organizationId })

  const csvData = useMemo(
    () =>
      membersCsvData?.map((m) => {
        const memberNumber = "メンバー番号"
        const nickname = "ニックネーム"
        const nicknameKane = "フリガナ"
        const memberType = "権限"
        const groups = "所属グループ"
        const leaderGroups = "グループリーダー"
        return {
          [memberNumber]: m.memberSeq,
          [nickname]: m.nickname,
          [nicknameKane]: m.nicknameKana,
          [memberType]:
            (m.role === "manager" && "代表者") ||
            (m.role === "general" && "一般メンバー") ||
            (m.role === "mailSender" && "連絡メンバー"),
          [groups]: m.groups.join(","),
          [leaderGroups]: m.leaderGroups.join(","),
        }
      }),
    [membersCsvData]
  )

  const csv = useMemo(() => {
    if (!csvData || csvData.length === 0) return ""
    const jsonKeys = Object.keys(csvData[0])
    const headerData = jsonKeys.map((key) => `"${key}"`).join(",")
    const rowData = csvData.map((item) => {
      return jsonKeys
        .map((key) => {
          const validKey = key as keyof typeof item
          let value = item[validKey]
          if (value === null || value === undefined) {
            return `""`
          }
          if (typeof value === "string") {
            value = value.replace(/"/g, '""')
          }
          return `"${value}"`
        })
        .join(",")
    })

    return `${headerData}\n${rowData.join("\n")}`
  }, [csvData])

  const insets = useSafeAreaInsets()
  const [adLoadStateList] = useRecoilState(adLoadState)
  const downloadBlob = (content: any, filename: string, contentType: string) => {
    const blob = new Blob(["\uFEFF", content], { type: contentType })
    const url = URL.createObjectURL(blob)
    const pom = document.createElement("a")
    pom.href = url
    pom.setAttribute("download", filename)
    pom.click()
  }
  const goToOrganizationMemberDetail = useCallback(
    (memberId: string, imageUrl?: string) => {
      navigation.navigate(Screens.OrganizationMemberDetail, { organizationId, memberId, imageUrl, allowEditMember })
    },
    [navigation, organizationId, allowEditMember]
  )

  const ListItem = useCallback(
    ({ index, item: { id, nickname, memberNumber, imageUrl, ...otherItem } }: ListRenderItemInfo<TeamMemberOverview>) => (
      <BorderedListItem index={isLargeScreen ? 2 : index} length={isLargeScreen ? 3 : teamMembers?.length ?? 0}>
        <Button onPress={() => goToOrganizationMemberDetail(id, imageUrl)}>
          <MemberListItem
            {...otherItem}
            id={id}
            name={`${nickname} (${memberNumber})`}
            imageUrl={imageUrl}
            withChevronRightIcon
            isCustomImg
          />
        </Button>
        {Platform.OS !== "web" &&
        (!organization?.paidFunctionEnabled || organization?.showAdsFlg) &&
        teamMembers &&
        index === teamMembers.length - 1 ? (
          <View style={!isLoadDoneAd("home_bottom", adLoadStateList, 4) && { height: 0 }}>{BannerAd.HomeBottom_4}</View>
        ) : null}
      </BorderedListItem>
    ),
    [teamMembers, goToOrganizationMemberDetail, adLoadStateList, isLargeScreen, organization]
  )
  const ListEmptyComponent = useMemo(
    () =>
      members?.total === 0 && !isLoadingMembers ? (
        <View style={styles.noRecordContainer}>
          <Text style={styles.noRecordText}>一致するメンバーはいません</Text>
        </View>
      ) : null,
    [members, isLoadingMembers]
  )

  const backToOrganizationDetail = useCallback(() => {
    navigation.navigate(Screens.Root, {
      screen: RootTabs.OrganizationManagerTab,
      params: {
        screen: OrganizationManagerScreens.OrganizationDetail,
        params: { organizationId: organizationId },
      },
    })
  }, [navigation, organizationId])

  useEffect(() => {
    if (!allowViewMembers && !isLoadingAccountPermission) {
      backToOrganizationDetail()
    }
  }, [backToOrganizationDetail, allowViewMembers, isLoadingAccountPermission])

  useEffect(() => {
    if (Platform.OS === "web") {
      navigation.setOptions({
        headerLeft: () => <HeaderLeft onPress={backToOrganizationDetail} canGoBack={true} />,
      })
    }
  }, [navigation, backToOrganizationDetail])

  return (
    <MainLayout>
      <HeaderCommon title="メンバー一覧" back={backToOrganizationDetail} />
      <>
        <View style={styles.headerCount}>
          <ItemLabel label={`メンバー ${totalMember}人`} />
        </View>
        <View style={styles.headerContainer}>
          <SearchInput
            style={[styles.searchInput, isLargeScreen ? { borderTopWidth: 0 } : {}]}
            value={searchText}
            onChangeText={setSearchText}
            placeholder="名前で検索"
          />
        </View>
        {isLargeScreen && allowExportCsv && (
          <View style={{ paddingVertical: 8, display: "flex", alignItems: "flex-end", paddingRight: 30 }}>
            <Button
              style={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: 2,
              }}
              onPress={async () => {
                await Linking.openURL(`${guideURL}\\menber_csv_download`)
              }}
            >
              <QuestionSvg></QuestionSvg>
              <Text style={{ color: "#0000EE", fontWeight: "800", marginLeft: 5, textDecorationLine: "underline" }}>
                CSVダウンロードについて
              </Text>
            </Button>
            <Button
              style={{
                display: "flex",
                flexDirection: "row",
              }}
              onPress={() => {
                if (!csvData || csvData.length === 0) {
                  CustomAlert.alert("エラー", "該当メンバーが0件のため出力できません。")
                  return
                }
                setIsConfirmDownLoad(true)
              }}
            >
              <DownloadSVG></DownloadSVG>
              <Text style={{ color: Colors.orange, fontWeight: "600", marginLeft: 5 }}>メンバーCSVダウンロード</Text>
            </Button>
          </View>
        )}
        <FlatList
          data={teamMembers}
          renderItem={ListItem}
          keyExtractor={(item) => item.id.toString()}
          ListEmptyComponent={ListEmptyComponent}
          onEndReached={!isLoadingMembers ? getMoreMembers : undefined}
          ListFooterComponent={
            isLoadingMembers ? (
              <View style={styles.loading}>
                <LoadingIndicator />
              </View>
            ) : null
          }
          contentContainerStyle={[{ paddingBottom: insets.bottom + 16 }, isLargeScreen ? { paddingHorizontal: 30 } : {}]}
        />

        <Modal animationType="fade" visible={isConfirmDownLoad} transparent>
          <View style={styles.secondLayer}></View>
          <View style={styles.backgroundModal}>
            <View style={[styles.mainModal, isLargeScreen ? { width: 500 } : { width: 360 }]}>
              <View style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                <Button
                  onPress={() => {
                    setIsConfirmDownLoad(false)
                  }}
                  style={{ paddingVertical: 10, paddingHorizontal: 10 }}
                >
                  <RemoveIcon fill={Colors.orange}></RemoveIcon>
                </Button>
                <Text style={styles.titleModal}>確認</Text>
              </View>
              <Text style={styles.content}>
                {
                  "ダウンロードされる内容には個人情報が含まれますので、取り扱いには十分ご注意下さいますようお願いいたします。\n尚、『らくらく連絡網＋』での個人情報の取り扱いについては利用規約をご確認下さい。"
                }
              </Text>
              <Button
                onPress={async () => {
                  await Linking.openURL(ruleURL)
                }}
              >
                <Text style={styles.terms}>利用規約</Text>
              </Button>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <Button
                  onPress={() => {
                    setIsConfirmDownLoad(false)
                  }}
                  style={styles.buttonCustom}
                >
                  <Text style={[styles.textButton, { color: Colors.orange }]}>キャンセル</Text>
                </Button>
                <Button
                  onPress={() => {
                    downloadBlob(csv, "team_member.csv", "text/csv;charset=utf8bom;")
                    setIsConfirmDownLoad(false)
                  }}
                  style={[styles.buttonCustom, { backgroundColor: Colors.orange }]}
                >
                  <Text style={[styles.textButton, { color: Colors.white3 }]}>OK</Text>
                </Button>
              </View>
            </View>
          </View>
        </Modal>
      </>
    </MainLayout>
  )
})
export const DownloadSVG = () => {
  return (
    <Svg width={14.162} height={14.135} viewBox="0 0 14.162 14.135">
      <Path
        data-name="\u30D1\u30B9 1364"
        d="M14.162 10.286v3.134a.722.722 0 01-.022.174.673.673 0 01-.656.539H.674a.672.672 0 01-.665-.6.523.523 0 01-.009-.121v-3.131a.669.669 0 011.338 0v2.51h11.49v-2.51a.668.668 0 011.141-.472.7.7 0 01.192.477z"
        fill="#f0830e"
      />
      <Path
        data-name="\u30D1\u30B9 1365"
        d="M10.938 7.147l-3.286 3.286c0 .009-.013.013-.018.018a.775.775 0 01-.433.219h-.04a.515.515 0 01-.08 0h-.116a.762.762 0 01-.433-.219l-.018-.018-3.286-3.286a.782.782 0 011.106-1.106l1.975 1.975V.78a.784.784 0 01.78-.78.8.8 0 01.553.227.77.77 0 01.227.553v7.237l1.975-1.975a.786.786 0 011.106 0 .8.8 0 01-.013 1.106z"
        fill="#f0830e"
      />
    </Svg>
  )
}
export const QuestionSvg = () => {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none">
      <Path
        d="M9 16h2v-2H9v2zm1-16C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm0-14C7.79 4 6 5.79 6 8h2c0-1.1.9-2 2-2s2 .9 2 2c0 2-3 1.75-3 5h2c0-2.25 3-2.5 3-5 0-2.21-1.79-4-4-4z"
        fill="#EE8327"
      />
    </Svg>
  )
}
export const options: ScreenOptions = {
  ...commonHeaderOptions,
  title: "メンバー一覧",
}

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 10,
  },
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  headerCount: {
    padding: 16,
  },
  headerContainer: {},
  searchInput: {
    backgroundColor: Colors.white,
  },
  noRecordContainer: {
    marginTop: 48,
    flexDirection: "row",
    justifyContent: "center",
  },
  noRecordText: {
    marginTop: 8,
    marginLeft: 16,
    color: Colors.warmGrey,
    fontSize: 16,
  },
  backgroundModal: {
    position: "absolute",
    zIndex: 100,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainModal: {
    backgroundColor: Colors.white3,
    padding: 24,
    borderRadius: 24,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  secondLayer: { backgroundColor: "#f0f0f0", opacity: 0.4, width: "100%", height: "100%" },
  alertTop: {
    backgroundColor: Colors.white3,
    width: 270,
    padding: 16,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  alertBottom: {
    display: "flex",
    justifyContent: "center",
    borderTopWidth: 0.2,
    borderTopColor: "#f0f0f0",
    textAlign: "center",
    backgroundColor: Colors.white3,
    width: 270,
    height: 44,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  contentAlert: {
    fontSize: 14,
    fontWeight: "400",
    width: "100%",
    textAlign: "left",
    paddingHorizontal: 2,
  },
  textButton: { fontSize: 18, fontWeight: "800", lineHeight: 25.2, textAlign: "center" },
  buttonCustom: { margin: 5, width: 130, borderRadius: 20, padding: 6, borderColor: Colors.orange, borderWidth: 2 },
  titleModal: {
    fontSize: 18,
    fontWeight: "800",
    width: "100%",
    textAlign: "center",
    paddingRight: 33,
  },
  content: {
    fontSize: 14,
    fontWeight: "400",
    width: "100%",
    textAlign: "left",
    paddingHorizontal: 30,
  },
  terms: {
    color: Colors.orange,
    fontWeight: "800",
    marginVertical: 10,
    textDecorationLine: "underline",
    paddingHorizontal: 30,
  },
})
