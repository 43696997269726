import { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilState } from "recoil"
import { createFavoriteMatchingOffer } from "src/apis/matching/createFavoriteMatchingOffer"
import { deleteFavoriteMatchingOffer } from "src/apis/matching/deleteFavoriteMatchingOffer"
import { getMatchingOffers } from "src/apis/matching/getMatchingOffer"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { useFetcher } from "src/hooks/useFetcher"
import { useRefresher } from "src/hooks/useRefresher"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { listOfferFavoriteFlgsCacheState } from "src/recoil/atoms/contact/contactFavoriteFlgsCacheState"
import { favoriteMatchingOffersRequestIdState } from "src/recoil/atoms/matching/favoriteMatchingOffersRequestIdState"
import { otherOffersState } from "src/recoil/atoms/matching/otherOfferState"
import { FilterParams, filterParamsMatchingState } from "src/recoil/atoms/matching/search/filterParamsMatchingState"
import { searchFlgState } from "src/recoil/atoms/matching/search/searchFlgState"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { MatchingOffersModel } from "src/types/matching/matchingOfferModel"
import { CustomAlert } from "src/utils/CustomAlert"
import { UseIOSString } from "src/utils/useDate"
import { useResetter } from "../screens/contact/create/useResetter"
import { useRequestData } from "../useRequestData"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"

export const useOtherMatchingOffers = () => {
  const [otherOffers, setOtherOffers] = useAsyncState<MatchingOffersModel[]>(otherOffersState)
  const { value: selectedMyTeam } = useAsyncSelector(selectedMyOrganizationSelector)
  const teamId = useMemo(() => selectedMyTeam?.id, [selectedMyTeam])
  const [searchFlg, setSearchFlg] = useAsyncState(searchFlgState)
  const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(teamId))
  const teamMemberId = useMemo(() => myProfile?.memberId, [myProfile])
  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const [filterParams, setFilterParams] = useRecoilState<FilterParams>(filterParamsMatchingState)
  const [nextToken, setNextTokens] = useState<string | undefined>(undefined)
  const [loadingOffer, setLoadingOffer] = useState<boolean>(false)
  const refreshFavoriteMatchingOffers = useRefresher(favoriteMatchingOffersRequestIdState)
  const requestDataResult = useRequestData()
  const { value: organization } = useAsyncSelector(organizationDetailSelectorFamily(teamId))

  const [favoriteFlgsCache, setFavoriteFlgsCache] = useRecoilState(listOfferFavoriteFlgsCacheState)
  const [cacheOfferIdFav, setCacheOfferIdFav] = useState("")
  const [offerToken, setToken] = useState<string>()

  const { fetch: execCreateFavoriteMatchingOffer, isFetching: isLoadingCreateFavFlg } = useFetcher(
    useCallback(
      async ({ offerId }: { offerId: string }) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accountId, accessToken } = requestDataResult.content
        if (accountId == null || accessToken == null || teamMemberId == null) {
          return
        }
        setFavoriteFlgsCache((prev) => ({ ...prev, [offerId]: true }))
        const updatedOtherOffers = otherOffers?.map((offer) => {
          if (offer.id === offerId) {
            return { ...offer, favoriteFlg: true }
          }
          return offer
        })
        setOtherOffers(updatedOtherOffers ?? [])
        const result = await createFavoriteMatchingOffer({
          accessToken,
          teamMemberId,
          matchingOfferId: offerId,
        })
        if (!result.isOk) {
          await CustomAlert.alert("エラー", result.error.message)
        }
        refreshFavoriteMatchingOffers()
      },
      [requestDataResult, teamMemberId, setFavoriteFlgsCache, otherOffers, setOtherOffers, refreshFavoriteMatchingOffers]
    )
  )
  const { fetch: execDeleteFavoriteMatchingOffer, isFetching: isLoadingDeleteFavFlg } = useFetcher(
    useCallback(
      async ({ offerId }: { offerId: string }) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accountId, accessToken } = requestDataResult.content
        if (accountId == null || accessToken == null || teamMemberId == null) {
          return
        }
        setFavoriteFlgsCache((prev) => ({ ...prev, [offerId]: false }))
        const updatedOtherOffers = otherOffers?.map((offer) => {
          if (offer.id === offerId) {
            return { ...offer, favoriteFlg: false }
          }
          return offer
        })
        setOtherOffers(updatedOtherOffers ?? [])
        const result = await deleteFavoriteMatchingOffer({
          accessToken,
          teamMemberId,
          matchingOfferId: offerId,
        })
        if (!result.isOk) {
          await CustomAlert.alert("エラー", result.error.message)
        }
        refreshFavoriteMatchingOffers()
      },
      [requestDataResult, teamMemberId, refreshFavoriteMatchingOffers, otherOffers, setOtherOffers, setFavoriteFlgsCache]
    )
  )
  const params = useMemo(
    () => ({
      activityId: filterParams.activity?.id,
      dateFrom: filterParams.dateFrom ? UseIOSString(filterParams.dateFrom).split("T")[0] : undefined,
      dateTo: filterParams.dateTo ? UseIOSString(filterParams.dateTo).split("T")[0] : undefined,
      prefectureId: filterParams.prefecture?.id ? filterParams.prefecture.id.toString() : undefined,
      cityId: filterParams.city ? filterParams.city.id : undefined,
      ageFrom: filterParams.ageFrom ? Number(filterParams.ageFrom.id) : undefined,
      ageTo: filterParams.ageTo ? Number(filterParams.ageTo.id) : undefined,
      keyword: filterParams.keyword ? filterParams.keyword : undefined,
    }),
    [filterParams]
  )

  const refresh = async () => {
    if (teamId == null || accessToken == null || teamMemberId == null) return
    setLoadingOffer(true)
    getMatchingOffers({ accessToken, teamId, teamMemberId, type: "otherOffers", filter: params }).then((res) => {
      setLoadingOffer(false)
      if (!res.isOk) {
        CustomAlert.alert("エラー", res.error.message)
        return
      }
      setOtherOffers(res.content.data)
      setNextTokens(res.content.nextToken)
    })
  }

  useEffect(() => {
    if (teamId == null || accessToken == null || teamMemberId == null) return
    if (!searchFlg && !nextToken) {
      setLoadingOffer(true)
      getMatchingOffers({ accessToken, teamId, teamMemberId, type: "otherOffers", filter: params }).then((res) => {
        setLoadingOffer(false)
        if (!res.isOk) {
          CustomAlert.alert("エラー", res.error.message)
          return
        }
        setOtherOffers(res.content.data)
        setToken(res.content.nextToken)
      })
    } else if (!searchFlg && nextToken) {
      setLoadingOffer(true)
      getMatchingOffers({ accessToken, teamId, teamMemberId, nextToken, type: "otherOffers", filter: params }).then((res) => {
        setLoadingOffer(false)
        if (!res.isOk) {
          CustomAlert.alert("エラー", res.error.message)
          return
        }
        const newData = [...(otherOffers || []), ...(res.content.data || [])]
        setOtherOffers(newData)
        setToken(res.content.nextToken)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchFlg, params, nextToken])
  // Remove teamId dependency in this useEffect because dont need call this API when change team (new selected team may be not sport team)

  const onChangeFavoriteFlg = useCallback(
    (offerId: string, flg?: boolean) => {
      setCacheOfferIdFav(offerId)
      if (flg) {
        execDeleteFavoriteMatchingOffer({ offerId })
      } else {
        execCreateFavoriteMatchingOffer({ offerId })
      }
    },
    [execDeleteFavoriteMatchingOffer, execCreateFavoriteMatchingOffer]
  )

  const resetFilterScreenData = useResetter()
  return {
    otherOffers,
    loadingOffer,
    nextToken,
    setToken,
    offerToken,
    refresh,
    onChangeFavoriteFlg,
    resetFilterScreenData,
    setNextTokens,
    execCreateFavoriteMatchingOffer,
    execDeleteFavoriteMatchingOffer,
    favoriteFlgsCache,
    isLoadingDeleteFavFlg,
    isLoadingCreateFavFlg,
    cacheOfferIdFav,
    organization,
  }
}
