import { selector } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { listPrefectureMaster } from "src/aws/customAPI"

type Prefecture = {
  id: string
  label: string
}

export const prefecturesSelector = selector<Prefecture[]>({
  key: "selectors/account/prefecturesSelector",
  get: async ({ get }) => {
    const tokens = get(tokensState)
    if (tokens == null) return []

    const prefectureMaster = await listPrefectureMaster(tokens.accessToken)
    const prefectures: Prefecture[] =
      prefectureMaster.data?.listPrefectureMaster.items
        .filter((p) => p.displayFlg === 1)
        .map((p) => ({
          id: p.id,
          label: p.label,
        })) || []

    return prefectures
  },
})
