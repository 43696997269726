import { memo, ReactNode } from "react"
import { StyleSheet } from "react-native"
import { Colors } from "src/constants/Colors"
import { ChevronRightIcon } from "src/components/parts/icons/ChevronRightIcon"
import { Button } from "src/components/parts/buttons/Button"

type Props = {
  onPress: () => void
  children: ReactNode
}

export const SelectButton = memo<Props>(({ onPress, children }) => {
  return (
    <Button style={styles.container} onPress={onPress}>
      {children}
      <ChevronRightIcon />
    </Button>
  )
})

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    backgroundColor: Colors.white3,
    borderWidth: 2,
    borderRadius: 8,
    borderColor: Colors.lightGrey,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "space-between",
    height: 50,
  },
})
