import { selectorFamily } from "recoil"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { trashboxContactsSelectorFamily } from "src/recoil/selectors/contact/trashboxContactsSelectorFamily"
import { trashboxContactsConcatRequestIdState } from "src/recoil/atoms/contact/trashboxContactsConcatRequestIdState"

export const trashboxContactsConcatSelectorFamily = selectorFamily<
  { contacts: ContactOverviewModel[]; nextToken?: string } | undefined,
  { count?: number; nextTokens: string[] }
>({
  key: "selectors/contact/trashboxContactsConcatSelectorFamily",
  get:
    ({ nextTokens, ...params }) =>
    async ({ get }) => {
      get(trashboxContactsConcatRequestIdState)
      const result = [
        get(trashboxContactsSelectorFamily(params)),
        ...nextTokens.map((nextToken) => get(trashboxContactsSelectorFamily({ ...params, nextToken }))),
      ]
      return {
        contacts: result.reduce((p, c) => (c != null ? [...p, ...c.contacts] : p), [] as ContactOverviewModel[]),
        nextToken: result.length > 0 ? result[result.length - 1]?.nextToken : undefined,
      }
    },
})
