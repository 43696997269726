import { selector } from "recoil"
import { CreateNewContactErrors } from "src/constants/contact/CreateNewContactErrors"
import { ContactType } from "src/constants/ContactType"
import { bodyState } from "src/recoil/atoms/contact/create/bodyState"
import { contactTypeState } from "src/recoil/atoms/contact/create/contactTypeState"
import { deadlineDateState } from "src/recoil/atoms/contact/create/deadlineDateState"
import { eventDateCandidatesState } from "src/recoil/atoms/contact/create/eventDateCandidatesState"
import {
  isAddToOtherAdminsHistoryState,
  isEnableForCommentState,
} from "src/recoil/atoms/contact/create/isAddToOtherAdminsHistoryState"
import { isPublishAnswersToMembersState } from "src/recoil/atoms/contact/create/isPublishAnswersToMembersState"
import { questionsState } from "src/recoil/atoms/contact/create/questionsState"
import { reminderDateState } from "src/recoil/atoms/contact/create/reminderDateState"
import { reservationDateState } from "src/recoil/atoms/contact/create/reservationDateState"
import { titleState } from "src/recoil/atoms/contact/create/titleState"
import {
  AdditionalOptions,
  CreateNewContactCommonRequestParams,
  CreateNewContactRequestParams,
} from "src/types/contact/CreateNewContactRequestParams"
import { Result } from "src/utils/Result"
import { receiversSelector } from "./receiversSelector"

export const partialRequestBodySelector = selector<Result<Partial<CreateNewContactRequestParams>, CreateNewContactErrors>>({
  key: "selectors/contact/create/partialRequestBodySelector",
  get: ({ get }) => {
    const contactType = get(contactTypeState)
    const receivers = get(receiversSelector)
    const title = get(titleState)
    const body = get(bodyState)
    const isAddToOtherAdminsHistory = get(isAddToOtherAdminsHistoryState)
    const isEnableForComment = get(isEnableForCommentState)
    const reservationDate = get(reservationDateState)
    const commonRequestParams: Partial<CreateNewContactCommonRequestParams> = {
      receivers,
      title,
      body,
      isAddToOtherAdminsHistory,
      isEnableForComment,
      reservationDate,
    }
    if (contactType === ContactType.Normal) {
      return Result.Ok({ ...commonRequestParams, contactType })
    }
    const isPublishAnswersToMembers = get(isPublishAnswersToMembersState)
    const deadlineDate = get(deadlineDateState)
    const reminderDate = get(reminderDateState)
    const additionalOptions: Partial<AdditionalOptions> = {
      isPublishAnswersToMembers,
      deadlineDate,
      reminderDate,
    }
    switch (contactType) {
      case ContactType.Attendance: {
        return Result.Ok({
          ...commonRequestParams,
          ...additionalOptions,
          contactType,
        })
      }
      case ContactType.Survey: {
        const questions = get(questionsState)
        return Result.Ok({
          ...commonRequestParams,
          ...additionalOptions,
          contactType,
          questions,
        })
      }
      case ContactType.ScheduleAdjustment: {
        const eventDateCandidates = get(eventDateCandidatesState)
        return Result.Ok({
          ...commonRequestParams,
          ...additionalOptions,
          contactType,
          eventDateCandidates,
        })
      }
      default: {
        throw new Error(`contactType (${contactType}) is not implemented at requestBodySelector.`)
      }
    }
  },
})
