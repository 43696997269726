import { Result } from "src/utils/Result"
import { sendReminder as sendReminderApi } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"


type SendReminderParams = {
  accessToken: string
  contactId: string
}

type SendReminderSuccess = unknown

type SendReminderFailure = {
  message: string
}

export const sendReminder = async (params: SendReminderParams): Promise<Result<SendReminderSuccess, SendReminderFailure>> => {
  const {accessToken, contactId} = params

  return execApi(
    () => sendReminderApi(accessToken, {
      input: {
        mailId: contactId
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["sendReminder", "sendReminderApi"],
      defaultErrorMessage: "回答の催促に失敗しました"
    }
  )
}
