import { listReceivedTeamMemberMail, listReservedTeamMemberMail } from "src/aws/customAPI"
import { TeamMemberContact, TeamMemberContacts } from "src/types/contact/TeamMemberContact"
import { Result } from "src/utils/Result"
import { AttendanceType } from "src/constants/AttendanceType"
import { ScheduleAnswerType } from "src/constants/ScheduleAnswerType"
import { MailType, YesNoAnswer } from "src/aws/API"
import { ContactType } from "src/constants/ContactType"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
  contactId: string
  type?: string
}

type Success = TeamMemberContacts

type Failure = {
  message: string
}

export const getTeamMemberContacts = ({accessToken, contactId, type}: Params): Promise<Result<Success, Failure>> => {
  return type !== "Reserved" ? execApi(
    () =>  listReceivedTeamMemberMail(accessToken, { input: { mailId: contactId }, appInfo }),
    res => {
      if (res.listReceivedTeamMemberMail == null) throw Error("システムエラーが発生しました。")
      const contactType = mailTypeMapping[res.listReceivedTeamMemberMail.mailType]
      const items: TeamMemberContact[] = res.listReceivedTeamMemberMail.teamMemberMails.map(item => ({
        teamMember: item.teamMember || undefined,
        showReadFlg: item.showReadFlg,
        readFlg: item.readFlg ?? undefined,
        readAt: item.readAt ? new Date(item.readAt) : undefined,
        showAnsweredFlg: item.showAnsweredFlg,
        answeredFlg: item.answeredFlg ?? undefined,
        answeredAt: item.answeredAt ? new Date(item.answeredAt) : undefined,
        attendanceAnswer: item.attendanceAnswer ? attendanceTypeMapping[item.attendanceAnswer] : undefined,
        scheduleAnswers: item.scheduleAnswers?.map(a => scheduleAnswerTypeMapping[a]) || undefined,
        questionnaireAnswers: item.questionnaireAnswers?.map(a => ({answer: a.answer})) || undefined
      }))

      return { contactType, items }
    }
  ) : execApi(
    () =>  listReservedTeamMemberMail(accessToken, { input: { reservedMailId: contactId }, appInfo }),
    res => {
      if (res.listReservedTeamMemberMail == null) throw Error("システムエラーが発生しました。")
      const contactType = mailTypeMapping[res.listReservedTeamMemberMail.mailType]
      const items: TeamMemberContact[] = res.listReservedTeamMemberMail.teamMemberMails.map(item => ({
        teamMember: item.teamMember || undefined,
        showReadFlg: item.showReadFlg,
        readFlg: item.readFlg ?? undefined,
        readAt: item.readAt ? new Date(item.readAt) : undefined,
        showAnsweredFlg: item.showAnsweredFlg,
        answeredFlg: item.answeredFlg ?? undefined,
        answeredAt: item.answeredAt ? new Date(item.answeredAt) : undefined,
        attendanceAnswer: item.attendanceAnswer ? attendanceTypeMapping[item.attendanceAnswer] : undefined,
        scheduleAnswers: item.scheduleAnswers?.map(a => scheduleAnswerTypeMapping[a]) || undefined,
        questionnaireAnswers: item.questionnaireAnswers?.map(a => ({answer: a.answer})) || undefined
      }))

      return { contactType, items }
    })
}

const mailTypeMapping: {[key in MailType]: ContactType} = {
  [MailType.normal]: ContactType.Normal,
  [MailType.attendance]: ContactType.Attendance,
  [MailType.questionnaire]: ContactType.Survey,
  [MailType.schedule]: ContactType.ScheduleAdjustment,
}

const attendanceTypeMapping: { [key in YesNoAnswer]: AttendanceType} = {
  [YesNoAnswer.yes]: AttendanceType.Attend,
  [YesNoAnswer.no]: AttendanceType.NonAttend,
  [YesNoAnswer.tbd]: AttendanceType.Pend,
}

const scheduleAnswerTypeMapping: { [key in YesNoAnswer]: ScheduleAnswerType} = {
  [YesNoAnswer.yes]: ScheduleAnswerType.Attend,
  [YesNoAnswer.no]: ScheduleAnswerType.NonAttend,
  [YesNoAnswer.tbd]: ScheduleAnswerType.Pend,
}
