import { Result } from "src/utils/Result"
import { updateSentMailFavoriteFlg } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type GetUpdateFavoriteContactRequestParams = {
  accessToken: string
  myMemberId: string
  contactId: string
  isFavorite: boolean
}

export type GetUpdateFavoriteContactSuccess = unknown

type GetUpdateFavoriteContactFailure = {
  message: string
}

export const updateFavoriteContactOutbox = async (
  { accessToken, myMemberId, contactId, isFavorite }: GetUpdateFavoriteContactRequestParams
): Promise<Result<GetUpdateFavoriteContactSuccess, GetUpdateFavoriteContactFailure>> => {
  return execApi(
    () => updateSentMailFavoriteFlg(accessToken, {
      input: {
        mailId: contactId,
        memberId: myMemberId,
        favoriteFlg: isFavorite
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["updateFavoriteContactOutbox", "updateSentMailFavoriteFlg"]
    }
  )
}
