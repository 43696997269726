import { useCallback, useMemo } from "react"
import { StyleSheet, Text } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { RadioView } from "src/components/parts/RadioButton/RadioView"

type Props<T> = {
  selected: T
  value: T
  label: string
  onPress: (next: T) => void
}

/**
 * RadioButton has `value`, `selected` is selected value of current radio group, and checked if value equals selected.
 */
export const RadioButton = <T,>({ selected, value, label, onPress }: Props<T>) => {
  const handlePress = useCallback(() => onPress(value), [onPress, value])
  const isChecked = useMemo(() => selected === value, [value, selected])

  return (
    <Button style={styles.container} onPress={handlePress}>
      <RadioView isChecked={isChecked} />
      <Text style={styles.label}>{label}</Text>
    </Button>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  label: {
    fontSize: 16,
    marginLeft: 6,
  },
})
