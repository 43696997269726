
import { GqlError, sendInvitationEmail as _sendInvitationEmail } from "src/aws/customAPI"
import { Result } from "src/utils/Result"

type Params = {
  accessToken: string
  teamId: string
  emails: Array< string >
}

type Success = unknown

type Failure = {
  message: string
}

export const sendInvitationEmail = async ({accessToken, teamId, emails}: Params): Promise<Result<Success, Failure>> => {
  return _sendInvitationEmail(accessToken, {
    input: {
      teamId: teamId,
      emails: emails
    }
  }).then(res => {
    if (res.data?.sendInvitationEmail.id) {
      return Result.Ok(undefined)
    } else {
      return Result.Error<Failure>({message: "予期せぬエラーが発生しました"})
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "予期せぬエラーが発生しました"
    return Result.Error<Failure>({
      message: message
    })
  })
}