import { Result } from "src/utils/Result"
import { deleteMigratedMail } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type DeleteContactParams = {
  accessToken: string
  migrationContactId: string
}

type DeleteContactSuccess = unknown

type DeleteContactFailure = {
  message: string
}

export const deleteMigrationContact = async (params: DeleteContactParams): Promise<Result<DeleteContactSuccess, DeleteContactFailure>> => {
  const {accessToken, migrationContactId} = params

  return execApi(
    () => deleteMigratedMail(accessToken, {
      input: {
        id: migrationContactId
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["deleteMigrationContact", "deleteMigratedMail"],
      defaultErrorMessage: "連絡の削除に失敗しました。"
    }
  )
}
