import { atomFamily, selectorFamily } from "recoil"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"

export const phoneNumberStateFamily = atomFamily<string | undefined, string>({
  key: "atoms/organization/myProfile/phoneNumberStateFamily",
  default: selectorFamily<string | undefined, string>({
    key: "atoms/organization/myProfile/phoneNumberStateFamily_default",
    get:
      (organizationId) =>
      ({ get }) => {
        const myProfile = get(myProfileSelectorFamily(organizationId))
        return myProfile?.phoneNumber
      },
  }),
})
