import { StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"
import { memo, useMemo, useState, useRef, useEffect } from "react"
import { BellIcon } from "src/components/parts/icons/BellIcon"
import dayjs from "dayjs"
import { ONE_DAY } from "src/recoil/hooks/resource/fileManager/common"
type Props = {
  timeLimitDate?: Date
}

export const TimeLimitLabel = memo<Props>(({ timeLimitDate }) => {
  const isLimitPassed = useMemo(() => {
    return timeLimitDate ? new Date(timeLimitDate) < new Date() : false
  }, [timeLimitDate])
  const [remainingTime, setRemainingMinutes] = useState<number | null>(
    timeLimitDate ? Math.floor((timeLimitDate.getTime() - new Date().getTime()) / 1000 / 60) : null
  )
  const intervalIdRef = useRef<any>()
  useEffect(() => {
    if (remainingTime && remainingTime > 0 && remainingTime < 60) {
      if (timeLimitDate) {
        const intervalId = setInterval(() => {
          setRemainingMinutes((prevMinutes) => {
            if (prevMinutes === null || prevMinutes === 0) {
              clearInterval(intervalId)
              intervalIdRef.current = null
              return 0
            } else {
              return prevMinutes - 1
            }
          })
        }, 60000)
        intervalIdRef.current = intervalId
      } else {
        clearInterval(intervalIdRef.current)
        intervalIdRef.current = null
        setRemainingMinutes(0)
      }
    }
  }, [remainingTime, timeLimitDate])
  if (!timeLimitDate) return <></>

  return (
    <View style={styles.timeLimitLabelContainer}>
      <View style={styles.timeLimitLabelIconWrapper}>
        <BellIcon />
      </View>
      {!isLimitPassed ? (
        <Text style={styles.timeLimitLabel}>回答期限まであと{formatLimit(timeLimitDate)}</Text>
      ) : (
        <Text style={styles.timeLimitLabel}>回答期限が過ぎています</Text>
      )}
    </View>
  )
})

const formatLimit = (timeLimitDate: Date) => {
  const timeLimitDayjs = dayjs(timeLimitDate)
  const nowDayjs = dayjs()
  const daysDiff = timeLimitDayjs.diff(nowDayjs, "day")
  if (daysDiff > 0) return `${daysDiff}日`
  const hoursDiff = timeLimitDayjs.diff(nowDayjs, "hour")
  if (hoursDiff > 0) return `${hoursDiff}時間`
  const minutesDiff = timeLimitDayjs.diff(nowDayjs, "minute")
  return `${minutesDiff}分`
}

const styles = StyleSheet.create({
  timeLimitLabelContainer: {
    paddingVertical: 2,
    paddingHorizontal: 10,
    borderRadius: 16,
    backgroundColor: Colors.leaf,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  timeLimitLabelIconWrapper: {
    width: 10,
    height: 10,
  },
  timeLimitLabel: {
    marginLeft: 4,
    fontSize: 12,
    color: Colors.white3,
  },
})
