import { useCallback, useMemo } from "react"
import { useSetRecoilState } from "recoil"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { isReceiversAllLeadersState } from "src/recoil/atoms/contact/create/isReceiversAllLeadersState"
import { isReceiversAllMembersState } from "src/recoil/atoms/contact/create/isReceiversAllMembersState"
import { selectedGroupIdsState } from "src/recoil/atoms/contact/create/selectedGroupIdsState"
import { selectedMemberIdsState } from "src/recoil/atoms/contact/create/selectedMemberIdsState"
import { receiversSelector } from "src/recoil/selectors/contact/create/receiversSelector"
import { permissionSelector } from "src/recoil/selectors/account/permissionSelector"
import { groupsSelectorFamily } from "src/recoil/selectors/organization/group/groupsSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"

export const useSelectMethodScreenData = () => {
  const { value: receivers } = useAsyncSelector(receiversSelector)
  const setIsReceiversAllMembers = useSetRecoilState(isReceiversAllMembersState)
  const setIsReceiversAllLeaders = useSetRecoilState(isReceiversAllLeadersState)
  const setSelectedGroupIds = useSetRecoilState(selectedGroupIdsState)
  const setSelectedMemberIds = useSetRecoilState(selectedMemberIdsState)
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])

  const isAllMembersSelected = useMemo(() => receivers?.isAllMembers === true, [receivers])
  const isAllLeadersSelected = useMemo(() => receivers?.isAllLeaders === true, [receivers])
  const isGroupsSelected = useMemo(() => (receivers?.groupIds || []).length > 0, [receivers])
  const isMembersSelected = useMemo(() => (receivers?.memberIds || []).length > 0, [receivers])

  const setReceiverTypeAllMembers = useCallback(() => {
    setIsReceiversAllMembers(true)
    setIsReceiversAllLeaders(undefined)
    setSelectedGroupIds(undefined)
    setSelectedMemberIds(undefined)
  }, [setIsReceiversAllMembers, setIsReceiversAllLeaders, setSelectedGroupIds, setSelectedMemberIds])

  const { value: accountPermission } = useAsyncSelector(permissionSelector)
  const checkDestinationSelection = accountPermission?.mail?.make
  const { value: groups } = useAsyncSelector(groupsSelectorFamily(organizationId))
  const showSelectGroups = useMemo(() => {
    if ((groups || []).length === 0) return false
    return checkDestinationSelection?.accessAll || checkDestinationSelection?.groupIds?.length !== 0
  }, [groups, checkDestinationSelection])

  return {
    isAllMembersSelected,
    isAllLeadersSelected,
    isGroupsSelected,
    isMembersSelected,
    setReceiverTypeAllMembers,
    setIsReceiversAllMembers,
    setIsReceiversAllLeaders,
    checkDestinationSelection,
    showSelectGroups,
  }
}
