import { memo, useCallback, useMemo } from "react"
import { RootStackScreenProps } from "src/types.d"
import {
  ActivityIndicator,
  FlatList,
  ListRenderItemInfo,
  Platform,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { Screens } from "src/constants/Screens"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { useSendingHistoryData } from "src/recoil/hooks/organization/useSendingHistoryData"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { EmailStatusLabel, InvitationEmailStatus } from "src/recoil/selectors/organization/invitationEmailsEmailSelectorFamily"
import dayjs from "dayjs"
import { Button } from "src/components/parts/buttons/Button"

export type SendingHistoryParams = {
  id: string
}

type Props = RootStackScreenProps<typeof Screens.SendingHistory>

export const SendingHistory = memo<Props>(({ route }) => {
  const isLargeScreen = useCheckPCScreen()
  const widthScreen = useWindowDimensions().width
  const id = useMemo(() => route.params.id, [route.params.id])

  const { thumbnailImageUrl, organizationName, isSendingHistoryDataLoading, emails, onResend, isEmailsLoading, setNextTokens } =
    useSendingHistoryData(id)

  const ListItem = useCallback(
    (listRenderItemInfo: ListRenderItemInfo<InvitationEmailStatus>) => (
      <View style={styles.borderWrapper}>
        <View style={[styles.nameContainer, { width: "100%" }]}>
          <View style={styles.nameInner}>
            <Text style={styles.nameText}>{listRenderItemInfo.item.email}</Text>
          </View>
          <View style={[{ flexDirection: "row", width: "100%", paddingTop: 8 }]}>
            <View style={[{ width: "75%" }]}>
              <View style={styles.roleContainer}>
                <Text style={styles.roleText}>
                  送信日：{dayjs(new Date(listRenderItemInfo.item.updatedAt)).format("YYYY/MM/DD")}
                </Text>
              </View>
              <View style={[styles.roleContainer, { marginTop: 4 }]}>
                <Text style={styles.roleText}>ステータス：</Text>
                <Text style={styles.statusTitle}>{EmailStatusLabel[listRenderItemInfo.item.status]}</Text>
              </View>
            </View>
            <Button onPress={() => onResend(listRenderItemInfo.item.id)} style={[styles.button, styles.rightButton]}>
              <Text style={styles.buttonLabel}>再送信</Text>
            </Button>
          </View>
        </View>
      </View>
    ),
    [onResend]
  )

  const mailsList = emails?.emails ?? []
  const getMoreEmails = useCallback(() => {
    setNextTokens((prev) => (emails?.nextToken != null ? [...prev, emails.nextToken] : prev))
  }, [emails, setNextTokens])

  return (
    <View style={{ height: useWindowDimensions().height - (isLargeScreen ? 65 : 110) }}>
      <CustomScrollView style={[styles.container]}>
        {isSendingHistoryDataLoading ? (
          <View
            style={[
              { flex: 1, alignItems: "center", justifyContent: "center" },
              // @ts-ignore
              Platform.OS === "android" ? { marginTop: "50%" } : { marginTop: "calc(80vh/2)" },
            ]}
          >
            <LoadingIndicator />
          </View>
        ) : (
          <>
            <View style={[styles.head, isLargeScreen ? { borderBottomWidth: 1, borderBottomColor: Colors.lightGrey } : {}]}>
              <CircleThumbnail size={32} source={thumbnailImageUrl ? { uri: thumbnailImageUrl } : defaultThumbnail} />
              <Text style={styles.orgName}>{organizationName}</Text>
            </View>
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <View style={isLargeScreen ? { width: 600 } : { width: "100%" }}>
                <View style={[styles.contentsContainer, isLargeScreen ? { borderTopWidth: 0 } : {}]}>
                  <ItemLabel label="メンバー登録メール送信履歴" />
                  <Text style={styles.description}>
                    正常に送信されなかったメールアドレスは表示されません。送信履歴の表示期間はメールの送信日から15日間です。
                  </Text>
                  <View style={{ flexDirection: "row", paddingTop: 5 }}>
                    <Text style={[styles.description, {}]}>※</Text>
                    <Text style={styles.description}>
                      らくらく連絡網のサーバーから正常にメールが配信され、受信者様がご利用の携帯会社やプロバイダーによってエラー情報が返ってきた場合には「不着」と表示されます。
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", paddingTop: 0 }}>
                    <Text style={[styles.description, {}]}>※</Text>
                    <Text style={styles.description}>送信には最大で2時間程度かかります。</Text>
                  </View>
                </View>

                <View
                  style={[
                    styles.contentsContainer,
                    { borderTopWidth: 0, paddingVertical: 0 },
                    isLargeScreen ? { justifyContent: "center", alignItems: "center" } : {},
                  ]}
                >
                  {isEmailsLoading && mailsList.length === 0 ? (
                    <ActivityIndicator size="large" color={Colors.warmGrey2} />
                  ) : (
                    <FlatList
                      style={[isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : {}]}
                      data={mailsList}
                      renderItem={ListItem}
                      keyExtractor={(item, index) => index.toString()}
                      onEndReached={!isEmailsLoading ? getMoreEmails : undefined}
                      contentContainerStyle={[isLargeScreen ? { width: 550 } : {}]}
                    />
                  )}
                </View>
              </View>
            </View>
          </>
        )}
      </CustomScrollView>
    </View>
  )
})

export const options: ScreenOptions = {
  ...commonSingleModalOptions,
  title: "メンバー招待",
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white3,
  },
  head: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 8,
    backgroundColor: Colors.white3,
  },
  orgName: {
    marginLeft: 8,
  },
  contentsContainer: {
    padding: 24,
    borderTopWidth: 1,
    borderTopColor: Colors.lightGrey,
  },
  description: {
    fontSize: 14,
    lineHeight: 21,
    color: Colors.black,
    marginVertical: 8,
    marginLeft: 0,
  },
  contentWrapper: {
    borderRadius: 8,
    backgroundColor: "#efeae3",
  },
  content: {
    fontSize: 16,
    lineHeight: 25,
    color: Colors.black2,
    padding: 12,
  },
  centering: {
    textAlign: "left",
  },
  actions: {
    paddingVertical: 16,
    paddingHorizontal: 32,
    paddingBottom: 25,
  },
  secondaryButton: {
    marginTop: 16,
  },
  linkText: {
    color: Colors.black,
    textDecorationLine: "underline",
  },
  actionContainer: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  nameContainer: {
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: 0,
    paddingVertical: 16,
    marginRight: 0,
  },
  nameInner: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  nameText: {
    fontSize: 14,
    fontWeight: "bold",
  },
  roleContainer: {
    marginTop: 8,
    flexDirection: "row",
  },
  roleText: {
    fontSize: 11,
    color: Colors.warmGrey,
    marginLeft: 0,
  },
  borderWrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  statusTitle: {
    fontSize: 11,
    fontWeight: "bold",
    width: 70,
  },
  buttonLabel: {
    color: Colors.white,
    fontSize: 16,
    fontWeight: "bold",
  },
  buttonContainer: {
    flex: 1,
    marginRight: 0,
    backgroundColor: Colors.red,
  },
  button: {
    flexDirection: "row",
    height: 44,
    width: 100,
    flex: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
    borderRadius: 6,
    marginRight: 0,
  },
  rightButton: {
    backgroundColor: Colors.orange,
  },
})
