import { useCallback, useEffect, useMemo, useState } from "react"
import { deleteContactTemplate } from "src/apis/contact/deleteContactTemplate"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useFetcher } from "src/hooks/useFetcher"
import { useRefresher } from "src/hooks/useRefresher"
import { templateContactsRequestIdState } from "src/recoil/atoms/contact/templateContactsRequestIdState"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { ContactFilterValue } from "src/types/contact/ContactFilter"
import { CustomAlert } from "src/utils/CustomAlert"
import { useResetter } from "./contact/create/useResetter"
import { templateContactsSelector } from "src/recoil/selectors/contact/templateContactsSelector"
import { updateFavoriteContactTemplate } from "src/apis/contact/updateFavoriteContactTemplate"
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import { templateContactFavoriteFlgsCacheState } from "src/recoil/atoms/contact/contactFavoriteFlgsCacheState"
import { wait } from "src/utils/wait"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { organizationDetailRequestIdState } from "src/recoil/atoms/organization/organizationDetailRequestIdState"
import { organizationRefreshState } from "src/recoil/atoms/organization/organizationRefreshPermissionState"

export const useTemplateData = () => {
  const [searchText, setSearchText] = useState("")
  const [filters, setFilters] = useState<ContactFilterValue[]>([])
  const [allContacts, setAllContacts] = useState<ContactOverviewModel[]>([])
  const { value: fetchedContacts, isLoading: isLoadingContacts } = useAsyncSelector(templateContactsSelector)
  useEffect(() => {
    if (fetchedContacts) {
      setAllContacts(fetchedContacts)
    }
  }, [fetchedContacts])
  const contacts = allContacts?.filter((contact) => {
    if (filters.some((f) => f === "favorite" && !contact.isFavorite)) return false
    if (searchText && !(contact.title.includes(searchText) || contact.body?.includes(searchText))) return false
    return true
  })
  const [favoriteFlgsCache, setFavoriteFlgsCache] = useRecoilState(templateContactFavoriteFlgsCacheState)
  const refreshTemplateContacts = useRefresher(templateContactsRequestIdState)
  const refreshOrganizationDetail = useRefresher(organizationDetailRequestIdState)
  const needRefreshOrganization = useRecoilValue(organizationRefreshState)
  useEffect(() => {
    if (needRefreshOrganization) {
      refreshOrganizationDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefreshOrganization])

  const setFiltersHelper = useCallback(
    (filters: ContactFilterValue[]) => {
      setFilters(filters)
      refreshTemplateContacts()
    },
    [refreshTemplateContacts]
  )

  const resetFavoriteFlgsCache = useResetRecoilState(templateContactFavoriteFlgsCacheState)
  const requestDataResult = useRequestData()
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const { value: organization } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))

  const { fetch: execDeleteContactTemplate } = useFetcher(
    useCallback(
      async (templateId: string) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accessToken } = requestDataResult.content
        if (accessToken == null) {
          return
        }
        const isConfirmed = await CustomAlert.confirm("確認", "このテンプレートを削除しますか？\n削除後は復元できません。")
        if (!isConfirmed) {
          return
        }
        const result = await deleteContactTemplate({ accessToken, templateId })
        if (result.isOk) {
          CustomAlert.alert("完了", "テンプレートを削除しました。")
          await wait(2000)
          refreshTemplateContacts()
          resetFavoriteFlgsCache()
        } else {
          CustomAlert.alert("エラー", result.error.message)
        }
      },
      [requestDataResult, refreshTemplateContacts, resetFavoriteFlgsCache]
    )
  )

  const { fetch: execUpdateFavoriteContact } = useFetcher(
    useCallback(
      async ({ templateId, isFavorite }: { templateId: string; isFavorite: boolean }) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accessToken } = requestDataResult.content
        if (accessToken == null) {
          return
        }
        const result = await updateFavoriteContactTemplate({ accessToken, templateId, isFavorite })
        if (!result.isOk) {
          CustomAlert.alert("エラー", result.error.message)
          return
        }
        if (result.isOk && filters.includes("favorite") && allContacts) {
          const filteredContacts = allContacts.filter((e: { id: string }) => e.id && e.id !== templateId)
          setAllContacts(filteredContacts)
        }
        setFavoriteFlgsCache((prev) => ({ ...prev, [templateId]: isFavorite }))
      },
      [requestDataResult, setFavoriteFlgsCache, filters, allContacts]
    )
  )

  const resetCreateNewContactScreenData = useResetter()

  return {
    searchText,
    setSearchText,
    filters,
    setFilters: setFiltersHelper,
    contacts,
    isLoadingContacts,
    refreshTemplateContacts,
    execDeleteContactTemplate,
    execUpdateFavoriteContact,
    resetCreateNewContactScreenData,
    favoriteFlgsCache,
    organizationId,
    organization,
    refreshOrganizationDetail,
  }
}
