import { selector } from "recoil"
import { CreateNewContactErrors } from "src/constants/contact/CreateNewContactErrors"
import { ContactType } from "src/constants/ContactType"
import type {
  CreateNewContactCommonRequestParams,
  CreateNewContactRequestParams,
} from "src/types/contact/CreateNewContactRequestParams"
import { Result } from "src/utils/Result"
import { partialRequestBodySelector } from "./partialRequestBodySelector"
import moment from "moment"

const validateDate = (reservationDate?: Date, deadlineDate?: Date, reminderDate?: Date) => {
  const errorList = []

  if (reservationDate) {
    const now = moment()
    const reservationDateMoment = moment(reservationDate)
    const deadlineDateMoment = deadlineDate ? moment(deadlineDate) : null
    const reminderDateMoment = reminderDate ? moment(reminderDate) : null
    if (reservationDateMoment.isBefore(now)) {
      errorList.push(Result.Error(CreateNewContactErrors.InvalidReservationDate, "現在時刻よりも前の日時が設定されています。"))
    }
    if (deadlineDateMoment && reservationDateMoment.isSameOrAfter(deadlineDateMoment)) {
      errorList.push(
        Result.Error(CreateNewContactErrors.InvalidReservationDate, "送信予約日時が回答期限以降に設定されています。")
      )
    }
    if (reminderDateMoment && reservationDateMoment.isAfter(reminderDateMoment)) {
      errorList.push(
        Result.Error(CreateNewContactErrors.InvalidReservationDate, "送信予約日時が催促日時以降に設定されています。")
      )
    }
  }

  if (reminderDate) {
    const now = moment()
    const reminderDateMoment = moment(reminderDate)
    const deadlineDateMoment = deadlineDate ? moment(deadlineDate) : null

    if (reminderDateMoment.isBefore(now)) {
      errorList.push(Result.Error(CreateNewContactErrors.InvalidReminderDate, "現在時刻よりも前の日時が設定されています。"))
    }
    if (deadlineDateMoment && reminderDateMoment.isAfter(deadlineDateMoment)) {
      errorList.push(Result.Error(CreateNewContactErrors.InvalidReminderDate, "催促日時が回答期限以降に設定されています。"))
    }
  }

  if (deadlineDate) {
    const now = moment()
    const deadline = moment(deadlineDate)
    if (deadline.isBefore(now)) {
      errorList.push(Result.Error(CreateNewContactErrors.InvalidDeadlineDate, "現在時刻よりも前の日時が設定されています。"))
    }
  }

  return errorList.length === 0 ? null : errorList
}

export const requestBodySelector = selector<Result<CreateNewContactRequestParams, CreateNewContactErrors>[]>({
  key: "selectors/contact/create/requestBodySelector",
  get: ({ get }) => {
    const partialRequestBody = get(partialRequestBodySelector)
    let errorResult: Result<CreateNewContactRequestParams, CreateNewContactErrors> | null = null
    if (!partialRequestBody.isOk) {
      return [partialRequestBody]
    }
    const { contactType, receivers, title, body, isAddToOtherAdminsHistory, isEnableForComment, reservationDate } =
      partialRequestBody.content
    if (contactType == null) {
      return [Result.Error(CreateNewContactErrors.ContactTypeIsEmpty)]
    }

    if (contactType == ContactType.Attendance) {
      const { isPublishAnswersToMembers, deadlineDate, reminderDate } = partialRequestBody.content
      if (isPublishAnswersToMembers == null) {
        if (errorResult == undefined) errorResult = Result.Error(CreateNewContactErrors.InvalidState)
      }
      const dateError = validateDate(reservationDate, deadlineDate, reminderDate)
      if (dateError) {
        return dateError
      }
    }
    if (contactType == ContactType.Survey) {
      const { questions, isPublishAnswersToMembers, deadlineDate, reminderDate } = partialRequestBody.content
      if (questions == null) {
        if (errorResult == undefined) errorResult = Result.Error(CreateNewContactErrors.SurveyIsEmpty)
      }
      if (isPublishAnswersToMembers == null) {
        if (errorResult == undefined) errorResult = Result.Error(CreateNewContactErrors.InvalidState)
      }
      const dateError = validateDate(reservationDate, deadlineDate, reminderDate)
      if (dateError) {
        return dateError
      }
    }
    if (contactType == ContactType.ScheduleAdjustment) {
      const { eventDateCandidates, isPublishAnswersToMembers, deadlineDate, reminderDate } = partialRequestBody.content
      if (eventDateCandidates == null) {
        if (errorResult == undefined) errorResult = Result.Error(CreateNewContactErrors.CandidatesAreEmpty)
      }
      if (isPublishAnswersToMembers == null) {
        if (errorResult == undefined) errorResult = Result.Error(CreateNewContactErrors.InvalidState)
      }
      const dateError = validateDate(reservationDate, deadlineDate, reminderDate)
      if (dateError) {
        return dateError
      }
    }
    if (errorResult) return [errorResult]

    if (
      receivers == null ||
      (!receivers.isAllMembers &&
        !receivers.isAllLeaders &&
        (receivers.groupIds || []).length === 0 &&
        (receivers.memberIds || []).length === 0)
    ) {
      if (errorResult == undefined) errorResult = Result.Error(CreateNewContactErrors.ReceiversAreEmpty)
    }
    if (title == null || title.trim() === "") {
      if (errorResult == undefined) errorResult = Result.Error(CreateNewContactErrors.TitleIsEmpty)
    }
    if (body == null || body.trim() === "") {
      if (errorResult == undefined) errorResult = Result.Error(CreateNewContactErrors.BodyIsEmpty)
    }
    if (isAddToOtherAdminsHistory == null || isEnableForComment == null) {
      if (errorResult == undefined) errorResult = Result.Error(CreateNewContactErrors.InvalidState)
    }
    const dateError = validateDate(reservationDate)
    if (dateError) {
      return dateError
    }

    if (
      receivers == null ||
      (!receivers.isAllMembers &&
        !receivers.isAllLeaders &&
        (receivers.groupIds || []).length === 0 &&
        (receivers.memberIds || []).length === 0) ||
      title == null ||
      title.trim() === "" ||
      body == null ||
      body.trim() === "" ||
      isAddToOtherAdminsHistory == null ||
      isEnableForComment == null
    ) {
      return [errorResult ?? Result.Error(CreateNewContactErrors.ContactTypeIsEmpty)]
    }
    const commonPart: CreateNewContactCommonRequestParams = {
      receivers,
      title,
      body,
      isAddToOtherAdminsHistory,
      isEnableForComment,
      reservationDate,
    }
    if (contactType === ContactType.Normal) {
      return [
        Result.Ok<CreateNewContactRequestParams>({
          ...commonPart,
          contactType,
        }),
      ]
    }
    switch (contactType) {
      case ContactType.Attendance: {
        const { isPublishAnswersToMembers, deadlineDate, reminderDate } = partialRequestBody.content

        if (isPublishAnswersToMembers == null) {
          return [Result.Error(CreateNewContactErrors.InvalidState)]
        }
        return [
          Result.Ok<CreateNewContactRequestParams>({
            ...commonPart,
            contactType,
            isPublishAnswersToMembers,
            deadlineDate,
            reminderDate,
          }),
        ]
      }
      case ContactType.Survey: {
        const { questions, isPublishAnswersToMembers, deadlineDate, reminderDate } = partialRequestBody.content
        if (errorResult != undefined || questions == null || isPublishAnswersToMembers == null) {
          return [errorResult ?? Result.Error(CreateNewContactErrors.SurveyIsEmpty)]
        }
        return [
          Result.Ok<CreateNewContactRequestParams>({
            ...commonPart,
            contactType,
            questions,
            isPublishAnswersToMembers,
            deadlineDate,
            reminderDate,
          }),
        ]
      }
      case ContactType.ScheduleAdjustment: {
        const { eventDateCandidates, isPublishAnswersToMembers, deadlineDate, reminderDate } = partialRequestBody.content
        if (errorResult != undefined || eventDateCandidates == null || isPublishAnswersToMembers == null) {
          return [errorResult ?? Result.Error(CreateNewContactErrors.CandidatesAreEmpty)]
        }
        return [
          Result.Ok<CreateNewContactRequestParams>({
            ...commonPart,
            contactType,
            eventDateCandidates,
            isPublishAnswersToMembers,
            deadlineDate,
            reminderDate,
          }),
        ]
      }
      default: {
        throw new Error(`contactType (${contactType}) is not implemented at requestBodySelector.`)
      }
    }
  },
})
