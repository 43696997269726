import { useCallback, useMemo, useState } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { login } from "src/apis/auth/login"
import { signup } from "src/apis/auth/signup"
import { AuthMethod } from "src/constants/AuthMethod"
import { emailState } from "src/recoil/atoms/authorization/emailState"
import { sessionState } from "src/recoil/atoms/authorization/sessionState"
import { CustomAlert } from "src/utils/CustomAlert"
import { handleAuthError } from "src/utils/handleAuthError"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  action?: (email: string) => Promise<void>
}

export const useEmailLoginData = (onSuccess?: (email: string) => void) => {
  const setSession = useSetRecoilState(sessionState)
  const loginAction = useCallback(
    async (email: string) => {
      try {
        const result = await login({ authMethod: AuthMethod.Email, value: email })
        if (!result.isOk) {
          if (result.error.errorCode?.toString() === "003")
            return await CustomAlert.alert(
              "エラー",
              `${result.error.message}\n\n※らくらく連絡網（旧サービス）から移行する方は「新規登録」よりアカウントを作成してください。 `
            )
          await CustomAlert.alert("エラー", result.error.message)
          return
        }
        const { session } = result.content
        setSession(session)
        onSuccess?.(email)
      } catch (error) {
        handleAuthError(error)
      }
    },
    [onSuccess, setSession]
  )
  return useAuthEmailData({ action: loginAction })
}

export const useEmailSignupData = (onSuccess?: (email: string) => void) => {
  const setSessionState = useSetRecoilState(sessionState)
  const signupAction = useCallback(
    async (email: string) => {
      try {
        const result = await signup({ email, appInfo })
        if (!result.isOk) {
          await CustomAlert.alert("エラー", result.error.message)
          return
        }
        setSessionState(result.content.session)
        onSuccess?.(email)
      } catch (error) {
        handleAuthError(error)
      }
    },
    [onSuccess, setSessionState]
  )
  return useAuthEmailData({ action: signupAction })
}

const useAuthEmailData = ({ action }: Params) => {
  const [email, setEmail] = useRecoilState(emailState)
  const [isSending, setIsSending] = useState(false)
  const error = useMemo(() => {
    if (email === "") {
      return "メールアドレスを入力してください"
    }
  }, [email])
  const isDisabled = useMemo(() => error != null || isSending, [error, isSending])
  const sendAuthCode = useCallback(async () => {
    setIsSending(true)
    try {
      await action?.(email)
    } finally {
      setIsSending(false)
    }
  }, [action, email])

  return {
    email,
    setEmail,
    isSending,
    isDisabled,
    sendAuthCode,
  }
}
