import { atom, selector } from "recoil"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { SignupStatus } from "src/aws/API"

export const birthDateState = atom({
  key: "atoms/account/birthDateState",
  default: selector({
    key: "atoms/account/birthDateState_default",
    get: ({ get }) => {
      const account = get(accountSelector)
      return account?.birthDate
    },
  }),
})
