import { FC } from "react"
import Svg, { G, Path } from "react-native-svg"

/*
 ** ばつボタン
 */

type Props = {
  fill: string
}

export const NoIcon: FC<Props> = ({ fill }) => (
  <Svg width="14.166" height="14.166" viewBox="0 0 14.166 14.166">
    <G data-name="グループ 2714">
      <Path
        data-name="線 339"
        transform="translate(1.061 1.061)"
        fill="none"
        stroke={fill}
        strokeWidth="3px"
        d="m0 0 12.045 12.045"
      />
      <Path
        data-name="線 340"
        transform="translate(1.061 1.061)"
        fill="none"
        stroke={fill}
        strokeWidth="3px"
        d="M12.045 0 0 12.045"
      />
    </G>
  </Svg>
)

NoIcon.displayName = "NoIcon"
