import { ComponentProps, memo, ReactNode, useMemo } from "react"
import { ScrollView, StyleSheet, useWindowDimensions, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"

type Props = {
  style?: ComponentProps<typeof ScrollView>["style"]
  children: ReactNode
  scrollEnabled?: ComponentProps<typeof ScrollView>["scrollEnabled"]
  refreshControl?: ComponentProps<typeof ScrollView>["refreshControl"]
  isLargeScreen?: boolean
  noPaddingBottom?: boolean
  isSmallContactDetail?: boolean
}

export const CustomScrollView = memo<Props>(
  ({ children, style, isLargeScreen, noPaddingBottom, isSmallContactDetail, ...rest }) => {
    const insets = useSafeAreaInsets()
    const { height } = useWindowDimensions()
    const headerHeight = 64
    const heightScroll = useMemo(() => height - headerHeight, [height])

    return isLargeScreen ? (
      <View style={[styles.container, style]}>{children}</View>
    ) : (
      <ScrollView
        style={[isSmallContactDetail ? { height: heightScroll } : styles.container, style]}
        scrollIndicatorInsets={{ right: 1 }}
        contentContainerStyle={{ paddingBottom: noPaddingBottom ? 0 : insets.bottom }}
        {...rest}
      >
        {children}
      </ScrollView>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
