import { selector } from "recoil"
import { listNotificationSettings, ListNotificationSettingsSuccess } from "src/apis/settings/listNotificationSettings"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"

export const notificationSettingsSelector = selector<ListNotificationSettingsSuccess[] | undefined>({
  key: "selectors/settings/notificationSettingsSelector",
  get: async ({ get }) => {
    const tokens = get(tokensState)
    if (tokens == null) {
      return
    }
    const result = await listNotificationSettings({ accessToken: tokens.accessToken })
    if (!result.isOk) {
      return
    }
    return result.content
  },
})
