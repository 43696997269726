import { memo, useCallback } from "react"
import { FlatList, ListRenderItemInfo, Platform, StyleSheet, View } from "react-native"
import { ContactItem } from "src/components/parts/ContactItem"
import { ContactListEmpty } from "src/components/parts/contactNetworkTab/ContactListEmpty"
import { ContactQueryBuilder } from "src/components/parts/contactNetworkTab/ContactQueryBuilder"
import { DrawerStatus } from "src/components/parts/contactNetworkTab/DrawerStatus"
import { TemplateIcon } from "src/components/parts/icons/drawer/TemplateIcon"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { ContactNetworkDrawers } from "src/constants/ContactNetworkDrawers"
import { CreateNewContactScreens } from "src/constants/CreateNewContactScreens"
import { Screens } from "src/constants/Screens"
import { DrawerScreenOptions } from "src/navigation/ContactNetworkDrawer"
import { useTemplateData } from "src/recoil/hooks/screens/useTemplateData"
import type { ContactNetworkDrawerProps } from "src/types.d"
import { ContactFilterType } from "src/types/contact/ContactFilter"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { DrawerIconContainer } from "src/components/parts/contactNetworkTab/DrawerIconContainer"
import { useContactNetworkDrawerNavEffect } from "src/recoil/hooks/contact/useContactNetworkDrawerNavEffect"
import { RootTabs } from "src/constants/RootTabs"
import { Analytics } from "src/tags/analytics/Analytics"
import { BannerAd } from "src/tags/ads/BannerAd"
import { adLoadState, isLoadDoneAd } from "src/recoil/atoms/ads/adState"
import { useRecoilState } from "recoil"
import { MainLayout } from "src/layouts/MainLayout"
import { DrawerLabel } from "src/components/parts/contactNetworkTab/DrawerLabel"
export type TemplateScreenParams = {
  organizationId?: string
}

export const TemplateScreen = memo<ContactNetworkDrawerProps<typeof ContactNetworkDrawers.TemplateScreen>>(
  ({ navigation, route }) => {
    const {
      searchText,
      setSearchText,
      filters,
      setFilters,
      contacts,
      isLoadingContacts,
      refreshTemplateContacts,
      execDeleteContactTemplate,
      execUpdateFavoriteContact,
      resetCreateNewContactScreenData,
      favoriteFlgsCache,
      organizationId,
      organization,
      refreshOrganizationDetail,
    } = useTemplateData()
    const [adLoadStateList] = useRecoilState(adLoadState)

    // web版のリロードに対応するため、クエリパラメータを付与してリダイレクト
    useContactNetworkDrawerNavEffect({
      queryParamOrganizationId: route.params?.organizationId,
      screenName: ContactNetworkDrawers.TemplateScreen,
      redirect: (organizationId) => {
        navigation.replace(Screens.Root, {
          screen: RootTabs.ContactNetworkDrawer,
          params: {
            screen: ContactNetworkDrawers.TemplateScreen,
            params: { organizationId: organizationId },
          },
        })
      },
    })

    const gotoCreateNewContact = useCallback(
      async (templateId: string) => {
        if (organizationId != null) await Analytics.goToCreateMail(organizationId)
        resetCreateNewContactScreenData({ mode: "New", templateId })
        navigation.navigate(Screens.CreateNewContactStack, { screen: CreateNewContactScreens.CreateNewContact })
      },
      [navigation, resetCreateNewContactScreenData, organizationId]
    )

    const ListItem = useCallback(
      (listRenderItemInfo: ListRenderItemInfo<ContactOverviewModel>) => {
        return (
          <View style={styles.contentWrapper}>
            <ContactItem
              contactId={listRenderItemInfo.item.id}
              viewType="Template"
              title={listRenderItemInfo.item.title}
              contactType={listRenderItemInfo.item.contactType}
              hasAttachment={listRenderItemInfo.item.isAttachmentExists}
              onContactPress={() => gotoCreateNewContact(listRenderItemInfo.item.id)}
              onDeletePress={() => execDeleteContactTemplate(listRenderItemInfo.item.id)}
              updatedDate={listRenderItemInfo.item.updatedDate || new Date()}
              isFavorite={listRenderItemInfo.item.isFavorite}
              onIsFavoriteChange={() =>
                execUpdateFavoriteContact({
                  templateId: listRenderItemInfo.item.id,
                  isFavorite: !(favoriteFlgsCache?.[listRenderItemInfo.item.id] ?? listRenderItemInfo.item.isFavorite),
                })
              }
              favoriteFlgsCache={favoriteFlgsCache}
              noBorder
              unreadThreadCount={0}
            />
            {Platform.OS !== "web" &&
            (!organization?.paidFunctionEnabled || organization?.showAdsFlg) &&
            listRenderItemInfo.index === 2 ? (
              <View style={!isLoadDoneAd("template_upper", adLoadStateList) && { height: 0 }}>{BannerAd.TemplateUpper}</View>
            ) : null}
            {Platform.OS !== "web" &&
            (!organization?.paidFunctionEnabled || organization?.showAdsFlg) &&
            listRenderItemInfo.index === 5 ? (
              <View style={!isLoadDoneAd("template_bottom", adLoadStateList) && { height: 0 }}>{BannerAd.TemplateBottom}</View>
            ) : null}
          </View>
        )
      },
      [
        favoriteFlgsCache,
        adLoadStateList,
        gotoCreateNewContact,
        execDeleteContactTemplate,
        execUpdateFavoriteContact,
        organization,
      ]
    )

    return (
      <MainLayout>
        <View style={[styles.container]}>
          <DrawerStatus Icon={<TemplateIcon color={Colors.orange} />} label="テンプレート" />
          <ContactQueryBuilder
            filterType={ContactFilterType.Template}
            filters={filters}
            onFiltersChange={setFilters}
            searchText={searchText}
            onSearchTextChange={setSearchText}
          />
          <FlatList
            data={contacts}
            renderItem={ListItem}
            ListEmptyComponent={!isLoadingContacts ? <ContactListEmpty label="連絡はありません。" /> : undefined}
            refreshing={isLoadingContacts}
            refreshControl={
              <CustomRefreshControl
                refreshing={isLoadingContacts}
                onRefresh={() => {
                  refreshTemplateContacts()
                  refreshOrganizationDetail()
                }}
              />
            }
          />
        </View>
      </MainLayout>
    )
  }
)
TemplateScreen.displayName = "TemplateScreen"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.white,
    height: 50,
  },
  headerTitle: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginLeft: 8,
  },
  contentWrapper: {
    paddingHorizontal: 16,
    paddingTop: 10,
  },
})

export const templateScreenOptions: DrawerScreenOptions = {
  title: "テンプレート",
  drawerIcon: (props) => (
    <DrawerIconContainer>
      <TemplateIcon {...props} color={Colors.orange} />
    </DrawerIconContainer>
  ),
  drawerLabel: () => <DrawerLabel title="テンプレート" showBadge={false} />,
}
