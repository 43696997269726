import type { ValueOf } from "src/types.d"

export const ContactType = Object.freeze({
  Normal: "Normal",
  Attendance: "Attendance",
  Survey: "Survey",
  ScheduleAdjustment: "ScheduleAdjustment",
  Event: "Event",
  Safety: "Safety",
} as const)

export type ContactType = ValueOf<typeof ContactType>

export const ContactTypeLabel = {
  [ContactType.Normal]: "通常連絡",
  [ContactType.Attendance]: "出欠確認",
  [ContactType.Survey]: "アンケート",
  [ContactType.ScheduleAdjustment]: "日程調整",
  [ContactType.Event]: "イベント",
  [ContactType.Safety]: "安否確認",
}

export const contactTypes = Object.values(ContactType)

const outOfScope: ContactType[] = [ContactType.Event, ContactType.Safety]

export const contactTypeOptions = contactTypes
  .filter((t) => !outOfScope.includes(t))
  .map((t) => ({ value: t, label: ContactTypeLabel[t] }))
