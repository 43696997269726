import { Result } from "src/utils/Result"
import { listAccountQuestionMaster } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import { AccountQuestionModel } from "src/types/user/AccountQuestionModel"

type Param = {
  accessToken: string
}

type Success = AccountQuestionModel[]

type Failure = {
  message: string
}

export const getAccountQuestions = async ({ accessToken }: Param): Promise<Result<Success, Failure>> => {
  return execApi(
    () => listAccountQuestionMaster(accessToken, { appInfo }),
    res => {
      const items = res.listAccountQuestionMaster.items
      return items.map(item => ({
        id: item.id,
        label: item.label,
        choices: item.choices.items.map((c, i) => ({
          id: c.id,
          label: c.label,
          isDefault: i === (item.choices.items.length -1)
        }))
      }))
    },
    {
      identifiers: ["getAccountQuestions", "listAccountQuestionMaster"],
    },
  )
}
