import Constants from "expo-constants"
import { memo, useState } from "react"
import { ScrollView, StyleSheet, Text, View, Platform } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Button } from "src/components/parts/buttons/Button"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { RootTabs } from "src/constants/RootTabs"
import { Screens } from "src/constants/Screens"
import { useSettingsTabData } from "src/recoil/hooks/settings/useSettingsTabData"
import { SettingStackScreenProps } from "src/types.d"
import { InformationIcon } from "src/components/parts/icons/settings/InformationIcon"
import { AccountIcon } from "src/components/parts/icons/settings/AccountIcon"
import { NotificationOptionsIcon } from "src/components/parts/icons/settings/NotificationOptionsIcon"
import { UserGuideIcon } from "src/components/parts/icons/settings/UserGuideIcon"
import { TeamsIcon } from "src/components/parts/icons/settings/TermsIcon"
import { LogoutIcon } from "src/components/parts/icons/settings/LogoutIcon"
import { BadgeIcon } from "src/components/parts/icons/BadgeIcon"
import { ConfirmModal } from "src/components/parts/Modal"
import { GuideIcon } from "src/components/parts/icons/settings/GuideIcon"
import { useSignout } from "src/recoil/hooks/authorization/useSignout"
import { MainLayout } from "src/layouts/MainLayout"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { CustomAlert } from "src/utils/CustomAlert"
export const SettingsTab = memo<SettingStackScreenProps<typeof RootTabs.SettingsTab>>(({ navigation }) => {
  const isPCScreen = useCheckPCScreen()
  const { isInformationsUnread, refresh, openTerms, openPaidVersion, openPrivacyPolicy, openUserGuide } = useSettingsTabData()
  const safeareaInsets = useSafeAreaInsets()

  const { signout, isProcessingSignout } = useSignout()
  const [signoutConfirmModal, setSignoutConfirmModal] = useState(false)

  const handleSignOut = async () => {
    setSignoutConfirmModal(false)
    await signout()
  }
  const signOutAction = async () => {
    if (Platform.OS === "web") {
      const isConfirmed = await CustomAlert.confirm("確認", "本当にログアウトしますか？")
      if (isConfirmed) {
        await handleSignOut()
      }
    } else {
      setSignoutConfirmModal(true)
    }
  }

  const isLargeScreen = useCheckPCScreen()

  return (
    <MainLayout>
      {isLargeScreen ? (
        <View
          style={{
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            padding: 15,
          }}
        >
          <Text style={styles.fontSizeHeader}>設定</Text>
        </View>
      ) : (
        <></>
      )}
      <View style={[styles.container, isLargeScreen ? styles.threeColumn : {}]}>
        <ConfirmModal
          visible={signoutConfirmModal}
          closeAction={() => setSignoutConfirmModal(false)}
          content="本当にログアウトしますか？"
          okAction={handleSignOut}
        />
        <ScrollView
          style={styles.scrollArea}
          contentContainerStyle={{ paddingBottom: safeareaInsets.bottom }}
          refreshControl={<CustomRefreshControl refreshing={false} onRefresh={refresh} />}
        >
          <Button
            style={styles.itemContainer}
            onPress={() => navigation.navigate(Screens.Information)}
            disabled={isProcessingSignout}
          >
            <View style={styles.rowContainerLeft}>
              <InformationIcon />
              <Text style={styles.rowTitle}>サービスからのお知らせ</Text>
            </View>
            {isInformationsUnread ? <BadgeIcon size={"medium"} /> : null}
          </Button>
          <Button
            style={styles.itemContainer}
            onPress={() => navigation.navigate(Screens.AccountInfo)}
            disabled={isProcessingSignout}
          >
            <View style={styles.rowContainerLeft}>
              <AccountIcon />
              <Text style={styles.rowTitle}>アカウント</Text>
            </View>
          </Button>
          <Button
            style={styles.itemContainer}
            onPress={() => navigation.navigate(Screens.NotificationSettings)}
            disabled={isProcessingSignout}
          >
            <View style={styles.rowContainerLeft}>
              <NotificationOptionsIcon />
              <Text style={styles.rowTitle}>通知設定</Text>
            </View>
          </Button>
          <Button style={styles.itemContainer} onPress={openUserGuide} disabled={isProcessingSignout}>
            <View style={styles.rowContainerLeft}>
              <GuideIcon />
              <Text style={styles.rowTitle}>ご利用ガイド</Text>
            </View>
          </Button>
          <Button
            style={styles.itemContainer}
            onPress={() => navigation.navigate(Screens.Inquiry)}
            disabled={isProcessingSignout}
          >
            <View style={styles.rowContainerLeft}>
              <UserGuideIcon />
              <Text style={styles.rowTitle}>お問い合わせ</Text>
            </View>
          </Button>
          <Button style={styles.itemContainer} onPress={openTerms} disabled={isProcessingSignout}>
            <View style={styles.rowContainerLeft}>
              <TeamsIcon />
              <Text style={styles.rowTitle}>利用規約</Text>
            </View>
          </Button>
          <Button style={styles.itemContainer} onPress={openPaidVersion} disabled={isProcessingSignout}>
            <View style={styles.rowContainerLeft}>
              <TeamsIcon />
              <Text style={styles.rowTitle}>特定商取引法に基づく表示</Text>
            </View>
          </Button>
          <Button style={styles.itemContainer} onPress={signOutAction} disabled={isProcessingSignout}>
            <View style={styles.rowContainerLeft}>
              <LogoutIcon />
              <Text style={styles.rowTitle}>ログアウト</Text>
            </View>
          </Button>
          {isPCScreen ? null : (
            <>
              <Text style={styles.weakLabel} onPress={() => navigation.navigate(Screens.Licenses)}>
                {"ライセンス"}
              </Text>
              <Text style={[styles.weakLabel, styles.tailLink]} onPress={openPrivacyPolicy}>
                {"個人情報の取り扱いについて"}
              </Text>
            </>
          )}
        </ScrollView>
        {Platform.OS !== "web" && (
          <View style={styles.footer}>
            <Text style={styles.weakLabel}>アプリバージョン v{Constants.manifest?.version}</Text>
          </View>
        )}
      </View>
    </MainLayout>
  )
})

const styles = StyleSheet.create({
  threeColumn: {
    paddingLeft: 30,
    paddingRight: 30,
  },
  container: {
    padding: 16,
    backgroundColor: "#faf8f5",
    flex: 1,
  },
  footer: {
    alignItems: "center",
  },
  scrollArea: {
    flex: 1,
  },
  weakLabel: {
    color: Colors.gray,
  },
  itemContainer: {
    padding: 16,
    borderRadius: 8,
    height: 64,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 16,
    backgroundColor: "#fff",
  },
  rowContainerLeft: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowTitle: {
    fontSize: 16,
    fontWeight: "bold",
    paddingLeft: 13.3,
  },
  disabled: {
    color: Colors.lightGrey,
  },
  tailLink: {
    marginTop: 8,
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  fontSizeHeader: {
    fontSize: 20,
    fontWeight: "bold",
  },
})
