import { memo, useEffect } from "react"
import { Platform, StyleSheet, Text, useWindowDimensions, View } from "react-native"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { RootStackScreenProps } from "src/types.d"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil"
import { getOrderDetailState } from "src/recoil/selectors/paidTeam/orderDetailState"
import { orderPlanState } from "src/recoil/selectors/paidTeam/orderPlanState"
import dayjs from "dayjs"
import { CustomAlert } from "../../utils/CustomAlert"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { cancelOrder } from "src/apis/paidTeam/cancelOrder"
import { Screens } from "src/constants/Screens"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { ResendEmailOrderType } from "src/aws/API"
import { useFetcher } from "src/hooks/useFetcher"
import { Button } from "src/components/parts/buttons/Button"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { PC_BREAKPOINT } from "src/constants/Screen"
import { isEmpty } from "lodash"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { convertDate } from "src/constants/Day"
import { useRefresher } from "src/hooks/useRefresher"
import { getOrderDetailIdRequestState } from "src/recoil/selectors/paidTeam/getOrderDetailIdRequestState"
import { defaultValue } from "src/recoil/selectors/paidTeam/orderDetailState"
import { getPreOrderDetailState } from "src/recoil/selectors/paidTeam/orderPreDetailState"
import { MIGRATE_PLAN_ID } from "src/utils/const"
import { useIncrementRecoilState } from "src/recoil/hooks/useIncrementRecoilState"
import { notificationsRequestIdState } from "src/recoil/atoms/home/notificationsRequestIdState"

type Props = RootStackScreenProps<"CancelOrder">

const CancelOrder = memo<Props>(({ navigation, route }) => {
  const { teamMembers, accountId, mode } = route.params
  const isLargeScreen = useCheckPCScreen()
  const [orderDetail, setOrderDetail] = useRecoilState(getOrderDetailState)
  const setOrderPreDetail = useSetRecoilState(getPreOrderDetailState)
  const heightScreen = useWindowDimensions().height
  const plan = useRecoilValue(orderPlanState)
  const formatCreatedDate = dayjs(orderDetail?.createdAt ?? "").format("YYYY-MM-DD")
  const refreshOrder = useRefresher(getOrderDetailIdRequestState)
  const refreshNotifications = useIncrementRecoilState(notificationsRequestIdState)

  const insets = useSafeAreaInsets()

  const requestDataResult = useRequestData()

  const accessToken = requestDataResult.isOk ? requestDataResult?.content?.accessToken : ""

  const { fetch: cancelOrderHandler, isFetching: isLoading } = useFetcher(async () => {
    const result = await CustomAlert.confirm("確認", "本当に申込をキャンセルしますか？")
    if (!result) return
    if (accessToken && orderDetail.id) {
      const result = await cancelOrder({ accessToken, orderId: orderDetail.id })
      const mode = result?.id ? "SUCCESS" : isEmpty(result?.errors[0]?.errorInfo?.detail) ? "FAIL_DB" : "FAIL_MAIL"
      if (mode === "SUCCESS" || mode === "FAIL_MAIL") {
        refreshNotifications()
      }
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.CompletedApplyPaid,
          params: {
            mode: mode,
            orderId: orderDetail.id,
            type: ResendEmailOrderType.cancel,
            teamId: orderDetail.teamId ?? undefined,
          },
        },
      })
    }
  })

  const goBack = () => {
    if (mode === "FAIL_DB") {
      setOrderDetail(defaultValue)
      setOrderPreDetail(defaultValue)
      refreshOrder()
    }
    navigation.navigate(Screens.Root, {
      screen: RootTabs.OrganizationManagerTab,
      params: {
        screen: OrganizationManagerScreens.OrderDetail,
        params: {
          teamId: orderDetail.teamId ?? "",
          teamMembers: teamMembers,
          accountId: accountId,
        },
      },
    })
  }

  useEffect(() => {
    navigation.setOptions({
      headerLeft: (props) => (
        <Button
          onPress={() => {
            goBack()
          }}
        >
          <BackIcon
            fill={Number(props.screenLayout?.width) >= PC_BREAKPOINT && Platform.OS === "web" ? Colors.orange : Colors.white3}
            {...props}
          ></BackIcon>
        </Button>
      ),
    })
  })

  return (
    <View style={[{ height: heightScreen - 64 }, Platform.OS === "web" ? {} : { paddingBottom: insets.bottom }]}>
      <CustomScrollView noPaddingBottom={true}>
        <View style={[styles.container]}>
          <View style={[styles.subContainer]}>
            <Text style={[styles.title]}>団体有料版申込情報</Text>
            <View style={[styles.wrapper]}>
              <View style={[styles.row]}>
                <ItemLabel label="受注番号" />
                <Text style={[styles.textField]}>{orderDetail.orderNumber}</Text>
              </View>
              <View style={[styles.row]}>
                <ItemLabel label="プラン" />
                <Text style={[styles.textField]}>
                  {plan.id === MIGRATE_PLAN_ID ? "らくらく連絡網からの移行プラン" : `${plan.memberLimit}名(${plan.period}カ月)`}
                </Text>
              </View>
              <View style={[styles.row]}>
                <ItemLabel label="人数上限" />
                <Text style={[styles.textField]}>{plan.id === MIGRATE_PLAN_ID ? "" : `${plan.memberLimit}名`}</Text>
              </View>
              <View style={[styles.row]}>
                <ItemLabel label="利用期間" />
                <Text style={[styles.textField]}>
                  {plan.id === MIGRATE_PLAN_ID
                    ? orderDetail.endDate && `${convertDate(orderDetail.endDate)}まで`
                    : `${plan.period}カ月（${convertDate(orderDetail.endDate)}まで)`}
                </Text>
              </View>
              <View style={[styles.row]}>
                <ItemLabel label="申込日" />
                <Text style={[styles.textField]}>{convertDate(formatCreatedDate)}</Text>
              </View>
              <View style={[styles.row]}>
                <ItemLabel label="利用開始日" />
                <Text style={[styles.textField]}>{convertDate(orderDetail.startDate)}</Text>
              </View>
              <View style={[styles.row]}>
                <ItemLabel label="料金（税込）" />
                <Text style={[styles.textField]}>
                  {plan.id === MIGRATE_PLAN_ID ? "" : `${orderDetail.amount.toLocaleString("ja-JP")}円`}
                </Text>
              </View>
              <View style={[styles.row]}>
                <ItemLabel label="請求書の宛名[振込名義]" />
                <Text style={[styles.textField]}>{orderDetail.invoiceAddressee}</Text>
              </View>
              <View style={[styles.row]}>
                <ItemLabel label="入金状況" />
                <Text style={[styles.textRed]}>入金前</Text>
              </View>
              <View style={[styles.row]}>
                <ItemLabel label="備考欄" />
                <Text style={[styles.longText]}>{orderDetail.applicantRemark}</Text>
              </View>
            </View>
          </View>
        </View>
      </CustomScrollView>
      <View style={[styles.buttonContainer, isLargeScreen ? null : { paddingVertical: 24 }]}>
        <View
          style={[
            isLargeScreen
              ? {
                  maxWidth: 600,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  paddingHorizontal: 24,
                }
              : { width: "100%" },
          ]}
        >
          <TextButton
            buttonType={ButtonType.Secondary}
            title="戻る"
            onPress={() => {
              goBack()
            }}
            style={[isLargeScreen ? { width: "49%" } : null]}
          />
          <TextButton
            buttonType={ButtonType.Primary}
            title="キャンセルを実行"
            isLoading={isLoading}
            onPress={async () => {
              cancelOrderHandler()
            }}
            style={[isLargeScreen ? { width: "49%" } : { marginTop: 16 }]}
          />
        </View>
      </View>
    </View>
  )
})

export const CancelOrderOption = {
  ...commonHeaderOptions,
  title: "有料版申込のキャンセル",
}

const styles = StyleSheet.create({
  container: {
    paddingVertical: 24,
    paddingHorizontal: 16,
    alignItems: "center",
    backgroundColor: "rgba(159, 134, 107, 0.10)",
  },
  subContainer: {
    width: "100%",
    maxWidth: 600,
  },
  title: {
    marginBottom: 16,
    fontSize: 18,
    fontWeight: "600",
    lineHeight: 25.2,
  },
  wrapper: {
    borderRadius: 8,
    backgroundColor: Colors.white3,
    paddingVertical: 24,
    paddingHorizontal: 16,
    shadowColor: "rgba(41, 39, 36, 0.06)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 30,
    elevation: 6,
  },
  row: {
    marginBottom: 16,
    width: "100%",
    borderBottomColor: Colors.borderColor,
    borderBottomWidth: 1,
  },
  textField: {
    paddingVertical: 14,
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 22.4,
  },
  longText: {
    fontSize: 14,
    marginTop: 16,
    fontWeight: "300",
    lineHeight: 20,
    marginBottom: 14,
  },
  textRed: {
    paddingVertical: 14,
    fontSize: 16,
    fontWeight: "600",
    lineHeight: 22.4,
    color: Colors.red,
  },
  buttonContainer: {
    paddingHorizontal: 24,
    width: "100%",
    alignItems: "center",
    borderTopColor: Colors.borderColor,
    borderTopWidth: 1,
    paddingVertical: 12,
  },
})

export default CancelOrder
