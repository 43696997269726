import { atomFamily, selectorFamily } from "recoil"
import { notificationSettingsSelector } from "src/recoil/selectors/settings/notificationSettingsSelector"

export const isNewMemberEmailEnabledStateFamily = atomFamily<boolean | undefined, string>({
  key: "atoms/settings/notifications/isNewMemberEmailEnabledStateFamily",
  default: selectorFamily<boolean | undefined, string>({
    key: "atoms/settings/notifications/isNewMemberEmailEnabledStateFamily_default",
    get:
      (organizationId) =>
      ({ get }) => {
        const settings = get(notificationSettingsSelector)
        if (settings == null) {
          return
        }
        const settingSelect = settings.find((e) => e.teamId === organizationId)
        return settingSelect?.joinRequestNotice.email
      },
  }),
})
