import { selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { groupDetailRequestIdState } from "src/recoil/atoms/organization/group/groupDetailRequestIdState"
import { GroupDetail } from "src/types/organization/group/GroupDetail"
import { getGroupDetail } from "src/queries/organization/getGroupDetail"

export const groupDetailSelectorFamily = selectorFamily<GroupDetail | undefined, string | undefined>({
  key: "selectors/organization/group/groupDetailSelectorFamily",
  get:
    (groupId) =>
    async ({ get }) => {
      const tokens = get(tokensState)
      get(groupDetailRequestIdState)
      if (tokens == null || groupId == null) return

      return await getGroupDetail({ accessToken: tokens.accessToken, groupId })
    },
})
