import { Result } from "src/utils/Result"
import { updateThreadMessage } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type UpdateContactReplyThreadMessageParams = {
  accessToken: string
  replyThreadMessageId: string
}

type UpdateContactReplyThreadMessageSuccess = unknown

type UpdateContactReplyThreadMessageFailure = {
  message: string
}

export const markContactReplyThreadMessageAsRead = async (params: UpdateContactReplyThreadMessageParams): Promise<Result<UpdateContactReplyThreadMessageSuccess, UpdateContactReplyThreadMessageFailure>> => {
  const {accessToken, replyThreadMessageId} = params
  return execApi(
    () => updateThreadMessage(accessToken, {
      input: {
        id: replyThreadMessageId,
        readFlg: true
      },
      appInfo
    }),
    _ => undefined
  )
}
