import { Result } from "../../utils/Result";
import { GqlError, migrateTeam } from "../../aws/customAPI";

type Params = {
  accessToken: string;
  oldRa9Ra9AccessToken: string;
  userId: number;
  teamId: number;
  memberId: number;
}

type PostTeamSuccess = {
  newTeamId: string;
  memberCount: number;
  uninvitedMembers: UninvitedMembers[] | undefined
}

export type UninvitedMembers ={
  memberId: number,
  nickname: string,
  reason: string,
}

type PostTeamFailure = {
  message: string;
}

export const migrationTeam = async ({
                                      accessToken,
                                      oldRa9Ra9AccessToken,
                                      userId,
                                      teamId,
                                      memberId
                                    }: Params): Promise<Result<PostTeamSuccess, PostTeamFailure>> => {
  return await migrateTeam(accessToken, {
    input: {
      oldRa9Ra9AccessToken: oldRa9Ra9AccessToken,
      oldRa9Ra9UserId: userId,
      oldRa9Ra9TeamId: teamId,
      oldRa9Ra9MemberId: memberId
    }
  }).then(res => {
    if (res.data) {
      return Result.Ok<PostTeamSuccess>({
        newTeamId: res.data?.migrateTeam.newTeamId,
        memberCount: res.data?.migrateTeam.invitedMemberCount,
        uninvitedMembers: res.data?.migrateTeam.uninvitedMembers
      });
    } else {
      return Result.Error<PostTeamFailure>({
        // 到達しない
        message: "システムエラーが発生しました。"
      });
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "移行に失敗しました。";
    return Result.Error<PostTeamFailure>({
      message: message
    });
  });
};