import { Dimensions, Platform, StyleSheet } from "react-native"
import { HeaderLeft, HeaderLeftOnNestedScreen } from "src/components/parts/headers/HeaderLeft"
import { Colors } from "src/constants/Colors"

const isLargeScreen = Dimensions.get("window").width >= 768 && Platform.OS === "web"

const styles = StyleSheet.create({
  header: {
    backgroundColor: isLargeScreen ? Colors.white3 : Colors.orange,
  },
  headerTitle: {
    color: isLargeScreen ? Colors.black : Colors.white,
  },
})

export const commonOptions = {
  headerStyle: styles.header,
  headerTitleStyle: styles.headerTitle,
  headerTitleAlign: "center",
} as const

export const commonHeaderOptions = {
  ...commonOptions,
  headerLeft: HeaderLeft,
} as const

export const commonHeaderOptionsOnNestedScreen = {
  ...commonOptions,
  headerLeft: HeaderLeftOnNestedScreen,
} as const
