import { atom } from "recoil"
import { getOldRa9Ra9KTokens, setOldRa9Ra9KTokens } from "../../../storage/oldRa9Ra9Tokens"

export type oldRa9Ra9Tokens = {
  oldRa9Ra9AccessToken: string
  oldRa9Ra9UserId: string
}

export const oldRa9Ra9TokenStates = atom({
  key: "atoms/migration/oldRa9Ra9TokenStates",
  default: getOldRa9Ra9KTokens(),
  effects: [
    ({ onSet }) => {
      onSet((newValue) => setOldRa9Ra9KTokens(newValue))
    },
  ],
})
