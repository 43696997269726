import { useMemo } from "react"
import { memo } from "react"
import { Svg, Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  color?: string
  filled?: boolean
}

export const HomeIcon = memo<Props>(({ color, filled }) => {
  const defaultColor = useMemo(() => color ?? Colors.white, [color])
  const fill = useMemo(() => {
    if (filled) {
      return defaultColor
    }
    return "none"
  }, [filled, defaultColor])

  return (
    <Svg width="18.36" height="20.289" viewBox="0 0 18.36 20.289">
      <Path
        d="M9.68 13.324a3.224 3.224 0 0 0-3.215 3.215v3.751H1v-12L9.68 1l8.68 7.287v12H12.9l-.107-.214v-3.534a3.07 3.07 0 0 0-3.113-3.215"
        transform="translate(-.5 -.5)"
        fill={fill}
        stroke={defaultColor}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
    </Svg>
  )
})
