import { selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { organizationMembersRequestIdState } from "src/recoil/atoms/organization/organizationMembersRequestIdState"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"
import { accountImages, searchTeamMember } from "src/aws/customAPI"
import { MemberRole, OrderBy, TeamMemberSortBy } from "src/aws/API"
import { MemberType } from "src/types/organization/OrganizationMember"
import Constants from "expo-constants"
import { s3ImageDomain } from "src/utils/s3ImageDomain"

export type SearchTeamMemberApiResponse = {
  items: Array<TeamMemberTypeResponse>
  total: number
  limit: number
  page: number
  sortBy?: string
  orderBy?: OrderBy
}

export type TeamMemberTypeResponse = {
  id: string
  nickname: string
  nicknameKana: string
  role: MemberType
  imageUrl: string
  memberNumber?: number
  isLeader?: boolean
  isAdministrator: boolean
}

export type Param = {
  organizationId: string
  getImage?: boolean
  joinFlg?: boolean
  page: number
  limit?: number
  myGroup?: boolean
  name: string
  excludedIds: Array<string | null>
  excludedGroupId?: string | null
}

const convertRole = (value: MemberRole) => {
  if (value == "mailSender") return "GeneralWithContactAuthorization"
  else if (value == "manager") return "Representative"
  return "General"
}

export const organizationMembersPaginationSelectorFamily = selectorFamily<SearchTeamMemberApiResponse | undefined, Param>({
  key: "selectors/organization/organizationMembersPaginationSelector",
  get:
    ({ organizationId, getImage, page, limit = 10, name, excludedIds = [], excludedGroupId, myGroup }) =>
    async ({ get }) => {
      if (organizationId == null) return

      get(organizationMembersRequestIdState)
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) {
        return
      }
      const accountId = get(accountIdState)
      if (accountId == null) {
        return
      }
      const result = await searchTeamMember(accessToken, {
        input: {
          teamId: organizationId,
          limit: limit,
          page: page,
          sortBy: TeamMemberSortBy.memberSeq,
          orderBy: OrderBy.ASC,
          name: name,
          excludedIds: excludedIds,
          excludedGroupId,
          myGroup,
        },
      })
      if (!result.data) return

      if (!getImage) {
        return {
          limit: limit,
          page: page,
          total: result.data.searchTeamMember.total,
          items: result.data.searchTeamMember.items.map((el) => ({
            id: el.id ?? "",
            nickname: el.nickname ?? "",
            nicknameKana: el.nicknameKana ?? "",
            memberNumber: el.memberSeq,
            role: convertRole(el.role),
            imageUrl: "",
            isAdministrator: el.role === MemberRole.manager,
            isLeader: el.groups.find((el) => el.leaderFlg) != null,
          })),
        }
      }
      const accountIds = result.data.searchTeamMember.items
        .filter((item) => !!item.accountTeamMembersId)
        .map((item) => item.accountTeamMembersId as string)

      const accountImagesResult = await accountImages(accessToken, {
        input: {
          accountIds,
        },
      })
      const accountImageMap: Record<string, string> =
        accountImagesResult.data?.multiAccountImageGetUrl.images?.reduce((map, ele) => {
          return {
            ...map,
            [ele.accountId]: [ele.url],
          }
        }, {}) ?? {}

      return {
        limit: limit,
        page: page,
        total: result.data.searchTeamMember.total,
        items: result.data.searchTeamMember.items.map((el) => ({
          id: el.id ?? "",
          nickname: el.nickname ?? "",
          nicknameKana: el.nicknameKana ?? "",
          memberNumber: el.memberSeq,
          role: convertRole(el.role),
          imageUrl: `${s3ImageDomain}${
            accountImageMap[el.accountTeamMembersId ?? ""] ?? "default/account/image/profile.png"
          }?${new Date().getTime()}`,
          isAdministrator: el.role === MemberRole.manager,
          isLeader: el.groups.find((el) => el.leaderFlg) != null,
        })),
      }
    },
})
