import { memo } from "react"
import { Props } from "src/components/parts/CustomImageView/Props"
import { Image, Modal, StyleSheet, View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { Colors } from "src/constants/Colors"

export const CustomImageView = memo<Props>(({ visible, onRequestClose, imageUri, FooterComponent }) => (
  <Modal visible={visible} onRequestClose={onRequestClose}>
    <View style={styles.container}>
      <Image style={styles.image} source={{ uri: imageUri }} resizeMode="contain" />
    </View>
    <View style={styles.headerContainer}>
      <Button onPress={onRequestClose}>
        <RemoveIcon size={20} fill={Colors.white3} />
      </Button>
    </View>
    <View style={styles.footerContainer}>
      <FooterComponent />
    </View>
  </Modal>
))

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.black2,
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    width: "100%",
    height: "100%",
  },
  headerContainer: {
    position: "absolute",
    left: 0,
    top: 26,
    width: "100%",
    alignItems: "flex-end",
    paddingHorizontal: 26,
  },
  footerContainer: {
    position: "absolute",
    left: 0,
    bottom: 0,
    width: "100%",
  },
})
