import { memo, useCallback, useEffect, useRef } from "react"
import { BottomSheet as BottomSheetWeb } from "react-spring-bottom-sheet"
import "react-spring-bottom-sheet/dist/style.css"
import { Colors } from "src/constants/Colors"
import type { Props } from "./Props"

export const BottomSheet = memo<Props>(({ isOpen, onIsOpenChange, children, backgroundColor = Colors.white, snapPoints }) => {
  const close = useCallback(() => onIsOpenChange(false), [onIsOpenChange])
  const ref = useRef<HTMLElement>(null)

  return (
    <BottomSheetWeb open={isOpen} onDismiss={close} initialFocusRef={ref}>
      {children}
      <Effect backgroundColor={backgroundColor} height={snapPoints[0]} isOpen={isOpen} />
    </BottomSheetWeb>
  )
})

BottomSheet.displayName = "BottomSheetWeb"

const Effect = memo<{ backgroundColor?: string; height?: any; isOpen?: boolean }>(({ backgroundColor, height, isOpen }) => {
  useEffect(() => {
    const element = document.querySelector("[data-rsbs-overlay]")
    if (element == null) {
      return
    }

    element.setAttribute("style", `background-color: ${backgroundColor}; height:${isOpen ? height : 0}`)
  }, [backgroundColor, height, isOpen])

  return null
})
