import { atom, selector } from "recoil"
import { draftSelector } from "src/recoil/selectors/contact/draftSelector"
import { reservedSelector } from "src/recoil/selectors/contact/reservedSelector"

const initialState = undefined

const initialStateSelector = selector({
  key: "atoms/contact/create/reservationDateState_initialState",
  get: ({ get }) => {
    const reserved = get(reservedSelector)
    const draft = get(draftSelector)
    return reserved?.reservedDate ?? draft?.reservationDate ?? initialState
  },
})

export const reservationDateState = atom<Date | undefined>({
  key: "atoms/contact/create/reservationDateState",
  default: initialStateSelector,
})
