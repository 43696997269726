import { memo } from "react"
import { StyleSheet, View } from "react-native"
import { Colors } from "src/constants/Colors"

type Props = {
  highlighted: boolean
}

export const BorderedItemSeparator = memo<Props>(({ highlighted }) => (
  <View style={[styles.itemSeparator, highlighted ? styles.highlightedSeparator : styles.normalSeparator]} />
))

const styles = StyleSheet.create({
  itemSeparator: {
    height: 1,
  },
  normalSeparator: {
    backgroundColor: Colors.white2,
  },
  highlightedSeparator: {
    backgroundColor: Colors.orange,
  },
})
