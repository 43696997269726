import { memo } from "react"
import { Svg, Path } from "react-native-svg"

export const InformationIcon = memo(() => (
  <Svg width="17.927" height="16" viewBox="0 0 17.927 16">
    <Path
      d="M28.628 15.3a.69.69 0 0 0-.644-.069l-10.227 4.092H11.69a.69.69 0 0 0-.689.689v5.562a.689.689 0 0 0 .689.689h.321v4.229a.69.69 0 0 0 .689.689h3.724a.69.69 0 0 0 .689-.689v-4.228h.644l10.227 4.091.253.046a.689.689 0 0 0 .689-.689V15.876a.69.69 0 0 0-.3-.575zm-8.664 10.367-1.379-.551v-4.643l1.379-.551z"
      transform="translate(-11 -15.183)"
      fill={"#f2caa0"}
    />
  </Svg>
))
