import { useCallback, useMemo } from "react"
import { ActivityIndicator, Image, Text, View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { ShowDate, getToday } from "src/utils/useDateTime"
import { MatchingOffersModel } from "src/types/matching/matchingOfferModel"
import { styles } from "./styles"
import { ListMatchingMessages } from "./MatchingMessageContents"
import { FavoriteIcon } from "../icons/FavoriteIcon"
import { LocationPinIcon } from "../icons/LocationPinIcon"
import { OclockIcon } from "../icons/OclockIcon"
import { Colors } from "src/constants/Colors"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { matchingIcon } from "src/constants/matchingIcon"
import { isEndPublishDatetime } from "src/recoil/hooks/resource/fileManager/common"

dayjs.extend(duration)

type Props = {
  data: MatchingOffersModel
  onPress: () => void
  showFavBtn?: boolean
  handleChangeFav?: (matchingOfferId: string) => void
  to?: string
  checkIsExpiredOrDeleted?: boolean
  isApplications?: boolean
  matchingTeamId?: string
  favoriteFlgsCache?: { [contactId: string]: boolean }
  isLoadingDeleteFavFlg?: boolean
  isLoadingCreateFavFlg?: boolean
  isLoadingFav?: boolean
}

export const MyMatchingCard = ({
  data,
  onPress,
  showFavBtn,
  handleChangeFav,
  checkIsExpiredOrDeleted,
  to,
  isApplications,
  matchingTeamId,
  favoriteFlgsCache,
  isLoadingFav,
}: Props) => {
  const {
    title,
    date,
    startTime,
    endTime,
    city,
    prefecture,
    activity,
    ageFrom,
    ageTo,
    publishEndDate,
    teamName,
    teamId,
    applications,
    id,
    favoriteFlg,
    teamMatchingOffersId,
    deleteFlg,
  } = data
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationIdMine = selectedOrganization?.id
  const { value: organization } = useAsyncSelector(organizationDetailSelectorFamily(organizationIdMine))
  const { resourceUrl: teamImageUrl, refreshResourceUrl: refreshTeamImageUrl } = useResource({
    type: "matchingTeamImage",
    teamId: teamId || "",
    size: "thumbnail",
  })
  const { today } = getToday()
  const formatDateTime = useMemo(
    () => ShowDate(date ? new Date(date) : null, startTime, endTime) + " ",
    [date, startTime, endTime]
  )
  const formatPublishEndDatetime = useMemo(() => publishEndDate && ShowDate(new Date(publishEndDate), true), [publishEndDate])

  const onPressFavoriteBtn = () => handleChangeFav && handleChangeFav(id)

  return (
    <View style={[styles.swipeableContainer]}>
      <Button style={[styles.container]} onPress={onPress}>
        <View
          style={[
            styles.viewContainerCard,
            {
              padding: 16,
              backgroundColor:
                checkIsExpiredOrDeleted && (deleteFlg || isEndPublishDatetime(publishEndDate ?? ""))
                  ? Colors.lightGrey
                  : Colors.white3,
            },
          ]}
        >
          {publishEndDate && <Text style={styles.litmitTime}>募集期限：{formatPublishEndDatetime}まで</Text>}
          <Text style={[styles.titleMatching, { marginBottom: 8 }]}>{title}</Text>
          <View style={styles.rowCard}>
            <Text
              style={[
                styles.bgLabelCard,
                {
                  backgroundColor:
                    checkIsExpiredOrDeleted && (deleteFlg || isEndPublishDatetime(publishEndDate ?? ""))
                      ? Colors.lightGrey
                      : Colors.white3,
                },
              ]}
            >
              {activity?.label}
            </Text>
            {(!!ageFrom || !!ageTo) && (
              <Text
                style={[
                  styles.bgLabelCard,
                  {
                    backgroundColor:
                      checkIsExpiredOrDeleted && (deleteFlg || isEndPublishDatetime(publishEndDate ?? ""))
                        ? Colors.lightGrey
                        : Colors.white3,
                  },
                ]}
              >
                {ageFrom}～{ageTo}歳
              </Text>
            )}
            <Text
              style={[
                styles.bgLabelCard,
                {
                  backgroundColor:
                    checkIsExpiredOrDeleted && (deleteFlg || isEndPublishDatetime(publishEndDate ?? ""))
                      ? Colors.lightGrey
                      : Colors.white3,
                },
              ]}
            >
              合同練習・試合
            </Text>
          </View>
          <View style={styles.rowCard}>
            <OclockIcon />
            <Text style={[styles.textCard]}>
              開催日時：{formatDateTime} {(startTime || endTime) && `${startTime ?? ""}～${endTime ?? ""}`}
            </Text>
          </View>
          {(prefecture || city) && (
            <View style={styles.rowCard}>
              <LocationPinIcon />
              <Text style={[styles.textCard]}>
                {prefecture?.label} {city?.label}
              </Text>
            </View>
          )}
          <View style={styles.footer}>
            <View style={styles.rowCard}>
              <Image
                style={styles.teamImage}
                source={teamImageUrl ? { uri: teamImageUrl } : matchingIcon}
                onError={refreshTeamImageUrl}
              />
              <Text style={[styles.textCard]}>{teamName}</Text>
            </View>
            {showFavBtn && (
              <View>
                {!isLoadingFav ? (
                  <Button onPress={onPressFavoriteBtn}>
                    <FavoriteIcon isFavorite={favoriteFlgsCache?.[id] ?? favoriteFlg} />
                  </Button>
                ) : (
                  <Button>
                    <ActivityIndicator />
                  </Button>
                )}
              </View>
            )}
          </View>
          {applications && (applications?.length ?? 0) !== 0 && id && (
            <ListMatchingMessages
              applications={applications}
              myOrganizationId={organizationIdMine}
              offerId={teamMatchingOffersId}
              to={to}
              isApplications={isApplications}
              matchingTeamId={matchingTeamId}
              matchingTeamName={isApplications ? teamName : undefined}
            />
          )}
        </View>
      </Button>
    </View>
  )
}
