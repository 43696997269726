import { MatchingOffersModel } from "../../types/matching/matchingOfferModel";
import { Result } from "src/utils/Result";
import { GqlError, listMatchingApplication } from "../../aws/customAPI";
import { MatchingPurposeMapping } from "../../types/matching/matchingPurpose";

type GetListMatchingApplicationParams = {
  accessToken: string
  teamId: string
  filter?: {
    keyword?: string | undefined
  }
  limit?: number
  nextToken?:string | null| undefined
}

export type GetListMatchingApplicationSuccess = {
  data: MatchingOffersModel[]
  nextToken?:string
}

type GetListMatchingApplicationFailure = {
  message: string
}

export const getListMatchingApplication = async (params: GetListMatchingApplicationParams): Promise<Result<GetListMatchingApplicationSuccess, GetListMatchingApplicationFailure>> => {
  return await listMatchingApplication(params.accessToken, {
    teamId: params.teamId,
    filter: params.filter,
    limit: params.limit,
    nextToken: params.nextToken ? params.nextToken : undefined
  }).then(res => {
    if (res.data) {
      return Result.Ok<GetListMatchingApplicationSuccess>({
        data: (res.data.searchMatchingApplications.items.map(item => ({
          id: item.offer.id,
          purpose: MatchingPurposeMapping["jointPracticeGame"],
          title: item.offer.title,
          date: item.offer.date,
          startTime: item.offer.startTime,
          endTime: item.offer.endTime,
          prefecture: item.offer.prefecture,
          city: item.offer.city,
          ageFrom: item.offer.ageFrom,
          ageTo: item.offer.ageTo,
          publishEndDate: item.offer.publishEndDate,
          activity: item.offer.activity,
          teamId: item.offer.teamId,
          teamName: item.offer.teamName,
          applicationId: item.id,
          applications:[{id: item.id, messages: {items: item.messages.items} }],
          deleteFlg: item.offer.deleteFlg,
        }))),
        nextToken: res.data.searchMatchingApplications.nextToken ? res.data.searchMatchingApplications.nextToken : undefined
      })
    } else {
      return Result.Error<GetListMatchingApplicationFailure>({ message: "応募の取得に失敗しました" });
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "応募の取得に失敗しました";
    return Result.Error<GetListMatchingApplicationFailure>({
      message: message
    });
  });
}