import { GqlError, getAttendanceAnswerCsvData as _getAttendanceAnswerCsvData } from "src/aws/customAPI";
import { AttendanceAnswerModel } from "src/types/csv/answerCsvData";
import { Result } from "src/utils/Result";

type GetAttendanceAnswerCsvDataRequestParams = {
  accessToken: string
  teamId: string
  mailId: string
}

type GetAttendanceAnswerCsvDataSuccess = AttendanceAnswerModel[]

type GetAttendanceAnswerCsvDataFailure = {
  message: string
}

export const getAttendanceAnswerCsvData = async (
  params: GetAttendanceAnswerCsvDataRequestParams
): Promise<Result<GetAttendanceAnswerCsvDataSuccess, GetAttendanceAnswerCsvDataFailure>> => {
  return _getAttendanceAnswerCsvData(params.accessToken, {
    input: {
      teamId: params.teamId,
      mailId: params.mailId
    }
  }).then(res => {
    const answers: AttendanceAnswerModel[] = res.items.map(item => ({
      memberId: item.memberId,
      memberSeq: item.memberSeq,
      nickname: item.nickname,
      groups: item.groups,
      attendanceAnswer: item.attendanceAnswer,
      answeredAt: item.answeredAt,
    })) || [];
    return Result.Ok<GetAttendanceAnswerCsvDataSuccess>(answers);
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "予期せぬエラーが発生しました";
    return Result.Error<GetAttendanceAnswerCsvDataFailure>({
      message: message
    });
  });
};