import { Result } from "src/utils/Result"
import { updateAuthMethod } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type UpdatePhoneNumberParams = {
  accessToken: string
  phoneNumber: string
}

type UpdatePhoneNumberSuccess = unknown

type UpdatePhoneNumberFailure = {
  message: string
}

export const updatePhoneNumber = async (params: UpdatePhoneNumberParams): Promise<Result<UpdatePhoneNumberSuccess, UpdatePhoneNumberFailure>> => {
  return execApi(
    () => updateAuthMethod(params.accessToken,{
      input: {
        phone_number: params.phoneNumber
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["updatePhoneNumber", "updateAuthMethod"],
    }
  )
}
