import { selectorFamily } from "recoil"
import { getMyProfile, GetMyProfileSuccess } from "src/apis/organization/myProfile/getMyProfile"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { myProfileRequestIdState } from "src/recoil/atoms/organization/myProfile/myProfileRequestIdState"

export const myProfileSelectorFamily = selectorFamily<GetMyProfileSuccess | undefined, string | undefined>({
  key: "selectors/organization/myprofile/myProfileSelectorFamily",
  get:
    (organizationId) =>
    async ({ get }) => {
      get(myProfileRequestIdState)
      const accountId = get(accountIdState)
      const tokens = get(tokensState)
      if (accountId == null || tokens == null || organizationId == null) {
        return
      }
      const { accessToken } = tokens
      const myProfileResult = await getMyProfile({
        accountId,
        accessToken,
        organizationId,
      })
      if (!myProfileResult.isOk) {
        return undefined
      }
      return myProfileResult.content
    },
})
