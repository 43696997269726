import { Buffer } from "buffer"
import { MAX_BYTE_CONTENT_MAIL } from "src/utils/const"

export const validateNumbers = (str: string) => {
  return /^[0-9]*$/.test(str)
}

export const validateKanas = (str: string) => {
  /* eslint no-irregular-whitespace: 0 */
  return /^[ァ-ヶー　ｦ-ﾟ ]*$/.test(str)
}

export const validateSpecialCharacter = (str: string) => {
  return /^([々一-龯ぁ-んァ-ンa-zA-Z0-9０-９ \r\nァ-ヶー　ｦ-ﾟ（〜）、。]+)$/.test(str)
}

export const validateApplicantAddressSpecialCharacter = (str: string) => {
  return /^([々一-龯ぁ-んァ-ンa-zA-Z0-9０-９ \r\nァ-ヶー　ｦ-ﾟ（〜）、。-]+)$/.test(str)
}

export const validateJapanese = (str: string) => {
  return /^([々一-龯ぁ-んァ-ン０-９ ァ-ヶー　ｦ-ﾟ]+)$/.test(str)
}

export const validateBirthday = (time: Date) => {
  const now = new Date()
  const currentYear = now.getFullYear()
  const inputYear = time.getFullYear()

  return inputYear < currentYear
}

export const isEmptyString = (str?: string) => {
  if (!str) {
    return true
  }
  return str.trim().replace("　", "").length === 0
}

export const isValidUrl = (value: string) => {
  const pattern = new RegExp(
    "^((http|https):\\/\\/)" + //プロトコルパターン
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + //ドメインパターン
      "((\\d{1,3}\\.){3}\\d{1,3}))" + //IPアドレスパターン
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // ポート番号またはパス
      "(\\?[;&a-z\\d%_.~+=-]*)?" + //クエリストリング
      "(\\#[-a-z\\d_]*)?$", //フラグ情報
    "i"
  )
  return pattern.test(value)
}

export const convertDataEmail = (content: string): string => {
  const parts = content ? content.split("\n") : []
  let newContent = ""
  for (const part of parts) {
    newContent = splitContent(newContent, part)
  }
  return newContent
}

export const splitContent = (content: string, part: string): string => {
  const byteLength = Buffer.byteLength(part, "utf-8")
  if (byteLength > MAX_BYTE_CONTENT_MAIL) {
    const regex = /(?:[. 　。])/
    const words = part.split(regex)
    let firstHalf = ""
    let secondHalf = ""
    if (words.length == 1) {
      const middleIndex = Math.floor(part.length / 2)
      firstHalf = splitContent(firstHalf, part.slice(0, middleIndex))
      secondHalf = splitContent(secondHalf, part.slice(middleIndex))
    } else {
      const halfIndex = Math.floor(words.length / 2)
      firstHalf = splitContent(firstHalf, words.slice(0, halfIndex).join(""))
      secondHalf = splitContent(secondHalf, words.slice(halfIndex).join(""))
    }
    return content ? `${content}\n${firstHalf}\n${secondHalf}` : `${firstHalf}\n${secondHalf}`
  } else {
    return content ? `${content}\n${part}` : `${part}`
  }
}
