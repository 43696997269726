import type { HeaderBackButtonProps } from "@react-navigation/elements"
import { useNavigation } from "@react-navigation/native"
import { useCallback } from "react"
import { Dimensions, Platform } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { Colors } from "src/constants/Colors"
import { Screens } from "src/constants/Screens"

const isLargeScreen = Dimensions.get("window").width >= 768 && Platform.OS === "web"

export const HeaderLeft = ({ onPress, canGoBack }: HeaderBackButtonProps) => {
  return canGoBack ? (
    <Button onPress={onPress}>
      <BackIcon fill={isLargeScreen ? Colors.orange : Colors.white3} />
    </Button>
  ) : null
}

export const HeaderLeftOnNestedScreen = ({ onPress, canGoBack }: HeaderBackButtonProps) => {
  const navigation = useNavigation()
  const goBack = useCallback(() => {
    canGoBack ? onPress?.() : navigation.reset({ index: 0, routes: [{ name: Screens.Root }] })
  }, [canGoBack, onPress, navigation])

  return (
    <Button onPress={goBack}>
      <BackIcon fill={isLargeScreen ? Colors.orange : Colors.white3} />
    </Button>
  )
}
