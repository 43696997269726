import dayjs from "dayjs"
import { memo, useCallback, useEffect, useRef, useState, ChangeEvent, useMemo } from "react"
import * as Clipboard from "expo-clipboard"
import {
  FlatList,
  Image,
  Keyboard,
  ListRenderItemInfo,
  Platform,
  Pressable,
  ScrollView,
  StyleSheet,
  Text,
  TextInput,
  useWindowDimensions,
  View,
} from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Button } from "src/components/parts/buttons/Button"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CancelWithCircleIcon } from "src/components/parts/icons/CancelWithCircleIcon"
import { CheckWithCircleIcon } from "src/components/parts/icons/CheckWithCircleIcon"
import { DraftIcon } from "src/components/parts/icons/drawer/DraftIcon"
import { NoPersonIcon } from "src/components/parts/icons/NoPersonIcon"
import { SendMessageIcon } from "src/components/parts/icons/SendMessageIcon"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { Colors } from "src/constants/Colors"
import { ContactReplyThreadItem, ContactReplyThreadType } from "src/constants/ContactReplyThreadType"
import { headerHeight } from "src/constants/headerHeight"
import { commonHeaderOptionsOnNestedScreen } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useMarkThreadMessageAsRead } from "src/recoil/hooks/contact/useMarkThreadMessageAsRead"
import { RootStackScreenProps } from "src/types.d"
import { CustomAlert } from "src/utils/CustomAlert"
import { useFetcher } from "src/hooks/useFetcher"
import { useAsyncSelector } from "../../../hooks/useAsyncSelector"
import { listMatchingMessageSelectorFamily } from "src/recoil/selectors/matching/listMatchingMessageSelectorFamily"
import { matchingOfferDetailSelectorFamily } from "src/recoil/selectors/matching/matchingOfferDetailSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { listMatchingMessageRequestIdState } from "src/recoil/atoms/matching/listMatchingMessageRequestIdState"
import { matchingOtherProfileSelectorFamily } from "src/recoil/selectors/matching/matchingOtherProfileSelectorFamily"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { createMatchingMessage, updateMatchingMessage, deleteMatchingMessage } from "src/apis/matching/matchingMessage"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { notificationsSelector } from "src/recoil/selectors/home/notificationsSelector"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { useRecoilValue } from "recoil"
import { markNotificationAsRead } from "src/queries/home/markNotificationAsRead"
import { useIncrementRecoilState } from "src/recoil/hooks/useIncrementRecoilState"
import { notificationsRequestIdState } from "src/recoil/atoms/home/notificationsRequestIdState"
import { isEmptyString } from "src/utils/validate"
import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { RootTabs } from "src/constants/RootTabs"
import { ListActionBackInThread } from "src/constants/OfferDetail"
import { ButtonBackIcon } from "src/components/parts/buttons/ButtonBackIcon"
import { SeeMoreIcon } from "src/components/parts/icons/SeeMoreIcon"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { matchingIcon } from "src/constants/matchingIcon"
import { useInterval } from "src/hooks/useInterval"
import { TIME_INTERVAL_REPLY_THREAD } from "src/constants/Screen"

export const useMatchingThread = (
  applicationId: string | undefined,
  offerId: string | undefined,
  myOrganizationId: string | undefined,
  matchingTeamId?: string
) => {
  const [nextTokens, setNextTokens] = useState<string[]>([])
  const { value, isLoading } = useAsyncSelector(
    listMatchingMessageSelectorFamily({ applicationId, myOrganizationId, nextTokens })
  )
  const { value: matchingOfferDetail } = useAsyncSelector(
    matchingOfferDetailSelectorFamily({ offerId, type: "otherOffer", myOrganizationId })
  )
  const senderTeamId = useMemo(
    () =>
      value?.reversedReplyItems
        .map(
          (item) =>
            (item.contactReplyThreadType === ContactReplyThreadType.ReplyFromOthers ||
              item.contactReplyThreadType === ContactReplyThreadType.RemovedReply) &&
            item.messageSenderTeamId
        )
        .filter((a) => a)[0] || matchingTeamId,

    [value, matchingTeamId]
  )

  const { value: matchingOtherProfile } = useAsyncSelector(
    matchingOtherProfileSelectorFamily({ otherTeamId: senderTeamId, myTeamId: myOrganizationId })
  )

  const { value: matchingOtherProfileGetBlock } = useAsyncSelector(
    matchingOtherProfileSelectorFamily({ otherTeamId: myOrganizationId, myTeamId: senderTeamId })
  )
  const isBlocked = useMemo(() => matchingOtherProfileGetBlock?.isBlocking, [matchingOtherProfileGetBlock])

  const otherTeamName = useMemo(
    () => (matchingOtherProfile ? matchingOtherProfile.name : matchingOfferDetail?.organizationName),
    [matchingOfferDetail, matchingOtherProfile]
  )
  const otherTeamId = useMemo(
    () => (senderTeamId ? senderTeamId : matchingOfferDetail?.organizationId),
    [senderTeamId, matchingOfferDetail]
  )
  const isBlocking = useMemo(() => matchingOtherProfile?.isBlocking, [matchingOtherProfile])
  const offerTitle = useMemo(() => matchingOfferDetail?.title, [matchingOfferDetail])

  const { value: selectedMyOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(
    () => (myOrganizationId ? myOrganizationId : selectedMyOrganization?.id),
    [selectedMyOrganization, myOrganizationId]
  )
  const { value: organizationDetail } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))
  const teamName = useMemo(() => organizationDetail?.organizationName, [organizationDetail?.organizationName])

  const refreshListMatchingMessage = useRefresher(listMatchingMessageRequestIdState)
  useInterval(refreshListMatchingMessage, TIME_INTERVAL_REPLY_THREAD)
  const requestDataResult = useRequestData()

  const { fetch: execCreateMatchingMessage } = useFetcher(
    useCallback(
      async ({ message, callback }: { message: string; callback: () => void }) => {
        if (!requestDataResult.isOk || applicationId == null || organizationId == null) return
        const { accessToken } = requestDataResult.content
        const result = await createMatchingMessage({
          accessToken,
          matchingApplicationId: applicationId,
          messageSenderTeamId: organizationId,
          text: message,
        })
        if (result.isOk) {
          refreshListMatchingMessage()
          callback()
        } else {
          await CustomAlert.alert("エラー", result.error.message)
        }
      },
      [requestDataResult, refreshListMatchingMessage, applicationId, organizationId]
    )
  )

  const { fetch: execDeleteMatchingMessage } = useFetcher(
    useCallback(
      async (threadMessageId: string) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accessToken } = requestDataResult.content
        const isConfirmed = await CustomAlert.confirm("注意", "本当に送信を取り消しますか？元には戻せません。")
        if (!isConfirmed) return
        const result = await deleteMatchingMessage({ accessToken, threadMessageId })
        if (result.isOk) {
          refreshListMatchingMessage()
          await CustomAlert.alert("完了", "送信を取り消しました")
        } else {
          await CustomAlert.alert("エラー", result.error.message)
        }
      },
      [requestDataResult, refreshListMatchingMessage]
    )
  )

  const { fetch: execUpdateMatchingMessage } = useFetcher(
    useCallback(
      async ({ threadMessageId, message, callback }: { threadMessageId: string; message: string; callback: () => void }) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accessToken } = requestDataResult.content
        const result = await updateMatchingMessage({
          accessToken,
          threadMessageId,
          text: message,
        })
        if (result.isOk) {
          refreshListMatchingMessage()
          callback()
        } else {
          await CustomAlert.alert("エラー", result.error.message)
        }
      },
      [requestDataResult, refreshListMatchingMessage]
    )
  )

  const onClickMatchingProfile = () => {
    //
  }

  const { resourceUrl, refreshResourceUrl } = useResource({
    type: "matchingTeamImage",
    teamId: otherTeamId || "",
    size: "normal",
  })

  const [editFlg, setEditFlg] = useState(false)
  const [message, setMessage] = useState("")
  const onChangeMessage = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.currentTarget.value)
  }

  const { fetch: postMessage, isFetching: isPostingMessage } = useFetcher(
    useCallback(async () => {
      if (message == null) {
        return
      }
      await execCreateMatchingMessage({
        message,
        callback: () => {
          setMessage("")
        },
      })
    }, [execCreateMatchingMessage, message])
  )

  const removeMessage = useCallback(
    (replyThreadMessageId: string) => {
      execDeleteMatchingMessage(replyThreadMessageId)
    },
    [execDeleteMatchingMessage]
  )

  const [menuFlg, setMenuFlg] = useState(false)
  const [editingMessageId, setEditingMessageId] = useState<string>()

  const copyMessage = useCallback((message: string) => {
    Clipboard.setStringAsync(message)
  }, [])

  const editMessage = useCallback(
    (id: string, message: string) => {
      setEditFlg(!editFlg)
      setMessage(message)
      setEditingMessageId(id)
    },
    [editFlg]
  )
  const editCancel = useCallback(() => {
    setEditFlg(false)
    setMenuFlg(false)
    setMessage("")
    setEditingMessageId(undefined)
  }, [])
  const onClickMenu = useCallback(
    (id: string) => {
      setEditingMessageId(id)
      setMenuFlg(!menuFlg)
    },
    [menuFlg]
  )

  const { fetch: updateMessage, isFetching: isUpdatingMessage } = useFetcher(
    useCallback(async () => {
      if (editingMessageId == null) {
        return
      }
      if (message == null) {
        await CustomAlert.alert("エラー", "メッセージが入力されていません")
        return
      }
      await execUpdateMatchingMessage({
        message,
        threadMessageId: editingMessageId,
        callback: () => {
          setMessage("")
          setEditingMessageId(undefined)
          setEditFlg(false)
        },
      })
    }, [message, editingMessageId, execUpdateMatchingMessage])
  )

  return {
    setMessage,
    value,
    offerTitle,
    otherTeamName,
    otherTeamId,
    onClickMatchingProfile,
    resourceUrl,
    refreshResourceUrl,
    refreshListMatchingMessage,
    isBlocking,
    teamName,
    matchingOtherProfile,
    onChangeMessage,
    postMessage,
    isPostingMessage,
    copyMessage,
    removeMessage,
    editMessage,
    editCancel,
    onClickMenu,
    updateMessage,
    isUpdatingMessage,
    menuFlg,
    editingMessageId,
    editFlg,
    message,
    isLoading,
    setNextTokens,
    matchingOfferDetail,
    isBlocked,
  }
}

export type ContactReplyThreadLinkParams = {
  replyThreadId: string
}

export type ContactReplyThreadParams = {
  replyThreadId: string
  isNew: boolean
  contactTitle?: string
  senderName?: string
}

type Props = RootStackScreenProps<typeof Screens.MatchingThread>

export const MatchingReplyThread = memo<Props>(
  ({
    route: {
      params: { applicationId, myOrganizationId, to, isMyMatching, isFavorite, isApplications, matchingTeamId },
    },
    navigation,
  }) => {
    const offerId = useMemo(() => applicationId?.substring(applicationId.indexOf(".") + 1), [applicationId])

    const {
      offerTitle,
      otherTeamName,
      otherTeamId,
      value,
      message,
      isLoading,
      setMessage,
      matchingOtherProfile,
      refreshListMatchingMessage,
      postMessage,
      editMessage,
      editCancel,
      copyMessage,
      removeMessage,
      updateMessage,
      isUpdatingMessage,
      editingMessageId,
      isPostingMessage,
      resourceUrl,
      refreshResourceUrl,
      setNextTokens,
      isBlocking,
    } = useMatchingThread(applicationId, offerId, myOrganizationId, matchingTeamId)

    const { value: notifications } = useAsyncSelector(notificationsSelector)
    const tokens = useRecoilValue(tokensState)
    const refreshNotifications = useIncrementRecoilState(notificationsRequestIdState)

    const [toShowTextInput, setToShowTextInput] = useState(false)
    const [showableMessageMenuId, setShowableMessageMenuId] = useState<string>()

    const [pressableHeight, setPressableHeight] = useState(0)
    const [titleHeight, setTitleHeight] = useState(0)
    const [buttonHeight, setButtonHeight] = useState(0)
    const scrollViewHeight = useMemo(
      () => pressableHeight - titleHeight - buttonHeight - 16,
      [pressableHeight, titleHeight, buttonHeight]
    )

    const textInputScrollViewRef = useRef<ScrollView>(null)
    const textInputRef = useRef<TextInput>(null)

    useEffect(() => {
      if (notifications && applicationId && tokens) {
        const noti = notifications
          .filter((el) => !el.isRead)
          .find((e) => {
            const splitURL = e.url.split("applicationId=")
            const secondSplit = splitURL[1]?.split("&")
            return secondSplit && secondSplit[0] === applicationId
          })
        if (noti) {
          markNotificationAsRead({ accessToken: tokens.accessToken, id: noti.id })
          refreshNotifications()
        }
      }
    }, [notifications, applicationId, tokens, refreshNotifications])

    useEffect(() => {
      navigation.setOptions({
        headerTitle: offerTitle,
      })
    }, [navigation, offerTitle])

    const goToProfile = useCallback(async () => {
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: RootTabs.MatchingDrawer,
          params: {
            screen: MatchingDrawers.MyMatchingProfile,
            params: {
              id: otherTeamId,
              applicationId,
              myOrganizationId,
              offerId: offerId,
              to,
              isMyMatching,
              isFavorite,
              isApplications,
              matchingTeamId,
            },
          },
        },
      })
    }, [
      navigation,
      applicationId,
      myOrganizationId,
      otherTeamId,
      offerId,
      to,
      isMyMatching,
      isFavorite,
      isApplications,
      matchingTeamId,
    ])
    const isLargeScreen = useCheckPCScreen()

    const handlePressOutside = () => {
      if (Platform.OS !== "web") return
      Keyboard.dismiss()
    }

    const renderItem = useCallback(
      ({ item }: ListRenderItemInfo<ContactReplyThreadItem>) =>
        item.contactReplyThreadType === ContactReplyThreadType.ReplyFromMe ? (
          <View style={[styles.replyFromMeContainer]}>
            <View style={styles.replyFromMeMessageWrapper}>
              <View style={{ display: "flex", flexDirection: "row", alignItems: isLargeScreen ? "flex-end" : "center" }}>
                <View>
                  {showableMessageMenuId !== item.replyThreadMessageId ? undefined : (
                    <View style={[isLargeScreen && styles.replyMessageMenuContainer, { paddingHorizontal: 5 }]}>
                      {!isLargeScreen && (
                        <Button
                          style={[styles.replyMessageMenuButtonContainer, !isLargeScreen && { marginBottom: 5 }]}
                          onPress={() => {
                            copyMessage(item.message), setShowableMessageMenuId(undefined)
                          }}
                        >
                          <Text style={styles.replyMessageMenuButtonText}>コピー</Text>
                        </Button>
                      )}
                      <Button
                        style={[styles.replyMessageMenuButtonContainer, !isLargeScreen && { marginBottom: 5 }]}
                        onPress={() => {
                          editMessage(item.replyThreadMessageId, item.message), setShowableMessageMenuId(undefined)
                        }}
                      >
                        <Text style={styles.replyMessageMenuButtonText}>編集</Text>
                      </Button>
                      <Button
                        style={styles.replyMessageMenuButtonContainer}
                        onPress={() => {
                          removeMessage(item.replyThreadMessageId), setShowableMessageMenuId(undefined)
                        }}
                      >
                        <Text style={styles.replyMessageMenuButtonText}>送信の取り消し</Text>
                      </Button>
                    </View>
                  )}
                  <View style={[styles.seeMoreAction]}>
                    {!isLargeScreen ? undefined : (
                      <Button
                        style={styles.buttonSeeMore}
                        onPress={() =>
                          setShowableMessageMenuId(
                            showableMessageMenuId !== item.replyThreadMessageId ? item.replyThreadMessageId : undefined
                          )
                        }
                      >
                        <SeeMoreIcon />
                      </Button>
                    )}
                  </View>
                </View>
                <View>
                  <Button
                    style={styles.replyFromMeMessageContainer}
                    onPress={() => setShowableMessageMenuId(undefined)}
                    onLongPress={() => setShowableMessageMenuId(item.replyThreadMessageId)}
                  >
                    <Text style={styles.replyFromMeMessageText}>{item.message}</Text>
                  </Button>
                  <View style={[styles.replyFromMeDateContainer]}>
                    <Text style={styles.replyFromMeDate}>{dayjs(item.date).format("HH:mm")}</Text>
                    {item.isEdited ? (
                      <>
                        <View style={styles.replyFromMeEditedIcon}>
                          <DraftIcon color={Colors.cement} size={8} />
                        </View>
                        <Text style={styles.replyFromMeDate}>編集済み</Text>
                      </>
                    ) : null}
                    {item.isRead ? (
                      <>
                        <Text style={styles.replyFromMeDate}> 既読</Text>
                      </>
                    ) : null}
                  </View>
                </View>
              </View>
            </View>
          </View>
        ) : item.contactReplyThreadType === ContactReplyThreadType.ReplyFromOthers ? (
          <View style={styles.replyFromOthersContainer}>
            <View style={styles.replyFromOthersInner}>
              <NoPersonIcon size={30} />
              <View style={styles.replyFromOthersMessageTriangle} />
              <View style={styles.replyFromOthersMessageWrapper}>
                <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <ReplyFromOthersMessage
                    replyThreadMessageId={item.replyThreadMessageId}
                    message={item.message}
                    isRead={item.isRead}
                    organizationId={myOrganizationId ?? ""}
                    itemType={item.contactReplyThreadType}
                    setShowableMessageMenuId={setShowableMessageMenuId}
                  />
                  <View>
                    {showableMessageMenuId !== item.replyThreadMessageId ? undefined : (
                      <View style={[isLargeScreen && styles.replyMessageMenuContainer, { paddingHorizontal: 5 }]}>
                        {!isLargeScreen && (
                          <Button
                            style={styles.replyMessageMenuButtonContainer}
                            onPress={() => {
                              copyMessage(item.message), setShowableMessageMenuId(undefined)
                            }}
                          >
                            <Text style={styles.replyMessageMenuButtonText}>コピー</Text>
                          </Button>
                        )}
                      </View>
                    )}
                  </View>
                </View>
                <View style={styles.replyFromOthersDateContainer}>
                  <Text style={styles.replyFromOthersDate}>{dayjs(item.date).format("HH:mm")}</Text>
                  {item.isEdited ? (
                    <>
                      <View style={styles.replyFromOthersEditedIcon}>
                        <DraftIcon color={Colors.cement} size={8} />
                      </View>
                      <Text style={styles.replyFromOthersDate}>編集済み</Text>
                    </>
                  ) : null}
                </View>
              </View>
            </View>
          </View>
        ) : item.contactReplyThreadType === ContactReplyThreadType.RemovedReply ? (
          <View style={styles.replyRemovedReplyContainer}>
            <MarkAsReadMessageRemove
              replyThreadMessageId={item.replyThreadMessageId}
              isRead={true}
              organizationId={myOrganizationId ?? ""}
              itemType={item.contactReplyThreadType}
            />
            <Text style={styles.replyRemovedReplyText}>送信者が送信を取り消しました。</Text>
          </View>
        ) : item.contactReplyThreadType === ContactReplyThreadType.DateLine ? (
          <View style={styles.replyDateLineContainer}>
            <View style={styles.replyDateLineSeparator} />
            <Text style={styles.replyDateLineLabel}>{item.label}</Text>
            <View style={styles.replyDateLineSeparator} />
          </View>
        ) : item.contactReplyThreadType === ContactReplyThreadType.MemberDeleted ? (
          <View style={styles.replyRemovedReplyContainer}>
            <Text style={styles.replyRemovedReplyText}>メンバーが脱退したため、返信できません。</Text>
          </View>
        ) : item.contactReplyThreadType === ContactReplyThreadType.NoMessage ? (
          <View style={styles.noMessageContainer}>
            <Text style={styles.noMessageText}>これは送信者に対する返信で、他の受信者にはこのメッセージは表示されません。</Text>
          </View>
        ) : null,
      [showableMessageMenuId, copyMessage, editMessage, removeMessage, myOrganizationId, isLargeScreen]
    )

    const backToAfterAction = useCallback(() => {
      if (to == ListActionBackInThread.detail) {
        if (offerId) {
          navigation.navigate(Screens.Root, {
            screen: RootTabs.OrganizationManagerTab,
            params: {
              screen: RootTabs.MatchingDrawer,
              params: {
                screen: MatchingDrawers.MatchingOfferDetail,
                params: {
                  id: offerId,
                  isMyMatching: isMyMatching,
                  isFavorite: isFavorite,
                  isApplications: isApplications,
                },
              },
            },
          })
        }
      } else if (to == ListActionBackInThread.favorite) {
        navigation.navigate(Screens.Root, {
          screen: RootTabs.OrganizationManagerTab,
          params: {
            screen: RootTabs.MatchingDrawer,
            params: {
              screen: MatchingDrawers.FavoriteMatchingOffer,
            },
          },
        })
      } else if (to == ListActionBackInThread.myMatching) {
        navigation.navigate(Screens.Root, {
          screen: RootTabs.OrganizationManagerTab,
          params: {
            screen: RootTabs.MatchingDrawer,
            params: {
              screen: MatchingDrawers.MyMatchingOffer,
            },
          },
        })
      } else if (to == ListActionBackInThread.otherMatching) {
        navigation.navigate(Screens.Root, {
          screen: RootTabs.OrganizationManagerTab,
          params: {
            screen: RootTabs.MatchingDrawer,
            params: {
              screen: MatchingDrawers.MyMatchingOffer,
            },
          },
        })
      } else {
        navigation.navigate(Screens.Root, {
          screen: RootTabs.OrganizationManagerTab,
          params: {
            screen: RootTabs.MatchingDrawer,
            params: {
              screen: MatchingDrawers.Applications,
            },
          },
        })
      }
    }, [to, offerId, navigation, isMyMatching, isFavorite, isApplications])

    useEffect(() => {
      navigation.setOptions({
        headerLeft: () => <ButtonBackIcon onPress={backToAfterAction} />,
      })
    }, [navigation, backToAfterAction])

    useEffect(() => {
      return () => refreshListMatchingMessage()
    }, [refreshListMatchingMessage])

    const getMoreContacts = useCallback(() => {
      setNextTokens((prev) => (value?.nextToken != null ? [...prev, value.nextToken] : prev))
    }, [value, setNextTokens])

    const { bottom } = useSafeAreaInsets()
    const { height } = useWindowDimensions()

    if (isLoading && value == null) {
      return (
        <View style={styles.loadingIndicator}>
          <LoadingIndicator />
        </View>
      )
    }

    return (
      <CustomKeyboardAvoidingView>
        <Pressable
          style={[
            styles.container,
            Platform.OS !== "web" ? { maxHeight: height - (headerHeight + 16) } : { height: height - (headerHeight + 16) },
          ]}
          disabled={showableMessageMenuId == null && Platform.OS !== "web"}
          onPress={() => setShowableMessageMenuId(undefined)}
          onLayout={(e) => setPressableHeight(e.nativeEvent.layout.height)}
        >
          <Button
            onPress={() => goToProfile()}
            style={styles.headerContainer}
            onLayout={(e) => setTitleHeight(e.nativeEvent.layout.height)}
          >
            {resourceUrl ? (
              <Image
                style={styles.replyFromOthersSenderImage}
                source={resourceUrl ? { uri: resourceUrl } : matchingIcon}
                onError={refreshResourceUrl}
              />
            ) : (
              <NoPersonIcon size={30} />
            )}
            <Text style={styles.headerText}>{otherTeamName}</Text>
          </Button>

          {matchingOtherProfile && (
            <>
              {isBlocking ? (
                <View style={styles.replyItemsContainerBlock}>
                  <Text style={styles.replyTextBlock}>ブロック中の団体とのメッセージは表示されません。</Text>
                </View>
              ) : (
                <>
                  <FlatList
                    style={[styles.replyItemsContainer, Platform.OS === "web" && { height: 500 }]}
                    contentContainerStyle={styles.replyItemContainer}
                    data={value?.reversedReplyItems}
                    renderItem={renderItem}
                    inverted={value?.hasMessage}
                    onEndReached={!isLoading ? getMoreContacts : undefined}
                    keyExtractor={(item) => item.replyThreadMessageId}
                  />
                  <View style={styles.footerContainer} pointerEvents={isBlocking ? "none" : "auto"}>
                    <View
                      style={[
                        styles.textInputContainer,
                        {
                          paddingBottom: Platform.OS === "ios" && !toShowTextInput ? bottom : 6,
                        },
                      ]}
                    >
                      <ScrollView style={{ maxHeight: toShowTextInput ? scrollViewHeight : 40 }} ref={textInputScrollViewRef}>
                        <MultilineTextInput
                          style={styles.textInput}
                          ref={textInputRef}
                          minHeight={40}
                          value={message}
                          onChangeText={setMessage}
                          maxLength={1000}
                          placeholder="返信を入力する"
                          placeholderTextColor={Colors.cement}
                          onFocus={() => setToShowTextInput(true)}
                          onBlur={() => {
                            setToShowTextInput(false)
                            handlePressOutside()
                          }}
                          // onLayout={() => textInputScrollViewRef.current?.scrollToEnd()}
                        />
                      </ScrollView>
                      <View
                        style={styles.textInputButtonContainer}
                        onLayout={(e) => setButtonHeight(e.nativeEvent.layout.height)}
                      >
                        {editingMessageId == null ? (
                          <Button onPress={postMessage} disabled={isEmptyString(message) || isPostingMessage}>
                            <SendMessageIcon />
                          </Button>
                        ) : (
                          <>
                            <Button style={styles.editCancelButton} onPress={editCancel}>
                              <CancelWithCircleIcon />
                            </Button>
                            <Button onPress={updateMessage} disabled={isUpdatingMessage || !message.length}>
                              <CheckWithCircleIcon />
                            </Button>
                          </>
                        )}
                      </View>
                    </View>
                    {!toShowTextInput ? (
                      <View style={styles.dummyTextInputContainer} pointerEvents="none">
                        {message == null || message === "" ? (
                          <Text style={styles.dummyTextPlaceholder}>返信を入力する</Text>
                        ) : (
                          <Text style={styles.dummyTextInput} numberOfLines={1}>
                            {message}
                          </Text>
                        )}
                      </View>
                    ) : null}
                  </View>
                </>
              )}
            </>
          )}
        </Pressable>
      </CustomKeyboardAvoidingView>
    )
  }
)

type ReplyFromOthersMessageProps = {
  replyThreadMessageId: string
  itemType: string
  message: string
  isRead: boolean
  organizationId: string
  setShowableMessageMenuId: (id: string | undefined) => void
}
type ReplyFromOthersMessageRemoveProps = {
  replyThreadMessageId: string
  itemType: string
  isRead: boolean
  organizationId: string
}
const ReplyFromOthersMessage = memo<ReplyFromOthersMessageProps>(
  ({ replyThreadMessageId, message, isRead, organizationId, itemType, setShowableMessageMenuId }) => {
    useMarkThreadMessageAsRead({ replyThreadMessageId, isRead, organizationId, isMatching: true, itemType })

    return (
      <Button
        style={styles.replyFromOthersMessageContainer}
        onPress={() => setShowableMessageMenuId(undefined)}
        onLongPress={() => setShowableMessageMenuId(replyThreadMessageId)}
      >
        <Text style={styles.replyFromOthersMessageText}>{message}</Text>
      </Button>
    )
  }
)
const MarkAsReadMessageRemove = memo<ReplyFromOthersMessageRemoveProps>(
  ({ replyThreadMessageId, isRead, organizationId, itemType }) => {
    useMarkThreadMessageAsRead({ replyThreadMessageId, isRead, organizationId, isMatching: true, itemType })
    return <></>
  }
)
export const MatchingThreadOptions: ScreenOptions = {
  ...commonHeaderOptionsOnNestedScreen,
  headerTitle: () => <LoadingIndicator />,
  title: "メッセージ",
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: "hidden",
  },

  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  headerContainer: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: Colors.white3,
    paddingVertical: 11,
    paddingHorizontal: 22,
    flexDirection: "row",
  },
  headerText: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 22,
    color: Colors.black,
    paddingVertical: 5,
    paddingLeft: 2,
  },

  replyItemsContainer: {
    backgroundColor: Colors.duckEggBlue,
  },
  replyItemsContainerBlock: {
    backgroundColor: Colors.duckEggBlue,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    paddingVertical: 20,
    height: "100%",
  },
  replyTextBlock: {
    color: Colors.cement,
  },
  replyItemContainer: {
    paddingTop: 16,
  },

  replyMessageMenuContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  replyMessageMenuButtonContainer: {
    marginHorizontal: 3,
    paddingVertical: 5,
    paddingHorizontal: 7,
    borderWidth: 1,
    borderColor: Colors.silver,
    borderRadius: 6,
    backgroundColor: Colors.white7,
    textAlign: "center",
  },
  replyMessageMenuButtonText: {
    fontSize: 13,
    color: "rgb(51,51,51)",
    textAlign: "center",
  },

  replyFromMeContainer: {
    marginTop: 16,
    alignItems: "flex-end",
    paddingRight: 20,
    position: "relative",
  },
  replyFromMeMessageWrapper: {
    alignItems: "flex-start",
  },
  replyFromMeMessageContainer: {
    borderRadius: 16,
    backgroundColor: Colors.pale2,
    paddingVertical: 15,
    paddingHorizontal: 20,
    maxWidth: 245,
  },
  replyFromMeMessageText: {
    fontSize: 14,
    lineHeight: 24,
    color: Colors.black,
  },
  replyFromMeDateContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 2,
  },
  replyFromMeDate: {
    fontSize: 10,
    color: "rgba(0, 0, 0, 0.3)",
  },
  replyFromMeEditedIcon: {
    marginLeft: 8,
    marginRight: 4,
  },

  replyFromOthersContainer: {
    marginTop: 16,
    alignItems: "flex-start",
    paddingLeft: 20,
  },
  replyFromOthersInner: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  replyFromOthersSenderImage: {
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  replyFromOthersMessageTriangle: {
    marginTop: 23,
    marginLeft: 1,
    borderRightWidth: 10,
    borderRightColor: Colors.white3,
    borderTopWidth: 5,
    borderTopColor: "transparent",
    borderBottomWidth: 5,
    borderBottomColor: "transparent",
  },
  replyFromOthersMessageWrapper: {
    alignItems: "flex-start",
  },
  replyFromOthersMessageContainer: {
    borderRadius: 16,
    backgroundColor: Colors.white3,
    paddingVertical: 15,
    paddingHorizontal: 20,
    maxWidth: 245,
  },
  replyFromOthersMessageText: {
    fontSize: 14,
    lineHeight: 24,
    color: Colors.black,
  },
  replyFromOthersDateContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 2,
  },
  replyFromOthersDate: {
    fontSize: 10,
    color: "rgba(0, 0, 0, 0.3)",
  },
  replyFromOthersEditedIcon: {
    marginLeft: 8,
    marginRight: 4,
  },
  replyRemovedReplyContainer: {
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.05)",
    paddingVertical: 3,
    paddingHorizontal: 24,
    alignSelf: "center",
    borderRadius: 11,
  },
  replyRemovedReplyText: {
    fontSize: 11,
    color: "rgba(0, 0, 0, 0.29)",
  },
  noMessageContainer: {
    marginTop: 12,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 3,
    paddingHorizontal: 24,
    alignSelf: "center",
  },
  noMessageText: {
    fontSize: 15,
    color: "rgba(0, 0, 0, 0.4)",
  },
  replyDateLineContainer: {
    marginTop: 16,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  replyDateLineSeparator: {
    backgroundColor: "rgba(0,0,0,0.08)",
    height: 1,
    flex: 1,
  },
  replyDateLineLabel: {
    fontSize: 13,
    color: "rgba(0,0,0,0.3)",
    paddingHorizontal: 18,
  },

  footerContainer: {
    position: "relative",
  },
  textInputContainer: {
    backgroundColor: Colors.white3,
    borderTopWidth: 1,
    borderTopColor: Colors.orange,
    maxHeight: 350,
  },
  textInput: {
    borderWidth: 0,
    outlineStyle: "none",
    paddingHorizontal: 16,
    // TextInputに対してpaddingVerticalが効かないバグがある
    paddingTop: 12,
    paddingBottom: 12,
    height: "100%",
    lineHeight: 22,
  },
  textInputButtonContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: "rgba(0,0,0,0.2)",
    paddingVertical: 12,
    paddingHorizontal: 20,
  },
  dummyTextInputContainer: {
    position: "absolute",
    justifyContent: "center",
    alignItems: "flex-start",
    backgroundColor: Colors.white3,
    borderTopWidth: 1,
    borderTopColor: Colors.orange,
    paddingRight: 20,
    paddingLeft: 20,
    width: "100%",
    height: 41,
  },
  dummyTextInput: {
    fontSize: 16,
    height: 22,
    lineHeight: 22,
    color: Colors.greyshBrown,
  },
  dummyTextPlaceholder: {
    fontSize: 16,
    height: 22,
    lineHeight: 22,
    color: Colors.cement,
  },
  editCancelButton: {
    marginRight: 12,
  },
  seeMoreAction: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  buttonSeeMore: {
    marginTop: 5,
    paddingBottom: 14,
    marginRight: 5,
  },
})
