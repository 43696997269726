import { atom } from "recoil"

export const currentMemberPageState = atom<number>({
  key: "atoms/organization/currentMemberPageState",
  default: 1,
})
export const currentGroupMemberPageState = atom<number>({
  key: "atoms/organization/currentGroupMemberPageState",
  default: 1,
})
export const currentEditGroupMemberPageState = atom<number>({
  key: "atoms/organization/currentEditGroupMemberPageState",
  default: 1,
})
