import { useRequestData } from "src/recoil/hooks/useRequestData"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { contactReplyThreadRequestIdState } from "src/recoil/atoms/contact/contactReplyThreadRequestIdState"
import { useInterval } from "src/hooks/useInterval"
import { useFetcher } from "src/hooks/useFetcher"
import { useCallback, useEffect, useMemo, useState } from "react"
import { CustomAlert } from "src/utils/CustomAlert"
import { deleteContactReplyThreadMessage } from "src/apis/contact/deleteContactReplyThreadMessage"
import { postContactReplyThreadMessage } from "src/apis/contact/postContactReplyThreadMessage"
import { updateContactReplyThreadMessage } from "src/apis/contact/updateContactReplyThreadMessage"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { contactReplyThreadsRequestIdState } from "src/recoil/atoms/contact/contactReplyThreadsRequestIdState"
import { unreadThreadCountRequestIdState } from "src/recoil/atoms/contact/unreadThreadCountRequestIdState"
import { hasUnreadThreadMessageRequestIdState } from "src/recoil/atoms/contact/hasUnreadThreadMessageRequestIdState"
import { contactReplyThreadConcatSelectorFamily } from "src/recoil/selectors/contact/contactReplyThreadConcatSelectorFamily"
import { contactReplyThreadConcatRequestIdState } from "src/recoil/atoms/contact/contactReplyThreadConcatRequestIdState"
import { TIME_INTERVAL_REPLY_THREAD } from "src/constants/Screen"
import { Screens } from "src/constants/Screens"
import { errorMessageNotInThread } from "src/utils/const"
export const useContactReplyThreadData = (replyThreadId: string, isNew: boolean, navigation?: any) => {
  const [newThread, setNewThread] = useState(isNew)
  const [nextTokens, setNextTokens] = useState<string[]>([])
  const { value, isLoading, error } = useAsyncSelector(
    contactReplyThreadConcatSelectorFamily({ replyThreadId: newThread ? "" : replyThreadId, nextTokens })
  )

  const [networkError, setNetworkError] = useState(false)
  const { value: selectedMyOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedMyOrganization?.id, [selectedMyOrganization])
  const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(organizationId))
  const memoMemberId = useMemo(() => myProfile?.memberId, [myProfile])

  const _refreshContactReplyThreads = useRefresher(contactReplyThreadsRequestIdState)
  const _refreshContactReplyThreadsConcat = useRefresher(contactReplyThreadConcatRequestIdState)
  const _refreshUnreadThreadCount = useRefresher(unreadThreadCountRequestIdState)
  const _refreshHasUnreadThreadMessage = useRefresher(hasUnreadThreadMessageRequestIdState)
  const refreshContactReplyThreads = useCallback(() => {
    _refreshContactReplyThreads()
    _refreshContactReplyThreadsConcat()
    _refreshUnreadThreadCount()
    _refreshHasUnreadThreadMessage()
  }, [
    _refreshContactReplyThreads,
    _refreshContactReplyThreadsConcat,
    _refreshUnreadThreadCount,
    _refreshHasUnreadThreadMessage,
  ])
  const refreshContactReplyThread = useRefresher(contactReplyThreadRequestIdState)
  useInterval(refreshContactReplyThread, TIME_INTERVAL_REPLY_THREAD)
  const requestDataResult = useRequestData()
  useEffect(() => {
    const handleErrors = async () => {
      if (value) {
        setNetworkError(false)
      }
      if (!networkError && error) {
        if (error.includes(errorMessageNotInThread)) {
          const isConfirmed = await CustomAlert.confirm("エラー", error, error)
          if (isConfirmed) {
            await navigation.replace(Screens.Root)
          }
          setNetworkError(true)
        } else {
          CustomAlert.alert("エラー", error)
          setNetworkError(true)
        }
      }
    }
    handleErrors()
  }, [error, networkError, value, navigation])

  const { resourceUrl: partnerImageUrl, refreshResourceUrl: refreshPartnerImageUrl } = useResource(
    value?.partner.id
      ? {
          type: "teamMemberImage",
          id: value.partner.id,
          size: "thumbnail",
        }
      : {
          type: "none",
        }
  )

  const { fetch: execPostContactReplyThreadMessage } = useFetcher(
    useCallback(
      async ({ message, callback }: { message: string; callback: () => void }) => {
        const myMemberId = value?.myMemberId ?? memoMemberId
        if (!requestDataResult.isOk || myMemberId == null) {
          return
        }
        const { accessToken } = requestDataResult.content
        const result = await postContactReplyThreadMessage({ accessToken, replyThreadId, myMemberId, message })
        if (result.isOk) {
          setNewThread(false)
          refreshContactReplyThread()
          callback()
        } else {
          CustomAlert.alert("エラー", result.error.message)
        }
      },
      [requestDataResult, refreshContactReplyThread, replyThreadId, memoMemberId, value?.myMemberId]
    )
  )

  const { fetch: execDeleteContactReplyThreadMessage } = useFetcher(
    useCallback(
      async (replyThreadMessageId: string) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accessToken } = requestDataResult.content
        const isConfirmed = await CustomAlert.confirm("確認", "本当に送信を取り消しますか？\n元には戻せません。")
        if (!isConfirmed) {
          return
        }
        const result = await deleteContactReplyThreadMessage({ accessToken, replyThreadMessageId })
        if (result.isOk) {
          refreshContactReplyThread()
          CustomAlert.alert("完了", "送信を取り消しました。")
        } else {
          CustomAlert.alert("エラー", result.error.message)
        }
      },
      [requestDataResult, refreshContactReplyThread]
    )
  )

  const { fetch: execUpdateContactReplyThreadMessage } = useFetcher(
    useCallback(
      async ({
        replyThreadMessageId,
        message,
        callback,
      }: {
        replyThreadMessageId: string
        message: string
        callback: () => void
      }) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accessToken } = requestDataResult.content
        const result = await updateContactReplyThreadMessage({
          accessToken,
          replyThreadMessageId,
          message,
        })
        if (result.isOk) {
          refreshContactReplyThread()
          callback()
        } else {
          CustomAlert.alert("エラー", result.error.message)
        }
      },
      [requestDataResult, refreshContactReplyThread]
    )
  )

  return {
    value,
    isLoading,
    partnerImageUrl,
    refreshPartnerImageUrl,
    refreshContactReplyThreads,
    execPostContactReplyThreadMessage,
    execDeleteContactReplyThreadMessage,
    execUpdateContactReplyThreadMessage,
    setNextTokens,
    organizationId,
  }
}
