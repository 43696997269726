import { View, Text, StyleSheet, Image, useWindowDimensions } from "react-native"
import { memo, useCallback, useState } from "react"
import { useRecoilValue } from "recoil"

import { ItemLabel } from "src/components/parts/ItemLabel"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { LockIcon } from "src/components/parts/icons/LockIcon"
import { Colors } from "src/constants/Colors"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CreateOrEditGroupStackScreenProps } from "src/types.d"
import { CreateOrEditGroupScreens } from "src/constants/CreateOrEditGroupScreens"
import { selectedMember } from "src/recoil/selectors/organization/teamMemberSelectorFamily"
import { MemberType } from "src/types/organization/OrganizationMember"
import { MemberTypeLabel } from "src/constants/MemberTypeLabel"
import { FlagIcon } from "src/components/parts/icons/FlagIcon"
import { EmailIcon } from "src/components/parts/icons/EmailIcon"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { Screens } from "src/constants/Screens"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { useFetcher } from "src/hooks/useFetcher"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { transferApplicantAuthorityApi } from "src/apis/organization/updateMemberDetail"
import { teamPermissionRequestIdState } from "src/recoil/atoms/organization/teamPermissionRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"
import { getOrderDetailIdRequestState } from "src/recoil/selectors/paidTeam/getOrderDetailIdRequestState"

export type ConfirmationTransferApplicantAuthorityType = {
  organizationId: string
  accountId?: string
}

type Props = CreateOrEditGroupStackScreenProps<typeof CreateOrEditGroupScreens.ConfirmationTransferApplicantAuthority>

function ConfirmationTransferApplicantAuthority({
  navigation,
  route: {
    params: { organizationId, accountId },
  },
}: Props) {
  const isLargeScreen = useCheckPCScreen()
  const widthScreen = useWindowDimensions().width
  const applicant = useRecoilValue(selectedMember)
  const [imgFail, setImgFail] = useState(false)

  const requestDataResult = useRequestData()
  const accessToken = requestDataResult.isOk ? requestDataResult?.content?.accessToken : ""
  const refreshPermission = useRefresher(teamPermissionRequestIdState)
  const refreshOrder = useRefresher(getOrderDetailIdRequestState)

  const { fetch: transferApplicant, isFetching: isLoadingApi } = useFetcher(
    useCallback(async () => {
      if (!accessToken || !applicant || !organizationId) return
      try {
        const result = await transferApplicantAuthorityApi({
          accessToken,
          teamId: organizationId,
          newApplicantId: applicant.id,
        })
        if (result.id) {
          refreshPermission()
          if (accountId) {
            refreshOrder()
            navigation.navigate(Screens.Root, {
              screen: RootTabs.OrganizationManagerTab,
              params: {
                screen: OrganizationManagerScreens.OrderDetail,
                params: {
                  teamId: organizationId,
                  accountId: accountId,
                },
              },
            })
          } else {
            navigation.navigate(Screens.Root, {
              screen: RootTabs.OrganizationManagerTab,
              params: {
                screen: OrganizationManagerScreens.OrganizationDetail,
                params: { organizationId: organizationId },
              },
            })
          }
        } else {
          alert(result.errors[0].message)
        }
      } catch (error) {
        console.error("Failed transfer:", error)
      }
    }, [accessToken, applicant, organizationId, refreshPermission, accountId, refreshOrder, navigation])
  )

  return (
    <CustomKeyboardAvoidingView style={styles.container}>
      <View style={styles.header}>
        <Text style={styles.headerText}>下記のメンバーに申込者権限を譲渡します。</Text>
      </View>
      <View style={[styles.body, isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : {}]}>
        <ItemLabel label="選択したメンバー" />
        <View style={{ flexDirection: "row", marginTop: 10, marginLeft: 15 }}>
          <Image
            source={applicant.imageUrl && !imgFail ? { uri: applicant.imageUrl } : defaultThumbnail}
            style={styles.image}
            onError={() => setImgFail(true)}
          />
          <View style={styles.nameContainer}>
            <View style={styles.nameInner}>
              <Text numberOfLines={10} style={styles.nameText}>
                {applicant.nickname}
              </Text>
            </View>
            <View style={styles.nameInner}>
              {applicant.memberType === MemberType.Representative ? (
                <View style={styles.roleContainer}>
                  <LockIcon />
                  <Text style={styles.roleText}>{MemberTypeLabel.Representative}</Text>
                </View>
              ) : null}
              {applicant.isLeader ? (
                <View style={styles.roleContainer}>
                  <FlagIcon />
                  <Text style={styles.roleText}>{"グループリーダー"}</Text>
                </View>
              ) : null}
              {applicant.memberType === MemberType.GeneralWithContactAuthorization ? (
                <View style={styles.roleContainer}>
                  <EmailIcon />
                  <Text style={styles.roleText}>{MemberTypeLabel.GeneralWithContactAuthorization}</Text>
                </View>
              ) : null}
            </View>
          </View>
        </View>
      </View>
      <View
        style={[
          styles.footer,
          isLargeScreen ? { paddingBottom: 12, flexDirection: "row", justifyContent: "center" } : { paddingBottom: 32 },
        ]}
      >
        {isLargeScreen && (
          <TextButton
            style={{ width: 300, marginRight: 15 }}
            buttonType={ButtonType.Secondary}
            title={"戻る"}
            onPress={() => navigation.goBack()}
          />
        )}
        <TextButton
          style={isLargeScreen ? { width: 300 } : { marginBottom: 15 }}
          buttonType={ButtonType.Primary}
          title={"申込者権限を譲渡する"}
          onPress={transferApplicant}
          isLoading={isLoadingApi}
        />
        {!isLargeScreen && <TextButton buttonType={ButtonType.Secondary} title={"戻る"} onPress={() => navigation.goBack()} />}
      </View>
    </CustomKeyboardAvoidingView>
  )
}

export default memo(ConfirmationTransferApplicantAuthority)

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  header: {
    padding: 16,
    borderBottomColor: Colors.white2,
    borderBottomWidth: 1,
    flexDirection: "row",
    justifyContent: "center",
  },
  headerText: {
    color: "#888888",
    fontSize: 14,
    fontWeight: "600",
    lineHeight: 19.6,
  },
  body: {
    padding: 24,
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  nameContainer: {
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: 15,
    width: "100%",
  },
  nameInner: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  nameText: {
    fontSize: 14,
    fontWeight: "bold",
  },
  roleContainer: {
    marginTop: 8,
    marginRight: 12,
    flexDirection: "row",
    alignItems: "center",
  },
  roleText: {
    fontSize: 11,
    color: Colors.warmGrey,
    marginLeft: 4,
  },
  footer: {
    marginTop: "auto",
    width: "100%",
    paddingHorizontal: 24,
    paddingVertical: 12,
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
})
