import { useCallback, useState } from "react"
import { useRecoilValue } from "recoil"
import { updateEmail as postUpdadteEmail } from "src/apis/auth/updateEmail"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { CustomAlert } from "src/utils/CustomAlert"

export const useEditEmail = (newEmail?: string) => {
  const [isSending, setIsSending] = useState(false)
  const tokens = useRecoilValue(tokensState)
  const updateEmail = useCallback(
    async (onSuccess?: (email: string) => void) => {
      if (tokens == null || isSending || newEmail == null) {
        return
      }

      try {
        setIsSending(true)
        const result = await postUpdadteEmail({ accessToken: tokens.accessToken, email: newEmail })
        if (!result.isOk) {
          await CustomAlert.alert("エラー", result.error.message)
          return
        }
        onSuccess?.(newEmail)
      } catch (error) {
        await CustomAlert.alert("完了", "予期せぬエラーが発生しました")
      } finally {
        setIsSending(false)
      }
    },
    [isSending, newEmail, tokens]
  )

  return {
    isSending,
    updateEmail,
  }
}
