export type AuthCodeError = {
  errors: {
    errorInfo: {
      code: number
      userMessage: string
      detail?: {
        data?: {
          session: string
        }
      }
    }
  }[]
}

export const assertAuthCodeError = (data: unknown): data is AuthCodeError => {
  if (typeof data !== "object" || data == null) {
    return false
  }

  return Object.keys(data).includes("errors")
}
