import "dayjs/locale/ja"
import { memo, useCallback } from "react"
import { FlatList, ListRenderItemInfo, StyleSheet, Text, View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { ChevronRightIcon } from "src/components/parts/icons/ChevronRightIcon"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { InformationItem } from "src/queries/settings/getInformations"
import { useInformationData } from "src/recoil/hooks/screens/useInformationData"
import { RootStackScreenProps } from "src/types.d"
import { BadgeIcon } from "src/components/parts/icons/BadgeIcon"
import { MainLayout } from "src/layouts/MainLayout"
import { HeaderCommon } from "src/components/parts/HeaderCommon"
import { RootTabs } from "src/constants/RootTabs"

export type InformationParams = undefined

type Props = RootStackScreenProps<typeof Screens.Information>

export const Information = memo<Props>(({ navigation }) => {
  const { insets, informations, isLoading, refreshInformations } = useInformationData()
  const renderItem = useCallback(
    (info: ListRenderItemInfo<InformationItem>) => {
      return (
        <View style={styles.listItem}>
          <Button
            style={styles.button}
            onPress={() => {
              refreshInformations()
              navigation.navigate(Screens.InformationDetail, {
                infoId: info.item.id,
                updatedAt: info.item.updatedAt,
                isRead: info.item.readFlg,
              })
            }}
          >
            <View>
              <View style={styles.metadata}>
                {!info.item.readFlg && !info.item.isRead ? <BadgeIcon size={"small"} style={styles.readIndicator} /> : null}
                <Text style={styles.date}>{info.item.updatedAt}</Text>
              </View>
              <Text style={styles.title}>{info.item.title}</Text>
            </View>
            <View style={styles.right}>
              <ChevronRightIcon />
            </View>
          </Button>
        </View>
      )
    },
    [navigation, refreshInformations]
  )
  if (informations == null) {
    return (
      <View style={styles.loadingContainer}>
        <LoadingIndicator />
      </View>
    )
  }

  if (informations.length === 0) {
    return (
      <MainLayout>
        <View style={styles.noInformationContainer}>
          <Text style={styles.noInformation}>現在、お知らせはありません。</Text>
        </View>
      </MainLayout>
    )
  }

  return (
    <>
      <MainLayout>
        <HeaderCommon title="サービスからのお知らせ" back={() => navigation.navigate(RootTabs.SettingsTab as any)} />
        <FlatList
          data={informations}
          renderItem={renderItem}
          keyExtractor={({ id }) => id}
          refreshControl={<CustomRefreshControl refreshing={isLoading} onRefresh={refreshInformations} />}
          style={styles.container}
          contentContainerStyle={{ paddingBottom: insets.bottom }}
        />
      </MainLayout>
    </>
  )
})

export const options: ScreenOptions = {
  ...commonHeaderOptions,
  title: "サービスからのお知らせ",
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  listItem: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
  },
  button: {
    paddingVertical: 20,
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  metadata: {
    flexDirection: "row",
    alignItems: "center",
  },
  readIndicator: {
    marginRight: 10,
  },
  date: {
    color: Colors.cement,
    fontSize: 12,
  },
  title: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    paddingTop: 6,
    maxWidth: 320,
  },
  right: {},
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  noInformationContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: Colors.beige,
  },
  noInformation: {
    flex: 1,
    textAlign: "center",
    fontWeight: "bold",
    paddingHorizontal: 16,
    paddingVertical: 32,
    color: Colors.black,
  },
  threeColumn: {
    maxWidth: 1020,
    paddingLeft: 30,
    paddingRight: 30,
  },
  fontSizeHeader: {
    fontSize: 20,
    fontWeight: "bold",
  },
})
