import { deleteAsync } from "./secureStorage/deleteAsync"
import { getAsync } from "./secureStorage/getAsync"
import { setAsync } from "./secureStorage/setAsync"

export const getQuery = async (query: string) => {
  const value = await getAsync(query)
  return value ?? undefined
}

export const setQuery = (query: string, value: string) => {
  return setAsync(query, value)
}

export const deleteQuery = (query: string) => {
  return deleteAsync(query)
}

export const setUrlQuery = async (url: string, query: string) => {
  const regrex = new RegExp("^.*?[\\?|\\&]" + query + "=([\\w-]+)") ?? ""
  const value = url.match(regrex) ?? null
  if (value) {
    await setAsync(query, value[1])
  }
}
