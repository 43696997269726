export type GetAuthStateFromResultParams = {
  AccessToken?: string | null
  ExpiresIn?: string | null
  IdToken?: string | null
  RefreshToken?: string | null
  TokenType?: string | null
}

export const getAuthStateFromResult = (params?: GetAuthStateFromResultParams | null) => {
  if (params == null) {
    return
  }
  const { AccessToken, ExpiresIn, IdToken, RefreshToken, TokenType } = params
  if (AccessToken == null || ExpiresIn == null || IdToken == null || RefreshToken == null || TokenType == null) {
    return
  }
  return {
    accessToken: AccessToken,
    expiresIn: ExpiresIn,
    idToken: IdToken,
    refreshToken: RefreshToken,
    tokenType: TokenType,
  }
}
