import { Result } from "src/utils/Result"
import { deleteMail } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type DeleteContactParams = {
  accessToken: string
  contactId: string
}

type DeleteContactSuccess = unknown

type DeleteContactFailure = {
  message: string
}

export const deleteContact = async (params: DeleteContactParams): Promise<Result<DeleteContactSuccess, DeleteContactFailure>> => {
  const {accessToken, contactId} = params

  return execApi(
    () => deleteMail(accessToken, {
      input: {
        id: contactId
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["deleteContact", "deleteMail"],
      defaultErrorMessage: "連絡の送信取り消しに失敗しました。"
    }
  )
}
