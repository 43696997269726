import { memo, useCallback, useMemo } from "react"
import { RootStackScreenProps } from "src/types.d"
import { FlatList, ListRenderItemInfo, StyleSheet, Text, View, useWindowDimensions } from "react-native"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { Screens } from "src/constants/Screens"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { useEmailInvitationResultData } from "src/recoil/hooks/organization/useEmailInvitationResultData"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { SmallButton } from "src/components/parts/buttons/SmallButton"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { validateEmail } from "src/utils/validateEmail"

export type InvitationEmailResultParams = {
  id: string
  successCount: number
  errorEmailList: string[]
}

type Props = RootStackScreenProps<typeof Screens.InvitationEmailResult>

export const InvitationEmailResult = memo<Props>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const insets = useSafeAreaInsets()
  const widthScreen = useWindowDimensions().width
  const id = useMemo(() => route.params.id, [route.params.id])
  const successCount = useMemo(() => route.params.successCount, [route.params.successCount])
  const errorEmailList = useMemo(() => route.params.errorEmailList, [route.params.errorEmailList])

  const { thumbnailImageUrl, organizationName, isEmailInvitationResultDataLoading } = useEmailInvitationResultData(id)

  const goToInvitationMember = useCallback(() => {
    navigation.navigate(Screens.InvitationMember, { id: id })
  }, [navigation, id])

  const goToInvitationHistory = useCallback(() => {
    navigation.navigate(Screens.SendingHistory, { id: id })
  }, [navigation, id])

  function formatErrorEmails(email: string) {
    if (!validateEmail(email)) {
      return "メールアドレス形式エラー"
    }
    return "メールアドレス重複"
  }

  const ListItem = useCallback(
    (listRenderItemInfo: ListRenderItemInfo<string>) => (
      <View style={styles.borderWrapper}>
        <View style={styles.nameContainer}>
          <View style={styles.nameInner}>
            <Text style={styles.nameText}>{listRenderItemInfo.item}</Text>
          </View>
          <View style={styles.roleContainer}>
            <Text style={styles.roleText}>{formatErrorEmails(listRenderItemInfo.item)}</Text>
          </View>
        </View>
      </View>
    ),
    []
  )

  return (
    <View style={{ height: useWindowDimensions().height - (isLargeScreen ? 65 : 110) }}>
      <CustomScrollView style={[styles.container]}>
        {isEmailInvitationResultDataLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <View style={[styles.head, isLargeScreen ? { borderBottomWidth: 1, borderBottomColor: Colors.lightGrey } : {}]}>
              <CircleThumbnail size={32} source={thumbnailImageUrl ? { uri: thumbnailImageUrl } : defaultThumbnail} />
              <Text style={styles.orgName}>{organizationName}</Text>
            </View>
            <View style={{ justifyContent: "center", alignItems: "center" }}>
              <View style={isLargeScreen ? { width: 600 } : { width: "100%" }}>
                <View style={[styles.contentsContainer, isLargeScreen ? { borderTopWidth: 0 } : {}]}>
                  <ItemLabel label="メンバー登録メール受付完了" />
                  <Text style={styles.description}>
                    {successCount}
                    件のメンバー登録メール送信を受け付けました。各メールアドレス宛に団体メンバー登録用メールが順次送信されます。また、送信には最大で2時間程度かかります。
                  </Text>
                  <View style={{ flexDirection: "row", paddingTop: 5 }}>
                    <Text style={[styles.description, {}]}>※</Text>
                    <Text style={styles.description}>
                      指定受信等でメールが届いていないものに関しては、送信履歴をご確認ください。
                    </Text>
                  </View>
                  <View style={styles.buttonContainer}>
                    <SmallButton onPress={goToInvitationHistory} width={230}>
                      <Text style={styles.buttonLabel}>メール送信履歴を確認する</Text>
                    </SmallButton>
                  </View>
                  {errorEmailList?.length > 0 && (
                    <Text style={[styles.description, { color: "#e11520", paddingTop: 12 }]}>
                      ※以下のアドレスにはメールが送信できませんでした。ご確認の上、再度入力をお願いします。
                    </Text>
                  )}
                </View>

                <View
                  style={[
                    styles.contentsContainer,
                    { borderTopWidth: 0, paddingVertical: 0 },
                    isLargeScreen ? { justifyContent: "center", alignItems: "center" } : {},
                  ]}
                >
                  {errorEmailList?.length > 0 && (
                    <FlatList
                      style={[isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : {}]}
                      data={errorEmailList}
                      renderItem={ListItem}
                      keyExtractor={(item, index) => index.toString()}
                      contentContainerStyle={[isLargeScreen ? { width: 550 } : {}]}
                    />
                  )}
                </View>
              </View>
            </View>
          </>
        )}
      </CustomScrollView>
      <View
        style={[styles.actionContainer, { paddingBottom: insets.bottom + 10 }, isLargeScreen ? { alignItems: "center" } : {}]}
      >
        <TextButton
          style={isLargeScreen ? { width: 300 } : {}}
          title={"招待TOPへ戻る"}
          buttonType={ButtonType.Secondary}
          onPress={goToInvitationMember}
        />
      </View>
    </View>
  )
})

export const options: ScreenOptions = {
  ...commonSingleModalOptions,
  title: "メンバー招待",
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white3,
  },
  head: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 8,
    backgroundColor: Colors.white3,
  },
  orgName: {
    marginLeft: 8,
  },
  contentsContainer: {
    padding: 24,
    borderTopWidth: 1,
    borderTopColor: Colors.lightGrey,
  },
  description: {
    fontSize: 14,
    lineHeight: 21,
    color: Colors.black,
    marginVertical: 8,
    marginLeft: 0,
  },
  contentWrapper: {
    borderRadius: 8,
    backgroundColor: "#efeae3",
  },
  content: {
    fontSize: 16,
    lineHeight: 25,
    color: Colors.black2,
    padding: 12,
  },
  centering: {
    textAlign: "left",
  },
  buttonContainer: {
    marginTop: 16,
    alignItems: "center",
  },
  buttonLabel: {
    marginLeft: 14,
    color: Colors.orange,
  },
  actions: {
    paddingVertical: 16,
    paddingHorizontal: 32,
    paddingBottom: 25,
  },
  secondaryButton: {
    marginTop: 16,
  },
  linkText: {
    color: Colors.black,
    textDecorationLine: "underline",
  },
  actionContainer: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  nameContainer: {
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: 0,
    paddingVertical: 16,
  },
  nameInner: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  nameText: {
    fontSize: 14,
    fontWeight: "bold",
  },
  roleContainer: {
    marginTop: 8,
    marginRight: 12,
    flexDirection: "row",
    alignItems: "center",
  },
  roleText: {
    fontSize: 11,
    color: Colors.warmGrey,
    marginLeft: 4,
  },
  borderWrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
})
