import { memo, useCallback, useEffect } from "react"
import { StyleSheet, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { ContactDetailEditor } from "src/components/parts/contactNetworkTab/ContactDetailEditor"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import {
  CloseModalHeaderLeftButton,
  CloseModalHeaderLeftDisableButton,
} from "src/components/parts/headers/CloseModalHeaderLeftButton"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { CreateNewContactScreens } from "src/constants/CreateNewContactScreens"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { Screens } from "src/constants/Screens"
import { useCreateNewContactData } from "src/recoil/hooks/screens/useCreateNewContactData"
import { CreateNewContactStackScreenProps } from "src/types.d"

export type CreateNewContactScreenParams = undefined

type Props = CreateNewContactStackScreenProps<typeof CreateNewContactScreens.CreateNewContact>

export const CreateNewContactScreen = memo<Props>(({ navigation }) => {
  const goBack = useCallback(() => navigation.goBack(), [navigation])
  const goSubmittedContact = useCallback(
    (label: string, message?: string) => navigation.replace(Screens.SubmittedContact, { label, message }),
    [navigation]
  )

  const {
    mode,
    contactId,
    reservedId,
    organizationId,
    organizationName,
    organizationThumbnailURL,
    templateId,
    selectTemplate,
    contactType,
    onContactTypeChange,
    receivers,
    title,
    setTitle,
    body,
    setBody,
    isAddToOtherAdminsHistory,
    toggleIsAddToOtherAdminsHistory,
    isEnableForComment,
    toggleIsEnableForComment,
    isPublishAnswersToMembers,
    toggleIsPublishAnswersToMembers,
    deadlineDate,
    setDeadlineDate,
    reminderDate,
    setReminderDate,
    reservationDate,
    setReservationDate,
    saveAsDraft,
    isSavingAsDraft,
    sendContact,
    updateContact,
    isSendingContact,
    isUpdatingContact,
    questions,
    setQuestions,
    eventDateCandidates,
    isSaveAsDraftDisabled,
    isCreateNewContactDisabled,
    tempMailId,
    attachmentFileNames,
    attachmentItemProps,
    sendDate,
    setAttachmentItemProps,
    setAttachmentUploaders,
    setAttachmentThumbnailUploaders,
    setEventDateCandidates,
    resetReceiverType,
  } = useCreateNewContactData({ goBack, goSubmittedContact })

  const openSelectReceiversModal = useCallback(() => {
    navigation.navigate(Screens.CreateNewContactStack, { screen: CreateNewContactScreens.SelectMethod })
  }, [navigation])

  const gotoSelectEventDateCandidates = useCallback(() => {
    navigation.navigate(Screens.CreateNewContactStack, { screen: CreateNewContactScreens.SelectEventDateCandidates })
  }, [navigation])

  const insets = useSafeAreaInsets()

  useEffect(() => {
    navigation.setOptions({
      headerTitle: mode === "New" ? "連絡作成" : "連絡編集",
      headerLeft: (mode === "New" ? isSendingContact : isUpdatingContact)
        ? CloseModalHeaderLeftDisableButton
        : CloseModalHeaderLeftButton,
    })
  }, [navigation, mode, isSendingContact, isUpdatingContact])

  if (
    organizationId == null ||
    organizationName == null ||
    contactType == null ||
    receivers == null ||
    title == null ||
    body == null ||
    tempMailId == null
  ) {
    return (
      <View style={[styles.loadingContainer, { paddingBottom: insets.bottom }]}>
        <LoadingIndicator />
      </View>
    )
  }
  const TODAY = new Date()

  return (
    <CustomKeyboardAvoidingView>
      <ContactDetailEditor
        mode={mode}
        contactId={contactId}
        sendDate={sendDate ?? TODAY}
        reservedId={reservedId}
        organizationId={organizationId}
        organizationName={organizationName}
        organizationThumbnailURL={organizationThumbnailURL}
        templateId={templateId}
        onTemplateIdChange={selectTemplate}
        contactType={contactType}
        onContactTypeChange={onContactTypeChange}
        receivers={receivers}
        title={title}
        onTitleChange={setTitle}
        body={body}
        onBodyChange={setBody}
        isAddLeadersSentHistory={isAddToOtherAdminsHistory}
        onIsAddLeadersSentHistoryChange={toggleIsAddToOtherAdminsHistory}
        isEnableCommentFunction={isEnableForComment}
        onIsEnableCommentFunctionChange={toggleIsEnableForComment}
        reservationDate={reservationDate}
        onReservationDateChange={setReservationDate}
        isPublishAnswers={isPublishAnswersToMembers}
        onIsPublishAnswersChange={toggleIsPublishAnswersToMembers}
        deadlineDate={deadlineDate}
        onDeadlineDateChange={setDeadlineDate}
        reminderDate={reminderDate}
        onReminderDateChange={setReminderDate}
        questions={questions}
        onQuestionsChange={setQuestions}
        primaryLabel={mode === "New" ? "連絡を送信" : "編集を保存"}
        primaryAction={mode === "New" ? sendContact : updateContact}
        isPrimaryDisabled={isCreateNewContactDisabled}
        isPrimaryLoading={mode === "New" ? isSendingContact : isUpdatingContact}
        secondaryLabel="下書きとして保存"
        secondaryAction={saveAsDraft}
        isSecondaryDisabled={isSaveAsDraftDisabled}
        isSecondaryLoading={isSavingAsDraft}
        openSelectReceiversModal={openSelectReceiversModal}
        gotoSelectEventDateCandidates={gotoSelectEventDateCandidates}
        eventDateCandidates={eventDateCandidates}
        tempMailId={tempMailId}
        attachmentFileNames={attachmentFileNames}
        attachmentItemProps={attachmentItemProps}
        setAttachmentItemProps={setAttachmentItemProps}
        setAttachmentUploaders={setAttachmentUploaders}
        setAttachmentThumbnailUploaders={setAttachmentThumbnailUploaders}
        setEventDateCandidates={setEventDateCandidates}
        resetReceiverType={resetReceiverType}
      />
    </CustomKeyboardAvoidingView>
  )
})

export const createNewContactScreenOptions = {
  ...commonSingleModalOptions,
  title: "連絡作成",
}

const styles = StyleSheet.create({
  headerRightContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  submitButton: {
    marginRight: 8,
  },
  v3dbutton: {
    justifyContent: "center",
    alignItems: "center",
    width: 36,
    height: 36,
  },
  headerLeft: {
    width: 45,
    height: 45,
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
})
