import { Result } from "src/utils/Result"
import { approvePreTeamMember } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type AcceptInvitationRequestParams = {
  accessToken: string
  organizationId: string
  pendingMemberIds: string[]
  isProcessAll: boolean
}

type AcceptInvitationSuccess = undefined

type AcceptInvitationFailure = {
  message: string
}

export const acceptInvitations = async ({ accessToken, organizationId, pendingMemberIds, isProcessAll }: AcceptInvitationRequestParams): Promise<Result<AcceptInvitationSuccess, AcceptInvitationFailure>> => {
  return execApi(
    () => approvePreTeamMember(accessToken, {
      input: {
        teamId: organizationId,
        preMemberIds: isProcessAll ? [] : pendingMemberIds,
        isProcessAll: isProcessAll
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["acceptInvitations", "approvePreTeamMember"],
    }
  )
}
