import { Result } from "src/utils/Result";
import { createMatchingTeamProfile, getMatchingTeamBgImagePutUrl, getMatchingTeamImagePutUrl, GqlError, updateMatchingTeamProfile } from "../../aws/customAPI";
import { ResourceUnit } from "src/types/resource/ResourceUnit";
import { ImageExtension } from "src/aws/API";
import { getResourcePutUrl } from "../resource/getResourcePutUrl";
import { deleteResource } from "../resource/deleteResource";

export type InitProfileRequestParams = {
  accessToken: string;
  id: string;
  ageFrom: number;
  ageTo: number;
  introduction: string;
  teamUrl?: string;
  mode: "create" | "edit"
  localBgImageName?:string
  bgImageData?:File
  deleteBgImageResourceFlg?: boolean
  localIconName?:string
  iconData?:File
}

type IniProfileSuccess = unknown

type IniProfileFailure = {
  message: string
}

export const initOrUpdateProfile = async (params: InitProfileRequestParams): Promise<Result<IniProfileSuccess, IniProfileFailure>> => {
  if (params.mode === "create") {
    await createMatchingTeamProfile(params.accessToken, {
      input: {
        teamId: params.id,
        ageFrom: params.ageFrom,
        ageTo: params.ageTo,
        introduction: params.introduction,
        teamUrl: params.teamUrl
      }
    }).then(_ => {
      return Result.Ok<IniProfileSuccess>({})
    }).catch((e: GqlError) => {
      const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || '予期せぬエラーが発生しました'
      return Result.Error<IniProfileFailure>({
        message: message
      })
    })
    if ((params.localBgImageName && params.bgImageData) || params.deleteBgImageResourceFlg) {
      await uploadMatchingImageFile({
        type: "matchingTeamBgImage", teamId: params.id, size: "normal",
        accessToken: params.accessToken,
        fileData: params.bgImageData,
        localImageName: params.localBgImageName,
        deleteResourceFlg: params.deleteBgImageResourceFlg,
      })
    }
    if (params.localIconName && params.iconData) {
      await uploadMatchingImageFile({
        type: "matchingTeamImage", teamId: params.id, size: "thumbnail",
        accessToken: params.accessToken,
        fileData: params.iconData,
        localImageName: params.localIconName,
      })
    }
  } else {
    await updateMatchingTeamProfile(params.accessToken, {
      input: {
        id: params.id,
        ageFrom: params.ageFrom,
        ageTo: params.ageTo,
        introduction: params.introduction,
        teamUrl: params.teamUrl,
      }
    })
    if ((params.localBgImageName && params.bgImageData) || params.deleteBgImageResourceFlg) {
      await uploadMatchingImageFile({
        type: "matchingTeamBgImage", teamId: params.id, size: "normal",
        accessToken: params.accessToken,
        fileData: params.bgImageData,
        localImageName: params.localBgImageName,
        deleteResourceFlg: params.deleteBgImageResourceFlg,
      })
    }
    if (params.localIconName && params.iconData) {
      await uploadMatchingImageFile({
        type: "matchingTeamImage", teamId: params.id, size: "thumbnail",
        accessToken: params.accessToken,
        fileData: params.iconData,
        localImageName: params.localIconName,
      })
    }
  }
  return Result.Ok(undefined)
};

type SuccessResponse = unknown

type FailureResponse = {
  message: string
}
const ERROR_MESSAGE = "画像のアップロードに失敗しました。ネットワークに接続されているかを確認し、再度保存をしてください。";

type UploadTeamImageInput = ResourceUnit & {
  accessToken: string
  fileData: File | undefined
  localImageName: string | undefined
  deleteResourceFlg?: boolean,
}

export const uploadMatchingImageFile = async ({
                                                accessToken,
                                                fileData,
                                                localImageName,
                                                deleteResourceFlg,
                                                ...resourceUnit
                                              }: UploadTeamImageInput): Promise<Result<SuccessResponse, FailureResponse>> => {
  if (deleteResourceFlg && resourceUnit.type === "matchingTeamBgImage") {
    return deleteResource({ accessToken, ...resourceUnit })
      .then(async () => {
        return Result.Ok(undefined);
      })
      .catch(() => Result.Error({ message: "リソースの削除に失敗しました。" }));
  }
  if (resourceUnit.type === "matchingTeamBgImage" && localImageName != null) {
    const extension = getExtension(localImageName) as ImageExtension;
    const teamId = resourceUnit.teamId
    const urls = await getMatchingTeamBgImagePutUrl(accessToken, {
      input: { teamId, extension }
    }).then(res => res.data?.getMatchingTeamBgImagePutUrl);
    if (urls != null) {
      await fetch(urls.url, {
        method: "PUT",
        body: fileData
      }).then(_ => Result.Ok<SuccessResponse>(undefined))
        .catch(_ => {
          return Result.Error<FailureResponse>({
            message: ERROR_MESSAGE
          });
        });
    } else {
      return Result.Error<FailureResponse>({
        message: ERROR_MESSAGE
      });
    }
  }
  if (resourceUnit.type === "matchingTeamImage" && localImageName != null) {
    const extension = getExtension(localImageName) as ImageExtension;
    const teamId = resourceUnit.teamId;
    const urls = await getMatchingTeamImagePutUrl(accessToken, {
      input: { teamId, extension }
    }).then(res => res.data?.getMatchingTeamImagePutUrl);
    if (urls != null) {
      await fetch(urls.url, {
        method: "PUT",
        body: fileData
      }).then(_ => Result.Ok<SuccessResponse>(undefined))
        .catch(_ => {
          return Result.Error<FailureResponse>({
            message: ERROR_MESSAGE
          });
        });
      await fetch(urls.thumbnailUrl, {
        method: "PUT",
        body: fileData
      }).then(_ => Result.Ok<SuccessResponse>(undefined))
        .catch(_ => {
          return Result.Error<FailureResponse>({
            message: ERROR_MESSAGE
          });
        });
      return Result.Ok(undefined);
    } else {
      return Result.Error<FailureResponse>({
        message: ERROR_MESSAGE
      });
    }
  }
  return Result.Ok(undefined);
};

type UploadAttachmentFileInput = ResourceUnit & {
  accessToken: string
  fileData: File
}

export const uploadMatchingAttachmentFile = async ({
                                             accessToken,
                                             fileData,
                                             ...resourceUnit
                                           }: UploadAttachmentFileInput): Promise<Result<SuccessResponse, FailureResponse>> => {
  if (resourceUnit.type === "matchingOfferAttachmentFile") {
    const urls = await getResourcePutUrl({ accessToken, ...resourceUnit });
    if (urls != null) {
      return await fetch(urls.url, {
        method: "PUT",
        body: fileData
      }).then(_ => Result.Ok<SuccessResponse>(undefined))
        .catch(_ => {
          return Result.Error<FailureResponse>({
            message: ERROR_MESSAGE
          });
        });
    } else {
      return Result.Error<FailureResponse>({
        message: ERROR_MESSAGE
      });
    }
  }
  return Result.Ok(undefined);
};

type UploadAttachmentThumbnailFileInput = ResourceUnit & {
  accessToken: string
  fileData: File
}

export const uploadMatchingAttachmentThumbnailFile = async ({
                                                      accessToken,
                                                      fileData,
                                                      ...resourceUnit
                                                    }: UploadAttachmentThumbnailFileInput): Promise<Result<SuccessResponse, FailureResponse>> => {
  if (resourceUnit.type === "matchingOfferAttachmentThumbnail") {
    const urls = await getResourcePutUrl({ accessToken, ...resourceUnit });
    if (urls != null) {
      return await fetch(urls.url, {
        method: "PUT",
        body: fileData
      }).then(_ => Result.Ok<SuccessResponse>(undefined))
        .catch(_ => {
          return Result.Error<FailureResponse>({
            message: ERROR_MESSAGE
          });
        });
    } else {
      return Result.Error<FailureResponse>({
        message: ERROR_MESSAGE
      });
    }
  }
  return Result.Ok(undefined);
};

const getExtension = (uri: string): string => {
  const split = uri.split(".");
  return split.length > 0 ? split.slice(-1)[0] : "";
};
