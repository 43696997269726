import { selector } from "recoil"
import { getInformations } from "src/queries/settings/getInformations"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { informationsRequestIdState } from "src/recoil/atoms/settings/informationsRequestIdState"
import { readSystemInformationsState } from "src/recoil/atoms/bottomTab/readSystemInformationsState"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"

export const informationsSelector = selector({
  key: "selectors/settings/informationsSelector",
  get: async ({ get }) => {
    get(informationsRequestIdState)
    const accountId = get(accountIdState)
    const tokens = get(tokensState)
    const accessToken = tokens?.accessToken
    if (accountId == null || accessToken == null) {
      return
    }
    const readIds = get(readSystemInformationsState)
    const result = await getInformations({ accountId, accessToken, readIds })
    if (!result.isOk) {
      return
    }
    return result.content
  },
})
