import React, { memo, useMemo } from "react"
import { Image, Text, View } from "react-native"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { matchingOtherProfileSelectorFamily } from "src/recoil/selectors/matching/matchingOtherProfileSelectorFamily"
import { unreadMatchingMessageCountFamily } from "src/recoil/selectors/matching/unreadMatchingMessageCountFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { messagesItem } from "src/types/matching/matchingOfferModel"
import { UseFormatDatetime } from "src/utils/useDateTime"
import { styles } from "./styles"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { Button } from "../buttons/Button"
import { Colors } from "src/constants/Colors"

type Props = {
  message?: messagesItem
  onClick?: () => void
  applicationId: string
  applicationFlg?: boolean
  matchingTeamName?: string
  matchingTeamId?: string
  deleteFlg?: boolean
}

export const MatchingMessageCard = memo<Props>(
  ({ message, onClick, applicationId, applicationFlg, matchingTeamId, deleteFlg }) => {
    const lastMessageCreatedAt = message && UseFormatDatetime(new Date(message.createdAt))

    const { value: matchingOtherProfile } = useAsyncSelector(
      matchingOtherProfileSelectorFamily({ otherTeamId: matchingTeamId })
    )

    const teamApplyId = useMemo(() => (applicationId ? applicationId.split(".")[0] : ""), [applicationId])
    const { value: organization } = useAsyncSelector(matchingOtherProfileSelectorFamily({ otherTeamId: teamApplyId }))

    const isBlocking = useMemo(
      () => (applicationFlg ? matchingOtherProfile?.isBlocking : organization?.isBlocking),
      [matchingOtherProfile, organization, applicationFlg]
    )
    const { value: selectedMyTeam } = useAsyncSelector(selectedMyOrganizationSelector)
    const myTeamId = selectedMyTeam?.id
    const { value: unreadThread } = useAsyncSelector(unreadMatchingMessageCountFamily({ applicationId, myTeamId }))
    const { resourceUrl, refreshResourceUrl } = useResource({
      type: "matchingTeamImage",
      teamId: teamApplyId,
      size: "normal",
    })

    return (
      <Button
        onPress={onClick}
        style={[styles.messageCardContainer, { backgroundColor: deleteFlg ? Colors.gray : Colors.white3 }]}
      >
        <View style={styles.messageContentCard}>
          <View style={styles.topArea}>
            <View style={styles.senderArea}>
              <Image
                style={styles.replySenderImage}
                source={resourceUrl ? { uri: resourceUrl } : defaultThumbnail}
                onError={refreshResourceUrl}
              />
              <View style={styles.replyTextContainer}>
                {unreadThread?.count ? (
                  unreadThread?.count < 100 ? (
                    <View style={styles.replyUnreadCountContainer}>
                      <Text style={styles.replyUnreadCount}>{unreadThread?.count}</Text>
                    </View>
                  ) : (
                    <View style={styles.replyBigUnreadCountContainer}>
                      <View style={styles.replyUnreadCountWrapper}>
                        <Text style={styles.replyUnreadCount}>{unreadThread?.count}</Text>
                      </View>
                    </View>
                  )
                ) : null}
                <View style={styles.sender}>
                  <Text style={[styles.name, { width: "90%" }]}>{organization?.name}</Text>
                  <Text style={styles.datetime}>{lastMessageCreatedAt}</Text>
                </View>
              </View>
            </View>
            {isBlocking ? (
              <Text style={styles.messageCard}>ブロック中の団体とのメッセージは表示されません。</Text>
            ) : (
              <Text ellipsizeMode="tail" style={styles.messageCard} numberOfLines={1}>
                {message?.text}
              </Text>
            )}
          </View>
        </View>
      </Button>
    )
  }
)
