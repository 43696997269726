import { memo, useCallback, useMemo, useState } from "react"
import { Platform, ScrollView, StyleSheet, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Button } from "src/components/parts/buttons/Button"
import { ButtonSize, ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomDateTimePicker } from "src/components/parts/CustomDateTimePicker"
import { PickerViewType } from "src/components/parts/CustomDateTimePicker/PickerViewType"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { CreateNewContactScreens } from "src/constants/CreateNewContactScreens"
import { useAsyncState } from "src/hooks/useAsyncState"
import { eventDateCandidatesState } from "src/recoil/atoms/contact/create/eventDateCandidatesState"
import { CreateNewContactStackScreenProps } from "src/types.d"
import type { EventDateCandidate } from "src/types/contact/EventDateCandidate"
import { getEventDateCandidate, PartialCandidate } from "src/types/contact/EventDateCandidate"
import { Checkbox } from "src/components/parts/Checkbox"
import dayjs from "dayjs"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
type Props = CreateNewContactStackScreenProps<typeof CreateNewContactScreens.SelectEventDateCandidates>

const gap1 = 4
const gap2 = 8
const gap3 = 16

const checkRequired = (candidates: PartialCandidate[]): boolean => {
  return candidates.every((c) => getEventDateCandidate(c) != null)
}

const maxCandidateCount = 20

export const SelectEventDateCandidatesScreen = memo<Props>(({ navigation }) => {
  const [originalCandidates, setOriginalCandidates] = useAsyncState(eventDateCandidatesState)
  const [eventDateCandidates, setEventDateCandidates] = useState<PartialCandidate[]>(originalCandidates ?? [])
  const numberOfCandidates = useMemo(() => eventDateCandidates.length, [eventDateCandidates])
  const isLargeScreen = useCheckPCScreen()

  const addNewCandidate = useCallback(() => {
    setEventDateCandidates((previous) => {
      const next = [...previous]
      next.push({
        startDate: undefined,
        startTime: undefined,
        endDate: undefined,
        endTime: undefined,
      })
      return next
    })
  }, [])
  const applyCandidates = useCallback(() => {
    if (!checkRequired(eventDateCandidates)) {
      console.log(eventDateCandidates)
      console.log("assert failed")
      return
    }
    setOriginalCandidates(eventDateCandidates.map(getEventDateCandidate) as EventDateCandidate[])
    navigation.navigate(CreateNewContactScreens.CreateNewContact)
  }, [setOriginalCandidates, eventDateCandidates, navigation])
  const isApplyDisabled = useMemo(
    () => numberOfCandidates === 0 || !checkRequired(eventDateCandidates),
    [numberOfCandidates, eventDateCandidates]
  )
  const insets = useSafeAreaInsets()

  return (
    <>
      {isLargeScreen ? (
        <ScrollView style={Platform.OS === "web" ? styles.containerWeb : styles.container}>
          <View style={{ display: "flex", width: "100%", alignItems: "center", marginTop: 30 }}>
            <View style={(styles.label, { width: 600 })}>
              <View style={{ display: "flex", justifyContent: "space-between", flexDirection: "row" }}>
                <ItemLabel label="候補日時" />
                <Text>
                  {numberOfCandidates}/{maxCandidateCount}
                </Text>
              </View>
            </View>
            <View style={styles.candidates}>
              {eventDateCandidates.map((candidate, index) => {
                const updateCandidate =
                  (isStart: boolean) => (param: { nextDate?: Date; isDateDeleted: boolean; isAllDay: boolean }) => {
                    const { nextDate: _nextDate, isDateDeleted, isAllDay } = param
                    setEventDateCandidates((previous) => {
                      const next = [...previous]
                      if (isStart) {
                        const nextDate = isDateDeleted ? undefined : _nextDate || previous[index].startDate
                        const nextCandidate = next[index]
                        next[index] = {
                          startDate: nextDate,
                          startTime: isAllDay || isDateDeleted ? undefined : dayjs(nextDate).format("HH:mm"),
                          endDate: nextCandidate.endDate,
                          endTime: isAllDay ? undefined : nextCandidate.endTime,
                        }
                      } else {
                        const nextDate = isDateDeleted ? undefined : _nextDate || previous[index].endDate
                        const nextCandidate = next[index]
                        next[index] = {
                          startDate: nextCandidate.startDate,
                          startTime: isAllDay
                            ? undefined
                            : nextCandidate.startTime || dayjs(nextCandidate.startDate).format("HH:mm"),
                          endDate: nextDate,
                          endTime: isAllDay || isDateDeleted ? undefined : dayjs(nextDate).format("HH:mm"),
                        }
                      }
                      return next
                    })
                  }
                const updateStartCandidate = updateCandidate(true)
                const updateEndCandidate = updateCandidate(false)
                const removeCandidate = () => {
                  setEventDateCandidates((previous) => {
                    const next = [...previous]
                    next.splice(index, 1)
                    return next
                  })
                }

                return (
                  <EventDateCandidateInput
                    candidate={candidate}
                    index={index}
                    updateStartCandidate={updateStartCandidate}
                    updateEndCandidate={updateEndCandidate}
                    removeCandidate={removeCandidate}
                    key={index}
                  />
                )
              })}
            </View>
            <View style={styles.addCandidateButton}>
              <TextButton
                buttonType={ButtonType.Unprioritized}
                buttonSize={ButtonSize.S}
                title="候補日時を追加"
                onPress={addNewCandidate}
                disabled={numberOfCandidates >= maxCandidateCount}
              />
            </View>
          </View>
        </ScrollView>
      ) : (
        <ScrollView style={Platform.OS === "web" ? styles.containerWeb : styles.container}>
          <View style={styles.label}>
            <ItemLabel label="候補日時" />
            <Text>
              {numberOfCandidates}/{maxCandidateCount}
            </Text>
          </View>
          <View style={isLargeScreen ? styles.candidates : { width: "100%" }}>
            {eventDateCandidates.map((candidate, index) => {
              const updateCandidate =
                (isStart: boolean) => (param: { nextDate?: Date; isDateDeleted: boolean; isAllDay: boolean }) => {
                  const { nextDate: _nextDate, isDateDeleted, isAllDay } = param
                  setEventDateCandidates((previous) => {
                    const next = [...previous]
                    if (isStart) {
                      const nextDate = isDateDeleted ? undefined : _nextDate || previous[index].startDate
                      const nextCandidate = next[index]
                      next[index] = {
                        startDate: nextDate,
                        startTime: isAllDay || isDateDeleted ? undefined : dayjs(nextDate).format("HH:mm"),
                        endDate: nextCandidate.endDate,
                        endTime: isAllDay ? undefined : nextCandidate.endTime,
                      }
                    } else {
                      const nextDate = isDateDeleted ? undefined : _nextDate || previous[index].endDate
                      const nextCandidate = next[index]
                      next[index] = {
                        startDate: nextCandidate.startDate,
                        startTime: isAllDay
                          ? undefined
                          : nextCandidate.startTime || dayjs(nextCandidate.startDate).format("HH:mm"),
                        endDate: nextDate,
                        endTime: isAllDay || isDateDeleted ? undefined : dayjs(nextDate).format("HH:mm"),
                      }
                    }
                    return next
                  })
                }
              const updateStartCandidate = updateCandidate(true)
              const updateEndCandidate = updateCandidate(false)
              const removeCandidate = () => {
                setEventDateCandidates((previous) => {
                  const next = [...previous]
                  next.splice(index, 1)
                  return next
                })
              }

              return (
                <EventDateCandidateInput
                  candidate={candidate}
                  index={index}
                  updateStartCandidate={updateStartCandidate}
                  updateEndCandidate={updateEndCandidate}
                  removeCandidate={removeCandidate}
                  key={index}
                />
              )
            })}
          </View>
          <View style={styles.addCandidateButton}>
            <TextButton
              buttonType={ButtonType.Unprioritized}
              buttonSize={ButtonSize.S}
              title="候補日時を追加"
              onPress={addNewCandidate}
              disabled={numberOfCandidates >= maxCandidateCount}
            />
          </View>
        </ScrollView>
      )}
      <View
        style={[
          isLargeScreen
            ? {
                display: "flex",
                alignItems: "center",
              }
            : {},
          { borderTopWidth: 1, borderTopColor: Colors.lightGrey, width: "100%" },
        ]}
      >
        <View
          style={[
            isLargeScreen
              ? [{ width: 300 }, styles.actions, { paddingBottom: insets.bottom + gap3 }]
              : [styles.actions, { paddingBottom: insets.bottom + gap3 }],
          ]}
        >
          <TextButton buttonType={ButtonType.Primary} title="選択する" onPress={applyCandidates} disabled={isApplyDisabled} />
        </View>
      </View>
    </>
  )
})

const addTimeValueToDate = (timeValue: string, dateValue?: Date) => {
  const timeArr = timeValue.split(":")
  if (!timeValue || timeArr.length !== 2 || timeValue.length !== 5) {
    return dateValue
  }
  if (!dateValue) {
    return dateValue
  }
  return dayjs(dateValue)
    .set("hour", +timeArr[0])
    .set("minutes", +timeArr[1])
    .toDate()
}

type EventDateCandidateInputProps = {
  index: number
  candidate: PartialCandidate
  updateStartCandidate: (param: { nextDate?: Date; isDateDeleted: boolean; isAllDay: boolean }) => void
  updateEndCandidate: (param: { nextDate?: Date; isDateDeleted: boolean; isAllDay: boolean }) => void
  removeCandidate: () => void
}

const EventDateCandidateInput = memo<EventDateCandidateInputProps>(
  ({ index, candidate, updateStartCandidate, updateEndCandidate, removeCandidate }) => {
    const [isAllDay, setIsAllDay] = useState(candidate.startDate != null && candidate.startTime == null)
    return (
      <View style={styles.candidateRow} key={index}>
        <View style={styles.candidateLeft}>
          <View style={styles.candidate}>
            <Text style={styles.candidateLabel}>開始</Text>
            <CustomDateTimePicker
              value={candidate.startTime ? addTimeValueToDate(candidate.startTime, candidate.startDate) : candidate.startDate}
              onChange={(nextDate) => updateStartCandidate({ nextDate, isDateDeleted: nextDate == null, isAllDay: isAllDay })}
              viewType={isAllDay ? PickerViewType.Date : PickerViewType.DateTime}
              max={candidate.endDate}
              min={new Date()}
              minuteInterval={1}
            />
            <View style={styles.checkboxContainer}>
              <Checkbox
                isChecked={isAllDay}
                onPress={() => {
                  updateStartCandidate({ isDateDeleted: false, isAllDay: !isAllDay })
                  setIsAllDay(!isAllDay)
                }}
              />
              <Text style={styles.checkboxLabel}>終日</Text>
            </View>
          </View>
          <View style={styles.candidate}>
            <Text style={styles.candidateLabel}>終了</Text>
            <CustomDateTimePicker
              value={
                candidate.endTime
                  ? addTimeValueToDate(candidate.endTime, candidate.endDate ?? candidate.startDate)
                  : candidate.endDate
              }
              onChange={(nextDate) => updateEndCandidate({ nextDate, isDateDeleted: nextDate == null, isAllDay: isAllDay })}
              viewType={isAllDay ? PickerViewType.Date : PickerViewType.DateTime}
              min={candidate.startDate}
              minuteInterval={1}
            />
          </View>
        </View>
        <View style={styles.candidateRight}>
          <Button onPress={removeCandidate}>
            <RemoveIcon />
          </Button>
        </View>
      </View>
    )
  }
)

export const selectEventDateCandidatesScreenOptions = {
  ...commonSingleModalOptions,
  title: "候補日時を選択",
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  containerWeb: {
    // heightを指定しないとスクロールできないため、適当に100を指定
    height: 100,
  },
  label: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: gap3,
  },
  candidates: {
    width: 600,
  },
  candidateRow: {
    flexDirection: "row",
    justifyContent: "center",
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
    paddingVertical: gap2,
    paddingHorizontal: gap2,
  },
  candidateLeft: {
    paddingLeft: gap2,
  },
  candidateRight: {
    justifyContent: "center",
    paddingHorizontal: gap3,
  },
  addCandidateButton: {
    alignItems: "center",
    paddingVertical: gap3,
  },
  actions: {
    paddingTop: gap3,
    paddingHorizontal: gap3,
  },
  candidate: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: gap1,
  },
  candidateLabel: {
    paddingRight: gap3,
    fontWeight: "bold",
  },
  checkboxContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginLeft: 8,
  },
  checkboxLabel: {
    paddingLeft: 8,
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
})
