import { useFocusEffect } from "@react-navigation/native"
import { useCallback } from "react"
import { useRecoilState } from "recoil"
import { linkingRestorerState } from "../atoms/linkingRestorerState"

export const useRestoreLinking = () => {
  const [linkingRestorer, setRestoreLinking] = useRecoilState(linkingRestorerState)
  useFocusEffect(
    useCallback(() => {
      if (linkingRestorer == null) {
        return
      }
      linkingRestorer.restorer()
      setRestoreLinking(undefined)
    }, [linkingRestorer, setRestoreLinking])
  )
}
