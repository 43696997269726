import { selectorFamily } from "recoil"
import { OrganizationMemberDetail } from "src/types/organization/OrganizationMember"
import { organizationMemberDetailRequestIdState } from "src/recoil/atoms/organization/organizationMemberDetailRequestIdState"
import { getOrganizationMemberDetail } from "src/queries/organization/getOrganizationMemberDetail"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"

export const organizationMemberDetailSelectorFamily = selectorFamily<
  OrganizationMemberDetail | undefined,
  { organizationId: string; memberId: string }
>({
  key: "selectors/organization/organizationMemberDetailSelectorFamily",
  get:
    (params) =>
    async ({ get }) => {
      get(organizationMemberDetailRequestIdState)
      const tokens = get(tokensState)
      if (tokens == null) {
        return
      }

      return await getOrganizationMemberDetail({
        accessToken: tokens.accessToken,
        memberId: params.memberId,
      })
    },
})
