import { Result } from "src/utils/Result"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { listMigratedMail, ListMigratedMailVariables } from "src/aws/customAPI"
import { ContactType } from "src/constants/ContactType"
import { ContactFilterValue } from "src/types/contact/ContactFilter"
import { removeUndefined } from "src/utils/removeUndefined"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type GetMigrationContactsParams = {
  accessToken: string
  myMemberId: string
  searchText?: string
  filters?: ContactFilterValue[]
  nextToken?: string
}

type GetMigrationContactsSuccess = {
  contacts: ContactOverviewModel[],
  nextToken?: string
}

type GetMigrationContactsFailure = {
  message: string
}

export const getMigrationContacts = async (params: GetMigrationContactsParams): Promise<Result<GetMigrationContactsSuccess, GetMigrationContactsFailure>> => {
  const { accessToken, myMemberId, searchText, filters } = params

  return execApi(
    () => listMigratedMail(accessToken, {
      teamMemberId: myMemberId,
      filter: getFilter({searchText, filters}),
      appInfo,
      nextToken: params.nextToken,
    }),
    res => ({
      contacts: res.searchMigratedMails.items.map(convertContact),
      nextToken: res.searchMigratedMails.nextToken ?? undefined,
    }),
    {
      identifiers: ["getMigrationContacts", "listMigratedMail"],
      defaultErrorMessage: "移行トレイの取得に失敗しました"
    }
  )
}

type MigratedMail = {
  id: string
  title: string
  sender: string
  favoriteFlg: boolean
  sentDatetime: string
}

const convertContact = (mail: MigratedMail): ContactOverviewModel => {
  return {
    id: mail.id,
    contactType: ContactType.Normal, // データ上は全て通常メール扱いとする
    isRead: false, // 使用しない
    isAnswered: false, // 使用しない
    title: mail.title,
    senderId: '', // 使用しない
    senderName: mail.sender,
    isSenderDeleted: false, // 使用しない
    isFavorite: mail.favoriteFlg,
    isAttachmentExists: false,
    sentDate: new Date(mail.sentDatetime),
    isUpdate: false,
    teamMemberContactId: '' // 使用しない
  }
}

const getFilter = (param: { searchText?: string, filters?: ContactFilterValue[] }): ListMigratedMailVariables["filter"] => {
  return removeUndefined({
    keyword : param.searchText,
    favorite: param.filters?.includes("favorite") || undefined,
  })
}
