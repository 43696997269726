import { selector } from "recoil"
import { ReservedModel } from "src/types/contact/ReservedModel"
import { reservedIdState } from "src/recoil/atoms/contact/create/reservedIdState"
import { reservedSelectorFamily } from "src/recoil/selectors/contact/reservedSelectorFamily"

export const reservedSelector = selector<ReservedModel | undefined>({
  key: "selectors/contact/reservedSelector",
  get: async ({ get }) => {
    const reservedId = get(reservedIdState)
    if (reservedId == null) {
      return
    }
    const reserved = get(reservedSelectorFamily(reservedId))
    return reserved
  },
})
