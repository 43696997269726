import { selectorFamily } from "recoil"
import {
  ContactReplyThread,
  contactReplyThreadSelectorFamily,
} from "src/recoil/selectors/contact/contactReplyThreadSelectorFamily"
import { ContactReplyThreadItem, ContactReplyThreadType, DateLine } from "src/constants/ContactReplyThreadType"
import { contactReplyThreadConcatRequestIdState } from "src/recoil/atoms/contact/contactReplyThreadConcatRequestIdState"

export const contactReplyThreadConcatSelectorFamily = selectorFamily<
  ContactReplyThread | undefined,
  { count?: number; nextTokens: string[]; replyThreadId: string }
>({
  key: "selectors/contact/contactReplyThreadConcatSelectorFamily",
  get:
    ({ nextTokens, ...params }) =>
    async ({ get }) => {
      get(contactReplyThreadConcatRequestIdState)
      if (params.replyThreadId.length === 0) return
      const firstContactReplyThreads = get(contactReplyThreadSelectorFamily(params))
      if (firstContactReplyThreads == null) return
      const result = [
        ...nextTokens
          .map((nextToken) => {
            const r = get(contactReplyThreadSelectorFamily({ ...params, nextToken }))
            return r == null ? undefined : r
          })
          .reverse(),
        firstContactReplyThreads,
      ].reverse()
      const replyItems = result.reduce(
        (p, c) => (c != null ? [...p, ...c.reversedReplyItems] : p),
        [] as ContactReplyThreadItem[]
      )

      const reverseReplyItems = replyItems.reverse()
      const dateLineList: DateLine[] = []
      const filteredReplyItems: ContactReplyThreadItem[] = reverseReplyItems.reduce((acc: ContactReplyThreadItem[], item) => {
        if (item.contactReplyThreadType === ContactReplyThreadType.DateLine) {
          const existingItem = dateLineList.find((accItem) => accItem.label === item.label)
          if (!existingItem) {
            dateLineList.push(item)
            acc.push(item)
          }
        } else {
          acc.push(item)
        }
        return acc
      }, [])

      return {
        ...firstContactReplyThreads,
        reversedReplyItems: filteredReplyItems.reverse(),
        nextToken: result.length > 0 ? result[result.length - 1]?.nextToken : undefined,
      }
    },
})
