import { useCallback, useMemo, useState } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { CustomAlert } from "src/utils/CustomAlert"
import { useFetcher } from "src/hooks/useFetcher"
import { useAuthorizationData } from "../authorization/useAuthorizationData"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { reportMatchingTeam } from "src/apis/matching/reportMatchingTeam"
import { convertDataEmail } from "src/utils/validate"

export const useReportMatchingProfileData = (id: string, onCloseReportModal: () => void) => {
  const [inputComment, setComment] = useState("")
  const { value: selectedMyTeam } = useAsyncSelector(selectedMyOrganizationSelector)
  const teamId = useMemo(() => selectedMyTeam?.id, [selectedMyTeam])

  const { accessToken } = useAuthorizationData()
  const { fetch: onSubmit, isFetching: isLoadingSubmit } = useFetcher(
    useCallback(async () => {
      if (accessToken == null || id == null || teamId == null) return
      const isConfirmed = await CustomAlert.confirm("確認", "この内容で通報しますか？")
      if (!isConfirmed) return

      const newContent = inputComment ? convertDataEmail(inputComment) : ""
      const [createMatchingOfferDraftResult] = await Promise.all([
        reportMatchingTeam({
          accessToken,
          reporterTeamId: teamId,
          otherTeamId: id,
          content: newContent,
        }),
      ])
      if (!createMatchingOfferDraftResult.isOk) {
        await CustomAlert.alert("エラー", createMatchingOfferDraftResult.error.message)
        return
      }
      await CustomAlert.alert("通報完了", "通報が送信されました。")
      onCloseReportModal()
    }, [accessToken, id, inputComment, onCloseReportModal, teamId])
  )

  return {
    inputComment,
    setComment,
    onSubmit,
    isLoadingSubmit,
  }
}
