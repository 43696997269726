import { ValueOf } from "src/types.d"

export const AnswerType = Object.freeze({
  Single: "Single",
  Multiple: "Multiple",
} as const)

export type AnswerType = ValueOf<typeof AnswerType>

export const AnswerTypeLabel = {
  [AnswerType.Single]: "単一選択",
  [AnswerType.Multiple]: "複数選択",
}

export const answerTypes = Object.values(AnswerType)

export const answerTypeOptions = answerTypes.map((t) => ({ value: t, label: AnswerTypeLabel[t] }))
