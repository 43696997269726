import { atomFamily, selectorFamily } from "recoil"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"

export const isShowAffiliationStateFamily = atomFamily<boolean | undefined, string>({
  key: "atoms/organization/myProfile/isShowAffiliationStateFamily",
  default: selectorFamily<boolean | undefined, string>({
    key: "atoms/organization/myProfile/isShowAffiliationStateFamily_default",
    get:
      (organizationId) =>
      ({ get }) => {
        const myProfile = get(myProfileSelectorFamily(organizationId))
        return myProfile?.isShowAffiliation
      },
  }),
})
