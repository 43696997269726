import { useCallback, useMemo, useState } from "react"
import { useRecoilState, useSetRecoilState } from "recoil"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { isReceiversAllMembersState } from "src/recoil/atoms/contact/create/isReceiversAllMembersState"
import { selectedGroupIdsState } from "src/recoil/atoms/contact/create/selectedGroupIdsState"
import { groupsSelectorFamily } from "src/recoil/selectors/organization/group/groupsSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { GroupOverview as Group } from "src/types/organization/group/GroupOverview"

type Params = {
  goToCreateNewContact: () => void
}

export const useSelectGroupsScreenData = ({ goToCreateNewContact }: Params) => {
  const [searchText, setSearchText] = useState("")
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  if (selectedOrganization == null) {
    throw new Error("Select organization before useSelectGroupScreenData.")
  }
  const [selectedGroupIds, setSelectedGroupIds] = useRecoilState(selectedGroupIdsState)
  const setIsReceiversAllMembers = useSetRecoilState(isReceiversAllMembersState)
  const [localSelectedGroupIds, setLocalSelectedGroupIds] = useState(selectedGroupIds)
  const toggleGroup = useCallback((groupId: Group["id"]) => {
    setLocalSelectedGroupIds((ids) => {
      if (ids == null) {
        return [groupId]
      }
      if (ids.includes(groupId)) {
        return ids.filter((id) => groupId !== id)
      }
      return [...ids, groupId]
    })
  }, [])
  const { value: groups } = useAsyncSelector(groupsSelectorFamily(selectedOrganization.id))

  const filteredGroups = useMemo(() => {
    if (groups == null) {
      return
    }

    return groups
      .filter(({ groupName }) => groupName.includes(searchText))
      .map(({ id, groupName }) => ({
        id,
        groupName,
        isChecked: localSelectedGroupIds?.includes(id),
      }))
  }, [searchText, groups, localSelectedGroupIds])

  const selectGroups = useCallback(() => {
    if (localSelectedGroupIds == null) {
      return
    }
    setSelectedGroupIds(localSelectedGroupIds)
    setIsReceiversAllMembers(undefined)
    goToCreateNewContact()
  }, [localSelectedGroupIds, setSelectedGroupIds, goToCreateNewContact, setIsReceiversAllMembers])

  return {
    searchText,
    setSearchText,
    filteredGroups,
    toggleGroup,
    selectGroups,
  }
}
