import { Result } from "src/utils/Result";
import { GqlError, listMatchingOfferSp, PaginationReader } from "../../aws/customAPI";
import { MatchingOffersModel } from "../../types/matching/matchingOfferModel";

export type GetMyOffersParams = {
  accessToken: string
  teamId: string
  teamMemberId: string
  type: "myOffers" | "otherOffers"
  filter?: {
    activityId?: string | undefined
    dateFrom?: string | undefined
    dateTo?: string | undefined
    prefectureId?: string | undefined
    cityId?: string | undefined
    ageFrom?: number | undefined
    ageTo?: number | undefined
    keyword?: string | undefined
  }
  limit?: number
  nextToken?:string | null| undefined
}

type GetMyOffersSuccess = {
  total: number
  data: MatchingOffersModel[]
  nextToken?:string
}

type GetMyOffersFailure = {
  message: string
}

export const getMatchingOffers = async (params: GetMyOffersParams): Promise<Result<GetMyOffersSuccess, GetMyOffersFailure>> => {
  return listMatchingOfferSp(params.accessToken, {
    teamId: params.teamId,
    teamMemberId: params.teamMemberId,
    type: params.type,
    filter: params.filter,
    limit: params.limit,
    nextToken: params.nextToken ? params.nextToken : undefined
  }).then(res => {
    return Result.Ok<GetMyOffersSuccess>({
      data:res.data,
      total: res.total,
      nextToken: res.nextToken ? res.nextToken : undefined
    })
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || '予期せぬエラーが発生しました'
    return Result.Error<GetMyOffersFailure>({
      message: message
    })
  })
}
