import { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { postInquiryPaidTeam } from "src/apis/organization/postInquiryPaidTeam"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useFetcher } from "src/hooks/useFetcher"
import { ApplicantInformationData } from "src/recoil/atoms/applicantInformation/applicantInformationDataState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { inquiryTeamPaidState } from "src/recoil/atoms/organization/inquiryTeamPaidState"
import { inquiryUserDeviceState } from "src/recoil/atoms/settings/inquiry/inquiryUserDeviceState"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { getOrderDetailState } from "src/recoil/selectors/paidTeam/orderDetailState"
import { ModeCompleted } from "src/screens/organization/CompletedApplyPaid"
import { getAsync } from "src/storage/secureStorage/getAsync"
import { CustomAlert } from "src/utils/CustomAlert"
import { convertDataEmail } from "src/utils/validate"

export const useInquiryPaidTeam = (organizationId: string, goToCompletedScreen: (mode: ModeCompleted) => void) => {
  const [inquiry, setInquiry] = useState("")
  const [confirmMode, setConfirmMode] = useState(false)
  const [policyAccept, setPolicyAccept] = useState(false)
  const { value: organizationDetail, isLoading: isLoading } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))
  const orderDetail = useRecoilValue(getOrderDetailState)
  const { value: accountInfo } = useAsyncSelector(accountSelector)
  const userDevice = useRecoilValue(inquiryUserDeviceState)
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const [inquiryState, setInquiryTeamPaidState] = useRecoilState(inquiryTeamPaidState)
  const [applicantData, setApplicantData] = useState<ApplicantInformationData>()

  const setData = async () => {
    const storedData = await getAsync("applicantInformationData")
    if (storedData !== undefined) {
      setApplicantData(JSON.parse(storedData))
    }
  }

  useEffect(() => {
    setData()
  }, [])

  const { fetch: sendInquiry, isFetching: isUpdatingNewContactPush } = useFetcher(async () => {
    const acceptCreate = await CustomAlert.confirm("確認", "本当に問い合わせ内容を送信しますか？")
    if (!acceptCreate || !accessToken) return
    const result = await postInquiryPaidTeam({
      accessToken,
      content: convertDataEmail(inquiry).length <= 2000 ? convertDataEmail(inquiry) : convertDataEmail(inquiry).slice(0, 2000),
      userDevice,
      organizationId: organizationId,
      agreedToPrivacyPolicy: true,
    })
    if (!result.isOk) {
      setInquiryTeamPaidState({
        inquiry: inquiry,
        organizationId: organizationId,
      })
    } else {
      setInquiryTeamPaidState({})
    }
    return goToCompletedScreen(result.isOk ? "SUCCESS" : "FAIL_DB")
  })

  const changeModeOrSendInquiry = useCallback(() => {
    if (confirmMode) {
      sendInquiry()
    } else {
      setConfirmMode(!confirmMode)
    }
  }, [confirmMode, sendInquiry])

  const disabledAcceptButton = useMemo(() => {
    if (confirmMode) return !policyAccept
    return !inquiry
  }, [inquiry, policyAccept, confirmMode])

  useEffect(() => {
    if (inquiryState.organizationId === organizationId && inquiryState.inquiry) {
      setInquiry(inquiryState.inquiry)
      setConfirmMode(true)
    }
  }, [inquiryState, organizationId])

  return {
    inquiry,
    setInquiry,
    confirmMode,
    setConfirmMode,
    policyAccept,
    setPolicyAccept,
    changeModeOrSendInquiry,
    disabledAcceptButton,
    isUpdatingNewContactPush,
    organizationDetail,
    orderDetail,
    accountInfo,
    setInquiryTeamPaidState,
    applicantData,
    isLoading,
  }
}
