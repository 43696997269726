import { selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { groupDetailRequestIdState } from "src/recoil/atoms/organization/group/groupDetailRequestIdState"
import { groupDetailSelectorFamily } from "src/recoil/selectors/organization/group/groupDetailSelectorFamily"

export const groupNameSelectorFamily = selectorFamily<string | undefined, string | undefined>({
  key: "selectors/organization/group/groupNameSelectorFamily",
  get:
    (groupId) =>
    async ({ get }) => {
      const tokens = get(tokensState)
      get(groupDetailRequestIdState)
      const groupDetail = get(groupDetailSelectorFamily(groupId))
      if (tokens == null || groupId == null || groupDetail == null) return

      return groupDetail.groupName
    },
})
