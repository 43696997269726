import { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilState } from "recoil"
import { getFavoriteMatchingOffers } from "src/apis/matching/getFavoriteMatchingOffers"
import { deleteFavoriteMatchingOffer } from "src/aws/customAPI"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { favOffersState } from "src/recoil/atoms/matching/favOfferState"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { MatchingOffersModel } from "src/types/matching/matchingOfferModel"
import { CustomAlert } from "src/utils/CustomAlert"
import { listOfferFavoriteFlgsCacheState } from "src/recoil/atoms/contact/contactFavoriteFlgsCacheState"
export const useFavoriteMatchingOffers = () => {
  const [favOffers, setFavOffers] = useRecoilState<MatchingOffersModel[]>(favOffersState)

  const { value: selectedMyTeam } = useAsyncSelector(selectedMyOrganizationSelector)
  const teamId = selectedMyTeam?.id
  const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(selectedMyTeam?.id))
  const teamMemberId = useMemo(() => myProfile?.memberId, [myProfile])
  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const [searchText, setSearchText] = useState<string>("")
  const [nextToken, setNextToken] = useState<string | undefined | null>(undefined)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [tokenMyFavorite, setTokenMyFavorite] = useState<string | null | undefined>()

  const getData = useCallback(
    async (search: string, nextToken?: string) => {
      if (teamId == null || accessToken == null || teamMemberId == null) return
      setLoading(true)
      await getFavoriteMatchingOffers({
        accessToken,
        teamMemberId,
        search,
        nextToken,
      }).then((res) => {
        setLoading(false)
        if (!res.isOk) {
          CustomAlert.alert("エラー", res.error.message)
          return
        }
        if (nextToken) {
          const filterExistOffer = res.content.data.filter((offer) => {
            return !favOffers.map((of) => of.id).includes(offer.id)
          })
          setFavOffers([...favOffers, ...filterExistOffer])
        } else {
          setFavOffers(res.content.data)
        }
        setTokenMyFavorite(res.content.nextToken)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamId, accessToken, teamMemberId, favOffers]
  )
  const [favOffersCache, setFavOffersCaChe] = useRecoilState<{ [offerId: string]: boolean }>(listOfferFavoriteFlgsCacheState)

  const refreshList = async (searchText: string) => {
    setNextToken(undefined)
    setFavOffers([])
    await getData(searchText)
  }

  const loadMore = async () => {
    await setNextToken(tokenMyFavorite)
  }

  const removeFavorite = async (matchingOfferId: string) => {
    if (!accessToken || !teamMemberId) {
      return
    }
    try {
      await deleteFavoriteMatchingOffer(accessToken, {
        teamMemberId,
        matchingOfferId,
      })
      setFavOffers(favOffers.filter((offer) => offer.id !== matchingOfferId))
      setFavOffersCaChe((prevCache) => {
        const newCache = { ...prevCache }
        delete newCache[matchingOfferId]
        return newCache
      })
    } catch (e) {
      console.error(e)
      CustomAlert.alert("エラー", "お気に入りの削除に失敗しました")
    }
  }
  const hasMore = useMemo(() => tokenMyFavorite && tokenMyFavorite?.length > 0, [tokenMyFavorite])
  useEffect(() => {
    if (nextToken) {
      getData(searchText, nextToken)
    } else {
      getData(searchText)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken, searchText])
  useEffect(() => {
    getData(searchText)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId])

  return {
    favOffers,
    isLoading,
    refreshList,
    loadMore,
    searchText,
    setSearchText,
    setFavOffersCaChe,
    favOffersCache,
    hasMore,
    removeFavorite,
  }
}
