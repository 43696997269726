import { selector } from "recoil"
import { selectedMyOrganizationSelector } from "../organization/selectedMyOrganizationSelector"
import { tokensState } from "../../atoms/authorization/tokensState"
import { CustomAlert } from "../../../utils/CustomAlert"
import { getListMatchingOfferDraft } from "src/apis/matching/matchingOfferDraft"
import { listMatchingOfferDraftRequestIdState } from "src/recoil/atoms/matching/listMatchingOfferDraftRequestIdState"

export const listMatchingOfferDraftSelector = selector({
  key: "selectors/matting/listMatchingOfferDraftSelector",
  get: async ({ get }) => {
    get(listMatchingOfferDraftRequestIdState)
    const selectedMyOrganization = get(selectedMyOrganizationSelector)
    if (selectedMyOrganization == null) return
    const organizationId = selectedMyOrganization.id
    const tokens = get(tokensState)
    if (tokens == null) return
    const { accessToken } = tokens

    const result = await getListMatchingOfferDraft({ accessToken, organizationId })
    if (!result.isOk) {
      await CustomAlert.alert("エラー", result.error.message)
      return
    }
    return result.content
  },
})
