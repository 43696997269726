import { memo } from "react"
import Svg, { Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
  size?: number
}

export const SuccessAppyIcon = memo<Props>(({ fill = Colors.orangeLight, size = 12.874 }) => (
  <Svg width={size} height={size} viewBox="0 0 42 42" fill="none">
    <Path
      d="M20.9984 1.16504C10.0504 1.16504 1.16504 10.0504 1.16504 20.9984C1.16504 31.9464 10.0504 40.8318 20.9984 40.8318C31.9464 40.8318 40.8318 31.9464 40.8318 20.9984C40.8318 10.0504 31.9464 1.16504 20.9984 1.16504ZM20.9984 36.865C12.2519 36.865 5.1317 29.7449 5.1317 20.9984C5.1317 12.2519 12.2519 5.1317 20.9984 5.1317C29.7449 5.1317 36.865 12.2519 36.865 20.9984C36.865 29.7449 29.7449 36.865 20.9984 36.865ZM30.1019 12.232L17.0318 25.3023L11.8949 20.1853L9.09838 22.9818L17.0318 30.915L32.8984 15.0484L30.1019 12.232Z"
      fill={fill}
    />
  </Svg>
))
