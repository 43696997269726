import { selector } from "recoil"
import { unreadContactCountRequestIdState } from "src/recoil/atoms/bottomTab/unreadContactCountRequestIdState"
import { getUnreadContactCount } from "src/apis/bottomTab/getUnreadContactCount"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"

export const unreadContactCountSelector = selector({
  key: "selectors/bottomTab/unreadContactCountSelector",
  get: ({ get }) => {
    get(unreadContactCountRequestIdState)
    const tokens = get(tokensState)
    const accessToken = tokens?.accessToken
    if (accessToken == null) {
      return
    }
    const accountId = get(accountIdState)
    if (accountId == null) {
      return
    }

    return getUnreadContactCount({ accessToken, accountId })
  },
})
