import { Result } from "src/utils/Result"
import { updateQuestionnaireAnswer } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

export type SurveyAnswer = {
  questionId: string,
  answerIds: string[]
}

type PostSurveyAnswerRequestParams = {
  accessToken: string
  contactId: string
  myMemberId: string
  answers: {
    answer: number[]
  }[]
}

type PostSurveyAnswerSuccess = unknown

type PostSurveyAnswerFailure = {
  errorCode?: number
  message: string
}

export const postSurveyAnswer = async (
  params: PostSurveyAnswerRequestParams
): Promise<Result<PostSurveyAnswerSuccess, PostSurveyAnswerFailure>> => {
  const {accessToken, contactId, myMemberId, answers} = params

  return execApi(
    () => updateQuestionnaireAnswer(accessToken, {
      input: {
        mailId: contactId,
        memberId: myMemberId,
        questionnaireAnswers: answers
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["postSurveyAnswer", "updateQuestionnaireAnswer"],
      defaultErrorMessage: "回答の保存に失敗しました。"
    }
  )
}
