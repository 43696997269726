import { memo, useCallback, useEffect, useMemo, useRef } from "react"
import { AppState, FlatList, ListRenderItemInfo, Platform, StyleSheet, Text, View, useWindowDimensions } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Button } from "src/components/parts/buttons/Button"
import { ButtonSize, ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { Checkbox } from "src/components/parts/Checkbox"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { usePendingInvitationsData } from "src/recoil/hooks/screens/usePendingInvitationsData"
import { PendingMember } from "src/recoil/selectors/organization/pendingInvitationsSelectorFamily"
import { RootStackScreenProps } from "src/types.d"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ItemLabel } from "src/components/parts/ItemLabel"

export type PendingInvitationsParams = {
  id: string
  joinCount?: number
}

type Props = RootStackScreenProps<typeof Screens.PendingInvitations>

export const PendingInvitations = memo<Props>(({ navigation, route }) => {
  const widthScreen = useWindowDimensions().width
  const isLargeScreen = useCheckPCScreen()
  const { id, joinCount } = useMemo(() => route.params, [route.params])
  const closeModal = useCallback(() => navigation.goBack(), [navigation])
  const {
    isPendingInvitationsLoading,
    pendingInvitations,
    overallActionLabel,
    selectedMemberIDs,
    isAccepting,
    isDeclining,
    isBlockingAction,
    toggleSelectedMember,
    applyOverallAction,
    accept,
    decline,
    refreshPendingInvitations: refresh,
    setNextTokens,
    setHasFetchedPendingInvitations,
  } = usePendingInvitationsData({ organizationId: id, joinCount: joinCount, closeModal })
  const insets = useSafeAreaInsets()

  const MemberRow = useCallback(
    (props: ListRenderItemInfo<PendingMember>) => {
      const isChecked = selectedMemberIDs.includes(props.item.id)
      const select = () => toggleSelectedMember(props.item.id)

      return (
        <MemberItem
          onPress={select}
          memberId={props.item.id}
          nickname={props.item.nickname}
          isChecked={isChecked}
          isDisabled={isBlockingAction}
        />
      )
    },
    [selectedMemberIDs, toggleSelectedMember, isBlockingAction]
  )

  const appState = useRef(AppState.currentState)
  useEffect(() => {
    if (Platform.OS === "web") return
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (appState.current.match(/inactive|background/) && nextAppState === "active") {
        refresh()
      }
      appState.current = nextAppState
    })
    return () => {
      subscription.remove()
    }
  }, [refresh])

  const getMorePendingInvitations = useCallback(() => {
    setHasFetchedPendingInvitations(false)
    setNextTokens((prev) => (pendingInvitations?.nextToken != null ? [...prev, pendingInvitations.nextToken] : prev))
  }, [pendingInvitations, setNextTokens, setHasFetchedPendingInvitations])

  const overallActionButton = useMemo(
    () => (
      <TextButton
        title={overallActionLabel}
        buttonType={ButtonType.Blank}
        buttonSize={ButtonSize.S}
        onPress={applyOverallAction}
        style={{ alignSelf: "flex-end" }}
      />
    ),
    // check pr task 831
    [overallActionLabel, applyOverallAction]
  )

  return (
    <View style={styles.container}>
      {(pendingInvitations?.pendingMembers?.length || 0) > 0 ? (
        <View style={[styles.actions, isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : {}]}>
          <ItemLabel
            label={`メンバー ${pendingInvitations?.pendingMembers.length}人`}
            RightComponent={overallActionButton}
            full={true}
          />
        </View>
      ) : (
        <Text style={[styles.noPendingMembers, isLargeScreen ? { textAlign: "center" } : {}]}>
          承認待ちのメンバーはいません。
        </Text>
      )}
      <FlatList
        data={pendingInvitations?.pendingMembers}
        renderItem={MemberRow}
        refreshControl={<CustomRefreshControl refreshing={isPendingInvitationsLoading} onRefresh={refresh} />}
        onEndReached={!isPendingInvitationsLoading ? getMorePendingInvitations : undefined}
        contentContainerStyle={[isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : {}]}
      />
      <View style={[styles.bottomActions, { paddingTop: 10, paddingBottom: insets.bottom + 10 }]}>
        <View style={[{ flexDirection: "row" }, isLargeScreen ? { width: 400, paddingRight: 12 } : { width: "100%" }]}>
          <TextButton
            title="承認"
            buttonType={ButtonType.Primary}
            onPress={accept}
            style={styles.buttonLeft}
            disabled={isBlockingAction || selectedMemberIDs.length === 0}
            isLoading={isAccepting}
          />
          <View style={{ width: 16 }} />
          <TextButton
            title="拒否"
            buttonType={ButtonType.Secondary}
            onPress={decline}
            style={styles.buttonRight}
            disabled={isBlockingAction || selectedMemberIDs.length === 0}
            isLoading={isDeclining}
          />
        </View>
      </View>
    </View>
  )
})

type MemberItemProps = {
  onPress: () => void
  memberId: string
  nickname: string
  isChecked: boolean
  isDisabled: boolean
}

const MemberItem = memo<MemberItemProps>(({ onPress, memberId, nickname, isChecked, isDisabled }) => {
  const { resourceUrl, refreshResourceUrl } = useResource({
    type: "preTeamMemberImage",
    id: memberId,
    size: "thumbnail",
  })
  return (
    <View style={styles.row}>
      <Button style={styles.rowButton} onPress={onPress} disabled={isDisabled}>
        <View style={styles.thumbnail}>
          <CircleThumbnail
            size={40}
            source={resourceUrl ? { uri: resourceUrl } : defaultThumbnail}
            onError={refreshResourceUrl}
          />
        </View>
        <View style={styles.name}>
          <Text style={styles.nameText}>{nickname}</Text>
        </View>
        <View>
          <Checkbox isChecked={isChecked} onPress={onPress} isDisabled={isDisabled} />
        </View>
      </Button>
    </View>
  )
})

export const options: ScreenOptions = {
  ...commonSingleModalOptions,
  title: "承認待ちメンバー一覧",
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  head: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    fontSize: 13,
    fontWeight: "bold",
    color: Colors.warmGrey,
  },
  actions: {
    flexDirection: "row",
    paddingHorizontal: 16,
    paddingVertical: 12,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  noPendingMembers: {
    paddingHorizontal: 16,
    paddingVertical: 12,
    color: Colors.gray,
  },
  selectAllLabel: {
    color: Colors.orange,
    fontSize: 13,
    lineHeight: 35,
    fontWeight: "500",
  },
  acceptLabel: {
    color: Colors.white3,
    fontSize: 14,
    fontWeight: "500",
  },
  declinelabel: {
    color: Colors.greyshBrown,
    fontSize: 14,
    fontWeight: "500",
  },
  smallButton: {
    height: 32,
    borderRadius: 16,
  },
  actionRight: {
    marginLeft: 16,
    flex: 1,
  },
  buttonRight: {
    marginRight: 8,
    flex: 1,
  },
  buttonLeft: {
    marginLeft: 8,
    flex: 1,
  },
  bottomActions: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 24,
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  row: {
    paddingVertical: 10,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  rowButton: {
    flexDirection: "row",
    alignItems: "center",
  },
  thumbnail: {
    paddingRight: 16,
  },
  name: {
    flex: 1,
  },
  nameText: {
    fontSize: 14,
    fontWeight: "bold",
    color: Colors.black2,
  },
})
