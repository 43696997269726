import { MatchingOfferDraftModel } from "../../types/matching/matchingOfferModel";
import { Result } from "src/utils/Result";
import { createMatchingOfferDraft as _createMatchingOfferDraft, GqlError } from "src/aws/customAPI";
import { MatchingOfferPurpose } from "../../aws/API";

type CreateMatchingOfferDraftParams = {
  accessToken: string
  tempMatchingOfferId: string
  model: MatchingOfferDraftModel
}

type CreateMatchingOfferDraftSuccess = unknown

type CreateMatchingOfferDraftFailure = {
  message: string
}

export const createMatchingOfferDraft = async ({ accessToken, tempMatchingOfferId, model }: CreateMatchingOfferDraftParams): Promise<Result<CreateMatchingOfferDraftSuccess, CreateMatchingOfferDraftFailure>> => {
  return await _createMatchingOfferDraft(accessToken, {
    input: {
      teamId: model.organizationId ?? "",
      tempMatchingOfferId,
      purpose: MatchingOfferPurpose.jointPracticeGame,
      title: model.title,
      body: model.body,
      date: model.date,
      startTime: model.startTime?.toString().slice(16, 21),
      endTime: model.endTime?.toString().slice(16, 21),
      prefectureId: model.prefectureId,
      cityId: model.cityId,
      placeURL: model.placeURL,
      expense: model.expense,
      expensePurpose: model.expensePurpose,
      ageFrom: model.ageFrom,
      ageTo: model.ageTo,
      offerCount: model.offerCount,
      publishEndDate: model.publishEndDate,
      place: model.place,
    }
  }).then(res => {
    if (res.data) {
      return Result.Ok(undefined)
    } else {
      return Result.Error<CreateMatchingOfferDraftFailure>({ message: '募集下書きの保存に失敗しました' })
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || '募集下書きの保存に失敗しました'
    return Result.Error<CreateMatchingOfferDraftFailure>({
      message: message
    })
  })
}