import { memo, useCallback, useMemo, useState } from "react"
import { FlatList, ListRenderItemInfo, StyleSheet, Text, View } from "react-native"
import { ScrollView } from "react-native-gesture-handler"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Button } from "src/components/parts/buttons/Button"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { SearchInput } from "src/components/parts/SearchInput"
import { Colors } from "src/constants/Colors"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { Screens } from "src/constants/Screens"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { schoolIdState } from "src/recoil/atoms/account/schoolIdState"
import { School, schoolsSelectorFamily } from "src/recoil/selectors/account/schoolsSelectorFamily"
import { RootStackScreenProps } from "src/types.d"

export type SelectAccountSchoolParams = {
  jobId: string
}

type Props = RootStackScreenProps<typeof Screens.SelectAccountSchool>

const size = 30

export const SelectAccountSchool = memo<Props>(({ navigation, route }) => {
  const { jobId } = useMemo(() => route.params, [route.params])
  const [pageIndex, setPageIndex] = useState(0)
  const [searchText, setSearchText] = useState("")
  const isPCScreen = useCheckPCScreen()
  const loadMore = useCallback(() => setPageIndex((n) => n + 1), [])
  const { value: schoolOptions, isLoading: isLoadingSchoolOptions } = useAsyncSelector(schoolsSelectorFamily(jobId))
  const filteredSchoolOptions = useMemo(() => {
    if (schoolOptions == null) {
      return
    }
    const nameMatched = schoolOptions.filter((opt) => opt.label.includes(searchText) || opt.labelKana.includes(searchText))
    return nameMatched.slice(0, (pageIndex + 1) * size)
  }, [schoolOptions, pageIndex, searchText])
  const [schoolId, setSchoolId] = useAsyncState(schoolIdState)
  const select = useCallback(
    (schoolId: string) => {
      setSchoolId(schoolId)
      navigation.goBack()
    },
    [setSchoolId, navigation]
  )
  const RenderItem = useCallback(
    (option: ListRenderItemInfo<School>) => (
      <Button
        key={option.item.id}
        style={[styles.button, option.item.id === schoolId && { borderColor: Colors.orange, backgroundColor: Colors.pale }]}
        onPress={() => select(option.item.id)}
      >
        <Text>{option.item.label}</Text>
      </Button>
    ),
    [schoolId, select]
  )
  const insets = useSafeAreaInsets()

  return (
    <CustomKeyboardAvoidingView style={[styles.container, { alignItems: "center" }]}>
      <View style={isPCScreen ? { width: 480, alignItems: "center", height: "100%" } : { width: "100%", height: "100%" }}>
        <View style={styles.search}>
          <SearchInput value={searchText} onChangeText={setSearchText} />
        </View>
        {isLoadingSchoolOptions || filteredSchoolOptions == null ? (
          <View style={styles.loading}>
            <LoadingIndicator />
          </View>
        ) : (
          <FlatList
            data={filteredSchoolOptions}
            renderItem={RenderItem}
            contentContainerStyle={{ paddingTop: 8, paddingBottom: insets.bottom + 8 }}
            style={{ width: "100%" }}
            onEndReached={loadMore}
          />
        )}
      </View>
    </CustomKeyboardAvoidingView>
  )
})

export const selectAccountSchoolOptions: ScreenOptions = {
  ...commonSingleModalOptions,
  title: "学校名選択",
}

const styles = StyleSheet.create({
  search: { width: "100%" },
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  scroll: {
    flex: 1,
  },
  loading: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  button: {
    marginHorizontal: 16,
    marginVertical: 4,
    backgroundColor: Colors.white3,
    borderRadius: 8,
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderWidth: 1,
    borderColor: "transparent",
  },
  contents: {
    flex: 1,
    paddingVertical: 4,
  },
})
