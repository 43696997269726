import { Result } from "src/utils/Result"
import { updateReceivedMailFavoriteFlg } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type GetUpdateFavoriteContactRequestParams = {
  accessToken: string
  isFavorite: boolean
} & ({
  myMemberId: string
  contactId: string
} | {
  teamMemberContactId: string
})

export type GetUpdateFavoriteContactSuccess = unknown

type GetUpdateFavoriteContactFailure = {
  message: string
}

export const updateFavoriteContactInbox = async (
  { accessToken, isFavorite, ...key }: GetUpdateFavoriteContactRequestParams
): Promise<Result<GetUpdateFavoriteContactSuccess, GetUpdateFavoriteContactFailure>> => {
  const keyInput = 'myMemberId' in key ? {
    memberId: key.myMemberId,
    mailId: key.contactId,
  } : {
    teamMemberMailId: key.teamMemberContactId,
  }

  return execApi(
    () => updateReceivedMailFavoriteFlg(accessToken, {
      input: {
        favoriteFlg: isFavorite,
        ...keyInput
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["updateFavoriteContactInbox", "updateReceivedMailFavoriteFlg"]
    }
  )
}
