import { Result } from "src/utils/Result"
import { trashReceivedMail } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type TrashContactParams = {
  accessToken: string
  myMemberId: string
  contactId: string
}

type TrashContactSuccess = undefined

type TrashContactFailure = {
  message: string
}

export const trashContact = async (params: TrashContactParams): Promise<Result<TrashContactSuccess, TrashContactFailure>> => {
  const {accessToken, myMemberId, contactId} = params

  return execApi(
    () => trashReceivedMail(accessToken, {
      input: {
        memberId: myMemberId,
        mailId: contactId
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["trashContact", "trashReceivedMail"],
      defaultErrorMessage: "ゴミ箱への移動に失敗しました。"
    }
  )
}
