import { FC, memo } from "react"
import Svg, { G, Path, Rect } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
}

export const HamburgerIcon = memo<Props>(({ fill = Colors.white3 }) => {
  return (
    <Svg width="38" height="38" viewBox="0 0 38 38">
      <G data-name="グループ 2456" transform="translate(-323 -50)">
        <Rect data-name="長方形 1613" width="38" height="38" rx="5" transform="translate(323 50)" fill="none" />
        <G data-name="グループ 2055">
          <Path
            data-name="線 11"
            transform="translate(336.547 63.483)"
            stroke={fill}
            strokeLinecap="round"
            strokeWidth="2px"
            fill="none"
            d="M0 0h11.072"
          />
          <Path
            data-name="線 12"
            transform="translate(336.547 69.16)"
            stroke={fill}
            strokeLinecap="round"
            strokeWidth="2px"
            fill="none"
            d="M0 0h11.072"
          />
          <Path
            data-name="線 13"
            transform="translate(336.547 74.836)"
            stroke={fill}
            strokeLinecap="round"
            strokeWidth="2px"
            fill="none"
            d="M0 0h6.666"
          />
        </G>
      </G>
    </Svg>
  )
})

HamburgerIcon.displayName = "HamburgerIcon"
