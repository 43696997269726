import { StyleSheet } from "react-native"
import { Colors } from "src/constants/Colors"

const styles = StyleSheet.create({
  header: {
    backgroundColor: Colors.white3,
  },
  headerTitle: {
    color: Colors.greyshBrown,
  },
})

/**
 * You should use `commonSingleModalOptions` or `commonStackedModalOptions` alternatively.
 */
export const commonModalOptions = {
  presentation: "modal",
  headerStyle: styles.header,
  headerTitleStyle: styles.headerTitle,
  headerTitleAlign: "center",
  headerBackgroundContainerStyle: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
  },
  headerShadowVisible: false,
} as const
