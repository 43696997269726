import { Result } from "src/utils/Result"
import { registerAuthMethodCode } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type ValidateAuthMethodCodeParams = {
  accessToken: string
  code: string
  type: 'email' | 'phoneNumber'
}

type ValidateAuthMethodCodeSuccess = unknown

type ValidateAuthMethodCodeFailure = {
  message: string
}

export const validateAuthMethodCode = async (params: ValidateAuthMethodCodeParams): Promise<Result<ValidateAuthMethodCodeSuccess, ValidateAuthMethodCodeFailure>> => {
  return execApi(
    () => registerAuthMethodCode(params.accessToken,{
      input: {
        code: params.code,
        type: params.type,
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["validateAuthMethodCode", "registerAuthMethodCode"]
    }
  )
}
