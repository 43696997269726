import { useTeamMemberContacts } from "src/recoil/hooks/screens/contact/useTeamMemberContacts"

export const useContactSurveyAnswerOverviewData = (contactId: string) => {
  const { surveyAnswerOverviews, isLoading } = useTeamMemberContacts(contactId)

  return {
    surveyAnswerOverviews,
    isLoading,
  }
}
