import type { ValueOf } from "src/types.d"

export const CreateNewContactScreens = Object.freeze({
  CreateNewContact: "CreateNewContact",
  SelectMethod: "SelectMethod",
  SelectGroup: "SelectGroup",
  SelectMembers: "SelectMembers",
  SelectEventDateCandidates: "SelectEventDateCandidates",
} as const)

export type CreateNewContactScreens = ValueOf<typeof CreateNewContactScreens>
