import { selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { getGroupMembersByName } from "src/apis/organization/getGroupMembersByName"
import { GroupMember } from "src/types/organization/group/GroupMember"
import { MemberRole, accountImages } from "src/aws/customAPI"
import { groupMemberListRequestIdState } from "src/recoil/atoms/organization/group/groupMemberListIdState"
import { s3ImageDomain } from "src/utils/s3ImageDomain"

export const groupMembersByNameSelectorFamily = selectorFamily<
  { data: GroupMember[] | undefined; total: number | undefined; page: number | undefined; limit: number | undefined },
  { groupId: string | undefined; getImage: boolean; teamId: string; searchText: string; page: number; limit?: number }
>({
  key: "selectors/organization/group/groupMembersByNameSelectorFamily",
  get:
    ({ groupId, getImage, teamId, searchText, page, limit }) =>
    async ({ get }): Promise<any> => {
      get(groupMemberListRequestIdState)
      const tokens = get(tokensState)

      const defaultReturnValue = { data: undefined, total: undefined, number: undefined }
      if (tokens == null || groupId == null || !teamId) return undefined
      const listGroupMembers = await getGroupMembersByName({
        accessToken: tokens.accessToken,
        teamId: teamId,
        groupId: groupId,
        name: searchText,
        page: page,
        limit,
      })

      if (!listGroupMembers.isOk) {
        return defaultReturnValue
      }

      if (!getImage || listGroupMembers.content.members.length === 0) {
        return defaultReturnValue
      }

      const convertRole = (value: MemberRole) => {
        if (value == "mailSender") return "GeneralWithContactAuthorization"
        else if (value == "manager") return "Representative"
        return "General"
      }

      const accountIds = listGroupMembers.content.members
        .filter((item) => !!item.accountTeamMembersId)
        .map((item) => item.accountTeamMembersId as string)

      const accountImagesResult = await accountImages(tokens.accessToken, {
        input: {
          accountIds,
        },
      })
      const accountImageMap: Record<string, string> =
        accountImagesResult.data?.multiAccountImageGetUrl.images?.reduce((map, ele) => {
          return {
            ...map,
            [ele.accountId]: [ele.url],
          }
        }, {}) ?? {}

      return {
        data: listGroupMembers.content.members.map((item) => ({
          id: item.id,
          nickname: item.nickname,
          imageUrl: `${s3ImageDomain}${
            accountImageMap[item.accountTeamMembersId ?? ""] ?? "default/account/image/profile.png"
          }?${new Date().getTime()}`,
          memberNumber: item.memberSeq,
          memberType: convertRole(item.role),
          isLeader: item.leaderFlg,
          nicknameKana: item.nicknameKana,
        })),
        total: listGroupMembers.content.total,
        page: listGroupMembers.content.page,
        limit: listGroupMembers.content.limit,
      }
    },
})
