import { execApi } from "src/apis/execApi"
import { getTeamOrder } from "src/aws/customAPI"
import {PaidVersionOrderType,  PlanType } from "src/aws/API"

type Params = {
  accessToken: string
  teamId: string
}

export type PlanModelDetail = {
  id: string
  planCode: string
  name: string
  type: string
  memberLimit?: number | null | undefined
  teamLimit?: number | null | undefined
  period: number
  amount: number
  planType: PlanType
}

export type Response = {
    usedOrder: {
      order?: PaidVersionOrderType
      plan?: PlanModelDetail
    },
    preOrder: {
      order?: PaidVersionOrderType
      plan?: PlanModelDetail
    }
}

export enum OderPaymentType {
  PostPaid = "postpaid",
  PrePaid = "prepaid",
}

export enum OrderPaymentStatus {
  Wait = 'wait',
  Paid = 'paid',
}

export enum OrderStatus {
  New = 'new',
  Wait = 'wait',
  Use = 'use',
  Cancel = 'cancel',
  End = 'end',
}

export const getOrderDetail = async (params: Params): Promise<Response | string> => {
  const result = await execApi(() => getTeamOrder(params.accessToken, {
    input: {
      teamId: params.teamId
    }
  }), res => {
    return {
    usedOrder: {
      order: res.getTeamOrder.usedOrder?.order ?? undefined,
      plan: res.getTeamOrder.usedOrder?.plan ?? undefined
    },
    preOrder: {
      order: res.getTeamOrder.preOrder?.order ?? undefined,
      plan: res.getTeamOrder.preOrder?.plan ?? undefined
    }
    }
  })

  return result.isOk ? result.content : (result.errorMessage ?? "")
}