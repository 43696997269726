import { DrawerContentComponentProps } from "@react-navigation/drawer"
import { useEffect, useMemo } from "react"
import { Pressable, StyleSheet, Text, View } from "react-native"
import { useRecoilState, useRecoilValue } from "recoil"
import { Button } from "src/components/parts/buttons/Button"
import { BadgeIcon } from "src/components/parts/icons/BadgeIcon"
import { ContactIcon } from "src/components/parts/icons/bottomTab/ContactIcon"
import { DraftIcon } from "src/components/parts/icons/drawer/DraftIcon"
import { InboxIcon } from "src/components/parts/icons/drawer/InboxIcon"
import { MigrationIcon } from "src/components/parts/icons/drawer/MigrationIcon"
import { OutboxIcon } from "src/components/parts/icons/drawer/OutboxIcon"
import { TemplateIcon } from "src/components/parts/icons/drawer/TemplateIcon"
import { TrashBoxIcon } from "src/components/parts/icons/drawer/TrashBoxIcon"
import { Colors } from "src/constants/Colors"
import { ContactNetworkDrawers } from "src/constants/ContactNetworkDrawers"
import { RootTabs } from "src/constants/RootTabs"
import { Screens } from "src/constants/Screens"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { routeNameState } from "src/recoil/atoms/routeNameState"
import { contacGroupState } from "src/recoil/atoms/sidebar/contactGroupState"
import { useAuthorization } from "src/recoil/hooks/authorization/useAuthorization"
import { unreadContactCountSelector } from "src/recoil/selectors/bottomTab/unreadContactCountSelector"
import { hasUnreadThreadMessageSelectorFamily } from "src/recoil/selectors/contact/hasUnreadThreadMessageSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"

type ContactSideMenuProps = DrawerContentComponentProps

type HeaderProps = {
  title: string
  isActive?: boolean
  hasBadge?: boolean
}

const PcHeader = ({ title, isActive, hasBadge }: HeaderProps) => {
  return (
    <View style={styles.pcHeader}>
      <ContactIcon filled={isActive} color={isActive ? Colors.orange : Colors.gray} backgroundColor={Colors.white3} />
      <Text style={styles.pcHeaderTitle}>{title}</Text>
      {hasBadge && <BadgeIcon size={"small"} />}
    </View>
  )
}

export const ContactSideMenu = ({ navigation, state }: ContactSideMenuProps) => {
  const { value: selectedOrganization, isLoading: isLoadingOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const { allowCreateContact } = useCheckAllowAccountPermission({})
  const {
    isLoading: isLoadingAuth,
    allowGoToMigrationBox,
    allowTeamMatching,
  } = useAuthorization({
    organizationId: selectedOrganization?.id,
  })

  const { value: _unreadCount } = useAsyncSelector(unreadContactCountSelector)
  const unreadCount = useMemo(
    () => (selectedOrganization ? _unreadCount?.[selectedOrganization.id] ?? 0 : 0),
    [selectedOrganization, _unreadCount]
  )

  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const { value: hasUnreadThreadMessage } = useAsyncSelector(hasUnreadThreadMessageSelectorFamily({ organizationId }))
  const inbox = () =>
    navigation.navigate(Screens.Root, {
      screen: RootTabs.ContactNetworkDrawer,
      params: { screen: ContactNetworkDrawers.InboxScreen, params: { organizationId } },
    })
  const outbox = () =>
    navigation.navigate(Screens.Root, {
      screen: RootTabs.ContactNetworkDrawer,
      params: { screen: ContactNetworkDrawers.OutboxScreen, params: { organizationId } },
    })
  const draft = () =>
    navigation.navigate(Screens.Root, {
      screen: RootTabs.ContactNetworkDrawer,
      params: { screen: ContactNetworkDrawers.DraftScreen, params: { organizationId } },
    })
  const template = () =>
    navigation.navigate(Screens.Root, {
      screen: RootTabs.ContactNetworkDrawer,
      params: { screen: ContactNetworkDrawers.TemplateScreen, params: { organizationId } },
    })
  const trash = () =>
    navigation.navigate(Screens.Root, {
      screen: RootTabs.ContactNetworkDrawer,
      params: { screen: ContactNetworkDrawers.TrashBoxScreen, params: { organizationId } },
    })
  const migration = () =>
    navigation.navigate(Screens.Root, {
      screen: RootTabs.ContactNetworkDrawer,
      params: { screen: ContactNetworkDrawers.MigrationScreen, params: { organizationId } },
    })

  const routeName = useRecoilValue(routeNameState)
  const [showSubGroup, setShowSubGroup] = useRecoilState(contacGroupState)

  useEffect(() => {
    if (!showSubGroup) {
      setShowSubGroup(Object.values<string>(ContactNetworkDrawers).includes(routeName ?? ""))
    }
  }, [routeName, setShowSubGroup, showSubGroup])

  return (
    <View style={styles.container}>
      <View style={styles.pcGroupContainer}>
        <Pressable
          style={({ pressed }) => [
            {
              backgroundColor: pressed ? Colors.pale : Colors.white3,
            },
            styles.controlButton,
          ]}
          onPress={() => setShowSubGroup(!showSubGroup)}
        >
          <PcHeader title="連絡" isActive={showSubGroup} hasBadge={unreadCount > 0 || hasUnreadThreadMessage?.hasUnread} />
        </Pressable>
      </View>
      {showSubGroup && (
        <View style={styles.subGroupContainer}>
          <Button
            onPress={inbox}
            style={[styles.button, routeName === ContactNetworkDrawers.InboxScreen && styles.activeButton]}
          >
            <View style={styles.iconContainer}>
              <InboxIcon color={Colors.orange} />
            </View>
            <Text style={styles.buttonText}>受信トレイ</Text>
            {(unreadCount > 0 || (!!hasUnreadThreadMessage?.hasUnread && hasUnreadThreadMessage.type !== "outbox")) && (
              <BadgeIcon size={"small"} />
            )}
          </Button>

          {isLoadingAuth ||
            (allowCreateContact && (
              <Button
                onPress={outbox}
                style={[styles.button, routeName === ContactNetworkDrawers.OutboxScreen && styles.activeButton]}
              >
                <View style={styles.iconContainer}>
                  <OutboxIcon color={Colors.orange} />
                </View>
                <Text style={styles.buttonText}>送信トレイ</Text>
                {hasUnreadThreadMessage?.hasUnread && hasUnreadThreadMessage.type === "outbox" && <BadgeIcon size={"small"} />}
              </Button>
            ))}

          {isLoadingAuth ||
            (allowCreateContact && (
              <Button
                onPress={draft}
                style={[styles.button, routeName === ContactNetworkDrawers.DraftScreen && styles.activeButton]}
              >
                <View style={styles.iconContainer}>
                  <DraftIcon color={Colors.orange} />
                </View>
                <Text style={styles.buttonText}>下書き</Text>
              </Button>
            ))}

          {isLoadingAuth ||
            (allowCreateContact && (
              <Button
                onPress={template}
                style={[styles.button, routeName === ContactNetworkDrawers.TemplateScreen && styles.activeButton]}
              >
                <View style={styles.iconContainer}>
                  <TemplateIcon color={Colors.orange} />
                </View>
                <Text style={styles.buttonText}>テンプレート</Text>
              </Button>
            ))}

          <Button
            onPress={trash}
            style={[styles.button, routeName === ContactNetworkDrawers.TrashBoxScreen && styles.activeButton]}
          >
            <View style={styles.iconContainer}>
              <TrashBoxIcon color={Colors.orange} />
            </View>
            <Text style={styles.buttonText}>ゴミ箱</Text>
          </Button>

          {isLoadingAuth ||
            isLoadingOrganization ||
            allowGoToMigrationBox == null ||
            (allowGoToMigrationBox && selectedOrganization?.migrationId != null && (
              <Button
                onPress={migration}
                style={[styles.button, routeName === ContactNetworkDrawers.MigrationScreen && styles.activeButton]}
              >
                <View style={styles.iconContainer}>
                  <MigrationIcon color={Colors.orange} />
                </View>
                <Text style={styles.buttonText}>移行トレイ</Text>
              </Button>
            ))}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
  },
  groupContainer: {
    paddingTop: 8,
    paddingHorizontal: 8,
  },
  subGroupContainer: {
    paddingRight: 8,
    paddingLeft: 32,
  },
  pcGroupContainer: {
    marginHorizontal: 10,
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  header: {
    flexDirection: "row",
    paddingVertical: 8,
    alignItems: "center",
  },
  pcHeader: {
    flexDirection: "row",
    paddingHorizontal: 6,
    paddingVertical: 8,
    width: "100%",
    alignItems: "center",
    elevation: 5,
    gap: 35,
  },
  headerTitle: {
    paddingHorizontal: 4,
    fontWeight: "bold",
    fontSize: 18,
  },
  pcHeaderTitle: {
    paddingHorizontal: 4,
    fontWeight: "bold",
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: 8,
    paddingHorizontal: 8,
    marginBottom: 8,
    borderRadius: 8,
    gap: 17,
    backgroundColor: Colors.white3,
  },
  controlButton: {
    paddingVertical: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 8,
  },
  activeButton: {
    backgroundColor: Colors.bgOrange,
  },
  buttonText: {
    paddingHorizontal: 6,
    fontSize: 14,
    fontWeight: "bold",
  },
  iconContainer: {
    width: 24,
    height: 24,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
})
