import { getListPlan as getListPlanApi } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import { PlanModel } from "src/types/paidTeam/plan"

type Param = {
  accessToken: string
}


export const getListPlan = async ({ accessToken }: Param): Promise<PlanModel[]> => {
  const result = await execApi(
    () => getListPlanApi(accessToken, { appInfo }),
    res => res.listPlan.items.map((item) => ({
      id: item.id,
      planCode: item.planCode,
      name: item.name,
      type: item.type,
      memberLimit: item?.memberLimit ?? undefined,
      teamLimit: item?.teamLimit ?? undefined,
      period: item.period,
      amount: item.amount,
      taxRate: item.taxRate,
      planType: item.planType
    })),
    {
      identifiers: ["getListPlan", "getListPlanApi"],
    }
  )

  return result.isOk ? result.content : []
}
