import { memo } from "react"
import { StyleSheet, Text, View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"

type Props = {
  rightButtonTitle: string
  leftButtonTitle: string
  RightButtonIcon: JSX.Element
  LeftButtonIcon: JSX.Element
  onRightButtonPress: () => void
  onLeftButtonPress: () => void
  hideLeft?: boolean
  hideRight?: boolean
  isLargeScreen: boolean
}

export const DoubleButtonFooter = memo<Props>(
  ({
    leftButtonTitle,
    rightButtonTitle,
    LeftButtonIcon,
    RightButtonIcon,
    onLeftButtonPress,
    onRightButtonPress,
    hideLeft,
    hideRight,
    isLargeScreen,
  }) => (
    <View style={[styles.footer, isLargeScreen ? { borderTopWidth: 1, borderTopColor: Colors.white2 } : {}]}>
      <View style={[{ flexDirection: "row" }, isLargeScreen ? { width: 400, paddingRight: 12 } : { width: "100%" }]}>
        <View style={[styles.buttonContainer, hideLeft ? { opacity: 0 } : {}]}>
          <Button onPress={() => !hideLeft && onLeftButtonPress()} style={[styles.button, styles.leftButton]}>
            {LeftButtonIcon}
            <Text style={styles.buttonLabel}>{leftButtonTitle}</Text>
          </Button>
        </View>
        <View style={[styles.buttonContainer, hideRight ? { opacity: 0 } : {}]}>
          <Button onPress={() => !hideRight && onRightButtonPress()} style={[styles.button, styles.rightButton]}>
            {RightButtonIcon}
            <Text style={styles.buttonLabel}>{rightButtonTitle}</Text>
          </Button>
        </View>
      </View>
    </View>
  )
)

const styles = StyleSheet.create({
  footer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 20,
    backgroundColor: Colors.white3,
  },
  buttonContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    padding: 5,
    flexDirection: "row",
  },
  button: {
    flexDirection: "row",
    height: 44,
    flex: 1,
    justifyContent: "space-evenly",
    alignItems: "center",
    borderRadius: 6,
  },
  leftButton: {
    backgroundColor: Colors.leaf,
  },
  rightButton: {
    backgroundColor: Colors.orange,
  },
  buttonLabel: {
    color: Colors.white,
    fontSize: 16,
    fontWeight: "bold",
  },
})
