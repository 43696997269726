import { atom, selector } from "recoil"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { AccountQuestionModel } from "src/types/user/AccountQuestionModel"
import { accountQuestionMasterState } from "src/recoil/atoms/account/accountQuestionMasterState"

export const questionAnswersState = atom<{ [questionId: string]: string } | undefined>({
  key: "atoms/account/questionAnswersState",
  default: selector({
    key: "atoms/account/questionAnswersState_default",
    get: ({ get }) => {
      const account = get(accountSelector)
      if (account?.questionAnswers) {
        return account?.questionAnswers
      }

      const accountQuestionMaster = get(accountQuestionMasterState)
      return accountQuestionMaster ? getDefaultQuestionAnswer(accountQuestionMaster) : undefined
    },
  }),
})

const getDefaultQuestionAnswer = (accountQuestions: AccountQuestionModel[] | undefined): { [questionId: string]: string } => {
  const result: { [key: string]: string } = {}
  accountQuestions?.forEach((aq) => (result[aq.id] = (aq.choices.find((c) => c.isDefault) || aq.choices[0]).id))
  return result
}
