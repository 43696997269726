import { DrawerItem } from "@react-navigation/drawer"
import { DrawerDescriptorMap, DrawerNavigationHelpers } from "@react-navigation/drawer/lib/typescript/src/types"
import { CommonActions, DrawerActions, DrawerNavigationState, ParamListBase, useLinkBuilder } from "@react-navigation/native"
import * as React from "react"
import { Colors } from "src/constants/Colors"
import { CONTACT_GROUP } from "src/constants/RootDrawer"

type Props = {
  state: DrawerNavigationState<ParamListBase>
  navigation: DrawerNavigationHelpers
  descriptors: DrawerDescriptorMap
  filterRoutes?: string[]
  showStatus?: boolean
}

/**
 * Component that renders the navigation list in the drawer.
 */
export default function CustomDrawerItemList({ state, navigation, descriptors, filterRoutes, showStatus = true }: Props) {
  const buildLink = useLinkBuilder()
  const focusedRoute = state.routes[state.index]
  const focusedDescriptor = descriptors[focusedRoute.key]
  const focusedOptions = focusedDescriptor.options

  const { drawerInactiveTintColor, drawerActiveBackgroundColor, drawerInactiveBackgroundColor } = focusedOptions

  if (!showStatus) {
    return null
  }

  return state.routes
    .filter((r) => filterRoutes?.includes(r.name))
    .map((route, i) => {
      const focused = state.index === state.routes.findIndex((r) => r.name === route.name)

      const onPress = () => {
        const event = navigation.emit({
          type: "drawerItemPress",
          target: route.key,
          canPreventDefault: true,
        })

        if (!event.defaultPrevented) {
          navigation.dispatch({
            ...(focused ? DrawerActions.closeDrawer() : CommonActions.navigate({ name: route.name, merge: true })),
            target: state.key,
          })
        }
      }

      const { title, drawerLabel, drawerIcon, drawerLabelStyle, drawerItemStyle, drawerAllowFontScaling } =
        descriptors[route.key].options

      return (
        <DrawerItem
          key={route.key}
          label={drawerLabel !== undefined ? drawerLabel : title !== undefined ? title : route.name}
          icon={drawerIcon}
          focused={focused}
          activeTintColor={Colors.orange}
          inactiveTintColor={drawerInactiveTintColor}
          activeBackgroundColor={drawerActiveBackgroundColor}
          inactiveBackgroundColor={drawerInactiveBackgroundColor}
          allowFontScaling={drawerAllowFontScaling}
          labelStyle={[drawerLabelStyle, { fontWeight: "bold" }]}
          style={[drawerItemStyle, CONTACT_GROUP.includes(route.name) && { marginLeft: 4 }]}
          to={buildLink(route.name, route.params)}
          onPress={onPress}
        />
      )
    }) as React.ReactNode as React.ReactElement
}
