import { memo, useEffect, useMemo, useState } from "react"
import { PixelRatio, StyleSheet, Text, View } from "react-native"
import { CustomDateTimePicker } from "src/components/parts/CustomDateTimePicker"
import { PickerViewType } from "src/components/parts/CustomDateTimePicker/PickerViewType"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { RadioButton } from "src/components/parts/RadioButton"
import { Colors } from "src/constants/Colors"
import { Gender, GenderLabel } from "src/constants/Gender"
import { SelectOption } from "src/types/SelectOption"
import { SelectButton } from "src/components/parts/buttons/SelectButton"
import { AttendanceRadioButton } from "src/components/parts/AttendanceRadioButton"
import { AccountQuestionModel } from "src/types/user/AccountQuestionModel"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { Button } from "src/components/parts/buttons/Button"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { ValidationErrorMessage } from "src/components/parts/ValidationErrorMessage"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

type Props = {
  firstName?: string
  lastName?: string
  kanaFirstName?: string
  kanaLastName?: string
  email: string
  birthDate?: Date
  gender: Gender
  jobOptions?: SelectOption[]
  jobId?: string
  schoolId?: string
  schoolOptions?: SelectOption[]
  schoolDepartmentId?: string
  schoolDepartmentOptions?: SelectOption[]
  graduationYear?: number
  graduationYearOptions: { value: number; label: string }[]
  prefectureOptions?: SelectOption[]
  prefectureId?: string
  cityOptions?: SelectOption[]
  cityId?: string
  questionAnswers?: { [questionId: string]: string }
  accountQuestions?: AccountQuestionModel[]
  setFirstName: (next: string) => void
  setLastName: (next: string) => void
  setKanaFirstName: (next: string) => void
  setKanaLastName: (next: string) => void
  updateBirthDate: (next?: Date) => void
  setGender: (next: Gender) => void
  setJobId: (next?: string) => void
  setSchoolId: (next?: string) => void
  setSchoolDepartmentId: (next?: string) => void
  setGraduationYear: (next?: number) => void
  setPrefecture: (next?: string) => void
  setCity: (next?: string) => void
  setQuestionAnswers: (next?: { [questionId: string]: string }) => void
  gotoSelectAccountSchool: () => void
  migrateAccountData?: boolean
  firstNameErrorMessage?: string
  kanaLastNameErrorMessage: string
  kanaFirstNameErrorMessage: string
  birthdayErrorMessage: string
}

export const AccountEditor = memo<Props>(
  ({
    firstName,
    lastName,
    kanaFirstName,
    kanaLastName,
    email,
    birthDate,
    gender,
    jobOptions,
    jobId,
    schoolId,
    schoolDepartmentId,
    schoolOptions,
    schoolDepartmentOptions,
    graduationYear,
    graduationYearOptions,
    prefectureOptions,
    prefectureId,
    cityOptions,
    cityId,
    questionAnswers,
    accountQuestions,
    setFirstName,
    setLastName,
    setKanaFirstName,
    setKanaLastName,
    updateBirthDate,
    setGender,
    setJobId,
    setSchoolDepartmentId,
    setGraduationYear,
    setPrefecture,
    setCity,
    setQuestionAnswers,
    gotoSelectAccountSchool,
    firstNameErrorMessage,
    kanaLastNameErrorMessage,
    kanaFirstNameErrorMessage,
    birthdayErrorMessage,
  }) => {
    const schoolName = useMemo(() => schoolOptions?.find((so) => so.value === schoolId)?.label, [schoolOptions, schoolId])
    const getOnAnswerChange = (questionId: string) => (choiceId: string) => {
      setQuestionAnswers({ ...questionAnswers, [questionId]: choiceId })
    }
    const [defaultBirthDate, setDefaultBirthDate] = useState<Date | undefined>(getDefaultBirthDate())

    // birthDateが登録されていない際のDatePickerの初期値を設定するための処理
    useEffect(() => {
      if (birthDate == null && defaultBirthDate != null) setDefaultBirthDate(undefined)
    }, [defaultBirthDate, birthDate])

    const adjustedFontSize = 16 / PixelRatio.getFontScale()
    const isLargeScreen = useCheckPCScreen()
    return (
      <View style={[styles.form, isLargeScreen ? styles.oneColumn : {}]}>
        <View style={styles.requiredItemNote}>
          <Text style={styles.requiredItemNoteText}>
            <Text style={styles.requiredItemNoteAsterisk}>※</Text>は必須項目
          </Text>
        </View>
        <View style={styles.feild}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel
              label="お名前"
              required
              RightComponent={<InputLengthCounter text={lastName} maxLength={128} unit={"字以内"} />}
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>姓</Text>
            <CustomTextInput value={lastName} onChangeText={setLastName} style={styles.input} maxLength={128} />
          </View>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="" RightComponent={<InputLengthCounter text={firstName} maxLength={128} unit={"字以内"} />} />
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>名</Text>
            <CustomTextInput
              value={firstName}
              onChangeText={setFirstName}
              style={styles.input}
              maxLength={128}
              isError={firstNameErrorMessage !== "" && firstNameErrorMessage !== undefined}
            />
          </View>
          {firstNameErrorMessage !== "" && (
            <ValidationErrorMessage style={styles.validationErrorMessage} message={firstNameErrorMessage ?? ""} />
          )}
        </View>
        <View style={styles.feild}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel
              label="お名前（フリガナ）"
              required
              RightComponent={<InputLengthCounter text={kanaLastName} maxLength={128} unit={"字以内"} />}
            />
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>セイ</Text>
            <CustomTextInput
              value={kanaLastName}
              onChangeText={setKanaLastName}
              style={styles.input}
              maxLength={128}
              isError={kanaLastNameErrorMessage !== ""}
            />
          </View>
          {kanaLastNameErrorMessage !== "" && (
            <ValidationErrorMessage style={styles.validationErrorMessage} message={kanaLastNameErrorMessage} />
          )}
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="" RightComponent={<InputLengthCounter text={kanaFirstName} maxLength={128} unit={"字以内"} />} />
          </View>
          <View style={styles.row}>
            <Text style={styles.title}>メイ</Text>
            <CustomTextInput
              value={kanaFirstName}
              onChangeText={setKanaFirstName}
              style={styles.input}
              maxLength={128}
              isError={kanaFirstNameErrorMessage !== ""}
            />
          </View>
          {kanaFirstNameErrorMessage !== "" && (
            <ValidationErrorMessage style={styles.validationErrorMessage} message={kanaFirstNameErrorMessage} />
          )}
        </View>
        <View style={styles.itemLabelWrapper}>
          <ItemLabel label="メールアドレス" />
        </View>
        <Text style={styles.text}>{email}</Text>
        <View style={styles.itemLabelWrapper}>
          <ItemLabel label="生年月日" />
        </View>
        <View style={styles.pickerContainer}>
          <CustomDateTimePicker
            value={birthDate || defaultBirthDate}
            onChange={updateBirthDate}
            viewType={PickerViewType.Date}
          />
          <Button onPress={() => updateBirthDate(undefined)} style={styles.resetDateButton}>
            <RemoveIcon />
          </Button>
        </View>
        {birthdayErrorMessage !== "" && (
          <ValidationErrorMessage style={styles.validationErrorMessage} message={birthdayErrorMessage} />
        )}
        <View style={styles.itemLabelWrapper}>
          <ItemLabel label="性別" />
        </View>
        <View style={styles.radioContainer}>
          <View style={styles.radioItem}>
            <RadioButton value={Gender.Male} selected={gender} onPress={setGender} label={GenderLabel[Gender.Male]} />
          </View>
          <View style={styles.radioItem}>
            <RadioButton value={Gender.Female} selected={gender} onPress={setGender} label={GenderLabel[Gender.Female]} />
          </View>
          <View style={styles.radioItem}>
            <RadioButton value={Gender.NoAnswer} selected={gender} onPress={setGender} label={GenderLabel[Gender.NoAnswer]} />
          </View>
        </View>
        <View style={styles.itemLabelWrapper}>
          <ItemLabel label="職業" />
        </View>
        {jobOptions != null ? <CustomSelect options={jobOptions} value={jobId} onChange={setJobId} /> : null}
        {schoolOptions != null ? (
          <>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="学校名" />
            </View>
            <SelectButton onPress={gotoSelectAccountSchool}>
              <Text style={{ fontSize: adjustedFontSize, fontFamily: "'Noto Sans', 'Noto Sans JP', sans-serif" }}>
                {schoolName ?? "選択してください"}
              </Text>
            </SelectButton>
          </>
        ) : null}
        {schoolDepartmentOptions != null && schoolDepartmentOptions.length > 0 ? (
          <>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="学部" />
            </View>
            <CustomSelect options={schoolDepartmentOptions} value={schoolDepartmentId} onChange={setSchoolDepartmentId} />
          </>
        ) : null}
        {schoolOptions != null ? (
          <>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="卒業予定年" />
            </View>
            <CustomSelect options={graduationYearOptions} value={graduationYear} onChange={setGraduationYear} />
          </>
        ) : null}
        <View style={styles.itemLabelWrapper}>
          <ItemLabel label="居住地（都道府県）" />
        </View>
        {prefectureOptions != null ? (
          <CustomSelect options={prefectureOptions} value={prefectureId} onChange={setPrefecture} />
        ) : null}
        {cityOptions != null ? (
          <>
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="居住地（市区町村）" />
            </View>
            <CustomSelect options={cityOptions} value={cityId} onChange={setCity} />
          </>
        ) : null}
        <View style={styles.itemLabelWrapper}>
          <ItemLabel label="アンケート" />
        </View>
        <View style={styles.questionOverview}>
          <Text style={styles.questionOverviewText}>アンケートにご協力ください。お得な情報をお届けいたします。</Text>
        </View>
        {accountQuestions?.map(
          (aq) =>
            questionAnswers &&
            getAccountQuestion({
              question: aq,
              answerId: questionAnswers[aq.id],
              onAnswerChange: getOnAnswerChange(aq.id),
            })
        )}
      </View>
    )
  }
)

const getDefaultBirthDate = () => {
  const year = new Date().getFullYear() - 30
  return new Date(`${year}-01-01`)
}

type AccountQuestionProps = {
  question: AccountQuestionModel
  answerId: string
  onAnswerChange: (choiceId: string) => void
}

const getAccountQuestion = ({ question, answerId, onAnswerChange }: AccountQuestionProps) => (
  <View key={question.id}>
    <View style={styles.questionLabelWrapper}>
      <ItemLabel type="question" label={question.label} />
    </View>
    <View style={styles.threeToggleButtonWrapper}>
      <AttendanceRadioButton
        value={answerId}
        items={[
          { value: question.choices[0].id, label: question.choices[0].label },
          { value: question.choices[1].id, label: question.choices[1].label },
          { value: question.choices[2].id, label: question.choices[2].label },
        ]}
        onValueChange={(value) => onAnswerChange(value)}
        withoutYesNoIcon={true}
      />
    </View>
  </View>
)

AccountEditor.displayName = "AccountEditor"

const styles = StyleSheet.create({
  feild: {
    width: "100%",
  },
  oneColumn: {
    width: "60%",
    maxWidth: 1020,
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  form: {
    paddingHorizontal: 15,
    paddingBottom: 24,
  },
  requiredItemNote: {
    paddingTop: 12,
  },
  requiredItemNoteText: {
    fontWeight: "400",
    fontSize: 14,
  },
  requiredItemNoteAsterisk: {
    color: Colors.red,
  },
  input: {
    flex: 1,
    marginTop: 8,
  },
  text: {
    marginTop: 16,
    paddingLeft: 8,
    paddingBottom: 24,
  },
  validationErrorMessage: {
    marginTop: 5,
  },
  actions: {
    borderTopWidth: 1,
    borderColor: Colors.white6,
    paddingVertical: 24,
    paddingHorizontal: 24,
  },
  itemLabelWrapper: {
    marginTop: 16,
  },
  questionLabelWrapper: {
    marginTop: 16,
    marginBottom: 4,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginRight: 12,
  },
  row: {
    alignItems: "center",
    flexDirection: "row",
  },
  pickerContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
  },
  resetDateButton: {
    marginLeft: 8,
    width: 32,
    height: 32,
    borderRadius: 8,
    backgroundColor: "#efeae3",
    alignItems: "center",
    justifyContent: "center",
  },
  radioContainer: {
    flexDirection: "row",
    marginTop: 8,
    marginBottom: 8,
    paddingLeft: 16,
  },
  radioItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 20,
  },
  selectButtonLabel: {
    fontSize: 16,
  },
  threeToggleButtonWrapper: {
    marginTop: 13,
    width: "100%",
  },
  questionOverview: {
    marginBottom: 8,
  },
  questionOverviewText: {
    fontSize: 14,
  },
})
