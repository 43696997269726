import { memo } from "react"
import { Svg, Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

export const ChevronRightIcon = memo(() => {
  return (
    <Svg width="9.775" height="14.454" viewBox="0 0 9.775 14.454">
      <Path
        data-name="パス 1273"
        d="m1803.616 4362.342 7.57 6.731-7.57 6.2"
        transform="translate(-1802.951 -4361.595)"
        fill="none"
        stroke={Colors.orange}
        strokeWidth={2}
      />
    </Svg>
  )
})
