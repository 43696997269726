import { useCallback, useMemo, useState } from "react"
import { login } from "src/apis/auth/login"
import { AuthMethod } from "src/constants/AuthMethod"
import { useFetcher } from "src/hooks/useFetcher"
import { CustomAlert } from "src/utils/CustomAlert"
import { useSetRecoilState } from "recoil"
import { sessionState } from "src/recoil/atoms/authorization/sessionState"

type Params = {
  gotoEnterAuthCode?: (phoneNumber: string) => void
  phoneNumber?: string
}

export const useLoginPhoneNumberData = ({ gotoEnterAuthCode, phoneNumber: phoneNumber_ }: Params) => {
  const setSession = useSetRecoilState(sessionState)
  const [phoneNumber, setPhoneNumber] = useState(phoneNumber_ || "")
  const { fetch: sendAuthCode, isFetching: isSendingAuthCode } = useFetcher(
    useCallback(async () => {
      const result = await login({ authMethod: AuthMethod.PhoneNumber, value: phoneNumber })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      const { session } = result.content
      setSession(session)
      gotoEnterAuthCode?.(phoneNumber)
    }, [phoneNumber, gotoEnterAuthCode, setSession])
  )
  const isDisabled = useMemo(() => phoneNumber.length !== 11 || isSendingAuthCode, [phoneNumber, isSendingAuthCode])

  return {
    phoneNumber,
    setPhoneNumber,
    sendAuthCode,
    isSendingAuthCode,
    isDisabled,
  }
}
