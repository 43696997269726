import { memo } from "react"
import { Svg, Path, G, Defs, ClipPath } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
}

export const MatchingIcon = memo<Props>(({ fill = Colors.orange }) => {
  return (
    <Svg width={23.85} height={20.244} viewBox="0 0 23.85 20.244">
      <Defs>
        <ClipPath id="a">
          <Path d="M0 0h23.85v20.244H0z" fill="none" />
        </ClipPath>
      </Defs>
      <G clipPath="url(#a)">
        <Path
          data-name="\u30D1\u30B9 1360"
          d="M16.951 19.376a7.6 7.6 0 002.041-.277l.278-.078.194.214a3.574 3.574 0 002.219 1.008 5.188 5.188 0 01-.439-1.972l-.007-.239.189-.146a5.983 5.983 0 002.423-4.733 6.393 6.393 0 00-5.08-6 8.341 8.341 0 01-1.408 5.641.468.468 0 01-.776-.523 7.484 7.484 0 001.3-4.195 7.391 7.391 0 00-.109-1.264l-.006-.033-.025-.135a5.098 5.098 0 00-.029-.141V6.5C16.889 2.732 13.2 0 8.943 0 4.012 0 0 3.623 0 8.076a7.772 7.772 0 003.145 6.148l.189.146-.007.239a5.942 5.942 0 01-.657 2.677 4.852 4.852 0 003.158-1.356l.194-.214.278.078a9.832 9.832 0 002.642.359c.15 0 .528-.022.868-.045a6.651 6.651 0 01.947-7.338.468.468 0 11.713.606 5.722 5.722 0 00-.522 6.849 7.084 7.084 0 006 3.147"
          fill={fill}
        />
      </G>
    </Svg>
  )
})

export const EmptyMatchingIcon = () => {
  return (
    <Svg width={23} height={20} viewBox="0 0 23 20" fill="none">
      <G clipPath="url(#clip0_6144_25689)" fill={Colors.gray}>
        <Path d="M2.725 17.149c-.185 0-.313 0-.332-.01a.438.438 0 01-.401-.47c0-.098.049-.195.117-.264a3.976 3.976 0 00.851-2.338A7.738 7.738 0 01.006 8.061C.006 3.62 3.968 0 8.839 0c4.872 0 8.834 3.62 8.834 8.06a7.999 7.999 0 01-4.363 6.956.434.434 0 01-.587-.176.434.434 0 01.176-.587 7.111 7.111 0 003.913-6.192c0-3.962-3.57-7.19-7.963-7.19C4.457.87.876 4.089.876 8.06a6.956 6.956 0 002.798 5.478.422.422 0 01.167.352c0 .822-.206 1.634-.578 2.367a4.311 4.311 0 002.779-1.203.457.457 0 01.44-.127 8.678 8.678 0 002.357.323c.216 0 .93-.05 1.223-.069a.437.437 0 01.069.871s-.979.078-1.282.078a9.927 9.927 0 01-2.338-.284 5.607 5.607 0 01-3.776 1.321" />
        <Path d="M20.774 19.8a4.487 4.487 0 01-2.984-1.027 7.43 7.43 0 01-1.8.215c-3.864 0-7.004-2.876-7.004-6.398a6.538 6.538 0 015.145-6.173.431.431 0 01.529.313.431.431 0 01-.313.529 5.67 5.67 0 00-4.49 5.331c0 3.052 2.758 5.537 6.143 5.537.616 0 1.223-.078 1.82-.245.156-.039.322 0 .44.127a3.128 3.128 0 001.946.89 4.84 4.84 0 01-.381-1.74c0-.138.059-.274.166-.353a5.324 5.324 0 002.152-4.206 5.723 5.723 0 00-4.852-5.41.433.433 0 11.166-.851A7.185 7.185 0 0121.41 8.53a6.094 6.094 0 011.594 4.07 6.192 6.192 0 01-2.309 4.754c.03.626.245 1.233.636 1.722a.437.437 0 01-.284.734h-.264" />
      </G>
      <Defs>
        <ClipPath id="clip0_6144_25689">
          <Path fill="#fff" d="M0 0H23V19.8094H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  )
}
