import { Result } from "src/utils/Result"
import { ScheduleAnswerType } from "src/constants/ScheduleAnswerType"
import { updateScheduleAnswer } from "src/aws/customAPI"
import { YesNoAnswer } from "src/aws/API"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type PostScheduleAnswerRequestParams = {
  accessToken: string
  contactId: string
  myMemberId: string
  answers: ScheduleAnswerType[]
}

type PostScheduleAnswerSuccess = unknown

type PostScheduleAnswerFailure = {
  errorCode?: number
  message: string
}

export const postScheduleAnswer = async (
  params: PostScheduleAnswerRequestParams
): Promise<Result<PostScheduleAnswerSuccess, PostScheduleAnswerFailure>> => {
  const {accessToken, contactId, myMemberId, answers} = params

  return execApi(
    () => updateScheduleAnswer(accessToken, {
      input: {
        mailId: contactId,
        memberId: myMemberId,
        scheduleAnswers: answers.map(ans => scheduleAnswerTypeMapping[ans])
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["postScheduleAnswer", "updateScheduleAnswer"],
      defaultErrorMessage: "回答の保存に失敗しました"
    }
  )
}

const scheduleAnswerTypeMapping: { [key in ScheduleAnswerType]: YesNoAnswer} = {
  [ScheduleAnswerType.Attend]: YesNoAnswer.yes,
  [ScheduleAnswerType.NonAttend]: YesNoAnswer.no,
  [ScheduleAnswerType.Pend]: YesNoAnswer.tbd,
}
