import { ValueOf } from "src/types.d"

export const ContactFilterValue = Object.freeze({
  Unanswered: "unanswered",
  Favorite: "favorite",
  WithAttachment: "withAttachment",
  WithReply: "withReply",
  Unread: "unread",
  NormalContact: "normalContact",
  AttendanceContact: "attendanceContact",
  SurveyContact: "surveyContact",
  ScheduleAdjustmentContact: "scheduleAdjustmentContact",
} as const)

export type ContactFilterValue = ValueOf<typeof ContactFilterValue>

export const ContactFilterType = Object.freeze({
  Inbox: "inbox",
  Outbox: "outbox",
  Template: "template",
  Migration: "Migration",
} as const)

export type ContactFilterType = ValueOf<typeof ContactFilterType>
