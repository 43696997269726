import { atom, selector } from "recoil"
import { draftSelector } from "src/recoil/selectors/contact/draftSelector"

const initialState = undefined

const initialStateSelector = selector({
  key: "atoms/contact/create/deadlineDateState_initialState",
  get: ({ get }) => {
    const draft = get(draftSelector)
    return draft?.deadlineDate ?? initialState
  },
})

export const deadlineDateState = atom<Date | undefined>({
  key: "atoms/contact/create/deadlineDateState",
  default: initialStateSelector,
})
