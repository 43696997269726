import { OrganizationModel } from "src/types/organization/OrganizationModel"
import { Result } from "src/utils/Result"
import { createTeam, updateTeam } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type PostOrganizationRequestParams = {
  accessToken: string
  tempOrganizationId: string | undefined
  model: OrganizationModel
}

type PostOrganizationSuccess = {
  id?: string
}

type PostOrganizationFailure = {
  message: string
}

export const postOrganization = async ({ accessToken, tempOrganizationId, model }: PostOrganizationRequestParams): Promise<Result<PostOrganizationSuccess, PostOrganizationFailure>> => {
  const { id, category, subCategory, organizationName, prefecture, city, profile, isMembersPublic, requirementForMemberProfile, customQuestions } = model

  if (id == null && tempOrganizationId != null) {
    // 団体を新規作成
    return execApi(
      () => createTeam(accessToken, {
        input: {
          tempTeamId: tempOrganizationId,
          name: organizationName,
          teamCategoryId: category.id,
          teamActivityId: subCategory.id,
          teamPrefectureId: prefecture.toString(),
          teamCityId: city.id,
          description: profile || '',
          showMemberListFlg: isMembersPublic,
          customQuestions: customQuestions,
        },
        appInfo
      }),
      res => ({ id: res.createTeam.id }),
      {
        identifiers: ["postOrganization", "createTeam"]
      }
    )
  } else if (id != null ) {
    // 団体を更新
    return execApi(
      () => updateTeam(accessToken, {
        input: {
          id: id,
          name: organizationName,
          description: profile || '',
          requirementForMemberProfile:requirementForMemberProfile,
          customQuestions: customQuestions,
          showMemberListFlg: isMembersPublic,
          teamCategoryId: category.id,
          teamActivityId: subCategory.id,
          teamPrefectureId: prefecture.toString(),
          teamCityId: city.id,
        }
      }),
      res => ({ id: res.updateTeam.id }),
      {
        identifiers: ["postOrganization", "updateTeam"]
      }
    )
  }

  return Result.Ok({ id })
}