import { atom, selector } from "recoil"
import { draftSelector } from "src/recoil/selectors/contact/draftSelector"
import { templateSelector } from "src/recoil/selectors/contact/templateSelector"
import { OrganizationMemberOverview } from "src/types/organization/OrganizationMember"
import { existingContactDetailSelector } from "src/recoil/selectors/contact/create/existingContactDetailSelector"
import { reservedSelector } from "src/recoil/selectors/contact/reservedSelector"

const initialState = undefined

const initialStateSelector = selector({
  key: "atoms/contact/create/selectedMemberIdsState_initialState",
  get: ({ get }) => {
    const contact = get(existingContactDetailSelector)
    const contactValue = contact?.receivers.memberIds
    const reserved = get(reservedSelector)
    const reservedValue = reserved?.receivers.memberIds
    const template = get(templateSelector)
    const draft = get(draftSelector)
    return contactValue ?? reservedValue ?? (template ?? draft)?.memberIds ?? initialState
  },
})

export const selectedMemberIdsState = atom<OrganizationMemberOverview["id"][] | undefined>({
  key: "atoms/contact/create/selectedMemberIdsState",
  default: initialStateSelector,
})
