import { useResetRecoilState } from "recoil"
import { isPublishAnswersToMembersState } from "src/recoil/atoms/contact/create/isPublishAnswersToMembersState"
import { deadlineDateState } from "src/recoil/atoms/contact/create/deadlineDateState"
import { reminderDateState } from "src/recoil/atoms/contact/create/reminderDateState"
import { reservationDateState } from "src/recoil/atoms/contact/create/reservationDateState"
import { useCallback } from "react"

export const useResetNewContactOptionState = () => {
  const resetIsPublishAnswersToMembersState = useResetRecoilState(isPublishAnswersToMembersState)
  const resetDeadlineDateState = useResetRecoilState(deadlineDateState)
  const resetReminderDateState = useResetRecoilState(reminderDateState)
  const resetReservationDateState = useResetRecoilState(reservationDateState)
  const reset = useCallback(() => {
    resetIsPublishAnswersToMembersState()
    resetDeadlineDateState()
    resetReminderDateState()
    resetReservationDateState()
  }, [resetIsPublishAnswersToMembersState, resetDeadlineDateState, resetReminderDateState, resetReservationDateState])

  return reset
}
