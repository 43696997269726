import React, { memo, useCallback, useImperativeHandle, useMemo, useState } from "react"
import { View, StyleSheet, Text, Modal, Platform, useWindowDimensions } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { Screens } from "src/constants/Screens"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { RootStackScreenProps } from "src/types.d"
import { useRecoilState, useSetRecoilState } from "recoil"
import { selectTeamStateAdmin } from "src/recoil/atoms/migration/selectTeamState"
import { migrationTeam, UninvitedMembers } from "src/apis/migration/migrationTeam"
import { oldRa9Ra9TokenStates } from "src/recoil/atoms/migration/oldRa9Ra9TokenStates"
import { useFetcher } from "src/hooks/useFetcher"
import { useRefresher } from "src/hooks/useRefresher"
import { myTeamsRequestIdState } from "src/recoil/atoms/team/myTeamsRequestIdState"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { useAsyncState } from "src/hooks/useAsyncState"
import { sendAnalyticsEventAsync } from "src/tags/analytics/Analytics"
import { invitationEmailsRequestIdState } from "src/recoil/atoms/team/invitationEmailsRequestIdState"
import { PC_BREAKPOINT } from "src/constants/Screen"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { CustomAlert } from "src/utils/CustomAlert"
import { multiMigrationStatusState } from "src/recoil/atoms/migration/multiMigrationStatusState"

import { BreadCrumb, Step } from "./BreadCrumb"
import { listAllMigratedTeamsRequestIdState } from "src/recoil/atoms/organization/listAllMigratedTeamsRequestIdState"
import { myListTeamRequestIdState } from "src/recoil/atoms/team/myListTeamRequestIdState"
import { myFullOrganizationsRequestIdState } from "src/recoil/atoms/organization/myOrganizationsRequestIdState"
import { notificationsRequestIdState } from "src/recoil/atoms/home/notificationsRequestIdState"
import { migrationTeamsSelector } from "src/recoil/selectors/migration/migrationTeamsSelector"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { ScrollView } from "react-native-gesture-handler"
import { planMigrateFlag } from "src/recoil/atoms/planData/planMigrateFlag"

const useMigrationTeamExecution = (navigation?: any, teamIdProp?: number) => {
  const [selectTeamAdmin, setSelectTeamAdmin] = useRecoilState(selectTeamStateAdmin)
  const refreshTeam = useRefresher(multiMigrationStatusState)
  const { value: allTeamList } = useAsyncSelector(migrationTeamsSelector)

  const [newTeamId, setNewTeamId] = useState("")
  const [memberCount, setMemberCount] = useState(0)
  const [uninvitedMembers, setUninvitedMembers] = useState<UninvitedMembers[]>()
  const [migrationCompletedModalOpen, setMigrationCompletedModalOpen] = useState(false)
  const setMigratePaidFunc = useSetRecoilState(planMigrateFlag)

  if (!selectTeamAdmin) {
    const selectTeam = allTeamList?.filter((team) => team.team_id == teamIdProp)
    if (selectTeam) {
      setSelectTeamAdmin(selectTeam[0])
    }
  }

  const onCloseMigrationCompletedModal = () => {
    setMigrationCompletedModalOpen(false)
  }

  const [emailInvitationContentsModalOpen, setEmailInvitationContentsModalOpen] = useState(false)
  const onCloseEmailInvitationContentsModal = () => setEmailInvitationContentsModalOpen(false)
  const onClickEmailInvitationContentsModal = () => setEmailInvitationContentsModalOpen(true)

  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const { value: oldRa9Ra9AccessTokens } = useAsyncSelector(oldRa9Ra9TokenStates)
  const oldRa9Ra9AccessToken = useMemo(() => oldRa9Ra9AccessTokens?.oldRa9Ra9AccessToken, [oldRa9Ra9AccessTokens])
  const oldRa9Ra9UserId = useMemo(() => Number(oldRa9Ra9AccessTokens?.oldRa9Ra9UserId), [oldRa9Ra9AccessTokens])
  const teamId = useMemo(() => selectTeamAdmin?.team_id, [selectTeamAdmin?.team_id])
  const memberId = useMemo(() => selectTeamAdmin?.member_id, [selectTeamAdmin?.member_id])
  const refreshMyTeams = useRefresher(myTeamsRequestIdState)
  const refreshInvitationEmails = useRefresher(invitationEmailsRequestIdState)
  const refreshAllMigratedTeams = useRefresher(listAllMigratedTeamsRequestIdState)
  const refreshListTeamGql = useRefresher(myListTeamRequestIdState)
  const refreshMyOrganizations = useRefresher(myFullOrganizationsRequestIdState)
  const refreshNotifications = useRefresher(notificationsRequestIdState)

  const refresh = useCallback(() => {
    refreshListTeamGql()
    refreshMyOrganizations()
    refreshNotifications()
    refreshAllMigratedTeams()
  }, [refreshMyOrganizations, refreshNotifications, refreshAllMigratedTeams, refreshListTeamGql])

  const { fetch: migration, isFetching: isLoadingMigration } = useFetcher(
    useCallback(async () => {
      if (accessToken == null || oldRa9Ra9AccessToken == null || oldRa9Ra9UserId == null) return
      const [migrationTeamResult] = await Promise.all([
        migrationTeam({
          accessToken,
          oldRa9Ra9AccessToken,
          userId: oldRa9Ra9UserId,
          teamId,
          memberId,
        }),
      ])
      refreshTeam()
      if (!migrationTeamResult.isOk) {
        CustomAlert.alert("エラー", migrationTeamResult.error.message)
        return
      }
      sendAnalyticsEventAsync("custom_action", "clickButton", "移行を実行するボタン（団体移行）", teamId.toString())
      refreshMyTeams()
      refreshInvitationEmails()
      setNewTeamId(migrationTeamResult.content.newTeamId)
      setMemberCount(migrationTeamResult.content.memberCount)
      setUninvitedMembers(migrationTeamResult.content.uninvitedMembers)
      refresh()
      if (selectTeamAdmin.with_ads === 0) {
        setMigratePaidFunc(true)
      }
      navigation.navigate(Screens.MigrationTeamComplete, {
        teamName: selectTeamAdmin.name,
        memberCount: migrationTeamResult.content.memberCount,
        teamId: migrationTeamResult.content.newTeamId,
      })
    }, [
      accessToken,
      oldRa9Ra9AccessToken,
      oldRa9Ra9UserId,
      teamId,
      memberId,
      refreshTeam,
      refreshMyTeams,
      refreshInvitationEmails,
      selectTeamAdmin,
      navigation,
      refresh,
      setMigratePaidFunc,
    ])
  )

  return {
    selectTeamAdmin,
    newTeamId,
    memberCount,
    uninvitedMembers,
    migrationCompletedModalOpen,
    onCloseMigrationCompletedModal,
    emailInvitationContentsModalOpen,
    onCloseEmailInvitationContentsModal,
    onClickEmailInvitationContentsModal,
    migration,
    isLoadingMigration,
  }
}

export const MigrationTeamConfirmation = memo<RootStackScreenProps<"MigrationTeamConfirmation">>(({ navigation, route }) => {
  const { teamId } = route.params
  const { selectTeamAdmin, migration, isLoadingMigration } = useMigrationTeamExecution(navigation, teamId)
  const isLargeScreen = useCheckPCScreen()
  const [emailInvitationContentsModalOpen, setEmailInvitationContentsModalOpen] = useState(false)
  const insets = useSafeAreaInsets()

  const openDescription = useCallback(() => {
    setEmailInvitationContentsModalOpen(true)
  }, [])
  const heightScreen = useWindowDimensions().height
  const widthScreen = useWindowDimensions().width

  return (
    <View style={[styles.container]}>
      <ScrollView style={[isLargeScreen ? {} : { width: "100%" }]}>
        <BreadCrumb stepProp={Step.three} />
        {selectTeamAdmin ? (
          <View style={isLargeScreen ? styles.oneColumn : { width: "100%", paddingHorizontal: 24, paddingVertical: 32 }}>
            <Text style={[styles.title, { marginBottom: 8, textAlign: "left" }]}>
              {"選択した団体を確認し、移行を実行してください。移行を実行すると全メンバーに招待メールが送信されます。"}
            </Text>
            <Text style={{ marginBottom: 8 }}>
              招待メールは、らくらく連絡網の団体で「利用停止」・「配信エラー」となっている方も対象となります。招待が不要なメンバーは、事前にらくらく連絡網で削除した上で、移行の手続きを行ってください。
            </Text>
            <View style={[{ display: "flex", flexDirection: "row", marginBottom: 32 }]}>
              <View style={{ flexDirection: "row" }}>
                <Text style={[styles.contentLink, { paddingRight: 3 }]}>※</Text>
                <Text style={[styles.contentLink]}>招待メールの例文は </Text>
              </View>
              <Text onPress={openDescription}>
                <Text style={styles.linkText}>こちら</Text>
                <Text>。</Text>
              </Text>
            </View>
            <View>
              <ItemLabel label={"移行する団体"} />
              <View style={styles.card}>
                <Text style={{ fontWeight: "bold", fontSize: 16 }}>{selectTeamAdmin.name}</Text>
              </View>
            </View>
            <View style={styles.content}>
              <Text>
                {`移行完了後も、らくらく連絡網の団体は引き続きご利用可能です。\n不要になったタイミングで、らくらく連絡網より解約の手続きを行なってください。`}
              </Text>
              <View style={{ flexDirection: "row" }}>
                <Text style={[styles.contentNote, { paddingRight: 3 }]}>※</Text>
                <Text style={[styles.contentNote]}>
                  らくらく連絡網で団体を解約すると、団体移行のやり直しやメールの移行ができなくなります。{" "}
                </Text>
              </View>
            </View>
            <View style={isLargeScreen ? styles.centerLayout : { paddingVertical: 10 }}>
              <TextButton
                buttonType={ButtonType.Primary}
                title={"移行を実行する"}
                isLoading={isLoadingMigration}
                onPress={migration}
                style={[isLargeScreen ? styles.buttonsPC : {}, { borderWidth: 0, borderColor: Colors.white2 }]}
              />
            </View>
            <View
              style={[
                styles.actionContainer,
                { paddingBottom: insets.bottom + 10 },
                isLargeScreen ? { alignItems: "center" } : {},
              ]}
            >
              <Modal animationType="fade" visible={emailInvitationContentsModalOpen} transparent>
                <View style={styles.modalWrapper}>
                  <View style={styles.mainModalScroll}>
                    <View
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        marginBottom: 15,
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        onPress={() => setEmailInvitationContentsModalOpen(false)}
                        style={{ paddingVertical: 10, paddingHorizontal: 10 }}
                      >
                        <RemoveIcon fill={Colors.orange}></RemoveIcon>
                      </Button>
                      <Text style={styles.titleModalScroll}>招待メールの内容について</Text>
                      <View style={{ paddingVertical: 10, paddingHorizontal: 10, opacity: 0 }}>
                        <RemoveIcon fill={Colors.orange}></RemoveIcon>
                      </View>
                    </View>
                    <Text style={[styles.textModal, { fontWeight: "700" }]}>
                      {"招待メールでは、下記の内容が送信されます。"}
                    </Text>
                    <CustomScrollView
                      style={[
                        styles.textInput,
                        Platform.OS === "web" ? { maxHeight: heightScreen - 190, maxWidth: widthScreen - 60 } : {},
                      ]}
                    >
                      <Text style={styles.textModal}>
                        {`件名：【［団体名］】「らくらく連絡網＋」への団体移行のご案内\n\n［招待メールを送信した方の団体内ニックネーム］様にて新サービス「らくらく連絡網＋」に団体「［団体名］」が移行されました。\n参加手続きを行っていただきますと、「らくらく連絡網」（旧サービス）での団体内個人設定の情報を引き継ぐことができます。\n下記をご参考の上、お手続きをお願いいたします。\n\n■こちらのアドレスで既に「らくらく連絡網＋」のアカウントがある方\n下記［メンバー登録用URL］よりログインの上、団体参加申請のお手続きを行ってください。\n\n■こちらのアドレスで未だ「らくらく連絡網＋」のアカウントがない方\n下記［メンバー登録用URL］よりアカウント登録の上、団体参加申請のお手続きを行ってください。\n\n［メンバー登録用URL］\n［メンバー登録用URL］\n［招待コード］\n［招待コード］\n※URLの有効期限はyyyy/mm/dd です。\n\n登録についてのよくある質問はこちら\nhttps://www.ra9plus.jp/guide/category/start/ \n\n移行についての詳細はこちら\nhttps://www.ra9plus.jp/guide/category/migration/ \n\n※本メールにお心あたりがない方は、お手数ですが、このメールを破棄くださいますようお願いいたします。\n※このメ－ルは送信専用です。返信をいただいてもご回答できませんのでご了承ください。\n----\nらくらく連絡網＋\nhttps://www.ra9plus.jp/guide`}
                      </Text>
                    </CustomScrollView>
                  </View>
                </View>
              </Modal>
            </View>
          </View>
        ) : (
          <View style={{ paddingTop: 32, alignSelf: "center" }}>
            <LoadingIndicator />
          </View>
        )}
      </ScrollView>
    </View>
  )
})

export const Alert = React.forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  useImperativeHandle(ref, () => ({
    open: (title: string, content: string) => {
      setVisible(true)
      setTitle(title)
      setContent(content)
    },
  }))
  const close = () => {
    setVisible(false)
    setTitle("")
    setContent("")
  }
  return (
    <Modal animationType="fade" visible={visible} transparent>
      <View style={styles.secondLayer}></View>
      <View style={styles.backgroundModal}>
        <View style={styles.alertTop}>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={styles.titleModal}>{title}</Text>
          </View>
          <Text style={styles.contentAlert}>{content}</Text>
        </View>
        <View style={styles.alertBottom}>
          <Button onPress={close}>
            <Text style={{ color: "#3478F6", textAlign: "center" }}>閉じる</Text>
          </Button>
        </View>
      </View>
    </Modal>
  )
})
export const migrationTeamConfirmationOptions: ScreenOptions = ({ navigation }) => ({
  ...commonHeaderOptions,
  headerShown: true,
  title: "移行する団体を確認",
  headerLeft: (props) => (
    <Button onPress={() => navigation.navigate(Screens.MigrationTeamSelect)}>
      <BackIcon
        fill={Number(props.screenLayout?.width) >= PC_BREAKPOINT && Platform.OS === "web" ? Colors.orange : Colors.white3}
        {...props}
      ></BackIcon>
    </Button>
  ),
})
const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.beige,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  oneColumn: {
    width: 600,
    maxWidth: 1020,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 32,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  centerLayout: {
    display: "flex",
    alignItems: "center",
    paddingVertical: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
    marginBottom: 20,
  },
  titleModalLarge: {
    fontSize: 18,
    fontWeight: "600",
    width: "100%",
    textAlign: "center",
  },
  titleModal: {
    fontSize: 18,
    fontWeight: "600",
    textAlign: "center",
  },
  titleModalScroll: {
    fontSize: 18,
    fontWeight: "600",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  content: {
    fontSize: 14,
    fontWeight: "400",
    width: "100%",
    textAlign: "left",
    paddingTop: 12,
    paddingBottom: 14,
  },
  contentNote: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "left",
    paddingTop: 8,
  },
  contentLink: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "left",
  },
  card: {
    borderRadius: 12,
    padding: 15,
    fontWeight: "bold",
    backgroundColor: "#FFFFFF",
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginVertical: 12,
    fontFamily: "Hiragino Kaku Gothic Pro",
  },
  linkText: {
    color: Colors.orange,
    textDecorationLine: "underline",
  },
  backgroundModal: {
    position: "absolute",
    zIndex: 100,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  secondLayer: { backgroundColor: "#f0f0f0", opacity: 0.4, width: "100%", height: "100%" },
  alertTop: {
    backgroundColor: Colors.white3,
    width: 270,
    padding: 16,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  alertBottom: {
    display: "flex",
    justifyContent: "center",
    borderTopWidth: 0.2,
    borderTopColor: "#f0f0f0",
    textAlign: "center",
    backgroundColor: Colors.white3,
    width: 270,
    height: 44,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
    alignItems: "center",
  },
  contentAlert: {
    fontSize: 14,
    fontWeight: "400",
    width: "100%",
    textAlign: "left",
    paddingHorizontal: 2,
  },
  textModal: {
    color: Colors.greyshBrown,
    fontSize: 14,
    lineHeight: 20,
    marginVertical: 20,
  },
  modalWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.37)",
    padding: 22,
    paddingVertical: 60,
  },
  textInput: {
    fontSize: 16,
    textAlignVertical: "top",
    paddingVertical: 13,
    paddingHorizontal: 16,
    backgroundColor: Colors.white3,
    borderWidth: 2,
    borderColor: Colors.lightGrey,
    borderRadius: 10,
    minHeight: 320,
  },
  mainModalScroll: {
    backgroundColor: Colors.white3,
    borderRadius: 8,
    paddingVertical: 24,
    paddingHorizontal: 20,
  },
  actionContainer: {
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
})
