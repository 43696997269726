import { ValueOf } from "src/types.d"

export const ContactReplyThreadType = Object.freeze({
  ReplyFromMe: "ReplyFromMe",
  ReplyFromOthers: "ReplyFromOthers",
  RemovedReply: "RemovedReply",
  DateLine: "DateLine",
  MemberDeleted: "MemberDeleted",
  NoMessage: "NoMessage",
} as const)

export type ContactReplyThreadType = ValueOf<typeof ContactReplyThreadType>

export type ReplyFromMeItem = {
  contactReplyThreadType: typeof ContactReplyThreadType.ReplyFromMe
  replyThreadMessageId: string
  senderName: string
  message: string
  date: Date
  isEdited: boolean
  isRead: boolean
}

export type ReplyFromOthersItem = {
  contactReplyThreadType: typeof ContactReplyThreadType.ReplyFromOthers
  replyThreadMessageId: string
  messageSenderTeamId?: string
  senderName: string
  message: string
  date: Date
  isEdited: boolean
  isRead: boolean
}

export type RemovedReplyItem = {
  contactReplyThreadType: typeof ContactReplyThreadType.RemovedReply
  replyThreadMessageId: string
  messageSenderTeamId?: string
  senderName: string
  date: Date
}

export type DateLine = {
  contactReplyThreadType: typeof ContactReplyThreadType.DateLine
  label: string
  replyThreadMessageId: string
}

export type MemberDeleted = {
  contactReplyThreadType: typeof ContactReplyThreadType.MemberDeleted
  replyThreadMessageId: string
}
export const MemberDeleted: MemberDeleted = {
  contactReplyThreadType: ContactReplyThreadType.MemberDeleted,
  replyThreadMessageId: "",
}

export type NoMessage = {
  contactReplyThreadType: typeof ContactReplyThreadType.NoMessage
  replyThreadMessageId: string
}
export const NoMessage: NoMessage = {
  contactReplyThreadType: ContactReplyThreadType.NoMessage,
  replyThreadMessageId: "",
}

export type ContactReplyThreadItem =
  | ReplyFromMeItem
  | ReplyFromOthersItem
  | RemovedReplyItem
  | DateLine
  | MemberDeleted
  | NoMessage
