import { GqlError, changeApplicantInfo, changePlanPaidOrder } from "src/aws/customAPI";
import { ApplicantInformationData } from "src/recoil/atoms/applicantInformation/applicantInformationDataState";
type Params = {
  accessToken: string
  teamId: string
  applicantInformationData: ApplicantInformationData
  transferStatusId?: string
}

export const changeApplicantInfoApi = async ({ accessToken, applicantInformationData, teamId, transferStatusId }: Params): Promise<{ id: string } | any> => {
  if (applicantInformationData.phoneNumber && applicantInformationData.zip && applicantInformationData.address && applicantInformationData.prefecture) {
    return changeApplicantInfo(accessToken, {
      input: {
        teamId: teamId,
        corporateRepresentativeName: applicantInformationData.appCategory === "個人" ? null :  applicantInformationData.representativeName,
        corporateRepresentativeNameKana: applicantInformationData.appCategory === "個人" ? null :  applicantInformationData.companyRepresentativeNameKana,
        applicantPhone: applicantInformationData.phoneNumber,
        applicantPostcode: applicantInformationData.zip,
        applicantAddress: applicantInformationData.address,
        applicantPrefecture: applicantInformationData.prefecture,
        applicantBuildingName: applicantInformationData.buildingName ?? "",
        transferStatusId: transferStatusId ?? undefined
      }
    }).then(res => {
      if (res.data?.updatePaidVersionOrder.id) {
        return { id: res.data?.updatePaidVersionOrder.id }
      } else {
        return ""
      }
    }).catch((e: GqlError) => {
      return e
    })
  }
}