import { memo, useEffect } from "react"
import { StyleSheet, Text, View } from "react-native"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { OrganizationManagerStackScreenProps } from "src/types.d"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { Colors } from "src/constants/Colors"
import { CloseIcon } from "src/components/parts/icons/CloseIcon"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { SuccessAppyIcon } from "src/components/parts/icons/SuccessAppyIcon"
import { Button } from "src/components/parts/buttons/Button"
import { MainLayout } from "src/layouts/MainLayout"
import { HeaderCommon } from "src/components/parts/HeaderCommon"
import { useCompletedApplicationPaidTeam } from "src/recoil/hooks/organization/useCompletedApplicationPaidTeam"

export type CompletedApplyPaidParams = {
  mode: ModeCompleted
  orderId: string
  type: TypeCompleted
  teamId?: string
}

export type ModeCompleted = "SUCCESS" | "FAIL_DB" | "FAIL_MAIL"
export type TypeCompleted = "create" | "extend" | "cancel" | "updatePlan" | "inquiry"

type Props = OrganizationManagerStackScreenProps<typeof OrganizationManagerScreens.CompletedApplyPaid>

export const CompletedApplyPaid = memo<Props>(({ navigation, route }) => {
  const { mode, orderId, type, teamId } = route.params
  const isLargeScreen = useCheckPCScreen()

  const {
    actionButtonMain,
    goToInquiryPaidTeam,
    isLoadingSendMail,
    renderContent,
    setInquiryState,
    isLoading,
    orderDetailValues,
    goDetail,
  } = useCompletedApplicationPaidTeam(mode, orderId, type, teamId)

  useEffect(() => {
    navigation.setOptions({
      title: renderContent[mode].title,
    })
  })
  return (
    <MainLayout>
      {isLargeScreen && <HeaderCommon noMarginBot={true} title={renderContent[mode].title} needBackIcon={false} />}
      <CustomKeyboardAvoidingView style={styles.container}>
        <View style={[isLargeScreen && styles.rowCenter, { height: "100%", width: "100%" }]}>
          <View style={[isLargeScreen ? { width: "100%" } : { position: "absolute", height: "100%", width: "100%" }]}>
            <View style={[styles.mainContent, !isLargeScreen ? { height: "100%" } : styles.largeSizeContent]}>
              <View style={{ width: isLargeScreen ? 450 : "100%" }}>
                <View style={styles.rowCenter}>
                  {mode === "SUCCESS" ? <SuccessAppyIcon size={42} /> : <CloseIcon size={42} fill={Colors.red} />}
                </View>
                <View style={[styles.rowCenter, styles.spaceTop]}>
                  <Text style={[styles.header, mode !== "SUCCESS" && { color: Colors.red }]}>{renderContent[mode].header}</Text>
                </View>
                {renderContent[mode].content && (
                  <View style={styles.contentSpace}>
                    <Text>{renderContent[mode].content}</Text>
                  </View>
                )}
                <View style={[styles.rowCenter, !renderContent[mode].content && { marginTop: 32 }]}>
                  <TextButton
                    buttonType={ButtonType.Primary}
                    style={{
                      paddingHorizontal: 24,
                      paddingVertical: 12,
                      width: isLargeScreen ? 300 : 264,
                    }}
                    isLoading={
                      isLoadingSendMail ||
                      isLoading ||
                      orderDetailValues === undefined ||
                      typeof orderDetailValues === "string" ||
                      !goDetail
                    }
                    onPress={actionButtonMain}
                    title={renderContent[mode].button}
                  />
                </View>
              </View>
            </View>
            {mode !== "SUCCESS" && (
              <View
                style={[styles.footerContent, isLargeScreen ? { borderBottomWidth: 1 } : { position: "relative", bottom: 50 }]}
              >
                <Button
                  disabled={isLoadingSendMail}
                  onPress={() => {
                    goToInquiryPaidTeam()
                    setInquiryState({})
                  }}
                >
                  <Text style={styles.buttonFooter}>お問い合わせ</Text>
                </Button>
              </View>
            )}
          </View>
        </View>
      </CustomKeyboardAvoidingView>
    </MainLayout>
  )
})

export const CompletedApplyPaidOptions = {
  ...commonHeaderOptions,
  headerLeft: () => null,
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white3,
  },
  mainContent: {
    paddingHorizontal: 24,
    paddingVertical: 48,
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  spaceTop: {
    marginTop: 24,
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    textAlign: "center",
  },
  contentSpace: {
    marginVertical: 32,
  },
  footerContent: {
    paddingHorizontal: 24,
    paddingVertical: 16,
    borderColor: Colors.white2,
    borderTopWidth: 1,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  buttonFooter: {
    color: Colors.activeStep,
    fontWeight: "600",
    lineHeight: 19.6,
  },
  largeSizeContent: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
})
