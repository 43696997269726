import { memo, useCallback, useMemo, useState } from "react"
import { FlatList, ListRenderItemInfo, StyleSheet, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Colors } from "src/constants/Colors"
import packages from "src/constants/licenses.json"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"

type LicenseInfo = {
  name: string
  license: string
  content: string
}

const typedPackages = packages as LicenseInfo[]

const size = 30

export const LisensesScreen = memo(() => {
  const [index, setIndex] = useState(0)
  const filteredData = useMemo(() => typedPackages.slice(0, (index + 1) * size), [index])
  const insets = useSafeAreaInsets()
  const loadMore = useCallback(() => setIndex((n) => n + 1), [])

  const Item = useCallback(
    ({ item }: ListRenderItemInfo<LicenseInfo>) => (
      <View style={styles.item} key={item.name}>
        <Text style={styles.title}>{item.name}</Text>
        <Text style={styles.license}>{item.license}</Text>
        <Text style={styles.content} numberOfLines={3} ellipsizeMode="tail">
          {item.content}
        </Text>
      </View>
    ),
    []
  )

  return (
    <FlatList
      data={filteredData}
      renderItem={Item}
      style={{ backgroundColor: Colors.white }}
      contentContainerStyle={{
        paddingHorizontal: 16,
        paddingTop: 16,
        paddingBottom: insets.bottom + 16,
      }}
      onEndReached={loadMore}
    />
  )
})

const styles = StyleSheet.create({
  item: {
    paddingVertical: 8,
  },
  itemTail: {
    borderTopWidth: 1,
    borderTopColor: Colors.lightGrey,
  },
  title: {
    fontSize: 16,
    color: Colors.black,
  },
  license: {
    fontSize: 16,
    color: Colors.gray,
  },
  content: {
    fontSize: 12,
    color: Colors.gray,
  },
})

export const licensesScreenOptions: ScreenOptions = {
  ...commonHeaderOptions,
  title: "ライセンス",
}
