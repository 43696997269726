import { selectorFamily } from "recoil"
import { requestDataSelector } from "src/recoil/selectors/auth/requestDataSelector"
import { getUnreadThreadCount } from "src/apis/contact/getUnreadThreadCount"
import { unreadThreadCountRequestIdState } from "src/recoil/atoms/contact/unreadThreadCountRequestIdState"

export const unreadThreadCountSelectorFamily = selectorFamily<number, { contactIds: string[] | string | undefined }>({
  key: "selectors/contact/unreadThreadCountSelectorFamily",
  get:
    ({ contactIds }) =>
    async ({ get }) => {
      get(unreadThreadCountRequestIdState)
      if (contactIds === undefined) return null
      const requestData = get(requestDataSelector)
      if (requestData == null) return 0

      const { accessToken, accountId } = requestData
      if (Array.isArray(contactIds)) {
        return getUnreadThreadCount({ accessToken, accountId, contactIds })
      }

      let result = 0
      await getUnreadThreadCount({ accessToken, accountId, contactIds: [contactIds] }).then((data) => {
        if (data.buckets.length > 0) {
          result = data.buckets[0].doc_count ?? 0
        }
      })
      return result
    },
})
