import { ComponentProps, memo, useMemo } from "react"
import { StyleSheet, Text, View } from "react-native"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { openConfigureSpam } from "src/utils/links/openConfigureSpam"
import { UnorderedList } from "src/components/parts/UnorderedList"
import { validateNumbers } from "src/utils/validate"
import { ValidationErrorMessage } from "src/components/parts/ValidationErrorMessage"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ScrollView } from "react-native-gesture-handler"

type Props = {
  type: "email" | "phoneNumber"
  code: string
  onCodeChange: ComponentProps<typeof CustomTextInput>["onChangeText"]
  actionLabel: string
  isResendDisabled: boolean
  isSending: boolean
  isSendingCode: boolean
  next: () => Promise<void>
  resendAuthCode: () => Promise<void>
  description: JSX.Element
}

export const EnterAuthorizationCode = memo<Props>(
  ({
    type,
    code,
    onCodeChange,
    actionLabel,
    next,
    resendAuthCode,
    isResendDisabled,
    isSending,
    isSendingCode,
    description,
  }) => {
    const errorMessage = useMemo(() => (validateNumbers(code) ? "" : "半角数字で正しい認証コードを入力してください。"), [code])
    const isEditable = useMemo(() => !isSending && !isSendingCode, [isSending, isSendingCode])
    const isDisabled = useMemo(() => errorMessage !== "" || code.length !== 6 || isSending, [errorMessage, code, isSending])
    const isLargeScreen = useCheckPCScreen()
    return (
      <View style={styles.container}>
        <ScrollView style={[isLargeScreen ? {} : { width: "100%" }]}>
          <View style={isLargeScreen ? styles.contentPC : styles.content}>
            <View style={styles.description}>
              <Text style={styles.descriptionText}>{description}</Text>
            </View>
            <View style={isLargeScreen ? { display: "flex", alignItems: "center" } : {}}>
              <View style={[styles.labelContainer, isLargeScreen ? { width: 380 } : {}]}>
                <ItemLabel label="認証コード" RightComponent={<InputLengthCounter text={code} maxLength={6} unit={"桁"} />} />
              </View>
              <View style={[styles.inputContainer, styles.separator, isLargeScreen ? { width: 380 } : {}]}>
                <CustomTextInput
                  value={code}
                  onChangeText={onCodeChange}
                  placeholder="入力してください"
                  maxLength={6}
                  editable={isEditable}
                  onSubmitEditing={next}
                  keyboardType="number-pad"
                  isError={errorMessage !== ""}
                />
                {errorMessage !== "" && <ValidationErrorMessage style={styles.validationErrorMessage} message={errorMessage} />}
              </View>
            </View>
            <View style={[styles.actions, styles.separator, isLargeScreen ? { display: "flex", alignItems: "center" } : {}]}>
              <TextButton
                title={actionLabel}
                onPress={next}
                buttonType={ButtonType.Primary}
                disabled={isDisabled}
                isLoading={isSendingCode}
                style={isLargeScreen ? styles.buttonsPC : {}}
              />
              <TextButton
                title="認証コードを再送"
                onPress={resendAuthCode}
                buttonType={ButtonType.Secondary}
                style={[styles.actionTail, isLargeScreen ? styles.buttonsPC : {}]}
                disabled={isResendDisabled}
                isLoading={isSending}
              />
            </View>
            {type === "email" ? (
              <View style={styles.caveats}>
                <ItemLabel label="メールが届かない方へ" />
                <View style={styles.listContainer}>
                  <UnorderedList items={caveats} />
                </View>
              </View>
            ) : null}
          </View>
        </ScrollView>
      </View>
    )
  }
)

EnterAuthorizationCode.displayName = "EnterAuthorizationCode"

const styles = StyleSheet.create({
  container: {
    display: "flex",
    alignItems: "center",
  },
  content: {
    width: "100%",
  },
  contentPC: {
    width: 600,
    maxWidth: 1020,
    paddingLeft: 30,
    paddingRight: 30,
  },
  description: {
    padding: 24,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  descriptionText: {
    color: Colors.black,
    fontSize: 14,
  },
  labelContainer: {
    paddingHorizontal: 15,
    marginTop: 20,
  },
  inputContainer: {
    paddingTop: 10,
    paddingBottom: 20,
    paddingHorizontal: 15,
  },
  separator: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  validationErrorMessage: {
    marginTop: 5,
  },
  textCommon: {
    color: Colors.greyshBrown,
    fontSize: 11,
    lineHeight: 11,
    textAlign: "center",
  },
  underline: {
    textDecorationLine: "underline",
  },
  actions: {
    paddingVertical: 32,
    paddingHorizontal: 24,
  },
  actionTail: {
    marginTop: 20,
  },
  caveats: {
    paddingVertical: 32,
    paddingHorizontal: 24,
  },
  listContainer: {
    paddingTop: 16,
  },
  link: {
    color: Colors.orange,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
})

const caveats = [
  <Text>
    「@ra9plus.jp」からのメールが受け取れるよう設定をお願いします。設定方法は
    <Text onPress={openConfigureSpam} style={styles.link}>
      こちら
    </Text>
    。
  </Text>,
  <Text>ご入力いただいたメールアドレスが間違っている可能性があります。</Text>,
  <Text>
    迷惑メールフォルダに振り分けられている可能性があります。受信トレイに届かない場合は迷惑メールフォルダをご確認ください。
  </Text>,
]
