import { searchGroupMember } from './../../aws/customAPI';
import { Result } from "src/utils/Result"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import { GroupMemberSortBy, GroupMemberType, OrderBy } from 'src/aws/API';

export type SearchGroupMemberParams = {
  accessToken: string
  teamId: string
  groupId: string
  name?: string | null
  limit?: number
  page: number | 0
}

export type SearchGroupMemberSuccess = {
  members: GroupMemberType[] | []
  total: number
  limit: number
  page: number
}

export type SearchGroupMemberFailure = {
  message: string
}

export const getGroupMembersByName = async (params: SearchGroupMemberParams): Promise<Result<SearchGroupMemberSuccess, SearchGroupMemberFailure>> => {
  return await execApi(
    () => searchGroupMember(params.accessToken, {
      input: {
        teamId: params.teamId,
        groupId: params.groupId,
        name: params.name ?? "",
        page: params.page++,
        sortBy: GroupMemberSortBy.memberSeq,
        orderBy: OrderBy.ASC,
        limit: params.limit ?? 10,
      }, appInfo}), res => {
        return (
          {
            members: res.searchGroupMember.items,
            total: res.searchGroupMember.total,
            limit: res.searchGroupMember.limit,
            page: res.searchGroupMember.page
          }
        )
      }
    )
}