import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { Result } from "src/utils/Result"
import { ContactType } from "src/constants/ContactType"
import { listDraft } from "src/aws/customAPI"
import { MailType } from "src/aws/API"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type GetDraftContactsRequestParams = {
  accessToken: string
  myMemberId: string
}

type GetDraftContactsSuccess = ContactOverviewModel[]

type GetDraftContactsFailure = {
  message: string
}

export const getDraftContacts = async (params: GetDraftContactsRequestParams): Promise<Result<GetDraftContactsSuccess, GetDraftContactsFailure>> => {
  const { accessToken, myMemberId } = params

  return execApi(
    () => listDraft(accessToken, {
      teamMemberId: myMemberId,
      appInfo
    }),
    res => {
      return res.getTeamMember.drafts.items.map(item => ({
        id: item.id,
        contactType: mailTypeMapping[item.mailType],
        isRead: false,  // 使用しない
        title: item.title,
        body: item.body,
        senderId: myMemberId, // 使用しない
        senderName: '', // 使用しない
        isSenderDeleted: false, // 使用しない
        isFavorite: false, // 使用しない
        isAttachmentExists: (item.attachmentFileNames || []).length > 0,
        isUpdate: false, // 使用しない
        updatedDate: new Date(item.updatedAt),
        teamMemberContactId: '' // 使用しない
      }))
    }
  )
}

const mailTypeMapping: {[key in MailType]: ContactType} = {
  [MailType.normal]: ContactType.Normal,
  [MailType.attendance]: ContactType.Attendance,
  [MailType.questionnaire]: ContactType.Survey,
  [MailType.schedule]: ContactType.ScheduleAdjustment,
}
