import { atom } from "recoil"
import { OrganizationMemberOverview } from "src/types/organization/OrganizationMember"

export const listNotJoinMembersRequestIdState = atom<number>({
  key: "atoms/organization/listNotJoinMembersRequestIdState",
  default: 0,
})

export const sendNotJoinMembersState = atom<OrganizationMemberOverview[] | undefined>({
  key: "atoms/organization/sendNotJoinMembersState",
  default: [],
})
