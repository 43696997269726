import { useCallback, useEffect, useMemo } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useFetcher } from "src/hooks/useFetcher"
import { useRefresher } from "src/hooks/useRefresher"
import { draftContactsRequestIdState } from "src/recoil/atoms/contact/draftContactsRequestIdState"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { draftContactsSelector } from "src/recoil/selectors/contact/draftContactsSelector"
import { CustomAlert } from "src/utils/CustomAlert"
import { useResetter } from "./contact/create/useResetter"
import { deleteContactDraft } from "src/apis/contact/deleteContactDraft"
import { wait } from "src/utils/wait"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { useFocusEffect } from "@react-navigation/native"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { useRecoilValue } from "recoil"
import { organizationRefreshState } from "src/recoil/atoms/organization/organizationRefreshPermissionState"
import { organizationDetailRequestIdState } from "src/recoil/atoms/organization/organizationDetailRequestIdState"

export const useDraftData = () => {
  const { value: contacts, isLoading: isLoadingContacts } = useAsyncSelector(draftContactsSelector)
  const refreshContacts = useRefresher(draftContactsRequestIdState)
  const refreshOrganizationDetail = useRefresher(organizationDetailRequestIdState)
  const needRefreshOrganization = useRecoilValue(organizationRefreshState)
  useEffect(() => {
    if (needRefreshOrganization) {
      refreshOrganizationDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefreshOrganization])
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const { value: organization } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))

  const requestDataResult = useRequestData()

  const { fetch: execDeleteContactDraft } = useFetcher(
    useCallback(
      async (draftId: string) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accountId, accessToken } = requestDataResult.content
        if (accountId == null || accessToken == null) {
          return
        }
        const isConfirmed = await CustomAlert.confirm("確認", "選択した下書きを削除しますか？\n削除後は復元できません。")
        if (!isConfirmed) {
          return
        }
        const result = await deleteContactDraft({ accessToken, draftId })
        if (result.isOk) {
          CustomAlert.alert("完了", "下書きを削除しました。")
          await wait(2000)
          refreshContacts()
        } else {
          CustomAlert.alert("エラー", result.error.message)
        }
      },
      [requestDataResult, refreshContacts]
    )
  )

  const resetCreateNewContactScreenData = useResetter()

  useFocusEffect(
    useCallback(() => {
      refreshContacts()
    }, [refreshContacts])
  )

  return {
    contacts,
    isLoadingContacts,
    refreshContacts,
    execDeleteContactDraft,
    resetCreateNewContactScreenData,
    organizationId,
    organization,
    refreshOrganizationDetail,
  }
}
