import { memo, useCallback, useMemo, useState } from "react"
import {
  FlatList,
  Image,
  ListRenderItemInfo,
  Modal,
  Platform,
  ScrollView,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { DoubleButtonFooter } from "src/components/parts/DoubleButtonFooter"
import { CreateOrganizationIcon } from "src/components/parts/icons/CreateOrganizationIcon"
import { JoinIcon } from "src/components/parts/icons/JoinIcon"
import { JoinOrCreateOrganizationActionView } from "src/components/parts/JoinOrCreateOrganizationActionView"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { MyOrganization } from "src/queries/getMyOrganizations"
import { myFullOrganizationsRequestIdState } from "src/recoil/atoms/organization/myOrganizationsRequestIdState"
import { myFullOrganizationsSelector } from "src/recoil/selectors/organization/myOrganizationsSelector"
import { OrganizationManagerStackScreenProps } from "src/types.d"
import { OrganizationMigrateIcon } from "src/components/parts/icons/OrganizationMigrateIcon"
import { OrganizationMigrationDescription } from "src/components/parts/OrganizationMigrationDescription"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { getTempToken } from "src/apis/auth/getTempToken"
import { CustomAlert } from "src/utils/CustomAlert"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { Screens } from "src/constants/Screens"
import { RootTabs } from "src/constants/RootTabs"
import { BadgeIcon } from "src/components/parts/icons/BadgeIcon"
import { hasPreTeamMemberSelector } from "src/recoil/selectors/bottomTab/hasPreTeamMemberSelector"
import { MaxCount } from "src/constants/MaxCount"
import { Analytics } from "src/tags/analytics/Analytics"
import { BannerAd } from "src/tags/ads/BannerAd"
import { myTeamsSelector } from "src/recoil/selectors/team/myTeamsSelector"
import { adLoadState, isLoadDoneAd } from "src/recoil/atoms/ads/adState"
import { useRecoilState, useSetRecoilState } from "recoil"
import { notificationsRequestIdState } from "src/recoil/atoms/home/notificationsRequestIdState"
import { defaultTeam } from "src/constants/defaultTeam"
import { MainLayout } from "src/layouts/MainLayout"
import { HeaderCommon } from "src/components/parts/HeaderCommon"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { useAsyncState } from "src/hooks/useAsyncState"
import { OrganizationListTeamMigrateIcon } from "src/components/parts/icons/OrganizationListTeamMigrateIcon"
import { GoToIcon } from "src/components/parts/icons/GoToIcon"
import { haveOldRa9TeamJoinSelector } from "src/recoil/selectors/team/haveOldRa9TeamJoinSelector"
import { listAllMigratedTeamsRequestIdState } from "src/recoil/atoms/organization/listAllMigratedTeamsRequestIdState"
import { listAllMigratedTeamsSelector } from "src/recoil/selectors/organization/listAllMigratedTeamsSelector"
import { notificationsSelector } from "src/recoil/selectors/home/notificationsSelector"
import { myListTeamRequestIdState } from "src/recoil/atoms/team/myListTeamRequestIdState"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { migration } from "src/constants/links/migration"
import * as WebBrowser from "expo-web-browser"
import { organizationMemberImageRefresherState } from "src/recoil/atoms/organization/organizationMemberImageRefresherState"
import { useIsFocused } from "@react-navigation/native"
import { TeamNoticeType } from "src/aws/customAPI"
export const OrganizationManagerTab = memo<
  OrganizationManagerStackScreenProps<typeof OrganizationManagerScreens.OrganizationManager>
>(({ navigation }) => {
  const isLargeScreen = useCheckPCScreen()
  const refreshAllMigratedTeams = useRefresher(listAllMigratedTeamsRequestIdState)
  const { value: organizations, isLoading: isMyOrganizationsLoading } = useAsyncSelector(myFullOrganizationsSelector)
  const { value: notifications } = useAsyncSelector(notificationsSelector)
  const [selectedOrganizationId, setSelectedOrganizationId] = useAsyncState(selectedMyOrganizationState)
  const setRefreshImage = useSetRecoilState(organizationMemberImageRefresherState)
  const refreshMyOrganizations = useRefresher(myFullOrganizationsRequestIdState)
  const refreshNotifications = useRefresher(notificationsRequestIdState)
  const refreshListTeamGql = useRefresher(myListTeamRequestIdState)
  const isShowTeamListAd = useMemo(() => {
    if (organizations != null) {
      return organizations.some((item) => !item.paidFunctionEnabled || item.showAdsFlg)
    }
    return true
  }, [organizations])
  const refresh = useCallback(() => {
    refreshListTeamGql()
    refreshMyOrganizations()
    refreshNotifications()
    refreshAllMigratedTeams()
  }, [refreshMyOrganizations, refreshNotifications, refreshAllMigratedTeams, refreshListTeamGql])
  const isFocused = useIsFocused()
  const { width } = useWindowDimensions()
  const imageWidth = useMemo(() => (width - 28) * (isLargeScreen ? 0.42 : 1), [isLargeScreen, width])
  const imageHeight = useMemo(() => imageWidth * 0.54, [imageWidth])
  const { value: hasPreTeamMemberByTeam } = useAsyncSelector(hasPreTeamMemberSelector)
  const [adLoadStateList] = useRecoilState(adLoadState)
  const showBadge = useCallback(
    (organizationId: string) => {
      const hasMatchingNotice =
        notifications
          ?.filter((notice) => notice.organizationId === organizationId && notice.type === "matchingMessage")
          .some((hasBadge) => !hasBadge.isRead) ?? false
      const paidFunctionNotice =
        notifications
          ?.filter(
            (notice) =>
              notice.organizationId === organizationId &&
              (notice.type === TeamNoticeType.paymentDeadline ||
                notice.type === TeamNoticeType.transferApplicant ||
                notice.type === TeamNoticeType.startExtendOrderTime ||
                notice.type === TeamNoticeType.middleExtendOrderTime)
          )
          .some((hasBadge) => !hasBadge.isRead) ?? false
      return (hasPreTeamMemberByTeam || {})[organizationId] || hasMatchingNotice || paidFunctionNotice
    },
    [hasPreTeamMemberByTeam, notifications]
  )
  const gotoOrganizationDetail = useCallback(
    (id: string) => {
      if (selectedOrganizationId !== id) {
        setRefreshImage(true)
      }
      if (isLargeScreen) {
        setSelectedOrganizationId(id)
      }
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.OrganizationDetail,
          params: { organizationId: id },
        },
      })
    },
    [navigation, isLargeScreen, setSelectedOrganizationId, setRefreshImage, selectedOrganizationId]
  )

  const RenderItem = useCallback(
    ({ item: { id, title, uriImage }, index }: ListRenderItemInfo<MyOrganization>) => {
      const handlePress = () => {
        gotoOrganizationDetail(id)
      }
      return (
        <View style={isLargeScreen ? { flex: 1 / 3, alignItems: "center" } : styles.webCategory}>
          <ListItem
            id={id}
            title={title}
            uriImage={uriImage ?? undefined}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            handlePress={handlePress}
            showBadge={showBadge(id)}
            isLargeScreen={isLargeScreen}
          />
          {Platform.OS !== "web" && isShowTeamListAd && organizations && index === organizations.length - 1 ? (
            <View style={!isLoadDoneAd("home_bottom", adLoadStateList, 3) && { height: 0 }}>{BannerAd.HomeBottom_3}</View>
          ) : null}
        </View>
      )
    },
    [
      imageWidth,
      imageHeight,
      showBadge,
      organizations,
      adLoadStateList,
      gotoOrganizationDetail,
      isLargeScreen,
      isShowTeamListAd,
    ]
  )

  const gotoJoinOrganization = useCallback(async () => {
    await Analytics.sendJoinRequest()
    if ((organizations?.length || 0) >= MaxCount.Team) {
      CustomAlert.alert(
        "エラー",
        `所属団体数が上限を超えるため、参加できません。\n※1人の方が所属できる団体数は最大${MaxCount.Team}団体です。`
      )
      return
    }
    navigation.navigate("JoinOrganization")
  }, [navigation, organizations])

  const gotoCreateOrganization = useCallback(() => {
    if ((organizations?.length || 0) >= MaxCount.Team) {
      CustomAlert.alert(
        "エラー",
        `所属団体数が上限を超えるため、作成できません。\n※1人の方が所属できる団体数は最大${MaxCount.Team}団体です。`
      )
      return
    }
    navigation.navigate("CreateOrganizationStack")
  }, [navigation, organizations])
  const { value: myTeams } = useAsyncSelector(myTeamsSelector)
  const { value: ListTeamGql } = useAsyncSelector(haveOldRa9TeamJoinSelector)
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const { value: myOrganizations } = useAsyncSelector(listAllMigratedTeamsSelector)
  const allOrganizations = (myOrganizations ?? []).filter((n) => !n.joinFlg)
  const openMigrateOrganization = useCallback(async () => {
    if (accessToken == null) return
    if (organizations && organizations.length >= MaxCount.Team) {
      await CustomAlert.alert(
        "エラー",
        `所属団体数が上限を超えるため、移行できません。\n※「らくらく連絡網＋」で1人の方が所属できる団体数は最大${MaxCount.Team}団体です。`
      )
      return
    }
    const result = await getTempToken({ accessToken })
    if (!result.isOk) {
      CustomAlert.alert("エラー", result.error.message)
      return
    }
    navigation.navigate(Screens.MigrationLogin as any, { type: "team" })
  }, [accessToken, organizations, navigation])

  const openListMigrateOrganization = useCallback(async () => {
    refreshAllMigratedTeams()
    navigation.navigate(Screens.MigrateOrganizations, { joinFlg: true })
  }, [navigation, refreshAllMigratedTeams])

  const openListMigrateOrganizationCanJoin = useCallback(async () => {
    refreshAllMigratedTeams()
    navigation.navigate(Screens.MigrateOrganizations, { joinFlg: false })
  }, [navigation, refreshAllMigratedTeams])

  const [modalVisible, setModalVisible] = useState(false)
  const openModal = useCallback(() => setModalVisible(true), [])
  const closeModal = useCallback(() => setModalVisible(false), [])
  const openMigrationDescription = useCallback(async () => {
    await WebBrowser.openBrowserAsync(migration.description)
  }, [])

  if (organizations == null) {
    return null
  }

  if (organizations.length === 0) {
    return (
      <MainLayout>
        <HeaderCommon title="団体管理" needBackIcon={false} />
        {ListTeamGql?.getAccount?.haveOldRa9TeamNotJoin && allOrganizations.length != 0 && (
          <Button onPress={openListMigrateOrganizationCanJoin} style={styles.headerBar}>
            <Text style={styles.migrateButtonText}>【要確認】未参加の移行団体があります。</Text>
            <GoToIcon />
          </Button>
        )}
        <ScrollView
          contentContainerStyle={styles.container}
          refreshControl={<CustomRefreshControl refreshing={isMyOrganizationsLoading} onRefresh={refresh} />}
        >
          {isLargeScreen ? (
            <View style={styles.itemWrapper}>
              <Text>所属している団体がありません。</Text>
            </View>
          ) : (
            <JoinOrCreateOrganizationActionView
              join={gotoJoinOrganization}
              create={gotoCreateOrganization}
              migrate={openMigrateOrganization}
              teamMigrate={openListMigrateOrganization}
            />
          )}
        </ScrollView>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <HeaderCommon title="団体管理" needBackIcon={false} />
      {ListTeamGql?.getAccount?.haveOldRa9TeamNotJoin && allOrganizations.length != 0 && (
        <Button onPress={openListMigrateOrganizationCanJoin} style={styles.headerBar}>
          <Text style={styles.migrateButtonText}>【要確認】未参加の移行団体があります。</Text>
          <GoToIcon />
        </Button>
      )}
      <View style={styles.container}>
        <View style={styles.main}>
          <FlatList
            data={organizations}
            ListHeaderComponent={
              isLargeScreen ? null : (
                <View style={styles.migrateContainer}>
                  <View style={styles.popUpNoticeText}>
                    <ItemLabel label="団体移行" />
                  </View>
                  <View style={styles.popUpNoticeText}>
                    <Text>らくらく連絡網（旧サービス）の団体を、らくらく連絡網＋に移行できます。</Text>
                  </View>
                  <View style={styles.popUpNoticeText}>
                    <View style={[{ display: "flex", flexDirection: "row", paddingBottom: 16 }]}>
                      <View style={{ flexDirection: "row" }}>
                        <Text style={[styles.contentLink, { paddingRight: 3 }]}>※</Text>
                        <Text style={[styles.contentLink]}>団体移行のガイドページは </Text>
                      </View>
                      <Text onPress={openMigrationDescription}>
                        <Text style={styles.linkText}>こちら</Text>
                        <Text>。</Text>
                      </Text>
                    </View>
                  </View>

                  <View style={styles.doubleButtonHeader}>
                    <View style={styles.buttonContainer}>
                      <Button style={styles.migrateButtonContainer} onPress={openMigrateOrganization}>
                        <View style={styles.centerRow}>
                          <OrganizationMigrateIcon />
                          <View>
                            <Text style={[styles.migrateButtonText, { marginTop: 10 }]}>団体情報を移行</Text>
                          </View>
                        </View>
                      </Button>
                    </View>
                    <View style={styles.buttonContainer}>
                      <Button style={styles.listTeamMigrateButtonContainer} onPress={openListMigrateOrganization}>
                        <View style={styles.centerRow}>
                          <OrganizationListTeamMigrateIcon />
                          <View>
                            <Text style={[styles.migrateButtonText, { marginTop: 10 }]}>移行団体を管理</Text>
                          </View>
                        </View>
                      </Button>
                    </View>
                  </View>
                </View>
              )
            }
            numColumns={isLargeScreen ? 3 : 1}
            style={isLargeScreen ? styles.webCategories : {}}
            renderItem={RenderItem}
            keyExtractor={(item, index) => item.id + index.toString()}
            refreshControl={<CustomRefreshControl refreshing={isMyOrganizationsLoading && isFocused} onRefresh={refresh} />}
          />
        </View>
        {!isLargeScreen && (
          <DoubleButtonFooter
            leftButtonTitle="団体に参加"
            rightButtonTitle="団体を作成"
            LeftButtonIcon={<JoinIcon size={24} />}
            RightButtonIcon={<CreateOrganizationIcon size={33} />}
            onLeftButtonPress={gotoJoinOrganization}
            onRightButtonPress={gotoCreateOrganization}
            isLargeScreen={isLargeScreen}
          />
        )}
        <Modal visible={modalVisible} onRequestClose={closeModal} transparent>
          <View style={styles.modalWrapper}>
            <OrganizationMigrationDescription onClose={closeModal} />
          </View>
        </Modal>
      </View>
    </MainLayout>
  )
})

type ListItemProps = {
  id: string
  title: string
  uriImage?: string
  imageWidth: number
  imageHeight: number
  handlePress: () => void
  showBadge: boolean
  isLargeScreen: boolean
}

const ListItem = memo<ListItemProps>(({ title, uriImage, handlePress, imageWidth, imageHeight, showBadge, isLargeScreen }) => {
  return (
    <View style={isLargeScreen ? { padding: 12, maxWidth: imageWidth / 3 + 50 } : styles.itemContainer}>
      <View style={isLargeScreen ? styles.cardCategory : {}}>
        <Button onPress={handlePress} style={isLargeScreen ? {} : styles.imageButton}>
          <Image
            source={uriImage ? { uri: uriImage } : defaultTeam}
            style={[
              styles.itemImage,
              { width: isLargeScreen ? imageWidth / 3 : imageWidth, height: isLargeScreen ? imageHeight / 3 : imageHeight },
            ]}
            resizeMode="cover"
          />
        </Button>
        <View style={[styles.itemLabel, isLargeScreen ? { height: 45 } : {}]}>
          {showBadge ? <BadgeIcon size={"small"} /> : null}
          <Button onPress={handlePress}>
            <Text style={[styles.label, isLargeScreen ? { width: imageWidth / 3 } : {}]}>{title}</Text>
          </Button>
        </View>
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  main: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  itemContainer: {
    paddingVertical: 6,
    alignItems: "center",
  },
  itemImage: {
    borderRadius: 8,
  },
  itemLabel: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: 14,
    fontWeight: "bold",
    padding: 12,
    textAlign: "center",
  },
  imageButton: {
    shadowColor: "#e0d6ca",
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 17,
    elevation: 3,
    borderRadius: 8,
  },
  migrateContainer: {
    paddingHorizontal: 3,
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
    backgroundColor: Colors.white3,
  },
  migrateButtonContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 17,
    backgroundColor: Colors.orange,
    borderRadius: 10,
    width: "100%",
    height: "100%",
  },
  listTeamMigrateButtonContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 17,
    backgroundColor: Colors.leaf,
    borderRadius: 10,
    width: "100%",
    height: "100%",
  },
  migrateButtonText: {
    color: Colors.white3,
    fontSize: 15,
    fontWeight: "700",
    lineHeight: 20,
    textAlign: "center",
  },
  aboutMigrationContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 16,
  },
  aboutMigrationText: {
    color: Colors.greyshBrown,
    textDecorationLine: "underline",
    marginLeft: 8,
  },
  popUpNoticeContainer: {
    flexDirection: "row",
    marginTop: 24,
    paddingHorizontal: 15,
  },
  popUpNoticeText: {
    paddingHorizontal: 10,
    paddingTop: 10,
  },
  modalWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.37)",
    padding: 22,
  },
  webCategories: {
    paddingVertical: 24,
  },
  webCategory: {
    flex: 1,
    padding: 6,
    paddingTop: 16,
    paddingBottom: 0,
  },
  itemWrapper: {
    marginTop: 40,
    alignItems: "center",
  },
  cardCategory: {
    alignItems: "center",
    paddingTop: 10,
    paddingBottom: 15,
    paddingHorizontal: 10,
    display: "flex",
    flexDirection: "column",
    backgroundColor: Colors.white3,
    borderRadius: 8,
    shadowColor: "#e0d6ca",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 17,
    elevation: 5,
  },
  doubleButtonHeader: {
    flexDirection: "row",
    width: "100%",
  },
  buttonContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: "row",
    width: "43%",
  },
  centerRow: {
    alignItems: "center",
  },
  headerBar: {
    height: 40,
    backgroundColor: Colors.red,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  contentLink: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "left",
  },
  linkText: {
    color: Colors.orange,
    textDecorationLine: "underline",
  },
})
