import { Platform, useWindowDimensions } from "react-native"
import { RootTabs } from "src/constants/RootTabs"
import { Screens } from "src/constants/Screens"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { SettingsTab } from "src/screens/bottomTabs/SettingsTab"

import { createStackNavigator } from "@react-navigation/stack"
import { AccountInfo, options as accountInfoOptions } from "src/screens/settings/AccountInfo"
import { EditAccount, editAccountOptions } from "src/screens/settings/EditAccount"
import { Information, options as informationOptions } from "src/screens/settings/Information"
import { InformationDetail, informationDetailOptions } from "src/screens/settings/InformationDetail"
import { NotificationSettings, notificationSettingsOptions } from "src/screens/settings/NotificationSettings"
import { SettingStackParamList } from "src/types.d"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

const SettingStack = createStackNavigator<SettingStackParamList>()
const SettingNavigator = () => {
  const isPCScreen = useCheckPCScreen()
  return (
    <SettingStack.Navigator>
      <SettingStack.Screen
        name={RootTabs.SettingsTab}
        component={SettingsTab}
        options={{
          ...commonHeaderOptions,
          title: Platform.OS === "web" ? "設定" : "",
          headerShown: !isPCScreen,
        }}
      />
      <SettingStack.Screen
        name={Screens.Information}
        component={Information}
        options={{ ...informationOptions, headerShown: !isPCScreen }}
      />
      <SettingStack.Screen
        name={Screens.InformationDetail}
        component={InformationDetail}
        options={{ ...informationDetailOptions, headerShown: !isPCScreen }}
      />
      <SettingStack.Screen name={Screens.AccountInfo} component={AccountInfo} options={accountInfoOptions} />
      <SettingStack.Screen name={Screens.EditAccount} component={EditAccount} options={editAccountOptions} />
      <SettingStack.Screen
        name={Screens.NotificationSettings}
        component={NotificationSettings}
        options={{
          ...notificationSettingsOptions,
          headerShown: !isPCScreen,
        }}
      />
    </SettingStack.Navigator>
  )
}

export default SettingNavigator
