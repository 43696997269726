import { getUnreadMatchingMessageCount as _getUnreadMatchingMessageCount, } from "../../aws/customAPI";

type Params = {
  accessToken: string
  applicationId?: string | string[]
  myTeamId: string
}

export const getUnreadMatchingMessageCount = async ({ accessToken, applicationId, myTeamId}: Params) => {
  const res = await _getUnreadMatchingMessageCount(accessToken, {
    applicationId,
    myTeamId,
  }).catch(() => undefined)
  return {
    count:res?.data?.unreadMessageCount || 0,
    offerMessage:res?.data?.offerMessage || false,
    applicationMessage:res?.data?.applicationMessage || false
  }
}