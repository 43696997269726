import { memo, useMemo } from "react"
import { Path, Svg } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  color?: string
  filled?: boolean
}

export const SettingsIcon = memo<Props>(({ color, filled }) => {
  const fill = useMemo(() => color ?? Colors.white, [color])

  return filled ? (
    <Svg width="18.37" height="18.365" viewBox="0 0 18.37 18.365">
      <Path
        d="M307.371 419.443a.478.478 0 0 0-.366-.139l-1.817.068a5.893 5.893 0 0 0-1.133-2.585l1.375-1.273a.507.507 0 0 0 .16-.358.5.5 0 0 0-.147-.363l-1.228-1.226a.5.5 0 0 0-.721.014l-1.179 1.368a5.876 5.876 0 0 0-2.682-1.061l.07-1.879a.5.5 0 0 0-.5-.519h-1.736a.5.5 0 0 0-.5.519l.131 1.8a5.913 5.913 0 0 0-2.647 1.145l-1.275-1.377a.5.5 0 0 0-.358-.16.524.524 0 0 0-.363.146l-1.226 1.226a.507.507 0 0 0-.147.364.5.5 0 0 0 .161.357l1.368 1.178a5.911 5.911 0 0 0-1.061 2.683l-1.879-.07a.451.451 0 0 0-.365.139.5.5 0 0 0-.154.36v1.736a.5.5 0 0 0 .154.36.515.515 0 0 0 .365.14l1.8-.131a5.92 5.92 0 0 0 1.146 2.647l-1.377 1.275a.5.5 0 0 0-.014.721l1.226 1.225a.539.539 0 0 0 .363.147.5.5 0 0 0 .357-.16l1.18-1.367a5.9 5.9 0 0 0 2.683 1.061l-.071 1.879a.5.5 0 0 0 .5.518h1.736a.5.5 0 0 0 .5-.518l-.132-1.8a5.922 5.922 0 0 0 2.648-1.145l1.273 1.377a.507.507 0 0 0 .358.16.517.517 0 0 0 .363-.147l1.225-1.225a.5.5 0 0 0 .147-.364.507.507 0 0 0-.16-.357l-1.366-1.179a5.918 5.918 0 0 0 1.06-2.683l1.879.071a.506.506 0 0 0 .365-.139.5.5 0 0 0 .154-.359v-1.737a.5.5 0 0 0-.143-.363zm-5.916 1.228a3.116 3.116 0 1 1-3.116-3.115 3.124 3.124 0 0 1 3.116 3.115z"
        transform="translate(-289.153 -411.49)"
        fill={fill}
      />
    </Svg>
  ) : (
    <Svg width="19.07" height="19.07" viewBox="0 0 19.07 19.07">
      <Path
        d="m35.113 24.286-1.957.074a7.156 7.156 0 0 0-.914-2.205l1.437-1.332a.429.429 0 0 0 .137-.3.417.417 0 0 0-.126-.31l-1.853-1.853a.41.41 0 0 0-.31-.126.429.429 0 0 0-.3.137L29.891 19.8a7.183 7.183 0 0 0-2.205-.914l.074-1.957a.429.429 0 0 0-.427-.445h-2.62a.427.427 0 0 0-.307.131.438.438 0 0 0-.12.313l.074 1.957a7.156 7.156 0 0 0-2.205.914l-1.332-1.437a.429.429 0 0 0-.3-.137.4.4 0 0 0-.31.126l-1.853 1.853a.426.426 0 0 0 .011.615l1.429 1.336a7.218 7.218 0 0 0-.914 2.205l-1.957-.074a.439.439 0 0 0-.313.12.431.431 0 0 0-.131.307v2.621a.427.427 0 0 0 .131.307.438.438 0 0 0 .313.12l1.957-.074a7.156 7.156 0 0 0 .914 2.205l-1.437 1.332a.429.429 0 0 0-.137.3.417.417 0 0 0 .126.31l1.853 1.853a.426.426 0 0 0 .615-.011l1.332-1.437a7.183 7.183 0 0 0 2.205.914l-.074 1.957a.429.429 0 0 0 .427.445h2.621a.427.427 0 0 0 .307-.131.438.438 0 0 0 .12-.313l-.074-1.957a7.156 7.156 0 0 0 2.205-.914l1.332 1.437a.429.429 0 0 0 .3.137.414.414 0 0 0 .31-.126l1.853-1.853a.426.426 0 0 0-.011-.615l-1.437-1.332a7.218 7.218 0 0 0 .914-2.205l1.957.074a.439.439 0 0 0 .313-.12.431.431 0 0 0 .131-.307v-2.617a.427.427 0 0 0-.131-.307.45.45 0 0 0-.307-.12zm-.41 2.607-1.879-.071a.428.428 0 0 0-.436.352 6.37 6.37 0 0 1-1.053 2.544.427.427 0 0 0 .06.558l1.377 1.275-1.226 1.226-1.273-1.377a.427.427 0 0 0-.558-.06 6.4 6.4 0 0 1-2.544 1.053.426.426 0 0 0-.352.436l.071 1.879h-1.737l.071-1.879a.426.426 0 0 0-.352-.436 6.37 6.37 0 0 1-2.544-1.053.427.427 0 0 0-.558.06l-1.275 1.377-1.226-1.226 1.377-1.275a.427.427 0 0 0 .06-.558 6.4 6.4 0 0 1-1.053-2.544.43.43 0 0 0-.436-.352l-1.879.071v-1.736l1.879.071a.431.431 0 0 0 .436-.352 6.37 6.37 0 0 1 1.053-2.544.427.427 0 0 0-.06-.558L19.268 20.5l1.226-1.226 1.275 1.377a.427.427 0 0 0 .558.06 6.4 6.4 0 0 1 2.544-1.053.426.426 0 0 0 .352-.436l-.071-1.879h1.736l-.071 1.879a.426.426 0 0 0 .352.436 6.37 6.37 0 0 1 2.544 1.053.427.427 0 0 0 .558-.06l1.275-1.377 1.228 1.226-1.374 1.273a.427.427 0 0 0-.06.558 6.4 6.4 0 0 1 1.053 2.544.43.43 0 0 0 .436.352l1.879-.071z"
        transform="translate(-16.488 -16.489)"
        fill={fill}
      />
      <Path
        d="M40.059 35.5a4.559 4.559 0 1 0 4.559 4.559 4.562 4.562 0 0 0-4.559-4.559zm0 8.175a3.616 3.616 0 1 1 3.616-3.616 3.62 3.62 0 0 1-3.616 3.616z"
        transform="translate(-30.523 -30.523)"
        fill={fill}
      />
    </Svg>
  )
})
