import { useCallback, useEffect, useRef } from "react"
import { AppState, Platform } from "react-native"
import * as Notifications from "expo-notifications"

export const useResetAppBadgeIconEffect = () => {
  const appState = useRef(AppState.currentState)
  const resetBadgeIcon = useCallback(async () => {
    if (Platform.OS === "ios") {
      // https://docs.expo.dev/versions/latest/sdk/notifications/#requestpermissionsasyncrequest-notificationpermissionsrequest-promisenotificationpermissionsstatus
      const { ios } = await Notifications.getPermissionsAsync()
      if (!ios?.allowsBadge) {
        const { ios } = await Notifications.requestPermissionsAsync({
          ios: {
            allowBadge: true,
            allowAlert: true,
            allowSound: true,
          },
        })
        if (!ios?.allowsBadge) return
      }
    }
    // https://docs.expo.dev/versions/latest/sdk/notifications/#setbadgecountasyncbadgecount-number-options-setbadgecountoptions-promiseboolean
    await Notifications.setBadgeCountAsync(0)
  }, [])

  useEffect(() => {
    if (Platform.OS === "web") return

    // https://reactnative.dev/docs/appstate
    const subscription = AppState.addEventListener("change", (nextAppState) => {
      if (appState.current.match(/inactive|background/) && nextAppState === "active") {
        resetBadgeIcon()
      }

      appState.current = nextAppState
    })

    return () => {
      subscription.remove()
    }
  }, [resetBadgeIcon])
}
