import { Result } from "src/utils/Result"
import { signUp } from "src/aws/customAPI"
import { ApiError, execApi } from "src/apis/execApi"
import { AppInfo } from "src/types/RequestData"
import { appInfo } from "src/constants/AppInfo"


type SignupParams = {
  email: string
  appInfo?: AppInfo
}

type SignupSuccessResponse = {
  session: string
}

export const signup = (_params: SignupParams): Promise<Result<SignupSuccessResponse, ApiError>> => {
  return execApi(
    () => signUp({
      input: {
        email: _params.email,
      },
      appInfo
    }),
    res => {
      // レスポンスの型上Sessionがoptionalとなっているが、成功ケースのみこちらに入るため、Sessionがundefinedになることはない
      const session = res.signUp.data?.Session || ''
      return { session }
    },
    {
      identifiers: ["signup", "signUp"],
      defaultErrorMessage: "ログインに失敗しました。"
    }
  )
}
