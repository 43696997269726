import { FC, PropsWithChildren, useCallback, useMemo, useState } from "react"
import { Image, ImageStyle, Linking, Modal, StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"
import { Button } from "src/components/parts/buttons/Button"
import { CreateOrganizationIcon } from "src/components/parts/icons/CreateOrganizationIcon"
import { JoinIcon } from "src/components/parts/icons/JoinIcon"
import { useNavigation } from "@react-navigation/native"
import { Screens } from "src/constants/Screens"
import { OrganizationMigrateIcon } from "src/components/parts/icons/OrganizationMigrateIcon"
import { OrganizationMigrationDescription } from "src/components/parts/OrganizationMigrationDescription"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { myTeamsSelector } from "src/recoil/selectors/team/myTeamsSelector"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { CustomAlert } from "src/utils/CustomAlert"
import { getTempToken } from "src/apis/auth/getTempToken"
import { Analytics } from "src/tags/analytics/Analytics"
import { myFullOrganizationsSelector } from "src/recoil/selectors/organization/myOrganizationsSelector"
import { MaxCount } from "src/constants/MaxCount"
import { OrganizationListTeamMigrateIcon } from "src/components/parts/icons/OrganizationListTeamMigrateIcon"
import { useRefresher } from "src/hooks/useRefresher"
import { listAllMigratedTeamsRequestIdState } from "src/recoil/atoms/organization/listAllMigratedTeamsRequestIdState"
import { migration } from "src/constants/links/migration"
import * as WebBrowser from "expo-web-browser"
import { ItemLabel } from "src/components/parts/ItemLabel"

export const MainLayout: FC<PropsWithChildren> = ({ children }) => {
  const isPCScreen = useCheckPCScreen()
  const navigation = useNavigation()
  const [modalVisible, setModalVisible] = useState(false)
  const openMigrationDescription = useCallback(async () => {
    await WebBrowser.openBrowserAsync(migration.description)
  }, [])
  const { value: myTeams } = useAsyncSelector(myTeamsSelector)
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const refreshAllMigratedTeams = useRefresher(listAllMigratedTeamsRequestIdState)
  const { value: organizations, isLoading: isMyOrganizationsLoading } = useAsyncSelector(myFullOrganizationsSelector)

  const gotoJoinOrganization = useCallback(async () => {
    await Analytics.sendJoinRequest()
    if ((organizations?.length || 0) >= MaxCount.Team) {
      CustomAlert.alert(
        "エラー",
        `所属団体数が上限を超えるため、参加できません。\n※1人の方が所属できる団体数は最大${MaxCount.Team}団体です。`
      )
      return
    }
    navigation.navigate(Screens.JoinOrganization)
  }, [navigation, organizations])

  const gotoCreateOrganization = useCallback(() => {
    if ((organizations?.length || 0) >= MaxCount.Team) {
      CustomAlert.alert(
        "エラー",
        `所属団体数が上限を超えるため、作成できません。\n※1人の方が所属できる団体数は最大${MaxCount.Team}団体です。`
      )
      return
    }
    navigation.navigate(Screens.CreateOrganizationStack)
  }, [navigation, organizations])
  const openMigrateOrganization = useCallback(async () => {
    if (accessToken == null) return
    if (organizations && organizations.length >= MaxCount.Team) {
      await CustomAlert.alert(
        "エラー",
        `所属団体数が上限を超えるため、移行できません。\n※「らくらく連絡網＋」で1人の方が所属できる団体数は最大${MaxCount.Team}団体です。`
      )
      return
    }
    const result = await getTempToken({ accessToken })
    if (!result.isOk) {
      CustomAlert.alert("エラー", result.error.message)
      return
    }
    navigation.navigate(Screens.MigrationLogin as any, { type: "team" })
  }, [accessToken, organizations, navigation])

  const openListMigrateOrganization = useCallback(async () => {
    refreshAllMigratedTeams()
    navigation.navigate(Screens.MigrateOrganizations, { joinFlg: true })
  }, [navigation, refreshAllMigratedTeams])

  if (isPCScreen) {
    return (
      <View style={styles.container}>
        <View style={styles.mainContent}>{children}</View>
        <View style={styles.rightSideBar}>
          <Button style={[styles.button, { backgroundColor: Colors.leaf }]} onPress={gotoJoinOrganization}>
            <JoinIcon size={24} />
            <Text style={styles.buttonLabel}>団体に参加</Text>
          </Button>
          <Button style={[styles.button, { backgroundColor: Colors.orange }]} onPress={gotoCreateOrganization}>
            <CreateOrganizationIcon size={33} />
            <Text style={styles.buttonLabel}>団体を作成</Text>
          </Button>
          <View style={styles.migrateContainer}>
            <View style={styles.popUpNoticeText}>
              <ItemLabel label="団体移行" />
            </View>
            <View style={styles.popUpNoticeText}>
              <Text>らくらく連絡網（旧サービス）の団体を、らくらく連絡網＋に移行できます。</Text>
            </View>
            <View style={{ paddingLeft: 16, paddingTop: 10 }}>
              <View style={[{ display: "flex", flexDirection: "row", paddingBottom: 16 }]}>
                <View style={{ flexDirection: "row" }}>
                  <Text style={[styles.contentLink, { paddingRight: 3 }]}>※</Text>
                  <Text style={[styles.contentLink]}>団体移行のガイドページは </Text>
                </View>
                <Text onPress={openMigrationDescription}>
                  <Text style={styles.linkText}>こちら</Text>
                  <Text>。</Text>
                </Text>
              </View>
            </View>
            <View style={{ paddingHorizontal: 16, paddingTop: 8 }}>
              <Button style={styles.migrateButtonContainer} onPress={openMigrateOrganization}>
                <OrganizationMigrateIcon />
                <View>
                  <Text style={styles.buttonLabel}>団体情報を移行</Text>
                </View>
              </Button>
            </View>
            <View style={styles.popUpNotice}>
              <Button style={styles.listTeamMigrateButtonContainer} onPress={openListMigrateOrganization}>
                <OrganizationListTeamMigrateIcon />
                <View>
                  <Text style={[styles.migrateButtonText, { marginTop: 10 }]}>移行団体を管理</Text>
                  <Text style={styles.migrateButtonText}></Text>
                </View>
              </Button>
            </View>
          </View>
          <View>
            <Button style={styles.guideButton} onPress={() => Linking.openURL("https://www.ra9plus.jp/guide")}>
              <Image style={styles.svg as ImageStyle} source={require("src/assets/images/userGuide.svg")} />
            </Button>
          </View>
          <View style={styles.migrationHelpContainer}>
            <Modal visible={modalVisible} onRequestClose={() => setModalVisible(false)} transparent>
              <View style={styles.modalWrapper}>
                <OrganizationMigrationDescription onClose={() => setModalVisible(false)} />
              </View>
            </Modal>
          </View>
        </View>
      </View>
    )
  } else {
    return <>{children}</>
  }
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "rgb(250, 248, 245)",
  },
  mainContent: {
    minWidth: 600,
    // @ts-ignore
    width: "calc(100% - 300px)",
    // @ts-ignore
    height: "100vh",
    flexDirection: "column",
  },
  rightSideBar: {
    width: 300,
    padding: 16,
    flexDirection: "column",
    gap: 10,
    backgroundColor: Colors.sidebarBg,

    // @ts-ignore
    minHeight: "100vh",
  },
  button: {
    flexDirection: "row",
    height: 44,
    justifyContent: "center",
    gap: 12,
    alignItems: "center",
    borderRadius: 6,
  },
  buttonLabel: {
    color: Colors.white,
    fontSize: 16,
    fontWeight: "bold",
  },
  migrateButtonContainer: {
    flexDirection: "column",
    alignItems: "center",
    paddingVertical: 10,
    gap: 12,
    backgroundColor: Colors.orange,
    borderRadius: 10,
  },
  migrationButtonLabel: {
    color: Colors.white,
    fontSize: 13,
    fontWeight: "bold",
  },
  aboutMigrationContainer: {
    flexDirection: "row",
    paddingVertical: 4,
    marginBottom: 12,
    alignItems: "center",
  },
  aboutMigrationText: {
    color: Colors.greyshBrown,
    textDecorationLine: "underline",
    marginLeft: 8,
  },
  svg: {
    width: 268,
    height: 90,
  },
  guideButton: {
    height: 60,
  },
  modalWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.37)",
    padding: 22,
  },
  migrationHelpContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  listTeamMigrateButtonContainer: {
    alignItems: "center",
    paddingVertical: 10,
    paddingHorizontal: 17,
    backgroundColor: Colors.leaf,
    borderRadius: 10,
    width: "100%",
  },
  migrateButtonText: {
    color: Colors.white3,
    fontSize: 15,
    fontWeight: "700",
    lineHeight: 20,
    textAlign: "center",
  },
  contentLink: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "left",
  },
  linkText: {
    color: Colors.orange,
    textDecorationLine: "underline",
  },
  popUpNoticeText: {
    paddingHorizontal: 16,
    paddingTop: 10,
  },
  popUpNotice: {
    paddingHorizontal: 16,
    paddingVertical: 16,
  },
  doubleButtonHeader: {
    flexDirection: "row",
    width: "100%",
  },
  buttonContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: "row",
    width: "43%",
  },
  centerRow: {
    alignItems: "center",
  },
  migrateContainer: {
    borderRadius: 6,
    backgroundColor: Colors.white3,
  },
})
