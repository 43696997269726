import { Result } from "src/utils/Result"
import { deleteTeamMember } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type DeleteMemberParams = {
  accessToken: string
  organizationMemberId: string
}

type DeleteMemberSuccess = unknown

type DeleteMemberFailure = {
  message: string
}

export const deleteMember = async (params: DeleteMemberParams): Promise<Result<DeleteMemberSuccess, DeleteMemberFailure>> => {
  const {accessToken, organizationMemberId} = params

  return execApi(
    () => deleteTeamMember(accessToken, {
      input: {
        id: organizationMemberId
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["deleteMember", "deleteTeamMember"],
      defaultErrorMessage: "団体からの除外に失敗しました。"
    }
  )
}
