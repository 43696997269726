import { Result } from "../../utils/Result";
import { GqlError, migrateMail } from "../../aws/customAPI";

type Params = {
  accessToken: string;
  teamMemberId: string;
  oldRa9Ra9AccessToken: string;
  oldRa9Ra9UserId: number;
  oldRa9Ra9TeamId: number;
  oldRa9Ra9MemberId: number;
}

type MigrationMailSuccess = unknown

type MigrationMailFailure = {
  message: string;
}

export const migrationMail = async (
  {
    accessToken,
    teamMemberId,
    oldRa9Ra9AccessToken,
    oldRa9Ra9UserId,
    oldRa9Ra9MemberId,
    oldRa9Ra9TeamId
  }: Params): Promise<Result<MigrationMailSuccess, MigrationMailFailure>> => {
  return migrateMail(accessToken, {
    input: {
      teamMemberId: teamMemberId,
      oldRa9Ra9AccessToken: oldRa9Ra9AccessToken,
      oldRa9Ra9UserId: oldRa9Ra9UserId,
      oldRa9Ra9TeamId: oldRa9Ra9TeamId,
      oldRa9Ra9MemberId: oldRa9Ra9MemberId
    }
  }).then(res => {
    return (res.data?.migrateMail.id) ?
      Result.Ok(undefined) :
      Result.Error<MigrationMailFailure>({ message: "連絡の移行に失敗しました。" });
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "連絡の移行に失敗しました。";
    return Result.Error<MigrationMailFailure>({
      message: message
    });
  });
};