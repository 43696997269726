import { Fragment, memo, useCallback } from "react"
import { StyleSheet, Text, View } from "react-native"
import { ContactSurveyAnswerStackScreenProps } from "src/types.d"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { Colors } from "src/constants/Colors"
import { Button } from "src/components/parts/buttons/Button"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { ContactSurveyAnswerScreens } from "src/constants/ContactSurveyAnswerScreens"
import { useContactSurveyAnswerOverviewData } from "src/recoil/hooks/screens/useContactSurveyAnswerOverviewData"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"

export type ContactSurveyAnswerOverviewParams = {
  contactId: string
  type: "Inbox" | "Outbox"
}

export const ContactSurveyAnswerOverview = memo<
  ContactSurveyAnswerStackScreenProps<typeof ContactSurveyAnswerScreens.ContactSurveyAnswerOverview>
>(
  ({
    route: {
      params: { contactId, type },
    },
    navigation,
  }) => {
    const { surveyAnswerOverviews, isLoading } = useContactSurveyAnswerOverviewData(contactId)
    const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
    const isLargeScreen = useCheckPCScreen()
    const { allowViewAnswerMemberList } = useCheckAllowAccountPermission({
      contactId,
      viewMode: type,
      organizationId: selectedOrganization?.id,
    })

    const goToContactAttendanceAnswerDetail = useCallback(
      (surveyId: string, choiceId: string) => {
        if (allowViewAnswerMemberList) {
          navigation.navigate(ContactSurveyAnswerScreens.ContactSurveyAnswerDetail, {
            contactId,
            surveyId,
            choiceId,
          })
        }
      },
      [navigation, contactId, allowViewAnswerMemberList]
    )

    if (isLoading) {
      return (
        <View style={styles.loadingIndicator}>
          <LoadingIndicator />
        </View>
      )
    }

    if (surveyAnswerOverviews == null) {
      return null
    }

    return (
      <CustomKeyboardAvoidingView style={isLargeScreen ? styles.containerLarge : styles.container}>
        <CustomScrollView>
          {surveyAnswerOverviews.map((v, i) => (
            <View style={isLargeScreen ? styles.listContainerLarge : styles.listContainer} key={v.survey.id}>
              <View style={styles.questionContainer}>
                <Text style={styles.questionSubTitle}>質問{i + 1}</Text>
                <Text style={styles.questionTitle}>{v.survey.question}</Text>
              </View>
              {v.answers.map((ans, index) => (
                <Fragment key={index}>
                  <View style={styles.separator} />
                  <Button
                    style={styles.rowContainer}
                    onPress={() => goToContactAttendanceAnswerDetail(v.survey.id, v.survey.choices[index].id)}
                  >
                    <Text style={styles.rowText}>{v.survey.choices[index].value}</Text>
                    <View style={styles.resultContainer}>
                      <Text style={styles.resultText}>{ans.count}</Text>
                      <Text style={styles.resultPercentText}>{ans.percent}%</Text>
                    </View>
                  </Button>
                </Fragment>
              ))}
            </View>
          ))}
        </CustomScrollView>
      </CustomKeyboardAvoidingView>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    paddingBottom: 20,
    paddingHorizontal: 15,
  },
  containerLarge: {
    backgroundColor: Colors.white3,
    paddingBottom: 20,
    paddingHorizontal: 15,
  },
  listContainer: {
    borderRadius: 10,
    backgroundColor: Colors.white3,
    marginTop: 20,
  },
  listContainerLarge: {
    borderRadius: 10,
    backgroundColor: Colors.white3,
    marginTop: 20,
    width: "60%",
    maxWidth: 1020,
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  questionContainer: {
    paddingVertical: 20,
    paddingHorizontal: 23,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  questionSubTitle: {
    fontSize: 13,
    lineHeight: 20,
    color: Colors.cement,
  },
  questionTitle: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 20,
    color: Colors.orange,
    maxWidth: "85%",
  },
  rowContainer: {
    paddingVertical: 13,
    paddingHorizontal: 23,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowText: {
    fontSize: 15,
    fontWeight: "bold",
    lineHeight: 35,
    color: Colors.greyshBrown,
    width: "75%",
  },
  resultContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  resultText: {
    fontSize: 27,
    fontWeight: "bold",
    lineHeight: 35,
    color: Colors.leaf,
  },
  resultPercentText: {
    fontSize: 13,
    lineHeight: 20,
    color: Colors.cement,
    marginLeft: 15,
  },
  separator: {
    marginLeft: 23,
    height: 1,
    backgroundColor: Colors.white2,
  },
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
})
