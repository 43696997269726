import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { FlatList, Image, ListRenderItemInfo, Platform, StyleSheet, Text, useWindowDimensions, View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { RootStackScreenProps } from "src/types.d"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { Screens } from "src/constants/Screens"
import { RootTabs } from "src/constants/RootTabs"
import { BadgeIcon } from "src/components/parts/icons/BadgeIcon"
import { hasPreTeamMemberSelector } from "src/recoil/selectors/bottomTab/hasPreTeamMemberSelector"
import { defaultTeam } from "src/constants/defaultTeam"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { listAllMigratedTeamsSelector } from "src/recoil/selectors/organization/listAllMigratedTeamsSelector"
import { MyMigratedTeam } from "src/queries/getListAllMigratedTeams"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { listAllMigratedTeamsRequestIdState } from "src/recoil/atoms/organization/listAllMigratedTeamsRequestIdState"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { commonHeaderOptionsOnNestedScreen } from "src/constants/options/commonHeaderOptions"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { PC_BREAKPOINT } from "src/constants/Screen"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { useAsyncState } from "src/hooks/useAsyncState"
import { EditMigrateOrganizationScreens } from "src/constants/EditMigrateOrganizationScreens"
import { organizationMembersRequestIdState } from "src/recoil/atoms/organization/organizationMembersRequestIdState"
import { listNotJoinMembersRequestIdState } from "src/recoil/atoms/organization/listNotJoinMembersRequestIdState"
import { JoinIcon } from "src/components/parts/icons/JoinIcon"
import { OrganizationListTeamMigrateIcon } from "src/components/parts/icons/OrganizationListTeamMigrateIcon"
import { OrganizationIcon } from "src/components/parts/icons/bottomTab/OrganizationIcon"
import { TeamCustomQuestion } from "src/aws/API"
import { CustomAlert } from "src/utils/CustomAlert"
import { useFetcher } from "src/hooks/useFetcher"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { existOrganizationMemberPreset } from "src/apis/organization/existOrganizationMemberPreset"
import { createOrganizationMemberFromPreset } from "src/apis/organization/createOrganizationMemberFromPreset"
import { myListTeamRequestIdState } from "src/recoil/atoms/team/myListTeamRequestIdState"
import { notificationsRequestIdState } from "src/recoil/atoms/home/notificationsRequestIdState"
import { myFullOrganizationsRequestIdState } from "src/recoil/atoms/organization/myOrganizationsRequestIdState"
import { MaxCount } from "src/constants/MaxCount"
import { myFullOrganizationsSelector } from "src/recoil/selectors/organization/myOrganizationsSelector"

export type MigrateOrganizationsParams = {
  joinFlg: boolean
}

export type migrate = {
  code: string
  isMigrateFlg?: boolean
  orgId?: string
  name?: string
  role?: string
}

type Props = RootStackScreenProps<"MigrateOrganizations">

export const MigrateOrganizations = memo<Props>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const refreshMembersNotInTeam = useRefresher(listNotJoinMembersRequestIdState)
  const refreshOrganizationMembers = useRefresher(organizationMembersRequestIdState)
  const { value: myOrganizations, isLoading: isMyOrganizationsLoading } = useAsyncSelector(listAllMigratedTeamsSelector)
  const [selectedOrganizationId, setSelectedOrganizationId] = useAsyncState(selectedMyOrganizationState)
  const allOrganizations = (myOrganizations ?? []).filter((n) => (route.params?.joinFlg === false ? !n.joinFlg : true))
  const adminOrganizations = (allOrganizations ?? []).filter((n) => n.role === "manager")
  const memberOrganizations = (allOrganizations ?? []).filter((n) => n.role !== "manager")
  let newAdminOrganizations = adminOrganizations
  let newMemberOrganizations = memberOrganizations

  const spaceObj = {
    id: "",
    title: "",
    invitationCode: "",
    joinFlg: false,
    migrationId: undefined,
    role: "",
  }
  if (isLargeScreen) {
    newAdminOrganizations = [...[spaceObj, spaceObj, spaceObj], ...newAdminOrganizations]
    if (adminOrganizations.length % 3 === 1) {
      newAdminOrganizations = [...(newAdminOrganizations ?? []), ...[spaceObj, spaceObj]]
    } else if (adminOrganizations.length % 3 === 2) {
      newAdminOrganizations = [...(newAdminOrganizations ?? []), ...[spaceObj]]
    }
    newMemberOrganizations = [...[spaceObj, spaceObj, spaceObj], ...memberOrganizations]
  } else {
    newAdminOrganizations = [...[spaceObj], ...newAdminOrganizations]
    newMemberOrganizations = [...[spaceObj], ...newMemberOrganizations]
  }

  const organizations = [...newAdminOrganizations, ...newMemberOrganizations]
  const refreshMyOrganizations = useRefresher(listAllMigratedTeamsRequestIdState)
  const refreshListTeamGql = useRefresher(myListTeamRequestIdState)
  const refreshNotifications = useRefresher(notificationsRequestIdState)
  const refreshMyFullOrganizations = useRefresher(myFullOrganizationsRequestIdState)
  const refreshTeams = useRefresher(myFullOrganizationsRequestIdState)

  const refresh = useCallback(() => {
    refreshMyOrganizations()
  }, [refreshMyOrganizations])

  const refreshAll = useCallback(() => {
    refreshMyOrganizations()
    refreshListTeamGql()
    refreshNotifications()
    refreshMyFullOrganizations()
  }, [refreshMyOrganizations, refreshListTeamGql, refreshNotifications, refreshMyFullOrganizations])

  const { width } = useWindowDimensions()
  const { value: allTeams } = useAsyncSelector(myFullOrganizationsSelector)
  const imageWidth = useMemo(() => (width - 28) * (isLargeScreen ? 0.42 : 1), [isLargeScreen, width])
  const imageHeight = useMemo(() => imageWidth * 0.54, [imageWidth])
  const { value: hasPreTeamMemberByTeam } = useAsyncSelector(hasPreTeamMemberSelector)
  const showBadge = useCallback(
    (organizationId: string) => (hasPreTeamMemberByTeam || {})[organizationId],
    [hasPreTeamMemberByTeam]
  )
  const requestDataResult = useRequestData()
  const { value: account } = useAsyncSelector(accountSelector)
  const [idClick, setIdClick] = useState("")

  const goBack = () => {
    refreshAll()
    navigation.reset({
      index: 0,
      routes: [
        {
          name: Screens.Root,
          params: {
            screen: RootTabs.OrganizationManagerTab,
            params: { screen: OrganizationManagerScreens.OrganizationManager },
          },
        },
      ],
    })
  }

  useEffect(() => {
    navigation.setOptions({
      headerLeft: (props) => (
        <Button onPress={goBack}>
          <BackIcon
            fill={Number(props.screenLayout?.width) >= PC_BREAKPOINT && Platform.OS === "web" ? Colors.orange : Colors.white3}
            {...props}
          ></BackIcon>
        </Button>
      ),
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation])

  const gotoOrganizationDetail = useCallback(
    (id: string, role: string, title: string) => {
      if (role === "manager") {
        refreshMembersNotInTeam()
        refreshOrganizationMembers()
        navigation.navigate(Screens.EditMigrateOrganizationStack, {
          screen: EditMigrateOrganizationScreens.SendMemberInMigrate,
          params: { organizationId: id, title: title },
        })
      } else {
        if (isLargeScreen) {
          setSelectedOrganizationId(id)
        }
        navigation.navigate(Screens.Root, {
          screen: RootTabs.OrganizationManagerTab,
          params: {
            screen: OrganizationManagerScreens.OrganizationDetail,
            params: { organizationId: id },
          },
        })
      }
    },
    [isLargeScreen, navigation, refreshMembersNotInTeam, refreshOrganizationMembers, setSelectedOrganizationId]
  )

  const gotoRegisterOrganizationProfile = useCallback(
    (
      organizationId: string,
      organizationName: string,
      requirementForMemberProfile?: string | null,
      customQuestions?: TeamCustomQuestion[] | null
    ) =>
      navigation.navigate(Screens.RegisterProfile, {
        organizationId,
        organizationName,
        requirementForMemberProfile,
        customQuestions,
      }),
    [navigation]
  )

  const openListMigrateOrganization = useCallback(async () => {
    navigation.navigate(Screens.MigrateOrganizations, { joinFlg: true })
  }, [navigation])

  const { fetch: next, isFetching: isProcessing } = useFetcher(
    useCallback(
      async (migrate: migrate) => {
        if (!requestDataResult.isOk || account?.email == null) {
          return
        }
        const email = account.email
        const { accessToken, accountId } = requestDataResult.content
        if (migrate?.isMigrateFlg) {
          const existPreset = await existOrganizationMemberPreset({ accessToken, organizationId: migrate?.orgId || "", email })
          if (existPreset) {
            const res = await createOrganizationMemberFromPreset({ accessToken, organizationId: migrate?.orgId || "", email })
            if (res.isOk) {
              await setSelectedOrganizationId(undefined)
              await refreshMyOrganizations()
              refreshTeams()
              await CustomAlert.alert("完了", "団体への参加が完了しました。")
              if (openListMigrateOrganization) {
                openListMigrateOrganization()
              }
              return
            } else {
              CustomAlert.alert("エラー", res.error.message ?? "")
            }
          }
          gotoRegisterOrganizationProfile(migrate?.orgId || "", migrate?.name || "")
        }
      },
      [
        refreshMyOrganizations,
        gotoRegisterOrganizationProfile,
        setSelectedOrganizationId,
        requestDataResult,
        account?.email,
        openListMigrateOrganization,
        refreshTeams,
      ]
    )
  )

  const RenderItem = useCallback(
    ({ item: { id, title, uriImage, joinFlg, invitationCode, role }, index }: ListRenderItemInfo<MyMigratedTeam>) => {
      const migrate = {
        code: invitationCode,
        isMigrateFlg: true,
        name: title,
        orgId: id,
        role: role,
      }
      const handlePress = async () => {
        if (joinFlg) {
          gotoOrganizationDetail(id, role, title)
        } else {
          if ((allTeams?.length || 0) >= MaxCount.Team) {
            CustomAlert.alert(
              "エラー",
              `所属団体数が上限を超えるため、参加できません。\n※1人の方が所属できる団体数は最大${MaxCount.Team}団体です。`
            )
            return
          }
          const result = await CustomAlert.confirm("確認", "この団体に参加しますか？")
          if (result) {
            setIdClick(id)
            next(migrate)
          }
        }
      }
      return (
        <View style={isLargeScreen ? { flex: 1 / 3, alignItems: "center" } : styles.webCategory}>
          {index === 0 ? (
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="代表者として参加している団体" />
            </View>
          ) : null}
          {index === 0 && adminOrganizations.length === 0 ? (
            <View style={styles.itemWrapper}>
              <Text>{route.params?.joinFlg === false ? "未参加の団体はありません。" : "移行された団体はありません。"}</Text>
            </View>
          ) : null}
          {index === newAdminOrganizations.length ? (
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label="メンバーとして参加している団体" />
            </View>
          ) : null}
          {index === newAdminOrganizations.length && memberOrganizations.length === 0 ? (
            <View style={styles.itemWrapper}>
              <Text>{route.params?.joinFlg === false ? "未参加の団体はありません。" : "移行された団体はありません。"}</Text>
            </View>
          ) : null}
          {id === "" ? null : (
            <ListItem
              id={id}
              title={title}
              uriImage={uriImage ?? undefined}
              imageWidth={imageWidth}
              imageHeight={imageHeight}
              handlePress={handlePress}
              showBadge={showBadge(id)}
              isLargeScreen={isLargeScreen}
              joinFlg={joinFlg}
              role={role}
              isProcessing={isProcessing}
              idClick={idClick}
            />
          )}
        </View>
      )
    },
    [
      isLargeScreen,
      adminOrganizations.length,
      newAdminOrganizations.length,
      memberOrganizations.length,
      imageWidth,
      imageHeight,
      showBadge,
      gotoOrganizationDetail,
      next,
      route.params?.joinFlg,
      isProcessing,
      idClick,
      allTeams?.length,
    ]
  )

  if (allOrganizations == null) {
    if (isMyOrganizationsLoading) {
      return (
        <View style={styles.loadingContainer}>
          <LoadingIndicator />
        </View>
      )
    }
    return null
  }

  if (allOrganizations.length === 0 && isMyOrganizationsLoading) {
    return (
      <View style={styles.loadingContainer}>
        <LoadingIndicator />
      </View>
    )
  }

  return (
    <>
      <View style={styles.container}>
        <View style={styles.main}>
          {
            <View style={[styles.popUpNoticeContainer, { justifyContent: "center" }]}>
              <Text style={[styles.popUpNoticeText, isLargeScreen ? { textAlign: "center" } : { marginLeft: 0 }]}>
                {route.params?.joinFlg === false
                  ? `代表者により「らくらく連絡網＋」に移行済みの団体で、未参加の団体があります。\n下記ご確認の上、必要な団体については「団体に参加」ボタンより、参加手続きを行ってください。`
                  : "このページには「らくらく連絡網（旧サービス）」から移行された団体のみ表示されます。代表者の方は未参加の移行団体に参加すると、メンバーの参加状況を確認することができます。"}
              </Text>
            </View>
          }
          <FlatList
            data={organizations}
            numColumns={isLargeScreen ? 3 : 1}
            style={isLargeScreen ? styles.webCategories : {}}
            renderItem={RenderItem}
            keyExtractor={(item, index) => item.id + index.toString()}
            refreshControl={<CustomRefreshControl refreshing={isMyOrganizationsLoading} onRefresh={refresh} />}
            contentContainerStyle={[isLargeScreen ? { paddingHorizontal: 300 } : {}]}
            decelerationRate={"fast"}
            scrollEventThrottle={100}
          />
        </View>
      </View>
    </>
  )
})

type ListItemProps = {
  id: string
  title: string
  uriImage?: string
  imageWidth: number
  imageHeight: number
  handlePress: () => void
  showBadge: boolean
  isLargeScreen: boolean
  joinFlg: boolean
  role: string
  isProcessing: boolean
  idClick: string
}

const ListItem = memo<ListItemProps>(
  ({
    title,
    uriImage,
    handlePress,
    imageWidth,
    imageHeight,
    showBadge,
    isLargeScreen,
    joinFlg,
    role,
    isProcessing,
    id,
    idClick,
  }) => {
    const buttonColor = joinFlg ? (role === "manager" ? Colors.orange : Colors.warmGrey3) : Colors.leaf
    const buttonTitle = joinFlg ? (role === "manager" ? "進捗を確認" : "   団体詳細を確認") : "   団体に参加"
    const buttonIcon = joinFlg ? (
      role === "manager" ? (
        <OrganizationListTeamMigrateIcon height={18} />
      ) : (
        <OrganizationIcon filled={true} color={Colors.white} />
      )
    ) : (
      <JoinIcon size={22} />
    )
    return (
      <View style={isLargeScreen ? { padding: 12, maxWidth: imageWidth / 3 + 50 } : styles.itemContainer}>
        <View style={styles.cardCategory}>
          <View style={isLargeScreen ? {} : styles.imageButton}>
            <Image
              source={uriImage ? { uri: uriImage } : defaultTeam}
              style={[
                styles.itemImage,
                isLargeScreen ? { width: imageWidth / 3, height: imageHeight / 3 } : { width: imageWidth, height: imageHeight },
              ]}
              resizeMode="cover"
            ></Image>
            {!joinFlg && (
              <View style={[styles.flagLabelContainer, isLargeScreen ? { height: imageHeight / 15 } : {}]}>
                <View style={[styles.flagLabel, isLargeScreen ? { height: imageHeight / 10 } : {}]}>
                  <Text style={styles.flagLabelText}>未参加</Text>
                </View>
              </View>
            )}
          </View>
          <View style={[styles.itemLabel, isLargeScreen ? { height: 45 } : {}]}>
            {showBadge ? <BadgeIcon size={"small"} style={{ position: "absolute", start: 5 }} /> : null}
            <View>
              <Text style={[styles.label, isLargeScreen ? { width: imageWidth / 3 } : {}]}>{title}</Text>
            </View>
          </View>
          <Button
            style={[styles.button, { width: (isLargeScreen ? imageWidth / 3 : imageWidth) - 24, backgroundColor: buttonColor }]}
            onPress={handlePress}
          >
            {isProcessing && id === idClick ? (
              <LoadingIndicator />
            ) : (
              <>
                {buttonIcon}
                <Text style={styles.buttonLabel}>{buttonTitle}</Text>
              </>
            )}
          </Button>
        </View>
      </View>
    )
  }
)

export const options: ScreenOptions = () => ({
  ...commonHeaderOptionsOnNestedScreen,
  title: "移行団体管理",
})

MigrateOrganizations.displayName = "MigrateOrganizations"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  main: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  itemContainer: {
    paddingVertical: 6,
    alignItems: "center",
  },
  itemImage: {
    borderTopRightRadius: 8,
    borderTopLeftRadius: 8,
  },
  itemLabel: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: 14,
    fontWeight: "bold",
    marginVertical: 12,
    paddingHorizontal: 12,
    textAlign: "center",
  },
  imageButton: {
    shadowColor: "#e0d6ca",
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 17,
    elevation: 3,
    borderRadius: 8,
  },
  popUpNoticeContainer: {
    flexDirection: "row",
    marginTop: 24,
    paddingHorizontal: 15,
    borderBottomColor: Colors.white2,
    borderBottomWidth: 1,
  },
  popUpNoticeText: {
    color: Colors.black,
    marginLeft: 8,
    paddingBottom: 20,
  },
  modalWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.37)",
    padding: 22,
  },
  webCategories: {
    paddingVertical: 24,
  },
  webCategory: {
    flex: 1,
    padding: 6,
  },
  itemWrapper: {
    marginTop: 6,
    alignItems: "center",
  },
  cardCategory: {
    alignItems: "center",
    paddingTop: 0,
    paddingBottom: 12,
    paddingHorizontal: 0,
    display: "flex",
    flexDirection: "column",
    backgroundColor: Colors.white3,
    borderRadius: 8,
    shadowColor: "#e0d6ca",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 0.5,
    shadowRadius: 17,
    elevation: 5,
  },
  itemLabelWrapper: {
    paddingVertical: 20,
    paddingHorizontal: 12,
  },
  loadingContainer: {
    padding: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  flagLabelContainer: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  flagLabelText: {
    color: Colors.white,
    fontSize: 13,
    fontWeight: "bold",
  },
  flagLabel: {
    backgroundColor: Colors.red,
    paddingVertical: 13,
    paddingHorizontal: 19,
    borderTopStartRadius: 6,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 8,
  },
  button: {
    flexDirection: "row",
    height: 44,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 6,
  },
  buttonLabel: {
    color: Colors.white,
    fontSize: 16,
    fontWeight: "bold",
  },
})
