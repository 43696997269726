import { memo, useMemo } from "react"
import Svg, { G, Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
  width?: number
  height?: number
}

export const OclockIcon = memo<Props>(({ fill = Colors.black2, width = 16, height = 17 }) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 16 17" fill="none">
      <G data-name="Icon oclock">
        <Path
          d="M7.75 15.5C11.616 15.5 14.75 12.366 14.75 8.5C14.75 4.63401 11.616 1.5 7.75 1.5C3.88401 1.5 0.75 4.63401 0.75 8.5C0.75 12.366 3.88401 15.5 7.75 15.5Z"
          stroke="#3C3C3C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <Path
          d="M7.91797 3.95703V8.34103L10.84 9.80203"
          stroke="#3C3C3C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
          fill={fill}
        />
      </G>
    </Svg>
  )
})
