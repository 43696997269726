import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { Result } from "src/utils/Result"
import { ContactType } from "src/constants/ContactType"
import { listTemplate } from "src/aws/customAPI"
import { MailType } from "src/aws/API"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

export type GetTemplateContactsParams = {
  accessToken: string
  myMemberId: string
}

type GetTemplateContactsSuccess = ContactOverviewModel[]

type GetTemplateContactsFailure = {
  message: string
}

export const getTemplateContacts = async (params: GetTemplateContactsParams): Promise<Result<GetTemplateContactsSuccess, GetTemplateContactsFailure>> => {
  const { accessToken, myMemberId } = params
  return execApi(
    () => listTemplate(accessToken, {
      teamMemberId: myMemberId,
      appInfo
    }),
    res => {
      return res.getTeamMember.templates.items.map(item => ({
        id: item.id,
        contactType: mailTypeMapping[item.mailType],
        isRead: false,  // 使用しない
        title: item.title,
        body: item.body,
        senderId: myMemberId, // 使用しない
        senderName: "", // 使用しない
        isSenderDeleted: false, // 使用しない
        isFavorite: item.favoriteFlg,
        isAttachmentExists: false,
        isUpdate: false, // 使用しない
        updatedDate: new Date(item.createdAt),
        teamMemberContactId: "", // 使用しない
      }))
    }
  )
}

const mailTypeMapping: { [key in MailType]: ContactType } = {
  [MailType.normal]: ContactType.Normal,
  [MailType.attendance]: ContactType.Attendance,
  [MailType.questionnaire]: ContactType.Survey,
  [MailType.schedule]: ContactType.ScheduleAdjustment,
}
