import { Result } from "src/utils/Result"
import { AttendanceType } from "src/constants/AttendanceType"
import { updateAttendanceAnswer } from "src/aws/customAPI"
import { YesNoAnswer } from "src/aws/API"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type PostAttendanceAnswerRequestParams = {
  accessToken: string
  contactId: string
  myMemberId: string
  attendance: AttendanceType
}

type PostAttendanceAnswerSuccess = unknown

type PostAttendanceAnswerFailure = {
  errorCode?: number
  message: string
}

export const postAttendanceAnswer = async (
  params: PostAttendanceAnswerRequestParams
): Promise<Result<PostAttendanceAnswerSuccess, PostAttendanceAnswerFailure>> => {
  const {accessToken, contactId, myMemberId, attendance} = params
  return execApi(
    () => updateAttendanceAnswer(accessToken, {
      input: {
        mailId: contactId,
        memberId: myMemberId,
        attendanceAnswer: attendanceTypeMapping[attendance]
      },
      appInfo
    }),
    res => res.updateAttendanceAnswer.id
  )
}

const attendanceTypeMapping: { [key in AttendanceType]: YesNoAnswer} = {
  [AttendanceType.Attend]: YesNoAnswer.yes,
  [AttendanceType.NonAttend]: YesNoAnswer.no,
  [AttendanceType.Pend]: YesNoAnswer.tbd,
}
