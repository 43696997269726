import { StyleSheet, Text } from "react-native"
import { AuthMethod, AuthMethodLabel, AuthMethodMediaLabel } from "src/constants/AuthMethod"

const getTail = (authMethod: AuthMethod) => {
  const label = AuthMethodLabel[authMethod]
  const media = AuthMethodMediaLabel[authMethod]
  return `
上記${label}宛に認証コードを送信しました。${media}に記載されている6桁の認証コードを入力してください。`
}

export const getCodeSentDescription = (value: string, authMethod: AuthMethod) => (
  <Text>
    <Text style={styles.bold}>{value}</Text>
    {getTail(authMethod)}
  </Text>
)

const styles = StyleSheet.create({
  bold: {
    fontWeight: "bold",
  },
})
