import { useCallback, useMemo, useState } from "react"
import { updatePhoneNumber } from "src/apis/auth/updatePhoneNumber"
import { useFetcher } from "src/hooks/useFetcher"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { CustomAlert } from "src/utils/CustomAlert"
import { validateAuthMethodCode } from "src/apis/auth/validateAuthMethodCode"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { accountRequestIdState } from "src/recoil/atoms/account/accountRequestIdState"
import { Platform } from "react-native"
import { useSetRecoilState } from "recoil"

type Params = {
  currentPhoneNumber?: string
  phoneNumber: string
  onSuccess: () => void
}

export const useAuthPhoneNumberCodeData = ({ currentPhoneNumber, phoneNumber, onSuccess }: Params) => {
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const refreshAccount = useRefresher(accountRequestIdState)
  const setAccountRequestIdState = useSetRecoilState(accountRequestIdState)
  const [code, setCode] = useState("")
  const label = useMemo(() => (currentPhoneNumber != null ? "変更" : "登録"), [currentPhoneNumber])
  const { fetch: resendAuthCode, isFetching: isSending } = useFetcher(
    useCallback(async () => {
      if (accessToken == null) {
        return
      }
      const result = await updatePhoneNumber({ accessToken, phoneNumber })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      CustomAlert.alert("完了", "認証コードを再送しました。")
    }, [accessToken, phoneNumber])
  )
  const { fetch: authorize, isFetching: isSendingCode } = useFetcher(
    useCallback(async () => {
      if (accessToken == null) {
        return
      }
      const result = await validateAuthMethodCode({ accessToken, code, type: "phoneNumber" })
      if (!result.isOk) {
        await CustomAlert.alert("認証エラー", result.error.message)
        return
      }
      if (Platform.OS !== "web") {
        refreshAccount()
      } else {
        setAccountRequestIdState((n) => n + 1)
      }

      if (label == "変更") {
        await CustomAlert.alert(`変更完了`, `携帯電話番号（認証用）の変更が完了しました。`)
      } else {
        await CustomAlert.alert(`認証完了`, `携帯電話番号（認証用）が登録されました。`)
      }
      onSuccess()
    }, [accessToken, code, setAccountRequestIdState, label, onSuccess, refreshAccount])
  )

  const actionLabel = useMemo(() => `携帯電話番号を${label}`, [label])

  const isResendDisabled = useMemo(() => isSendingCode || isSending, [isSendingCode, isSending])

  return {
    code,
    setCode,
    isResendDisabled,
    isSendingCode,
    isSending,
    resendAuthCode,
    authorize,
    actionLabel,
  }
}
