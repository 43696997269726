import { useCallback } from "react"
import { useSetRecoilState } from "recoil"
import { validateCode } from "src/apis/auth/validateCode"
import { AuthMethod } from "src/constants/AuthMethod"
import { useFetcher } from "src/hooks/useFetcher"
import { accountRequestIdState } from "src/recoil/atoms/account/accountRequestIdState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { CustomAlert } from "src/utils/CustomAlert"
import { registerDevice } from "src/apis/auth/createDevice"
import { sessionState } from "src/recoil/atoms/authorization/sessionState"
import { oldRa9Ra9TokenStates } from "src/recoil/atoms/migration/oldRa9Ra9TokenStates"

type Params = {
  code: string
  authMethod: AuthMethod
  value: string
  session: string
}

export const useValidateCode = (params?: Params) => {
  const setAccountRequestId = useSetRecoilState(accountRequestIdState)
  const setAuthorization = useSetRecoilState(tokensState)
  const setSession = useSetRecoilState(sessionState)
  const setOldRa9Ra9AccessTokens = useSetRecoilState(oldRa9Ra9TokenStates)
  const refreshAccount = useCallback(() => setAccountRequestId((n) => n + 1), [setAccountRequestId])
  const { fetch: login, isFetching: isSendingCode } = useFetcher(
    useCallback(async () => {
      if (params == null) {
        return
      }
      const result = await validateCode(params)
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        if (result.error.session) {
          setSession(result.error.session)
        }
        return
      }
      setOldRa9Ra9AccessTokens(undefined)
      setAuthorization(result.content)
      await registerDevice(result.content.accessToken)
      refreshAccount()
    }, [params, refreshAccount, setAuthorization, setSession, setOldRa9Ra9AccessTokens])
  )

  return {
    login,
    isSendingCode,
  }
}
