import { memo } from "react"
import Svg, { G, Path } from "react-native-svg"

export const FlagIcon = memo(() => (
  <Svg width="8.102" height="10.5" viewBox="0 0 8.102 10.5">
    <G>
      <Path d="M18.215 6.006V15.5h1.279v-3.508l6.823-3.2L18.215 5z" transform="translate(-18.215 -5)" fill="#f0830e" />
    </G>
  </Svg>
))
