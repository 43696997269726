import { ValueOf } from "src/types.d"

export const ContactNetworkDrawers = Object.freeze({
  InboxScreen: "InboxScreen",
  OutboxScreen: "OutboxScreen",
  TrashBoxScreen: "TrashBoxScreen",
  TemplateScreen: "TemplateScreen",
  DraftScreen: "DraftScreen",
  MigrationScreen: "MigrationScreen",
} as const)

export type ContactNetworkDrawers = ValueOf<typeof ContactNetworkDrawers>
