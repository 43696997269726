
import { GqlError, resendInvitationEmail as _resendInvitationEmail, sendMigrationInvitationEmail as _sendMigrationInvitationEmail } from "src/aws/customAPI"
import { Result } from "src/utils/Result"

type Params = {
  accessToken: string
  teamId: string
  invitationId: string
}

type InputMigrationInvitationEmail = {
  accessToken: string
  teamId: string
  emails: string[]
}

type Success = unknown

type Failure = {
  message: string
}

export const resendInvitationEmail = async ({accessToken, teamId, invitationId}: Params): Promise<Result<Success, Failure>> => {
  return _resendInvitationEmail(accessToken, {
    input: {
      teamId: teamId,
      invitationEmailStatusId: invitationId,
    }
  }).then(res => {
    if (res.data?.resendInvitationEmail.id) {
      return Result.Ok(undefined)
    } else {
      return Result.Error<Failure>({message: "予期せぬエラーが発生しました"})
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "予期せぬエラーが発生しました"
    return Result.Error<Failure>({
      message: message
    })
  })
}

export const sendMigrationInvitationEmail = async ({accessToken, teamId, emails}: InputMigrationInvitationEmail): Promise<Result<Success, Failure>> => {
  return _sendMigrationInvitationEmail(accessToken, {
    input: {
      teamId: teamId,
      emails: emails
    }
  }).then(res => {
    console.log(res.data)
    if (res.data?.sendMigrationInvitationEmail.id) {
      return Result.Ok(undefined)
    } else {
      return Result.Error<Failure>({message: "予期せぬエラーが発生しました"})
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "予期せぬエラーが発生しました"
    return Result.Error<Failure>({
      message: message
    })
  })
}