import { atom, selector } from "recoil"
import { draftSelector } from "src/recoil/selectors/contact/draftSelector"
import { templateSelector } from "src/recoil/selectors/contact/templateSelector"
import { existingContactDetailSelector } from "src/recoil/selectors/contact/create/existingContactDetailSelector"
import { reservedSelector } from "src/recoil/selectors/contact/reservedSelector"

const initialState = undefined

const initialStateSelector = selector<boolean | undefined>({
  key: "atoms/contact/create/isReceiversAllLeadersState_initialState",
  get: ({ get }) => {
    const contact = get(existingContactDetailSelector)
    const contactValue = contact?.receivers.isAllLeaders
    const reserved = get(reservedSelector)
    const reservedValue = reserved?.receivers.isAllLeaders
    const template = get(templateSelector)
    const draft = get(draftSelector)
    return contactValue ?? reservedValue ?? (template ?? draft)?.isReceiversAllLeaders ?? initialState
  },
})

export const isReceiversAllLeadersState = atom<boolean | undefined>({
  key: "atoms/contact/create/isReceiversAllLeadersState",
  default: initialStateSelector,
})
