import type { HeaderBackButtonProps } from "@react-navigation/elements"
import { StyleSheet } from "react-native"
import { Colors } from "src/constants/Colors"
import { Button } from "src/components/parts/buttons/Button"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"

const styles = StyleSheet.create({
  container: {
    width: 45,
    height: 45,
    justifyContent: "center",
    alignItems: "center",
  },
})

export const ControlledModalHeaderLeftButton = ({ onPress, canGoBack }: HeaderBackButtonProps) => {
  return (
    <Button onPress={onPress} style={styles.container}>
      {canGoBack ? <BackIcon fill={Colors.orange} /> : <RemoveIcon fill={Colors.orange} />}
    </Button>
  )
}
