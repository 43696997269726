import { selector } from "recoil"
import { getAccountPermission } from "src/aws/customAPI"
import { appInfo } from "src/constants/AppInfo"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { tokensState } from "../../atoms/authorization/tokensState"
import { teamPermissionRequestIdState } from "src/recoil/atoms/organization/teamPermissionRequestIdState"

export const permissionSelector = selector({
  key: "selectors/account/permissionSelector",
  get: async ({ get }) => {
    get(teamPermissionRequestIdState)
    const selectedTeam = get(selectedMyOrganizationSelector)
    if (!selectedTeam) return undefined

    const tokens = get(tokensState)
    if (!tokens) return undefined

    const accountPermission = await getAccountPermission(tokens.accessToken, {
      input: { teamId: selectedTeam.id },
      appInfo: appInfo,
    }).then((res) => res.data?.getPermissionAccount)
    return accountPermission
  },
})
