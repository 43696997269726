import { FC } from "react"
import Svg, { G, Path } from "react-native-svg"

export const MemberIcon: FC = () => (
  <Svg width="12.79" height="15.864" viewBox="0 0 12.79 15.864">
    <G data-name="グループ 2331">
      <Path
        data-name="楕円形 393"
        d="M2.376-1A3.376 3.376 0 1 1-1 2.376 3.38 3.38 0 0 1 2.376-1zm0 5.282A1.906 1.906 0 1 0 .47 2.376a1.908 1.908 0 0 0 1.906 1.906z"
        transform="translate(4.018 1)"
        fill="#f0830e"
      />
      <Path
        data-name="前面オブジェクトで型抜き 3"
        d="M12.054 7.96a.735.735 0 0 1-.735-.735V5.263A3.806 3.806 0 0 0 7.527 1.47H5.263A3.806 3.806 0 0 0 1.47 5.263v1.962a.735.735 0 1 1-1.47 0V5.263A5.269 5.269 0 0 1 5.263 0h2.264a5.269 5.269 0 0 1 5.263 5.263v1.962a.735.735 0 0 1-.736.735z"
        transform="translate(0 7.904)"
        fill="#f0830e"
      />
    </G>
  </Svg>
)
