import { Result } from "src/utils/Result"
import { GqlError, deleteFavoriteMatchingOffer as _deleteFavoriteMatchingOffer } from "src/aws/customAPI"

type DeleteFavoriteMatchingOfferParams = {
  accessToken: string
  teamMemberId: string
  matchingOfferId: string
}

export type DeleteFavoriteMatchingOfferSuccess = unknown

type DeleteFavoriteMatchingOfferFailure = {
  message: string
}

export const deleteFavoriteMatchingOffer = async (
  {accessToken, teamMemberId, matchingOfferId}: DeleteFavoriteMatchingOfferParams): Promise<Result<DeleteFavoriteMatchingOfferSuccess, DeleteFavoriteMatchingOfferFailure>> => {
  return await _deleteFavoriteMatchingOffer(accessToken, {
      teamMemberId,
      matchingOfferId,
    }
  ).then(res => {
    return (res.data?.deleteFavoriteMatchingOffer.id) ?
      Result.Ok(undefined) :
      Result.Error<DeleteFavoriteMatchingOfferFailure>({ message: 'お気に入りの削除に失敗しました' })
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || 'お気に入りの削除に失敗しました'
    return Result.Error<DeleteFavoriteMatchingOfferFailure>({
      message: message
    })
  })
}