import { memo, ReactElement } from "react"
import { Dimensions, StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"
import { QuestionWithCircleIcon } from "src/components/parts/icons/QuestionWithCircleIcon"

type LabelType = "default" | "question"

type Props = {
  label: string
  RightComponent?: ReactElement
  type?: LabelType
  required?: boolean
  noteRequired?: string
  size?: number
  full?: boolean
  marginBottom?: boolean
}

const windowWidth = Dimensions.get("window").width
const maxWidth = windowWidth >= 768 ? "80%" : "70%"

export const ItemLabel = memo<Props>(({ label, RightComponent, type, required, noteRequired, size, full, marginBottom }) => {
  const type_ = type || "default"

  return (
    <View style={[styles.container, full ? { flex: 1 } : null]}>
      <View style={[styles.leftContainer, RightComponent != null ? { maxWidth: maxWidth } : null]}>
        {label !== "" ? (
          <>
            {type_ === "default" ? <View style={styles.circle} /> : <QuestionWithCircleIcon />}
            <View style={styles.rowCenterLine}>
              <Text style={[styles.sectionTitle, size ? { fontSize: 16 } : null]}> {label}</Text>
              {required && <Text style={[styles.required, marginBottom ? { marginBottom: 5 } : {}]}>{"※"}</Text>}
              {noteRequired && <Text style={[styles.subTextRequired]}>{noteRequired}</Text>}
            </View>
          </>
        ) : null}
      </View>
      {RightComponent != null ? <View style={styles.rightContainer}>{RightComponent}</View> : null}
    </View>
  )
})

export const ItemLabelLongText = memo<Props>(
  ({ label, RightComponent, type, required, noteRequired, size, full, marginBottom }) => {
    const type_ = type || "default"

    return (
      <View style={[styles.container]}>
        {label !== "" ? (
          <View
            style={[
              styles.leftContainer,
              { alignItems: "flex-start" },
              full ? undefined : { width: !RightComponent ? "100%" : "65%" },
            ]}
          >
            {type_ === "default" ? <View style={[styles.circle, { marginTop: 4 }]} /> : <QuestionWithCircleIcon />}
            <View style={[styles.rowCenterLine, { alignItems: "flex-start" }, full ? undefined : { width: "95%" }]}>
              <Text style={[styles.sectionTitle, size ? { fontSize: 16 } : null, full ? undefined : { width: "100%" }]}>
                {label}
                {required && <Text style={[styles.required, marginBottom ? { marginBottom: 5 } : {}]}>{"※"}</Text>}
              </Text>
              {noteRequired && <Text style={[styles.subTextRequired]}>{noteRequired}</Text>}
            </View>
          </View>
        ) : null}
        {RightComponent != null ? <View style={styles.rightContainer}>{RightComponent}</View> : null}
      </View>
    )
  }
)
const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  rightContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  sectionTitle: {
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginLeft: 8,
    marginRight: 6,
    fontSize: 18,
  },
  circle: {
    width: 16,
    height: 16,
    borderRadius: 8,
    backgroundColor: Colors.blush,
  },
  required: {
    color: Colors.red,
    fontSize: 18,
    fontWeight: "bold",
  },
  rowCenterLine: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  subTextRequired: { color: Colors.red, lineHeight: 15, paddingTop: 5 },
})
