import { Fragment, memo } from "react"
import { Platform, StyleSheet, Text, View } from "react-native"
import { FlatList } from "react-native-gesture-handler"
import { Colors } from "src/constants/Colors"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import dayjs from "dayjs"
import { BannerAd } from "src/tags/ads/BannerAd"
import { adLoadState, isLoadDoneAd } from "src/recoil/atoms/ads/adState"
import { useRecoilState } from "recoil"
type Props = {
  title: string
  subTitle?: string
  list?: { value: string; label: string; datetime?: Date }[]
  isShowAds?: boolean
}

export const SimpleListBottomSheetInner = memo<Props>(({ title, subTitle, list, isShowAds }) => {
  const [adLoadStateList] = useRecoilState(adLoadState)
  return list ? (
    <>
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{title}</Text>
        <Text style={styles.subTitle}>{subTitle}</Text>
      </View>
      <FlatList
        data={[...list, { value: "ADS_BANNER", label: "ADS_LABEL" }]}
        keyExtractor={(item) => item.value}
        renderItem={({ item, index }) => {
          if (index === list.length) {
            return Platform.OS !== "web" && isShowAds ? (
              <View style={!isLoadDoneAd("mail_detail", adLoadStateList, 1) && { height: 0 }}>{BannerAd.MailDetail_1}</View>
            ) : null
          }
          return (
            <Fragment key={item.value}>
              <View style={styles.rowContainer}>
                <Text style={styles.rowText} numberOfLines={1} ellipsizeMode="tail">
                  {item.label}
                </Text>
                {item.datetime != null ? (
                  <View style={styles.datetimeContainer}>
                    <Text style={styles.dateText}>{dayjs(item.datetime).format("YYYY/MM/DD")}</Text>
                    <Text style={styles.timeText}>{dayjs(item.datetime).format("HH:mm")}</Text>
                  </View>
                ) : null}
              </View>
              <View style={styles.rowSeparator} />
            </Fragment>
          )
        }}
      />
    </>
  ) : (
    <View style={styles.loadingContainer}>
      <LoadingIndicator />
    </View>
  )
})
const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white3,
  },
  titleContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 13,
    paddingHorizontal: 22,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
  },
  title: {
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
  subTitle: {
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.orange,
  },
  rowContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 13,
    paddingHorizontal: 22,
    width: "100%",
  },
  rowText: {
    fontSize: 16,
    color: Colors.greyshBrown,
    flexShrink: 1,
  },
  datetimeContainer: {
    flexDirection: "row",
    marginLeft: 12,
  },
  dateText: {
    marginRight: 12,
    fontSize: 15,
  },
  timeText: {
    fontSize: 15,
  },
  rowSeparator: {
    width: "100%",
    height: 1,
    marginLeft: 22,
    backgroundColor: Colors.lightGrey,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "center",
    paddingTop: 30,
  },
})
