import { ComponentProps, memo } from "react"
import { Colors } from "src/constants/Colors"
import { Button } from "src/components/parts/buttons/Button"
import { BackIcon } from "../icons/BackIcon"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export const ButtonBackIcon = memo<ComponentProps<typeof Button>>((props) => {
  const isLargeScreen = useCheckPCScreen()

  return (
    <Button {...props}>
      <BackIcon fill={isLargeScreen ? Colors.orange : Colors.white3} />
    </Button>
  )
})
