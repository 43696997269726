import { memo, useCallback } from "react"
import { StyleSheet, Text, View } from "react-native"
import Accordion from "react-native-collapsible/Accordion"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { ChevronDownIcon } from "src/components/parts/icons/ChevronDownIcon"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { OrganizationNotificationSetting } from "src/components/projects/NotificationSettings/OrganizationNotificationSetting"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useNotificationSettingsData } from "src/recoil/hooks/screens/useNotificationSettingsData"
import { RootStackScreenProps } from "src/types.d"
import { ListNotificationSettingsSuccess } from "src/apis/settings/listNotificationSettings"
import { defaultTeam } from "src/constants/defaultTeam"
import { MainLayout } from "src/layouts/MainLayout"
import { HeaderCommon } from "src/components/parts/HeaderCommon"
import { Colors } from "src/constants/Colors"
import { RootTabs } from "src/constants/RootTabs"
import { useNavigation } from "@react-navigation/native"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export type NotificationSettingsParams = undefined

type Props = RootStackScreenProps<typeof Screens.NotificationSettings>

export const NotificationSettings = memo<Props>(() => {
  const { organizations, activeSections, setActiveSections } = useNotificationSettingsData()
  const navigation = useNavigation()
  const Header = useCallback(
    (organization: ListNotificationSettingsSuccess, _: unknown, isActive: boolean) => (
      <AccordionHeader organization={organization} isActive={isActive} />
    ),
    []
  )
  const isLargeScreen = useCheckPCScreen()
  const Content = useCallback(
    (organization: ListNotificationSettingsSuccess) => <OrganizationNotificationSetting organization={organization} />,
    []
  )

  if (organizations == null) {
    return (
      <MainLayout>
        <View style={styles.loadingContainer}>
          <LoadingIndicator />
        </View>
      </MainLayout>
    )
  }

  if (organizations.length === 0) {
    return (
      <MainLayout>
        <View style={styles.noInformationContainer}>
          <Text style={styles.noInformation}>
            {"まずは団体に参加しましょう。\n団体管理メニューから新しく団体を作成するか、団体に参加してください。"}
          </Text>
        </View>
      </MainLayout>
    )
  }

  return (
    <MainLayout>
      <HeaderCommon title="通知設定" back={() => navigation.navigate(RootTabs.SettingsTab as any)} />
      <CustomScrollView style={[styles.container, isLargeScreen ? { paddingLeft: 30, paddingRight: 30 } : {}]}>
        <Accordion
          activeSections={activeSections}
          sections={organizations}
          renderHeader={Header}
          renderContent={Content}
          onChange={setActiveSections}
          underlayColor="transparent"
          sectionContainerStyle={styles.sectionContainer}
        />
      </CustomScrollView>
    </MainLayout>
  )
})

type AccordionHeaderProps = {
  organization: ListNotificationSettingsSuccess
  isActive: boolean
}

const AccordionHeader = memo<AccordionHeaderProps>(({ organization, isActive }) => {
  return (
    <View style={styles.accordionHeader}>
      <View style={styles.headerLeft}>
        <CircleThumbnail
          size={32}
          source={organization.teamProfileImage ? { uri: organization.teamProfileImage } : defaultTeam}
        />
        <Text style={styles.orgName}>{organization.teamName}</Text>
      </View>
      <View style={styles.headerRight}>
        <View style={{ transform: isActive ? [{ rotate: "180deg" }] : [] }}>
          <ChevronDownIcon />
        </View>
      </View>
    </View>
  )
})

export const notificationSettingsOptions: ScreenOptions = {
  ...commonHeaderOptions,
  title: "通知設定",
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    backgroundColor: Colors.white,
  },
  accordionHeader: {
    paddingHorizontal: 16,
    paddingVertical: 16,
    flexDirection: "row",
    alignItems: "center",
  },
  headerLeft: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  headerRight: {},
  orgName: {
    marginLeft: 16,
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
  sectionContainer: {
    backgroundColor: Colors.white3,
    marginBottom: 10,
  },
  noInformationContainer: {
    flex: 1,
    flexDirection: "row",
    justifyContent: "center",
    backgroundColor: Colors.beige,
  },
  noInformation: {
    flex: 1,
    textAlign: "center",
    fontWeight: "bold",
    paddingHorizontal: 16,
    paddingVertical: 32,
    color: Colors.black,
  },
})
