import { memo, useCallback } from "react"
import { ScrollView, StyleSheet, Text, View } from "react-native"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { Colors } from "src/constants/Colors"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { ContactScheduleAnswerScreens } from "src/constants/ContactScheduleAnswerScreens"
import { ContactScheduleAnswerStackScreenProps } from "src/types.d"
import { useContactScheduleAnswerOverviewData } from "src/recoil/hooks/screens/useContactScheduleAnswerOverviewData"
import { Button } from "src/components/parts/buttons/Button"
import { ScheduleAnswerLabel, ScheduleAnswerType } from "src/constants/ScheduleAnswerType"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { formatCandidate } from "src/types/contact/EventDateCandidate"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"

export type ContactScheduleAnswerOverviewParams = {
  contactId: string
  type: "Inbox" | "Outbox"
}

export const ContactScheduleAnswerOverview = memo<
  ContactScheduleAnswerStackScreenProps<typeof ContactScheduleAnswerScreens.ContactScheduleAnswerOverview>
>(
  ({
    route: {
      params: { contactId, type },
    },
    navigation,
  }) => {
    const { scheduleAnswerOverviews, isLoading } = useContactScheduleAnswerOverviewData(contactId)
    const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
    const isLargeScreen = useCheckPCScreen()
    const { allowViewAnswerMemberList } = useCheckAllowAccountPermission({
      contactId,
      viewMode: type,
      organizationId: selectedOrganization?.id,
    })

    const goToContactAttendanceAnswerDetail = useCallback(
      (scheduleId: string, scheduleAnswerType: ScheduleAnswerType) => {
        if (allowViewAnswerMemberList) {
          navigation.navigate(ContactScheduleAnswerScreens.ContactScheduleAnswerDetail, {
            contactId,
            scheduleId,
            scheduleAnswerType,
          })
        }
      },
      [navigation, contactId, allowViewAnswerMemberList]
    )

    if (isLoading) {
      return (
        <View style={styles.loadingIndicator}>
          <LoadingIndicator />
        </View>
      )
    }

    if (scheduleAnswerOverviews == null) {
      return null
    }

    return (
      <CustomKeyboardAvoidingView style={isLargeScreen ? styles.containerLarge : styles.container}>
        <ScrollView style={isLargeScreen ? styles.mainLarge : styles.main}>
          <View style={styles.itemLabelWrapper}>
            <ItemLabel label="日程候補" />
          </View>
          {scheduleAnswerOverviews.map((v) => (
            <View style={styles.itemContainer} key={v.schedule.id}>
              <View style={styles.resultContainer}>
                <Text style={styles.dateText}>{formatCandidate(v.schedule.eventDateCandidate)}</Text>
                <View style={styles.answerButtonListContainer}>
                  <Button
                    style={styles.answerButtonContainer}
                    onPress={() => goToContactAttendanceAnswerDetail(v.schedule.id, ScheduleAnswerType.Attend)}
                  >
                    <Text style={styles.answerButtonTitle}>{ScheduleAnswerLabel[ScheduleAnswerType.Attend]}</Text>
                    <Text style={styles.answerButtonResult}>{v.Attend.count}人</Text>
                  </Button>
                  <Button
                    style={styles.answerButtonContainer}
                    onPress={() => goToContactAttendanceAnswerDetail(v.schedule.id, ScheduleAnswerType.NonAttend)}
                  >
                    <Text style={styles.answerButtonTitle}>{ScheduleAnswerLabel[ScheduleAnswerType.NonAttend]}</Text>
                    <Text style={styles.answerButtonResult}>{v.NonAttend.count}人</Text>
                  </Button>
                  <Button
                    style={styles.answerButtonContainer}
                    onPress={() => goToContactAttendanceAnswerDetail(v.schedule.id, ScheduleAnswerType.Pend)}
                  >
                    <Text style={styles.answerButtonTitle}>{ScheduleAnswerLabel[ScheduleAnswerType.Pend]}</Text>
                    <Text style={styles.answerButtonResult}>{v.Pend.count}人</Text>
                  </Button>
                </View>
              </View>
            </View>
          ))}
        </ScrollView>
      </CustomKeyboardAvoidingView>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    paddingVertical: 20,
  },
  containerLarge: {
    backgroundColor: Colors.white3,
    paddingVertical: 20,
  },
  main: {
    flex: 1,
    paddingHorizontal: 15,
  },
  mainLarge: {
    flex: 1,
    paddingHorizontal: 15,
    marginTop: 20,
    width: "60%",
    maxWidth: 1020,
    marginLeft: "20%",
    marginRight: "20%",
    paddingLeft: 30,
    paddingRight: 30,
  },
  itemLabelWrapper: {
    marginTop: 12,
    marginLeft: 15,
  },
  itemContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingVertical: 20,
    paddingHorizontal: 15,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
  },
  radioButtonContainer: {
    justifyContent: "flex-start",
    marginRight: 15,
  },
  resultContainer: {
    flex: 1,
  },
  dateText: {
    fontSize: 16,
    lineHeight: 20,
    color: Colors.greyshBrown,
  },
  answerButtonListContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: 15,
  },
  answerButtonContainer: {
    paddingVertical: 5,
    paddingHorizontal: 20,
    backgroundColor: Colors.reddishGrey,
    borderRadius: 4,
  },
  answerButtonTitle: {
    fontSize: 13,
    lineHeight: 15,
    color: Colors.greyshBrown,
  },
  answerButtonResult: {
    fontSize: 13,
    lineHeight: 15,
    color: Colors.orange,
  },
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
})
