import { FC } from "react"
import Svg, { G, Path } from "react-native-svg"

export const PermanentlyDeleteIcon: FC = () => (
  <Svg width="26" height="22" viewBox="0 0 26 22" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.21193 19L3.48462 10.9996L8.21185 3L17.7881 3L22.5154 10.9996L17.7881 19H8.21193ZM0 10.9995L6.5 0H19.5L26 10.9995L19.5 22H6.5L0 10.9995ZM8 14.0927L10.9861 11.1066L8.00086 8.12132L10.1222 6L13.1074 8.98526L16.0927 6L18.214 8.12132L15.2288 11.1066L18.2149 14.0927L16.0936 16.214L13.1074 13.2279L10.1213 16.214L8 14.0927Z"
      fill="#E20101"
    />
  </Svg>
)

PermanentlyDeleteIcon.displayName = "PermanentlyDeleteIcon"
