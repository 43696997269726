import { memo, useCallback, useEffect } from "react"
import { StyleSheet, useWindowDimensions, View } from "react-native"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { Colors } from "src/constants/Colors"
import { RootStackScreenProps } from "src/types.d"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { planDataState } from "src/recoil/atoms/planData/planDataState"
import { Screens } from "src/constants/Screens"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import PlanUpdateConfirmBlock from "./components/PlanUpdateConfirmBlock"
import { useRecoilValue } from "recoil"
import { getOrderDetailState } from "src/recoil/selectors/paidTeam/orderDetailState"
import { changePlanPaidOrderApi } from "src/apis/paidTeam/changePlanPaidOrder"
import { useFetcher } from "src/hooks/useFetcher"
import { isEmpty } from "lodash"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Button } from "src/components/parts/buttons/Button"
import { BackIcon } from "src/components/parts/icons/BackIcon"

const confirmUpdatePlan = memo<RootStackScreenProps<"confirmUpdatePlan">>(({ navigation, route }) => {
  const { orderId, extend, initUpdatePlan } = route.params
  const isLargeScreen = useCheckPCScreen()
  const requestDataResult = useRequestData()
  const accessToken = requestDataResult.isOk ? requestDataResult?.content?.accessToken : ""
  const orderDetail = useRecoilValue(getOrderDetailState)
  const heightScreen = useWindowDimensions().height
  const insets = useSafeAreaInsets()
  const planData = useRecoilValue(planDataState)

  const { fetch: updatePlan, isFetching: isLoadingApi } = useFetcher(
    useCallback(async () => {
      if (!accessToken || !planData || !orderId) {
        return
      }
      try {
        const result = await changePlanPaidOrderApi({ accessToken, planData, orderId })
        const mode = result?.id ? "SUCCESS" : isEmpty(result?.errors[0]?.errorInfo?.detail) ? "FAIL_DB" : "FAIL_MAIL"
        navigation.navigate(Screens.Root, {
          screen: RootTabs.OrganizationManagerTab,
          params: {
            screen: OrganizationManagerScreens.CompletedApplyPaid,
            params: {
              mode,
              orderId: result,
              type: "updatePlan",
              teamId: orderDetail?.teamId ?? "",
            },
          },
        })
      } catch (error) {
        console.error("Failed to update plan:", error)
      }
    }, [accessToken, planData, orderDetail, navigation, orderId])
  )

  const goBack = useCallback(() => {
    navigation.reset({
      index: 0,
      routes: [
        {
          name: Screens.registerPlan,
          params: {
            organizationId: orderDetail?.teamId ?? "",
            mode: "back",
            orderId: orderId,
            extend,
            oriUpdatePlan: initUpdatePlan,
          },
        },
      ],
    })
  }, [navigation, orderDetail, orderId, extend, initUpdatePlan])

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Button onPress={goBack}>
          <BackIcon fill={isLargeScreen ? Colors.orange : Colors.white3}></BackIcon>
        </Button>
      ),
    })
  }, [navigation, goBack, isLargeScreen])

  return (
    <View style={[{ height: heightScreen - 64, paddingBottom: insets.bottom }]}>
      <CustomScrollView noPaddingBottom={true}>
        <PlanUpdateConfirmBlock
          orderNumber={orderDetail.orderNumber ?? ""}
          companyName={orderDetail.teamName ?? ""}
          maxMembers={planData?.maxMembers ?? ""}
          limitTime={planData?.limitTime ?? ""}
          price={planData?.price ?? ""}
          invoiceAddress={planData?.invoiceAddress ?? ""}
          invoiceKanaAddress={planData?.invoiceKanaAddress ?? ""}
          createdTime={orderDetail.createdAt ?? ""}
          startTimeConvert={planData?.startTimeConvert ?? ""}
          remark={planData?.remark ?? ""}
          planName={`${planData?.maxMembers + "名"}(${planData?.limitTime + "カ月"})`}
        />
      </CustomScrollView>
      <View style={[styles.line]}></View>
      <View style={[styles.buttonContainer, isLargeScreen ? null : { paddingVertical: 24 }]}>
        <View
          style={[
            isLargeScreen
              ? {
                  maxWidth: 600,
                  flexDirection: "row",
                  justifyContent: "space-between",
                  width: "100%",
                  paddingHorizontal: 24,
                }
              : { width: "100%" },
          ]}
        >
          <TextButton
            buttonType={ButtonType.Secondary}
            title="戻る"
            onPress={goBack}
            style={[isLargeScreen ? { width: "49%" } : null]}
          />
          <TextButton
            buttonType={ButtonType.Primary}
            title="プランを変更"
            onPress={() => {
              updatePlan()
            }}
            isLoading={isLoadingApi}
            style={[isLargeScreen ? { width: "49%" } : { marginTop: 16 }]}
          />
        </View>
      </View>
    </View>
  )
})

export const confirmUpdatePlanOptions: ScreenOptions = () => ({
  ...commonHeaderOptions,
  headerShown: true,
  title: "プラン変更内容確認",
})

const styles = StyleSheet.create({
  line: {
    height: 1,
    width: "100%",
    backgroundColor: Colors.borderColor,
  },
  row: {
    marginBottom: 16,
    width: "100%",
    borderBottomColor: Colors.borderColor,
    borderBottomWidth: 1,
  },
  buttonContainer: {
    padding: 12,
    width: "100%",
    alignItems: "center",
  },
  containerCheck: {
    width: "100%",
    padding: 24,
    backgroundColor: "#FAF8F5",
    borderBottomColor: Colors.borderColor,
    borderBottomWidth: 1,
    alignItems: "center",
  },
  titleCheck: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 20,
    marginBottom: 16,
  },
  wrap: {
    width: "100%",
    maxWidth: 600,
  },
  checkBox: {
    flexDirection: "row",
    alignItems: "center",
  },
  textCheckBox: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 19.6,
  },
  textLink: {
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 19.6,
    marginLeft: 8,
    color: Colors.activeStep,
  },
})

export default confirmUpdatePlan
