import { MemberType } from "src/types/organization/OrganizationMember"
import { Result } from "src/utils/Result"
import { searchTeamMember } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import { OrderBy, TeamMemberSortBy } from "src/aws/API"

type GetTeamMembersRequestParams = {
  accessToken: string
  organizationId: string
  excludedIds: Array<string | null>
  page: number 
  limit: number
  searchText: string | undefined
}

export type TeamMemberOverview = {
  id: string
  accountTeamMembersId: string | null | undefined
  nickname: string
  imageUrl?: string
  memberNumber: number
  memberType: MemberType
  groupName?: string
  isLeader: boolean
  isInGroup: boolean
  isAdministrator: boolean
  groupIds: string[]
  email?: string
  joinFlg?: boolean
}

type GetTeamMembersSuccess = {
  data: TeamMemberOverview[]
  total: number
  page: number
}

type GetTeamMembersFailure = {
  message: string
}

export const getTeamMembers = async (
  params: GetTeamMembersRequestParams
): Promise<Result<GetTeamMembersSuccess, GetTeamMembersFailure>> => {
  const apiResponse = await execApi(
    () => searchTeamMember(params.accessToken, {
      input: {
        teamId: params.organizationId,
        name: params.searchText,
        excludedIds: params.excludedIds,
        limit: params.limit,
        page: params.page,
        sortBy: TeamMemberSortBy.memberSeq,
        orderBy: OrderBy.ASC,
      },                                          
      appInfo
    }),
    
    res => {
      const transformedData = res.searchTeamMember.items.map(item => ({
        id: item.id ?? '', 
        nickname: item.nickname,
        accountTeamMembersId: item.accountTeamMembersId,
        memberNumber: item.memberSeq,
        memberType: memberTypeMapping[item.role],
        isAdministrator: item.role === 'manager',
        isLeader:  !!item.groups.find(e=>e.leaderFlg) ,
        isInGroup: item.groups.length > 0,
        groupIds: item.groups.map(e => e.groupID).filter((id): id is string => id !== null && id !== undefined),
      })) || [];
      return {
        data: transformedData,
        total: res.searchTeamMember.total,
        page: res.searchTeamMember.page,
      };
    }
  );
  
  return apiResponse;
}

const memberTypeMapping = {
  general: MemberType.General,
  mailSender: MemberType.GeneralWithContactAuthorization,
  manager: MemberType.Representative,
}
