import { FC } from "react"
import Svg, { G, Path } from "react-native-svg"
import { DrawerIconProps } from "src/types/DrawerIconProps"

export const MigrationIcon: FC<DrawerIconProps> = ({ color }) => (
  <Svg width="28.532" height="23.987" viewBox="0 0 28.532 23.987">
    <G data-name="グループ 3363">
      <Path
        data-name="パス 1374"
        d="M47.575 4.986h-9.844l-1.79 4.2v5.034h13.424V9.181zm-2.4 3.356a2.517 2.517 0 0 1-5.034 0h-2.93l1.074-2.517h8.735l1.08 2.517z"
        transform="translate(-33.175 -4.986)"
        fill={color}
      />
      <Path
        data-name="パス 1375"
        d="M51.088 4.986H38.271l-2.33 5.462V17h17.478v-6.552zm-3.131 4.369a3.277 3.277 0 0 1-6.554 0h-3.808l1.4-3.277h11.372l1.4 3.277z"
        transform="translate(-24.887 6.985)"
        fill={color}
      />
      <Path
        d="M8.982 2.785v4.548a.748.748 0 0 1-.711.739H2.8a.81.81 0 0 1 0-1.62h4.618V2.729l-1.7.682L8.271 0 11 3.553z"
        transform="rotate(90 -1.46 9.533)"
        fillRule="evenodd"
        fill={color}
      />
    </G>
  </Svg>
)

MigrationIcon.displayName = "MigrationIcon"
