import { ResendEmailOrderType } from "src/aws/API"
import { GqlError, resendEmailPaidVersionOrder as _resendEmailPaidVersionOrder } from "src/aws/customAPI"
import { Result } from "src/utils/Result"

type Params = {
  accessToken: string
  orderId: string
  type: ResendEmailOrderType
}

type Success = unknown

type Failure = {
  message: string
}

export const resendEmailPaidVersionOrder = async ({ accessToken, orderId, type }: Params): Promise<Result<Success, Failure>> => {
  return _resendEmailPaidVersionOrder(accessToken, {
    input: {
      orderId: orderId,
      type: type
    }
  }).then(res => {
    if (res.data?.resendEmailPaidVersionOrder.id) {
      return Result.Ok(undefined)
    } else {
      return Result.Error<Failure>({ message: "予期せぬエラーが発生しました" })
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "予期せぬエラーが発生しました"
    return Result.Error<Failure>({
      message: message
    })
  })
}