import { atom } from "recoil"

export const inboxContactFavoriteFlgsCacheState = atom<{ [contactId: string]: boolean }>({
  key: "atoms/contact/inboxContactFavoriteFlgsCacheState",
  default: {},
})

export const outboxContactFavoriteFlgsCacheState = atom<{ [contactId: string]: boolean }>({
  key: "atoms/contact/outboxContactFavoriteFlgsCacheState",
  default: {},
})

export const templateContactFavoriteFlgsCacheState = atom<{ [contactId: string]: boolean }>({
  key: "atoms/contact/templateContactFavoriteFlgsCacheState",
  default: {},
})

export const trashContactFavoriteFlgsCacheState = atom<{ [contactId: string]: boolean }>({
  key: "atoms/contact/trashContactFavoriteFlgsCacheState",
  default: {},
})

export const migrationContactFavoriteFlgsCacheState = atom<{ [contactId: string]: boolean }>({
  key: "atoms/contact/migrationContactFavoriteFlgsCacheState",
  default: {},
})

export const listOfferFavoriteFlgsCacheState = atom<{ [offerId: string]: boolean }>({
  key: "atoms/contact/listOfferFavoriteFlgsCacheState",
  default: {},
})
