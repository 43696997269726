import { IDlApiConfig, IDlAppConfig, IDlMakeUrlPayload, IDlResponse, IDlSocialMetaTag } from "./types"

export class DynamicLink {
  protected apiConfig: IDlApiConfig
  protected deepLinkDomain: string
  protected payload: IDlMakeUrlPayload

  constructor(apiConfig: IDlApiConfig, appConfig: IDlAppConfig) {
    this.apiConfig = {
      apiUrl: apiConfig?.apiUrl,
      apiKey: apiConfig?.apiKey,
    }
    this.deepLinkDomain = appConfig.deepLinkDomain
    this.payload = {
      dynamicLinkInfo: {
        domainUriPrefix: appConfig.dynamicDomain,
        link: "",
        androidInfo: {
          androidPackageName: appConfig.androidPackageName,
        },
        iosInfo: {
          iosBundleId: appConfig.iosBundleId,
          iosAppStoreId: appConfig.iosAppStoreId,
        },
        navigationInfo: {
          enableForcedRedirect: true,
        },
      },
      suffix: {
        option: "SHORT",
      },
    }
  }

  // 遷移先リンクのパスを設定する
  setLink(path: string, params?: { [key: string]: string }) {
    this.payload.dynamicLinkInfo.link = this.deepLinkDomain + path

    if (params) {
      Object.keys(params).forEach((key: string, i: number) => {
        if (i === 0) {
          this.payload.dynamicLinkInfo.link += `?${key}=${params[key]}`
        } else {
          this.payload.dynamicLinkInfo.link += `&${key}=${params[key]}`
        }
      })
    }
  }

  // アプリがインストールされていないとき、ストアからアプリをインストールする以外のURLを設定する
  set setFallbackLink(linkUrl: string) {
    this.payload.dynamicLinkInfo.androidInfo.androidFallbackLink = linkUrl
    this.payload.dynamicLinkInfo.iosInfo.iosFallbackLink = linkUrl
    this.payload.dynamicLinkInfo.iosInfo.iosIpadFallbackLink = linkUrl
    this.payload.dynamicLinkInfo.iosInfo.iosAppStoreId = undefined
  }

  // ソーシャル画面を設定する
  set setSocialMetaTag(tag: IDlSocialMetaTag) {
    this.payload.dynamicLinkInfo.socialMetaTagInfo = {
      socialTitle: tag.socialTitle,
      socialDescription: tag.socialDescription,
      socialImageLink: tag.socialImageLink,
    }
  }

  // REST APIを送信する
  async getDynamicLink(): Promise<IDlResponse> {
    const url = `${this.apiConfig.apiUrl}?key=${this.apiConfig.apiKey}`

    const config: RequestInit = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.payload),
    }
    const res = await fetch(url, config)
    return (await res.json()) as IDlResponse
  }
}
