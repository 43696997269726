import { memo, useMemo } from "react"
import { Image, StyleSheet, Text, View, useWindowDimensions } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ValueOf } from "src/types.d"
import { MatchingIcon } from "../icons/MatchingIcon"
import { BadgeIcon } from "../icons/BadgeIcon"

type Props = {
  onPress: () => void
  showBadge?: boolean
}

export const MatchingButton = memo<Props>(({ onPress, showBadge }) => {
  const isPCScreen = useCheckPCScreen()
  return (
    <View style={commonStyles.viewWrapper}>
      <Button style={[commonStyles.container, isPCScreen && { maxWidth: 300 }]} onPress={onPress}>
        <MatchingIcon />
        <Text style={[commonStyles.title]}>団体マッチング</Text>
        {showBadge && <BadgeIcon size="medium" style={commonStyles.badgeIcon} />}
      </Button>
    </View>
  )
})

const commonStyles = StyleSheet.create({
  viewWrapper: {
    width: "100%",
    paddingTop: 16,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.white3,
    paddingVertical: 14,
    paddingHorizontal: 20,
    borderRadius: 8,
    minWidth: 358,
    borderColor: Colors.orange,
    borderWidth: 1,
  },
  title: {
    marginLeft: 8,
    fontSize: 13,
    color: Colors.orange,
    fontWeight: "bold",
  },
  badgeIcon: {
    marginTop: -8,
    marginLeft: 4,
    width: 10,
    height: 10,
  },
})
