import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { teamMemberContactsSelectorFamily } from "src/recoil/selectors/contact/teamMemberContactsSelectorFamily"
import { useCallback, useMemo } from "react"
import { ContactType } from "src/constants/ContactType"
import { AttendanceAnswer, AttendanceAnswerOverview } from "src/types/contact/AttendanceAnswer"
import { useRefresher } from "src/hooks/useRefresher"
import { teamMemberContactsRequestIdState } from "src/recoil/atoms/contact/teamMemberContactsRequestIdState"
import { AttendanceType } from "src/constants/AttendanceType"
import { Schedule, ScheduleAnswer, ScheduleAnswerOverview } from "src/types/contact/ScheduleAnswer"
import { contactDetailSelectorFamily } from "src/recoil/selectors/contact/contactDetailSelectorFamily"
import { Survey, SurveyAnswer, SurveyAnswerOverview } from "src/types/contact/SurveyAnswer"
import { useAuthorization } from "src/recoil/hooks/authorization/useAuthorization"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"

const calcPercent = (all: number, part: number) => Math.round((all > 0 ? part / all : 0) * 100)
type Member = {
  id: string
  nickname: string
  memberSeq: string
}

const notNull = <T>(item: T | undefined): item is T => item != null

export const useTeamMemberContacts = (contactId: string | undefined, type?: "Inbox" | "Outbox" | "Reserved" | "Migration") => {
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const { value: contactDetail } = useAsyncSelector(contactDetailSelectorFamily({ contactId, type: type || "Inbox" }))
  const {
    value: teamMemberContacts,
    isLoading,
    error,
  } = useAsyncSelector(teamMemberContactsSelectorFamily({ contactId, type: type || "Inbox" }))
  const _rerefreshTeamMemberContacts = useRefresher(teamMemberContactsRequestIdState)
  const refreshTeamMemberContacts = useCallback(() => {
    if (isLoading) return
    _rerefreshTeamMemberContacts()
  }, [_rerefreshTeamMemberContacts, isLoading])
  const _optionalReceivedMembers = useMemo<(Member | undefined)[]>(
    () => teamMemberContacts?.items.map((item) => item.teamMember) || [],
    [teamMemberContacts]
  )
  const { allowCountUndefinedMemberAnswers } = useAuthorization({ organizationId: selectedOrganization?.id, contactId, type })
  const receivedMembers = useMemo<Member[]>(() => _optionalReceivedMembers.filter(notNull), [_optionalReceivedMembers])

  type ReadMember = Member & { readAt: Date | undefined }
  const _optionalReadMembers = useMemo<(ReadMember | undefined)[]>(
    () =>
      teamMemberContacts?.items
        .filter((item) => item.showReadFlg && item.readFlg)
        .map((item) => (item.teamMember ? { ...item.teamMember, readAt: item.readAt } : undefined)) || [],
    [teamMemberContacts]
  )
  const readMembers = useMemo<ReadMember[]>(() => _optionalReadMembers.filter(notNull), [_optionalReadMembers])

  const _optionalUnreadMembers = useMemo(
    () => teamMemberContacts?.items.filter((item) => item.showReadFlg && !item.readFlg).map((item) => item.teamMember) || [],
    [teamMemberContacts]
  )
  const unreadMembers = useMemo<Member[]>(() => _optionalUnreadMembers.filter(notNull), [_optionalUnreadMembers])

  type AnsweredMember = Member & { answeredAt: Date | undefined }
  const answeredMembers = useMemo<(AnsweredMember | undefined)[]>(
    () =>
      teamMemberContacts?.items
        .filter(
          (item) =>
            item.showAnsweredFlg &&
            item.answeredFlg &&
            item.answeredAt &&
            (allowCountUndefinedMemberAnswers || item.teamMember != null)
        )
        .map((item) => (item.teamMember ? { ...item.teamMember, answeredAt: item.answeredAt } : undefined)) || [],
    [teamMemberContacts, allowCountUndefinedMemberAnswers]
  )

  const unansweredMembers = useMemo<(Member | undefined)[]>(
    () =>
      teamMemberContacts?.items
        .filter(
          (item) => item.showAnsweredFlg && !item.answeredFlg && (allowCountUndefinedMemberAnswers || item.teamMember != null)
        )
        .map((item) => item.teamMember) || [],
    [teamMemberContacts, allowCountUndefinedMemberAnswers]
  )

  const attendanceAnswer = useMemo<AttendanceAnswer>(() => {
    const attendMembers: (AnsweredMember | undefined)[] = []
    const nonAttendMembers: (AnsweredMember | undefined)[] = []
    const pendMembers: (AnsweredMember | undefined)[] = []

    if (teamMemberContacts?.contactType === ContactType.Attendance) {
      teamMemberContacts?.items.forEach((item) => {
        if (item.showAnsweredFlg && item.answeredFlg && (allowCountUndefinedMemberAnswers || item.teamMember != null)) {
          if (item.attendanceAnswer === "Attend") {
            attendMembers.push(
              item.teamMember && item.answeredAt ? { ...item.teamMember, answeredAt: item.answeredAt } : undefined
            )
          } else if (item.attendanceAnswer === "NonAttend") {
            nonAttendMembers.push(
              item.teamMember && item.answeredAt ? { ...item.teamMember, answeredAt: item.answeredAt } : undefined
            )
          } else if (item.attendanceAnswer === "Pend") {
            pendMembers.push(
              item.teamMember && item.answeredAt ? { ...item.teamMember, answeredAt: item.answeredAt } : undefined
            )
          }
        }
      })
    }
    return {
      [AttendanceType.Attend]: {
        members: attendMembers,
      },
      [AttendanceType.NonAttend]: {
        members: nonAttendMembers,
      },
      [AttendanceType.Pend]: {
        members: pendMembers,
      },
    }
  }, [teamMemberContacts, allowCountUndefinedMemberAnswers])

  const attendanceAnswerOverview = useMemo<AttendanceAnswerOverview>(() => {
    const memberCount = answeredMembers.length + unansweredMembers.length
    const attendCount = attendanceAnswer.Attend.members.length
    const notAttendCount = attendanceAnswer.NonAttend.members.length
    const pendCount = attendanceAnswer.Pend.members.length

    return {
      [AttendanceType.Attend]: {
        count: attendCount,
        percent: calcPercent(memberCount, attendCount),
      },
      [AttendanceType.NonAttend]: {
        count: notAttendCount,
        percent: calcPercent(memberCount, notAttendCount),
      },
      [AttendanceType.Pend]: {
        count: pendCount,
        percent: calcPercent(memberCount, pendCount),
      },
    }
  }, [attendanceAnswer, answeredMembers, unansweredMembers])

  const _getScheduleAnswer = useCallback(
    (schedule: Schedule, index: number): ScheduleAnswer => {
      const attendMembers: (AnsweredMember | undefined)[] = []
      const nonAttendMembers: (AnsweredMember | undefined)[] = []
      const pendMembers: (AnsweredMember | undefined)[] = []

      if (teamMemberContacts?.contactType === ContactType.ScheduleAdjustment) {
        teamMemberContacts?.items.forEach((item) => {
          if (item.showAnsweredFlg && item.answeredFlg && (allowCountUndefinedMemberAnswers || item.teamMember != null)) {
            if (item.scheduleAnswers?.[index] === "Attend") {
              attendMembers.push(
                item.teamMember && item.answeredAt ? { ...item.teamMember, answeredAt: item.answeredAt } : undefined
              )
            } else if (item.scheduleAnswers?.[index] === "NonAttend") {
              nonAttendMembers.push(
                item.teamMember && item.answeredAt ? { ...item.teamMember, answeredAt: item.answeredAt } : undefined
              )
            } else if (item.scheduleAnswers?.[index] === "Pend") {
              pendMembers.push(
                item.teamMember && item.answeredAt ? { ...item.teamMember, answeredAt: item.answeredAt } : undefined
              )
            }
          }
        })
      }
      return {
        schedule: schedule,
        [AttendanceType.Attend]: {
          members: attendMembers,
        },
        [AttendanceType.NonAttend]: {
          members: nonAttendMembers,
        },
        [AttendanceType.Pend]: {
          members: pendMembers,
        },
      }
    },
    [teamMemberContacts, allowCountUndefinedMemberAnswers]
  )

  const scheduleAnswers = useMemo<ScheduleAnswer[]>(() => {
    const schedules = contactDetail?.contactType === "ScheduleAdjustment" ? contactDetail.schedules : []
    return schedules.map((s, i) => _getScheduleAnswer(s, i))
  }, [contactDetail, _getScheduleAnswer])

  const _getScheduleAnswerOverview = useCallback(
    (scheduleAnswer: ScheduleAnswer): ScheduleAnswerOverview => {
      const memberCount = answeredMembers.length + unansweredMembers.length
      const attendCount = scheduleAnswer.Attend.members.length
      const notAttendCount = scheduleAnswer.NonAttend.members.length
      const pendCount = scheduleAnswer.Pend.members.length

      return {
        schedule: scheduleAnswer.schedule,
        [AttendanceType.Attend]: {
          count: attendCount,
          percent: calcPercent(memberCount, attendCount),
        },
        [AttendanceType.NonAttend]: {
          count: notAttendCount,
          percent: calcPercent(memberCount, notAttendCount),
        },
        [AttendanceType.Pend]: {
          count: pendCount,
          percent: calcPercent(memberCount, pendCount),
        },
      }
    },
    [answeredMembers, unansweredMembers]
  )

  const scheduleAnswerOverviews = useMemo<ScheduleAnswerOverview[]>(
    () => scheduleAnswers.map(_getScheduleAnswerOverview),
    [scheduleAnswers, _getScheduleAnswerOverview]
  )

  const _getSurveyAnswer = useCallback(
    (survey: Survey, index: number): SurveyAnswer => {
      const answers: { members: (AnsweredMember | undefined)[] }[] = survey.choices.map(() => ({ members: [] }))

      if (teamMemberContacts?.contactType === ContactType.Survey) {
        teamMemberContacts?.items.forEach((item) => {
          if (item.showAnsweredFlg && item.answeredFlg && (allowCountUndefinedMemberAnswers || item.teamMember != null)) {
            item.questionnaireAnswers?.[index].answer.forEach((ans) => {
              answers[ans].members.push(
                item.teamMember && item.answeredAt ? { ...item.teamMember, answeredAt: item.answeredAt } : undefined
              )
            })
          }
        })
      }
      return {
        survey: survey,
        answers: answers,
      }
    },
    [teamMemberContacts, allowCountUndefinedMemberAnswers]
  )

  const surveyAnswers = useMemo<SurveyAnswer[]>(() => {
    const surveys = contactDetail?.contactType === "Survey" ? contactDetail.surveys : []
    return surveys.map((s, i) => _getSurveyAnswer(s, i))
  }, [contactDetail, _getSurveyAnswer])

  const _getSurveyAnswerOverview = useCallback(
    (surveyAnswer: SurveyAnswer): SurveyAnswerOverview => {
      const memberCount = answeredMembers.length + unansweredMembers.length

      return {
        survey: surveyAnswer.survey,
        answers: surveyAnswer.answers.map((ans) => {
          const count = ans.members.length
          return {
            count: count,
            percent: calcPercent(memberCount, count),
          }
        }),
      }
    },
    [answeredMembers, unansweredMembers]
  )

  const surveyAnswerOverviews = useMemo<SurveyAnswerOverview[]>(
    () => surveyAnswers.map(_getSurveyAnswerOverview),
    [surveyAnswers, _getSurveyAnswerOverview]
  )

  return {
    teamMemberContacts,
    isLoading,
    error,
    refreshTeamMemberContacts,
    receivedMembers,
    readMembers,
    unreadMembers,
    answeredMembers,
    unansweredMembers,
    attendanceAnswer,
    attendanceAnswerOverview,
    scheduleAnswers,
    scheduleAnswerOverviews,
    surveyAnswers,
    surveyAnswerOverviews,
  }
}
