import { useRequestData } from "src/recoil/hooks/useRequestData"
import { useCallback, useState } from "react"
import { postOrganizationReport } from "src/apis/organization/postOrganizationReport"
import { CustomAlert } from "src/utils/CustomAlert"
import { useNavigation } from "@react-navigation/native"
import { useFetcher } from "src/hooks/useFetcher"
import { convertDataEmail } from "src/utils/validate"

type Params = {
  id: string
}

export const useReportOrganizationData = ({ id }: Params) => {
  const navigation = useNavigation()
  const requestDataResult = useRequestData()

  const [reportText, setReportText] = useState("")

  const { fetch: sendOrganizationReport, isFetching: isOrganizationReportSending } = useFetcher(
    useCallback(async () => {
      if (!requestDataResult.isOk) {
        return
      }
      if ([...reportText].length === 0) {
        await CustomAlert.alert("エラー", "通報内容を記入してください")
        return
      }
      const isConfirmed = await CustomAlert.confirm("確認", "この内容で通報しますか？")
      if (!isConfirmed) {
        return
      }
      const requestData = requestDataResult.content
      const newContent = reportText ? convertDataEmail(reportText) : ""
      const result = await postOrganizationReport({ requestData, organizationId: id, reportText: newContent })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      await CustomAlert.alert("通報完了", "通報が送信されました。")
      navigation.goBack()
    }, [requestDataResult, id, reportText, navigation])
  )

  return {
    reportText,
    setReportText,
    sendOrganizationReport,
    isOrganizationReportSending,
  }
}
