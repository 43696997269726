import { ValueOf } from "src/types.d"

export const Screens = Object.freeze({
  Root: "Root",
  CreateOrganizationStack: "CreateOrganizationStack",
  EditOrganizationStack: "EditOrganizationStack",
  JoinOrganization: "JoinOrganization",
  MigrateOrganizations: "MigrateOrganizations",
  EditMigrateOrganizationStack: "EditMigrateOrganizationStack",
  RegisterProfile: "RegisterProfile",
  EditMyProfile: "EditMyProfile",
  OrganizationMembers: "OrganizationMembers",
  OrganizationMemberDetail: "OrganizationMemberDetail",
  EditOrganizationMemberDetail: "EditOrganizationMemberDetail",
  CreateOrEditGroupStack: "CreateOrEditGroupStack",
  DeleteOrganization: "DeleteOrganization",
  ReportOrganization: "ReportOrganization",
  GroupMembers: "GroupMembers",
  AuthorizationTop: "AuthorizationTop",
  SignupEmail: "SignupEmail",
  LoginEmail: "LoginEmail",
  EditEmail: "EditEmail",
  UpdateCode: "UpdateCode",
  LoginPhoneNumber: "LoginPhoneNumber",
  LoginCode: "LoginCode",
  SignupCode: "SignupCode",
  CreateNewContactStack: "CreateNewContactStack",
  ContactDetail: "ContactDetail",
  ContactAttendanceAnswerStack: "ContactAttendanceAnswerStack",
  ContactSurveyAnswerStack: "ContactSurveyAnswerStack",
  ContactScheduleAnswerStack: "ContactScheduleAnswerStack",
  ContactListCommentStack: "ContactListCommentStack",
  ContactReplyThread: "ContactReplyThread",
  PendingInvitations: "PendingInvitations",
  InvitationDialog: "InvitationDialog",
  InvitationMember: "InvitationMember",
  InvitationEmail: "InvitationEmail",
  InvitationEmailResult: "InvitationEmailResult",
  SendingHistory: "SendingHistory",
  OrganizationGroups: "OrganizationGroups",
  ExtendPaidTeam: "ExtendPaidTeam",
  ReportMatchingProfile: "ReportMatchingProfile",
  CreateOrEditMatchingProfile: "CreateOrEditMatchingProfile",
  NotificationSettings: "NotificationSettings",
  AccountInfo: "AccountInfo",
  EditAccount: "EditAccount",
  SelectAccountSchool: "SelectAccountSchool",
  EditOrRegisterPhoneNumber: "EditOrRegisterPhoneNumber",
  AuthorizationPhoneNumberCode: "AuthorizationPhoneNumberCode",
  DeleteAccount: "DeleteAccount",
  Information: "Information",
  InformationDetail: "InformationDetail",
  Licenses: "Licenses",
  NotFound: "NotFound",
  DlinkRedirect: "DlinkRedirect",
  UpdateAppInfo: "UpdateAppInfo",
  UpdateTerms: "UpdateTerms",
  Maintenance: "Maintenance",
  SubmittedContact: "SubmittedContact",
  Inquiry: "Inquiry",
  InquiryConfirm: "InquiryConfirm",
  OtherMatchingOffersScreen: "OtherMatchingOffersScreen",
  MigrationLogin: "MigrationLogin",
  MigrationTeamSelect: "MigrationTeamSelect",
  MigrationMailConfirmation: "MigrationMailConfirmation",
  MigrationTeamConfirmation: "MigrationTeamConfirmation",
  MigrationTeamComplete: "MigrationTeamComplete",
  MailMigrationAsMember: "MailMigrationAsMember",
  MigrationAsMemberFinish: "MigrationAsMemberFinish",
  MatchingThread: "MatchingThread",
  MatchingOfferCreate: "MatchingOfferCreate",
  MatchingOfferSearch: "MatchingOfferSearch",
  MigrateOrRegisterAccount: "MigrateOrRegisterAccount",
  RegisterAccount: "RegisterAccount",
  MailInviteMemberToMigrateTeam: "MailInviteMemberToMigrateTeam",
  InquiryPaidTeam: "InquiryPaidTeam",
  registerApplicantInformation: "registerApplicantInformation",
  registerPlan: "registerPlan",
  confirmPlan: "confirmPlan",
  confirmUpdatePlan: "confirmUpdatePlan",
  cancelOrder: "CancelOrder",
  confirmApplicantInfo: "confirmApplicantInfo",
} as const)

export type Screens = ValueOf<typeof Screens>
