import { selectorFamily } from "recoil"
import { listMatchingApplicationRequestIdState } from "../../atoms/matching/listMatchingApplicationRequestIdState"
import { CustomAlert } from "../../../utils/CustomAlert"
import { getAllListMatchingApplication } from "../../../apis/matching/getAllListMatchingApplication"
import { selectedMyOrganizationSelector } from "../organization/selectedMyOrganizationSelector"
import { tokensState } from "../../atoms/authorization/tokensState"
import { MatchingOffersModel } from "../../../types/matching/matchingOfferModel"

export const listMatchingApplicationSelectorFamily = selectorFamily<
  MatchingOffersModel[] | undefined,
  { keyword?: string | undefined }
>({
  key: "selectors/matting/listMatchingApplicationSelectorFamily",
  get:
    ({ keyword }) =>
    async ({ get }) => {
      get(listMatchingApplicationRequestIdState)
      const selectedMyTeam = get(selectedMyOrganizationSelector)
      if (selectedMyTeam == null) return
      const teamId = selectedMyTeam.id
      const tokens = get(tokensState)
      if (tokens == null) return
      const { accessToken } = tokens
      const params = keyword ? keyword : undefined

      const result = await getAllListMatchingApplication({
        accessToken,
        teamId,
        filter: { keyword: params },
        limit: 100,
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      return result.content
    },
})
