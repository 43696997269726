import { Result } from "src/utils/Result"
import { getTeamInvitationMessage, GqlError, sendJoinRequest } from "src/aws/customAPI"
import { crashlyticsWrap } from "src/tags/crashlytics"
import { execApi } from "src/apis/execApi"

type Params = {
  accessToken: string
  organizationId: string
}

type Success = {
  title: string
  message: string
}

type Failure = {
  message: string
}

export const getOrganizationInvitationMessage = async ({accessToken, organizationId}: Params): Promise<Result<Success, Failure>> => {
  return execApi(
    () => getTeamInvitationMessage(accessToken, {
      input: {
        teamId: organizationId,
      },
    }),
    res => {
      const {title, message} = res.getTeamInvitationMessage
      return {title, message}
    },
    {
      identifiers: ["getOrganizationInvitationMessage", "getTeamInvitationMessage"]
    }
  )
}
