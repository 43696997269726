import { GqlError, getScheduleAnswerCsvData as _getScheduleAnswerCsvData } from "src/aws/customAPI";
import { ScheduleAnswerModel } from "src/types/csv/answerCsvData";
import { Result } from "src/utils/Result";

type GetScheduleAnswerCsvDataParams = {
  accessToken: string
  teamId: string
  mailId: string
  memberId: string
}

type GetScheduleAnswerCsvDataSuccess = ScheduleAnswerModel

type GetScheduleAnswerCsvDataFailure = {
  message: string
}

export const getScheduleAnswerCsvData = async (
  params: GetScheduleAnswerCsvDataParams
): Promise<Result<GetScheduleAnswerCsvDataSuccess, GetScheduleAnswerCsvDataFailure>> => {
  return _getScheduleAnswerCsvData(params.accessToken, {
    input: {
      teamId: params.teamId,
      mailId: params.mailId,
      memberId: params.memberId,
    }
  }).then(res => {
    return Result.Ok<GetScheduleAnswerCsvDataSuccess>({
      candidates: res.candidates.map((c) => c.formatted),
      items: res.items
    });
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "予期せぬエラーが発生しました";
    return Result.Error<GetScheduleAnswerCsvDataFailure>({
      message: message
    });
  });
};