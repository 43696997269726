import { Result } from "src/utils/Result"
import { agreeToTerms } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type UpdateAcceptTermsParams = {
  accessToken: string
  termsVersion: string
}

type UpdateAcceptTermsSuccess = undefined

type UpdateAcceptTermsFailure = {
  message: string
}

export const updateAcceptTerms = async ({accessToken, termsVersion}: UpdateAcceptTermsParams): Promise<Result<UpdateAcceptTermsSuccess, UpdateAcceptTermsFailure>> => {
  return execApi(
    () => agreeToTerms(accessToken, {
      input: {
        version: termsVersion
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["updateAcceptTerms", "agreeToTerms"],
      defaultErrorMessage: "規約の同意処理に失敗しました。"
    }
  )
}
