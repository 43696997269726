import { memo } from "react"
import Svg, { Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
  size?: number
}

export const CloseIcon = memo<Props>(({ fill = Colors.greyshBrown, size = 12.874 }) => (
  <Svg width={size} height={size} viewBox="0 0 42 42" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.0007 25.2428L34.6884 38.9306L38.9311 34.688L25.2433 21.0002L38.9311 7.31246L34.6884 3.06982L21.0007 16.7576L7.31295 3.06982L3.07031 7.31246L16.7581 21.0002L3.07031 34.688L7.31295 38.9306L21.0007 25.2428Z"
      fill={fill}
    />
  </Svg>
))
