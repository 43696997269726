import { SchoolGrade } from "src/constants/SchoolGradeOptions"
import { Result } from "src/utils/Result"
import { getAccountTeamMember, MemberRole } from "src/aws/customAPI"
import { MemberType } from "src/types/organization/OrganizationMember"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type GetMyProfileParams = {
  accessToken: string
  accountId: string
  organizationId: string
}

export type GetMyProfileSuccess = {
  memberId: string
  memberType: MemberType
  nickname: string
  kanaNickname: string
  thumbnailURL: string
  isShowEmail?: boolean
  phoneNumber?: string
  isShowPhoneNumber: boolean
  affiliation?: string
  isShowAffiliation: boolean
  schoolGrade?: SchoolGrade
  memberNumber?: number
  isMailMigrated: boolean
}

type GetMyProfileFailure = {
  message: string
}

export const getMyProfile = async (params: GetMyProfileParams): Promise<Result<GetMyProfileSuccess, GetMyProfileFailure>> => {
  const {accessToken, accountId, organizationId} = params

  return execApi(
    () => getAccountTeamMember(accessToken, {
      accountId: accountId,
      teamId: organizationId,
      appInfo
    }),
    res => {
      const teamMember = res.getAccount.teamMembers.items[0]
      if (teamMember == null) throw Error('団体から退出済みです。')
      return {
        memberId: teamMember.id,
        memberType: memberTypeMapping[teamMember.role],
        nickname: teamMember.nickname,
        kanaNickname: teamMember.nicknameKana,
        thumbnailURL: 'http://www.ra9.jp/wp-content/themes/ra9/images/web_guide/guide_ren.png',
        isShowEmail: teamMember.showEmailFlg,
        phoneNumber: teamMember.phoneNumber,
        isShowPhoneNumber: teamMember.showPhoneNumberFlg,
        affiliation: teamMember.belongs,
        isShowAffiliation: teamMember.showBelongsFlg,
        schoolGrade: teamMember.grade as SchoolGrade,
        memberNumber: teamMember.memberSeq,
        isMailMigrated: teamMember.mailMigratedFlg || false,
      }
    },
    {
      identifiers: ["getMyProfile", "getAccountTeamMember"],
    }
  )
}

const memberTypeMapping: { [k in MemberRole]: MemberType } = {
  "manager": MemberType.Representative,
  "general": MemberType.General,
  "mailSender": MemberType.GeneralWithContactAuthorization
}
