import { FC } from "react"
import Svg, { ClipPath, Defs, G, Path } from "react-native-svg"

export const EducationIcon: FC = () => (
  <Svg width="43.061" height="43.061" viewBox="0 0 43.061 43.061">
    <Defs>
      <ClipPath id="vv3wvz99oa">
        <Path data-name="長方形 1582" fill="none" d="M0 0h43.061v43.061H0z" />
      </ClipPath>
    </Defs>
    <G data-name="グループ 2357">
      <G data-name="グループ 2356" clipPath="url(#vv3wvz99oa)">
        <Path
          data-name="パス 1099"
          d="M5.4 24.727a1.682 1.682 0 0 0-1.682 1.682v26.072l6.308 2.1 1.262-.42L12.973 38.6l-1.682-13.873z"
          transform="translate(-1.956 -12.995)"
          fill="#708cc6"
        />
        <Path
          data-name="パス 1100"
          d="M75.1 24.727h-5.884L67.534 38.6l1.682 15.559 1.262.42 6.308-2.1v-26.07a1.682 1.682 0 0 0-1.686-1.682"
          transform="translate(-35.491 -12.995)"
          fill="#819dd0"
        />
        <Path
          data-name="パス 1101"
          d="M24.721 24.727h-5.046v29.436l5.046-1.682z"
          transform="translate(-10.34 -12.995)"
          fill="#597ebe"
        />
        <Path
          data-name="パス 1102"
          d="M60.444 24.727v27.754l5.046 1.682V24.727z"
          transform="translate(-31.765 -12.995)"
          fill="#708cc6"
        />
        <Path
          data-name="パス 1103"
          d="M12.449 53.7h-1.682a.841.841 0 0 1-.841-.841V49.5a.841.841 0 0 1 .841-.841h1.682a.841.841 0 0 1 .841.841v3.364a.841.841 0 0 1-.841.841"
          transform="translate(-5.216 -25.571)"
          fill="#a5d9ed"
        />
        <Path
          data-name="パス 1104"
          d="M12.449 71.428h-1.682a.841.841 0 0 1-.841-.841v-3.364a.841.841 0 0 1 .841-.841h1.682a.841.841 0 0 1 .841.841v3.364a.841.841 0 0 1-.841.841"
          transform="translate(-5.216 -34.885)"
          fill="#a5d9ed"
        />
        <Path
          data-name="パス 1105"
          d="M12.449 35.977h-1.682a.841.841 0 0 1-.841-.841v-3.364a.841.841 0 0 1 .841-.841h1.682a.841.841 0 0 1 .841.841v3.364a.841.841 0 0 1-.841.841"
          transform="translate(-5.216 -16.255)"
          fill="#a5d9ed"
        />
        <Path
          data-name="パス 1106"
          d="M74.578 53.7h1.682a.841.841 0 0 0 .841-.841V49.5a.841.841 0 0 0-.841-.841h-1.682a.841.841 0 0 0-.841.841v3.364a.841.841 0 0 0 .841.841"
          transform="translate(-38.751 -25.571)"
          fill="#bfe4f3"
        />
        <Path
          data-name="パス 1107"
          d="M74.578 71.428h1.682a.841.841 0 0 0 .841-.841v-3.364a.841.841 0 0 0-.841-.841h-1.682a.841.841 0 0 0-.841.841v3.364a.841.841 0 0 0 .841.841"
          transform="translate(-38.751 -34.885)"
          fill="#bfe4f3"
        />
        <Path
          data-name="パス 1108"
          d="M74.578 35.977h1.682a.841.841 0 0 0 .841-.841v-3.364a.841.841 0 0 0-.841-.841h-1.682a.841.841 0 0 0-.841.841v3.364a.841.841 0 0 0 .841.841"
          transform="translate(-38.751 -16.255)"
          fill="#bfe4f3"
        />
        <Path
          data-name="パス 1109"
          d="m35.051 45.749.452.09 10.513-2.1V14.5L35.5 8.088 26.588 14.5v29.237z"
          transform="translate(-13.973 -4.25)"
          fill="#f3f3f9"
        />
        <Path
          data-name="パス 1110"
          d="M33.733 4.387 23.22 8.86v32.932L33.281 43.8a51.709 51.709 0 0 1-5.015-22.492 53.766 53.766 0 0 1 1.152-11.12.841.841 0 0 1 .482-.588l3.833-1.7 10.513 4.654V8.86z"
          transform="translate(-12.203 -2.306)"
          fill="#eaeaf5"
        />
        <Path
          data-name="パス 1111"
          d="M40.254 18.169a4.626 4.626 0 1 1-4.626 4.626 4.626 4.626 0 0 1 4.626-4.626"
          transform="translate(-18.723 -9.548)"
          fill="#f5e768"
        />
        <Path
          data-name="パス 1112"
          d="m38.341 74.058.888.178 6.308-1.262v-9.251h-10.6L33.679 68.9z"
          transform="translate(-17.699 -33.488)"
          fill="#bfe4f3"
        />
        <Path
          data-name="パス 1113"
          d="M32.083 63.723v9.251l5.417 1.084a49.982 49.982 0 0 1-3.4-10.335z"
          transform="translate(-16.86 -33.488)"
          fill="#a5d9ed"
        />
        <Path
          data-name="パス 1114"
          d="M34.961 63.122h10.734a.841.841 0 0 0 .841-.841v-1.262a.841.841 0 0 0-.841-.841H34.344l-1.165 1.472z"
          transform="translate(-17.436 -31.625)"
          fill="#ef8d85"
        />
        <Path
          data-name="パス 1115"
          d="M30.265 60.178a.841.841 0 0 0-.841.841v1.261a.841.841 0 0 0 .841.841h2.722q-.348-1.452-.617-2.944z"
          transform="translate(-15.463 -31.625)"
          fill="#ec766b"
        />
        <Path
          data-name="パス 1116"
          d="M41.669 86.165H62.6a.841.841 0 0 0 .841-.841v-1.262a.841.841 0 0 0-.841-.841H41.172l-1.2 1.472z"
          transform="translate(-21.009 -43.735)"
          fill="#758697"
        />
        <Path
          data-name="パス 1117"
          d="M20.861 83.221H2.17a.841.841 0 0 0-.841.841v1.261a.841.841 0 0 0 .841.841h19.188a5.16 5.16 0 0 1-.533-2.313 5.4 5.4 0 0 1 .036-.63z"
          transform="translate(-.698 -43.735)"
          fill="#5d6f7e"
        />
        <Path
          data-name="パス 1118"
          d="m35.6 6.107 3.386-1.5a.842.842 0 0 1 .681 0l11.089 4.912a.841.841 0 0 0 1.181-.769v-.977a1.682 1.682 0 0 0-1-1.538L40.343 1.546a2.522 2.522 0 0 0-2.043 0l-2.546 1.127L33.855 4.5z"
          transform="translate(-17.792 -.699)"
          fill="#758697"
        />
        <Path
          data-name="パス 1119"
          d="M27.836 4.161 19.79 7.723a1.682 1.682 0 0 0-1 1.538v.977a.841.841 0 0 0 1.181.769L27.678 7.6a5.308 5.308 0 0 1-.209-1.487 5.218 5.218 0 0 1 .368-1.947"
          transform="translate(-9.874 -2.187)"
          fill="#5d6f7e"
        />
        <Path
          data-name="パス 1120"
          d="M77.034 48.8a1.474 1.474 0 0 0-1.472-1.472H73.88a1.473 1.473 0 0 0-1.472 1.472v3.364a1.473 1.473 0 0 0 1.472 1.472h1.682a1.474 1.474 0 0 0 1.472-1.472zm-1.262 3.364a.211.211 0 0 1-.21.21H73.88a.211.211 0 0 1-.21-.21V48.8a.211.211 0 0 1 .21-.21h1.682a.211.211 0 0 1 .21.21z"
          transform="translate(-38.052 -24.872)"
          fill="#545151"
        />
        <Path
          data-name="パス 1121"
          d="M75.562 65.053H73.88a1.473 1.473 0 0 0-1.472 1.472v3.364a1.473 1.473 0 0 0 1.472 1.472h1.682a1.474 1.474 0 0 0 1.472-1.472v-3.364a1.474 1.474 0 0 0-1.472-1.472m.21 4.836a.211.211 0 0 1-.21.21H73.88a.211.211 0 0 1-.21-.21v-3.364a.211.211 0 0 1 .21-.21h1.682a.211.211 0 0 1 .21.21z"
          transform="translate(-38.052 -34.187)"
          fill="#545151"
        />
        <Path
          data-name="パス 1122"
          d="M77.034 31.074a1.474 1.474 0 0 0-1.472-1.474H73.88a1.473 1.473 0 0 0-1.472 1.472v3.364a1.473 1.473 0 0 0 1.472 1.474h1.682a1.474 1.474 0 0 0 1.472-1.472zm-1.262 3.364a.211.211 0 0 1-.21.21H73.88a.211.211 0 0 1-.21-.21v-3.364a.211.211 0 0 1 .21-.21h1.682a.211.211 0 0 1 .21.21z"
          transform="translate(-38.052 -15.557)"
          fill="#545151"
        />
        <Path
          data-name="パス 1123"
          d="M51.744 48.614h1.682a.841.841 0 0 0 .841-.841v-3.364a.841.841 0 0 0-.841-.841h-1.682a.841.841 0 0 0-.841.841v3.364a.841.841 0 0 0 .841.841"
          transform="translate(-26.751 -22.896)"
          fill="#bfe4f3"
        />
        <Path
          data-name="パス 1124"
          d="M54.2 43.71a1.474 1.474 0 0 0-1.472-1.472h-1.682a1.473 1.473 0 0 0-1.472 1.472v3.364a1.473 1.473 0 0 0 1.472 1.472h1.682a1.474 1.474 0 0 0 1.472-1.472zm-1.262 3.364a.211.211 0 0 1-.21.21h-1.682a.211.211 0 0 1-.21-.21V43.71a.211.211 0 0 1 .21-.21h1.682a.211.211 0 0 1 .21.21z"
          transform="translate(-26.052 -22.197)"
          fill="#545151"
        />
        <Path
          data-name="パス 1125"
          d="M34.23 48.614h1.682a.841.841 0 0 0 .841-.841v-3.364a.841.841 0 0 0-.841-.841H34.23a.841.841 0 0 0-.841.841v3.364a.841.841 0 0 0 .841.841"
          transform="translate(-17.547 -22.896)"
          fill="#bfe4f3"
        />
        <Path
          data-name="パス 1126"
          d="M36.686 43.71a1.474 1.474 0 0 0-1.472-1.472h-1.682a1.473 1.473 0 0 0-1.472 1.472v3.364a1.473 1.473 0 0 0 1.472 1.472h1.682a1.474 1.474 0 0 0 1.472-1.472zm-1.262 3.364a.211.211 0 0 1-.21.21h-1.682a.211.211 0 0 1-.21-.21V43.71a.211.211 0 0 1 .21-.21h1.682a.211.211 0 0 1 .21.21z"
          transform="translate(-16.848 -22.197)"
          fill="#545151"
        />
        <Path
          data-name="パス 1127"
          d="M8.6 52.163a1.474 1.474 0 0 0 1.472 1.472h1.682a1.473 1.473 0 0 0 1.472-1.472V48.8a1.473 1.473 0 0 0-1.472-1.472h-1.685A1.474 1.474 0 0 0 8.6 48.8zM9.859 48.8a.211.211 0 0 1 .21-.21h1.682a.211.211 0 0 1 .21.21v3.364a.211.211 0 0 1-.21.21h-1.682a.211.211 0 0 1-.21-.21z"
          transform="translate(-4.518 -24.872)"
          fill="#545151"
        />
        <Path
          data-name="パス 1128"
          d="M8.6 69.888a1.474 1.474 0 0 0 1.472 1.472h1.682a1.473 1.473 0 0 0 1.472-1.472v-3.364a1.473 1.473 0 0 0-1.472-1.472h-1.685A1.474 1.474 0 0 0 8.6 66.524zm1.262-3.364a.211.211 0 0 1 .21-.21h1.682a.211.211 0 0 1 .21.21v3.364a.211.211 0 0 1-.21.21h-1.685a.211.211 0 0 1-.21-.21z"
          transform="translate(-4.518 -34.187)"
          fill="#545151"
        />
        <Path
          data-name="パス 1129"
          d="M10.069 35.91h1.682a1.473 1.473 0 0 0 1.472-1.472v-3.364a1.473 1.473 0 0 0-1.472-1.474h-1.682A1.474 1.474 0 0 0 8.6 31.074v3.364a1.474 1.474 0 0 0 1.472 1.472m-.21-4.836a.211.211 0 0 1 .21-.21h1.682a.211.211 0 0 1 .21.21v3.364a.211.211 0 0 1-.21.21h-1.685a.211.211 0 0 1-.21-.21z"
          transform="translate(-4.518 -15.557)"
          fill="#545151"
        />
        <Path
          data-name="パス 1130"
          d="M39.555 27.352A5.256 5.256 0 1 0 34.3 22.1a5.262 5.262 0 0 0 5.256 5.256m0-9.251a3.995 3.995 0 1 1-4 4 4 4 0 0 1 4-4"
          transform="translate(-18.025 -8.849)"
          fill="#545151"
        />
        <Path
          data-name="パス 1131"
          d="M41.278 27.01a.631.631 0 0 1 .282-.846l1.333-.667v-1.292a.631.631 0 1 1 1.262 0v1.682a.631.631 0 0 1-.349.564l-1.682.841a.634.634 0 0 1-.846-.282"
          transform="translate(-21.658 -12.389)"
          fill="#545151"
        />
        <Path
          data-name="パス 1132"
          d="M41.925 38.894v-2.351a.631.631 0 1 0-1.262 0v2.313h-7.99V12.362h6.938a1.053 1.053 0 0 1 1.051 1.051V33.6a.631.631 0 0 0 1.262 0V13.414a2.315 2.315 0 0 0-2.312-2.314h-6.938V9.381l.035.016a1.472 1.472 0 0 0 2.067-1.347v-.977A2.315 2.315 0 0 0 33.4 4.958L22.807.269a3.163 3.163 0 0 0-2.553 0L9.661 4.958a2.315 2.315 0 0 0-1.377 2.115v.977a1.471 1.471 0 0 0 2.068 1.35l.035-.016V11.1H3.448a2.315 2.315 0 0 0-2.313 2.313v3.364a.631.631 0 1 0 1.261 0v-3.363a1.053 1.053 0 0 1 1.051-1.051h6.939v26.492H2.4V19.722a.631.631 0 0 0-1.261 0v19.172A1.474 1.474 0 0 0 0 40.327v1.262a1.473 1.473 0 0 0 1.472 1.472h40.117a1.474 1.474 0 0 0 1.472-1.472v-1.262a1.474 1.474 0 0 0-1.136-1.433zM9.841 8.243a.212.212 0 0 1-.3-.193v-.977a1.052 1.052 0 0 1 .626-.961l10.597-4.689a1.908 1.908 0 0 1 1.532 0l10.593 4.689a1.051 1.051 0 0 1 .625.961v.977a.212.212 0 0 1-.3.193L22.126 3.332a1.475 1.475 0 0 0-1.191 0zm1.807.579 9.8-4.337a.213.213 0 0 1 .17 0l9.8 4.337v30.033h-2.949v-6.743a1.474 1.474 0 0 0 1.262-1.457v-1.261a1.473 1.473 0 0 0-1.472-1.472H14.8a1.473 1.473 0 0 0-1.472 1.472v1.262a1.474 1.474 0 0 0 1.262 1.457v6.743h-2.942zm16.61 22.043H14.8a.211.211 0 0 1-.21-.21v-1.261a.211.211 0 0 1 .21-.21h13.458a.211.211 0 0 1 .21.21v1.262a.211.211 0 0 1-.21.21m-12.405 1.261H20.9v6.729h-5.047zm6.308 0h5.046v6.729h-5.046zM41.8 41.589a.211.211 0 0 1-.21.21H1.472a.21.21 0 0 1-.21-.21v-1.262a.21.21 0 0 1 .21-.21h40.117a.211.211 0 0 1 .21.21z"
          fill="#545151"
        />
      </G>
    </G>
  </Svg>
)

EducationIcon.displayName = "EducationIcon"
