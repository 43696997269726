import { memo, useCallback, useState } from "react"
import { Modal, StyleSheet, Text, View, ScrollView } from "react-native"
import { CreateOrganizationIcon } from "src/components/parts/icons/CreateOrganizationIcon"
import { Colors } from "src/constants/Colors"
import { Button } from "./buttons/Button"
import { JoinIcon } from "./icons/JoinIcon"
import { OrganizationMigrateIcon } from "src/components/parts/icons/OrganizationMigrateIcon"
import { OrganizationMigrationDescription } from "src/components/parts/OrganizationMigrationDescription"
import { OrganizationListTeamMigrateIcon } from "./icons/OrganizationListTeamMigrateIcon"
import { CustomRefreshControl } from "../projects/CustomRefreshControl"
import { migration } from "src/constants/links/migration"
import * as WebBrowser from "expo-web-browser"
import { ItemLabel } from "./ItemLabel"

type Props = {
  join: () => void
  create: () => void
  migrate: () => void
  teamMigrate: () => void
  refresh?: () => void
  isLoadingRefesh?: boolean
}

export const JoinOrCreateOrganizationActionView = memo<Props>(
  ({ join, create, migrate, teamMigrate, refresh, isLoadingRefesh }) => {
    const [modalVisible, setModalVisible] = useState(false)
    const openModal = useCallback(() => setModalVisible(true), [])
    const closeModal = useCallback(() => setModalVisible(false), [])
    const openMigrationDescription = useCallback(async () => {
      await WebBrowser.openBrowserAsync(migration.description)
    }, [])
    return (
      <ScrollView
        refreshControl={
          <CustomRefreshControl refreshing={isLoadingRefesh || false} onRefresh={refresh ? refresh : undefined} />
        }
      >
        <View style={styles.migrateContainer}>
          <View style={styles.popUpNoticeText}>
            <ItemLabel label="団体移行" />
          </View>
          <View style={styles.popUpNoticeText}>
            <Text>らくらく連絡網（旧サービス）の団体を、らくらく連絡網＋に移行できます。</Text>
          </View>
          <View style={styles.popUpNoticeText}>
            <View style={[{ display: "flex", flexDirection: "row", paddingBottom: 16 }]}>
              <View style={{ flexDirection: "row" }}>
                <Text style={[styles.contentLink, { paddingRight: 3 }]}>※</Text>
                <Text style={[styles.contentLink]}>団体移行のガイドページは </Text>
              </View>
              <Text onPress={openMigrationDescription}>
                <Text style={styles.linkText}>こちら</Text>
                <Text>。</Text>
              </Text>
            </View>
          </View>
          <View style={styles.doubleButtonHeader}>
            <View style={styles.buttonContainer}>
              <Button style={styles.migrateButtonContainer} onPress={migrate}>
                <View style={styles.centerRow}>
                  <OrganizationMigrateIcon />
                  <View>
                    <Text style={[styles.migrateButtonText, { marginTop: 10 }]}>団体情報を移行</Text>
                  </View>
                </View>
              </Button>
            </View>
            <View style={styles.buttonContainer}>
              <Button style={styles.listTeamMigrateButtonContainer} onPress={teamMigrate}>
                <View style={styles.centerRow}>
                  <OrganizationListTeamMigrateIcon />
                  <View>
                    <Text style={[styles.migrateButtonText, { marginTop: 10 }]}>移行団体を管理</Text>
                  </View>
                </View>
              </Button>
            </View>
          </View>
        </View>
        <View style={styles.container}>
          <View style={{ paddingTop: 10, paddingBottom: 24, width: "100%" }}>
            <View style={{}}>
              <Text>{`所属している団体がありません。\n既存の団体に参加するか、新しく団体を作成してください。`}</Text>
            </View>
          </View>
          <Button onPress={join} style={[styles.button, styles.joinButton]}>
            <JoinIcon />
            <Text style={styles.text}>団体に参加</Text>
          </Button>
          <Button onPress={create} style={[styles.button, styles.createButton, styles.tail]}>
            <CreateOrganizationIcon />
            <Text style={styles.text}>団体を作成</Text>
          </Button>
          <Modal visible={modalVisible} onRequestClose={closeModal} transparent>
            <View style={styles.modalWrapper}>
              <OrganizationMigrationDescription onClose={closeModal} />
            </View>
          </Modal>
        </View>
      </ScrollView>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 32,
    paddingVertical: 24,
    backgroundColor: Colors.white,
  },
  text: {
    color: Colors.white,
    textAlign: "center",
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: 22,
  },
  button: {
    width: "100%",
    height: 100,
    borderRadius: 6,
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  tail: {
    marginTop: 20,
  },
  joinButton: {
    backgroundColor: Colors.leaf,
  },
  createButton: {
    backgroundColor: Colors.orange,
  },
  migrateButton: {
    backgroundColor: Colors.orange,
  },
  aboutMigrationContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 24,
  },
  aboutMigrationText: {
    color: Colors.greyshBrown,
    textDecorationLine: "underline",
    marginLeft: 8,
  },
  popUpNoticeContainer: {
    flexDirection: "row",
    marginTop: 24,
  },
  popUpNoticeText: {
    color: Colors.important,
    marginLeft: 8,
    marginBottom: 10,
  },
  modalWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.37)",
    padding: 22,
  },
  headerBar: {
    height: 40,
    backgroundColor: Colors.red,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  contentLink: {
    fontSize: 14,
    fontWeight: "400",
    textAlign: "left",
  },
  linkText: {
    color: Colors.orange,
    textDecorationLine: "underline",
  },
  migrateContainer: {
    paddingHorizontal: 3,
    paddingVertical: 16,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
    backgroundColor: Colors.white3,
  },
  doubleButtonHeader: {
    flexDirection: "row",
    width: "100%",
  },
  buttonContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 5,
    paddingHorizontal: 10,
    flexDirection: "row",
    width: "43%",
  },
  centerRow: {
    alignItems: "center",
  },
  migrateButtonContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 17,
    backgroundColor: Colors.orange,
    borderRadius: 10,
    width: "100%",
    height: "100%",
  },
  listTeamMigrateButtonContainer: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    paddingVertical: 10,
    paddingHorizontal: 17,
    backgroundColor: Colors.leaf,
    borderRadius: 10,
    width: "100%",
    height: "100%",
  },
  migrateButtonText: {
    color: Colors.white3,
    fontSize: 15,
    fontWeight: "700",
    lineHeight: 20,
    textAlign: "center",
  },
})
