import { selectorFamily } from "recoil"
import { getOrganizationMembers } from "src/apis/organization/getOrganizationMembers"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { organizationMembersRequestIdState } from "src/recoil/atoms/organization/organizationMembersRequestIdState"
import { OrganizationMemberOverview } from "src/types/organization/OrganizationMember"
import { multiTeamMemberImageUrls } from "src/apis/team/getMemberImageUrl"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"

export const organizationMembersSelectorFamily = selectorFamily<
  OrganizationMemberOverview[] | undefined,
  { organizationId: string; getImage: boolean; joinFlg?: boolean }
>({
  key: "selectors/organization/organizationMembersSelector",
  get:
    ({ organizationId, getImage, joinFlg }) =>
    async ({ get }) => {
      if (organizationId == null) return

      get(organizationMembersRequestIdState)
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) {
        return
      }
      const accountId = get(accountIdState)
      if (accountId == null) {
        return
      }
      const result = await getOrganizationMembers({ accessToken, organizationId, accountId })
      if (!result.isOk) {
        return
      }
      if (!getImage) {
        return result.content
      }

      const teamMemberList =
        result.content
          ?.map((mem) => {
            return { teamMemberId: mem.id, thumbnail: false }
          })
          .flat() || []
      const data = await multiTeamMemberImageUrls({ accessToken: accessToken, teamMemberImages: teamMemberList })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let teamMemberImageList: any[] = []
      if (data.isOk) {
        teamMemberImageList = data.content
      }

      return (
        result.content.map((item) => ({
          id: item.id,
          nickname: item.nickname,
          imageUrl: teamMemberImageList.find((o) => o.teamMemberId === item.id).url,
          memberNumber: item.memberNumber,
          memberType: item.memberType,
          isLeader: item.isLeader,
          isInGroup: item.isInGroup,
          isAdministrator: item.isAdministrator,
          groupIds: item.groupIds,
          joinFlg: joinFlg,
          memberSeq: item.memberSeq,
        })) || []
      )
    },
})
