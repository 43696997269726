import { useCallback, useMemo } from "react"
import { Text, View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { ShowDate, getToday } from "src/utils/useDateTime"
import { MatchingOffersModel } from "src/types/matching/matchingOfferModel"
import { styles } from "./styles"
import Swipeable from "react-native-gesture-handler/Swipeable"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { PermanentlyDeleteIcon } from "../icons/PermanentlyDeleteIcon"
import { LocationPinIcon } from "../icons/LocationPinIcon"
import { OclockIcon } from "../icons/OclockIcon"
dayjs.extend(duration)

type Props = {
  data: MatchingOffersModel
  onPress: () => void
  onDelete: () => void
}

export const DraftMatchingCard = ({ data, onPress, onDelete }: Props) => {
  const { title, date, startTime, endTime, city, prefecture, activity, ageFrom, ageTo, publishEndDate, teamName } = data
  const { today } = getToday()
  const formatDateTime = useMemo(
    () => ShowDate(date ? new Date(date) : null, startTime, endTime) + " ",
    [date, startTime, endTime]
  )
  const formatPublishEndDatetime = useMemo(() => publishEndDate && ShowDate(new Date(publishEndDate), true), [publishEndDate])
  const isLargeScreen = useCheckPCScreen()

  const renderRightActions = useCallback(
    () => (
      <Button style={[styles.swipeToDeleteContainer]} onPress={onDelete}>
        <View style={styles.deleteTrashIconContainer}>
          <PermanentlyDeleteIcon />
          <Text style={styles.removeIconText}>削除</Text>
        </View>
      </Button>
    ),
    [onDelete]
  )
  return (
    <Swipeable
      overshootRight={false}
      renderRightActions={renderRightActions}
      enabled={!isLargeScreen}
      containerStyle={[styles.swipeableContainer]}
    >
      <View style={[styles.swipeableContainer]}>
        <Button style={[styles.container]} onPress={onPress}>
          <View style={[styles.viewContainer, { padding: 16 }]}>
            {publishEndDate && <Text style={styles.litmitTime}>募集期限：{formatPublishEndDatetime}まで</Text>}
            <Text style={styles.titleMatching}>{title}</Text>

            <View style={styles.row}>
              <Text style={[styles.bgLabel]}>{activity?.label}</Text>
              {(!!ageFrom || !!ageTo) && (
                <Text style={[styles.bgLabel]}>
                  {ageFrom}～{ageTo}歳
                </Text>
              )}
              <Text style={[styles.bgLabel]}>合同練習・試合</Text>
            </View>
            <View style={styles.row}>
              <OclockIcon />
              <Text style={[styles.text, { marginLeft: 2 }]}>開催日時：{formatDateTime}</Text>
              {(startTime || endTime) && (
                <Text style={styles.text}>
                  {startTime ?? ""}～{endTime ?? today}
                </Text>
              )}
            </View>
            {(prefecture || city) && (
              <View style={styles.row}>
                <LocationPinIcon />
                <Text style={[styles.text, { marginLeft: 2 }]}>
                  {prefecture?.label} {city?.label}
                </Text>
              </View>
            )}
            {teamName && (
              <View style={styles.row}>
                <Text style={styles.text}>{teamName}</Text>
              </View>
            )}
            {isLargeScreen ? (
              <View style={styles.rowEnd}>
                <Button onPress={onDelete}>
                  <View style={styles.deleteTrashIconContainer}>
                    <PermanentlyDeleteIcon />
                  </View>
                </Button>
              </View>
            ) : (
              <></>
            )}
          </View>
        </Button>
      </View>
    </Swipeable>
  )
}
