import { memo } from "react"
import { Svg, Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
}

export const MatchingFavoriteIcon = memo<Props>(({ fill = Colors.orange }) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Path
        d="M5.19568 22L7.00101 14.2325L1 9.01203L8.90433 8.32329L12 1L15.0957 8.32329L23 9.01203L16.999 14.2325L18.8043 22L12 17.8759L5.19568 22Z"
        fill={fill}
      />
    </Svg>
  )
})
