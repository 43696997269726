import { useCallback, useEffect, useMemo } from "react"
import { FlatList, ListRenderItemInfo, StyleSheet, View } from "react-native"
import { useRecoilValue } from "recoil"
import { deleteMatchingOfferDraft } from "src/apis/matching/matchingOfferDraft"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { DrawerStatus } from "src/components/parts/contactNetworkTab/DrawerStatus"
import { DraftIcon } from "src/components/parts/icons/drawer/DraftIcon"
import { DraftMatchingCard } from "src/components/parts/matching/DraftMatchingCard"
import { MatchingListEmpty } from "src/components/parts/matching/MatchingListEmpty"
import { MatchingListFooter } from "src/components/parts/matching/MatchingListFooter"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { Screens } from "src/constants/Screens"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { useFetcher } from "src/hooks/useFetcher"
import { useRefresher } from "src/hooks/useRefresher"
import { MainLayout } from "src/layouts/MainLayout"

import { listMatchingOfferDraftRequestIdState } from "src/recoil/atoms/matching/listMatchingOfferDraftRequestIdState"
import { routeNameState } from "src/recoil/atoms/routeNameState"
import { useMyMatchingOffers } from "src/recoil/hooks/matching/useMyMatchingOffers"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { listMatchingOfferDraftSelector } from "src/recoil/selectors/matching/listMatchingOfferDraftSelector"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { OrganizationManagerStackScreenProps } from "src/types.d"
import { MatchingOffersModel } from "src/types/matching/matchingOfferModel"
import { CustomAlert } from "src/utils/CustomAlert"

export const useMatchingOfferDraft = () => {
  const { value: matchingOfferDrafts, isLoading } = useAsyncSelector(listMatchingOfferDraftSelector)
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const { value: organizationDetail } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))
  const organizationName = useMemo(() => organizationDetail?.organizationName, [organizationDetail])
  const refreshMatchingOfferDrafts = useRefresher(listMatchingOfferDraftRequestIdState)
  const requestDataResult = useRequestData()
  const { fetch: execDeleteMatchingOfferDraft } = useFetcher(
    useCallback(
      async (id: string) => {
        if (!requestDataResult.isOk) return
        const { accountId, accessToken } = requestDataResult.content
        if (accountId == null || accessToken == null) return
        const isConfirmed = await CustomAlert.confirm("確認", "この下書きを削除しますか？削除後は復元できません。")
        if (!isConfirmed) return
        const result = await deleteMatchingOfferDraft({ accessToken, draftId: id })
        if (result?.isOk) {
          refreshMatchingOfferDrafts()
          CustomAlert.alert("完了", "下書きを削除しました。")
        } else {
          CustomAlert.alert("エラー", result.error.message)
        }
      },
      [requestDataResult, refreshMatchingOfferDrafts]
    )
  )

  return {
    matchingOfferDrafts,
    organizationId,
    organizationName,
    isLoading,
    refreshMatchingOfferDrafts,
    execDeleteMatchingOfferDraft,
  }
}

export const MyMatchingDraftOffer = ({
  navigation,
  route,
}: OrganizationManagerStackScreenProps<typeof MatchingDrawers.MyMatchingDraftOffer>) => {
  const routeName = useRecoilValue(routeNameState)
  const { matchingOfferDrafts, isLoading, refreshMatchingOfferDrafts, execDeleteMatchingOfferDraft } = useMatchingOfferDraft()
  const { checkCreateNewOffer, resetValueCreateOffer } = useMyMatchingOffers()

  const gotoMyOfferDetail = async (item: any) => {
    navigation.navigate(Screens.MatchingOfferCreate, { draftId: item.id })
  }

  const gotoCreateNewMatching = async () => {
    resetValueCreateOffer()

    checkCreateNewOffer(() => navigation.navigate(Screens.MatchingOfferCreate))
  }

  const ListItem = (listRenderItemInfo: ListRenderItemInfo<MatchingOffersModel>) => {
    const { item } = listRenderItemInfo
    return (
      <View style={styles.contentWrapper}>
        <DraftMatchingCard
          data={item}
          onPress={() => checkCreateNewOffer(() => gotoMyOfferDetail(item))}
          onDelete={() => execDeleteMatchingOfferDraft(item.id)}
        />
      </View>
    )
  }

  useEffect(() => {
    if (routeName === MatchingDrawers.MyMatchingDraftOffer) {
      refreshMatchingOfferDrafts()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeName])

  return (
    <MainLayout>
      <View style={[styles.container]}>
        <DrawerStatus Icon={<DraftIcon color={Colors.orange} />} label="募集の下書き" />
        {isLoading ? (
          <View style={styles.loading}>
            <LoadingIndicator />
          </View>
        ) : (
          <FlatList
            data={matchingOfferDrafts}
            renderItem={ListItem}
            ListEmptyComponent={!isLoading ? <MatchingListEmpty label="下書きがありません。" /> : undefined}
            refreshing={isLoading}
            refreshControl={<CustomRefreshControl refreshing={isLoading} onRefresh={refreshMatchingOfferDrafts} />}
            keyExtractor={(item) => item.id}
          />
        )}
      </View>
      <MatchingListFooter
        onPress={gotoCreateNewMatching}
        closeCallback={() => navigation.navigate(MatchingDrawers.OtherMatchingOffer)}
      />
    </MainLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  containerLarge: {
    flex: 1,
    backgroundColor: Colors.white,
    paddingLeft: 10,
    paddingRight: 10,
  },
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.white,
    height: 50,
  },
  headerTitle: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginLeft: 8,
  },
  contentWrapper: {
    display: "flex",
    alignItems: "center",
    paddingHorizontal: 16,
    paddingTop: 10,
  },
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
})
