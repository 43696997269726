import { memo, useCallback, useMemo } from "react"
import { StyleSheet, TouchableOpacity, View } from "react-native"
import { Path, Svg } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  isChecked?: boolean
  isDisabled?: boolean
  onPress?: (next: boolean) => void
}

type IconProps = {
  fill?: string
}

const Check = memo<IconProps>(({ fill }) => (
  <Svg width="18" height="14" viewBox="0 0 18 14" fill="none">
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.9371 0.828691C17.5839 1.34621 17.6888 2.29014 17.1713 2.93704L9.17131 12.937C8.91186 13.2613 8.52956 13.4636 8.11548 13.4955C7.70139 13.5275 7.29258 13.3864 6.98642 13.1057L0.986422 7.60573C0.375743 7.04594 0.334489 6.09709 0.894278 5.48641C1.45407 4.87573 2.40292 4.83447 3.0136 5.39426L7.83094 9.81016L14.8287 1.06295C15.3462 0.416059 16.2902 0.311177 16.9371 0.828691Z"
      fill={fill}
    />
  </Svg>
))

Check.displayName = "Check"

export const Checkbox = memo<Props>(({ isChecked, onPress, isDisabled }) => {
  const styles = useMemo(() => (isDisabled ? disabledStyles : enabledStyles), [isDisabled])
  const handlePress = useCallback(() => onPress?.(!isChecked), [onPress, isChecked])

  return (
    <TouchableOpacity style={commonStyles.container} onPress={handlePress} disabled={isDisabled}>
      <View style={[commonStyles.item, isChecked ? styles.checkedItem : styles.uncheckedItem]}>
        {isChecked ? <Check fill={Colors.white} /> : null}
      </View>
    </TouchableOpacity>
  )
})

Checkbox.displayName = "Checkbox"

const size = 24

const commonStyles = StyleSheet.create({
  container: {
    position: "relative",
    width: size,
    height: size,
    backgroundColor: Colors.white3,
  },
  item: {
    position: "absolute",
    width: size,
    height: size,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4,
    borderWidth: 1,
  },
})

const enabledStyles = StyleSheet.create({
  checkedItem: {
    borderColor: Colors.orange,
    backgroundColor: Colors.orange,
  },
  uncheckedItem: {
    borderColor: Colors.white2,
  },
})

const disabledStyles = StyleSheet.create({
  checkedItem: {
    borderColor: Colors.pale,
    backgroundColor: Colors.pale,
  },
  uncheckedItem: {
    borderColor: Colors.white4,
  },
})
