import { memo, useCallback } from "react"
import { FlatList, ListRenderItemInfo, Platform, StyleSheet, Text, useWindowDimensions, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Button } from "src/components/parts/buttons/Button"
import { TextButton, ButtonType } from "src/components/parts/buttons/TextButton"
import { Checkbox } from "src/components/parts/Checkbox"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { SearchInput } from "src/components/parts/SearchInput"
import { Colors } from "src/constants/Colors"
import { CreateNewContactScreens } from "src/constants/CreateNewContactScreens"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useSelectGroupsScreenData } from "src/recoil/hooks/screens/useSelectGroupsScreenData"
import { CreateNewContactStackScreenProps } from "src/types.d"

type Props = CreateNewContactStackScreenProps<typeof CreateNewContactScreens.SelectGroup>

export const SelectGroupsScreen = memo<Props>(({ navigation }) => {
  const widthScreen = useWindowDimensions().width
  const isLargeScreen = useCheckPCScreen()

  const goToCreateNewContact = useCallback(() => {
    navigation.navigate(CreateNewContactScreens.CreateNewContact)
  }, [navigation])

  const { searchText, setSearchText, filteredGroups, toggleGroup, selectGroups } = useSelectGroupsScreenData({
    goToCreateNewContact,
  })

  const RenderItem = useCallback(
    (info: ListRenderItemInfo<{ id: string; groupName: string; isChecked?: boolean }>) => {
      const toggle = () => toggleGroup(info.item.id)
      return (
        <View
          style={{
            borderBottomWidth: 1,
            borderColor: info.item.isChecked || filteredGroups?.[info.index + 1]?.isChecked ? Colors.orange : Colors.white2,
            backgroundColor: info.item.isChecked ? Colors.pale : Colors.white3,
          }}
        >
          <Button onPress={toggle} style={styles.button}>
            <Text style={styles.groupName}>{info.item.groupName}</Text>
            <Checkbox isChecked={info.item.isChecked} onPress={toggle} />
          </Button>
        </View>
      )
    },
    [toggleGroup, filteredGroups]
  )

  const insets = useSafeAreaInsets()

  return (
    <CustomKeyboardAvoidingView>
      <View style={isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : {}}>
        <View style={styles.headingContainer}>
          <Text style={styles.headingText}>宛先に追加するグループを選択してください</Text>
        </View>
        <SearchInput
          value={searchText}
          onChangeText={setSearchText}
          placeholder="グループ名で検索"
          style={styles.searchInput}
        />
      </View>

      <FlatList
        data={filteredGroups}
        renderItem={RenderItem}
        contentContainerStyle={[
          {
            borderTopWidth: 0,
            borderColor:
              filteredGroups != null && filteredGroups.length !== 0 && filteredGroups[0].isChecked
                ? Colors.orange
                : Colors.white2,
            ...(Platform.OS === "web" ? { height: 100 } : {}),
          },
          isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : {},
        ]}
      />
      <View
        style={[
          styles.action,
          { paddingBottom: insets.bottom + 10, paddingTop: 10 },
          isLargeScreen ? { alignItems: "center" } : {},
        ]}
      >
        <TextButton
          buttonType={ButtonType.Primary}
          title="決定"
          onPress={selectGroups}
          style={isLargeScreen ? { width: 300 } : {}}
        />
      </View>
    </CustomKeyboardAvoidingView>
  )
})

export const selectGroupScreenOptions = {
  title: "グループを選択",
}

const styles = StyleSheet.create({
  headingContainer: {
    paddingVertical: 16,
    alignItems: "center",
  },
  headingText: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.warmGrey,
  },
  searchInput: {
    borderBottomWidth: 1,
    borderColor: Colors.white2,
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingHorizontal: 20,
    paddingVertical: 12,
  },
  action: {
    borderTopWidth: 1,
    borderColor: Colors.white2,
    paddingHorizontal: 24,
  },
  groupName: {
    fontWeight: "bold",
    color: Colors.black2,
    paddingRight: 12,
    flex: 1,
  },
})
