import { memo, useCallback, useMemo } from "react"
import { RootStackScreenProps } from "src/types.d"
import { StyleSheet, Text, View } from "react-native"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { Screens } from "src/constants/Screens"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { useMemberInvitationData } from "src/recoil/hooks/organization/useMemberInvitationData"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { SmallButton } from "src/components/parts/buttons/SmallButton"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { ShareIcon } from "src/components/parts/icons/ShareIcon"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { EditIcon } from "src/components/parts/icons/EditIcon"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { QuestionWithCircleIcon } from "src/components/parts/icons/QuestionWithCircleIcon"
import * as WebBrowser from "expo-web-browser"
import { Button } from "src/components/parts/buttons/Button"
import { CreateOrEditOrganizationScreens } from "src/constants/CreateOrEditOrganizationScreens"

export type InvitationMemberParams = {
  id: string
}

type Props = RootStackScreenProps<typeof Screens.InvitationMember>

export const InvitationMember = memo<Props>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const id = useMemo(() => route.params.id, [route.params.id])

  const {
    thumbnailImageUrl,
    organizationName,
    invitationCode,
    isMemberInvitationDataLoading,
    copyInvitationCode,
    openShareSheet,
    organizationDetail,
  } = useMemberInvitationData(id)

  const openWebBrowser = useCallback(async () => {
    await WebBrowser.openBrowserAsync("https://www.ra9plus.jp/guide/invitation_simultaneously")
  }, [])

  const goToEditOrganizationDetail = useCallback(() => {
    if (organizationDetail == null) {
      return
    }
    navigation.navigate(Screens.EditOrganizationStack, {
      screen: CreateOrEditOrganizationScreens.CreateOrEditOrganization,
      params: { ...organizationDetail, originalCategoryId: organizationDetail.category.id },
    })
  }, [navigation, organizationDetail])

  const goToInvitationEmail = useCallback(() => {
    navigation.navigate(Screens.InvitationEmail, { id: id })
  }, [navigation, id])

  const goToInvitationHistory = useCallback(() => {
    navigation.navigate(Screens.SendingHistory, { id: id })
  }, [navigation, id])

  return (
    <CustomScrollView style={styles.container}>
      {isMemberInvitationDataLoading ? (
        <View style={{ width: "100%", height: "80%", alignItems: "center", justifyContent: "center" }}>
          <LoadingIndicator />
        </View>
      ) : (
        <>
          <View style={[styles.head, isLargeScreen ? { borderBottomWidth: 1, borderBottomColor: Colors.lightGrey } : {}]}>
            <CircleThumbnail size={32} source={thumbnailImageUrl ? { uri: thumbnailImageUrl } : defaultThumbnail} />
            <Text style={styles.orgName}>{organizationName}</Text>
          </View>
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <View style={isLargeScreen ? { width: 600 } : { width: "100%" }}>
              <View style={[styles.contentsContainer, isLargeScreen ? { borderTopWidth: 0 } : {}]}>
                <ItemLabel label="一括メールで招待" />
                <Text style={styles.description}>
                  複数人への招待メールを一括で送信することができます。招待メール内のURLを開くと、団体への参加申請が行えます。
                </Text>
                <View style={styles.actions}>
                  <TextButton buttonType={ButtonType.Primary} title="一括メールで招待" onPress={goToInvitationEmail} />
                  <TextButton
                    buttonType={ButtonType.Secondary}
                    title="メール送信履歴を確認"
                    onPress={goToInvitationHistory}
                    style={styles.secondaryButton}
                  />
                </View>
                <View style={styles.migrateContainer}>
                  <Button style={styles.aboutMigrationContainer} onPress={openWebBrowser}>
                    <QuestionWithCircleIcon fill={Colors.orange} />
                    <Text style={styles.aboutMigrationText}>一括メールで招待とは</Text>
                  </Button>
                </View>
              </View>

              {isLargeScreen ? null : (
                <View style={styles.contentsContainer}>
                  <ItemLabel label="招待リンクを共有" />
                  <View style={styles.buttonContainer}>
                    <SmallButton onPress={openShareSheet}>
                      <ShareIcon />
                      <Text style={styles.buttonLabel}>共有</Text>
                    </SmallButton>
                  </View>
                </View>
              )}

              <View style={styles.contentsContainer}>
                <ItemLabel label="招待コードをコピーして共有" />
                <Text style={styles.description}>
                  参加画面で下記招待コードを入力すると、この団体へ参加申請を行うことができます。
                </Text>
                <View style={styles.contentWrapper}>
                  <Text style={[styles.content, styles.centering]}>{invitationCode}</Text>
                </View>
                <View style={styles.buttonContainer}>
                  <SmallButton onPress={copyInvitationCode}>
                    <Text style={styles.buttonLabel}>コピー</Text>
                  </SmallButton>
                </View>
              </View>

              <View style={styles.contentsContainer}>
                <Text style={[styles.description, { textAlign: "center" }, isLargeScreen ? { paddingHorizontal: 32 } : {}]}>
                  「学年」や「連絡先電話番号」などメンバー登録時に質問したい項目を追加設定できます。
                </Text>
                <View style={styles.buttonContainer}>
                  <SmallButton onPress={goToEditOrganizationDetail}>
                    <EditIcon />
                    <Text style={styles.registerImageButtonText}>団体設定</Text>
                  </SmallButton>
                </View>
              </View>
            </View>
          </View>
        </>
      )}
    </CustomScrollView>
  )
})

export const options: ScreenOptions = {
  ...commonSingleModalOptions,
  title: "メンバー招待",
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white3,
  },
  head: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 8,
    backgroundColor: Colors.white3,
  },
  orgName: {
    marginLeft: 8,
  },
  contentsContainer: {
    padding: 24,
    borderTopWidth: 1,
    borderTopColor: Colors.lightGrey,
  },
  description: {
    fontSize: 14,
    lineHeight: 21,
    color: Colors.black,
    marginVertical: 8,
    marginLeft: 0,
  },
  contentWrapper: {
    borderRadius: 8,
    backgroundColor: "#efeae3",
  },
  content: {
    fontSize: 16,
    lineHeight: 25,
    color: Colors.black2,
    padding: 12,
  },
  centering: {
    textAlign: "left",
  },
  buttonContainer: {
    marginTop: 16,
    alignItems: "center",
  },
  buttonLabel: {
    marginLeft: 14,
    color: Colors.orange,
  },
  actions: {
    paddingVertical: 16,
    paddingHorizontal: 32,
    paddingBottom: 20,
  },
  secondaryButton: {
    marginTop: 16,
  },
  linkText: {
    color: Colors.black,
    textDecorationLine: "underline",
  },
  aboutMigrationContainer: {
    flexDirection: "row",
    marginTop: 16,
  },
  aboutMigrationText: {
    color: Colors.greyshBrown,
    textDecorationLine: "underline",
    marginLeft: 8,
  },
  migrateContainer: {
    alignItems: "center",
    flex: 1,
  },
  registerImageButtonText: {
    color: Colors.orange,
    fontSize: 14,
    fontWeight: "bold",
    paddingLeft: 8,
  },
})
