import { GqlError, getQuestionnaireAnswerCsvData as _getQuestionnaireAnswerCsvData } from "src/aws/customAPI";
import { QuestionnaireAnswerModel } from "src/types/csv/answerCsvData";
import { Result } from "src/utils/Result";

type GetQuestionnaireAnswerCsvDataParams = {
  accessToken: string
  teamId: string
  mailId: string
  memberId: string
}

type GetQuestionnaireAnswerCsvDataSuccess = QuestionnaireAnswerModel

type GetQuestionnaireAnswerCsvDataFailure = {
  message: string
}

export const getQuestionnaireAnswerCsvData = async (
  params: GetQuestionnaireAnswerCsvDataParams
): Promise<Result<GetQuestionnaireAnswerCsvDataSuccess, GetQuestionnaireAnswerCsvDataFailure>> => {
  return _getQuestionnaireAnswerCsvData(params.accessToken, {
    input: {
      teamId: params.teamId,
      mailId: params.mailId,
      memberId: params.memberId,
    }
  }).then(res => {
    return Result.Ok<GetQuestionnaireAnswerCsvDataSuccess>({
      questions: res.questions.map((c) => c.formatted),
      items: res.items,
    });
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "予期せぬエラーが発生しました";
    return Result.Error<GetQuestionnaireAnswerCsvDataFailure>({
      message: message
    });
  });
};