import { memo, useCallback, useMemo } from "react"
import { Image, Platform, StyleSheet, Text, View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { AttachmentIcon } from "src/components/parts/icons/AttachmentIcon"
import { Colors } from "src/constants/Colors"
import dayjs from "dayjs"
import duration from "dayjs/plugin/duration"
import { FavoriteIcon } from "src/components/parts/icons/FavoriteIcon"
import { TimeLimitLabel } from "src/components/parts/TimeLimitLabel"
import Swipeable from "react-native-gesture-handler/Swipeable"
import { TrashBoxIcon } from "src/components/parts/icons/drawer/TrashBoxIcon"
import { ContactType, ContactTypeLabel } from "src/constants/ContactType"
import { PermanentlyDeleteIcon } from "src/components/parts/icons/PermanentlyDeleteIcon"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { NoPersonIcon } from "src/components/parts/icons/NoPersonIcon"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { unreadThreadCountSelectorFamily } from "src/recoil/selectors/contact/unreadThreadCountSelectorFamily"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

dayjs.extend(duration)

type CommonProps = {
  contactId: string
  title: string
  contactType: ContactType
  hasAttachment: boolean
  onContactPress: () => void
  noBorder?: boolean
  unreadThreadCount?: number
  imageUrl?: string
}

type SentCommonProps = {
  sentDate?: Date
  senderId: string
  senderName: string
  senderImageUrl?: string
  isSenderDeleted: boolean
  timeLimitDate?: Date
  isFavorite: boolean
  onIsFavoriteChange: () => void
  favoriteFlgsCache: { [contactId: string]: boolean }
}

type Props = CommonProps &
  (
    | ({
        viewType: "Inbox"
        isEdited: boolean
        updatedDate?: Date
        onTrashPress: () => void
        isRead: boolean
        isAnswered: boolean | undefined
      } & SentCommonProps)
    | ({
        viewType: "Outbox"
        isEdited: boolean
        updatedDate?: Date
        reservedDate?: Date
        onDeletePress: () => void
      } & SentCommonProps)
    | ({
        viewType: "Trashbox"
        onRestorePress: () => void
        onDeletePress: () => void
        isRead: boolean
      } & SentCommonProps)
    | {
        viewType: "Draft"
        isEdited: boolean
        onDeletePress: () => void
        updatedDate: Date
      }
    | {
        viewType: "Template"
        onDeletePress: () => void
        updatedDate: Date
        isFavorite: boolean
        onIsFavoriteChange: () => void
        favoriteFlgsCache: { [contactId: string]: boolean }
      }
    | ({
        viewType: "Home"
        isRead: boolean
      } & SentCommonProps)
    | ({
        viewType: "Reserved"
      } & SentCommonProps)
    | ({
        viewType: "Migration"
        isFavorite: boolean
        onIsFavoriteChange: () => void
        favoriteFlgsCache: { [contactId: string]: boolean }
        onDeletePress: () => void
      } & SentCommonProps)
  )

export const ContactItem = memo<Props>(
  ({ contactId, title, contactType, hasAttachment, onContactPress, noBorder, unreadThreadCount, imageUrl, ...rest }) => {
    const { value: unreadThreadCountNumber } = useAsyncSelector(
      unreadThreadCountSelectorFamily({ contactIds: unreadThreadCount === undefined ? contactId : undefined })
    )
    const isLargeScreen = useCheckPCScreen()
    const {
      sentDate,
      senderId,
      senderName,
      senderImageUrl,
      isSenderDeleted,
      timeLimitDate,
      isFavorite,
      onIsFavoriteChange,
      favoriteFlgsCache,
    } = useMemo(
      () =>
        rest.viewType === "Inbox" ||
        rest.viewType === "Outbox" ||
        rest.viewType === "Trashbox" ||
        rest.viewType === "Home" ||
        rest.viewType === "Template" ||
        rest.viewType === "Migration"
          ? {
              ...rest,
            }
          : {
              sentDate: undefined,
              senderId: undefined,
              senderName: undefined,
              senderImageUrl: undefined,
              isSenderDeleted: undefined,
              timeLimitDate: undefined,
              isFavorite: undefined,
              onIsFavoriteChange: undefined,
              favoriteFlgsCache: undefined,
            },
      [rest]
    )
    const viewType = useMemo(() => rest.viewType, [rest.viewType])
    const onTrashOrDeletePress = useMemo(
      () =>
        rest.viewType === "Inbox"
          ? rest.onTrashPress
          : rest.viewType === "Outbox" ||
            rest.viewType === "Trashbox" ||
            rest.viewType === "Template" ||
            rest.viewType === "Draft" ||
            rest.viewType === "Migration"
          ? rest.onDeletePress
          : undefined,
      [rest]
    )
    const isSwipeToDeleteEnabled = useMemo(() => onTrashOrDeletePress != null, [onTrashOrDeletePress])
    const toShowUnreadIcon = useMemo(
      () => (rest.viewType === "Inbox" || rest.viewType === "Trashbox" || rest.viewType === "Home") && !rest.isRead,
      [rest]
    )
    const toShowBottomContainer = useMemo(() => viewType !== "Draft", [viewType])
    const toShowDraftLabel = useMemo(() => viewType === "Draft", [viewType])
    const { value: selectedMyOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
    const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(selectedMyOrganization?.id))
    const isSender = useMemo(
      () => senderId != null && myProfile?.memberId != null && senderId === myProfile.memberId,
      [senderId, myProfile?.memberId]
    )
    const toShowUnreadThreadCount = useMemo(
      () =>
        ((viewType === "Home" && !isSender) || (viewType === "Inbox" && !isSender) || (viewType === "Outbox" && isSender)) &&
        ((unreadThreadCount != null && unreadThreadCount > 0) ||
          (unreadThreadCountNumber != null && unreadThreadCountNumber > 0)),
      [unreadThreadCount, viewType, isSender, unreadThreadCountNumber]
    )
    const onRestorePress = useCallback(() => (rest.viewType === "Trashbox" ? rest.onRestorePress() : undefined), [rest])
    const toShowRestoreButton = useMemo(() => viewType === "Trashbox", [viewType])

    const renderRightActions = useCallback(
      () => (
        <Button style={styles.swipeToDeleteContainer} onPress={onTrashOrDeletePress}>
          {viewType === "Inbox" ? (
            <View style={styles.deleteTrashIconContainer}>
              <TrashBoxIcon color={Colors.orange} />
              <Text style={styles.trashIconText}>ゴミ箱</Text>
            </View>
          ) : (
            <View style={styles.deleteTrashIconContainer}>
              <PermanentlyDeleteIcon />
              <Text style={styles.deleteIconText}>削除</Text>
            </View>
          )}
        </Button>
      ),
      [onTrashOrDeletePress, viewType]
    )

    const checkDate = (date?: Date) => {
      if (!date) return ""
      const weekday = ["日", "月", "火", "水", "木", "金", "土"]
      const nowDay = dayjs()
      const formattedDate = dayjs(date)

      if (formattedDate.isSame(nowDay, "day")) {
        return `今日${formattedDate.format(" HH:mm")}`
      } else if (formattedDate.year() === nowDay.year()) {
        return `${formattedDate.format("MM/DD")} (${weekday[formattedDate.day()]})${formattedDate.format(" HH:mm")}`
      } else {
        return `${formattedDate.format("YYYY/MM/DD")} (${weekday[formattedDate.day()]})${formattedDate.format(" HH:mm")}`
      }
    }

    return !isLargeScreen ? (
      <Swipeable
        overshootRight={false}
        renderRightActions={renderRightActions}
        enabled={isSwipeToDeleteEnabled}
        containerStyle={[styles.swipeableContainer, timeLimitDate != null ? styles.swipeableContainerWithTimeLimit : null]}
      >
        <Button
          style={[styles.container, timeLimitDate != null ? styles.timeLimitContainer : null, !noBorder && styles.border]}
          onPress={onContactPress}
        >
          {timeLimitDate != null ? (
            <View style={styles.timeLimitLabelWrapper}>
              <TimeLimitLabel timeLimitDate={timeLimitDate} />
            </View>
          ) : null}
          <View style={styles.topContainer}>
            {toShowDraftLabel ? <Text style={styles.draftLabel}>下書き保存</Text> : null}
            {toShowUnreadIcon ? <View style={styles.topUnreadIcon} /> : null}
            {rest.viewType === "Outbox" && rest.reservedDate ? (
              <View style={styles.reservedLabelContainer}>
                <Text style={styles.reservedLabelText}>
                  {checkDate(rest.reservedDate)}
                  {"に送信予定"}
                </Text>
              </View>
            ) : null}
            {rest.viewType === "Inbox" || rest.viewType === "Outbox" ? (
              rest.viewType === "Outbox" && rest.reservedDate ? null : rest.isEdited ? (
                <Text style={styles.topDate}>
                  {sentDate ? "送信：" : null}
                  {sentDate ? checkDate(sentDate) : null}
                  {sentDate ? "\n" : null}
                  {"編集済み："}
                  {checkDate(rest.updatedDate)}
                </Text>
              ) : sentDate ? (
                <Text style={styles.topDate}>
                  {"送信："}
                  {checkDate(sentDate)}
                </Text>
              ) : null
            ) : sentDate ? (
              <Text style={styles.topDate}>
                {"送信："}
                {checkDate(sentDate)}
              </Text>
            ) : null}
            {rest.viewType === "Template" || rest.viewType === "Draft" ? (
              <Text style={styles.topDate}>{checkDate(rest.updatedDate)}</Text>
            ) : null}
            {hasAttachment ? <AttachmentIcon /> : null}
            <View style={styles.space} />
            {toShowUnreadThreadCount ? (
              <Text style={styles.topUnreadReply}>未読の返信 {unreadThreadCount ?? unreadThreadCountNumber}件</Text>
            ) : null}
            {toShowRestoreButton ? (
              <Button onPress={onRestorePress} style={styles.restoreButton}>
                <Text style={styles.restoreButtonText}>復元</Text>
              </Button>
            ) : null}
          </View>
          {rest.viewType === "Outbox" && rest.reservedDate ? (
            <View style={styles.topContainer}>
              <Text style={styles.topUpdateDate}>
                {"編集済み："}
                {checkDate(rest.updatedDate)}
              </Text>
            </View>
          ) : null}
          <Text numberOfLines={3} ellipsizeMode="tail" style={styles.titleContainer}>
            {viewType !== "Migration" ? (
              <>
                <Text style={styles.contactType}>{ContactTypeLabel[contactType]}</Text>
                <Text style={styles.titleDelimiter}> | </Text>
              </>
            ) : null}
            <Text style={styles.title}>{title}</Text>
          </Text>
          {toShowBottomContainer ? (
            <View style={styles.bottomContainer}>
              <View style={styles.senderContainer}>
                {!isSenderDeleted ? (
                  <>
                    {viewType !== "Migration" ? (
                      <Image style={styles.senderImage} source={imageUrl ? { uri: imageUrl } : defaultThumbnail} />
                    ) : null}
                    <Text
                      style={[styles.senderName, viewType === "Migration" && { marginLeft: 0 }]}
                      numberOfLines={3}
                      ellipsizeMode="tail"
                    >
                      {senderName}
                    </Text>
                  </>
                ) : (
                  <>
                    <NoPersonIcon size={32} />
                    <Text style={styles.senderName} numberOfLines={3} ellipsizeMode="tail">
                      {senderName}（脱退）
                    </Text>
                  </>
                )}
              </View>
              {!((rest.viewType === "Outbox" && rest.reservedDate) || rest.viewType === "Draft") ? (
                <Button onPress={onIsFavoriteChange}>
                  <FavoriteIcon isFavorite={favoriteFlgsCache?.[contactId] ?? isFavorite} />
                </Button>
              ) : null}
            </View>
          ) : null}
        </Button>
      </Swipeable>
    ) : (
      <View
        style={[styles.containerLarge, timeLimitDate != null ? styles.timeLimitContainer : null, !noBorder && styles.border]}
      >
        <Button style={[styles.viewButton]} onPress={onContactPress}>
          <View style={[]}>
            {timeLimitDate != null ? (
              <View style={styles.timeLimitLabelWrapper}>
                <TimeLimitLabel timeLimitDate={timeLimitDate} />
              </View>
            ) : null}
            <View style={styles.topContainer}>
              {toShowDraftLabel ? <Text style={styles.draftLabel}>下書き保存</Text> : null}
              {toShowUnreadIcon ? <View style={styles.topUnreadIcon} /> : null}
              {rest.viewType === "Outbox" && rest.reservedDate ? (
                <View style={styles.reservedLabelContainer}>
                  <Text style={styles.reservedLabelText}>
                    {checkDate(rest.reservedDate)}
                    {"に送信予定"}
                  </Text>
                </View>
              ) : null}
              {rest.viewType === "Inbox" || rest.viewType === "Outbox" ? (
                rest.viewType === "Outbox" && rest.reservedDate ? null : rest.isEdited ? (
                  <Text style={styles.topDate}>
                    {sentDate ? "送信：" : null}
                    {sentDate ? checkDate(sentDate) : null}
                    {sentDate ? "\n" : null}
                    {"編集済み："}
                    {checkDate(rest.updatedDate)}
                  </Text>
                ) : sentDate ? (
                  <Text style={styles.topDate}>
                    {"送信："}
                    {checkDate(sentDate)}
                  </Text>
                ) : null
              ) : sentDate ? (
                <Text style={styles.topDate}>
                  {"送信："}
                  {checkDate(sentDate)}
                </Text>
              ) : null}
              {rest.viewType === "Template" || rest.viewType === "Draft" ? (
                <Text style={styles.topDate}>{checkDate(rest.updatedDate)}</Text>
              ) : null}
              {hasAttachment ? <AttachmentIcon /> : null}
              <View style={styles.space} />
              {toShowUnreadThreadCount ? (
                <Text style={styles.topUnreadReply}>未読の返信 {unreadThreadCount ?? unreadThreadCountNumber}件</Text>
              ) : null}
            </View>
            {rest.viewType === "Outbox" && rest.reservedDate ? (
              <View style={styles.topContainer}>
                <Text style={styles.topUpdateDate}>
                  {"編集済み："}
                  {checkDate(rest.updatedDate)}
                </Text>
              </View>
            ) : null}
            <Text numberOfLines={3} ellipsizeMode="tail" style={styles.titleContainer}>
              {viewType !== "Migration" ? (
                <>
                  <Text style={styles.contactType}>{ContactTypeLabel[contactType]}</Text>
                  <Text style={styles.titleDelimiter}> | </Text>
                </>
              ) : null}
              <Text style={styles.title}>{title}</Text>
            </Text>
            {toShowBottomContainer ? (
              <View style={styles.bottomContainer}>
                <View style={styles.senderContainer}>
                  {!isSenderDeleted ? (
                    <>
                      {viewType !== "Migration" ? (
                        <Image style={styles.senderImage} source={imageUrl ? { uri: imageUrl } : defaultThumbnail} />
                      ) : null}
                      <Text
                        style={[styles.senderName, viewType === "Migration" && { marginLeft: 0 }]}
                        numberOfLines={3}
                        ellipsizeMode="tail"
                      >
                        {senderName}
                      </Text>
                    </>
                  ) : (
                    <>
                      <NoPersonIcon size={32} />
                      <Text style={styles.senderName} numberOfLines={3} ellipsizeMode="tail">
                        {senderName}（脱退）
                      </Text>
                    </>
                  )}
                </View>
              </View>
            ) : null}
          </View>
        </Button>
        <View style={styles.deleteTrashBox}>
          <View style={[{ display: "flex", width: "100%", alignItems: "flex-end" }]}>
            {toShowRestoreButton ? (
              <Button onPress={onRestorePress} style={styles.restoreButton}>
                <Text style={styles.restoreButtonText}>復元</Text>
              </Button>
            ) : null}
          </View>
          <View
            style={[
              {
                display: "flex",
                height: "90%",
                alignItems: "flex-end",
                justifyContent: "flex-end",
                flexDirection: "row",
              },
              toShowRestoreButton ? styles.restoreDelete : undefined,
            ]}
          >
            {!((rest.viewType === "Outbox" && rest.reservedDate) || rest.viewType === "Draft") ? (
              <Button onPress={onIsFavoriteChange} style={{ marginBottom: 3 }}>
                <FavoriteIcon isFavorite={favoriteFlgsCache?.[contactId] ?? isFavorite} />
              </Button>
            ) : null}
            {viewType === "Home" ? (
              <></>
            ) : viewType === "Inbox" ? (
              <Button onPress={onTrashOrDeletePress} style={[styles.buttonDeleteStyle]}>
                <TrashBoxIcon color={Colors.orange} />
              </Button>
            ) : (
              <Button onPress={onTrashOrDeletePress} style={[styles.buttonDeleteStyle]}>
                <PermanentlyDeleteIcon />
              </Button>
            )}
          </View>
        </View>
      </View>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    padding: 16,
    backgroundColor: Colors.white3,
    shadowColor: "#F9D5C1",
    shadowOpacity: 0.38,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 15,
  },
  containerLarge: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 16,
    padding: 16,
    backgroundColor: Colors.white3,
    shadowColor: "#F9D5C1",
    shadowOpacity: 0.38,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 15,
    width: "100%",
  },
  viewButton: {
    width: "90%",
  },
  buttonDeleteStyle: {
    paddingLeft: 15,
    paddingBottom: 3,
    height: 18,
  },
  deleteTrashBox: {
    display: "flex",
    width: "10%",
    flexDirection: "column",
  },
  border: {
    borderWidth: 1,
    borderColor: Colors.silver,
  },
  timeLimitContainer: {
    borderWidth: 1,
    borderColor: Colors.leaf,
    paddingTop: 11,
    position: "relative",
  },
  timeLimitLabelWrapper: {
    position: "absolute",
    top: -10,
    left: Platform.OS === "web" ? 0 : 16,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  topContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  space: {
    flex: 1,
  },
  topUnreadIcon: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: Colors.brightSkyBlue,
    marginRight: 10,
  },
  draftLabel: {
    fontSize: 12,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginRight: 10,
  },
  reservedLabelContainer: {
    backgroundColor: Colors.cement,
    paddingVertical: 2,
    paddingHorizontal: 10,
    borderRadius: 16,
  },
  reservedLabelText: {
    fontSize: 12,
    color: Colors.white,
  },
  topDate: {
    fontSize: 12,
    color: Colors.cement,
  },
  topUpdateDate: {
    marginTop: 4,
    fontSize: 12,
    color: Colors.cement,
  },
  topUnreadReply: {
    fontSize: 13,
    color: Colors.brightSkyBlue,
    marginLeft: 10,
    fontWeight: "bold",
  },
  restoreButton: {
    backgroundColor: Colors.lightGrey,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 10,
  },
  restoreButtonText: {
    fontSize: 12,
  },
  restoreDelete: {
    paddingBottom: 17,
  },
  titleContainer: {
    fontSize: 16,
    lineHeight: 22,
    marginTop: 6,
  },
  contactType: {
    fontWeight: "bold",
    color: Colors.orange,
  },
  titleDelimiter: {
    color: Colors.cement,
  },
  title: {
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
  bottomContainer: {
    marginTop: 12,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  senderContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexShrink: 1,
    width: "85%",
  },
  senderImage: {
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  senderName: {
    fontSize: 13,
    color: Colors.greyshBrown,
    marginLeft: 10,
  },
  swipeableContainer: {
    backgroundColor: "rgb(232,230,226)",
    borderRadius: 16,
    overflow: "visible",
  },
  swipeableContainerWithTimeLimit: {
    marginTop: 10,
  },
  swipeToDeleteContainer: {
    width: 72,
    justifyContent: "center",
    alignItems: "center",
  },
  deleteTrashIconContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: 38,
  },
  deleteIconText: {
    color: Colors.red,
  },
  trashIconText: {
    color: Colors.orange,
  },
})
