import { listTeamNotice, TeamNoticeType } from "src/aws/customAPI"
import { Result } from "src/utils/Result"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
  accountId: string
}

export type Notification = {
  id: string
  type: TeamNoticeType
  title: string
  organizationId: string
  organizationName: string
  updatedAt: Date
  isRead: boolean
  url: string
  paidFunctionEnabled: boolean
}

type Success = Notification[]

type Failure = {
  message: string
}

export const getNotifications = ({ accessToken, accountId }: Params): Promise<Result<Success, Failure>> => {
  return execApi(
    () => listTeamNotice(accessToken, { accountId, appInfo }),
    (res) => {
      return res.getAccount.teamNotices.items.map((item) => ({
        id: item.id,
        type: item.noticeType,
        title: item.title,
        organizationId: item.team.id,
        organizationName: item.team.name,
        url: item.url,
        updatedAt: new Date(item.createdAt),
        isRead: item.readFlg,
        paidFunctionEnabled: item.team.paidFunctionEnabled,
      }))
    },
    {
      identifiers: ["getNotifications", "listTeamNotice"],
    }
  )
}
