import { selector } from "recoil"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { templateContactsRequestIdState } from "src/recoil/atoms/contact/templateContactsRequestIdState"
import { getTemplateContacts } from "src/apis/contact/getTemplateContacts"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { CustomAlert } from "src/utils/CustomAlert"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"

export const templateContactsSelector = selector<ContactOverviewModel[] | undefined>({
  key: "selectors/contact/templateContactsSelector",
  get: async ({ get }) => {
    get(templateContactsRequestIdState)
    const selectedMyOrganization = get(selectedMyOrganizationSelector)
    if (selectedMyOrganization == null) {
      return
    }
    const myProfile = get(myProfileSelectorFamily(selectedMyOrganization.id))
    if (myProfile == null) {
      return
    }
    const myMemberId = myProfile.memberId
    const tokens = get(tokensState)
    const accessToken = tokens?.accessToken
    if (accessToken == null) {
      return
    }
    const result = await getTemplateContacts({
      accessToken,
      myMemberId,
    })
    if (!result.isOk) {
      CustomAlert.alert("エラー", result.error.message)
      return
    }
    return result.content
  },
})
