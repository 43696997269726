import { DrawerContentComponentProps } from "@react-navigation/drawer"
import { useMemo } from "react"
import { Pressable, StyleSheet, Text, View } from "react-native"
import { useRecoilState, useRecoilValue } from "recoil"
import { Button } from "src/components/parts/buttons/Button"
import { BadgeIcon } from "src/components/parts/icons/BadgeIcon"
import { ChevronDownIcon } from "src/components/parts/icons/ChevronDownIcon"
import { ChevronUpIcon } from "src/components/parts/icons/ChevronUpIcon"
import { MatchingFavoriteIcon } from "src/components/parts/icons/MatchingFavoriteIcon"
import { EmptyMatchingIcon, MatchingIcon } from "src/components/parts/icons/MatchingIcon"
import { MachingProfileIcon } from "src/components/parts/icons/MatchingProfileIcon"
import { MyMatchingOfferIcon } from "src/components/parts/icons/MyMatchingOfferIcon"
import { OrangeCircleIcon } from "src/components/parts/icons/OrangeCircleIcon"
import { SearchIcon } from "src/components/parts/icons/SearchIcon"
import { TagIcon } from "src/components/parts/icons/TagIcon"
import { DraftIcon } from "src/components/parts/icons/drawer/DraftIcon"
import { Colors } from "src/constants/Colors"
import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { RootDrawer } from "src/constants/RootDrawer"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { routeNameState } from "src/recoil/atoms/routeNameState"
import { matchingGroupState } from "src/recoil/atoms/sidebar/matchingGroupState"
import { matchingSubGroupState } from "src/recoil/atoms/sidebar/matchingSubGroupState"
import { hasMatchingNoticeSelector } from "src/recoil/selectors/matching/hasMatchingNoticeSelector"
import { unreadMatchingMessageCountFamily } from "src/recoil/selectors/matching/unreadMatchingMessageCountFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"

type MatchingSideMenuProps = DrawerContentComponentProps & { isPCScreen?: boolean }

type HeaderProps = {
  title: string
  hasBadge?: boolean
  handlePress?: () => void
  isActive?: boolean
}

const Header = ({ title }: HeaderProps) => {
  return (
    <View style={styles.header}>
      <OrangeCircleIcon />
      <Text style={styles.headerTitle}>{title}</Text>
    </View>
  )
}

const PcHeader = ({ title, hasBadge, handlePress, isActive }: HeaderProps) => {
  if (handlePress) {
    return (
      <Pressable style={styles.pcHeader} onPress={handlePress}>
        <View style={styles.icon}>{isActive ? <MatchingIcon /> : <EmptyMatchingIcon />}</View>
        <Text style={styles.pcHeaderTitle}>{title}</Text>
        {hasBadge && <BadgeIcon size={"small"} />}
      </Pressable>
    )
  } else {
    return (
      <View style={styles.pcHeader}>
        <View style={styles.icon}>
          <MatchingIcon />
        </View>
        <Text style={styles.pcHeaderTitle}>{title}</Text>
        {hasBadge && <BadgeIcon size={"small"} />}
      </View>
    )
  }
}

export const MatchingSideMenu = ({ navigation, isPCScreen, state }: MatchingSideMenuProps) => {
  const otherMatchingOffer = () => {
    if (isPCScreen) {
      navigation.navigate(RootDrawer.MatchingDrawer, { screen: MatchingDrawers.OtherMatchingOffer })
    } else {
      navigation.navigate({ name: MatchingDrawers.OtherMatchingOffer, merge: true, params: undefined })
    }
  }

  const myMatchingOffer = () => {
    if (isPCScreen) {
      navigation.navigate(RootDrawer.MatchingDrawer, { screen: MatchingDrawers.MyMatchingOffer })
    } else {
      navigation.navigate({ name: MatchingDrawers.MyMatchingOffer, merge: true, params: undefined })
    }
  }

  const myMatchingProfile = () => {
    if (isPCScreen) {
      navigation.navigate(RootDrawer.MatchingDrawer, { screen: MatchingDrawers.MyMatchingProfile })
    } else {
      navigation.navigate({
        name: MatchingDrawers.MyMatchingProfile,
        merge: true,
        params: { id: undefined, offerId: undefined },
      })
    }
  }

  const favoriteMatchingOffer = () => {
    if (isPCScreen) {
      navigation.navigate(RootDrawer.MatchingDrawer, { screen: MatchingDrawers.FavoriteMatchingOffer })
    } else {
      navigation.navigate({ name: MatchingDrawers.FavoriteMatchingOffer, merge: true, params: undefined })
    }
  }

  const myMatchingOfferDraft = () => {
    if (isPCScreen) {
      navigation.navigate(RootDrawer.MatchingDrawer, { screen: MatchingDrawers.MyMatchingDraftOffer })
    } else {
      navigation.navigate({ name: MatchingDrawers.MyMatchingDraftOffer, merge: true, params: undefined })
    }
  }

  const listMatchingApplication = () => {
    if (isPCScreen) {
      navigation.navigate(RootDrawer.MatchingDrawer, { screen: MatchingDrawers.Applications })
    } else {
      navigation.navigate({ name: MatchingDrawers.Applications, merge: true, params: undefined })
    }
  }

  const [showSubGroup, setShowSubGroup] = useRecoilState(matchingSubGroupState)
  const [showGroup, setShowGroup] = useRecoilState(matchingGroupState)
  const routeName = useRecoilValue(routeNameState)

  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const { value: hasBadge } = useAsyncSelector(hasMatchingNoticeSelector(organizationId ?? ""))
  const { value: unreadMatching } = useAsyncSelector(
    unreadMatchingMessageCountFamily({ myTeamId: organizationId, applicationId: undefined })
  )
  const isMatchingFeature = useMemo(() => Object.values(MatchingDrawers).some((name) => name === routeName), [routeName])
  const buttonTextStyle = useMemo(
    () => (isPCScreen ? styles.buttonText : [styles.buttonText, { marginLeft: 24 }]),
    [isPCScreen]
  )

  return (
    <View style={styles.container}>
      <View style={isPCScreen ? styles.pcGroupContainer : styles.groupContainer}>
        {isPCScreen ? (
          <PcHeader
            title="団体マッチング"
            isActive={isMatchingFeature || showGroup}
            hasBadge={hasBadge}
            handlePress={() => setShowGroup(!showGroup)}
          />
        ) : (
          <Header title="団体マッチング" />
        )}
        {!isPCScreen && (
          <Button onPress={otherMatchingOffer} style={[styles.button]}>
            <View style={styles.iconContainer}>
              <SearchIcon fillColor={Colors.orange} />
            </View>
            <Text style={buttonTextStyle}>募集情報</Text>
          </Button>
        )}
      </View>
      {(showGroup || !isPCScreen) && (
        <View style={isPCScreen ? styles.subGroupContainer : styles.groupContainer}>
          {isPCScreen ? (
            <>
              <Button
                onPress={otherMatchingOffer}
                style={[
                  styles.button,
                  isPCScreen && styles.pcButton,
                  routeName === MatchingDrawers.OtherMatchingOffer && styles.activeButton,
                ]}
              >
                <View style={styles.iconContainer}>
                  <SearchIcon fillColor={Colors.orange} />
                </View>
                <Text style={buttonTextStyle}>募集情報</Text>
              </Button>
              <Pressable
                style={({ pressed }) => [
                  {
                    backgroundColor: pressed ? Colors.pale : Colors.white3,
                  },
                  styles.controlButton,
                ]}
                onPress={() => setShowSubGroup(!showSubGroup)}
              >
                <Text style={styles.pcHeaderTitle}>マッチング管理</Text>
                {hasBadge && <BadgeIcon size={"small"} />}
                {showSubGroup ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </Pressable>
            </>
          ) : (
            <Header title="マッチング管理" />
          )}
          {(showSubGroup || !isPCScreen) && (
            <View>
              <Button
                onPress={myMatchingOffer}
                style={[
                  styles.button,
                  isPCScreen && styles.pcButton,
                  routeName === MatchingDrawers.MyMatchingOffer && styles.activeButton,
                ]}
              >
                <View style={styles.iconContainer}>
                  <MyMatchingOfferIcon />
                </View>
                <Text style={buttonTextStyle}>募集一覧</Text>
                {unreadMatching?.offerMessage && <BadgeIcon size={"small"} />}
              </Button>
              <Button
                onPress={myMatchingOfferDraft}
                style={[
                  styles.button,
                  isPCScreen && styles.pcButton,
                  routeName === MatchingDrawers.MyMatchingDraftOffer && styles.activeButton,
                ]}
              >
                <View style={styles.iconContainer}>
                  <DraftIcon color={Colors.orange} />
                </View>
                <Text style={buttonTextStyle}>募集の下書き</Text>
              </Button>
              <Button
                onPress={listMatchingApplication}
                style={[
                  styles.button,
                  isPCScreen && styles.pcButton,
                  routeName === MatchingDrawers.Applications && styles.activeButton,
                ]}
              >
                <View style={styles.iconContainer}>
                  <TagIcon />
                </View>
                <Text style={buttonTextStyle}>応募一覧</Text>
                {unreadMatching?.applicationMessage && <BadgeIcon size={"small"} />}
              </Button>
              <Button
                onPress={myMatchingProfile}
                style={[
                  styles.button,
                  isPCScreen && styles.pcButton,
                  routeName === MatchingDrawers.MyMatchingProfile && styles.activeButton,
                ]}
              >
                <View style={styles.iconContainer}>
                  <MachingProfileIcon />
                </View>
                <Text style={buttonTextStyle}>マッチング用プロフィール</Text>
              </Button>
              <Button
                onPress={favoriteMatchingOffer}
                style={[
                  styles.button,
                  isPCScreen && styles.pcButton,
                  routeName === MatchingDrawers.FavoriteMatchingOffer && styles.activeButton,
                ]}
              >
                <View style={styles.iconContainer}>
                  <MatchingFavoriteIcon />
                </View>
                <Text style={buttonTextStyle}>お気に入り一覧</Text>
              </Button>
            </View>
          )}
        </View>
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
  },
  groupContainer: {
    paddingTop: 8,
    paddingHorizontal: 8,
  },
  subGroupContainer: {
    paddingRight: 8,
    paddingLeft: 32,
  },
  pcGroupContainer: {
    marginHorizontal: 10,
    marginBottom: 4,
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  header: {
    flexDirection: "row",
    paddingVertical: 8,
    alignItems: "center",
  },
  pcHeader: {
    flexDirection: "row",
    paddingHorizontal: 6,
    paddingVertical: 8,
    width: "100%",
    alignItems: "center",
    elevation: 5,
    gap: 33,
  },
  headerTitle: {
    paddingHorizontal: 4,
    fontWeight: "bold",
    fontSize: 18,
  },
  pcHeaderTitle: {
    paddingHorizontal: 4,
    fontWeight: "bold",
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: Colors.reddishGrey,
    paddingVertical: 8,
    paddingHorizontal: 8,
    marginBottom: 8,
    borderRadius: 8,
  },
  pcButton: {
    flexDirection: "row",
    gap: 17,
    backgroundColor: Colors.white3,
  },
  controlButton: {
    paddingVertical: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 8,
  },
  activeButton: {
    backgroundColor: Colors.bgOrange,
  },
  buttonText: {
    paddingHorizontal: 6,
    fontSize: 14,
    fontWeight: "bold",
  },
  icon: {
    width: 23,
    height: 20,
  },
  iconContainer: {
    width: 24,
    height: 24,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
})
