import { atomFamily, selectorFamily } from "recoil"
import { SchoolGrade } from "src/constants/SchoolGradeOptions"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"

export const schoolGradeStateFamily = atomFamily<SchoolGrade | undefined, string>({
  key: "atoms/organization/myProfile/schoolGradeStateFamily",
  default: selectorFamily<SchoolGrade | undefined, string>({
    key: "atoms/organization/myProfile/schoolGradeStateFamily_default",
    get:
      (organizationId) =>
      ({ get }) => {
        const myProfile = get(myProfileSelectorFamily(organizationId))
        return myProfile?.schoolGrade
      },
  }),
})
