import { Result } from "src/utils/Result"
import { updateMigratedMail } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type UpdateFavoriteContactMigrationParams = {
  accessToken: string
  contactId: string
  isFavorite: boolean
}

type UpdateFavoriteContactMigrationSuccess = unknown

type UpdateFavoriteContactMigrationFailure = {
  message: string
}

export const updateFavoriteContactMigration = async (params: UpdateFavoriteContactMigrationParams): Promise<Result<UpdateFavoriteContactMigrationSuccess, UpdateFavoriteContactMigrationFailure>> => {
  const { accessToken, contactId, isFavorite } = params

  return execApi(
    () => updateMigratedMail(accessToken, {
      input: {
        id: contactId,
        favoriteFlg: isFavorite,
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["updateFavoriteContactMigration", "updateMigratedMail"],
      defaultErrorMessage: "お気に入りの更新に失敗しました。"
    }
  )
}
