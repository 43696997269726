import { atom } from "recoil"
import { MatchingOffersModel } from "../../../types/matching/matchingOfferModel"

export const myOffersState = atom<MatchingOffersModel[]>({
  key: "atoms/matching/myOffersState",
  default: [],
})

export const myApplicationsState = atom<MatchingOffersModel[]>({
  key: "atoms/matching/myApplicationsState",
  default: [],
})
