import { selectorFamily } from "recoil"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { ContactFilterValue } from "src/types/contact/ContactFilter"
import { outboxContactsSelectorFamily } from "src/recoil/selectors/contact/outboxContactsSelectorFamily"
import { outboxContactsConcatRequestIdState } from "src/recoil/atoms/contact/outboxContactsConcatRequestIdState"
import { outboxContactFavoriteFlgsCacheState } from "src/recoil/atoms/contact/contactFavoriteFlgsCacheState"

export const outboxContactsConcatSelectorFamily = selectorFamily<
  { contacts: ContactOverviewModel[]; nextToken?: string } | undefined,
  { searchText?: string; filters?: ContactFilterValue[]; count?: number; nextTokens: string[] }
>({
  key: "selectors/contact/outboxContactsConcatSelectorFamily",
  get:
    ({ nextTokens, ...params }) =>
    async ({ get }) => {
      get(outboxContactsConcatRequestIdState)
      const outboxFavState = get(outboxContactFavoriteFlgsCacheState)
      const result = [
        get(outboxContactsSelectorFamily(params)),
        ...nextTokens.map((nextToken) => get(outboxContactsSelectorFamily({ ...params, nextToken }))),
      ]
      const contactsRes = result.reduce((p, c) => (c != null ? [...p, ...c.contacts] : p), [] as ContactOverviewModel[])
      const contacts = contactsRes.map((c) => {
        if (outboxFavState[c.id] === undefined) {
          return c
        } else {
          return { ...c, isFavorite: outboxFavState[c.id] }
        }
      })
      return {
        contacts,
        nextToken: result.length > 0 ? result[result.length - 1]?.nextToken : undefined,
      }
    },
})
