import { selectorFamily } from "recoil"
import { MatchingOfferDetailsModel } from "../../../types/matching/matchingOfferDetailsModel"
import { matchingOfferDetailRequestIdState } from "../../atoms/matching/matchingOfferDetailRequestIdState"
import { tokensState } from "../../atoms/authorization/tokensState"
import { getMatchingOfferDetails } from "../../../apis/matching/getMatchingOfferDetails"
import { CustomAlert } from "../../../utils/CustomAlert"
import { myProfileSelectorFamily } from "../organization/myProfile/myProfileSelectorFamily"
import { selectedMyOrganizationSelector } from "../organization/selectedMyOrganizationSelector"

export const matchingOfferDetailSelectorFamily = selectorFamily<
  MatchingOfferDetailsModel | undefined,
  { offerId: string | undefined; type: "myOffer" | "otherOffer"; myOrganizationId?: string | undefined }
>({
  key: "selectors/matting/matchingOfferDetailSelectorFamily",
  get:
    ({ offerId, type, myOrganizationId }) =>
    async ({ get }) => {
      get(matchingOfferDetailRequestIdState)
      if (offerId == null) return
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) return
      const selectedMyOrganization = get(selectedMyOrganizationSelector)
      const organizationId = myOrganizationId ? myOrganizationId : selectedMyOrganization?.id
      if (organizationId == null) return
      const myProfile = get(myProfileSelectorFamily(organizationId))
      if (myProfile == null) return
      const organizationMemberId = myProfile.memberId
      if (organizationMemberId == null) return

      const result = await getMatchingOfferDetails({ accessToken, offerId, type, organizationMemberId })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      return result.content
    },
})
