import type { FC } from "react"
import { Svg, G, Path } from "react-native-svg"
import { DrawerIconProps } from "src/types/DrawerIconProps"

export const TrashBoxIcon: FC<DrawerIconProps> = ({ color }) => {
  return (
    <Svg width="17.86" height="21.983" viewBox="0 0 17.86 21.983">
      <G data-name="グループ 2473">
        <Path
          data-name="パス 939"
          d="M175.816 3.445v2.748h2.748V3.445h4.121v2.748h2.748V3.445C185.433.7 184.059.7 182.685.7h-4.121c-2.748 0-2.748 1.374-2.748 2.748"
          transform="translate(-171.694 -.697)"
          fill={color}
        />
        <Path
          data-name="パス 940"
          d="M189.5 5.368a.5.5 0 0 0-.5-.493h-16.869a.5.5 0 0 0-.493.493V7.13a.5.5 0 0 0 .493.493h.388a.5.5 0 0 1 .493.493v14.126a.5.5 0 0 0 .493.493h14.126a.5.5 0 0 0 .493-.493V8.116a.5.5 0 0 1 .493-.493H189a.5.5 0 0 0 .493-.493zm-12.366 14.126a.5.5 0 0 1-.493.493h-.388a.5.5 0 0 1-.493-.493V8.921a.5.5 0 0 1 .493-.493h.388a.5.5 0 0 1 .493.493zm4.121 0a.5.5 0 0 1-.493.493h-.388a.5.5 0 0 1-.493-.493V8.921a.5.5 0 0 1 .493-.493h.388a.5.5 0 0 1 .493.493zm4.121 0a.5.5 0 0 1-.493.493h-.383a.5.5 0 0 1-.493-.493V8.921a.5.5 0 0 1 .493-.493h.387a.5.5 0 0 1 .493.493z"
          transform="translate(-171.638 -.753)"
          fill={color}
        />
      </G>
    </Svg>
  )
}

TrashBoxIcon.displayName = "TrashBoxIcon"
