import { atomFamily, selectorFamily } from "recoil"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"

export const isShowEmailStateFamily = atomFamily<boolean | undefined, string>({
  key: "atoms/organization/myProfile/isShowEmailStateFamily",
  default: selectorFamily<boolean | undefined, string>({
    key: "atoms/organization/myProfile/isShowEmailStateFamily_default",
    get:
      (organizationId) =>
      ({ get }) => {
        const myProfile = get(myProfileSelectorFamily(organizationId))
        return myProfile?.isShowEmail
      },
  }),
})
