import { selectorFamily } from "recoil"
import { getListInvitationEmailStatus } from "src/apis/team/invitation/getListInvitationEmailStatus"
import { EmailStatus } from "src/aws/API"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import {
  invitationEmailsConcatRequestIdState,
  invitationEmailsRequestIdState,
} from "src/recoil/atoms/organization/invitationEmailsRequestIdState"
import { CustomAlert } from "src/utils/CustomAlert"

export const Status = Object.freeze({
  Sending: "sending",
  Sent: "sent",
  Bounce: "bounce",
  Error: "error",
} as const)

export const EmailStatusLabel = {
  [Status.Sending]: "送信中",
  [Status.Sent]: "送信済み",
  [Status.Bounce]: "不着",
  [Status.Error]: "エラー",
}

export type InvitationEmailStatus = {
  id: string
  email: string
  status: EmailStatus
  createdAt: string
  updatedAt: string
}

export const invitationEmailsSelectorFamily = selectorFamily<
  { emails: InvitationEmailStatus[]; nextToken?: string | null; total?: number } | undefined,
  { organizationId: string; nextToken?: string }
>({
  key: "selectors/team/invitationEmailsSelectorFamily",
  get:
    ({ organizationId, nextToken }) =>
    async ({ get }) => {
      get(invitationEmailsRequestIdState)
      const tokens = get(tokensState)
      if (tokens == null) {
        return
      }

      const limit = 30
      const result = await getListInvitationEmailStatus({
        accessToken: tokens.accessToken,
        teamId: organizationId,
        limit,
        nextToken: nextToken ? nextToken : undefined,
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      return result.content
    },
})

export const invitationEmailsConcatSelectorFamily = selectorFamily<
  { emails: InvitationEmailStatus[]; nextToken?: string | null; total?: number } | undefined,
  { organizationId: string; nextTokens: string[] }
>({
  key: "selectors/organization/invitationEmailsConcatSelectorFamily",
  get:
    ({ nextTokens, ...params }) =>
    async ({ get }) => {
      get(invitationEmailsConcatRequestIdState)
      const result = [
        get(invitationEmailsSelectorFamily(params)),
        ...nextTokens.map((nextToken) => get(invitationEmailsSelectorFamily({ ...params, nextToken }))),
      ]
      return {
        emails: result.reduce((p, c) => (c != null ? [...p, ...c.emails] : p), [] as InvitationEmailStatus[]),
        nextToken: result.length > 0 ? result[result.length - 1]?.nextToken : undefined,
        total: result[0]?.total,
      }
    },
})
