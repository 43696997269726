import { memo } from "react"
import { StyleSheet, Text } from "react-native"
import { notNull } from "src/utils/typeguards"

type Props = {
  text: string | undefined | null
  maxLength: number
  unit?: string
}

export const InputLengthCounter = memo<Props>(({ text, maxLength, unit }) => {
  return <Text style={styles.text}>{[text?.length || 0, "/", maxLength, unit].filter(notNull).join(" ")}</Text>
})

const styles = StyleSheet.create({
  text: {
    fontSize: 14,
  },
})
