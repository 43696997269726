import React, { memo, useState, useCallback, useEffect, useMemo } from "react"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { RootStackScreenProps } from "src/types.d"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { Colors } from "src/constants/Colors"
import { Button } from "src/components/parts/buttons/Button"
import { View, Text, StyleSheet, Platform, Linking } from "react-native"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { Screens } from "src/constants/Screens"
import { oldRa9Ra9Login } from "src/apis/migration/oldRa9Ra9Api"
import { CustomAlert } from "src/utils/CustomAlert"
import { oldRa9Ra9TokenStates } from "src/recoil/atoms/migration/oldRa9Ra9TokenStates"
import { useSetRecoilState } from "recoil"
import { validateEmail } from "src/utils/validateEmail"
import { getOldRa9Ra9AuthStateFromResult } from "src/utils/getOldRa9Ra9AuthStateFromResult"
import { getAccessTokenByTempToken } from "src/apis/migration/getAccessTokenByTempToken"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { PC_BREAKPOINT } from "src/constants/Screen"
import Constants from "expo-constants"
import { BreadCrumb } from "./BreadCrumb"
import { Step } from "./BreadCrumb"
import { ScrollView } from "react-native-gesture-handler"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"

const useMigrationLogin = (type: string | undefined, teamId: string | undefined, tempToken?: string, navigation?: any) => {
  const [loginId, setLoginId] = useState("")
  const [password, setPassword] = useState("")
  const [isSending, setIsSending] = useState(false)

  const loginIdError = useMemo(
    () => (loginId && validateEmail(loginId) ? "" : "正しいメールアドレスを入力してください"),
    [loginId]
  )
  const passwordError = useMemo(() => (password !== "" ? "" : "パスワードを入力してください"), [password])

  const isDisabled = useMemo(
    () => loginIdError !== "" || passwordError !== "" || isSending,
    [loginIdError, passwordError, isSending]
  )

  const setOldRa9Ra9AccessTokens = useSetRecoilState(oldRa9Ra9TokenStates)

  const onLogin = useCallback(() => {
    setIsSending(true)
    loginId &&
      password &&
      oldRa9Ra9Login(loginId, password)
        .then(async (res) => {
          if (res.status === 200) {
            const { access_token, user_id } = await res.json()
            if (access_token == null || user_id == null) {
              setIsSending(false)
              return
            }
            const oldRa9Ra9AuthState = getOldRa9Ra9AuthStateFromResult({
              access_token,
              user_id: user_id.toString(),
            })
            if (oldRa9Ra9AuthState == null) {
              setIsSending(false)
              return
            }
            setOldRa9Ra9AccessTokens(oldRa9Ra9AuthState)
            type === "team"
              ? navigation?.navigate(Screens.MigrationTeamSelect)
              : navigation?.navigate(Screens.MigrationMailConfirmation, { organizationId: teamId })
          } else {
            setIsSending(false)
            await CustomAlert.alert("エラーコード019", "メールアドレスまたはパスワードが正しくありません。")
            return
          }
          setIsSending(false)
        })
        .catch((e) => {
          console.log(e)
          setIsSending(false)
        })
  }, [loginId, password, setOldRa9Ra9AccessTokens, type, navigation, teamId])

  const setTokens = useSetRecoilState(tokensState)
  useEffect(() => {
    if (tempToken == null) return
    getAccessTokenByTempToken({ tempToken }).then((res) => {
      if (res.isOk) {
        setTokens(res.content)
      } else {
        CustomAlert.alert("エラー", "認証情報の取得に失敗しました。")
      }
    })
  }, [tempToken, setTokens])

  return {
    loginId,
    setLoginId,
    password,
    setPassword,
    onLogin,
    isDisabled,
    isSending,
  }
}

export const MigrationLogin = memo<RootStackScreenProps<"MigrationLogin">>(({ navigation, route }) => {
  const [passwordVisibility] = useState(true)
  const isLargeScreen = useCheckPCScreen()
  const type = route.params.type?.toString()
  const teamId = route.params.organizationId?.toString()
  const tempToken = route.params.tt?.toString()
  const { loginId, setLoginId, password, setPassword, isSending, onLogin, isDisabled } = useMigrationLogin(
    type,
    teamId,
    tempToken,
    navigation
  )

  const urlOld = () => {
    let domain = "spitz.ra9.jp"
    if (Constants.expoConfig?.extra?.env == "prd") {
      domain = "ra9.jp"
    }
    if (Constants.expoConfig?.extra?.env == "stg") {
      domain = "rev.ra9.jp"
    }
    return domain
  }

  const forgotMyPassword = async () => {
    const url = urlOld()
    await Linking.openURL(`https://${url}/reminders/`)
  }
  const renderViewModal = (content: string) => {
    return (
      <View style={{ flexDirection: "row", padding: 4 }}>
        <Text style={{ paddingRight: 3 }}>※</Text>
        <Text>{content}</Text>
      </View>
    )
  }
  return (
    <CustomKeyboardAvoidingView style={[styles.container]} behavior="position">
      <ScrollView style={[isLargeScreen ? {} : { width: "100%" }]}>
        <BreadCrumb stepProp={Step.one} />
        <View style={[styles.descriptionWrap, isLargeScreen ? { width: 600 } : null]}>
          <Text style={[styles.description]}>
            ここでは、らくらく連絡網（旧サービス）の団体情報を、らくらく連絡網＋に移行します。
          </Text>
          <View style={{ flexDirection: "row" }}>
            <Text style={{ marginRight: 2 }}>・</Text>
            <Text style={[styles.description]}>団体情報を移行できるのは、らくらく連絡網で代表者となっている団体のみです。</Text>
          </View>
          <View style={{ flexDirection: "row" }}>
            <Text style={{ marginRight: 2 }}>・</Text>
            <Text style={[styles.description]}>メンバーの方も代表者に団体の移行リクエストができます。</Text>
          </View>
          <Text style={{ paddingBottom: 19, display: "flex", alignItems: "center" }}>
            {Platform.OS === "ios" || Platform.OS === "android" ? (
              <>
                <Text>※ </Text>
                <Text>移行できる団体情報の詳細は </Text>
              </>
            ) : (
              renderViewModal("移行できる団体情報の詳細は")
            )}
            <Text
              style={[styles.textLink]}
              onPress={() => Linking.openURL("https://www.ra9plus.jp/guide/migration_of_team_information/")}
            >
              こちら
            </Text>
            。
          </Text>
        </View>
        <View style={[styles.titleWrap, isLargeScreen ? { width: 600 } : null]}>
          <Text style={[styles.title]}>団体情報を取得するため、らくらく連絡網（旧サービス）の認証を行ってください。</Text>
          <Text style={[styles.description]}>らくらく連絡網のメールアドレスとパスワードを入力してください。</Text>
        </View>
        <View style={isLargeScreen ? styles.oneColumn : { width: "100%", paddingHorizontal: 24, paddingVertical: 8 }}>
          <View style={styles.vertical}>
            <ItemLabel label={"メールアドレス"} />
            <CustomTextInput
              placeholder={"入力してください"}
              value={loginId}
              onChangeText={setLoginId}
              style={{ marginVertical: 10 }}
              autoComplete="off"
            />

            <ItemLabel label={"パスワード"} />
            <CustomTextInput
              placeholder={"入力してください"}
              secureTextEntry={passwordVisibility}
              value={password}
              onChangeText={setPassword}
              style={{ marginVertical: 10 }}
              autoComplete="off"
            />
          </View>
          <View style={isLargeScreen ? styles.centerLayout : {}}>
            <TextButton
              buttonType={ButtonType.Primary}
              title={"認証"}
              isLoading={isSending}
              disabled={isDisabled}
              onPress={onLogin}
              style={[isLargeScreen ? styles.buttonsPC : {}, { marginTop: 14 }]}
            />
            <TextButton
              buttonType={ButtonType.Blank}
              title={"パスワードを忘れた方はこちら"}
              onPress={forgotMyPassword}
              style={
                isLargeScreen
                  ? styles.buttonsPCForgot
                  : {
                      marginBottom: 20,
                      borderRadius: 0,
                      borderTopColor: Colors.white2,
                      borderBottomColor: Colors.white2,
                      borderTopWidth: 1,
                      borderBottomWidth: 1,
                      marginTop: 40,
                      marginHorizontal: -20,
                    }
              }
            />
          </View>
        </View>
      </ScrollView>
    </CustomKeyboardAvoidingView>
  )
})
MigrationLogin.displayName = "MigrationLogin"

export const migrationLoginOptions: ScreenOptions = ({ navigation }) => ({
  ...commonHeaderOptions,
  headerShown: true,
  title: "団体情報を移行",
  headerLeft: (props) => (
    <Button
      onPress={() =>
        navigation.navigate(Screens.Root, {
          screen: RootTabs.OrganizationManagerTab,
          params: { screen: OrganizationManagerScreens.OrganizationManager },
        })
      }
    >
      <BackIcon
        fill={Number(props.screenLayout?.width) >= PC_BREAKPOINT && Platform.OS === "web" ? Colors.orange : Colors.white3}
        {...props}
      ></BackIcon>
    </Button>
  ),
})
const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.beige,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  oneColumn: {
    width: 600,
    maxWidth: 1020,
    paddingHorizontal: 24,
  },
  vertical: {
    paddingVertical: 10,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  centerLayout: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: 16,
    fontWeight: "600",
    lineHeight: 22.4,
    fontStyle: "normal",
    color: Colors.black2,
    marginBottom: 8,
  },
  buttonsPCForgot: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    alignContent: "center",
    marginBottom: 20,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: Colors.white2,
    borderBottomColor: Colors.white2,
    borderRadius: 0,
    marginTop: 40,
  },
  descriptionWrap: {
    paddingTop: 32,
    paddingHorizontal: 24,
    paddingBottom: 24,
    borderBottomWidth: 1,
    borderBottomColor: Colors.borderColor,
  },
  description: {
    color: Colors.black2,
    fontSize: 14,
    fontStyle: "normal",
    fontFamily: "Hiragino Kaku Gothic Pro",
    fontWeight: "300",
    lineHeight: 19.6,
    marginBottom: 8,
  },
  textLink: {
    color: Colors.orange,
    textDecorationLine: "underline",
  },
  titleWrap: {
    paddingTop: 32,
    paddingHorizontal: 24,
    paddingBottom: 6,
  },
})
