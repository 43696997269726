import { atom } from "recoil"

type Props = {
  organizationId?: string
  inquiry?: string
}

export const inquiryTeamPaidState = atom<Props>({
  key: "atoms/organization/inquiryTeamPaidState",
  default: {},
})
