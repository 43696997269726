import { memo, useCallback, useState } from "react"
import { ScrollView, StyleSheet, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { RootStackScreenProps } from "src/types.d"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { Button } from "src/components/parts/buttons/Button"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { useInviteMailTeamMigrate } from "src/recoil/hooks/organization/useInviteMailTeamMigrate"
import { BottomSheet } from "src/components/parts/BottomSheet"
import { SimpleListBottomSheetInner } from "src/components/parts/bottomSheetInner/SimpleListBottomSheetInner"

export const MailInviteMemberToMigrateTeam = memo<RootStackScreenProps<"MailInviteMemberToMigrateTeam">>(
  ({ navigation, route }) => {
    const { organizationId, nameOrg } = route.params
    const isLargeScreen = useCheckPCScreen()
    const { sendMailToMember, isLoadingSendMailToMember, mailInfo, organization } = useInviteMailTeamMigrate(organizationId)
    const insets = useSafeAreaInsets()
    const [isOpenReceiversBottomSheet, setIsOpenReceiversBottomSheet] = useState(false)
    const openReceiversBottomSheet = useCallback(() => {
      setTimeout(() => {
        setIsOpenReceiversBottomSheet(true)
      }, 800)
    }, [setIsOpenReceiversBottomSheet])

    const backToDetailMigrateTeam = useCallback(() => {
      navigation.goBack()
    }, [navigation])

    return (
      <CustomKeyboardAvoidingView>
        <View style={[styles.headerPC, isLargeScreen ? { height: 63 } : { marginTop: insets.top, paddingVertical: 10 }]}>
          <Button onPress={() => navigation.goBack()}>
            <BackIcon fill={Colors.orange}></BackIcon>
          </Button>
          <View style={styles.viewCenter}>
            <Text style={styles.titleScreen}>{nameOrg}</Text>
          </View>
          <View style={{ width: 36 }}></View>
        </View>
        <ScrollView style={[styles.scrollArea]}>
          <View style={styles.containerMainView}>
            <View style={isLargeScreen ? styles.containerContentViewLarge : { width: "100%" }}>
              <View style={styles.content}>
                <Text>以下の内容でメールを送信いたします。よろしければ「送信」を押してください。</Text>
                <Text>※ [ ]内の文言は、送信時にそれぞれ適切な情報に置き換わります。</Text>
              </View>
              <View style={styles.content}>
                <ItemLabel label="宛先"></ItemLabel>
                <Button onPress={openReceiversBottomSheet} style={styles.marginLabel}>
                  <Text style={styles.textModalLisstMember}>選択中のメンバー ({mailInfo?.length})</Text>
                </Button>
              </View>
              <View style={styles.content}>
                <ItemLabel label="タイトル"></ItemLabel>
                <Text style={styles.marginLabel}>{`【[団体名]】らくらく連絡網＋（プラス）への移行について`}</Text>
              </View>
            </View>
          </View>
          <View style={[isLargeScreen ? styles.containerMainView : { width: "100%" }]}>
            <View style={[isLargeScreen ? styles.containerContentViewLarge : {}, { padding: 16 }]}>
              <View style={{ paddingBottom: 10 }}>
                <ItemLabel label="本文"></ItemLabel>
              </View>
              <Text style={[{ marginTop: 6 }]}>
                ［招待メールを送信した方の団体内ニックネーム］様にて新サービス「らくらく連絡網＋」に団体「［団体名］」が移行されました。
              </Text>
              <Text>
                参加手続きを行っていただきますと、「らくらく連絡網」（旧サービス）での団体内個人設定の情報を引き継ぐことができます。
              </Text>
              <Text>下記をご参考の上、お手続きをお願いいたします。 </Text>
              <Text style={styles.contentSpace}>■こちらのアドレスで既に「らくらく連絡網＋」のアカウントがある方</Text>
              <Text>下記［メンバー登録用URL］よりログインの上、団体参加申請のお手続きを行ってください。</Text>
              <Text style={styles.contentSpace}>■こちらのアドレスで未だ「らくらく連絡網＋」のアカウントがない方</Text>
              <Text>下記［メンバー登録用URL］よりアカウント登録の上、団体参加申請のお手続きを行ってください。</Text>
              <Text style={styles.contentSpace}>［メンバー登録用URL］</Text>
              <Text>［メンバー登録用URL］</Text>
              <Text>［招待コード］</Text>
              <Text>［招待コード］</Text>
              <Text>※URLの有効期限はyyyy/mm/dd です。</Text>
              <Text style={styles.contentSpace}>
                ※本メールは、既に参加手続き済みの方にも配信される場合がございます。URLへアクセス後、団体参加画面で「次へ」ボタンを押下した際に「既に参加申請済です。」と表示される場合、お手続きは不要です。
              </Text>
              <Text style={styles.contentSpace}>登録についてのよくある質問はこちら </Text>
              <Text>https://www.ra9plus.jp/guide/category/start/</Text>
              <Text style={styles.contentSpace}>
                ※本メールにお心あたりがない方は、お手数ですが、このメールを破棄くださいますようお願いいたします。
              </Text>
              <Text>※このメ－ルは送信専用です。返信をいただいてもご回答できませんのでご了承ください。</Text>
              <Text>----</Text>
              <Text>らくらく連絡網＋</Text>
              <Text>https://www.ra9plus.jp/guide</Text>
            </View>
          </View>
          <BottomSheet
            snapPoints={["90%"]}
            isOpen={isOpenReceiversBottomSheet}
            onIsOpenChange={setIsOpenReceiversBottomSheet}
            backgroundColor={Colors.white3}
          >
            <SimpleListBottomSheetInner
              title="メンバー一覧"
              subTitle={`${mailInfo?.length}人`}
              list={mailInfo?.map((member) => ({ value: member.email ?? "", label: member.nickname }))}
              isShowAds={!organization?.paidFunctionEnabled || organization?.showAdsFlg}
            />
          </BottomSheet>
        </ScrollView>
        <View
          style={[
            styles.section,
            styles.tailSection,
            { paddingBottom: insets.bottom + 10 },
            isLargeScreen ? styles.listBtnLargeScreen : {},
          ]}
        >
          <TextButton
            style={[isLargeScreen ? styles.btnCancelLarge : { marginBottom: 10 }, styles.btnCancel]}
            title="キャンセル"
            buttonType={ButtonType.Blank}
            onPress={backToDetailMigrateTeam}
            disabled={isLoadingSendMailToMember}
          />
          <TextButton
            style={isLargeScreen ? { width: 300 } : {}}
            title="送信"
            buttonType={ButtonType.Primary}
            onPress={sendMailToMember}
            isLoading={isLoadingSendMailToMember}
            disabled={isLoadingSendMailToMember}
          />
        </View>
      </CustomKeyboardAvoidingView>
    )
  }
)

MailInviteMemberToMigrateTeam.displayName = "MailInviteMemberToMigrateTeam"

const styles = StyleSheet.create({
  scrollArea: {
    flex: 1,
  },
  section: {
    padding: 16,
  },
  tailSection: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  headerPC: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    backgroundColor: Colors.white3,
    borderColor: Colors.white2,
    borderBottomWidth: 1,
  },
  listBtnLargeScreen: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },

  btnCancel: {
    borderColor: Colors.orange,
    borderRadius: 25,
    borderWidth: 2,
  },

  btnCancelLarge: {
    width: 300,
    marginRight: 10,
  },
  containerMainView: {
    flexDirection: "row",
    justifyContent: "center",
  },
  containerContentViewLarge: {
    width: "60%",
    maxWidth: 1020,
  },
  content: {
    padding: 16,
    borderBottomColor: Colors.white2,
    borderBottomWidth: 1,
  },
  textModalLisstMember: {
    color: Colors.orange,
  },
  contentSpace: {
    marginTop: 20,
  },
  titleScreen: {
    fontSize: 15,
    fontWeight: "700",
    textAlign: "center",
  },
  marginLabel: {
    marginTop: 10,
  },
  viewCenter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
})
