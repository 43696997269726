import { GroupOverview } from "src/types/organization/group/GroupOverview"
import { listGroup } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
  organizationId: string
}

export const getGroups = async ({ accessToken, organizationId }: Params): Promise<GroupOverview[] | undefined> => {
  const result = await execApi(
    () =>
      listGroup(accessToken, {
        input: {
          teamId: organizationId,
        },
        appInfo,
      }),
    (res) => res.listGroup.items,
    {
      identifiers: ["getGroups", "listGroup"],
    }
  )
  if (!result.isOk) return

  const groups = result.content

  if (groups == null) {
    return
  }

  return groups
    .map(
      (g) =>
        g && {
          id: g.id,
          groupName: g.name,
        }
    )
    .filter((g) => g != null) as GroupOverview[]
}
