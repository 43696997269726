import React, { memo, useCallback, useImperativeHandle, useMemo, useRef, useState, useEffect } from "react"
import { TeamList } from "src/apis/migration/getMigrationTeams"
import { View, StyleSheet, Text, Modal, Platform } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { Screens } from "src/constants/Screens"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { RootTabs } from "src/constants/RootTabs"
import { RootStackScreenProps } from "src/types.d"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { oldRa9Ra9TokenStates } from "src/recoil/atoms/migration/oldRa9Ra9TokenStates"
import { migrationMail } from "src/apis/migration/migrationMail"
import { organizationDetailSelectorFamily as teamDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { useFetcher } from "src/hooks/useFetcher"
import { useMigrationTeams } from "./migrationTeamSelect"
import { useRefresher } from "src/hooks/useRefresher"
import { migrationMailRequestIdState } from "src/recoil/atoms/migration/migrationMailRequestIdState"
import { useAsyncState } from "src/hooks/useAsyncState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { sendAnalyticsEventAsync } from "src/tags/analytics/Analytics"
import { ContactNetworkDrawers } from "src/constants/ContactNetworkDrawers"
import { PC_BREAKPOINT } from "src/constants/Screen"
import { CustomAlert } from "src/utils/CustomAlert"
import { ScrollView } from "react-native-gesture-handler"

const useMigrationMailExecution = (teamId: string | undefined) => {
  /**
   * 連絡移行のために必要な旧らくらく連絡網のmemberIdを取得のために所属団体一覧取得を叩いてる。
   */
  const { allTeamList } = useMigrationTeams()
  const { value: team } = useAsyncSelector(teamDetailSelectorFamily(teamId))
  const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(teamId))
  const teamName = useMemo(
    () => allTeamList && allTeamList?.find((oldTeam) => oldTeam?.team_id === team?.migrationId)?.name,
    [allTeamList, team]
  )

  useEffect(() => {
    if (allTeamList == null || team == null) return
    if (allTeamList.find((oldTeam) => oldTeam.team_id === team.migrationId) === undefined) {
      CustomAlert.alert(
        "エラーコード021",
        "「らくらく連絡網」（旧サービス）側の団体が解約されている、またはメンバー登録されていないため、移行データを取得できません。"
      )
    }
  }, [team, allTeamList])

  const [migrationCompletedModalOpen, setMigrationCompletedModalOpen] = useState(false)
  const onCloseMigrationCompletedModal = () => {
    setMigrationCompletedModalOpen(false)
  }

  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const teamMemberId = useMemo(() => myProfile?.memberId, [myProfile])
  const { value: oldRa9Ra9AccessTokens } = useAsyncSelector(oldRa9Ra9TokenStates)
  const oldRa9Ra9AccessToken = useMemo(() => oldRa9Ra9AccessTokens?.oldRa9Ra9AccessToken, [oldRa9Ra9AccessTokens])
  const oldRa9Ra9UserId = useMemo(() => Number(oldRa9Ra9AccessTokens?.oldRa9Ra9UserId), [oldRa9Ra9AccessTokens])
  const oldRa9Ra9MemberId = useMemo(
    () => allTeamList?.find((oldTeam) => oldTeam?.team_id === team?.migrationId)?.member_id,
    [allTeamList, team]
  )
  const refreshMails = useRefresher(migrationMailRequestIdState)

  const { fetch: migration, isFetching: isLoadingMigration } = useFetcher(
    useCallback(async () => {
      if (
        accessToken == null ||
        teamMemberId == null ||
        oldRa9Ra9MemberId == null ||
        team?.migrationId == null ||
        oldRa9Ra9AccessToken == null ||
        oldRa9Ra9UserId == null
      )
        return
      const migrationMailResult = await migrationMail({
        accessToken,
        teamMemberId,
        oldRa9Ra9AccessToken,
        oldRa9Ra9UserId,
        oldRa9Ra9TeamId: team?.migrationId as number,
        oldRa9Ra9MemberId,
      })
      if (!migrationMailResult.isOk) {
        CustomAlert.alert("エラー", migrationMailResult.error.message)
        return
      }
      sendAnalyticsEventAsync("custom_action", "clickButton", "移行を実行するボタン（連絡移行）", teamId)
      refreshMails()
      setMigrationCompletedModalOpen(true)
    }, [
      accessToken,
      teamMemberId,
      oldRa9Ra9MemberId,
      team?.migrationId,
      oldRa9Ra9AccessToken,
      oldRa9Ra9UserId,
      teamId,
      refreshMails,
    ])
  )

  return {
    migration,
    isLoadingMigration,
    migrationCompletedModalOpen,
    onCloseMigrationCompletedModal,
    teamName,
  }
}

export const MigrationMailConfirmation = memo<RootStackScreenProps<"MigrationMailConfirmation">>(({ navigation, route }) => {
  const alert = useRef()
  const organizationId = useMemo(() => route.params?.organizationId?.toString(), [route.params?.organizationId])
  const { migration, isLoadingMigration, migrationCompletedModalOpen, onCloseMigrationCompletedModal, teamName } =
    useMigrationMailExecution(organizationId)
  const isLargeScreen = useCheckPCScreen()

  return (
    <View style={[styles.container]}>
      <ScrollView style={[isLargeScreen ? {} : { width: "100%" }]}>
        <Alert ref={alert}></Alert>
        <Modal animationType="fade" visible={migrationCompletedModalOpen} transparent>
          <View style={styles.secondLayer}></View>
          <View style={styles.backgroundModal}>
            <View style={styles.mainModal}>
              <View
                style={[
                  { display: "flex", flexDirection: "row", marginBottom: 15 },
                  !isLargeScreen && { justifyContent: "space-between", alignItems: "center" },
                ]}
              >
                <Button onPress={onCloseMigrationCompletedModal} style={{ paddingVertical: 10, paddingHorizontal: 10 }}>
                  <RemoveIcon fill={Colors.orange}></RemoveIcon>
                </Button>
                <Text style={isLargeScreen ? styles.titleModalLarge : styles.titleModal}>移行完了</Text>
                <View style={{ paddingVertical: 10, paddingHorizontal: 10, opacity: 0 }}>
                  <RemoveIcon fill={Colors.orange}></RemoveIcon>
                </View>
              </View>
              <Text style={styles.title}>{"「らくらく連絡網＋」に" + teamName + "の\n送信履歴が移行されました。"}</Text>
              <Text style={styles.content}>
                {
                  "・アプリ版ご利用の方：\n画面左上の「完了」から団体選択画面に戻った上で、画面を再読み込みして移行した連絡をご確認ください。\n\n・WEB版ご利用の方：\n「閉じる」をクリック後、画面を再読み込みして移行した連絡をご確認ください。"
                }
              </Text>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <Button
                  onPress={() => {
                    navigation.navigate(Screens.Root, {
                      screen: RootTabs.ContactNetworkDrawer,
                      params: {
                        screen: ContactNetworkDrawers.MigrationScreen,
                        params: { organizationId: organizationId },
                      },
                    })
                    onCloseMigrationCompletedModal()
                  }}
                  style={styles.buttonCustom}
                >
                  <Text style={styles.textButton}>閉じる</Text>
                </Button>
              </View>
            </View>
          </View>
        </Modal>
        {teamName ? (
          <View style={isLargeScreen ? styles.oneColumn : { width: "100%", padding: 20 }}>
            <Text style={[styles.title]}>{"下記の団体の送信トレイを移行します。"}</Text>
            <View style={styles.vertical}>
              <ItemLabel label={"移行する団体"} />
              <View style={styles.card}>
                <Text style={{ fontWeight: "bold" }}>{teamName}</Text>
              </View>
            </View>
            <Text style={styles.content}>
              {
                "最新の5,000件までの連絡の送信履歴が移行されます。\nすでに移行した履歴がある場合、現在の移行トレイ内の履歴は全て削除され、新しく移行する履歴に書き換わります。\n※移行できる情報は【件名・本文・送信者・送信日時】のみとなります。回答項目や回答結果、添付ファイル等は移行されません。\n※移行した履歴の保持期限は、移行後2年間です。\n※受信履歴は移行できません。"
              }
            </Text>
            <View style={isLargeScreen ? styles.centerLayout : { paddingHorizontal: 30, marginTop: 15 }}>
              <TextButton
                buttonType={ButtonType.Primary}
                title={"移行を実行する"}
                isLoading={isLoadingMigration}
                onPress={migration}
                style={isLargeScreen ? styles.buttonsPC : {}}
              />
            </View>
          </View>
        ) : (
          <Text style={{ paddingTop: 12 }}>団体が選択されていません。</Text>
        )}
      </ScrollView>
    </View>
  )
})

export const Alert = React.forwardRef((props, ref) => {
  const [visible, setVisible] = useState(false)
  const [title, setTitle] = useState("")
  const [content, setContent] = useState("")
  useImperativeHandle(ref, () => ({
    open: (title: string, content: string) => {
      setVisible(true)
      setTitle(title)
      setContent(content)
    },
  }))
  const close = () => {
    setVisible(false)
    setTitle("")
    setContent("")
  }
  return (
    <Modal animationType="fade" visible={visible} transparent>
      <View style={styles.secondLayer}></View>
      <View style={styles.backgroundModal}>
        <View style={styles.alertTop}>
          <View style={{ display: "flex", flexDirection: "row" }}>
            <Text style={styles.titleModal}>{title}</Text>
          </View>
          <Text style={styles.contentAlert}>{content}</Text>
        </View>
        <View style={styles.alertBottom}>
          <Button onPress={close}>
            <Text style={{ color: "#3478F6" }}>閉じる</Text>
          </Button>
        </View>
      </View>
    </Modal>
  )
})
export const migrationMailConfirmationOptions: ScreenOptions = ({ navigation }) => ({
  ...commonHeaderOptions,
  headerShown: true,
  title: "移行団体確認(連絡)",
  headerLeft: (props) => (
    <Button
      onPress={() =>
        navigation.navigate(Screens.Root, {
          screen: RootTabs.ContactNetworkDrawer,
          params: { screen: ContactNetworkDrawers.MigrationScreen },
        })
      }
    >
      <BackIcon
        fill={Number(props.screenLayout?.width) >= PC_BREAKPOINT && Platform.OS === "web" ? Colors.orange : Colors.white3}
        {...props}
      ></BackIcon>
    </Button>
  ),
})
const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.beige,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  oneColumn: {
    width: 600,
    maxWidth: 1020,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
  },
  vertical: {
    paddingVertical: 10,
    paddingHorizontal: 30,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    marginTop: 15,
    borderRadius: 26,
    alignContent: "center",
  },
  centerLayout: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: 16,
    fontWeight: "bold",
    width: "100%",
    textAlign: "center",
    marginBottom: 20,
  },
  titleModal: {
    fontSize: 18,
    fontWeight: "600",
    textAlign: "center",
  },
  titleModalLarge: {
    fontSize: 18,
    fontWeight: "600",
    width: "100%",
    textAlign: "center",
  },
  content: {
    fontSize: 14,
    fontWeight: "400",
    width: "100%",
    textAlign: "left",
    paddingHorizontal: 22,
  },
  card: {
    borderRadius: 12,
    padding: 15,
    fontWeight: "bold",
    backgroundColor: "#FFFFFF",
    shadowColor: "#171717",
    shadowOffset: { width: -2, height: 4 },
    shadowOpacity: 0.2,
    shadowRadius: 3,
    cursor: "pointer",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    width: "100%",
    marginVertical: 5,
    fontFamily: "Hiragino Kaku Gothic Pro",
  },
  linkText: {
    color: Colors.orange,
    textDecorationLine: "underline",
  },
  backgroundModal: {
    position: "absolute",
    zIndex: 100,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainModal: {
    backgroundColor: Colors.white3,
    width: 342,
    height: 419,
    padding: 20,
    borderRadius: 24,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  secondLayer: { backgroundColor: "#f0f0f0", opacity: 0.4, width: "100%", height: "100%" },
  alertTop: {
    backgroundColor: Colors.white3,
    width: 270,
    padding: 16,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  alertBottom: {
    display: "flex",
    justifyContent: "center",
    borderTopWidth: 0.2,
    borderTopColor: "#f0f0f0",
    textAlign: "center",
    backgroundColor: Colors.white3,
    width: 270,
    height: 44,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  contentAlert: {
    fontSize: 14,
    fontWeight: "400",
    width: "100%",
    textAlign: "left",
    paddingHorizontal: 2,
  },
  textButton: { color: "#F0830E", fontSize: 18, fontWeight: "600", lineHeight: 25.2, textAlign: "center" },
  buttonCustom: { margin: 25, width: 246, borderRadius: 8, backgroundColor: "rgba(159, 134, 107, 0.1)", padding: 10 },
})
