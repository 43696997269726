import { useCallback, useMemo } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { memberInvitationSelectorFamily } from "src/recoil/selectors/organization/memberInvitationSelectorFamily"
import * as Clipboard from "expo-clipboard"
import { CustomAlert } from "src/utils/CustomAlert"
import { Share } from "react-native"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { getOrganizationInvitationMessage } from "src/apis/organization/getOrganizationInvitationMessage"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { Analytics } from "src/tags/analytics/Analytics"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"

export const useMemberInvitationData = (id: string) => {
  const { value: organizationDetail } = useAsyncSelector(organizationDetailSelectorFamily(id))
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const { value: memberInvitationData, isLoading: isMemberInvitationDataLoading } = useAsyncSelector(
    memberInvitationSelectorFamily(id)
  )
  const { resourceUrl: thumbnailImageUrl } = useResource({ type: "teamImage", id, size: "thumbnail" })
  const organizationId = useMemo(() => memberInvitationData?.organizationId, [memberInvitationData])
  const organizationName = useMemo(() => memberInvitationData?.organizationName, [memberInvitationData])
  const invitationCode = useMemo(() => memberInvitationData?.invitationCode, [memberInvitationData])
  const openShareSheet = useCallback(async () => {
    if (accessToken == null || organizationId == null) {
      return
    }
    await Analytics.shareInvitation(organizationId)

    const message = await getOrganizationInvitationMessage({ accessToken, organizationId })
    if (!message.isOk) {
      await CustomAlert.alert("エラー", message.error.message)
      return
    }

    try {
      await Share.share(message.content)
    } catch (_error) {
      await CustomAlert.alert("エラー", "シェアに失敗しました")
    }
  }, [accessToken, organizationId])
  const copyInvitationCode = useCallback(async () => {
    if (invitationCode == null) {
      return
    }
    if (organizationId != null) await Analytics.copyInvitationCode(organizationId)
    const isSuccess = await Clipboard.setStringAsync(invitationCode)
    if (isSuccess) {
      await CustomAlert.alert("完了", "コピーしました")
    }
  }, [invitationCode, organizationId])

  return {
    thumbnailImageUrl,
    organizationName,
    invitationCode,
    isMemberInvitationDataLoading,
    openShareSheet,
    copyInvitationCode,
    organizationDetail,
  }
}
