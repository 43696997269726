import { memo, useCallback, useEffect } from "react"
import { Platform } from "react-native"
import { EnterEmail } from "src/components/parts/authorization/EnterEmail"
import { HeaderLeft } from "src/components/parts/headers/HeaderLeft"
import { Screens } from "src/constants/Screens"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useEmailSignupData } from "src/recoil/hooks/authorization/useAuthEmailData"
import { RootStackScreenProps } from "src/types.d"

export const SignupEmail = memo<RootStackScreenProps<"SignupEmail">>(({ navigation }) => {
  const gotoSignupCode = useCallback((email: string) => navigation.navigate(Screens.SignupCode, { email }), [navigation])
  const { email, setEmail, sendAuthCode, isDisabled, isSending } = useEmailSignupData(gotoSignupCode)

  useEffect(() => {
    if (Platform.OS === "web") {
      navigation.setOptions({
        headerLeft: () => <HeaderLeft onPress={() => navigation.navigate(Screens.AuthorizationTop)} canGoBack={true} />,
      })
    }
  }, [navigation])

  return (
    <EnterEmail
      label="メールアドレスを入力"
      email={email}
      onEmailChange={setEmail}
      sendAuthCode={sendAuthCode}
      isDisabled={isDisabled}
      isEditable={!isSending}
    />
  )
})
SignupEmail.displayName = "SignupEmail"

export const signupEmailOptions: ScreenOptions = {
  ...commonHeaderOptions,
  headerShown: true,
  title: "新規登録",
}
