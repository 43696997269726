import { selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { OrganizationMemberOverview } from "src/types/organization/OrganizationMember"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"
import { listNotJoinMembersRequestIdState } from "src/recoil/atoms/organization/listNotJoinMembersRequestIdState"
import { getListNotJoinMembers } from "src/apis/organization/getListNotJoinMembers"

export const listNotJoinMembersSelectorFamily = selectorFamily<
  OrganizationMemberOverview[] | undefined,
  { organizationId: string }
>({
  key: "selectors/organization/listNotJoinMembersSelector",
  get:
    ({ organizationId }) =>
    async ({ get }) => {
      if (organizationId == null) return

      get(listNotJoinMembersRequestIdState)
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) {
        return
      }
      const accountId = get(accountIdState)
      if (accountId == null) {
        return
      }
      let pageIndex = 1
      let allList: OrganizationMemberOverview[] = []
      let loopValue = true
      while (loopValue) {
        const result = await getListNotJoinMembers({
          accessToken,
          organizationId,
          accountId,
          page: pageIndex,
          pageSize: 300,
        })
        if (!result.isOk) {
          return
        }
        allList = [...allList, ...result.content]
        if (result.content.length < 300) {
          loopValue = false
          break
        }
        pageIndex++
      }
      return allList
    },
})
