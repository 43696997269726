import { GqlError, changePlanPaidOrder  } from "src/aws/customAPI";
import { PlanData } from "src/recoil/atoms/planData/planDataState";
import { convertDataEmail } from "src/utils/validate";
type Params = {
  accessToken: string
  orderId: string
  planData: PlanData
}

export const changePlanPaidOrderApi = async ({accessToken, orderId , planData}: Params): Promise<{id: string} | any> => {
  if (planData.planId && planData.startTimeConvert) {
    return changePlanPaidOrder(accessToken, {input: {
      orderId : orderId,
      planId: planData.planId,
      startDate: planData.startTimeConvert,
      applicantRemark: convertDataEmail(planData.remark ?? "").length <= 2000 ? convertDataEmail(planData.remark ?? "") : convertDataEmail(planData.remark ?? "").slice(0, 2000)
    }}).then(res => {
      if (res.data?.changePlanPaidVersionOrder.id) {
        return {id: res.data?.changePlanPaidVersionOrder.id}
      } else {
        return ""
      }
    }).catch((e: GqlError) => {
      return e
    })
  }
}