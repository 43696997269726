import { memo } from "react"
import { Svg, Path } from "react-native-svg"

export const UserGuideIcon = memo(() => (
  <Svg width="17.895" height="17.873" viewBox="0 0 17.895 17.873">
    <Path
      d="M19.427 6.4a5.455 5.455 0 0 0-3.062-3.712A18.274 18.274 0 0 0 10.945 2a18.227 18.227 0 0 0-5.4.687A5.456 5.456 0 0 0 2.482 6.4 19.773 19.773 0 0 0 2 10.946a19.724 19.724 0 0 0 .483 4.529 5.455 5.455 0 0 0 3.062 3.712 18.121 18.121 0 0 0 5.4.687 17.546 17.546 0 0 0 5.4-.687 5.456 5.456 0 0 0 3.062-3.712 18.759 18.759 0 0 0 .483-4.528 18.808 18.808 0 0 0-.463-4.547zm-7.72 8.779H10.3v-1.208h1.411zm.018-3.025v.705H10.3v-2.024h.7a1.077 1.077 0 1 0-1.076-1.077H8.5a2.506 2.506 0 1 1 5.011 0 2.477 2.477 0 0 1-1.787 2.394z"
      transform="translate(-1.999 -2)"
      fill={"#f2caa0"}
    />
  </Svg>
))
