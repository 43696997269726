import { StyleSheet, View, Image, Text, Modal, FlatList, ListRenderItemInfo, ActivityIndicator, Platform } from "react-native"
import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { OrganizationManagerStackScreenProps } from "src/types.d"
import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { useCheckPCScreen, useCheckSPScreen } from "src/hooks/useCheckPCScreen"
import { useMatchingOfferDetails } from "src/recoil/hooks/matching/useMatchingOfferDetails"
import { FavoriteIcon } from "src/components/parts/icons/FavoriteIcon"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"
import { ShowDate } from "src/utils/useDateTime"
import { ScrollView } from "react-native-gesture-handler"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { MainLayout } from "src/layouts/MainLayout"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { ListMatchingMessages } from "src/components/parts/matching/MatchingMessageContents"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { BottomSheet } from "src/components/parts/BottomSheet"
import {
  ListOptionBottomSheet,
  OptionValueBottomSheet,
  OptionBottomSheet,
  ListActionBackInThread,
  StoppedListOptionBottomSheet,
} from "src/constants/OfferDetail"
import { EditIcon } from "src/components/parts/icons/EditIcon"
import { PlusIcon } from "src/components/parts/icons/PlusIcon"
import { OclockIcon } from "src/components/parts/icons/OclockIcon"
import { LocationPinIcon } from "src/components/parts/icons/LocationPinIcon"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { HamburgerIcon } from "src/components/parts/icons/HamburgerIcon"
import { useAsyncState } from "src/hooks/useAsyncState"
import {
  createOrEditMatchingOfferState,
  createOrEditParams,
} from "src/recoil/atoms/matching/createOrEdit/createOrEditMatchingOfferState"
import { Screens } from "src/constants/Screens"
import { AgreeMatchingTermModal } from "./AgreeMatchingTermModal"
import { useMyMatchingOffers } from "src/recoil/hooks/matching/useMyMatchingOffers"
import { Linking } from "react-native"
import { MatchingAttachmentViewer } from "src/components/parts/matching/AttachmentViewer"
import { CustomAlert } from "src/utils/CustomAlert"
import { isBeforeToday } from "src/utils/useDate"
import { matchingIcon } from "src/constants/matchingIcon"
import { ProfileSetupModal } from "src/components/parts/matching/MatchingListFooter"
import { isEndPublishDatetime } from "src/recoil/hooks/resource/fileManager/common"

export type OfferDetailParams = {
  id: string
  isMyMatching?: boolean
  isApplications?: boolean
  isFavorite?: boolean
}

type Props = OrganizationManagerStackScreenProps<typeof MatchingDrawers.MatchingOfferDetail>

export const MatchingOfferDetail = memo<Props>(({ navigation, route }) => {
  const { id, isMyMatching, isApplications, isFavorite } = useMemo(() => route.params ?? {}, [route.params])
  const isLargeScreen = useCheckPCScreen()
  const { checkCreateNewOffer } = useMyMatchingOffers()
  const [myCreateData, setMyCreateData] = useAsyncState<createOrEditParams>(createOrEditMatchingOfferState)
  const [isLoadingFile, setIsLoadingFile] = useState<boolean>(false)
  const {
    value,
    teamId,
    teamImageUrl,
    agreeModalOpen,
    onClickApplication,
    refreshTeamImageUrl,
    message,
    setMessage,
    onApplication,
    onClickSubmissionConfirmationModal,
    submissionConfirmationModalOpen,
    onCloseSubmissionConfirmationModal,
    isSubmitDisabled,
    appliedFlg,
    isBottomSheet,
    onIsFavoriteChange,
    setBottomSheet,
    execDeleteMatchingOffer,
    setConfirmSubmitOpen,
    confirmSubmitOpen,
    isLoading,
    isSavingAsDraft,
    isDeleteMatchingOffer,
    favFlag,
    isLoadingCreateFav,
    isLoadingDeleteFav,
    goToProfile,
    profileSetupModalOpen,
    setProfileSetupOpen,
    goToSetupProfile,
    continuousAction,
  } = useMatchingOfferDetails(id)

  const isSPScreen = useCheckSPScreen()

  const handleDeleteMatchingOffer = async (callback: () => void) => {
    if (isSPScreen) setBottomSheet(false)
    const isConfirmed = await CustomAlert.confirm(
      "確認",
      "募集を停止しますか？\n一度募集を停止すると同原稿で募集を再開することはできません。"
    )
    if (isConfirmed) {
      execDeleteMatchingOffer(callback)
    } else {
      if (isSPScreen) setBottomSheet(true)
    }
  }

  const lastAplication = useCallback(() => {
    if (value?.applications?.length) {
      const myApplication = value.applications?.find((el) => {
        const idTeamApply = el.id.split(".")[0] ?? ""
        return idTeamApply === teamId
      })
      return myApplication ? [myApplication] : []
    }
    return []
  }, [value, teamId])

  const backToList = useCallback(() => {
    if (isApplications) {
      navigation.navigate(MatchingDrawers.Applications)
    } else if (isMyMatching) {
      navigation.navigate(MatchingDrawers.MyMatchingOffer)
    } else if (isFavorite) {
      navigation.navigate(MatchingDrawers.FavoriteMatchingOffer)
    } else {
      navigation.navigate(MatchingDrawers.OtherMatchingOffer)
    }
    setMessage("")
  }, [navigation, isMyMatching, isApplications, setMessage, isFavorite])

  useEffect(() => {
    navigation.setOptions({
      headerRight: () =>
        !isLargeScreen &&
        isMyMatching && (
          <Button disabled={isLoading} onPress={() => setBottomSheet(true)}>
            <HamburgerIcon />
          </Button>
        ),
      headerLeft: () => (
        <Button
          onPress={() => {
            backToList()
            setConfirmSubmitOpen(false)
          }}
        >
          <BackIcon fill={Colors.white3}></BackIcon>
        </Button>
      ),
    })
  }, [navigation, setBottomSheet, isLargeScreen, isMyMatching, backToList, setConfirmSubmitOpen, isLoading])

  const formatDateTime = useMemo(
    () => ShowDate(value?.date ? new Date(value?.date) : null, value?.startTime, value?.endTime) + " ",
    [value]
  )
  const formatPublishEndDatetime = useMemo(
    () => value?.publishEndDate && ShowDate(new Date(value?.publishEndDate), true),
    [value?.publishEndDate]
  )

  const actionBottomSheet = async (option: OptionValueBottomSheet) => {
    const startPath = value?.startTime?.split(":") || ""
    const endPath = value?.endTime?.split(":") || ""
    const startTime = startPath.length === 2 ? new Date() : undefined
    if (startTime) {
      startTime.setHours(Number(startPath[0]))
      startTime.setMinutes(Number(startPath[1]))
    }
    const endTime = endPath.length === 2 ? new Date() : undefined
    if (endTime) {
      endTime.setHours(Number(endPath[0]))
      endTime.setMinutes(Number(endPath[1]))
    }

    const newVaule: createOrEditParams = {
      body: value?.body || "",
      attachmentFileNames: value?.attachmentFileNames || [],
      endTime: endTime,
      startTime: startTime,
      title: value?.title || "",
      place: value?.place || "",
      placeURL: value?.placeURL || "",
      expense: value?.expense || "",
      expensePurpose: value?.expensePurpose || "",
      activity: value?.activity,
      offerCount: value?.offerCount?.toString(),
      date: value?.date ? new Date(value.date) : undefined,
      publishEndDatetime: new Date(value?.publishEndDate || ""),
      prefecture: {
        id: Number(value?.prefecture?.id),
        label: value?.prefecture?.label || "",
      },
      city: {
        id: value?.city?.id || "",
        label: value?.city?.label || "",
      },
      ageFrom: {
        id: value?.ageFrom?.toString() || "",
        label: value?.ageFrom?.toString() || "",
      },
      ageTo: {
        id: value?.ageTo?.toString() || "",
        label: value?.ageTo?.toString() || "",
      },
      modeValue: "Edit",
    }

    if (option === OptionValueBottomSheet.EDIT) {
      setBottomSheet(false)
      if (value?.applications?.length !== 0 || isBeforeToday(value.publishEndDate)) {
        CustomAlert.alert("エラーコード035", "既に応募が発生している、または募集期限を過ぎているため、編集できません。")
        return
      }
      navigation.navigate(Screens.MatchingOfferCreate, { id })
      setMyCreateData(newVaule)
    } else if (option === OptionValueBottomSheet.COPY) {
      checkCreateNewOffer(() => navigation.navigate(Screens.MatchingOfferCreate))
      setMyCreateData(newVaule)
      setBottomSheet(false)
    } else if (option === OptionValueBottomSheet.STOP) {
      await handleDeleteMatchingOffer(() => navigation.navigate(MatchingDrawers.MyMatchingOffer))
    }
  }

  const renderItem = (data: ListRenderItemInfo<OptionBottomSheet>) => (
    <Button
      onPress={() => actionBottomSheet(data.item.value)}
      style={{ borderBottomWidth: 1, borderColor: Colors.white2, paddingHorizontal: 8, paddingVertical: 16 }}
    >
      <Text style={{ textAlign: "center", fontSize: 16 }}>{data.item.label}</Text>
    </Button>
  )

  if (isLoadingFile) {
    return (
      <View style={styles.loadingIndicator}>
        <LoadingIndicator />
      </View>
    )
  }

  return (
    <MainLayout>
      <CustomKeyboardAvoidingView>
        <AgreeMatchingTermModal agreeTermProp={!agreeModalOpen} continuousAction={continuousAction} />
        <ProfileSetupModal
          visible={profileSetupModalOpen}
          handleClose={() => setProfileSetupOpen(false)}
          goToSetupProfile={goToSetupProfile}
        />
        {isLargeScreen && (
          <View style={[styles.headerPC, { justifyContent: "space-between" }]}>
            <Button
              onPress={() => {
                backToList()
                setConfirmSubmitOpen(false)
              }}
            >
              <BackIcon fill={Colors.orange}></BackIcon>
            </Button>
            <View style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
              <Text style={[{ fontSize: 18, fontWeight: "500", textAlign: "center" }]}>団体マッチング募集詳細</Text>
            </View>
            <View style={{ width: 36 }}></View>
          </View>
        )}

        {isSavingAsDraft || isLoading ? (
          <View style={styles.loading}>
            <LoadingIndicator />
          </View>
        ) : (
          <ScrollView>
            <Modal
              animationType="fade"
              visible={submissionConfirmationModalOpen}
              onRequestClose={() => onCloseSubmissionConfirmationModal()}
              transparent
            >
              <View style={styles.centeredView}>
                <View style={isLargeScreen ? styles.modalViewPC : styles.modalView}>
                  <View style={isLargeScreen ? styles.headerModelPC : styles.headerModel}>
                    <Button
                      style={isLargeScreen ? styles.iconClosePopupLarge : styles.iconClosePopup}
                      onPress={() => onCloseSubmissionConfirmationModal()}
                    >
                      <RemoveIcon fill={Colors.orange} />
                    </Button>
                    <Text style={isLargeScreen ? styles.titleModelLarge : styles.titleModel}>
                      応募してメッセージの やりとりを開始します
                    </Text>
                  </View>
                  <Text style={styles.modalText}>
                    応募するとマッチング用プロフィール情報及び応募メッセージが相手に表示されます。
                    募集団体の都合により、返信が遅れる場合がございます。
                  </Text>
                  <View
                    style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 16 }}
                  >
                    <Button
                      onPress={() => {
                        onApplication(), onCloseSubmissionConfirmationModal()
                      }}
                      style={[styles.btnInterview, { width: isLargeScreen ? 300 : "100%" }]}
                    >
                      <Text style={styles.textBtnInterview}>応募</Text>
                    </Button>
                  </View>
                </View>
              </View>
            </Modal>
            <View>
              <View style={[styles.container, { marginHorizontal: isLargeScreen ? 15 : 0, paddingBottom: 0 }]}>
                <View style={styles.teamInfo}>
                  <View style={[styles.contactTeam]}>
                    <Image
                      style={[styles.imageTeam]}
                      source={teamImageUrl ? { uri: teamImageUrl } : matchingIcon}
                      onError={refreshTeamImageUrl}
                    />
                    <View>
                      <Text style={styles.organizationName}>{value?.organizationName}</Text>
                      {!isMyMatching && (
                        <Button onPress={() => value?.organizationId && goToProfile(value?.organizationId, id)}>
                          <Text style={styles.detailOrg}>この団体のプロフィールを見る</Text>
                        </Button>
                      )}
                    </View>
                  </View>
                  {!isMyMatching && (
                    <View>
                      {!isLoadingCreateFav && !isLoadingDeleteFav ? (
                        <Button onPress={() => onIsFavoriteChange(id)}>
                          <FavoriteIcon isFavorite={favFlag} />
                        </Button>
                      ) : (
                        <Button>
                          <ActivityIndicator />
                        </Button>
                      )}
                    </View>
                  )}
                </View>
                <View style={{ marginBottom: 24 }}>
                  <Text style={styles.litmitTime}>募集期限：{formatPublishEndDatetime}まで</Text>
                  <Text style={styles.titleMatching}>{value?.title}</Text>
                </View>

                <View style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", marginBottom: 24 }}>
                  <Text style={[styles.bgLabel]}>{value?.activity.label}</Text>
                  {value?.ageFrom && value?.ageTo ? (
                    <Text style={[styles.bgLabel]}>
                      {value?.ageFrom}～{value?.ageTo}歳
                    </Text>
                  ) : undefined}

                  <Text style={[styles.bgLabel]}>合同練習・試合</Text>
                </View>
                <View style={{ marginBottom: 24 }}>
                  <View style={{ display: "flex", flexDirection: "row", marginBottom: 12 }}>
                    <View style={{ marginRight: 10 }}>
                      <OclockIcon />
                    </View>
                    <Text style={{ fontSize: 14, fontWeight: "300", lineHeight: 17 }}>開催日時：{formatDateTime}</Text>

                    {(value?.startTime || value?.endTime) && (
                      <Text style={{ fontSize: 14, fontWeight: "300", lineHeight: 17 }}>
                        {value?.startTime ?? ""}～{value?.endTime ?? ""}
                      </Text>
                    )}
                  </View>
                  {(value?.prefecture || value?.city) && (
                    <View style={{ display: "flex", flexDirection: "row", marginBottom: 12 }}>
                      <View style={{ marginRight: 10 }}>
                        <LocationPinIcon />
                      </View>
                      <Text style={{ fontSize: 14, fontWeight: "300", lineHeight: 17 }}>
                        {value?.prefecture?.label} {value?.city?.label}
                      </Text>
                    </View>
                  )}
                  <Text style={styles.offer}>会場：{value?.place}</Text>
                  <View style={styles.url}>
                    <Text style={styles.offer}>会場URL：</Text>
                    {value?.placeURL ? (
                      <Text
                        numberOfLines={1}
                        ellipsizeMode="clip"
                        style={[styles.offerUrl, !isLargeScreen && { width: 250 }]}
                        onPress={() => Linking.openURL(value?.placeURL || "")}
                      >
                        {value?.placeURL}
                      </Text>
                    ) : undefined}
                  </View>
                  <Text style={styles.offer}>費用：{value?.expense}</Text>
                  <Text style={styles.offer}>費用の利用目的：{value?.expensePurpose}</Text>
                  <Text style={[styles.offer, { marginBottom: 0 }]}>募集団体数：{value?.offerCount}</Text>
                </View>
                <View style={styles.description}>
                  <Text>{value?.body}</Text>
                </View>
                {value?.createdAt && value?.attachmentFileNames?.length !== 0 && (
                  <View style={styles.mediaFile}>
                    <MatchingAttachmentViewer
                      setIsLoadingFile={setIsLoadingFile}
                      offerId={id}
                      attachmentFileNames={value?.attachmentFileNames || []}
                      organizationId={value?.organizationId || ""}
                    />
                  </View>
                )}
              </View>
              {(lastAplication().length && appliedFlg) || isMyMatching ? (
                <View>
                  {lastAplication().length ? (
                    <View
                      style={{
                        paddingBottom: 24,
                        backgroundColor: Colors.duckEggBlue,
                        paddingHorizontal: isLargeScreen ? 23 : 8,
                      }}
                    >
                      <ListMatchingMessages
                        to={ListActionBackInThread.detail}
                        title="最新のメッセージ"
                        isMyMatching={isMyMatching}
                        isFavorite={isFavorite}
                        matchingTeamId={value?.organizationId}
                        matchingTeamName={value?.organizationName}
                        isApplications={isApplications || false}
                        applications={lastAplication() || []}
                        myOrganizationId={teamId}
                      />
                    </View>
                  ) : (
                    <View></View>
                  )}
                </View>
              ) : (
                <View>
                  {confirmSubmitOpen ? (
                    <View style={[styles.applicationView, isLargeScreen ? { marginHorizontal: 15 } : { paddingTop: 24 }]}>
                      <Text style={styles.applicationTitle}>この募集に応募します</Text>
                      <Text style={{ marginBottom: 16 }}>
                        応募をすると団体マッチング用プロフィール情報は、応募先の団体に公開されます。
                      </Text>
                      <View style={{ marginBottom: 16 }}>
                        <ItemLabel
                          label="メッセージ"
                          required
                          RightComponent={<InputLengthCounter text={message} maxLength={1000} unit={"字以内"} />}
                        />
                        <MultilineTextInput
                          value={message ?? ""}
                          onChangeText={setMessage}
                          numberOfLines={6}
                          maxLength={1000}
                          style={{ marginTop: 12, height: 240 }}
                          placeholder={"初めまして。\n練習試合に興味があるので、ご連絡しました。よろしくお願いします。"}
                        />
                      </View>

                      {isLargeScreen ? (
                        <View style={isLargeScreen && { display: "flex", justifyContent: "center", flexDirection: "row" }}>
                          <Button
                            style={[
                              styles.btnCloseInterview,
                              { width: isLargeScreen ? 300 : "100%", marginRight: isLargeScreen ? 24 : 0 },
                            ]}
                            onPress={() => {
                              setConfirmSubmitOpen(false), setMessage("")
                            }}
                          >
                            <Text style={styles.textCloseBtnInterview}>キャンセル</Text>
                          </Button>
                          <Button
                            style={[styles.btnInterview, { marginTop: 16, width: isLargeScreen ? 300 : "100%" }]}
                            onPress={onClickSubmissionConfirmationModal}
                            disabled={isSubmitDisabled}
                          >
                            <Text style={[styles.textBtnInterview]}>応募</Text>
                          </Button>
                        </View>
                      ) : (
                        <View>
                          <Button
                            style={[styles.btnInterview, { marginTop: 16 }]}
                            onPress={onClickSubmissionConfirmationModal}
                            disabled={isSubmitDisabled}
                          >
                            <Text style={[styles.textBtnInterview]}>応募</Text>
                          </Button>
                          <Button
                            style={[styles.btnCloseInterview]}
                            onPress={() => {
                              setConfirmSubmitOpen(false), setMessage("")
                            }}
                          >
                            <Text style={styles.textCloseBtnInterview}>キャンセル</Text>
                          </Button>
                        </View>
                      )}
                    </View>
                  ) : (
                    <>
                      {!value?.deleteFlg && !isEndPublishDatetime(value?.publishEndDate ?? "") && (
                        <View
                          style={[
                            styles.container,
                            { display: "flex", justifyContent: "center", flexDirection: "row", marginTop: 16 },
                          ]}
                        >
                          <Button
                            style={[styles.btnInterview, { width: isLargeScreen ? 300 : "100%" }]}
                            onPress={onClickApplication}
                          >
                            <Text style={styles.textBtnInterview}>応募</Text>
                          </Button>
                        </View>
                      )}
                    </>
                  )}
                </View>
              )}
            </View>
          </ScrollView>
        )}
        {isLargeScreen && isMyMatching ? (
          <View
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingHorizontal: 20,
              paddingVertical: 10,
            }}
          >
            {!value?.deleteFlg && (
              <Button
                style={styles.btnActionMore}
                disabled={isLoading}
                onPress={() => actionBottomSheet(OptionValueBottomSheet.EDIT)}
              >
                <EditIcon fill={Colors.orange} />
                <Text style={{ fontSize: 13, fontWeight: "700", color: Colors.orange, marginLeft: 5 }}>募集内容を編集</Text>
              </Button>
            )}
            <Button
              style={styles.btnActionMore}
              disabled={isLoading}
              onPress={() => actionBottomSheet(OptionValueBottomSheet.COPY)}
            >
              <PlusIcon fillColor={Colors.orange} />
              <Text style={{ fontSize: 13, fontWeight: "700", color: Colors.orange, marginLeft: 5 }}>コピーして新規作成</Text>
            </Button>
            {!value?.deleteFlg && (
              <Button
                style={styles.btnActionMore}
                disabled={isLoading || isDeleteMatchingOffer}
                onPress={() => actionBottomSheet(OptionValueBottomSheet.STOP)}
              >
                {isDeleteMatchingOffer ? (
                  <LoadingIndicator />
                ) : (
                  <>
                    <RemoveIcon fill={Colors.black} size={11} />
                    <Text style={{ fontSize: 13, fontWeight: "700", marginLeft: 5 }}>募集を停止</Text>
                  </>
                )}
              </Button>
            )}
          </View>
        ) : undefined}
      </CustomKeyboardAvoidingView>

      <BottomSheet
        snapPoints={[Platform.OS === "android" ? "30%" : "25%"]}
        isOpen={isBottomSheet}
        onIsOpenChange={setBottomSheet}
        backgroundColor={Colors.white3}
      >
        <FlatList
          data={value?.deleteFlg ? StoppedListOptionBottomSheet : ListOptionBottomSheet}
          renderItem={renderItem}
          contentContainerStyle={styles.list}
        />
      </BottomSheet>
    </MainLayout>
  )
})

const styles = StyleSheet.create({
  container: {
    paddingTop: 24,
    paddingRight: 16,
    paddingBottom: 32,
    paddingLeft: 16,
  },
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  teamInfo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 24,
  },
  detailOrg: {
    paddingLeft: 16,
    fontSize: 14,
    color: Colors.orange,
    lineHeight: 16,
  },
  imageTeam: {
    width: 50,
    height: 50,
    borderRadius: 25,
  },
  contactTeam: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  litmitTime: {
    fontSize: 13,
    font: "Noto Sans",
    color: Colors.leaf,
    marginBottom: 12,
  },
  titleMatching: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: "600",
  },
  bgLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 13,
    minWidth: 42,
    marginRight: 10,
    height: 26,
    paddingVertical: 3,
    paddingHorizontal: 10,
    borderWidth: 1,
    borderColor: Colors.coolGrey,
    color: Colors.coolGrey,
    backgroundColor: Colors.white3,
    outline: "none",
    borderRadius: 6,
    flexWrap: "wrap",
    paddingLeft: 10,
    paddingRight: 10,
  },
  offer: {
    fontSize: 14,
    fontStyle: "normal",
    lineHeight: 20,
    marginBottom: 12,
  },
  url: {
    display: "flex",
    flexDirection: "row",
  },
  offerUrl: {
    fontSize: 14,
    fontStyle: "normal",
    lineHeight: 20,
    marginBottom: 12,
    color: Colors.orange,
  },
  description: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
    paddingBottom: 16,
    paddingTop: 16,
    marginBottom: 24,
  },
  btnInterview: {
    backgroundColor: Colors.orange,
    display: "flex",
    padding: 12,
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 100,
  },
  textBtnInterview: {
    color: Colors.white3,
    fontSize: 18,
    fontWeight: "600",
  },
  textCloseBtnInterview: {
    color: Colors.orange,
    fontSize: 18,
    fontWeight: "600",
  },
  btnCloseInterview: {
    backgroundColor: Colors.white3,
    display: "flex",
    padding: 12,
    justifyContent: "center",
    alignItems: "center",
    borderColor: Colors.orange,
    borderWidth: 1,
    borderRadius: 100,
    marginTop: 16,
  },
  applicationView: {
    paddingTop: 0,
    paddingRight: 16,
    paddingBottom: 24,
    paddingLeft: 16,
    backgroundColor: Colors.white,
  },
  applicationTitle: {
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 16,
  },
  modalText: {
    marginBottom: 15,
    padding: 20,
    textAlign: "center",
  },
  titleModel: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    paddingRight: 20,
    fontSize: 18,
    fontWeight: "600",
    textAlign: "center",
  },
  titleModelLarge: {
    paddingTop: 4,
    paddingBottom: 4,
    paddingLeft: 10,
    paddingRight: 60,
    fontSize: 18,
    fontWeight: "600",
    textAlign: "center",
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: 20,
  },
  modalView: {
    margin: 10,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 15,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "80%",
  },

  modalViewPC: {
    margin: 10,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 15,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: 500,
  },
  headerModel: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
  },
  headerModelPC: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    flex: 1,
  },
  mediaFile: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  loading: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  headerPC: {
    display: "flex",
    flexDirection: "row",
    backgroundColor: Colors.white3,
    paddingVertical: 8,
    borderColor: Colors.white2,
    borderBottomWidth: 1,
  },

  list: {
    paddingBottom: 10,
  },

  btnActionMore: {
    paddingHorizontal: 16,
    paddingVertical: 10,
    backgroundColor: Colors.orange1,
    borderRadius: 6,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginHorizontal: 12,
  },
  organizationName: {
    fontSize: 14,
    fontStyle: "normal",
    fontWeight: "600",
    paddingLeft: 16,
    marginBottom: 3,
  },
  iconClosePopup: {
    paddingTop: 10,
    marginRight: 10,
    marginLeft: 25,
  },
  iconClosePopupLarge: {
    width: 33,
  },
})
