import { memo, useCallback } from "react"
import { StyleSheet, View } from "react-native"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { Colors } from "src/constants/Colors"
import { CreateNewContactScreens } from "src/constants/CreateNewContactScreens"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useSelectMethodScreenData } from "src/recoil/hooks/screens/contact/create/useSelectMethodScreenData"
import { CreateNewContactStackScreenProps } from "src/types.d"

type Props = CreateNewContactStackScreenProps<typeof CreateNewContactScreens.SelectMethod>

export const SelectMethodScreen = memo<Props>(({ navigation }) => {
  const isLargeScreen = useCheckPCScreen()
  const {
    isAllMembersSelected,
    isAllLeadersSelected,
    isGroupsSelected,
    isMembersSelected,
    setReceiverTypeAllMembers,
    setIsReceiversAllMembers,
    setIsReceiversAllLeaders,
    checkDestinationSelection,
    showSelectGroups,
  } = useSelectMethodScreenData()

  const selectAllMembers = useCallback(() => {
    if (isAllMembersSelected) {
      setIsReceiversAllMembers(false)
    } else {
      setReceiverTypeAllMembers()
      navigation.navigate("CreateNewContact")
    }
  }, [isAllMembersSelected, setIsReceiversAllMembers, setReceiverTypeAllMembers, navigation])

  const selectLeaders = useCallback(() => {
    if (isAllLeadersSelected) {
      setIsReceiversAllLeaders(false)
    } else {
      setIsReceiversAllMembers(false)
      setIsReceiversAllLeaders(true)
      navigation.navigate("CreateNewContact")
    }
  }, [isAllLeadersSelected, setIsReceiversAllLeaders, setIsReceiversAllMembers, navigation])

  const goToSelectGroups = useCallback(() => {
    navigation.navigate("SelectGroup")
  }, [navigation])

  const goToSelectMembers = useCallback(() => {
    navigation.navigate("SelectMembers")
  }, [navigation])

  return (
    <CustomScrollView style={styles.container}>
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <View style={isLargeScreen ? { width: 600 } : { width: "100%" }}>
          {checkDestinationSelection?.accessAll ? (
            <View style={styles.row}>
              <TextButton
                buttonType={ButtonType.Card}
                title="団体全員"
                onPress={selectAllMembers}
                style={[isAllMembersSelected ? styles.selected : styles.unselected]}
              />
            </View>
          ) : null}
          {checkDestinationSelection?.accessAll ? (
            <View style={styles.row}>
              <TextButton
                buttonType={ButtonType.Card}
                title="代表者のみ"
                onPress={selectLeaders}
                style={[isAllLeadersSelected ? styles.selected : styles.unselected]}
              />
            </View>
          ) : null}
          {showSelectGroups ? (
            <View style={styles.row}>
              <TextButton
                buttonType={ButtonType.Card}
                title="グループを選択"
                onPress={goToSelectGroups}
                style={[isGroupsSelected ? styles.selected : styles.unselected]}
              />
            </View>
          ) : null}
          <View style={styles.row}>
            <TextButton
              buttonType={ButtonType.Card}
              title="メンバーを選択"
              onPress={goToSelectMembers}
              style={[isMembersSelected ? styles.selected : styles.unselected]}
            />
          </View>
        </View>
      </View>
    </CustomScrollView>
  )
})

export const selectMethodScreenOptions = {
  title: "宛先一覧",
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    padding: 12,
  },
  row: {
    paddingVertical: 3,
  },
  selected: {
    borderWidth: 1,
    borderColor: Colors.orange,
    backgroundColor: Colors.pale,
  },
  unselected: {
    borderWidth: 1,
    borderColor: Colors.white3,
    backgroundColor: Colors.white3,
  },
})
