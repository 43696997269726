/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const signUp = /* GraphQL */ `
  mutation SignUp($input: SignUpInput!) {
    signUp(input: $input) {
      message
      data {
        ChallengeName
        Session
        ChallengeParameters
        AuthenticationResult {
          AccessToken
          ExpiresIn
          TokenType
          RefreshToken
          IdToken
          NewDeviceMetadata
        }
      }
    }
  }
`
export const signIn = /* GraphQL */ `
  mutation SignIn($input: SignInInput!) {
    signIn(input: $input) {
      message
      data {
        ChallengeName
        Session
        ChallengeParameters
        AuthenticationResult {
          AccessToken
          ExpiresIn
          TokenType
          RefreshToken
          IdToken
          NewDeviceMetadata
        }
      }
    }
  }
`
export const signOut = /* GraphQL */ `
  mutation SignOut {
    signOut {
      message
    }
  }
`
export const registerAuthCode = /* GraphQL */ `
  mutation RegisterAuthCode($input: RegisterAuthCodeInput!) {
    registerAuthCode(input: $input) {
      message
      data {
        auth {
          ChallengeName
          Session
          ChallengeParameters
        }
        user {
          id
          lastName
          firstName
          lastNameKana
          firstNameKana
          email
          signupStatus
        }
      }
    }
  }
`
export const updateAuthMethod = /* GraphQL */ `
  mutation UpdateAuthMethod($input: UpdateAuthMethodInput!) {
    updateAuthMethod(input: $input) {
      message
    }
  }
`
export const registerAuthMethodCode = /* GraphQL */ `
  mutation RegisterAuthMethodCode($input: RegisterAuthMethodCodeInput!) {
    registerAuthMethodCode(input: $input) {
      message
    }
  }
`
export const deletePhoneNumber = /* GraphQL */ `
  mutation DeletePhoneNumber {
    deletePhoneNumber {
      message
    }
  }
`
export const deleteAccountAuth = /* GraphQL */ `
  mutation DeleteAccountAuth {
    deleteAccountAuth {
      message
    }
  }
`
export const confirmAccessToken = /* GraphQL */ `
  mutation ConfirmAccessToken($tokens: ConfirmAuthTokenInput!) {
    confirmAccessToken(tokens: $tokens) {
      message
      data {
        user {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        AccessToken
        RefreshToken
      }
    }
  }
`
export const initAccount = /* GraphQL */ `
  mutation InitAccount($input: InitAccountInput!) {
    initAccount(input: $input) {
      id
    }
  }
`
export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
    }
  }
`
export const createAccountDevice = /* GraphQL */ `
  mutation CreateAccountDevice($input: CreateAccountDeviceInput!) {
    createAccountDevice(input: $input) {
      id
    }
  }
`
export const deleteAccountDevice = /* GraphQL */ `
  mutation DeleteAccountDevice($input: DeleteAccountDeviceInput!) {
    deleteAccountDevice(input: $input) {
      id
    }
  }
`
export const deleteAccount = /* GraphQL */ `
  mutation DeleteAccount($input: DeleteAccountInput) {
    deleteAccount(input: $input) {
      id
    }
  }
`
export const notifyAccountEmailUpdated = /* GraphQL */ `
  mutation NotifyAccountEmailUpdated {
    notifyAccountEmailUpdated {
      id
    }
  }
`
export const notifyAccountPhoneNumberUpdated = /* GraphQL */ `
  mutation NotifyAccountPhoneNumberUpdated {
    notifyAccountPhoneNumberUpdated {
      id
    }
  }
`
export const getTempToken = /* GraphQL */ `
  mutation GetTempToken($input: GetTempTokenInput) {
    getTempToken(input: $input) {
      tempToken
    }
  }
`
export const getAccessTokenByTempToken = /* GraphQL */ `
  mutation GetAccessTokenByTempToken($input: GetAccessTokenByTempTokenInput) {
    getAccessTokenByTempToken(input: $input) {
      accessToken
    }
  }
`
export const deleteAllTeamNotice = /* GraphQL */ `
  mutation DeleteAllTeamNotice {
    deleteAllTeamNotice {
      id
    }
  }
`
export const createTeam = /* GraphQL */ `
  mutation CreateTeam($input: CreateTeamInput!) {
    createTeam(input: $input) {
      id
    }
  }
`
export const updateTeam = /* GraphQL */ `
  mutation UpdateTeam($input: UpdateTeamInput!) {
    updateTeam(input: $input) {
      id
    }
  }
`
export const deleteTeam = /* GraphQL */ `
  mutation DeleteTeam($input: DeleteTeamInput!) {
    deleteTeam(input: $input) {
      id
    }
  }
`
export const reportTeam = /* GraphQL */ `
  mutation ReportTeam($input: ReportTeamInput!) {
    reportTeam(input: $input) {
      id
    }
  }
`
export const getOrCreateTempTeamId = /* GraphQL */ `
  mutation GetOrCreateTempTeamId {
    getOrCreateTempTeamId {
      id
    }
  }
`
export const sendJoinRequest = /* GraphQL */ `
  mutation SendJoinRequest($input: SendJoinRequestInput!) {
    sendJoinRequest(input: $input) {
      id
    }
  }
`
export const approvePreTeamMember = /* GraphQL */ `
  mutation ApprovePreTeamMember($input: ApprovePreTeamMemberInput!) {
    approvePreTeamMember(input: $input) {
      ids
    }
  }
`
export const rejectPreTeamMember = /* GraphQL */ `
  mutation RejectPreTeamMember($input: RejectPreTeamMemberInput!) {
    rejectPreTeamMember(input: $input) {
      ids
    }
  }
`
export const sendInvitationEmail = /* GraphQL */ `
  mutation SendInvitationEmail($input: SendInvitationEmailInput!) {
    sendInvitationEmail(input: $input) {
      id
    }
  }
`
export const resendInvitationEmail = /* GraphQL */ `
  mutation ResendInvitationEmail($input: ResendInvitationEmailInput!) {
    resendInvitationEmail(input: $input) {
      id
    }
  }
`
export const sendMigrationInvitationEmail = /* GraphQL */ `
  mutation SendMigrationInvitationEmail($input: SendMigrationInvitationEmailInput!) {
    sendMigrationInvitationEmail(input: $input) {
      id
      results {
        email
        status
        error
        deliverId
      }
    }
  }
`
export const updateTeamMember = /* GraphQL */ `
  mutation UpdateTeamMember($input: UpdateTeamMemberInput!) {
    updateTeamMember(input: $input) {
      id
    }
  }
`
export const deleteTeamMember = /* GraphQL */ `
  mutation DeleteTeamMember($input: DeleteTeamMemberInput!) {
    deleteTeamMember(input: $input) {
      id
    }
  }
`
export const multiDeleteTeamMember = /* GraphQL */ `
  mutation MultiDeleteTeamMember($input: IdListInput!) {
    multiDeleteTeamMember(input: $input) {
      ids
    }
  }
`
export const createGroup = /* GraphQL */ `
  mutation CreateGroup($input: CreateGroupInput!) {
    createGroup(input: $input) {
      id
    }
  }
`
export const updateGroup = /* GraphQL */ `
  mutation UpdateGroup($input: UpdateGroupInput!) {
    updateGroup(input: $input) {
      id
    }
  }
`
export const updateNameGroupAndGroupMembers = /* GraphQL */ `
  mutation UpdateNameGroupAndGroupMembers($input: UpdateNameGroupAndGroupMembersInput!) {
    updateNameGroupAndGroupMembers(input: $input) {
      id
    }
  }
`
export const deleteGroup = /* GraphQL */ `
  mutation DeleteGroup($input: DeleteGroupInput!) {
    deleteGroup(input: $input) {
      id
    }
  }
`
export const updateAttendanceAnswer = /* GraphQL */ `
  mutation UpdateAttendanceAnswer($input: UpdateAttendanceAnswerInput!) {
    updateAttendanceAnswer(input: $input) {
      id
    }
  }
`
export const updateQuestionnaireAnswer = /* GraphQL */ `
  mutation UpdateQuestionnaireAnswer($input: UpdateQuestionnaireAnswerInput!) {
    updateQuestionnaireAnswer(input: $input) {
      id
    }
  }
`
export const updateScheduleAnswer = /* GraphQL */ `
  mutation UpdateScheduleAnswer($input: UpdateScheduleAnswerInput!) {
    updateScheduleAnswer(input: $input) {
      id
    }
  }
`
export const markMailAsRead = /* GraphQL */ `
  mutation MarkMailAsRead($input: MarkMailAsReadInput!) {
    markMailAsRead(input: $input) {
      id
    }
  }
`
export const createMail = /* GraphQL */ `
  mutation CreateMail($input: CreateMailInput!) {
    createMail(input: $input) {
      id
      type
    }
  }
`
export const updateMail = /* GraphQL */ `
  mutation UpdateMail($input: UpdateMailInput!) {
    updateMail(input: $input) {
      id
    }
  }
`
export const deleteMail = /* GraphQL */ `
  mutation DeleteMail($input: DeleteMailInput!) {
    deleteMail(input: $input) {
      id
    }
  }
`
export const sendReminder = /* GraphQL */ `
  mutation SendReminder($input: SendReminderInput!) {
    sendReminder(input: $input) {
      id
    }
  }
`
export const getOrCreateTempMailId = /* GraphQL */ `
  mutation GetOrCreateTempMailId($input: GetOrCreateTempMailIdInput!) {
    getOrCreateTempMailId(input: $input) {
      id
    }
  }
`
export const multiDeleteSentMail = /* GraphQL */ `
  mutation MultiDeleteSentMail($input: MultiMailInput!) {
    multiDeleteSentMail(input: $input) {
      id
    }
  }
`
export const multiTrashReceivedMail = /* GraphQL */ `
  mutation MultiTrashReceivedMail($input: MultiMailInput!) {
    multiTrashReceivedMail(input: $input) {
      id
    }
  }
`
export const multiRestoreTrashedMail = /* GraphQL */ `
  mutation MultiRestoreTrashedMail($input: MultiMailInput!) {
    multiRestoreTrashedMail(input: $input) {
      id
    }
  }
`
export const multiDeleteTrashedMail = /* GraphQL */ `
  mutation MultiDeleteTrashedMail($input: MultiMailInput!) {
    multiDeleteTrashedMail(input: $input) {
      id
    }
  }
`
export const createDraft = /* GraphQL */ `
  mutation CreateDraft($input: CreateDraftInput!) {
    createDraft(input: $input) {
      id
    }
  }
`
export const multiDeleteDraft = /* GraphQL */ `
  mutation MultiDeleteDraft($input: IdListInput!) {
    multiDeleteDraft(input: $input) {
      ids
    }
  }
`
export const createTemplate = /* GraphQL */ `
  mutation CreateTemplate($input: CreateTemplateInput!) {
    createTemplate(input: $input) {
      id
    }
  }
`
export const multiDeleteTemplate = /* GraphQL */ `
  mutation MultiDeleteTemplate($input: IdListInput!) {
    multiDeleteTemplate(input: $input) {
      ids
    }
  }
`
export const createThreadMessage = /* GraphQL */ `
  mutation CreateThreadMessage($input: CreateThreadMessageInput!) {
    createThreadMessage(input: $input) {
      id
    }
  }
`
export const updateThreadMessage = /* GraphQL */ `
  mutation UpdateThreadMessage($input: UpdateThreadMessageInput!) {
    updateThreadMessage(input: $input) {
      id
    }
  }
`
export const deleteAccountImage = /* GraphQL */ `
  mutation DeleteAccountImage {
    deleteAccountImage {
      id
    }
  }
`
export const deleteTeamImage = /* GraphQL */ `
  mutation DeleteTeamImage($input: DeleteTeamImageInput!) {
    deleteTeamImage(input: $input) {
      id
    }
  }
`
export const deleteTeamMemberImage = /* GraphQL */ `
  mutation DeleteTeamMemberImage($input: DeleteTeamMemberImageInput!) {
    deleteTeamMemberImage(input: $input) {
      id
    }
  }
`
export const deleteMatchingTeamImage = /* GraphQL */ `
  mutation DeleteMatchingTeamImage($input: DeleteMatchingTeamImageInput!) {
    deleteMatchingTeamImage(input: $input) {
      id
    }
  }
`
export const deleteMatchingTeamBgImage = /* GraphQL */ `
  mutation DeleteMatchingTeamBgImage($input: DeleteMatchingTeamBgImageInput!) {
    deleteMatchingTeamBgImage(input: $input) {
      id
    }
  }
`
export const migrateTeam = /* GraphQL */ `
  mutation MigrateTeam($input: MigrateTeamInput!) {
    migrateTeam(input: $input) {
      newTeamId
      invitedMemberCount
      uninvitedMembers {
        memberId
        nickname
        reason
      }
    }
  }
`
export const migrateMail = /* GraphQL */ `
  mutation MigrateMail($input: MigrateMailInput!) {
    migrateMail(input: $input) {
      id
    }
  }
`
export const createTeamMemberFromPreset = /* GraphQL */ `
  mutation CreateTeamMemberFromPreset($input: CreateTeamMemberFromPresetInput!) {
    createTeamMemberFromPreset(input: $input) {
      id
    }
  }
`
export const getOrCreateTempMatchingOfferId = /* GraphQL */ `
  mutation GetOrCreateTempMatchingOfferId($input: GetOrCreateTempMatchingOfferIdInput!) {
    getOrCreateTempMatchingOfferId(input: $input) {
      id
    }
  }
`
export const createMatchingOffer = /* GraphQL */ `
  mutation CreateMatchingOffer($input: CreateMatchingOfferInput2!) {
    createMatchingOffer(input: $input) {
      id
    }
  }
`
export const createMatchingOfferDraft = /* GraphQL */ `
  mutation CreateMatchingOfferDraft($input: CreateMatchingOfferDraftInput2!) {
    createMatchingOfferDraft(input: $input) {
      id
    }
  }
`
export const createMatchingApplication = /* GraphQL */ `
  mutation CreateMatchingApplication($input: CreateMatchingApplicationInput3!) {
    createMatchingApplication(input: $input) {
      id
    }
  }
`
export const createMatchingMessage = /* GraphQL */ `
  mutation CreateMatchingMessage($input: CreateMatchingMessageInput!) {
    createMatchingMessage(input: $input) {
      id
    }
  }
`
export const updateMatchingMessage = /* GraphQL */ `
  mutation UpdateMatchingMessage($input: UpdateMatchingMessageInput!) {
    updateMatchingMessage(input: $input) {
      id
    }
  }
`
export const markMatchingMessageAsRead = /* GraphQL */ `
  mutation MarkMatchingMessageAsRead($input: MarkMatchingMessageAsReadInput!) {
    markMatchingMessageAsRead(input: $input) {
      id
    }
  }
`
export const reportMatchingTeam = /* GraphQL */ `
  mutation ReportMatchingTeam($input: ReportMatchingTeamInput!) {
    reportMatchingTeam(input: $input) {
      id
    }
  }
`
export const deleteMatchingOfferDraftByIds = /* GraphQL */ `
  mutation DeleteMatchingOfferDraftByIds($input: IdListInput!) {
    deleteMatchingOfferDraftByIds(input: $input) {
      ids
    }
  }
`
export const createInquiry = /* GraphQL */ `
  mutation CreateInquiry($input: CreateInquiryInput!) {
    createInquiry(input: $input) {
      id
    }
  }
`
export const createPaidTeamInquiry = /* GraphQL */ `
  mutation CreatePaidTeamInquiry($input: CreateInquiryInput!) {
    createPaidTeamInquiry(input: $input) {
      id
    }
  }
`
export const agreeToTerms = /* GraphQL */ `
  mutation AgreeToTerms($input: AgreeToTermsInput) {
    agreeToTerms(input: $input) {
      id
    }
  }
`
export const createAccountAdminNotice = /* GraphQL */ `
  mutation CreateAccountAdminNotice($input: CreateAccountAdminNoticeInput!) {
    createAccountAdminNotice(input: $input) {
      id
    }
  }
`
export const createPaidVersionOrder = /* GraphQL */ `
  mutation CreatePaidVersionOrder($input: CreatePaidVersionOrderInput!) {
    createPaidVersionOrder(input: $input) {
      id
    }
  }
`
export const resendEmailPaidVersionOrder = /* GraphQL */ `
  mutation ResendEmailPaidVersionOrder($input: ResendEmailPaidVersionOrderInput!) {
    resendEmailPaidVersionOrder(input: $input) {
      id
    }
  }
`
export const updatePaidVersionOrder = /* GraphQL */ `
  mutation UpdatePaidVersionOrder($input: UpdatePaidVersionOrderInput!) {
    updatePaidVersionOrder(input: $input) {
      id
    }
  }
`
export const cancelPaidVersionOrder = /* GraphQL */ `
  mutation CancelPaidVersionOrder($input: CancelPaidVersionOrderInput!) {
    cancelPaidVersionOrder(input: $input) {
      id
    }
  }
`
export const changePlanPaidVersionOrder = /* GraphQL */ `
  mutation ChangePlanPaidVersionOrder($input: ChangePlanPaidVersionOrderInput!) {
    changePlanPaidVersionOrder(input: $input) {
      id
    }
  }
`
export const extendPaidVersionOrder = /* GraphQL */ `
  mutation ExtendPaidVersionOrder($input: ExtendPaidVersionOrderInput!) {
    extendPaidVersionOrder(input: $input) {
      id
    }
  }
`
export const applicantAuthorityTransfer = /* GraphQL */ `
  mutation ApplicantAuthorityTransfer($input: ApplicantAuthorityTransferInput!) {
    applicantAuthorityTransfer(input: $input) {
      id
    }
  }
`
export const updateComment = /* GraphQL */ `
  mutation UpdateComment($input: UpdateCommentInput!) {
    updateComment(input: $input) {
      id
    }
  }
`
export const deleteComment = /* GraphQL */ `
  mutation DeleteComment($input: DeleteCommentInput!) {
    deleteComment(input: $input) {
      id
    }
  }
`
export const updateNotificationSetting = /* GraphQL */ `
  mutation UpdateNotificationSetting(
    $input: UpdateNotificationSettingInput!
    $condition: ModelNotificationSettingConditionInput
  ) {
    updateNotificationSetting(input: $input, condition: $condition) {
      id
      mailReceivedNotice {
        push
        email
      }
      threadMessageReceivedNotice {
        push
        email
      }
      joinRequestNotice {
        push
        email
      }
      teamMatchingNotice {
        email
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteTempTeam = /* GraphQL */ `
  mutation DeleteTempTeam($input: DeleteTempTeamInput!, $condition: ModelTempTeamConditionInput) {
    deleteTempTeam(input: $input, condition: $condition) {
      accountId
      tempTeamId
      createdAt
      updatedAt
    }
  }
`
export const _updateTeam = /* GraphQL */ `
  mutation _updateTeam($input: UpdateTeamInput!, $condition: ModelTeamConditionInput) {
    _updateTeam(input: $input, condition: $condition) {
      id
      name
      category {
        id
        label
        displayNumber
        displayFlg
        activities {
          nextToken
        }
        createdAt
        updatedAt
      }
      activity {
        id
        label
        displayNumber
        displayFlg
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      prefecture {
        id
        label
        displayNumber
        displayFlg
        cities {
          nextToken
        }
        createdAt
        updatedAt
      }
      city {
        id
        label
        displayNumber
        displayFlg
        createdAt
        updatedAt
        prefectureMasterCitiesId
      }
      description
      requirementForMemberProfile
      customQuestions
      showMemberListFlg
      invitationCode
      teamMembers {
        items {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        nextToken
      }
      preTeamMembers {
        items {
          id
          nickname
          nicknameKana
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          createdAt
          updatedAt
          accountPreTeamMembersId
          teamPreTeamMembersId
        }
        nextToken
      }
      invitationEmailStatuses {
        items {
          id
          email
          status
          repicaDeliverId
          repicaKeyField
          repicaSentDate
          errorMessage
          createdAt
          teamInvitationEmailStatusesId
          updatedAt
        }
        nextToken
      }
      groups {
        items {
          id
          name
          migrationId
          createdAt
          updatedAt
          teamGroupsId
        }
        nextToken
      }
      matchingOffers {
        items {
          id
          purpose
          title
          body
          date
          startTime
          endTime
          place
          placeURL
          expense
          expensePurpose
          ageFrom
          ageTo
          offerCount
          publishEndDate
          attachmentFileNames
          teamId
          teamName
          deleteFlg
          createdAt
          updatedAt
          teamMatchingOffersId
          matchingOfferPrefectureId
          matchingOfferCityId
          matchingOfferActivityId
        }
        nextToken
      }
      matchingOfferDrafts {
        items {
          id
          purpose
          title
          body
          date
          startTime
          endTime
          place
          placeURL
          expense
          expensePurpose
          ageFrom
          ageTo
          offerCount
          publishEndDate
          createdAt
          updatedAt
          teamMatchingOfferDraftsId
          matchingOfferDraftPrefectureId
          matchingOfferDraftCityId
        }
        nextToken
      }
      matchingApplications {
        items {
          id
          offerTeamName
          offerTitle
          offerBody
          createdAt
          updatedAt
          teamMatchingApplicationsId
          matchingOfferApplicationsId
        }
        nextToken
      }
      blockingMatchingTeams {
        items {
          id
          blockingTeamId
          createdAt
          updatedAt
          teamBlockingMatchingTeamsId
        }
        nextToken
      }
      paidVersionOrders {
        items {
          id
          orderNumber
          originalOrderId
          orderStatus
          jobId
          masterCode
          zacStatus
          supportedDate
          address
          orderType
          companyName
          companyNameKana
          corporateRepresentativeName
          corporateRepresentativeNameKana
          applicantPhone
          applicantMail
          applicantName
          applicantPostcode
          applicantPrefecture
          applicantAddress
          applicantBuildingName
          applicantRemark
          antiSocialCheck
          teamId
          migrateTeamId
          teamName
          memberCount
          applicantId
          transferApplicantId
          planId
          startDate
          endDate
          adsDisableEndDate
          paymentStatus
          paymentDate
          paymentDeadline
          invoiceIssuedDate
          paymentType
          paymentMethod
          invoiceAddressee
          invoiceAddresseeKana
          amount
          taxRate
          tax
          subTotal
          remark
          adminId
          createdAt
          updatedAt
        }
        nextToken
      }
      blockingMatchingTeamsByBlockingTeamId {
        items {
          id
          blockingTeamId
          createdAt
          updatedAt
          teamBlockingMatchingTeamsId
        }
        nextToken
      }
      migrationId
      paidFunctionEnabled
      showAdsFlg
      createdAt
      updatedAt
      teamCategoryId
      teamActivityId
      teamPrefectureId
      teamCityId
    }
  }
`
export const updateTeamNotice = /* GraphQL */ `
  mutation UpdateTeamNotice($input: UpdateTeamNoticeInput!, $condition: ModelTeamNoticeConditionInput) {
    updateTeamNotice(input: $input, condition: $condition) {
      id
      noticeType
      title
      readFlg
      url
      team {
        id
        name
        category {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        description
        requirementForMemberProfile
        customQuestions
        showMemberListFlg
        invitationCode
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        invitationEmailStatuses {
          nextToken
        }
        groups {
          nextToken
        }
        matchingOffers {
          nextToken
        }
        matchingOfferDrafts {
          nextToken
        }
        matchingApplications {
          nextToken
        }
        blockingMatchingTeams {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        blockingMatchingTeamsByBlockingTeamId {
          nextToken
        }
        migrationId
        paidFunctionEnabled
        showAdsFlg
        createdAt
        updatedAt
        teamCategoryId
        teamActivityId
        teamPrefectureId
        teamCityId
      }
      createdAt
      createdYearMonth
      createdDate
      accountTeamNoticesId
      updatedAt
      teamMemberTeamNoticeId
      teamNoticeTeamId
    }
  }
`
export const deleteTempMail = /* GraphQL */ `
  mutation DeleteTempMail($input: DeleteTempMailInput!, $condition: ModelTempMailConditionInput) {
    deleteTempMail(input: $input, condition: $condition) {
      teamMemberId
      tempMailId
      createdAt
      updatedAt
    }
  }
`
export const updateTeamMemberMail = /* GraphQL */ `
  mutation UpdateTeamMemberMail($input: UpdateTeamMemberMailInput!, $condition: ModelTeamMemberMailConditionInput) {
    updateTeamMemberMail(input: $input, condition: $condition) {
      id
      receivedFlg
      sentFlg
      readFlg
      readAt
      favoriteFlgReceived
      favoriteFlgSent
      answerRequiredFlg
      responseDueDatetimeForFilter
      answeredFlg
      answeredAt
      attendanceAnswer
      questionnaireAnswers {
        answer
      }
      scheduleAnswers
      senderId
      mailType
      title
      body
      hasAttachment
      hasThread
      trashedFlgReceived
      deletedFlgReceived
      deletedFlgSent
      count
      teamMemberID
      mailID
      teamMember {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      mail {
        id
        teamId
        mailType
        title
        sender {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        body
        sendTo {
          allMembersFlg
          managersFlg
          groupIds
          memberIds
        }
        attachmentFileNames
        startDate
        startTime
        endDate
        endTime
        questionnaires {
          answerSelectType
          question
          choices
        }
        candidateDatetimes {
          startDate
          startTime
          endDate
          endTime
        }
        shareManagerFlg
        showAnswerFlg
        responseDueDatetime
        remindDate
        remindDatetime
        remindExecDatetime
        sendScheduledAt
        sentAt
        teamMemberMails {
          nextToken
        }
        threads {
          nextToken
        }
        commentEnableFlg
        createdAt
        updatedAt
        mailSenderId
      }
      createdYearMonth
      createdDate
      comment
      commentedFlg
      commentSentAt
      commentUpdatedAt
      createdAt
      updatedAt
    }
  }
`
export const deleteTeamMemberMail = /* GraphQL */ `
  mutation DeleteTeamMemberMail($input: DeleteTeamMemberMailInput!, $condition: ModelTeamMemberMailConditionInput) {
    deleteTeamMemberMail(input: $input, condition: $condition) {
      id
      receivedFlg
      sentFlg
      readFlg
      readAt
      favoriteFlgReceived
      favoriteFlgSent
      answerRequiredFlg
      responseDueDatetimeForFilter
      answeredFlg
      answeredAt
      attendanceAnswer
      questionnaireAnswers {
        answer
      }
      scheduleAnswers
      senderId
      mailType
      title
      body
      hasAttachment
      hasThread
      trashedFlgReceived
      deletedFlgReceived
      deletedFlgSent
      count
      teamMemberID
      mailID
      teamMember {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      mail {
        id
        teamId
        mailType
        title
        sender {
          id
          memberSeq
          nickname
          nicknameKana
          role
          showEmailFlg
          phoneNumber
          showPhoneNumberFlg
          belongs
          showBelongsFlg
          grade
          teamIdGsi
          teamTeamMembersId
          mailMigratedFlg
          deleteFlg
          accountTeamMembersId
          migrateTeamMemberId
          createdAt
          updatedAt
          teamMemberNotificationSettingId
        }
        body
        sendTo {
          allMembersFlg
          managersFlg
          groupIds
          memberIds
        }
        attachmentFileNames
        startDate
        startTime
        endDate
        endTime
        questionnaires {
          answerSelectType
          question
          choices
        }
        candidateDatetimes {
          startDate
          startTime
          endDate
          endTime
        }
        shareManagerFlg
        showAnswerFlg
        responseDueDatetime
        remindDate
        remindDatetime
        remindExecDatetime
        sendScheduledAt
        sentAt
        teamMemberMails {
          nextToken
        }
        threads {
          nextToken
        }
        commentEnableFlg
        createdAt
        updatedAt
        mailSenderId
      }
      createdYearMonth
      createdDate
      comment
      commentedFlg
      commentSentAt
      commentUpdatedAt
      createdAt
      updatedAt
    }
  }
`
export const updateReservedMail = /* GraphQL */ `
  mutation UpdateReservedMail($input: UpdateReservedMailInput!, $condition: ModelReservedMailConditionInput) {
    updateReservedMail(input: $input, condition: $condition) {
      id
      teamId
      mailType
      title
      teamMember {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      body
      sendTo {
        allMembersFlg
        managersFlg
        groupIds
        memberIds
      }
      attachmentFileNames
      startDate
      startTime
      endDate
      endTime
      questionnaires {
        answerSelectType
        question
        choices
      }
      candidateDatetimes {
        startDate
        startTime
        endDate
        endTime
      }
      shareManagerFlg
      showAnswerFlg
      responseDueDatetime
      remindDatetime
      sendScheduledDate
      sendScheduledAt
      commentEnableFlg
      createdAt
      updatedAt
      teamMemberReservedMailsId
    }
  }
`
export const deleteReservedMail = /* GraphQL */ `
  mutation DeleteReservedMail($input: DeleteReservedMailInput!, $condition: ModelReservedMailConditionInput) {
    deleteReservedMail(input: $input, condition: $condition) {
      id
      teamId
      mailType
      title
      teamMember {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      body
      sendTo {
        allMembersFlg
        managersFlg
        groupIds
        memberIds
      }
      attachmentFileNames
      startDate
      startTime
      endDate
      endTime
      questionnaires {
        answerSelectType
        question
        choices
      }
      candidateDatetimes {
        startDate
        startTime
        endDate
        endTime
      }
      shareManagerFlg
      showAnswerFlg
      responseDueDatetime
      remindDatetime
      sendScheduledDate
      sendScheduledAt
      commentEnableFlg
      createdAt
      updatedAt
      teamMemberReservedMailsId
    }
  }
`
export const updateDraft = /* GraphQL */ `
  mutation UpdateDraft($input: UpdateDraftInput!, $condition: ModelDraftConditionInput) {
    updateDraft(input: $input, condition: $condition) {
      id
      mailType
      title
      teamMember {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      body
      sendTo {
        allMembersFlg
        managersFlg
        groupIds
        memberIds
      }
      attachmentFileNames
      startDate
      startTime
      endDate
      endTime
      questionnaires {
        answerSelectType
        question
        choices
      }
      candidateDatetimes {
        startDate
        startTime
        endDate
        endTime
      }
      shareManagerFlg
      showAnswerFlg
      responseDueDatetime
      remindDatetime
      sendScheduledAt
      commentEnableFlg
      createdAt
      updatedAt
      teamMemberDraftsId
    }
  }
`
export const deleteDraft = /* GraphQL */ `
  mutation DeleteDraft($input: DeleteDraftInput!, $condition: ModelDraftConditionInput) {
    deleteDraft(input: $input, condition: $condition) {
      id
      mailType
      title
      teamMember {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      body
      sendTo {
        allMembersFlg
        managersFlg
        groupIds
        memberIds
      }
      attachmentFileNames
      startDate
      startTime
      endDate
      endTime
      questionnaires {
        answerSelectType
        question
        choices
      }
      candidateDatetimes {
        startDate
        startTime
        endDate
        endTime
      }
      shareManagerFlg
      showAnswerFlg
      responseDueDatetime
      remindDatetime
      sendScheduledAt
      commentEnableFlg
      createdAt
      updatedAt
      teamMemberDraftsId
    }
  }
`
export const updateTemplate = /* GraphQL */ `
  mutation UpdateTemplate($input: UpdateTemplateInput!, $condition: ModelTemplateConditionInput) {
    updateTemplate(input: $input, condition: $condition) {
      id
      mailType
      title
      teamMember {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      body
      sendTo {
        allMembersFlg
        managersFlg
        groupIds
        memberIds
      }
      startDate
      startTime
      endDate
      endTime
      questionnaires {
        answerSelectType
        question
        choices
      }
      candidateDatetimes {
        startDate
        startTime
        endDate
        endTime
      }
      shareManagerFlg
      showAnswerFlg
      favoriteFlg
      commentEnableFlg
      createdAt
      updatedAt
      teamMemberTemplatesId
    }
  }
`
export const deleteTemplate = /* GraphQL */ `
  mutation DeleteTemplate($input: DeleteTemplateInput!, $condition: ModelTemplateConditionInput) {
    deleteTemplate(input: $input, condition: $condition) {
      id
      mailType
      title
      teamMember {
        id
        memberSeq
        nickname
        nicknameKana
        role
        showEmailFlg
        phoneNumber
        showPhoneNumberFlg
        belongs
        showBelongsFlg
        grade
        account {
          id
          email
          lastName
          firstName
          lastNameKana
          firstNameKana
          phoneNumber
          gender
          birthday
          schoolGraduationYear
          termsAgreedAt
          agreedTermsVersion
          matchingTermsAgreedAt
          agreedMatchingTermsVersion
          signupStatus
          haveOldRa9TeamNotJoin
          migrateAccountId
          createdAt
          updatedAt
          accountJobId
          accountSchoolId
          accountSchoolDepartmentId
          accountPrefectureId
          accountCityId
        }
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        teamIdGsi
        teamTeamMembersId
        notificationSetting {
          id
          createdAt
          updatedAt
        }
        teamNotice {
          nextToken
        }
        groups {
          nextToken
        }
        teamMemberMails {
          nextToken
        }
        reservedMails {
          nextToken
        }
        drafts {
          nextToken
        }
        templates {
          nextToken
        }
        migratedMails {
          nextToken
        }
        mailMigratedFlg
        favoriteMatchingOffer {
          nextToken
        }
        deleteFlg
        accountTeamMembersId
        migrateTeamMemberId
        orderHistories {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        transferPaidVersionOrders {
          nextToken
        }
        createdAt
        updatedAt
        teamMemberNotificationSettingId
      }
      body
      sendTo {
        allMembersFlg
        managersFlg
        groupIds
        memberIds
      }
      startDate
      startTime
      endDate
      endTime
      questionnaires {
        answerSelectType
        question
        choices
      }
      candidateDatetimes {
        startDate
        startTime
        endDate
        endTime
      }
      shareManagerFlg
      showAnswerFlg
      favoriteFlg
      commentEnableFlg
      createdAt
      updatedAt
      teamMemberTemplatesId
    }
  }
`
export const updateMigratedMail = /* GraphQL */ `
  mutation UpdateMigratedMail($input: UpdateMigratedMailInput!, $condition: ModelMigratedMailConditionInput) {
    updateMigratedMail(input: $input, condition: $condition) {
      id
      migrationId
      title
      body
      sender
      favoriteFlg
      shareManagerFlg
      sentDatetime
      createdAt
      updatedAt
      teamMemberMigratedMailsId
    }
  }
`
export const deleteMigratedMail = /* GraphQL */ `
  mutation DeleteMigratedMail($input: DeleteMigratedMailInput!, $condition: ModelMigratedMailConditionInput) {
    deleteMigratedMail(input: $input, condition: $condition) {
      id
      migrationId
      title
      body
      sender
      favoriteFlg
      shareManagerFlg
      sentDatetime
      createdAt
      updatedAt
      teamMemberMigratedMailsId
    }
  }
`
export const createMatchingTeamProfile = /* GraphQL */ `
  mutation CreateMatchingTeamProfile(
    $input: CreateMatchingTeamProfileInput!
    $condition: ModelMatchingTeamProfileConditionInput
  ) {
    createMatchingTeamProfile(input: $input, condition: $condition) {
      id
      ageFrom
      ageTo
      introduction
      teamUrl
      createdAt
      updatedAt
    }
  }
`
export const updateMatchingTeamProfile = /* GraphQL */ `
  mutation UpdateMatchingTeamProfile(
    $input: UpdateMatchingTeamProfileInput!
    $condition: ModelMatchingTeamProfileConditionInput
  ) {
    updateMatchingTeamProfile(input: $input, condition: $condition) {
      id
      ageFrom
      ageTo
      introduction
      teamUrl
      createdAt
      updatedAt
    }
  }
`
export const updateMatchingOffer = /* GraphQL */ `
  mutation UpdateMatchingOffer($input: UpdateMatchingOfferInput!, $condition: ModelMatchingOfferConditionInput) {
    updateMatchingOffer(input: $input, condition: $condition) {
      id
      purpose
      title
      body
      date
      startTime
      endTime
      prefecture {
        id
        label
        displayNumber
        displayFlg
        cities {
          nextToken
        }
        createdAt
        updatedAt
      }
      city {
        id
        label
        displayNumber
        displayFlg
        createdAt
        updatedAt
        prefectureMasterCitiesId
      }
      place
      placeURL
      expense
      expensePurpose
      ageFrom
      ageTo
      offerCount
      publishEndDate
      team {
        id
        name
        category {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        description
        requirementForMemberProfile
        customQuestions
        showMemberListFlg
        invitationCode
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        invitationEmailStatuses {
          nextToken
        }
        groups {
          nextToken
        }
        matchingOffers {
          nextToken
        }
        matchingOfferDrafts {
          nextToken
        }
        matchingApplications {
          nextToken
        }
        blockingMatchingTeams {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        blockingMatchingTeamsByBlockingTeamId {
          nextToken
        }
        migrationId
        paidFunctionEnabled
        showAdsFlg
        createdAt
        updatedAt
        teamCategoryId
        teamActivityId
        teamPrefectureId
        teamCityId
      }
      attachmentFileNames
      activity {
        id
        label
        displayNumber
        displayFlg
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      teamId
      teamName
      applications {
        items {
          id
          offerTeamName
          offerTitle
          offerBody
          createdAt
          updatedAt
          teamMatchingApplicationsId
          matchingOfferApplicationsId
        }
        nextToken
      }
      deleteFlg
      createdAt
      updatedAt
      teamMatchingOffersId
      matchingOfferPrefectureId
      matchingOfferCityId
      matchingOfferActivityId
    }
  }
`
export const deleteMatchingOffer = /* GraphQL */ `
  mutation DeleteMatchingOffer($input: DeleteMatchingOfferInput!, $condition: ModelMatchingOfferConditionInput) {
    deleteMatchingOffer(input: $input, condition: $condition) {
      id
      purpose
      title
      body
      date
      startTime
      endTime
      prefecture {
        id
        label
        displayNumber
        displayFlg
        cities {
          nextToken
        }
        createdAt
        updatedAt
      }
      city {
        id
        label
        displayNumber
        displayFlg
        createdAt
        updatedAt
        prefectureMasterCitiesId
      }
      place
      placeURL
      expense
      expensePurpose
      ageFrom
      ageTo
      offerCount
      publishEndDate
      team {
        id
        name
        category {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        description
        requirementForMemberProfile
        customQuestions
        showMemberListFlg
        invitationCode
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        invitationEmailStatuses {
          nextToken
        }
        groups {
          nextToken
        }
        matchingOffers {
          nextToken
        }
        matchingOfferDrafts {
          nextToken
        }
        matchingApplications {
          nextToken
        }
        blockingMatchingTeams {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        blockingMatchingTeamsByBlockingTeamId {
          nextToken
        }
        migrationId
        paidFunctionEnabled
        showAdsFlg
        createdAt
        updatedAt
        teamCategoryId
        teamActivityId
        teamPrefectureId
        teamCityId
      }
      attachmentFileNames
      activity {
        id
        label
        displayNumber
        displayFlg
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      teamId
      teamName
      applications {
        items {
          id
          offerTeamName
          offerTitle
          offerBody
          createdAt
          updatedAt
          teamMatchingApplicationsId
          matchingOfferApplicationsId
        }
        nextToken
      }
      deleteFlg
      createdAt
      updatedAt
      teamMatchingOffersId
      matchingOfferPrefectureId
      matchingOfferCityId
      matchingOfferActivityId
    }
  }
`
export const updateMatchingOfferDraft = /* GraphQL */ `
  mutation UpdateMatchingOfferDraft($input: UpdateMatchingOfferDraftInput!, $condition: ModelMatchingOfferDraftConditionInput) {
    updateMatchingOfferDraft(input: $input, condition: $condition) {
      id
      purpose
      title
      body
      date
      startTime
      endTime
      prefecture {
        id
        label
        displayNumber
        displayFlg
        cities {
          nextToken
        }
        createdAt
        updatedAt
      }
      city {
        id
        label
        displayNumber
        displayFlg
        createdAt
        updatedAt
        prefectureMasterCitiesId
      }
      place
      placeURL
      expense
      expensePurpose
      ageFrom
      ageTo
      offerCount
      publishEndDate
      team {
        id
        name
        category {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        description
        requirementForMemberProfile
        customQuestions
        showMemberListFlg
        invitationCode
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        invitationEmailStatuses {
          nextToken
        }
        groups {
          nextToken
        }
        matchingOffers {
          nextToken
        }
        matchingOfferDrafts {
          nextToken
        }
        matchingApplications {
          nextToken
        }
        blockingMatchingTeams {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        blockingMatchingTeamsByBlockingTeamId {
          nextToken
        }
        migrationId
        paidFunctionEnabled
        showAdsFlg
        createdAt
        updatedAt
        teamCategoryId
        teamActivityId
        teamPrefectureId
        teamCityId
      }
      createdAt
      updatedAt
      teamMatchingOfferDraftsId
      matchingOfferDraftPrefectureId
      matchingOfferDraftCityId
    }
  }
`
export const deleteMatchingOfferDraft = /* GraphQL */ `
  mutation DeleteMatchingOfferDraft($input: DeleteMatchingOfferDraftInput!, $condition: ModelMatchingOfferDraftConditionInput) {
    deleteMatchingOfferDraft(input: $input, condition: $condition) {
      id
      purpose
      title
      body
      date
      startTime
      endTime
      prefecture {
        id
        label
        displayNumber
        displayFlg
        cities {
          nextToken
        }
        createdAt
        updatedAt
      }
      city {
        id
        label
        displayNumber
        displayFlg
        createdAt
        updatedAt
        prefectureMasterCitiesId
      }
      place
      placeURL
      expense
      expensePurpose
      ageFrom
      ageTo
      offerCount
      publishEndDate
      team {
        id
        name
        category {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        description
        requirementForMemberProfile
        customQuestions
        showMemberListFlg
        invitationCode
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        invitationEmailStatuses {
          nextToken
        }
        groups {
          nextToken
        }
        matchingOffers {
          nextToken
        }
        matchingOfferDrafts {
          nextToken
        }
        matchingApplications {
          nextToken
        }
        blockingMatchingTeams {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        blockingMatchingTeamsByBlockingTeamId {
          nextToken
        }
        migrationId
        paidFunctionEnabled
        showAdsFlg
        createdAt
        updatedAt
        teamCategoryId
        teamActivityId
        teamPrefectureId
        teamCityId
      }
      createdAt
      updatedAt
      teamMatchingOfferDraftsId
      matchingOfferDraftPrefectureId
      matchingOfferDraftCityId
    }
  }
`
export const deleteTempMatchingOffer = /* GraphQL */ `
  mutation DeleteTempMatchingOffer($input: DeleteTempMatchingOfferInput!, $condition: ModelTempMatchingOfferConditionInput) {
    deleteTempMatchingOffer(input: $input, condition: $condition) {
      teamMemberId
      tempMatchingOfferId
      createdAt
      updatedAt
    }
  }
`
export const createFavoriteMatchingOffer = /* GraphQL */ `
  mutation CreateFavoriteMatchingOffer(
    $input: CreateFavoriteMatchingOfferInput!
    $condition: ModelFavoriteMatchingOfferConditionInput
  ) {
    createFavoriteMatchingOffer(input: $input, condition: $condition) {
      id
      offer {
        id
        purpose
        title
        body
        date
        startTime
        endTime
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        place
        placeURL
        expense
        expensePurpose
        ageFrom
        ageTo
        offerCount
        publishEndDate
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        attachmentFileNames
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        teamId
        teamName
        applications {
          nextToken
        }
        deleteFlg
        createdAt
        updatedAt
        teamMatchingOffersId
        matchingOfferPrefectureId
        matchingOfferCityId
        matchingOfferActivityId
      }
      createdAt
      updatedAt
      teamMemberFavoriteMatchingOfferId
      favoriteMatchingOfferOfferId
    }
  }
`
export const deleteFavoriteMatchingOffer = /* GraphQL */ `
  mutation DeleteFavoriteMatchingOffer(
    $input: DeleteFavoriteMatchingOfferInput!
    $condition: ModelFavoriteMatchingOfferConditionInput
  ) {
    deleteFavoriteMatchingOffer(input: $input, condition: $condition) {
      id
      offer {
        id
        purpose
        title
        body
        date
        startTime
        endTime
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        place
        placeURL
        expense
        expensePurpose
        ageFrom
        ageTo
        offerCount
        publishEndDate
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        attachmentFileNames
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        teamId
        teamName
        applications {
          nextToken
        }
        deleteFlg
        createdAt
        updatedAt
        teamMatchingOffersId
        matchingOfferPrefectureId
        matchingOfferCityId
        matchingOfferActivityId
      }
      createdAt
      updatedAt
      teamMemberFavoriteMatchingOfferId
      favoriteMatchingOfferOfferId
    }
  }
`
export const updateMatchingApplication = /* GraphQL */ `
  mutation UpdateMatchingApplication(
    $input: UpdateMatchingApplicationInput!
    $condition: ModelMatchingApplicationConditionInput
  ) {
    updateMatchingApplication(input: $input, condition: $condition) {
      id
      applicant {
        id
        name
        category {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        description
        requirementForMemberProfile
        customQuestions
        showMemberListFlg
        invitationCode
        teamMembers {
          nextToken
        }
        preTeamMembers {
          nextToken
        }
        invitationEmailStatuses {
          nextToken
        }
        groups {
          nextToken
        }
        matchingOffers {
          nextToken
        }
        matchingOfferDrafts {
          nextToken
        }
        matchingApplications {
          nextToken
        }
        blockingMatchingTeams {
          nextToken
        }
        paidVersionOrders {
          nextToken
        }
        blockingMatchingTeamsByBlockingTeamId {
          nextToken
        }
        migrationId
        paidFunctionEnabled
        showAdsFlg
        createdAt
        updatedAt
        teamCategoryId
        teamActivityId
        teamPrefectureId
        teamCityId
      }
      offer {
        id
        purpose
        title
        body
        date
        startTime
        endTime
        prefecture {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        city {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
          prefectureMasterCitiesId
        }
        place
        placeURL
        expense
        expensePurpose
        ageFrom
        ageTo
        offerCount
        publishEndDate
        team {
          id
          name
          description
          requirementForMemberProfile
          customQuestions
          showMemberListFlg
          invitationCode
          migrationId
          paidFunctionEnabled
          showAdsFlg
          createdAt
          updatedAt
          teamCategoryId
          teamActivityId
          teamPrefectureId
          teamCityId
        }
        attachmentFileNames
        activity {
          id
          label
          displayNumber
          displayFlg
          createdAt
          updatedAt
        }
        teamId
        teamName
        applications {
          nextToken
        }
        deleteFlg
        createdAt
        updatedAt
        teamMatchingOffersId
        matchingOfferPrefectureId
        matchingOfferCityId
        matchingOfferActivityId
      }
      messages {
        items {
          id
          messageSenderTeamId
          text
          unreadFlg
          deleteFlg
          contentUpdatedAt
          createdAtForSort
          createdAtForSortIncludeDeleted
          applicationIdMessageReceiverGsi
          matchingApplicationMessagesId
          matchingApplicationMessagesIncludeDeletedId
          createdAt
          updatedAt
        }
        nextToken
      }
      messagesIncludeDeleted {
        items {
          id
          messageSenderTeamId
          text
          unreadFlg
          deleteFlg
          contentUpdatedAt
          createdAtForSort
          createdAtForSortIncludeDeleted
          applicationIdMessageReceiverGsi
          matchingApplicationMessagesId
          matchingApplicationMessagesIncludeDeletedId
          createdAt
          updatedAt
        }
        nextToken
      }
      offerTeamName
      offerTitle
      offerBody
      createdAt
      updatedAt
      teamMatchingApplicationsId
      matchingOfferApplicationsId
    }
  }
`
export const createBlockingMatchingTeam = /* GraphQL */ `
  mutation CreateBlockingMatchingTeam(
    $input: CreateBlockingMatchingTeamInput!
    $condition: ModelBlockingMatchingTeamConditionInput
  ) {
    createBlockingMatchingTeam(input: $input, condition: $condition) {
      id
      blockingTeamId
      createdAt
      updatedAt
      teamBlockingMatchingTeamsId
    }
  }
`
export const deleteBlockingMatchingTeam = /* GraphQL */ `
  mutation DeleteBlockingMatchingTeam(
    $input: DeleteBlockingMatchingTeamInput!
    $condition: ModelBlockingMatchingTeamConditionInput
  ) {
    deleteBlockingMatchingTeam(input: $input, condition: $condition) {
      id
      blockingTeamId
      createdAt
      updatedAt
      teamBlockingMatchingTeamsId
    }
  }
`
export const createTeamCategoryActivity = /* GraphQL */ `
  mutation CreateTeamCategoryActivity(
    $input: CreateTeamCategoryActivityInput!
    $condition: ModelTeamCategoryActivityConditionInput
  ) {
    createTeamCategoryActivity(input: $input, condition: $condition) {
      id
      teamCategoryMasterID
      teamActivityMasterID
      teamCategoryMaster {
        id
        label
        displayNumber
        displayFlg
        activities {
          nextToken
        }
        createdAt
        updatedAt
      }
      teamActivityMaster {
        id
        label
        displayNumber
        displayFlg
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const updateTeamCategoryActivity = /* GraphQL */ `
  mutation UpdateTeamCategoryActivity(
    $input: UpdateTeamCategoryActivityInput!
    $condition: ModelTeamCategoryActivityConditionInput
  ) {
    updateTeamCategoryActivity(input: $input, condition: $condition) {
      id
      teamCategoryMasterID
      teamActivityMasterID
      teamCategoryMaster {
        id
        label
        displayNumber
        displayFlg
        activities {
          nextToken
        }
        createdAt
        updatedAt
      }
      teamActivityMaster {
        id
        label
        displayNumber
        displayFlg
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
export const deleteTeamCategoryActivity = /* GraphQL */ `
  mutation DeleteTeamCategoryActivity(
    $input: DeleteTeamCategoryActivityInput!
    $condition: ModelTeamCategoryActivityConditionInput
  ) {
    deleteTeamCategoryActivity(input: $input, condition: $condition) {
      id
      teamCategoryMasterID
      teamActivityMasterID
      teamCategoryMaster {
        id
        label
        displayNumber
        displayFlg
        activities {
          nextToken
        }
        createdAt
        updatedAt
      }
      teamActivityMaster {
        id
        label
        displayNumber
        displayFlg
        categories {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`
