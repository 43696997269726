import { memo, useCallback, useEffect } from "react"
import { Platform } from "react-native"
import { EnterPhoneNumber } from "src/components/parts/authorization/EnterPhoneNumber"
import { HeaderLeft } from "src/components/parts/headers/HeaderLeft"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useLoginPhoneNumberData } from "src/recoil/hooks/screens/useLoginPhoneNumberData"
import { RootStackScreenProps } from "src/types.d"

export const LoginPhoneNumber = memo<RootStackScreenProps<typeof Screens.LoginPhoneNumber>>(({ navigation }) => {
  const gotoEnterAuthCode = useCallback(
    (phoneNumber: string) => navigation.navigate("LoginCode", { phoneNumber }),
    [navigation]
  )
  const { phoneNumber, setPhoneNumber, sendAuthCode, isSendingAuthCode, isDisabled } = useLoginPhoneNumberData({
    gotoEnterAuthCode,
  })
  const gotoLoginEmail = useCallback(() => navigation.navigate(Screens.LoginEmail), [navigation])

  useEffect(() => {
    if (Platform.OS === "web") {
      navigation.setOptions({
        headerLeft: () => <HeaderLeft onPress={() => navigation.navigate(Screens.AuthorizationTop)} canGoBack={true} />,
      })
    }
  }, [navigation])

  return (
    <EnterPhoneNumber
      label="登録した携帯電話番号を入力"
      phoneNumber={phoneNumber}
      onPhoneNumberChange={(text) => {
        const numericValue = text.replace(/[^0-9]/g, "")
        setPhoneNumber(numericValue)
      }}
      sendAuthCode={sendAuthCode}
      gotoLoginEmail={gotoLoginEmail}
      isDisabled={isDisabled}
      isSending={isSendingAuthCode}
    />
  )
})

LoginPhoneNumber.displayName = "LoginPhoneNumber"

export const loginPhoneNumberOptions: ScreenOptions = {
  ...commonHeaderOptions,
  title: "ログイン",
}
