import { Result } from "src/utils/Result";
import { GqlError, createFavoriteMatchingOffer as _createFavoriteMatchingOffer } from "src/aws/customAPI"

type CreateFavoriteMatchingOfferParams = {
  accessToken: string
  teamMemberId: string
  matchingOfferId: string
}

export type CreateFavoriteMatchingOfferSuccess = unknown

type CreateFavoriteMatchingOfferFailure = {
  message: string
}

export const createFavoriteMatchingOffer = async (
  {accessToken, teamMemberId, matchingOfferId}: CreateFavoriteMatchingOfferParams): Promise<Result<CreateFavoriteMatchingOfferSuccess, CreateFavoriteMatchingOfferFailure>> => {
  return await _createFavoriteMatchingOffer(accessToken, {
    teamMemberId,
    matchingOfferId,
    }
  ).then(res => {
    return (res.data?.createFavoriteMatchingOffer.id) ?
      Result.Ok(undefined) :
      Result.Error<CreateFavoriteMatchingOfferFailure>({ message: 'お気に入りの登録に失敗しました' })
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || 'お気に入りの登録に失敗しました'
    return Result.Error<CreateFavoriteMatchingOfferFailure>({
      message: message
    })
  })
}