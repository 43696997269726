import { useMemo } from "react"
import { memo } from "react"
import Svg, { Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  isFavorite?: boolean
}

export const FavoriteIcon = memo<Props>(({ isFavorite }) => {
  const fill = useMemo(() => (isFavorite ? Colors.marigolod : Colors.white4), [isFavorite])

  return (
    <Svg width="17.239" height="16.5" viewBox="0 0 17.239 16.5">
      <Path
        d="m28.194.561-2.1 4.266-4.708.686a1.032 1.032 0 0 0-.57 1.759l3.406 3.319-.806 4.688a1.031 1.031 0 0 0 1.5 1.086l4.211-2.214 4.211 2.214a1.031 1.031 0 0 0 1.5-1.086l-.806-4.688 3.406-3.319a1.032 1.032 0 0 0-.57-1.759l-4.708-.686L30.044.561a1.032 1.032 0 0 0-1.85 0z"
        transform="translate(-20.5 .013)"
        fill={fill}
      />
    </Svg>
  )
})
