import { Result } from "src/utils/Result"
import { createInquiry } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import { getUserAgent } from 'react-native-device-info';

type PostInquiryRequestParams = {
  accessToken: string
  content?: string
  userDevice?: string
  organizationId?: string
  agreedToPrivacyPolicy: boolean
}

type PostInquirySuccess = unknown

type PostInquiryFailure = {
  message: string
}

export const postInquiry = async ({accessToken, ...params}: PostInquiryRequestParams): Promise<Result<PostInquirySuccess, PostInquiryFailure>> => {
  const userAgent = await getUserAgent()
  return execApi(
    () => createInquiry(accessToken, {
      input: {
        content: params.content,
        deviceInfo: params.userDevice,
        teamId: params.organizationId,
        agreedToPrivacyPolicy: params.agreedToPrivacyPolicy,
        userAgent,
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["postInquiry", "createInquiry"]
    }
  )
}
