import { RouteProp } from "@react-navigation/native"
import { StackNavigationOptions } from "@react-navigation/stack"
import { memo, useEffect, useMemo } from "react"
import { Platform, ScrollView, StyleSheet, Text, View } from "react-native"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { useInformationDetailData } from "src/recoil/hooks/screens/useInformationDetailData"
import { RootStackParamList, RootStackScreenProps } from "src/types.d"
import { SimpleMarkDownStyle } from "src/components/parts/SimpleMarkDown"
import { MainLayout } from "src/layouts/MainLayout"
import { HeaderCommon } from "src/components/parts/HeaderCommon"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { HeaderLeft } from "src/components/parts/headers/HeaderLeft"
import Markdown from "react-native-markdown-display"
import { setAsync } from "src/storage/secureStorage/setAsync"
import { getAsync } from "src/storage/secureStorage/getAsync"
import { useRecoilState } from "recoil"
import { readSystemInformationsState } from "src/recoil/atoms/bottomTab/readSystemInformationsState"

export type InformationDetailParams = {
  infoId: string
  updatedAt: string
  isRead: boolean
}
type Props = RootStackScreenProps<typeof Screens.InformationDetail>

export const InformationDetail = memo<Props>(({ route, navigation }) => {
  const readFlg = useMemo(() => route.params.isRead, [route.params.isRead])
  const infoId = useMemo(() => route.params.infoId, [route.params.infoId])
  const updateAt = useMemo(() => route.params?.updatedAt, [route.params?.updatedAt])
  const { insets, information } = useInformationDetailData({ infoId, readFlg })
  const isPCScreen = useCheckPCScreen()
  const [readSystemInformations, setReadSystemInformations] = useRecoilState(readSystemInformationsState)
  useEffect(() => {
    if (!readSystemInformations.has(infoId)) {
      setReadSystemInformations((prevSet) => new Set(prevSet).add(infoId))
    }
  }, [infoId, readSystemInformations, setReadSystemInformations])

  useEffect(
    () =>
      navigation.setOptions({
        title: updateAt,
        headerStyle: { backgroundColor: isPCScreen ? Colors.white3 : Colors.orange },
        headerTitleStyle: { color: isPCScreen ? Colors.black : Colors.white },
        headerTitleAlign: "center",
        headerLeft: HeaderLeft,
      }),
    [updateAt, navigation, isPCScreen]
  )

  if (information == null) {
    return (
      <MainLayout>
        <View style={styles.loadingContainer}>
          <LoadingIndicator />
        </View>
      </MainLayout>
    )
  }
  return (
    <MainLayout>
      <HeaderCommon
        title={route.params?.updatedAt}
        back={() => {
          navigation.navigate(Screens.Information)
        }}
      />
      <ScrollView contentContainerStyle={{ paddingBottom: insets.bottom }}>
        <View style={styles.title}>
          <Text style={styles.titleText}>{information.title}</Text>
        </View>
        <View style={{ paddingHorizontal: 16, paddingVertical: 24 }}>
          <Markdown style={mdNewStyles}>{information.body}</Markdown>
        </View>
      </ScrollView>
    </MainLayout>
  )
})

type Opts = (param: { route: RouteProp<RootStackParamList, typeof Screens.InformationDetail> }) => StackNavigationOptions

export const informationDetailOptions: Opts = ({ route }) => ({
  ...commonHeaderOptions,
  title: route.params?.updatedAt,
})

const mdNewStyles = StyleSheet.create({
  body: {
    fontSize: 16,
    lineHeight: 24,
    color: "rgb(60, 60, 60)",
  },

  link: {
    fontSize: 16,
    color: "rgb(225, 21, 32)",
  },
})

const styles = StyleSheet.create({
  title: {
    paddingVertical: 24,
    paddingHorizontal: 16,
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
  },
  titleText: {
    fontSize: 18,
    lineHeight: 24,
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
})

const mdStyles: SimpleMarkDownStyle = StyleSheet.create({
  container: {
    paddingVertical: 24,
    paddingHorizontal: 16,
  },
  text: {
    fontSize: 16,
    lineHeight: 24,
    color: Colors.greyshBrown,
  },
  link: {
    fontSize: 16,
    lineHeight: 24,
    color: "#e11520",
    textDecorationLine: "underline",
  },
})
