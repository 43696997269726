import { useAsyncState } from "src/hooks/useAsyncState"
import { sessionState } from "src/recoil/atoms/authorization/sessionState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"

export const useIsAuthorized = () => {
  const [session] = useAsyncState(sessionState)
  const [tokens] = useAsyncState(tokensState)

  return session != null && tokens != null
}
