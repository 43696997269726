import { listAllMigratedTeams } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
}

export type MyMigratedTeam = {
  id: string
  title: string
  uriImage?: string
  invitationCode: string
  joinFlg: boolean
  migrationId: number | undefined
  role: string
}

export const getListAllMigratedTeams = async ({ accessToken }: Params): Promise<MyMigratedTeam[]> => {
  const result = await execApi(
    () =>
      listAllMigratedTeams(accessToken, {
        appInfo,
      }),
    (res) => {
      return (res.listAllMigratedTeams?.migratedTeams ?? []).map((item) => ({
        id: item?.id ?? "",
        title: item?.name ?? "",
        uriImage: item?.image.url,
        invitationCode: item?.invitationCode ?? "",
        joinFlg: item?.joinFlg ?? false,
        migrationId: item?.migrationId,
        role: item?.role ?? "",
      }))
    },
    {
      identifiers: ["getListAllMigratedTeams", "listAllMigratedTeams"],
    }
  )

  return result.isOk ? result.content : []
}
