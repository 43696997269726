import { memo } from "react"
import Svg, { Circle, G, Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fillColor?: string
  height?: number
  width?: number
}

export const SearchIcon = memo<Props>(({ fillColor = Colors.black, width = 18.334, height = 19.452 }) => (
  <Svg width={width} height={height} viewBox="0 0 18.334 19.452">
    <G>
      <G stroke={fillColor} strokeWidth="3" fill="none">
        <Circle cx="8.071" cy="8.071" r="8.071" stroke="none" />
        <Circle cx="8.071" cy="8.071" r="6.571" fill="none" />
      </G>
      <Path
        transform="translate(12.858 13.977)"
        strokeLinecap="round"
        stroke={fillColor}
        strokeWidth="3"
        fill="none"
        d="m0 0 3.354 3.354"
      />
    </G>
  </Svg>
))
