import { View, StyleSheet } from "react-native"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import StepComponent from "./Step"

export enum Step {
  one = "one",
  two = "two",
  three = "three",
}

enum StepContent {
  one = "14.72 12.736H84.58c.096-.624.128-1.248.128-2.08v-8.816h-.768c-.592 0-1.056.032-1.648.096V12.36c.496.08.848.096 1.6.096h1.472c.816 0 1.056-.016 1.632-.08-.096.944-.128 1.68-.128 2.64v8.096c0 .848.032 1.424.128 2.08z",
  two = "15.616 12.736h-5.136c-1.248 0-1.504.016-2.064.08.096-.912.208-1.472.416-2.048.432-1.168 1.152-2.192 2.192-3.136.56-.496 1.04-.864 2.64-1.936 1.472-1.008 1.92-1.536 1.92-2.352 0-1.088-.848-1.76-2.208-1.76-.928 0-1.632.32-2.032.944-.272.384-.384.736-.496 1.472l-2.288-.272c.224-1.216.512-1.872 1.104-2.56.8-.928 2.064-1.424 3.664-1.424 2.8 0 4.528 1.344 4.528 3.536 0 1.056-.352 1.824-1.136 2.56-.448.416-.608.544-2.688 1.968-.704.496-1.504 1.168-1.904 1.6-.48.512-.672.832-.912 1.44h4.48c1.008 0 1.808-.048 2.336-.128v2.112a25.903 25.903 0 00-2.416-.096z",
  three = "8.208 9.712l2.288-.448c.208.736.352.992.752 1.312.528.416 1.168.624 1.936.624 1.584 0 2.592-.816 2.592-2.08 0-.736-.32-1.344-.896-1.68-.384-.224-1.136-.384-1.84-.384-.704 0-1.168.016-1.712.08v-1.984c.512.064.864.08 1.552.08 1.6 0 2.544-.736 2.544-1.968 0-1.104-.752-1.728-2.112-1.728-.784 0-1.36.176-1.744.496-.32.304-.48.576-.672 1.232l-2.288-.384c.4-1.184.768-1.744 1.52-2.288.816-.592 1.856-.88 3.136-.88 2.672 0 4.4 1.344 4.4 3.424 0 .96-.416 1.84-1.104 2.352-.352.272-.704.4-1.552.624 1.136.176 1.728.448 2.288 1.072.528.592.768 1.264.768 2.208 0 .864-.368 1.744-.96 2.336-.88.848-2.224 1.296-3.936 1.296-1.872 0-3.152-.48-4.064-1.52-.4-.448-.608-.864-.896-1.792z",
}

type Props = {
  stepProp: string
}

export const BreadCrumb = ({ stepProp }: Props) => {
  const isLargeScreen = useCheckPCScreen()
  const renderStep = (step: string) => {
    if (step === Step.one) {
      return (
        <>
          <StepComponent active={true} stepContent={StepContent.one} />
          <StepComponent active={false} stepContent={StepContent.two} />
          <StepComponent active={false} stepContent={StepContent.three} />
        </>
      )
    } else if (step === Step.two) {
      return (
        <>
          <StepComponent active={false} stepContent={StepContent.one} />
          <StepComponent active={true} stepContent={StepContent.two} />
          <StepComponent active={false} stepContent={StepContent.three} />
        </>
      )
    } else {
      return (
        <>
          <StepComponent active={false} stepContent={StepContent.one} />
          <StepComponent active={false} stepContent={StepContent.two} />
          <StepComponent active={true} stepContent={StepContent.three} />
        </>
      )
    }
  }
  return <View style={[styles.container, isLargeScreen ? { width: 600 } : null]}>{renderStep(stepProp)}</View>
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 24,
    marginTop: 24,
    justifyContent: "center",
    flexDirection: "row",
  },
})
