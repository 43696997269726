import { useCallback } from "react"

export const useRequiredCallback = <A>(cb?: (arg: A) => void): ((arg: A) => void) => {
  return useCallback(
    (arg) => {
      cb?.(arg)
    },
    [cb]
  )
}
