import { selector } from "recoil"
import { MyMigratedTeam, getListAllMigratedTeams } from "src/queries/getListAllMigratedTeams"
import { listAllMigratedTeamsRequestIdState } from "src/recoil/atoms/organization/listAllMigratedTeamsRequestIdState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"

export const listAllMigratedTeamsSelector = selector<MyMigratedTeam[]>({
  key: "selectors/organization/listAllMigratedTeamsSelector",
  get: async ({ get }) => {
    get(listAllMigratedTeamsRequestIdState)
    const tokens = get(tokensState)
    const accountId = get(accountIdState)
    if (tokens == null || accountId == null) return []

    return await getListAllMigratedTeams({
      accessToken: tokens.accessToken,
    })
  },
})
