import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { Result } from "src/utils/Result"
import { listTrashedMail } from "src/aws/customAPI"
import { MailType } from "src/aws/API"
import { ContactType } from "src/constants/ContactType"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type GetTrashboxContactsRequestParams = {
  accessToken: string
  myMemberId: string
  count?: number
  nextToken?: string
}

type GetTrashboxContactsSuccess = {
  contacts: ContactOverviewModel[]
  nextToken?: string
}

type GetTrashboxContactsFailure = {
  message: string
}

export const getTrashboxContacts = async (params: GetTrashboxContactsRequestParams): Promise<Result<GetTrashboxContactsSuccess, GetTrashboxContactsFailure>> => {
  const {accessToken, myMemberId} = params
  return execApi(
    () => listTrashedMail(accessToken, {
      teamMemberId: myMemberId,
      appInfo,
      nextToken: params.nextToken,
      count: params.count,
    }),
    res => {
      return {
        contacts: res.searchTeamMemberMails.items.map(m => ({
          id: m.mail.id,
          contactType: mailTypeMapping[m.mail.mailType],
          isRead: m.readFlg,
          title: m.mail.title,
          senderId: m.mail.sender.id,
          senderName: m.mail.sender.nickname,
          isSenderDeleted: m.mail.sender.deleteFlg || false,
          isFavorite: m.favoriteFlgReceived,
          isAttachmentExists: (m.mail.attachmentFileNames || []).length > 0,
          sentDate: new Date(m.mail.sentAt),
          isUpdate: m.mail.updatedAt > m.mail.createdAt,
          deadlineDate: m.mail.responseDueDatetime ? new Date(m.mail.responseDueDatetime) : undefined,
          teamMemberContactId: m.id,
        })),
        nextToken: res.searchTeamMemberMails.nextToken
      }
    }
  )
}

const mailTypeMapping: {[key in MailType]: ContactType} = {
  [MailType.normal]: ContactType.Normal,
  [MailType.attendance]: ContactType.Attendance,
  [MailType.questionnaire]: ContactType.Survey,
  [MailType.schedule]: ContactType.ScheduleAdjustment,
}
