import { memo } from "react"
import { Image, StyleSheet } from "react-native"
import Svg, { Path, Rect } from "react-native-svg"

export function ExpiredAttachment(props: any) {
  return (
    <Svg width={50} height={50} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path fill="#000" fillOpacity={0.05} d="M0 0H50V50H0z" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M35.4 14.6H14.6v20.8h20.8V14.6zM14.6 12a2.6 2.6 0 00-2.6 2.6v20.8a2.6 2.6 0 002.6 2.6h20.8a2.6 2.6 0 002.6-2.6V14.6a2.6 2.6 0 00-2.6-2.6H14.6z"
        fill="#888"
      />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.8 21.75a1.95 1.95 0 100-3.9 1.95 1.95 0 000 3.9zm0 1.95a3.9 3.9 0 100-7.8 3.9 3.9 0 000 7.8zM28.306 23.43a1.3 1.3 0 011.838 0l4.225 4.226a1.3 1.3 0 11-1.838 1.838l-3.306-3.305-4.28 4.28a1.3 1.3 0 01-1.84 0l-1.68-1.68-3.63 3.63a1.3 1.3 0 11-1.84-1.838l4.55-4.55a1.3 1.3 0 011.84 0l1.68 1.68 4.28-4.28z"
        fill="#888"
      />
      <Rect x={28} y={28} width={18} height={18} rx={9} fill="#F00E0E" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M41.74 33.586a.9.9 0 10-1.274-1.273l-3.44 3.44-3.44-3.44a.9.9 0 10-1.273 1.273l3.44 3.44-3.44 3.44a.9.9 0 101.273 1.273l3.44-3.44 3.44 3.44a.9.9 0 101.273-1.273l-3.44-3.44 3.44-3.44z"
        fill="#fff"
      />
    </Svg>
  )
}

export const AttachmentIcon = memo(() => (
  <Image style={styles.icon} source={require("src/assets/images/contactNetworkTab/attachment.png")} />
))

const styles = StyleSheet.create({
  icon: {
    width: 16,
    height: 16,
    marginLeft: 8,
  },
  iconAttachment: {
    width: 50,
    height: 50,
  },
})
