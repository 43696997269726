import { FC } from "react"
import Svg, { Circle, G } from "react-native-svg"

/*
 ** まるボタン
 */

type Props = {
  fill: string
}

export const YesIcon: FC<Props> = ({ fill }) => (
  <Svg width="18" height="18" viewBox="0 0 18 18">
    <G stroke={fill} strokeWidth="3px" fill="none">
      <Circle cx="9" cy="9" r="9" stroke="none" />
      <Circle cx="9" cy="9" r="7.5" fill="none" />
    </G>
  </Svg>
)

YesIcon.displayName = "YesIcon"
