import Select, { SingleValue } from "react-select"
import { useCallback, useMemo } from "react"
import { Colors } from "src/constants/Colors"
import { Image, View } from "react-native"
import { styles } from "./styles"
import { Text } from "react-native"
import { BadgeIcon } from "src/components/parts/icons/BadgeIcon"
import { hasUnreadThreadMessageSelectorFamily } from "src/recoil/selectors/contact/hasUnreadThreadMessageSelectorFamily"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"

const height = 50

interface TeamOption {
  label: string
  value?: string
  teamId?: string
  count?: number
  imgUrl?: string
}

type TeamSelectProps = {
  options: TeamOption[]
  placeholder?: string
  isLoading?: boolean
  isDisabled?: boolean
  required?: false
  value?: string
  onChange: (next?: string) => void
}

const FormatOptionLabel = ({ option }: { option: TeamOption }) => {
  const organizationId = option.teamId
  const { value: hasUnreadThreadMessage } = useAsyncSelector(hasUnreadThreadMessageSelectorFamily({ organizationId }))
  return (
    <View style={styles.teamOption}>
      {option.imgUrl && <img src={option.imgUrl} style={styles.teamOptionImage} />}
      <View style={styles.teamOptionLabel}>
        <Text>{option.label}</Text>
      </View>
      {(option.count ?? 0) > 0 || hasUnreadThreadMessage?.hasUnread ? (
        <View>
          <BadgeIcon size="medium" style={styles.teamOptionIcon} />
        </View>
      ) : (
        <View style={styles.teamOptionIcon} />
      )}
    </View>
  )
}

export const TeamSelect = ({
  options,
  placeholder = "選択してください",
  isLoading,
  value,
  required,
  isDisabled,
  onChange,
}: TeamSelectProps) => {
  const defaultValue = useMemo(() => ({ value: undefined, label: placeholder }), [placeholder])
  const selected = useMemo(() => options.find((opt) => opt.value === value) ?? defaultValue, [options, value, defaultValue])

  const update = useCallback(
    (next: SingleValue<{ value?: string; label: string }>) => {
      if (required) {
        if (next?.value == null) {
          return
        }
        onChange(next.value)
      } else {
        onChange(next?.value)
      }
    },
    [onChange, required]
  )
  const opts = useMemo(() => {
    const head = required ? [] : [defaultValue]
    return [...head, ...options]
  }, [required, options, defaultValue])

  return (
    <Select<TeamOption>
      options={opts}
      value={selected}
      onChange={update}
      isSearchable={false}
      isDisabled={isDisabled}
      isLoading={isLoading}
      formatOptionLabel={(option) => <FormatOptionLabel option={option} />}
      styles={{
        control: (provided: any) => {
          return {
            ...provided,
            borderColor: Colors.lightGrey,
            borderWidth: 2,
            borderRadius: 8,
            height,
            minHeight: height,
            boxShadow: "none",
          }
        },
        menu: (provided) => ({
          ...provided,
        }),
        indicatorsContainer: (provided: any) => {
          return { ...provided, paddingRight: 8 }
        },
        indicatorSeparator: () => {
          return { display: "none" }
        },
        valueContainer: (provided) => {
          return { ...provided }
        },
      }}
      menuPortalTarget={document.body}
      menuShouldBlockScroll
      components={{
        DropdownIndicator: () => (
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="7.4" viewBox="0 0 12 7.4">
            <path data-name="↳Color" d="M10.59 0 6 4.574 1.41 0 0 1.408 6 7.4l6-5.992z" fill="#f0830e" />
          </svg>
        ),
      }}
      placeholder={placeholder}
    />
  )
}

TeamSelect.displayName = "TeamSelect"
