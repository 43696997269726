import { GqlError, createPaidVersionOrder as createPaidVersionOrderApi } from "src/aws/customAPI";
import { execApi } from "src/apis/execApi"
import { PlanData } from "src/recoil/atoms/planData/planDataState";
import { ApplicantInformationData } from "src/recoil/atoms/applicantInformation/applicantInformationDataState";
import { OrderPaymentMethodType, OrderPaymentType, OrderType } from "src/aws/API";
import { ResendEmailOrderType } from 'src/aws/API';
import { convertDataEmail } from "src/utils/validate";
import dayjs from 'dayjs';
import { MIGRATE_PLAN_ID } from "src/utils/const";

type Params = {
  accessToken: string
  planData: PlanData
  applicantInformationData: ApplicantInformationData
}

const now = dayjs();

export const createPaidVersionOrder = async ({ accessToken, planData, applicantInformationData }: Params): Promise<{ id: string } | any> => {
  // TODO
  // const result = await execApi(
  //   () => createPaidVersionOrderApi(accessToken, {input: {
  //     address: applicantInformationData.address,
    // orderType: applicantInformationData.appCategory === "個人" ? OrderType.private : OrderType.corporation,
    // companyName: applicantInformationData.companyName,
    // companyNameKana: applicantInformationData.companyNameKana,
    // corporateRepresentativeName: applicantInformationData.representativeName,
    // corporateRepresentativeNameKana: applicantInformationData.companyRepresentativeNameKana,
    // applicantPhone: applicantInformationData.phoneNumber.toString(),
    // applicantMail: applicantInformationData.email,
    // applicantName: applicantInformationData.adminName,
    // applicantPostcode: applicantInformationData.zip.toString(),
    // applicantPrefecture: applicantInformationData.prefecture,
    // applicantAddress: applicantInformationData.address,
    // applicantBuildingName: applicantInformationData.buildingName,
    // applicantRemark: planData.remark,
    // teamId: applicantInformationData.idTeam,
    // teamName: applicantInformationData.teamName,
    // planId: planData.planId,
    // startDate: planData.startTimeConvert,
    // paymentType: OrderPaymentType.prepaid,
    // paymentMethod: OrderPaymentMethodType.BANK_TRANSFER,
    // invoiceAddressee: planData.invoiceAddress,
    // invoiceAddresseeKana: planData.invoiceKanaAddress,
  //   }}),
  //   res =>{
  //     return  ({id: res?.createPaidVersionOrder.id})
  //   },
  //   {
  //   identifiers: ["createPaidVersionOrder", "createPaidVersionOrderApi"],
  //   }
  // )
  // return result.isOk ? result.content : ( result.error )
  if (applicantInformationData.phoneNumber && applicantInformationData.adminName && applicantInformationData.email && applicantInformationData.idTeam && planData.planId && planData.startTimeConvert && planData.invoiceAddress && planData.invoiceKanaAddress)
  return createPaidVersionOrderApi(accessToken, {input: {
    orderType: applicantInformationData.appCategory === "個人" ? OrderType.private : OrderType.corporation,
    companyName: applicantInformationData.appCategory === "個人" ? null : applicantInformationData.companyName,
    companyNameKana: applicantInformationData.appCategory === "個人" ? null : applicantInformationData.companyNameKana,
    corporateRepresentativeName: applicantInformationData.appCategory === "個人" ? null : applicantInformationData.representativeName,
    corporateRepresentativeNameKana: applicantInformationData.appCategory === "個人" ? null : applicantInformationData.companyRepresentativeNameKana,
    applicantPhone: applicantInformationData.phoneNumber,
    applicantMail: applicantInformationData.email,
    applicantName: applicantInformationData.adminName,
    applicantPostcode: applicantInformationData.zip,
    applicantPrefecture: applicantInformationData.prefecture,
    applicantAddress: applicantInformationData.address,
    applicantBuildingName: applicantInformationData.buildingName,
    applicantRemark: convertDataEmail(planData.remark ?? "").length <= 2000 ? convertDataEmail(planData.remark ?? "") : convertDataEmail(planData.remark ?? "").slice(0, 2000),
    teamId: applicantInformationData.idTeam,
    teamName: applicantInformationData.teamName,
    planId: planData.planId,
    startDate: planData.planId === MIGRATE_PLAN_ID ? dayjs().startOf('month').format('YYYY-MM-DD') : planData.startTimeConvert,
    paymentType: OrderPaymentType.prepaid,
    paymentMethod: OrderPaymentMethodType.BANK_TRANSFER,
    invoiceAddressee: planData.invoiceAddress,
    invoiceAddresseeKana: planData.invoiceKanaAddress,
  }}).then(res => {
    if (res.data?.createPaidVersionOrder.id) {
      return {id: res.data?.createPaidVersionOrder.id}
    } else {
      return ""
    }
  }).catch((e: GqlError) => {
    return e
  })
}