import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { useRecoilValue } from "recoil"
import { inquirySelectedOrganizationState } from "src/recoil/atoms/settings/inquiry/inquirySelectedOrganizationState"
import { inquiryContentState } from "src/recoil/atoms/settings/inquiry/inquiryContentState"
import { myOrganizationsSelector } from "src/recoil/selectors/organization/myOrganizationsSelector"
import { useCallback, useMemo, useState } from "react"
import { postInquiry } from "src/apis/settings/postInquery"
import { CustomAlert } from "src/utils/CustomAlert"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { inquiryUserDeviceState } from "src/recoil/atoms/settings/inquiry/inquiryUserDeviceState"
import { convertDataEmail } from "src/utils/validate"

export const useInquiryConfirmData = () => {
  const { value: account, isLoading: isEmailLoading } = useAsyncSelector(accountSelector)
  const { value: tokens } = useAsyncSelector(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const { value: myOrganizations, isLoading: isMyOrganizationsLoading } = useAsyncSelector(myOrganizationsSelector)
  const selectedOrganizationId = useRecoilValue(inquirySelectedOrganizationState)
  const selectedOrganization = useMemo(
    () => myOrganizations?.find((v) => v.id === selectedOrganizationId)?.title ?? "",
    [selectedOrganizationId, myOrganizations]
  )
  const [isSending, setIsSending] = useState(false)
  const content = useRecoilValue(inquiryContentState)
  const userDevice = useRecoilValue(inquiryUserDeviceState)
  const sendInquiry = useCallback(
    async (callback: () => void) => {
      if (accessToken == null || account == null) {
        return
      }
      const newContent = content ? convertDataEmail(content) : ""
      setIsSending(true)
      const result = await postInquiry({
        accessToken,
        content: newContent,
        userDevice,
        organizationId: selectedOrganizationId,
        agreedToPrivacyPolicy: true,
      })
      if (!result.isOk) {
        CustomAlert.alert("エラー", result.error.message)
        setIsSending(false)
        return
      }
      callback()
      setIsSending(false)
    },
    [accessToken, account, content, selectedOrganizationId, setIsSending, userDevice]
  )

  return {
    email: account?.email,
    isEmailLoading,
    isMyOrganizationsLoading,
    selectedOrganization,
    selectedOrganizationId,
    content,
    userDevice,
    sendInquiry,
    isSending,
  }
}
