import React, { memo } from "react"
import { StyleSheet, View, Text, Image } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { DocumentIcon } from "src/components/parts/icons/DocumentIcon"
import { getSizeStr } from "src/components/parts/contactNetworkTab/ContactDetailEditor"

import { AttachmentItemPropsMatching } from "src/screens/matching/OfferCreate"
import { Colors } from "src/constants/Colors"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
const dp = 16
const ddp = dp * 2
export const AttachmentItem = memo<AttachmentItemPropsMatching>(({ index, onRemove, ...rest }: AttachmentItemPropsMatching) => {
  const localImage = rest.type === "image" ? rest.localImage : undefined
  const localDocument = rest.type === "document" ? rest.localDocument : undefined
  const isLargeScreen = useCheckPCScreen()

  return (
    <View style={styles.attachmentsItemContainer}>
      <View style={styles.attachmentItem}>
        {localImage ? (
          <Image resizeMode="cover" source={{ uri: localImage?.uri }} style={styles.attachmentThumbnailContainer} />
        ) : (
          <View style={styles.attachmentThumbnailContainer}>
            <DocumentIcon />
          </View>
        )}

        <View style={{ display: "flex", flexDirection: "row", alignItems: "center", flex: 1 }}>
          <Text numberOfLines={1} ellipsizeMode="clip" style={[styles.attachmentLabel, { maxWidth: "75%", minWidth: "15%" }]}>
            {localImage ? localImage?.fileName : localDocument?.fileName}
          </Text>
          <Text style={[styles.attachmentLabel, isLargeScreen && { maxWidth: 60 }, { marginRight: 10 }]}>
            {localImage ? getSizeStr(localImage?.sizeInB ?? 0) : getSizeStr(localDocument?.sizeInB ?? 0)}
          </Text>
        </View>
      </View>
      <Button onPress={() => onRemove(index)} style={styles.removeAttachmentButton}>
        <RemoveIcon />
      </Button>
    </View>
  )
})

const styles = StyleSheet.create({
  attachmentsItemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 4,
  },
  attachmentItem: {
    height: 50,
    backgroundColor: Colors.reddishGrey,
    borderRadius: 10,
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    overflow: "hidden",
  },
  attachmentThumbnailContainer: {
    width: 50,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#f2caa0",
  },
  removeAttachmentButton: {
    width: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  attachmentLabel: {
    fontSize: 15,
    color: Colors.greyshBrown,
    marginLeft: 16,
  },
})
