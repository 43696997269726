import { selectorFamily } from "recoil"
import { requestDataSelector } from "../auth/requestDataSelector"
import { ReservedModel } from "src/types/contact/ReservedModel"
import { reservedRequestIdState } from "src/recoil/atoms/contact/reservedRequestIdState"
import { getReservedContact } from "src/apis/contact/getContactDetail"

export const reservedSelectorFamily = selectorFamily<ReservedModel | undefined, string>({
  key: "selectors/contact/reservedSelectorFamily",
  get:
    (contactId) =>
    async ({ get }) => {
      get(reservedRequestIdState)
      const requestData = get(requestDataSelector)
      if (requestData == null) {
        return
      }
      const { accessToken } = requestData
      const reservedResult = await getReservedContact({ accessToken, contactId })
      if (!reservedResult.isOk) {
        return
      }
      return reservedResult.content
    },
})
