import { selector } from "recoil"
import { getMyOrganizations, MyOrganization } from "src/queries/getMyOrganizations"
import {
  myFullOrganizationsRequestIdState,
  myOrganizationsRequestIdState,
} from "src/recoil/atoms/organization/myOrganizationsRequestIdState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { ImageTeamModel, multiTeamImageGetUrl } from "src/apis/resource/getResourceGetUrl"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"

export const myOrganizationsSelector = selector<MyOrganization[]>({
  key: "selectors/organization/myOrganizationsSelector",
  get: async ({ get }) => {
    get(myOrganizationsRequestIdState)
    const tokens = get(tokensState)
    const accountId = get(accountIdState)
    if (tokens == null || accountId == null) return []

    return await getMyOrganizations({
      accessToken: tokens.accessToken,
      accountId,
    })
  },
})

export const myFullOrganizationsSelector = selector<MyOrganization[]>({
  key: "selectors/organization/myFullOrganizationsSelector",
  get: async ({ get }) => {
    get(myFullOrganizationsRequestIdState)
    const tokens = get(tokensState)
    const accountId = get(accountIdState)
    if (tokens == null || accountId == null) return []

    const myOrganizations = await getMyOrganizations({
      accessToken: tokens.accessToken,
      accountId,
    })
    if (myOrganizations.length === 0) {
      return []
    }

    const teamImages = myOrganizations.map((el) => {
      const result = {
        teamId: el.id,
        thumbnail: false,
      }
      return result
    })

    if (teamImages.length === 0) {
      return []
    }

    const data = await multiTeamImageGetUrl({
      accessToken: tokens.accessToken,
      teamImages: teamImages,
    })
    if (!data) {
      return myOrganizations
    }

    return myOrganizations.map((el) => {
      const result = {
        id: el.id,
        title: el.title,
        uriImage: getTeamImageUrl(el.id, data),
        organizationNumber: el.organizationNumber,
        myMemberNumber: el.myMemberNumber,
        migrationId: el.migrationId,
        paidFunctionEnabled: el.paidFunctionEnabled ?? true,
        showAdsFlg: el.showAdsFlg ?? true,
      }
      return result
    })
  },
})

function getTeamImageUrl(organizationId: string, teamImagetUrls: ImageTeamModel[]) {
  const team = teamImagetUrls.find((el) => el.teamId === organizationId)
  return team?.url ?? undefined
}
