import { Platform, StyleSheet } from "react-native"
import { Colors } from "src/constants/Colors"

export const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    backgroundColor: Colors.white3,
    shadowColor: "#F9D5C1",
    shadowOpacity: 0.38,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 15,
  },
  bgLabel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 6,
    paddingVertical: 4,
    fontSize: 13,
    minWidth: 42,
    height: 26,
    marginRight: 10,
    borderColor: Colors.coolGrey,
    borderWidth: 1,
    color: Colors.coolGrey,
    backgroundColor: Colors.white3,
    outline: "none",
    borderRadius: 6,
    flexWrap: "wrap",
  },
  bgLabelCard: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingHorizontal: 6,
    paddingVertical: 4,
    fontSize: 13,
    minWidth: 42,
    height: 26,
    gap: 10,
    borderColor: Colors.coolGrey,
    borderWidth: 1,
    color: Colors.coolGrey,
    backgroundColor: Colors.white3,
    outline: "none",
    borderRadius: 6,
    flexWrap: "wrap",
    marginRight: 16,
  },
  titleMatching: {
    fontSize: 16,
    lineHeight: 22,
    fontWeight: "600",
    font: "Hiragino Kaku Gothic Pro",
  },
  viewContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    backgroundColor: Colors.white3,
    boxShadow: "0 0 15px 0 rgb(249 213 193 / 38%)",
    cursor: "pointer",
    gap: 8,
  },
  viewContainerCard: {
    display: "flex",
    flexDirection: "column",
    borderRadius: 10,
    backgroundColor: Colors.white3,
    boxShadow: "0 0 15px 0 rgb(249 213 193 / 38%)",
    cursor: "pointer",
  },
  litmitTime: {
    fontSize: 13,
    font: "Noto Sans",
    color: Colors.leaf,
  },
  containerLarge: {
    display: "flex",
    flexDirection: "row",
    borderRadius: 16,
    padding: 16,
    backgroundColor: Colors.white3,
    shadowColor: "#F9D5C1",
    shadowOpacity: 0.38,
    shadowOffset: { width: 0, height: 0 },
    shadowRadius: 15,
    width: "100%",
  },
  viewButton: {
    width: "90%",
  },
  buttonDeleteStyle: {
    paddingLeft: 15,
    paddingBottom: 3,
    height: 18,
  },
  deleteTrashBox: {
    display: "flex",
    width: "10%",
    flexDirection: "column",
  },
  border: {
    borderWidth: 1,
    borderColor: Colors.silver,
  },
  timeLimitContainer: {
    borderWidth: 1,
    borderColor: Colors.leaf,
    paddingTop: 11,
    position: "relative",
  },
  timeLimitLabelWrapper: {
    position: "absolute",
    top: -10,
    left: Platform.OS === "web" ? 0 : 16,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
  },
  topContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  space: {
    flex: 1,
  },
  topUnreadIcon: {
    width: 10,
    height: 10,
    borderRadius: 5,
    backgroundColor: Colors.brightSkyBlue,
    marginRight: 10,
  },
  draftLabel: {
    fontSize: 12,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginRight: 10,
  },
  reservedLabelContainer: {
    backgroundColor: Colors.cement,
    paddingVertical: 2,
    paddingHorizontal: 10,
    borderRadius: 16,
  },
  reservedLabelText: {
    fontSize: 12,
    color: Colors.white3,
  },
  topDate: {
    fontSize: 12,
    color: Colors.cement,
  },
  topUpdateDate: {
    marginTop: 4,
    fontSize: 12,
    color: Colors.cement,
  },
  topUnreadReply: {
    fontSize: 13,
    color: Colors.brightSkyBlue,
    marginLeft: 10,
    fontWeight: "bold",
  },
  restoreButton: {
    backgroundColor: Colors.lightGrey,
    paddingHorizontal: 8,
    paddingVertical: 4,
    borderRadius: 10,
  },
  restoreButtonText: {
    fontSize: 12,
  },
  restoreDelete: {
    paddingBottom: 17,
  },
  titleContainer: {
    fontSize: 16,
    lineHeight: 22,
    marginTop: 6,
  },
  contactType: {
    fontWeight: "bold",
    color: Colors.orange,
  },
  titleDelimiter: {
    color: Colors.cement,
  },
  title: {
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
  bottomContainer: {
    marginTop: 12,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  senderContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexShrink: 1,
  },
  senderImage: {
    width: 32,
    height: 32,
    borderRadius: 16,
  },
  senderName: {
    fontSize: 13,
    color: Colors.greyshBrown,
    marginLeft: 10,
  },
  swipeableContainer: {
    backgroundColor: Colors.white3,
    borderRadius: 16,
    overflow: "visible",
    width: "100%",
  },
  swipeableContainerWithTimeLimit: {
    marginTop: 10,
  },
  swipeToDeleteContainer: {
    width: 72,
    justifyContent: "center",
    alignItems: "center",
  },
  deleteTrashIconContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    height: 38,
  },
  deleteIconText: {
    color: Colors.red,
  },
  trashIconText: {
    color: Colors.orange,
  },
  removeIconText: {
    color: Colors.red,
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingVertical: 4,
  },
  rowCard: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingVertical: 4,
    marginBottom: 8,
  },
  rowEnd: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-end",
  },
  teamImage: {
    width: 38,
    height: 38,
    borderRadius: 19,
  },
  icon: {
    width: 16,
    height: 16,
    padding: 2,
  },
  text: {
    fontSize: 12,
    fontWeight: "300",
    lineHeight: 17,
  },
  textCard: {
    fontSize: 12,
    fontWeight: "300",
    lineHeight: 17,
    marginLeft: 8,
  },
  messageCardContainer: {
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 15,
    boxShadow: "0 0 1px 0 rgb(0 0 0 / 16%)",
    marginBottom: 10,
  },
  messageContent: {
    flexDirection: "column",
    width: "100%",
    gap: 10,
  },
  messageContentCard: {
    flexDirection: "column",
    width: "100%",
  },
  topArea: {
    width: "100%",
    justifyContent: "space-between",
  },
  senderArea: {
    flexDirection: "row",
    alignItems: "center",
  },
  sender: {
    flexDirection: "column",
    marginLeft: 0,
  },
  name: {
    fontSize: 15,
    fontWeight: "bold",
  },
  datetime: {
    fontSize: 12,
    color: Colors.cement,
  },
  message: {
    display: "flex",
    gap: 10,
    marginLeft: 50,
    fontSize: 14,
  },
  messageCard: {
    display: "flex",
    marginLeft: 55,
    fontSize: 14,
    marginTop: 10,
  },
  unreadMark: {
    width: 25,
    height: 25,
    lineHeight: 25,
    backgroundColor: Colors.brightSkyBlue,
    borderRadius: 12.5,
    textAlign: "center",
  },
  num: {
    color: Colors.white3,
  },
  listMessageContainer: {
    backgroundColor: Colors.duckEggBlue,
    padding: 8,
  },
  applicationContainer: {
    backgroundColor: Colors.duckEggBlue,
    padding: 8,
  },
  header: {
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "600",
    marginVertical: 8,
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  replyUnreadCountContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 24,
    height: 24,
    borderRadius: 12,
    backgroundColor: Colors.brightSkyBlue,
    justifyContent: "center",
    alignItems: "center",
  },
  replyBigUnreadCountContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    borderRadius: 12,
    backgroundColor: Colors.brightSkyBlue,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  replyUnreadCountWrapper: {
    flexDirection: "row",
    alignItems: "center",
  },
  replyUnreadCount: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.white3,
  },
  replyTextContainer: {
    marginLeft: 8,
    flex: 1,
    position: "relative",
  },
  replySenderImage: {
    width: 44,
    height: 44,
    borderRadius: 22,
  },
})
