import { atom, selector } from "recoil"
import { existingContactDetailSelector } from "src/recoil/selectors/contact/create/existingContactDetailSelector"
const initialState = ""
const initialStateSelector = selector({
  key: "atoms/contact/create/sendDate_initialState",
  get: ({ get }) => {
    const contact = get(existingContactDetailSelector)
    return contact?.sentDate ?? initialState
  },
})

export const sendDateState = atom({
  key: "atoms/contact/create/sendDateState",
  default: initialStateSelector,
})
