import { deleteAllTeamNotice } from "src/aws/customAPI"
import { Result } from "src/utils/Result"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
}

type Success = undefined

type Failure = {
  message: string
}

export const deleteNotifications = ({ accessToken }: Params): Promise<Result<Success, Failure>> => {
  return execApi(
    () => deleteAllTeamNotice(accessToken, { appInfo }),
    (_) => undefined,
    {
      identifiers: ["deleteNotifications", "deleteAllTeamNotice"],
    }
  )
}
