import { AppInfo } from "src/types/RequestData"
import { Platform } from "react-native"
import Constants from "expo-constants"

export const appInfo: AppInfo | undefined =
  Platform.OS === "ios" || Platform.OS === "android"
    ? {
        osType: Platform.OS,
        appVersion: Constants.manifest?.version || "",
        // https://reactnative.dev/docs/platform-specific-code#platform-module
        // 例: iOS: 15.3.1, Android: 28
        osVersion: String(Platform.Version),
      }
    : undefined
