import { Result } from "src/utils/Result";
import { createMatchingOffer as _createMatchingOffer, GqlError } from "src/aws/customAPI";
import { MatchingOfferModel } from "../../types/matching/matchingOfferModel";
import { MatchingOfferPurpose } from "../../aws/API";
import { uploadMatchingAttachmentFile, uploadMatchingAttachmentThumbnailFile } from "../file/matchingUploadFile";
import { AttachmentItemProps } from "src/components/parts/contactNetworkTab/ContactDetailEditor"
import { execApi } from "../execApi";
import { appInfo } from "src/constants/AppInfo";

type CreateMatchingOfferParams = {
  accessToken: string
  tempMatchingOfferId: string
  model: MatchingOfferModel
  attachments: File[]
  teamId: string
}

type CreateMatchingOfferSuccess = unknown

type CreateMatchingOfferFailure = {
  message: string
}

export const createMatchingOffer = async ({ model, tempMatchingOfferId, attachments, accessToken, teamId }: CreateMatchingOfferParams):
  Promise<Result<CreateMatchingOfferSuccess, CreateMatchingOfferFailure>> => {
  return await _createMatchingOffer(accessToken, {
    input: {
      teamId: teamId,
      tempMatchingOfferId,
      purpose: MatchingOfferPurpose.jointPracticeGame,
      title: model.title,
      body: model.body,
      date: model.date,
      startTime: model.startTime?.toString().slice(16, 21),
      endTime: model.endTime?.toString().slice(16, 21),
      prefectureId: model.prefectureId,
      cityId: model.cityId,
      place: model.place,
      placeURL: model.placeURL,
      expense: model.expense,
      expensePurpose: model.expensePurpose,
      ageFrom: model.ageFrom,
      ageTo: model.ageTo,
      offerCount: model.offerCount,
      publishEndDate: model.publishEndDate,
      attachmentFileNames: model.attachmentFileNames,
    }
  }).then(res => {
    if (res.data) {
      const offerId = res.data.createMatchingOffer.id
      if (attachments) {
        attachments.map((file, index) => {
          uploadMatchingAttachmentFile({
            type: "matchingOfferAttachmentFile",
            teamId: teamId,
            offerId: offerId,
            index: index,
            fileName: file.name,
            accessToken: accessToken,
            fileData: file,
          });
        });
        attachments.map((file, index) => {
          uploadMatchingAttachmentThumbnailFile({
            type: "matchingOfferAttachmentThumbnail",
            teamId: teamId,
            offerId: offerId,
            index: index,
            fileName: file.name,
            accessToken: accessToken,
            fileData: file,
          });
        });
      }
      return Result.Ok(undefined)
    } else {
      return Result.Error<CreateMatchingOfferFailure>({ message: '募集の登録に失敗しました' })
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || '募集の登録に失敗しました'
    return Result.Error<CreateMatchingOfferFailure>({
      message: message
    })
  })
}