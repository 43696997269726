import { selector } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { RequestData } from "src/types/RequestData"
import { appInfo } from "src/constants/AppInfo"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"

export const requestDataSelector = selector<RequestData | undefined>({
  key: "selectors/auth/requestDataSelector",
  get: ({ get }) => {
    const tokens = get(tokensState)
    if (tokens == null) {
      return
    }
    const { accessToken } = tokens
    const accountId = get(accountIdState)
    if (accountId == null) {
      return
    }

    return {
      accountId,
      accessToken,
      appInfo,
    }
  },
})
