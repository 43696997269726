import { GqlError } from './../../aws/customAPI';
import { cancelPaidOrderVersion } from "src/aws/customAPI"

type Params = {
  orderId: string
  accessToken: string
}

export const cancelOrder = async ({orderId, accessToken}: Params): Promise<{id: string} | any> => {
  return cancelPaidOrderVersion(accessToken, {input: {orderId}}).then(res => {
    if (res.data?.cancelPaidVersionOrder.id) {
      return {id: res.data?.cancelPaidVersionOrder.id}
    } else return ""
  }).catch((e:GqlError) => e)
}