import { deleteAsync } from "./secureStorage/deleteAsync"
import { getAsync } from "./secureStorage/getAsync"
import { setAsync } from "./secureStorage/setAsync"

const key = "session"

export const getSession = async () => {
  const value = await getAsync(key)
  return value ?? undefined
}

export const setSession = (session?: string) => {
  return session == null ? deleteAsync(key) : setAsync(key, session)
}
