import { memo, useCallback, useMemo, useEffect, useState } from "react"
import { StyleSheet, Text, View } from "react-native"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useDeleteAccountData } from "src/recoil/hooks/screens/useDeleteAccountData"
import { RootStackScreenProps } from "src/types.d"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { canDeleteAccountSelector } from "src/recoil/selectors/account/canDeleteAccountSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { accountRequestIdDeleteState } from "src/recoil/atoms/account/accountRequestIdDeleteState"
import { useNavigation } from "@react-navigation/native"
import { CustomAlert } from "src/utils/CustomAlert"

export type DeleteAccountParams = {
  accountId: string
}

const description = `退会すると、所属するすべての団体からの脱退となり、登録済みのすべての情報が削除されます。
一度削除されたデータは元には戻せませんので、ご注意ください。
退会しないときは「キャンセル」を押してください。`

const reasonDescription = `今後のよりよいサイト作りに役立てたいと思いますので、よろしければ退会の理由を詳しくご記入ください。
また、ご意見・ご要望等ございましたら、ご記入お願いいたします。`

type Props = RootStackScreenProps<typeof Screens.DeleteAccount>

export const DeleteAccount = memo<Props>(({ navigation }) => {
  const cancel = useCallback(() => navigation.navigate(Screens.AccountInfo), [navigation])
  const { reason, setReason, execDeleteAccount, isDeletingAccount } = useDeleteAccountData()
  const tailSectionStyle = useMemo(() => [styles.section, styles.tailSection], [])
  const { value: canDeleteAccount, isLoading } = useAsyncSelector(canDeleteAccountSelector)
  const [goBack, setGoBack] = useState(false)
  const refresh = useRefresher(accountRequestIdDeleteState)
  const navigate = useNavigation()

  const showPopup = async () => {
    if (!canDeleteAccount?.canDeleteAccount.canDelete && !isLoading) {
      await CustomAlert.alert("警告", canDeleteAccount?.canDeleteAccount.message)
      setGoBack(true)
    }
  }

  useEffect(() => {
    if (goBack) {
      setReason("")
      navigate.goBack()
      refresh()
      setGoBack(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goBack])

  useEffect(() => {
    showPopup()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canDeleteAccount?.canDeleteAccount.canDelete])

  return (
    <CustomKeyboardAvoidingView>
      <CustomScrollView>
        <View style={styles.section}>
          <ItemLabel label="退会" />
          <Text style={styles.title}>らくらく連絡網+を退会します</Text>
        </View>
        <View style={tailSectionStyle}>
          <Text style={styles.warning}>退会に関するご注意！</Text>
          <Text style={styles.description}>{description}</Text>
          <View style={styles.topActionContainer}>
            <TextButton title="キャンセル" buttonType={ButtonType.Secondary} onPress={cancel} isLoading={isDeletingAccount} />
          </View>
        </View>
        <View style={tailSectionStyle}>
          <Text style={styles.reason}>退会の理由</Text>
        </View>
        <View style={tailSectionStyle}>
          <Text>{reasonDescription}</Text>
          <View style={styles.inputLengthCountContainer}>
            <InputLengthCounter text={reason} maxLength={500} unit={"字以内"} />
          </View>
          <MultilineTextInput
            value={reason}
            onChangeText={setReason}
            style={[styles.reasonInput, { height: 240 }]}
            editable={!isDeletingAccount}
            maxLength={500}
          />
          <TextButton title="キャンセル" buttonType={ButtonType.Secondary} onPress={cancel} isLoading={isDeletingAccount} />
          <View style={styles.secondaryContainer}>
            <TextButton
              title="退会する"
              buttonType={ButtonType.Primary}
              onPress={execDeleteAccount}
              isLoading={isDeletingAccount || isLoading}
              disabled={!canDeleteAccount?.canDeleteAccount.canDelete}
            />
          </View>
        </View>
        <View style={tailSectionStyle}>
          <Text style={styles.finalDescription}>{description}</Text>
        </View>
      </CustomScrollView>
    </CustomKeyboardAvoidingView>
  )
})

export const options: ScreenOptions = {
  ...commonHeaderOptions,
  title: "会員の退会",
}

const styles = StyleSheet.create({
  section: {
    padding: 16,
  },
  tailSection: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  title: {
    marginTop: 8,
  },
  warning: {
    fontWeight: "bold",
    color: Colors.red,
  },
  description: {
    marginTop: 8,
  },
  topActionContainer: {
    paddingTop: 16,
  },
  inputLengthCountContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  reason: {
    fontWeight: "bold",
  },
  reasonInput: {
    marginVertical: 16,
  },
  finalDescription: {
    color: Colors.red,
  },
  secondaryContainer: {
    paddingTop: 16,
  },
})
