import { atom } from "recoil"

export type OrderDetail = {
  id: string
  orderNumber: string
  originalOrderId?: string | null
  orderStatus: string
  jobId?: string | null
  masterCode?: string | null
  zacStatus?: string | null
  supportedDate?: string | null
  address?: string | null
  orderType: string
  companyName?: string | null
  companyNameKana?: string | null
  corporateRepresentativeName?: string | null
  corporateRepresentativeNameKana?: string | null
  applicantPhone?: string | null
  applicantMail?: string | null
  applicantName?: string | null
  applicantPostcode?: string | null
  applicantPrefecture?: string | null
  applicantAddress?: string | null
  applicantBuildingName?: string | null
  applicantRemark?: string | null
  antiSocialCheck: string
  teamId?: string | null
  teamName?: string | null
  applicantId?: string | null
  transferApplicantId?: string | null
  planId: string
  startDate: string
  endDate: string
  paymentStatus: string
  paymentDate?: string | null
  paymentDeadline?: string | null
  invoiceIssuedDate?: string | null
  paymentType: string
  paymentMethod: string
  invoiceAddressee?: string | null
  invoiceAddresseeKana?: string | null
  amount: number
  taxRate: number
  tax: number
  subTotal: number
  remark?: string | null
  adminId?: string | null
  createdAt?: string | null
  updatedAt?: string | null
}

export const defaultValue = {
  id: "",
  orderNumber: "",
  originalOrderId: undefined,
  orderStatus: "",
  jobId: undefined,
  masterCode: undefined,
  zacStatus: undefined,
  supportedDate: undefined,
  address: undefined,
  orderType: "",
  companyName: undefined,
  companyNameKana: undefined,
  corporateRepresentativeName: undefined,
  corporateRepresentativeNameKana: undefined,
  applicantPhone: undefined,
  applicantMail: undefined,
  applicantName: undefined,
  applicantPostcode: undefined,
  applicantPrefecture: undefined,
  applicantAddress: undefined,
  applicantBuildingName: undefined,
  applicantRemark: undefined,
  antiSocialCheck: "",
  teamId: undefined,
  teamName: undefined,
  applicantId: undefined,
  transferApplicantId: undefined,
  planId: "",
  startDate: "",
  endDate: "",
  paymentStatus: "",
  paymentDate: undefined,
  paymentDeadline: undefined,
  invoiceIssuedDate: undefined,
  paymentType: "",
  paymentMethod: "",
  invoiceAddressee: undefined,
  invoiceAddresseeKana: undefined,
  amount: 0,
  taxRate: 0,
  tax: 0,
  subTotal: 0,
  remark: undefined,
  adminId: undefined,
  createdAt: undefined,
  updatedAt: undefined,
}

export const getOrderDetailState = atom<OrderDetail>({
  key: "atoms/paidTeam/getOrderDetailState",
  default: defaultValue,
})
