import { FC } from "react"
import { Path, Svg } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  size?: number
  fillColor?: string
}

export const CopyClipboardIcon: FC<Props> = ({ size = 16, fillColor = Colors.orange }) => (
  <Svg height={size} viewBox="0 0 24 24" width={size} fill={fillColor}>
    <Path d="M0 0h24v24H0V0z" fill="none" />
    <Path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
  </Svg>
)

CopyClipboardIcon.displayName = "CopyClipboardIcon"
