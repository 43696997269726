import {
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItem,
  createDrawerNavigator,
} from "@react-navigation/drawer"
import { Image, Linking, Pressable, Text, View } from "react-native"
import { HomeIcon } from "src/components/parts/icons/bottomTab/HomeIcon"
import { RootDrawer } from "src/constants/RootDrawer"
import { RootTabs } from "src/constants/RootTabs"
import { HomeTab } from "src/screens/bottomTabs/HomeTab"
import { RootDrawerParamList } from "src/types.d"
import { styles } from "./styles"

import { useMemo } from "react"
import { useRecoilValue, useSetRecoilState } from "recoil"
import { Button } from "src/components/parts/buttons/Button"
import { DrawerLabel } from "src/components/parts/contactNetworkTab/DrawerLabel"
import { BadgeIcon } from "src/components/parts/icons/BadgeIcon"
import { OrganizationIcon } from "src/components/parts/icons/bottomTab/OrganizationIcon"
import { SettingsIcon } from "src/components/parts/icons/bottomTab/SettingsIcon"
import { Colors } from "src/constants/Colors"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { Screens } from "src/constants/Screens"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { routeNameState } from "src/recoil/atoms/routeNameState"
import { contacGroupState } from "src/recoil/atoms/sidebar/contactGroupState"
import { matchingGroupState } from "src/recoil/atoms/sidebar/matchingGroupState"
import { matchingSubGroupState } from "src/recoil/atoms/sidebar/matchingSubGroupState"
import { useAuthorization } from "src/recoil/hooks/authorization/useAuthorization"
import { hasOrganizationNoticeSelector } from "src/recoil/selectors/bottomTab/hasOrganizationNoticeSelector"
import { hasServiceInformationSelector } from "src/recoil/selectors/bottomTab/hasServiceInformationSelector"
import { unreadContactCountSelector } from "src/recoil/selectors/bottomTab/unreadContactCountSelector"
import { myFullOrganizationsSelector } from "src/recoil/selectors/organization/myOrganizationsSelector"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { ContactNetworkDrawer } from "../ContactNetworkDrawer"
import { ContactSideMenu } from "../ContactNetworkDrawer/ContactSideMenu"
import CustomDrawerItemList from "../CustomDrawerItemList"
import { MatchingDrawer } from "../MatchingDrawer"
import { MatchingSideMenu } from "../MatchingDrawer/MatchingSideMenu"
import OrganizationManagerNavigator from "./OrganizationManagerNavigator"
import SettingNavigator from "./SettingNavigator"
import { TeamSelect } from "./TeamSelect"
import { completedSignUpFlagState } from "src/recoil/atoms/account/completedSignUpFlagState"

type DrawrContentProps = DrawerContentComponentProps & {
  allowTeamMatching?: boolean
  matchableFlg?: boolean
}

const CustomDrawerContent = (props: DrawrContentProps) => {
  const { value: organizations, isLoading: isLoaddingFullOrg } = useAsyncSelector(myFullOrganizationsSelector)
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const setSelectedOrganization = useSetRecoilState(selectedMyOrganizationState)
  const setShowContactGroup = useSetRecoilState(contacGroupState)
  const setShowMatchingGroup = useSetRecoilState(matchingGroupState)
  const setShowMatchingSubGroup = useSetRecoilState(matchingSubGroupState)
  const { value: _unreadCount } = useAsyncSelector(unreadContactCountSelector)
  const { value: hasOrganizationNotice } = useAsyncSelector(hasOrganizationNoticeSelector)
  const { value: hasServiceInformation } = useAsyncSelector(hasServiceInformationSelector)
  const routeName = useRecoilValue(routeNameState)

  const isMatchingFeature = props.state.routes[props.state.index]?.name.startsWith("matching")
  const isOrderDetail = routeName === "OrderDetail"
  const isCompletedPaid = routeName === "CompletedApplyPaid"
  const isOrganizationPath = useMemo(
    () => Object.values(OrganizationManagerScreens).some((name) => name === routeName),
    [routeName]
  )
  const isSettingPath = useMemo(
    () =>
      [
        RootTabs.SettingsTab,
        Screens.Information,
        Screens.InformationDetail,
        Screens.AccountInfo,
        Screens.EditAccount,
        Screens.NotificationSettings,
      ].some((name) => name === routeName),
    [routeName]
  )

  return (
    <DrawerContentScrollView {...props}>
      <Button onPress={() => props.navigation.navigate(RootTabs.HomeTab)}>
        <View style={styles.logoContainer}>
          <Image style={styles.logo} source={require("src/assets/images/ra9ra9Logo.svg")} />
        </View>
      </Button>

      <CustomDrawerItemList {...props} filterRoutes={[RootDrawer.HomeTab]} />

      <View style={styles.teamContainer}>
        <View style={styles.labelContainer}>
          <TeamSelect
            isLoading={isLoaddingFullOrg}
            isDisabled={isMatchingFeature || isOrderDetail || isCompletedPaid}
            options={
              organizations?.map((org) => {
                return {
                  value: org.id,
                  label: org.title,
                  teamId: org.id,
                  imgUrl: org.uriImage,
                  count: _unreadCount ? _unreadCount[org.id] : 0,
                }
              }) ?? []
            }
            value={selectedOrganization?.id}
            onChange={(val) => {
              setSelectedOrganization(val)
              setShowContactGroup(false)
              setShowMatchingGroup(false)
              setShowMatchingSubGroup(false)
            }}
            placeholder="団体を選択する"
          />
        </View>

        <ContactSideMenu {...props} />
        <View style={styles.pcGroupContainer}>
          <Pressable
            style={({ pressed }) => [
              {
                backgroundColor: pressed || isOrganizationPath ? Colors.pale : Colors.white3,
              },
              styles.controlButton,
            ]}
            onPress={() =>
              props.navigation.navigate(RootTabs.OrganizationManagerTab, {
                screen: OrganizationManagerScreens.OrganizationManager,
              })
            }
          >
            <View style={styles.pcHeader}>
              <OrganizationIcon filled={isOrganizationPath} color={isOrganizationPath ? Colors.orange : Colors.gray} />
              <Text style={styles.pcHeaderTitle}>団体管理</Text>
              {!!hasOrganizationNotice && <BadgeIcon size={"small"} />}
            </View>
          </Pressable>
        </View>
        {props.allowTeamMatching && props.matchableFlg && <MatchingSideMenu {...props} isPCScreen={true} />}
      </View>

      <View style={styles.pcGroupContainer}>
        <Pressable
          style={({ pressed }) => [
            {
              backgroundColor: pressed || isSettingPath ? Colors.pale : Colors.white3,
            },
            styles.controlButton,
          ]}
          onPress={() => props.navigation.navigate(RootTabs.SettingsTab, { screen: RootTabs.SettingsTab })}
        >
          <View style={styles.settingPcHeader}>
            <SettingsIcon filled={isSettingPath} color={isSettingPath ? Colors.orange : Colors.gray} />
            <Text style={styles.pcHeaderTitle}>設定</Text>
            {!!hasServiceInformation && <BadgeIcon size={"small"} />}
          </View>
        </Pressable>
      </View>

      <View style={styles.links}>
        <DrawerItem
          style={styles.link}
          labelStyle={styles.linkLabel}
          label="利用規約"
          onPress={() => Linking.openURL("https://www.ra9plus.jp/rule/")}
        />
        <DrawerItem
          style={styles.link}
          labelStyle={styles.linkLabel}
          label="個人情報の取り扱いについて"
          onPress={() => Linking.openURL("https://www.ra9plus.jp/privacypolicy")}
        />
        <DrawerItem
          style={styles.link}
          labelStyle={styles.linkLabel}
          label="会社概要"
          onPress={() => Linking.openURL("https://www.eole.co.jp/corporate/overview.html")}
        />
      </View>
    </DrawerContentScrollView>
  )
}

const Drawer = createDrawerNavigator<RootDrawerParamList>()

interface OwnProps {
  sendAnalyticsEvent: (screen: string) => void
}

export const RootDrawerNavigator = ({ sendAnalyticsEvent }: OwnProps) => {
  const { value: hasOrganizationNotice } = useAsyncSelector(hasOrganizationNoticeSelector)
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const { allowTeamMatching } = useAuthorization({
    organizationId: selectedOrganization?.id,
  })

  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const { value: organizationDetail } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))
  const matchableFlg = useMemo(() => organizationDetail?.category.label === "スポーツ系サークル・チーム", [organizationDetail])

  const completedSignUpState = useRecoilValue(completedSignUpFlagState)

  return (
    <Drawer.Navigator
      initialRouteName={completedSignUpState ? RootDrawer.OrganizationManagerTab : RootDrawer.HomeTab}
      screenOptions={{
        drawerType: "permanent",
        overlayColor: "transparent",
        // @ts-ignore
        drawerStyle: {
          width: 280,
          paddingHorizontal: 1,
          height: "100vh",
        },
      }}
      drawerContent={(props) => (
        <CustomDrawerContent {...props} matchableFlg={matchableFlg} allowTeamMatching={allowTeamMatching} />
      )}
    >
      <Drawer.Screen
        name={RootDrawer.HomeTab}
        component={HomeTab}
        options={{
          title: "ホーム",
          headerShown: false,
          drawerIcon: ({ focused, color }) => (
            <View style={styles.iconContainer}>
              <HomeIcon filled={focused} color={color} />
            </View>
          ),
          drawerLabel: () => <DrawerLabel title={"ホーム"} showBadge={!!hasOrganizationNotice} />,
        }}
        listeners={{
          blur: () => sendAnalyticsEvent(RootTabs.HomeTab),
        }}
      />

      <Drawer.Screen
        name={RootTabs.ContactNetworkDrawer}
        component={ContactNetworkDrawer}
        options={{
          title: "連絡網",
          headerShown: false,
        }}
        listeners={{
          blur: () => sendAnalyticsEvent(RootTabs.ContactNetworkDrawer),
        }}
      />

      <Drawer.Screen
        name={RootDrawer.OrganizationManagerTab}
        component={OrganizationManagerNavigator}
        options={{
          title: "団体管理",
          headerShown: false,
        }}
        listeners={{
          blur: () => sendAnalyticsEvent(RootTabs.OrganizationManagerTab),
        }}
      />

      <Drawer.Screen
        name={RootDrawer.MatchingDrawer}
        component={MatchingDrawer}
        options={{ headerShown: false, title: "団体マッチング" }}
      />

      <Drawer.Screen
        name={RootTabs.SettingsTab}
        component={SettingNavigator}
        options={{
          title: "設定",
          headerShown: false,
        }}
        listeners={{
          blur: () => sendAnalyticsEvent(RootTabs.SettingsTab),
        }}
      />
    </Drawer.Navigator>
  )
}
