import { GqlError, listFavoriteMatchingOffer } from "src/aws/customAPI"
import { MatchingOffersModel } from "src/types/matching/matchingOfferModel"
import { MatchingPurposeMapping } from "src/types/matching/matchingPurpose"
import { Result } from "src/utils/Result"

type GetFavoriteMatchingOfferParams = {
  accessToken: string
  teamMemberId: string
  search: string
  nextToken?: string
}

export type GetFavoriteMatchingOfferSuccess = {
  data: MatchingOffersModel[]
  nextToken?: string | null 
}

type GetFavoriteMatchingOfferFailure = {
  message: string
}

export const getFavoriteMatchingOffers = async({accessToken, teamMemberId, search, nextToken}: GetFavoriteMatchingOfferParams): Promise<Result<GetFavoriteMatchingOfferSuccess, GetFavoriteMatchingOfferFailure>> => {
  return await listFavoriteMatchingOffer(accessToken, {
    teamMemberId,
    search,
    nextToken
  }).then(res => {
    if (res.data) {
      const {items, nextToken} = res.data.getTeamMember.favoriteMatchingOffer
      const data = items.map(item => ({
        id: item.offer.id,
        purpose: MatchingPurposeMapping["jointPracticeGame"],
        title: item.offer.title,
        date: item.offer.date,
        startTime: item.offer.startTime,
        endTime: item.offer.endTime,
        prefecture: item.offer.prefecture,
        city: item.offer.city,
        ageFrom: item.offer.ageFrom,
        ageTo: item.offer.ageTo,
        publishEndDate: item.offer.publishEndDate,
        activity: item.offer.activity,
        teamId: item.offer.teamId,
        teamName: item.offer.teamName,
        deleteFlg: item.offer.deleteFlg,
        favoriteFlg: item.offer.favoriteFlg,
        createdAt: item.offer.createdAt,
        updatedAt: item.offer.updatedAt,
      }));
      return Result.Ok<GetFavoriteMatchingOfferSuccess>({
        data,
        nextToken
      })
    } else {
      return Result.Error<GetFavoriteMatchingOfferFailure>({ message: "お気に入りの取得に失敗しました" });
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "お気に入りの取得に失敗しました";
    return Result.Error<GetFavoriteMatchingOfferFailure>({
      message: message
    });
  });
}