import dayjs from "dayjs"
import { memo, useCallback, useEffect } from "react"
import { Platform, ScrollView, StyleSheet, Text, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { ButtonBackIcon } from "src/components/parts/buttons/ButtonBackIcon"
import { EditButtonForHeader } from "src/components/parts/buttons/EditButtonForHeader"
import { SmallButton } from "src/components/parts/buttons/SmallButton"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { GenderLabel } from "src/constants/Gender"
import { commonHeaderOptionsOnNestedScreen } from "src/constants/options/commonHeaderOptions"
import { Screens } from "src/constants/Screens"
import { RootTabs } from "src/constants/RootTabs"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useAccountInfoData } from "src/recoil/hooks/screens/useAccountInfoData"
import { RootStackScreenProps } from "src/types.d"

export type AccountInfoParams = undefined

type Props = RootStackScreenProps<"AccountInfo">

export const AccountInfo = memo<Props>(({ navigation }) => {
  const {
    account,
    imageUrl,
    refreshImageUrl,
    jobName,
    schoolName,
    prefectureName,
    schoolDepartmentName,
    cityName,
    accountQuestions,
  } = useAccountInfoData()

  const isLargeScreen = useCheckPCScreen()

  useEffect(() => {
    refreshImageUrl()
    if (Platform.OS === "web") {
      navigation.setOptions({
        headerLeft: () => (
          <ButtonBackIcon
            onPress={() => {
              navigation.navigate(Screens.Root, {
                screen: RootTabs.SettingsTab,
                params: {
                  screen: RootTabs.SettingsTab,
                },
              })
            }}
          />
        ),
      })
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigation])

  const gotoEditEmail = useCallback(() => {
    if (account == null) {
      return
    }
    navigation.navigate(Screens.EditEmail, { currentEmail: account.email })
  }, [account, navigation])

  const gotoEditOrRegisterPhoneNumber = useCallback(() => {
    if (account == null) {
      return
    }
    navigation.navigate(Screens.EditOrRegisterPhoneNumber, { currentPhoneNumber: account.phoneNumber })
  }, [account, navigation])

  const gotoDeleteAccount = useCallback(() => {
    if (account == null) {
      return
    }
    navigation.navigate(Screens.DeleteAccount, { accountId: account.id })
  }, [navigation, account])

  const safeareaInsets = useSafeAreaInsets()

  if (account == null) {
    return null
  }

  return (
    <ScrollView style={styles.container} contentContainerStyle={{ paddingTop: 16, paddingBottom: safeareaInsets.bottom + 16 }}>
      <View style={isLargeScreen ? { display: "flex", alignItems: "center" } : {}}>
        <View style={isLargeScreen ? styles.threeColumn : {}}>
          <View style={styles.topContainer}>
            <CircleThumbnail source={imageUrl ? { uri: imageUrl } : defaultThumbnail} onError={refreshImageUrl} />
            <Text style={styles.userName}>
              {account.lastName} {account.firstName}
            </Text>
            <Text style={styles.userNameKana}>
              {account.kanaLastName} {account.kanaFirstName}
            </Text>
          </View>
          <View style={[styles.rowHead, styles.row]}>
            <ItemLabel label="メールアドレス" />
            <Text style={styles.rowTitle} numberOfLines={10}>
              {account.email}
            </Text>
          </View>
          {account.phoneNumber != null ? (
            <View style={styles.row}>
              <ItemLabel label="携帯電話番号" />
              <Text style={styles.rowTitle}>{account.phoneNumber}</Text>
            </View>
          ) : null}
          {account.birthDate != null ? (
            <View style={styles.row}>
              <ItemLabel label="生年月日" />
              <Text style={styles.rowTitle}>{dayjs(account.birthDate).format("YYYY年MM月DD日")}</Text>
            </View>
          ) : null}
          <View style={styles.row}>
            <ItemLabel label="性別" />
            <Text style={styles.rowTitle}>{account.gender ? GenderLabel[account.gender] : GenderLabel.NoAnswer}</Text>
          </View>
          {jobName != null ? (
            <View style={styles.row}>
              <ItemLabel label="職業" />
              <Text style={styles.rowTitle}>{jobName}</Text>
            </View>
          ) : null}
          {schoolName != null ? (
            <View style={styles.row}>
              <ItemLabel label="学校名" />
              <Text style={styles.rowTitle}>{schoolName}</Text>
            </View>
          ) : null}
          {schoolDepartmentName != null ? (
            <View style={styles.row}>
              <ItemLabel label="学部" />
              <Text style={styles.rowTitle}>{schoolDepartmentName}</Text>
            </View>
          ) : null}
          {account.graduationYear != null ? (
            <View style={styles.row}>
              <ItemLabel label="卒業予定年" />
              <Text style={styles.rowTitle}>{`${account.graduationYear}年`}</Text>
            </View>
          ) : null}
          {prefectureName != null ? (
            <View style={styles.row}>
              <ItemLabel label="居住地（都道府県）" />
              <Text style={styles.rowTitle}>{prefectureName}</Text>
            </View>
          ) : null}
          {cityName != null ? (
            <View style={styles.row}>
              <ItemLabel label="居住地（市区町村）" />
              <Text style={styles.rowTitle}>{cityName}</Text>
            </View>
          ) : null}
          {account.questionAnswers != null && accountQuestions != null ? (
            <>
              <View style={styles.questionAnswer}>
                <ItemLabel label="アンケート" />
              </View>
              {Object.entries(account.questionAnswers).map(([questionId, choiceId], i) => {
                const question = accountQuestions.find((q) => q.id === questionId)
                const choice = question?.choices.find((c) => c.id === choiceId)
                return (
                  question &&
                  choice && (
                    <View style={styles.row} key={i}>
                      <ItemLabel label={question.label} type={"question"} />
                      <Text style={styles.rowTitle}>{choice.label}</Text>
                    </View>
                  )
                )
              })}
            </>
          ) : null}
          <View style={[styles.actions, isLargeScreen ? { display: "flex", alignItems: "center" } : {}]}>
            <TextButton
              buttonType={ButtonType.Primary}
              title="メールアドレスの変更"
              onPress={gotoEditEmail}
              style={isLargeScreen ? styles.buttonsPC : {}}
            />
            <TextButton
              buttonType={ButtonType.Secondary}
              title={`携帯電話番号の${account.phoneNumber == null ? "登録" : "変更"}`}
              onPress={gotoEditOrRegisterPhoneNumber}
              style={[styles.secondaryButton, isLargeScreen ? styles.buttonsPC : {}]}
            />
          </View>
          <View style={styles.bottom}>
            <SmallButton onPress={gotoDeleteAccount}>
              <RemoveIcon />
              <Text style={styles.deleteAccountText}>会員の退会</Text>
            </SmallButton>
          </View>
        </View>
      </View>
    </ScrollView>
  )
})

export const options: ScreenOptions = ({ navigation }) => ({
  ...commonHeaderOptionsOnNestedScreen,
  headerLeft: () => <ButtonBackIcon onPress={() => navigation.navigate(RootTabs.SettingsTab as any)} />,
  title: "アカウント情報",
  headerRight: () => <EditButtonForHeader onPress={() => navigation.navigate(Screens.EditAccount)} />,
})

const styles = StyleSheet.create({
  threeColumn: {
    width: "60%",
    maxWidth: 1020,
    paddingLeft: 30,
    paddingRight: 30,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
  },
  container: {
    backgroundColor: Colors.white,
  },
  topContainer: {
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 16,
    paddingBottom: 32,
  },
  userName: {
    fontSize: 24,
    fontWeight: "bold",
    marginTop: 16,
  },
  userNameKana: {
    fontSize: 16,
    marginTop: 4,
  },
  questionAnswer: {
    paddingTop: 16,
    paddingLeft: 16,
  },
  rowHead: {
    borderTopWidth: 1,
  },
  row: {
    borderColor: Colors.lightGrey,
    borderBottomWidth: 1,
    paddingVertical: 18,
    paddingHorizontal: 16,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  rowTitle: {
    marginLeft: 16,
    fontSize: 18,
    flexShrink: 1,
  },
  rowInnerErrorRight: {
    paddingVertical: 8,
  },
  deleteAccountText: {
    marginLeft: 8,
    fontSize: 16,
    color: Colors.black,
  },
  bottom: {
    flexDirection: "row",
    justifyContent: "center",
  },
  deleteAccountButton: {
    flexDirection: "row",
    alignItems: "center",
  },
  actions: {
    paddingVertical: 32,
    paddingHorizontal: 32,
  },
  secondaryButton: {
    marginTop: 16,
  },
})
