import { useCallback, useMemo } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { matchingOtherProfileSelectorFamily } from "src/recoil/selectors/matching/matchingOtherProfileSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { useRequestData } from "../useRequestData"
import { useFetcher } from "src/hooks/useFetcher"
import { CustomAlert } from "src/utils/CustomAlert"
import { useRefresher } from "src/hooks/useRefresher"
import { matchingOtherProfileRequestIdState } from "src/recoil/atoms/matching/matchingOtherProfileRequestIdState"
import { blockMatchingTeam } from "src/apis/matching/blockMatchingTeam"
import { deleteBlockingMatchingTeam } from "src/apis/matching/deleteBlockingMatchingTeam"

export const useMatchingMyProfileData = (id: string | undefined) => {
  const { value: selectedMyTeam } = useAsyncSelector(selectedMyOrganizationSelector)
  const teamId = useMemo(() => selectedMyTeam?.id, [selectedMyTeam])
  const { value: matchingMyProfile } = useAsyncSelector(
    matchingOtherProfileSelectorFamily({ otherTeamId: id ?? teamId, myTeamId: teamId })
  )
  const refreshMatchingOtherProfile = useRefresher(matchingOtherProfileRequestIdState)
  const requestDataResult = useRequestData()

  const { fetch: execBlockMatchingTeam } = useFetcher(
    useCallback(async () => {
      const isConfirmed = await CustomAlert.confirm("確認", "本当にこの団体をブロックしますか？")
      if (!isConfirmed) return
      if (!requestDataResult.isOk) return
      const { accessToken } = requestDataResult.content
      if (accessToken == null || teamId == null || id == null) return
      const result = await blockMatchingTeam({
        accessToken,
        myTeamId: teamId,
        blockingTeamId: id,
      })
      if (!result.isOk) await CustomAlert.alert("エラー", result.error.message)
      refreshMatchingOtherProfile()
    }, [requestDataResult, teamId, id, refreshMatchingOtherProfile])
  )

  const { fetch: execDeleteBlockingMatchingTeam } = useFetcher(
    useCallback(async () => {
      if (!requestDataResult.isOk) return
      const { accessToken } = requestDataResult.content
      if (accessToken == null || teamId == null || id == null) return
      const result = await deleteBlockingMatchingTeam({
        accessToken,
        myTeamId: teamId,
        blockingTeamId: id,
      })
      if (!result.isOk) await CustomAlert.alert("エラー", result.error.message)
      refreshMatchingOtherProfile()
    }, [requestDataResult, teamId, id, refreshMatchingOtherProfile])
  )

  return {
    selectedMyTeam,
    matchingMyProfile,
    execBlockMatchingTeam,
    execDeleteBlockingMatchingTeam,
    refreshMatchingOtherProfile,
  }
}
