import { useEffect, useRef } from "react"

export const useInterval = (callback: () => void, timeout: number) => {
  const callbackRef = useRef(callback)
  const timeoutRef = useRef(timeout)

  useEffect(() => {
    const id = window.setInterval(callbackRef.current, timeoutRef.current)
    return () => {
      clearInterval(id)
    }
  }, [])
}
