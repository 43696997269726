import { ValueOf } from "src/types.d"

export const RootTabs = Object.freeze({
  HomeTab: "HomeTab",
  ContactNetworkDrawer: "ContactNetworkDrawer",
  MatchingDrawer: "matching",
  OrganizationManagerTab: "OrganizationManagerTab",
  SettingsTab: "SettingsTab",
} as const)

export type RootTabs = ValueOf<typeof RootTabs>
