import { ComponentProps, memo, PropsWithChildren } from "react"
import { StyleSheet, Text, View, Switch, Platform } from "react-native"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { Colors } from "src/constants/Colors"
import { useOrganizationNotificationSettingData } from "./useOrganizationNotificationSettingData"
import { ListNotificationSettingsSuccess } from "src/apis/settings/listNotificationSettings"
import { MemberType } from "src/types/organization/OrganizationMember"
import { convertMemberRole } from "src/apis/organization/updateMemberDetail"

type Props = {
  organization: ListNotificationSettingsSuccess
}

const Card = memo<PropsWithChildren>(({ children }) => {
  return <View style={cardStyles.card}>{children}</View>
})

const cardStyles = StyleSheet.create({
  card: {
    borderWidth: 1,
    borderColor: Colors.white2,
    borderRadius: 10,
    paddingLeft: 16,
    marginTop: 8,
    marginBottom: 16,
  },
})

const trackColor = { true: Colors.orange, false: Colors.white6 }
const thumbColor = Colors.white3

const CustomSwitch = memo<Pick<ComponentProps<typeof Switch>, "value" | "onValueChange" | "disabled">>((props) => (
  <Switch
    {...props}
    thumbColor={thumbColor}
    trackColor={trackColor}
    {...Platform.select({ web: { activeThumbColor: thumbColor } })}
  />
))

export const OrganizationNotificationSetting = memo(({ organization }: Props) => {
  const {
    isNewContactPushEnabled,
    isNewContactEmailEnabled,
    isReplyPushEnabled,
    isReplyEmailEnabled,
    isNewMemberPushEnabled,
    isNewMemberEmailEnabled,
    isLoading,
    updateNewContactPush,
    updateNewContactEmail,
    updateReplyPush,
    updateReplyEmail,
    updateNewMemberPush,
    updateNewMemberEmail,
    isUpdatingNewContactPush,
    isUpdatingNewContactEmail,
    isUpdatingReplyPush,
    isUpdatingReplyEmail,
    isUpdatingNewMemberPush,
    isUpdatingNewMemberEmail,
    showPushSetting,
  } = useOrganizationNotificationSettingData(organization.teamId)

  if (isLoading) {
    return (
      <View style={styles.loadingContainer}>
        <LoadingIndicator />
      </View>
    )
  }

  return (
    <View style={styles.container}>
      <ItemLabel label="新規連絡" />
      <Card>
        {showPushSetting ? (
          <View style={[styles.cardItem, styles.cardFirstItem]}>
            <Text style={styles.label}>プッシュ通知</Text>
            <CustomSwitch
              value={isNewContactPushEnabled}
              onValueChange={updateNewContactPush}
              disabled={isUpdatingNewContactPush}
            />
          </View>
        ) : null}
        <View style={styles.cardItem}>
          <Text style={styles.label}>メール通知</Text>
          <CustomSwitch
            value={isNewContactEmailEnabled}
            onValueChange={updateNewContactEmail}
            disabled={isUpdatingNewContactEmail}
          />
        </View>
      </Card>
      <ItemLabel label="返信" />
      <Card>
        {showPushSetting ? (
          <View style={[styles.cardItem, styles.cardFirstItem]}>
            <Text style={styles.label}>プッシュ通知</Text>
            <CustomSwitch value={isReplyPushEnabled} onValueChange={updateReplyPush} disabled={isUpdatingReplyPush} />
          </View>
        ) : null}
        <View style={styles.cardItem}>
          <Text style={styles.label}>メール通知</Text>
          <CustomSwitch value={isReplyEmailEnabled} onValueChange={updateReplyEmail} disabled={isUpdatingReplyEmail} />
        </View>
      </Card>
      {organization?.role === convertMemberRole(MemberType.Representative) ? (
        <>
          <ItemLabel label="新規メンバー参加通知" />
          <Card>
            {showPushSetting ? (
              <View style={[styles.cardItem, styles.cardFirstItem]}>
                <Text style={styles.label}>プッシュ通知</Text>
                <CustomSwitch
                  value={isNewMemberPushEnabled}
                  onValueChange={updateNewMemberPush}
                  disabled={isUpdatingNewMemberPush}
                />
              </View>
            ) : null}
            <View style={styles.cardItem}>
              <Text style={styles.label}>メール通知</Text>
              <CustomSwitch
                value={isNewMemberEmailEnabled}
                onValueChange={updateNewMemberEmail}
                disabled={isUpdatingNewMemberEmail}
              />
            </View>
          </Card>
        </>
      ) : null}
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
  },
  loadingContainer: {
    padding: 16,
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: 16,
    color: Colors.greyshBrown,
  },
  cardItem: {
    paddingRight: 16,
    paddingVertical: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardFirstItem: {
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
})
