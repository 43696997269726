import { CustomAlert } from "./CustomAlert"

type AuthError = {
  errors: {
    errorInfo: {
      userMessage: string
    }
  }[]
}

const assertAuthError = (data: unknown): data is AuthError => {
  if (typeof data !== "object" || data == null) {
    return false
  }

  return Object.keys(data).includes("errors")
}

export const handleAuthError = (error: unknown) => {
  let isHandled = false
  if (assertAuthError(error)) {
    error.errors.forEach((error) => {
      CustomAlert.alert("エラー", error.errorInfo.userMessage)
      isHandled = true
    })
  }
  return isHandled
}
