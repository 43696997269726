import { memo } from "react"
import { Svg, Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
}

export const TagIcon = memo<Props>(({ fill = Colors.orange }) => {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24">
      <Path
        d="M13.1 17.388L16.5464 13.9282C16.7321 13.743 16.8646 13.5398 16.9438 13.3185C17.0229 13.0972 17.0625 12.8673 17.0625 12.6287C17.0625 12.1056 16.8787 11.6597 16.511 11.291C16.1433 10.9223 15.698 10.738 15.175 10.738C14.8435 10.738 14.5119 10.8463 14.1803 11.063C13.8487 11.2796 13.49 11.6296 13.104 12.113C12.7097 11.638 12.3458 11.29 12.0125 11.0692C11.6792 10.8484 11.3458 10.738 11.0125 10.738C10.4895 10.738 10.0442 10.9222 9.67654 11.2906C9.30885 11.6591 9.12501 12.1053 9.12501 12.6294C9.12501 12.8684 9.1646 13.0984 9.24376 13.3192C9.32293 13.54 9.45509 13.743 9.64024 13.9282L13.1 17.388ZM14.2642 21.8146C13.8783 22.2005 13.4173 22.3934 12.8813 22.3934C12.3452 22.3934 11.8842 22.2005 11.4984 21.8146L2.20064 12.504C1.99299 12.3001 1.8484 12.0817 1.76686 11.8488C1.68533 11.6159 1.64456 11.3706 1.64456 11.113V3.56296C1.64456 3.01493 1.82721 2.5559 2.19251 2.18588C2.55781 1.81587 3.01448 1.63086 3.56251 1.63086H11.1125C11.374 1.63086 11.6279 1.67208 11.8741 1.75451C12.1204 1.83694 12.3469 1.98151 12.5536 2.18821L21.8142 11.4222C22.2167 11.8247 22.418 12.2985 22.418 12.8434C22.418 13.3883 22.2167 13.8621 21.8142 14.2646L14.2642 21.8146ZM6.16876 7.40046C6.51043 7.40046 6.8073 7.27515 7.05939 7.02453C7.31147 6.77392 7.43751 6.47913 7.43751 6.14016C7.43751 5.80119 7.312 5.50567 7.06096 5.25358C6.80991 5.0015 6.5146 4.87546 6.17501 4.87546C5.82918 4.87546 5.5323 5.00077 5.28439 5.25138C5.03647 5.502 4.91251 5.79679 4.91251 6.13576C4.91251 6.47473 5.03647 6.77025 5.28439 7.02233C5.5323 7.27442 5.8271 7.40046 6.16876 7.40046Z"
        fill={fill}
      />
    </Svg>
  )
})
