import { selector } from "recoil"
import { listTeamCategoryMaster } from "src/aws/customAPI"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { OrganizationCategoryMaster } from "src/types/organization/OrganizationCategoryMaster"

export const organizationCategorySelector = selector({
  key: "selectors/organization/organizationCategorySelector",
  get: async ({ get }) => {
    const tokens = get(tokensState)
    if (tokens == null) return undefined

    const res = await listTeamCategoryMaster(tokens.accessToken)
    const categoryMaster: OrganizationCategoryMaster = {}

    res.data?.listTeamCategoryMaster.items.forEach((c) => {
      categoryMaster[c.id] = {
        subCategories: c.activities.items.map((a) => a.teamActivityMaster),
      }
    })

    return categoryMaster
  },
})
