import { memo, useMemo } from "react"
import { Svg, G, Circle, Path } from "react-native-svg"

type Props = {
  size?: number
}

export const JoinIcon = memo<Props>(({ size = 38 }) => {
  const height = useMemo(() => size * 0.92, [size])

  return (
    <Svg width={size} height={height} viewBox="0 0 38.078 35.006">
      <G data-name="グループ 2266" transform="translate(0 1)">
        <Circle
          data-name="楕円形 24"
          cx="5.66"
          cy="5.66"
          r="5.66"
          transform="translate(8.043)"
          stroke="#fff"
          fill="none"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
          strokeWidth={2}
        />
        <Path
          data-name="前面オブジェクトで型抜き 1"
          d="M1.575 17.058A1.577 1.577 0 0 1 0 15.483v-4.2A11.291 11.291 0 0 1 11.278 0h4.851a11.3 11.3 0 0 1 10.312 6.715h-3.6a8.312 8.312 0 0 0-.978-1.176 8.072 8.072 0 0 0-5.734-2.389h-4.851a8.155 8.155 0 0 0-8.128 8.128v4.2a1.577 1.577 0 0 1-1.575 1.58z"
          transform="translate(0 15.362)"
          fill="#fff"
        />
        <Path
          d="M30.022 23a1.04 1.04 0 0 0-1.04 1.04v4.942H24.04a1.04 1.04 0 0 0 0 2.081h4.942V36a1.04 1.04 0 1 0 2.081 0v-4.937H36a1.04 1.04 0 1 0 0-2.081h-4.937V24.04A1.04 1.04 0 0 0 30.022 23z"
          transform="translate(.534 -3.539)"
          fill="#fff"
          stroke="#fff"
        />
      </G>
    </Svg>
  )
})

JoinIcon.displayName = "JoinIcon"
