import { getOrderDetailSelectorFamily } from "src/recoil/selectors/paidTeam/getOrderDetailSelectorFamily"
import { getOrderDetailState, defaultValue } from "src/recoil/selectors/paidTeam/orderDetailState"
import dayjs from "dayjs"
import { useRecoilState, useSetRecoilState } from "recoil"
import { useEffect, useMemo, useState } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { orderPlanState } from "src/recoil/selectors/paidTeam/orderPlanState"
import { useRefresher } from "src/hooks/useRefresher"
import { getOrderDetailIdRequestState } from "src/recoil/selectors/paidTeam/getOrderDetailIdRequestState"
import { getPreOrderDetailState } from "src/recoil/selectors/paidTeam/orderPreDetailState"
import { orderPrePlanState } from "src/recoil/selectors/paidTeam/orderPrePlanState"
import { Platform } from "react-native"
import { organizationRefreshPermissionState } from "src/recoil/atoms/organization/organizationRefreshPermissionState"

type Params = {
  teamId: string
  adminId: string
  mode?: string
  goBack: () => void
}

export const useOrderDetail = ({ teamId, adminId, mode, goBack }: Params) => {
  const [orderDetail, setOrderDetail] = useRecoilState(getOrderDetailState)
  const [orderPreDetail, setPreOrderDetail] = useRecoilState(getPreOrderDetailState)
  const [plan, setPlan] = useRecoilState(orderPlanState)
  const [prePlan, setPrePlan] = useRecoilState(orderPrePlanState)
  const [isExtended, setIsExtended] = useState(false)
  const isWeb = Platform.OS === "web"
  const setNeedRefreshPermission = useSetRecoilState(organizationRefreshPermissionState)
  const [loadUseEffect, setLoadUseEffect] = useState(false)

  const { value: orderDetailValues, isLoading } = useAsyncSelector(getOrderDetailSelectorFamily({ teamId: teamId }))
  const refreshOrder = useRefresher(getOrderDetailIdRequestState)

  useEffect(() => {
    if (mode !== "home") {
      refreshOrder()
    }
  }, [teamId, mode, refreshOrder])

  useEffect(() => {
    if (loadUseEffect && orderDetail.teamId && orderDetail.teamId !== teamId && orderPreDetail.teamId !== teamId) {
      refreshOrder()
      setLoadUseEffect(false)
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamId, refreshOrder, mode, orderDetail, loadUseEffect])

  useEffect(() => {
    if (orderDetailValues !== undefined && typeof orderDetailValues !== "string" && !isLoading) {
      if (orderDetailValues.preOrder.order !== undefined && orderDetailValues.usedOrder.order === undefined) {
        setOrderDetail(orderDetailValues.preOrder.order)
        if (orderDetailValues.preOrder.plan != undefined) setPlan(orderDetailValues.preOrder.plan)
      }
      if (orderDetailValues.usedOrder.order !== undefined) {
        setOrderDetail(orderDetailValues.usedOrder.order)
        if (orderDetailValues.usedOrder.plan != undefined) setPlan(orderDetailValues.usedOrder.plan)
      }
      setIsExtended(orderDetailValues.preOrder.order !== undefined && orderDetailValues.usedOrder.order !== undefined)
      if (orderDetailValues.preOrder.order !== undefined && orderDetailValues.usedOrder.order !== undefined) {
        setOrderDetail(orderDetailValues.usedOrder.order)
        setPreOrderDetail(orderDetailValues.preOrder.order)
        if (orderDetailValues.usedOrder.plan != undefined) {
          setPlan(orderDetailValues.usedOrder.plan)
        }
        if (orderDetailValues.preOrder.plan != undefined) {
          setPrePlan(orderDetailValues.preOrder.plan)
        }
      }
      if (!orderDetailValues.preOrder.order && !orderDetailValues.usedOrder.order) {
        setOrderDetail(defaultValue)
        setPreOrderDetail(defaultValue)
      }
      setLoadUseEffect(true)
    }
  }, [orderDetailValues, setOrderDetail, setPlan, setPreOrderDetail, setPrePlan, isLoading])

  useEffect(() => {
    const id = setTimeout(() => {
      if (
        orderDetailValues !== undefined &&
        typeof orderDetailValues !== "string" &&
        orderDetailValues.preOrder.order === undefined &&
        orderDetailValues.usedOrder.order === undefined &&
        !isLoading &&
        loadUseEffect
      ) {
        if (mode === "home") {
          goBack()
        }
      }
    }, 800)
    return () => {
      clearTimeout(id)
    }
  }, [goBack, orderDetail.createdAt, orderPreDetail.createdAt, isLoading, loadUseEffect, orderDetailValues, mode])

  const isApplicant = useMemo(
    () =>
      isExtended
        ? adminId === (orderPreDetail?.transferApplicantId ?? orderPreDetail?.applicantId)
        : adminId === (orderDetail?.transferApplicantId ?? orderDetail?.applicantId),
    [isExtended, orderDetail, orderPreDetail, adminId]
  )

  const formatCreatedDate = dayjs(orderDetail?.createdAt ?? "").format("YYYY-MM-DD")

  const currentMonth = dayjs().month() + 1

  const endMonth = dayjs(orderDetail?.endDate ?? "").month() + 1

  const isShowExtendButton = currentMonth === endMonth && !isExtended && isWeb

  return {
    orderDetail,
    plan,
    isLoading,
    isApplicant,
    formatCreatedDate,
    isShowExtendButton,
    orderPreDetail,
    prePlan,
    isExtended,
    setNeedRefreshPermission,
  }
}
