import { memo } from "react"
import { Svg, Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
}

export const MachingProfileIcon = memo<Props>(({ fill = Colors.orange }) => {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20">
      <Path
        d="M10.0385 11.9159C11.0705 11.9159 11.9471 11.5553 12.6683 10.8341C13.3894 10.113 13.75 9.22836 13.75 8.18028C13.75 7.14824 13.3894 6.27164 12.6683 5.55047C11.9471 4.82932 11.0705 4.46875 10.0385 4.46875C8.99039 4.46875 8.10577 4.82932 7.38463 5.55047C6.66346 6.27164 6.30287 7.14824 6.30287 8.18028C6.30287 9.22836 6.66346 10.113 7.38463 10.8341C8.10577 11.5553 8.99039 11.9159 10.0385 11.9159ZM1.73075 20C1.24679 20 0.837344 19.8325 0.502406 19.4976C0.167469 19.1627 0 18.7532 0 18.2692V1.73075C0 1.24679 0.167469 0.837345 0.502406 0.502407C0.837344 0.16747 1.24679 0 1.73075 0H18.2693C18.7532 0 19.1627 0.16747 19.4976 0.502407C19.8325 0.837345 20 1.24679 20 1.73075V18.2692C20 18.7532 19.8325 19.1627 19.4976 19.4976C19.1627 19.8325 18.7532 20 18.2693 20H1.73075ZM1.49038 19.0385H18.5096C18.6859 18.8574 18.8061 18.7328 18.8702 18.6647C18.9343 18.5966 18.9904 18.4479 19.0385 18.2188C17.8526 17.0841 16.496 16.1775 14.9688 15.4988C13.4415 14.8201 11.7852 14.4808 10 14.4808C8.21475 14.4808 6.5585 14.8201 5.03125 15.4988C3.504 16.1775 2.14743 17.0841 0.961531 18.2188C1.00961 18.4479 1.06571 18.5966 1.12981 18.6647C1.19392 18.7328 1.3141 18.8574 1.49038 19.0385Z"
        fill={fill}
      />
    </Svg>
  )
})
