import type { FC, ReactNode } from "react"
import { StyleSheet, View } from "react-native"
import { Colors } from "src/constants/Colors"

type Props = {
  children: ReactNode
}
export const DrawerIconContainer: FC<Props> = ({ children }) => {
  return <View style={styles.container}>{children}</View>
}

const styles = StyleSheet.create({
  container: {
    width: 22,
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
})
