import { selector } from "recoil"
import { accountRequestIdState } from "src/recoil/atoms/account/accountRequestIdState"
import { AccountModel } from "src/types/user/AccountModel"
import { getAccountInfo } from "src/aws/customAPI"
import { Gender as APIGender } from "src/aws/API"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { Gender } from "src/constants/Gender"
import { appInfo } from "src/constants/AppInfo"

export const accountSelector = selector<AccountModel | undefined>({
  key: "selectors/account/accountSelector",
  get: async ({ get }) => {
    get(accountRequestIdState)
    const tokens = get(tokensState)
    if (tokens == null) return undefined

    const account = await getAccountInfo(tokens.accessToken, {
      appInfo,
    }).then((res) => res.data?.getAccountInfo)
    if (account == null) return undefined

    const questionAnswers: { [questionId: string]: string } = {}
    account.questionAnswers?.forEach((qa) => {
      if (qa.question?.id && qa.answer?.id) {
        questionAnswers[qa.question.id] = qa.answer.id
      }
    })

    const accountModel: AccountModel = {
      id: account.id,
      firstName: account.firstName,
      lastName: account.lastName,
      kanaFirstName: account.firstNameKana,
      kanaLastName: account.lastNameKana,
      email: account.email,
      phoneNumber: account.phoneNumber || undefined,
      birthDate: account.birthday ? new Date(account.birthday) : undefined,
      gender: convertGender(account.gender),
      jobId: account.accountJobId || undefined,
      schoolId: account.accountSchoolId || undefined,
      schoolDepartmentId: account.accountSchoolDepartmentId || undefined,
      graduationYear: account.schoolGraduationYear || undefined,
      prefectureId: account.accountPrefectureId || undefined,
      cityId: account.accountCityId || undefined,
      questionAnswers: questionAnswers,
      matchingTermsAgreedAt: account.matchingTermsAgreedAt || undefined,
      signupStatus: account.signupStatus,
      agreedMatchingTermsVersion: account.agreedMatchingTermsVersion || undefined,
      agreedTermsVersion: account.agreedTermsVersion || undefined,
    }

    return accountModel
  },
})

const convertGender = (gender?: APIGender | null): Gender => {
  return gender
    ? {
        [APIGender.male]: Gender.Male,
        [APIGender.female]: Gender.Female,
        [APIGender.unspecified]: Gender.NoAnswer,
      }[gender]
    : Gender.NoAnswer
}
