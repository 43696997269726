import { useState, useEffect } from "react"
import { Linking, Modal, Pressable, StyleSheet, Text, View } from "react-native"
import { useRecoilState, useRecoilValue } from "recoil"
import { agreeToMatchingTerms } from "src/aws/customAPI"
import { Checkbox } from "src/components/parts/Checkbox"
import { Colors } from "src/constants/Colors"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useRefresher } from "src/hooks/useRefresher"
import { accountRequestIdState } from "src/recoil/atoms/account/accountRequestIdState"
import { latestTermsState } from "src/recoil/atoms/account/latestTermsState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { acceptMatchingTermState } from "src/recoil/atoms/matching/acceptMatchingTermState"
import { acceptMatchingTermFlagState } from "src/recoil/atoms/matching/acceptMatchingTermFlagState"
import { sendAnalyticsEventAsync } from "src/tags/analytics/Analytics"
import { CustomAlert } from "src/utils/CustomAlert"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"

interface Props {
  agreeTermProp?: boolean
  continuousAction?: () => void
}

export const AgreeMatchingTermModal = ({ agreeTermProp = false, continuousAction }: Props) => {
  const tokens = useRecoilValue(tokensState)
  const { value: accountInfo } = useAsyncSelector(accountSelector)
  const { value: latestTerms } = useAsyncSelector(latestTermsState)
  const [agreeTerm, setAgreeTerm] = useRecoilState(acceptMatchingTermState)
  const refreshAccount = useRefresher(accountRequestIdState)
  const [agreed, setAgreed] = useState<boolean>(false)
  const isPCScreen = useCheckPCScreen()
  const [agreeFlag, setAgreeFlag] = useRecoilState(acceptMatchingTermFlagState)

  const checkVersion = accountInfo?.agreedMatchingTermsVersion === latestTerms?.matching.version

  const matchingVersion = accountInfo?.agreedMatchingTermsVersion

  useEffect(() => {
    if (latestTerms?.matching.version) {
      if (!checkVersion && !agreeFlag) {
        setAgreeTerm(false)
      }
    }
  }, [latestTerms, checkVersion, agreeFlag, setAgreeTerm])

  const agreeMatchingTerm = async () => {
    if (!tokens?.accessToken || !latestTerms?.matching.version) return
    try {
      await agreeToMatchingTerms(tokens.accessToken, { input: { version: latestTerms.matching.version } })
      sendAnalyticsEventAsync("custom_action", "clickButton", "利用規約に同意ボタン")
      setAgreeTerm(true)
      setAgreeFlag(true)
      refreshAccount()
      if (continuousAction) {
        continuousAction()
      }
    } catch (e) {
      console.log(e)
      await CustomAlert.alert("エラー", "利用規約同意に失敗しました")
    }
  }

  return (
    <Modal animationType="fade" transparent={true} visible={!agreeTerm && !agreeTermProp}>
      <View style={styles.centeredView}>
        <View style={[styles.container, isPCScreen ? { width: 600, maxWidth: 600 } : {}]}>
          <View style={styles.header}>
            {!matchingVersion ? (
              <Text style={[styles.headerLabel, styles.centerLabel]}>利用規約</Text>
            ) : (
              <Text style={[styles.headerLabel, styles.centerLabel]}>利用規約が更新されました</Text>
            )}
          </View>
          {isPCScreen ? (
            <Text style={styles.textNormal}>{`${
              matchingVersion
                ? "お知らせでご案内している新しい利用規約に更新されました。利用規約を確認いただき、同意の上、ご利用をお願いいたします。"
                : "「利用規約」を確認し、同意した上でご利用ください。"
            }`}</Text>
          ) : (
            <View style={{}}>
              <Text style={styles.textNormal}>{`${
                matchingVersion
                  ? "お知らせでご案内している新しい利用規約に更新されました。利用規約を確認いただき、同意の上、ご利用をお願いいたします。"
                  : "「利用規約」を確認し、"
              }`}</Text>
              {!matchingVersion && <Text style={[styles.textNormal, { marginTop: 0 }]}>{"同意した上でご利用ください。"}</Text>}
            </View>
          )}
          {matchingVersion ? (
            <Text style={[styles.textLink]} onPress={() => Linking.openURL("https://www.ra9plus.jp/post/")}>
              [<Text style={{ textDecorationLine: "underline" }}>お知らせ一覧</Text>]
            </Text>
          ) : (
            <></>
          )}
          {latestTerms?.matching.url && (
            <Pressable
              style={[styles.button, matchingVersion ? {} : { marginTop: 16 }]}
              onPress={() => Linking.openURL(latestTerms?.matching.url)}
            >
              <Text style={styles.buttonText}>利用規約を確認</Text>
            </Pressable>
          )}

          <View style={[styles.checkboxWrapper]}>
            <Checkbox isChecked={agreed} onPress={() => setAgreed(!agreed)}></Checkbox>
            <Text style={[styles.checkboxLabel, styles.textNormal, { marginTop: 0 }]}>上記の項目に同意する</Text>
          </View>
          <View style={{ flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "center" }}>
            <Pressable
              style={[
                isPCScreen ? styles.footerButtonPC : styles.footerButtonMobile,
                { backgroundColor: agreed ? Colors.orange : Colors.pale },
              ]}
              onPress={agreeMatchingTerm}
              disabled={!agreed}
            >
              <Text style={styles.footerButtonText}>{`${matchingVersion ? "利用規約に同意" : "同意する"}`}</Text>
            </Pressable>
          </View>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  container: {
    margin: 10,
    backgroundColor: "white",
    borderRadius: 10,
    padding: 24,
    alignItems: "center",
    shadowColor: "rgba(41, 39, 36, 0.06)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 30,
    elevation: 2,
  },
  header: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
  },
  centerLabel: {
    flex: 1,
  },
  headerLabel: {
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "Hiragino Kaku Gothic Pro",
    lineHeight: 22.4,
    color: Colors.greyshBrown,
    textAlign: "center",
  },
  checkboxWrapper: {
    flexDirection: "row",
    paddingVertical: 16,
    alignItems: "center",
    justifyContent: "center",
  },
  checkboxLabel: {
    paddingLeft: 8,
  },
  paddingView: {
    paddingVertical: 20,
  },
  button: {
    paddingVertical: 12,
    paddingHorizontal: 24,
    borderRadius: 8,
    backgroundColor: "#9F866B1A",
  },
  buttonText: {
    color: Colors.orange,
    fontWeight: "600",
    lineHeight: 19.6,
    fontSize: 14,
    fontFamily: "Hiragino Kaku Gothic Pro",
  },
  footerButtonPC: {
    width: 300,
    paddingHorizontal: 24,
    paddingVertical: 12,
    borderRadius: 100,
    backgroundColor: Colors.orange,
    maxWidth: 300,
  },
  footerButtonMobile: {
    width: "100%",
    flex: 1,
    paddingHorizontal: 24,
    paddingVertical: 12,
    borderRadius: 100,
    backgroundColor: Colors.orange,
  },
  footerButtonText: {
    color: Colors.white3,
    textAlign: "center",
    fontWeight: "600",
    fontSize: 18,
    lineHeight: 25.2,
    fontFamily: "Hiragino Kaku Gothic Pro",
  },
  textNormal: {
    color: Colors.greyshBrown,
    fontSize: 14,
    lineHeight: 19.6,
    marginTop: 16,
    fontFamily: "Hiragino Kaku Gothic Pro",
    fontWeight: "300",
  },
  textLink: {
    color: Colors.orange,
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 19.6,
    alignSelf: "flex-start",
    marginBottom: 16,
    fontFamily: "Hiragino Kaku Gothic Pro",
    marginTop: 4,
  },
})
