import { ComponentProps, memo } from "react"
import { Pressable, StyleSheet } from "react-native"
import { ButtonType } from "./TextButton"
type Props = ComponentProps<typeof Pressable> & {
  buttonType?: string
}

export const Button = memo<Props>(({ style, onPress, children, disabled, buttonType, ...rest }) => {
  return (
    <Pressable
      onPress={onPress}
      style={(parameter) => [
        style == null ? {} : typeof style === "function" ? style(parameter) : style,
        parameter.pressed ? styles.active : styles.idle,
        disabled && buttonType !== ButtonType.Primary && styles.disabled,
      ]}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Pressable>
  )
})
Button.displayName = "Button"

const styles = StyleSheet.create({
  active: {
    opacity: 0.6,
  },
  idle: {
    opacity: 1,
  },
  disabled: {
    opacity: 0.3,
  },
})
