import { Result } from "src/utils/Result"
import {
  ContactReplyThreadType,
  RemovedReplyItem,
  ReplyFromMeItem,
  ReplyFromOthersItem,
} from "src/constants/ContactReplyThreadType"
import { listThreadMessage, ThreadMessage } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type ReplyThread = {
  replyThreadId: string
  partner: {
    id: string
    name: string
    isDeleted: boolean
  }
  contactTitle: string
  replyItems: (ReplyFromMeItem | ReplyFromOthersItem | RemovedReplyItem)[]
}

type GetContactReplyThreadRequestParams = {
  accessToken: string
  replyThreadId: string
  nextToken?: string
  count?: number
}

export type GetContactReplyThreadRequestSuccess = {
  replyThread: ReplyThread
  nextToken?: string
  myMemberId?: string
}

type GetContactReplyThreadRequestFailure = {
  message: string
}

export const getContactReplyThread = async (
  params: GetContactReplyThreadRequestParams
): Promise<Result<GetContactReplyThreadRequestSuccess, GetContactReplyThreadRequestFailure>> => {
  const {accessToken, replyThreadId} = params

  return execApi(
    () => listThreadMessage(accessToken, {
      threadId: replyThreadId,
      appInfo,
      nextToken: params.nextToken,
    }),
    res => {
      const {mail, myMemberId, mailSender, mailRecipient, messages} = res.getThread
      const partner = getPartner(myMemberId, mailSender, mailRecipient)
      return {
        replyThread: {
          replyThreadId: replyThreadId,
            partner: {
              id: partner.id,
              name: partner.nickname,
              isDeleted: partner.deleteFlg || false,
          },
          contactTitle: mail.title,
          replyItems: messages.items.map(message => convertThreadMessage(myMemberId, message))
        },
        nextToken: res.getThread.messages.nextToken ?? undefined,
        myMemberId: myMemberId ?? undefined,
      }
    },
    {
      identifiers: ["listThreadMessage", "listThreadMessage"]
    }
  )
}

type Member = {
  id: string
  nickname: string
  deleteFlg?: boolean | null
}

const getPartner = (myMemberId: string, mailSender: Member, mailRecipient: Member) => {
  const partner = [mailSender, mailRecipient].find(m => m.id !== myMemberId)
  if (!partner) throw Error("返信メッセージの取得に失敗しました")
  return partner
}

const convertThreadMessage = (myMemberId: string, threadMessage: ThreadMessage): ReplyFromMeItem | ReplyFromOthersItem | RemovedReplyItem => {
  if (!threadMessage.message.deleteFlg) {
    const type = threadMessage.member.id === myMemberId ? ContactReplyThreadType.ReplyFromMe : ContactReplyThreadType.ReplyFromOthers
    return {
      contactReplyThreadType: type,
      replyThreadMessageId: threadMessage.id,
      senderName: threadMessage.member.nickname,
      message: threadMessage.message.text || '',
      date: new Date(threadMessage.message.createdAt),
      isEdited: threadMessage.message.isEdited,
      isRead: threadMessage.message.readFlg,
    }
  } else {
    return {
      contactReplyThreadType: ContactReplyThreadType.RemovedReply,
      replyThreadMessageId: threadMessage.id,
      senderName: threadMessage.member.nickname,
      date: new Date(threadMessage.message.createdAt),
    }
  }
}
