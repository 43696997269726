import { memo } from "react"
import { Linking, Modal, StyleSheet, Text, View } from "react-native"
import { useTermsData } from "src/recoil/hooks/screens/useTermsData"
import { Colors } from "src/constants/Colors"
import { ButtonType, IconButton } from "src/components/parts/buttons/IconButton"
import { Checkbox } from "src/components/parts/Checkbox"
import { TextButton } from "src/components/parts/buttons/TextButton"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

export const TermsModal = memo(() => {
  const { isChecked, toggleCheck, newTerms, acceptTerms, isTermsUpdating, isTermsModalOpen, onOpenTerms, serviceVersion } =
    useTermsData()
  const isPCScreen = useCheckPCScreen()

  return (
    <Modal visible={isTermsModalOpen && newTerms != null} transparent>
      <View style={styles.modalWrapper}>
        <View style={[styles.container, isPCScreen ? { width: 600, maxWidth: 600 } : {}]}>
          <Text style={styles.title}>{serviceVersion ? "利用規約が更新されました" : "規約が更新されました"}</Text>
          {serviceVersion ? (
            <>
              <Text style={styles.textNew}>
                お知らせでご案内している新しい利用規約に更新されました。利用規約を確認いただき、同意の上、ご利用をお願いいたします。
              </Text>
              <Text style={styles.textLink} onPress={() => Linking.openURL("https://www.ra9plus.jp/post/")}>
                [<Text style={{ textDecorationLine: "underline" }}>お知らせ一覧</Text>]
              </Text>
            </>
          ) : (
            <Text style={styles.text}>利用規約を確認し、同意した上でご利用ください。</Text>
          )}
          <IconButton
            style={{ paddingVertical: 12, paddingHorizontal: 24 }}
            styleTitle={{
              fontSize: 14,
              lineHeight: 19.6,
              fontWeight: "600",
              fontFamily: "Hiragino Kaku Gothic Pro",
              marginLeft: 0,
            }}
            buttonType={ButtonType.Primary}
            IconComponent={<></>}
            title="利用規約を確認"
            onPress={onOpenTerms}
          />
          <View style={styles.checkbox}>
            <Checkbox isChecked={isChecked} onPress={toggleCheck} />
            <Text style={styles.checkboxLabel}>上記項目に同意する</Text>
          </View>
          <View style={{ flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "center" }}>
            <TextButton
              buttonType={ButtonType.Primary}
              buttonSize={"M"}
              style={[isPCScreen ? { width: 300, maxWidth: 300 } : { flex: 1 }]}
              styleTitle={[
                { fontWeight: "600", lineHeight: 25.2, fontFamily: "Hiragino Kaku Gothic Pro" },
                !isChecked && { color: Colors.orange },
              ]}
              title="利用規約に同意"
              onPress={acceptTerms}
              isLoading={isTermsUpdating}
              disabled={!isChecked}
            />
          </View>
        </View>
      </View>
    </Modal>
  )
})

const styles = StyleSheet.create({
  modalWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.37)",
    padding: 24,
  },
  container: {
    backgroundColor: Colors.white3,
    borderRadius: 10,
    padding: 24,
    flexDirection: "column",
    alignItems: "center",
    shadowColor: "rgba(41, 39, 36, 0.06)",
    shadowOffset: {
      width: 0,
      height: 0,
    },
    shadowOpacity: 1,
    shadowRadius: 30,
    elevation: 2,
  },
  title: {
    fontSize: 16,
    fontWeight: "600",
    fontFamily: "Hiragino Kaku Gothic Pro",
    lineHeight: 22.4,
    color: Colors.greyshBrown,
  },
  text: {
    color: Colors.greyshBrown,
    fontSize: 14,
    lineHeight: 20,
    marginVertical: 20,
    textAlign: "center",
  },
  textNew: {
    color: Colors.greyshBrown,
    fontSize: 14,
    lineHeight: 19.6,
    marginTop: 16,
    fontFamily: "Hiragino Kaku Gothic Pro",
  },
  textLink: {
    color: Colors.orange,
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 19.6,
    alignSelf: "flex-start",
    marginBottom: 16,
    fontFamily: "Hiragino Kaku Gothic Pro",
    marginTop: 4,
  },
  checkbox: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginVertical: 16,
  },
  checkboxLabel: {
    marginLeft: 8,
    color: Colors.greyshBrown,
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 19.6,
    fontFamily: "Hiragino Kaku Gothic Pro",
  },
})
