import { atom } from "recoil"
export type createOrEditParams = {
  title: string
  body: string
  date: Date | undefined
  activity?:
    | {
        id: string
        label: string
      }
    | undefined
  startTime: any
  endTime: any
  prefecture:
    | {
        id: number
        label: string
      }
    | undefined
  city:
    | {
        id: string
        label: string
      }
    | undefined
  place: string | undefined
  placeURL: string | undefined
  expense: number | undefined | string
  expensePurpose: string | undefined
  ageFrom:
    | {
        id: string
        label: string
      }
    | undefined
  ageTo:
    | {
        id: string
        label: string
      }
    | undefined
  offerCount: string | undefined
  publishEndDatetime: undefined | Date
  attachmentFileNames?: string[]
  modeValue?: string
}
const defaultCreteParams = {
  title: "",
  body: "",
  place: undefined,
  placeURL: undefined,
  expense: undefined,
  expensePurpose: undefined,
  activity: undefined,
  offerCount: undefined,
  date: undefined,
  publishEndDatetime: undefined,
  startTime: undefined,
  endTime: undefined,
  prefecture: undefined,
  city: undefined,
  ageFrom: undefined,
  ageTo: undefined,
}
export const createOrEditMatchingOfferState = atom<createOrEditParams>({
  key: "atoms/matching/search/createOrEditMatchingOfferState",
  default: defaultCreteParams,
})
