import { UpdateMatchingOfferModel } from "../../types/matching/matchingOfferModel";
import { Result } from "src/utils/Result";
import { GqlError, updateMatchingOffer as _updateMatchingOffer } from "src/aws/customAPI";
import { MatchingOfferPurpose } from "../../aws/API";

type UpdateMatchingOfferParams = {
  accessToken: string
  model: UpdateMatchingOfferModel
}

type UpdateMatchingOfferSuccess = unknown

type UpdateMatchingOfferFailure = {
  message: string
}
export const updateMatchingOffer = async ({ accessToken, model }: UpdateMatchingOfferParams): Promise<Result<UpdateMatchingOfferSuccess, UpdateMatchingOfferFailure>> => {
  return await _updateMatchingOffer(accessToken, {
    input: {
      id: model.offerId,
      purpose: MatchingOfferPurpose.jointPracticeGame,
      title: model.title,
      body: model.body,
      date: model.date ? model.date : null,
      startTime: model.startTime ? model.startTime.toString().slice(16, 21) : null,
      endTime: model.endTime ? model.endTime.toString().slice(16, 21): null,
      place: model.place,
      placeURL: model.placeURL,
      expense: model.expense,
      expensePurpose: model.expensePurpose,
      ageFrom: model.ageFrom,
      ageTo: model.ageTo,
      offerCount: model.offerCount,
      publishEndDate: model.publishEndDate,
      attachmentFileNames: model.attachmentFileNames,
      prefectureId: model.prefectureId,
      cityId: model.cityId,
    }
  }).then(res => {
    if (res.data?.updateMatchingOffer.id) {
      return Result.Ok(undefined)
    } else {
      return Result.Error<UpdateMatchingOfferFailure>({ message: "予期せぬエラーが発生しました" })
    }
  })
    .catch((e: GqlError) => {
      const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || '予期せぬエラーが発生しました'
      return Result.Error<UpdateMatchingOfferFailure>({
        message: message
      })
    })
}