import { memo } from "react"
import Svg, { G, Path, Rect } from "react-native-svg"

export const EmailIcon = memo(() => (
  <Svg width="13.838" height="7.71" viewBox="0 0 13.838 7.71">
    <G data-name="Icon Mail" transform="translate(-.64 -2.803)">
      <Rect data-name="長方形 29" width="12.667" height="7.71" rx="1" transform="translate(1.32 2.803)" fill="#9a9996" />
      <Path
        data-name="パス 65"
        d="m1 2 6.239 3.466L13.478 2"
        transform="translate(.32 1.497)"
        fill="none"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit={10}
      />
    </G>
  </Svg>
))
