import { selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { contactReplyThreadRequestIdState } from "src/recoil/atoms/contact/contactReplyThreadRequestIdState"
import { getContactReplyThread } from "src/apis/contact/getContactReplyThread"
import { ContactReplyThreadItem, ContactReplyThreadType } from "src/constants/ContactReplyThreadType"
import dayjs from "dayjs"
import { v4 as uuidv4 } from "uuid"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"

export type ContactReplyThread = {
  replyThreadId: string
  partner: {
    id: string
    name: string
    isDeleted: boolean
  }
  contactTitle: string
  hasMessage: boolean
  reversedReplyItems: ContactReplyThreadItem[]
  nextToken?: string
  myMemberId?: string
}

export const contactReplyThreadSelectorFamily = selectorFamily<
  ContactReplyThread | undefined,
  { replyThreadId: string; count?: number; nextToken?: string }
>({
  key: "selectors/contact/contactReplyThreadSelectorFamily",
  get:
    ({ replyThreadId, count, nextToken }) =>
    async ({ get }) => {
      get(contactReplyThreadRequestIdState)
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) {
        return
      }
      const accountId = get(accountIdState)
      if (accountId == null) {
        return
      }
      const result = await getContactReplyThread({ accessToken, replyThreadId, count, nextToken })
      if (!result.isOk) {
        throw result.error.message
      }
      const replyItems = result.content.replyThread.replyItems
        .reverse()
        .reduce<ContactReplyThreadItem[]>(function insertDateLine(prev, curr) {
          const currDate = dayjs(curr.date)

          const getDateLineLabel = () => {
            const nowDay = new Date()
            const yesterday = new Date(nowDay)
            yesterday.setDate(nowDay.getDate() - 1)
            if (
              curr.date.getFullYear() === nowDay.getFullYear() &&
              curr.date.getMonth() === nowDay.getMonth() &&
              curr.date.getDate() === nowDay.getDate()
            ) {
              return `今日`
            } else if (
              curr.date.getFullYear() === yesterday.getFullYear() &&
              curr.date.getMonth() === yesterday.getMonth() &&
              curr.date.getDate() === yesterday.getDate()
            ) {
              return `昨日`
            } else if (curr.date.getFullYear() === nowDay.getFullYear()) {
              return `${dayjs(curr.date).format("MM/DD")}`
            } else {
              return `${dayjs(curr.date).format("YYYY/MM/DD")}`
            }
          }

          if (prev.length === 0) {
            return [
              {
                contactReplyThreadType: ContactReplyThreadType.DateLine,
                label: getDateLineLabel(),
                replyThreadMessageId: uuidv4(),
              },
              curr,
            ]
          }
          const prevValue = prev[prev.length - 1]
          if (
            prevValue.contactReplyThreadType === ContactReplyThreadType.DateLine ||
            prevValue.contactReplyThreadType === ContactReplyThreadType.MemberDeleted ||
            prevValue.contactReplyThreadType === ContactReplyThreadType.NoMessage ||
            dayjs(prevValue.date).format("YYYY/MM/DD") === dayjs(currDate).format("YYYY/MM/DD")
          ) {
            return [...prev, curr]
          }
          return [
            ...prev,
            {
              contactReplyThreadType: ContactReplyThreadType.DateLine,
              label: getDateLineLabel(),
              replyThreadMessageId: uuidv4(),
            },
            curr,
          ]
        }, [])

      const hasMessage = replyItems.length > 0
      if (replyItems.length === 0) {
        replyItems.push({
          contactReplyThreadType: ContactReplyThreadType.NoMessage,
          replyThreadMessageId: uuidv4(),
        })
      }

      if (result.content.replyThread.partner.isDeleted) {
        replyItems.push({
          contactReplyThreadType: ContactReplyThreadType.MemberDeleted,
          replyThreadMessageId: uuidv4(),
        })
      }

      return {
        ...result.content.replyThread,
        hasMessage,
        reversedReplyItems: replyItems.reverse(),
        nextToken: result.content.nextToken,
        myMemberId: result.content.myMemberId,
      }
    },
})
