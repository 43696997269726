import AsyncStorage from "@react-native-async-storage/async-storage"

export const getUpdateDeviceFlag = async () => {
  try {
    const value = await AsyncStorage.getItem("updateDeviceFlag")
    if (value !== null) {
      return true
    } else {
      return false
    }
  } catch (error) {
    console.log(error)
    return false
  }
}

export const setUpdateDeviceFlag = async () => {
  try {
    await AsyncStorage.setItem("updateDeviceFlag", "true")
  } catch (error) {
    console.log(error)
  }
}
