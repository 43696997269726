import { selectorFamily } from "recoil"
import { migrationRequestIdState } from "src/recoil/atoms/contact/migrationRequestIdState"
import { requestDataSelector } from "../auth/requestDataSelector"
import { MigrationModel } from "src/types/contact/MigrationModel"
import { getMigrationContact } from "src/apis/contact/getContactDetail"

export const migrationSelectorFamily = selectorFamily<
  MigrationModel | undefined,
  { migrationId: string; organizationId: string }
>({
  key: "selectors/contact/migrationSelectorFamily",
  get:
    ({ migrationId, organizationId }) =>
    async ({ get }) => {
      get(migrationRequestIdState)
      const requestData = get(requestDataSelector)
      if (requestData == null) {
        return
      }
      const { accessToken } = requestData
      const migrationResult = await getMigrationContact({ accessToken, contactId: migrationId, organizationId })
      if (!migrationResult.isOk) {
        return
      }
      const migration = migrationResult.content
      return {
        id: migration.contactId,
        title: migration.title,
        body: migration.body,
        isAddToOtherAdminsHistory: migration.isAddToOtherAdminsHistory,
        isEnableForComment: migration.isEnableForComment,
      }
    },
})
