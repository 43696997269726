import { Result } from "src/utils/Result"
import { deleteMatchingOffer as _deleteMatchingOffer, GqlError } from "src/aws/customAPI"

type DeleteMatchingOfferParams = {
  accessToken: string
  offerId: string
}

type DeleteMatchingOfferSuccess = unknown

type DeleteMatchingOfferFailure = {
  message: string
}

export const deleteMatchingOffer = async ({accessToken, offerId}: DeleteMatchingOfferParams): Promise<Result<DeleteMatchingOfferSuccess, DeleteMatchingOfferFailure>> => {
  return await _deleteMatchingOffer(accessToken, {
    input: {
      id: offerId
    }
  }).then(res => {
    return (res.data?.deleteMatchingOffer.id) ?
      Result.Ok(undefined) :
      Result.Error<DeleteMatchingOfferFailure>({ message: '募集の停止に失敗しました' })
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || '募集の停止に失敗しました'
    return Result.Error<DeleteMatchingOfferFailure>({
      message: message
    })
  })
}