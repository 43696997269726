import { memo, useCallback, useEffect, useMemo, useState, useLayoutEffect } from "react"
import { Image, StyleSheet, Text, View, FlatList, ListRenderItemInfo, Pressable, TouchableWithoutFeedback } from "react-native"
import { ContactListCommentStackScreenProps } from "src/types.d"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { Colors } from "src/constants/Colors"
import { Button } from "src/components/parts/buttons/Button"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import { ContactListCommentScreens } from "src/constants/ContactListCommentScreens"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { ListCommentTeamMemberMailItem } from "src/aws/customAPI"
import { listCommentState } from "src/apis/comment/listCommentState"
import { MatchingListEmpty } from "src/components/parts/matching/MatchingListEmpty"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { useFetcher } from "src/hooks/useFetcher"
import { getListComment } from "src/apis/comment/getListComment"
import { useAsyncState } from "src/hooks/useAsyncState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { ContactType } from "src/constants/ContactType"
import { CloseModalHeaderLeftButton } from "src/components/parts/headers/CloseModalHeaderLeftButton"
import { commonModalOptions } from "src/constants/options/commonModalOptions"
import { convertDate } from "src/utils/useDateTime"
import { Screens } from "src/constants/Screens"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import CollapseText from "src/components/parts/CollapseText"
import { permissionSelector } from "src/recoil/selectors/account/permissionSelector"
import { defaultUser } from "src/constants/defaultUser"
import { CustomAlert } from "src/utils/CustomAlert"
import { useRefresher } from "src/hooks/useRefresher"
import { teamPermissionRequestIdState } from "src/recoil/atoms/organization/teamPermissionRequestIdState"
export type ContactListCommentOverviewParams = {
  contactId: string
  type: "Inbox" | "Outbox"
  contactType: string
  alwaysShow: boolean
}

export const ContactListCommentOverview = memo<
  ContactListCommentStackScreenProps<typeof ContactListCommentScreens.ContactListCommentOverview>
>(
  ({
    route: {
      params: { contactId, contactType, type, alwaysShow },
    },
    navigation,
  }) => {
    const [tokens] = useAsyncState(tokensState)
    const accessToken = useMemo(() => tokens?.accessToken, [tokens])
    const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
    const { allowEditMember, allowViewAnswerSummary, permissionLoading } = useCheckAllowAccountPermission({
      organizationId: selectedOrganization?.id,
      contactId,
      viewMode: type,
    })
    const isLargeScreen = useCheckPCScreen()
    const [nextToken, setNextToken] = useState<string | undefined>(undefined)
    const [listComment, setListComment] = useAsyncState<ListCommentTeamMemberMailItem[]>(listCommentState)
    const [tokenComment, setTokenComment] = useState<string>()
    const [total, setTotal] = useState(0)
    const { value: accountPermission, isLoading: isPermissionLoading } = useAsyncSelector(permissionSelector)

    const getTitleByContactType = (type: string) => {
      switch (type) {
        case ContactType.Normal:
          return "コメント一覧"
        case ContactType.Attendance:
          return "出欠確認 回答一覧"
        case ContactType.Survey:
          return "アンケート 回答一覧"
        default:
          return "日程調整 回答一覧"
      }
    }

    useLayoutEffect(() => {
      let title
      if (!allowViewAnswerSummary) {
        title = "コメント一覧"
      } else {
        title = getTitleByContactType(contactType)
      }
      navigation.setOptions({ title })
    }, [navigation, contactType, allowViewAnswerSummary])

    const { fetch: getData, isFetching: isLoading } = useFetcher(
      useCallback(async () => {
        if (!accessToken || !contactId || !contactType) return
        try {
          const result = await getListComment({
            mailId: contactId,
            accessToken: accessToken,
            contactType: contactType,
            nextToken: nextToken,
            count: 10,
          })
          if (typeof result !== "undefined" && typeof result !== "string" && result.items) {
            if (nextToken) {
              setListComment([...(listComment ?? []), ...result.items])
            } else {
              setListComment(result.items)
            }
            setTotal(result.total ?? 0)
            setTokenComment(result.nextToken)
          }
        } catch (error) {
          console.log(error)
        }
      }, [nextToken, contactId, contactType, accessToken, listComment, setListComment])
    )

    const isShowAnswer = (
      groupIdsInput: {
        id: string
        leaderFlg: boolean
        groupID: string
      }[]
    ) => {
      if (accountPermission?.mail.recv.memberListPublishOff.showAnsweredListMembers.accessAll || alwaysShow) {
        return true
      } else {
        const groupIds = accountPermission?.mail.recv.memberListPublishOff.showAnsweredListMembers.groupIds ?? []
        const result = groupIdsInput.some((item) => groupIds.includes(item.groupID))
        return result
      }
    }

    const refresh = () => {
      setNextToken(undefined)
      getData()
    }

    useEffect(() => {
      getData()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken, nextToken])

    useEffect(() => {
      setNextToken(undefined)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contactId])

    const [isFirst, setIsFirst] = useState(true)
    useEffect(() => {
      if (!isFirst) {
        return
      }
      const unsubscribe = navigation.addListener("focus", () => {
        setListComment([])
        setIsFirst(false)
      })
      return unsubscribe
    }, [isFirst, navigation, setListComment])

    const loadMore = () => {
      setNextToken(tokenComment)
    }

    const hasMore = useMemo(() => (listComment?.length ?? 0) < total, [listComment, total])

    const renderAttendanceBtn = (answer: string) => {
      switch (answer) {
        case "yes":
          return "出席"
        case "no":
          return "欠席"
        default:
          return "保留"
      }
    }
    const refreshPermission = useRefresher(teamPermissionRequestIdState)
    const goToCommentDetail = (item: ListCommentTeamMemberMailItem) => {
      if (contactType === ContactType.Survey) {
        navigation.navigate(ContactListCommentScreens.ContactSurveyCommentDetail, { contactId, type, item })
      } else if (contactType === ContactType.ScheduleAdjustment) {
        navigation.navigate(ContactListCommentScreens.ContactScheduleCommentDetail, { contactId, type, item })
      }
    }
    const [storeItem, setStoreItem] = useState<ListCommentTeamMemberMailItem | null>(null)
    const [isRefreshing, setIsRefreshing] = useState<boolean>(false)
    const checkGoToCommentDetail = (item: ListCommentTeamMemberMailItem) => {
      if (permissionLoading) {
        return
      }

      if (allowViewAnswerSummary) {
        refreshPermission()
        setIsRefreshing(true)
        setStoreItem(item)
      } else {
        goToCommentDetail(item)
      }
    }

    useEffect(() => {
      if (isRefreshing && !permissionLoading) {
        setIsRefreshing(false)
        if (!allowViewAnswerSummary) {
          CustomAlert.alert("エラー", "アクセス権限がありません。", "閉じる")
        } else {
          storeItem && goToCommentDetail(storeItem)
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [permissionLoading])

    const CommentCard = (commentItem: ListRenderItemInfo<ListCommentTeamMemberMailItem>) => {
      const { item } = commentItem

      return (
        <>
          {
            <View
              style={[
                styles.CommentCardContainer,
                isLargeScreen && {
                  marginLeft: "20%",
                  marginRight: "20%",
                  width: "60%",
                },
              ]}
            >
              <View style={[styles.CommentCardHeader]}>
                <Image
                  style={[styles.CommentImage]}
                  source={item.teamMember.deleteFlg ? defaultUser : item?.imageUrl ? { uri: item?.imageUrl } : defaultThumbnail}
                />
                <View style={[styles.CommentHeaderRight]}>
                  <Pressable
                    onPress={() => {
                      if (item.teamMember.deleteFlg) return
                      navigation.navigate(Screens.OrganizationMemberDetail, {
                        organizationId: selectedOrganization?.id ?? "",
                        memberId: item?.teamMember?.id,
                        imageUrl: item?.imageUrl ?? undefined,
                        allowEditMember,
                      })
                    }}
                  >
                    <Text style={[styles.CommentName]}>
                      {item.teamMember.nickname} {item.teamMember.deleteFlg ? "（脱退）" : ""}
                    </Text>
                  </Pressable>
                  {allowViewAnswerSummary && item.answeredAt && isShowAnswer(item.teamMember.groups.items) ? (
                    <View style={[styles.AnswerWrapper]}>
                      <Text style={[styles.CommentDueDate, { marginBottom: 8 }]}>
                        回答日時: {convertDate(item.answeredAt ?? "")}
                      </Text>
                      {contactType === ContactType.Attendance && item.attendanceAnswer && (
                        <View style={[styles.AnswerButton]}>
                          <Text style={[styles.AnswerText]}>{renderAttendanceBtn(item.attendanceAnswer)}</Text>
                        </View>
                      )}
                      {(item.questionnaireAnswers || item.scheduleAnswers) && (
                        <Button style={[styles.ButtonAnswer]} onPress={() => checkGoToCommentDetail(item)}>
                          <Text style={[styles.TextButtonAnswer]}>回答詳細</Text>
                        </Button>
                      )}
                    </View>
                  ) : (
                    <></>
                  )}
                  {item.commentUpdatedAt && (
                    <Text style={[styles.CommentDueDate]}>
                      {allowViewAnswerSummary && contactType !== ContactType.Normal ? "回答コメント送信日時: " : "送信日時: "}
                      {convertDate(item.commentUpdatedAt ?? "")}
                    </Text>
                  )}
                </View>
              </View>
              <View style={{ width: "100%", paddingLeft: 40 }}>
                {item.commentUpdatedAt && <CollapseText>{item.comment}</CollapseText>}
              </View>
            </View>
          }
        </>
      )
    }

    return (
      <CustomKeyboardAvoidingView style={[styles.container, { backgroundColor: Colors.backgroundColor }]}>
        <FlatList
          data={listComment?.filter((item) => {
            return !(!isShowAnswer(item.teamMember.groups.items) && !item.commentUpdatedAt)
          })}
          renderItem={CommentCard}
          ListEmptyComponent={
            !isLoading ? (
              <MatchingListEmpty
                label={
                  !allowViewAnswerSummary || contactType === ContactType.Normal
                    ? "コメントはありません。"
                    : "回答はありません。"
                }
              />
            ) : undefined
          }
          refreshing={isLoading}
          refreshControl={
            <CustomRefreshControl
              refreshing={isLoading}
              onRefresh={() => {
                refresh()
              }}
            />
          }
          onEndReached={!isLoading && hasMore ? () => loadMore() : undefined}
          keyExtractor={(item) => item.id}
          ListFooterComponent={
            isLoading ? (
              <View style={styles.loadingIndicator}>
                <LoadingIndicator />
              </View>
            ) : (
              <></>
            )
          }
        />
        {isPermissionLoading && (
          <TouchableWithoutFeedback
            onPress={() => {
              /* Prevent interaction */
            }}
          >
            <View style={styles.loadingOverlay}>
              <LoadingIndicator />
            </View>
          </TouchableWithoutFeedback>
        )}
      </CustomKeyboardAvoidingView>
    )
  }
)

export const ContactListCommentOverviewOption = {
  ...commonModalOptions,
  title: "コメント一覧",
  headerLeft: CloseModalHeaderLeftButton,
}

const styles = StyleSheet.create({
  loadingOverlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    justifyContent: "center",
    alignItems: "center",
  },
  container: {
    backgroundColor: Colors.white3,
    paddingVertical: 24,
    width: "100%",
  },
  LargeContainer: {
    backgroundColor: Colors.white3,
    paddingVertical: 24,
    marginLeft: "20%",
    marginRight: "20%",
    width: "60%",
    borderRadius: 10,
  },
  loadingIndicator: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  CommentCardContainer: {
    width: "100%",
    paddingHorizontal: 16,
    alignItems: "center",
    borderBottomColor: Colors.borderColor,
    borderBottomWidth: 1,
    paddingBottom: 16,
    marginBottom: 16,
  },
  CommentCardHeader: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  CommentImage: {
    marginRight: 8,
    width: 32,
    height: 32,
    borderRadius: 16,
    alignSelf: "flex-start",
  },
  CommentHeaderRight: {
    flexDirection: "column",
    alignItems: "flex-start",
  },
  CommentName: {
    width: "100%",
    fontWeight: "600",
    fontSize: 15,
    lineHeight: 19.6,
    color: Colors.greyshBrown,
    marginBottom: 2,
  },
  CommentDueDate: {
    fontSize: 11,
    lineHeight: 15.4,
    fontWeight: "300",
    color: Colors.warmGrey3,
  },
  CommentContent: {
    width: "100%",
    flexDirection: "row",
    position: "relative",
    marginTop: 16,
  },
  CommentText: {
    fontSize: 14,
    lineHeight: 19.6,
    color: Colors.greyshBrown,
    width: "100%",
  },
  CommentBottom: {
    width: "100%",
    marginTop: 8,
    flexDirection: "row",
    justifyContent: "center",
  },
  ShowMoreBtn: {
    flexDirection: "row",
    alignItems: "center",
    cursor: "pointer",
  },
  ShowMoreText: {
    fontWeight: "300",
    fontSize: 14,
    lineHeight: 19.6,
    marginLeft: 4,
    color: Colors.orange,
  },
  AnswerWrapper: {
    marginBottom: 16,
    alignItems: "flex-start",
    width: "100%",
  },
  AnswerButton: {
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 4,
    backgroundColor: Colors.warmGrey3,
  },
  AnswerText: {
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 16.8,
    color: Colors.white3,
  },
  ButtonAnswer: {
    paddingVertical: 4,
    paddingHorizontal: 12,
    borderRadius: 4,
    backgroundColor: Colors.orange1,
  },
  TextButtonAnswer: {
    fontWeight: "600",
    fontSize: 12,
    lineHeight: 16.8,
    color: "#F0830E",
  },
})
