import { atom } from "recoil"

export const invitationEmailsRequestIdState = atom({
  key: "atoms/organization/invitationEmailsRequestIdState",
  default: 0,
})

export const invitationEmailsConcatRequestIdState = atom({
  key: "atoms/organization/invitationEmailsConcatRequestIdState",
  default: 0,
})

export const invitationCodeState = atom({
  key: "atoms/organization/invitationCodeState",
  default: "",
})
