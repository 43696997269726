import { memo, useCallback } from "react"
import { FlatList, ListRenderItemInfo, Platform, StyleSheet, Text, useWindowDimensions, View } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Button } from "src/components/parts/buttons/Button"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { Checkbox } from "src/components/parts/Checkbox"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { MemberListItem } from "src/components/parts/organizationTab/MemberListItem"
import { SearchInput } from "src/components/parts/SearchInput"
import { Colors } from "src/constants/Colors"
import { CreateNewContactScreens } from "src/constants/CreateNewContactScreens"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useSelectMembersScreenData } from "src/recoil/hooks/screens/contact/create/useSelectMembersScreenData"
import { TeamMemberTypeResponse } from "src/recoil/selectors/organization/organizationMembersPaginationSelectorFamily"
import { CreateNewContactStackScreenProps } from "src/types.d"

type Props = CreateNewContactStackScreenProps<typeof CreateNewContactScreens.SelectMembers>

export const SelectMembersScreen = memo<Props>(({ navigation }) => {
  const widthScreen = useWindowDimensions().width
  const isLargeScreen = useCheckPCScreen()
  const goToCreateNewContactScreen = useCallback(
    () => navigation.navigate(CreateNewContactScreens.CreateNewContact),
    [navigation]
  )
  const {
    showSearchText,
    handleChangeTextSearch,
    filteredMembers,
    toggleMember,
    selectMembers,
    getMoreTeamMembers,
    isLoadingMoreMembers,
    limitPageSize,
  } = useSelectMembersScreenData({
    goToCreateNewContactScreen,
    isLargeScreen,
  })

  const MemberRow = useCallback(
    (info: ListRenderItemInfo<TeamMemberTypeResponse & { isChecked?: boolean }>) => {
      const select = () => {
        toggleMember(info.item.id)
      }

      return (
        <View
          style={{
            borderBottomWidth: 1,
            borderColor: info.item.isChecked || filteredMembers?.[info.index + 1]?.isChecked ? Colors.orange : Colors.white2,
            backgroundColor: info.item.isChecked ? Colors.pale : Colors.white3,
          }}
        >
          <Button style={styles.button} onPress={select}>
            <MemberListItem
              mode="createContact"
              id={info.item.id}
              name={`${info.item.nickname} (${info.item.memberNumber})`}
              imageUrl={info.item.imageUrl}
              memberType={info.item.role}
              isLeader={info.item.isLeader ?? false}
              isSelectedForCheckbox={info.item.isChecked}
              isCustomImg={true}
            />
            <Checkbox isChecked={info.item.isChecked} onPress={select} />
          </Button>
        </View>
      )
    },
    [toggleMember, filteredMembers]
  )

  const insets = useSafeAreaInsets()

  const MembersFooterFlatList = memo(() => {
    if (!isLoadingMoreMembers)
      return Number(filteredMembers?.length) > 0 ? null : (
        <View style={styles.loading}>
          <Text style={[styles.noRecordText]}>一致するメンバーはいません</Text>
        </View>
      )
    return (
      <View style={styles.loading}>
        <LoadingIndicator />
      </View>
    )
  })

  return (
    <CustomKeyboardAvoidingView
      style={
        isLargeScreen
          ? {
              height: "100%",
              position: "absolute",
            }
          : {}
      }
    >
      <View style={styles.titleContainer}>
        <Text style={styles.title}>宛先に追加するメンバーを選択してください</Text>
      </View>
      <View style={{ justifyContent: "center", alignItems: "center" }}>
        <View style={isLargeScreen ? { width: 600 } : { width: "100%" }}>
          <SearchInput
            value={showSearchText}
            onChangeText={handleChangeTextSearch}
            placeholder="名前で検索"
            style={styles.search}
          />
        </View>
      </View>
      <FlatList
        style={[isLargeScreen ? { paddingVertical: 5, paddingHorizontal: (widthScreen - 600) / 2 } : {}]}
        data={filteredMembers}
        renderItem={MemberRow}
        keyExtractor={(item) => item.id.toString()}
        contentContainerStyle={[
          {
            borderTopWidth: 1,
            borderColor:
              filteredMembers != null && filteredMembers.length !== 0 && filteredMembers[0].isChecked
                ? Colors.orange
                : Colors.white2,
            // Web版の場合はheightを指定しないとスクロールできないため、適当に100を指定
            ...(Platform.OS === "web"
              ? { height: isLargeScreen && (filteredMembers?.length ?? 0) <= limitPageSize ? "101%" : 100 }
              : {}),
          },
          isLargeScreen ? { width: 600 } : {},
        ]}
        ListFooterComponent={<MembersFooterFlatList />}
        onEndReached={getMoreTeamMembers}
      />
      <View style={!filteredMembers?.length || !isLargeScreen ? {} : { position: "relative", backgroundColor: Colors.white3 }}>
        <View
          style={[
            styles.action,
            { paddingBottom: insets.bottom + 10, paddingTop: 10 },
            isLargeScreen ? { justifyContent: "center", alignItems: "center" } : {},
          ]}
        >
          <TextButton
            style={isLargeScreen ? { width: 300 } : {}}
            buttonType={ButtonType.Primary}
            onPress={selectMembers}
            title="決定"
          />
        </View>
      </View>
    </CustomKeyboardAvoidingView>
  )
})

export const selectMembersScreenOptions = {
  title: "メンバーを選択",
}

const styles = StyleSheet.create({
  titleContainer: {
    alignItems: "center",
    paddingVertical: 16,
  },
  title: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.warmGrey,
  },
  action: {
    borderTopWidth: 1,
    borderColor: Colors.white2,
    paddingHorizontal: 24,
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 16,
  },
  search: {
    borderBottomWidth: 0,
  },
  button: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 20,
  },
  loading: {
    paddingVertical: 15,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  noRecordText: {
    marginTop: 8,
    marginLeft: 16,
    color: Colors.warmGrey,
    fontSize: 16,
  },
})
