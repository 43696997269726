import React, { memo, useState, useCallback, useMemo, useEffect } from "react"
import { RootStackScreenProps } from "src/types.d"
import { Colors } from "src/constants/Colors"
import { View, Text, StyleSheet, Linking, Platform } from "react-native"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { Screens } from "src/constants/Screens"
import { oldRa9Ra9Login } from "src/apis/migration/oldRa9Ra9Api"
import { CustomAlert } from "src/utils/CustomAlert"
import { oldRa9Ra9TokenStates } from "src/recoil/atoms/migration/oldRa9Ra9TokenStates"
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil"
import { validateEmail } from "src/utils/validateEmail"
import { getOldRa9Ra9AuthStateFromResult } from "src/utils/getOldRa9Ra9AuthStateFromResult"
import Constants from "expo-constants"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { Button } from "src/components/parts/buttons/Button"
import { useSignout } from "src/recoil/hooks/authorization/useSignout"
import { ConfirmModal } from "src/components/parts/Modal"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { migrateAccountDataState } from "src/recoil/atoms/account/migrateAccountDataState"
import { accountRequestIdState } from "src/recoil/atoms/account/accountRequestIdState"
import { firstNameState } from "src/recoil/atoms/account/firstNameState"
import { lastNameState } from "src/recoil/atoms/account/lastNameState"
import { kanaFirstNameState } from "src/recoil/atoms/account/kanaFirstNameState"
import { kanaLastNameState } from "src/recoil/atoms/account/kanaLastNameState"
import { birthDateState } from "src/recoil/atoms/account/birthDateState"
import { genderState } from "src/recoil/atoms/account/genderState"
import { jobIdState } from "src/recoil/atoms/account/jobIdState"
import { schoolDepartmentIdState } from "src/recoil/atoms/account/schoolDepartmentIdState"
import { schoolIdState } from "src/recoil/atoms/account/schoolIdState"
import { questionAnswersState } from "src/recoil/atoms/account/questionAnswersState"
import { ScrollView } from "react-native-gesture-handler"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
const useMigrationLogin = (navigation?: any) => {
  const [loginId, setLoginId] = useState("")
  const [password, setPassword] = useState("")
  const [isSending, setIsSending] = useState(false)
  const [migrateAccountData, setMigrateAccountData] = useRecoilState(migrateAccountDataState)
  const setAccountRequestIdState = useSetRecoilState(accountRequestIdState)
  const refreshAccount = useCallback(() => {
    setAccountRequestIdState((n) => n + 1)
  }, [setAccountRequestIdState])
  const resetFirstName = useResetRecoilState(firstNameState)
  const resetLastName = useResetRecoilState(lastNameState)
  const resetKanaFirstName = useResetRecoilState(kanaFirstNameState)
  const resetKanaLastName = useResetRecoilState(kanaLastNameState)
  const resetBirthDate = useResetRecoilState(birthDateState)
  const resetGender = useResetRecoilState(genderState)
  const resetJobId = useResetRecoilState(jobIdState)
  const refreshSchoolId = useResetRecoilState(schoolIdState)
  const refreshSchoolDepartment = useResetRecoilState(schoolDepartmentIdState)
  const refreshQuestionAnswers = useResetRecoilState(questionAnswersState)

  const refreshFormData = useCallback(() => {
    refreshAccount()
    resetFirstName()
    resetLastName()
    resetKanaFirstName()
    resetKanaLastName()
    resetBirthDate()
    resetGender()
    resetJobId()
    refreshSchoolId()
    refreshSchoolDepartment()
    refreshQuestionAnswers()
  }, [
    refreshAccount,
    resetFirstName,
    resetLastName,
    resetKanaFirstName,
    resetKanaLastName,
    resetBirthDate,
    resetGender,
    resetJobId,
    refreshSchoolId,
    refreshSchoolDepartment,
    refreshQuestionAnswers,
  ])
  const loginIdError = useMemo(
    () => (loginId && validateEmail(loginId) ? "" : "正しいメールアドレスを入力してください"),
    [loginId]
  )
  const passwordError = useMemo(() => (password !== "" ? "" : "パスワードを入力してください"), [password])

  const isDisabled = useMemo(
    () => loginIdError !== "" || passwordError !== "" || isSending,
    [loginIdError, passwordError, isSending]
  )
  const setOldRa9Ra9AccessTokens = useSetRecoilState(oldRa9Ra9TokenStates)
  const onLogin = useCallback(() => {
    setIsSending(true)
    loginId &&
      password &&
      oldRa9Ra9Login(loginId, password)
        .then(async (res) => {
          if (res.status === 200) {
            const { access_token, user_id } = await res.json()
            if (access_token == null || user_id == null) {
              setIsSending(false)
              return
            }
            const oldRa9Ra9AuthState = getOldRa9Ra9AuthStateFromResult({
              access_token,
              user_id: user_id.toString(),
            })
            if (oldRa9Ra9AuthState == null) {
              setIsSending(false)
              return
            }
            setOldRa9Ra9AccessTokens(oldRa9Ra9AuthState)
            setMigrateAccountData(true)
            if (Platform.OS === "web") {
              navigation.navigate(Screens.RegisterAccount)
            } else {
              navigation.goBack()
            }
          } else {
            setIsSending(false)
            await CustomAlert.alert("エラーコード019", "メールアドレスまたはパスワードが正しくありません。")
            return
          }
          setIsSending(false)
        })
        .catch((e) => {
          setIsSending(false)
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginId, password, setOldRa9Ra9AccessTokens, navigation])

  return {
    loginId,
    setLoginId,
    password,
    setPassword,
    onLogin,
    isDisabled,
    isSending,
    setOldRa9Ra9AccessTokens,
    migrateAccountData,
    setMigrateAccountData,
    refreshFormData,
  }
}

export const MigrateOrRegisterAccount = memo<RootStackScreenProps<"MigrateOrRegisterAccount">>(({ navigation, route }) => {
  const { email } = useMemo(() => route.params, [route.params])

  const [passwordVisibility] = useState(true)
  const isLargeScreen = useCheckPCScreen()

  const {
    loginId,
    password,
    isSending,
    isDisabled,
    setLoginId,
    setPassword,
    onLogin,
    setOldRa9Ra9AccessTokens,
    migrateAccountData,
    setMigrateAccountData,
    refreshFormData,
  } = useMigrationLogin(navigation)

  const { signout } = useSignout()
  const [signoutConfirmModal, setSignoutConfirmModal] = useState(false)

  const handleSignOut = async () => {
    setSignoutConfirmModal(false)
    await signout()
  }
  const signOutAction = async () => {
    if (Platform.OS === "web") {
      const isConfirmed = await CustomAlert.confirm("確認", "本当にログアウトしますか？")
      if (isConfirmed) {
        await handleSignOut()
      }
    } else {
      setSignoutConfirmModal(true)
    }
  }

  const urlOld = () => {
    let domain = "spitz.ra9.jp"
    if (Constants.expoConfig?.extra?.env == "prd") {
      domain = "ra9.jp"
    }
    if (Constants.expoConfig?.extra?.env == "stg") {
      domain = "rev.ra9.jp"
    }
    return domain
  }
  const goBack = useCallback(() => {
    setOldRa9Ra9AccessTokens(undefined)
    refreshFormData()
    setMigrateAccountData(true)
    if (Platform.OS === "web") {
      navigation.navigate(Screens.RegisterAccount as any)
    } else {
      navigation.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setOldRa9Ra9AccessTokens, navigation, setMigrateAccountData])

  const forgotMyPassword = async () => {
    const url = urlOld()
    await Linking.openURL(`https://${url}/reminders/`)
  }
  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <>
          <View style={{ marginLeft: 8 }}>
            <Button onPress={goBack} style={{ padding: 8 }}>
              <RemoveIcon size={16} fill={Colors.orange} />
            </Button>
          </View>
        </>
      ),
    })
  }, [navigation, goBack, isLargeScreen])

  return (
    <CustomKeyboardAvoidingView style={[styles.container]} behavior="position">
      <ScrollView style={[isLargeScreen ? {} : { width: "100%" }]}>
        <ConfirmModal
          visible={signoutConfirmModal}
          closeAction={() => setSignoutConfirmModal(false)}
          content="本当にログアウトしますか？"
          okAction={handleSignOut}
        />

        <View
          style={[
            styles.logoutContainer,
            isLargeScreen ? styles.oneColumn : { width: "100%" },
            Platform.OS === "android" ? { borderTopWidth: 1, borderTopColor: Colors.lightGrey } : null,
            { paddingVertical: 12 },
          ]}
        >
          <Text style={[{ fontSize: 14, fontWeight: "600" }]}>{email}</Text>
          <View style={{ flex: 1 }} />
          <Button onPress={signOutAction}>
            <Text style={[styles.logOutBtn]}>ログアウト</Text>
          </Button>
        </View>

        <View style={[isLargeScreen ? styles.oneColumn : { width: "100%", padding: 20 }, { paddingTop: 32 }]}>
          <Text style={[styles.title]}>
            {
              "らくらく連絡網（旧サービス）をご利用中の方は個人設定の情報を、らくらく連絡網＋のアカウント情報に引継げます。\nらくらく連絡網（旧サービス）に登録したメールアドレスとパスワードを入力し「認証」ボタンを押してください。"
            }
          </Text>
          <View style={styles.vertical}>
            <ItemLabel label={"メールアドレス"} />
            <CustomTextInput
              placeholder={"入力してください"}
              value={loginId}
              onChangeText={setLoginId}
              style={{ marginVertical: 10 }}
              autoComplete="off"
              keyboardType="email-address"
              autoCapitalize="none"
            />

            <ItemLabel label={"パスワード"} />
            <CustomTextInput
              placeholder={"入力してください"}
              secureTextEntry={passwordVisibility}
              value={password}
              onChangeText={setPassword}
              style={{ marginVertical: 10 }}
              autoComplete="off"
            />
          </View>
          <View style={isLargeScreen ? styles.centerLayout : {}}>
            <TextButton
              buttonType={ButtonType.Primary}
              title={"認証"}
              isLoading={isSending}
              disabled={isDisabled}
              onPress={onLogin}
              style={isLargeScreen ? styles.buttonsPC : { marginBottom: 20 }}
            />
            <TextButton
              buttonType={ButtonType.Blank}
              title={"パスワードを忘れた方はこちら"}
              onPress={forgotMyPassword}
              style={
                isLargeScreen
                  ? styles.buttonsPCForgot
                  : {
                      marginBottom: 20,
                      borderRadius: 0,
                      borderTopColor: Colors.white2,
                      borderBottomColor: Colors.white2,
                      borderTopWidth: 1,
                      borderBottomWidth: 1,
                      marginHorizontal: -20,
                      marginTop: 12,
                    }
              }
            />
          </View>
        </View>
      </ScrollView>
    </CustomKeyboardAvoidingView>
  )
})
MigrateOrRegisterAccount.displayName = "MigrateOrRegisterAccount"

export const MigrateOrRegisterAccountOptions: ScreenOptions = {
  ...commonSingleModalOptions,
  title: "らくらく連絡網認証",
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.beige,
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  oneColumn: {
    width: 600,
    maxWidth: 1020,
    paddingLeft: 30,
    paddingRight: 30,
    paddingTop: 20,
  },
  vertical: {
    paddingVertical: 10,
  },
  buttonsPC: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    borderRadius: 26,
    alignContent: "center",
    marginBottom: 20,
  },
  buttonsPCForgot: {
    maxWidth: 300,
    width: 300,
    fontSize: 18,
    padding: 10,
    alignContent: "center",
    marginBottom: 20,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderTopColor: Colors.white2,
    borderBottomColor: Colors.white2,
    borderRadius: 0,
    marginTop: 12,
  },
  centerLayout: {
    display: "flex",
    alignItems: "center",
  },
  title: {
    fontSize: 14,
    fontWeight: "300",
    textAlign: "left",
    marginBottom: 15,
    lineHeight: 19.6,
    fontStyle: "normal",
    fontFamily: "Hiragino Kaku Gothic Pro",
  },
  textTitle: {
    fontSize: 16,
    width: "100%",
    textAlign: "center",
    marginBottom: 15,
  },
  logoutContainer: {
    paddingHorizontal: 16,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottomWidth: 1,
    borderBottomColor: Colors.lightGrey,
  },
  logOutBtn: {
    paddingVertical: 8,
    paddingHorizontal: 16,
    fontSize: 14,
    fontWeight: "600",
    borderRadius: 4,
    backgroundColor: "rgba(159,134,107,0.1)",
    minWidth: 98,
    overflow: "hidden",
  },
})
