import { atomFamily, selectorFamily } from "recoil"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"

export const thumbnailStateFamily = atomFamily<string | undefined, string>({
  key: "atoms/organization/myProfile/thumbnailStateFamily",
  default: selectorFamily<string | undefined, string>({
    key: "atoms/organization/myProfile/thumbnailStateFamily_default",
    get:
      (organizationId) =>
      ({ get }) => {
        const myProfile = get(myProfileSelectorFamily(organizationId))
        return myProfile?.thumbnailURL
      },
  }),
})
