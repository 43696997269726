import { Result } from "src/utils/Result"
import { updateDraft as updateDraftApi } from "src/aws/customAPI"
import { AnswerSelectType, MailType, QuestionnaireInput } from "src/aws/API"
import { Question } from "src/constants/Questions"
import { AnswerType } from "src/constants/AnswerType"
import { CreateNewContactRequestParams } from "src/types/contact/CreateNewContactRequestParams"
import { ContactType } from "src/constants/ContactType"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import { convertDateTimeCandidate } from "src/utils/convertDateTimeCandidate"


type UpdateDraftRequestParams = {
  accessToken: string
  draftId: string
} & Partial<CreateNewContactRequestParams>

type UpdateDraftSuccess = unknown
type UpdateDraftFailure = {
  message: string
}

export const updateDraft = async (params: UpdateDraftRequestParams): Promise<Result<UpdateDraftSuccess, UpdateDraftFailure>> => {
  const { accessToken, ...c } = params
  if (c.contactType == null || c.title == null || c.body == null) {
    return Result.Error<UpdateDraftFailure>({message: "システムエラーが発生しました。"})
  }

  return execApi(
    () => updateDraftApi(accessToken, {
      input: {
        id: c.draftId,
        mailType: c.contactType ? mailTypeMapping[c.contactType] : MailType.normal,
        title: c.title,
        body: c.body,
        sendTo: {
          allMembersFlg: c.receivers?.isAllMembers,
          managersFlg: c.receivers?.isAllLeaders,
          groupIds: c.receivers?.groupIds,
          memberIds: c.receivers?.memberIds,
        },
        questionnaires: c.contactType === "Survey" ? c.questions?.map(convertQuestionnaire) : undefined,
        candidateDatetimes: c.contactType === "ScheduleAdjustment" ? c.eventDateCandidates?.map(convertDateTimeCandidate) : undefined,
        shareManagerFlg: c.isAddToOtherAdminsHistory ? 1 : 0,
        commentEnableFlg: c.isEnableForComment,
        sendScheduledAt: c.reservationDate?.toISOString(),
        ...(c.contactType === "Attendance" || c.contactType === "Survey" || c.contactType === "ScheduleAdjustment" ? {
          showAnswerFlg: c.isPublishAnswersToMembers,
          responseDueDatetime: c.deadlineDate?.toISOString() || undefined,
          remindDatetime: c.reminderDate?.toISOString() || undefined
        } : {}),
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["updateDraft", "updateDraftApi"],
      defaultErrorMessage: "下書きの保存に失敗しました。"
    }
  )
}


const mailTypeMapping: {[key in ContactType]: MailType} = {
  [ContactType.Normal]: MailType.normal,
  [ContactType.Attendance]: MailType.attendance,
  [ContactType.Survey]: MailType.questionnaire,
  [ContactType.ScheduleAdjustment]: MailType.schedule,
  // 以下、使用しない
  Event: MailType.normal,
  Safety: MailType.normal
}

const answerTypeMapping: {[key in AnswerType]: AnswerSelectType} = {
  Single: AnswerSelectType.single,
  Multiple: AnswerSelectType.multiple,
}

const convertQuestionnaire = (question: Question): QuestionnaireInput => ({
  answerSelectType: answerTypeMapping[question.answerType],
  question: question.title,
  choices: question.choices,
})

