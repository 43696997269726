import { atom, selector } from "recoil"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"

export const kanaFirstNameState = atom({
  key: "atoms/account/kanaFirstNameState",
  default: selector({
    key: "atoms/account/kanaFirstNameState_default",
    get: ({ get }) => {
      const account = get(accountSelector)
      return account?.kanaFirstName
    },
  }),
})
