import { selector } from "recoil"
import { getHaveOldRa9TeamNotJoin } from "src/queries/team/getHaveOldRa9TeamNotJoin"
import { myListTeamRequestIdState } from "src/recoil/atoms/team/myListTeamRequestIdState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"

export const haveOldRa9TeamJoinSelector = selector({
  key: "selectors/team/haveOldRa9TeamJoinSelector",
  get: async ({ get }) => {
    get(myListTeamRequestIdState)
    const tokens = get(tokensState)
    const accountId = get(accountIdState)
    if (tokens == null || accountId == null) return []

    return await getHaveOldRa9TeamNotJoin({
      accessToken: tokens.accessToken,
      accountId,
    })
  },
})
