import { memo, useCallback, useMemo, useState } from "react"
import { FlatList, ListRenderItemInfo, StyleSheet, Text, View, useWindowDimensions } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { SearchInput } from "src/components/parts/SearchInput"
import { Colors } from "src/constants/Colors"
import { Button } from "src/components/parts/buttons/Button"
import type { CreateOrEditOrganizationStackScreenProps } from "src/types.d"
import { CreateOrEditOrganizationScreens } from "src/constants/CreateOrEditOrganizationScreens"
import { organizationCategorySelector } from "src/recoil/selectors/organization/organizationCategorySelector"
import { OrganizationSubCategoryMaster } from "src/types/organization/OrganizationCategoryMaster"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

const numColumns = 2

export const SelectSubCategory = memo<
  CreateOrEditOrganizationStackScreenProps<typeof CreateOrEditOrganizationScreens.SelectSubCategory>
>(({ navigation, route }) => {
  const widthScreen = useWindowDimensions().width
  const isLargeScreen = useCheckPCScreen()
  const category = useMemo(() => route.params.category, [route.params])
  if (category == null) {
    throw new Error("Select category at SelectCategory.tsx and pass it to navigation")
  }
  const { value: categoryMaster } = useAsyncSelector(organizationCategorySelector)
  const [partialMatch, setPartialMatch] = useState("")
  const subCategories = useMemo(() => {
    if (categoryMaster == null) return []
    return categoryMaster[category.id].subCategories
  }, [category, categoryMaster])
  const filteredSubCategories = useMemo(
    () => subCategories.filter((c) => c.label.includes(partialMatch)),
    [partialMatch, subCategories]
  )
  type CardProps = ListRenderItemInfo<OrganizationSubCategoryMaster>
  const Card = useCallback(
    ({ item, index }: CardProps) => (
      <Button
        style={[styles.card, index % numColumns === 0 ? styles.cardSpace : null]}
        onPress={() =>
          navigation.navigate(CreateOrEditOrganizationScreens.CreateOrEditOrganization, {
            ...route.params,
            subCategory: item,
          })
        }
      >
        <Text style={styles.cardText}>{item.label}</Text>
      </Button>
    ),
    [navigation, route]
  )
  const insets = useSafeAreaInsets()

  return (
    <View style={styles.container}>
      <View style={[styles.descriptionContainer, isLargeScreen ? { borderBottomWidth: 1, borderColor: Colors.white2 } : {}]}>
        <Text style={styles.description}>活動内容を選択してください</Text>
      </View>
      <View style={[isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : { width: "100%" }]}>
        <SearchInput style={styles.search} placeholder="行うことで検索" value={partialMatch} onChangeText={setPartialMatch} />
      </View>
      <FlatList
        style={[isLargeScreen ? { paddingVertical: 5, paddingHorizontal: (widthScreen - 600) / 2 } : styles.subCategories]}
        data={filteredSubCategories}
        renderItem={Card}
        numColumns={numColumns}
        contentInset={{ top: 15, bottom: insets.bottom }}
        contentContainerStyle={[isLargeScreen ? { width: 600 } : {}]}
      />
    </View>
  )
})
SelectSubCategory.displayName = "SelectSubCategory"

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white,
    flex: 1,
  },
  descriptionContainer: {
    padding: 16,
    backgroundColor: Colors.white3,
  },
  description: {
    color: Colors.warmGrey,
    fontSize: 15,
    fontWeight: "bold",
    textAlign: "center",
  },
  search: {
    backgroundColor: Colors.white,
  },
  subCategories: {
    paddingHorizontal: 18,
  },
  card: {
    flex: 1 / numColumns,
    backgroundColor: Colors.white3,
    borderRadius: 6,
    marginVertical: 3,
    justifyContent: "center",
    alignItems: "flex-start",
    borderColor: Colors.white,
  },
  cardSpace: {
    marginRight: 10,
  },
  cardText: {
    fontSize: 14,
    fontWeight: "bold",
    lineHeight: 20,
    color: Colors.greyshBrown,
    margin: 21,
  },
})
