import { memo } from "react"
import Svg, { Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
  size?: number
}

export const RemoveIcon = memo<Props>(({ fill = Colors.greyshBrown, size = 12.874 }) => (
  <Svg width={size} height={size} viewBox="0 0 12.874 12.874">
    <Path
      d="m-443.6 18.194-4.268-4.268-4.267 4.268a1.272 1.272 0 0 1-1.8 0 1.27 1.27 0 0 1 0-1.8l4.268-4.268-4.268-4.267a1.27 1.27 0 0 1 0-1.8 1.27 1.27 0 0 1 1.8 0l4.267 4.268 4.268-4.268a1.269 1.269 0 0 1 1.8 0 1.27 1.27 0 0 1 0 1.8l-4.267 4.267 4.267 4.268a1.27 1.27 0 0 1 0 1.8 1.266 1.266 0 0 1-.9.372 1.268 1.268 0 0 1-.9-.372z"
      transform="translate(454.308 -5.692)"
      fill={fill}
    />
  </Svg>
))
