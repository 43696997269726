import { LocalDocument, LocalImage } from "src/types/resource/LocalResource"
import { Result } from "src/utils/Result"

export type UseFileManager = (param: {
  setLocalImage: (localImage: LocalImage) => void
  onLocalImageChange?: (localImage: LocalImage) => boolean
  setLocalDocument: (localDocument: LocalDocument) => void
  onLocalDocumentChange?: (localDocument: LocalDocument) => boolean
}) => FileManager

export type FileManager = {
  pickImage: (param: {
    maxSizeInMB: number
    altFileName: string
    allowsEditing?: boolean
    messageErrorMaxSizeFile?: string
  }) => Promise<void>

  pickDocument: (param: { maxSizeInMB: number; altFileName: string; messageErrorMaxSizeFile?: string }) => Promise<void>

  upload: (param: { localUri: string; putUrl: string }) => Promise<Result<undefined, { message: string }>>
}

export const IMAGE_MIME_TYPES = ["image/jpeg", "image/gif", "image/png"]

export const DOCUMENT_MIME_TYPES = [
  "image/jpeg",
  "image/gif",
  "image/png",
  "text/plain",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/vnd.ms-powerpoint",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/pdf",
  "text/csv",
]
