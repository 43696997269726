import { memo } from "react"
import { Svg, Rect } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
}

export const OrangeCircleIcon = memo<Props>(({ fill = Colors.orange }) => {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 17">
      <Rect y="0.5" width="16" height="16" rx="8" fill={fill} />
    </Svg>
  )
})
