import type { ValueOf } from "src/types.d"

export enum EnumMatchingPurpose {
  jointPracticeGame = "jointPracticeGame",
}

export const MatchingPurpose = Object.freeze({
  jointPracticeGame: "jointPracticeGame",
} as const)

export type MatchingPurpose = ValueOf<typeof MatchingPurpose>

export const MatchingPurposeLabel = {
  [MatchingPurpose.jointPracticeGame]: "合同練習・試合",
}

export const MatchingPurposeMapping: { [key in EnumMatchingPurpose]: MatchingPurpose } = {
  [EnumMatchingPurpose.jointPracticeGame]: MatchingPurpose.jointPracticeGame,
}
