import { selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { addMemberSelectorOverview } from "src/types/organization/OrganizationMember"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"
import { getTeamMembersByName } from "src/apis/organization/getTeamMembesByName"
import { teamMemberRequestState } from "src/recoil/atoms/team/teamMemberRequestState"
import { MemberRole, accountImages } from "src/aws/customAPI"
import { s3ImageDomain } from "src/utils/s3ImageDomain"

export const addMemberSelectorFamily = selectorFamily<
  {
    data: addMemberSelectorOverview[] | undefined
    total: number | undefined
    page: number | undefined
    limit: number | undefined
  },
  {
    organizationId: string
    getImage: boolean
    joinFlg?: boolean
    groupId: string
    name?: string
    limit?: number
    page: number
    mode?: string
    excludedIds: string[]
    isRoleSort?: boolean
  }
>({
  key: "selectors/organization/addMemberSelectorFamily",
  get:
    ({ organizationId, getImage, joinFlg, groupId, name, page, excludedIds, limit, mode, isRoleSort }) =>
    async ({ get }) => {
      const defaultReturnValue = { data: undefined, total: undefined, page: undefined, limit: undefined }
      if (!organizationId) return defaultReturnValue
      get(teamMemberRequestState)
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null || page < 1) {
        return defaultReturnValue
      }
      const accountId = get(accountIdState)
      if (accountId == null) {
        return defaultReturnValue
      }
      const result = await getTeamMembersByName({
        accessToken: accessToken,
        teamId: organizationId,
        name: name ?? "",
        limit,
        groupId: mode ? "" : groupId,
        excludedAllGroup: mode ? true : undefined,
        page: page,
        excludedIds: excludedIds,
        isRoleSort: isRoleSort,
      })
      if (!result.isOk) {
        return defaultReturnValue
      }
      if (!getImage) {
        return defaultReturnValue
      }

      const convertRole = (value: MemberRole) => {
        if (value == "mailSender") return "GeneralWithContactAuthorization"
        else if (value == "manager") return "Representative"
        return "General"
      }

      const accountIds = result.content.data
        .filter((item) => !!item.accountTeamMembersId)
        .map((item) => item.accountTeamMembersId as string)

      const accountImagesResult = await accountImages(accessToken, {
        input: {
          accountIds,
        },
      })
      const accountImageMap: Record<string, string> =
        accountImagesResult.data?.multiAccountImageGetUrl.images?.reduce((map, ele) => {
          return {
            ...map,
            [ele.accountId]: [ele.url],
          }
        }, {}) ?? {}
      return {
        data:
          result.content.data.map((item) => ({
            id: item.id ? item.id : "",
            nickname: item.nickname,
            nicknameKana: item.nicknameKana,
            imageUrl: `${s3ImageDomain}${
              accountImageMap[item.accountTeamMembersId ?? ""] ?? "default/account/image/profile.png"
            }?${new Date().getTime()}`,
            memberNumber: item.memberSeq,
            memberType: convertRole(item.role),
            isLeader: item.groups.find((leader) => leader.leaderFlg) != null,
            isInGroup: item.groups.length > 0,
            isAdministrator: convertRole(item.role) === "Representative",
            groupIds: item.groups.map((e) => e.groupID).filter((id): id is string => id !== null && id !== undefined),
          })) || [],
        limit: result.content.limit,
        page: result.content.page,
        total: result.content.total,
      }
    },
})
