import { useCallback } from "react"
import { StyleSheet, Text, View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { Colors } from "src/constants/Colors"
import { YesIcon } from "src/components/parts/icons/threeToggleButton/YesIcon"
import { NoIcon } from "src/components/parts/icons/threeToggleButton/NoIcon"
import { PendIcon } from "src/components/parts/icons/threeToggleButton/PendIcon"

const getIcon = (index: number, isSelected: boolean) => {
  const fill = isSelected ? Colors.orange : Colors.coolGrey
  switch (index) {
    case 0:
      return <YesIcon fill={fill} />
    case 1:
      return <NoIcon fill={fill} />
    case 2:
      return <PendIcon fill={fill} />
  }
}

type Props<T> = {
  items: [{ label: string; value: T }, { label: string; value: T }, { label: string; value: T }]
  value?: T
  onValueChange: (value: T, index: number) => void
  withoutYesNoIcon?: boolean
  disabled?: boolean
  isLargeScreen?: boolean
}

export const AttendanceRadioButton = <T,>({
  value,
  items,
  onValueChange,
  withoutYesNoIcon,
  disabled,
  isLargeScreen,
}: Props<T>) => {
  const getStyles = useCallback((isActive: boolean) => {
    return isActive ? activeStyles : neutralStyles
  }, [])

  const styles = withoutYesNoIcon ? commonStylesWithoutYesNoIcon : commonStyles

  return (
    <View style={isLargeScreen ? commonStyles.containerLarge : styles.container}>
      {items.map((item, index) => (
        <Button
          style={[
            styles.button,
            getStyles(item.value === value).button,
            isLargeScreen &&
              index != 0 && {
                marginLeft: 20,
              },
          ]}
          onPress={() => onValueChange(item.value, index)}
          disabled={disabled}
          key={String(item.value)}
        >
          {!withoutYesNoIcon && getIcon(index, item.value === value)}
          <Text style={[styles.commonText, getStyles(item.value === value).text]}>{item.label}</Text>
        </Button>
      ))}
    </View>
  )
}

const commonStyles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  containerLarge: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  button: {
    borderRadius: 22,
    borderWidth: 1,
    paddingVertical: 12,
    paddingHorizontal: 20,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  commonText: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.black2,
    marginLeft: 8,
  },
})

const commonStylesWithoutYesNoIcon = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  button: {
    borderRadius: 25,
    borderWidth: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    width: "30%",
  },
  commonText: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.black2,
  },
})

const neutralStyles = StyleSheet.create({
  button: {
    borderColor: Colors.silver,
    backgroundColor: Colors.white7,
  },
  text: {
    color: Colors.coolGrey,
  },
})

const activeStyles = StyleSheet.create({
  button: {
    borderColor: Colors.orange,
    backgroundColor: Colors.pale,
  },
  text: {
    color: Colors.black2,
  },
})
