import { DOCUMENT_MIME_TYPES, FileManager, IMAGE_MIME_TYPES, UseFileManager } from "src/recoil/hooks/resource/fileManager/type"
import { useCallback } from "react"
import { LocalDocument, LocalImage } from "src/types/resource/LocalResource"
import { getExtension, isImageExt } from "src/recoil/hooks/resource/fileManager/common"
import { CustomAlert } from "src/utils/CustomAlert"
import { Result } from "src/utils/Result"
import { ImageExtension } from "src/aws/API"
import * as DocumentPicker from "expo-document-picker"

export const useFileManager: UseFileManager = ({
  setLocalImage,
  onLocalImageChange,
  setLocalDocument,
  onLocalDocumentChange,
}) => {
  const onLoadImage = useCallback(
    (fileName: string, fileSize: number, uri: string, extension: ImageExtension, image: HTMLImageElement) => {
      const localImage: LocalImage = {
        type: "image",
        uri,
        fileName: fileName,
        extension,
        sizeInB: fileSize,
        width: image.width,
        height: image.height,
      }

      if (onLocalImageChange) {
        const result = onLocalImageChange(localImage)
        if (!result) return
      }

      setLocalImage(localImage)
    },
    [setLocalImage, onLocalImageChange]
  )

  const pickFile = useCallback(
    async (param: {
      type: "image" | "document"
      maxSizeInMB: number
      altFileName?: string
      messageErrorMaxSizeFile?: string
    }) => {
      const { type, maxSizeInMB, altFileName } = param
      const documentResult = await DocumentPicker.getDocumentAsync({
        type: type === "image" ? IMAGE_MIME_TYPES : DOCUMENT_MIME_TYPES,
        multiple: false,
      })
      if (documentResult.canceled || documentResult.assets.length == 0) return undefined

      if (documentResult.assets[0].size == null) {
        await CustomAlert.alert("エラー", "ファイルサイズを取得できませんでした。")
        return undefined
      }
      const sizeInMB = documentResult.assets[0].size / 1024 ** 2
      if (sizeInMB > maxSizeInMB) {
        await CustomAlert.alert("エラー", param.messageErrorMaxSizeFile || `ファイルサイズが${maxSizeInMB}MBを超えています。`)
        return undefined
      }

      const extension = documentResult.assets[0] ? getExtension(documentResult.assets[0].name) : undefined
      if (!extension) {
        await CustomAlert.alert("エラー", "画像の形式が無効です。")
        return undefined
      }

      const file = documentResult.assets[0]
      const uri = documentResult.assets[0].uri

      if (isImageExt(extension)) {
        const image = new Image()
        // SPからimageのファイル名を取得するとuuidとなってしまうため、altFileNameを優先的に使用
        const fileName = altFileName || file.name

        image.onload = () => onLoadImage(fileName, file.size ?? 0, uri, extension as ImageExtension, image)
        image.onerror = () =>
          CustomAlert.alert(
            "エラー",
            "画像が破損しているかサポートされていない形式のため、画像が開けません。別の画像を選択してください。"
          )

        image.src = uri
      } else if (type === "image") {
        CustomAlert.alert("エラー", "画像の形式が無効です。")
      } else {
        const localDocument: LocalDocument = {
          type: "document",
          uri,
          fileName: file.name,
          extension,
          sizeInB: file.size ?? 0,
        }

        if (onLocalDocumentChange) {
          const result = onLocalDocumentChange(localDocument)
          if (!result) return
        }

        setLocalDocument(localDocument)
      }
    },
    [onLoadImage, onLocalDocumentChange, setLocalDocument]
  )

  const pickImage = useCallback<FileManager["pickImage"]>(
    async ({ maxSizeInMB, altFileName, messageErrorMaxSizeFile }) => {
      await pickFile({ type: "image", maxSizeInMB, altFileName, messageErrorMaxSizeFile })
    },
    [pickFile]
  )

  const pickDocument = useCallback<FileManager["pickDocument"]>(
    async ({ maxSizeInMB, altFileName, messageErrorMaxSizeFile }) => {
      await pickFile({ type: "document", maxSizeInMB, altFileName, messageErrorMaxSizeFile })
    },
    [pickFile]
  )

  const upload = useCallback<FileManager["upload"]>(async ({ localUri, putUrl }) => {
    const blob = await (await fetch(localUri)).blob()
    const result = await fetch(putUrl, {
      method: "PUT",
      body: blob,
    })

    return result.status === 200 ? Result.Ok(undefined) : Result.Error({ message: "ファイルのアップロードに失敗しました。" })
  }, [])

  return {
    pickImage,
    pickDocument,
    upload,
  }
}
