import { Platform, useWindowDimensions } from "react-native"

const PC_BREAKPOINT = 768
const PC_SMALL_BREAKPOINT = 1080

export const useCheckPCScreen = () => {
  const dimensions = useWindowDimensions()
  const isLargeScreen = dimensions.width >= PC_BREAKPOINT
  return Platform.OS === "web" && isLargeScreen
}

export const useCheckPCSmallScreen = () => {
  const dimensions = useWindowDimensions()
  const isSmallScreen = dimensions.width < PC_SMALL_BREAKPOINT
  return isSmallScreen
}

export const useCheckSPScreen = () => {
  const dimensions = useWindowDimensions()
  const isSPScreen = dimensions.width < PC_BREAKPOINT
  return Platform.OS === "web" && isSPScreen
}
