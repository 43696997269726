import { selector } from "recoil"
import { listJobMaster } from "src/aws/customAPI"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { JobType } from "src/aws/API"

type Job = {
  id: string
  label: string
  isSchool: boolean
}

export const jobsSelector = selector<Job[]>({
  key: "selectors/account/jobsSelector",
  get: async ({ get }) => {
    const tokens = get(tokensState)
    if (tokens == null) return []

    const jobMaster = await listJobMaster(tokens.accessToken)
    const jobs: Job[] =
      jobMaster.data?.listJobMaster.items
        .filter((j) => j.displayFlg === 1)
        .map((j) => ({
          id: j.id,
          label: j.label,
          isSchool: j.type === JobType.university,
        })) || []
    return jobs
  },
})
