import {
  createDrawerNavigator,
  DrawerContentComponentProps,
  DrawerContentScrollView,
  DrawerItemList,
} from "@react-navigation/drawer"
import { ComponentProps, memo, useCallback, useMemo } from "react"
import { ListRenderItemInfo, StyleSheet, Text, View } from "react-native"
import { FlatList } from "react-native-gesture-handler"
import { atom, useRecoilState, useSetRecoilState } from "recoil"
import { BottomSheet } from "src/components/parts/BottomSheet"
import { Button } from "src/components/parts/buttons/Button"
import { ChevronDownIcon } from "src/components/parts/icons/ChevronDownIcon"
import { ListIcon } from "src/components/parts/icons/ListIcon"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { ContactNetworkDrawers } from "src/constants/ContactNetworkDrawers"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { MyOrganization } from "src/queries/getMyOrganizations"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { myFullOrganizationsSelector } from "src/recoil/selectors/organization/myOrganizationsSelector"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { InboxScreen, inboxScreenOptions } from "src/screens/bottomTabs/ContactNetworkDrawer/InboxScreen"
import { OutboxScreen, outboxScreenOptions } from "src/screens/bottomTabs/ContactNetworkDrawer/OutboxScreen"
import { TrashBoxScreen, trashBoxScreenOptions } from "src/screens/bottomTabs/ContactNetworkDrawer/TrashBoxScreen"
import { TemplateScreen, templateScreenOptions } from "src/screens/bottomTabs/ContactNetworkDrawer/TemplateScreen"
import { DraftScreen, draftScreenOptions } from "src/screens/bottomTabs/ContactNetworkDrawer/DraftScreen"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { useAuthorization } from "src/recoil/hooks/authorization/useAuthorization"
import { unreadContactCountSelector } from "src/recoil/selectors/bottomTab/unreadContactCountSelector"
import { MigrationScreen, migrationScreenOptions } from "src/screens/bottomTabs/ContactNetworkDrawer/MigrationScreen"
import { hasUnreadThreadMessageSelectorFamily } from "src/recoil/selectors/contact/hasUnreadThreadMessageSelectorFamily"
import { BadgeIcon } from "src/components/parts/icons/BadgeIcon"
import { ImageTeamModel } from "src/apis/resource/getResourceGetUrl"
import { defaultTeam } from "src/constants/defaultTeam"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"

const isOrganizationSelectorOpenState = atom({
  key: "isOrganizationSelectorOpenState",
  default: false,
})

type SimpleOrganizationInfoProps = {
  organizationId: string
  title: string
  uriImage: string | undefined
  iconSize: number
}

export function getTeamImageUrl(organizationId: string, teamImagetUrls: ImageTeamModel[]) {
  const team = teamImagetUrls.find((el) => el.teamId === organizationId)
  return team?.url ?? undefined
}

const SimpleOrganizationInfo = memo<SimpleOrganizationInfoProps>(({ organizationId, title, uriImage, iconSize }) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { value: _unreadCount } = useAsyncSelector(unreadContactCountSelector)
  const { value: hasUnreadThreadMessage } = useAsyncSelector(hasUnreadThreadMessageSelectorFamily({ organizationId }))
  const size = useMemo(() => iconSize * 0.8, [iconSize])
  if (uriImage) {
    return (
      <View style={styles.item}>
        <CircleThumbnail source={{ uri: uriImage }} size={size} />
        <View style={styles.container}>
          <Text style={styles.itemLabel}>{title}</Text>
          {(_unreadCount?.[organizationId] ?? 0) === 0 && !hasUnreadThreadMessage?.hasUnread ? null : (
            <BadgeIcon size={"small"} />
          )}
        </View>
      </View>
    )
  } else {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { resourceUrl, refreshResourceUrl } = useResource({
      type: "teamImage",
      id: organizationId,
      size: "thumbnail",
    })
    return (
      <View style={styles.item}>
        <CircleThumbnail source={resourceUrl ? { uri: resourceUrl } : defaultTeam} onError={refreshResourceUrl} size={size} />
        <View style={styles.container}>
          <Text style={styles.itemLabel}>{title}</Text>
          {(_unreadCount?.[organizationId] ?? 0) === 0 && !hasUnreadThreadMessage?.hasUnread ? null : (
            <BadgeIcon size={"small"} />
          )}
        </View>
      </View>
    )
  }
})

const Drawer = createDrawerNavigator()

export type DrawerScreenOptions = ComponentProps<typeof Drawer.Screen>["options"]

const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  const setIsOrganizationSelectorOpen = useSetRecoilState(isOrganizationSelectorOpenState)
  const open = useCallback(() => setIsOrganizationSelectorOpen(true), [setIsOrganizationSelectorOpen])
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)

  return (
    <DrawerContentScrollView {...props}>
      <View style={styles.labelContainer}>
        <ItemLabel label="団体を切り替える" />
      </View>
      <View style={styles.switch}>
        {selectedOrganization != null ? (
          <Button onPress={open} style={styles.selector}>
            <SimpleOrganizationInfo
              organizationId={selectedOrganization.id}
              title={selectedOrganization.title}
              uriImage={selectedOrganization.uriImage}
              iconSize={30}
            />
            <View style={styles.chevronDown}>
              <ChevronDownIcon />
            </View>
          </Button>
        ) : null}
      </View>
      <View style={styles.labelContainer}>
        <ItemLabel label="連絡網メニュー" />
      </View>
      <DrawerItemList {...props} />
    </DrawerContentScrollView>
  )
}

export const ContactNetworkDrawer = memo(() => {
  const [isOpen, setIsOpen] = useRecoilState(isOrganizationSelectorOpenState)
  const { value: organizations } = useAsyncSelector(myFullOrganizationsSelector)
  const { value: selectedOrganization, isLoading: isLoadingOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const organizationName = useMemo(() => selectedOrganization?.title, [selectedOrganization])
  const setSelectedOrganization = useSetRecoilState(selectedMyOrganizationState)
  const { value: hasUnreadThreadMessage } = useAsyncSelector(hasUnreadThreadMessageSelectorFamily({ organizationId }))
  const { allowCreateContact } = useCheckAllowAccountPermission({})
  const { isLoading: isLoadingAuth, allowGoToMigrationBox } = useAuthorization({
    organizationId: selectedOrganization?.id,
  })
  const { value: _unreadCount } = useAsyncSelector(unreadContactCountSelector)
  const unreadCount = useMemo(
    () => (selectedOrganization ? _unreadCount?.[selectedOrganization.id] || 0 : 0),
    [selectedOrganization, _unreadCount]
  )

  const select = useCallback(
    (id: string) => {
      setSelectedOrganization(id)
      setIsOpen(false)
    },
    [setSelectedOrganization, setIsOpen]
  )
  const renderItem = useCallback(
    (data: ListRenderItemInfo<MyOrganization>) => (
      <View
        style={[styles.wrapper, data.item.id === selectedOrganization?.id ? styles.wrapperHighlighted : styles.wrapperIdle]}
      >
        <Button onPress={() => select(data.item.id)} style={styles.button}>
          <SimpleOrganizationInfo
            title={data.item.title}
            organizationId={data.item.id}
            uriImage={data.item.uriImage ?? undefined}
            iconSize={40}
          />
        </Button>
      </View>
    ),
    [select, selectedOrganization]
  )
  const keyExtractor = useCallback((item: MyOrganization) => item.id, [])

  const isLargeScreen = useCheckPCScreen()

  const options: ComponentProps<typeof Drawer.Navigator>["screenOptions"] = useMemo(
    () =>
      ({ navigation }) => ({
        ...commonHeaderOptions,
        headerLeft: () => (
          <>
            {!isLargeScreen && (
              <View style={styles.headerLeft}>
                <Button onPress={navigation.openDrawer}>
                  <ListIcon size={18} />
                </Button>
              </View>
            )}
          </>
        ),
        headerTitle: organizationName ?? "",
        headerShown: !isLargeScreen,
        drawerActiveTintColor: Colors.greyshBrown,
        drawerInactiveTintColor: Colors.cement,
        drawerActiveBackgroundColor: Colors.bgOrange,
        drawerInactiveBackgroundColor: Colors.reddishGrey,
      }),
    [organizationName, isLargeScreen]
  )

  // TODO: remove any, this is workaround of type error (possible related issue: https://github.com/react-navigation/react-navigation/issues/10802)
  return (
    <>
      <Drawer.Navigator initialRouteName="InboxDrawer" drawerContent={CustomDrawerContent} screenOptions={options}>
        <Drawer.Screen
          name={ContactNetworkDrawers.InboxScreen}
          component={InboxScreen as any}
          options={inboxScreenOptions(
            unreadCount > 0 || (!!hasUnreadThreadMessage?.hasUnread && hasUnreadThreadMessage.type !== "outbox")
          )}
        />
        {isLoadingAuth || allowCreateContact ? (
          <Drawer.Screen
            name={ContactNetworkDrawers.OutboxScreen}
            component={OutboxScreen as any}
            options={outboxScreenOptions(!!hasUnreadThreadMessage?.hasUnread && hasUnreadThreadMessage.type === "outbox")}
          />
        ) : null}
        {isLoadingAuth || allowCreateContact ? (
          <Drawer.Screen name={ContactNetworkDrawers.DraftScreen} component={DraftScreen as any} options={draftScreenOptions} />
        ) : null}
        {isLoadingAuth || allowCreateContact ? (
          <Drawer.Screen
            name={ContactNetworkDrawers.TemplateScreen}
            component={TemplateScreen as any}
            options={templateScreenOptions}
          />
        ) : null}
        <Drawer.Screen
          name={ContactNetworkDrawers.TrashBoxScreen}
          component={TrashBoxScreen as any}
          options={trashBoxScreenOptions}
        />
        {isLoadingAuth ||
        isLoadingOrganization ||
        allowGoToMigrationBox == null ||
        (allowGoToMigrationBox && selectedOrganization?.migrationId != null) ? (
          <Drawer.Screen
            name={ContactNetworkDrawers.MigrationScreen}
            component={MigrationScreen as any}
            options={migrationScreenOptions}
          />
        ) : null}
      </Drawer.Navigator>
      <BottomSheet snapPoints={["50%"]} isOpen={isOpen} onIsOpenChange={setIsOpen} backgroundColor={Colors.beige}>
        <FlatList
          data={organizations}
          keyExtractor={keyExtractor}
          renderItem={renderItem}
          contentContainerStyle={styles.list}
        />
      </BottomSheet>
    </>
  )
})

const styles = StyleSheet.create({
  selector: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 10,
    height: 50,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: Colors.lightGrey,
  },
  switch: {
    paddingHorizontal: 10,
    paddingBottom: 21,
  },
  labelContainer: {
    paddingHorizontal: 10,
    paddingBottom: 14,
  },
  headerLeft: {
    paddingLeft: 12,
  },
  list: {
    paddingBottom: 10,
  },
  item: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
  },
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flex: 1,
  },
  image: {
    marginRight: 15,
  },
  itemLabel: {
    fontSize: 14,
    fontWeight: "bold",
    marginLeft: 8,
  },
  wrapper: {
    flexDirection: "row",
    height: 54,
    marginVertical: 3,
    marginHorizontal: 15,
    borderRadius: 10,
    alignItems: "center",
    paddingHorizontal: 25,
  },
  wrapperHighlighted: {
    backgroundColor: Colors.lightTan,
  },
  wrapperIdle: {
    backgroundColor: Colors.white,
  },
  chevronDown: {
    marginLeft: 8,
  },
  button: {
    flex: 1,
  },
})
