import { selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { getOrderDetail, Response } from "src/apis/paidTeam/getOrderDetail"
import { getOrderDetailIdRequestState } from "./getOrderDetailIdRequestState"

export const getOrderDetailSelectorFamily = selectorFamily<Response | undefined | string, { teamId: string }>({
  key: "selectors/paidTeam/getOrderDetailSelectorFamily",
  get:
    ({ teamId }) =>
    async ({ get }) => {
      get(getOrderDetailIdRequestState)
      if (teamId === null) {
        return
      }
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken

      if (accessToken === undefined) {
        return
      }

      const result = await getOrderDetail({ accessToken: accessToken, teamId: teamId })
      return result
    },
})
