import { ValueOf } from "src/types.d"

export const AttendanceType = Object.freeze({
  Attend: "Attend",
  NonAttend: "NonAttend",
  Pend: "Pend",
} as const)

export const AttendanceLabel: { [key in AttendanceType]: string } = {
  [AttendanceType.Attend]: "出席",
  [AttendanceType.NonAttend]: "欠席",
  [AttendanceType.Pend]: "保留",
}

export type AttendanceType = ValueOf<typeof AttendanceType>
