
import { GqlError, deleteBlockingMatchingTeam as _deleteBlockingMatchingTeam } from "src/aws/customAPI"
import { Result } from "src/utils/Result"

type DeleteBlockMatchingTeamParams = {
  accessToken: string
  myTeamId: string
  blockingTeamId: string
}

type DeleteBlockMatchingTeamSuccess = unknown

type DeleteBlockMatchingTeamFailure = {
  message: string
}

export const deleteBlockingMatchingTeam = async ({
                                                   accessToken,
                                                   blockingTeamId,
                                                   myTeamId
}: DeleteBlockMatchingTeamParams):
  Promise<Result<DeleteBlockMatchingTeamSuccess, DeleteBlockMatchingTeamFailure>> => {
  return await _deleteBlockingMatchingTeam(accessToken, {
    myTeamId,
    blockingTeamId,
  }).then(res => {
    if (res.data?.deleteBlockingMatchingTeam?.id) {
      return Result.Ok(undefined)
    } else {
      return Result.Error<DeleteBlockMatchingTeamFailure>({message: "団体のブロック解除に失敗しました"})
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "団体のブロック解除に失敗しました"
    return Result.Error<DeleteBlockMatchingTeamFailure>({
      message: message
    })
  })
}