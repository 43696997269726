import { useCallback, useEffect, useMemo, useState } from "react"
import { getListMatchingApplication } from "src/apis/matching/getListMatchingApplication"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { myApplicationsState } from "src/recoil/atoms/matching/myOfferState"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { MatchingOffersModel } from "src/types/matching/matchingOfferModel"
import { CustomAlert } from "src/utils/CustomAlert"

export const useApplications = () => {
  const [myApplications, setMyApplications] = useAsyncState<MatchingOffersModel[]>(myApplicationsState)

  const { value: selectedMyTeam } = useAsyncSelector(selectedMyOrganizationSelector)
  const teamId = selectedMyTeam?.id
  const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(selectedMyTeam?.id))
  const teamMemberId = useMemo(() => myProfile?.memberId, [myProfile])
  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const [tokenMyApplication, setTokenMyApplication] = useState<string>()
  const [nextToken, setNextToken] = useState<string | undefined>(undefined)
  const [isLoading, setLoading] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>("")
  const getData = useCallback(
    async (keyword?: string, nextToken?: string) => {
      if (teamId == null || accessToken == null || teamMemberId == null) return
      setLoading(true)
      await getListMatchingApplication({
        accessToken,
        teamId,
        filter: { keyword },
        nextToken,
      }).then((res) => {
        setLoading(false)
        if (!res.isOk) {
          CustomAlert.alert("エラー", res.error.message)
          return
        }
        if (nextToken) {
          const filterExistOffer = res.content.data.filter((offer) => {
            if (!myApplications) {
              return false
            }
            return !myApplications.map((of) => of.id).includes(offer.id)
          })
          setMyApplications([...(myApplications ?? []), ...filterExistOffer])
        } else {
          setMyApplications(res.content.data)
        }
        setTokenMyApplication(res.content.nextToken)
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [teamId, accessToken, teamMemberId, myApplications]
  )

  const refreshList = async (searchText?: string) => {
    setNextToken(undefined)
    setMyApplications([])
    await getData(searchText)
  }

  const loadMore = async () => {
    await setNextToken(tokenMyApplication)
  }

  const hasMore = useMemo(() => tokenMyApplication && tokenMyApplication?.length > 0, [tokenMyApplication])

  useEffect(() => {
    if (nextToken) {
      getData(searchText, nextToken)
    } else {
      getData(searchText)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextToken, searchText])

  return {
    myApplications,
    isLoading,
    searchText,
    setSearchText,
    refreshList,
    loadMore,
    hasMore,
  }
}
