import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { Image, ScrollView, StyleSheet, Text, View, Platform } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { Button } from "src/components/parts/buttons/Button"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { Checkbox } from "src/components/parts/Checkbox"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { EditIcon } from "src/components/parts/icons/EditIcon"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { Colors } from "src/constants/Colors"
import { CreateOrEditOrganizationScreens } from "src/constants/CreateOrEditOrganizationScreens"
import { RootTabs } from "src/constants/RootTabs"
import { Screens } from "src/constants/Screens"
import { useFetcher } from "src/hooks/useFetcher"
import { postOrganization } from "src/apis/organization/postOrganization"
import { CreateOrEditOrganizationStackScreenProps, RootStackScreenProps } from "src/types.d"
import { OrganizationModel } from "src/types/organization/OrganizationModel"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { useAsyncState } from "src/hooks/useAsyncState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { CustomAlert } from "src/utils/CustomAlert"
import { citiesSelectorFamily } from "src/recoil/selectors/account/citiesSelectorFamily"
import { organizationDetailRequestIdState } from "src/recoil/atoms/organization/organizationDetailRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"
import { TeamCustomQuestion } from "src/aws/API"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { myFullOrganizationsRequestIdState } from "src/recoil/atoms/organization/myOrganizationsRequestIdState"
import { imageSize, useResource } from "src/recoil/hooks/resource/useResource"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { getTempOrganizationId } from "src/apis/organization/getTempOrganizationId"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { ChevronRightIcon } from "src/components/parts/icons/ChevronRightIcon"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { prefecturesSelector } from "src/recoil/selectors/account/prefecturesSelector"
import { isEmptyString } from "src/utils/validate"
import { defaultTeam } from "src/constants/defaultTeam"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useSetRecoilState } from "recoil"
import { selectedMyOrganizationState } from "src/recoil/atoms/organization/selectedMyOrganizationState"
import { MIGRATE_PLAN_ID } from "src/utils/const"

type Props = CreateOrEditOrganizationStackScreenProps<typeof CreateOrEditOrganizationScreens.CreateOrEditOrganization> & {
  navigation: RootStackScreenProps<typeof Screens.Root>["navigation"]
}

const defaultIsMembersPublic = true

const useNavigationState = <K extends keyof OrganizationModel>(
  {
    route,
    navigation,
  }: CreateOrEditOrganizationStackScreenProps<typeof CreateOrEditOrganizationScreens.CreateOrEditOrganization>,
  key: K
): [OrganizationModel[K] | undefined, (next?: Readonly<OrganizationModel[K]>) => void] => {
  const value = useMemo(() => route.params?.[key] as OrganizationModel[K], [route, key])
  const setValue = useCallback(
    (next?: Readonly<OrganizationModel[K]>) => navigation.setParams({ [key]: next }),
    [navigation, key]
  )
  return [value, setValue]
}

export const CreateOrEditOrganization = memo<Props>((props) => {
  const isLargeScreen = useCheckPCScreen()
  const { navigation, route } = props
  if (route.params.category == null || route.params.subCategory == null) {
    throw new Error("Specify category and sub-category to params")
  }
  const setSelectedOrganization = useSetRecoilState(selectedMyOrganizationState)
  const originalCategoryId = useMemo(() => route.params.originalCategoryId, [route.params.originalCategoryId])

  const requiredOrganization = useMemo<OrganizationModel | undefined>(() => {
    const { category, subCategory, organizationName, prefecture, city, profile, isMembersPublic, customQuestions } =
      route.params
    if (
      category == null ||
      subCategory == null ||
      isEmptyString(organizationName) ||
      prefecture == null ||
      city == null ||
      isEmptyString(profile)
    ) {
      return
    }
    return {
      ...route.params,
      category,
      subCategory,
      organizationName: organizationName ?? "",
      prefecture,
      city,
      profile,
      isMembersPublic: isMembersPublic ?? defaultIsMembersPublic,
      customQuestions: customQuestions,
    }
  }, [route.params])
  const insets = useSafeAreaInsets()
  const isEdit = useMemo(() => route.params.id != null, [route.params.id])
  const { value: account } = useAsyncSelector(accountSelector)
  const accountId = useMemo(() => account?.id, [account])
  const [tokens] = useAsyncState(tokensState)
  const accessToken = useMemo(() => tokens?.accessToken, [tokens])
  const [tempOrganizationId, setTempOrganizationId] = useState<string>()
  const resourceId = useMemo(() => route.params.id || tempOrganizationId, [route.params.id, tempOrganizationId])
  const {
    resourceUrl: imageUrl,
    localResource: localImage,
    pickLocalImage,
    prepareDeleteResource: prepareDeleteImage,
    deleteResourceFlg: deleteImageFlg,
    uploadResource: uploadLocalImage,
    refreshResourceUrl: refreshImageUrl,
  } = useResource(
    resourceId
      ? {
          type: "teamImage",
          id: resourceId,
          size: "normal",
        }
      : {
          type: "none",
        }
  )
  const localImageUri = useMemo(() => localImage?.uri, [localImage])
  const { resourceUrl: defaultImageUrl, refreshResourceUrl: refreshDefaultImageUrl } = useResource({
    type: "teamImage",
    id: "DEFAULT",
    size: "normal",
  })
  const displayedImage = useMemo(
    () => (deleteImageFlg ? defaultImageUrl : localImageUri || imageUrl || defaultImageUrl),
    [deleteImageFlg, defaultImageUrl, localImageUri, imageUrl]
  )
  const showDeleteImageButton = useMemo(() => {
    return !deleteImageFlg && (localImageUri != null || (imageUrl != null && !imageUrl.toLocaleLowerCase().includes("default")))
  }, [deleteImageFlg, localImageUri, imageUrl])
  const refreshDisplayedImageUrl = useCallback(async () => {
    if (deleteImageFlg || (localImageUri == null && imageUrl)) {
      await refreshDefaultImageUrl()
    } else {
      await refreshImageUrl()
    }
  }, [deleteImageFlg, localImageUri, imageUrl, refreshDefaultImageUrl, refreshImageUrl])

  const refreshOrganizationDetail = useRefresher(organizationDetailRequestIdState)
  const refreshMyOrganizations = useRefresher(myFullOrganizationsRequestIdState)
  const refresh = useCallback(async () => {
    refreshOrganizationDetail()
    refreshMyOrganizations()
  }, [refreshOrganizationDetail, refreshMyOrganizations])
  const { fetch: save, isFetching: isSaving } = useFetcher(async (params: OrganizationModel) => {
    if (accountId == null || accessToken == null) {
      return
    }
    // スポーツ系サークル・チームの場合は、それ以外のカテゴリに変更した際にアラートを表示
    if (originalCategoryId === MIGRATE_PLAN_ID && originalCategoryId !== params.category.id) {
      const isConfirmed = await CustomAlert.confirm(
        "警告",
        "団体カテゴリ（団体で行うこと）を「スポーツ系サークル・チーム」以外に変更すると、団体マッチングをご利用いただけなくなります。\n" +
          "※団体マッチングで作成した募集やメッセージがある場合は、すべて削除されます。\n" +
          "本当に団体カテゴリを変更しますか？"
      )
      if (!isConfirmed) {
        return
      }
    }
    // 新規作成/更新兼用
    const [postOrganizationResult, uploadImageResult] = await Promise.all([
      postOrganization({ accessToken, tempOrganizationId, model: params }),
      uploadLocalImage(),
    ])
    if (!postOrganizationResult.isOk) {
      await CustomAlert.alert("エラー", postOrganizationResult.error.message)
      return
    } else if (!uploadImageResult.isOk) {
      await CustomAlert.alert("エラー", uploadImageResult.error.message)
      return
    } else if (postOrganizationResult.content.id == null) {
      return
    }

    await refresh()
    if (isEdit) {
      navigation.goBack()
    } else {
      await CustomAlert.alert("完了", "団体作成が完了しました。")
      setSelectedOrganization(postOrganizationResult.content.id)
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: OrganizationManagerScreens.OrganizationDetail,
          params: { organizationId: postOrganizationResult.content.id },
        },
      })
    }
  })
  const submit = useCallback(() => requiredOrganization && save(requiredOrganization), [save, requiredOrganization])
  const isSubmitDisabled = useMemo(() => isSaving || requiredOrganization == null, [isSaving, requiredOrganization])
  const [organizationName, setOrganizationName] = useNavigationState(props, "organizationName")
  const [profile, setProfile] = useNavigationState(props, "profile")
  const navigateToSelectCategory = useCallback(() => {
    navigation.navigate(CreateOrEditOrganizationScreens.SelectCategory, { ...route.params })
  }, [navigation, route.params])
  const { value: prefectures } = useAsyncSelector(prefecturesSelector)
  const prefectureOptions = useMemo(
    () => prefectures?.map((prefecture) => ({ value: parseInt(prefecture.id), label: prefecture.label })),
    [prefectures]
  )
  const [prefecture, setPrefecture] = useNavigationState(props, "prefecture")
  const onChangePrefecture = (prefecture?: number) => {
    setCity(undefined)
    if (prefecture == undefined) {
      setPrefecture(prefecture)
      return
    }
    setPrefecture(isNaN(prefecture) ? undefined : prefecture)
  }
  const { value: cities } = useAsyncSelector(citiesSelectorFamily(prefecture?.toString()))
  const cityOptions = useMemo(() => cities?.map((city) => ({ value: city.id, label: city.label })), [cities])
  const [city, setCity] = useNavigationState(props, "city")
  const onChangeCity = (id?: string) => {
    const next = cityOptions?.find((c) => c.value === id)
    setCity(
      next && {
        id: next.value,
        label: next.label,
      }
    )
  }

  const [requirementForMemberProfile, setRequirementForMemberProfile] = useNavigationState(props, "requirementForMemberProfile")
  const [customQuestions, setCustomQuestions] = useNavigationState(props, "customQuestions")
  const requiredCustomQuestions = useMemo(() => customQuestions || [], [customQuestions])

  const toggleCustomQuestion = (current: TeamCustomQuestion[], target: TeamCustomQuestion): TeamCustomQuestion[] => {
    const result = []
    let targetFound = false
    current.forEach((c) => {
      if (c === target) {
        targetFound = true
      } else {
        result.push(c)
      }
    })
    if (!targetFound) {
      result.push(target)
    }
    return result
  }
  const toggleAskPhoneNumber = useCallback(() => {
    setCustomQuestions(toggleCustomQuestion(requiredCustomQuestions, TeamCustomQuestion.phoneNumber))
  }, [requiredCustomQuestions, setCustomQuestions])

  const toggleAskBelongs = useCallback(() => {
    setCustomQuestions(toggleCustomQuestion(requiredCustomQuestions, TeamCustomQuestion.belongs))
  }, [requiredCustomQuestions, setCustomQuestions])

  const toggleAskGrade = useCallback(() => {
    setCustomQuestions(toggleCustomQuestion(requiredCustomQuestions, TeamCustomQuestion.grade))
  }, [requiredCustomQuestions, setCustomQuestions])

  const [isMembersPublic, setIsMembersPublic] = useNavigationState(props, "isMembersPublic")
  const isRequiredMembersPublic = useMemo(
    () => (isMembersPublic == null ? defaultIsMembersPublic : isMembersPublic),
    [isMembersPublic]
  )
  const toggleIsMembersPublic = useCallback(() => {
    const current = isMembersPublic == null ? defaultIsMembersPublic : isMembersPublic
    setIsMembersPublic(!current)
  }, [isMembersPublic, setIsMembersPublic])

  useEffect(() => {
    if (accessToken == null || route.params.id != null || tempOrganizationId != null) return

    getTempOrganizationId({ accessToken }).then((res) => {
      if (res.isOk) {
        setTempOrganizationId(res.content)
      } else {
        CustomAlert.alert("エラー", res.error.message)
        navigation.goBack()
      }
    })
  }, [accessToken, route.params.id, tempOrganizationId, setTempOrganizationId, navigation])

  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationThumbnailURL = useMemo(() => selectedOrganization?.uriImage, [selectedOrganization])

  return (
    <CustomKeyboardAvoidingView>
      <ScrollView>
        <View style={isLargeScreen ? { alignItems: "center" } : {}}>
          <View style={isLargeScreen ? { width: 600, paddingTop: 16 } : {}}>
            <View style={[styles.keyvisualContainer, isLargeScreen ? { borderRadius: 16 } : {}]}>
              <Image
                style={[styles.keyvisual, isLargeScreen ? { borderRadius: 16 } : {}]}
                source={
                  displayedImage
                    ? { uri: displayedImage, cache: "force-cache" }
                    : organizationThumbnailURL
                    ? { uri: organizationThumbnailURL }
                    : defaultTeam
                }
                resizeMode="cover"
                onError={refreshDisplayedImageUrl}
              />
              <View style={styles.registerImageButtonContainer}>
                <Button
                  style={styles.registerImageButton}
                  onPress={() => pickLocalImage({ maxSizeInMB: imageSize.team.maxSizeInMB })}
                >
                  <EditIcon />
                  <Text style={styles.registerImageButtonText}>写真を登録</Text>
                </Button>
                {showDeleteImageButton ? (
                  <Button style={styles.registerImageButton} onPress={prepareDeleteImage}>
                    <RemoveIcon />
                    <Text style={styles.deleteImageButtonText}>写真を削除</Text>
                  </Button>
                ) : null}
              </View>
            </View>
            <View style={styles.items}>
              <View style={styles.itemLabelWrapper}>
                <ItemLabel
                  label="団体名"
                  required
                  RightComponent={<InputLengthCounter text={organizationName} maxLength={20} unit={"字以内"} />}
                />
              </View>
              <View style={styles.item}>
                <CustomTextInput
                  style={styles.textInput}
                  placeholder="入力してください"
                  placeholderTextColor={Colors.lightGrey}
                  value={organizationName ?? ""}
                  onChangeText={setOrganizationName}
                  editable={!isSaving}
                  maxLength={20}
                />
              </View>
              <View style={styles.itemLabelWrapper}>
                <ItemLabel label="団体で行うこと" required />
              </View>
              <View style={styles.item}>
                <Button style={styles.category} onPress={navigateToSelectCategory} disabled={isSaving}>
                  <View>
                    <Text>{route.params.category.label}</Text>
                    <Text>{route.params.subCategory.label}</Text>
                  </View>
                  <ChevronRightIcon />
                </Button>
              </View>
              <View style={styles.itemLabelWrapper}>
                <ItemLabel label="活動エリア" required />
              </View>
              {prefectureOptions != null ? (
                <View style={styles.item}>
                  <CustomSelect
                    options={prefectureOptions}
                    value={prefecture}
                    onChange={onChangePrefecture}
                    disabled={isSaving}
                  />
                </View>
              ) : null}
              {cityOptions != null ? (
                <View style={styles.item}>
                  <CustomSelect options={cityOptions} value={city?.id} onChange={onChangeCity} />
                </View>
              ) : null}
              <View style={styles.itemLabelWrapper}>
                <ItemLabel
                  label="団体の紹介文"
                  required
                  RightComponent={<InputLengthCounter text={profile} maxLength={200} unit={"字以内"} />}
                />
              </View>
              <View style={styles.item}>
                <MultilineTextInput
                  style={[
                    styles.textInputMulti,
                    { height: 240 },
                    Platform.OS !== "ios" && { fontSize: 16, paddingVertical: 13 },
                  ]}
                  placeholder="入力してください"
                  value={profile ?? ""}
                  onChangeText={setProfile}
                  editable={!isSaving}
                  maxLength={200}
                />
              </View>
              {isEdit && (
                <>
                  <Text style={styles.optionSetting}>オプション設定</Text>
                  <View style={styles.itemLabelWrapper}>
                    <ItemLabel
                      label="団体参加時の注意事項"
                      RightComponent={<InputLengthCounter text={requirementForMemberProfile} maxLength={200} unit={"字以内"} />}
                    />
                  </View>
                  <View style={styles.item}>
                    <MultilineTextInput
                      style={[
                        styles.textInputMulti,
                        { height: 240 },
                        Platform.OS !== "ios" && { fontSize: 16, paddingVertical: 13 },
                      ]}
                      placeholder="入力してください"
                      value={requirementForMemberProfile ?? ""}
                      onChangeText={setRequirementForMemberProfile}
                      editable={!isSaving}
                      maxLength={200}
                      minHeight={240}
                    />
                  </View>
                  <View style={styles.itemLabelWrapper}>
                    <ItemLabel label="メンバー参加時に尋ねる追加項目" />
                  </View>
                  <View style={[styles.item, styles.horizontal]}>
                    <Checkbox
                      isChecked={requiredCustomQuestions.includes(TeamCustomQuestion.phoneNumber)}
                      onPress={toggleAskPhoneNumber}
                      isDisabled={isSaving}
                    />
                    <Text style={styles.radioLabel}>連絡先電話番号</Text>
                  </View>
                  <View style={[styles.item, styles.horizontal]}>
                    <Checkbox
                      isChecked={requiredCustomQuestions.includes(TeamCustomQuestion.belongs)}
                      onPress={toggleAskBelongs}
                      isDisabled={isSaving}
                    />
                    <Text style={styles.radioLabel}>所属（学校・会社等）</Text>
                  </View>
                  <View style={[styles.item, styles.horizontal]}>
                    <Checkbox
                      isChecked={requiredCustomQuestions.includes(TeamCustomQuestion.grade)}
                      onPress={toggleAskGrade}
                      isDisabled={isSaving}
                    />
                    <Text style={styles.radioLabel}>学年</Text>
                  </View>
                </>
              )}
              <View style={styles.itemLabelWrapper}>
                <ItemLabel label="公開設定" />
              </View>
              <View style={[styles.item, styles.horizontal]}>
                <Checkbox isChecked={isRequiredMembersPublic} onPress={toggleIsMembersPublic} isDisabled={isSaving} />
                <Text style={styles.radioLabel}>団体のメンバーに名簿を公開する</Text>
              </View>
              <View style={[styles.textWrap]}>
                <Text style={[styles.text, { marginRight: 4 }]}>※</Text>
                <Text style={[styles.text, Platform.OS !== "web" ? { marginRight: 15 } : null]}>
                  一般メンバーにメンバー一覧を公開したい場合や、連絡にコメント欄を付けたい場合は、「団体のメンバーに名簿を公開する」にチェックを入れてください。
                </Text>
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <View
        style={[styles.actionContainer, { paddingBottom: insets.bottom + 10 }, isLargeScreen ? { alignItems: "center" } : {}]}
      >
        <TextButton
          style={isLargeScreen ? { width: 300 } : {}}
          title={isEdit ? "保存" : "団体を作成"}
          buttonType={ButtonType.Primary}
          onPress={submit}
          disabled={isSubmitDisabled}
          isLoading={isSaving}
        />
      </View>
    </CustomKeyboardAvoidingView>
  )
})

CreateOrEditOrganization.displayName = "CreateOrEditOrganization"

const styles = StyleSheet.create({
  keyvisualContainer: {
    position: "relative",
    height: 213,
    backgroundColor: Colors.white2,
  },
  keyvisual: {
    width: "100%",
    height: "100%",
  },
  registerImageButtonContainer: {
    position: "absolute",
    bottom: 12,
    right: 16,
  },
  registerImageButton: {
    backgroundColor: "rgba(255, 255, 255, 0.96)",
    paddingVertical: 13,
    paddingHorizontal: 19,
    borderRadius: 6,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 8,
  },
  registerImageButtonText: {
    color: Colors.orange,
    fontSize: 14,
    fontWeight: "bold",
    paddingLeft: 8,
  },
  deleteImageButtonText: {
    color: "#3C3C3C",
    marginLeft: 8,
    fontSize: 13,
  },
  items: {
    padding: 15,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  item: {
    paddingTop: 16,
  },
  itemLabelWrapper: {
    marginTop: 20,
  },
  optionSetting: {
    marginTop: 20,
    fontSize: 18,
    fontWeight: "bold",
    color: Colors.greyshBrown,
  },
  actionContainer: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  category: {
    paddingVertical: 12,
    paddingHorizontal: 16,
    borderRadius: 6,
    borderWidth: 2,
    borderColor: Colors.lightGrey,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  horizontal: {
    alignItems: "center",
    flexDirection: "row",
  },
  radioLabel: {
    paddingLeft: 9,
    fontSize: 15,
    color: Colors.greyshBrown,
    fontWeight: "bold",
  },
  textWrap: {
    display: "flex",
    flexDirection: "row",
    marginTop: 14,
    marginBottom: 17,
  },
  text: {
    fontSize: 14,
    color: Colors.greyshBrown,
  },
  textInput: {
    fontSize: 16,
    textAlignVertical: "top",
    paddingVertical: 13,
    paddingHorizontal: 16,
    backgroundColor: Colors.white3,
    borderWidth: 2,
    borderColor: Colors.lightGrey,
    borderRadius: 10,
  },
  textInputMulti: {
    textAlignVertical: "top",
    paddingLeft: 16,
    backgroundColor: Colors.white3,
    borderWidth: 2,
    borderColor: Colors.lightGrey,
    borderRadius: 10,
  },
})
