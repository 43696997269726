import { CustomAlert } from "src/utils/CustomAlert";
import { deleteComment, GqlError } from "src/aws/customAPI";

type Params = {
  accessToken: string
  mailId: string
  memberId: string
}

export const deleteCommentApi = async ({accessToken, mailId, memberId} :Params): Promise<{id: string} | any> => {
  if (!accessToken || !mailId || !memberId) return
  return deleteComment(accessToken, {
      input: {
        mailId,
        memberId,
      }
  }).then(res => {
    if (res.data?.deleteComment.id) {
      CustomAlert.alert("完了", "コメントを削除しました。")
      return {id: res.data?.deleteComment.id}
    }
  }).catch((error: GqlError) => {
    CustomAlert.alert("エラー", error.errors?.[0]?.errorInfo?.userMessage, "閉じる")
    return error
  })
}