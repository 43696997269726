import { Result } from "src/utils/Result"
import { updateTemplateFavoriteFlg } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type GetUpdateFavoriteContactRequestParams = {
  accessToken: string
  templateId: string
  isFavorite: boolean
}

export type GetUpdateFavoriteContactSuccess = unknown

type GetUpdateFavoriteContactFailure = {
  message: string
}

export const updateFavoriteContactTemplate = async (
  { accessToken, templateId, isFavorite }: GetUpdateFavoriteContactRequestParams
): Promise<Result<GetUpdateFavoriteContactSuccess, GetUpdateFavoriteContactFailure>> => {
  return execApi(
    () => updateTemplateFavoriteFlg(accessToken, {
      input: {
        id: templateId,
        favoriteFlg: isFavorite,
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["updateFavoriteContactTemplate", "updateTemplateFavoriteFlg"],
      defaultErrorMessage: "お気に入りの更新に失敗しました。"
    }
  )
}
