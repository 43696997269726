import { selector } from "recoil"
import { notificationsSelector } from "src/recoil/selectors/home/notificationsSelector"

export const hasOrganizationNoticeSelector = selector({
  key: "selectors/bottomTab/hasOrganizationNoticeSelector",
  get: ({ get }) => {
    const notifications = get(notificationsSelector)
    return notifications?.some((n) => !n.isRead) || false
  },
})
