import { memo, useCallback, useMemo } from "react"
import { FlatList, ListRenderItemInfo, Platform, StyleSheet, View, Text, useWindowDimensions } from "react-native"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { SearchInput } from "src/components/parts/SearchInput"
import { GroupMember } from "src/types/organization/group/GroupMember"
import { useNavigation } from "@react-navigation/native"
import { MemberListItem } from "src/components/parts/organizationTab/MemberListItem"
import { Colors } from "src/constants/Colors"
import { ButtonType } from "src/components/parts/buttons/TextButton"
import { BorderedItemSeparator } from "src/components/parts/BorderedItemSeparator"
import { AddIconButton } from "src/components/parts/buttons/AddIconButton"
import { Screens } from "src/constants/Screens"
import { CustomAlert } from "src/utils/CustomAlert"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { SingleButtonFooter } from "src/components/parts/SingleButtonFooter"
import { useRefresher } from "src/hooks/useRefresher"
import { organizationMembersRequestIdState } from "src/recoil/atoms/organization/organizationMembersRequestIdState"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import { LoadingIndicator } from "../LoadingIndicator"
import { useRecoilValue } from "recoil"
import { addedMembersState } from "src/recoil/atoms/organization/group/addedMembersState"
import { groupDetailRequestIdState } from "src/recoil/atoms/organization/group/groupDetailRequestIdState"

type GroupMemberWithDeleteStatus = GroupMember & { isSelectedForDelete?: boolean }

type Props = {
  organizationId: string
  groupId?: string
  name: string
  members: GroupMemberWithDeleteStatus[]
  searchText: string
  onSearchTextChange: (searchText: string) => void
  isMemberSubmitting: boolean
  onNameChange: (name: string) => void
  deleteMember: (memberId: GroupMember["id"]) => void
  recoverMember: (memberId: GroupMember["id"]) => void
  toggleLeader: (memberId: GroupMember["id"]) => void
  onSubmit: () => Promise<void>
  totalMember: number
  loadMoreMembers: () => void
  isMembersGroupLoading: boolean
  searchDebouce: string
  setDefaultGroupMembers: (next: GroupMember[]) => void
}

export const GroupInOrganizationEditor = memo<Props>(
  ({
    organizationId,
    groupId,
    name,
    members,
    searchDebouce,
    searchText,
    isMemberSubmitting,
    onNameChange,
    onSearchTextChange,
    deleteMember,
    recoverMember,
    toggleLeader,
    onSubmit,
    totalMember,
    loadMoreMembers,
    isMembersGroupLoading,
    setDefaultGroupMembers,
  }) => {
    const widthScreen = useWindowDimensions().width
    const refreshGroupDetail = useRefresher(groupDetailRequestIdState)
    const isLargeScreen = useCheckPCScreen()
    const { allowEditGroup } = useCheckAllowAccountPermission({})
    const navigation = useNavigation()
    const addedMembers = useRecoilValue(addedMembersState)
    const goToAddMember = useCallback(() => {
      navigation.navigate(Screens.CreateOrEditGroupStack, {
        params: { organizationId, groupId },
        screen: "AddMemberInGroup",
      })
    }, [navigation, organizationId, groupId])
    const refreshOrganizationMembers = useRefresher(organizationMembersRequestIdState)
    const AddMemberButton = useMemo(() => <AddIconButton title="メンバー追加" onPress={goToAddMember} />, [goToAddMember])

    const onSubmitHandler = useCallback(async () => {
      if (name === "") {
        CustomAlert.alert("エラーコード 028", "グループ名が入力されていません。")
        return
      }
      try {
        await onSubmit()
        refreshOrganizationMembers()
        refreshGroupDetail()
      } catch (e) {
        CustomAlert.alert("エラー", "通信に失敗しました")
      }
    }, [onSubmit, name, refreshOrganizationMembers, refreshGroupDetail])

    // Todo
    const membersUnselect = members.filter((member) => !member.isSelectedForDelete).length

    const ListHeader = useMemo(
      () => (
        <>
          <View style={styles.sectionWrapper}>
            <ItemLabel label="グループ名" RightComponent={<InputLengthCounter text={name} maxLength={20} unit={"字以内"} />} />
          </View>
          <CustomTextInput style={styles.nameInput} value={name} onChangeText={onNameChange} maxLength={20} />
          <View style={styles.sectionWrapper}>
            {/* todo */}
            <ItemLabel
              label={`メンバー ${totalMember === 0 ? membersUnselect : totalMember + addedMembers.length}人`}
              RightComponent={AddMemberButton}
            />
          </View>
          <View style={styles.searchInputWrapper}>
            <SearchInput
              value={searchText}
              onChangeText={(e) => {
                onSearchTextChange(e)
                setDefaultGroupMembers([])
              }}
              placeholder="名前で検索"
            />
          </View>
        </>
      ),
      [
        name,
        searchText,
        onNameChange,
        onSearchTextChange,
        AddMemberButton,
        membersUnselect,
        totalMember,
        setDefaultGroupMembers,
        addedMembers.length,
      ]
    )

    const ListFooter = useMemo(
      () =>
        !isMembersGroupLoading && members.length === 0 && searchDebouce ? (
          <View style={styles.footerContainer}>
            <Text style={[styles.noRecordText]}>一致するメンバーはいません</Text>
          </View>
        ) : null,
      [isMembersGroupLoading, members, searchDebouce]
    )
    const ListItem = useCallback(
      (info: ListRenderItemInfo<GroupMemberWithDeleteStatus>) => (
        <MemberListItem
          id={info.item.id}
          name={`${info.item.nickname} (${info.item.memberNumber})`}
          imageUrl={info.item.imageUrl}
          memberType={info.item.memberType}
          isLeader={info.item.isLeader}
          isSelectedForDelete={info.item.isSelectedForDelete}
          deleteMember={() => deleteMember(info.item.id)}
          recoverMember={() => recoverMember(info.item.id)}
          toggleLeader={() => toggleLeader(info.item.id)}
          isCustomImg
        />
      ),
      [deleteMember, recoverMember, toggleLeader]
    )

    return (
      <CustomKeyboardAvoidingView style={styles.container}>
        <View style={styles.main}>
          <FlatList
            data={members}
            renderItem={ListItem}
            ItemSeparatorComponent={BorderedItemSeparator}
            ListHeaderComponent={ListHeader}
            keyExtractor={(data) => `${data.id}`}
            contentContainerStyle={[isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : {}]}
            onEndReached={!isMembersGroupLoading ? loadMoreMembers : null}
            ListFooterComponent={
              isMembersGroupLoading ? (
                <View style={styles.loading}>
                  <LoadingIndicator />
                </View>
              ) : (
                ListFooter
              )
            }
          />
        </View>
        <SingleButtonFooter
          buttonType={ButtonType.Primary}
          title={allowEditGroup ? "保存" : "グループ作成"}
          onPress={onSubmitHandler}
          isLoading={isMemberSubmitting}
          disabled={!name || isMemberSubmitting}
        />
      </CustomKeyboardAvoidingView>
    )
  }
)

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
  container: {
    flex: 1,
  },
  main: {
    flex: 1,
  },
  nameInput: {
    marginTop: 8,
    marginHorizontal: 15,
  },
  sectionWrapper: {
    marginTop: 16,
    paddingHorizontal: 15,
  },
  searchInputWrapper: {
    marginTop: 8,
  },
  footerContainer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  separator: {
    height: 1,
    backgroundColor: Colors.white2,
  },
  noRecordText: {
    marginTop: 8,
    marginLeft: 16,
    color: Colors.warmGrey,
    fontSize: 16,
  },
})
