import { ValueOf } from "src/types.d"

export const Gender = Object.freeze({
  Male: "Male",
  Female: "Female",
  NoAnswer: "NoAnswer",
} as const)

export type Gender = ValueOf<typeof Gender>

export const GenderLabel = {
  [Gender.Male]: "男",
  [Gender.Female]: "女",
  [Gender.NoAnswer]: "回答しない",
}
