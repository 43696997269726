import { memo, PropsWithChildren } from "react"
import { StyleSheet, View } from "react-native"
import { Colors } from "src/constants/Colors"

type Props = {
  index: number
  length: number
}

export const BorderedListItem = memo<PropsWithChildren<Props>>(({ index, length, children }) => (
  <>
    {index === 0 ? (
      <View style={styles.borderWrapper}>
        <View style={[styles.commonBorder, styles.normalBorder]} />
      </View>
    ) : null}
    <>{children}</>
    {index === length - 1 ? (
      <View style={styles.borderWrapper}>
        <View style={[styles.commonBorder, styles.normalBorder]} />
      </View>
    ) : (
      <View style={styles.borderWrapper}>
        <View style={[styles.commonBorder, styles.shortBorder]} />
      </View>
    )}
  </>
))

const styles = StyleSheet.create({
  borderWrapper: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  commonBorder: {
    height: 1,
    backgroundColor: Colors.white2,
  },
  normalBorder: {
    width: "100%",
  },
  shortBorder: {
    width: "100%",
    marginLeft: 15,
  },
})
