import React, { memo, useEffect, useState } from "react"
import { StyleSheet, View, Text, useWindowDimensions, SafeAreaView } from "react-native"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { useOfferSearchData } from "src/recoil/hooks/matching/useOfferSearchData"
import { Colors } from "src/constants/Colors"
import { CustomDateTimePicker } from "src/components/parts/CustomDateTimePicker"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { TextButton, ButtonType } from "src/components/parts/buttons/TextButton"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ageOptions, purposeOptions } from "src/constants/matchingSearchDate"
import { Button } from "src/components/parts/buttons/Button"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { OrganizationManagerStackScreenProps, RootStackScreenProps, ValueOf } from "src/types.d"
import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { Screens } from "src/constants/Screens"

export const imageSize = {
  account: {
    normal: { width: 100, height: 100 },
    thumbnail: { width: 50, height: 50 },
    maxSizeInMB: 10,
  },
  team: {
    normal: { width: 720, height: 250 },
    thumbnail: { width: 100, height: 100 },
    maxSizeInMB: 10,
  },
  // TeamMember画像はAccount画像と同じため省略
  attachment: {
    thumbnail: { width: 100, height: 100 },
    maxSizeInMB: 10,
    maxCount: 5,
  },
}
const maxFileCount = imageSize.attachment.maxCount
const maxEachFileSizeInMB = imageSize.attachment.maxSizeInMB
const maxTotalFileSizeInMB = maxEachFileSizeInMB * maxFileCount
type Props = RootStackScreenProps<typeof Screens.MatchingOfferSearch>
export const OfferSearchModal = memo<Props>(({ navigation }) => {
  const onClose = () => navigation.goBack()
  const {
    searchForm,
    prefectureOptions,
    cityOptions,
    activityOptions,
    onChangePrefecture,
    onChangeCity,
    onChangeActivity,
    onChangeStartAge,
    onChangeEndAge,
    onKeyWordChange,
    onSearch,
    onChangeStartDate,
    openConfirmModal,
    setOpenConfirmModal,
    onChangeEndDate,
    onCloseSearchOfferModal,
    resetSearchData,
    isDisabled,
  } = useOfferSearchData(onClose)
  const isLargeScreen = useCheckPCScreen()
  const { bottom } = useSafeAreaInsets()
  const filteredAgeOptions = (isFrom: boolean) => {
    if (isFrom) {
      return ageOptions.filter((ageOpt) =>
        searchForm?.ageTo?.label ? Number(ageOpt.label) <= Number(searchForm.ageTo?.label) : true
      )
    } else {
      return ageOptions.filter((ageOpt) =>
        searchForm?.ageFrom?.label ? Number(ageOpt.label) >= Number(searchForm?.ageFrom?.label) : true
      )
    }
  }
  const [purpose, setPurpose] = useState<any>(purposeOptions[0].value)
  const onChangePurpose = () => {
    setPurpose(purposeOptions[0].value)
  }
  useEffect(() => {
    if (!isLargeScreen) {
      navigation.setOptions({
        headerLeft: () => (
          <Button
            style={{ marginLeft: 15 }}
            onPress={() => (openConfirmModal ? setOpenConfirmModal(false) : onCloseSearchOfferModal())}
          >
            <RemoveIcon size={17} fill={Colors.orange} />
          </Button>
        ),
        title: "募集を検索",
      })
    }
  }, [navigation, isLargeScreen, onCloseSearchOfferModal, openConfirmModal, setOpenConfirmModal])
  return (
    <CustomKeyboardAvoidingView>
      <SafeAreaView>
        {isLargeScreen && (
          <View style={[styles.header]}>
            <Button onPress={() => onCloseSearchOfferModal()}>
              <RemoveIcon size={17} fill={Colors.orange} />
            </Button>
            <Text style={[styles.textHeaderPC]}>募集を検索</Text>
          </View>
        )}
      </SafeAreaView>
      {!isLargeScreen ? (
        <CustomScrollView>
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <View style={{ width: "100%" }}>
              <View style={styles.container}>
                <View style={styles.contactType}>
                  <ItemLabel label="募集目的" />
                  <View style={styles.contactTypePicker}>
                    <CustomSelect value={purpose} options={purposeOptions} onChange={onChangePurpose} />
                  </View>
                </View>
                <View style={styles.contactType}>
                  <ItemLabel label="競技カテゴリ" />
                  <View style={styles.contactTypePicker}>
                    {activityOptions && (
                      <CustomSelect
                        options={activityOptions ?? []}
                        onChange={onChangeActivity}
                        value={searchForm?.activity?.id}
                      />
                    )}
                  </View>
                </View>
                <View style={styles.contactType}>
                  <ItemLabel label="開催日" />
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      paddingTop: 16,
                    }}
                  >
                    <View style={[]}>
                      <CustomDateTimePicker value={searchForm?.dateFrom} onChange={onChangeStartDate} viewType="Date" />
                    </View>

                    <Text style={[styles.text1]}> ～ </Text>
                    <View style={[{ paddingLeft: 17 }]}>
                      <CustomDateTimePicker
                        value={searchForm?.dateTo}
                        onChange={onChangeEndDate}
                        min={searchForm?.dateFrom}
                        viewType="Date"
                      />
                    </View>
                  </View>
                </View>
                <View style={styles.contactType}>
                  <ItemLabel label="開催エリア" />
                  <View style={styles.contactTypePicker}>
                    <View style={{ display: "flex", flexDirection: "column" }}>
                      {prefectureOptions !== undefined && (
                        <View style={[styles.customButtonSelect]}>
                          <CustomSelect
                            options={prefectureOptions}
                            value={searchForm?.prefecture?.id.toString()}
                            onChange={onChangePrefecture}
                            placeholder={"都道府県を選択してください"}
                          />
                        </View>
                      )}
                      <View>
                        <CustomSelect
                          options={cityOptions ?? []}
                          value={searchForm?.city?.id}
                          onChange={onChangeCity}
                          placeholder={"市区町村を選択してください"}
                        />
                      </View>
                    </View>
                  </View>
                </View>

                <View style={styles.contactType}>
                  <ItemLabel label="年齢層" />
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      paddingTop: 16,
                    }}
                  >
                    <View style={[styles.CustomSelect]}>
                      <CustomSelect
                        options={filteredAgeOptions(true)}
                        onChange={onChangeStartAge}
                        value={searchForm?.ageFrom?.label}
                        placeholder={""}
                      />
                    </View>
                    <Text style={[styles.text]}> ～ </Text>
                    <View style={[styles.CustomSelect]}>
                      <CustomSelect
                        options={filteredAgeOptions(false)}
                        onChange={onChangeEndAge}
                        value={searchForm?.ageTo?.label}
                        placeholder={""}
                      />
                    </View>
                    <Text style={[styles.text]}> 歳</Text>
                  </View>
                </View>

                <View style={styles.contactType}>
                  <ItemLabel label="キーワード" />
                  <View style={styles.titleTextInput}>
                    <CustomTextInput
                      value={searchForm?.keyword}
                      onChangeText={onKeyWordChange}
                      placeholder="キーワードを入力してください"
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View style={[{ paddingTop: 16 }]}>
            <View style={[styles.actions]}>
              <TextButton buttonType={ButtonType.Primary} title={"検索"} onPress={onSearch} />
            </View>
            <View style={[styles.actions1, { paddingBottom: bottom + 16 }]}>
              <TextButton buttonType={ButtonType.Secondary} title={"検索条件をクリア"} onPress={resetSearchData} />
            </View>
          </View>
        </CustomScrollView>
      ) : (
        <CustomScrollView>
          <View style={{ justifyContent: "center", alignItems: "center" }}>
            <View style={{ width: "60%", maxWidth: 1020, paddingRight: 14, paddingLeft: 14 }}>
              <View style={styles.container}>
                <View style={styles.contactType}>
                  <ItemLabel label="募集目的" />
                  <View style={styles.contactTypePicker}>
                    <CustomSelect value={purposeOptions[0].value} options={purposeOptions} onChange={onChangePurpose} />
                  </View>
                </View>
                <View style={styles.contactType}>
                  <ItemLabel label="競技カテゴリ" />
                  <View style={styles.contactTypePicker}>
                    {activityOptions && (
                      <CustomSelect
                        options={activityOptions ?? []}
                        onChange={onChangeActivity}
                        value={searchForm?.activity?.id}
                      />
                    )}
                  </View>
                </View>
                <View style={styles.contactType}>
                  <ItemLabel label="開催日" />
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      paddingTop: 16,
                    }}
                  >
                    <View style={[]}>
                      <CustomDateTimePicker value={searchForm?.dateFrom} onChange={onChangeStartDate} viewType="Date" />
                    </View>

                    <Text style={[styles.text1]}> ～ </Text>
                    <View style={[{ paddingLeft: 17 }]}>
                      <CustomDateTimePicker
                        value={searchForm?.dateTo}
                        onChange={onChangeEndDate}
                        min={searchForm?.dateFrom}
                        viewType="Date"
                      />
                    </View>
                  </View>
                </View>
                <View style={styles.contactType}>
                  <ItemLabel label="開催エリア" />
                  <View style={styles.contactTypePicker}>
                    <View style={{ display: "flex", flexDirection: "column" }}>
                      {prefectureOptions !== undefined && (
                        <View style={[styles.customButtonSelect]}>
                          <CustomSelect
                            options={prefectureOptions}
                            value={searchForm?.prefecture?.id.toString()}
                            onChange={onChangePrefecture}
                            placeholder={"都道府県を選択してください"}
                          />
                        </View>
                      )}
                      <View>
                        <CustomSelect
                          options={cityOptions ?? []}
                          value={searchForm?.city?.id}
                          onChange={onChangeCity}
                          placeholder={"市区町村を選択してください"}
                        />
                      </View>
                    </View>
                  </View>
                </View>
                <View style={styles.contactType}>
                  <ItemLabel label="年齢層" />
                  <View
                    style={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: "row",
                      paddingTop: 16,
                    }}
                  >
                    <View style={[styles.CustomSelectNativeLarge]}>
                      <CustomSelect
                        options={filteredAgeOptions(true)}
                        onChange={onChangeStartAge}
                        value={searchForm?.ageFrom?.label}
                        placeholder={""}
                      />
                    </View>
                    <Text style={[styles.text]}> ～ </Text>
                    <View style={[styles.CustomSelectNativeLarge]}>
                      <CustomSelect
                        options={filteredAgeOptions(false)}
                        onChange={onChangeEndAge}
                        value={searchForm?.ageTo?.label}
                        placeholder={""}
                      />
                    </View>
                    <Text style={[styles.text]}> 歳</Text>
                  </View>
                </View>
                <View style={styles.contactType}>
                  <ItemLabel label="キーワード" />
                  <View style={styles.titleTextInputLarge}>
                    <CustomTextInput
                      value={searchForm?.keyword}
                      onChangeText={onKeyWordChange}
                      placeholder="キーワードを入力してください"
                    />
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View
            style={[
              styles.actions,
              { paddingBottom: bottom + 16 },
              {
                width: "100%",
                display: "flex",
                alignItems: "center",
                flexDirection: "row-reverse",
                justifyContent: "center",
                marginTop: 16,
              },
            ]}
          >
            <View style={[{ width: 300, paddingTop: 16 }]}>
              <TextButton buttonType={ButtonType.Primary} title={"検索"} onPress={onSearch} disabled={isDisabled} />
            </View>
            <View style={[{ width: 300, paddingTop: 16, marginRight: 15 }]}>
              <TextButton buttonType={ButtonType.Secondary} title={"検索条件をクリア"} onPress={resetSearchData} />
            </View>
          </View>
        </CustomScrollView>
      )}
    </CustomKeyboardAvoidingView>
  )
})
const styles = StyleSheet.create({
  headerRightContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  bottomAction: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
    paddingHorizontal: 24,
  },
  text: {
    fontFamily: "Hiragino Kaku Gothic Pro",
    fontWeight: "600",
    fontSize: 18,
    lineHeight: 25,
  },
  text1: {
    fontFamily: "Hiragino Kaku Gothic Pro",
    fontWeight: "600",
    fontSize: 18,
    lineHeight: 25,
  },
  customButtonSelect: {
    paddingBottom: 20,
  },
  CustomSelect: {
    width: "42%",
  },
  CustomSelectNativeLarge: {
    width: "20%",
  },
  submitButton: {
    marginRight: 8,
  },
  textHeader: {
    fontSize: 18,
    font: "Hiragino Kaku Gothic Pro",
    fontWeight: "300",
    lineHeight: 25,
    textAlign: "center",
    width: "87%",
  },
  textHeaderLarge: {
    fontSize: 18,
    font: "Hiragino Kaku Gothic Pro",
    fontWeight: "300",
    lineHeight: 25,
    textAlign: "center",
    width: "100%",
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    minHeight: 64,
    width: "100%",
    paddingHorizontal: 16,
    borderColor: Colors.white2,
    borderBottomWidth: 1,
  },
  v3dbutton: {
    justifyContent: "center",
    alignItems: "center",
    width: 36,
    height: 36,
  },
  headerLeft: {
    width: 45,
    height: 45,
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  textHeaderPC: {
    fontSize: 18,

    fontWeight: "500",
    lineHeight: 25,
    textAlign: "center",
    width: "100%",
  },
  organizationContainer: {
    paddingTop: 16,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  organizationName: {
    marginLeft: 8,
    fontSize: 15,
    color: Colors.greyshBrown,
    fontWeight: "bold",
  },
  container: {
    paddingTop: 16,
  },
  containerLarge: {
    width: 600,
  },
  template: {
    paddingHorizontal: 16,
    backgroundColor: Colors.white,
    paddingVertical: 20,
  },
  templateLarge: {
    paddingHorizontal: 16,
    backgroundColor: Colors.white3,
    paddingVertical: 20,
  },
  templateRow: {
    flexDirection: "row",
    alignItems: "center",
  },
  templateLabel: {
    paddingLeft: 8,
  },
  templatePicker: {
    paddingTop: 16,
  },
  contactType: {
    paddingTop: 16,
    paddingHorizontal: 16,
  },
  contactTypePicker: {
    paddingTop: 16,
  },
  label: {
    fontSize: 16,
  },
  titleTextInput: {
    paddingTop: 16,
  },
  titleTextInputLarge: {
    paddingTop: 16,
    paddingBottom: 16,
  },
  actions: {
    paddingHorizontal: 16,
    paddingTop: 16,
    borderTopWidth: 1,
    borderTopColor: Colors.lightGrey,
  },
  actions1: {
    paddingHorizontal: 16,
    paddingTop: 16,
  },
})
