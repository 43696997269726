import { memo, useCallback, useEffect, useState } from "react"
import { FlatList, ListRenderItemInfo, Platform, StyleSheet, View } from "react-native"
import { ContactItem } from "src/components/parts/ContactItem"
import { ContactListFooterMargin } from "src/components/parts/contactNetworkTab/ContactListFooter"
import { DrawerStatus } from "src/components/parts/contactNetworkTab/DrawerStatus"
import { TrashBoxIcon } from "src/components/parts/icons/drawer/TrashBoxIcon"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { ContactNetworkDrawers } from "src/constants/ContactNetworkDrawers"
import { Screens } from "src/constants/Screens"
import { DrawerScreenOptions } from "src/navigation/ContactNetworkDrawer"
import { useTrashboxData } from "src/recoil/hooks/screens/useTrashboxData"
import type { ContactNetworkDrawerProps } from "src/types.d"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { ContactListEmpty } from "src/components/parts/contactNetworkTab/ContactListEmpty"
import { DrawerIconContainer } from "src/components/parts/contactNetworkTab/DrawerIconContainer"
import { useContactNetworkDrawerNavEffect } from "src/recoil/hooks/contact/useContactNetworkDrawerNavEffect"
import { RootTabs } from "src/constants/RootTabs"
import { BannerAd } from "src/tags/ads/BannerAd"
import { Buckets } from "src/aws/customAPI"
import { requestDataSelector } from "src/recoil/selectors/auth/requestDataSelector"
import { useRecoilValue } from "recoil"
import { getUnreadThreadCount } from "src/apis/contact/getUnreadThreadCount"
import { adLoadState, isLoadDoneAd } from "src/recoil/atoms/ads/adState"
import { useRecoilState } from "recoil"
import { MainLayout } from "src/layouts/MainLayout"
import { DrawerLabel } from "src/components/parts/contactNetworkTab/DrawerLabel"
export type TrashBoxScreenParams = {
  organizationId?: string
}

export const TrashBoxScreen = memo<ContactNetworkDrawerProps<typeof ContactNetworkDrawers.TrashBoxScreen>>(
  ({ navigation, route }) => {
    const [unreadThreadCountBuckets, setUnreadThreadCountBuckets] = useState<Buckets[]>([])
    const request = useRecoilValue(requestDataSelector)
    const {
      contacts,
      isLoadingContacts,
      refreshContacts,
      organizationId,
      refreshContactDetailData,
      execDeleteContact,
      execRestoreContact,
      execUpdateFavoriteContact,
      favoriteFlgsCache,
      setNextTokens,
      organization,
    } = useTrashboxData()
    const [adLoadStateList] = useRecoilState(adLoadState)

    useEffect(() => {
      const contactIds = contacts?.contacts.map((el) => el.id)
      if (request) getUnreadThreadCount({ ...request, contactIds }).then((data) => setUnreadThreadCountBuckets(data.buckets))
    }, [request, contacts])
    function calculateUnreadThreadCount(contactId: string, unreadThreadCountBuckets: Buckets[]) {
      const unreadThread = unreadThreadCountBuckets?.find((el) => el.key === contactId)
      return unreadThread?.doc_count ?? 0
    }

    // web版のリロードに対応するため、クエリパラメータを付与してリダイレクト
    useContactNetworkDrawerNavEffect({
      queryParamOrganizationId: route.params?.organizationId,
      screenName: ContactNetworkDrawers.TrashBoxScreen,
      redirect: (organizationId) => {
        navigation.replace(Screens.Root, {
          screen: RootTabs.ContactNetworkDrawer,
          params: {
            screen: ContactNetworkDrawers.TrashBoxScreen,
            params: { organizationId: organizationId },
          },
        })
      },
    })

    const gotoContactDetail = useCallback(
      (contactId: string) => {
        if (organizationId == null) {
          return
        }
        refreshContactDetailData()
        navigation.navigate(Screens.ContactDetail, { contactId, organizationId })
      },
      [navigation, organizationId, refreshContactDetailData]
    )

    const ListItem = useCallback(
      (info: ListRenderItemInfo<ContactOverviewModel>) => (
        <View style={styles.contentWrapper}>
          <ContactItem
            contactId={info.item.id}
            viewType="Trashbox"
            title={info.item.title}
            contactType={info.item.contactType}
            sentDate={info.item.sentDate}
            timeLimitDate={info.item.deadlineDate}
            isRead={info.item.isRead}
            senderId={info.item.senderId}
            senderName={info.item.senderName}
            isSenderDeleted={info.item.isSenderDeleted}
            isFavorite={info.item.isFavorite}
            hasAttachment={info.item.isAttachmentExists}
            onIsFavoriteChange={() =>
              execUpdateFavoriteContact({
                contactId: info.item.id,
                isFavorite: !(favoriteFlgsCache?.[info.item.id] ?? info.item.isFavorite),
              })
            }
            favoriteFlgsCache={favoriteFlgsCache}
            onContactPress={() => gotoContactDetail(info.item.id)}
            onDeletePress={() => execDeleteContact(info.item.id)}
            onRestorePress={() => execRestoreContact(info.item.id)}
            noBorder
            unreadThreadCount={calculateUnreadThreadCount(info.item.id, unreadThreadCountBuckets)}
          />
          {Platform.OS !== "web" && (!organization?.paidFunctionEnabled || organization?.showAdsFlg) && info.index === 2 ? (
            <View style={!isLoadDoneAd("trash_upper", adLoadStateList) && { height: 0 }}>{BannerAd.TrashUpper}</View>
          ) : null}
          {Platform.OS !== "web" && (!organization?.paidFunctionEnabled || organization?.showAdsFlg) && info.index === 5 ? (
            <View style={!isLoadDoneAd("trash_bottom", adLoadStateList) && { height: 0 }}>{BannerAd.TrashBottom}</View>
          ) : null}
        </View>
      ),
      [
        favoriteFlgsCache,
        unreadThreadCountBuckets,
        adLoadStateList,
        execUpdateFavoriteContact,
        gotoContactDetail,
        execDeleteContact,
        execRestoreContact,
        organization,
      ]
    )
    const getMoreContacts = useCallback(() => {
      setNextTokens((prev) => (contacts?.nextToken != null ? [...prev, contacts.nextToken] : prev))
    }, [contacts, setNextTokens])

    return (
      <MainLayout>
        <View style={[styles.container]}>
          <DrawerStatus Icon={<TrashBoxIcon color={Colors.orange} />} label="ゴミ箱" />
          <View style={styles.line}></View>
          <FlatList
            data={contacts?.contacts}
            renderItem={ListItem}
            ListFooterComponent={ContactListFooterMargin}
            ListEmptyComponent={!isLoadingContacts ? <ContactListEmpty label="連絡はありません。" /> : undefined}
            refreshing={isLoadingContacts}
            refreshControl={<CustomRefreshControl refreshing={isLoadingContacts} onRefresh={refreshContacts} />}
            onEndReached={!isLoadingContacts ? getMoreContacts : undefined}
            keyExtractor={(item) => item.id}
          />
        </View>
      </MainLayout>
    )
  }
)
TrashBoxScreen.displayName = "TrashBoxScreen"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  searchContainer: {
    padding: 16,
  },
  titleContainer: {},
  title: {
    fontSize: 20,
  },
  pickerContainer: {
    width: "30%",
    minWidth: 120,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  contentWrapper: {
    paddingHorizontal: 16,
    paddingTop: 10,
  },
  line: {
    width: "100%",
    height: 1,
    backgroundColor: Colors.white2,
  },
})

export const trashBoxScreenOptions: DrawerScreenOptions = {
  title: "ゴミ箱",
  drawerIcon: (props) => (
    <DrawerIconContainer>
      <TrashBoxIcon {...props} color={Colors.orange} />
    </DrawerIconContainer>
  ),
  drawerLabel: () => <DrawerLabel title="ゴミ箱" showBadge={false} />,
}
