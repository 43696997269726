import { atomFamily, selectorFamily } from "recoil"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"

export const isShowPhoneNumberStateFamily = atomFamily<boolean | undefined, string>({
  key: "atoms/organization/myProfile/isShowPhoneNumberStateFamily",
  default: selectorFamily<boolean | undefined, string>({
    key: "atoms/organization/myProfile/isShowPhoneNumberStateFamily_default",
    get:
      (organizationId) =>
      ({ get }) => {
        const myProfile = get(myProfileSelectorFamily(organizationId))
        return myProfile?.isShowPhoneNumber
      },
  }),
})
