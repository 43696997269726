import { useMemo } from "react"
import { memo } from "react"
import { Svg, Path, Rect, G } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  color?: string
  backgroundColor?: string
  filled?: boolean
}

export const ContactIcon = memo<Props>(({ filled, color, backgroundColor }) => {
  const defaultColor = useMemo(() => color ?? Colors.white, [color])
  const fill = useMemo(() => {
    if (filled) {
      return defaultColor
    }
    return "none"
  }, [filled, defaultColor])
  const stroke = useMemo(() => {
    if (filled) {
      return backgroundColor ?? Colors.greyshBrown
    }
    return defaultColor
  }, [filled, defaultColor, backgroundColor])

  return (
    <Svg width="21.54" height="13.393" viewBox="0 0 21.54 13.393">
      <G data-name="Icon Mail" transform="translate(-.64 -2.303)">
        <Rect
          data-name="長方形 29"
          width="20.36"
          height="12.393"
          rx="2"
          transform="translate(1.32 2.803)"
          fill={fill}
          stroke={defaultColor}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        />
        <Path
          data-name="パス 65"
          d="m1 2 10.028 5.571L21.056 2"
          transform="translate(.32 1.918)"
          fill={fill}
          stroke={stroke}
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeMiterlimit={10}
        />
      </G>
    </Svg>
  )
})
