import { useEffect, useMemo } from "react"
import { FlatList, ListRenderItemInfo, StyleSheet, View } from "react-native"
import { useRecoilValue } from "recoil"
import { SearchInput } from "src/components/parts/SearchInput"
import { DrawerStatus } from "src/components/parts/contactNetworkTab/DrawerStatus"
import { MatchingFavoriteIcon } from "src/components/parts/icons/MatchingFavoriteIcon"
import { MatchingListEmpty } from "src/components/parts/matching/MatchingListEmpty"
import { MatchingListFooter, MatchingListFooterMargin } from "src/components/parts/matching/MatchingListFooter"
import { MyMatchingCard } from "src/components/parts/matching/MyMatchingCard"
import { CustomRefreshControl } from "src/components/projects/CustomRefreshControl"
import { Colors } from "src/constants/Colors"
import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { ListActionBackInThread } from "src/constants/OfferDetail"
import { Screens } from "src/constants/Screens"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { MainLayout } from "src/layouts/MainLayout"
import { routeNameState } from "src/recoil/atoms/routeNameState"
import { useFavoriteMatchingOffers } from "src/recoil/hooks/matching/useFavoriteMatchingOffers"
import { useMyMatchingOffers } from "src/recoil/hooks/matching/useMyMatchingOffers"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { OrganizationManagerStackScreenProps } from "src/types.d"
import { MatchingOffersModel } from "src/types/matching/matchingOfferModel"

export const FavoriteMatchingOffer = ({
  navigation,
  route,
}: OrganizationManagerStackScreenProps<typeof MatchingDrawers.FavoriteMatchingOffer>) => {
  const routeName = useRecoilValue(routeNameState)
  const {
    favOffers,
    isLoading,
    refreshList,
    loadMore,
    hasMore,
    removeFavorite,
    searchText,
    setSearchText,
    setFavOffersCaChe,
    favOffersCache,
  } = useFavoriteMatchingOffers()
  const { checkCreateNewOffer, resetValueCreateOffer } = useMyMatchingOffers()
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const { refreshResourceUrl: refreshTeamImageUrl } = useResource({
    type: "matchingTeamImage",
    teamId: organizationId || "",
    size: "thumbnail",
  })

  const gotoMyOfferDetail = (offerId: string) => {
    navigation.navigate(MatchingDrawers.MatchingOfferDetail, { id: offerId, isFavorite: true })
  }

  const gotoCreateNewMatching = async () => {
    resetValueCreateOffer()
    checkCreateNewOffer(() => navigation.navigate(Screens.MatchingOfferCreate))
  }

  const ListItem = (listRenderItemInfo: ListRenderItemInfo<MatchingOffersModel>) => {
    const { item } = listRenderItemInfo
    return (
      <View style={[styles.contentWrapper]}>
        <MyMatchingCard
          data={item}
          to={ListActionBackInThread.favorite}
          checkIsExpiredOrDeleted={true}
          onPress={() => gotoMyOfferDetail(item.id)}
          showFavBtn={true}
          handleChangeFav={removeFavorite}
        />
      </View>
    )
  }

  useEffect(() => {
    if (routeName === MatchingDrawers.FavoriteMatchingOffer) {
      refreshList("")
      refreshTeamImageUrl()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [routeName])

  return (
    <MainLayout>
      <View style={[styles.container]}>
        <DrawerStatus Icon={<MatchingFavoriteIcon />} label="お気に入り" />
        <SearchInput value={searchText} onChangeText={setSearchText} onBlur={() => refreshList(searchText ?? "")} />
        <FlatList
          data={favOffers}
          renderItem={ListItem}
          ListEmptyComponent={!isLoading ? <MatchingListEmpty label="表示できる募集がありません。" /> : undefined}
          ListFooterComponent={MatchingListFooterMargin}
          refreshing={isLoading}
          refreshControl={<CustomRefreshControl refreshing={isLoading} onRefresh={() => refreshList(searchText ?? "")} />}
          onEndReached={!isLoading && hasMore ? () => loadMore() : undefined}
          keyExtractor={(item) => item.id}
        />
        <MatchingListFooter
          onPress={gotoCreateNewMatching}
          closeCallback={() => navigation.navigate(MatchingDrawers.OtherMatchingOffer)}
        />
      </View>
    </MainLayout>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  header: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: Colors.white,
    height: 50,
  },
  headerTitle: {
    fontSize: 15,
    fontWeight: "bold",
    color: Colors.greyshBrown,
    marginLeft: 8,
  },
  contentWrapper: {
    paddingHorizontal: 16,
    paddingTop: 10,
  },
})
