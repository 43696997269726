import { Result } from "src/utils/Result"
import { GqlError, getTeamMemberCsvData as _getTeamMemberCsvData } from "src/aws/customAPI";
import { TeamMemberModel } from "src/types/csv/teamMemberCsvData";

type GetTeamMembersCsvDataRequestParams = {
  accessToken: string
  teamId: string
  groupId?: string
}

type GetTeamMembersCsvDataSuccess = TeamMemberModel[]

type GetTeamMembersCsvDataFailure = {
  message: string
}

export const getTeamMemberCsvData = async (
  params: GetTeamMembersCsvDataRequestParams
): Promise<Result<GetTeamMembersCsvDataSuccess, GetTeamMembersCsvDataFailure>> => {
  return _getTeamMemberCsvData(params.accessToken, {
    input: {
      teamId: params.teamId,
      groupId: params.groupId
    }
  }).then(res => {
    const members: TeamMemberModel[] = res.items.map(item => ({
      id: item.id,
      memberSeq: item.memberSeq,
      nickname: item.nickname,
      nicknameKana: item.nicknameKana,
      role: item.role,
      groups: item.groups,
      leaderGroups: item.leaderGroups
    })) || [];
    return Result.Ok<GetTeamMembersCsvDataSuccess>(members);
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "予期せぬエラーが発生しました";
    return Result.Error<GetTeamMembersCsvDataFailure>({
      message: message
    });
  });
};