import { memo } from "react"
import Svg, { Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

export const ReloadIcon = memo(() => (
  <Svg width="12" height="12" viewBox="0 0 12 12" fill="none">
    <Path
      d="M10.2378 1.7625C9.15406 0.675 7.65781 0 6.00031 0C2.68531 0 0.0078125 2.685 0.0078125 6C0.0078125 9.315 2.68531 12 6.00031 12C8.79406 12 11.1341 10.0875 11.7978 7.5H10.2378C9.61906 9.2475 7.96156 10.5 6.00031 10.5C3.51406 10.5 1.50031 8.48625 1.50031 6C1.50031 3.51375 3.51406 1.5 6.00031 1.5C7.24156 1.5 8.35531 2.0175 9.16906 2.83125L6.75031 5.25H12.0003V0L10.2378 1.7625Z"
      fill={Colors.orange}
    />
  </Svg>
))
