import type { ValueOf } from "src/types.d"
import { SportsIcon } from "src/components/parts/icons/CreateOrganization/SportsIcon"
import { CultureIcon } from "src/components/parts/icons/CreateOrganization/CultureIcon"
import { WorkIcon } from "src/components/parts/icons/CreateOrganization/WorkIcon"
import { EducationIcon } from "src/components/parts/icons/CreateOrganization/EducationIcon"
import { FriendshipIcon } from "src/components/parts/icons/CreateOrganization/FriendshipIcon"
import { OthersIcon } from "src/components/parts/icons/CreateOrganization/OthersIcon"
import { FunctionComponent } from "react"

export const OrganizationCategory = Object.freeze({
  Sports: "Sports",
  Culture: "Culture",
  Work: "Work",
  Education: "Education",
  Friendship: "Friendship",
  Others: "Others",
} as const)

export type OrganizationCategory = ValueOf<typeof OrganizationCategory>

const OrganizationCategoryLabel: { [key in OrganizationCategory]: string } = {
  [OrganizationCategory.Sports]: "スポーツ系\nサークル・チーム",
  [OrganizationCategory.Culture]: "文化・趣味系\nサークル・団体",
  [OrganizationCategory.Work]: "仕事関係",
  [OrganizationCategory.Education]: "学校・教育",
  [OrganizationCategory.Friendship]: "友達・家族",
  [OrganizationCategory.Others]: "その他組織",
}

export const getCategoryLabel = (category: OrganizationCategory) => OrganizationCategoryLabel[category]

const OrganizationCategoryIcon: { [key in OrganizationCategory]: FunctionComponent } = {
  [OrganizationCategory.Sports]: SportsIcon,
  [OrganizationCategory.Culture]: CultureIcon,
  [OrganizationCategory.Work]: WorkIcon,
  [OrganizationCategory.Education]: EducationIcon,
  [OrganizationCategory.Friendship]: FriendshipIcon,
  [OrganizationCategory.Others]: OthersIcon,
}

export const organizationCategories = Object.values(OrganizationCategory).map((id) => ({
  id,
  label: OrganizationCategoryLabel[id],
  Icon: OrganizationCategoryIcon[id],
}))
