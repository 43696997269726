import { memo } from "react"
import { StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"

export const ExpiryBadge = memo(() => (
  <View style={styles.badge}>
    <Text style={styles.badgeText}>※認証コードの有効期限は3分です</Text>
  </View>
))

const styles = StyleSheet.create({
  badge: {
    height: 22,
    borderRadius: 11,
    borderWidth: 1,
    borderColor: Colors.turtleGreen,
    alignSelf: "flex-start",
    justifyContent: "center",
    paddingHorizontal: 12,
    fontSize: 12,
  },
  badgeText: {
    color: Colors.turtleGreen,
  },
})
