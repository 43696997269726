import { memo, useCallback } from "react"
import { StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { Button } from "src/components/parts/buttons/Button"
import * as WebBrowser from "expo-web-browser"
import { migration } from "src/constants/links/migration"

type Props = {
  onClose: () => void
}

export const OrganizationMigrationDescription = memo<Props>(({ onClose }) => {
  const openMigrationDescription = useCallback(async () => {
    await WebBrowser.openBrowserAsync(migration.description)
  }, [])

  return (
    <View style={styles.container}>
      <Button onPress={onClose}>
        <RemoveIcon fill={Colors.orange} />
      </Button>
      <Text style={styles.text}>
        「らくらく連絡網」で代表者となっている団体情報を「らくらく連絡網＋」に移行し、メンバーを一括招待できます。{`\n`}
        移行完了後、代表者に限り【連絡】→【移行トレイ】より「らくらく連絡網」の送信トレイの内容も移行可能です。{`\n\n`}
        ※元の登録が消えることはありません。{`\n`}
        ※移行方法の詳細は
        <Button onPress={openMigrationDescription}>
          <Text style={styles.linkText}>こちら</Text>
        </Button>
      </Text>
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white3,
    borderRadius: 8,
    paddingVertical: 24,
    paddingHorizontal: 20,
  },
  text: {
    color: Colors.greyshBrown,
    fontSize: 14,
    lineHeight: 20,
    marginTop: 12,
  },
  linkText: {
    color: Colors.orange,
    textDecorationLine: "underline",
  },
})
