import { atom } from "recoil"

export const myOrganizationsRequestIdState = atom({
  key: "atoms/organization/myOrganizationsRequestIdState",
  default: 0,
})

export const myFullOrganizationsRequestIdState = atom({
  key: "atoms/organization/myFullOrganizationsRequestIdState",
  default: 0,
})
