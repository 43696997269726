import { View, Text, StyleSheet } from "react-native"
import { BackIcon } from "./icons/BackIcon"
import { Colors } from "src/constants/Colors"
import { memo } from "react"
import { useNavigation } from "@react-navigation/native"
import { Button } from "./buttons/Button"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

type Props = {
  title: string
  back?: () => void
  needBackIcon?: boolean
  noMarginBot?: boolean
}

export const HeaderCommon = memo<Props>(({ title, back, needBackIcon = true, noMarginBot }) => {
  const navigation = useNavigation()
  const isLargeScreen = useCheckPCScreen()
  if (!isLargeScreen) return <></>
  return (
    <View style={[styles.container, noMarginBot ? { marginBottom: 0 } : null]}>
      {needBackIcon && (
        <Button
          onPress={() => {
            if (back) {
              back()
            } else {
              navigation.goBack()
            }
          }}
        >
          <BackIcon fill={Colors.orange}></BackIcon>
        </Button>
      )}
      <View style={styles.title}>
        <Text style={styles.titleText}>{title}</Text>
      </View>
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    alignContent: "center",
    padding: 10,
    borderBottomColor: "#f0f0f0",
    borderBottomWidth: 1,
    marginBottom: 5,
  },
  title: {
    // @ts-ignore
    width: "calc(100% - 36px)",
    backgroundColor: "white",
    display: "flex",
    alignItems: "center",
  },
  titleText: {
    fontSize: 20,
    fontWeight: "bold",
    paddingTop: 5,
  },
})
