import { ComponentProps, memo } from "react"
import { TextButton } from "src/components/parts/buttons/TextButton"
import { StyleSheet, View } from "react-native"
import { Colors } from "src/constants/Colors"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

type Props = ComponentProps<typeof TextButton>

export const SingleButtonFooter = memo<Props>((props) => {
  const { bottom } = useSafeAreaInsets()
  const isLargeScreen = useCheckPCScreen()
  return (
    <View style={[isLargeScreen ? styles.webContainer : styles.container, { paddingBottom: bottom + 10 }]}>
      <TextButton {...props} style={isLargeScreen ? { width: 300 } : {}} />
    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    width: "100%",
    paddingHorizontal: 24,
    paddingVertical: 12,
    paddingBottom: 32,
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  webContainer: {
    alignItems: "center",
    paddingVertical: 12,
    paddingBottom: 32,
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
})
