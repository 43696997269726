import { listTeam } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
  accountId: string
}

export type MyOrganization = {
  id: string
  title: string
  uriImage?: string
  organizationNumber: string
  myMemberNumber: number
  migrationId: number | undefined
  paidFunctionEnabled: boolean
  showAdsFlg: boolean
}

export const getMyOrganizations = async ({ accessToken, accountId }: Params): Promise<MyOrganization[]> => {
  const result = await execApi(
    () =>
      listTeam(accessToken, {
        accountId: accountId,
        appInfo,
      }),
    (res) => {
      return res.listTeam.items.map((item) => ({
        id: item.team.id,
        title: item.team.name,
        organizationNumber: item.team.invitationCode,
        myMemberNumber: item.memberSeq,
        migrationId: item.team.migrationId,
        paidFunctionEnabled: item.team.paidFunctionEnabled ?? true,
        showAdsFlg: item.team.showAdsFlg ?? true,
      }))
    },
    {
      identifiers: ["getMyOrganizations", "listTeam"],
    }
  )

  return result.isOk ? result.content : []
}
