import { useCallback, useEffect, useMemo, useState } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { memberInvitationSelectorFamily } from "src/recoil/selectors/organization/memberInvitationSelectorFamily"
import { CustomAlert } from "src/utils/CustomAlert"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { invitationEmailsConcatSelectorFamily } from "src/recoil/selectors/organization/invitationEmailsEmailSelectorFamily"
import { useFetcher } from "src/hooks/useFetcher"
import { useRequestData } from "../useRequestData"
import { resendInvitationEmail } from "src/apis/team/invitation/resendInvitationEmail"
import {
  invitationEmailsConcatRequestIdState,
  invitationEmailsRequestIdState,
} from "src/recoil/atoms/organization/invitationEmailsRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"

export const useSendingHistoryData = (id: string) => {
  const { value: sendingHistoryData, isLoading: isSendingHistoryDataLoading } = useAsyncSelector(
    memberInvitationSelectorFamily(id)
  )
  const { resourceUrl: thumbnailImageUrl } = useResource({ type: "teamImage", id, size: "thumbnail" })
  const organizationId = useMemo(() => id, [id])
  const organizationName = useMemo(() => sendingHistoryData?.organizationName, [sendingHistoryData])
  const refreshEmailsConcat = useRefresher(invitationEmailsConcatRequestIdState)
  const refreshEmails = useRefresher(invitationEmailsRequestIdState)

  const [nextTokens, setNextTokens] = useState<string[]>([])
  useEffect(() => {
    setNextTokens([])
    refreshEmailsConcat()
    refreshEmails()
  }, [organizationId, refreshEmails, refreshEmailsConcat, setNextTokens])

  const params = useMemo(
    () => ({
      organizationId: organizationId ?? "",
    }),
    [organizationId]
  )
  const { value: emails, isLoading: isEmailsLoading } = useAsyncSelector(
    invitationEmailsConcatSelectorFamily({
      ...params,
      nextTokens,
    })
  )

  const requestDataResult = useRequestData()
  const { fetch: onResend, isFetching: isLoadingResend } = useFetcher(
    useCallback(
      async (invitationId: string) => {
        if (!requestDataResult.isOk) return
        const { accessToken } = requestDataResult.content
        if (accessToken == null || invitationId == null || organizationId == null) return
        const result = await resendInvitationEmail({
          accessToken,
          teamId: organizationId,
          invitationId,
        })
        if (!result.isOk) {
          await CustomAlert.alert("エラー", result.error.message)
          return
        }
        await CustomAlert.alert("完了", "招待メールが再送信されました。")
      },
      [requestDataResult, organizationId]
    )
  )

  return {
    thumbnailImageUrl,
    organizationName,
    isSendingHistoryDataLoading,
    emails,
    isEmailsLoading,
    onResend,
    isLoadingResend,
    setNextTokens,
  }
}
