import { selector } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { hasPreTeamMemberRequestIdState } from "src/recoil/atoms/bottomTab/hasPreTeamMemberRequestIdState"
import { getHasPreTeamMemberByTeam } from "src/apis/bottomTab/hasPreTeamMember"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"

export const hasPreTeamMemberSelector = selector({
  key: "selectors/bottomTab/hasPreTeamMemberSelector",
  get: ({ get }) => {
    get(hasPreTeamMemberRequestIdState)
    const tokens = get(tokensState)
    const accessToken = tokens?.accessToken
    if (accessToken == null) {
      return
    }
    const accountId = get(accountIdState)
    if (accountId == null) {
      return
    }

    return getHasPreTeamMemberByTeam({ accessToken, accountId })
  },
})
