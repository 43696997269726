import { listTeam, MemberRole } from "src/aws/customAPI"

type Params = {
  accessToken: string
  accountId: string
}

export type MyTeam = {
  id: string
  title: string
  role: MemberRole
  teamNumber: string
  myMemberNumber: number
}

export const getMyTeams = async ({ accessToken, accountId }: Params): Promise<MyTeam[]> => {
  const res = await listTeam(accessToken, {
    accountId: accountId,
  })

  return (
    res.data?.listTeam.items.map((item) => ({
      id: item.team.id,
      title: item.team.name,
      role: item.role,
      teamNumber: item.team.invitationCode,
      myMemberNumber: item.memberSeq,
    })) || []
  )
}
