import { FC } from "react"
import Svg, { Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
}

export const EditIcon: FC<Props> = ({ fill = Colors.orange }) => (
  <Svg width="14.694" height="13.346" viewBox="0 0 14.694 13.346">
    <Path
      data-name="パス 948"
      d="M46.415 6.216 45.3 5.133A.5.5 0 0 0 44.961 5a.438.438 0 0 0-.329.133l-6.55 6.3a.533.533 0 0 0-.109.148l-.735 1.764a.419.419 0 0 0 .109.474.462.462 0 0 0 .329.133.721.721 0 0 0 .172-.03l1.845-.7a.563.563 0 0 0 .156-.1l6.55-6.3a.389.389 0 0 0 .015-.608z"
      transform="translate(-31.86 -5)"
      fill={fill}
    />
    <Path
      data-name="パス 949"
      d="M14.569 16.279a.755.755 0 0 0-.782.741v4.952H4.563v-9.49h7.6a.742.742 0 1 0 0-1.483H3.782a.741.741 0 0 0-.782.713v11a.755.755 0 0 0 .782.741h10.756a.793.793 0 0 0 .813-.741v-5.691a.755.755 0 0 0-.782-.741z"
      transform="translate(-3 -10.11)"
      fill={fill}
    />
  </Svg>
)

EditIcon.displayName = "EditIcon"
