import { Result } from "src/utils/Result";
import { MatchingOfferDetailsModel } from "../../types/matching/matchingOfferDetailsModel";
import { getMatchingOffer, GqlError } from "../../aws/customAPI";

type GetMatchingOfferDetailsParams = {
  accessToken: string
  offerId: string
  type: "myOffer" | "otherOffer"
  organizationMemberId: string
}

export type GetMatchingOfferDetailsSuccess = MatchingOfferDetailsModel

type GetMatchingOfferDetailsFailure = {
  message: string
}

export const getMatchingOfferDetails = async (params: GetMatchingOfferDetailsParams): Promise<Result<GetMatchingOfferDetailsSuccess, GetMatchingOfferDetailsFailure>> => {
  return await getMatchingOffer(params.accessToken, {
    id: params.offerId,
    type: params.type,
    teamMemberId: params.organizationMemberId
  }).then(res => {
    const offer = res.data?.getMatchingOffer;
    if (offer == null) return Result.Error<GetMatchingOfferDetailsFailure>({ message: "募集の取得に失敗しました" });
    return Result.Ok<GetMatchingOfferDetailsSuccess>({
      id: offer.id,
      organizationId: offer.teamId,
      organizationName: offer.teamName,
      deleteFlg: offer.deleteFlg,
      activity: offer.activity,
      title: offer.title,
      body: offer.body,
      date: offer.date,
      startTime: offer.startTime,
      endTime: offer.endTime,
      prefecture: offer.prefecture,
      city: offer.city,
      place: offer.place,
      placeURL: offer.placeURL,
      expense: offer.expense,
      expensePurpose: offer.expensePurpose,
      ageFrom: offer.ageFrom,
      ageTo: offer.ageTo,
      offerCount: offer.offerCount,
      publishEndDate: offer.publishEndDate,
      attachmentFileNames: offer.attachmentFileNames,
      favoriteFlg: offer.favoriteFlg,
      applications: offer.applications,
      createdAt: offer.createdAt,
      updatedAt: offer.updatedAt
    });
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "募集の取得に失敗しました";
    return Result.Error<GetMatchingOfferDetailsFailure>({
      message: message
    });
  });
};