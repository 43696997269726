import { atomFamily, selectorFamily } from "recoil"
import { notificationSettingsSelector } from "src/recoil/selectors/settings/notificationSettingsSelector"

export const isNewContactEmailEnabledStateFamily = atomFamily<boolean | undefined, string>({
  key: "atoms/settings/notifications/isNewContactEmailEnabledStateFamily",
  default: selectorFamily<boolean | undefined, string>({
    key: "atoms/settings/notifications/isNewContactEmailEnabledStateFamily_default",
    get:
      (organizationId) =>
      ({ get }) => {
        const settings = get(notificationSettingsSelector)
        if (settings == null) {
          return
        }
        const settingSelect = settings.find((e) => e.teamId === organizationId)
        return settingSelect?.mailReceivedNotice.email
      },
  }),
})
