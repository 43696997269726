import { atom, selector } from "recoil"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"

export const lastNameState = atom({
  key: "atoms/account/lastNameState",
  default: selector({
    key: "atoms/account/lastNameState_default",
    get: ({ get }) => {
      const account = get(accountSelector)
      return account?.lastName
    },
  }),
})
