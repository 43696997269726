import { memo, useCallback, useEffect } from "react"
import { useSetRecoilState } from "recoil"
import { GroupInOrganizationEditor } from "src/components/parts/organizationTab/GroupInOrganizationEditor"
import { useFetcher } from "src/hooks/useFetcher"
import { groupMemberListRequestIdState } from "src/recoil/atoms/organization/group/groupMemberListIdState"
import { useGroupEditorData } from "src/recoil/hooks/organization/group/useGroupEditorData"
import { CreateOrEditGroupStackScreenProps } from "src/types.d"
import { CreateOrEditGroupScreens } from "src/constants/CreateOrEditGroupScreens"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { CustomAlert } from "src/utils/CustomAlert"
import { putNameGroupAndGroupMembers } from "src/queries/organization/putNameGroupAndGroupMembers"
import { Screens } from "src/constants/Screens"
import { useCheckAllowAccountPermission } from "src/recoil/hooks/screens/useCheckAllowAccountPermission"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"

export type EditGroupMembersParams = {
  organizationId: string
  groupId: string
}

type Props = CreateOrEditGroupStackScreenProps<typeof CreateOrEditGroupScreens.EditGroupMembers>

export const EditGroupMembers = memo<Props>(
  ({
    navigation,
    route: {
      params: { organizationId, groupId },
    },
  }) => {
    const { value: tokens } = useAsyncSelector(tokensState)
    const {
      groupName,
      setGroupName,
      searchText,
      setSearchText,
      toggleGroupLeader,
      deleteMember,
      recoverMember,
      mergedMembers,
      debouncedSearchText,
      resetState,
      refresh,
      totalMember,
      loadMoreMembers,
      isMembersGroupLoading,
      filteredMembers,
      setDefaultGroupMembers,
    } = useGroupEditorData(groupId, organizationId)
    const setRequestId = useSetRecoilState(groupMemberListRequestIdState)
    const { allowEditGroup } = useCheckAllowAccountPermission({})
    const putGroupMembersCallback = useCallback(async () => {
      if (!tokens) return
      const result = await putNameGroupAndGroupMembers({
        accessToken: tokens.accessToken,
        groupId,
        groupName,
        members: mergedMembers,
      })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        if (result.error.message.includes("404")) {
          await refresh()
          await navigation.navigate(Screens.Root, {
            screen: RootTabs.OrganizationManagerTab,
            params: {
              screen: OrganizationManagerScreens.OrganizationDetail,
              params: { organizationId: organizationId },
            },
          })
        }
        return
      }
      CustomAlert.alert("完了", "保存しました。")
      refresh()
      navigation.goBack()
    }, [tokens, mergedMembers, groupId, groupName, refresh, navigation, organizationId])
    const { fetch: fetchPostGroupMembers, isFetching: isPostGroupMembersFetching } = useFetcher(putGroupMembersCallback)
    const onSubmit = useCallback(async () => {
      await fetchPostGroupMembers()
      setDefaultGroupMembers([])
    }, [fetchPostGroupMembers, setDefaultGroupMembers])

    useEffect(() => {
      setDefaultGroupMembers([])
    }, [setDefaultGroupMembers])

    useEffect(() => {
      setRequestId((prev) => prev + 1)
      return () => {
        resetState()
      }
    }, [resetState, setRequestId, organizationId, groupId])

    return (
      <GroupInOrganizationEditor
        organizationId={organizationId}
        groupId={groupId}
        name={groupName}
        members={filteredMembers}
        searchDebouce={debouncedSearchText}
        searchText={searchText}
        onSearchTextChange={setSearchText}
        isMemberSubmitting={isPostGroupMembersFetching}
        onNameChange={setGroupName}
        recoverMember={recoverMember}
        deleteMember={deleteMember}
        toggleLeader={toggleGroupLeader}
        onSubmit={onSubmit}
        totalMember={totalMember}
        loadMoreMembers={loadMoreMembers}
        isMembersGroupLoading={isMembersGroupLoading}
        setDefaultGroupMembers={setDefaultGroupMembers}
      />
    )
  }
)
