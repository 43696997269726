import { MemberType, OrganizationMemberOverview } from "src/types/organization/OrganizationMember"
import { Result } from "src/utils/Result"
import { listTeamMember } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type GetOrganizationMembersRequestParams = {
  accessToken: string
  accountId: string
  organizationId: string
}

type GetOrganizationMembersSuccess = OrganizationMemberOverview[]

type GetOrganizationMembersFailure = {
  message: string
}

export const getOrganizationMembers = async (
  params: GetOrganizationMembersRequestParams
): Promise<Result<GetOrganizationMembersSuccess, GetOrganizationMembersFailure>> => {
  return execApi(
    () => listTeamMember(params.accessToken, {
      teamId: params.organizationId,
      appInfo
    }),
    res => {
      return res.listTeamMember.items.map(item => ({
        id: item.id,
        nickname: item.nickname,
        memberNumber: item.memberSeq,
        memberType: memberTypeMapping[item.role],
        isLeader: item.groupLeaderFlg,
        isInGroup: item.inGroupFlg,
        isAdministrator: item.role === 'manager',
        groupIds: item.groupIds
      })) || []
    }
  )
}

const memberTypeMapping ={
  general: MemberType.General,
  mailSender: MemberType.GeneralWithContactAuthorization,
  manager: MemberType.Representative,
}
