import { atom, selector } from "recoil"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"

export const accountIdState = atom({
  key: "atoms/account/accountIdState",
  default: selector({
    key: "atoms/account/accountIdState_default",
    get: ({ get }) => {
      const account = get(accountSelector)
      return account?.id
    },
  }),
})
