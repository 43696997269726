import { StyleSheet, Text, View } from "react-native"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { SelectOption } from "src/types/SelectOption"
import { Button } from "src/components/parts/buttons/Button"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { ValidationErrorMessage } from "src/components/parts/ValidationErrorMessage"
import { ApplicantInformationData } from "src/recoil/atoms/applicantInformation/applicantInformationDataState"

type Props = {
  errorMessage: string
  representativeNameError: string
  companyRepresentativeNameKanaError: string
  phoneNumberError: string
  zipError: string
  prefectureOptions?: SelectOption[]
  prefectureId?: string
  setPrefecture: (next: string | undefined) => void
  addressError: string
  prefectureError: boolean
  getLocationByZipCode: (next: string) => void
  setApplicantInformationData: (next: ApplicantInformationData) => void
  applicantInformationData: ApplicantInformationData
  buildingNameError: string
}

export const ApplicantInfoUpdate = ({
  errorMessage,
  representativeNameError,
  companyRepresentativeNameKanaError,
  phoneNumberError,
  zipError,
  prefectureOptions,
  prefectureId,
  setPrefecture,
  addressError,
  prefectureError,
  getLocationByZipCode,
  applicantInformationData,
  setApplicantInformationData,
  buildingNameError,
}: Props) => {
  return (
    <View style={[styles.container]}>
      {applicantInformationData.appCategory === "法人" && (
        <>
          <View style={styles.row}>
            <ItemLabel label="企業名" />
            <Text style={[styles.textField]}>{applicantInformationData.companyName}</Text>
          </View>
          <View style={styles.row}>
            <ItemLabel
              label="法人代表者名"
              required
              RightComponent={
                <InputLengthCounter text={applicantInformationData.representativeName} maxLength={30} unit={"字以内"} />
              }
            />
            <CustomTextInput
              value={
                applicantInformationData.representativeName === undefined ? "" : applicantInformationData.representativeName
              }
              onChangeText={(e) => {
                setApplicantInformationData({ ...applicantInformationData, representativeName: e })
              }}
              style={styles.input}
              maxLength={30}
              isError={representativeNameError !== ""}
            />
            {representativeNameError !== "" && (
              <ValidationErrorMessage style={styles.validationErrorMessage} message={representativeNameError} />
            )}
          </View>
          <View style={styles.row}>
            <ItemLabel
              label="法人代表者名（フリガナ）"
              required
              RightComponent={
                <InputLengthCounter
                  text={applicantInformationData.companyRepresentativeNameKana}
                  maxLength={50}
                  unit={"字以内"}
                />
              }
            />
            <CustomTextInput
              value={
                applicantInformationData.companyRepresentativeNameKana === undefined
                  ? ""
                  : applicantInformationData.companyRepresentativeNameKana
              }
              onChangeText={(e) => {
                setApplicantInformationData({ ...applicantInformationData, companyRepresentativeNameKana: e })
              }}
              style={styles.input}
              maxLength={50}
              isError={companyRepresentativeNameKanaError !== ""}
            />
            {companyRepresentativeNameKanaError !== "" && (
              <ValidationErrorMessage style={styles.validationErrorMessage} message={companyRepresentativeNameKanaError} />
            )}
          </View>
        </>
      )}

      <View style={styles.row}>
        <ItemLabel
          label="電話番号"
          required
          RightComponent={<InputLengthCounter text={applicantInformationData.phoneNumber} maxLength={11} unit={"字以内"} />}
        />
        <CustomTextInput
          value={applicantInformationData.phoneNumber === undefined ? "" : applicantInformationData.phoneNumber}
          onChangeText={(e) => {
            setApplicantInformationData({ ...applicantInformationData, phoneNumber: e })
          }}
          style={styles.input}
          maxLength={11}
          keyboardType="number-pad"
          placeholder="09012345678"
          isError={phoneNumberError !== ""}
        />
        {phoneNumberError !== "" && <ValidationErrorMessage style={styles.validationErrorMessage} message={phoneNumberError} />}
      </View>

      <View style={styles.row}>
        <ItemLabel
          label="郵便番号"
          required
          RightComponent={<InputLengthCounter text={applicantInformationData.zip} maxLength={7} unit={"字以内"} />}
        />
        <CustomTextInput
          value={applicantInformationData.zip === undefined ? "" : applicantInformationData.zip}
          onChangeText={(e) => {
            setApplicantInformationData({ ...applicantInformationData, zip: e })
          }}
          style={styles.input}
          maxLength={7}
          keyboardType="number-pad"
          placeholder="例：123456"
          isError={zipError !== ""}
        />
        {zipError !== "" && <ValidationErrorMessage style={styles.validationErrorMessage} message={zipError} />}
        <Button
          style={[styles.grayButton]}
          onPress={() => {
            if (applicantInformationData.zip) getLocationByZipCode(applicantInformationData.zip)
          }}
        >
          <Text style={[styles.textGrayButton]}>郵便番号から住所を検索</Text>
        </Button>
      </View>

      <View style={[styles.row]}>
        <ItemLabel label="都道府県" required />
        <View style={[{ marginTop: 8 }]}></View>
        {prefectureOptions != null ? (
          <CustomSelect
            options={prefectureOptions}
            value={prefectureId}
            onChange={(e) => {
              setPrefecture(e)
              setApplicantInformationData({
                ...applicantInformationData,
                prefecture: prefectureOptions?.find((pre) => pre.value == e)?.label ?? "",
                address: "",
              })
            }}
            isError={prefectureError}
          />
        ) : null}
        {prefectureError && <ValidationErrorMessage style={styles.validationErrorMessage} message={errorMessage} />}
      </View>

      <View style={styles.row}>
        <ItemLabel
          label="住所"
          required
          RightComponent={<InputLengthCounter text={applicantInformationData.address} maxLength={50} unit={"字以内"} />}
        />
        <CustomTextInput
          value={applicantInformationData.address === undefined ? "" : applicantInformationData.address}
          onChangeText={(e) => {
            setApplicantInformationData({ ...applicantInformationData, address: e })
          }}
          style={styles.input}
          maxLength={50}
          isError={addressError !== ""}
        />
        {addressError !== "" && <ValidationErrorMessage style={styles.validationErrorMessage} message={addressError} />}
      </View>
      <View style={[styles.row, { marginBottom: 0 }]}>
        <ItemLabel
          label="ビル・建物名"
          RightComponent={<InputLengthCounter text={applicantInformationData.buildingName} maxLength={50} unit={"字以内"} />}
        />
        <CustomTextInput
          value={applicantInformationData.buildingName}
          onChangeText={(e) => setApplicantInformationData({ ...applicantInformationData, buildingName: e })}
          style={styles.input}
          maxLength={50}
          isError={buildingNameError !== ""}
        />
        {buildingNameError !== "" && (
          <ValidationErrorMessage style={styles.validationErrorMessage} message={buildingNameError} />
        )}
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    width: "100%",
    maxWidth: 600,
  },
  row: {
    marginBottom: 16,
    width: "100%",
  },
  requiredItemNoteText: {
    fontWeight: "400",
    fontSize: 14,
  },
  requiredItemNoteAsterisk: {
    color: Colors.red,
  },
  itemLabelWrapper: {
    marginBottom: 4,
  },
  textField: {
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 22.4,
    marginVertical: 14,
    textAlign: "left",
    width: "100%",
  },
  radioContainer: {
    flexDirection: "row",
    marginTop: 4,
    paddingVertical: 14,
  },
  radioItem: {
    flexDirection: "row",
    alignItems: "center",
    marginRight: 16,
  },
  text: {
    width: "100%",
    fontSize: 14,
    lineHeight: 20,
    fontWeight: "300",
  },
  validationErrorMessage: {
    marginTop: 5,
  },
  input: {
    flex: 1,
    marginTop: 8,
    paddingHorizontal: 12,
    paddingVertical: 14,
  },
  grayButton: {
    backgroundColor: "rgba(159, 134, 107, 0.1)",
    paddingHorizontal: 24,
    paddingVertical: 12,
    borderRadius: 8,
    alignItems: "center",
    marginTop: 10,
    alignSelf: "flex-start",
  },
  textGrayButton: {
    fontSize: 13,
    lineHeight: 18.2,
    fontWeight: "600",
  },
})
