import { selectorFamily } from "recoil"
import { migrationContactsRequestIdState } from "src/recoil/atoms/contact/migrationContactsRequestIdState"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { getMigrationContacts } from "src/apis/contact/getMigrationContacts"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { ContactFilterValue } from "src/types/contact/ContactFilter"
import { CustomAlert } from "src/utils/CustomAlert"

export const migrationContactsSelectorFamily = selectorFamily<
  { contacts: ContactOverviewModel[]; nextToken?: string } | undefined,
  { searchText?: string; filters?: ContactFilterValue[]; nextToken?: string }
>({
  key: "selectors/contact/migrationContactsSelectorFamily",
  get:
    ({ searchText, filters, nextToken }) =>
    async ({ get }) => {
      get(migrationContactsRequestIdState)
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) {
        return
      }
      const selectedMyOrganization = get(selectedMyOrganizationSelector)
      if (selectedMyOrganization == null) {
        return
      }
      const myProfile = get(myProfileSelectorFamily(selectedMyOrganization.id))
      if (myProfile == null) {
        return
      }
      const myMemberId = myProfile.memberId

      const result = await getMigrationContacts({ accessToken, myMemberId, searchText, filters, nextToken })
      if (!result.isOk) {
        CustomAlert.alert("エラー", result.error.message)
        return
      }
      return result.content
    },
})
