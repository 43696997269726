import { memo } from "react"
import { messagesItem } from "src/types/matching/matchingOfferModel"
import { MatchingMessageCard } from "./MatchingMessageCard"
import { Text, View } from "react-native"
import { styles } from "./styles"
import { useNavigation } from "@react-navigation/native"
import { Screens } from "src/constants/Screens"
import { ItemLabel } from "../ItemLabel"

type ListProps = {
  applications: { id: string; messages: { items: messagesItem[] } }[]
  myOrganizationId?: string
  offerId?: string
  title?: string
  to?: string
  isMyMatching?: boolean
  isFavorite?: boolean
  isApplications?: boolean
  matchingTeamId?: string
  matchingTeamName?: string
}

export const ListMatchingMessages = memo<ListProps>(
  ({
    applications,
    myOrganizationId,
    offerId,
    title,
    to,
    isMyMatching,
    isFavorite,
    isApplications,
    matchingTeamId,
    matchingTeamName,
  }) => {
    const navigation = useNavigation()
    const onClickCard = (applicationId: string) => {
      navigation.navigate(Screens.MatchingThread, {
        applicationId,
        offerId,
        myOrganizationId,
        to,
        isMyMatching,
        isFavorite,
        isApplications,
        matchingTeamId,
      })
    }

    return (
      <View style={styles.listMessageContainer}>
        {title ? (
          <View style={{ marginVertical: 8 }}>
            <ItemLabel label={title} />
          </View>
        ) : (
          <Text style={styles.header}>最新のメッセージ</Text>
        )}

        <View style={styles.messageContentCard}>
          {applications.map((item, index) => (
            <View key={index}>
              {item.messages.items.length !== 0 ? (
                <>
                  <MatchingMessageCard
                    applicationFlg={isApplications}
                    key={index}
                    message={item.messages.items[0]}
                    onClick={() => onClickCard(item.id)}
                    applicationId={item.id}
                    matchingTeamId={matchingTeamId}
                    matchingTeamName={matchingTeamName}
                  />
                </>
              ) : (
                <MatchingMessageCard
                  key={index}
                  onClick={() => onClickCard(item.id)}
                  applicationFlg={isApplications}
                  applicationId={item.id}
                  matchingTeamId={matchingTeamId}
                  matchingTeamName={matchingTeamName}
                />
              )}
            </View>
          ))}
        </View>
      </View>
    )
  }
)
