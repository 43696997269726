import { atom, selector } from "recoil"
import { Gender } from "src/constants/Gender"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"

export const genderState = atom({
  key: "atoms/account/genderState",
  default: selector({
    key: "atoms/account/genderState_default",
    get: ({ get }) => {
      const account = get(accountSelector)
      return account?.gender ?? Gender.NoAnswer
    },
  }),
})
