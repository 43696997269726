import { memo, useMemo } from "react"
import { StyleProp, StyleSheet, Text, View, ViewStyle } from "react-native"
import { Colors } from "src/constants/Colors"

type Props = {
  size: "small" | "medium" | "large"
  number?: number
  position?: "rightTop"
  style?: StyleProp<ViewStyle>
}

const size = {
  small: 8,
  medium: 12,
  large: 16,
}

export const BadgeIcon = memo<Props>(({ size: _size, number: number_, position, style }) => {
  // 表示の最大値は5000
  const number = number_ != null ? Math.min(number_, 5000) : undefined
  const styles = useMemo(() => getStyles(size[_size], number), [_size, number])
  const positionStyle = position === "rightTop" ? styles.positionRightTop : undefined

  return (
    <View style={[styles.badge, positionStyle, style]}>{number ? <Text style={styles.badgeNumber}>{number}</Text> : null}</View>
  )
})

const getStyles = (size: number, number?: number) =>
  StyleSheet.create(
    number != null
      ? {
          badge: {
            minWidth: 16,
            height: 16,
            paddingHorizontal: 2,
            borderRadius: 8,
            backgroundColor: Colors.brightSkyBlue,
            justifyContent: "center",
            alignItems: "center",
          },
          positionRightTop: {
            position: "absolute",
            top: -3,
            right: -(3 + Math.floor(String(number).length / 4) * 3),
          },
          badgeNumber: {
            color: Colors.white,
            fontSize: 10,
          },
        }
      : {
          badge: {
            width: size,
            height: size,
            borderRadius: size / 2,
            backgroundColor: Colors.brightSkyBlue,
            justifyContent: "center",
            alignItems: "center",
          },
          positionRightTop: {
            position: "absolute",
            top: 0,
            right: 0,
          },
          badgeNumber: {},
        }
  )
