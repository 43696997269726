import { DrawerContentComponentProps, DrawerContentScrollView, createDrawerNavigator } from "@react-navigation/drawer"
import { ComponentProps, useMemo } from "react"
import { View } from "react-native"
import { Button } from "src/components/parts/buttons/Button"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { ListIcon } from "src/components/parts/icons/ListIcon"
import { Colors } from "src/constants/Colors"
import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { MyMatchingOffer } from "src/screens/matching/MyMatchingOffer"
import { OtherMatchingOffer } from "src/screens/matching/OtherMatchingOffer"
import { MatchingDrawerParamList } from "src/types.d"
import { MatchingSideMenu } from "./MatchingSideMenu"
import { MatchingOfferDetail } from "src/screens/matching/OfferDetail"
import { MyMatchingProfile } from "src/screens/matching/MyMatchingProfile"
import { FavoriteMatchingOffer } from "src/screens/matching/FavoriteMatchingOffer"
import { MyMatchingDraftOffer } from "src/screens/matching/MyMatchingDraftOffer"
import { Applications } from "src/screens/matching/Applications"
import { BadgeIcon } from "src/components/parts/icons/BadgeIcon"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { hasMatchingNoticeSelector } from "src/recoil/selectors/matching/hasMatchingNoticeSelector"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { unreadMatchingMessageRequestIdState } from "src/recoil/atoms/matching/unreadMatchingMessageRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"

const MatchingDrawerNav = createDrawerNavigator<MatchingDrawerParamList>()

const CustomDrawerContent = (props: DrawerContentComponentProps) => {
  return (
    <DrawerContentScrollView {...props}>
      <MatchingSideMenu {...props} />
    </DrawerContentScrollView>
  )
}

const options: (
  isPCScreen: boolean,
  hasBadge: boolean,
  loadBadge: () => void
) => ComponentProps<typeof MatchingDrawerNav.Navigator>["screenOptions"] =
  (isPCScreen: boolean, hasBadge: boolean, loadBadge: () => void) =>
  ({ navigation }) => ({
    headerStyle: {
      backgroundColor: isPCScreen ? Colors.white3 : Colors.orange,
    },
    headerTitleStyle: {
      color: isPCScreen ? Colors.black : Colors.white,
    },
    headerTitleAlign: "center",
    headerLeft: () => (
      <>
        {!isPCScreen && (
          <Button onPress={() => navigation.goBack()}>
            <BackIcon fill={isPCScreen ? Colors.orange : Colors.white3} />
          </Button>
        )}
      </>
    ),
    headerRight: () => (
      <>
        {!isPCScreen && (
          <View style={{ width: 20, height: 20, marginRight: 8 }}>
            <Button
              onPress={() => {
                loadBadge()
                navigation.openDrawer()
              }}
              style={{ position: "relative" }}
            >
              {hasBadge && <BadgeIcon size={"small"} style={{ position: "absolute", top: -8, right: -4 }} />}
              <ListIcon size={18} fill={isPCScreen ? Colors.orange : Colors.white3} />
            </Button>
          </View>
        )}
      </>
    ),
    // drawerPosition: "right",
    drawerActiveTintColor: Colors.greyshBrown,
    drawerInactiveTintColor: Colors.cement,
    drawerActiveBackgroundColor: Colors.lightTan,
    drawerInactiveBackgroundColor: Colors.reddishGrey,
  })

export const MatchingDrawer = () => {
  const isPCScreen = useCheckPCScreen()
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const { value: hasBadge } = useAsyncSelector(hasMatchingNoticeSelector(organizationId ?? ""))
  const refreshMessageCountMatching = useRefresher(unreadMatchingMessageRequestIdState)

  return (
    <MatchingDrawerNav.Navigator
      initialRouteName={MatchingDrawers.OtherMatchingOffer}
      screenOptions={options(isPCScreen, hasBadge || false, refreshMessageCountMatching)}
      drawerContent={CustomDrawerContent}
    >
      <MatchingDrawerNav.Screen
        name={MatchingDrawers.OtherMatchingOffer}
        component={OtherMatchingOffer}
        options={{ title: "団体マッチング募集情報", headerShown: !isPCScreen }}
      />
      <MatchingDrawerNav.Screen
        name={MatchingDrawers.MyMatchingOffer}
        component={MyMatchingOffer}
        options={{ title: "マッチング管理", headerShown: !isPCScreen }}
      />
      <MatchingDrawerNav.Screen
        name={MatchingDrawers.MatchingOfferDetail}
        component={MatchingOfferDetail}
        options={{ title: "団体マッチング募集詳細", headerShown: !isPCScreen }}
      />
      <MatchingDrawerNav.Screen
        name={MatchingDrawers.MyMatchingProfile}
        component={MyMatchingProfile}
        options={{ title: "マッチング管理", headerShown: !isPCScreen }}
      />
      <MatchingDrawerNav.Screen
        name={MatchingDrawers.FavoriteMatchingOffer}
        component={FavoriteMatchingOffer}
        options={{ title: "マッチング管理", headerShown: !isPCScreen }}
      />
      <MatchingDrawerNav.Screen
        name={MatchingDrawers.MyMatchingDraftOffer}
        component={MyMatchingDraftOffer}
        options={{ title: "マッチング管理", headerShown: !isPCScreen }}
      />
      <MatchingDrawerNav.Screen
        name={MatchingDrawers.Applications}
        component={Applications}
        options={{ title: "応募一覧", headerShown: !isPCScreen }}
      />
    </MatchingDrawerNav.Navigator>
  )
}
