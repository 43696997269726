import { Result } from "src/utils/Result"
import { updateThreadMessage } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type UpdateContactReplyThreadMessageParams = {
  accessToken: string
  replyThreadMessageId: string
  message: string
}

type UpdateContactReplyThreadMessageSuccess = unknown

type UpdateContactReplyThreadMessageFailure = {
  message: string
}

export const updateContactReplyThreadMessage = async (params: UpdateContactReplyThreadMessageParams): Promise<Result<UpdateContactReplyThreadMessageSuccess, UpdateContactReplyThreadMessageFailure>> => {
  const {accessToken, replyThreadMessageId, message} = params

  return execApi(
    () => updateThreadMessage(accessToken, {
      input: {
        id: replyThreadMessageId,
        text: message,
      },
      appInfo
    }),
    _ => undefined,
    {
      identifiers: ["updateContactReplyThreadMessage", "updateThreadMessage"],
      defaultErrorMessage: "メッセージの編集に失敗しました。"
    }
  )
}
