import { useCallback } from "react"
import * as WebBrowser from "expo-web-browser"
import { privacypolicyURL } from "src/constants/links/privacypolicy"
import { ruleURL } from "src/constants/links/rule"
import { CustomAlert } from "src/utils/CustomAlert"
import { useSignout } from "src/recoil/hooks/authorization/useSignout"
import { useBottomTabBadgeData } from "src/recoil/hooks/bottomTab/useBottomTabBadgeData"
import { guideURL } from "src/constants/links/guide"
import { paidVersionURL } from "src/constants/links/paidVersion"
export const useSettingsTabData = () => {
  const { hasServiceInformation: isInformationsUnread, refreshHasServiceInformation: refresh } = useBottomTabBadgeData()
  const openUserGuide = useCallback(async () => {
    await WebBrowser.openBrowserAsync(guideURL)
  }, [])
  const openTerms = useCallback(async () => {
    await WebBrowser.openBrowserAsync(ruleURL)
  }, [])
  const openPaidVersion = useCallback(async () => {
    await WebBrowser.openBrowserAsync(paidVersionURL)
  }, [])
  const openPrivacyPolicy = useCallback(async () => {
    await WebBrowser.openBrowserAsync(privacypolicyURL)
  }, [])
  const { signout, isProcessingSignout } = useSignout()
  const logout = useCallback(async () => {
    const isConfirmed = await CustomAlert.confirm("確認", "本当にログアウトしますか？")
    if (isConfirmed) {
      signout()
    }
  }, [signout])

  return {
    isInformationsUnread,
    refresh,
    openUserGuide,
    openTerms,
    openPrivacyPolicy,
    isProcessingSignout,
    logout,
    openPaidVersion,
  }
}
