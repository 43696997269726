import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { Result } from "src/utils/Result"
import { ContactFilterValue } from "src/types/contact/ContactFilter"
import { listReceivedMail, ListReceivedMailVariables } from "src/aws/customAPI"
import { removeUndefined } from "src/utils/removeUndefined"
import { ContactType } from "src/constants/ContactType"
import { MailType } from "src/aws/API"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

export type GetInboxContactsParams = {
  accessToken: string
  myMemberId: string
  organizationId: string
  searchText?: string
  filters?: ContactFilterValue[]
  count?: number
  nextToken?: string
  imgUrl?: string
}

export type GetInboxContactsSuccess = {
  contacts: ContactOverviewModel[]
  nextToken?: string
}

type GetInboxContactsFailure = {
  message: string
}

export const getInboxContacts = async (params: GetInboxContactsParams): Promise<Result<GetInboxContactsSuccess, GetInboxContactsFailure>> => {
  return execApi(
    () => listReceivedMail(params.accessToken, {
      teamMemberId: params.myMemberId,
      filter: getFilter(params),
      count: params.count,
      nextToken: params.nextToken,
      appInfo
    }),
    res => {
      return {
        contacts: res.searchTeamMemberMails.items.map(m => ({
          id: m.mail.id,
          contactType: mailTypeMapping[m.mail.mailType],
          isRead: m.readFlg,
          isAnswered: m.answerRequiredFlg ? m.answeredFlg ?? false : undefined,
          title: m.mail.title,
          senderId: m.mail.sender.id,
          senderName: m.mail.sender.nickname,
          isSenderDeleted: m.mail.sender.deleteFlg || false,
          isFavorite: m.favoriteFlgReceived,
          isAttachmentExists: (m.mail.attachmentFileNames || []).length > 0,
          sentDate: new Date(m.mail.sentAt),
          isUpdate: m.mail.updatedAt > m.mail.createdAt,
          updatedDate: m.mail.updatedAt ? new Date(m.mail.updatedAt) : undefined,
          deadlineDate: m.mail.responseDueDatetime ? new Date(m.mail.responseDueDatetime) : undefined,
          teamMemberContactId: m.id,
        })),
        nextToken: res.searchTeamMemberMails.nextToken
      }
    },
    {
      identifiers: ["getInboxContacts", "listReceivedMail"]
    }
  )
}

const getFilter = (param: { searchText?: string, filters?: ContactFilterValue[] }): ListReceivedMailVariables["filter"] => {
  const mailTypes: MailType[] = param.filters?.filter(f => contactTypeFilterValues.has(f)).map(toMailType) || []
  return removeUndefined({
    keyword : param.searchText,
    unanswered : param.filters?.includes("unanswered") || undefined,
    favorite: param.filters?.includes("favorite") || undefined,
    hasThread: param.filters?.includes("withReply") || undefined,
    hasAttachment: param.filters?.includes("withAttachment") || undefined,
    isUnread: param.filters?.includes("unread") || undefined,
    mailTypes: mailTypes.length > 0 ? mailTypes : undefined
  })
}

export const mailTypeMapping: {[key in MailType]: ContactType} = {
  [MailType.normal]: ContactType.Normal,
  [MailType.attendance]: ContactType.Attendance,
  [MailType.questionnaire]: ContactType.Survey,
  [MailType.schedule]: ContactType.ScheduleAdjustment,
}

const contactTypeFilterValues = new Set<ContactFilterValue>([
  ContactFilterValue.NormalContact,
  ContactFilterValue.AttendanceContact,
  ContactFilterValue.SurveyContact,
  ContactFilterValue.ScheduleAdjustmentContact
])

const contactTypeFilterValueMapping: Partial<{[key in ContactFilterValue]: MailType}> = {
  [ContactFilterValue.NormalContact]: MailType.normal,
  [ContactFilterValue.AttendanceContact]: MailType.attendance,
  [ContactFilterValue.SurveyContact]: MailType.questionnaire,
  [ContactFilterValue.ScheduleAdjustmentContact]: MailType.schedule,
}

const toMailType = (filterValue: ContactFilterValue): MailType => {
  return contactTypeFilterValueMapping[filterValue] || MailType.normal
}
