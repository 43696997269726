import { useCallback, useEffect, useMemo, useState } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { trashboxContactsRequestIdState } from "src/recoil/atoms/contact/trashboxContactsRequestIdState"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { useContactDetailDataRefresher } from "src/recoil/hooks/refresher/useContactDetailDataRefresher"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { useFetcher } from "src/hooks/useFetcher"
import { CustomAlert } from "src/utils/CustomAlert"
import { deleteContactTrashbox } from "src/apis/contact/deleteContactTrashbox"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { restoreContact } from "src/apis/contact/restoreContact"
import { updateFavoriteContactInbox } from "src/apis/contact/updateFavoriteContactInbox"
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import { trashContactFavoriteFlgsCacheState } from "src/recoil/atoms/contact/contactFavoriteFlgsCacheState"
import { wait } from "src/utils/wait"
import { trashboxContactsConcatSelectorFamily } from "src/recoil/selectors/contact/trashboxContactsConcatSelectorFamily"
import { trashboxContactsConcatRequestIdState } from "src/recoil/atoms/contact/trashboxContactsConcatRequestIdState"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { organizationRefreshState } from "src/recoil/atoms/organization/organizationRefreshPermissionState"
import { organizationDetailRequestIdState } from "src/recoil/atoms/organization/organizationDetailRequestIdState"

export const useTrashboxData = () => {
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const { value: organization } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))
  const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(organizationId))
  const myMemberId = useMemo(() => myProfile?.memberId, [myProfile])
  const refreshContactDetailData = useContactDetailDataRefresher()
  const [nextTokens, setNextTokens] = useState<string[]>([])
  useEffect(() => {
    setNextTokens([])
  }, [organizationId, setNextTokens])
  const { value: contacts, isLoading: isLoadingContacts } = useAsyncSelector(
    trashboxContactsConcatSelectorFamily({ nextTokens })
  )
  const _refreshContacts = useRefresher(trashboxContactsRequestIdState)
  const _refreshContactsConcat = useRefresher(trashboxContactsConcatRequestIdState)
  const refreshOrganizationDetail = useRefresher(organizationDetailRequestIdState)
  const needRefreshOrganization = useRecoilValue(organizationRefreshState)
  useEffect(() => {
    if (needRefreshOrganization) {
      refreshOrganizationDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefreshOrganization])
  const refreshContacts = useCallback(() => {
    _refreshContacts()
    _refreshContactsConcat()
    setNextTokens([])
    refreshOrganizationDetail()
  }, [_refreshContacts, _refreshContactsConcat, refreshOrganizationDetail])
  const [favoriteFlgsCache, setFavoriteFlgsCache] = useRecoilState(trashContactFavoriteFlgsCacheState)
  const resetFavoriteFlgsCache = useResetRecoilState(trashContactFavoriteFlgsCacheState)

  const requestDataResult = useRequestData()

  const { fetch: execDeleteContact } = useFetcher(
    useCallback(
      async (contactId: string) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accessToken } = requestDataResult.content
        if (accessToken == null || myMemberId == null) {
          return
        }
        const isConfirmed = await CustomAlert.confirm("確認", "この連絡を完全に削除しますか？\n削除後は復元できません。")
        if (!isConfirmed) {
          return
        }
        const result = await deleteContactTrashbox({ accessToken, myMemberId, contactId })
        if (result.isOk) {
          await CustomAlert.alert("完了", "連絡を完全に削除しました。")
          await wait(2000)
          refreshContacts()
          resetFavoriteFlgsCache()
        } else {
          await CustomAlert.alert("完了", result.error.message)
        }
      },
      [requestDataResult, refreshContacts, myMemberId, resetFavoriteFlgsCache]
    )
  )

  const { fetch: execRestoreContact } = useFetcher(
    useCallback(
      async (contactId: string) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accessToken } = requestDataResult.content
        if (accessToken == null || myMemberId == null) {
          return
        }
        const isConfirmed = await CustomAlert.confirm("確認", "この連絡を復元しますか?")
        if (!isConfirmed) {
          return
        }
        const result = await restoreContact({ accessToken, myMemberId, contactId })
        if (result.isOk) {
          await CustomAlert.alert("完了", "連絡を受信トレイに移動しました。")
          await wait(2000)
          refreshContacts()
          resetFavoriteFlgsCache()
        } else {
          await CustomAlert.alert("エラー", result.error.message)
        }
      },
      [requestDataResult, refreshContacts, myMemberId, resetFavoriteFlgsCache]
    )
  )

  const { fetch: execUpdateFavoriteContact } = useFetcher(
    useCallback(
      async ({ contactId, isFavorite }: { contactId: string; isFavorite: boolean }) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accountId, accessToken } = requestDataResult.content
        if (accountId == null || accessToken == null || myMemberId == null) {
          return
        }
        const result = await updateFavoriteContactInbox({ accessToken, myMemberId, contactId, isFavorite })
        if (!result.isOk) {
          CustomAlert.alert("エラー", result.error.message)
          return
        }
        setFavoriteFlgsCache((prev) => ({ ...prev, [contactId]: isFavorite }))
      },
      [requestDataResult, myMemberId, setFavoriteFlgsCache]
    )
  )

  return {
    contacts,
    isLoadingContacts,
    refreshContacts,
    organizationId,
    refreshContactDetailData,
    execDeleteContact,
    execRestoreContact,
    execUpdateFavoriteContact,
    favoriteFlgsCache,
    setNextTokens,
    organization,
  }
}
