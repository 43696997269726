import { selector } from "recoil"
import { migrationAccountState } from "../../atoms/migration/migrationAccountState"
import { oldRa9Ra9TokenStates } from "../../atoms/migration/oldRa9Ra9TokenStates"
import { oldAccountInfo, UserInfo } from "../../../apis/migration/getAccountInfoOld"

export const migrationAccountSelector = selector<UserInfo>({
  key: "selectors/migration/migrationAccountSelector",
  get: async ({ get }) => {
    get(migrationAccountState)
    const oldRa9Ra9AccessTokens = get(oldRa9Ra9TokenStates)
    const oldRa9Ra9AccessToken = oldRa9Ra9AccessTokens?.oldRa9Ra9AccessToken
    const oldRa9Ra9UserId = Number(oldRa9Ra9AccessTokens?.oldRa9Ra9UserId)

    if (oldRa9Ra9AccessToken == null || oldRa9Ra9UserId == null) return <UserInfo>{}

    return await oldAccountInfo({
      accessToken: oldRa9Ra9AccessToken,
      userId: oldRa9Ra9UserId,
    })
  },
})
