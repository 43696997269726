import { ValueOf } from "src/types.d"
import { SchoolGrade } from "src/constants/SchoolGradeOptions"

export const MemberType = Object.freeze({
  Representative: "Representative",
  General: "General",
  GeneralWithContactAuthorization: "GeneralWithContactAuthorization",
} as const)

export type MemberType = ValueOf<typeof MemberType>

export type OrganizationMemberOverview = {
  id: string
  nickname: string
  imageUrl?: string
  memberNumber: number
  memberType: MemberType
  groupName?: string
  isLeader: boolean
  isInGroup: boolean
  isAdministrator: boolean
  groupIds: string[]
  email?: string
  joinFlg?: boolean
  memberSeq?: number
}

export type addMemberSelectorOverview = {
  id: string
  nickname: string
  imageUrl?: string
  memberNumber: number
  memberType: MemberType
  groupName?: string
  isLeader: boolean
  isInGroup: boolean
  isAdministrator: boolean
  groupIds: string[]
  email?: string
  nicknameKana: string
}

export const OrganizationMemberDetailOthersType = Object.freeze({
  TextInput: "TextInput",
  Select: "Select",
} as const)

export type OrganizationMemberDetailOthersType = ValueOf<typeof OrganizationMemberDetailOthersType>

export type OrganizationMemberDetail = {
  memberType: MemberType
  nicknameKana: string
  editableMemberId: string
  email: string
  isShowEmail: boolean
  phoneNumber: string
  isShowPhoneNumber: boolean
  affiliation: string
  isShowAffiliation: boolean
  schoolGrade?: SchoolGrade
} & Omit<OrganizationMemberOverview, "isLeader" | "isInGroup" | "groupIds">
