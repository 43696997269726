import { useNavigation } from "@react-navigation/native"
import { useCallback, useEffect, useMemo, useState } from "react"
import { createFavoriteMatchingOffer } from "src/apis/matching/createFavoriteMatchingOffer"
import { createMatchingApplication } from "src/apis/matching/createMatchingApplication"
import { deleteFavoriteMatchingOffer } from "src/apis/matching/deleteFavoriteMatchingOffer"
import { Screens } from "src/constants/Screens"
import { useAsyncState } from "src/hooks/useAsyncState"
import { myOffersState } from "src/recoil/atoms/matching/myOfferState"
import { matchingOtherProfileSelectorFamily } from "src/recoil/selectors/matching/matchingOtherProfileSelectorFamily"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { MatchingOffersModel } from "src/types/matching/matchingOfferModel"
import { wait } from "src/utils/wait"
import { deleteMatchingOffer } from "../../../apis/matching/deleteMatchingOffer"
import { useAsyncSelector } from "../../../hooks/useAsyncSelector"
import { useFetcher } from "../../../hooks/useFetcher"
import { useRefresher } from "../../../hooks/useRefresher"
import { CustomAlert } from "../../../utils/CustomAlert"
import { listMatchingApplicationRequestIdState } from "../../atoms/matching/listMatchingApplicationRequestIdState"
import { matchingOfferDetailRequestIdState } from "../../atoms/matching/matchingOfferDetailRequestIdState"
import { accountSelector } from "../../selectors/account/accountSelector"
import { listMatchingApplicationSelectorFamily } from "../../selectors/matching/listMatchingApplicationSelectorFamily"
import { matchingOfferDetailSelectorFamily } from "../../selectors/matching/matchingOfferDetailSelectorFamily"
import { useAuthorizationData } from "../authorization/useAuthorizationData"
import { useResource } from "../resource/useResource"
import { useRequestData } from "../useRequestData"
import { useMyMatchingOffers } from "./useMyMatchingOffers"
import { useRecoilState, useSetRecoilState } from "recoil"
import { acceptMatchingTermState } from "src/recoil/atoms/matching/acceptMatchingTermState"
import { MatchingDrawers } from "src/constants/MatchingDrawers"
import { RootTabs } from "src/constants/RootTabs"
import { listOfferFavoriteFlgsCacheState } from "src/recoil/atoms/contact/contactFavoriteFlgsCacheState"
import { createdAtState } from "src/recoil/atoms/matching/createdAtState"
export const useMatchingOfferDetails = (offerId: string | undefined) => {
  const { value, isLoading } = useAsyncSelector(matchingOfferDetailSelectorFamily({ offerId, type: "otherOffer" }))
  const [createdAt, setCreated] = useAsyncState<Date | string>(createdAtState)
  const _refresh = useRefresher(matchingOfferDetailRequestIdState)
  const { value: selectedTeam } = useAsyncSelector(selectedMyOrganizationSelector)
  const teamName = useMemo(() => selectedTeam?.title, [selectedTeam])
  const [myOffers, setMyOffers] = useAsyncState<MatchingOffersModel[]>(myOffersState)
  const teamId = useMemo(() => selectedTeam?.id, [selectedTeam])
  const setAgreeMatchingTerm = useSetRecoilState(acceptMatchingTermState)
  const otherTeamId = useMemo(() => value?.organizationId, [value])
  const [message, setMessage] = useState("")
  const [isBottomSheet, setBottomSheet] = useState(false)
  const [isDeleteOfferSuccess, setIsDeleteOfferSuccess] = useState(false)
  const navigation = useNavigation()
  const [favFlagCache, setFavFlagCache] = useRecoilState<{ [offerId: string]: boolean }>(listOfferFavoriteFlgsCacheState)
  const { refreshList } = useMyMatchingOffers()

  const areRequiredFieldsFilled = useMemo(() => !!message.trim(), [message])
  const isSubmitDisabled = useMemo(() => !areRequiredFieldsFilled, [areRequiredFieldsFilled])
  const [messageCount, setMessageCount] = useState(message.length || 0)
  const [favFlag, setFavFlag] = useState(false)

  const [submissionConfirmationModalOpen, setSubmissionConfirmationModalOpen] = useState(false)
  const onClickSubmissionConfirmationModal = () => setSubmissionConfirmationModalOpen(true)
  const onCloseSubmissionConfirmationModal = () => {
    setSubmissionConfirmationModalOpen(false)
  }

  const { accessToken } = useAuthorizationData()
  const refreshListMatchingApplication = useRefresher(listMatchingApplicationRequestIdState)
  const refreshOfferDetails = useRefresher(matchingOfferDetailRequestIdState)

  const requiredApplication = useMemo<{ message: string } | undefined>(() => {
    if (!message) return
    return { message }
  }, [message])
  useEffect(() => {
    if (value && value.createdAt) setCreated(value?.createdAt)
  }, [setCreated, value])
  useEffect(() => {
    if (favFlagCache && offerId && favFlagCache[offerId] !== undefined) {
      setFavFlag(favFlagCache[offerId])
    } else {
      setFavFlag(value?.favoriteFlg ?? false)
    }
  }, [value?.favoriteFlg, offerId, favFlagCache])

  const { fetch: application, isFetching: isSavingAsDraft } = useFetcher(async (params: { message: string }) => {
    if (accessToken == null || offerId == null || teamId == null) return
    const [applicationResult] = await Promise.all([
      createMatchingApplication({
        accessToken,
        applicantTeamId: teamId,
        matchingOfferId: offerId,
        firstMessage: params.message,
      }),
    ])
    if (!applicationResult.isOk) {
      if (applicationResult.error.errorCode == 400) {
        await CustomAlert.alert("エラー", "募集期限が過ぎているため、応募できません。")
      } else {
        await CustomAlert.alert("エラー", applicationResult.error.message)
      }
      return
    }
    await wait(2000)
    await refreshOfferDetails()
    await refreshListMatchingApplication()
    if (!appliedFlg) {
      await refreshListMatchingApplication()
    }
    await CustomAlert.alert("完了", "応募が完了しました。")
    setMessage("")
  })

  const [isDeletedSuc, setIsDeletedSuc] = useState(false)
  const { fetch: execDeleteMatchingOffer, isFetching: isDeleteMatchingOffer } = useFetcher(
    useCallback(
      async (callback: () => void) => {
        if (accessToken == null || offerId == null) return
        setBottomSheet(false)
        const result = await deleteMatchingOffer({
          accessToken,
          offerId,
        })
        if (!result.isOk) {
          await CustomAlert.alert("エラー", result.error.message)
          return
        }
        await refreshList()
        await CustomAlert.alert("完了", "募集を停止しました。")
        const myOffersAfterDelete: MatchingOffersModel[] = myOffers?.filter((offer) => offer.id !== offerId) || []
        await setMyOffers([...myOffersAfterDelete])
        await setIsDeletedSuc(true)
        callback()
      },
      [accessToken, offerId, myOffers, setMyOffers, refreshList, setIsDeletedSuc]
    )
  )

  const onApplication = useCallback(
    () => requiredApplication && application(requiredApplication),
    [application, requiredApplication]
  )

  const requestDataResult = useRequestData()
  const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(teamId))
  const teamMemberId = useMemo(() => myProfile?.memberId, [myProfile])

  const { fetch: execCreateFavoriteMatchingOffer, isFetching: isLoadingCreateFav } = useFetcher(
    useCallback(
      async ({ offerId }: { offerId: string }) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accountId, accessToken } = requestDataResult.content
        if (accountId == null || accessToken == null || teamMemberId == null) {
          return
        }
        const result = await createFavoriteMatchingOffer({
          accessToken,
          teamMemberId,
          matchingOfferId: offerId,
        })
        if (!result.isOk) {
          await CustomAlert.alert("エラー", result.error.message)
        }
        setFavFlagCache((prev) => ({ ...prev, [offerId]: true }))
        setFavFlag(true)
      },
      [requestDataResult, teamMemberId, setFavFlagCache]
    )
  )

  const { fetch: execDeleteFavoriteMatchingOffer, isFetching: isLoadingDeleteFav } = useFetcher(
    useCallback(
      async ({ offerId }: { offerId: string }) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accountId, accessToken } = requestDataResult.content
        if (accountId == null || accessToken == null || teamMemberId == null) {
          return
        }
        const currentFavoriteFlags = { ...favFlagCache }
        currentFavoriteFlags[offerId] = false
        setFavFlagCache(currentFavoriteFlags)
        const result = await deleteFavoriteMatchingOffer({
          accessToken,
          teamMemberId,
          matchingOfferId: offerId,
        })
        if (!result.isOk) {
          await CustomAlert.alert("エラー", result.error.message)
        }
        setFavFlag(false)
      },
      [requestDataResult, teamMemberId, setFavFlagCache, favFlagCache]
    )
  )

  const { resourceUrl: teamImageUrl, refreshResourceUrl: refreshTeamImageUrl } = useResource({
    type: "matchingTeamImage",
    teamId: otherTeamId || "",
    size: "thumbnail",
  })
  const { value: matchingMyProfile } = useAsyncSelector(
    matchingOtherProfileSelectorFamily({ otherTeamId: teamId, myTeamId: teamId })
  )
  const [profileUnsetModal, setProfileUnsetModal] = useState(false)
  const onCloseProfileUnsetModal = () => setProfileUnsetModal(false)

  const { value: account } = useAsyncSelector(accountSelector)
  const agreedFlg = useMemo(() => account?.matchingTermsAgreedAt !== undefined, [account?.matchingTermsAgreedAt])
  const [agreeModalOpen, setAgreeModalOpen] = useState(false)
  const [profileSetupModalOpen, setProfileSetupOpen] = useState(false)
  const [confirmSubmitOpen, setConfirmSubmitOpen] = useState(false)
  const [continuousAction, setContinuousAction] = useState<() => void>()
  const onCloseAgreeModal = () => setAgreeModalOpen(false)

  const goToSetupProfile = () => {
    setProfileSetupOpen(false)
    navigation.navigate(Screens.CreateOrEditMatchingProfile, {
      matchingProfile: matchingMyProfile,
      id: teamId || "",
      selectedMyTeam: selectedTeam,
    })
  }

  const onClickApplication = () => {
    if (!agreedFlg) {
      setContinuousAction(() => {
        console.log("")
      })
      setAgreeMatchingTerm(!!account?.matchingTermsAgreedAt ?? false)
      return setAgreeModalOpen(true)
    }
    if (matchingMyProfile === undefined) {
      return setProfileSetupOpen(true)
    }
    setConfirmSubmitOpen(true)
  }

  const goToProfile = (teamId: string, offerId: string) => {
    const goToProfileAction = () => {
      navigation.navigate(Screens.Root, {
        screen: RootTabs.OrganizationManagerTab,
        params: {
          screen: RootTabs.MatchingDrawer,
          params: {
            screen: MatchingDrawers.MyMatchingProfile,
            params: {
              id: teamId,
              offerId,
            },
          },
        },
      })
    }

    if (!agreedFlg) {
      setAgreeMatchingTerm(!!account?.matchingTermsAgreedAt ?? false)
      setContinuousAction(() => goToProfileAction)
      setAgreeModalOpen(true)
    } else {
      goToProfileAction()
    }
  }

  const { value: listMatchingApplication } = useAsyncSelector(listMatchingApplicationSelectorFamily({}))
  const appliedFlg = useMemo(
    () => listMatchingApplication?.some((ap) => value && ap.id === offerId),
    [listMatchingApplication, offerId, value]
  )

  const formatDate = useMemo(() => (typeof value?.date === "string" ? new Date(value.date) : undefined), [value?.date])
  const [favoriteChangeFlg, setFavoriteChangeFlg] = useState(false)

  const onIsFavoriteChange = (id: string) => {
    if (!isLoadingCreateFav && !isLoadingDeleteFav) {
      if (favFlag) {
        execDeleteFavoriteMatchingOffer({
          offerId: id,
        })
      } else {
        execCreateFavoriteMatchingOffer({
          offerId: id,
        })
      }
    }
  }

  return {
    value,
    teamName,
    teamId,
    message,
    setMessage,
    isSubmitDisabled,
    messageCount,
    setMessageCount,
    submissionConfirmationModalOpen,
    onClickSubmissionConfirmationModal,
    onCloseSubmissionConfirmationModal,
    onApplication,
    isSavingAsDraft,
    execCreateFavoriteMatchingOffer,
    execDeleteFavoriteMatchingOffer,
    teamImageUrl,
    refreshTeamImageUrl,
    otherTeamId,
    formatDate,
    profileUnsetModal,
    onCloseProfileUnsetModal,
    agreeModalOpen,
    onCloseAgreeModal,
    onClickApplication,
    appliedFlg,
    favoriteChangeFlg,
    setFavoriteChangeFlg,
    setBottomSheet,
    isBottomSheet,
    isDeleteMatchingOffer,
    onIsFavoriteChange,
    execDeleteMatchingOffer,
    isDeleteOfferSuccess,
    setConfirmSubmitOpen,
    confirmSubmitOpen,
    isLoading,
    isDeletedSuc,
    favFlag,
    _refresh,
    isLoadingDeleteFav,
    isLoadingCreateFav,
    goToProfile,
    profileSetupModalOpen,
    setProfileSetupOpen,
    goToSetupProfile,
    continuousAction,
  }
}
