import { selector } from "recoil"
import { draftIdState } from "src/recoil/atoms/contact/create/draftIdState"
import { DraftModel } from "src/types/contact/DraftModel"
import { draftSelectorFamily } from "./draftSelectorFamily"

export const draftSelector = selector<DraftModel | undefined>({
  key: "selectors/contact/draftSelector",
  get: async ({ get }) => {
    const draftId = get(draftIdState)
    if (draftId == null) {
      return
    }
    const draft = get(draftSelectorFamily(draftId))
    return draft
  },
})
