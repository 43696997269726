import { memo } from "react"
import { Svg, Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
}

export const MyMatchingOfferIcon = memo<Props>(({ fill = Colors.orange }) => {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20">
      <Path
        d="M1.73075 20C1.26321 20 0.857864 19.8284 0.514719 19.4853C0.171573 19.1421 0 18.7368 0 18.2692V4.21394C0 4.02408 0.03125 3.83757 0.09375 3.65441C0.15625 3.47124 0.25 3.29877 0.375 3.137L2.36056 0.653843C2.51121 0.456718 2.70531 0.298469 2.94287 0.179094C3.18042 0.0596985 3.43824 0 3.71634 0H16.2284C16.5065 0 16.7683 0.0596985 17.0139 0.179094C17.2594 0.298469 17.4575 0.456718 17.6082 0.653843L19.625 3.18509C19.75 3.34686 19.8438 3.52333 19.9062 3.7145C19.9688 3.90567 20 4.09619 20 4.28606V18.2692C20 18.7368 19.8284 19.1421 19.4853 19.4853C19.1421 19.8284 18.7368 20 18.2693 20H1.73075ZM1.44472 3.3125H18.5L16.8093 1.22597C16.7292 1.14584 16.6371 1.08174 16.5329 1.03366C16.4287 0.985574 16.3206 0.961531 16.2084 0.961531H3.74759C3.63541 0.961531 3.52323 0.985574 3.41106 1.03366C3.29887 1.08174 3.21073 1.14584 3.14662 1.22597L1.44472 3.3125ZM13.75 4.27403H6.25V13.0625L10 11.1875L13.75 13.0625V4.27403Z"
        fill={fill}
      />
    </Svg>
  )
})
