import { Result } from "src/utils/Result"
import { getTempToken as getTempTokenApi } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
}

type Success = {
  tempToken: string
}

type Failure = {
  message: string
}

export const getTempToken = ({ accessToken }: Params): Promise<Result<Success, Failure>> => {
  return execApi(
    () => getTempTokenApi(accessToken, {
      input: {
        accessToken: accessToken
      },
      appInfo
    }),
    res => ({tempToken: res.getTempToken.tempToken}),
    {
      identifiers: ["getTempToken", "getTempTokenApi"]
    }
  )
}
