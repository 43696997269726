import { Platform } from "react-native"
import * as WebBrowser from "expo-web-browser"

import { View } from "src/components/parts/Themed"
import { Screens } from "src/constants/Screens"
import { RootStackScreenProps } from "src/types.d"
import { OrganizationManagerStackScreenProps } from "src/types.d"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { useEffect, useState } from "react"
import { RootTabs } from "src/constants/RootTabs"
import { ContactReplyThreadLinkParams } from "./contact/reply/ContactReplyThread"
import { PendingInvitationsParams } from "./organization/PendingInvitations"
import { ContactDetailScreenParams } from "./contact/contactDetail/ContactDetailScreen"
import { OrganizationDetailParams } from "./organization/OrganizationDetail"
import { ConfirmModal } from "src/components/parts/Modal"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { accountSelector } from "src/recoil/selectors/account/accountSelector"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { appStoreIosRedirectLinkApp } from "src/constants/links/appStore"

type OrganizationDetailProps = OrganizationManagerStackScreenProps<typeof OrganizationManagerScreens.OrganizationDetail>
type InvitationProps = RootStackScreenProps<typeof Screens.PendingInvitations>
type ContactDetailProps = RootStackScreenProps<typeof Screens.ContactDetail>
type ContactReplyProps = RootStackScreenProps<typeof Screens.ContactReplyThread>

export type DlinkParams = OrganizationDetailProps | InvitationProps | ContactDetailProps | ContactReplyProps

export type MatchingThreadParams = {
  applicationId: string
  organizationId: string
}

export default function DlinkRedirect({ navigation, route }: RootStackScreenProps<typeof Screens.DlinkRedirect>) {
  const [visible, setVisible] = useState(true)
  const { value: account } = useAsyncSelector(accountSelector)
  const isPCScreen = useCheckPCScreen()

  const handleRedirect = () => {
    // if (Platform.OS === "web") {
    setVisible(false)
    const { path, params } = route
    if (!account) {
      navigation.replace(Screens.AuthorizationTop)
    }

    // 団体作成完了のメール通知のURLから
    if (path?.startsWith("/dlink/org/detail") && "organizationId" in params) {
      const { organizationId } = params as OrganizationDetailParams
      if (organizationId) {
        navigation.replace(Screens.Root, {
          screen: RootTabs.OrganizationManagerTab,
          params: {
            screen: OrganizationManagerScreens.OrganizationDetail,
            params: { organizationId },
          },
        })
      }
    }

    // 承認待ちのメール通知のURLから
    if (path?.startsWith("/dlink/invitations") && "id" in params) {
      const { id } = params as PendingInvitationsParams
      if (id) {
        navigation.replace(Screens.PendingInvitations, { id })
      }
    }
    if (path?.startsWith("/dlink/invitations") && "code" in params) {
      const { code } = params
      if (typeof code === "string") {
        navigation.replace(Screens.JoinOrganization, { code })
      }
    }
    // 返信のメール通知のURLから
    if (path?.startsWith("/dlink/replies") && "replyThreadId" in params) {
      const { replyThreadId } = params as ContactReplyThreadLinkParams
      if (replyThreadId) {
        navigation.replace(Screens.ContactReplyThread, {
          replyThreadId,
          isNew: false,
        })
      }
    }

    // 連絡のメール通知のURLから
    if (path?.startsWith("/dlink/contacts") && "contactId" in params && "organizationId" in params) {
      const { contactId, organizationId } = params as ContactDetailScreenParams
      if (contactId && organizationId) {
        navigation.replace(Screens.ContactDetail, { contactId, organizationId })
      }
    }
    // }
    if (path?.startsWith("/dlink/matching/matching_thread") && "applicationId" in params && "organizationId" in params) {
      const { applicationId, organizationId } = params as MatchingThreadParams
      if (applicationId && organizationId) {
        navigation.replace(Screens.MatchingThread, {
          applicationId: applicationId ?? "",
          myOrganizationId: organizationId ?? "",
          offerId: undefined,
        })
      }
    }
    if (path?.startsWith("/dlink/auth/login/email")) {
      if (!account) {
        navigation.navigate(Screens.LoginEmail)
      } else {
        navigation.replace(Screens.Root)
      }
    }
  }

  const handleOpenAppStore = async () => {
    // Check android ios
    const iOS = /iphone|ipod|ipad/.test(navigator.userAgent.toLowerCase())
    const isAndroid = navigator.userAgent.includes("Android")

    if (iOS) {
      await WebBrowser.openBrowserAsync(appStoreIosRedirectLinkApp)
    }

    if (isAndroid) {
      await WebBrowser.openBrowserAsync("https://play.google.com/store/apps/details?id=jp.ra9plus")
    }
  }

  useEffect(() => {
    if (Platform.OS === "ios" || Platform.OS === "android" || isPCScreen) {
      handleRedirect()
    }
  })

  return (
    <View>
      {!isPCScreen && (
        <ConfirmModal
          visible={visible}
          closeAction={handleRedirect}
          content="スマホアプリで開きますか？"
          okAction={handleOpenAppStore}
        />
      )}
    </View>
  )
}
