import { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilState, useResetRecoilState, useSetRecoilState } from "recoil"
import { GroupMember } from "src/types/organization/group/GroupMember"
import { addedMembersState } from "src/recoil/atoms/organization/group/addedMembersState"
import { deletedMemberIdsState } from "src/recoil/atoms/organization/group/deletedMemberIdsState"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { groupNameSelectorFamily } from "src/recoil/selectors/organization/group/groupNameSelectorFamily"
import { groupMembersByNameSelectorFamily } from "src/recoil/selectors/organization/group/groupMembersByNameSelectorFamily"
import { groupMemberListState } from "src/recoil/atoms/organization/group/groupMemberListState"
import { groupMemberListRequestIdState } from "src/recoil/atoms/organization/group/groupMemberListIdState"
import {
  currentEditGroupMemberPageState,
  currentGroupMemberPageState,
} from "src/recoil/atoms/organization/currentMemberPageState"
import { Member } from "src/queries/organization/putGroupInOrganization"
import { groupsRequestIdState } from "src/recoil/atoms/organization/group/groupsRequestIdState"
import { useWindowDimensions } from "react-native"
export const useGroupEditorData = (groupId?: string, organizationId?: string) => {
  const [searchText, setSearchText] = useState("")
  const [page, setPage] = useRecoilState(currentEditGroupMemberPageState)
  const refreshGroup = useRefresher(groupMemberListRequestIdState)
  const setPageGroupMember = useSetRecoilState(currentGroupMemberPageState)
  const [debouncedSearchText, setDebouncedSearchText] = useState("")
  const heightScreen = useWindowDimensions().height
  const pageSize = useMemo(() => (heightScreen - 184 > 600 ? 20 : 10), [heightScreen]) // Heigh of each row = 60px => 10 row = 600

  const { value: membersValue, isLoading: isMembersGroupLoading } = useAsyncSelector(
    groupMembersByNameSelectorFamily({
      groupId: groupId,
      getImage: true,
      teamId: organizationId ?? "",
      page: page,
      searchText: debouncedSearchText,
      limit: pageSize,
    })
  )
  const totalMember = useMemo(() => membersValue?.total || 0, [membersValue])
  const maxPages = useMemo(() => Math.ceil(totalMember / 10), [totalMember])

  const [defaultGroupMembers, setDefaultGroupMembers] = useRecoilState(groupMemberListState)

  useEffect(() => {
    if (membersValue && membersValue?.data) {
      setDefaultGroupMembers((prevMembers) => {
        const newMembers = membersValue.data?.filter((member) => !prevMembers.some((prevMember) => prevMember.id === member.id))
        return [...prevMembers, ...(newMembers ?? [])]
      })
    }
  }, [membersValue?.data, membersValue, setDefaultGroupMembers])

  useEffect(() => {
    setPage(1)
    if (!debouncedSearchText) {
      refreshGroup()
    }
  }, [debouncedSearchText, refreshGroup, setDefaultGroupMembers, setPageGroupMember, setPage])

  useEffect(() => {
    return () => {
      setPageGroupMember(1)
    }
  }, [setPageGroupMember])
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchText(searchText)
    }, 500)
    return () => {
      clearTimeout(handler)
    }
  }, [searchText])
  const loadMoreMembers = useCallback(() => {
    if (page < maxPages) {
      setPage((prevPage) => prevPage + 1)
    }
  }, [page, maxPages, setPage])

  const defaultLeaderMemberIds = useMemo(
    () => new Set(defaultGroupMembers?.filter((member) => member.isLeader).map((member) => member.id) || []),
    [defaultGroupMembers]
  )

  const { value: defaultGroupName } = useAsyncSelector(groupNameSelectorFamily(groupId))
  const [inputGroupName, setGroupName] = useState<string>()
  const groupName = useMemo(() => (inputGroupName ?? defaultGroupName) || "", [inputGroupName, defaultGroupName])
  const [addedMembers, setAddedMembers] = useRecoilState(addedMembersState)
  const [deletedMemberIds, setDeletedMemberIds] = useRecoilState(deletedMemberIdsState)
  const resetAddedMembers = useResetRecoilState(addedMembersState)
  const resetDeletedMemberIds = useResetRecoilState(deletedMemberIdsState)
  const [selectedGroupLeaders, setSelectedGroupLeaders] = useState<{ [id: GroupMember["id"]]: boolean }>({})

  const refreshGroups = useRefresher(groupsRequestIdState)
  const refresh = useCallback(() => {
    refreshGroups()
    refreshGroup()
  }, [refreshGroup, refreshGroups])

  /** グループリーダー選択を切り替える */
  const toggleGroupLeader = useCallback(
    (memberId: GroupMember["id"]) => {
      setSelectedGroupLeaders((prev) => ({
        ...prev,
        [memberId]: !(prev[memberId] ?? defaultLeaderMemberIds.has(memberId)),
      }))
    },
    [setSelectedGroupLeaders, defaultLeaderMemberIds]
  )

  /** メンバーを追加する */
  const addMembers = useCallback(
    (members: GroupMember[]) => {
      setDeletedMemberIds((prev) => {
        const newSet = new Set(prev)
        members.forEach((m) => newSet.delete(m.id))
        return newSet
      })
      setAddedMembers((prev) => {
        const existingIds = new Set(prev.concat(defaultGroupMembers || []).map((m) => m.id))
        const data = prev
          .concat(members.filter((m) => !existingIds.has(m.id)))
          .sort((l, r) => (l.memberNumber < r.memberNumber ? -1 : 1))

        return data
      })
    },
    [defaultGroupMembers, setAddedMembers, setDeletedMemberIds]
  )

  /** メンバーを削除対象に指定する */
  const deleteMember = useCallback(
    (memberId: GroupMember["id"]) => {
      setDeletedMemberIds((prev) => {
        const newSet = new Set(prev)
        newSet.add(memberId)
        return newSet
      })
      setSelectedGroupLeaders((prev) => ({
        ...prev,
        [memberId]: false,
      }))
    },
    [setDeletedMemberIds]
  )

  /** 削除対象にしたメンバーを対象外に戻す */
  const recoverMember = useCallback(
    (memberId: GroupMember["id"]) => {
      setDeletedMemberIds((prev) => {
        const newSet = new Set(prev)
        newSet.delete(memberId)
        return newSet
      })
    },
    [setDeletedMemberIds]
  )

  /** 全データを統合した表示用のメンバー配列 (削除対象を含む) */
  const mergedMembersWithDeleted = useMemo<(GroupMember & { isSelectedForDelete: boolean })[]>(
    () =>
      (defaultGroupMembers || []).concat(addedMembers.map((add) => ({ ...add, isLeader: false }))).map((m) => ({
        ...m,
        isLeader: selectedGroupLeaders[m.id] ?? m.isLeader,
        deleteFlg: deletedMemberIds.has(m.id),
        isSelectedForDelete: deletedMemberIds.has(m.id),
      })),
    [defaultGroupMembers, addedMembers, selectedGroupLeaders, deletedMemberIds]
  )

  /** 全データを統合した表示用のメンバー配列 (削除対象を除く) */
  const mergedMembers = useMemo<Member[]>(
    () =>
      mergedMembersWithDeleted.map((e) => ({
        id: e.id,
        isLeader: e.isLeader,
        isDeleteFlg: !!e.deleteFlg,
      })),
    [mergedMembersWithDeleted]
  )

  /** 全データを統合したメンバー配列にテキストフィルタを適用したメンバー配列 */
  const filteredMembers = useMemo<(GroupMember & { isSelectedForDelete: boolean })[]>(
    () =>
      mergedMembersWithDeleted
        .filter((member) => member.nickname.includes(searchText) || member?.nicknameKana?.includes(searchText))
        .sort((l, r) => (l.memberNumber < r.memberNumber ? -1 : 1)),
    [mergedMembersWithDeleted, searchText]
  )

  const resetState = useCallback(() => {
    resetAddedMembers()
    resetDeletedMemberIds()
  }, [resetAddedMembers, resetDeletedMemberIds])

  return {
    groupName,
    setGroupName,
    searchText,
    setSearchText,
    toggleGroupLeader,
    addMembers,
    deleteMember,
    debouncedSearchText,
    recoverMember,
    mergedMembers,
    defaultGroupMembers,
    resetState,
    refresh,
    totalMember,
    loadMoreMembers,
    isMembersGroupLoading,
    filteredMembers,
    setDefaultGroupMembers,
  }
}
