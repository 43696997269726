import { Result } from "src/utils/Result"
import { sendJoinRequest } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type JoinOrganizationParams = {
  accessToken: string
  accountId: string
  organizationId: string
  nickname: string
  kanaNickname: string
  isShowEmail?: boolean
  phoneNumber?: string
  isShowPhoneNumber?: boolean
  affiliation?: string
  isShowAffiliation?: boolean
  gradeId?: number
}

type JoinOrganizationSuccess = unknown

type JoinOrganizationFailure = {
  message: string
}

export const joinOrganization = async (params: JoinOrganizationParams): Promise<Result<JoinOrganizationSuccess, JoinOrganizationFailure>> => {
  return execApi(
    () => sendJoinRequest(params.accessToken, {
      input: {
        teamId: params.organizationId,
        nickname: params.nickname,
        nicknameKana: params.kanaNickname,
        showEmailFlg: params.isShowEmail || false,
        phoneNumber: params.phoneNumber,
        showPhoneNumberFlg: params.isShowPhoneNumber || false,
        belongs: params.affiliation,
        showBelongsFlg: params.isShowAffiliation || false,
        grade: params.gradeId,
      },
      appInfo
    }),
    res => res.sendJoinRequest,
    {
      identifiers: ["joinOrganization", "sendJoinRequest"]
    }
  )
}
