import { FunctionComponent, memo, useCallback } from "react"
import { FlatList, ListRenderItemInfo, StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"
import { organizationCategories, OrganizationCategory } from "src/constants/organization/OrganizationCategory"
import type { CreateOrEditOrganizationStackScreenProps } from "src/types.d"
import { Button } from "src/components/parts/buttons/Button"
import { CreateOrEditOrganizationScreens } from "src/constants/CreateOrEditOrganizationScreens"
import { toCategoryMasterValue } from "src/types/organization/OrganizationCategoryMaster"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

type CardProps = ListRenderItemInfo<{ id: OrganizationCategory; label: string; Icon: FunctionComponent }>

export const SelectCategory = memo<
  CreateOrEditOrganizationStackScreenProps<typeof CreateOrEditOrganizationScreens.SelectCategory>
>(({ navigation, route }) => {
  const isLargeScreen = useCheckPCScreen()
  const Card = useCallback(
    ({ item: { Icon, ...otherItem } }: CardProps) => (
      <View style={isLargeScreen ? styles.webCategory : styles.category}>
        <Button
          style={styles.categoryCard}
          onPress={() =>
            navigation.navigate(CreateOrEditOrganizationScreens.SelectSubCategory, {
              ...route.params,
              category: { id: toCategoryMasterValue(otherItem.id), label: otherItem.label },
            })
          }
        >
          <View style={styles.categoryIcon}>
            <Icon />
          </View>
          <View>
            <Text style={styles.categoryLabel}>{otherItem.label}</Text>
          </View>
        </Button>
      </View>
    ),
    [isLargeScreen, navigation, route.params]
  )

  return (
    <View style={styles.container}>
      <View style={styles.description}>
        <Text style={styles.descriptionText}>活動内容に当てはまるカテゴリを選択してください</Text>
      </View>
      {isLargeScreen ? (
        <View style={{ alignItems: "center" }}>
          <FlatList numColumns={3} renderItem={Card} data={organizationCategories} style={styles.webCategories} />
        </View>
      ) : (
        <FlatList numColumns={2} renderItem={Card} data={organizationCategories} style={styles.categories} />
      )}
    </View>
  )
})
SelectCategory.displayName = "SelectCategory"

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: Colors.white,
  },
  description: {
    paddingVertical: 20,
    backgroundColor: Colors.white3,
    paddingHorizontal: 30,
    borderBottomWidth: 1,
    borderBottomColor: Colors.white2,
  },
  descriptionText: {
    color: Colors.warmGrey,
    fontSize: 13,
    fontWeight: "bold",
    textAlign: "center",
  },
  categories: {
    paddingHorizontal: 24,
    paddingVertical: 14,
  },
  webCategories: {
    width: 480,
    paddingVertical: 14,
  },
  category: {
    flex: 1,
    minWidth: "40%",
    padding: 6,
  },
  webCategory: {
    flex: 1,
    padding: 6,
  },
  categoryCard: {
    justifyContent: "space-around",
    alignItems: "center",
    backgroundColor: Colors.white3,
    borderRadius: 16,
    padding: 16,
    height: 130,
  },
  categoryIcon: {
    width: 50,
    height: 50,
    justifyContent: "center",
    alignItems: "center",
  },
  categoryLabel: {
    textAlign: "center",
    fontSize: 13,
    lineHeight: 17,
    fontWeight: "bold",
  },
})
