import { Result } from "src/utils/Result";
import { deleteMatchingOfferDraft as _deleteMatchingOfferDraft, GqlError, listMatchingOfferDraft } from "../../aws/customAPI";
import { MatchingPurposeMapping } from "../../types/matching/matchingPurpose";
import { MatchingOffersModel } from "../../types/matching/matchingOfferModel";

type DeleteMatchingOfferDraftParams = {
  accessToken: string
  draftId: string
}

type DeleteMatchingOfferDraftSuccess = unknown

type DeleteMatchingOfferDraftFailure = {
  message: string
}

export const deleteMatchingOfferDraft = async ({accessToken, draftId}: DeleteMatchingOfferDraftParams): Promise<Result<DeleteMatchingOfferDraftSuccess, DeleteMatchingOfferDraftFailure>> => {
  return await _deleteMatchingOfferDraft(accessToken, {
    input: {
      id: draftId
    }
  }).then(res => {
    return (res.data?.deleteMatchingOfferDraft.id) ?
      Result.Ok(undefined) :
      Result.Error<DeleteMatchingOfferDraftFailure>({ message: '募集の下書きの削除に失敗しました' })
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || '募集の下書きの削除に失敗しました'
    return Result.Error<DeleteMatchingOfferDraftFailure>({
      message: message
    })
  })
}


type GetMatchingOfferParams = {
  accessToken: string
  organizationId: string
}

export type GetMatchingOfferSuccess = MatchingOffersModel[]

type GetMatchingOfferFailure = {
  message: string
}

export const getListMatchingOfferDraft = async ({
                                                  accessToken,
                                                  organizationId
                                                }: GetMatchingOfferParams): Promise<Result<GetMatchingOfferSuccess, GetMatchingOfferFailure>> => {
  return await listMatchingOfferDraft(accessToken, {
    teamId: organizationId
  }).then(res => {
    if (res.data) {
      return Result.Ok<GetMatchingOfferSuccess>(res.data.getTeam.matchingOfferDrafts.items.map(item => ({
        id: item.id,
        purpose: MatchingPurposeMapping["jointPracticeGame"],
        title: item.title,
        date: item.date,
        startTime: item.startTime,
        endTime: item.endTime,
        prefecture: item.prefecture,
        city: item.city,
        ageFrom: item.ageFrom,
        ageTo: item.ageTo,
        publishEndDate: item.publishEndDate,
        activity: res.data?.getTeam.activity,
        teamId: item.teamId,
        teamName: item.teamName,
      })));
    } else {
      return Result.Error<GetMatchingOfferFailure>({ message: "下書きの取得に失敗しました" });
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "下書きの取得に失敗しました";
    return Result.Error<GetMatchingOfferFailure>({
      message: message
    });
  });
};