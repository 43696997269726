import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { RootStackScreenProps } from "src/types.d"
import { Modal, Platform, ScrollView, StyleSheet, Text, View, useWindowDimensions } from "react-native"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { Screens } from "src/constants/Screens"
import { commonSingleModalOptions } from "src/constants/options/commonSingleModalOptions"
import { useEmailInvitationData } from "src/recoil/hooks/organization/useEmailInvitationData"
import { CustomScrollView } from "src/components/parts/CustomScrollView"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { Colors } from "src/constants/Colors"
import { CircleThumbnail } from "src/components/parts/CircleThumbnail"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { QuestionWithCircleIcon } from "src/components/parts/icons/QuestionWithCircleIcon"
import { Button } from "src/components/parts/buttons/Button"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"

const placeholderText =
  "招待するメールアドレスを入力してください。\n入力例\ntanaka@eole.co.jp\nsuzuki@eole.co.jp\nssto@eole.co.jp\nyosida@role.co.jp\nyamada@eole.co.jp"

export type InvitationEmailParams = {
  id: string
}

type Props = RootStackScreenProps<typeof Screens.InvitationEmail>

export const InvitationEmail = memo<Props>(({ navigation, route }) => {
  const heightScreen = useWindowDimensions().height
  const widthScreen = useWindowDimensions().width
  const isLargeScreen = useCheckPCScreen()
  const id = useMemo(() => route.params.id, [route.params.id])
  const insets = useSafeAreaInsets()
  const [invitationMethodModalOpen, setInvitationMethodModalOpen] = useState(false)
  const [emailInvitationContentsModalOpen, setEmailInvitationContentsModalOpen] = useState(false)

  const onOpenResultModal = useCallback(
    (successCount: number, errorEmailList: string[] | undefined) => {
      if (errorEmailList) {
        navigation.navigate(Screens.InvitationEmailResult, { id, successCount, errorEmailList })
      }
    },
    [id, navigation]
  )

  const {
    thumbnailImageUrl,
    organizationName,
    inputEmails,
    setInputEmails,
    onSubmit,
    isLoadingSubmit,
    isMemberInvitationDataLoading,
  } = useEmailInvitationData(id, onOpenResultModal)

  const onCloseInvitationMethodModal = useCallback(() => {
    setInvitationMethodModalOpen(false)
  }, [])

  const onOpenInvitationMethodModal = useCallback(() => {
    setInvitationMethodModalOpen(true)
  }, [])

  const onCloseEmailInvitationContentsModal = useCallback(() => {
    setEmailInvitationContentsModalOpen(false)
  }, [])

  const onOpenEmailInvitationContentsModal = useCallback(() => {
    setEmailInvitationContentsModalOpen(true)
  }, [])

  return (
    <CustomKeyboardAvoidingView>
      <ScrollView scrollEnabled={Platform.OS === "web" || Platform.OS === "ios"}>
        <View style={[styles.head, isLargeScreen ? { borderBottomWidth: 1, borderBottomColor: Colors.lightGrey } : {}]}>
          <CircleThumbnail size={32} source={thumbnailImageUrl ? { uri: thumbnailImageUrl } : defaultThumbnail} />
          <Text style={styles.orgName}>{organizationName}</Text>
        </View>
        <View style={{ justifyContent: "center", alignItems: "center" }}>
          <View style={isLargeScreen ? { width: 600 } : { width: "100%" }}>
            <View style={[styles.contentsContainer, isLargeScreen ? { borderTopWidth: 0 } : {}]}>
              <ItemLabel label="一括メールで招待" />
              <View style={{ flexDirection: "row", justifyContent: "center", alignItems: "center", paddingTop: 25 }}>
                <View style={{ display: "flex", flexDirection: "row" }}>
                  <Button style={styles.aboutMigrationContainer} onPress={onOpenInvitationMethodModal}>
                    <QuestionWithCircleIcon fill={Colors.orange} />
                    <Text>{"  "}</Text>
                    <Text style={styles.aboutMigrationText}>招待方法について</Text>
                  </Button>
                  <Button style={styles.aboutMigrationContainer} onPress={onOpenEmailInvitationContentsModal}>
                    <QuestionWithCircleIcon fill={Colors.white3} />
                    <Text style={styles.aboutMigrationText}>招待メールの内容について</Text>
                  </Button>
                </View>
              </View>
              <View style={{ paddingTop: 16, paddingBottom: Platform.OS === "android" ? 60 : 25 }}>
                <MultilineTextInput
                  style={[
                    styles.textInputMulti,
                    { height: 320 },
                    Platform.OS !== "ios" && { fontSize: 16, paddingVertical: 13 },
                  ]}
                  minHeight={320}
                  placeholder={placeholderText}
                  value={inputEmails}
                  onChangeText={setInputEmails}
                />
              </View>
            </View>
          </View>
        </View>
      </ScrollView>
      <View
        style={[styles.actionContainer, { paddingBottom: insets.bottom + 10 }, isLargeScreen ? { alignItems: "center" } : {}]}
      >
        <TextButton
          style={isLargeScreen ? { width: 300 } : {}}
          title={"招待メールを送信"}
          buttonType={ButtonType.Primary}
          onPress={onSubmit}
          disabled={isMemberInvitationDataLoading || inputEmails.length === 0}
          isLoading={isLoadingSubmit}
        />
      </View>
      <Modal animationType="fade" visible={invitationMethodModalOpen} transparent>
        <View style={styles.modalWrapper}>
          <View style={[styles.mainModal, { maxWidth: 600 }]}>
            <View style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
              <Button onPress={onCloseInvitationMethodModal} style={{ paddingVertical: 10, paddingHorizontal: 10 }}>
                <RemoveIcon fill={Colors.orange}></RemoveIcon>
              </Button>
              <Text style={styles.titleModal}>招待方法について</Text>
            </View>
            <Text style={styles.textModal}>
              {
                "団体に招待したい方のメールアドレスを入力欄に1行1アドレスで入力して下さい。アドレスを入力し、「招待メールを送信」をクリックした後、各メールアドレス宛に「info@ra9plus.jp」より招待メールが順次送信されます。\n尚、メール送信には最大で2時間程度かかります。"
              }
            </Text>
          </View>
        </View>
      </Modal>
      <Modal animationType="fade" visible={emailInvitationContentsModalOpen} transparent>
        <View style={styles.modalWrapper}>
          <View style={styles.mainModal}>
            <View style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
              <Button onPress={onCloseEmailInvitationContentsModal} style={{ paddingVertical: 10, paddingHorizontal: 10 }}>
                <RemoveIcon fill={Colors.orange}></RemoveIcon>
              </Button>
              <Text style={styles.titleModal}>招待メールの内容について</Text>
            </View>
            <Text style={styles.textModal}>{"招待メールでは、下記の内容が送信されます。"}</Text>
            <CustomScrollView
              style={[
                styles.textInput,
                Platform.OS === "web" ? { maxHeight: heightScreen - 190, maxWidth: widthScreen - 60 } : {},
              ]}
            >
              <Text style={styles.textModal}>
                {
                  "件名：【[団体名]】に招待されています[らくらく連絡網＋]\n\n\n団体名『[団体名]』の[招待メールを送信した方の団体内ニックネーム]様より団体に招待されました。\n下記のURLより団体への参加手続きをお願いいたします。\n\n■こちらのアドレスで既に「らくらく連絡網＋」のアカウントがある方\n下記[メンバー登録用URL]よりログインの上、団体参加申請のお手続きを行なってください。\n\n■こちらのアドレスで未だ「らくらく連絡網＋」のアカウントがない方\n下記［メンバー登録用URL］よりアカウント登録の上、団体参加申請のお手続きを行ってください。\n\n［メンバー登録用URL］\n［メンバー移行登録用URL］\n［招待コード］\n［招待コード］\n※URLの有効期限はyyyy/mm/dd です。\n\n※本メールは、既に参加手続き済みの方にも配信される場合がございます。URLへアクセス後、団体参加画面で「次へ」ボタンを押下した際に「既に参加申請済です。」と表示される場合、お手続きは不要です。\n\n登録についてのよくある質問はこちら\nhttps://www.ra9plus.jp/guide/category/start/\n\n※本メールにお心あたりがない方は、お手数ですが、このメールを破棄くださいますようお願いいたします。\n※このメ－ルは送信専用です。返信をいただいてもご回答できませんのでご了承ください。\n----\nらくらく連絡網＋\nhttps://www.ra9plus.jp/guide"
                }
              </Text>
            </CustomScrollView>
          </View>
        </View>
      </Modal>
    </CustomKeyboardAvoidingView>
  )
})

export const options: ScreenOptions = {
  ...commonSingleModalOptions,
  title: "一括メールで招待",
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: Colors.white3,
  },
  head: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 8,
    backgroundColor: Colors.white3,
  },
  orgName: {
    marginLeft: 8,
  },
  contentsContainer: {
    padding: 24,
    borderTopWidth: 1,
    borderTopColor: Colors.lightGrey,
  },
  description: {
    fontSize: 14,
    lineHeight: 21,
    color: Colors.black,
    marginVertical: 8,
    marginLeft: 0,
  },
  contentWrapper: {
    borderRadius: 8,
    backgroundColor: "#efeae3",
  },
  content: {
    fontSize: 16,
    lineHeight: 25,
    color: Colors.black2,
    padding: 12,
  },
  centering: {
    textAlign: "left",
  },
  buttonContainer: {
    marginTop: 16,
    alignItems: "center",
  },
  buttonLabel: {
    marginLeft: 14,
    color: Colors.orange,
  },
  actions: {
    paddingVertical: 16,
    paddingHorizontal: 32,
    paddingBottom: 25,
  },
  secondaryButton: {
    marginTop: 16,
  },
  linkText: {
    color: Colors.black,
    textDecorationLine: "underline",
  },
  actionContainer: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  textInput: {
    fontSize: 16,
    textAlignVertical: "top",
    paddingVertical: 13,
    paddingHorizontal: 16,
    backgroundColor: Colors.white3,
    borderWidth: 2,
    borderColor: Colors.lightGrey,
    borderRadius: 10,
    minHeight: 320,
  },
  textInputMulti: {
    textAlignVertical: "top",
    paddingLeft: 16,
    backgroundColor: Colors.white3,
    borderWidth: 2,
    borderColor: Colors.lightGrey,
    borderRadius: 10,
    minHeight: 320,
  },

  modalWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.37)",
    padding: 22,
    paddingVertical: 60,
  },
  mainModal: {
    backgroundColor: Colors.white3,
    borderRadius: 8,
    paddingVertical: 24,
    paddingHorizontal: 20,
  },
  titleModal: {
    fontSize: 18,
    fontWeight: "600",
    width: "100%",
    textAlign: "center",
    paddingRight: 50,
  },
  textModal: {
    color: Colors.greyshBrown,
    fontSize: 14,
    lineHeight: 20,
    marginVertical: 20,
  },
  aboutMigrationContainer: {
    flexDirection: "row",
    marginTop: 0,
  },
  aboutMigrationText: {
    color: Colors.greyshBrown,
    textDecorationLine: "underline",
    marginLeft: 0,
  },
  migrateContainer: {
    alignItems: "center",
  },
})
