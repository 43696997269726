import { ComponentProps, memo } from "react"
import { StyleSheet, Text, View } from "react-native"
import { Colors } from "src/constants/Colors"

type Props = {
  message: string
  style?: ComponentProps<typeof View>["style"]
}

export const ValidationErrorMessage = memo<Props>(({ message, style }) => {
  return <View style={[styles.container, style]}>{message && <Text style={styles.message}>{message}</Text>}</View>
})

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#FF0000",
    borderRadius: 4,
    paddingHorizontal: 8,
  },
  message: {
    lineHeight: 25,
    fontSize: 14,
    color: Colors.white,
  },
})
