import { FC } from "react"
import Svg, { Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

export const DoubleQuoteStart: FC = () => (
  <Svg width="16.38" height="14.217" viewBox="0 0 16.38 14.217">
    <Path
      data-name="パス 950"
      d="M20.29-23.4h-6.4v3.468a16.785 16.785 0 0 0 .854 5.708 16.446 16.446 0 0 0 2.774 5.041l2.427-2.081a12.37 12.37 0 0 1-1.387-2.267 7.858 7.858 0 0 1-.533-1.547q-.16-.48-.32-1.04h2.585zm-9.978 0H3.91v3.468a16.785 16.785 0 0 0 .854 5.708 16.446 16.446 0 0 0 2.774 5.045l2.427-2.081a12.37 12.37 0 0 1-1.387-2.267 7.858 7.858 0 0 1-.533-1.547q-.16-.48-.32-1.04h2.587z"
      transform="translate(-3.91 23.396)"
      fill={Colors.white3}
    />
  </Svg>
)

DoubleQuoteStart.displayName = "DoubleQuoteStart"
