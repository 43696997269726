import { getResourceGetUrl as getResourceGetUrlApi, multiTeamImageGetUrl as multiTeamImageGetUrlApi,MultiTeamImageGetUrlVariables,ResourceGetUrlParams } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"
import { GetTeamImageGetUrlInput } from "src/aws/API"
import { Result } from "src/utils/Result"
import { LIMIT_TIME_ERROR } from "src/utils/const"

type Params = {
  accessToken: string
} & ResourceGetUrlParams

export const getResourceGetUrl = async ({accessToken, ...rest}: Params): Promise<string | undefined> => {
  const result = await execApi(
    () => getResourceGetUrlApi(accessToken, { ...rest, appInfo }),
    res => res.url,
    {
      identifiers: ["getResourceGetUrl", "getResourceGetUrlApi"]
    }
  )

  if (result.isOk) {
    return result.content
  } else {
    if (result.error.errorCode == 400) {
      return LIMIT_TIME_ERROR
    } else {
      return undefined
    }
  }
}

type MultiTeamImageParams = {
  accessToken: string
  teamImages: GetTeamImageGetUrlInput[]
}

export type ImageTeamModel = {
  teamId: string
  url: string
}

export const multiTeamImageGetUrl = async ({accessToken, teamImages}: MultiTeamImageParams): Promise<ImageTeamModel[] | undefined> => {
  const variables: MultiTeamImageGetUrlVariables = {
    input: {
      teamImages: teamImages
    }
  }
  const result = await execApi(
    () => multiTeamImageGetUrlApi(accessToken, { ...variables, appInfo }),
    res => {
      const teamImages: ImageTeamModel[] = res.multiTeamImageGetUrl.teamImages.map((teamImage) => {
        const { image, ...rest } = teamImage;
        const url = image?.url || ""
        return { ...rest, url };
      });
      return teamImages;
    },
    {
      identifiers: ["multiTeamImageGetUrl", "multiTeamImageGetUrlApi"]
    }
  );

  return result.isOk ? result.content : []
}
