import { ComponentProps, memo, ReactNode, useMemo, useState } from "react"
import { Image, Pressable, StyleSheet, Text, TouchableOpacity, View } from "react-native"
import { useRequiredCallback } from "src/hooks/useRequiredCallback"
import { RemoveRowIcon } from "src/components/parts/icons/RemoveRowIcon"
import { Checkbox } from "src/components/parts/Checkbox"
import { Colors } from "src/constants/Colors"
import { FlagIcon } from "src/components/parts/icons/FlagIcon"
import { Button } from "src/components/parts/buttons/Button"
import { MemberTypeLabel } from "src/constants/MemberTypeLabel"
import { MemberType } from "src/types/organization/OrganizationMember"
import { EmailIcon } from "src/components/parts/icons/EmailIcon"
import { LockIcon } from "src/components/parts/icons/LockIcon"
import { useResource } from "src/recoil/hooks/resource/useResource"
import { ChevronRightIcon } from "src/components/parts/icons/ChevronRightIcon"
import { defaultThumbnail } from "src/constants/defaultThumbnail"
import { RadioButton } from "../RadioButton"

type Props = {
  id: string
  name: string
  imageUrl?: string
  deleteImage?: any
  memberType: MemberType
  isLeader: boolean
  isSelectedForDelete?: boolean
  isSelectedForCheckbox?: boolean
  deleteMember?: () => void
  recoverMember?: () => void
  toggleLeader?: () => void
  toggleSelectedForCheckbox?: () => void
  withChevronRightIcon?: boolean
  isJoined?: boolean
  customSelect?: boolean
  mode?: string
  isCustomImg?: boolean
  isApplicantAuthorityTransfer?: boolean
  isClearBackground?: boolean
  tapToName?: () => void
}

const DynamicContainer = memo<
  Pick<ComponentProps<typeof Button>, "onPress"> &
    Pick<ComponentProps<typeof View>, "style"> & { isButton: boolean; children: ReactNode }
>(({ isButton, children, ...otherProps }) =>
  isButton ? <Button {...otherProps}>{children}</Button> : <View {...otherProps}>{children}</View>
)

export const MemberListItem = memo<Props>(
  ({
    id,
    name,
    imageUrl,
    deleteImage,
    memberType,
    isLeader,
    isSelectedForDelete,
    isSelectedForCheckbox,
    deleteMember,
    recoverMember,
    toggleLeader,
    toggleSelectedForCheckbox,
    withChevronRightIcon,
    isJoined,
    customSelect,
    mode,
    isCustomImg,
    isApplicantAuthorityTransfer = false,
    isClearBackground = false,
    tapToName,
  }) => {
    const [imgFail, setImgFail] = useState(false)
    const { resourceUrl: imageUrlResource, refreshResourceUrl: refreshImageUrl } = useResource(
      !imageUrl ? { type: "teamMemberImage", id, size: "thumbnail" } : { type: "none" }
    )
    const isRepresentative = useMemo(() => memberType === MemberType.Representative, [memberType])
    const isGeneralWithContactAuthorization = useMemo(
      () => memberType === MemberType.GeneralWithContactAuthorization,
      [memberType]
    )
    const handleDeleteMember = useRequiredCallback(deleteMember)
    const handleRecoverMember = useRequiredCallback(recoverMember)
    const handleToggleLeader = useRequiredCallback(toggleLeader)
    const handleToggleCheckbox = useRequiredCallback(toggleSelectedForCheckbox)
    const isRemoveButtonEnabled = useMemo(() => isSelectedForDelete != null, [isSelectedForDelete])
    const isToggleLeaderButtonEnabled = useMemo(() => toggleLeader != null, [toggleLeader])
    const isCheckboxEnabled = useMemo(() => toggleSelectedForCheckbox != null, [toggleSelectedForCheckbox])
    const isJoinedEnabled = useMemo(() => isJoined != null, [isJoined])

    return (
      <>
        {isRemoveButtonEnabled && isSelectedForDelete ? (
          <View style={styles.removedContainer} key={id}>
            <Text numberOfLines={3} style={{ width: "85%" }}>
              {name}さんを削除しました
            </Text>
            <TouchableOpacity onPress={handleRecoverMember}>
              <Text style={styles.textButtonOrange}>元に戻す</Text>
            </TouchableOpacity>
          </View>
        ) : (
          <DynamicContainer
            style={[
              styles.container,
              isClearBackground ? null : { backgroundColor: Colors.white3 },
              isSelectedForCheckbox ? styles.containerHighlight : null,
              isJoinedEnabled ? { paddingRight: 5 } : null,
              customSelect ? { borderBottomWidth: 1 } : null,
              customSelect && isSelectedForCheckbox !== undefined ? { borderBottomWidth: 0.8, borderTopWidth: 0.8 } : null,
              mode === "createContact" ? { width: "75%" } : null,
              isApplicantAuthorityTransfer ? { borderTopWidth: 0.8 } : {},
            ]}
            isButton={isCheckboxEnabled}
            onPress={handleToggleCheckbox}
            key={id}
          >
            <View style={[styles.leftContainer, isJoinedEnabled ? { paddingRight: 12 } : null]}>
              {isApplicantAuthorityTransfer && (
                <TouchableOpacity style={styles.radio}>
                  <RadioButton value={true} label="" onPress={handleToggleCheckbox} selected={isSelectedForCheckbox} />
                </TouchableOpacity>
              )}

              {isRemoveButtonEnabled ? (
                <TouchableOpacity style={styles.removeButtonWrapper} onPress={handleDeleteMember}>
                  <RemoveRowIcon />
                </TouchableOpacity>
              ) : null}
              {isCustomImg ? (
                <Image
                  style={styles.image}
                  source={deleteImage ? deleteImage : imageUrl && !imgFail ? { uri: imageUrl } : defaultThumbnail}
                  onError={() => setImgFail(true)}
                />
              ) : (
                <Image
                  style={styles.image}
                  source={
                    deleteImage
                      ? deleteImage
                      : imageUrl
                      ? { uri: imageUrl }
                      : imageUrlResource
                      ? { uri: imageUrlResource }
                      : defaultThumbnail
                  }
                  onError={refreshImageUrl}
                />
              )}

              <View style={styles.nameContainer}>
                <View style={styles.nameInner}>
                  <Pressable onPress={tapToName}>
                    <Text numberOfLines={10} style={[styles.nameText, { width: isRemoveButtonEnabled ? "65%" : "100%" }]}>
                      {name}
                    </Text>
                  </Pressable>
                </View>
                <View style={styles.nameInner}>
                  {isRepresentative ? (
                    <View style={styles.roleContainer}>
                      <LockIcon />
                      <Text style={styles.roleText}>{MemberTypeLabel.Representative}</Text>
                    </View>
                  ) : null}
                  {isLeader ? (
                    <View style={styles.roleContainer}>
                      <FlagIcon />
                      <Text style={styles.roleText}>{"グループリーダー"}</Text>
                    </View>
                  ) : null}
                  {isGeneralWithContactAuthorization ? (
                    <View style={styles.roleContainer}>
                      <EmailIcon />
                      <Text style={styles.roleText}>{MemberTypeLabel.GeneralWithContactAuthorization}</Text>
                    </View>
                  ) : null}
                </View>
              </View>
            </View>
            {!isApplicantAuthorityTransfer && (
              <>
                {isToggleLeaderButtonEnabled ? (
                  <View style={styles.rightContainer}>
                    {isLeader ? (
                      <TouchableOpacity onPress={handleToggleLeader}>
                        <Text style={styles.textButton}>リーダー解除</Text>
                      </TouchableOpacity>
                    ) : (
                      <TouchableOpacity onPress={handleToggleLeader}>
                        <Text style={styles.textButtonOrange}>リーダーに設定</Text>
                      </TouchableOpacity>
                    )}
                  </View>
                ) : isCheckboxEnabled ? (
                  <View style={styles.rightContainer}>
                    <Checkbox isChecked={isSelectedForCheckbox} onPress={handleToggleCheckbox} />
                  </View>
                ) : withChevronRightIcon ? (
                  <ChevronRightIcon />
                ) : null}
                {isJoinedEnabled ? (
                  <View style={styles.rightContainer}>
                    {isJoined ? (
                      <Text style={styles.textButtonGray}>参加済</Text>
                    ) : (
                      <Text style={styles.textButtonOrange}>未参加</Text>
                    )}
                  </View>
                ) : null}
              </>
            )}
          </DynamicContainer>
        )}
      </>
    )
  }
)

const styles = StyleSheet.create({
  container: {
    paddingVertical: 10,
    paddingHorizontal: 15,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottomColor: Colors.white2,
    borderTopColor: Colors.white2,
  },
  containerHighlight: {
    backgroundColor: Colors.pale,
    borderBottomColor: Colors.orange,
    borderTopColor: Colors.orange,
  },
  leftContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "75%",
  },
  rightContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "25%",
  },
  nameContainer: {
    justifyContent: "center",
    alignItems: "flex-start",
    marginLeft: 15,
    width: "100%",
  },
  nameInner: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    width: "100%",
  },
  roleContainer: {
    marginTop: 8,
    marginRight: 12,
    flexDirection: "row",
    alignItems: "center",
  },
  roleText: {
    fontSize: 11,
    color: Colors.warmGrey,
    marginLeft: 4,
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 20,
  },
  removeButtonWrapper: {
    marginRight: 10,
  },
  removeButtonText: {
    color: "white",
  },
  nameText: {
    fontSize: 14,
    fontWeight: "bold",
  },
  flagWrapper: {
    marginLeft: 4,
  },
  groupName: {
    marginTop: 4,
    marginRight: 10,
    fontSize: 11,
    color: Colors.warmGrey,
  },
  removedContainer: {
    backgroundColor: "#ccc",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingVertical: 8,
    paddingHorizontal: 16,
    height: 64,
  },
  textButton: {
    fontSize: 13,
    fontWeight: "bold",
  },
  textButtonOrange: {
    fontSize: 13,
    fontWeight: "bold",
    color: "orange",
  },
  textButtonGray: {
    fontSize: 13,
    fontWeight: "bold",
    color: Colors.warmGrey,
  },
  radio: {
    marginRight: 10,
  },
})
