import { StyleSheet, View, Pressable, Text, Modal } from "react-native"
import { Colors } from "src/constants/Colors"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"

type ProfileSetupProps = {
  visible: boolean
  handleClose: () => void
  goToMigrateTeam: () => void
  goToHomeScreen: () => void
}
export const PopupCustom = ({ visible, handleClose, goToMigrateTeam, goToHomeScreen }: ProfileSetupProps) => {
  const isPCScreen = useCheckPCScreen()
  return (
    <Modal animationType="fade" transparent={true} visible={visible} onRequestClose={handleClose}>
      <View style={styles.centeredView}>
        <View style={styles.modalContainer}>
          <View style={[styles.header]}>
            <Text style={[styles.headerLabel, styles.centerLabel]}>
              {isPCScreen
                ? `らくらく連絡網（旧サービス）の団体で代表者\nの場合、らくらく連絡網＋に団体を移行するこ\nとができます。移行しますか？ `
                : "らくらく連絡網（旧サービス）の団体で代表者の場合、らくらく連絡網＋に団体を移行することができます。移行しますか？ "}
            </Text>
          </View>
          <Pressable style={[styles.footerButton]} onPress={goToMigrateTeam}>
            <Text style={styles.footerButtonText}>団体移行をする</Text>
          </Pressable>
          <Pressable style={[styles.footerButton]} onPress={goToHomeScreen}>
            <Text style={styles.footerButtonText}>後でする</Text>
          </Pressable>
        </View>
      </View>
    </Modal>
  )
}

const styles = StyleSheet.create({
  modalContainer: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 8,
    padding: 25,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  header: {
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    marginBottom: 24,
  },
  centerLabel: {
    flex: 1,
  },
  headerLabel: {
    paddingVertical: 2,
    fontWeight: "bold",
    fontSize: 16,
    textAlign: "center",
  },
  paddingView: {
    paddingVertical: 20,
  },
  footerButton: {
    width: "100%",
    paddingHorizontal: 24,
    paddingVertical: 12,
    height: 50,
    backgroundColor: "#f6f2f0",
    marginBottom: 24,
    borderRadius: 8,
    minWidth: 230,
  },
  footerButtonText: {
    color: Colors.orange,
    textAlign: "center",
    fontWeight: "bold",
    fontSize: 18,
  },
  margin: {
    height: 120,
  },
})
