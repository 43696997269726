import { useCallback, useEffect, useMemo, useState } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { useRefresher } from "src/hooks/useRefresher"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { useContactDetailDataRefresher } from "src/recoil/hooks/refresher/useContactDetailDataRefresher"
import { useFetcher } from "src/hooks/useFetcher"
import { CustomAlert } from "src/utils/CustomAlert"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { useResetter } from "./contact/create/useResetter"
import { ContactFilterValue } from "src/types/contact/ContactFilter"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { deleteContactOutbox } from "src/apis/contact/deleteContactOutbox"
import { updateFavoriteContactOutbox } from "src/apis/contact/updateFavoriteContactOutbox"
import { deleteReservedContact } from "src/apis/contact/deleteReservedContact"
import { unreadThreadCountRequestIdState } from "src/recoil/atoms/contact/unreadThreadCountRequestIdState"
import { contactReplyThreadsRequestIdState } from "src/recoil/atoms/contact/contactReplyThreadsRequestIdState"
import { hasUnreadThreadMessageRequestIdState } from "src/recoil/atoms/contact/hasUnreadThreadMessageRequestIdState"
import { useRecoilState, useRecoilValue, useResetRecoilState } from "recoil"
import { outboxContactFavoriteFlgsCacheState } from "src/recoil/atoms/contact/contactFavoriteFlgsCacheState"
import { wait } from "src/utils/wait"
import { outboxContactsConcatSelectorFamily } from "src/recoil/selectors/contact/outboxContactsConcatSelectorFamily"
import { useFocusEffect } from "@react-navigation/native"
import { outboxContactsRequestIdState } from "src/recoil/atoms/contact/outboxContactsRequestIdState"
import { outboxContactsConcatRequestIdState } from "src/recoil/atoms/contact/outboxContactsConcatRequestIdState"
import { GetInboxContactsSuccess } from "src/apis/contact/getInboxContacts"
import { unreadThreadCountPerContactState } from "src/recoil/atoms/contact/unreadThreadCountPerContactState"
import { useAsyncState } from "src/hooks/useAsyncState"
import { requestDataSelector } from "src/recoil/selectors/auth/requestDataSelector"
import { getUnreadThreadCount } from "src/apis/contact/getUnreadThreadCount"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { organizationRefreshState } from "src/recoil/atoms/organization/organizationRefreshPermissionState"
import { organizationDetailRequestIdState } from "src/recoil/atoms/organization/organizationDetailRequestIdState"
export const useOutboxData = () => {
  const [searchText, setSearchText] = useState("")
  const [filters, setFilters] = useState<ContactFilterValue[]>([])
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = useMemo(() => selectedOrganization?.id, [selectedOrganization])
  const { value: organization } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))
  const { value: myProfile } = useAsyncSelector(myProfileSelectorFamily(organizationId))
  const myMemberId = useMemo(() => myProfile?.memberId, [myProfile])
  const request = useRecoilValue(requestDataSelector)
  const [nextTokens, setNextTokens] = useState<string[]>([])
  const [unreadPerContact, setUnreadContact] = useAsyncState(unreadThreadCountPerContactState)
  useEffect(() => {
    setNextTokens([])
  }, [organizationId, setNextTokens])
  const setSearchTextHelper = useCallback((text: string) => {
    setNextTokens([])
    setSearchText(text)
  }, [])
  const params = useMemo(
    () => ({
      searchText: searchText !== "" ? searchText : undefined,
      filters: filters.length !== 0 ? filters : undefined,
    }),
    [searchText, filters]
  )
  const { value: getContacts, isLoading: isLoadingContacts } = useAsyncSelector(
    outboxContactsConcatSelectorFamily({ ...params, nextTokens })
  )

  const _refreshContacts = useRefresher(outboxContactsRequestIdState)
  const _refreshContactsConcat = useResetRecoilState(outboxContactsConcatRequestIdState)
  const _resetFavoriteFlgsCache = useResetRecoilState(outboxContactFavoriteFlgsCacheState)
  const refreshContactReplyThreads = useRefresher(contactReplyThreadsRequestIdState)
  const refreshUnreadThreadCount = useRefresher(unreadThreadCountRequestIdState)
  const refreshHasUnreadThreadMessage = useRefresher(hasUnreadThreadMessageRequestIdState)
  const refreshOrganizationDetail = useRefresher(organizationDetailRequestIdState)
  const needRefreshOrganization = useRecoilValue(organizationRefreshState)
  useEffect(() => {
    if (needRefreshOrganization) {
      refreshOrganizationDetail()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needRefreshOrganization])
  const refreshContacts = useCallback(() => {
    setNextTokens([])
    _refreshContacts()
    _resetFavoriteFlgsCache()
    _refreshContactsConcat()
    refreshContactReplyThreads()
    refreshUnreadThreadCount()
    refreshHasUnreadThreadMessage()
    setNextTokens([])
    refreshOrganizationDetail()
  }, [
    _refreshContacts,
    _refreshContactsConcat,
    _resetFavoriteFlgsCache,
    refreshContactReplyThreads,
    refreshUnreadThreadCount,
    refreshHasUnreadThreadMessage,
    refreshOrganizationDetail,
  ])

  const refreshFiltersContacts = useCallback(() => {
    setNextTokens([])
    _refreshContactsConcat()
  }, [_refreshContactsConcat])

  const setFiltersHelper = useCallback(
    (filters: ContactFilterValue[]) => {
      setFilters(filters)
      if (!filters.includes("favorite")) {
        refreshFiltersContacts()
      }
    },
    [refreshFiltersContacts]
  )

  useFocusEffect(
    useCallback(() => {
      if (filters.includes("favorite")) {
        refreshContacts()
      }
    }, [filters, refreshContacts])
  )

  const [favoriteFlgsCache, setFavoriteFlgsCache] = useRecoilState(outboxContactFavoriteFlgsCacheState)
  const [contacts, setContacts] = useState<GetInboxContactsSuccess>()
  useEffect(() => {
    if (getContacts) {
      if (filters.includes("favorite") && getContacts.contacts) {
        const newContacts = getContacts.contacts.filter((e) => e.id && favoriteFlgsCache?.[e.id] !== false)
        setContacts({
          contacts: newContacts,
          nextToken: getContacts.nextToken,
        })
      } else {
        setContacts(getContacts)
      }
    }
  }, [getContacts, filters, favoriteFlgsCache])

  const refreshContactDetailData = useContactDetailDataRefresher()
  const requestDataResult = useRequestData()

  const reservedContactIdSet = useMemo(
    () => new Set((contacts?.contacts || []).filter((item) => item.reservedDate != null).map((item) => item.id)),
    [contacts]
  )
  const isReservedContact = useCallback((contactId: string) => reservedContactIdSet.has(contactId), [reservedContactIdSet])

  const { fetch: execDeleteContact } = useFetcher(
    useCallback(
      async (contactId: string) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accessToken } = requestDataResult.content
        if (accessToken == null || myMemberId == null) {
          return
        }
        const isReserved = isReservedContact(contactId)
        const isConfirmed = await CustomAlert.confirm(
          "確認",
          "この連絡を完全に削除しますか？\n削除後は復元できません。\n※送信予約も取り消されます。"
        )
        if (!isConfirmed) {
          return
        }
        const result = isReserved
          ? await deleteReservedContact({ accessToken, reservedContactId: contactId })
          : await deleteContactOutbox({ accessToken, myMemberId, contactId })

        if (result.isOk) {
          await CustomAlert.alert("完了", isReserved ? "送信予約を取り消しました。" : "連絡を削除しました。")
          await wait(2000)
          refreshContacts()
        } else {
          await CustomAlert.alert("エラー", result.error.message)
        }
      },
      [requestDataResult, refreshContacts, myMemberId, isReservedContact]
    )
  )

  const { fetch: execUpdateFavoriteContact } = useFetcher(
    useCallback(
      async ({ contactId, isFavorite }: { contactId: string; isFavorite: boolean }) => {
        if (!requestDataResult.isOk) {
          return
        }
        const { accountId, accessToken } = requestDataResult.content
        if (accountId == null || accessToken == null || myMemberId == null) {
          return
        }
        const result = await updateFavoriteContactOutbox({ accessToken, myMemberId, contactId, isFavorite })
        if (!result.isOk) {
          CustomAlert.alert("エラー", result.error.message)
          return
        }
        setFavoriteFlgsCache((prev) => ({ ...prev, [contactId]: isFavorite }))
        if (!isFavorite && result.isOk && filters.includes("favorite") && contacts && contacts.contacts) {
          const newContacts = contacts.contacts.filter((e: { id: string }) => e.id && e.id !== contactId)
          setContacts({
            contacts: newContacts,
            nextToken: contacts.nextToken,
          })
        }
      },
      [requestDataResult, myMemberId, setFavoriteFlgsCache, filters, contacts]
    )
  )

  const resetCreateNewContactScreenData = useResetter()
  useEffect(() => {
    const contactIds = contacts?.contacts.map((el) => el.id)
    if (request) getUnreadThreadCount({ ...request, contactIds }).then((data) => setUnreadContact(data.buckets))
  }, [request, contacts, setUnreadContact])
  return {
    searchText,
    setSearchText: setSearchTextHelper,
    filters,
    setFilters: setFiltersHelper,
    contacts,
    isLoadingContacts,
    refreshContacts,
    _refreshContacts,
    organizationId,
    refreshContactDetailData,
    execDeleteContact,
    unreadPerContact,
    execUpdateFavoriteContact,
    resetCreateNewContactScreenData,
    isReservedContact,
    favoriteFlgsCache,
    setNextTokens,
    organization,
  }
}
