import React from "react"

function ArrowIcon(props: any) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={props.width} height={props.height} fill="#F0830E" {...props}>
      <path d="M12 6.586l-8.707 8.707 1.414 1.414L12 9.414l7.293 7.293 1.414-1.414L12 6.586z"></path>
    </svg>
  )
}

export default ArrowIcon
