import { selector } from "recoil"
import { MigrationModel } from "src/types/contact/MigrationModel"
import { migrationIdState } from "src/recoil/atoms/contact/create/migrationIdState"
import { migrationSelectorFamily } from "src/recoil/selectors/contact/migrationSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"

export const migrationSelector = selector<MigrationModel | undefined>({
  key: "selectors/contact/migrationSelector",
  get: async ({ get }) => {
    const migrationId = get(migrationIdState)
    const organization = get(selectedMyOrganizationSelector)
    if (migrationId == null || organization == null) {
      return
    }
    const migration = get(migrationSelectorFamily({ migrationId, organizationId: organization.id }))
    return migration
  },
})
