import { atom } from "recoil"
import { TeamList } from "../../../apis/migration/getMigrationTeams"

export const selectTeamStateAdmin = atom<TeamList>({
  key: "atoms/migration/selectTeamStateAdmin",
  default: undefined,
})

export const selectTeamStateMember = atom<TeamList>({
  key: "atoms/migration/selectTeamStateMember",
  default: undefined,
})
