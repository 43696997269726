import { Result } from "src/utils/Result"
import { createThreadMessage } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type PostContactReplyThreadMessageParams = {
  accessToken: string
  replyThreadId: string
  myMemberId: string
  message: string
}

type PostContactReplyThreadMessageSuccess = unknown

type PostContactReplyThreadMessageFailure = {
  message: string
}

export const postContactReplyThreadMessage = async (params: PostContactReplyThreadMessageParams): Promise<Result<PostContactReplyThreadMessageSuccess, PostContactReplyThreadMessageFailure>> => {
  const {accessToken, replyThreadId, myMemberId, message} = params

  return execApi(
    () => createThreadMessage(accessToken, {
      input: {
        threadId: replyThreadId,
        messageSenderId: myMemberId,
        messageText: message
      },
      appInfo
    }),
    res => res.createThreadMessage.id
  )
}
