import { Result } from "src/utils/Result";
import { getOrCreateTempMatchingOfferId } from "../../aws/customAPI";

type Params = {
  accessToken: string
  organizationId: string
}

type Success = string

type Failure = {
  message: string
}

export const getTempMatchingId = ({accessToken, organizationId}: Params): Promise<Result<Success, Failure>> => {
  return getOrCreateTempMatchingOfferId(accessToken, {
    input: {
      teamId: organizationId
    }
  }).then(res => {
    if (res.data?.getOrCreateTempMatchingOfferId.id) {
      return Result.Ok(res.data.getOrCreateTempMatchingOfferId.id)
    } else {
      return Result.Error<Failure>({message: "募集作成の準備に失敗しました。"})
    }
  }).catch(() => {
    return Result.Error<Failure>({message: "募集作成の準備に失敗しました。"})
  })
}