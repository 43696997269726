import { StyleSheet, View } from "react-native"
import { Colors } from "src/constants/Colors"

type Props = {
  isChecked: boolean
}

export const RadioView = ({ isChecked }: Props) => (
  <View style={[styles.outer, isChecked ? styles.outerWithCheck : styles.outerWithUncheck]}>
    {isChecked ? <View style={styles.inner} /> : null}
  </View>
)

const size = 20
const halfSize = size / 2
const inset = 6
const innerSize = size - inset
const halfInnerSize = innerSize / 2

const styles = StyleSheet.create({
  outer: {
    justifyContent: "center",
    alignItems: "center",
    width: size,
    height: size,
    borderRadius: halfSize,
    borderWidth: 1,
  },
  outerWithCheck: {
    borderColor: Colors.orange,
    backgroundColor: Colors.pale,
  },
  outerWithUncheck: {
    borderColor: Colors.silver,
    backgroundColor: Colors.white7,
  },
  inner: {
    width: innerSize,
    height: innerSize,
    borderRadius: halfInnerSize,
    backgroundColor: Colors.orange,
  },
})
