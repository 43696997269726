import { Result } from "src/utils/Result"
import { getInvitationCodeByVolatileId as getInvitationCodeByVolatileIdApi } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type GetInvitationCodeByVolatileIdParams = {
  accessToken: string
  volatileId: string
}

type GetInvitationCodeByVolatileIdSuccess = {
  invitationCode: string
}

type GetInvitationCodeByVolatileIdFailure = {
  message: string
}

export const getInvitationCodeByVolatileId = async (params: GetInvitationCodeByVolatileIdParams): Promise<Result<GetInvitationCodeByVolatileIdSuccess, GetInvitationCodeByVolatileIdFailure>> => {
  return execApi(
    () => getInvitationCodeByVolatileIdApi(params.accessToken, {
      input: {
        volatileInvitationId: params.volatileId
      },
      appInfo
    }),
    res => ({ invitationCode: res.getInvitationCodeByVolatileId.invitationCode }),
    {
      identifiers: ["getInvitationCodeByVolatileId", "getInvitationCodeByVolatileIdApi"]
    }
  )
}
