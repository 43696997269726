import { StyleSheet } from "react-native"
import { Colors } from "src/constants/Colors"

export const styles = StyleSheet.create({
  iconContainer: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
    width: 36,
    height: 24,
  },
  logo: {
    width: 150,
    height: 18,
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    paddingVertical: 16,
    marginVertical: 16,
  },
  icon: {
    width: 24,
    height: 24,
  },
  link: {
    padding: 0,
    height: 32,
  },
  linkLabel: {
    fontSize: 12,
    textDecorationLine: "underline",
  },
  menuLabel: {
    fontWeight: "bold",
    color: Colors.black,
  },
  labelContainer: {
    paddingHorizontal: 10,
    paddingBottom: 14,
  },
  teamContainer: {
    paddingVertical: 10,
    // paddingHorizontal: 8,
    borderTopColor: "rgb(227, 227, 227)",
    borderTopWidth: 1,
    borderBottomColor: "rgb(227, 227, 227)",
    borderBottomWidth: 1,
    color: "rgb(141, 141, 141)",
  },
  links: {
    paddingVertical: 16,
    paddingHorizontal: 8,
    borderTopColor: "rgb(227, 227, 227)",
    borderTopWidth: 1,
    color: "rgb(141, 141, 141)",
  },
  subScreenContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "center",
    flexDirection: "column",
    paddingTop: 16,
    paddingBottom: 16,
    paddingLeft: 32,
  },
  button: {
    paddingHorizontal: 6,
    paddingVertical: 8,
    width: "100%",
    alignItems: "center",
    alignContent: "center",
    elevation: 5,
    flexDirection: "row",
    gap: 35,
  },
  mailGroupContainer: {
    marginHorizontal: 10,
    marginBottom: 4,
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  mailIcon: {
    width: 24,
    height: 20,
    justifyContent: "center",
  },
  teamOption: {
    paddingVertical: 2,
    paddingHorizontal: 4,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  teamOptionLabel: {
    flexDirection: "row",
    alignItems: "center",
    flex: 1,
    textAlign: "left",
    paddingHorizontal: 8,
    overflow: "hidden",
  },
  teamOptionImage: {
    width: 24,
    height: 24,
    borderRadius: 12,
  },
  teamOptionIcon: {
    width: 10,
    height: 10,
  },
  pcGroupContainer: {
    marginHorizontal: 10,
    paddingVertical: 8,
    paddingHorizontal: 8,
  },
  controlButton: {
    paddingVertical: 8,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingRight: 8,
  },
  pcHeader: {
    flexDirection: "row",
    paddingHorizontal: 6,
    paddingVertical: 8,
    width: "100%",
    alignItems: "center",
    elevation: 5,
    gap: 24,
  },
  settingPcHeader: {
    flexDirection: "row",
    paddingHorizontal: 6,
    paddingVertical: 8,
    width: "100%",
    alignItems: "center",
    elevation: 5,
    gap: 36,
  },
  pcHeaderTitle: {
    paddingHorizontal: 4,
    fontWeight: "bold",
  },
})
