import { memo } from "react"
import { Svg, Path } from "react-native-svg"

export const GuideIcon = memo(() => (
  <Svg width="18" height="14" viewBox="0 0 18 14" fill="none">
    <Path
      d="M16.3636 0.409091C15.4595 0.122727 14.4573 0 13.5 0C11.9045 0 10.1864 0.327273 9 1.22727C7.81364 0.327273 6.09545 0 4.5 0C2.90455 0 1.18636 0.327273 0 1.22727V13.2136C0 13.4182 0.204545 13.6227 0.409091 13.6227C0.490909 13.6227 0.531818 13.5818 0.613636 13.5818C1.71818 13.05 3.31364 12.6818 4.5 12.6818C6.09545 12.6818 7.81364 13.0091 9 13.9091C10.1045 13.2136 12.1091 12.6818 13.5 12.6818C14.85 12.6818 16.2409 12.9273 17.3864 13.5409C17.4682 13.5818 17.5091 13.5818 17.5909 13.5818C17.7955 13.5818 18 13.3773 18 13.1727V1.22727C17.5091 0.859091 16.9773 0.613636 16.3636 0.409091ZM16.3636 11.4545C15.4636 11.1682 14.4818 11.0455 13.5 11.0455C12.1091 11.0455 10.1045 11.5773 9 12.2727V2.86364C10.1045 2.16818 12.1091 1.63636 13.5 1.63636C14.4818 1.63636 15.4636 1.75909 16.3636 2.04545V11.4545Z"
      fill={"#F2CAA0"}
    />
  </Svg>
))
