import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useRecoilState, useRecoilValue } from "recoil"
import { createMatchingOffer } from "src/apis/matching/createMatchingOffer"
import { createMatchingOfferDraft } from "src/apis/matching/createMatchingOfferDraft"
import { getTempMatchingId } from "src/apis/matching/getTempMatchingId"
import { deleteMatchingOfferDraft } from "src/apis/matching/matchingOfferDraft"
import { getMB } from "src/components/parts/contactNetworkTab/ContactDetailEditor"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { createMatchingOfferModeState } from "src/recoil/atoms/matching/createOrEdit/createMatchingOfferModeState"
import {
  createOrEditMatchingOfferState,
  createOrEditParams,
} from "src/recoil/atoms/matching/createOrEdit/createOrEditMatchingOfferState"
import { newMyOffersState } from "src/recoil/atoms/matching/createOrEdit/newMyOffersState"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { AttachmentItemPropsMatching } from "src/screens/matching/OfferCreate"
import { Result } from "src/utils/Result"
import { dateComparison } from "src/utils/useDateTime"
import { isValidUrl, validateNumbers } from "src/utils/validate"
import { getMatchingOfferDetails } from "../../../apis/matching/getMatchingOfferDetails"
import { updateMatchingOffer } from "../../../apis/matching/updateMatchingOffer"
import { updateMatchingOfferDraft } from "../../../apis/matching/updateMatchingOfferDraft"
import { ageOptions } from "../../../constants/matchingSearchDate"
import { useAsyncSelector } from "../../../hooks/useAsyncSelector"
import { useAsyncState } from "../../../hooks/useAsyncState"
import { useFetcher } from "../../../hooks/useFetcher"
import {
  MatchingOfferDraftModel,
  MatchingOfferModel,
  UpdateMatchingOfferModel,
} from "../../../types/matching/matchingOfferModel"
import { CustomAlert } from "../../../utils/CustomAlert"
import { citiesSelectorFamily } from "../../selectors/account/citiesSelectorFamily"
import { prefecturesSelector } from "../../selectors/account/prefecturesSelector"
import { useAuthorizationData } from "../authorization/useAuthorizationData"
import { imageSize, useResource } from "../resource/useResource"
import { matchingOfferDraftSelectorFamily } from "src/recoil/selectors/matching/matchingOfferDraftSelectorFamily"
import { matchingOfferDetailRequestIdState } from "src/recoil/atoms/matching/matchingOfferDetailRequestIdState"
import { useRefresher } from "src/hooks/useRefresher"
import { createdAtState } from "src/recoil/atoms/matching/createdAtState"
type Params = {
  onClose: (type?: string) => void
  offerId?: string
  draftId?: string
}

const maxFileCount = imageSize.attachment.maxCount
const maxEachFileSizeInMB = imageSize.attachment.maxSizeInMB
const maxTotalFileSizeInMB = maxEachFileSizeInMB * maxFileCount

export const useCreateOrEditMatchingOfferData = ({ onClose, offerId, draftId }: Params) => {
  const { value: selectedOrganization } = useAsyncSelector(selectedMyOrganizationSelector)
  const organizationId = selectedOrganization?.id
  const { value: organizationDetail } = useAsyncSelector(organizationDetailSelectorFamily(organizationId))
  const [token] = useAsyncState(tokensState)
  const [myCreateData, setMyCreateData] = useAsyncState<createOrEditParams>(createOrEditMatchingOfferState)
  const { value: draftData } = useAsyncSelector(matchingOfferDraftSelectorFamily(draftId ?? ""))
  const { value: prefectures } = useAsyncSelector(prefecturesSelector)
  const [createdAt, setCreated] = useAsyncState<Date | string>(createdAtState)
  const prefectureOptions = useMemo(
    () => prefectures?.map((prefecture) => ({ value: prefecture.id, label: prefecture.label })),
    [prefectures]
  )
  const [isLoading, setLoading] = useState(false)
  const _refresh = useRefresher(matchingOfferDetailRequestIdState)
  const { value: cities } = useAsyncSelector(citiesSelectorFamily(myCreateData?.prefecture?.id?.toString()))
  const cityOptions = useMemo(() => cities?.map((city) => ({ value: city.id, label: city.label })), [cities])
  const [isFirstSetDraff, setFirstDraff] = useState(false)
  useEffect(() => {
    if (draftId && draftData && !isFirstSetDraff) {
      const startPath = draftData?.startTime?.split(":") || ""
      const endPath = draftData?.endTime?.split(":") || ""
      const startTime = startPath.length === 2 ? new Date() : undefined
      if (startTime) {
        startTime.setHours(Number(startPath[0]))
        startTime.setMinutes(Number(startPath[1]))
      }
      const endTime = endPath.length === 2 ? new Date() : undefined
      if (endTime) {
        endTime.setHours(Number(endPath[0]))
        endTime.setMinutes(Number(endPath[1]))
      }
      const newVaule: createOrEditParams = {
        body: draftData?.body || "",
        endTime: endTime,
        startTime: startTime,
        title: draftData?.title || "",
        place: draftData?.place || "",
        placeURL: draftData?.placeURL || "",
        expense: draftData?.expense || "",
        expensePurpose: draftData?.expensePurpose || "",
        offerCount: (draftData?.offerCount ?? undefined)?.toString(),
        date: draftData?.date ? new Date(draftData.date) : undefined,
        publishEndDatetime: draftData?.publishEndDatetime ? new Date(draftData.publishEndDatetime) : undefined,
        prefecture: {
          id: Number(draftData?.prefecture?.id),
          label: draftData?.prefecture?.label || "",
        },
        city: draftData?.city?.id
          ? {
              id: draftData?.city?.id || "",
              label: draftData?.city?.label || "",
            }
          : undefined,
        ageFrom: draftData?.ageFrom
          ? {
              id: draftData?.ageFrom?.toString() || "",
              label: draftData?.ageFrom?.toString() || "",
            }
          : undefined,
        ageTo: draftData?.ageTo
          ? {
              id: draftData?.ageTo?.toString() || "",
              label: draftData?.ageTo?.toString() || "",
            }
          : undefined,
      }
      setMyCreateData(newVaule)
      setFirstDraff(true)
    }
  }, [draftId, draftData, prefectureOptions, setMyCreateData, cityOptions, isFirstSetDraff])

  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const mode = useRecoilValue(createMatchingOfferModeState)
  const [attachmentThumbnailUploaders, setAttachmentThumbnailUploaders] = useState<
    Readonly<(() => Promise<Result<undefined, { message: string }>>)[]>
  >([])
  // const refreshMatchingOfferDrafts = useRefresher(listMatchingOfferDraftRequestIdState)

  const updateLocation = useCallback(async () => {
    const accessToken = token?.accessToken ?? ""
    if (offerId) {
      try {
        const result = (await getMatchingOfferDetails({
          accessToken,
          offerId: offerId,
          type: "myOffer",
          organizationMemberId: organizationId ?? "",
        })) as any
        setMyCreateData((prevData) => ({
          ...prevData,
          prefecture: result?.content.prefecture,
          city: result?.content.city,
        }))
      } catch (e) {
        console.log(e)
      }
    }
  }, [token, offerId, organizationId, setMyCreateData])
  const findItemByValue = (arr?: any[], value?: string) => {
    return arr?.find((item) => item.value === value)
  }
  const onChangePrefecture = (id?: string) => {
    const next = findItemByValue(prefectureOptions, id)
    if (myCreateData) {
      setMyCreateData({
        ...myCreateData,
        prefecture: next ? { id: next.value, label: next.label } : undefined,
        city: undefined,
      })
    }
  }

  const onChangeSelect = useCallback(
    (field: string, id?: string) => {
      const next = findItemByValue(ageOptions, id)
      setMyCreateData((prevData) => ({
        ...prevData,
        [field]: next ? { id: next.value, label: next.label } : undefined,
      }))
    },
    [setMyCreateData]
  )

  const onChangeCity = useCallback(
    (id?: string) => {
      const next = findItemByValue(cityOptions, id)
      setMyCreateData((prevData) => ({
        ...prevData,
        city: next ? { id: next.value, label: next.label } : undefined,
      }))
    },
    [setMyCreateData, cityOptions]
  )
  const onChangeDate = useCallback(
    (next: Date) => {
      setMyCreateData((prevData) => ({
        ...prevData,
        date: next,
      }))
    },
    [setMyCreateData]
  )
  const onTimeChange = useCallback(
    (next: Date | undefined, field: keyof typeof myCreateData) => {
      setMyCreateData((prevData) => ({
        ...prevData,
        [field]: next,
      }))
    },
    [setMyCreateData]
  )

  const onChangePublishEndDatetime = useCallback(
    (next: Date) => {
      setMyCreateData((prevData) => ({
        ...prevData,
        publishEndDatetime: next,
      }))
    },
    [setMyCreateData]
  )
  const onChangeText = useCallback(
    (next: string | undefined, field: keyof createOrEditParams) => {
      setMyCreateData((prevData) => ({
        ...prevData,
        [field]: next ?? "",
      }))
    },
    [setMyCreateData]
  )
  const [costDisabled, setCostDisabled] = useState(false)
  const [undecided, setUndecided] = useState(false)
  const onClickUndecided = () => {
    setUndecided(!undecided)
    setAllDay(false)
    setMyCreateData((prevData) => ({
      ...prevData,
      date: undefined,
      startTime: undefined,
      endTime: undefined,
    }))
  }

  const [allDay, setAllDay] = useState(false)
  const onClickAllDay = () => {
    setAllDay(!allDay)
    setUndecided(false)
  }

  const [costUndecided, setCostUndecided] = useState(false)
  const onClickCostUndecided = () => {
    setCostUndecided(!costUndecided)
    setCostDisabled(!costDisabled)
    setMyCreateData((prevData) => ({
      ...prevData,
      expense: "",
    }))
  }

  const [firstLoad, setFirstload] = useState(false)

  useEffect(() => {
    if (myCreateData?.modeValue === "Edit" && !firstLoad) {
      if (!myCreateData.date && !myCreateData.startTime && !myCreateData.endTime) {
        setUndecided(!undecided)
        setAllDay(false)
      } else if (!myCreateData.startTime && !myCreateData.endTime) {
        setAllDay(!allDay)
        setUndecided(false)
      }

      if (!myCreateData.expense) {
        setCostUndecided(!costUndecided)
        setCostDisabled(!costDisabled)
      }
    }
    setFirstload(true)
  }, [myCreateData, firstLoad, costUndecided, costDisabled, undecided, allDay])

  const [localAttachments, setLocalAttachments] = useState<AttachmentItemPropsMatching[]>([])
  const [localImageURLList, setLocalImageURLList] = useState<
    Readonly<(() => Promise<Result<undefined, { message: string }>>)[]>
  >([])

  const attachmentsCount = useMemo(() => localAttachments.length, [localAttachments])
  const attachmentsSize = useMemo(
    () =>
      getMB(
        localAttachments.reduce((prev, { sizeInB }) => prev + sizeInB, 0),
        true
      ),
    [localAttachments]
  )

  useEffect(() => {
    if (mode === "Edit" && myCreateData?.date === undefined) setUndecided(true)
    if (mode === "Edit" && myCreateData?.expense === "0") {
      setCostUndecided(true)
      setCostDisabled(true)
    }
  }, [mode, myCreateData, setUndecided, setCostUndecided, setCostDisabled])

  const expenseErrorMessage = useMemo(
    () =>
      myCreateData?.expense !== undefined
        ? validateNumbers(myCreateData?.expense.toString())
          ? ""
          : "半角数字で入力してください。"
        : "",
    [myCreateData?.expense]
  )
  const offerCountErrorMessage = useMemo(
    () =>
      myCreateData?.offerCount !== undefined
        ? validateNumbers(myCreateData?.offerCount)
          ? ""
          : "半角数字で入力してください。"
        : "",
    [myCreateData?.offerCount]
  )
  const ageToErrorMessage = useMemo(
    () =>
      myCreateData?.ageFrom && myCreateData?.ageTo && Number(myCreateData?.ageFrom.label) > Number(myCreateData?.ageTo?.label)
        ? "正しく入力してください。"
        : "",
    [myCreateData?.ageFrom, myCreateData?.ageTo]
  )
  const publishEndDatetimeErrorMessage = useMemo(
    () =>
      myCreateData?.publishEndDatetime &&
      myCreateData?.date &&
      dateComparison(myCreateData?.publishEndDatetime, myCreateData?.date)
        ? "募集期限が開催日以降の日付で設定されています。"
        : "",
    [myCreateData?.date, myCreateData?.publishEndDatetime]
  )
  const timeErrorMessage = useMemo(() => {
    if (
      myCreateData?.startTime == undefined ||
      myCreateData?.endTime == undefined ||
      myCreateData?.startTime === "" ||
      myCreateData?.endTime === ""
    )
      return ""

    const startTimeHourMin = new Date(myCreateData?.startTime)
    const startTimeHour = startTimeHourMin.getHours().toString()
    const startTimeMin = startTimeHourMin.getMinutes().toString()

    const endTimeHourMin = new Date(myCreateData?.endTime)
    const endTimeHour = endTimeHourMin.getHours().toString()
    const endTimeMin = endTimeHourMin.getMinutes().toString()

    if (parseInt(startTimeHour, 10) < parseInt(endTimeHour, 10)) {
      return ""
    } else if (parseInt(startTimeMin, 10) < parseInt(endTimeMin, 10)) {
      return ""
    } else {
      return "時刻を正しく入力してください。"
    }
  }, [myCreateData?.startTime, myCreateData?.endTime])

  const placeUrlErrorMessage = useMemo(
    () => (myCreateData?.placeURL ? (isValidUrl(myCreateData?.placeURL) ? "" : "正しいURLを入力してください") : ""),
    [myCreateData?.placeURL]
  )

  const areRequiredFieldsFilled = useMemo(
    () =>
      !!(
        myCreateData?.title &&
        myCreateData.body &&
        myCreateData.prefecture &&
        (myCreateData.expense || costUndecided) &&
        (myCreateData.date || undecided) &&
        myCreateData.publishEndDatetime &&
        !expenseErrorMessage &&
        !offerCountErrorMessage &&
        !ageToErrorMessage &&
        !timeErrorMessage &&
        !publishEndDatetimeErrorMessage &&
        !placeUrlErrorMessage
      ),
    [
      myCreateData,
      expenseErrorMessage,
      offerCountErrorMessage,
      ageToErrorMessage,
      publishEndDatetimeErrorMessage,
      timeErrorMessage,
      costUndecided,
      undecided,
      placeUrlErrorMessage,
    ]
  )

  const { accessToken } = useAuthorizationData()
  const [tempMatchingOfferId, setTempMatchingOfferId] = useState(draftId)
  // const refreshMatchingOfferDraft = useRefresher(listMatchingOfferDraftRequestIdState)
  const [newMyOffersFlg, setNewMyOffersFlg] = useRecoilState(newMyOffersState)
  // const refreshMyMatchingOffers = () => setNewMyOffersFlg(!newMyOffersFlg)

  /**
   * マッチング募集下書き登録,更新
   */
  const requiredMatchingOfferDraft = useMemo<MatchingOfferDraftModel | undefined>(() => {
    if (organizationId === undefined || tempMatchingOfferId === undefined || myCreateData?.title === undefined) {
      return
    }
    return {
      organizationId,
      tempMatchingOfferId,
      title: myCreateData.title,
      body: myCreateData.body,
      date: myCreateData.date ? moment(myCreateData.date).format()?.split("T")[0] : undefined,
      startTime: myCreateData.startTime,
      endTime: myCreateData.endTime,
      prefectureId: myCreateData.prefecture?.id.toString(),
      cityId: myCreateData.city?.id,
      place: myCreateData.place,
      placeURL: myCreateData.placeURL,
      expense: Number(myCreateData.expense),
      expensePurpose: myCreateData.expensePurpose,
      ageFrom: Number(myCreateData.ageFrom?.id),
      ageTo: Number(myCreateData.ageTo?.id),
      offerCount: Number(myCreateData.offerCount),
      publishEndDate: myCreateData.publishEndDatetime
        ? moment(myCreateData.publishEndDatetime).format().split("T")[0]
        : undefined,
    }
  }, [organizationId, tempMatchingOfferId, myCreateData])

  const { fetch: saveAsDraft, isFetching: isSavingDraft } = useFetcher(async (params: MatchingOfferDraftModel) => {
    if (accessToken == null || tempMatchingOfferId == null) return
    const isConfirmed = await CustomAlert.confirm(
      "確認",
      "募集記事の下書きは、他の代表者に共有されます。\n下書きを保存しますか？"
    )
    if (!isConfirmed) {
      setIsSavingDraft(false)
      return
    }
    let newTempMatchingId = ""
    if (!draftId) {
      const res = await getTempMatchingId({ accessToken, organizationId: params.organizationId ?? "" })
      if (res.isOk) {
        newTempMatchingId = res.content
      }
    }
    const [createMatchingOfferDraftResult] = draftId
      ? await Promise.all([updateMatchingOfferDraft({ accessToken, matchingDraftId: draftId, model: params })])
      : await Promise.all([createMatchingOfferDraft({ accessToken, tempMatchingOfferId: newTempMatchingId, model: params })])
    if (!createMatchingOfferDraftResult.isOk) {
      await CustomAlert.alert("エラー", createMatchingOfferDraftResult.error.message)
      return
    }
    // refreshMatchingOfferDraft()
    await CustomAlert.alert("完了", "保存しました。")
    onClose("draff")
    setCurrentPage("create")
    setAllDay(false)
    setUndecided(false)
    setCostUndecided(false)
  })
  const onSaveDraft = useCallback(
    () => requiredMatchingOfferDraft && saveAsDraft(requiredMatchingOfferDraft),
    [saveAsDraft, requiredMatchingOfferDraft]
  )
  const [isSavingAsDraft, setIsSavingDraft] = useState(isSavingDraft)

  /**
   * マッチング募集登録
   */
  const requiredMatchingOffer = useMemo<MatchingOfferModel | undefined>(() => {
    if (
      organizationId === undefined ||
      tempMatchingOfferId === undefined ||
      myCreateData?.title === undefined ||
      myCreateData?.body === undefined ||
      Number.isNaN(myCreateData?.prefecture) ||
      myCreateData?.prefecture === undefined ||
      myCreateData?.expense === undefined ||
      myCreateData?.publishEndDatetime === undefined
    ) {
      return
    }
    return {
      organizationId,
      tempMatchingOfferId,
      title: myCreateData.title,
      body: myCreateData.body,
      date: myCreateData.date ? moment(myCreateData.date).format().split("T")[0] : undefined,
      startTime: myCreateData.startTime,
      endTime: myCreateData.endTime,
      prefectureId: myCreateData.prefecture?.id?.toString(),
      cityId: myCreateData.city?.id,
      place: myCreateData.place,
      placeURL: myCreateData.placeURL,
      expense: Number(myCreateData.expense),
      expensePurpose: myCreateData.expensePurpose,
      ageFrom: Number(myCreateData.ageFrom?.id),
      ageTo: Number(myCreateData.ageTo?.id),
      offerCount: Number(myCreateData.offerCount),
      publishEndDate: moment(myCreateData.publishEndDatetime).format().split("T")[0],
      attachmentFileNames: localAttachments.map((file) => file.fileName),
    }
  }, [organizationId, tempMatchingOfferId, myCreateData, localAttachments])

  const [isSaving, setSaving] = useState(false)

  const pathFileName = (fileName: string) => {
    const fileExtension = fileName.split(".").pop()
    return `image/${fileExtension}`
  }

  const { fetch: save } = useFetcher(async (params: MatchingOfferModel) => {
    setSaving(true)
    if (accessToken == null || tempMatchingOfferId == null) return
    if (attachmentsSize >= maxTotalFileSizeInMB) {
      await CustomAlert.alert("警告", `画像のサイズが上限(${maxTotalFileSizeInMB}MB)を越えています。`)
      setSaving(false)
      return
    }
    let attachments = undefined
    if (localAttachments) {
      const blobs = await Promise.all(
        localAttachments.map(async (el) => {
          const localImage = el.type === "image" ? el.localImage : el.localDocument
          const response = await fetch(localImage?.uri ?? "")
          return response.blob()
        })
      )
      attachments = blobs.map(
        (blob, index) =>
          new File([blob], localAttachments[index].fileName ?? "", {
            type: `${pathFileName(localAttachments[index].fileName)}`,
          })
      )
    }

    const [createMatchingOfferResult] = await Promise.all([
      createMatchingOffer({
        accessToken,
        tempMatchingOfferId,
        model: params,
        attachments: attachments || [],
        teamId: organizationId || "",
      }),
    ])
    if (draftId) {
      await deleteMatchingOfferDraft({ accessToken, draftId: draftId })
    }
    if (!createMatchingOfferResult.isOk) {
      await CustomAlert.alert("エラー", createMatchingOfferResult.error.message)
      setSaving(false)
      return
    }
    setSaving(false)
    await CustomAlert.alert("完了", "募集作成が完了しました。")
    setOpenConfirmModal(false)
    onClose("create")
    setCurrentPage("create")
    setAllDay(false)
    setUndecided(false)
    setCostUndecided(false)
    setTempMatchingOfferId(undefined)
    setLocalAttachments([])
    setLocalImageURLList([])
  })
  const submit = useCallback(() => requiredMatchingOffer && save(requiredMatchingOffer), [save, requiredMatchingOffer])

  /**
   * マッチング募集更新
   */
  const requiredUpdateMatchingOffer = useMemo<UpdateMatchingOfferModel | undefined>(() => {
    if (offerId === undefined) return
    return {
      offerId: offerId,
      title: myCreateData?.title,
      body: myCreateData?.body,
      date: myCreateData?.date ? moment(myCreateData?.date).format().split("T")[0] : undefined,
      startTime: myCreateData?.startTime,
      endTime: myCreateData?.endTime,
      place: myCreateData?.place,
      placeURL: myCreateData?.placeURL,
      expense: Number(myCreateData?.expense),
      expensePurpose: myCreateData?.expensePurpose,
      ageFrom: Number(myCreateData?.ageFrom?.id),
      ageTo: Number(myCreateData?.ageTo?.id),
      offerCount: Number(myCreateData?.offerCount),
      publishEndDate: myCreateData?.publishEndDatetime
        ? moment(myCreateData?.publishEndDatetime).format().split("T")[0]
        : undefined,
      prefectureId: myCreateData?.prefecture?.id?.toString(),
      cityId: myCreateData?.city?.id?.toString(),
    }
  }, [myCreateData, offerId])
  const { fetch: update, isFetching: isUpdating } = useFetcher(async (params: UpdateMatchingOfferModel) => {
    if (accessToken == null) return
    const [updateMatchingOfferResult] = await Promise.all([updateMatchingOffer({ accessToken, model: params })])
    if (!updateMatchingOfferResult.isOk) {
      await CustomAlert.alert("エラー", updateMatchingOfferResult.error.message)
      return
    }
    // refreshMyMatchingOffers()
    setOpenConfirmModal(false)
    // refreshOfferDetails()
    // refreshMyMatchingOffers()
    _refresh()
    onClose("update")
    setCurrentPage("create")
    setAllDay(false)
    setUndecided(false)
    setCostUndecided(false)
  })
  const onUpdate = useCallback(
    () => requiredUpdateMatchingOffer && update(requiredUpdateMatchingOffer),
    [requiredUpdateMatchingOffer, update]
  )

  /**
   * マッチング仮募集ID取得
   */

  const [isLoadingTempId, setLoadingTemp] = useState(false)

  useEffect(() => {
    if (accessToken == null || organizationId == null || tempMatchingOfferId != null) return
    if (draftId) {
      setTempMatchingOfferId(draftId)
    } else {
      if (!isLoadingTempId) {
        setLoadingTemp(true)
        getTempMatchingId({ accessToken, organizationId }).then((res) => {
          if (res.isOk) {
            setTempMatchingOfferId(res.content)
            setLoadingTemp(false)
          } else {
            onClose()
            setLoadingTemp(false)
          }
        })
      }
    }
  }, [accessToken, organizationId, setTempMatchingOfferId, tempMatchingOfferId, draftId, onClose, isLoadingTempId])

  const isDisabled = useMemo(() => !areRequiredFieldsFilled, [areRequiredFieldsFilled])
  const isSaveDraftDisabled = useMemo(() => !myCreateData?.title, [myCreateData])

  const [currentPage, setCurrentPage] = useState("create")
  const onCloseCreateOfferModal = useCallback(async () => {
    const isConfirmed = await CustomAlert.confirm("確認", "入力内容は保存されません。\n画面を移動しますか？")
    if (isConfirmed) {
      setCurrentPage("create")
      setAllDay(false)
      setUndecided(false)
      setCostUndecided(false)
      setLocalAttachments([])
      setLocalImageURLList([])
      onClose()
    }
  }, [setCurrentPage, onClose, setAllDay, setUndecided, setLocalAttachments, setLocalImageURLList])
  const onClickBack = () => setCurrentPage("create")
  const goToConfirmation = () => setCurrentPage("confirmation")

  const { resourceUrl: teamImageUrl, refreshResourceUrl: refreshTeamImageUrl } = useResource({
    type: "matchingTeamImage",
    teamId: organizationId || "",
    size: "thumbnail",
  })
  const resetAllUserInput = () => {
    setMyCreateData({
      title: "",
      body: "",
      place: "",
      placeURL: "",
      expense: "",
      expensePurpose: "",
      activity: undefined,
      offerCount: undefined,
      date: undefined,
      publishEndDatetime: undefined,
      startTime: undefined,
      endTime: undefined,
      prefecture: undefined,
      city: undefined,
      ageFrom: undefined,
      ageTo: undefined,
    })
  }
  return {
    mode,
    prefectureOptions,
    cityOptions,
    attachmentThumbnailUploaders,
    setAttachmentThumbnailUploaders,
    onChangePrefecture,
    onChangeText,
    onChangeSelect,
    onChangeDate,
    onTimeChange,
    resetAllUserInput,
    onChangePublishEndDatetime,
    category: organizationDetail?.category.label,
    activity: organizationDetail?.subCategory.label,
    expenseErrorMessage,
    offerCountErrorMessage,
    isDisabled,
    currentPage,
    onCloseCreateOfferModal,
    onClickBack,
    goToConfirmation,
    undecided,
    onClickUndecided,
    allDay,
    onClickAllDay,
    costDisabled,
    costUndecided,
    onClickCostUndecided,
    submit,
    isSaving,
    onSaveDraft,
    isSavingAsDraft,
    isSaveDraftDisabled,
    onUpdate,
    isUpdating,
    publishEndDatetimeErrorMessage,
    ageToErrorMessage,
    timeErrorMessage,
    createdAt,
    localAttachments,
    setLocalAttachments,
    setLocalImageURLList,
    localImageURLList,
    attachmentsSize,
    attachmentsCount,
    teamImageUrl,
    refreshTeamImageUrl,
    placeUrlErrorMessage,
    updateLocation,
    myCreateData,
    openConfirmModal,
    setOpenConfirmModal,
    onChangeCity,
    setLoading,
    isLoading,
  }
}
