import { memo } from "react"
import { Path, Svg } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
}

export const BackIcon = memo<Props>(({ fill = Colors.black }) => (
  <Svg width="36" height="36" viewBox="0 0 36 36" fill="none">
    <Path d="M23.1225 11.1225L21 9L12 18L21 27L23.1225 24.8775L16.245 18L23.1225 11.1225Z" fill={fill} />
  </Svg>
))
