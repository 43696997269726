import { selectorFamily } from "recoil"
import {
  pendingInvitationsConcatRequestIdState,
  pendingInvitationsRequestIdState,
} from "src/recoil/atoms/organization/pendingInvitationsRequestIdState"
import { listPreTeamMember, listPreTeamMemberByPage } from "src/aws/customAPI"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { appInfo } from "src/constants/AppInfo"
import { execApi } from "src/apis/execApi"
import { myProfileSelectorFamily } from "./myProfile/myProfileSelectorFamily"

export type PendingMember = {
  id: string
  nickname: string
}

type PendingInvitationsData = {
  pendingMembers: PendingMember[]
  nextToken?: string
}

export const pendingInvitationsSelectorFamily = selectorFamily<
  PendingInvitationsData | undefined,
  { organizationId: string; isGetAll: boolean; nextToken?: string }
>({
  key: "selectors/organization/pendingInvitationsSelectorFamily",
  get:
    ({ organizationId, isGetAll, nextToken }) =>
    async ({ get }) => {
      get(pendingInvitationsRequestIdState)
      const myProfile = get(myProfileSelectorFamily(organizationId))
      if (myProfile?.memberType !== "Representative") {
        return {
          pendingMembers: [],
          nextToken: undefined,
        }
      }
      const tokens = get(tokensState)
      if (tokens == null) {
        return
      }

      const result = await execApi(
        () =>
          isGetAll
            ? listPreTeamMember(tokens.accessToken, {
                teamId: organizationId,
                appInfo,
              })
            : listPreTeamMemberByPage(tokens.accessToken, {
                teamId: organizationId,
                nextToken: nextToken,
                appInfo,
              }),
        (res) => {
          return {
            pendingMembers: res.getTeam.preTeamMembers.items.map((ptm) => ({
              id: ptm.id,
              nickname: ptm.nickname,
            })),
            nextToken: res.getTeam.preTeamMembers.nextToken,
          }
        },
        {
          identifiers: ["", "listPreTeamMember"],
        }
      )

      return {
        pendingMembers: result.isOk ? result.content.pendingMembers : [],
        nextToken: result.isOk ? result.content.nextToken : undefined,
      }
    },
})

export const pendingInvitationsConcatSelectorFamily = selectorFamily<
  { pendingMembers: PendingMember[]; nextToken?: string } | undefined,
  { organizationId: string; isGetAll: false; nextTokens: string[] }
>({
  key: "selectors/organization/pendingInvitationsConcatSelectorFamily",
  get:
    ({ nextTokens, ...params }) =>
    async ({ get }) => {
      get(pendingInvitationsConcatRequestIdState)
      const result = [
        get(pendingInvitationsSelectorFamily(params)),
        ...nextTokens.map((nextToken) => get(pendingInvitationsSelectorFamily({ ...params, nextToken }))),
      ]
      return {
        pendingMembers: result.reduce((p, c) => (c != null ? [...p, ...c.pendingMembers] : p), [] as PendingMember[]),
        nextToken: result.length > 0 ? result[result.length - 1]?.nextToken : undefined,
      }
    },
})
