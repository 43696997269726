import { Result } from "src/utils/Result"
import { createAccountDevice, GqlError } from "src/aws/customAPI"
import { DevicePushToken } from "expo-notifications"
import * as Notifications from 'expo-notifications';
import { Platform } from "react-native"
import { DeviceKind } from "src/aws/API"
import * as Device from 'expo-device';
import { crashlyticsWrap } from "src/tags/crashlytics"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type CreateDeviceSuccessResponse = {
  message: string
}

type CreateDeviceFailureResponse = {
  message: string
}

const saveDevice = (access_token: string, device_token: DevicePushToken): Promise<Result<CreateDeviceSuccessResponse, CreateDeviceFailureResponse>> => {

  if (!device_token) {
    return Promise.resolve(Result.Ok<CreateDeviceSuccessResponse>({
      message: 'No DeviceToken',
    }))
  }

  const kind = (Platform.OS === DeviceKind.ios) ? DeviceKind.ios : DeviceKind.android;

  return execApi(
    () => createAccountDevice(access_token, {
      input: {
        kind: kind,
        deviceToken: device_token.data,
      },
      appInfo
    }),
    _ => ({message: "OK"}),
    {
      identifiers: ["saveDevice", "createAccountDevice"],
    }
  )
}

export const registerDevice = async (access_token: string): Promise<Result<CreateDeviceSuccessResponse, CreateDeviceFailureResponse>> => {
  if (Platform.OS === "web") {
    return Result.Error<CreateDeviceFailureResponse>({
      message: 'no device'
    });
  }
  if (Device.isDevice) {
    const { status: existingStatus } = await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
      const { status } = await Notifications.requestPermissionsAsync();
      finalStatus = status;
    }
    if (finalStatus !== 'granted') {
      return Result.Error<CreateDeviceFailureResponse>({
        message: 'Failed to get push token for push notification!'
      });
    }
    const device_token = await Notifications.getDevicePushTokenAsync();

    if (Platform.OS === 'android') {
      await Notifications.setNotificationChannelAsync('default', {
        name: 'default',
        importance: Notifications.AndroidImportance.MAX,
        vibrationPattern: [0, 250, 250, 250],
        lightColor: '#FF231F7C',
      });
    }

    return await saveDevice(access_token, device_token);
  } else {
    return Result.Error<CreateDeviceFailureResponse>({
      message: 'プッシュ通知を使用するには実機が必要です。'
    });
  }

}
