import { memo, useCallback, useEffect, useMemo, useState } from "react"
import { RootStackScreenProps } from "src/types.d"
import {
  FlatList,
  Linking,
  ListRenderItemInfo,
  Modal,
  Platform,
  StyleSheet,
  Text,
  View,
  useWindowDimensions,
} from "react-native"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { BorderedListItem } from "src/components/parts/BorderedListItem"
import { GroupMember } from "src/types/organization/group/GroupMember"
import { MemberListItem } from "src/components/parts/organizationTab/MemberListItem"
import { useRecoilState, useSetRecoilState } from "recoil"
import { ItemLabel } from "src/components/parts/ItemLabel"
import { SearchInput } from "src/components/parts/SearchInput"
import { Colors } from "src/constants/Colors"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { EditButtonForHeader } from "src/components/parts/buttons/EditButtonForHeader"
import { Screens } from "src/constants/Screens"
import { CreateOrEditGroupScreens } from "src/constants/CreateOrEditGroupScreens"
import { RemoveIconButton } from "src/components/parts/buttons/RemoveIconButton"
import { Button } from "src/components/parts/buttons/Button"
import { groupNameSelectorFamily } from "src/recoil/selectors/organization/group/groupNameSelectorFamily"
import { deleteGroup } from "src/apis/organization/deleteGroup"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { CustomAlert } from "src/utils/CustomAlert"
import { useRefresher } from "src/hooks/useRefresher"
import { groupsRequestIdState } from "src/recoil/atoms/organization/group/groupsRequestIdState"
import { organizationMembersRequestIdState } from "src/recoil/atoms/organization/organizationMembersRequestIdState"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { DownloadSVG, QuestionSvg } from "./OrganizationMembers"
import { ruleURL } from "src/constants/links/rule"
import { RemoveIcon } from "src/components/parts/icons/RemoveIcon"
import { guideURL } from "src/constants/links/guide"
import { teamMembersCsvDataSelectorFamily } from "src/recoil/selectors/csv/teamMembersCsvDataSelectorFamily"
import { permissionSelector } from "src/recoil/selectors/account/permissionSelector"
import { groupMembersByNameSelectorFamily } from "src/recoil/selectors/organization/group/groupMembersByNameSelectorFamily"
import { groupMemberListState } from "src/recoil/atoms/organization/group/groupMemberListState"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { groupMemberListRequestIdState } from "src/recoil/atoms/organization/group/groupMemberListIdState"
import { currentGroupMemberPageState } from "src/recoil/atoms/organization/currentMemberPageState"
import { debounce } from "lodash"

export type GroupMembersParams = {
  organizationId: string
  groupId: string
  allowEditGroup: boolean
  allowEditMember: boolean
}

type Props = RootStackScreenProps<typeof Screens.GroupMembers>

export const GroupMembers = memo<Props>(
  ({
    navigation,
    route: {
      params: { organizationId, groupId, allowEditMember },
    },
  }) => {
    const [isConfirmDownLoad, setIsConfirmDownLoad] = useState(false)
    const widthScreen = useWindowDimensions().width
    const heightScreen = useWindowDimensions().height
    const isLargeScreen = useCheckPCScreen()
    const { value: tokens } = useAsyncSelector(tokensState)
    const accessToken = useMemo(() => tokens?.accessToken, [tokens])
    const setRequestId = useSetRecoilState(groupMemberListRequestIdState)
    const { value: groupName } = useAsyncSelector(groupNameSelectorFamily(groupId))
    const [searchText, setSearchText] = useState("")
    const [listMembers, setListMembers] = useRecoilState(groupMemberListState)
    const [page, setPage] = useRecoilState(currentGroupMemberPageState)
    const [debouncedSearchText, setDebouncedSearchText] = useState("")

    const pageSize = useMemo(() => (heightScreen - 184 > 600 ? 20 : 10), [heightScreen]) // Heigh of each row = 60px => 10 row = 600
    const { value: membersValue, isLoading: isLoadingMembers } = useAsyncSelector(
      groupMembersByNameSelectorFamily({
        groupId: groupId,
        getImage: true,
        limit: pageSize,
        teamId: organizationId,
        page: page,
        searchText: debouncedSearchText,
      })
    )
    const totalMember = useMemo(() => membersValue?.total || 0, [membersValue])
    const maxPages = useMemo(() => Math.ceil(totalMember / pageSize), [totalMember, pageSize])
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearchHandler = useCallback(
      debounce((nextSearchText) => {
        setDebouncedSearchText(nextSearchText)
        setPage(1)
      }, 500),
      []
    )
    useEffect(() => {
      if (debouncedSearchText === "") {
        setPage(1)
      }
      debouncedSearchHandler(searchText)
      return () => debouncedSearchHandler.cancel()
    }, [searchText, debouncedSearchText, debouncedSearchHandler, setPage])
    useEffect(() => {
      return () => {
        setListMembers([])
        setPage(1)
        setRequestId((prev) => prev + 1)
      }
    }, [setRequestId, organizationId, groupId, setListMembers, setPage])
    useEffect(() => {
      if (membersValue && membersValue?.data) {
        if (page === 1) {
          setListMembers(membersValue.data)
        } else {
          setListMembers((prevMembers = []) => {
            const newMembers = membersValue.data?.filter(
              (member) => !prevMembers.some((prevMember) => prevMember.id === member.id)
            )
            return [...prevMembers, ...(newMembers ?? [])]
          })
        }
      }
    }, [membersValue?.data, membersValue, setListMembers, page])
    const filteredMembers = listMembers.filter((m) => m.nickname.includes(searchText) || m.nicknameKana?.includes(searchText))
    const loadMoreMembers = useCallback(() => {
      if (page < maxPages) {
        setPage((prevPage) => prevPage + 1)
      }
    }, [page, maxPages, setPage])
    const refreshGroups = useRefresher(groupsRequestIdState)
    const { value: accountPermission } = useAsyncSelector(permissionSelector)

    const refreshOrganizationMembers = useRefresher(organizationMembersRequestIdState)

    const removeGroup = useCallback(async () => {
      if (accessToken == null) return

      const isConfirmed = await CustomAlert.confirm("確認", "本当に削除しますか？\n元に戻せません。")
      if (!isConfirmed) {
        return
      }

      const result = await deleteGroup({ accessToken, groupId })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      refreshGroups()
      refreshOrganizationMembers()
      await CustomAlert.alert("完了", "グループを削除しました。")
      navigation.goBack()
    }, [accessToken, groupId, navigation, refreshGroups, refreshOrganizationMembers])

    const { value: membersCsvData } = useAsyncSelector(teamMembersCsvDataSelectorFamily({ teamId: organizationId, groupId }))
    const csvData = useMemo(
      () =>
        membersCsvData?.map((m) => {
          const memberNumber = "メンバー番号"
          const nickname = "ニックネーム"
          const nicknameKane = "フリガナ"
          const memberType = "権限"
          const groups = "所属グループ"
          const leaderGroups = "グループリーダー"
          return {
            [memberNumber]: m.memberSeq,
            [nickname]: m.nickname,
            [nicknameKane]: m.nicknameKana,
            [memberType]:
              (m.role === "manager" && "代表者") ||
              (m.role === "general" && "一般メンバー") ||
              (m.role === "mailSender" && "連絡メンバー"),
            [groups]: m.groups.join(","),
            [leaderGroups]: m.leaderGroups.join(","),
          }
        }),
      [membersCsvData]
    )
    const csv = useMemo(() => {
      if (!csvData || csvData.length === 0) return ""
      const jsonKeys = Object.keys(csvData[0])
      const headerData = jsonKeys.map((key) => `"${key}"`).join(",")
      const rowData = csvData.map((item) => {
        return jsonKeys
          .map((key) => {
            const validKey = key as keyof typeof item
            let value = item[validKey]
            if (value === null || value === undefined) {
              return `""`
            }
            if (typeof value === "string") {
              value = value.replace(/"/g, '""')
            }
            return `"${value}"`
          })
          .join(",")
      })

      return `${headerData}\n${rowData.join("\n")}`
    }, [csvData])

    const downloadBlob = (content: any, filename: string, contentType: string) => {
      const blob = new Blob(["\uFEFF", content], { type: contentType })
      const url = URL.createObjectURL(blob)
      const pom = document.createElement("a")
      pom.href = url
      pom.setAttribute("download", filename)
      pom.click()
    }
    const goToOrganizationMemberDetail = useCallback(
      (memberId: string, imageUrl?: string) => {
        navigation.navigate(Screens.OrganizationMemberDetail, { organizationId, memberId, allowEditMember, imageUrl })
      },
      [navigation, organizationId, allowEditMember]
    )

    const ListHeader = useMemo(
      () => (
        <View style={styles.headerContainer}>
          <View style={styles.headerTitleContainer}>
            <Text style={styles.headerTitle}>{groupName}</Text>
          </View>
          {totalMember != null ? (
            <View style={styles.itemLabelWrapper}>
              <ItemLabel label={`メンバー ${totalMember}人`} />
            </View>
          ) : null}
          <SearchInput
            style={[isLargeScreen ? { backgroundColor: Colors.white3 } : styles.searchInput]}
            value={searchText}
            onChangeText={(e) => {
              setSearchText(e)
            }}
            placeholder="名前で検索"
          />
        </View>
      ),
      [groupName, searchText, setSearchText, totalMember, isLargeScreen]
    )
    const ListFooter = useMemo(() => {
      return (
        <View style={styles.footerContainer}>
          {!isLoadingMembers && !membersValue?.total && membersCsvData && membersCsvData.length > 0 && (
            <Text style={[{ paddingBottom: 10 }, styles.noRecordText]}>一致するメンバーはいません</Text>
          )}
        </View>
      )
    }, [isLoadingMembers, membersValue, membersCsvData])

    const ListItem = useCallback(
      (listRenderItemInfo: ListRenderItemInfo<GroupMember>) => (
        <BorderedListItem
          index={isLargeScreen ? 2 : listRenderItemInfo.index}
          length={isLargeScreen ? 3 : listMembers?.length ?? 0}
          key={listRenderItemInfo.item.id}
        >
          <Button onPress={() => goToOrganizationMemberDetail(listRenderItemInfo.item.id, listRenderItemInfo.item.imageUrl)}>
            <MemberListItem
              {...listRenderItemInfo.item}
              name={`${listRenderItemInfo.item.nickname} (${listRenderItemInfo.item.memberNumber})`}
              imageUrl={listRenderItemInfo.item.imageUrl}
              isCustomImg
            />
          </Button>
        </BorderedListItem>
      ),
      [listMembers, goToOrganizationMemberDetail, isLargeScreen]
    )

    const renderDownloadCSVButton = () => {
      if (accountPermission?.management?.group?.canCreateGroup && isLargeScreen) {
        return (
          <View
            style={{ paddingVertical: 8, display: "flex", alignItems: "flex-end", paddingHorizontal: (widthScreen - 600) / 2 }}
          >
            <Button
              style={{
                display: "flex",
                flexDirection: "row",
                paddingBottom: 2,
              }}
              onPress={async () => {
                await Linking.openURL(`${guideURL}\\menber_csv_download`)
              }}
            >
              <QuestionSvg></QuestionSvg>
              <Text style={{ color: "#0000EE", fontWeight: "800", marginLeft: 5, textDecorationLine: "underline" }}>
                CSVダウンロードについて
              </Text>
            </Button>
            <Button
              style={{
                display: "flex",
                flexDirection: "row",
              }}
              onPress={() => {
                if (!csvData || csvData.length === 0) {
                  CustomAlert.alert("エラー", "該当メンバーが0件のため出力できません。")
                  return
                }
                setIsConfirmDownLoad(true)
              }}
            >
              <DownloadSVG></DownloadSVG>
              <Text style={{ color: Colors.orange, fontWeight: "600", marginLeft: 5 }}>グループメンバーCSVダウンロード</Text>
            </Button>
          </View>
        )
      }
      return
    }

    return (
      <View
        style={[
          isLargeScreen ? { backgroundColor: Colors.white3 } : styles.container,
          { width: widthScreen, height: heightScreen - 64 },
          Platform.OS === "web" ? {} : { paddingBottom: 30 },
        ]}
      >
        {renderDownloadCSVButton()}
        <FlatList
          style={[isLargeScreen ? { paddingVertical: 5, paddingHorizontal: (widthScreen - 600) / 2 } : {}]}
          data={filteredMembers}
          keyExtractor={(item) => item.id.toString()}
          renderItem={ListItem}
          ListHeaderComponent={ListHeader}
          ListFooterComponent={
            isLoadingMembers ? (
              <View style={[styles.loading]}>
                <LoadingIndicator />
              </View>
            ) : (
              ListFooter
            )
          }
          contentContainerStyle={[isLargeScreen ? { width: 600 } : {}, Platform.OS === "web" ? { height: "100%" } : {}]}
          onEndReached={!isLoadingMembers ? loadMoreMembers : undefined}
        />
        <View style={styles.footerButton}>
          {accountPermission?.management?.group?.canDeleteGroup && (
            <RemoveIconButton title="グループを削除" onPress={removeGroup} />
          )}
        </View>
        <Modal animationType="fade" visible={isConfirmDownLoad} transparent>
          <View style={styles.secondLayer}></View>
          <View style={styles.backgroundModal}>
            <View style={[styles.mainModal, isLargeScreen ? { width: 500 } : { width: 360 }]}>
              <View style={{ display: "flex", flexDirection: "row", marginBottom: 15 }}>
                <Button
                  onPress={() => {
                    setIsConfirmDownLoad(false)
                  }}
                  style={{ paddingVertical: 10, paddingHorizontal: 10 }}
                >
                  <RemoveIcon fill={Colors.orange}></RemoveIcon>
                </Button>
                <Text style={styles.titleModal}>確認</Text>
              </View>
              <Text style={styles.content}>
                {
                  "ダウンロードされる内容には個人情報が含まれますので、取り扱いには十分ご注意下さいますようお願いいたします。\n尚、『らくらく連絡網＋』での個人情報の取り扱いについては利用規約をご確認下さい。"
                }
              </Text>
              <Button
                onPress={async () => {
                  await Linking.openURL(ruleURL)
                }}
              >
                <Text style={styles.terms}>利用規約</Text>
              </Button>
              <View style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                <Button
                  onPress={() => {
                    setIsConfirmDownLoad(false)
                  }}
                  style={styles.buttonCustom}
                >
                  <Text style={[styles.textButton, { color: Colors.orange }]}>キャンセル</Text>
                </Button>
                <Button
                  onPress={() => {
                    downloadBlob(csv, "group_member.csv", "text/csv;charset=utf8bom;")
                    setIsConfirmDownLoad(false)
                  }}
                  style={[styles.buttonCustom, { backgroundColor: Colors.orange }]}
                >
                  <Text style={[styles.textButton, { color: Colors.white3 }]}>OK</Text>
                </Button>
              </View>
            </View>
          </View>
        </Modal>
      </View>
    )
  }
)

export const options: ScreenOptions = ({ navigation, route }) => ({
  ...commonHeaderOptions,
  title: "グループ詳細",
  headerRight: () => {
    const params = route.params as GroupMembersParams
    return params.allowEditGroup ? (
      <EditButtonForHeader
        onPress={() =>
          navigation.navigate(Screens.CreateOrEditGroupStack, {
            screen: CreateOrEditGroupScreens.EditGroupMembers,
            params: route.params,
          })
        }
      />
    ) : null
  },
})

const styles = StyleSheet.create({
  loading: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 10,
  },
  footerButton: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  container: {
    backgroundColor: Colors.white,
  },
  headerContainer: {},
  headerTitleContainer: {
    justifyContent: "center",
    alignItems: "center",
    height: 63,
    marginTop: 15,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: "bold",
  },
  itemLabelWrapper: {
    paddingHorizontal: 15,
    marginBottom: 10,
  },
  searchInput: {
    backgroundColor: Colors.white,
  },
  footerContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: 16,
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  backgroundModal: {
    position: "absolute",
    zIndex: 100,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainModal: {
    backgroundColor: Colors.white3,
    padding: 24,
    borderRadius: 24,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  secondLayer: { backgroundColor: "#f0f0f0", opacity: 0.4, width: "100%", height: "100%" },
  alertTop: {
    backgroundColor: Colors.white3,
    width: 270,
    padding: 16,
    borderTopRightRadius: 20,
    borderTopLeftRadius: 20,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  alertBottom: {
    display: "flex",
    justifyContent: "center",
    borderTopWidth: 0.2,
    borderTopColor: "#f0f0f0",
    textAlign: "center",
    backgroundColor: Colors.white3,
    width: 270,
    height: 44,
    borderBottomRightRadius: 20,
    borderBottomLeftRadius: 20,
    shadowOffset: { width: -1, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 2,
  },
  contentAlert: {
    fontSize: 14,
    fontWeight: "400",
    width: "100%",
    textAlign: "left",
    paddingHorizontal: 2,
  },
  textButton: { fontSize: 18, fontWeight: "800", lineHeight: 25.2, textAlign: "center" },
  buttonCustom: { margin: 5, width: 130, borderRadius: 20, padding: 6, borderColor: Colors.orange, borderWidth: 2 },
  titleModal: {
    fontSize: 18,
    fontWeight: "800",
    width: "100%",
    textAlign: "center",
    paddingRight: 33,
  },
  content: {
    fontSize: 14,
    fontWeight: "400",
    width: "100%",
    textAlign: "left",
    paddingHorizontal: 30,
  },
  terms: {
    color: Colors.orange,
    fontWeight: "800",
    marginVertical: 10,
    textDecorationLine: "underline",
    paddingHorizontal: 30,
  },
  noRecordText: {
    marginTop: 8,
    marginLeft: 16,
    color: Colors.warmGrey,
    fontSize: 16,
  },
})
