import { selector } from "recoil"
import { Item as ContactDetail } from "src/apis/contact/getContactDetail"
import { existingContactIdState } from "src/recoil/atoms/contact/create/existingContactIdState"
import { contactDetailSelectorFamily } from "src/recoil/selectors/contact/contactDetailSelectorFamily"

export const existingContactDetailSelector = selector<ContactDetail | undefined>({
  key: "selectors/contact/existingContactDetailSelector",
  get: async ({ get }) => {
    const existingContactId = get(existingContactIdState)
    if (existingContactId == null) {
      return
    }
    const contact = get(contactDetailSelectorFamily({ contactId: existingContactId, type: "Inbox" }))
    return contact
  },
})
