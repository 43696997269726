import { memo, useMemo } from "react"
import Svg, { Path, Rect } from "react-native-svg"
import { StyleSheet, View } from "react-native"

type Params = {
  size: number
}

export const NoPersonIcon = memo<Params>(({ size }) => {
  const style = useMemo(() => styles(size), [size])
  return (
    <View style={style.container}>
      <Svg width={size} height={size} viewBox="0 0 33 33" fill="none">
        <Rect width="33" height="33" rx="16.5" fill="#EDEDED" />
        <Path
          d="M16.5 20.1475C20.5056 20.1475 23.75 16.7488 23.75 12.5738C23.75 8.38926 20.5056 5 16.5 5C12.4944 5 9.25 8.38926 9.25 12.5738C9.25 16.7488 12.4944 20.1475 16.5 20.1475ZM16.5 21.6393C11.6697 21.6393 2 24.1671 2 29.2131V33H31V29.2131C31 24.1671 21.3303 21.6393 16.5 21.6393Z"
          fill="#CFCFCF"
        />
      </Svg>
    </View>
  )
})

const styles = (size: number) =>
  StyleSheet.create({
    container: {
      width: size,
      height: size,
      borderRadius: size / 2,
      overflow: "hidden",
    },
  })
