import { atom, selector } from "recoil"
import { AccountQuestionModel } from "src/types/user/AccountQuestionModel"
import { getAccountQuestions } from "src/apis/account/getAccountQuestions"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { CustomAlert } from "src/utils/CustomAlert"

export const accountQuestionMasterState = atom<AccountQuestionModel[] | undefined>({
  key: "atoms/account/accountQuestionMasterState",
  default: selector({
    key: "atoms/account/accountQuestionMasterState_default",
    get: async ({ get }) => {
      const tokens = get(tokensState)
      if (tokens == null) {
        return
      }
      const { accessToken } = tokens
      const result = await getAccountQuestions({ accessToken })
      if (!result.isOk) {
        CustomAlert.alert("エラー", result.error.message)
        return
      }
      return result.content
    },
  }),
})
