import { memo } from "react"
import { ClipPath, Defs, G, Path, Rect, Svg } from "react-native-svg"

export const RakurakuKeyVisual = memo(() => {
  return (
    <Svg width="250" height="30" viewBox="0 0 250 30" fill="none">
      <G clip-path="url(#clip0_6015_24636)">
        <Path
          d="M128.05 7.469C129.861 7.469 131.329 6.00075 131.329 4.18958C131.329 2.3784 129.861 0.910156 128.05 0.910156C126.239 0.910156 124.771 2.3784 124.771 4.18958C124.771 6.00075 126.239 7.469 128.05 7.469Z"
          fill="#EA800E"
        />
        <Path
          d="M144.433 26.4768V24.3347H152.238V20.9747H144.433V19.6288H150.75V7.21253H144.433V5.86664H152.238V2.50665H144.433V0.279297H140.978V2.50665H133.177V5.86664H140.978V7.21253H134.661V19.6241H140.978V20.97H133.177V24.33H140.978V26.472H133.277C132.22 25.1072 129.666 20.7804 131.784 12.7383L132.353 10.5725H123.453V14.0273H127.955C127.268 17.7332 127.472 20.7283 128.031 23.0647C126.633 27.2303 123.259 29.8273 123.093 29.9505H127.998C128.595 29.2396 129.211 28.4008 129.784 27.4435C130.552 28.7231 131.244 29.3865 131.325 29.4624L131.822 29.9268H152.854V26.472H144.433V26.4768ZM144.433 16.1741V14.5391H147.295V16.1741H144.433ZM147.295 10.6673V12.3023H144.433V10.6673H147.295ZM140.978 10.6673V12.3023H138.116V10.6673H140.978ZM138.116 16.1741V14.5391H140.978V16.1741H138.116Z"
          fill="black"
        />
        <Path
          d="M73.7966 29.8842C69.4983 29.8842 66.5079 28.4387 66.3705 28.3724L68.1761 24.6854C68.2045 24.6996 71.0764 26.0455 75.0098 25.7327C80.9289 25.2494 83.45 22.2543 83.2842 19.6762C83.1467 17.5816 81.0947 15.4348 76.9054 15.6196C74.062 15.757 68.8774 17.7427 65.1241 20.8326L61.8115 18.8185C61.821 18.7758 62.7072 14.5391 63.1811 6.81445L67.2804 7.06562C67.0813 10.274 66.816 12.8994 66.5695 14.904C69.9911 12.9705 73.8013 11.653 76.7111 11.5156C83.3695 11.1791 87.1181 15.2452 87.3882 19.4108C87.682 23.9319 84.0377 29.1164 75.351 29.8273C74.8202 29.8699 74.3037 29.8889 73.8013 29.8889L73.7966 29.8842Z"
          fill="black"
        />
        <Path
          d="M73.8394 7.88578C76.017 7.88578 77.7823 6.12049 77.7823 3.94289C77.7823 1.76529 76.017 0 73.8394 0C71.6618 0 69.8965 1.76529 69.8965 3.94289C69.8965 6.12049 71.6618 7.88578 73.8394 7.88578Z"
          fill="#EA800E"
        />
        <Path
          d="M12.0142 29.885C7.71589 29.885 4.72555 28.4396 4.58812 28.3732L6.3937 24.6862C6.42213 24.7005 9.294 26.0464 13.2274 25.7336C19.1465 25.2502 21.6677 22.2551 21.5018 19.6771C21.3644 17.5824 19.3124 15.4356 15.123 15.6204C12.2796 15.7579 7.09508 17.7435 3.34175 20.8334L0.0244141 18.8146C0.0338922 18.7719 0.920095 14.5352 1.394 6.81055L5.49328 7.06172C5.29424 10.2701 5.02885 12.8955 4.78242 14.9001C8.20402 12.9666 12.0142 11.6491 14.924 11.5117C21.5824 11.1752 25.3309 15.2413 25.6011 19.4069C25.8949 23.928 22.2506 29.1125 13.5639 29.8234C13.0331 29.866 12.5166 29.885 12.0142 29.885Z"
          fill="black"
        />
        <Path
          d="M12.0523 7.88578C14.2299 7.88578 15.9952 6.12049 15.9952 3.94289C15.9952 1.76529 14.2299 0 12.0523 0C9.87467 0 8.10938 1.76529 8.10938 3.94289C8.10938 6.12049 9.87467 7.88578 12.0523 7.88578Z"
          fill="#EA800E"
        />
        <Path
          d="M52.0069 29.9896C40.1355 20.5778 32.0649 17.1467 31.9844 17.1136V13.3223C32.0649 13.2892 39.9886 9.92443 52.0353 0.640625L54.5423 3.89161C46.9693 9.73013 41.0123 13.2892 37.4058 15.2227C41.0407 17.1847 47.0451 20.8148 54.5565 26.7718L52.0069 29.9896Z"
          fill="black"
        />
        <Path
          d="M113.795 29.9896C101.924 20.5778 93.853 17.1467 93.7725 17.1136V13.3223C93.853 13.2892 101.777 9.92443 113.823 0.640625L116.33 3.89161C108.757 9.73013 102.8 13.2892 99.1939 15.2227C102.829 17.1847 108.833 20.8148 116.345 26.7718L113.795 29.9896Z"
          fill="black"
        />
        <Path
          d="M159.522 26.696C160.76 26.696 161.763 25.6924 161.763 24.4545C161.763 23.2165 160.76 22.2129 159.522 22.2129C158.284 22.2129 157.28 23.2165 157.28 24.4545C157.28 25.6924 158.284 26.696 159.522 26.696Z"
          fill="#EA800E"
        />
        <Path
          d="M168.971 26.696C170.209 26.696 171.213 25.6924 171.213 24.4545C171.213 23.2165 170.209 22.2129 168.971 22.2129C167.733 22.2129 166.729 23.2165 166.729 24.4545C166.729 25.6924 167.733 26.696 168.971 26.696Z"
          fill="black"
        />
        <Path
          d="M188.477 16.3123C188.43 16.2886 184.743 14.3409 181.8 11.6254C183.895 9.11372 185.61 5.99542 186.15 2.24684L186.435 0.275391H174.919L174.469 1.32272C172.559 5.78217 170.317 9.15163 169.668 10.09H173.791C174.132 9.5355 174.521 8.88624 174.928 8.16591C175.459 9.15637 176.108 10.2464 176.838 11.2605C176.909 11.36 176.99 11.4595 177.066 11.5543C173.578 14.7579 169.801 16.4119 169.734 16.4403L170.412 18.0279L171.09 19.6202C171.194 19.5728 171.763 19.3264 172.62 18.8762V29.9513H176.075V28.8708H182.008V29.9513H185.463V18.5681C186.264 19.0468 186.804 19.3311 186.88 19.3738L188.477 16.3076V16.3123ZM177.184 3.73016H182.293C181.696 5.71108 180.672 7.46453 179.468 8.9905C178.303 7.30814 177.369 5.39357 176.895 4.35097C176.99 4.14719 177.089 3.94342 177.184 3.73016ZM176.075 26.07V20.914H182.008V26.07H176.075ZM175.061 17.4592C176.397 16.6062 177.928 15.4925 179.421 14.1229C180.843 15.4451 182.383 16.5872 183.7 17.4592H175.066H175.061Z"
          fill="black"
        />
        <Path
          d="M167.218 14.9996V16.0849H164.171C166.313 13.3504 167.754 10.8861 167.829 10.7487L169.313 8.19434H163.991C166.692 4.59267 168.36 1.55493 169.014 0.275391H165.123C162.493 4.99075 159.2 8.75829 157.797 10.4075L158.489 11.6444H163.195C160.958 14.9759 158.617 17.2601 157.536 18.388L158.181 19.5396H162.517V29.9561H165.972V19.5396H169.739V14.9996H167.213H167.218Z"
          fill="black"
        />
        <Path
          d="M204.698 0.294922V29.9566H208.153V18.3033H210.906V24.4167H216.612V21.6159H213.707V18.3033H218.972V25.1133C218.972 25.9616 218.792 26.1654 218.787 26.1654C218.607 26.3407 217.565 26.336 217.001 26.336H216.721V29.786H216.991C218.418 29.786 220.034 29.7955 221.228 28.606C222.034 27.8051 222.427 26.6583 222.427 25.1086V0.294922H204.703H204.698ZM218.972 3.74969V9.63085H216.451C216.958 8.54087 217.356 7.06229 217.356 5.07662H214.555C214.555 7.87266 213.574 9.17116 213.105 9.63085H208.153V3.74969H218.972ZM208.153 12.4269H212.162V15.5025H208.153V12.4269ZM214.963 15.5025V12.4269H218.972V15.5025H214.963Z"
          fill="black"
        />
        <Path d="M212.569 5.07617H209.769V7.84378H212.569V5.07617Z" fill="black" />
        <Path
          d="M193.884 26.696C195.122 26.696 196.126 25.6924 196.126 24.4545C196.126 23.2165 195.122 22.2129 193.884 22.2129C192.646 22.2129 191.643 23.2165 191.643 24.4545C191.643 25.6924 192.646 26.696 193.884 26.696Z"
          fill="#EA800E"
        />
        <Path
          d="M203.333 26.696C204.571 26.696 205.575 25.6924 205.575 24.4545C205.575 23.2165 204.571 22.2129 203.333 22.2129C202.095 22.2129 201.092 23.2165 201.092 24.4545C201.092 25.6924 202.095 26.696 203.333 26.696Z"
          fill="black"
        />
        <Path
          d="M201.58 14.9996V16.0849H198.533C200.675 13.3504 202.116 10.8861 202.192 10.7487L203.675 8.19434H198.353C201.054 4.59267 202.722 1.55493 203.376 0.275391H199.486C196.855 4.99075 193.562 8.75829 192.159 10.4075L192.851 11.6444H197.557C195.32 14.9759 192.979 17.2601 191.898 18.388L192.543 19.5396H196.879V29.9561H200.334V19.5396H204.101V14.9996H201.576H201.58Z"
          fill="black"
        />
        <Path
          d="M249.976 13.1122H241.313V4.44922H237.313V13.1122H228.65V17.1167H237.313V25.7797H241.313V17.1167H249.976V13.1122Z"
          fill="#EA800E"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_6015_24636">
          <Rect width="250" height="29.9887" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  )
})
