import { Result } from "src/utils/Result";
import { GqlError, updateMatchingMessage as _updateMatchingMessage, listMatchingMessage, createMatchingMessage as _createMatchingMessage } from "src/aws/customAPI";
import { matchingMessageModel } from "../../types/matching/matchingMessageModel";
import {
  ContactReplyThreadType,
  RemovedReplyItem,
  ReplyFromMeItem,
  ReplyFromOthersItem
} from "src/constants/ContactReplyThreadType";

type UpdateMatchingMessageParams = {
  accessToken: string
  threadMessageId: string
  text: string,
}

type UpdateMatchingMessageSuccess = unknown

type UpdateMatchingMessageFailure = {
  message: string
}

export const updateMatchingMessage = async ({
                                              accessToken,
                                              threadMessageId,
                                              text
                                            }: UpdateMatchingMessageParams): Promise<Result<UpdateMatchingMessageSuccess, UpdateMatchingMessageFailure>> => {
  return _updateMatchingMessage(accessToken, {
    input: {
      id: threadMessageId,
      text
    }
  }).then(res => {
    if (res.data?.updateMatchingMessage.id) {
      return Result.Ok(undefined);
    } else {
      return Result.Error<UpdateMatchingMessageFailure>({ message: "メッセージの編集に失敗しました" });
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "メッセージの編集に失敗しました";
    return Result.Error<UpdateMatchingMessageFailure>({
      message: message
    });
  });
};


export type ReplyThread = {
  replyItems: (ReplyFromMeItem | ReplyFromOthersItem | RemovedReplyItem)[]
  nextToken?: string | null
}

type GetListMatchingMessageParams = {
  accessToken: string
  matchingApplicationId: string
  teamId: string
  limit?: number | null
  nextToken?: string | null
}

export type GetListMatchingMessageSuccess = ReplyThread

type GetListMatchingMessageFailure = {
  message: string
}

export const getListMatchingMessage = async ({
                                               accessToken,
                                               matchingApplicationId,
                                               teamId,
                                               limit,
                                               nextToken
                                             }: GetListMatchingMessageParams):
  Promise<Result<GetListMatchingMessageSuccess, GetListMatchingMessageFailure>> => {
  return await listMatchingMessage(accessToken, {
    matchingApplicationId,
    limit,
    nextToken
  }).then(res => {
    if (res.data) {
      return Result.Ok<GetListMatchingMessageSuccess>({
        replyItems: res.data.getMatchingApplication.messagesIncludeDeleted.items.map(message => convertThreadMessage(teamId, message)),
        nextToken: res.data.getMatchingApplication.messagesIncludeDeleted.nextToken

      });
    } else {
      return Result.Error<GetListMatchingMessageFailure>({ message: "マッチングメッセージの取得に失敗しました" });
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "マッチングメッセージの取得に失敗しました";
    return Result.Error<GetListMatchingMessageFailure>({
      message: message
    });
  });
};

const convertThreadMessage = (teamId: string, threadMessage: matchingMessageModel): ReplyFromMeItem | ReplyFromOthersItem | RemovedReplyItem => {
  if (!threadMessage.deleteFlg) {
    const type = threadMessage.messageSenderTeamId === teamId ? ContactReplyThreadType.ReplyFromMe : ContactReplyThreadType.ReplyFromOthers;
    return {
      contactReplyThreadType: type,
      replyThreadMessageId: threadMessage.id,
      messageSenderTeamId: threadMessage.messageSenderTeamId,
      senderName: "",
      message: threadMessage.text || '',
      date: new Date(threadMessage.createdAt),
      isEdited: threadMessage.contentUpdatedAt !== threadMessage.createdAt,
      isRead: threadMessage.unreadFlg === 0,
    };
  } else {
    return {
      contactReplyThreadType: ContactReplyThreadType.RemovedReply,
      messageSenderTeamId: threadMessage.messageSenderTeamId,
      replyThreadMessageId: threadMessage.id,
      senderName: "",
      date: new Date(threadMessage.createdAt)
    };
  }
};

type CreateMatchingMessageParams = {
  accessToken: string;
  matchingApplicationId: string;
  messageSenderTeamId: string;
  text: string;
}

type CreateMatchingMessageSuccess = unknown

type CreateMatchingMessageFailure = {
  message: string
}

export const createMatchingMessage = async (params: CreateMatchingMessageParams): Promise<Result<CreateMatchingMessageSuccess, CreateMatchingMessageFailure>> => {
  const {accessToken, matchingApplicationId, messageSenderTeamId, text} = params

  return await _createMatchingMessage(accessToken, {
    input: {
      matchingApplicationId,
      messageSenderTeamId,
      text
    }
  }).then(res => {
    if (res.data?.createMatchingMessage.id) {
      return Result.Ok(undefined)
    } else {
      return Result.Error<CreateMatchingMessageFailure>({message: "返信の送信に失敗しました"})
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "返信の送信に失敗しました"
    return Result.Error<CreateMatchingMessageFailure>({
      message: message
    })
  })
}

type DeleteThreadMessageParams = {
  accessToken: string
  threadMessageId: string
}

type DeleteThreadMessageSuccess = unknown

type DeleteThreadMessageFailure = {
  message: string
}

export const deleteMatchingMessage = async ({
                                            accessToken,
                                            threadMessageId}: DeleteThreadMessageParams): Promise<Result<DeleteThreadMessageSuccess, DeleteThreadMessageFailure>> => {
  return _updateMatchingMessage(accessToken, {
    input: {
      id: threadMessageId,
      text: null,
      deleteFlg: 1,
    }
  }).then(res => {
    if (res.data?.updateMatchingMessage.id) {
      return Result.Ok(undefined)
    } else {
      return Result.Error<DeleteThreadMessageFailure>({message: "メッセージの取り消しに失敗しました"})
    }
  }).catch((e: GqlError) => {
    const message = (e.errors && e.errors[0].errorInfo?.userMessageWithCode) || "メッセージの取り消しに失敗しました"
    return Result.Error<DeleteThreadMessageFailure>({
      message: message
    })
  })
}