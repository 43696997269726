import type { FC } from "react"
import { Svg, Path } from "react-native-svg"
import { DrawerIconProps } from "src/types/DrawerIconProps"

export const DraftIcon: FC<DrawerIconProps> = ({ color, size = 18.82 }) => {
  return (
    <Svg data-name="グループ 2474" width={size} height={size * 1.006} viewBox="0 0 18.82 18.699">
      <Path
        data-name="パス 935"
        d="m210.46 22.436.434-3.058.436-3.062 2.627 2.626 2.624 2.623-3.06.438z"
        transform="translate(-210.46 -3.737)"
        fill={color}
      />
      <Path
        data-name="パス 936"
        d="m225.525 9.906-7.873 7.875-.873-.873 7.872-7.875-1.751-1.75-7.874 7.872-.876-.874 7.876-7.874-.875-.874-8.751 8.747 5.249 5.25 8.746-8.75z"
        transform="translate(-210.666 -2.594)"
        fill={color}
      />
      <Path
        data-name="パス 937"
        d="M230.3 7.828a1.213 1.213 0 0 0-.035-1.713l-3.5-3.5a1.218 1.218 0 0 0-1.713-.034 1.319 1.319 0 0 0-.087.126l-.02-.021-1.681 1.68 5.249 5.249 1.68-1.68-.022-.023a.789.789 0 0 0 .126-.083"
        transform="translate(-211.818 -2.246)"
        fill={color}
      />
    </Svg>
  )
}

DraftIcon.displayName = "DraftIcon"
