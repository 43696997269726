import { memo } from "react"
import Svg, { Path } from "react-native-svg"

export const LockIcon = memo(() => (
  <Svg width="9" height="10.5" viewBox="0 0 9 10.5">
    <Path
      d={
        "M17 6.25a3 3 0 0 0-3 3v1.5h-1.5v6h9v-6H20v-1.5a3 3 0 0 0-3-3zm0 1.5a1.425 1.425 0 0 1 1.5 1.5v1.5h-3v-1.5a1.425 1.425 0 0 1 1.5-1.5z"
      }
      transform={"translate(-12.5 -6.25)"}
      fill={"#f00e0e"}
    />
  </Svg>
))
