import type { FC } from "react"
import { Path, Svg } from "react-native-svg"
import { DrawerIconProps } from "src/types/DrawerIconProps"

export const TemplateIcon: FC<DrawerIconProps> = ({ color }) => {
  return (
    <Svg width="16.499" height="21.661" viewBox="0 0 16.499 21.661">
      <Path
        data-name="前面オブジェクトで型抜き 4"
        d="M14.531 20.661H.968A.97.97 0 0 1 0 19.695V.968A.97.97 0 0 1 .967 0h9.367l.18.182v3.836a.977.977 0 0 0 .966.97h3.84l.177.177V19.7a.967.967 0 0 1-.966.961zM4.383 14.206a.51.51 0 0 0-.509.509v.275a.51.51 0 0 0 .509.508h6.733a.508.508 0 0 0 .508-.508v-.275a.51.51 0 0 0-.509-.509zm0-2.583a.51.51 0 0 0-.509.509v.274a.51.51 0 0 0 .509.509h6.732a.509.509 0 0 0 .509-.509v-.274a.51.51 0 0 0-.509-.509zm-.024-2.582a.488.488 0 0 0-.485.484v.323a.488.488 0 0 0 .485.485h6.78a.488.488 0 0 0 .485-.485v-.323a.488.488 0 0 0-.485-.484zM15.32 4.988 10.514.182V0h.065a.979.979 0 0 1 .686.282l3.951 3.956a.957.957 0 0 1 .282.681v.068z"
        transform="translate(.5 .5)"
        fill={color}
      />
    </Svg>
  )
}

TemplateIcon.displayName = "TemplateIcon"
