import { OrganizationCategory } from "src/constants/organization/OrganizationCategory"

export type OrganizationSubCategoryMaster = {
  id: string
  label: string
  displayNumber: number
  displayFlg: number
}

export type OrganizationCategoryMaster = {
  [categoryId: string]: {
    subCategories: OrganizationSubCategoryMaster[]
  }
}

export const toCategoryMasterValue = (category: OrganizationCategory): string => {
  return {
    Sports: "1",
    Culture: "2",
    Work: "3",
    Education: "4",
    Friendship: "5",
    Others: "6",
  }[category]
}
