import { selector } from "recoil"
import { templateIdState } from "src/recoil/atoms/contact/create/templateIdState"
import { templateSelectorFamily } from "./templateSelectorFamily"

export const templateSelector = selector({
  key: "selectors/contact/templateSelector",
  get: ({ get }) => {
    const templateId = get(templateIdState)
    if (templateId == null) {
      return
    }
    const template = get(templateSelectorFamily(templateId))
    return template
  },
})
