import { selectorFamily } from "recoil"
import { tokensState } from "../../atoms/authorization/tokensState"
import { getMatchingTeamProfile } from "../../../aws/customAPI"
import { MatchingProfileModel } from "../../../types/matching/matchingProfileModel"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { matchingOtherProfileRequestIdState } from "src/recoil/atoms/matching/matchingOtherProfileRequestIdState"
import { organizationDetailSelectorFamily } from "../organization/organizationDetailSelectorFamily"
import { myProfileSelectorFamily } from "../organization/myProfile/myProfileSelectorFamily"

export const matchingOtherProfileSelectorFamily = selectorFamily<
  MatchingProfileModel | undefined,
  { otherTeamId: string | undefined; myTeamId?: string | undefined }
>({
  key: "selectors/matting/matchingOtherProfileSelectorFamily",
  get:
    ({ otherTeamId, myTeamId }) =>
    async ({ get }) => {
      get(matchingOtherProfileRequestIdState)
      const tokens = get(tokensState)
      if (tokens == null) return undefined
      if (otherTeamId == null) return undefined
      const selectedTeam = get(selectedMyOrganizationSelector)
      if (selectedTeam == null) return undefined
      const teamId = myTeamId ?? selectedTeam.id

      const organizationDetail = get(organizationDetailSelectorFamily(teamId))
      if (organizationDetail?.category.label !== "スポーツ系サークル・チーム") {
        return
      }
      const myProfile = get(myProfileSelectorFamily(selectedTeam.id))
      if (myProfile?.memberType !== "Representative") {
        return
      }

      const mattingProfile = await getMatchingTeamProfile(tokens.accessToken, {
        input: {
          teamId: otherTeamId,
          myTeamId: teamId,
        },
      }).then((res) => res.data?.getMatchingTeamProfile)
      if (mattingProfile == null) return undefined

      const mattingProfileModel: MatchingProfileModel = {
        id: mattingProfile.id,
        ageFrom: mattingProfile.ageFrom,
        ageTo: mattingProfile.ageTo,
        introduction: mattingProfile.introduction,
        teamUrl: mattingProfile.teamUrl,
        name: mattingProfile.name,
        category: mattingProfile.category,
        activity: mattingProfile.activity,
        prefecture: mattingProfile.prefecture,
        city: mattingProfile.city,
        isBlocking: mattingProfile.isBlocking,
      }
      return mattingProfileModel
    },
})
