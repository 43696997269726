export enum OptionValueBottomSheet {
  EDIT = "edit",
  COPY = "copy",
  STOP = "stop",
}

export type OptionBottomSheet = {
  label: string
  value: OptionValueBottomSheet
}

export const ListOptionBottomSheet: OptionBottomSheet[] = [
  {
    label: "募集内容を編集",
    value: OptionValueBottomSheet.EDIT,
  },
  {
    label: "コピーして新規作成",
    value: OptionValueBottomSheet.COPY,
  },
  {
    label: "募集を停止",
    value: OptionValueBottomSheet.STOP,
  },
]

export const StoppedListOptionBottomSheet: OptionBottomSheet[] = [
  {
    label: "コピーして新規作成",
    value: OptionValueBottomSheet.COPY,
  },
]

export const ListActionBackInThread = {
  application: "application",
  myMatching: "myMatching",
  otherMatching: "otherMatching",
  favorite: "favorite",
  detail: "detail",
}
