import { useCallback, useState } from "react"
import { deleteOrganization } from "src/apis/organization/deleteOrganization"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { organizationDetailSelectorFamily } from "src/recoil/selectors/organization/organizationDetailSelectorFamily"
import { CustomAlert } from "src/utils/CustomAlert"
import { useRequestData } from "src/recoil/hooks/useRequestData"
import { useRefresher } from "src/hooks/useRefresher"
import { myFullOrganizationsRequestIdState } from "src/recoil/atoms/organization/myOrganizationsRequestIdState"
import { useFetcher } from "src/hooks/useFetcher"
import { Analytics } from "src/tags/analytics/Analytics"
import { MIGRATE_PLAN_ID } from "src/utils/const"

type Params = {
  id: string
  gotoOrganizations: () => void
}

export const useDeleteOrganizationData = ({ id, gotoOrganizations }: Params) => {
  const { value: organizationDetail } = useAsyncSelector(organizationDetailSelectorFamily(id))

  const [reasonText, setReasonText] = useState("")

  const requestDataResult = useRequestData()

  const refreshMyOrganizations = useRefresher(myFullOrganizationsRequestIdState)

  const { fetch: sendDeleteOrganization, isFetching: isDeleteOrganizationSending } = useFetcher(
    useCallback(async () => {
      if (!requestDataResult.isOk) {
        return
      }
      await Analytics.deleteTeam()
      const { accessToken } = requestDataResult.content
      const isConfirmed = await CustomAlert.confirm(
        "警告",
        "本当に団体（全体）を解約しますか？\n" +
          "・解約は参加メンバーに通知されます。\n" +
          (organizationDetail?.category.id === MIGRATE_PLAN_ID
            ? "・団体マッチングで作成した募集やメッセージがある場合、すべて削除されます。\n"
            : "") +
          "・元に戻すことはできません。"
      )
      if (!isConfirmed) {
        return
      }
      const result = await deleteOrganization({ accessToken, organizationId: id, reasonText })
      if (!result.isOk) {
        await CustomAlert.alert("エラー", result.error.message)
        return
      }
      refreshMyOrganizations()
      await CustomAlert.alert("完了", "団体を解約しました。")
      gotoOrganizations()
    }, [requestDataResult, id, gotoOrganizations, reasonText, refreshMyOrganizations, organizationDetail?.category.id])
  )

  return {
    organizationDetail,
    reasonText,
    setReasonText,
    sendDeleteOrganization,
    isDeleteOrganizationSending,
  }
}
