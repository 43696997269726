import { ComponentProps, memo } from "react"
import { StyleSheet, TextInput, View } from "react-native"
import { SearchIcon } from "src/components/parts/icons/SearchIcon"
import { Colors } from "src/constants/Colors"

type Props = ComponentProps<typeof TextInput>

export const SearchInput = memo<Props>(({ style, placeholder = "検索", ...otherProps }) => {
  return (
    <View style={styles.container}>
      <TextInput
        placeholder={placeholder}
        placeholderTextColor={Colors.warmGrey}
        style={[styles.textInput, style]}
        {...otherProps}
      />
      <View style={styles.icon}>
        <SearchIcon />
      </View>
    </View>
  )
})

SearchInput.displayName = "SearchInput"

const styles = StyleSheet.create({
  container: {
    position: "relative",
  },
  icon: {
    position: "absolute",
    top: 14,
    left: 16,
    width: 24,
    height: 24,
  },
  textInput: {
    height: 46,
    paddingLeft: 48,
    paddingRight: 16,
    paddingVertical: 8,
    backgroundColor: "#fff",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: Colors.white2,
    outlineStyle: "none",
  },
})
