import { deleteAsync } from "./secureStorage/deleteAsync"
import { getAsync } from "./secureStorage/getAsync"
import { setAsync } from "./secureStorage/setAsync"
import { oldRa9Ra9Tokens } from "../recoil/atoms/migration/oldRa9Ra9TokenStates"

const keyPrefix = "oldRa9Ra9tokens"

const oldRa9Ra9TokenType = Object.freeze({
  OldRa9Ra9AccessToken: "oldRa9Ra9_access_token",
  OldRa9Ra9UserId: "oldRa9Ra9_use_id",
} as const)

type oldRa9Ra9TokenType = typeof oldRa9Ra9TokenType[keyof typeof oldRa9Ra9TokenType]

const getOldRa9Ra9Key = (tokenType: oldRa9Ra9TokenType) => `${keyPrefix}.${tokenType}`

const oldRa9Ra9AccessTokenKey = getOldRa9Ra9Key(oldRa9Ra9TokenType.OldRa9Ra9AccessToken)
const oldRa9Ra9UserIdKey = getOldRa9Ra9Key(oldRa9Ra9TokenType.OldRa9Ra9UserId)

export const getOldRa9Ra9KTokens = async () => {
  const [oldRa9Ra9AccessToken, oldRa9Ra9UserId] = await Promise.all([
    getAsync(oldRa9Ra9AccessTokenKey),
    getAsync(oldRa9Ra9UserIdKey),
  ])
  if (oldRa9Ra9AccessToken == null || oldRa9Ra9UserId == null) {
    return
  }
  return {
    oldRa9Ra9AccessToken,
    oldRa9Ra9UserId,
  }
}

export const setOldRa9Ra9KTokens = async (tokens?: oldRa9Ra9Tokens) => {
  if (tokens == null) {
    return Promise.all([deleteAsync(oldRa9Ra9AccessTokenKey), deleteAsync(oldRa9Ra9UserIdKey)])
  } else {
    return Promise.all([
      setAsync(oldRa9Ra9AccessTokenKey, tokens.oldRa9Ra9AccessToken),
      setAsync(oldRa9Ra9UserIdKey, tokens.oldRa9Ra9UserId),
    ])
  }
}
