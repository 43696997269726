import { selector, selectorFamily } from "recoil"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { accountIdState } from "src/recoil/atoms/account/accoutnIdState"
import { TeamMemberOverview, getTeamMembers } from "src/apis/organization/getTeamMembers"
import { teamMembersRequestIdState } from "src/recoil/atoms/organization/teamMembersRequestIdState"
import { oneMember } from "src/recoil/atoms/organization/teamMemberState"
import { accountImages } from "src/aws/customAPI"
import { s3ImageDomain } from "src/utils/s3ImageDomain"

export const teamMembersSelectorFamily = selectorFamily<
  { data: TeamMemberOverview[] | undefined; total: number | undefined; page: number | undefined },
  { organizationId: string; getImage: boolean; joinFlg?: boolean; page: number; searchText: string; limit: number }
>({
  key: "selectors/organization/organizationMembersSelector",
  get:
    ({ organizationId, getImage, joinFlg, page, searchText, limit }) =>
    async ({ get }) => {
      const defaultReturnValue = { data: undefined, total: undefined, page: undefined }
      if (organizationId == null) {
        return defaultReturnValue
      }
      get(teamMembersRequestIdState)
      const tokens = get(tokensState)
      const accessToken = tokens?.accessToken
      if (accessToken == null) {
        return defaultReturnValue
      }

      const accountId = get(accountIdState)
      if (accountId == null) {
        return defaultReturnValue
      }

      const result = await getTeamMembers({ accessToken, organizationId, page: page, limit, excludedIds: [], searchText })
      if (!result.isOk) {
        return defaultReturnValue
      }

      if (!getImage) {
        return {
          data: result.content.data,
          total: result.content.total,
          page: result.content.page,
        }
      }

      const accountIds = result.content.data
        .filter((item) => !!item.accountTeamMembersId)
        .map((item) => item.accountTeamMembersId as string)

      const accountImagesResult = await accountImages(accessToken, {
        input: {
          accountIds,
        },
      })
      const accountImageMap: Record<string, string> =
        accountImagesResult.data?.multiAccountImageGetUrl.images?.reduce((map, ele) => {
          return {
            ...map,
            [ele.accountId]: [ele.url],
          }
        }, {}) ?? {}
      return {
        data:
          result.content.data.map((item) => ({
            id: item.id,
            accountTeamMembersId: item.accountTeamMembersId,
            nickname: item.nickname,
            imageUrl: `${s3ImageDomain}${
              accountImageMap[item.accountTeamMembersId ?? ""] ?? "default/account/image/profile.png"
            }?${new Date().getTime()}`,
            memberNumber: item.memberNumber,
            memberType: item.memberType,
            isAdministrator: item.isAdministrator,
            joinFlg: joinFlg,
            isLeader: item.isLeader,
            isInGroup: item.isInGroup,
            groupIds: item.groupIds,
          })) || [],
        total: result.content.total,
        page: result.content.page,
      }
    },
})

export const selectedMember = selector({
  key: "selectors/organization/organizationMemberSelector",
  get: ({ get }) => get(oneMember),
})
