import { memo, useCallback, useEffect, useMemo } from "react"
import { Linking, Platform, Text, useWindowDimensions } from "react-native"
import { ScrollView, View, StyleSheet } from "react-native"
import { Checkbox } from "src/components/parts/Checkbox"
import { CustomSelect } from "src/components/parts/CustomSelect"
import { CustomTextInput } from "src/components/parts/CustomTextInput"
import { InputLengthCounter } from "src/components/parts/InputLengthCounter"
import { ItemLabel, ItemLabelLongText } from "src/components/parts/ItemLabel"
import { MultilineTextInput } from "src/components/parts/MultilineTextInput"
import { ValidationErrorMessage } from "src/components/parts/ValidationErrorMessage"
import { Button } from "src/components/parts/buttons/Button"
import { ButtonType, TextButton } from "src/components/parts/buttons/TextButton"
import { BackIcon } from "src/components/parts/icons/BackIcon"
import { Colors } from "src/constants/Colors"
import { Screens } from "src/constants/Screens"
import { rulePaidURL } from "src/constants/links/guide"
import { privacypolicyURL } from "src/constants/links/privacypolicy"
import { commonHeaderOptions } from "src/constants/options/commonHeaderOptions"
import { useCheckPCScreen } from "src/hooks/useCheckPCScreen"
import { ScreenOptions } from "src/navigation/RootStack/ScreenOptions"
import { useExtendPaidTeam } from "src/recoil/hooks/organization/useExtendPaidTeam"
import { RootStackScreenProps } from "src/types.d"
import { ModeCompleted } from "./CompletedApplyPaid"
import { RootTabs } from "src/constants/RootTabs"
import { OrganizationManagerScreens } from "src/constants/OrganizationManagerScreens"
import { LoadingIndicator } from "src/components/parts/LoadingIndicator"
import { useSafeAreaInsets } from "react-native-safe-area-context"
import { CustomKeyboardAvoidingView } from "src/components/parts/CustomKeyboardAvoidingView"

type Props = RootStackScreenProps<typeof Screens.ExtendPaidTeam>
export const ExtendPaidTeam = memo<Props>(({ navigation, route }) => {
  const { organizationId, accountId } = route.params
  const insets = useSafeAreaInsets()
  const widthScreen = useWindowDimensions().width
  const heightScreen = useWindowDimensions().height
  const isLargeScreen = useCheckPCScreen()
  const gotoCompletedScreen = (orderId: string, mode: ModeCompleted) => {
    navigation.navigate(Screens.Root, {
      screen: RootTabs.OrganizationManagerTab,
      params: {
        screen: OrganizationManagerScreens.CompletedApplyPaid,
        params: {
          mode: mode,
          orderId: orderId,
          type: "extend",
          teamId: organizationId,
        },
      },
    })
  }

  const {
    setInvoiceAddress,
    changeModeConfirmOrSubmit,
    disabledSubmit,
    invoiceAddressErrorMessage,
    invoiceAddressKanaErrorMessage,
    onChangePlan,
    optionPlan,
    plan,
    planErrorMessage,
    confirmMode,
    invoiceAddress,
    invoiceAddressKana,
    policyStatus,
    remask,
    rulePaidStatus,
    setConfirmMode,
    setInvoiceAddressKana,
    setPolicyStatus,
    setRemask,
    setRulePaidStatus,
    organizationDetail,
    orderDetail,
    formatCreatedDate,
    scrollViewRef,
    convertDate,
    showEndDateExtend,
    isChangePlan,
    planStore,
    isLoadingSubmit,
    setExtendDataRequest,
    // remaskErrorMessage,
    isLoadingView,
    isMigratePlan,
  } = useExtendPaidTeam(organizationId, gotoCompletedScreen)
  const changeModeExtendOrBackDetail = useCallback(() => {
    if (confirmMode) {
      setConfirmMode(!confirmMode)
      setPolicyStatus(false)
      setRulePaidStatus(false)
      return
    }
    setExtendDataRequest({
      applicantRemark: "",
      invoiceAddressee: "",
      invoiceAddresseeKana: "",
      isSubmit: false,
      orderId: "",
      planId: "",
    })
    if (!organizationDetail) return
    navigation.navigate(Screens.Root, {
      screen: RootTabs.OrganizationManagerTab,
      params: {
        screen: OrganizationManagerScreens.OrderDetail,
        params: {
          teamId: organizationId,
          teamMembers: organizationDetail.memberNumber,
          accountId: accountId,
        },
      },
    })
  }, [
    confirmMode,
    navigation,
    setConfirmMode,
    setExtendDataRequest,
    organizationDetail,
    organizationId,
    accountId,
    setPolicyStatus,
    setRulePaidStatus,
  ])

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Button onPress={changeModeExtendOrBackDetail}>
          <BackIcon fill={isLargeScreen ? Colors.orange : Colors.white3}></BackIcon>
        </Button>
      ),
      headerTitle: !confirmMode ? "継続の申込" : "継続申込内容の確認",
    })
  }, [isLargeScreen, navigation, changeModeExtendOrBackDetail, confirmMode])

  const requiredPlanMessage = "プランを選択すると自動表示されます。"

  const showPeriod = useMemo(() => {
    if (!isChangePlan) return `${planStore.period}カ月`
    if (isMigratePlan) return ""
    if (!confirmMode) return plan?.period ? `${plan?.period}カ月` : requiredPlanMessage
    return `${plan?.period}カ月 ${showEndDateExtend(orderDetail.endDate)}`
  }, [plan?.period, orderDetail, confirmMode, planStore, showEndDateExtend, isChangePlan, isMigratePlan])

  const showLimitMember = useMemo(() => {
    if (!plan?.memberLimit && !isChangePlan) return `${planStore.memberLimit}名`
    if (isMigratePlan) return ""
    if (plan?.memberLimit) return `${plan?.memberLimit}名`
    return requiredPlanMessage
  }, [plan, planStore, isChangePlan, isMigratePlan])

  const showAmount = useMemo(() => {
    if (!plan?.amount && !isChangePlan)
      return `${(planStore.amount + (planStore.amount * (plan?.taxRate ?? 0)) / 100).toLocaleString()}円`
    if (isMigratePlan) return ""
    if (plan?.amount) return `${(plan?.amount + (plan?.amount * (plan?.taxRate ?? 0)) / 100).toLocaleString()}円`
    return requiredPlanMessage
  }, [plan, planStore, isChangePlan, isMigratePlan])

  if (isLoadingView)
    return (
      <View style={[styles.fullHeight, styles.centering]}>
        <LoadingIndicator />
      </View>
    )
  return (
    <CustomKeyboardAvoidingView
      style={[{ height: heightScreen - 64 }, Platform.OS === "web" ? {} : { paddingBottom: insets.bottom }]}
      noContainer={true}
      useAware={true}
    >
      <ScrollView ref={scrollViewRef} style={{ height: 500 }}>
        {!confirmMode && (
          <View style={styles.containerHeader}>
            <View style={isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : undefined}>
              <Text>
                {
                  "ご利用中の契約を継続する場合は更新手続き期間中に、継続の申込と入金の手続きをお願いします。\nプランを変更したい場合は、下記の「プラン名」でご希望のプランを選択してください。\n\n※更新手続き期間を過ぎてもご入金が確認できなかった場合、有料版の契約が終了し無料版に自動的に切り替わります。"
                }
              </Text>
            </View>
          </View>
        )}
        <View style={styles.bodyContainer}>
          <View style={isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : undefined}>
            {confirmMode ? (
              <View style={styles.rowCenter}>
                <Text style={{ fontSize: 18, fontWeight: "700", marginBottom: 16 }}>{"団体有料版申込情報"}</Text>
              </View>
            ) : (
              <View style={styles.rowCenter}>
                <Text style={styles.required}>{"※"}</Text>
                <Text>{"は必須項目"}</Text>
              </View>
            )}
            <View style={styles.spaceTop}>
              <ItemLabel label="受注番号" />
              <Text style={confirmMode ? styles.contentConfirm : styles.content}>{orderDetail?.orderNumber}</Text>
            </View>
            {!confirmMode && (
              <View style={styles.spaceTop}>
                <ItemLabel label="メンバー数" />
                <Text style={confirmMode ? styles.contentConfirm : styles.content}>{organizationDetail?.memberNumber}名</Text>
              </View>
            )}
            {confirmMode ? (
              <View style={styles.spaceTop}>
                <ItemLabel label="プラン" />
                <Text style={confirmMode ? styles.contentConfirm : styles.content}>
                  {plan?.name.replace("一般", "").replace(/\d+/, (match) => `${match}名`)}
                </Text>
              </View>
            ) : (
              <View style={styles.spaceTop}>
                <ItemLabel label="プラン" required />
                <View style={styles.select}>
                  <CustomSelect
                    options={optionPlan}
                    value={plan?.id}
                    onChange={(id) => onChangePlan(id ?? "", true)}
                    placeholder={"選択してください"}
                    isError={!!planErrorMessage}
                  />
                  {!!planErrorMessage && (
                    <ValidationErrorMessage style={styles.validationErrorMessage} message={planErrorMessage} />
                  )}
                </View>
              </View>
            )}
            <View style={styles.spaceTop}>
              <ItemLabel label="人数上限" />
              <Text style={confirmMode ? styles.contentConfirm : styles.content}>{showLimitMember}</Text>
            </View>
            <View style={styles.spaceTop}>
              <ItemLabel label="利用期間" />
              <Text style={confirmMode ? styles.contentConfirm : styles.content}>{showPeriod}</Text>
            </View>
            <View style={styles.spaceTop}>
              <ItemLabel label="申込日" />
              <Text style={confirmMode ? styles.contentConfirm : styles.content}>{convertDate(formatCreatedDate)}</Text>
            </View>
            <View style={styles.spaceTop}>
              <ItemLabel label="継続開始日" />
              <Text style={confirmMode ? styles.contentConfirm : styles.content}>{convertDate(orderDetail.endDate, true)}</Text>
            </View>
            <View style={styles.spaceTop}>
              <ItemLabel label="料金（税込）" />
              <Text style={confirmMode ? styles.contentConfirm : styles.content}>{showAmount}</Text>
            </View>
            <View style={styles.spaceTop}>
              <ItemLabel label="入金方法" />
              <Text style={confirmMode ? styles.contentConfirm : styles.content}>指定口座への振込</Text>
            </View>
            {confirmMode ? (
              <View style={styles.spaceTop}>
                <ItemLabelLongText
                  label="請求書の宛名[振込名義]"
                  full={isLargeScreen}
                  RightComponent={
                    confirmMode ? undefined : <InputLengthCounter text={invoiceAddress} maxLength={30} unit={"字以内"} />
                  }
                />
                <Text style={confirmMode ? styles.contentConfirm : styles.content}>{invoiceAddress}</Text>
              </View>
            ) : (
              <View style={styles.spaceTop}>
                <ItemLabelLongText
                  label="請求書の宛名[振込名義]"
                  required
                  full={isLargeScreen}
                  RightComponent={
                    confirmMode ? undefined : <InputLengthCounter text={invoiceAddress} maxLength={30} unit={"字以内"} />
                  }
                />
                <CustomTextInput
                  value={invoiceAddress}
                  onChangeText={(e) => setInvoiceAddress(e)}
                  style={styles.input}
                  maxLength={30}
                  isError={!!invoiceAddressErrorMessage}
                />
                {!!invoiceAddressErrorMessage && (
                  <ValidationErrorMessage style={styles.validationErrorMessage} message={invoiceAddressErrorMessage} />
                )}
              </View>
            )}
            {confirmMode ? (
              <View style={styles.spaceTop}>
                <ItemLabelLongText label={`入金状況`} />
                <Text style={[styles.contentConfirm, styles.contentStatus]}>入金前</Text>
              </View>
            ) : (
              <View style={styles.spaceTop}>
                <ItemLabelLongText
                  label={`請求書の宛名[振込名義]（フリガナ）`}
                  required
                  full={isLargeScreen}
                  RightComponent={
                    confirmMode ? undefined : <InputLengthCounter text={invoiceAddressKana} maxLength={50} unit={"字以内"} />
                  }
                />
                <View>
                  <CustomTextInput
                    value={invoiceAddressKana}
                    onChangeText={(e) => setInvoiceAddressKana(e)}
                    style={styles.input}
                    maxLength={50}
                    isError={invoiceAddressKanaErrorMessage !== ""}
                  />
                  {invoiceAddressKanaErrorMessage !== "" && (
                    <ValidationErrorMessage style={styles.validationErrorMessage} message={invoiceAddressKanaErrorMessage} />
                  )}
                </View>
              </View>
            )}

            <View style={styles.spaceTop}>
              <ItemLabel
                label={"備考欄"}
                RightComponent={confirmMode ? undefined : <InputLengthCounter text={remask} maxLength={2000} unit={"字以内"} />}
              />
              {confirmMode ? (
                <Text style={confirmMode ? [styles.contentConfirm, { marginBottom: 16 }] : styles.content}>{remask}</Text>
              ) : (
                <View>
                  <MultilineTextInput
                    value={remask}
                    onChangeText={(e) => setRemask(e)}
                    numberOfLines={6}
                    maxLength={2000}
                    style={{ marginTop: 12, height: 240 }}
                    placeholder={"入力してください"}
                    // isError={!!remaskErrorMessage}
                  />
                  {/* {remaskErrorMessage !== "" && (
                    <ValidationErrorMessage style={styles.validationErrorMessage} message={remaskErrorMessage} />
                  )} */}
                </View>
              )}
            </View>
          </View>
        </View>
        {confirmMode && (
          <View style={styles.containerBottom}>
            <View style={isLargeScreen ? { paddingHorizontal: (widthScreen - 600) / 2 } : undefined}>
              <Text>団体有料版のお申込には、団体有料版利用規約と個人情報の取り扱いをご確認のうえ、同意してください。</Text>
              <View style={[styles.checkboxContainer, styles.spaceTop]}>
                <Checkbox
                  isChecked={policyStatus}
                  onPress={() => {
                    setPolicyStatus(!policyStatus)
                  }}
                  isDisabled={isLoadingSubmit}
                />
                <View style={styles.urlContainer}>
                  <Button
                    onPress={async () => {
                      await Linking.openURL(rulePaidURL)
                    }}
                  >
                    <Text style={styles.url}>団体有料版利用規約</Text>
                  </Button>
                  <Text>に同意する</Text>
                </View>
              </View>
              <View style={[styles.checkboxContainer, styles.spaceTop]}>
                <Checkbox
                  isChecked={rulePaidStatus}
                  onPress={() => {
                    setRulePaidStatus(!rulePaidStatus)
                  }}
                  isDisabled={isLoadingSubmit}
                />
                <View style={styles.urlContainer}>
                  <Button
                    onPress={async () => {
                      await Linking.openURL(privacypolicyURL)
                    }}
                  >
                    <Text style={styles.url}>個人情報の取り扱い</Text>
                  </Button>
                  <Text>に同意する</Text>
                </View>
              </View>
            </View>
          </View>
        )}
      </ScrollView>
      <View style={[styles.buttonContainer, isLargeScreen ? {} : { paddingVertical: 24 }]}>
        <View
          style={[
            isLargeScreen
              ? { maxWidth: 600, flexDirection: "row", justifyContent: "space-between", width: "100%", paddingHorizontal: 24 }
              : { width: "100%" },
          ]}
        >
          <TextButton
            buttonType={ButtonType.Secondary}
            disabled={isLoadingSubmit}
            title={"戻る"}
            onPress={changeModeExtendOrBackDetail}
            style={[isLargeScreen ? { width: "49%" } : null]}
          />
          <TextButton
            disabled={disabledSubmit}
            buttonType={ButtonType.Primary}
            isLoading={isLoadingSubmit}
            title={confirmMode ? "継続を申込" : "確認"}
            onPress={changeModeConfirmOrSubmit}
            style={[isLargeScreen ? { width: "49%" } : { marginTop: 16 }]}
          />
        </View>
      </View>
    </CustomKeyboardAvoidingView>
  )
})

const styles = StyleSheet.create({
  containerHeader: {
    paddingHorizontal: 16,
    paddingVertical: 24,
    borderColor: Colors.white2,
    borderBottomWidth: 1,
    fontSize: 14,
    fontWeight: "300",
    lineHeight: 20,
    color: Colors.black2,
  },
  containerBottom: {
    paddingHorizontal: 16,
    paddingVertical: 24,
    borderColor: Colors.white2,
    borderBottomWidth: 1,
    backgroundColor: Colors.beige,
  },
  actionContainer: {
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
    paddingHorizontal: 24,
    paddingVertical: 12,
  },
  container: {
    flex: 1,
  },
  main: {
    flex: 1,
  },
  required: {
    color: Colors.red,
  },
  rowCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  bodyContainer: {
    paddingHorizontal: 16,
    paddingVertical: 24,
  },
  spaceTop: {
    marginTop: 16,
  },
  content: {
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 22.4,
    marginVertical: 14,
    textAlign: "left",
    width: "100%",
  },
  contentConfirm: {
    borderColor: Colors.lightGrayishOrange,
    borderBottomWidth: 1,
    paddingVertical: 14,
    fontSize: 16,
    fontWeight: "300",
    lineHeight: 22.4,
    textAlign: "left",
    width: "100%",
  },
  input: {
    flex: 1,
    marginTop: 8,
    paddingHorizontal: 12,
    paddingVertical: 14,
  },
  select: {
    flex: 1,
    marginTop: 8,
  },
  actions: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  bottomContainer: {
    padding: 24,
    borderColor: Colors.white2,
    borderTopWidth: 1,
  },
  validationErrorMessage: {
    marginTop: 8,
  },
  contentStatus: {
    color: Colors.red,
    fontWeight: "600",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  url: {
    color: Colors.orange,
  },
  urlContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: 8,
  },
  centering: {
    justifyContent: "center",
    alignItems: "center",
  },
  fullHeight: {
    flex: 1,
  },
  footer: {
    width: "100%",
    paddingHorizontal: 24,
    paddingVertical: 12,
    borderTopWidth: 1,
    borderTopColor: Colors.white2,
  },
  buttonContainer: {
    paddingHorizontal: 24,
    width: "100%",
    borderTopColor: Colors.borderColor,
    borderTopWidth: 1,
    alignItems: "center",
    paddingVertical: 12,
  },
})

export const extendPaidTeamOptions: ScreenOptions = () => ({
  ...commonHeaderOptions,
  title: "継続の申込",
})
