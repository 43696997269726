import { selectorFamily } from "recoil"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { ContactFilterValue } from "src/types/contact/ContactFilter"
import { inboxContactsSelectorFamily } from "src/recoil/selectors/contact/inboxContactsSelectorFamily"
import { inboxContactsConcatRequestIdState } from "src/recoil/atoms/contact/inboxContactsConcatRequestIdState"
import { inboxContactFavoriteFlgsCacheState } from "src/recoil/atoms/contact/contactFavoriteFlgsCacheState"

export const inboxContactsConcatSelectorFamily = selectorFamily<
  { contacts: ContactOverviewModel[]; nextToken?: string } | undefined,
  { searchText?: string; filters?: ContactFilterValue[]; count?: number; organizationId?: string; nextTokens: string[] }
>({
  key: "selectors/contact/inboxContactsConcatSelectorFamily",
  get:
    ({ nextTokens, ...params }) =>
    async ({ get }) => {
      get(inboxContactsConcatRequestIdState)
      const inboxFavState = get(inboxContactFavoriteFlgsCacheState)
      const result = [
        get(inboxContactsSelectorFamily(params)),
        ...nextTokens.map((nextToken) => get(inboxContactsSelectorFamily({ ...params, nextToken }))),
      ]
      const contactsRes = result.reduce((p, c) => (c != null ? [...p, ...c.contacts] : p), [] as ContactOverviewModel[])
      const contacts = contactsRes.map((c) => {
        if (inboxFavState[c.id] === undefined) {
          return c
        } else {
          return { ...c, isFavorite: inboxFavState[c.id] }
        }
      })
      return {
        contacts,
        nextToken: result.length > 0 ? result[result.length - 1]?.nextToken : undefined,
      }
    },
})
