import { ValueOf } from "src/types.d"

export const ScheduleAnswerType = Object.freeze({
  Attend: "Attend",
  NonAttend: "NonAttend",
  Pend: "Pend",
} as const)

export const ScheduleAnswerLabel: { [key in ScheduleAnswerType]: string } = {
  [ScheduleAnswerType.Attend]: "参加",
  [ScheduleAnswerType.NonAttend]: "不参加",
  [ScheduleAnswerType.Pend]: "未定",
}

export type ScheduleAnswerType = ValueOf<typeof ScheduleAnswerType>

export const CsvScheduleAnswerType = Object.freeze({
  yes: "yes",
  no: "no",
  tbd: "tbd",
})

export const ScheduleAnswerCSVLabel: { [key in CsvScheduleAnswerType]: string } = {
  [CsvScheduleAnswerType.yes]: "○",
  [CsvScheduleAnswerType.no]: "×",
  [CsvScheduleAnswerType.tbd]: "△",
}

export type CsvScheduleAnswerType = ValueOf<typeof CsvScheduleAnswerType>
