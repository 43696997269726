import { selector } from "recoil"
import { homeOrganizationsRequestIdState } from "src/recoil/atoms/home/homeOrganizationsRequestIdState"
import { myFullOrganizationsSelector } from "src/recoil/selectors/organization/myOrganizationsSelector"
import { ContactOverviewModel } from "src/types/contact/ContactOverviewModel"
import { tokensState } from "src/recoil/atoms/authorization/tokensState"
import { listTeamMemberMailByAccount } from "src/aws/customAPI"
import { mailTypeMapping } from "src/apis/contact/getInboxContacts"
import { multiTeamMemberImageUrls } from "src/apis/team/getMemberImageUrl"

export type HomeOrganization = {
  id: string
  name: string
  uriImage?: string
  contacts: ContactOverviewModel[]
  isShowAd: boolean
}

export const homeOrganizationsSelector = selector<HomeOrganization[] | undefined>({
  key: "selectors/home/homeOrganizationsSelector",
  get: async ({ get }) => {
    get(homeOrganizationsRequestIdState)
    const myOrganizations = get(myFullOrganizationsSelector)
    if (myOrganizations == null) return

    const tokens = get(tokensState)
    const accessToken = tokens?.accessToken
    if (accessToken == null) return
    const result = await listTeamMemberMailByAccount(accessToken, { limit: 3 })
    const contacts = result?.data?.listTeamMemberMailByAccount?.teamMemberMailByAccount
    const teamMemberList =
      contacts
        ?.map((team) => {
          return team?.teamMemberMail.map((contact) => {
            return { teamMemberId: contact?.mail.sender.id, thumbnail: false }
          })
        })
        .flat() || []

    const list = teamMemberList.reduce((uniqueList, item) => {
      if (item?.teamMemberId && !uniqueList.has(item.teamMemberId)) {
        uniqueList.set(item.teamMemberId, item)
      }
      return uniqueList
    }, new Map())
    const data = await multiTeamMemberImageUrls({ accessToken: accessToken, teamMemberImages: [...list.values()] })
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let teamMemberImageList: any[] = []
    if (data.isOk) {
      teamMemberImageList = data.content
    }

    const homeOrganizations = myOrganizations.map((o) => {
      return {
        id: o.id,
        name: o.title,
        uriImage: o.uriImage,
        isShowAd: !o.paidFunctionEnabled || o.showAdsFlg,
        contacts:
          contacts
            ?.find((el) => el?.teamId === o.id)
            ?.teamMemberMail.map((m) => ({
              id: m.mail.id,
              contactType: mailTypeMapping[m.mail.mailType],
              isRead: m.readFlg,
              isAnswered: m.answerRequiredFlg ? m.answeredFlg ?? false : undefined,
              title: m.mail.title,
              senderId: m.mail.sender.id,
              senderName: m.mail.sender.nickname,
              senderImageUrl: teamMemberImageList.find((o) => o.teamMemberId === m.mail.sender.id).url,
              isSenderDeleted: m.mail.sender.deleteFlg || false,
              isFavorite: m.favoriteFlgReceived,
              isAttachmentExists: (m.mail.attachmentFileNames || []).length > 0,
              sentDate: new Date(m.mail.sentAt),
              editDate: new Date(m.mail.updatedAt),
              isUpdate: m.mail.updatedAt > m.mail.createdAt,
              deadlineDate: m.mail.responseDueDatetime ? new Date(m.mail.responseDueDatetime) : undefined,
              teamMemberContactId: m.id,
            })) ?? [],
      }
    })
    return homeOrganizations
  },
})
