import { atom, selector } from "recoil"
import { draftSelector } from "src/recoil/selectors/contact/draftSelector"

const initialState = undefined

const initialStateSelector = selector({
  key: "atoms/contact/create/reminderDateState_initialState",
  get: ({ get }) => {
    const draft = get(draftSelector)
    return draft?.reminderDate ?? initialState
  },
})

export const reminderDateState = atom<Date | undefined>({
  key: "atoms/contact/create/reminderDateState",
  default: initialStateSelector,
})
