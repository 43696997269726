export type Result<T, E> = Ok<T> | Error<E>

export type Ok<T> = {
  isOk: true
  content: T
}

export type Error<E> = {
  isOk: false
  error: E
  errorMessage?: string
}

export const Result = {
  Ok: <T>(content: T): Ok<T> => ({
    isOk: true,
    content: content,
  }),
  Error: <E>(error: E, errorMessage?: string): Error<E> => ({
    isOk: false,
    error,
    errorMessage,
  }),
}
