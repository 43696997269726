import { selectorFamily } from "recoil"
import { hasUnreadThreadMessageRequestIdState } from "src/recoil/atoms/contact/hasUnreadThreadMessageRequestIdState"
import { UnreadResponse, hasUnreadThreadMessage } from "src/apis/contact/hasUnreadThreadMessage"
import { requestDataSelector } from "src/recoil/selectors/auth/requestDataSelector"
import { myTeamsSelector } from "../team/myTeamsSelector"
import { haveOldRa9TeamJoinSelector } from "../team/haveOldRa9TeamJoinSelector"

export const hasUnreadThreadMessageSelectorFamily = selectorFamily<UnreadResponse, { organizationId: string | undefined }>({
  key: "selectors/contact/hasUnreadThreadMessageSelectorFamily",
  get:
    ({ organizationId }) =>
    async ({ get }) => {
      get(hasUnreadThreadMessageRequestIdState)
      const requestData = get(requestDataSelector)
      const ListTeamGql = get(haveOldRa9TeamJoinSelector)
      const teamMemberId = ListTeamGql.getAccount.teamMembers.items.find((e: any) => e.team.id === organizationId).id
      if (requestData == null || organizationId == null) return { hasUnread: false }
      const { accessToken, accountId } = requestData

      return hasUnreadThreadMessage({
        teamMemberId,
        accessToken,
        accountId,
        organizationId,
      })
    },
})
