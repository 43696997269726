import { getUnreadMailCount } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
  accountId: string
}

type Response = {[organizationId: string]: number}

export const getUnreadContactCount = async ({accessToken, accountId}: Params): Promise<Response> => {
  const result = await execApi(
    () => getUnreadMailCount(accessToken, { accountId, appInfo }),
    res => Object.fromEntries((res.getUnreadMailCount.teams || []).map(team => [
      team.id, team.unreadMailCount
    ])),
    {
      identifiers: ["getUnreadContactCount", "getUnreadMailCount"]
    }
  )

  return result.isOk ? result.content : {}
}
