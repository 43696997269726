import { selectorFamily } from "recoil"
import { CustomAlert } from "src/utils/CustomAlert"
import { contactReplyThreadsRequestIdState } from "src/recoil/atoms/contact/contactReplyThreadsRequestIdState"
import { selectedMyOrganizationSelector } from "src/recoil/selectors/organization/selectedMyOrganizationSelector"
import { myProfileSelectorFamily } from "src/recoil/selectors/organization/myProfile/myProfileSelectorFamily"
import { getContactReplyThreads } from "src/apis/contact/getContactReplyThreads"
import { requestDataSelector } from "src/recoil/selectors/auth/requestDataSelector"

export type ContactReplyThreadOverview = {
  replyThreadId: string
  partner: {
    id: string
    name: string
    isDeleted?: boolean
  }
  recentMessage: {
    text: string
    updatedAt: Date
  }
  unreadMessageCount: number
}

export const contactReplyThreadsSelectorFamily = selectorFamily<
  ContactReplyThreadOverview[],
  { contactId: string | undefined; viewMode?: "Inbox" | "Outbox" | "Reserved" | "Migration" }
>({
  key: "selectors/contact/contactReplyThreadsSelectorFamily",
  get:
    ({ contactId, viewMode }) =>
    async ({ get }) => {
      if (!(viewMode === "Inbox" || viewMode === "Outbox")) return []
      get(contactReplyThreadsRequestIdState)
      if (contactId == null) return []

      const requestData = get(requestDataSelector)
      if (requestData == null) return []
      const { accountId, accessToken } = requestData

      const organization = get(selectedMyOrganizationSelector)
      const organizationId = organization?.id
      if (organizationId == null) {
        return []
      }
      const myProfile = get(myProfileSelectorFamily(organizationId))
      const myMemberId = myProfile?.memberId
      if (myMemberId == null) {
        return []
      }

      const result = await getContactReplyThreads({ accessToken, accountId, contactId, myMemberId, viewMode })

      if (!result.isOk) {
        CustomAlert.alert("エラー", result.error.message)
        return []
      }
      return result.content
    },
})
