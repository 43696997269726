import { useAsyncSelector } from "../../../hooks/useAsyncSelector"
import { prefecturesSelector } from "../../selectors/account/prefecturesSelector"
import { useCallback, useEffect, useMemo, useState } from "react"
import { citiesSelectorFamily } from "../../selectors/account/citiesSelectorFamily"
import { teamCategorySelector } from "src/recoil/selectors/team/teamCategorySelector"
import { getToday, UseSearchedDate } from "src/utils/useDateTime"
import { ageOptions } from "../../../constants/matchingSearchDate"
import { useAsyncState } from "../../../hooks/useAsyncState"
import { defaultFilterParams, filterParamsMatchingState } from "src/recoil/atoms/matching/search/filterParamsMatchingState"
import { searchFlgState } from "src/recoil/atoms/matching/search/searchFlgState"
import { CustomAlert } from "src/utils/CustomAlert"
export const useOfferSearchData = (onClose?: () => void) => {
  const [searchForm, setSearchForm] = useAsyncState(filterParamsMatchingState)
  const [searchFlg, setSearchFlg] = useAsyncState(searchFlgState)
  const { value: prefectures } = useAsyncSelector(prefecturesSelector)
  const prefectureOptions = useMemo(
    () => prefectures?.map((prefecture) => ({ value: prefecture.id, label: prefecture.label })),
    [prefectures]
  )
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const { value: cities } = useAsyncSelector(citiesSelectorFamily(searchForm?.prefecture?.id?.toString()))
  const cityOptions = useMemo(() => cities?.map((city) => ({ value: city.id, label: city.label })), [cities])
  const { value: categoryMaster } = useAsyncSelector(teamCategorySelector)
  const activityOptions = useMemo(
    () =>
      categoryMaster?.items[0].activities.items.map((a) => {
        return { value: a.teamActivityMaster.id, label: a.teamActivityMaster.label }
      }),
    [categoryMaster]
  )
  const onCloseSearchOfferModal = useCallback(async () => {
    if (onClose) {
      onClose()
    }
  }, [onClose])
  const resetSearchData = useCallback(() => {
    setSearchForm({
      activity: undefined,
      dateFrom: undefined,
      dateTo: undefined,
      prefecture: undefined,
      city: undefined,
      ageFrom: undefined,
      ageTo: undefined,
      keyword: "",
    })
  }, [setSearchForm])
  const findItemByValue = (arr?: any[], value?: string) => {
    return arr?.find((item) => item.value === value)
  }
  const onChangePrefecture = useCallback(
    (id?: string) => {
      const next = findItemByValue(prefectureOptions, id)
      if (searchForm) {
        setSearchForm({
          ...searchForm,
          prefecture: {
            id: Number(next?.value ?? 0),
            label: next?.label,
          },
          city: undefined,
        })
      }
    },
    [searchForm, setSearchForm, prefectureOptions]
  )

  const onChangeCity = useCallback(
    (id?: string) => {
      const next = findItemByValue(cityOptions, id)
      setSearchForm((prevSearchForm) => ({
        ...prevSearchForm,
        city: next ? { id: next.value, label: next.label } : undefined,
      }))
    },
    [setSearchForm, cityOptions]
  )

  const onChangeStartAge = useCallback(
    (id?: string) => {
      const next = findItemByValue(ageOptions, id)
      setSearchForm((prevSearchForm) => ({
        ...prevSearchForm,
        ageFrom: next ? { id: next.value, label: next.label } : undefined,
      }))
    },
    [setSearchForm]
  )

  const onChangeEndAge = useCallback(
    (id?: string) => {
      const next = findItemByValue(ageOptions, id)
      setSearchForm((prevSearchForm) => ({
        ...prevSearchForm,
        ageTo: next ? { id: next.value, label: next.label } : undefined,
      }))
    },
    [setSearchForm]
  )

  const onChangeActivity = useCallback(
    (id?: string) => {
      const next = findItemByValue(activityOptions, id)
      setSearchForm((prevSearchForm) => ({
        ...prevSearchForm,
        activity: next ? { id: next.value, label: next.label } : undefined,
      }))
    },
    [setSearchForm, activityOptions]
  )

  const onChangeStartDate = useCallback(
    (next: Date | undefined) => {
      setSearchForm((prevSearchForm) => ({
        ...prevSearchForm,
        dateFrom: next,
      }))
    },
    [setSearchForm]
  )

  const onChangeEndDate = useCallback(
    (next: Date | undefined) => {
      setSearchForm((prevSearchForm) => ({
        ...prevSearchForm,
        dateTo: next,
      }))
    },
    [setSearchForm]
  )
  const onKeyWordChange = useCallback(
    (next: string | undefined) => {
      setSearchForm((prevSearchForm) => ({
        ...prevSearchForm,
        keyword: next,
      }))
    },
    [setSearchForm]
  )
  const { today } = getToday()

  const formatStartDate = useMemo(() => searchForm?.dateFrom && UseSearchedDate(searchForm?.dateFrom), [searchForm?.dateFrom])
  const formatEndDate = useMemo(() => searchForm?.dateTo && UseSearchedDate(searchForm?.dateTo), [searchForm?.dateTo])
  const prefectureName = useMemo(
    () => prefectures?.find(({ id }) => id === searchForm?.prefecture?.id?.toString())?.label,
    [searchForm, prefectures]
  )
  const formatDate = useMemo(() => {
    if (formatStartDate && formatEndDate) {
      return formatStartDate + "～" + formatEndDate
    } else if (formatStartDate && !formatEndDate) {
      return formatStartDate + "～"
    } else if (!formatStartDate && formatEndDate) {
      return "～" + formatEndDate
    } else {
      return ""
    }
  }, [formatStartDate, formatEndDate])

  const formatAge = useMemo(() => {
    if (searchForm && searchForm?.ageFrom?.label && searchForm.ageTo?.label) {
      return searchForm?.ageFrom?.label + "～" + searchForm.ageTo?.label + "歳"
    } else if (searchForm?.ageFrom?.label && !searchForm.ageTo?.label) {
      return searchForm?.ageFrom?.label + "歳～"
    } else if (!searchForm?.ageFrom?.label && searchForm?.ageTo?.label) {
      return "～" + searchForm.ageTo?.label + "歳"
    } else {
      return ""
    }
  }, [searchForm])

  const params = useMemo(
    () => ({
      activity: searchForm?.activity?.label ? searchForm?.activity?.label + "、" : "",
      formatDate: formatDate ? formatDate + "、" : "",
      prefectureName: prefectureName ? prefectureName + "、" : "",
      city: searchForm?.city?.label ? searchForm?.city?.label + "、" : "",
      formatAge: formatAge ? formatAge + "、" : "",
      keyword: searchForm?.keyword ? searchForm?.keyword + "、、" : "、",
    }),
    [searchForm?.activity, formatDate, prefectureName, searchForm?.city, formatAge, searchForm?.keyword]
  )

  const kariSearchedItem = useMemo(
    () => params.activity + params.formatDate + params.prefectureName + params.city + params.formatAge + params.keyword,
    [params]
  ).slice(0, -1)
  const searchedItem = kariSearchedItem.slice(0, -1)

  const formatLastTimeStartDate = useMemo(
    () => searchForm?.dateFrom && UseSearchedDate(searchForm?.dateFrom),
    [searchForm?.dateFrom]
  )
  const formatLastTimeEndDate = useMemo(() => searchForm?.dateTo && UseSearchedDate(searchForm?.dateTo), [searchForm?.dateTo])
  const LastTimePrefectureName = useMemo(
    () => prefectures?.find(({ id }) => id === searchForm?.prefecture?.id?.toString())?.label,
    [prefectures, searchForm?.prefecture]
  )

  const formatLastTimeDate = useMemo(() => {
    if (formatLastTimeStartDate && formatLastTimeEndDate) {
      return formatLastTimeStartDate + "～" + formatLastTimeEndDate
    } else if (formatLastTimeStartDate && !formatLastTimeEndDate) {
      return formatLastTimeStartDate + "～"
    } else if (!formatLastTimeStartDate && formatLastTimeEndDate) {
      return "～" + formatLastTimeEndDate
    } else {
      return ""
    }
  }, [formatLastTimeStartDate, formatLastTimeEndDate])

  const formatLastTimeAge = useMemo(() => {
    if (searchForm?.ageFrom?.label && searchForm.ageTo?.label) {
      return searchForm?.ageFrom?.label + "～" + searchForm.ageTo?.label + "歳"
    } else if (searchForm?.ageFrom?.label && !searchForm.ageTo?.label) {
      return searchForm?.ageFrom?.label + "歳～"
    } else if (!searchForm?.ageFrom?.label && searchForm?.ageTo?.label) {
      return "～" + searchForm.ageTo?.label + "歳"
    } else {
      return ""
    }
  }, [searchForm?.ageFrom?.label, searchForm?.ageTo?.label])

  const lastTimeItemParams = useMemo(
    () => ({
      activity: searchForm?.activity?.label ? searchForm?.activity?.label + "、" : "",
      formatLastTimeDate: formatLastTimeDate ? formatLastTimeDate + "、" : "",
      prefectureName: LastTimePrefectureName ? LastTimePrefectureName + "、" : "",
      city: searchForm?.city?.label ? searchForm?.city?.label + "、" : "",
      formatLastTimeAge: formatLastTimeAge ? formatLastTimeAge + "、" : "",
      keyword: searchForm?.keyword + "、",
    }),
    [
      searchForm?.activity?.label,
      formatLastTimeDate,
      LastTimePrefectureName,
      searchForm?.city?.label,
      formatLastTimeAge,
      searchForm?.keyword,
    ]
  )

  const kariLastTimeItem = useMemo(
    () =>
      lastTimeItemParams.activity +
      lastTimeItemParams.formatLastTimeDate +
      lastTimeItemParams.prefectureName +
      lastTimeItemParams.city +
      lastTimeItemParams.formatLastTimeAge +
      lastTimeItemParams.keyword,
    [lastTimeItemParams]
  ).slice(0, -1)
  const lastTimeItem = kariLastTimeItem.slice(0, -1)

  const ageToErrorMessage = useMemo(
    () =>
      searchForm?.ageFrom && searchForm.ageTo && Number(searchForm?.ageFrom.label) > Number(searchForm.ageTo.label)
        ? "正しく入力してください。"
        : "",
    [searchForm?.ageFrom, searchForm?.ageTo]
  )
  const isDisabled = useMemo(() => !!ageToErrorMessage, [ageToErrorMessage])
  const onSearch = useCallback(() => {
    setSearchFlg(false)
    onClose && onClose()
  }, [setSearchFlg, onClose])
  return {
    searchForm,
    setSearchForm,
    prefectureOptions,
    cityOptions,
    activityOptions,
    onChangePrefecture,
    onChangeCity,
    onChangeActivity,
    onChangeStartAge,
    onChangeEndAge,
    onSearch,
    onChangeStartDate,
    onChangeEndDate,
    today,
    resetSearchData,
    searchFlg,
    setSearchFlg,
    searchedItem,
    lastTimeItem,
    ageToErrorMessage,
    setOpenConfirmModal,
    openConfirmModal,
    onKeyWordChange,
    isDisabled,
    onCloseSearchOfferModal,
  }
}
