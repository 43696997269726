import { FC } from "react"
import Svg, { ClipPath, Defs, G, Path } from "react-native-svg"

export const CultureIcon: FC = () => (
  <Svg data-name="グループ 2355" width="49" height="45.47" viewBox="0 0 49 45.47">
    <Defs>
      <ClipPath id="coywg6ygma">
        <Path data-name="長方形 1581" fill="none" d="M0 0h49v45.47H0z" />
      </ClipPath>
    </Defs>
    <G data-name="グループ 2354" clipPath="url(#coywg6ygma)">
      <Path
        data-name="パス 1058"
        d="M16.668 56.091a11.184 11.184 0 0 0 1.807 6.273c1.732 2.636 4.01 3.866 6.495 3.759 2.5-.073 5.206-1.483 8.134-3.323 2.877-1.92 5.27-3.814 6.358-6.065 1.114-2.224.923-4.8-.775-7.464a11.18 11.18 0 0 0-4.987-4.214 6.53 6.53 0 0 1-3.167-2.835q-.163-.295-.349-.58a6.544 6.544 0 0 0-5.221-3.29c-1.99-.1-4.11.819-6.279 2.187-2.136 1.421-3.838 2.981-4.563 4.837a6.545 6.545 0 0 0 .868 6.109q.184.286.387.555a6.533 6.533 0 0 1 1.292 4.051z"
        transform="translate(-7.665 -21.444)"
        fill="#d68556"
      />
      <Path
        data-name="パス 1059"
        d="M42.069 58.822a18.307 18.307 0 0 1-2.695 6.378 31.978 31.978 0 0 0 4.408-2.4c2.877-1.92 5.27-3.814 6.358-6.065 1.114-2.223.923-4.8-.775-7.463a11.18 11.18 0 0 0-4.986-4.214 6.535 6.535 0 0 1-3.168-2.835q-.163-.295-.349-.58a6.544 6.544 0 0 0-5.22-3.29 7.613 7.613 0 0 0-2.839.461 18.359 18.359 0 0 1 9.266 20.008z"
        transform="translate(-18.344 -21.443)"
        fill="#bf6b33"
      />
      <Path data-name="長方形 1579" transform="rotate(-32.935 22.517 .9)" fill="#fdf6e4" d="M0 0h5.955v20.205H0z" />
      <Path
        data-name="パス 1060"
        d="M36.33 58.162A4.347 4.347 0 1 1 32.988 53a4.347 4.347 0 0 1 3.342 5.159"
        transform="translate(-15.508 -29.586)"
        fill="#f2d387"
      />
      <Path
        data-name="パス 1061"
        d="m38.306 76.716-.006-.009a1.476 1.476 0 0 1 .436-2.04l4.576-2.967a1.475 1.475 0 0 1 2.04.436l.006.009a1.475 1.475 0 0 1-.436 2.04l-4.576 2.964a1.475 1.475 0 0 1-2.04-.436"
        transform="translate(-21.285 -39.964)"
        fill="#f2d387"
      />
      <Path
        data-name="パス 1062"
        d="m46.665 76.174 3.067-1.987a1.475 1.475 0 0 0 .436-2.04l-.006-.009a1.475 1.475 0 0 0-2.04-.436l-.555.36a18.445 18.445 0 0 1-.9 4.113"
        transform="translate(-26.096 -39.964)"
        fill="#ecc358"
      />
      <Path
        data-name="パス 1063"
        d="M32.931 61.166a2.213 2.213 0 1 1 3.061.654 2.216 2.216 0 0 1-3.061-.654"
        transform="translate(-18.217 -32.295)"
        fill="#59748b"
      />
      <Path
        data-name="長方形 1580"
        d="M4.213 0h.854a4.213 4.213 0 0 1 4.212 4.213 4.214 4.214 0 0 1-4.214 4.214h-.851A4.214 4.214 0 0 1 0 4.213 4.213 4.213 0 0 1 4.213 0z"
        transform="rotate(-32.97 16.313 5.795)"
        fill="#f2d387"
      />
      <Path
        data-name="パス 1064"
        d="M32.6 56.96a2.758 2.758 0 1 0 2.075-.381 2.739 2.739 0 0 0-2.075.381zm.591.912a1.67 1.67 0 1 1-.494 2.31 1.667 1.667 0 0 1 .494-2.31"
        transform="translate(-17.527 -31.605)"
        fill="#545151"
      />
      <Path
        data-name="パス 1065"
        d="m42.329 70.558-4.576 2.964a2.021 2.021 0 0 0-.6 2.792l.006.009a2.02 2.02 0 0 0 2.791.6l4.576-2.964a2.021 2.021 0 0 0 .6-2.791l-.006-.009a2.021 2.021 0 0 0-2.792-.6m-4.261 5.165a.933.933 0 0 1 .275-1.289l4.576-2.964a.934.934 0 0 1 1.289.275l.006.009a.933.933 0 0 1-.276 1.289l-4.576 2.964a.933.933 0 0 1-1.289-.275z"
        transform="translate(-20.597 -39.276)"
        fill="#545151"
      />
      <Path
        data-name="パス 1066"
        d="M1.525 14.542a3.34 3.34 0 0 0-.987 4.618l1.541 2.378a3.318 3.318 0 0 0 2.1 1.45 3.358 3.358 0 0 0 .86.071l2.913 4.491a8.4 8.4 0 0 0-2 2.9 7.125 7.125 0 0 0 .916 6.6c.129.2.267.4.409.588a6.019 6.019 0 0 1 1.184 3.714 11.775 11.775 0 0 0 1.895 6.58 8.326 8.326 0 0 0 5.154 3.856 7.192 7.192 0 0 0 1.815.148c2.753-.082 5.666-1.685 8.4-3.406l.012-.008c.433-.289.822-.555 1.189-.814a.543.543 0 1 0-.625-.888c-.357.252-.737.512-1.16.794-2.607 1.638-5.362 3.164-7.854 3.236h-.007c-2.359.1-4.439-1.113-6.017-3.514a10.676 10.676 0 0 1-1.718-5.965 7.111 7.111 0 0 0-1.4-4.385 9.43 9.43 0 0 1-.365-.524 6 6 0 0 1-.82-5.611 7.163 7.163 0 0 1 1.6-2.364l3.216 4.964a4.856 4.856 0 0 0 .7 3.59 4.96 4.96 0 0 0 .407.543.543.543 0 0 0 .822-.71 3.933 3.933 0 0 1-.317-.423 3.8 3.8 0 1 1 2.052 1.559.543.543 0 0 0-.323 1.037c.144.045.289.082.435.114a4.889 4.889 0 0 0 2.132-9.543l-3.216-4.964a7.163 7.163 0 0 1 2.8-.491 6 6 0 0 1 4.792 3.044 8.8 8.8 0 0 1 .329.547 7.113 7.113 0 0 0 3.429 3.071 10.678 10.678 0 0 1 4.742 4.008c1.546 2.42 1.8 4.816.746 6.927v.007a11.028 11.028 0 0 1-2.952 3.487.543.543 0 1 0 .7.831 12.054 12.054 0 0 0 3.229-3.841c1.237-2.471.959-5.236-.8-8a11.78 11.78 0 0 0-5.231-4.421 6.019 6.019 0 0 1-2.905-2.6c-.116-.209-.24-.415-.369-.614a7.126 7.126 0 0 0-5.655-3.536 8.4 8.4 0 0 0-3.455.643l-2.909-4.49a3.363 3.363 0 0 0 .287-.813 3.319 3.319 0 0 0-.463-2.514l-1.545-2.375a3.338 3.338 0 0 0-4.618-.987zM6.172 22.8a3.39 3.39 0 0 0 .526-.277l3.094-2a3.374 3.374 0 0 0 .468-.366l6.05 9.339a4.895 4.895 0 0 0-4.088 2.648zM1.45 18.569a2.252 2.252 0 0 1 .666-3.116l3.094-2a2.253 2.253 0 0 1 3.116.666l1.541 2.379a2.252 2.252 0 0 1-.667 3.111l-3.094 2a2.253 2.253 0 0 1-3.116-.666z"
        transform="translate(0 -6.71)"
        fill="#545151"
      />
      <Path
        data-name="パス 1067"
        d="m103.346 86.937-.328.344a.786.786 0 1 1-1.139-1.085l1.3-1.366.324 1.548a.624.624 0 0 1-.157.559z"
        transform="translate(-56.851 -47.438)"
        fill="#4a61ab"
      />
      <Path
        data-name="パス 1068"
        d="m94.473 84.45-.328.344a.787.787 0 0 1-1.139-1.085l1.3-1.366.324 1.548a.624.624 0 0 1-.157.559z"
        transform="translate(-51.889 -46.047)"
        fill="#4a61ab"
      />
      <Path
        data-name="パス 1069"
        d="m95.35 77.222 3.909 1.094-.331-1.579a.624.624 0 0 0-.443-.473l-2.553-.715a.625.625 0 0 0-.78.73z"
        transform="translate(-53.203 -42.234)"
        fill="#4a61ab"
      />
      <Path
        data-name="パス 1070"
        d="M77.443 22.679a7.345 7.345 0 1 1-5.715 8.7 7.361 7.361 0 0 1 5.715-8.7"
        transform="translate(-40.024 -12.594)"
        fill="#4a61ab"
      />
      <Path
        data-name="パス 1071"
        d="M88.914 23.717a7.388 7.388 0 0 0-6.748-.677 7.342 7.342 0 0 1 2.968 14.171 7.35 7.35 0 0 0 3.78-13.495"
        transform="translate(-45.948 -12.595)"
        fill="#334fa0"
      />
      <Path
        data-name="パス 1072"
        d="m84.709 37.678-12.762-8.3a.555.555 0 0 1-.161-.77l.768-1.17a.559.559 0 0 1 .772-.161l12.763 8.3a.554.554 0 0 1 .161.77l-.768 1.17a.56.56 0 0 1-.772.162"
        transform="translate(-40.092 -15.201)"
        fill="#f6db78"
      />
      <Path
        data-name="パス 1073"
        d="m48.648 90.227 1.82 1.184-2.34 3.562a1.092 1.092 0 0 1-1.507.316 1.08 1.08 0 0 1-.314-1.5z"
        transform="translate(-25.797 -50.456)"
        fill="#4a61ab"
      />
      <Path
        data-name="パス 1074"
        d="M52.641 67.258 50.24 65.7a1.583 1.583 0 0 1-.489-2.15l10.277-16.888 6.2 4.035-11.412 16.144a1.6 1.6 0 0 1-2.175.417z"
        transform="translate(-27.692 -26.094)"
        fill="#99cd94"
      />
      <Path
        data-name="パス 1075"
        d="M68.437 53.494 56.28 70.986l.141.092a1.6 1.6 0 0 0 2.175-.417l11.415-16.143z"
        transform="translate(-31.472 -29.914)"
        fill="#74c298"
      />
      <Path
        data-name="パス 1076"
        d="M70.59 59.808a1.138 1.138 0 0 1-.331-1.58l1.277-1.943a1.143 1.143 0 1 1 1.916 1.248l-1.276 1.943a1.148 1.148 0 0 1-1.585.332"
        transform="translate(-39.185 -31.186)"
        fill="#f9f6f6"
      />
      <Path
        data-name="パス 1077"
        d="M65.747 36.924a7.836 7.836 0 0 0 3.846-2.041 1.038 1.038 0 0 0 1.4-.309l.768-1.17a1.031 1.031 0 0 0-.275-1.4 7.807 7.807 0 0 0 .477-3.275.469.469 0 0 0-.935.065 6.871 6.871 0 0 1-.349 2.683l-11.188-7.283a6.934 6.934 0 0 1 8.431-.678 6.793 6.793 0 0 1 2.756 3.54.469.469 0 0 0 .888-.3 7.725 7.725 0 0 0-3.133-4.024 7.872 7.872 0 0 0-9.753.938 1.029 1.029 0 0 0-1.4.309l-.769 1.17a1.031 1.031 0 0 0 .275 1.4 7.766 7.766 0 0 0 .221 6l-8.56 14.071a.469.469 0 1 0 .8.488L57.7 33.225l5.39 3.506-11.137 15.747a1.125 1.125 0 0 1-1.537.294l-2.4-1.562a1.109 1.109 0 0 1-.344-1.514l.611-1a.469.469 0 1 0-.8-.488l-.611 1a2.041 2.041 0 0 0 .532 2.715l-2.07 3.153a1.555 1.555 0 1 0 2.6 1.7l2.073-3.156a2.09 2.09 0 0 0 2.71-.6l11.264-15.929a7.936 7.936 0 0 0 1.766-.167zM47.153 56.262a.624.624 0 0 1-.859.18.612.612 0 0 1-.178-.85l2.071-3.153 1.035.673zM57.3 25.663l.768-1.17a.09.09 0 0 1 .125-.026l12.763 8.3a.086.086 0 0 1 .025.119l-.769 1.17a.091.091 0 0 1-.125.026l-12.762-8.3a.085.085 0 0 1-.025-.119zm.6 6.574a6.833 6.833 0 0 1-.3-5.159l11.189 7.28a6.921 6.921 0 0 1-4.872 1.793z"
        transform="translate(-25.212 -12.001)"
        fill="#545151"
      />
      <Path
        data-name="パス 1078"
        d="m71.982 59.139 1.277-1.939a1.612 1.612 0 1 0-2.7-1.762l-1.276 1.943a1.612 1.612 0 1 0 2.7 1.762m-1.721-.318a.669.669 0 0 1-.195-.93l1.277-1.943a.68.68 0 0 1 .938-.2.669.669 0 0 1 .195.93L71.2 58.624a.68.68 0 0 1-.938.2"
        transform="translate(-38.601 -30.592)"
        fill="#545151"
      />
      <Path
        data-name="パス 1079"
        d="M104.669 55.944a.469.469 0 0 0-.363.555l.265 1.263a.469.469 0 1 0 .917-.192l-.264-1.263a.469.469 0 0 0-.555-.363"
        transform="translate(-58.324 -31.279)"
        fill="#545151"
      />
      <Path
        data-name="パス 1080"
        d="M106.314 63.8a.469.469 0 0 0-.363.555l.265 1.263a.469.469 0 1 0 .917-.192l-.264-1.263a.469.469 0 0 0-.555-.363"
        transform="translate(-59.244 -35.672)"
        fill="#545151"
      />
      <Path
        data-name="パス 1081"
        d="m102.669 61.827-.5.1a.469.469 0 0 0 .192.918l.5-.1a.469.469 0 0 0-.192-.918"
        transform="translate(-56.928 -34.569)"
        fill="#545151"
      />
      <Path
        data-name="パス 1082"
        d="m108.781 60.547-.5.1a.469.469 0 0 0 .192.918l.5-.1a.469.469 0 1 0-.192-.918z"
        transform="translate(-60.346 -33.853)"
        fill="#545151"
      />
      <Path
        data-name="パス 1083"
        d="M77.069 80.662a.469.469 0 0 0-.363.555l.265 1.263a.469.469 0 1 0 .917-.192l-.264-1.263a.469.469 0 0 0-.555-.363"
        transform="translate(-42.889 -45.102)"
        fill="#545151"
      />
      <Path
        data-name="パス 1084"
        d="M78.714 88.516a.469.469 0 0 0-.363.555l.265 1.263a.469.469 0 1 0 .917-.192l-.264-1.263a.469.469 0 0 0-.555-.363"
        transform="translate(-43.809 -49.494)"
        fill="#545151"
      />
      <Path
        data-name="パス 1085"
        d="m75.07 86.545-.5.1a.469.469 0 0 0 .192.918l.5-.1a.469.469 0 1 0-.192-.918"
        transform="translate(-41.495 -48.392)"
        fill="#545151"
      />
      <Path
        data-name="パス 1086"
        d="m81.181 85.265-.5.1a.469.469 0 0 0 .192.918l.5-.1a.469.469 0 0 0-.192-.918"
        transform="translate(-44.912 -47.676)"
        fill="#545151"
      />
      <Path
        data-name="パス 1087"
        d="m97.075 75.015-3.909-1.095q-.6-.056-.585.547l.632 3.017-1.133 1.189A1.257 1.257 0 0 0 93.9 80.4l.328-.344a1.094 1.094 0 0 0 .279-.979l-.44-2.1 2.892.81.165.79-1.132 1.188a1.257 1.257 0 0 0 1.816 1.735l.328-.345a1.093 1.093 0 0 0 .279-.979l-1.007-4.806a.469.469 0 0 0-.333-.355zm-3.488 4.259a.156.156 0 0 1-.04.14l-.328.344c-.268.282-.758-.126-.46-.439l.687-.721zm.261-3.327-.192-.916 2.892.81.192.916zm3.652 4.424a.156.156 0 0 1-.04.14l-.328.344c-.268.282-.758-.126-.46-.439l.687-.721z"
        transform="translate(-51.301 -41.334)"
        fill="#545151"
      />
      <Path
        data-name="パス 1088"
        d="M83.588 26.735a.468.468 0 1 0-.016.684.474.474 0 0 0 .016-.684"
        transform="translate(-46.299 -14.877)"
        fill="#545151"
      />
      <Path
        data-name="パス 1089"
        d="M88.253 25.83a.469.469 0 1 0 .151.624.473.473 0 0 0-.151-.624"
        transform="translate(-48.948 -14.403)"
        fill="#545151"
      />
      <Path
        data-name="パス 1090"
        d="M89.029 30.251a.468.468 0 1 0 .007.663.474.474 0 0 0-.007-.663"
        transform="translate(-49.342 -16.843)"
        fill="#545151"
      />
      <Path
        data-name="パス 1091"
        d="m58.78 13.043-.328.344a.786.786 0 1 1-1.139-1.087l1.3-1.366.324 1.548a.624.624 0 0 1-.159.559"
        transform="translate(-31.929 -6.116)"
        fill="#4a61ab"
      />
      <Path
        data-name="パス 1092"
        d="m49.907 10.555-.328.344a.787.787 0 0 1-1.139-1.085l1.3-1.366.326 1.552a.624.624 0 0 1-.159.559"
        transform="translate(-26.967 -4.724)"
        fill="#4a61ab"
      />
      <Path
        data-name="パス 1093"
        d="m50.783 3.328 3.909 1.094-.331-1.579a.624.624 0 0 0-.443-.473l-2.553-.715a.625.625 0 0 0-.78.73z"
        transform="translate(-28.28 -.912)"
        fill="#4a61ab"
      />
      <Path
        data-name="パス 1094"
        d="M47.806 25.243a.469.469 0 0 0 .363-.555l-.269-1.263a.469.469 0 1 0-.917.192l.264 1.263a.469.469 0 0 0 .555.363"
        transform="translate(-26.27 -12.891)"
        fill="#545151"
      />
      <Path
        data-name="パス 1095"
        d="M49.451 33.1a.469.469 0 0 0 .363-.555l-.265-1.263a.469.469 0 1 0-.917.192l.264 1.263a.469.469 0 0 0 .555.363"
        transform="translate(-27.19 -17.284)"
        fill="#545151"
      />
      <Path
        data-name="パス 1096"
        d="m45.045 29.967.5-.1a.469.469 0 0 0-.192-.918l-.5.1a.469.469 0 0 0 .192.918"
        transform="translate(-24.874 -16.181)"
        fill="#545151"
      />
      <Path
        data-name="パス 1097"
        d="m51.157 28.687.5-.1a.469.469 0 0 0-.192-.918l-.5.1a.469.469 0 1 0 .192.918"
        transform="translate(-28.292 -15.465)"
        fill="#545151"
      />
      <Path
        data-name="パス 1098"
        d="M48.09 6.839a1.285 1.285 0 0 0 1.253-.339l.328-.344a1.094 1.094 0 0 0 .279-.979l-.44-2.1 2.892.81.165.79-1.132 1.185a1.257 1.257 0 0 0 1.817 1.732l.328-.344a1.093 1.093 0 0 0 .279-.979l-1.007-4.808a.469.469 0 0 0-.332-.355L48.61.013a.492.492 0 0 0-.585.547l.632 3.017-1.132 1.189a1.273 1.273 0 0 0 .565 2.073m4.852-.375a.157.157 0 0 1-.04.14l-.328.344c-.268.282-.758-.126-.46-.439l.687-.721zM49.1 1.124l2.892.809.192.917-2.892-.81zm-.9 4.288.687-.721.141.675a.157.157 0 0 1-.04.14l-.328.344c-.268.282-.759-.126-.461-.439"
        transform="translate(-26.39)"
        fill="#545151"
      />
    </G>
  </Svg>
)

CultureIcon.displayName = "CultureIcon"
