import { getResourcePutUrl as getResourcePutUrlApi, ResourcePutUrlParams } from "src/aws/customAPI"
import { execApi } from "src/apis/execApi"
import { appInfo } from "src/constants/AppInfo"

type Params = {
  accessToken: string
} & ResourcePutUrlParams

type Response = {
  url: string
  thumbnailUrl?: string
}

export const getResourcePutUrl = async ({accessToken, ...rest}: Params): Promise<Response | undefined> => {
  const result = await execApi(
    () => getResourcePutUrlApi(accessToken, { ...rest, appInfo }),
    res => res,
    {
      identifiers: ["getResourcePutUrl", "getResourcePutUrlApi"]
    }
  )

  return result.isOk ? result.content : undefined
}
