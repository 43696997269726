import { FC } from "react"
import Svg, { G, Path } from "react-native-svg"
import { Colors } from "src/constants/Colors"

type Props = {
  fill?: string
}

export const QuestionWithCircleIcon: FC<Props> = ({ fill = Colors.greyshBrown }) => (
  <Svg width="15" height="15" viewBox="0 0 15.784 15.784">
    <G data-name="グループ 2716">
      <Path
        data-name="パス 1341"
        d="M37.958 22.7a2.364 2.364 0 0 0-2.358 2.36.6.6 0 0 0 .6.6.607.607 0 0 0 .6-.6 1.153 1.153 0 0 1 1.148-1.148 1.093 1.093 0 0 1 1.148 1.1v.049a1.307 1.307 0 0 1-1.245 1.375.609.609 0 0 0-.517.6v1.634a.6.6 0 0 0 .6.6.607.607 0 0 0 .6-.6v-1.152a2.462 2.462 0 0 0 1.762-2.507 2.285 2.285 0 0 0-2.338-2.311z"
        transform="translate(-30.067 -19.158)"
        fill={fill}
      />
      <Path
        data-name="パス 1342"
        d="M46.175 67.8a.777.777 0 0 0-.776.776.776.776 0 1 0 .776-.776z"
        transform="translate(-38.283 -56.966)"
        fill={fill}
      />
      <Path
        data-name="パス 1343"
        d="M9.29.8a7.892 7.892 0 1 0 7.892 7.892A7.9 7.9 0 0 0 9.29.8zm0 14.587a6.695 6.695 0 1 1 6.695-6.695 6.708 6.708 0 0 1-6.695 6.695z"
        transform="translate(-1.398 -.801)"
        fill={fill}
      />
    </G>
  </Svg>
)

QuestionWithCircleIcon.displayName = "QuestionWithCircleIcon"
