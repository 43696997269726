import { useState } from "react"
import { useAsyncSelector } from "src/hooks/useAsyncSelector"
import { notificationSettingsSelector } from "src/recoil/selectors/settings/notificationSettingsSelector"

export const useNotificationSettingsData = () => {
  const { value: organizations } = useAsyncSelector(notificationSettingsSelector)
  const [activeSections, setActiveSections] = useState<number[]>([])

  return {
    organizations,
    activeSections,
    setActiveSections,
  }
}
